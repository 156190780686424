import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { GetterTree } from "vuex";
import RootState from '@/store/modules/rootState'
import { ITermsAndConditionsState } from "./states";
import { TermsAndConditionsRequestDto } from "Api";

export const getters: GetterTree<ITermsAndConditionsState, RootState> = {
    termsAndConditions               : (state): TermsAndConditionsRequestDto => state.termsAndConditions,
    
    // COMMON
    isLoadingTermsAndConditions      : (state): boolean => state.isLoadingTermsAndConditions,
    isCreatingTermsAndConditions     : (state): boolean => state.isCreatingTermsAndConditions,
    isUpdatingTermsAndConditions     : (state): boolean => state.isUpdatingTermsAndConditions,
    isDeletingTermsAndConditions     : (state): boolean => state.isDeletingTermsAndConditions,
    requestStateTermsAndConditions   : (state): IRequestState => state.requestStateTermsAndConditions,
}