const environment = {
  name: "production",
  OpenIdConnect: {
    Authority: "https://auth-orisis.azurewebsites.net/",
    ClientId: "OrisisFrontProd",
    ClientName: "Orisis Front Prod",
    ClientSecret: "44df3d92-78b3-41ec-9020-d822a1a7b689",
  },
  ResourceServer: {
    Endpoint: process.env.VUE_APP_API_ORISIS,
  },
  CurrentUrl: "https://v2.orisis.fr/",
};
export default environment;
