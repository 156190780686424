import { ProductCategoryRequestDto, ProductRequestDto } from "Api";
import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { ICatalogState } from "./states";
export const getters: GetterTree<ICatalogState, RootState> = {
  catalog(state): ProductRequestDto[] {
    return state.catalog;
  },
  catalogArchived(state): ProductRequestDto[] {
    return state.catalogArchived;
  },
  catalogSortedByProductType(state) {
    return state.catalogSortedByProductType;
  },
  product(state): ProductRequestDto {
    return state.product;
  },

  getWorkElementHeader: (state): any => state.workElementHeader,

  getWorkElementDetails: (state): ProductRequestDto[] =>
    state.workElementDetails,
  getWorkElementDetail: (state): ProductRequestDto => state.workElementDetail,

  isLoadingProduct(state): boolean {
    return state.isLoadingProduct;
  },
  isLoadingProductElements(state): boolean {
    return state.isLoadingProductElements;
  },
  isDeletingWorkElement(state): boolean {
    return state.isDeletingWorkElement;
  },
  isCreatingProduct(state): boolean {
    return state.isCreatingProduct;
  },
  isUpdatingProduct(state): boolean {
    return state.isUpdatingProduct;
  },
  isDeletingProduct(state): boolean {
    return state.isDeletingProduct;
  },
  isFistLoadingCatalog(state): boolean {
    return state.isFirstLoadingCatalog;
  },
  isFirstLoadingProduct(state): boolean {
    return state.isFirstLoadingProduct;
  },
  productCategoryTree(state): ProductCategoryRequestDto {
    return state.productCategoryTree;
  },
  isProductCategoryTreeLoading(state): boolean {
    return state.isProductCategoryTreeLoading;
  },
  isLoadingCatalog(state): boolean {
    return state.isLoadingCatalog;
  },
  isLoadingCatalogArchived(state): boolean {
    return state.isLoadingCatalogArchived;
  },
  isArchivingCatalog(state): boolean {
    return state.isArchivingCatalog;
  },
  workElementValidate(state): boolean {
    return state.workElementValidate;
  },
};
