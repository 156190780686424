import { InvoiceProviderRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { InvoiceProviderMutations } from "./mutations";
import { IInvoiceProviderState } from "./states";
import dayjs from "dayjs";
import {
  CreditProviderType,
  IInvoiceProvider,
  RepartitionType,
  TvasType,
} from "@/types/api-orisis/interfaces/Purchase/invoice-provider/IInvoiceProvider";

import { Timezone } from "@syncfusion/ej2-schedule";

let timezoneSchedule = new Timezone();
import { generateParams } from "@/types/api-orisis/library/FormatOperations";

export let startDate = new Date(
  dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
).toISOString();
export let endDate = new Date(
  dayjs().endOf("month").format("YYYY-MM-DD")
).toISOString();

export const actions: ActionTree<IInvoiceProviderState, RootState> = {
  getInvoicesProviders(
    { commit },
    {
      dateFrom = null,
      dateTo = null,
      updateState = true,
      affairId = null,
      providerIds = [],
      status = [],
      route,
    }: {
      dateFrom: Date;
      dateTo: Date;
      updateState: boolean;
      affairId: number;
      providerIds: Array<number>;
      status: number[];
      route: string;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        switch (route) {
          case "affair":
            commit(
              InvoiceProviderMutations.SET_IS_LOADING_INVOICE_PROVIDERS_LIST_BY_AFFAIR,
              true
            );
            break;
          case "provider":
            commit(
              InvoiceProviderMutations.SET_IS_LOADING_INVOICE_PROVIDERS_LIST_BY_PROVIDER,
              true
            );
            break;
          default:
            commit(
              InvoiceProviderMutations.SET_IS_LOADING_INVOICE_PROVIDER_LIST,
              true
            );
            break;
        }
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/InvoiceProvider/GetInvoicesProviders`;
      let request = new URLSearchParams();
      request = dateFrom
        ? generateParams(
            request,
            [dayjs(dateFrom).format("YYYY-MM-DD")],
            "dateFrom"
          )
        : request;
      request = dateTo
        ? generateParams(
            request,
            [
              dayjs(dateTo)
                .hour(23)
                .minute(59)
                .second(59)
                .format("YYYY-MM-DD HH:mm:ss"),
            ],
            "dateTo"
          )
        : request;
      request = generateParams(request, providerIds, "providerIds");
      request = affairId
        ? generateParams(request, [affairId], "affairId")
        : request;
      request = generateParams(request, status, "status");
      axios
        .get(url, {
          params: {
            affairId: affairId,
            providerIds: providerIds,
            status: status,
            dateFrom: dateFrom ? dayjs(dateFrom).format("YYYY-MM-DD") : null,
            dateTo: dateTo
              ? dayjs(dateTo)
                  .hour(23)
                  .minute(59)
                  .second(59)
                  .format("YYYY-MM-DD HH:mm:ss")
              : null,
            route: route,
          },
        })
        .then((res) => {
          let list = res.data.map((item) => {
            return {
              ...item,
              documentDate: item.documentDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(item.documentDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              sourceDate: item.sourceDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(item.sourceDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              valueDate: item.valueDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(item.valueDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
            };
          });
          if (updateState) {
            switch (route) {
              case "affair":
                commit(
                  InvoiceProviderMutations.SET_IS_LOADING_INVOICE_PROVIDERS_LIST_BY_AFFAIR,
                  false
                );
                commit(
                  InvoiceProviderMutations.SET_INVOICE_PROVIDERS_LIST_BY_AFFAIR,
                  list
                );
                break;
              case "provider":
                commit(
                  InvoiceProviderMutations.SET_IS_LOADING_INVOICE_PROVIDERS_LIST_BY_PROVIDER,
                  false
                );
                commit(
                  InvoiceProviderMutations.SET_INVOICE_PROVIDER_LIST_BY_PROVIDER,
                  list
                );
                break;
              default:
                commit(
                  InvoiceProviderMutations.SET_IS_LOADING_INVOICE_PROVIDER_LIST,
                  false
                );
                commit(
                  InvoiceProviderMutations.SET_INVOICE_PROVIDERS_LIST,
                  list
                );
                break;
            }
          }
          commit(
            InvoiceProviderMutations.SET_REQUEST_STATE_INVOICE_PROVIDER,
            res
          );
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          commit(
            InvoiceProviderMutations.SET_IS_LOADING_INVOICE_PROVIDER_LIST,
            false
          );
          commit(
            InvoiceProviderMutations.SET_IS_LOADING_INVOICE_PROVIDERS_LIST_BY_AFFAIR,
            false
          );
          commit(
            InvoiceProviderMutations.SET_REQUEST_STATE_INVOICE_PROVIDER,
            err
          );
          reject(err);
        });
    });
  },

  getInvoicesProviderById(
    { commit, dispatch },
    {
      invoiceProviderId,
      getPaymentProviders = false,
      updateState = true,
    }: {
      invoiceProviderId: number;
      getPaymentProviders: boolean;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(InvoiceProviderMutations.SET_IS_LOADING_INVOICE_PROVIDER, true);
      }
      axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/InvoiceProvider/GetInvoicesProviderById`,
          {
            params: {
              invoiceProviderId: invoiceProviderId,
            },
          }
        )
        .then(async (res) => {
          if (getPaymentProviders) {
            await dispatch("getPaymentProviders", {
              invoiceProviderId: res.data.id,
              startTo: null,
              endTo: null,
              updateState: false,
            }).then((result) => {
              res.data.paymentProviders = result.map((payment) => {
                return {
                  amount: payment.amount,
                  date: payment.date,
                  paymentMethod: payment.paymentMethod.label,
                };
              });
            });
          }
          let invoiceProvider = {
            ...res.data,
            providerId: res.data.provider ? res.data.provider.id : null,
            purchaseCategoryId: res.data.purchaseCategory
              ? res.data.purchaseCategory.id
              : null,
          };
          if (updateState) {
            commit(
              InvoiceProviderMutations.SET_INVOICE_PROVIDER,
              invoiceProvider
            );
            commit(
              InvoiceProviderMutations.SET_IS_LOADING_INVOICE_PROVIDER,
              false
            );
          }
          commit(
            InvoiceProviderMutations.SET_REQUEST_STATE_INVOICE_PROVIDER,
            res
          );
          resolve(invoiceProvider);
        })
        .catch((err) => {
          console.error(err);
          commit(
            InvoiceProviderMutations.SET_REQUEST_STATE_INVOICE_PROVIDER,
            err
          );
          commit(
            InvoiceProviderMutations.SET_IS_LOADING_INVOICE_PROVIDER,
            false
          );
          reject(err);
        });
    });
  },

  createInvoiceProvider(
    { commit, rootGetters, dispatch },
    { invoiceProvider }: { invoiceProvider: InvoiceProviderRequestDto }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(InvoiceProviderMutations.SET_IS_CREATING_INVOICE_PROVIDER, true);
      axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Purchase/InvoiceProvider/CreateInvoicesProvider`,
          invoiceProvider
        )
        .then((res) => {
          let invoiceProviderFilters = rootGetters.userParametersList.find(
            (elem) => elem.key == "invoiceProviderFilters"
          );
          if (invoiceProviderFilters) {
            dispatch("getInvoicesProviders", {
              dateFrom: JSON.parse(invoiceProviderFilters.value).dateRange
                .startDate,
              dateTo: JSON.parse(invoiceProviderFilters.value).dateRange
                .endDate,
            });
          } else {
            dispatch("setFilter", {
              name: "invoiceProviderFilters",
              filter: {
                dateRangeType: 7,
                dateRange: { startDate: startDate, endDate: endDate },
              },
            });
            dispatch("getInvoicesProviders", {
              dateFrom: startDate,
              dateTo: endDate,
            });
          }
          commit(
            InvoiceProviderMutations.SET_REQUEST_STATE_INVOICE_PROVIDER,
            res
          );
          commit(
            InvoiceProviderMutations.SET_IS_CREATING_INVOICE_PROVIDER,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            InvoiceProviderMutations.SET_REQUEST_STATE_INVOICE_PROVIDER,
            err
          );
          commit(
            InvoiceProviderMutations.SET_IS_CREATING_INVOICE_PROVIDER,
            false
          );
          reject(err);
        });
    });
  },

  updateInvoiceProviderOptions(
    { commit, dispatch },
    {
      invoiceProvider,
      loading = true,
    }: { invoiceProvider: IInvoiceProvider; loading: boolean }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (loading) {
        commit(InvoiceProviderMutations.SET_IS_UPDATING_INVOICE_PROVIDER, true);
      }
      let allPromises = [];
      if (invoiceProvider.invoiceProviderRepartitions?.length > 0) {
        allPromises.push(
          dispatch("updateInvoiceProviderRepartitions", {
            invoiceProviderId: invoiceProvider.id,
            repartition: invoiceProvider.invoiceProviderRepartitions,
          })
        );
      }
      if (invoiceProvider.referentielTvas?.length > 0) {
        allPromises.push(
          dispatch("updateInvoiceProviderTva", {
            invoiceProviderId: invoiceProvider.id,
            tvas: invoiceProvider.referentielTvas,
          })
        );
      }
      Promise.all(allPromises).then((res) => {
        return Promise.all(allPromises)
          .then(() => {
            dispatch("updateInvoicesProvider", {
              invoiceProvider: invoiceProvider,
              loading: loading,
            }).then((res) => {
              commit(InvoiceProviderMutations.SET_INVOICE_PROVIDER, res.data);
              resolve(res);
            });
          })
          .catch((err) => {
            commit(
              InvoiceProviderMutations.SET_IS_UPDATING_INVOICE_PROVIDER,
              false
            );
            console.error(err);
            reject(err);
          });
      });
    });
  },

  updateInvoicesProvider(
    { commit, rootGetters, dispatch },
    {
      invoiceProvider,
      loading = true,
    }: { invoiceProvider: IInvoiceProvider; loading: boolean }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (loading) {
        commit(InvoiceProviderMutations.SET_IS_UPDATING_INVOICE_PROVIDER, true);
      }
      axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/InvoiceProvider/UpdateInvoicesProvider`,
          invoiceProvider
        )
        .then(async (res) => {
          commit(InvoiceProviderMutations.SET_INVOICE_PROVIDER, res.data.data);

          if (loading) {
            let invoiceProviderFilters = rootGetters.userParametersList.find(
              (elem) => elem.key == "invoiceProviderFilters"
            );
            if (invoiceProviderFilters) {
              dispatch("getInvoicesProviders", {
                dateFrom: JSON.parse(invoiceProviderFilters.value).dateRange
                  .startDate,
                dateTo: JSON.parse(invoiceProviderFilters.value).dateRange
                  .endDate,
              });
            } else {
              dispatch("setFilter", {
                name: "invoiceProviderFilters",
                filter: {
                  dateRangeType: 7,
                  dateRange: { startDate: startDate, endDate: endDate },
                },
              });
              dispatch("getInvoicesProviders", {
                dateFrom: startDate,
                dateTo: endDate,
              });
            }
          } else {
            rootGetters.invoiceProvidersList.splice(
              rootGetters.invoiceProvidersList.findIndex(
                (elem) => elem.id == invoiceProvider.id
              ),
              1,
              res.data.data
            );
            commit(
              InvoiceProviderMutations.SET_INVOICE_PROVIDERS_LIST,
              rootGetters.invoiceProvidersList
            );
          }

          commit(
            InvoiceProviderMutations.SET_REQUEST_STATE_INVOICE_PROVIDER,
            res
          );
          if (loading) {
            commit(
              InvoiceProviderMutations.SET_IS_UPDATING_INVOICE_PROVIDER,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            InvoiceProviderMutations.SET_REQUEST_STATE_INVOICE_PROVIDER,
            err
          );
          if (loading) {
            commit(
              InvoiceProviderMutations.SET_IS_UPDATING_INVOICE_PROVIDER,
              false
            );
          }
          reject(err);
        });
    });
  },

  updateInvoiceProviderRepartitions(
    { commit },
    {
      invoiceProviderId,
      repartition,
    }: { invoiceProviderId: number; repartition: RepartitionType }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/InvoiceProvider/UpdateInvoiceProviderRepartitions`,
          repartition,
          {
            params: {
              invoiceProviderId: invoiceProviderId,
            },
          }
        )
        .then((res) => {
          commit(
            InvoiceProviderMutations.SET_REQUEST_STATE_INVOICE_PROVIDER,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            InvoiceProviderMutations.SET_REQUEST_STATE_INVOICE_PROVIDER,
            err
          );
          reject(err);
        });
    });
  },

  updateInvoiceProviderTva(
    { commit },
    { invoiceProviderId, tvas }: { invoiceProviderId: number; tvas: TvasType }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/InvoiceProvider/UpdateInvoiceProviderTva`,
          tvas,
          {
            params: {
              invoiceProviderId: invoiceProviderId,
            },
          }
        )
        .then((res) => {
          commit(
            InvoiceProviderMutations.SET_REQUEST_STATE_INVOICE_PROVIDER,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            InvoiceProviderMutations.SET_REQUEST_STATE_INVOICE_PROVIDER,
            err
          );
          reject(err);
        });
    });
  },

  updateInvoiceProviderCreditProvider(
    { commit, dispatch, rootGetters },
    {
      invoiceProviderId,
      creditProviders,
    }: { invoiceProviderId: number; creditProviders: CreditProviderType }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/InvoiceProvider/UpdateInvoiceProviderCreditProvider",
          creditProviders,
          {
            params: {
              invoiceProviderId: invoiceProviderId,
            },
          }
        )
        .then((res) => {
          let creditProviderFilters = rootGetters.userParametersList.find(
            (elem) => elem.key == "creditProviderFilters"
          );
          let invoiceProviderFilters = rootGetters.userParametersList.find(
            (elem) => elem.key == "invoiceProviderFilters"
          );
          if (creditProviderFilters) {
            dispatch("getCreditsProviders", {
              startTo: JSON.parse(creditProviderFilters.value).dateRange
                .startDate,
              endTo: JSON.parse(creditProviderFilters.value).dateRange.endDate,
            });
          } else {
            dispatch("setFilter", {
              name: "creditProviderFilters",
              filter: {
                dateRangeType: 7,
                dateRange: { startDate: startDate, endDate: endDate },
              },
            });
            dispatch("getCreditsProviders", {
              startTo: startDate,
              endTo: endDate,
            });
          }

          if (invoiceProviderFilters) {
            dispatch("getInvoicesProviders", {
              dateFrom: JSON.parse(invoiceProviderFilters.value).dateRange
                .startDate,
              dateTo: JSON.parse(invoiceProviderFilters.value).dateRange
                .endDate,
            });
          } else {
            dispatch("setFilter", {
              name: "invoiceFilters",
              filter: {
                dateRangeType: 7,
                dateRange: { startDate: startDate, endDate: endDate },
              },
            });
            dispatch("getInvoicesProviders", {
              dateFrom: startDate,
              dateTo: endDate,
            });
          }

          commit(
            InvoiceProviderMutations.SET_REQUEST_STATE_INVOICE_PROVIDER,
            res
          );
          resolve();
        })
        .catch((err) => {
          console.error(err);
          commit(
            InvoiceProviderMutations.SET_REQUEST_STATE_INVOICE_PROVIDER,
            err
          );
          reject();
        });
    });
  },

  updateInvoiceProviderTagInvoiceProvider(
    { commit },
    {
      invoiceProviderIds,
      invoiceProviderTagIds,
    }: {
      invoiceProviderIds: Array<number>;
      invoiceProviderTagIds: Array<number>;
    }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/InvoiceProvider/UpdateInvoiceProviderTagInvoiceProvider`,
          {
            invoiceProviderIds: invoiceProviderIds,
            invoiceProviderTagIds: invoiceProviderTagIds,
          }
        )
        .then((res) => {
          commit(
            InvoiceProviderMutations.SET_REQUEST_STATE_INVOICE_PROVIDER,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            InvoiceProviderMutations.SET_REQUEST_STATE_INVOICE_PROVIDER,
            err
          );
          reject(err);
        });
    });
  },

  uploadInvoiceProviderFile(
    { commit, rootGetters, dispatch },
    { formData }: { formData: any }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/InvoiceProvider/UploadInvoiceProviderFile`,
          formData
        )
        .then(async (res) => {
          let invoiceProviderFilters = rootGetters.userParametersList.find(
            (elem) => elem.key == "invoiceProviderFilters"
          );
          if (invoiceProviderFilters) {
            dispatch("getInvoicesProviders", {
              dateFrom: JSON.parse(invoiceProviderFilters.value).dateRange
                .startDate,
              dateTo: JSON.parse(invoiceProviderFilters.value).dateRange
                .endDate,
            });
          } else {
            dispatch("setFilter", {
              name: "invoiceProviderFilters",
              filter: {
                dateRangeType: 7,
                dateRange: { startDate: startDate, endDate: endDate },
              },
            });
            dispatch("getInvoicesProviders", {
              dateFrom: startDate,
              dateTo: endDate,
            });
          }
          commit(
            InvoiceProviderMutations.SET_REQUEST_STATE_INVOICE_PROVIDER,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            InvoiceProviderMutations.SET_REQUEST_STATE_INVOICE_PROVIDER,
            err
          );
          reject(err);
        });
    });
  },

  deleteInvoiceProviders(
    { commit, rootGetters, dispatch },
    { invoiceProviderIds = [] }: { invoiceProviderIds }
  ) {
    return new Promise<void>((resolve, reject) => {
      commit(InvoiceProviderMutations.SET_IS_DELETING_INVOICE_PROVIDER, true);
      axios
        .delete(
          `${process.env.VUE_APP_API_ORISIS}Purchase/InvoiceProvider/Delete`,
          {
            data: invoiceProviderIds,
          }
        )
        .then((res) => {
          let invoiceProviderFilters = rootGetters.userParametersList.find(
            (elem) => elem.key == "invoiceProviderFilters"
          );
          if (invoiceProviderFilters) {
            dispatch("getInvoicesProviders", {
              dateFrom: JSON.parse(invoiceProviderFilters.value).dateRange
                .startDate,
              dateTo: JSON.parse(invoiceProviderFilters.value).dateRange
                .endDate,
            });
          } else {
            dispatch("setFilter", {
              name: "invoiceProviderFilters",
              filter: {
                dateRangeType: 7,
                dateRange: { startDate: startDate, endDate: endDate },
              },
            });
            dispatch("getInvoicesProviders", {
              dateFrom: startDate,
              dateTo: endDate,
            });
          }

          commit(
            InvoiceProviderMutations.SET_REQUEST_STATE_INVOICE_PROVIDER,
            res
          );
          commit(
            InvoiceProviderMutations.SET_IS_DELETING_INVOICE_PROVIDER,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            InvoiceProviderMutations.SET_REQUEST_STATE_INVOICE_PROVIDER,
            err
          );
          commit(
            InvoiceProviderMutations.SET_IS_DELETING_INVOICE_PROVIDER,
            false
          );
          reject(err);
        });
    });
  },
};
