import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import RootState from "@/store/modules/rootState"
import { IInstitutionTypeState } from "./states";
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

export const state: IInstitutionTypeState = {
    institutionTypesList       : Array<any>(),
    isLoadingInstitutionTypesList   : false,
    isCreatingInstitutionType  : false,
    isUpdatingInstitutionType  : false,
    isDeletingInstitutionType  : false,
    isArchivingInstitutionType : false,
    requestStateInstitutionType: <IRequestState>{},
}

export const InstitutionTypeModule: Module<IInstitutionTypeState, RootState> = {
  state, 
  getters,
  mutations,
  actions
}