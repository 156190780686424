import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IDeliveryFormOptionDefaultState } from "./states";
import { DeliveryFormOptionRequestDto } from "@/../Api";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export const getters: GetterTree<IDeliveryFormOptionDefaultState, RootState> = {
  deliveryFormOptionDefault: (state): DeliveryFormOptionRequestDto =>
    state.deliveryFormOptionDefault,
  deliveryFormOptionDefaultForm: (state): DeliveryFormOptionRequestDto =>
    state.deliveryFormOptionDefaultForm,
  isLoadingDeliveryFormOptionDefault: (state): boolean =>
    state.isLoadingDeliveryFormOptionDefault,
  requestStateDeliveryFormOptionDefault: (state): IRequestState =>
    state.requestStateDeliveryFormOptionDefault,
};
