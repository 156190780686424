import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import RootState from "@/store/modules/rootState";
import { IOrderFormCustomerState } from "./states";
import { Module } from "vuex";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { IOrderFormCustomer } from "@/types/api-orisis/interfaces/invoices/orderFormCustomer/IOrderFormCustomer";
import { IDocumentStats } from "@/types/api-orisis/interfaces/invoices/document/IDocumentStats";
import { IRemaining } from "@/types/api-orisis/interfaces/invoices/document/IRemaining";
import { IFilters } from "@/types/api-orisis/interfaces/invoices/filters/IFilters";
import { IPaymentMethod } from "@/types/api-orisis/interfaces/invoices/paymentMethod/IPaymentMethod";

export const state: IOrderFormCustomerState = {
  orderFormCustomersListStats: <IDocumentStats>{},
  orderFormCustomersListAllStats: <IDocumentStats>{},
  orderFormCustomersRemainingToBill: <IRemaining>{},
  lastDocumentOrderFormCustomerReference: "",
  orderFormCustomersList: Array<IOrderFormCustomer>(),
  orderFormCustomersListTemplates: Array<IOrderFormCustomer>(),
  orderFormCustomersListByAffair: Array<IOrderFormCustomer>(),
  orderFormCustomersListByCompany: Array<IOrderFormCustomer>(),
  orderFormCustomer: <IOrderFormCustomer>{},
  orderTrackingCustomer: <IOrderFormCustomer>{},
  orderFormCustomerPDF: "",
  orderTrackingCustomerPDF: "",
  isValidatingOrderFormCustomer: false,
  isLoadingOrderFormCustomersRemainingToBill: false,
  isLoadingOrderFormCustomersListArchived: false,
  isLoadingLastDocumentOrderFormCustomerReference: false,
  isLoadingOrderFormCustomersList: false,
  isLoadingOrderFormCustomersListStats: false,
  isLoadingOrderFormCustomersListByCompany: false,
  isLoadingOrderFormCustomersListByAffair: false,
  isLoadingOrderFormCustomer: false,
  isLoadingOrderFormCustomerPDF: false,
  isLoadingDuplicatingOrderFormCustomer: false,
  isCreatingOrderFormCustomer: false,
  isUpdatingOrderFormCustomer: false,
  isDeletingOrderFormCustomer: false,
  requestStateOrderFormCustomer: <IRequestState>{},
  requestStateOrderFormCustomerPDF: <IRequestState>{},
  orderFormCustomerFilters: <IFilters>{},
  orderFormCustomermarginStatus: true,
  orderFormCustomerTabActive: "order-form-customer-edit",
  orderFormCustomerPaymentMethods: Array<IPaymentMethod>(),
};

export const OrderFormCustomerModule: Module<
  IOrderFormCustomerState,
  RootState
> = {
  state,
  getters,
  mutations,
  actions,
};
