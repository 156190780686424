import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { InvoiceProviderRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { IInvoiceProviderState } from './states'
import { IFilters } from '@/types/api-orisis/interfaces/invoices/filters/IFilters'


export const getters: GetterTree<IInvoiceProviderState, RootState> = {
  invoiceProvidersList                     : (state): InvoiceProviderRequestDto[] => state.invoiceProvidersList,
  invoiceProvider                          : (state): InvoiceProviderRequestDto => state.invoiceProvider,
  invoiceProviderFilters                   : (state): IFilters => state.invoiceProviderFilters,
  invoiceProvidersListByAffair             : (state): InvoiceProviderRequestDto[] => state.invoiceProvidersListByAffair,
  invoicesProvidersListByProvider            : (state): InvoiceProviderRequestDto[] => state.invoicesProvidersListByProvider,

  // COMMON
  isLoadingInvoiceProvidersList            : (state): boolean => state.isLoadingInvoiceProvidersList,
  isLoadingInvoiceProvider                 : (state): boolean => state.isLoadingInvoiceProvider,
  isCreatingInvoiceProvider                : (state): boolean => state.isCreatingInvoiceProvider,
  isUpdatingInvoiceProvider                : (state): boolean => state.isUpdatingInvoiceProvider,
  isDeletingInvoiceProvider                : (state): boolean => state.isDeletingInvoiceProvider,
  requestStateInvoiceProvider              : (state): IRequestState => state.requestStateInvoiceProvider,
  isLoadingInvoiceProvidersListByAffair    : (state): boolean => state.isLoadingInvoiceProvidersListByAffair,
  isLoadingInvoiceProvidersListByProvider  : (state): boolean => state.isLoadingInvoiceProvidersListByProvider,
}