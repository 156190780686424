import { ProgrammedSmsRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { SmsMutations } from "./mutations";
import { ISmsState } from "./states";
import { Timezone } from "@syncfusion/ej2-schedule";

let timezoneSchedule = new Timezone();

export const actions: ActionTree<ISmsState, RootState> = {
  getHistorySms(
    { commit, rootGetters },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(SmsMutations.SET_IS_LOADING_SMS_LIST, true);
      }
      axios
        .get(process.env.VUE_APP_API_ORISIS + "Plannings/Sms/GetHistorySms")
        .then((res) => {
          let list = res.data.map((item) => {
            return {
              ...item,
              sendAt: item.sendAt
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(new Date(item.sendAt))
                    )
                  )
                : null,
            };
          });
          if (updateState) {
            commit(SmsMutations.SET_SMS_LIST, list);
            commit(SmsMutations.SET_IS_LOADING_SMS_LIST, false);
          }
          commit(SmsMutations.SET_REQUEST_STATE_SMS, res);
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          commit(SmsMutations.SET_REQUEST_STATE_SMS, err);
          if (updateState) {
            commit(SmsMutations.SET_IS_LOADING_SMS_LIST, false);
          }
          reject(err);
        });
    });
  },

  getHistorySmsByEventId(
    { commit, rootGetters },
    { eventId, updateState = true }: { eventId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(SmsMutations.SET_IS_LOADING_SMS_LIST_BY_EVENT_ID, true);
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Plannings/Sms/GetHistorySmsByEventId",
          {
            params: {
              eventId: eventId,
            },
          }
        )
        .then((res) => {
          let list = res.data;
          commit(SmsMutations.SET_REQUEST_STATE_SMS, res);
          if (updateState) {
            commit(SmsMutations.SET_IS_LOADING_SMS_LIST_BY_EVENT_ID, false);
            commit(SmsMutations.SET_SMS_LIST_BY_EVENT_ID, list);
          }
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          commit(SmsMutations.SET_REQUEST_STATE_SMS, err);
          if (updateState) {
            commit(SmsMutations.SET_IS_LOADING_SMS_LIST_BY_EVENT_ID, false);
          }
          reject(err);
        });
    });
  },

  programSms(
    { commit, rootGetters, dispatch },
    { sms }: { sms: ProgrammedSmsRequestDto }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(SmsMutations.SET_IS_LOADING_SMS, true);
      axios
        .post(
          process.env.VUE_APP_API_ORISIS + "Plannings/Sms/ProgramSms",
          sms,
        )
        .then((res) => {
          dispatch("getHistorySms", {});
          commit(SmsMutations.SET_IS_LOADING_SMS, false);
          commit(SmsMutations.SET_REQUEST_STATE_SMS, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(SmsMutations.SET_REQUEST_STATE_SMS, err);
          commit(SmsMutations.SET_IS_LOADING_SMS, false);
          reject(err);
        });
    });
  },
};
