import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { ReceiptFormOptionMutations } from "./mutations";
import { IReceiptFormOptionState } from "./states";
import { ReceiptFormOptionRequestDto } from "Api";

export const actions: ActionTree<IReceiptFormOptionState, RootState> = {
  getReceiptFormOptionByReceiptFormId(
    { commit },
    {
      receiptFormId,
      updateState = true,
    }: { receiptFormId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          ReceiptFormOptionMutations.SET_IS_LOADING_RECEIPT_FORM_OPTION,
          true
        );
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/ReceiptFormOption/GetReceiptFormOptionByReceiptFormId`;

      axios
        .get(url, {
          params: { receiptFormId },
        })
        .then((res) => {
          if (updateState) {
            commit(
              ReceiptFormOptionMutations.SET_RECEIPT_FORM_OPTION,
              res.data
            );
            commit(
              ReceiptFormOptionMutations.SET_IS_LOADING_RECEIPT_FORM_OPTION,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              ReceiptFormOptionMutations.SET_IS_LOADING_RECEIPT_FORM_OPTION,
              false
            );
          }
          reject(err);
        });
    });
  },

  createReceiptFormOption(
    { commit },
    {
      receiptFormOption,
      updateState = true,
    }: { receiptFormOption: ReceiptFormOptionRequestDto; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          ReceiptFormOptionMutations.SET_IS_LOADING_RECEIPT_FORM_OPTION,
          true
        );
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/ReceiptFormOption/CreateReceiptFormOption`;

      axios
        .post(url, receiptFormOption)
        .then((res) => {
          if (updateState) {
            commit(
              ReceiptFormOptionMutations.SET_RECEIPT_FORM_OPTION,
              res.data.data
            );
            commit(
              ReceiptFormOptionMutations.SET_IS_LOADING_RECEIPT_FORM_OPTION,
              false
            );
          }
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              ReceiptFormOptionMutations.SET_IS_LOADING_RECEIPT_FORM_OPTION,
              false
            );
          }
          reject(err);
        });
    });
  },

  updateReceiptFormOption(
    { commit, rootGetters },
    {
      receiptFormOption,
      updateState = true,
    }: { receiptFormOption: ReceiptFormOptionRequestDto; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          ReceiptFormOptionMutations.SET_IS_LOADING_RECEIPT_FORM_OPTION,
          true
        );
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/ReceiptFormOption/UpdateReceiptFormOption`;

      axios
        .put(url, {
          ...receiptFormOption,
          receiptFormId: rootGetters.receiptFormProvider.id,
        })
        .then((res) => {
          if (updateState) {
            commit(
              ReceiptFormOptionMutations.SET_RECEIPT_FORM_OPTION,
              res.data.data
            );
            commit(
              ReceiptFormOptionMutations.SET_IS_LOADING_RECEIPT_FORM_OPTION,
              false
            );
          }
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              ReceiptFormOptionMutations.SET_IS_LOADING_RECEIPT_FORM_OPTION,
              false
            );
          }
          reject(err);
        });
    });
  },
};
