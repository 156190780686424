import { ReceiptFormDetailRequestDto } from "Api";
import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IReceiptFormProviderDetailState } from "./states";

export const getters: GetterTree<IReceiptFormProviderDetailState, RootState> = {
  getReceiptFormProviderHeader: (state): any => state.receiptFormProviderHeader,
  getReceiptFormProviderDetails: (state): ReceiptFormDetailRequestDto[] =>
    state.receiptFormProviderDetails,
  getReceiptFormProviderDetail: (state): ReceiptFormDetailRequestDto =>
    state.receiptFormProviderDetail,
  isLoadingReceiptFormProviderDetails: (state): boolean =>
    state.isLoadingReceiptFormProviderDetails,
  isLoadingReceiptFormProviderDetailDuplication: (state): boolean =>
    state.isLoadingReceiptFormProviderDetailDuplication,
  isCreatingReceiptFormProviderDetail: (state): boolean =>
    state.isCreatingReceiptFormProviderDetail,
  isUpdatingReceiptFormProviderDetail: (state): boolean =>
    state.isUpdatingReceiptFormProviderDetail,
  isDeletingReceiptFormProviderDetail: (state): boolean =>
    state.isDeletingReceiptFormProviderDetail,
};
