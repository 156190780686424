import { MutationTree } from "vuex";
import { ModalEditRefProductState } from "./states";
import { ReceiptFormDetailRequestDto } from "@/../Api";

export enum ModalEditRefProductMutations {
  SET_IS_ACTIVE_MODAL_EDIT_REF_PRODUCT = "SET_IS_ACTIVE_MODAL_EDIT_REF_PRODUCT",
  SET_IS_EXISTING_PRODUCT = "SET_IS_EXISTING_PRODUCT",
  SET_IS_CHECK_REFERENCE = "SET_IS_CHECK_REFERENCE",
  SET_IS_LINKING_PRODUCT = "SET_IS_LINKING_PRODUCT",
  SET_PRODUCT_TO_CREATE = "SET_PRODUCT_TO_CREATE",
  SET_PRODUCT_PROVIDER_EXISTING = "SET_PRODUCT_PROVIDER_EXISTING",
  SET_REF_TO_PRODUCT = "SET_REF_TO_PRODUCT",
}

export const mutations: MutationTree<ModalEditRefProductState> = {
  [ModalEditRefProductMutations.SET_IS_ACTIVE_MODAL_EDIT_REF_PRODUCT]: (
    state,
    payload: boolean
  ) => {
    state.isActiveModalEditRefProduct = payload;
  },
  [ModalEditRefProductMutations.SET_IS_CHECK_REFERENCE]: (
    state,
    payload: boolean
  ) => {
    state.isCheckReference = payload;
  },
  [ModalEditRefProductMutations.SET_IS_EXISTING_PRODUCT]: (
    state,
    payload: boolean
  ) => {
    state.isExistingProduct = payload;
  },
  [ModalEditRefProductMutations.SET_IS_LINKING_PRODUCT]: (
    state,
    payload: boolean
  ) => {
    state.isLinkingProduct = payload;
  },
  [ModalEditRefProductMutations.SET_PRODUCT_TO_CREATE]: (
    state,
    payload: ReceiptFormDetailRequestDto
  ) => {
    state.productToCreate = payload;
  },
  [ModalEditRefProductMutations.SET_PRODUCT_PROVIDER_EXISTING]: (
    state,
    payload: boolean
  ) => {
    state.productProviderExisting = payload;
  },
  [ModalEditRefProductMutations.SET_REF_TO_PRODUCT]: (
    state,
    payload: string
  ) => {
    state.refToProduct = payload;
  },
};
