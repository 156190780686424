import { ActivityRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { ActivityMutations } from "./mutations";
import { IActivityState } from "./states";
var dayjs = require("dayjs");
import { Timezone } from "@syncfusion/ej2-schedule";
let timezoneSchedule = new Timezone();

export const actions: ActionTree<IActivityState, RootState> = {
  async getActivities(
    { commit },
    {
      startDate = null,
      endDate = null,
      collaboratorId = null,
      companyId = null,
      updateState = true,
    }: {
      startDate: Date;
      endDate: Date;
      collaboratorId: number;
      companyId: number;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(ActivityMutations.SET_IS_LOADING_ACTIVITY_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/Activity/GetActivities`;
      await axios
        .get(url, {
          params: {
            startDate: startDate ? dayjs(startDate).format("YYYY-MM-DD") : null,
            endDate: endDate
              ? dayjs(endDate)
                  .hour(23)
                  .minute(59)
                  .second(59)
                  .format("YYYY-MM-DD HH:mm:ss")
              : null,
            collaboratorId: collaboratorId,
            companyId: companyId,
          },
        })
        .then((res) => {
          let data = res.data.map((activity) => {
            return {
              ...activity,
              activityChanelTypeId: activity.activityChanelType?.id
                ? activity.activityChanelType.id
                : null,
              activityResultTypeId: activity.activityResultType?.id
                ? activity.activityResultType.id
                : null,
              activitySourceTypeId: activity.activitySourceType?.id
                ? activity.activitySourceType.id
                : null,
              activityDate: timezoneSchedule.removeLocalOffset(
                new Date(activity.activityDate)
              ),
              reminderDate: activity.reminderDate
                ? timezoneSchedule.removeLocalOffset(
                    new Date(activity.reminderDate)
                  )
                : null,
            };
          });
          if (updateState) {
            commit(ActivityMutations.SET_IS_LOADING_ACTIVITY_LIST, false);
            commit(ActivityMutations.SET_ACTIVITIES_LIST, data);
          }
          commit(ActivityMutations.SET_REQUEST_STATE_ACTIVITY, res);
          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          commit(ActivityMutations.SET_IS_LOADING_ACTIVITY_LIST, false);
          commit(ActivityMutations.SET_REQUEST_STATE_ACTIVITY, err);
          reject(err);
        });
    });
  },

  async getActivitiesByCollaboratorId(
    { commit },
    {
      startDate = null,
      endDate = null,
      collaboratorId = null,
      updateState = true,
    }: {
      startDate: Date;
      endDate: Date;
      collaboratorId: number;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          ActivityMutations.SET_IS_LOADING_ACTIVITY_LIST_BY_COLLABORATOR,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/Activity/GetActivities`;
      await axios
        .get(url, {
          params: {
            startDate: startDate ? dayjs(startDate).format("YYYY-MM-DD") : null,
            endDate: endDate
              ? dayjs(endDate)
                  .hour(23)
                  .minute(59)
                  .second(59)
                  .format("YYYY-MM-DD HH:mm:ss")
              : null,
            collaboratorId: collaboratorId,
          },
        })
        .then((res) => {
          let data = res.data.map((activity) => {
            return {
              ...activity,
              activityChanelTypeId: activity.activityChanelType?.id
                ? activity.activityChanelType.id
                : null,
              activityResultTypeId: activity.activityResultType?.id
                ? activity.activityResultType.id
                : null,
              activitySourceTypeId: activity.activitySourceType?.id
                ? activity.activitySourceType.id
                : null,
              activityDate: timezoneSchedule.removeLocalOffset(
                new Date(activity.activityDate)
              ),
              reminderDate: activity.reminderDate
                ? timezoneSchedule.removeLocalOffset(
                    new Date(activity.reminderDate)
                  )
                : null,
            };
          });
          if (updateState) {
            commit(
              ActivityMutations.SET_IS_LOADING_ACTIVITY_LIST_BY_COLLABORATOR,
              false
            );
            commit(ActivityMutations.SET_ACTIVITIES_LIST_BY_COLLABORATOR, data);
          }
          commit(ActivityMutations.SET_REQUEST_STATE_ACTIVITY, res);
          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivityMutations.SET_IS_LOADING_ACTIVITY_LIST_BY_COLLABORATOR,
            false
          );
          commit(ActivityMutations.SET_REQUEST_STATE_ACTIVITY, err);
          reject(err);
        });
    });
  },

  async getActivitiesByCompanyId(
    { commit },
    {
      startDate = null,
      endDate = null,
      companyId = null,
      updateState = true,
    }: {
      startDate: Date;
      endDate: Date;
      companyId: number;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(ActivityMutations.SET_IS_LOADING_ACTIVITY_LIST_BY_COMPANY, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/Activity/GetActivities`;
      await axios
        .get(url, {
          params: {
            startDate: startDate ? dayjs(startDate).format("YYYY-MM-DD") : null,
            endDate: endDate
              ? dayjs(endDate)
                  .hour(23)
                  .minute(59)
                  .second(59)
                  .format("YYYY-MM-DD HH:mm:ss")
              : null,
            companyId: companyId,
          },
        })
        .then((res) => {
          let data = res.data.map((activity) => {
            return {
              ...activity,
              activityChanelTypeId: activity.activityChanelType?.id
                ? activity.activityChanelType.id
                : null,
              activityResultTypeId: activity.activityResultType?.id
                ? activity.activityResultType.id
                : null,
              activitySourceTypeId: activity.activitySourceType?.id
                ? activity.activitySourceType.id
                : null,
              activityDate: timezoneSchedule.removeLocalOffset(
                new Date(activity.activityDate)
              ),
              reminderDate: activity.reminderDate
                ? timezoneSchedule.removeLocalOffset(
                    new Date(activity.reminderDate)
                  )
                : null,
            };
          });
          if (updateState) {
            commit(
              ActivityMutations.SET_IS_LOADING_ACTIVITY_LIST_BY_COMPANY,
              false
            );
            commit(ActivityMutations.SET_ACTIVITIES_LIST_BY_COMPANY, data);
          }
          commit(ActivityMutations.SET_REQUEST_STATE_ACTIVITY, res);
          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivityMutations.SET_IS_LOADING_ACTIVITY_LIST_BY_COMPANY,
            false
          );
          commit(ActivityMutations.SET_REQUEST_STATE_ACTIVITY, err);
          reject(err);
        });
    });
  },

  async getActivityById(
    { commit },
    {
      activityId,
      updateState = true,
    }: { activityId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(ActivityMutations.SET_IS_LOADING_ACTIVITY, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Directory/Activity/GetActivityById`,
          {
            params: {
              activityId: activityId,
            },
          }
        )
        .then((res) => {
          let data = res.data.map((activity) => {
            return {
              ...activity,
              activityChanelTypeId: activity.activityChanelType?.id
                ? activity.activityChanelType.id
                : null,
              activityResultTypeId: activity.activityResultType?.id
                ? activity.activityResultType.id
                : null,
              activitySourceTypeId: activity.activitySourceType?.id
                ? activity.activitySourceType.id
                : null,
              activityDate: timezoneSchedule.removeLocalOffset(
                new Date(activity.activityDate)
              ),
              reminderDate: activity.reminderDate
                ? timezoneSchedule.removeLocalOffset(
                    new Date(activity.reminderDate)
                  )
                : null,
            };
          });
          if (updateState) {
            commit(ActivityMutations.SET_ACTIVITY, data);
            commit(ActivityMutations.SET_IS_LOADING_ACTIVITY, false);
          }
          commit(ActivityMutations.SET_REQUEST_STATE_ACTIVITY, res);
          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          commit(ActivityMutations.SET_REQUEST_STATE_ACTIVITY, err);
          commit(ActivityMutations.SET_IS_LOADING_ACTIVITY, false);
          reject(err);
        });
    });
  },

  async getActivitiesArchived(
    { commit },
    {
      startDate = null,
      endDate = null,
      collaboratorId = null,
      companyId = null,
      updateState = true,
    }: {
      startDate: Date;
      endDate: Date;
      collaboratorId: number;
      companyId: number;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(ActivityMutations.SET_IS_LOADING_ACTIVITY_LIST_ARCHIVED, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/Activity/GetActivitiesArchived`;
      await axios
        .get(url, {
          params: {
            startDate: startDate ? dayjs(startDate).format("YYYY-MM-DD") : null,
            endDate: endDate
              ? dayjs(endDate)
                  .hour(23)
                  .minute(59)
                  .second(59)
                  .format("YYYY-MM-DD HH:mm:ss")
              : null,
            collaboratorId: collaboratorId,
            companyId: companyId,
          },
        })
        .then((res) => {
          let data = res.data.map((activity) => {
            return {
              ...activity,
              activityChanelTypeId: activity.activityChanelType?.id
                ? activity.activityChanelType.id
                : null,
              activityResultTypeId: activity.activityResultType?.id
                ? activity.activityResultType.id
                : null,
              activitySourceTypeId: activity.activitySourceType?.id
                ? activity.activitySourceType.id
                : null,
              activityDate: timezoneSchedule.removeLocalOffset(
                new Date(activity.activityDate)
              ),
              reminderDate: activity.reminderDate
                ? timezoneSchedule.removeLocalOffset(
                    new Date(activity.reminderDate)
                  )
                : null,
            };
          });
          if (updateState) {
            commit(
              ActivityMutations.SET_IS_LOADING_ACTIVITY_LIST_ARCHIVED,
              false
            );
            commit(ActivityMutations.SET_ACTIVITIES_LIST_ARCHIVED, data);
          }

          commit(ActivityMutations.SET_REQUEST_STATE_ACTIVITY, res);
          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivityMutations.SET_IS_LOADING_ACTIVITY_LIST_ARCHIVED,
            false
          );
          commit(ActivityMutations.SET_REQUEST_STATE_ACTIVITY, err);
          reject(err);
        });
    });
  },

  async getActivitiesArchivedByCollaboratorId(
    { commit },
    {
      startDate = null,
      endDate = null,
      collaboratorId = null,
      updateState = true,
    }: {
      startDate: Date;
      endDate: Date;
      collaboratorId: number;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          ActivityMutations.SET_IS_LOADING_ACTIVITY_LIST_ARCHIVED_BY_COLLABORATOR,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/Activity/GetActivitiesArchived`;
      await axios
        .get(url, {
          params: {
            startDate: startDate ? dayjs(startDate).format("YYYY-MM-DD") : null,
            endDate: endDate
              ? dayjs(endDate)
                  .hour(23)
                  .minute(59)
                  .second(59)
                  .format("YYYY-MM-DD HH:mm:ss")
              : null,
            collaboratorId: collaboratorId,
            companyId: null,
          },
        })
        .then((res) => {
          let data = res.data.map((activity) => {
            return {
              ...activity,
              activityChanelTypeId: activity.activityChanelType?.id
                ? activity.activityChanelType.id
                : null,
              activityResultTypeId: activity.activityResultType?.id
                ? activity.activityResultType.id
                : null,
              activitySourceTypeId: activity.activitySourceType?.id
                ? activity.activitySourceType.id
                : null,
              activityDate: timezoneSchedule.removeLocalOffset(
                new Date(activity.activityDate)
              ),
              reminderDate: activity.reminderDate
                ? timezoneSchedule.removeLocalOffset(
                    new Date(activity.reminderDate)
                  )
                : null,
            };
          });
          if (updateState) {
            commit(
              ActivityMutations.SET_IS_LOADING_ACTIVITY_LIST_ARCHIVED_BY_COLLABORATOR,
              false
            );
            commit(
              ActivityMutations.SET_ACTIVITIES_LIST_ARCHIVED_BY_COLLABORATOR,
              data
            );
          }

          commit(ActivityMutations.SET_REQUEST_STATE_ACTIVITY, res);
          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivityMutations.SET_IS_LOADING_ACTIVITY_LIST_ARCHIVED_BY_COLLABORATOR,
            false
          );
          commit(ActivityMutations.SET_REQUEST_STATE_ACTIVITY, err);
          reject(err);
        });
    });
  },

  async getActivitiesArchivedByCompanyId(
    { commit },
    {
      startDate = null,
      endDate = null,
      companyId = null,
      updateState = true,
    }: {
      startDate: Date;
      endDate: Date;
      companyId: number;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          ActivityMutations.SET_IS_LOADING_ACTIVITY_LIST_ARCHIVED_BY_COMPANY,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/Activity/GetActivitiesArchived`;
      await axios
        .get(url, {
          params: {
            startDate: startDate ? dayjs(startDate).format("YYYY-MM-DD") : null,
            endDate: endDate
              ? dayjs(endDate)
                  .hour(23)
                  .minute(59)
                  .second(59)
                  .format("YYYY-MM-DD HH:mm:ss")
              : null,
            collaboratorId: null,
            companyId: companyId,
          },
        })
        .then((res) => {
          let data = res.data.map((activity) => {
            return {
              ...activity,
              activityChanelTypeId: activity.activityChanelType?.id
                ? activity.activityChanelType.id
                : null,
              activityResultTypeId: activity.activityResultType?.id
                ? activity.activityResultType.id
                : null,
              activitySourceTypeId: activity.activitySourceType?.id
                ? activity.activitySourceType.id
                : null,
              activityDate: timezoneSchedule.removeLocalOffset(
                new Date(activity.activityDate)
              ),
              reminderDate: activity.reminderDate
                ? timezoneSchedule.removeLocalOffset(
                    new Date(activity.reminderDate)
                  )
                : null,
            };
          });
          if (updateState) {
            commit(
              ActivityMutations.SET_IS_LOADING_ACTIVITY_LIST_ARCHIVED_BY_COMPANY,
              false
            );
            commit(
              ActivityMutations.SET_ACTIVITIES_LIST_ARCHIVED_BY_COMPANY,
              data
            );
          }

          commit(ActivityMutations.SET_REQUEST_STATE_ACTIVITY, res);
          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivityMutations.SET_IS_LOADING_ACTIVITY_LIST_ARCHIVED_BY_COMPANY,
            false
          );
          commit(ActivityMutations.SET_REQUEST_STATE_ACTIVITY, err);
          reject(err);
        });
    });
  },

  async getActivityArchivedById(
    { commit },
    {
      activityId,
      updateState = true,
    }: { activityId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(ActivityMutations.SET_IS_LOADING_ACTIVITY, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Directory/Activity/GetActivityArchivedById`,
          {
            params: {
              activityId: activityId,
            },
          }
        )
        .then((res) => {
          let data = res.data.map((activity) => {
            return {
              ...activity,
              activityChanelTypeId: activity.activityChanelType?.id
                ? activity.activityChanelType.id
                : null,
              activityResultTypeId: activity.activityResultType?.id
                ? activity.activityResultType.id
                : null,
              activitySourceTypeId: activity.activitySourceType?.id
                ? activity.activitySourceType.id
                : null,
              activityDate: timezoneSchedule.removeLocalOffset(
                new Date(activity.activityDate)
              ),
              reminderDate: activity.reminderDate
                ? timezoneSchedule.removeLocalOffset(
                    new Date(activity.reminderDate)
                  )
                : null,
            };
          });
          if (updateState) {
            commit(ActivityMutations.SET_ACTIVITY, data);
            commit(ActivityMutations.SET_IS_LOADING_ACTIVITY, false);
          }
          commit(ActivityMutations.SET_REQUEST_STATE_ACTIVITY, res);
          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          commit(ActivityMutations.SET_REQUEST_STATE_ACTIVITY, err);
          commit(ActivityMutations.SET_IS_LOADING_ACTIVITY, false);
          reject(err);
        });
    });
  },

  // getLastActivitiesOfCompanies

  async getReminders(
    { commit },
    {
      collaboratorId = null,
      companyId = null,
      updateState = true,
    }: { collaboratorId?: number; companyId?: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(ActivityMutations.SET_IS_LOADING_REMINDERS_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/Activity/GetReminders`;
      await axios
        .get(url, {
          params: {
            collaboratorId: collaboratorId,
            companyId: companyId,
          },
        })
        .then((res) => {
          let data = res.data.map((reminder) => {
            return {
              ...reminder,
              reminderDate: reminder.reminderDate
                ? timezoneSchedule.removeLocalOffset(
                    new Date(reminder.reminderDate)
                  )
                : null,
            };
          });
          if (updateState) {
            commit(ActivityMutations.SET_IS_LOADING_REMINDERS_LIST, false);
            commit(ActivityMutations.SET_REMINDERS_LIST, data);
          }
          commit(ActivityMutations.SET_REQUEST_STATE_ACTIVITY, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ActivityMutations.SET_IS_LOADING_REMINDERS_LIST, false);
          commit(ActivityMutations.SET_REQUEST_STATE_ACTIVITY, err);
          reject(err);
        });
    });
  },

  async getRemindersByCollaboratorId(
    { commit, rootGetters },
    {
      collaboratorId,
      updateState = true,
    }: { collaboratorId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          ActivityMutations.SET_IS_LOADING_REMINDERS_LIST_BY_COLLABORATOR,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/Activity/GetReminders`;
      await axios
        .get(url, {
          params: {
            collaboratorId: collaboratorId,
          },
        })
        .then((res) => {
          let data = res.data.map((reminder) => {
            return {
              ...reminder,
              reminderDate: reminder.reminderDate
                ? timezoneSchedule.removeLocalOffset(
                    new Date(reminder.reminderDate)
                  )
                : null,
            };
          });
          if (updateState) {
            commit(
              ActivityMutations.SET_IS_LOADING_REMINDERS_LIST_BY_COLLABORATOR,
              false
            );
            commit(ActivityMutations.SET_REMINDERS_LIST_BY_COLLABORATOR, data);
          }
          commit(ActivityMutations.SET_REQUEST_STATE_ACTIVITY, res);
          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivityMutations.SET_IS_LOADING_REMINDERS_LIST_BY_COLLABORATOR,
            false
          );
          commit(ActivityMutations.SET_REQUEST_STATE_ACTIVITY, err);
          reject(err);
        });
    });
  },

  async getRemindersByCompanyId(
    { commit },
    {
      companyId,
      updateState = true,
    }: { companyId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          ActivityMutations.SET_IS_LOADING_REMINDERS_LIST_BY_COMPANY,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/Activity/GetReminders`;
      await axios
        .get(url, {
          params: {
            companyId: companyId,
          },
        })
        .then((res) => {
          let data = res.data.map((reminder) => {
            return {
              ...reminder,
              reminderDate: reminder.reminderDate
                ? timezoneSchedule.removeLocalOffset(
                    new Date(reminder.reminderDate)
                  )
                : null,
            };
          });
          if (updateState) {
            commit(
              ActivityMutations.SET_IS_LOADING_REMINDERS_LIST_BY_COMPANY,
              false
            );
            commit(ActivityMutations.SET_REMINDERS_LIST_BY_COMPANY, data);
          }
          commit(ActivityMutations.SET_REQUEST_STATE_ACTIVITY, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivityMutations.SET_IS_LOADING_REMINDERS_LIST_BY_COMPANY,
            false
          );
          commit(ActivityMutations.SET_REQUEST_STATE_ACTIVITY, err);
          reject(err);
        });
    });
  },

  async createActivity(
    { commit, rootGetters },
    { activity, route }: { activity: ActivityRequestDto; route: string }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(ActivityMutations.SET_IS_CREATING_ACTIVITY, true);
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Directory/Activity/CreateActivity`,
          activity
        )
        .then((res) => {
          if (route == "activity") {
            rootGetters.activitiesList.push(res.data.data);
            commit(
              ActivityMutations.SET_ACTIVITIES_LIST,
              rootGetters.activitiesList
            );
          } else if (route == "company") {
            rootGetters.activitiesListByCompany.push(res.data.data);
            commit(
              ActivityMutations.SET_ACTIVITIES_LIST_BY_COMPANY,
              rootGetters.activitiesListByCompany
            );
          } else if (route == "collaborator") {
            rootGetters.activitiesListByCollaborator.push(res.data.data);
            commit(
              ActivityMutations.SET_ACTIVITIES_LIST_BY_COLLABORATOR,
              rootGetters.activitiesListByCollaborator
            );
          }
          commit(ActivityMutations.SET_REQUEST_STATE_ACTIVITY, res);
          commit(ActivityMutations.SET_IS_CREATING_ACTIVITY, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ActivityMutations.SET_REQUEST_STATE_ACTIVITY, err);
          commit(ActivityMutations.SET_IS_CREATING_ACTIVITY, false);
          reject(err);
        });
    });
  },

  async updateActivity(
    { commit, rootGetters },
    { activity, route }: { activity: ActivityRequestDto; route: string }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(ActivityMutations.SET_IS_UPDATING_ACTIVITY, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Directory/Activity/UpdateActivity`,
          activity
        )
        .then((res) => {
          if (route == "activity") {
            rootGetters.activitiesList.splice(
              rootGetters.activitiesList.findIndex(
                (activity) => activity.id == activity.id
              ),
              1,
              res.data.data
            );
            commit(
              ActivityMutations.SET_ACTIVITIES_LIST,
              rootGetters.activitiesList
            );
          } else if (route == "company") {
            rootGetters.activitiesListByCompany.splice(
              rootGetters.activitiesListByCompany.findIndex(
                (activity) => activity.id == activity.id
              ),
              1,
              res.data.data
            );
            commit(
              ActivityMutations.SET_ACTIVITIES_LIST_BY_COMPANY,
              rootGetters.activitiesListByCompany
            );
          } else if (route == "collaborator") {
            rootGetters.activitiesListByCollaborator.splice(
              rootGetters.activitiesListByCollaborator.findIndex(
                (activity) => activity.id == activity.id
              ),
              1,
              res.data.data
            );
            commit(
              ActivityMutations.SET_ACTIVITIES_LIST_BY_COLLABORATOR,
              rootGetters.activitiesListByCollaborator
            );
          }
          commit(ActivityMutations.SET_REQUEST_STATE_ACTIVITY, res);
          commit(ActivityMutations.SET_IS_UPDATING_ACTIVITY, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ActivityMutations.SET_REQUEST_STATE_ACTIVITY, err);
          commit(ActivityMutations.SET_IS_UPDATING_ACTIVITY, false);
          reject(err);
        });
    });
  },

  async restoreActivities(
    { commit, rootGetters },
    { activityIds = [], route }: { activityIds: number[]; route: string }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(ActivityMutations.SET_IS_UPDATING_ACTIVITY, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Directory/Activity/Restore",
          activityIds
        )
        .then(async (res) => {
          if (route == "activity") {
            activityIds.forEach((activityId: number) => {
              rootGetters.activitiesListArchived.splice(
                rootGetters.activitiesListArchived.findIndex(
                  (activity) => activity.id == activityId
                ),
                1
              );
            });
            commit(
              ActivityMutations.SET_ACTIVITIES_LIST_ARCHIVED,
              rootGetters.activitiesListArchived
            );
          } else if (route == "company") {
            activityIds.forEach((activityId: number) => {
              rootGetters.activitiesListByCompanyArchived.splice(
                rootGetters.activitiesListByCompanyArchived.findIndex(
                  (activity) => activity.id == activityId
                ),
                1
              );
            });
            commit(
              ActivityMutations.SET_ACTIVITIES_LIST_ARCHIVED_BY_COMPANY,
              rootGetters.activitiesListByCompanyArchived
            );
          } else if (route == "collaborator") {
            activityIds.forEach((activityId: number) => {
              rootGetters.activitiesListByCollaboratorArchived.splice(
                rootGetters.activitiesListByCollaboratorArchived.findIndex(
                  (activity) => activity.id == activityId
                ),
                1
              );
            });
            commit(
              ActivityMutations.SET_ACTIVITIES_LIST_ARCHIVED_BY_COLLABORATOR,
              rootGetters.activitiesListByCollaboratorArchived
            );
          }
          commit(ActivityMutations.SET_REQUEST_STATE_ACTIVITY, res);
          commit(ActivityMutations.SET_IS_UPDATING_ACTIVITY, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ActivityMutations.SET_REQUEST_STATE_ACTIVITY, err);
          commit(ActivityMutations.SET_IS_UPDATING_ACTIVITY, false);
          reject(err);
        });
    });
  },

  async archiveActivities(
    { commit, rootGetters },
    { activityIds = [], route }: { activityIds: number[]; route: string }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(ActivityMutations.SET_IS_ARCHIVING_ACTIVITY, true);
      await axios
        .delete(process.env.VUE_APP_API_ORISIS + "Directory/Activity/Archive", {
          data: activityIds,
        })
        .then((res) => {
          if (route == "activity") {
            activityIds.forEach((activityId: number) => {
              rootGetters.activitiesList.splice(
                rootGetters.activitiesList.findIndex(
                  (activity) => activity.id == activityId
                ),
                1
              );
            });
            commit(
              ActivityMutations.SET_ACTIVITIES_LIST,
              rootGetters.activitiesList
            );
          } else if (route == "company") {
            activityIds.forEach((activityId: number) => {
              rootGetters.activitiesListByCompany.splice(
                rootGetters.activitiesListByCompany.findIndex(
                  (activity) => activity.id == activityId
                ),
                1
              );
            });
            commit(
              ActivityMutations.SET_ACTIVITIES_LIST_BY_COMPANY,
              rootGetters.activitiesListByCompany
            );
          } else if (route == "collaborator") {
            activityIds.forEach((activityId: number) => {
              rootGetters.activitiesListByCollaborator.splice(
                rootGetters.activitiesListByCollaborator.findIndex(
                  (activity) => activity.id == activityId
                ),
                1
              );
            });
            commit(
              ActivityMutations.SET_ACTIVITIES_LIST_BY_COLLABORATOR,
              rootGetters.activitiesListByCollaborator
            );
          }
          commit(ActivityMutations.SET_REQUEST_STATE_ACTIVITY, res);
          commit(ActivityMutations.SET_IS_ARCHIVING_ACTIVITY, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ActivityMutations.SET_REQUEST_STATE_ACTIVITY, err);
          commit(ActivityMutations.SET_IS_ARCHIVING_ACTIVITY, false);
          reject(err);
        });
    });
  },

  async deleteActivities(
    { commit, rootGetters },
    { activityIds = [], route }: { activityIds: number[]; route: string }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(ActivityMutations.SET_IS_DELETING_ACTIVITY, true);
      await axios
        .delete(process.env.VUE_APP_API_ORISIS + "Directory/Activity/Delete", {
          data: activityIds,
        })
        .then((res) => {
          if (route == "activity") {
            activityIds.forEach((activityId: number) => {
              rootGetters.activitiesListArchived.splice(
                rootGetters.activitiesListArchived.findIndex(
                  (activity) => activity.id == activityId
                ),
                1
              );
            });
            commit(
              ActivityMutations.SET_ACTIVITIES_LIST_ARCHIVED,
              rootGetters.activitiesListArchived
            );
          } else if (route == "company") {
            activityIds.forEach((activityId: number) => {
              rootGetters.activitiesListByCompanyArchived.splice(
                rootGetters.activitiesListByCompanyArchived.findIndex(
                  (activity) => activity.id == activityId
                ),
                1
              );
            });
            commit(
              ActivityMutations.SET_ACTIVITIES_LIST_ARCHIVED_BY_COMPANY,
              rootGetters.activitiesListByCompanyArchived
            );
          } else if (route == "collaborator") {
            activityIds.forEach((activityId: number) => {
              rootGetters.activitiesListByCollaboratorArchived.splice(
                rootGetters.activitiesListByCollaboratorArchived.findIndex(
                  (activity) => activity.id == activityId
                ),
                1
              );
            });
            commit(
              ActivityMutations.SET_ACTIVITIES_LIST_ARCHIVED_BY_COLLABORATOR,
              rootGetters.activitiesListByCollaboratorArchived
            );
          }
          commit(ActivityMutations.SET_REQUEST_STATE_ACTIVITY, res);
          commit(ActivityMutations.SET_IS_DELETING_ACTIVITY, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ActivityMutations.SET_REQUEST_STATE_ACTIVITY, err);
          commit(ActivityMutations.SET_IS_DELETING_ACTIVITY, false);
          reject(err);
        });
    });
  },
};
