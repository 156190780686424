import { IActivityState } from "./states";
import { Module } from "vuex";
import { ActivityRequestDto } from "@/../Api";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import RootState from "@/store/modules/rootState";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

const state: IActivityState = {
  activitiesList: Array<ActivityRequestDto>(),
  activitiesListArchived: Array<ActivityRequestDto>(),
  activitiesListByCollaborator: Array<ActivityRequestDto>(),
  activitiesListArchivedByCollaborator: Array<ActivityRequestDto>(),
  activitiesListByCompany: Array<ActivityRequestDto>(),
  activitiesListArchivedByCompany: Array<ActivityRequestDto>(),
  activity: <ActivityRequestDto>{},
  remindersList: Array<ActivityRequestDto>(),
  remindersListByCollaborator: Array<ActivityRequestDto>(),
  remindersListByCompany: Array<ActivityRequestDto>(),
  isLoadingActivitiesList: false,
  isLoadingActivitiesListArchived: false,
  isLoadingActivitiesListByCollaborator: false,
  isLoadingActivitiesListByCompany: false,
  isLoadingRemindersList: false,
  isLoadingRemindersListByCollaborator: false,
  isLoadingActivitiesListArchivedByCollaborator: false,
  isLoadingRemindersListByCompany: false,
  isLoadingActivitiesListArchivedByCompany: false,
  isLoadingActivity: false,
  isCreatingActivity: false,
  isUpdatingActivity: false,
  isDeletingActivity: false,
  isArchivingActivity: false,
  requestStateActivity: <IRequestState>{},
  activityFilters: {},
  activityFiltersByCollaborator: {},
};

export const ActivityModule: Module<IActivityState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
