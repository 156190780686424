import { MutationTree } from "vuex";
import { IOrderFormProviderDetailState } from "./states";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export enum OrderFormProviderDetailMutations {
    SET_ORDER_FORM_HEADER = "SET_ORDER_FORM_HEADER",
    SET_ORDER_FORM_DETAIL = "SET_ORDER_FORM_DETAIL",
    SET_ORDER_FORM_DETAILS = "SET_ORDER_FORM_DETAILS",
    SET_IS_LOADING_ORDER_FORM_DETAILS = "SET_IS_LOADING_ORDER_FORM_DETAILS",
    SET_IS_LOADING_ORDER_FORM_DETAIL_DUPLICATION = "SET_IS_LOADING_ORDER_FORM_DETAIL_DUPLICATION",
    SET_IS_CREATING_ORDER_FORM_DETAIL = "SET_IS_CREATING_ORDER_FORM_DETAIL",
    SET_IS_UPDATING_ORDER_FORM_DETAIL = "SET_IS_UPDATING_ORDER_FORM_DETAIL",
    SET_IS_DELETING_ORDER_FORM_DETAIL = "SET_IS_DELETING_ORDER_FORM_DETAIL",
    SET_REQUEST_STATE_ORDER_FORM_DETAIL = "SET_REQUEST_STATE_ORDER_FORM_DETAIL",
}

export const mutations: MutationTree<IOrderFormProviderDetailState> = {
    [OrderFormProviderDetailMutations.SET_ORDER_FORM_HEADER]: (state, payload: any) => {
        state.orderFormProviderHeader = payload;
    },
    [OrderFormProviderDetailMutations.SET_ORDER_FORM_DETAILS]: (state, payload: any[]) => {
        state.orderFormProviderDetails = payload;
    },
    [OrderFormProviderDetailMutations.SET_ORDER_FORM_DETAIL]: (state, payload: any) => {
        state.orderFormProviderDetail = payload;
    },
    [OrderFormProviderDetailMutations.SET_IS_LOADING_ORDER_FORM_DETAILS]: (state, payload: boolean) => {
        state.isLoadingOrderFormProviderDetails = payload;
    },
    [OrderFormProviderDetailMutations.SET_IS_LOADING_ORDER_FORM_DETAIL_DUPLICATION]: (state, payload: boolean) => {
        state.isLoadingOrderFormProviderDetailDuplication = payload;
    },
    [OrderFormProviderDetailMutations.SET_IS_CREATING_ORDER_FORM_DETAIL]: (state, payload: boolean) => {
        state.isCreatingOrderFormProviderDetail = payload;
    },
    [OrderFormProviderDetailMutations.SET_IS_UPDATING_ORDER_FORM_DETAIL]: (state, payload: boolean) => {
        state.isUpdatingOrderFormProviderDetail = payload;
    },
    [OrderFormProviderDetailMutations.SET_IS_DELETING_ORDER_FORM_DETAIL]: (state, payload: boolean) => {
        state.isDeletingOrderFormProviderDetail = payload;
    },
    [OrderFormProviderDetailMutations.SET_REQUEST_STATE_ORDER_FORM_DETAIL]: (state, payload: IRequestState) => {
        state.requestStateOrderFormProviderDetail = payload;
    },
}