import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { QuoteDownPaymentsRequestMutations } from "./mutations";
import { IQuoteDownPaymentsRequestState } from "./states";
import { QuoteDownPaymentsRequestDto } from "Api";

export const actions: ActionTree<IQuoteDownPaymentsRequestState, RootState> = {
  getQuoteDownPaymentsRequestById(
    { commit, rootGetters },
    {
      quoteDownPaymentsRequestId,
      updateState = true,
    }: { quoteDownPaymentsRequestId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          QuoteDownPaymentsRequestMutations.SET_IS_LOADING_QUOTE_DOWN_PAYMENTS_REQUEST,
          true
        );
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/QuoteDownPaymentsRequest/GetQuoteDownPaymentsRequestById",
          {
            params: {
              quoteDownPaymentsRequestId: quoteDownPaymentsRequestId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(
              QuoteDownPaymentsRequestMutations.SET_IS_LOADING_QUOTE_DOWN_PAYMENTS_REQUEST,
              false
            );
            commit(
              QuoteDownPaymentsRequestMutations.SET_QUOTE_DOWN_PAYMENTS_REQUEST,
              res.data
            );
          }
          commit(
            QuoteDownPaymentsRequestMutations.SET_REQUEST_STATE_QUOTE_DOWN_PAYMENTS_REQUEST,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            QuoteDownPaymentsRequestMutations.SET_REQUEST_STATE_QUOTE_DOWN_PAYMENTS_REQUEST,
            err
          );
          if (updateState) {
            commit(
              QuoteDownPaymentsRequestMutations.SET_IS_LOADING_QUOTE_DOWN_PAYMENTS_REQUEST,
              false
            );
          }
          reject(err);
        });
    });
  },

  getQuoteDownPaymentsRequestsByQuoteId(
    { commit, rootGetters },
    { quoteId, updateState = true }: { quoteId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          QuoteDownPaymentsRequestMutations.SET_IS_LOADING_QUOTE_DOWN_PAYMENTS_REQUEST,
          true
        );
        commit(
          QuoteDownPaymentsRequestMutations.SET_QUOTE_DOWN_PAYMENTS_REQUESTS_LIST,
          []
        );
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/QuoteDownPaymentsRequest/GetQuoteDownPaymentsRequestsByQuoteId",
          {
            params: {
              quoteId: quoteId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(
              QuoteDownPaymentsRequestMutations.SET_IS_LOADING_QUOTE_DOWN_PAYMENTS_REQUEST,
              false
            );
            commit(
              QuoteDownPaymentsRequestMutations.SET_QUOTE_DOWN_PAYMENTS_REQUESTS_LIST,
              res.data
            );
          }
          commit(
            QuoteDownPaymentsRequestMutations.SET_REQUEST_STATE_QUOTE_DOWN_PAYMENTS_REQUEST,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            QuoteDownPaymentsRequestMutations.SET_REQUEST_STATE_QUOTE_DOWN_PAYMENTS_REQUEST,
            err
          );
          if (updateState) {
            commit(
              QuoteDownPaymentsRequestMutations.SET_IS_LOADING_QUOTE_DOWN_PAYMENTS_REQUEST,
              false
            );
          }
          reject(err);
        });
    });
  },

  createQuoteDownPaymentsRequest(
    { commit, dispatch, rootGetters },
    {
      quoteDownPaymentsRequest,
      quoteDownPaymentsRequestIndex = null,
    }: {
      quoteDownPaymentsRequest: any;
      quoteDownPaymentsRequestIndex: number | null;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(
        QuoteDownPaymentsRequestMutations.SET_IS_CREATING_QUOTE_DOWN_PAYMENTS_REQUEST,
        true
      );
      axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            "Sale/QuoteDownPaymentsRequest/CreateQuoteDownPaymentsRequest",
          quoteDownPaymentsRequest
        )
        .then((res) => {
          if (quoteDownPaymentsRequest.institutionSettingsId != null) {
            rootGetters.institutionSettings.quoteDownPaymentsRequestDefaults.splice(
              quoteDownPaymentsRequestIndex,
              1,
              res.data.data
            );
          } else if (quoteDownPaymentsRequest.quoteId != null) {
            rootGetters.quoteDownPaymentsRequestsList.push(res.data.data);
          }
          commit(
            QuoteDownPaymentsRequestMutations.SET_REQUEST_STATE_QUOTE_DOWN_PAYMENTS_REQUEST,
            res
          );
          commit(
            QuoteDownPaymentsRequestMutations.SET_IS_CREATING_QUOTE_DOWN_PAYMENTS_REQUEST,
            false
          );
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            QuoteDownPaymentsRequestMutations.SET_REQUEST_STATE_QUOTE_DOWN_PAYMENTS_REQUEST,
            err
          );
          commit(
            QuoteDownPaymentsRequestMutations.SET_IS_CREATING_QUOTE_DOWN_PAYMENTS_REQUEST,
            false
          );
          reject(err);
        });
    });
  },

  updateQuoteDownPaymentsRequest(
    { commit, rootGetters },
    { quoteDownPaymentsRequest }: { quoteDownPaymentsRequest: any }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(
        QuoteDownPaymentsRequestMutations.SET_IS_UPDATING_QUOTE_DOWN_PAYMENTS_REQUEST,
        true
      );
      axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Sale/QuoteDownPaymentsRequest/UpdateQuoteDownPaymentsRequest",
          quoteDownPaymentsRequest
        )
        .then((res) => {
          if (quoteDownPaymentsRequest.institutionSettingsId != null) {
            rootGetters.institutionSettings.quoteDownPaymentsRequestDefaults.splice(
              rootGetters.institutionSettings.quoteDownPaymentsRequestDefaults.findIndex(
                (elem) => elem.id == quoteDownPaymentsRequest.id
              ),
              1,
              quoteDownPaymentsRequest
            );
          } else if (quoteDownPaymentsRequest.quoteId != null) {
            rootGetters.quoteDownPaymentsRequestsList.splice(
              rootGetters.quoteDownPaymentsRequestsList.findIndex(
                (elem) => elem.id == quoteDownPaymentsRequest.id
              ),
              1,
              quoteDownPaymentsRequest
            );
          }
          commit(
            QuoteDownPaymentsRequestMutations.SET_REQUEST_STATE_QUOTE_DOWN_PAYMENTS_REQUEST,
            res
          );
          commit(
            QuoteDownPaymentsRequestMutations.SET_IS_UPDATING_QUOTE_DOWN_PAYMENTS_REQUEST,
            false
          );
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            QuoteDownPaymentsRequestMutations.SET_REQUEST_STATE_QUOTE_DOWN_PAYMENTS_REQUEST,
            err
          );
          commit(
            QuoteDownPaymentsRequestMutations.SET_IS_UPDATING_QUOTE_DOWN_PAYMENTS_REQUEST,
            false
          );
          reject(err);
        });
    });
  },

  deleteQuoteDownPaymentsRequest(
    { commit, rootGetters },
    { quoteDownPaymentsRequestIds = [], route }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(
        QuoteDownPaymentsRequestMutations.SET_IS_DELETING_QUOTE_DOWN_PAYMENTS_REQUEST,
        true
      );
      axios
        .delete(
          process.env.VUE_APP_API_ORISIS +
            "Sale/QuoteDownPaymentsRequest/Delete",
          {
            data: quoteDownPaymentsRequestIds,
          }
        )
        .then((res) => {
          if (route == "institutionSettings") {
            quoteDownPaymentsRequestIds.forEach(
              (quoteDownPaymentsRequestId) => {
                rootGetters.institutionSettings.quoteDownPaymentsRequestDefaults.splice(
                  rootGetters.institutionSettings.quoteDownPaymentsRequestDefaults.findIndex(
                    (elem) => elem.id == quoteDownPaymentsRequestId
                  ),
                  1
                );
              }
            );
          } else if (route == "quoteDownPaymentsRequests") {
            quoteDownPaymentsRequestIds.forEach(
              (quoteDownPaymentsRequestId) => {
                rootGetters.quoteDownPaymentsRequestsList.splice(
                  rootGetters.quoteDownPaymentsRequestsList.findIndex(
                    (elem) => elem.id == quoteDownPaymentsRequestId
                  ),
                  1
                );
              }
            );
          }
          commit(
            QuoteDownPaymentsRequestMutations.SET_REQUEST_STATE_QUOTE_DOWN_PAYMENTS_REQUEST,
            res
          );
          commit(
            QuoteDownPaymentsRequestMutations.SET_IS_DELETING_QUOTE_DOWN_PAYMENTS_REQUEST,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            QuoteDownPaymentsRequestMutations.SET_REQUEST_STATE_QUOTE_DOWN_PAYMENTS_REQUEST,
            err
          );
          commit(
            QuoteDownPaymentsRequestMutations.SET_IS_DELETING_QUOTE_DOWN_PAYMENTS_REQUEST,
            false
          );
          reject(err);
        });
    });
  },
};
