import { IWasteCollectionPointTypeState } from "./states";
import { MutationTree } from "vuex";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export enum WasteCollectionPointTypeMutations {
  SET_WASTE_COLLECTION_POINT_TYPES_LIST = "SET_WASTE_COLLECTION_POINT_TYPES_LIST",
  SET_WASTE_COLLECTION_POINT_TYPES_LIST_ARCHIVED = "SET_WASTE_COLLECTION_POINT_TYPES_LIST_ARCHIVED",
  SET_WASTE_COLLECTION_POINT_TYPE = "SET_WASTE_COLLECTION_POINT_TYPE",

  // COMON
  SET_IS_LOADING_WASTE_COLLECTION_POINT_TYPES_LIST = "SET_IS_LOADING_WASTE_COLLECTION_POINT_TYPES_LIST",
  SET_IS_LOADING_WASTE_COLLECTION_POINT_TYPES_LIST_ARCHIVED = "SET_IS_LOADING_WASTE_COLLECTION_POINT_TYPES_LIST_ARCHIVED",
  SET_IS_LOADING_WASTE_COLLECTION_POINT_TYPE = "SET_IS_LOADING_WASTE_COLLECTION_POINT_TYPE",
  SET_IS_CREATING_WASTE_COLLECTION_POINT_TYPE = "SET_IS_CREATING_WASTE_COLLECTION_POINT_TYPE",
  SET_IS_UPDATING_WASTE_COLLECTION_POINT_TYPE = "SET_IS_UPDATING_WASTE_COLLECTION_POINT_TYPE",
  SET_IS_DELETING_WASTE_COLLECTION_POINT_TYPE = "SET_IS_DELETING_WASTE_COLLECTION_POINT_TYPE",
  SET_REQUEST_STATE_WASTE_COLLECTION_POINT_TYPE = "SET_REQUEST_STATE_WASTE_COLLECTION_POINT_TYPE",
  SET_IS_ARCHIVING_WASTE_COLLECTION_POINT_TYPE = "SET_IS_ARCHIVING_WASTE_COLLECTION_POINT_TYPE",  
}

  export const mutations: MutationTree<IWasteCollectionPointTypeState> = {
    [WasteCollectionPointTypeMutations.SET_WASTE_COLLECTION_POINT_TYPES_LIST]: (state, payload: any[]) => {
        state.wasteCollectionPointTypesList = payload;
    },
    [WasteCollectionPointTypeMutations.SET_WASTE_COLLECTION_POINT_TYPES_LIST_ARCHIVED]: (state, payload: any[]) => {
        state.wasteCollectionPointTypesListArchived = payload;
    },
    [WasteCollectionPointTypeMutations.SET_WASTE_COLLECTION_POINT_TYPE]: (state, payload: any) => {
        state.wasteCollectionPointType = payload;
    },


    //COMMON
    [WasteCollectionPointTypeMutations.SET_IS_LOADING_WASTE_COLLECTION_POINT_TYPES_LIST]: (state, payload: boolean) => {
        state.isLoadingWasteCollectionPointTypesList = payload;
    },
    [WasteCollectionPointTypeMutations.SET_IS_LOADING_WASTE_COLLECTION_POINT_TYPES_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingWasteCollectionPointTypesListArchived = payload;
    },
    [WasteCollectionPointTypeMutations.SET_IS_LOADING_WASTE_COLLECTION_POINT_TYPE]: (state, payload: boolean) => {
        state.isLoadingWasteCollectionPointType = payload;
    },
    [WasteCollectionPointTypeMutations.SET_IS_CREATING_WASTE_COLLECTION_POINT_TYPE]: (state, payload: boolean) => {
        state.isCreatingWasteCollectionPointType = payload;
    },
    [WasteCollectionPointTypeMutations.SET_IS_UPDATING_WASTE_COLLECTION_POINT_TYPE]: (state, payload: boolean) => {
        state.isUpdatingWasteCollectionPointType = payload;
    },
    [WasteCollectionPointTypeMutations.SET_IS_DELETING_WASTE_COLLECTION_POINT_TYPE]: (state, payload: boolean) => {
        state.isDeletingWasteCollectionPointType = payload;
    },
    [WasteCollectionPointTypeMutations.SET_REQUEST_STATE_WASTE_COLLECTION_POINT_TYPE]: (state, payload: IRequestState) => {
        state.requestStateWasteCollectionPointType = payload;
    },
    [WasteCollectionPointTypeMutations.SET_IS_ARCHIVING_WASTE_COLLECTION_POINT_TYPE]: (state, payload: boolean) => {
        state.isArchivingWasteCollectionPointType = payload;
    }
}