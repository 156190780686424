import { IPlanningViewState } from "./states";
import { MutationTree } from "vuex";
import { IPlanningView } from "@/types/api-orisis/interfaces/Planning/IPlanningViews";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export enum PlanningViewMutations {
  SET_PLANNING_VIEWS_LIST = "SET_PLANNING_VIEWS_LIST",
  SET_PLANNING_VIEWS_LIST_BY_AFFAIR = "SET_PLANNING_VIEWS_LIST_BY_AFFAIR",
  SET_PLANNING_VIEWS_LIST_ARCHIVED = "SET_PLANNING_VIEWS_LIST_ARCHIVED",
  SET_PLANNING_VIEW = "SET_PLANNING_VIEW",
  SET_PLANNING_VIEW_ARCHIVED = "SET_PLANNING_VIEW_ARCHIVED",
  //FILTERS
  SET_PLANNING_FILTERS = "SET_PLANNING_FILTERS",

  // COMON
  SET_IS_LOADING_PLANNING_VIEW_LIST = "SET_IS_LOADING_PLANNING_VIEW_LIST",
  SET_IS_LOADING_PLANNING_VIEW_LIST_BY_AFFAIR = "SET_IS_LOADING_PLANNING_VIEW_LIST_BY_AFFAIR",
  SET_IS_LOADING_PLANNING_VIEW = "SET_IS_LOADING_PLANNING_VIEW",
  SET_IS_LOADING_PLANNING_VIEW_ARCHIVED = "SET_IS_LOADING_PLANNING_VIEW_ARCHIVED",
  SET_IS_CREATING_PLANNING_VIEW = "SET_IS_CREATING_PLANNING_VIEW",
  SET_IS_UPDATING_PLANNING_VIEW = "SET_IS_UPDATING_PLANNING_VIEW",
  SET_IS_DELETING_PLANNING_VIEW = "SET_IS_DELETING_PLANNING_VIEW",
  SET_IS_ARCHIVING_PLANNING_VIEW = "SET_IS_DELETING_PLANNING_VIEW",
  SET_REQUEST_STATE_PLANNING_VIEW = "SET_REQUEST_STATE_PLANNING_VIEW",
  SET_IS_LOADING_PLANNING_VIEW_LIST_ARCHIVED = "SET_IS_LOADING_PLANNING_VIEW_LIST_ARCHIVED",
}

export const mutations: MutationTree<IPlanningViewState> = {
  [PlanningViewMutations.SET_PLANNING_VIEWS_LIST]: (
    state,
    payload: IPlanningView[]
  ) => {
    state.planningViewsList = payload;
  },
  [PlanningViewMutations.SET_PLANNING_VIEWS_LIST_BY_AFFAIR]: (
    state,
    payload: IPlanningView[]
  ) => {
    state.planningViewsListByAffair = payload;
  },
  [PlanningViewMutations.SET_PLANNING_VIEWS_LIST_ARCHIVED]: (
    state,
    payload: IPlanningView[]
  ) => {
    state.planningViewsListArchived = payload;
  },
  [PlanningViewMutations.SET_PLANNING_VIEW]: (
    state,
    payload: IPlanningView
  ) => {
    state.planningView = payload;
  },
  [PlanningViewMutations.SET_PLANNING_VIEW_ARCHIVED]: (
    state,
    payload: IPlanningView
  ) => {
    state.planningViewArchived = payload;
  },

  //FILTERS
  [PlanningViewMutations.SET_PLANNING_FILTERS]: (state, payload: any) => {
    state.planningFilters = payload;
  },

  //COMMON
  [PlanningViewMutations.SET_IS_LOADING_PLANNING_VIEW_LIST]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingPlanningViewsList = payload;
  },
  [PlanningViewMutations.SET_IS_LOADING_PLANNING_VIEW_LIST_BY_AFFAIR]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingPlanningViewsListByAffair = payload;
  },
  [PlanningViewMutations.SET_IS_LOADING_PLANNING_VIEW]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingPlanningView = payload;
  },
  [PlanningViewMutations.SET_IS_LOADING_PLANNING_VIEW_ARCHIVED]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingPlanningViewArchived = payload;
  },
  [PlanningViewMutations.SET_IS_LOADING_PLANNING_VIEW_LIST_ARCHIVED]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingPlanningViewsListArchived = payload;
  },
  [PlanningViewMutations.SET_IS_CREATING_PLANNING_VIEW]: (
    state,
    payload: boolean
  ) => {
    state.isCreatingPlanningView = payload;
  },
  [PlanningViewMutations.SET_IS_UPDATING_PLANNING_VIEW]: (
    state,
    payload: boolean
  ) => {
    state.isUpdatingPlanningView = payload;
  },
  [PlanningViewMutations.SET_IS_DELETING_PLANNING_VIEW]: (
    state,
    payload: boolean
  ) => {
    state.isDeletingPlanningView = payload;
  },
  [PlanningViewMutations.SET_REQUEST_STATE_PLANNING_VIEW]: (
    state,
    payload: IRequestState
  ) => {
    state.requestStatePlanningView = payload;
  },
};
