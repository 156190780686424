import { IEventState } from "./states"
import { Module } from "vuex"
import { EventRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IEventState = {
    eventsList         : Array<EventRequestDto>(),
    eventsListByCollaborator : Array<EventRequestDto>(),
    eventsListByCompany : Array<EventRequestDto>(),
    eventsListArchived : Array<EventRequestDto>(),
    eventsListArchivedByCollaborator : Array<EventRequestDto>(),
    eventsListArchivedByCompany : Array<EventRequestDto>(),
    event              : <EventRequestDto>{},
    isLoadingEventsList     : false,
    isLoadingEventsListByCollaborator     : false,
    isLoadingEventsListByCompany    : false,
    isLoadingEventsListArchived    : false,
    isLoadingEventsListArchivedByCollaborator : false,
    isLoadingEventsListArchivedByCompany : false,
    isLoadingEvent     : false,
    isCreatingEvent    : false,
    isUpdatingEvent    : false,
    isDeletingEvent    : false,
    requestStateEvent: <IRequestState>{},
    eventFilters                : {}
}

export const EventModule: Module<IEventState, RootState> = {
    state, 
    getters,
    mutations,
    actions
} 