import RootState from "@/store/modules/rootState";
import {
  calculateTotalDocumentLine,
  getNewIndex,
} from "@/types/api-orisis/library/DetailDocumentOperations";
import { calculateSubTotalLines } from "@/types/api-orisis/library/DocumentOperations";
import { formatNumber } from "@/types/api-orisis/library/FormatOperations";
import { CommonLine } from "@/types/api-orisis/models/LineModels";
import { CreditDetailRequestDto, DocumentDetailRequestDto } from "Api";
import axios from "axios";
import { ActionTree } from "vuex";
import { DocumentDetailMutations } from "./mutations";
import { IDocumentDetailState } from "./states";

export const actions: ActionTree<IDocumentDetailState, RootState> = {
  getDocumentDetailsByQuoteId(
    { commit },
    { quoteId }: { quoteId: number }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Sale/DocumentDetail/GetDocumentDetailsByQuoteId`,
          {
            params: {
              quoteId: quoteId,
            },
          }
        )
        .then((res) => {
          commit(
            DocumentDetailMutations.SET_DOCUMENT_DETAILS,
            res.data.map((el) => {
              return {
                ...el,
                referencielTvaId: el.referencielTva ? el.referencielTva.id : 5,
                unitId: el.unit ? el.unit.id : 1,
                total: el.quantity ? calculateTotalDocumentLine(el) : 0,
                quoteId: quoteId,
                titre: el.type == 0 || el.type == 11 ? el.description : null,
              };
            })
          );
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            res
          );
          resolve();
        })
        .catch((err) => {
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            err
          );
          reject(err);
        });
    });
  },

  getDocumentDetailsByInvoiceId(
    { commit },
    { invoiceId }: { invoiceId: number }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Sale/DocumentDetail/GetDocumentDetailsByInvoiceId`,
          {
            params: {
              invoiceId: invoiceId,
            },
          }
        )
        .then((res) => {
          commit(
            DocumentDetailMutations.SET_DOCUMENT_DETAILS,
            res.data.map((el) => {
              return {
                ...el,
                referencielTvaId: el.referencielTva ? el.referencielTva.id : 5,
                unitId: el.unit ? el.unit.id : 1,
                total: el.quantity ? calculateTotalDocumentLine(el) : 0,
                invoiceId: invoiceId,
                titre: el.type == 0 || el.type == 11 ? el.description : null,
              };
            })
          );
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            res
          );
          resolve();
        })
        .catch((err) => {
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            err
          );
          reject(err);
        });
    });
  },

  getDocumentDetailNotStoredByInvoiceId(
    {},
    { invoiceId }: { invoiceId: number }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Sale/DocumentDetail/GetDocumentDetailsByInvoiceId`,
          {
            params: {
              invoiceId: invoiceId,
            },
          }
        )
        .then((res) => {
          resolve(
            res.data.map((el) => {
              return {
                ...el,
                referencielTvaId: el.referencielTva ? el.referencielTva.id : 5,
                unitId: el.unit ? el.unit.id : 1,
                total: el.quantity ? calculateTotalDocumentLine(el) : 0,
                invoiceId: invoiceId,
                titre: el.type == 0 || el.type == 11 ? el.description : null,
              };
            })
          );
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // getDocumentDetailById(
  //   {},
  //   { documentDetailId }: { documentDetailId: number }
  // ) {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get(
  //         process.env.VUE_APP_API_ORISIS +
  //           "Sale/DocumentDetail/GetDocumentDetailById",
  //         {
  //           params: {
  //             documentDetailId: documentDetailId,
  //           },
  //         }
  //       )
  //       .then((res) => {
  //         resolve(res);
  //       })
  //       .catch((err) => {
  //         reject(err);
  //       });
  //   });
  // },

  createDocumentDetail(
    { commit, rootGetters },
    { documentDetail }: { documentDetail: DocumentDetailRequestDto }
  ) {
    return new Promise((resolve, reject) => {
      commit(DocumentDetailMutations.SET_IS_CREATING_DOCUMENT_DETAIL, true);
      axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            "Sale/DocumentDetail/CreateDocumentDetail",
          documentDetail
        )
        .then((res) => {
          let newObject = { ...documentDetail, id: res.data.data.id };
          rootGetters.getDocumentDetails.push(newObject);
          commit(
            DocumentDetailMutations.SET_DOCUMENT_DETAILS,
            rootGetters.getDocumentDetails
          );
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            res
          );
          commit(
            DocumentDetailMutations.SET_IS_CREATING_DOCUMENT_DETAIL,
            false
          );
          resolve(newObject);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            err
          );
          commit(
            DocumentDetailMutations.SET_IS_CREATING_DOCUMENT_DETAIL,
            false
          );
          reject(err);
        });
    });
  },

  createDocumentDetailSetPosition(
    { commit, rootGetters },
    {
      documentDetail,
      position,
    }: { documentDetail: DocumentDetailRequestDto; position: number }
  ) {
    return new Promise((resolve, reject) => {
      commit(DocumentDetailMutations.SET_IS_CREATING_DOCUMENT_DETAIL, true);
      axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            "Sale/DocumentDetail/CreateDocumentDetail",
          documentDetail
        )
        .then((res) => {
          let newObject = { ...documentDetail, id: res.data.data.id };
          rootGetters.getDocumentDetails.splice(position, 0, newObject);
          commit(
            DocumentDetailMutations.SET_DOCUMENT_DETAILS,
            rootGetters.getDocumentDetails
          );
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            res
          );
          commit(
            DocumentDetailMutations.SET_IS_CREATING_DOCUMENT_DETAIL,
            false
          );
          resolve(newObject);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            err
          );
          commit(
            DocumentDetailMutations.SET_IS_CREATING_DOCUMENT_DETAIL,
            false
          );
          reject(err);
        });
    });
  },

  duplicateDocumentDetail(
    { commit, rootGetters },
    { documentDetailId }: { documentDetailId: number }
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            "Sale/DocumentDetail/DuplicateDocumentDetails",
          null,
          {
            params: {
              documentDetailId: documentDetailId,
            },
          }
        )
        .then((res) => {
          let result = res.data.data.map((el) => {
            return {
              ...el,
              referencielTvaId: el.referencielTva ? el.referencielTva.id : 5,
              unitId: el.unit ? el.unit.id : 1,
              invoiceId:
                rootGetters.document.nature == 1 ||
                rootGetters.document.nature == 2 ||
                rootGetters.document.nature == 3 ||
                rootGetters.document.nature == 4
                  ? rootGetters.document.id
                  : null,
              quoteId:
                rootGetters.document.nature == 0
                  ? rootGetters.document.id
                  : null,
              total: el.quantity ? calculateTotalDocumentLine(el) : 0,
              titre: el.type == 0 || el.type == 11 ? el.description : null,
            };
          });
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            res
          );
          resolve(result);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            err
          );
          reject(err);
        });
    });
  },

  createDocumentDetailToInvoiceIdFromQuoteId(
    { commit },
    {
      invoiceId,
      quoteId,
      resetSubTotal = false,
    }: { invoiceId: number; quoteId: number; resetSubTotal: boolean }
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            "Sale/DocumentDetail/CreateDocumentDetailsToInvoiceIdFromQuoteId",
          null,
          {
            params: {
              invoiceId: invoiceId,
              quoteId: quoteId,
              resetSubTotal: resetSubTotal,
            },
          }
        )
        .then((res) => {
          let result = res.data.data.map((el) => {
            return {
              ...el,
              referencielTvaId: el.referencielTva ? el.referencielTva.id : 5,
              unitId: el.unit ? el.unit.id : 1,
              total: el.quantity ? calculateTotalDocumentLine(el) : 0,
              invoiceId: invoiceId,
              titre: el.type == 0 || el.type == 11 ? el.description : null,
            };
          });
          commit(DocumentDetailMutations.SET_DOCUMENT_DETAILS, result);
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            res
          );
          resolve(result);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            err
          );
          reject(err);
        });
    });
  },

  createDocumentDetailsToInvoiceIdFromOrderFormCustomerId(
    { commit },
    {
      invoiceId,
      orderFormCustomerId,
      resetSubTotal = false,
    }: {
      invoiceId: number;
      orderFormCustomerId: number;
      resetSubTotal: boolean;
    }
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            "Sale/DocumentDetail/CreateDocumentDetailsToInvoiceIdFromOrderFormCustomerId",
          null,
          {
            params: {
              invoiceId: invoiceId,
              orderFormCustomerId: orderFormCustomerId,
              resetSubTotal: resetSubTotal,
            },
          }
        )
        .then((res) => {
          let result = res.data.data.map((el) => {
            return {
              ...el,
              referencielTvaId: el.referencielTva ? el.referencielTva.id : 5,
              unitId: el.unit ? el.unit.id : 1,
              total: el.quantity ? calculateTotalDocumentLine(el) : 0,
              invoiceId: invoiceId,
              titre: el.type == 0 || el.type == 11 ? el.description : null,
            };
          });
          commit(DocumentDetailMutations.SET_DOCUMENT_DETAILS, result);
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            res
          );
          resolve(result);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            err
          );
          reject(err);
        });
    });
  },

  createDocumentDetailsToSitutationFromPreviousSituation(
    { commit },
    {
      toInvoiceId,
      fromInvoiceId,
      resetSubTotal = true,
    }: { toInvoiceId: number; fromInvoiceId: number; resetSubTotal: boolean }
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            "Sale/DocumentDetail/CreateDocumentDetailsToSitutationFromPreviousSituation",
          null,
          {
            params: {
              toInvoiceId: toInvoiceId,
              fromInvoiceId: fromInvoiceId,
              resetSubTotal: resetSubTotal,
            },
          }
        )
        .then((res) => {
          let result = res.data.data.map((el) => {
            return {
              ...el,
              referencielTvaId: el.referencielTva ? el.referencielTva.id : 5,
              unitId: el.unit ? el.unit.id : 1,
              total: el.quantity ? calculateTotalDocumentLine(el) : 0,
              invoiceId: toInvoiceId,
              titre: el.type == 0 || el.type == 11 ? el.description : null,
            };
          });
          commit(DocumentDetailMutations.SET_DOCUMENT_DETAILS, result);
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            res
          );
          resolve(result);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            err
          );
          reject(err);
        });
    });
  },

  updateDocumentDetail(
    { commit, rootGetters },
    { documentDetail }: { documentDetail: DocumentDetailRequestDto }
  ) {
    return new Promise((resolve, reject) => {
      commit(DocumentDetailMutations.SET_IS_UPDATING_DOCUMENT_DETAIL, true);
      axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Sale/DocumentDetail/UpdateDocumentDetail",
          documentDetail
        )
        .then((res) => {
          rootGetters.getDocumentDetails.splice(
            rootGetters.getDocumentDetails.findIndex(
              (elem) => elem.id == documentDetail.id
            ),
            1,
            documentDetail
          );
          commit(
            DocumentDetailMutations.SET_DOCUMENT_DETAILS,
            rootGetters.getDocumentDetails
          );
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            res
          );
          commit(
            DocumentDetailMutations.SET_IS_UPDATING_DOCUMENT_DETAIL,
            false
          );
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            err
          );
          commit(
            DocumentDetailMutations.SET_IS_UPDATING_DOCUMENT_DETAIL,
            false
          );
          reject(err);
        });
    });
  },

  updateTvaDocumentDetail(
    { commit },
    {
      documentDetailIds = [],
      referencielTvaId,
    }: { documentDetailIds: Array<number>; referencielTvaId: number }
  ) {
    return new Promise((resolve, reject) => {
      commit(DocumentDetailMutations.SET_IS_LOADING_DOCUMENT_DETAILS, true);
      axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Sale/DocumentDetail/UpdateTVADocumentDetail",
          documentDetailIds,
          {
            params: {
              tvaId: referencielTvaId,
            },
          }
        )
        .then((res) => {
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            res
          );
          commit(
            DocumentDetailMutations.SET_IS_LOADING_DOCUMENT_DETAILS,
            false
          );
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            err
          );
          commit(
            DocumentDetailMutations.SET_IS_LOADING_DOCUMENT_DETAILS,
            false
          );
          reject(err);
        });
    });
  },

  updateDocumentDetailIndex(
    { commit },
    { documentDetailIndex }: { documentDetailIndex: number }
  ) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Sale/DocumentDetail/UpdateDocumentDetailIndex",
          documentDetailIndex
        )
        .then((res) => {
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            res
          );
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            err
          );
          reject(err);
        });
    });
  },

  deleteDocumentDetails(
    { commit, dispatch, rootGetters },
    {
      documentDetailIds,
      line,
      reviewIndex,
    }: {
      documentDetailIds: Array<number>;
      line: DocumentDetailRequestDto;
      reviewIndex: number;
    }
  ) {
    return new Promise((resolve, reject) => {
      commit(DocumentDetailMutations.SET_IS_DELETING_DOCUMENT_DETAIL, true);
      axios
        .delete(process.env.VUE_APP_API_ORISIS + "Sale/DocumentDetail/Delete", {
          data: documentDetailIds,
        })
        .then((res) => {
          documentDetailIds.forEach((element) => {
            rootGetters.getDocumentDetails.splice(
              rootGetters.getDocumentDetails.findIndex(
                (elem) => elem.id == element
              ),
              1
            );
          });
          if (
            line.type == 14 ||
            line.type == 15 ||
            line.type == 16 ||
            line.type == 17 ||
            line.type == 18
          ) {
            // On traite les lignes de composant d'un ouvrage
            let workLine = rootGetters.getDocumentDetails.find(
              (el) => el.id == line.parentId
            );
            let workLineElements = rootGetters.getDocumentDetails.filter(
              (el) => el.parentId == line.parentId
            );
            let totalPriceHt = 0;
            let totalDryDisbursed = 0;
            for (let index = 0; index < workLineElements.length; index++) {
              const element = workLineElements[index];
              totalPriceHt += formatNumber(
                element.unitPriceHt * element.quantity
              );
              totalDryDisbursed += formatNumber(
                element.disbursedNetHt * element.quantity
              );
            }
            workLine.unitPriceHt = formatNumber(totalPriceHt);
            workLine.disbursedNetHt = formatNumber(totalDryDisbursed);
            workLine.marginBrutHt = formatNumber(
              workLine.unitPriceHt - workLine.disbursedNetHt
            );
            workLine.marginRate = formatNumber(
              workLine.unitPriceHt == 0
                ? 0
                : (workLine.marginBrutHt / workLine.unitPriceHt) * 100
            );

            // On met à jour le total
            workLine.total = formatNumber(
              workLine.unitPriceHt * workLine.quantity
            );
          }

          if (reviewIndex) {
            dispatch("reviewIndex", 1);
          }
          commit(
            DocumentDetailMutations.SET_DOCUMENT_DETAIL,
            rootGetters.getDocumentDetails
          );
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            res
          );
          commit(
            DocumentDetailMutations.SET_IS_DELETING_DOCUMENT_DETAIL,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            err
          );
          commit(
            DocumentDetailMutations.SET_IS_DELETING_DOCUMENT_DETAIL,
            false
          );
          reject(err);
        });
    });
  },

  loopDocumentDetails(
    { dispatch, rootGetters },
    { referencielTvaId }: { referencielTvaId: number }
  ) {
    return new Promise((resolve, reject) => {
      let documentDetailIds: Array<number> = [];
      for (let i = 0; i < rootGetters.getDocumentDetails.length; i++) {
        const line = rootGetters.getDocumentDetails[i];
        line.referencielTvaId = referencielTvaId;
        documentDetailIds.push(line.id);
      }
      dispatch("updateTvaDocumentDetail", {
        documentDetailIds: documentDetailIds,
        referencielTvaId: referencielTvaId,
      })
        .then((res: any) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async reviewIndex(
    { dispatch, commit, rootGetters },
    { nature }: { nature: number }
  ) {
    commit(DocumentDetailMutations.SET_IS_LOADING_DOCUMENT_DETAILS, true);
    let documentDetailIndex = [];
    for (let i = 0; i < rootGetters.getDocumentDetails.length; i++) {
      const line = rootGetters.getDocumentDetails[i];
      await getNewIndex(rootGetters.getDocumentDetails, {
        ...line,
        indexArray: i - 1,
      }).then(async (res: any) => {
        line.index = res.index;
        documentDetailIndex.push({
          id: line.id,
          index: res.index,
        });
      });
    }
    calculateSubTotalLines(true);
    commit(DocumentDetailMutations.SET_IS_LOADING_DOCUMENT_DETAILS, false);
    if (nature == 5 || nature == 6) {
      dispatch("updateCreditDetailIndex", {
        documentDetailIndex: documentDetailIndex,
      });
    } else {
      dispatch("updateDocumentDetailIndex", {
        documentDetailIndex: documentDetailIndex,
      });
    }
  },

  addLineInvoiceLease(
    { dispatch },
    {
      documentDetail,
      indexArray,
    }: { documentDetail: DocumentDetailRequestDto; indexArray: number }
  ) {
    return new Promise((resolve, reject) => {
      let newObject = CommonLine(documentDetail);
      dispatch("createDocumentDetailSetPosition", {
        documentDetail: newObject,
        position: indexArray,
      })
        .then(() => resolve(true))
        .catch((err) => reject(err));
    });
  },

  addLine(
    { dispatch, rootGetters, commit },
    {
      documentDetail,
      indexArray,
      isCredit,
      review,
    }: {
      documentDetail: DocumentDetailRequestDto;
      indexArray: number;
      isCredit: boolean;
      review: boolean;
    }
  ) {
    return new Promise(async (resolve, reject) => {
      commit(DocumentDetailMutations.SET_DISABLED_OPTIONS, true);
      if (review) {
        let newObject = CommonLine(documentDetail);
        if (isCredit) {
          await dispatch("createCreditDetailSetPosition", {
            creditDetail: newObject,
            position: indexArray,
          });
          await dispatch("reviewIndex", { nature: 6 });
        } else {
          await dispatch("createDocumentDetailSetPosition", {
            documentDetail: newObject,
            position: indexArray,
          });
          await dispatch("reviewIndex", { nature: 1 });
        }
        resolve(true);
      } else {
        getNewIndex(rootGetters.getDocumentDetails, documentDetail)
          .then((res: any) => {
            let newObject = CommonLine({
              ...documentDetail,
              index: res.index,
              type: res.type,
            });
            if (isCredit) {
              dispatch("createCreditDetail", { creditDetail: newObject });
            } else {
              dispatch("createDocumentDetail", { documentDetail: newObject });
            }
            resolve(true);
          })
          .catch((err) => reject(err));
      }

      commit(DocumentDetailMutations.SET_DISABLED_OPTIONS, false);
    });
  },
  async duplicateLine(
    { dispatch, rootGetters, commit },
    { payload, position }: { payload: any; position: string } // A VERIFER (Benjamin) - Cahnger le payload pour avoir line ; isCredit ; index
  ) {
    commit(DocumentDetailMutations.SET_DISABLED_OPTIONS, true);
    let line = JSON.parse(JSON.stringify(payload.line));
    let index = JSON.parse(JSON.stringify(payload.index));
    line.id = 0;
    line.situationProgress = 0;
    line.previousSituationProgress = 0;
    line.situationProgressQuantity = 0;
    line.previousSituationProgressQuantity = 0;

    if (payload.isCredit) {
      switch (position) {
        case "before":
          await dispatch("createCreditDetailSetPosition", {
            creditDetail: { ...line, isDisabled: false },
            position: index,
          });
          await dispatch("reviewIndex", { nature: 6 });
          break;
        case "after":
          await dispatch("createCreditDetailSetPosition", {
            creditDetail: { ...line, isDisabled: false },
            position: index + 1,
          });
          await dispatch("reviewIndex", { nature: 6 });
          break;
        case "beginning":
          await dispatch("createCreditDetailSetPosition", {
            creditDetail: { ...line, isDisabled: false },
            position: 0,
          });
          await dispatch("reviewIndex", { nature: 6 });
          break;
        case "end":
          await dispatch("createCreditDetailSetPosition", {
            creditDetail: { ...line, isDisabled: false },
            position: rootGetters.getDocumentDetails.length,
          });
          await dispatch("reviewIndex", { nature: 6 });
          break;
        default:
          await dispatch("createCreditDetailSetPosition", {
            creditDetail: { ...line, isDisabled: false },
            position: index + 1,
          });
          await dispatch("reviewIndex", { nature: 6 });
          break;
      }
    } else {
      switch (position) {
        case "before":
          await dispatch("createDocumentDetailSetPosition", {
            documentDetail: { ...line, isDisabled: false },
            position: index,
          });
          await dispatch("reviewIndex", { nature: 1 });
          break;
        case "after":
          await dispatch("createDocumentDetailSetPosition", {
            documentDetail: { ...line, isDisabled: false },
            position: index + 1,
          });
          await dispatch("reviewIndex", { nature: 1 });
          break;
        case "beginning":
          await dispatch("createDocumentDetailSetPosition", {
            documentDetail: { ...line, isDisabled: false },
            position: 0,
          });
          await dispatch("reviewIndex", { nature: 1 });
          break;
        case "end":
          await dispatch("createDocumentDetailSetPosition", {
            documentDetail: { ...line, isDisabled: false },
            position: rootGetters.getDocumentDetails.length,
          });
          await dispatch("reviewIndex", { nature: 1 });
          break;
        default:
          await dispatch("createDocumentDetailSetPosition", {
            documentDetail: { ...line, isDisabled: false },
            position: index + 1,
          });
          await dispatch("reviewIndex", { nature: 1 });
          break;
      }
    }
    commit(DocumentDetailMutations.SET_DISABLED_OPTIONS, false);
  },

  async duplicateWorkLine(
    { dispatch, rootGetters, commit },
    {
      documentDetailId,
      workLineElements,
      isCredit,
      index,
      position,
    }: {
      documentDetailId: Number;
      workLineElements: any[];
      isCredit: boolean;
      index: any;
      position: string;
    }
  ) {
    commit(DocumentDetailMutations.SET_DISABLED_OPTIONS, true);
    let workLineLength = workLineElements.length + 1;
    if (isCredit) {
      await dispatch("reviewIndex", { nature: 6 });
      switch (position) {
        case "before":
          await dispatch("duplicateCreditDetail", {
            documentDetailId: documentDetailId,
          }).then(async (res: any) => {
            for (let i = 0; i < res.length; i++) {
              const creditDetail = res[i];
              rootGetters.getDocumentDetails.splice(index + i, 0, creditDetail);
            }
          });
          await dispatch("reviewIndex", { nature: 6 });
          break;
        case "after":
          await dispatch("duplicateCreditDetail", {
            documentDetailId: documentDetailId,
          }).then(async (res: any) => {
            for (let i = 0; i < res.length; i++) {
              const creditDetail = res[i];
              rootGetters.getDocumentDetails.splice(
                index + workLineLength + i,
                0,
                creditDetail
              );
            }
          });
          await dispatch("reviewIndex", { nature: 6 });
          break;
        case "beginning":
          await dispatch("duplicateCreditDetail", {
            documentDetailId: documentDetailId,
          }).then(async (res: any) => {
            for (let i = 0; i < res.length; i++) {
              const creditDetail = res[i];
              rootGetters.getDocumentDetails.splice(0 + i, 0, creditDetail);
            }
          });
          await dispatch("reviewIndex", { nature: 6 });
          break;
        case "end":
          await dispatch("duplicateCreditDetail", {
            documentDetailId: documentDetailId,
          }).then(async (res: any) => {
            for (let i = 0; i < res.length; i++) {
              const creditDetail = res[i];
              rootGetters.getDocumentDetails.splice(
                rootGetters.getDocumentDetails.length + i,
                0,
                creditDetail
              );
            }
          });
          await dispatch("reviewIndex", { nature: 6 });
          break;
        default:
          await dispatch("duplicateCreditDetail", {
            documentDetailId: documentDetailId,
          }).then(async (res: any) => {
            for (let i = 0; i < res.length; i++) {
              const creditDetail = res[i];
              rootGetters.getDocumentDetails.splice(
                index + workLineLength + i,
                0,
                creditDetail
              );
            }
          });
          await dispatch("reviewIndex", { nature: 6 });
          break;
      }
    } else {
      switch (position) {
        case "before":
          await dispatch("duplicateDocumentDetail", {
            documentDetailId: documentDetailId,
          }).then(async (res: any) => {
            for (let i = 0; i < res.length; i++) {
              const documentDetail = res[i];
              rootGetters.getDocumentDetails.splice(
                index + i,
                0,
                documentDetail
              );
            }
          });
          await dispatch("reviewIndex", { nature: 1 });
          break;
        case "after":
          await dispatch("duplicateDocumentDetail", {
            documentDetailId: documentDetailId,
          }).then(async (res: any) => {
            for (let i = 0; i < res.length; i++) {
              const documentDetail = res[i];
              rootGetters.getDocumentDetails.splice(
                index + workLineLength + i,
                0,
                documentDetail
              );
            }
          });
          await dispatch("reviewIndex", { nature: 1 });
          break;
        case "beginning":
          await dispatch("duplicateDocumentDetail", {
            documentDetailId: documentDetailId,
          }).then(async (res: any) => {
            for (let i = 0; i < res.length; i++) {
              const documentDetail = res[i];
              rootGetters.getDocumentDetails.splice(0 + i, 0, documentDetail);
            }
          });
          await dispatch("reviewIndex", { nature: 1 });
          break;
        case "end":
          await dispatch("duplicateDocumentDetail", {
            documentDetailId: documentDetailId,
          }).then(async (res: any) => {
            for (let i = 0; i < res.length; i++) {
              const documentDetail = res[i];
              rootGetters.getDocumentDetails.splice(
                rootGetters.getDocumentDetails.length + i,
                0,
                documentDetail
              );
            }
          });
          await dispatch("reviewIndex", { nature: 1 });
          break;
        default:
          await dispatch("duplicateDocumentDetail", {
            documentDetailId: documentDetailId,
          }).then(async (res: any) => {
            for (let i = 0; i < res.length; i++) {
              const documentDetail = res[i];
              rootGetters.getDocumentDetails.splice(
                index + workLineLength + i,
                0,
                documentDetail
              );
            }
          });
          await dispatch("reviewIndex", { nature: 1 });
          break;
      }
    }
    commit(DocumentDetailMutations.SET_DISABLED_OPTIONS, false);
  },

  // ROUTE CreditDetail

  // getCreditDetailsByCreditId(
  //   {},
  //   { creditId }: { creditId: number }
  // ): Promise<void> {
  //   return new Promise<void>((resolve, reject) => {
  //     axios.get(
  //       `${process.env.VUE_APP_API_ORISIS}Sale/CreditDetail/GetCreditDetailsByCreditId`,
  //       {
  //         params: {
  //           creditId: creditId,
  //         },
  //       }
  //     );
  //   });
  // },

  getDocumentDetailByCreditId(
    { commit },
    { creditId }: { creditId: number }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Sale/CreditDetail/GetCreditDetailsByCreditId`,
          {
            params: {
              creditId: creditId,
            },
          }
        )
        .then((res) => {
          commit(
            DocumentDetailMutations.SET_DOCUMENT_DETAILS,
            res.data.map((el) => {
              return {
                ...el,
                referencielTvaId: el.referencielTva
                  ? el.referencielTva.id
                  : null,
                unitId: el.unit ? el.unit.id : 1,
                total: el.quantity ? calculateTotalDocumentLine(el) : 0,
                creditId: creditId,
                titre: el.type == 0 || el.type == 11 ? el.description : null,
              };
            })
          );
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            res
          );
          resolve();
        })
        .catch((err) => {
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            err
          );
          reject(err);
        });
    });
  },

  createCreditDetail(
    { commit, rootGetters },
    { creditDetail }: { creditDetail: CreditDetailRequestDto }
  ) {
    return new Promise((resolve, reject) => {
      commit(DocumentDetailMutations.SET_IS_CREATING_DOCUMENT_DETAIL, true);
      axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            "Sale/CreditDetail/CreateCreditDetail",
          creditDetail
        )
        .then((res) => {
          let newObject = { ...creditDetail, id: res.data.data.id };
          rootGetters.getDocumentDetails.push(newObject);
          commit(
            DocumentDetailMutations.SET_DOCUMENT_DETAILS,
            rootGetters.getDocumentDetails
          );
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            res
          );
          commit(
            DocumentDetailMutations.SET_IS_CREATING_DOCUMENT_DETAIL,
            false
          );
          resolve(newObject);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            err
          );
          commit(
            DocumentDetailMutations.SET_IS_CREATING_DOCUMENT_DETAIL,
            false
          );
          reject(err);
        });
    });
  },

  duplicateCreditDetail(
    { commit, rootGetters },
    { creditDetailId }: { creditDetailId: number }
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            "Sale/CreditDetail/DuplicateCreditDetails",
          null,
          {
            params: {
              creditDetailId: creditDetailId,
            },
          }
        )
        .then((res) => {
          let result = res.data.data.map((el) => {
            return {
              ...el,
              referencielTvaId: el.referencielTva ? el.referencielTva.id : 5,
              unitId: el.unit ? el.unit.id : 1,
              creditId: rootGetters.document.id,
              total: el.quantity ? calculateTotalDocumentLine(el) : 0,
              titre: el.type == 0 || el.type == 11 ? el.description : null,
            };
          });
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            res
          );
          resolve(result);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            err
          );
          reject(err);
        });
    });
  },

  updateCreditDetail(
    { commit, rootGetters },
    { creditDetail }: { creditDetail: CreditDetailRequestDto }
  ) {
    return new Promise((resolve, reject) => {
      commit(DocumentDetailMutations.SET_IS_UPDATING_DOCUMENT_DETAIL, true);
      axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Sale/CreditDetail/UpdateCreditDetail",
          creditDetail
        )
        .then((res) => {
          rootGetters.getDocumentDetails.splice(
            rootGetters.getDocumentDetails.findIndex(
              (elem) => elem.id == creditDetail.id
            ),
            1,
            creditDetail
          );
          commit(
            DocumentDetailMutations.SET_DOCUMENT_DETAILS,
            rootGetters.getDocumentDetails
          );
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            res
          );
          commit(
            DocumentDetailMutations.SET_IS_UPDATING_DOCUMENT_DETAIL,
            false
          );
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            err
          );
          commit(
            DocumentDetailMutations.SET_IS_UPDATING_DOCUMENT_DETAIL,
            false
          );
          reject(err);
        });
    });
  },

  updateCreditDetailIndex(
    { commit },
    { creditDetailIndex }: { creditDetailIndex: number }
  ) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Sale/CreditDetail/UpdateCreditDetailIndex",
          creditDetailIndex
        )
        .then((res) => {
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            res
          );
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            err
          );
          reject(err);
        });
    });
  },

  deleteCreditDetails(
    { commit, dispatch, rootGetters },
    {
      creditDetailIds = [],
      line,
      reviewIndex,
    }: {
      creditDetailIds: Array<number>;
      line: CreditDetailRequestDto;
      reviewIndex: boolean;
    }
  ) {
    return new Promise((resolve, reject) => {
      commit(DocumentDetailMutations.SET_IS_DELETING_DOCUMENT_DETAIL, true);
      axios
        .delete(
          process.env.VUE_APP_API_ORISIS +
            "Sale/CreditDetail/DeleteCreditDetail",
          {
            data: creditDetailIds,
          }
        )
        .then((res) => {
          res.data.data.forEach((element) => {
            rootGetters.getDocumentDetails.splice(
              rootGetters.getDocumentDetails.findIndex(
                (elem) => elem.id == element
              ),
              1
            );
          });

          // On traite les lignes de composant d'un ouvrage
          if (
            line.type == 14 ||
            line.type == 15 ||
            line.type == 16 ||
            line.type == 17 ||
            line.type == 18
          ) {
            let workLine = rootGetters.getDocumentDetails.find(
              (el) => el.id == line.parentId
            );
            let workLineElements = rootGetters.getDocumentDetails.filter(
              (el) => el.parentId == line.parentId
            );
            let totalPriceHt = 0;
            let totalDryDisbursed = 0;
            for (let index = 0; index < workLineElements.length; index++) {
              const element = workLineElements[index];
              totalPriceHt += formatNumber(
                element.unitPriceHt * element.quantity
              );
              totalDryDisbursed += formatNumber(
                element.disbursedNetHt * element.quantity
              );
            }
            workLine.unitPriceHt = formatNumber(totalPriceHt);
            workLine.disbursedNetHt = formatNumber(totalDryDisbursed);
            workLine.marginBrutHt = formatNumber(
              workLine.unitPriceHt - workLine.disbursedNetHt
            );
            workLine.marginRate = formatNumber(
              workLine.unitPriceHt == 0
                ? 0
                : (workLine.marginBrutHt / workLine.unitPriceHt) * 100
            );

            // On met à jour le total
            workLine.total = formatNumber(
              workLine.unitPriceHt * workLine.quantity
            );
          }

          if (reviewIndex) {
            dispatch("reviewIndex", { nature: 6 });
          }

          commit(
            DocumentDetailMutations.SET_DOCUMENT_DETAIL,
            rootGetters.getDocumentDetails
          );
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            res
          );
          commit(
            DocumentDetailMutations.SET_IS_DELETING_DOCUMENT_DETAIL,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL,
            err
          );
          commit(
            DocumentDetailMutations.SET_IS_DELETING_DOCUMENT_DETAIL,
            false
          );
          reject(err);
        });
    });
  },
};
