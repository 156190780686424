import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { IPaymentCondition } from "@/types/api-orisis/interfaces/invoices/paymentCondition/IPaymentCondition";
import { GetterTree } from "vuex";
import RootState from '@/store/modules/rootState'
import { IPaymentConditionState } from "./states";

export const getters: GetterTree<IPaymentConditionState, RootState> = {
    paymentConditionsList: (state): IPaymentCondition[] => state.paymentConditionsList,
    paymentConditionsListArchived: (state): IPaymentCondition[] => state.paymentConditionsListArchived,
    paymentCondition     : (state): IPaymentCondition => state.paymentCondition,
    
    // COMMON
    isLoadingPaymentConditionsList   : (state): boolean => state.isLoadingPaymentConditionsList,
    isLoadingPaymentConditionsListArchived   : (state): boolean => state.isLoadingPaymentConditionsListArchived,
    isLoadingPaymentCondition   : (state): boolean => state.isLoadingPaymentCondition,
    isCreatingPaymentCondition  : (state): boolean => state.isCreatingPaymentCondition,
    isUpdatingPaymentCondition  : (state): boolean => state.isUpdatingPaymentCondition,
    isDeletingPaymentCondition  : (state): boolean => state.isDeletingPaymentCondition,
    isArchivingPaymentCondition : (state): boolean => state.isArchivingPaymentCondition,
    requestStatePaymentCondition: (state): IRequestState => state.requestStatePaymentCondition,
}