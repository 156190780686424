import { IPayAllowanceTypeState } from "./states"
import { Module } from "vuex"
import { PayAllowanceTypeRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IPayAllowanceTypeState = {
    payAllowanceTypesList         : Array<PayAllowanceTypeRequestDto>(),
    payAllowanceTypesListArchived : Array<PayAllowanceTypeRequestDto>(),
    payAllowanceTypesListTemplates         : Array<PayAllowanceTypeRequestDto>(),
    payAllowanceType              : <PayAllowanceTypeRequestDto>{},
    payAllowanceTypeArchived      : <PayAllowanceTypeRequestDto>{},
    isLoadingPayAllowanceTypesList     : false,
    isLoadingPayAllowanceTypesListArchived     : false,
    isLoadingPayAllowanceTypesListTemplates     : false,
    isLoadingPayAllowanceType     : false,
    isCreatingPayAllowanceType    : false,
    isUpdatingPayAllowanceType    : false,
    isDeletingPayAllowanceType    : false,
    isArchivingPayAllowanceType    : false,
    requestStatePayAllowanceType: <IRequestState>{},
}

export const PayAllowanceTypeModule: Module<IPayAllowanceTypeState, RootState> = {
    state,
    getters,
    mutations,
    actions
}
