import { IAccountState } from "./states";
import { MutationTree } from "vuex";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export enum AccountMutations {
  SET_USER_INFO = "SET_USER_INFO",
  SET_USER_TOKEN = "SET_USER_TOKEN",
  SET_USER_IS_LOGGED_IN = "SET_USER_IS_LOGGED_IN",
  SET_PLANNING_VIEW_SELECTED = "SET_PLANNING_VIEW_SELECTED",
  SET_IS_TOASTING_NOTIFICATION = "SET_IS_TOASTING_NOTIFICATION",
  SET_IS_LOGGIN = "SET_IS_LOGGIN",
  SET_IS_OPEN_SIDEBAR_CAMPAIGN = "SET_IS_OPEN_SIDEBAR_CAMPAIGN",
  SET_IS_LOGGIN_MSG = "SET_IS_LOGGIN_MSG",

  // COMON
  SET_REQUEST_STATE_ACCOUNT = "SET_REQUEST_STATE_ACCOUNT",
}

export const mutations: MutationTree<IAccountState> = {
  [AccountMutations.SET_USER_INFO]: (state, payload: any) => {
    state.userInfo = payload;
    localStorage.setItem("userInfo", JSON.stringify(payload));
  },
  [AccountMutations.SET_USER_TOKEN]: (state, payload: any) => {
    state.userToken = payload;
    localStorage.setItem("userToken", payload);
  },
  [AccountMutations.SET_USER_IS_LOGGED_IN]: (state, payload: boolean) => {
    state.userIsLoggedIn = payload;
    localStorage.setItem("userIsLoggedIn", JSON.stringify(payload));
  },
  [AccountMutations.SET_PLANNING_VIEW_SELECTED]: (state, payload: any) => {
    state.planningViewSelected = payload;
  },
  [AccountMutations.SET_IS_TOASTING_NOTIFICATION]: (
    state,
    payload: boolean
  ) => {
    state.isToastingNotification = payload;
  },
  [AccountMutations.SET_IS_LOGGIN]: (state, payload: boolean) => {
    state.isLoggin = payload;
  },
  [AccountMutations.SET_IS_OPEN_SIDEBAR_CAMPAIGN]: (
    state,
    payload: boolean
  ) => {
    state.isOpenSidebarCampaign = payload;
  },

  [AccountMutations.SET_IS_LOGGIN_MSG]: (state, payload: string) => {
    state.isLogginMsg = payload;
  },

  //COMMON
  [AccountMutations.SET_REQUEST_STATE_ACCOUNT]: (
    state,
    payload: IRequestState
  ) => {
    state.requestStateAccount = payload;
  },
};
