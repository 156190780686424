import { LeaseRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { LeaseMutations } from "./mutations";
import { ILeaseState } from "./states";
var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
dayjs.extend(quarterOfYear);
import { getReferencielTvaValueById } from "@/types/api-orisis/library/DocumentOperations";
import { Timezone } from "@syncfusion/ej2-schedule";
let timezoneSchedule = new Timezone();

export const actions: ActionTree<ILeaseState, RootState> = {
  getLeases(
    { commit },
    { updateState = true }: { updateState?: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(LeaseMutations.SET_IS_LOADING_LEASE_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Management/Lease/GetLeases`;
      axios
        .get(url)
        .then((res) => {
          let lease = res.data.map((item) => {
            return {
              ...item,
              tvaRentId: item.tvaRent ? item.tvaRent.id : null,
              securityDepositReviewPeriodicityId:
                item.securityDepositReviewPeriodicity
                  ? item.securityDepositReviewPeriodicity.id
                  : null,
              rentReviewPeriodicity: item.rentReviewPeriodicity
                ? item.rentReviewPeriodicity.id
                : null,
              propertyTypeId: item.propertyType ? item.propertyType.id : null,
              periodicityId: item.periodicity ? item.periodicity.id : null,
              leaseTypeId: item.leaseType ? item.leaseType.id : null,
              tvaChargeId: item.tvaCharge?.id ? item.tvaCharge.id : null,
              startDate: item.startDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(item.startDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              endDate: item.endDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(new Date(item.endDate))
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              tvaSecurityDepositId: res.data.tvaSecurityDeposit?.id
                ? res.data.tvaSecurityDeposit.id
                : null,
              tvaPropertyTaxId: item.tvaPropertyTax?.id
                ? item.tvaPropertyTax.id
                : null,
            };
          });
          if (updateState) {
            commit(LeaseMutations.SET_LEASES_LIST, lease);
            commit(LeaseMutations.SET_IS_LOADING_LEASE_LIST, false);
          }
          commit(LeaseMutations.SET_REQUEST_STATE_LEASE, res);
          resolve(lease);
        })
        .catch((err) => {
          console.error(err);
          commit(LeaseMutations.SET_IS_LOADING_LEASE_LIST, false);
          commit(LeaseMutations.SET_REQUEST_STATE_LEASE, err);
          reject(err);
        });
    });
  },

  getLeasesByAffairId(
    { commit },
    {
      updateState = true,
      affairId,
    }: { updateState?: boolean; affairId: number }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(LeaseMutations.SET_IS_LOADING_LEASE_LIST_BY_AFFAIR, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Management/Lease/GetLeases`;
      axios
        .get(url, {
          params: {
            affairId: affairId,
          },
        })
        .then((res) => {
          let lease = {
            ...res.data[0],
            tvaRentId: res.data[0].tvaRent ? res.data[0].tvaRent.id : null,
            securityDepositReviewPeriodicityId: res.data[0]
              .securityDepositReviewPeriodicity
              ? res.data[0].securityDepositReviewPeriodicity.id
              : null,
            rentReviewPeriodicity: res.data[0].rentReviewPeriodicity
              ? res.data[0].rentReviewPeriodicity.id
              : null,
            propertyTypeId: res.data[0].propertyType
              ? res.data[0].propertyType.id
              : null,
            periodicityId: res.data[0].periodicity
              ? res.data[0].periodicity.id
              : null,
            leaseTypeId: res.data[0].leaseType
              ? res.data[0].leaseType.id
              : null,
            tvaChargeId: res.data[0].tvaCharge?.id
              ? res.data[0].tvaCharge.id
              : null,
            startDate: res.data[0].startDate
              ? new Date(
                  new Date(
                    timezoneSchedule.removeLocalOffset(
                      new Date(res.data[0].startDate)
                    )
                  ).setHours(12, 0, 0, 0)
                )
              : null,
            endDate: res.data[0].endDate
              ? new Date(
                  new Date(
                    timezoneSchedule.removeLocalOffset(
                      new Date(res.data[0].endDate)
                    )
                  ).setHours(12, 0, 0, 0)
                )
              : null,
            tvaSecurityDepositId: res.data.tvaSecurityDeposit?.id
              ? res.data.tvaSecurityDeposit.id
              : null,
            tvaPropertyTaxId: res.data[0].tvaPropertyTax?.id
              ? res.data[0].tvaPropertyTax.id
              : null,
          };

          if (updateState) {
            commit(LeaseMutations.SET_LEASES_LIST_BY_AFFAIR, lease);
            commit(LeaseMutations.SET_IS_LOADING_LEASE_LIST_BY_AFFAIR, false);
          }
          commit(LeaseMutations.SET_REQUEST_STATE_LEASE, res);
          resolve(lease);
        })
        .catch((err) => {
          console.error(err);
          commit(LeaseMutations.SET_IS_LOADING_LEASE_LIST_BY_AFFAIR, false);
          commit(LeaseMutations.SET_REQUEST_STATE_LEASE, err);
          reject(err);
        });
    });
  },

  getLeaseById(
    { commit, dispatch },
    { leaseId, updateState = true }: { leaseId: number; updateState?: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(LeaseMutations.SET_IS_LOADING_LEASE, true);
      }
      axios
        .get(`${process.env.VUE_APP_API_ORISIS}Management/Lease/GetLeaseById`, {
          params: {
            leaseId: leaseId,
          },
        })
        .then((res) => {
          let lease = {
            ...res.data,
            leaseTypeId: res.data.leaseType?.id ? res.data.leaseType.id : null,
            periodicityId: res.data.periodicity?.id
              ? res.data.periodicity.id
              : null,
            propertyTypeId: res.data.propertyType?.id
              ? res.data.propertyType.id
              : null,
            rentReviewPeriodicityId: res.data.rentReviewPeriodicity?.id
              ? res.data.rentReviewPeriodicity.id
              : null,
            securityDepositReviewPeriodicityId: res.data
              .securityDepositReviewPeriodicity?.id
              ? res.data.securityDepositReviewPeriodicity.id
              : null,
            tvaChargeId: res.data.tvaCharge?.id ? res.data.tvaCharge.id : null,
            tvaRentId: res.data.tvaRent?.id ? res.data.tvaRent.id : null,
            tvaSecurityDepositId: res.data.tvaSecurityDeposit?.id
              ? res.data.tvaSecurityDeposit.id
              : null,
            tvaPropertyTaxId: res.data.tvaPropertyTax?.id
              ? res.data.tvaPropertyTax.id
              : null,
          };

          commit(LeaseMutations.SET_REQUEST_STATE_LEASE, res);
          dispatch("getAffairById", { affairId: res.data.affairId })
            .then((affair) => {
              commit(LeaseMutations.SET_LEASE, {
                ...lease,
                folderId: affair.folderId,
                status: affair.status,
                internalNote: affair.internalNote,
              });
              commit(LeaseMutations.SET_IS_LOADING_LEASE, false);
              resolve({
                ...lease,
                folderId: affair.folderId,
                status: affair.status,
                internalNote: affair.internalNote,
              });
            })
            .catch((err) => {
              console.error(err);
              commit(LeaseMutations.SET_IS_LOADING_LEASE, false);
              resolve(lease);
            });
        })
        .catch((err) => {
          commit(LeaseMutations.SET_REQUEST_STATE_LEASE, err);
          commit(LeaseMutations.SET_IS_LOADING_LEASE, false);
          reject(err);
        });
    });
  },

  async getNewLease({ commit }, { institutionId }) {
    return new Promise((resolve) => {
      commit(LeaseMutations.SET_IS_CREATING_LEASE, true);
      let dataLease = {
        id: 0,
        institutionId: institutionId,
      };
      commit(LeaseMutations.SET_LEASE, dataLease);
      commit(LeaseMutations.SET_IS_CREATING_LEASE, false);
      resolve(dataLease);
    });
  },

  createLease(
    { commit, rootGetters },
    { lease }: { lease: LeaseRequestDto }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(LeaseMutations.SET_IS_CREATING_LEASE, true);
      axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Management/Lease/CreateLease`,
          lease
        )
        .then((res) => {
          let lease = {
            ...res.data.data,
            leaseTypeId: res.data.data.leaseType?.id
              ? res.data.data.leaseType.id
              : null,
            periodicityId: res.data.data.periodicity?.id
              ? res.data.data.periodicity.id
              : null,
            propertyTypeId: res.data.data.propertyType?.id
              ? res.data.data.propertyType.id
              : null,
            rentReviewPeriodicityId: res.data.data.rentReviewPeriodicity?.id
              ? res.data.data.rentReviewPeriodicity.id
              : null,
            securityDepositReviewPeriodicityId: res.data.data
              .securityDepositReviewPeriodicity?.id
              ? res.data.data.securityDepositReviewPeriodicity.id
              : null,
            tvaChargeId: res.data.data.tvaCharge?.id
              ? res.data.data.tvaCharge.id
              : null,
            tvaRentId: res.data.data.tvaRent?.id
              ? res.data.data.tvaRent.id
              : null,
            tvaSecurityDepositId: res.data.tvaSecurityDeposit?.id
              ? res.data.tvaSecurityDeposit.id
              : null,
            tvaPropertyTaxId: res.data.tvaPropertyTax?.id
              ? res.data.tvaPropertyTax.id
              : null,
          };
          rootGetters.leasesList.push(lease);
          commit(LeaseMutations.SET_LEASES_LIST, rootGetters.leasesList);
          commit(LeaseMutations.SET_REQUEST_STATE_LEASE, res);
          commit(LeaseMutations.SET_IS_CREATING_LEASE, false);
          resolve(lease);
        })
        .catch((err) => {
          console.error(err);
          commit(LeaseMutations.SET_REQUEST_STATE_LEASE, err);
          commit(LeaseMutations.SET_IS_CREATING_LEASE, false);
          reject(err);
        });
    });
  },

  duplicateLease(
    { commit, rootGetters },
    { leaseId }: { leaseId: number }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(LeaseMutations.SET_IS_CREATING_LEASE, true);
      axios
        .post(
          process.env.VUE_APP_API_ORISIS + "Management/Lease/DuplicateLease",
          {},
          {
            params: {
              leaseId: leaseId,
            },
          }
        )
        .then((res) => {
          commit(LeaseMutations.SET_LEASE, res.data.data);
          commit(LeaseMutations.SET_IS_CREATING_LEASE, false);
          commit(LeaseMutations.SET_REQUEST_STATE_LEASE, res);
          rootGetters.leasesList.push(res.data.data);
          commit(LeaseMutations.SET_LEASES_LIST, rootGetters.leasesList);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(LeaseMutations.SET_REQUEST_STATE_LEASE, err);
          commit(LeaseMutations.SET_IS_CREATING_LEASE, false);
          reject(err);
        });
    });
  },

  updateLease(
    { commit, rootGetters },
    {
      lease,
      updateState = true,
    }: { lease: LeaseRequestDto; updateState: boolean }
  ) {
    return new Promise<void>((resolve, reject) => {
      if (updateState) commit(LeaseMutations.SET_IS_UPDATING_LEASE, true);
      axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Management/Lease/UpdateLease`,
          lease
        )
        .then((res) => {
          rootGetters.leasesList.splice(
            rootGetters.leasesList.findIndex((elem) => elem.id == lease.id),
            1,
            lease
          );
          commit(LeaseMutations.SET_LEASES_LIST, rootGetters.leasesList);
          commit(LeaseMutations.SET_REQUEST_STATE_LEASE, res);
          commit(LeaseMutations.SET_IS_UPDATING_LEASE, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(LeaseMutations.SET_REQUEST_STATE_LEASE, err);
          commit(LeaseMutations.SET_IS_UPDATING_LEASE, false);
          reject(err);
        });
    });
  },

  deleteLeases(
    { commit, rootGetters },
    { leaseIds = [] }: { leaseIds: number[] }
  ) {
    return new Promise<void>((resolve, reject) => {
      commit(LeaseMutations.SET_IS_DELETING_LEASE, true);
      axios
        .delete(
          `${process.env.VUE_APP_API_ORISIS}Management/Lease/DeleteLease`,
          {
            data: leaseIds,
          }
        )
        .then((res) => {
          leaseIds.forEach((leaseId: number) => {
            rootGetters.leasesList.splice(
              rootGetters.leasesList.findIndex((elem) => elem.id == leaseId),
              1
            );
          });
          commit(LeaseMutations.SET_LEASES_LIST, rootGetters.leasesList);
          commit(LeaseMutations.SET_REQUEST_STATE_LEASE, res);
          commit(LeaseMutations.SET_IS_DELETING_LEASE, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(LeaseMutations.SET_REQUEST_STATE_LEASE, err);
          commit(LeaseMutations.SET_IS_DELETING_LEASE, false);
          reject(err);
        });
    });
  },

  async leaseGroupFunctions(
    { dispatch, commit },
    { lease, includeCharge = false }: { lease: any; includeCharge: boolean }
  ) {
    return new Promise(async (resolve, reject) => {
      // Calculer la période de la quittance
      dispatch("getInvoiceDateRangeLease", {
        lease: lease,
        activeGetObjectsInDateRange: includeCharge,
      })
        .then(async (invoiceDateRangeLeaseRes) => {
          // Calculer le montant de la quittance
          dispatch("getInvoiceRentLease", {
            lease: lease,
            dateRange: invoiceDateRangeLeaseRes,
          })
            .then(async (rentLeaseRes) => {
              // Calculer les détails de la quittance
              await dispatch("getDocumentDetailsOfLease", {
                lease: lease,
                invoiceDateRangeLease: invoiceDateRangeLeaseRes,
                rentLease: rentLeaseRes,
                includeCharge: includeCharge,
              }).then(async (documentDetailsOfLeaseRes) => {
                commit("SET_SUM_LEASE_MODEL", documentDetailsOfLeaseRes);
                resolve(documentDetailsOfLeaseRes);
                return documentDetailsOfLeaseRes;
              });
            })
            .catch((error) => {
              // TODO : Gérer l'erreur
              console.error(error);
              reject(error);
            });
        })
        .catch((error) => {
          // TODO : Gérer l'erreur
          commit("SET_IS_GLOBAL_CREATING", false);
          console.error(error);
          reject(error);
        });
    });
  },

  // TODO: Adapter au besoins de LHOTELLIER
  async getInvoiceDateRangeLease(
    { rootGetters, dispatch },
    { lease, activeGetObjectsInDateRange }
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let incompletePeriod = false;
      let dateRange = {
        startDate: null,
        endDate: null,
      };
      var startDateRange = null;
      const periodicity = rootGetters.periodicitiesList.find(
        (elem) => elem.id == lease.periodicity.id
      );
      // On regarder si la date de début du bail est comprise dans cette periode, si oui alors remplacer la date de début par la date de début du bail
      if (
        dayjs(lease.startDate).format("YYYY-MM-DD") >=
          dayjs().format("YYYY-MM-DD") ||
        (dayjs().startOf(periodicity.unit).format("YYYY-MM-DD") <=
          dayjs(lease.startDate).format("YYYY-MM-DD") &&
          dayjs(lease.startDate).format("YYYY-MM-DD") <=
            dayjs().endOf(periodicity.unit).format("YYYY-MM-DD"))
      ) {
        startDateRange = dayjs(lease.startDate).format("YYYY-MM-DD");
        incompletePeriod = true;
      }

      // On calcule la date de début et de fin de la période en fonction de la périodicité
      switch (periodicity.unit) {
        case "day":
          dateRange = {
            startDate: startDateRange
              ? startDateRange
              : dayjs(
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate() + periodicity.duration - 1
                  )
                ).format("YYYY-MM-DD"),
            endDate: dayjs(
              new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate() + periodicity.duration
              )
            ).format("YYYY-MM-DD"),
          };
          break;
        case "week":
          dateRange = {
            startDate: startDateRange
              ? startDateRange
              : dayjs(
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate() + periodicity.duration * 7 - 7
                  )
                ).format("YYYY-MM-DD"),
            endDate: dayjs(
              new Date(
                new Date(startDateRange).getFullYear(),
                new Date(startDateRange).getMonth(),
                new Date(startDateRange).getDate() +
                  periodicity.duration * 7 -
                  1
              )
            ).format("YYYY-MM-DD"),
          };
          break;
        case "month":
          dateRange = {
            startDate: startDateRange
              ? startDateRange
              : dayjs(
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + periodicity.duration - 1,
                    1
                  )
                ).format("YYYY-MM-DD"),
            endDate: startDateRange
              ? dayjs(
                  new Date(
                    new Date(startDateRange).getFullYear(),
                    new Date(startDateRange).getMonth() + periodicity.duration,
                    0
                  ).toISOString()
                ).format("YYYY-MM-DD")
              : dayjs(
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + periodicity.duration,
                    0
                  ).toISOString()
                ).format("YYYY-MM-DD"),
          };
          break;
        case "quarter":
          let startDateQuarter = startDateRange
            ? startDateRange
            : dayjs(
                new Date(
                  new Date().getFullYear(),
                  new Date().getMonth() + periodicity.duration - 1,
                  1
                )
              ).format("YYYY-MM-DD");
          switch (periodicity.duration) {
            case 1:
              var endDateQuarter = dayjs(startDateQuarter).endOf("quarter");
              break;
            case 2:
              var endDateQuarter = dayjs(startDateQuarter).endOf("quarter");
              if (dayjs(endDateQuarter).quarter() == 1) {
                endDateQuarter = dayjs(endDateQuarter).add(1, "quarter");
              }
              break;
            case 4:
              var endDateQuarter = dayjs(startDateQuarter).endOf("quarter");
              if (dayjs(endDateQuarter).quarter() == 1) {
                endDateQuarter = dayjs(endDateQuarter).add(2, "quarter");
              } else if (dayjs(endDateQuarter).quarter() == 2) {
                endDateQuarter = dayjs(endDateQuarter).add(1, "quarter");
              }
              break;
          }
          dateRange = {
            startDate: startDateRange
              ? startDateRange
              : dayjs(
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + periodicity.duration - 1,
                    1
                  )
                ).format("YYYY-MM-DD"),
            endDate: dayjs(endDateQuarter).format("YYYY-MM-DD"),
          };
          break;
        case "year":
          dateRange = {
            startDate: startDateRange
              ? startDateRange
              : dayjs(
                  new Date(
                    new Date().getFullYear() + periodicity.duration - 1,
                    0,
                    1
                  )
                ).format("YYYY-MM-DD"),
            endDate: dayjs(
              new Date(new Date().getFullYear() + periodicity.duration, 0, 0)
            ).format("YYYY-MM-DD"),
          };
          break;
        default:
          reject("Périodicité non prise en charge");
          throw new Error("Périodicité non prise en charge");
      }

      // On regarder si la date de fin du bail est inférieur, si oui alors remplacer la date de fin par la date de fin du bail
      if (
        dayjs(lease.endDate).format("YYYY-MM-DD") <
        dayjs(dateRange.endDate).format("YYYY-MM-DD")
      ) {
        dateRange.endDate = dayjs(lease.endDate).format("YYYY-MM-DD");
        incompletePeriod = true;
      }
      if (activeGetObjectsInDateRange) {
        // On regarde si une facture de loyer existe déja dans cette période
        dispatch("getObjectsInDateRange", {
          lease,
          periodicity,
          dateRange,
          incompletePeriod,
        })
          .then((getObjectsInDateRangeRes) => {
            resolve(getObjectsInDateRangeRes);
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      } else {
        resolve({
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          incompletePeriod: incompletePeriod,
        });
      }
    });
  },

  async getObjectsInDateRange(
    { dispatch },
    { lease, periodicity, dateRange, incompletePeriod = false }
  ) {
    return new Promise(async (resolve, reject) => {
      await dispatch("getInvoicesByAffairId", {
        affairId: lease.affairId,
        setMutation: true,
      })
        .then((invoices) => {
          const filteredObjects = invoices.filter((obj) => {
            return (
              dateRange.startDate <=
                dayjs(obj.startDate).format("YYYY-MM-DD") &&
              dateRange.endDate >= dayjs(obj.startDate).format("YYYY-MM-DD") &&
              obj.type == 4
            );
          });
          // Si une facture de loyer existe déja dans cette période, on passe à la période suivante de maniere récursive
          if (filteredObjects.length == 0) {
            resolve({
              startDate: dateRange.startDate,
              endDate: dateRange.endDate,
              incompletePeriod: incompletePeriod,
            });
          } else {
            let endDate = null;
            if (
              dayjs(lease.endDate).format("YYYY-MM-DD") <
              dayjs(dateRange.endDate)
                .add(1, "day")
                .add(periodicity.duration, periodicity.unit)
                .add(-1, "day")
                .format("YYYY-MM-DD")
            ) {
              endDate = dayjs(lease.endDate).format("YYYY-MM-DD");
              incompletePeriod = true;
            } else {
              endDate = dayjs(dateRange.endDate)
                .add(1, "day")
                .add(periodicity.duration, periodicity.unit)
                .add(-1, "day")
                .format("YYYY-MM-DD");
            }
            dateRange = {
              startDate: dayjs(dateRange.endDate)
                .add(1, "day")
                .format("YYYY-MM-DD"),
              endDate: endDate,
            };
            dispatch("getObjectsInDateRange", {
              lease,
              periodicity,
              dateRange,
            }).then((getObjectsInDateRangeRes) => {
              resolve(getObjectsInDateRangeRes);
            });
          }
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },

  async getInvoiceRentLease(
    { rootGetters },
    { lease, dateRange }
  ): Promise<any> {
    return new Promise(async (resolve) => {
      const periodicity = rootGetters.periodicitiesList.find(
        (elem) => elem.id == lease.periodicity.id
      );
      let periodDivider: number = 12;
      let daysInPeriodicity: number = 30;
      switch (periodicity.unit) {
        case "day":
          periodDivider = 365;
          daysInPeriodicity = 1;
          break;
        case "week":
          periodDivider = 52;
          daysInPeriodicity = 7;
          break;
        case "month":
          periodDivider = 12;
          daysInPeriodicity = 30;
          break;
        case "quarter":
          periodDivider = 4;
          daysInPeriodicity = 90;
          break;
        case "year":
          periodDivider = 1;
          daysInPeriodicity = 365;
          break;
        default:
          periodDivider = 12;
          daysInPeriodicity = 30;
          break;
      }
      const daysInDateRange = dateRange.incompletePeriod
        ? Math.abs(
            dayjs(dateRange.endDate).diff(dayjs(dateRange.startDate), "day")
          ) + 1
        : daysInPeriodicity;
      const rentHcHt =
        Math.round(
          (lease.rentHcHt / periodDivider / daysInPeriodicity) *
            daysInDateRange *
            100
        ) / 100;
      const chargeHt =
        Math.round(
          (lease.chargeHt / periodDivider / daysInPeriodicity) *
            daysInDateRange *
            100
        ) / 100;
      const propertyTaxProvisioned =
        Math.round(
          (lease.propertyTaxProvisioned / periodDivider / daysInPeriodicity) *
            daysInDateRange *
            100
        ) / 100;
      resolve({
        rentHcHt: rentHcHt,
        chargeHt: chargeHt,
        propertyTaxProvisioned: propertyTaxProvisioned,
      });
    });
  },

  async getDocumentDetailsOfLease(
    { dispatch },
    { lease, invoiceDateRangeLease, rentLease, includeCharge }
  ): Promise<any> {
    return new Promise(async (resolve) => {
      let invoicesProvider = [];
      let creditsProvider = [];
      let documentDetails = [];
      if (includeCharge) {
        // Appel des factures fournisseur liées au bail
        await dispatch("getInvoicesProviders", {
          affairId: lease.affairId,
          updateState: false,
          dateFrom: invoiceDateRangeLease.startDate,
          dateTo: invoiceDateRangeLease.endDate,
        }).then((res) => {
          invoicesProvider = res;
        });

        // Appel des avoirs fournisseur liés au bail
        await dispatch("getCreditsProvidersByAffairId", {
          affairId: lease.affairId,
          startValueDate: invoiceDateRangeLease.startDate,
          endValueDate: invoiceDateRangeLease.endDate,
        }).then((res) => {
          creditsProvider = res;
        });
      }

      // Loyer
      if (lease.rentHcHt > 0) {
        let rent = {
          id: 0,
          type: 9, // Ligne divers
          description:
            "Loyer du <b>" +
            dayjs(invoiceDateRangeLease.startDate).format("DD/MM/YYYY") +
            "</b> au <b>" +
            dayjs(invoiceDateRangeLease.endDate).format("DD/MM/YYYY") +
            "</b>",
          quantity: 1,
          unitId: 0,
          unitPriceHt: rentLease.rentHcHt,
          referencielTvaId: lease.tvaRentId ? lease.tvaRentId : 5,
          total: rentLease.rentHcHt,
        };
        documentDetails.push(rent);
      }
      // Forfait de charges
      if (lease.chargeHt > 0) {
        let charge = {
          id: 0,
          type: 7, // Ligne de sous traitance
          description: lease.isChargesPackage
            ? "Forfait de charges"
            : "Provision pour charges",
          quantity: 1,
          unitId: 0,
          unitPriceHt: rentLease.chargeHt,
          referencielTvaId: lease.tvaChargeId ? lease.tvaChargeId : 5,
          total: rentLease.chargeHt,
        };
        documentDetails.push(charge);
      }

      if (lease.propertyTaxProvisioned > 0) {
        let propertyTaxProvisioned = {
          id: 0,
          type: 7, // Ligne de sous traitance
          description: "Taxe foncière",
          quantity: 1,
          unitId: 0,
          unitPriceHt: rentLease.propertyTaxProvisioned,
          referencielTvaId: lease.tvaPropertyTaxId ? lease.tvaPropertyTaxId : 5,
          total: rentLease.propertyTaxProvisioned,
        };
        documentDetails.push(propertyTaxProvisioned);
      }
      if (
        lease.securityDeposit > 0 &&
        dayjs(lease.startDate).format("YYYY-MM-DD") ==
          dayjs(invoiceDateRangeLease.startDate).format("YYYY-MM-DD")
      ) {
        let securityDeposit = {
          id: 0,
          type: 9, // Ligne divers
          description: "Dépôt de garantie",
          quantity: 1,
          unitId: 0,
          unitPriceHt: lease.securityDeposit,
          referencielTvaId: lease.tvaSecurityDepositId
            ? lease.tvaSecurityDepositId
            : 5,
          total: lease.securityDeposit,
        };
        documentDetails.push(securityDeposit);
      }

      // On ajoute les factures fournisseur liées au bail
      if (invoicesProvider.length > 0) {
        // construct array of invoicesProvider.invoiceProviderRepartitions who is array where invoicesProvider.invoiceProviderRepartitions.affairId == lease.affairId with map
        let invoicesProviderCharge = invoicesProvider
          .map((item) => {
            return item.invoiceProviderRepartitions
              .filter((el) => el.affairId == lease.affairId)
              .map((el) => {
                return {
                  ...el,
                  documentReference: item.documentReference,
                  documentDate: item.documentDate,
                  provider: item.provider,
                  description: item.description,
                };
              });
          })
          .flat();
        for (let i = 0; i < invoicesProviderCharge.length; i++) {
          const invoiceProviderCharge = invoicesProviderCharge[i];
          let invoiceProviderChargeDocumentDetails = {
            id: 0,
            type: 7, // Ligne de sous traitance
            description:
              "<p><em>Refacturation</em></p><p><em>Facture fournisseur " +
              invoiceProviderCharge.documentReference +
              " du " +
              dayjs(invoiceProviderCharge.documentDate).format("DD/MM/YYYY") +
              " de " +
              invoiceProviderCharge.provider.name +
              "</em></p><p><em>" +
              (invoiceProviderCharge.description
                ? invoiceProviderCharge.description
                : "") +
              "</em></p>",
            quantity: 1,
            unitId: 18,
            unitPriceHt: invoiceProviderCharge.priceHt,
            referencielTvaId: invoiceProviderCharge.referencielTvaId,
            total: invoiceProviderCharge.priceHt,
          };
          documentDetails.push(invoiceProviderChargeDocumentDetails);
        }
      }

      // On ajoute les avoirs fournisseur liés au bail
      if (creditsProvider.length > 0) {
        let creditsProviderCharge = creditsProvider
          .map((item) => {
            return item.creditProviderRepartitions
              .filter((el) => el.affairId == lease.affairId)
              .map((el) => {
                return {
                  ...el,
                  documentReference: item.documentReference,
                  documentDate: item.documentDate,
                  provider: item.provider,
                  description: item.description,
                };
              });
          })
          .flat();
        for (let i = 0; i < creditsProviderCharge.length; i++) {
          const creditProviderCharge = creditsProviderCharge[i];
          let creditProviderChargeDocumentDetails = {
            id: 0,
            type: 7, // Ligne de sous traitance
            description:
              "<p><em>Refacturation</em></p><p><em>Avoir  fournisseur " +
              creditProviderCharge.documentReference +
              " du " +
              dayjs(creditProviderCharge.documentDate).format("DD/MM/YYYY") +
              " de " +
              creditProviderCharge.provider.name +
              "</em></p><p><em>" +
              (creditProviderCharge.description
                ? creditProviderCharge.description
                : "") +
              "</em></p>",
            quantity: 1,
            unitId: 18,
            unitPriceHt: creditProviderCharge.priceHt,
            referencielTvaId: creditProviderCharge.referencielTvaId,
            total: creditProviderCharge.priceHt,
          };
          documentDetails.push(creditProviderChargeDocumentDetails);
        }
      }

      // On calcule les totaux
      let totalDocument = {
        subTotalHt: 0,
        totalNetHt: 0,
        tvaDetails: [],
        totalTva: 0,
        totalTtc: 0,
      };
      totalDocument.tvaDetails = documentDetails
        .filter(
          (el) =>
            el.type != 0 &&
            el.type != 1 &&
            el.type != 2 &&
            el.type != 11 &&
            el.type != 12 &&
            el.type != 13 &&
            el.type != 18 &&
            el.type != 17 &&
            el.type != 16 &&
            el.type != 15 &&
            el.type != 14 &&
            !el.isOption
        )
        .reduce((acc, obj) => {
          // On crée un object par taux de TVA
          let findIndex = acc.findIndex(
            (el) => el.referencielTvaId === obj.referencielTvaId
          );
          let priceNetHt = obj.unitPriceHt * obj.quantity;
          let priceTva =
            (priceNetHt * getReferencielTvaValueById(obj.referencielTvaId)) /
            100;
          let priceTtc = priceTva + priceNetHt;
          if (findIndex < 0) {
            acc.push({
              priceHt: priceNetHt,
              priceTva: priceTva,
              priceTtc: priceTtc,
              referencielTvaId: obj.referencielTvaId,
            });
          } else {
            acc[findIndex].priceHt += priceNetHt;
            acc[findIndex].priceTva += priceTva;
            acc[findIndex].priceTtc += priceTtc;
          }
          totalDocument.subTotalHt += Math.round(priceNetHt * 100) / 100;
          totalDocument.totalNetHt += Math.round(priceNetHt * 100) / 100;
          totalDocument.totalTva += Math.round(priceTva * 100) / 100;
          totalDocument.totalTtc += Math.round(priceTtc * 100) / 100;
          return acc;
        }, []);
      if (totalDocument.tvaDetails && totalDocument.tvaDetails.length > 0) {
        totalDocument.tvaDetails.sort(function (a, b) {
          return (
            getReferencielTvaValueById(a.referencielTvaId) -
            getReferencielTvaValueById(b.referencielTvaId)
          );
        });
      }
      resolve({
        documentDetails: documentDetails,
        totalDocument: totalDocument,
        invoiceDateRangeLease: invoiceDateRangeLease,
      });
    });
  },

  // Vérifier si on garde ces deux fonctions d'archivage car utilisées dans la codebase

  async getLeasesArchived(
    { commit, rootGetters },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(LeaseMutations.SET_IS_LOADING_LEASE_LIST_ARCHIVED, true);
      let url = `${process.env.VUE_APP_API_ORISIS}Management/Lease/GetLeasesArchived`;
      await axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
        })
        .then((res) => {
          commit(LeaseMutations.SET_IS_LOADING_LEASE_LIST_ARCHIVED, false);
          if (updateState) {
            commit(LeaseMutations.SET_LEASES_LIST_ARCHIVED, res.data);
          }
          commit(LeaseMutations.SET_REQUEST_STATE_LEASE, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(LeaseMutations.SET_IS_LOADING_LEASE_LIST_ARCHIVED, false);
          commit(LeaseMutations.SET_REQUEST_STATE_LEASE, err);
          reject(err);
        });
    });
  },

  async getLeaseArchived(
    { commit, rootGetters, dispatch },
    { leaseId, updateState = true }: { leaseId: string; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(LeaseMutations.SET_IS_LOADING_LEASE, true);
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Management/Lease/GetLeaseArchivedById`,
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`,
            },
            params: {
              leaseId: leaseId,
            },
          }
        )
        .then(async (res) => {
          if (updateState) {
            commit(LeaseMutations.SET_LEASE, res.data);
          }
          commit(LeaseMutations.SET_REQUEST_STATE_LEASE, res);
          await dispatch("getAffairArchivedById", {
            affairId: res.data.affairId,
          });
          commit(LeaseMutations.SET_IS_LOADING_LEASE, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(LeaseMutations.SET_REQUEST_STATE_LEASE, err);
          commit(LeaseMutations.SET_IS_LOADING_LEASE, false);
          reject(err);
        });
    });
  },

  // Vérifier si on garde les fonctions pour restore et archive des leases car utilisées dans la codebase

  async archiveLease({ commit, rootGetters }, leaseId: string): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(LeaseMutations.SET_IS_ARCHIVING_LEASE, true);
      await axios
        .delete(process.env.VUE_APP_API_ORISIS + "Management/Lease/Archive", {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
          data: [leaseId],
        })
        .then((res) => {
          rootGetters.leasesListArchived.unshift(
            rootGetters.leasesList.find((elem) => elem.id == leaseId)
          );
          rootGetters.leasesList.splice(
            rootGetters.leasesList.findIndex((elem) => elem.id == leaseId),
            1
          );
          commit(LeaseMutations.SET_LEASES_LIST, rootGetters.leasesList);
          commit(LeaseMutations.SET_REQUEST_STATE_LEASE, res);
          commit(LeaseMutations.SET_IS_ARCHIVING_LEASE, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(LeaseMutations.SET_REQUEST_STATE_LEASE, err);
          commit(LeaseMutations.SET_IS_ARCHIVING_LEASE, false);
          reject(err);
        });
    });
  },

  async restoreLease({ commit, rootGetters }, leaseId: string): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(LeaseMutations.SET_IS_UPDATING_LEASE, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Management/Lease/Restore",
          [leaseId],
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`,
            },
          }
        )
        .then((res) => {
          rootGetters.leasesList.unshift(
            rootGetters.leasesListArchived.find((elem) => elem.id == leaseId)
          );
          rootGetters.leasesListArchived.splice(
            rootGetters.leasesListArchived.findIndex(
              (elem) => elem.id == leaseId
            ),
            1
          );
          commit(LeaseMutations.SET_REQUEST_STATE_LEASE, res);
          commit(LeaseMutations.SET_IS_UPDATING_LEASE, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(LeaseMutations.SET_REQUEST_STATE_LEASE, err);
          commit(LeaseMutations.SET_IS_UPDATING_LEASE, false);
          reject(err);
        });
    });
  },
};
