import { MutationTree } from "vuex";
import { IInvoiceOptionDefaultState } from "./states";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { InvoiceOptionRequestDto } from "Api";

export const enum InvoiceOptionDefaultMutations {
  SET_INVOICE_OPTION_DEFAULT = "SET_INVOICE_OPTION_DEFAULT",
  SET_INVOICE_OPTION_DEFAULT_FORM = "SET_INVOICE_OPTION_DEFAULT_FORM",
  SET_IS_LOADING_INVOICE_OPTION_DEFAULT = "SET_IS_LOADING_INVOICE_OPTION_DEFAULT",
  SET_REQUEST_STATE_INVOICE_OPTION_DEFAULT = "SET_REQUEST_STATE_INVOICE_OPTION_DEFAULT",
}

export const mutations: MutationTree<IInvoiceOptionDefaultState> = {
  [InvoiceOptionDefaultMutations.SET_INVOICE_OPTION_DEFAULT](
    state,
    payload: InvoiceOptionRequestDto
  ) {
    state.invoiceOptionDefault = payload;
  },
  [InvoiceOptionDefaultMutations.SET_INVOICE_OPTION_DEFAULT_FORM](
    state,
    payload: InvoiceOptionRequestDto
  ) {
    state.invoiceOptionDefaultForm = payload;
  },
  [InvoiceOptionDefaultMutations.SET_IS_LOADING_INVOICE_OPTION_DEFAULT](
    state,
    payload: boolean
  ) {
    state.isLoadingInvoiceOptionDefault = payload;
  },
  [InvoiceOptionDefaultMutations.SET_REQUEST_STATE_INVOICE_OPTION_DEFAULT](
    state,
    payload: IRequestState
  ) {
    state.requestStateInvoiceOptionDefault = payload;
  },
};
