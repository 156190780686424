import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { CustomFieldRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { ICustomFieldState } from "./states";

export enum CustomFieldMutations {
    SET_CUSTOM_FIELDS_LIST = "SET_CUSTOM_FIELDS_LIST",
    SET_CUSTOM_FIELDS_LIST_ARCHIVED = "SET_CUSTOM_FIELDS_LIST_ARCHIVED",
    SET_CUSTOM_FIELD = "SET_CUSTOM_FIELD",

    //FILTERS
    SET_CUSTOM_FIELD_FILTERS = "SET_CUSTOM_FIELD_FILTERS",

    // COMMON
    SET_IS_LOADING_CUSTOM_FIELD_LIST = "SET_IS_LOADING_CUSTOM_FIELD_LIST",
    SET_IS_LOADING_CUSTOM_FIELD_LIST_ARCHIVED = "SET_IS_LOADING_CUSTOM_FIELD_LIST_ARCHIVED",
    SET_IS_LOADING_CUSTOM_FIELD = "SET_IS_LOADING_CUSTOM_FIELD",
    SET_IS_CREATING_CUSTOM_FIELD = "SET_IS_CREATING_CUSTOM_FIELD",
    SET_IS_UPDATING_CUSTOM_FIELD = "SET_IS_UPDATING_CUSTOM_FIELD",
    SET_IS_DELETING_CUSTOM_FIELD = "SET_IS_DELETING_CUSTOM_FIELD",
    SET_IS_ARCHIVING_CUSTOM_FIELD = "SET_IS_ARCHIVING_CUSTOM_FIELD",
    SET_REQUEST_STATE_CUSTOM_FIELD = "SET_REQUEST_STATE_CUSTOM_FIELD",
}

export const mutations: MutationTree<ICustomFieldState> = {
    [CustomFieldMutations.SET_CUSTOM_FIELDS_LIST]: (state, payload: CustomFieldRequestDto[]) => {
        state.customFieldsList = payload;
    },
    [CustomFieldMutations.SET_CUSTOM_FIELDS_LIST_ARCHIVED]: (state, payload: CustomFieldRequestDto[]) => {
        state.customFieldsListArchived = payload;
    }, 
    [CustomFieldMutations.SET_CUSTOM_FIELD]: (state, payload: CustomFieldRequestDto) => {
        state.customField = payload;
    },

    //FILTERS
    [CustomFieldMutations.SET_CUSTOM_FIELD_FILTERS]: (state, payload: any) => {
        state.customFieldFilters = payload;
    },


    //COMMON
    [CustomFieldMutations.SET_IS_LOADING_CUSTOM_FIELD_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingCustomFieldsListArchived = payload;
    },
    [CustomFieldMutations.SET_IS_LOADING_CUSTOM_FIELD_LIST]: (state, payload: boolean) => {
        state.isLoadingCustomFieldsList = payload;
    },
    [CustomFieldMutations.SET_IS_LOADING_CUSTOM_FIELD]: (state, payload: boolean) => {
        state.isLoadingCustomField = payload;
    },
    [CustomFieldMutations.SET_IS_CREATING_CUSTOM_FIELD]: (state, payload: boolean) => {
        state.isCreatingCustomField = payload;
    },
    [CustomFieldMutations.SET_IS_UPDATING_CUSTOM_FIELD]: (state, payload: boolean) => {
        state.isUpdatingCustomField = payload;
    },
    [CustomFieldMutations.SET_IS_DELETING_CUSTOM_FIELD]: (state, payload: boolean) => {
        state.isDeletingCustomField = payload;
    },
    [CustomFieldMutations.SET_IS_ARCHIVING_CUSTOM_FIELD]: (state, payload: boolean) => {
        state.isArchivingCustomField = payload;
    },
    [CustomFieldMutations.SET_REQUEST_STATE_CUSTOM_FIELD]: (state, payload: IRequestState) => {
        state.requestStateCustomField = payload;
    },
}