import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { ReceiptFormRequestDto } from "@/../Api";
import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IReceiptFormProviderState } from "./states";
import { IPaymentMethod } from "@/types/api-orisis/interfaces/invoices/paymentMethod/IPaymentMethod";
import { IFilters } from "@/types/api-orisis/interfaces/invoices/filters/IFilters";

export const getters: GetterTree<IReceiptFormProviderState, RootState> = {
  receiptFormProvidersList: (state): ReceiptFormRequestDto[] => state.receiptFormProvidersList,
  filteredReceiptFormProvidersList: (state): ReceiptFormRequestDto[] =>
    state.receiptFormProvidersList,
  receiptFormProvidersListArchived: (state): ReceiptFormRequestDto[] =>
    state.receiptFormProvidersListArchived,
  filteredReceiptFormProvidersListArchived: (state): ReceiptFormRequestDto[] =>
    state.receiptFormProvidersListArchived,
  receiptFormProvider: (state): ReceiptFormRequestDto => state.receiptFormProvider,
  receiptFormProviderPaymentMethods: (state): IPaymentMethod[] =>
    state.receiptFormProviderPaymentMethods,
  receiptFormProviderFilters: (state): IFilters => state.receiptFormProviderFilters,
  receiptFormProviderPDF: (state): string => state.receiptFormProviderPDF,
  receiptFormProviderArchived: (state): ReceiptFormRequestDto =>
    state.receiptFormProviderArchived,
  initialReceiptFormProvider: (state): ReceiptFormRequestDto => state.initialReceiptFormProvider,
  receiptFormProvidersListByAffair: (state): ReceiptFormRequestDto[] =>
    state.receiptFormProvidersListByAffair,
  receiptFormProvidersListByCompany: (state): ReceiptFormRequestDto[] =>
  state.receiptFormProvidersListByCompany,  


  // COMMON
  isValidatingReceiptFormProvider           : (state): boolean => state.isValidatingReceiptFormProvider,
  isLoadingReceiptFormProvidersList: (state): boolean => state.isLoadingReceiptFormProvidersList,
  isLoadingPaymentMethodsReceiptFormProvider: (state): boolean =>
    state.isLoadingPaymentMethodsReceiptFormProvider,
  isLoadingReceiptFormProvidersListArchived: (state): boolean =>
    state.isLoadingReceiptFormProvidersListArchived,
  isLoadingReceiptFormProvider: (state): boolean => state.isLoadingReceiptFormProvider,
  isLoadingReceiptFormProviderPDF: (state): boolean => state.isLoadingReceiptFormProviderPDF,
  isLoadingDuplicatingReceiptFormProvider: (state): boolean =>
    state.isLoadingDuplicatingReceiptFormProvider,
  isLoadingReceiptFormProviderArchived: (state): boolean =>
    state.isLoadingReceiptFormProviderArchived,
  isCreatingReceiptFormProvider: (state): boolean => state.isCreatingReceiptFormProvider,
  isUpdatingReceiptFormProvider: (state): boolean => state.isUpdatingReceiptFormProvider,
  isRestoringReceiptFormProvider: (state): boolean => state.isRestoringReceiptFormProvider,
  isDeletingReceiptFormProvider: (state): boolean => state.isDeletingReceiptFormProvider,
  isArchivingReceiptFormProvider: (state): boolean => state.isArchivingReceiptFormProvider,
  requestStateReceiptFormProvider: (state): IRequestState => state.requestStateReceiptFormProvider,
  requestStateReceiptFormProviderPDF: (state): IRequestState =>
    state.requestStateReceiptFormProviderPDF,
  receiptFormProviderTabActive: (state): String => state.receiptFormProviderTabActive,
  isLoadingReceiptFormProvidersListByAffair: (state): boolean =>
    state.isLoadingReceiptFormProvidersListByAffair,
  isLoadingReceiptFormProvidersListByCompany: (state): boolean =>
  state.isLoadingReceiptFormProvidersListByCompany,
};
