import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { GetterTree } from "vuex";
import RootState from '@/store/modules/rootState'
import {  IWasteCollectionPointTypeState } from "./states";

export const getters: GetterTree<IWasteCollectionPointTypeState, RootState> = {
    wasteCollectionPointTypesList: (state): any[] => state.wasteCollectionPointTypesList,
    wasteCollectionPointTypesListArchived: (state): any[] => state.wasteCollectionPointTypesListArchived,
    wasteCollectionPointType     : (state): any => state.wasteCollectionPointType,
    
    // COMMON
    isLoadingWasteCollectionPointTypesList   : (state): boolean => state.isLoadingWasteCollectionPointTypesList,
    isLoadingWasteCollectionPointTypesListArchived : (state): boolean => state.isLoadingWasteCollectionPointTypesListArchived,
    isLoadingWasteCollectionPointType : (state): boolean => state.isLoadingWasteCollectionPointType,
    isCreatingWasteCollectionPointType  : (state): boolean => state.isCreatingWasteCollectionPointType,
    isUpdatingWasteCollectionPointType : (state): boolean => state.isUpdatingWasteCollectionPointType,
    isDeletingWasteCollectionPointType : (state): boolean => state.isDeletingWasteCollectionPointType,
    requestStateWasteCollectionPointType: (state): IRequestState => state.requestStateWasteCollectionPointType,
    isArchivingWasteCollectionPointType : (state): boolean => state.isArchivingWasteCollectionPointType,
}