import { ITagState } from "./states"
import { Module } from "vuex"
import { ITag } from "@/types/api-orisis/interfaces/Settings/tag/ITag";
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state: ITagState = {
    tagsList                               : Array<ITag>(),
    quoteTagsList                          : Array<ITag>(),
    orderFormCustomerTagsList              : Array<ITag>(),
    companyTagsList                        : Array<ITag>(),
    invoiceTagsList                        : Array<ITag>(),
    creditTagsList                         : Array<ITag>(),
    orderFormProviderTagsList              : Array<ITag>(),
    deliveryFormTagsList                   : Array<ITag>(),
    receiptFormProviderTagsList            : Array<ITag>(),
    invoiceProviderTagsList                : Array<ITag>(),
    creditProviderTagsList                 : Array<ITag>(),
    affairTagsList                         : Array<ITag>(),
    collaboratorTagsList                   : Array<ITag>(),
    contactTagsList                        : Array<ITag>(),
   
    quoteTagsListSelected                  : Array<ITag>(),
    orderFormCustomerTagsListSelected      : Array<ITag>(),
    companyTagsListSelected                : Array<ITag>(),
    invoiceTagsListSelected                : Array<ITag>(),
    creditTagsListSelected                 : Array<ITag>(),
    orderFormProviderTagsListSelected      : Array<ITag>(),
    deliveryFormTagsListSelected           : Array<ITag>(),
    receiptFormProviderTagsListSelected    : Array<ITag>(),
    invoiceProviderTagsListSelected        : Array<ITag>(),
    creditProviderTagsListSelected         : Array<ITag>(),
    affairTagsListSelected                 : Array<ITag>(),
    collaboratorTagsListSelected           : Array<ITag>(),
    contactTagsListSelected                : Array<ITag>(),
   
    tagsListArchived                       : Array<ITag>(),
    tag                                    : <ITag>{},
    isLoadingTagsList                      : false,
    isLoadingTagsListArchived              : false,
    isLoadingTag                           : false,
    isCreatingTag                          : false,
    isUpdatingTag                          : false,
    isDeletingTag                          : false,
    isArchivingTag                         : false,
    requestStateTag                        : <IRequestState>{},
}

export const TagModule: Module<ITagState, RootState> = {
    state, 
    getters,
    mutations,
    actions
} 