import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { IPaymentProvider } from "@/types/api-orisis/interfaces/Purchase/paymentProvider/IPaymentProvider";
import { GetterTree } from "vuex";
import RootState from '@/store/modules/rootState'
import { IPaymentProviderState } from "./states";

export const getters: GetterTree<IPaymentProviderState, RootState> = {
    paymentProvidersList                    : (state): IPaymentProvider[] => state.paymentProvidersList,
    paymentProvidersListArchived            : (state): IPaymentProvider[] => state.paymentProvidersListArchived,
    paymentProvider                         : (state): IPaymentProvider => state.paymentProvider,
    
    //FILTERS
    paymentProviderFilters                  : (state): any => state.paymentProviderFilters,

    // COMMON
    isLoadingPaymentProvidersList           : (state): boolean => state.isLoadingPaymentProvidersList,
    isLoadingPaymentProvidersListArchived   : (state): boolean => state.isLoadingPaymentProvidersListArchived,
    isLoadingPaymentProvider                : (state): boolean => state.isLoadingPaymentProvider,
    isCreatingPaymentProvider               : (state): boolean => state.isCreatingPaymentProvider,
    isUpdatingPaymentProvider               : (state): boolean => state.isUpdatingPaymentProvider,
    isDeletingPaymentProvider               : (state): boolean => state.isDeletingPaymentProvider,
    isArchivingPaymentProvider              : (state): boolean => state.isArchivingPaymentProvider,
    isLinkingPaymentProvider                : (state): boolean => state.isLinkingPaymentProvider,
    isUnlinkingPaymentProvider              : (state): boolean => state.isUnlinkingPaymentProvider,
    requestStatePaymentProvider             : (state): IRequestState => state.requestStatePaymentProvider,
}