import { ICollaboratorState } from "./states";
import { MutationTree } from "vuex";
import { CollaboratorRequestDto } from "@/../Api";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export enum CollaboratorMutations {
  SET_COLLABORATORS_LIST = "SET_COLLABORATORS_LIST",
  SET_COLLABORATORS_LIST_ARCHIVED = "SET_COLLABORATORS_LIST_ARCHIVED",
  SET_COLLABORATOR = "SET_COLLABORATOR",
  SET_USER_COLLABORATOR = "SET_USER_COLLABORATOR",

  // COMON
  SET_IS_LOADING_COLLABORATORS_LIST = "SET_IS_LOADING_COLLABORATORS_LIST",
  SET_IS_LOADING_COLLABORATORS_LIST_ARCHIVED = "SET_IS_LOADING_COLLABORATORS_LIST_ARCHIVED",
  SET_IS_LOADING_USER_COLLABORATOR_ID = "SET_IS_LOADING_USER_COLLABORATOR_ID",
  SET_IS_LOADING_COLLABORATOR = "SET_IS_LOADING_COLLABORATOR",
  SET_IS_CREATING_COLLABORATOR = "SET_IS_CREATING_COLLABORATOR",
  SET_IS_UPDATING_COLLABORATOR = "SET_IS_UPDATING_COLLABORATOR",
  SET_IS_DELETING_COLLABORATOR = "SET_IS_DELETING_COLLABORATOR",
  SET_IS_ARCHIVING_COLLABORATOR = "SET_IS_ARCHIVING_COLLABORATOR",
  SET_REQUEST_STATE_COLLABORATOR = "SET_REQUEST_STATE_COLLABORATOR",
}

export const mutations: MutationTree<ICollaboratorState> = {
  [CollaboratorMutations.SET_COLLABORATORS_LIST_ARCHIVED]: (
    state,
    payload: CollaboratorRequestDto[]
  ) => {
    state.collaboratorsListArchived = payload.map((el) => {
      return { ...el, displayLabel: el.firstName + " " + el.lastName };
    });
  },
  [CollaboratorMutations.SET_COLLABORATORS_LIST]: (
    state,
    payload: CollaboratorRequestDto[]
  ) => {
    state.collaboratorsList = payload.map((el) => {
      return { ...el, displayLabel: el.firstName + " " + el.lastName };
    });
  },
  [CollaboratorMutations.SET_COLLABORATOR]: (
    state,
    payload: CollaboratorRequestDto
  ) => {
    state.collaborator = {
      ...payload,
      displayLabel: payload.firstName + " " + payload.lastName,
    } as CollaboratorRequestDto;
  },
  [CollaboratorMutations.SET_USER_COLLABORATOR]: (state, payload: any) => {
    state.userCollaboratorId = payload ? payload.id : null;
    state.userCollaborator = payload ? payload : null;
    localStorage.setItem("userCollaborator", JSON.stringify(payload));
    localStorage.setItem("userCollaboratorId", payload ? payload.id : null);
  },

  //COMMON
  [CollaboratorMutations.SET_IS_LOADING_COLLABORATORS_LIST_ARCHIVED]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingCollaboratorsListArchived = payload;
  },
  [CollaboratorMutations.SET_IS_LOADING_USER_COLLABORATOR_ID]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingUserCollaboratorId = payload;
  },
  [CollaboratorMutations.SET_IS_LOADING_COLLABORATORS_LIST]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingCollaboratorsList = payload;
  },
  [CollaboratorMutations.SET_IS_LOADING_COLLABORATOR]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingCollaborator = payload;
  },
  [CollaboratorMutations.SET_IS_CREATING_COLLABORATOR]: (
    state,
    payload: boolean
  ) => {
    state.isCreatingCollaborator = payload;
  },
  [CollaboratorMutations.SET_IS_UPDATING_COLLABORATOR]: (
    state,
    payload: boolean
  ) => {
    state.isUpdatingCollaborator = payload;
  },
  [CollaboratorMutations.SET_IS_DELETING_COLLABORATOR]: (
    state,
    payload: boolean
  ) => {
    state.isDeletingCollaborator = payload;
  },
  [CollaboratorMutations.SET_IS_ARCHIVING_COLLABORATOR]: (
    state,
    payload: boolean
  ) => {
    state.isArchivingCollaborator = payload;
  },

  [CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR]: (
    state,
    payload: IRequestState
  ) => {
    state.requestStateCollaborator = payload;
  },
};
