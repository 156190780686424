import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { ReceiptFormOptionDefaultMutations } from "./mutations";
import { IReceiptFormOptionDefaultState } from "./states";
import { ReceiptFormOptionRequestDto } from "Api";

export const actions: ActionTree<IReceiptFormOptionDefaultState, RootState> = {
  getReceiptFormOptionDefaultByReceiptFormId(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          ReceiptFormOptionDefaultMutations.SET_IS_LOADING_RECEIPT_FORM_OPTION_DEFAULT,
          true
        );
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/ReceiptFormOptionDefault/GetDefault`;

      axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              ReceiptFormOptionDefaultMutations.SET_RECEIPT_FORM_OPTION_DEFAULT,
              res.data
            );
            commit(
              ReceiptFormOptionDefaultMutations.SET_RECEIPT_FORM_OPTION_DEFAULT_FORM,
              res.data
            );
            commit(
              ReceiptFormOptionDefaultMutations.SET_IS_LOADING_RECEIPT_FORM_OPTION_DEFAULT,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              ReceiptFormOptionDefaultMutations.SET_IS_LOADING_RECEIPT_FORM_OPTION_DEFAULT,
              false
            );
          }
          reject(err);
        });
    });
  },

  createReceiptFormOptionDefault(
    { commit },
    {
      receiptFormOptionDefault,
      updateState = true,
    }: {
      receiptFormOptionDefault: ReceiptFormOptionRequestDto;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          ReceiptFormOptionDefaultMutations.SET_IS_LOADING_RECEIPT_FORM_OPTION_DEFAULT,
          true
        );
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/ReceiptFormOptionDefault/CreateReceiptFormOptionDefault`;

      axios
        .post(url, receiptFormOptionDefault)
        .then((res) => {
          if (updateState) {
            commit(
              ReceiptFormOptionDefaultMutations.SET_RECEIPT_FORM_OPTION_DEFAULT,
              res.data
            );
            commit(
              ReceiptFormOptionDefaultMutations.SET_IS_LOADING_RECEIPT_FORM_OPTION_DEFAULT,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              ReceiptFormOptionDefaultMutations.SET_IS_LOADING_RECEIPT_FORM_OPTION_DEFAULT,
              false
            );
          }
          reject(err);
        });
    });
  },

  updateReceiptFormOptionDefault(
    { commit },
    {
      receiptFormOptionDefault,
      updateState = true,
    }: {
      receiptFormOptionDefault: ReceiptFormOptionRequestDto;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          ReceiptFormOptionDefaultMutations.SET_IS_LOADING_RECEIPT_FORM_OPTION_DEFAULT,
          true
        );
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/ReceiptFormOptionDefault/UpdateReceiptFormOptionDefault`;

      axios
        .put(url, receiptFormOptionDefault)
        .then((res) => {
          if (updateState) {
            commit(
              ReceiptFormOptionDefaultMutations.SET_RECEIPT_FORM_OPTION_DEFAULT,
              res.data
            );
            commit(
              ReceiptFormOptionDefaultMutations.SET_IS_LOADING_RECEIPT_FORM_OPTION_DEFAULT,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              ReceiptFormOptionDefaultMutations.SET_IS_LOADING_RECEIPT_FORM_OPTION_DEFAULT,
              false
            );
          }
          reject(err);
        });
    });
  },
};
