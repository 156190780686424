import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { InvoiceOptionDefaultMutations } from "./mutations";
import { IInvoiceOptionDefaultState } from "./states";
import { InvoiceOptionRequestDto } from "Api";

export const actions: ActionTree<IInvoiceOptionDefaultState, RootState> = {
  getInvoiceOptionDefaultByInvoiceId(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          InvoiceOptionDefaultMutations.SET_IS_LOADING_INVOICE_OPTION_DEFAULT,
          true
        );
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/InvoiceOptionDefault/GetDefault`;

      axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              InvoiceOptionDefaultMutations.SET_INVOICE_OPTION_DEFAULT,
              res.data
            );
            commit(
              InvoiceOptionDefaultMutations.SET_INVOICE_OPTION_DEFAULT_FORM,
              res.data
            );
            commit(
              InvoiceOptionDefaultMutations.SET_IS_LOADING_INVOICE_OPTION_DEFAULT,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              InvoiceOptionDefaultMutations.SET_IS_LOADING_INVOICE_OPTION_DEFAULT,
              false
            );
          }
          reject(err);
        });
    });
  },

  createInvoiceOptionDefault(
    { commit },
    {
      invoiceOptionDefault,
      updateState = true,
    }: {
      invoiceOptionDefault: InvoiceOptionRequestDto;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          InvoiceOptionDefaultMutations.SET_IS_LOADING_INVOICE_OPTION_DEFAULT,
          true
        );
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/InvoiceOptionDefault/CreateInvoiceOptionDefault`;

      axios
        .post(url, invoiceOptionDefault)
        .then((res) => {
          if (updateState) {
            commit(
              InvoiceOptionDefaultMutations.SET_INVOICE_OPTION_DEFAULT,
              res.data
            );
            commit(
              InvoiceOptionDefaultMutations.SET_IS_LOADING_INVOICE_OPTION_DEFAULT,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              InvoiceOptionDefaultMutations.SET_IS_LOADING_INVOICE_OPTION_DEFAULT,
              false
            );
          }
          reject(err);
        });
    });
  },

  updateInvoiceOptionDefault(
    { commit },
    {
      invoiceOptionDefault,
      updateState = true,
    }: {
      invoiceOptionDefault: InvoiceOptionRequestDto;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          InvoiceOptionDefaultMutations.SET_IS_LOADING_INVOICE_OPTION_DEFAULT,
          true
        );
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/InvoiceOptionDefault/UpdateInvoiceOptionDefault`;

      axios
        .put(url, invoiceOptionDefault)
        .then((res) => {
          if (updateState) {
            commit(
              InvoiceOptionDefaultMutations.SET_INVOICE_OPTION_DEFAULT,
              res.data
            );
            commit(
              InvoiceOptionDefaultMutations.SET_IS_LOADING_INVOICE_OPTION_DEFAULT,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              InvoiceOptionDefaultMutations.SET_IS_LOADING_INVOICE_OPTION_DEFAULT,
              false
            );
          }
          reject(err);
        });
    });
  },
};
