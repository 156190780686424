import { IQuoteOptionState } from "./states";
import { Module } from "vuex";
import { QuoteOptionRequestDto } from "@/../Api";
import RootState from "@/store/modules/rootState";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

const state: IQuoteOptionState = {
  quoteOption: <QuoteOptionRequestDto>{},
  isLoadingQuoteOption: false,
  requestStateQuoteOption: <IRequestState>{},
};

export const QuoteOptionModule: Module<IQuoteOptionState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
