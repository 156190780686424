import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { DeliveryFormOptionMutations } from "./mutations";
import { IDeliveryFormOptionState } from "./states";
import { DeliveryFormOptionRequestDto } from "Api";

export const actions: ActionTree<IDeliveryFormOptionState, RootState> = {
  getDeliveryFormOptionByDeliveryFormId(
    { commit },
    {
      deliveryFormId,
      updateState = true,
    }: { deliveryFormId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          DeliveryFormOptionMutations.SET_IS_LOADING_DELIVERY_FORM_OPTION,
          true
        );
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/DeliveryFormOption/GetDeliveryFormOptionByDeliveryFormId`;

      axios
        .get(url, {
          params: { deliveryFormId },
        })
        .then((res) => {
          if (updateState) {
            commit(
              DeliveryFormOptionMutations.SET_DELIVERY_FORM_OPTION,
              res.data
            );
            commit(
              DeliveryFormOptionMutations.SET_IS_LOADING_DELIVERY_FORM_OPTION,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              DeliveryFormOptionMutations.SET_IS_LOADING_DELIVERY_FORM_OPTION,
              false
            );
          }
          reject(err);
        });
    });
  },

  createDeliveryFormOption(
    { commit },
    {
      deliveryFormOption,
      updateState = true,
    }: {
      deliveryFormOption: DeliveryFormOptionRequestDto;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          DeliveryFormOptionMutations.SET_IS_LOADING_DELIVERY_FORM_OPTION,
          true
        );
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/DeliveryFormOption/CreateDeliveryFormOption`;

      axios
        .post(url, deliveryFormOption)
        .then((res) => {
          if (updateState) {
            commit(
              DeliveryFormOptionMutations.SET_DELIVERY_FORM_OPTION,
              res.data.data
            );
            commit(
              DeliveryFormOptionMutations.SET_IS_LOADING_DELIVERY_FORM_OPTION,
              false
            );
          }
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              DeliveryFormOptionMutations.SET_IS_LOADING_DELIVERY_FORM_OPTION,
              false
            );
          }
          reject(err);
        });
    });
  },

  updateDeliveryFormOption(
    { commit, rootGetters },
    {
      deliveryFormOption,
      updateState = true,
    }: {
      deliveryFormOption: DeliveryFormOptionRequestDto;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          DeliveryFormOptionMutations.SET_IS_LOADING_DELIVERY_FORM_OPTION,
          true
        );
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/DeliveryFormOption/UpdateDeliveryFormOption`;

      axios
        .put(url, {
          ...deliveryFormOption,
          deliveryFormId: rootGetters.deliveryForm.id,
        })
        .then((res) => {
          if (updateState) {
            commit(
              DeliveryFormOptionMutations.SET_DELIVERY_FORM_OPTION,
              res.data.data
            );
            commit(
              DeliveryFormOptionMutations.SET_IS_LOADING_DELIVERY_FORM_OPTION,
              false
            );
          }
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              DeliveryFormOptionMutations.SET_IS_LOADING_DELIVERY_FORM_OPTION,
              false
            );
          }
          reject(err);
        });
    });
  },
};
