import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { ContactRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IContactState } from "./states";

export enum ContactMutations {
    SET_CONTACTS_LIST = "SET_CONTACTS_LIST",
    SET_CONTACTS_LIST_ARCHIVED = "SET_CONTACTS_LIST_ARCHIVED",
    SET_CONTACT = "SET_CONTACT",

    // COMMON
    SET_IS_LOADING_CONTACTS_LIST= "SET_IS_LOADING_CONTACTS_LIST",
    SET_IS_LOADING_CONTACTS_LIST_ARCHIVED= "SET_IS_LOADING_CONTACTS_LIST_ARCHIVED",
    
    SET_IS_LOADING_CONTACT= "SET_IS_LOADING_CONTACT",
    SET_IS_CREATING_CONTACT = "SET_IS_CREATING_CONTACT",
    SET_IS_UPDATING_CONTACT= "SET_IS_UPDATING_CONTACT",
    SET_IS_DELETING_CONTACT = "SET_IS_DELETING_CONTACT",
    SET_REQUEST_STATE_CONTACT = "SET_REQUEST_STATE_CONTACT",
    SET_IS_ARCHIVING_CONTACT = "SET_IS_ARCHIVING_CONTACT"
}

export const mutations: MutationTree<IContactState> = {
    [ContactMutations.SET_CONTACTS_LIST]: (state, payload: ContactRequestDto[]) => {
        state.contactsList = payload.map(el=> { return {...el, displayLabel:el.firstName+' '+el.lastName } });
    },
    [ContactMutations.SET_CONTACTS_LIST_ARCHIVED]: (state, payload: ContactRequestDto[]) => {
        state.contactsListArchived = payload;
    },
    [ContactMutations.SET_CONTACT]: (state, payload: ContactRequestDto) => {
        state.contact = payload;
    },


    //COMMON
    [ContactMutations.SET_IS_LOADING_CONTACTS_LIST]: (state, payload: boolean) => {
        state.isLoadingContactsList = payload;
    },
    [ContactMutations.SET_IS_LOADING_CONTACTS_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingContactsListArchived = payload;
    },
    [ContactMutations.SET_IS_LOADING_CONTACT]: (state, payload: boolean) => {
        state.isLoadingContact = payload;
    },
    [ContactMutations.SET_IS_CREATING_CONTACT]: (state, payload: boolean) => {
        state.isCreatingContact = payload;
    },
    [ContactMutations.SET_IS_UPDATING_CONTACT]: (state, payload: boolean) => {
        state.isUpdatingContact = payload;
    },
    [ContactMutations.SET_IS_DELETING_CONTACT]: (state, payload: boolean) => {
        state.isDeletingContact = payload;
    },
    [ContactMutations.SET_REQUEST_STATE_CONTACT]: (state, payload: IRequestState) => {
        state.requestStateContact = payload;
    },
    [ContactMutations.SET_IS_ARCHIVING_CONTACT]: (state, payload: boolean) => {
        state.isArchivingContact = payload;
    },
}