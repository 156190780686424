import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { AnalyticsSettingRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { IAnalyticsSettingState } from './states'


export const getters: GetterTree<IAnalyticsSettingState, RootState> = {
  analyticsSettingsList: (state): AnalyticsSettingRequestDto[] => state.analyticsSettingsList,
  analyticsSettingsListArchived: (state): AnalyticsSettingRequestDto[] => state.analyticsSettingsListArchived,
  analyticsSettingsListTemplates: (state): AnalyticsSettingRequestDto[] => state.analyticsSettingsListTemplates,
  analyticsSetting     : (state): AnalyticsSettingRequestDto => state.analyticsSetting,

  // COMMON
  isLoadingAnalyticsSettingsList   : (state): boolean => state.isLoadingAnalyticsSettingsList,
  isLoadingAnalyticsSettingsListArchived   : (state): boolean => state.isLoadingAnalyticsSettingsListArchived,
  isLoadingAnalyticsSettingsListTemplates   : (state): boolean => state.isLoadingAnalyticsSettingsListTemplates,
  isLoadingAnalyticsSetting   : (state): boolean => state.isLoadingAnalyticsSetting,
  isCreatingAnalyticsSetting  : (state): boolean => state.isCreatingAnalyticsSetting,
  isUpdatingAnalyticsSetting  : (state): boolean => state.isUpdatingAnalyticsSetting,
  isDeletingAnalyticsSetting  : (state): boolean => state.isDeletingAnalyticsSetting,
  isArchivingAnalyticsSetting  : (state): boolean => state.isArchivingAnalyticsSetting,
  requestStateAnalyticsSetting: (state): IRequestState => state.requestStateAnalyticsSetting,
}



