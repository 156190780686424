import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { GetterTree } from "vuex";
import RootState from '@/store/modules/rootState'
import {  IUnitState } from "./states";

export const getters: GetterTree<IUnitState, RootState> = {
    unitsList                     : (state): any[] => state.unitsList,
    unitsListArchived             : (state): any[] => state.unitsListArchived,
    unit                          : (state): any => state.unit,
    
    // COMMON
    isLoadingUnitsList            : (state): boolean => state.isLoadingUnitsList,
    isLoadingUnitsListArchived    : (state): boolean => state.isLoadingUnitsListArchived,
    isLoadingUnit                 : (state): boolean => state.isLoadingUnit,
    isCreatingUnit                : (state): boolean => state.isCreatingUnit,
    isUpdatingUnit                : (state): boolean => state.isUpdatingUnit,
    isDeletingUnit                : (state): boolean => state.isDeletingUnit,
    requestStateUnit              : (state): IRequestState => state.requestStateUnit,
    isArchivingUnit               : (state): boolean => state.isArchivingUnit,
}