import { InterventionRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { InterventionMutations } from "./mutations";
import { IInterventionState } from "./states";
import { Timezone } from "@syncfusion/ej2-schedule";
let timezoneSchedule = new Timezone();
import dayjs from "dayjs";

export const actions: ActionTree<IInterventionState, RootState> = {
  async getInterventions(
    { commit },
    {
      startDate,
      endDate,
      updateState = true,
    }: { startDate: Date; endDate: Date; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(InterventionMutations.SET_IS_LOADING_INTERVENTIONS_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Interventions/Intervention/GetInterventions`;
      await axios
        .get(url, {
          params: {
            startDate: dayjs(startDate).format("YYYY-MM-DD"),
            endDate: dayjs(endDate)
              .hour(23)
              .minute(59)
              .second(59)
              .format("YYYY-MM-DD HH:mm:ss"),
            collaboratorId: null,
          },
        })
        .then((res) => {
          let data = res.data.map((inter) => {
            return {
              ...inter,
              date: inter.date
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(new Date(inter.date))
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              affairCodeId: inter.affairId
            };
          });
          if (updateState) {
            commit(InterventionMutations.SET_INTERVENTIONS_LIST, data);
            commit(
              InterventionMutations.SET_IS_LOADING_INTERVENTIONS_LIST,
              false
            );
          }
          commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, res);
          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            InterventionMutations.SET_IS_LOADING_INTERVENTIONS_LIST,
            false
          );
          commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, err);
          reject(err);
        });
    });
  },

  async getInterventionById(
    { commit },
    {
      interventionId,
      updateState = true,
    }: { interventionId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(InterventionMutations.SET_IS_LOADING_INTERVENTION, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Interventions/Intervention/GetInterventionById`,
          {
            params: {
              interventionId: interventionId,
            },
          }
        )
        .then((res) => {
          for (let i = 0; i < res.data.customFields.length; i++) {
            const customField = res.data.customFields[i];
            if (customField.customField.typeField == 10 && customField.value) {
              customField.value = customField.value.split(";");
            }
          }
          if (updateState) {
            commit(InterventionMutations.SET_INTERVENTION, res.data);
            commit(InterventionMutations.SET_IS_LOADING_INTERVENTION, false);
          }
          commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, err);
          commit(InterventionMutations.SET_IS_LOADING_INTERVENTION, false);
          reject(err);
        });
    });
  },

  async getInterventionsByCollaboratorId(
    { commit },
    {
      collaboratorId,
      startDate,
      endDate,
      updateState = true,
    }: {
      collaboratorId: number;
      startDate: Date;
      endDate: Date;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          InterventionMutations.SET_IS_LOADING_INTERVENTIONS_LIST_BY_COLLABORATOR_ID,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Interventions/Intervention/GetInterventions`;
      await axios
        .get(url, {
          params: {
            collaboratorId: collaboratorId,
            startDate: dayjs(startDate).format("YYYY-MM-DD"),
            endDate: dayjs(endDate)
              .hour(23)
              .minute(59)
              .second(59)
              .format("YYYY-MM-DD HH:mm:ss"),
          },
        })
        .then((res) => {
          let data = res.data.map((inter) => {
            return {
              ...inter,
              date: inter.date
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(new Date(inter.date))
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
            };
          });
          if (updateState) {
            commit(
              InterventionMutations.SET_INTERVENTIONS_LIST_BY_COLLABORATOR_ID,
              data
            );
            commit(
              InterventionMutations.SET_IS_LOADING_INTERVENTIONS_LIST_BY_COLLABORATOR_ID,
              false
            );
          }
          commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, res);
          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            InterventionMutations.SET_IS_LOADING_INTERVENTIONS_LIST_BY_COLLABORATOR_ID,
            false
          );
          commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, err);
          reject(err);
        });
    });
  },

  // async getInterventionCustomFieldByInterventionId

  async createIntervention(
    { commit, dispatch, rootGetters },
    { intervention, interventionCustomFields }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(InterventionMutations.SET_IS_CREATING_INTERVENTION, true);
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Interventions/Intervention/CreateIntervention`,
          intervention
        )
        .then(async (res) => {
          if (intervention.customFields) {
            for (let i = 0; i < res.data.data.length; i++) {
              await dispatch("updateInterventionCustomField", {
                interventionId: res.data.data[i].id,
                interventionCustomFields: interventionCustomFields,
              });
            }
          }
          rootGetters.interventionsList.push(res.data.data);
          commit(
            InterventionMutations.SET_INTERVENTIONS_LIST,
            rootGetters.interventionsList
          );
          commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, res);
          commit(InterventionMutations.SET_IS_CREATING_INTERVENTION, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, err);
          commit(InterventionMutations.SET_IS_CREATING_INTERVENTION, false);
          reject(err);
        });
    });
  },

  async updateIntervention(
    { commit, dispatch, rootGetters },
    { intervention, interventionCustomFields }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(InterventionMutations.SET_IS_UPDATING_INTERVENTION, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Interventions/Intervention/UpdateIntervention`,
          intervention
        )
        .then((res) => {
          if (intervention.customFields && interventionCustomFields) {
            dispatch("updateInterventionCustomField", {
              interventionId: res.data.data.id,
              interventionCustomFields: interventionCustomFields,
            });
          }
          if (
            rootGetters.interventionsList.find(
              (elem) => elem.id == intervention.id
            )
          ) {
            rootGetters.interventionsList.splice(
              rootGetters.interventionsList.findIndex(
                (elem) => elem.id == intervention.id
              ),
              1,
              intervention
            );
            commit(
              InterventionMutations.SET_INTERVENTIONS_LIST,
              rootGetters.interventionsList
            );
          }
          commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, res);
          commit(InterventionMutations.SET_IS_UPDATING_INTERVENTION, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, err);
          commit(InterventionMutations.SET_IS_UPDATING_INTERVENTION, false);
          reject(err);
        });
    });
  },

  async updateInterventionCustomField(
    { commit },
    { interventionId, interventionCustomFields }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      const customFields = { ...interventionCustomFields };
      for (let [key, value] of Object.entries(customFields)) {
        //if one of the value is an array, we stringify it and separate each elements with ';'
        if (Array.isArray(value)) {
          customFields[key] = value.join(";");
        }
      }
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Interventions/Intervention/UpdateInterventionCustomField",
          customFields,
          {
            params: {
              interventionId: interventionId,
            },
          }
        )
        .then((res) => {
          commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, res);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, err);
          reject(err);
        });
    });
  },

  async deleteInterventions(
    { commit, rootGetters },
    { interventionIds, origine }: { interventionIds: number[]; origine: string }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(InterventionMutations.SET_IS_DELETING_INTERVENTION, true);
      await axios
        .delete(
          `${process.env.VUE_APP_API_ORISIS}Interventions/Intervention/Delete`,
          {
            data: interventionIds,
          }
        )
        .then((res) => {
          let datas =
            origine == "myInterventions"
              ? rootGetters.interventionsListByCollaboratorId
              : rootGetters.interventionsList;
          interventionIds.forEach((interventionId) => {
            datas.splice(
              datas.findIndex((elem) => elem.id == interventionId),
              1
            );
          });
          commit(InterventionMutations.SET_INTERVENTIONS_LIST, datas);
          commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, res);
          commit(InterventionMutations.SET_IS_DELETING_INTERVENTION, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(InterventionMutations.SET_REQUEST_STATE_INTERVENTION, err);
          commit(InterventionMutations.SET_IS_DELETING_INTERVENTION, false);
          reject(err);
        });
    });
  },
};
