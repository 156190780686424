import { SmtpSettingRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { SmtpSettingMutations } from "./mutations";
import { ISmtpSettingState } from "./states";

export const actions: ActionTree<ISmtpSettingState, RootState> = {
  getSmtpSettings(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(SmtpSettingMutations.SET_IS_LOADING_SMTP_SETTING_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Parameters/SmtpSetting/GetSmtpSettings`;
      axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(SmtpSettingMutations.SET_IS_LOADING_SMTP_SETTING_LIST, false);
            commit(SmtpSettingMutations.SET_SMTP_SETTING_LIST, res.data);
          }
          commit(SmtpSettingMutations.SET_REQUEST_STATE_SMTP_SETTING, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(SmtpSettingMutations.SET_IS_LOADING_SMTP_SETTING_LIST, false);
          commit(SmtpSettingMutations.SET_REQUEST_STATE_SMTP_SETTING, err);
          reject(err);
        });
    });
  },

  getSmtpSettingById(
    { commit },
    { smtpSettingId, updateState = true }: { smtpSettingId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(SmtpSettingMutations.SET_IS_LOADING_SMTP_SETTING, true);
      }
      axios
        .get(`${process.env.VUE_APP_API_ORISIS}Parameters/SmtpSetting/GetSmtpSettingById`, {
          params: {
            smtpsettingId: smtpSettingId,
          },
        })
        .then((res) => {
          if (updateState) {
            commit(SmtpSettingMutations.SET_SMTP_SETTING, res.data);
            commit(SmtpSettingMutations.SET_IS_LOADING_SMTP_SETTING, false);
          }
          commit(SmtpSettingMutations.SET_REQUEST_STATE_SMTP_SETTING, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(SmtpSettingMutations.SET_REQUEST_STATE_SMTP_SETTING, err);
          commit(SmtpSettingMutations.SET_IS_LOADING_SMTP_SETTING, false);
          reject(err);
        });
    });
  },

  getSmtpSettingsArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(SmtpSettingMutations.SET_IS_LOADING_SMTP_SETTING_LIST_ARCHIVED, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Parameters/SmtpSetting/GetSmtpSettingsArchived`;
      axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(SmtpSettingMutations.SET_IS_LOADING_SMTP_SETTING_LIST_ARCHIVED, false);
            commit(SmtpSettingMutations.SET_SMTP_SETTING_LIST_ARCHIVED, res.data);
          }
          commit(SmtpSettingMutations.SET_REQUEST_STATE_SMTP_SETTING, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(SmtpSettingMutations.SET_IS_LOADING_SMTP_SETTING_LIST_ARCHIVED, false);
          commit(SmtpSettingMutations.SET_REQUEST_STATE_SMTP_SETTING, err);
          reject(err);
        });
    });
  },

  getSmtpSettingArchivedById(
    { commit },
    { smtpSettingId, updateState = true }: { smtpSettingId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(SmtpSettingMutations.SET_IS_LOADING_SMTP_SETTING, true);
      }
      axios
        .get(`${process.env.VUE_APP_API_ORISIS}Parameters/SmtpSetting/GetSmtpSettingArchivedById`, {
          params: {
            smtpsettingId: smtpSettingId,
          },
        })
        .then((res) => {
          if (updateState) {
            commit(SmtpSettingMutations.SET_SMTP_SETTING, res.data);
            commit(SmtpSettingMutations.SET_IS_LOADING_SMTP_SETTING, false);
          }
          commit(SmtpSettingMutations.SET_REQUEST_STATE_SMTP_SETTING, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(SmtpSettingMutations.SET_REQUEST_STATE_SMTP_SETTING, err);
          commit(SmtpSettingMutations.SET_IS_LOADING_SMTP_SETTING, false);
          reject(err);
        });
    });
  },

  createSmtpSetting(
    { commit, rootGetters },
    { smtpSetting }: { smtpSetting: SmtpSettingRequestDto }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(SmtpSettingMutations.SET_IS_CREATING_SMTP_SETTING, true);
      axios
        .post(`${process.env.VUE_APP_API_ORISIS}Parameters/SmtpSetting/CreateSmtpSetting`, smtpSetting)
        .then((res) => {
          rootGetters.smtpSettingList.push(res.data.data);
          commit(SmtpSettingMutations.SET_SMTP_SETTING_LIST, rootGetters.smtpSettingList);
          commit(SmtpSettingMutations.SET_REQUEST_STATE_SMTP_SETTING, res);
          commit(SmtpSettingMutations.SET_IS_CREATING_SMTP_SETTING, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(SmtpSettingMutations.SET_REQUEST_STATE_SMTP_SETTING, err);
          commit(SmtpSettingMutations.SET_IS_CREATING_SMTP_SETTING, false);
          reject(err);
        });
    });
  },

  updateSmtpSetting(
    { commit, rootGetters },
    { smtpSetting }: { smtpSetting: SmtpSettingRequestDto }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(SmtpSettingMutations.SET_IS_UPDATING_SMTP_SETTING, true);
      axios
        .post(`${process.env.VUE_APP_API_ORISIS}Parameters/SmtpSetting/UpdateSmtpSetting`, smtpSetting)
        .then((res) => {
          rootGetters.smtpSettingList.splice(
            rootGetters.smtpSettingList.findIndex((elem) => elem.id == smtpSetting.id),
            1,
            res.data
          );
          commit(SmtpSettingMutations.SET_SMTP_SETTING_LIST, rootGetters.smtpSettingList);
          commit(SmtpSettingMutations.SET_REQUEST_STATE_SMTP_SETTING, res);
          commit(SmtpSettingMutations.SET_IS_UPDATING_SMTP_SETTING, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(SmtpSettingMutations.SET_REQUEST_STATE_SMTP_SETTING, err);
          commit(SmtpSettingMutations.SET_IS_UPDATING_SMTP_SETTING, false);
          reject(err);
        });
    });
  },

  restoreSmtpSettings(
    { commit, rootGetters },
    { smtpSettingIds = [] }: { smtpSettingIds: number[] }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(SmtpSettingMutations.SET_IS_UPDATING_SMTP_SETTING, true);
      axios
        .put(process.env.VUE_APP_API_ORISIS + "Parameters/SmtpSetting/Restore", smtpSettingIds)
        .then((res) => {
          smtpSettingIds.forEach((smtpSettingId: number) => {
            rootGetters.smtpSettingListArchived.splice(
              rootGetters.smtpSettingListArchived.findIndex(
                (elem) => elem.id == smtpSettingId
              ),
              1
            );
          });
          commit(SmtpSettingMutations.SET_REQUEST_STATE_SMTP_SETTING, res);
          commit(SmtpSettingMutations.SET_IS_UPDATING_SMTP_SETTING, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(SmtpSettingMutations.SET_REQUEST_STATE_SMTP_SETTING, err);
          commit(SmtpSettingMutations.SET_IS_UPDATING_SMTP_SETTING, false);
          reject(err);
        });
    });
  },

  archiveSmtpSettings(
    { commit, rootGetters },
    { smtpSettingIds = [] }: { smtpSettingIds: number[] }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(SmtpSettingMutations.SET_IS_ARCHIVING_SMTP_SETTING, true);
      axios
        .delete(process.env.VUE_APP_API_ORISIS + "Parameters/SmtpSetting/Archive", {
          data: smtpSettingIds,
        })
        .then((res) => {
          smtpSettingIds.forEach((settingId: number) => {
            rootGetters.smtpSettingList.splice(
              rootGetters.smtpSettingList.findIndex((elem) => elem.id == settingId),
              1
            );
          });
          commit(SmtpSettingMutations.SET_SMTP_SETTING_LIST, rootGetters.smtpSettingList);
          commit(SmtpSettingMutations.SET_REQUEST_STATE_SMTP_SETTING, res);
          commit(SmtpSettingMutations.SET_IS_ARCHIVING_SMTP_SETTING, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(SmtpSettingMutations.SET_REQUEST_STATE_SMTP_SETTING, err);
          commit(SmtpSettingMutations.SET_IS_ARCHIVING_SMTP_SETTING, false);
          reject(err);
        });
    });
  },

  async deleteSmtpSettings(
    { commit, rootGetters },
    { smtpSettingIds = [] }: { smtpSettingIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(SmtpSettingMutations.SET_IS_DELETING_SMTP_SETTING, true);
      await axios
        .delete(`${process.env.VUE_APP_API_ORISIS}Parameters/SmtpSetting/Delete`, {
          data: smtpSettingIds,
        })
        .then((res) => {
          smtpSettingIds.forEach((settingId: number) => {
            rootGetters.smtpSettingList.splice(
              rootGetters.smtpSettingList.findIndex((elem) => elem.id == settingId),
              1
            );
          });
          commit(SmtpSettingMutations.SET_SMTP_SETTING_LIST, rootGetters.smtpSettingList);
          commit(SmtpSettingMutations.SET_REQUEST_STATE_SMTP_SETTING, res);
          commit(SmtpSettingMutations.SET_IS_DELETING_SMTP_SETTING, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(SmtpSettingMutations.SET_REQUEST_STATE_SMTP_SETTING, err);
          commit(SmtpSettingMutations.SET_IS_DELETING_SMTP_SETTING, false);
          reject(err);
        });
    });
  },
};
