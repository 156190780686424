import { ProductCategoryRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { ProductCategoryMutations } from "./mutations";
import { IProductCategoryState } from "./states";

export const actions: ActionTree<IProductCategoryState, RootState> = {
  async getProductCategoriesTree(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          ProductCategoryMutations.SET_IS_LOADING_PRODUCT_CATEGORIES_TREE_LIST,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Sale/ProductCategory/GetProductCategoriesTree`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              ProductCategoryMutations.SET_IS_LOADING_PRODUCT_CATEGORIES_TREE_LIST,
              false
            );
            commit(
              ProductCategoryMutations.SET_PRODUCT_CATEGORIES_TREE_LIST,
              res.data
            );
          }
          commit(
            ProductCategoryMutations.SET_REQUEST_STATE_PRODUCT_CATEGORY,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ProductCategoryMutations.SET_IS_LOADING_PRODUCT_CATEGORIES_TREE_LIST,
            false
          );
          commit(
            ProductCategoryMutations.SET_REQUEST_STATE_PRODUCT_CATEGORY,
            err
          );
          reject(err);
        });
    });
  },

  async getProductCategories(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          ProductCategoryMutations.SET_IS_LOADING_PRODUCT_CATEGORIES_LIST,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Sale/ProductCategory/GetProductCategories`;
      await axios
        .get(url)
        .then((res) => {
          let productCategories = res.data.map((elem: any) => {
            return {
              ...elem,
              parentId: elem.parent ? elem.parent.id : null,
              hasChild: res.data.filter((el) => el.parent?.id === elem.id)
                .length
                ? true
                : false,
            };
          });
          if (updateState) {
            commit(
              ProductCategoryMutations.SET_IS_LOADING_PRODUCT_CATEGORIES_LIST,
              false
            );
            commit(
              ProductCategoryMutations.SET_PRODUCT_CATEGORIES_LIST,
              productCategories
            );
          }
          commit(
            ProductCategoryMutations.SET_REQUEST_STATE_PRODUCT_CATEGORY,
            res
          );
          resolve(productCategories);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ProductCategoryMutations.SET_IS_LOADING_PRODUCT_CATEGORIES_LIST,
            false
          );
          commit(
            ProductCategoryMutations.SET_REQUEST_STATE_PRODUCT_CATEGORY,
            err
          );
          reject(err);
        });
    });
  },

  async getProductCategoriesArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          ProductCategoryMutations.SET_IS_LOADING_PRODUCT_CATEGORIES_LIST_ARCHIVED,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Sale/ProductCategory/GetProductCategoriesArchived`;
      await axios
        .get(url)
        .then((res) => {
          let productCategories = res.data.map((elem: any) => {
            return {
              ...elem,
              parentId: elem.parent ? elem.parent.id : null,
              hasChild: res.data.filter((el) => el.parent?.id === elem.id)
                .length
                ? true
                : false,
            };
          });
          if (updateState) {
            commit(
              ProductCategoryMutations.SET_IS_LOADING_PRODUCT_CATEGORIES_LIST_ARCHIVED,
              false
            );
            commit(
              ProductCategoryMutations.SET_PRODUCT_CATEGORIES_LIST_ARCHIVED,
              productCategories
            );
          }
          commit(
            ProductCategoryMutations.SET_REQUEST_STATE_PRODUCT_CATEGORY,
            res
          );
          resolve(productCategories);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ProductCategoryMutations.SET_IS_LOADING_PRODUCT_CATEGORIES_LIST_ARCHIVED,
            false
          );
          commit(
            ProductCategoryMutations.SET_REQUEST_STATE_PRODUCT_CATEGORY,
            err
          );
          reject(err);
        });
    });
  },

  async getProductCategoryById(
    { commit },
    {
      productCategoryId,
      updateState = true,
    }: { productCategoryId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(ProductCategoryMutations.SET_IS_LOADING_PRODUCT_CATEGORY, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Sale/ProductCategory/GetProductCategoryById`,
          {
            params: {
              productCategoryId: productCategoryId,
            },
          }
        )
        .then((res) => {
          let productCategory = {
            ...res.data,
            parentId: res.data.parent ? res.data.parent.id : null,
          };
          if (updateState) {
            commit(
              ProductCategoryMutations.SET_PRODUCT_CATEGORY,
              productCategory
            );
            commit(
              ProductCategoryMutations.SET_IS_LOADING_PRODUCT_CATEGORY,
              false
            );
          }
          commit(
            ProductCategoryMutations.SET_REQUEST_STATE_PRODUCT_CATEGORY,
            res
          );
          resolve(productCategory);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ProductCategoryMutations.SET_REQUEST_STATE_PRODUCT_CATEGORY,
            err
          );
          commit(
            ProductCategoryMutations.SET_IS_LOADING_PRODUCT_CATEGORY,
            false
          );
          reject(err);
        });
    });
  },

  async getProductCategoryArchivedById(
    { commit },
    {
      productCategoryId,
      updateState = true,
    }: { productCategoryId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(ProductCategoryMutations.SET_IS_LOADING_PRODUCT_CATEGORY, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Sale/ProductCategory/GetProductCategoryArchivedById`,
          {
            params: {
              productCategoryId: productCategoryId,
            },
          }
        )
        .then((res) => {
          let productCategoryArchived = {
            ...res.data,
            parentId: res.data.parent ? res.data.parent.id : null,
          };
          if (updateState) {
            commit(
              ProductCategoryMutations.SET_PRODUCT_CATEGORY,
              productCategoryArchived
            );
            commit(
              ProductCategoryMutations.SET_IS_LOADING_PRODUCT_CATEGORY,
              false
            );
          }
          commit(
            ProductCategoryMutations.SET_REQUEST_STATE_PRODUCT_CATEGORY,
            res
          );
          resolve(productCategoryArchived);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ProductCategoryMutations.SET_REQUEST_STATE_PRODUCT_CATEGORY,
            err
          );
          commit(
            ProductCategoryMutations.SET_IS_LOADING_PRODUCT_CATEGORY,
            false
          );
          reject(err);
        });
    });
  },

  async createProductCategory(
    { commit, rootGetters },
    { productCategory }: { productCategory: ProductCategoryRequestDto }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(ProductCategoryMutations.SET_IS_CREATING_PRODUCT_CATEGORY, true);
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Sale/ProductCategory/CreateProductCategory`,
          productCategory
        )
        .then((res) => {
          rootGetters.productCategoriesTreeList.push(res.data.data);
          commit(
            ProductCategoryMutations.SET_PRODUCT_CATEGORIES_TREE_LIST,
            rootGetters.productCategoriesTreeList
          );
          rootGetters.productCategoriesList.push(res.data.data);
          commit(
            ProductCategoryMutations.SET_PRODUCT_CATEGORIES_LIST,
            rootGetters.productCategoriesList
          );
          commit(
            ProductCategoryMutations.SET_REQUEST_STATE_PRODUCT_CATEGORY,
            res
          );
          commit(
            ProductCategoryMutations.SET_IS_CREATING_PRODUCT_CATEGORY,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ProductCategoryMutations.SET_REQUEST_STATE_PRODUCT_CATEGORY,
            err
          );
          commit(
            ProductCategoryMutations.SET_IS_CREATING_PRODUCT_CATEGORY,
            false
          );
          reject(err);
        });
    });
  },

  async updateProductCategory(
    { commit, dispatch },
    { productCategory }: { productCategory: ProductCategoryRequestDto }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(ProductCategoryMutations.SET_IS_UPDATING_PRODUCT_CATEGORY, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Sale/ProductCategory/UpdateProductCategory`,
          productCategory
        )
        .then((res) => {
          dispatch("getProductCategories", {});
          dispatch("getProductCategoriesTree", {});
          commit(
            ProductCategoryMutations.SET_REQUEST_STATE_PRODUCT_CATEGORY,
            res
          );
          commit(
            ProductCategoryMutations.SET_IS_UPDATING_PRODUCT_CATEGORY,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ProductCategoryMutations.SET_REQUEST_STATE_PRODUCT_CATEGORY,
            err
          );
          commit(
            ProductCategoryMutations.SET_IS_UPDATING_PRODUCT_CATEGORY,
            false
          );
          reject(err);
        });
    });
  },

  async restoreProductCategories(
    { commit, rootGetters, dispatch },
    { productCategoryIds = [] }: { productCategoryIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(ProductCategoryMutations.SET_IS_UPDATING_PRODUCT_CATEGORY, true);
      axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Sale/ProductCategory/Restore",
          productCategoryIds
        )
        .then((res) => {
          dispatch("getProductCategoriesTree", {});
          productCategoryIds.forEach((productCategoryId: number) => {
            rootGetters.productCategoriesList.splice(
              rootGetters.productCategoriesListArchived.findIndex(
                (elem) => elem.id == productCategoryId
              )
            );
          });
          commit(
            ProductCategoryMutations.SET_PRODUCT_CATEGORIES_LIST,
            rootGetters.productCategoriesList
          );
          commit(
            ProductCategoryMutations.SET_PRODUCT_CATEGORIES_LIST_ARCHIVED,
            rootGetters.productCategoriesListArchived
          );
          commit(
            ProductCategoryMutations.SET_REQUEST_STATE_PRODUCT_CATEGORY,
            res
          );
          commit(
            ProductCategoryMutations.SET_IS_UPDATING_PRODUCT_CATEGORY,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ProductCategoryMutations.SET_REQUEST_STATE_PRODUCT_CATEGORY,
            err
          );
          commit(
            ProductCategoryMutations.SET_IS_UPDATING_PRODUCT_CATEGORY,
            false
          );
          reject(err);
        });
    });
  },

  async archiveProductCategories(
    { commit, rootGetters, dispatch },
    { productCategoryIds = [] }: { productCategoryIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(ProductCategoryMutations.SET_IS_ARCHIVING_PRODUCT_CATEGORY, true);
      axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Sale/ProductCategory/Archive",
          {
            data: productCategoryIds,
          }
        )
        .then((res) => {
          dispatch("getProductCategoriesTree", {});
          productCategoryIds.forEach((productCategoryId: number) => {
            rootGetters.productCategoriesList.splice(
              rootGetters.productCategoriesListArchived.findIndex(
                (elem) => elem.id == productCategoryId
              )
            );
          });
          commit(
            ProductCategoryMutations.SET_PRODUCT_CATEGORIES_LIST_ARCHIVED,
            rootGetters.productCategoriesListArchived
          );
          commit(
            ProductCategoryMutations.SET_PRODUCT_CATEGORIES_LIST,
            rootGetters.productCategoriesList
          );
          commit(
            ProductCategoryMutations.SET_REQUEST_STATE_PRODUCT_CATEGORY,
            res
          );
          commit(
            ProductCategoryMutations.SET_IS_ARCHIVING_PRODUCT_CATEGORY,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ProductCategoryMutations.SET_REQUEST_STATE_PRODUCT_CATEGORY,
            err
          );
          commit(
            ProductCategoryMutations.SET_IS_ARCHIVING_PRODUCT_CATEGORY,
            false
          );
          reject(err);
        });
    });
  },

  async deleteProductCategories(
    { commit, rootGetters, dispatch },
    { productCategoryIds = [] }: { productCategoryIds: number[] }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(ProductCategoryMutations.SET_IS_DELETING_PRODUCT_CATEGORY, true);
      await axios
        .delete(
          `${process.env.VUE_APP_API_ORISIS}Sale/ProductCategory/Delete`,
          {
            data: productCategoryIds,
          }
        )
        .then((res) => {
          dispatch("getProductCategoriesTree", {});
          productCategoryIds.forEach((productCategoryId: number) => {
            rootGetters.productCategoriesList.splice(
              rootGetters.productCategoriesList.findIndex(
                (elem) => elem.id == productCategoryId
              ),
              1
            );
          });
          commit(
            ProductCategoryMutations.SET_PRODUCT_CATEGORIES_LIST,
            rootGetters.productCategoriesListArchived
          );
          commit(
            ProductCategoryMutations.SET_REQUEST_STATE_PRODUCT_CATEGORY,
            res
          );
          commit(
            ProductCategoryMutations.SET_IS_DELETING_PRODUCT_CATEGORY,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ProductCategoryMutations.SET_REQUEST_STATE_PRODUCT_CATEGORY,
            err
          );
          commit(
            ProductCategoryMutations.SET_IS_DELETING_PRODUCT_CATEGORY,
            false
          );
          reject(err);
        });
    });
  },
};
