import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { FolderRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { IFolderState } from './states'


export const getters: GetterTree<IFolderState, RootState> = {
  foldersList              : (state): FolderRequestDto[] => state.foldersList,
  folder                   : (state): FolderRequestDto => state.folder,

  // COMMON
  isLoadingFoldersList     : (state): boolean => state.isLoadingFoldersList,
  isLoadingFolder          : (state): boolean => state.isLoadingFolder,
  isCreatingFolder         : (state): boolean => state.isCreatingFolder,
  isUpdatingFolder         : (state): boolean => state.isUpdatingFolder,
  isDeletingFolder         : (state): boolean => state.isDeletingFolder,
  isArchivingFolder        : (state): boolean => state.isArchivingFolder,
  requestStateFolder       : (state): IRequestState => state.requestStateFolder,
}



