import { IReferencielTVAState } from "./states";
import { MutationTree } from "vuex";
import { InstitutionSettingReferencielTvaRequestDto } from "Api";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";




export enum ReferencielTVAMutations {
  SET_REFERENCIEL_TVAS_LIST = "SET_REFERENCIEL_TVAS_LIST",
  SET_REFERENCIEL_TVAS_LIST_ARCHIVED = "SET_REFERENCIEL_TVAS_LIST_ARCHIVED",
  SET_REFERENCIEL_TVA = "SET_REFERENCIEL_TVA",

  // COMON
  SET_IS_LOADING_REFERENCIEL_TVAS_LIST = "SET_IS_LOADING_REFERENCIEL_TVAS_LIST",
  SET_IS_LOADING_REFERENCIEL_TVAS_LIST_ARCHIVED = "SET_IS_LOADING_REFERENCIEL_TVAS_LIST_ARCHIVED",
  SET_IS_LOADING_REFERENCIEL_TVA = "SET_IS_LOADING_REFERENCIEL_TVA",
  SET_IS_CREATING_REFERENCIEL_TVA = "SET_IS_CREATING_REFERENCIEL_TVA",
  SET_IS_UPDATING_REFERENCIEL_TVA = "SET_IS_UPDATING_REFERENCIEL_TVA",
  SET_IS_DELETING_REFERENCIEL_TVA = "SET_IS_DELETING_REFERENCIEL_TVA",
  SET_REQUEST_STATE_REFERENCIEL_TVA = "SET_REQUEST_STATE_REFERENCIEL_TVA",
  SET_IS_ARCHIVING_REFERENCIEL_TVA = "SET_IS_ARCHIVING_REFERENCIEL_TVA",  
}

  export const mutations: MutationTree<IReferencielTVAState> = {
    [ReferencielTVAMutations.SET_REFERENCIEL_TVAS_LIST]: (state, payload: InstitutionSettingReferencielTvaRequestDto[]) => {
        state.referencielTVAsList = payload;
    },
    [ReferencielTVAMutations.SET_REFERENCIEL_TVAS_LIST_ARCHIVED]: (state, payload: InstitutionSettingReferencielTvaRequestDto[]) => {
        state.referencielTVAsListArchived = payload;
    },
    [ReferencielTVAMutations.SET_REFERENCIEL_TVA]: (state, payload: InstitutionSettingReferencielTvaRequestDto) => {
        state.referencielTVA = payload;
    },


    //COMMON
    [ReferencielTVAMutations.SET_IS_LOADING_REFERENCIEL_TVAS_LIST]: (state, payload: boolean) => {
        state.isLoadingReferencielTVAsList = payload;
    },
    [ReferencielTVAMutations.SET_IS_LOADING_REFERENCIEL_TVAS_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingReferencielTVAsListArchived = payload;
    },
    [ReferencielTVAMutations.SET_IS_LOADING_REFERENCIEL_TVA]: (state, payload: boolean) => {
        state.isLoadingReferencielTVA = payload;
    },
    [ReferencielTVAMutations.SET_IS_CREATING_REFERENCIEL_TVA]: (state, payload: boolean) => {
        state.isCreatingReferencielTVA = payload;
    },
    [ReferencielTVAMutations.SET_IS_UPDATING_REFERENCIEL_TVA]: (state, payload: boolean) => {
        state.isUpdatingReferencielTVA = payload;
    },
    [ReferencielTVAMutations.SET_IS_DELETING_REFERENCIEL_TVA]: (state, payload: boolean) => {
        state.isDeletingReferencielTVA = payload;
    },
    [ReferencielTVAMutations.SET_REQUEST_STATE_REFERENCIEL_TVA]: (state, payload: IRequestState) => {
        state.requestStateReferencielTVA = payload;
    },
    [ReferencielTVAMutations.SET_IS_ARCHIVING_REFERENCIEL_TVA]: (state, payload: boolean) => {
        state.isArchivingReferencielTVA = payload;
    }
}

