import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { ActivityResultTypeRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IActivityResultTypeState } from "./states";

export enum ActivityResultTypeMutations {
    SET_ACTIVITY_RESULT_TYPES_LIST = "SET_ACTIVITY_RESULT_TYPES_LIST",
    SET_ACTIVITY_RESULT_TYPES_LIST_ARCHIVED = "SET_ACTIVITY_RESULT_TYPES_LIST_ARCHIVED",
    SET_ACTIVITY_RESULT_TYPE = "SET_ACTIVITY_RESULT_TYPE",

    //FILTERS
    SET_ACTIVITY_RESULT_TYPE_FILTERS = "SET_ACTIVITY_RESULT_TYPE_FILTERS",

    // COMMON
    SET_IS_LOADING_ACTIVITY_RESULT_TYPE_LIST = "SET_IS_LOADING_ACTIVITY_RESULT_TYPE_LIST",
    SET_IS_LOADING_ACTIVITY_RESULT_TYPE_LIST_ARCHIVED = "SET_IS_LOADING_ACTIVITY_RESULT_TYPE_LIST_ARCHIVED",
    SET_IS_LOADING_ACTIVITY_RESULT_TYPE = "SET_IS_LOADING_ACTIVITY_RESULT_TYPE",
    SET_IS_CREATING_ACTIVITY_RESULT_TYPE = "SET_IS_CREATING_ACTIVITY_RESULT_TYPE",
    SET_IS_UPDATING_ACTIVITY_RESULT_TYPE = "SET_IS_UPDATING_ACTIVITY_RESULT_TYPE",
    SET_IS_DELETING_ACTIVITY_RESULT_TYPE = "SET_IS_DELETING_ACTIVITY_RESULT_TYPE",
    SET_IS_ARCHIVING_ACTIVITY_RESULT_TYPE = "SET_IS_ARCHIVING_ACTIVITY_RESULT_TYPE",
    SET_REQUEST_STATE_ACTIVITY_RESULT_TYPE = "SET_REQUEST_STATE_ACTIVITY_RESULT_TYPE",
}

export const mutations: MutationTree<IActivityResultTypeState> = {
    [ActivityResultTypeMutations.SET_ACTIVITY_RESULT_TYPES_LIST]: (state, payload: ActivityResultTypeRequestDto[]) => {
        state.activityResultTypesList = payload;
    },
    [ActivityResultTypeMutations.SET_ACTIVITY_RESULT_TYPES_LIST_ARCHIVED]: (state, payload: ActivityResultTypeRequestDto[]) => {
        state.activityResultTypesListArchived = payload;
    }, 
    [ActivityResultTypeMutations.SET_ACTIVITY_RESULT_TYPE]: (state, payload: ActivityResultTypeRequestDto) => {
        state.activityResultType = payload;
    },

    //FILTERS
    [ActivityResultTypeMutations.SET_ACTIVITY_RESULT_TYPE_FILTERS]: (state, payload: any) => {
        state.activityResultTypeFilters = payload;
    },


    //COMMON
    [ActivityResultTypeMutations.SET_IS_LOADING_ACTIVITY_RESULT_TYPE_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingActivityResultTypesListArchived = payload;
    },
    [ActivityResultTypeMutations.SET_IS_LOADING_ACTIVITY_RESULT_TYPE_LIST]: (state, payload: boolean) => {
        state.isLoadingActivityResultTypesList = payload;
    },
    [ActivityResultTypeMutations.SET_IS_LOADING_ACTIVITY_RESULT_TYPE]: (state, payload: boolean) => {
        state.isLoadingActivityResultType = payload;
    },
    [ActivityResultTypeMutations.SET_IS_CREATING_ACTIVITY_RESULT_TYPE]: (state, payload: boolean) => {
        state.isCreatingActivityResultType = payload;
    },
    [ActivityResultTypeMutations.SET_IS_UPDATING_ACTIVITY_RESULT_TYPE]: (state, payload: boolean) => {
        state.isUpdatingActivityResultType = payload;
    },
    [ActivityResultTypeMutations.SET_IS_DELETING_ACTIVITY_RESULT_TYPE]: (state, payload: boolean) => {
        state.isDeletingActivityResultType = payload;
    },
    [ActivityResultTypeMutations.SET_IS_ARCHIVING_ACTIVITY_RESULT_TYPE]: (state, payload: boolean) => {
        state.isArchivingActivityResultType = payload;
    },
    [ActivityResultTypeMutations.SET_REQUEST_STATE_ACTIVITY_RESULT_TYPE]: (state, payload: IRequestState) => {
        state.requestStateActivityResultType = payload;
    },
}