import { CollaboratorRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { CollaboratorMutations } from "./mutations";
import { ICollaboratorState } from "./states";

export const actions: ActionTree<ICollaboratorState, RootState> = {
  async getCollaboratorById(
    { commit },
    {
      collaboratorId,
      updateState = true,
    }: { collaboratorId: number; updateState: boolean }
  ): Promise<CollaboratorRequestDto> {
    return new Promise(async (resolve, reject) => {
      if (updateState) {
        commit(CollaboratorMutations.SET_IS_LOADING_COLLABORATOR, true);
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Directory/Collaborator/GetCollaboratorById",
          {
            params: {
              collaboratorId: collaboratorId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(CollaboratorMutations.SET_COLLABORATOR, res.data);
            commit(CollaboratorMutations.SET_IS_LOADING_COLLABORATOR, false);
          }
          commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, err);
          commit(CollaboratorMutations.SET_IS_LOADING_COLLABORATOR, false);
          reject(err);
        });
    });
  },

  async getCollaboratorArchivedById(
    { commit },
    {
      collaboratorId,
      updateState = true,
    }: { collaboratorId: number; updateState: boolean }
  ): Promise<void> {
    return await new Promise(async (resolve, reject) => {
      if (updateState) {
        commit(CollaboratorMutations.SET_IS_LOADING_COLLABORATOR, true);
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Directory/Collaborator/GetCollaboratorArchivedById",
          {
            params: {
              collaboratorId: collaboratorId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(CollaboratorMutations.SET_COLLABORATOR, res.data);
            commit(CollaboratorMutations.SET_IS_LOADING_COLLABORATOR, false);
          }
          commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, err);
          commit(CollaboratorMutations.SET_IS_LOADING_COLLABORATOR, false);
          reject(err);
        });
    });
  },

  async getCollaborators(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (updateState) {
        commit(CollaboratorMutations.SET_IS_LOADING_COLLABORATORS_LIST, true);
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Directory/Collaborator/GetCollaborators"
        )
        .then((res) => {
          if (updateState) {
            commit(CollaboratorMutations.SET_COLLABORATORS_LIST, res.data);
            commit(
              CollaboratorMutations.SET_IS_LOADING_COLLABORATORS_LIST,
              false
            );
          }
          commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, err);
          commit(
            CollaboratorMutations.SET_IS_LOADING_COLLABORATORS_LIST,
            false
          );
          reject(err);
        });
    });
  },

  async getCollaboratorsArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (updateState) {
        commit(
          CollaboratorMutations.SET_IS_LOADING_COLLABORATORS_LIST_ARCHIVED,
          true
        );
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Directory/Collaborator/GetCollaboratorsArchived"
        )
        .then((res) => {
          if (updateState) {
            commit(
              CollaboratorMutations.SET_COLLABORATORS_LIST_ARCHIVED,
              res.data
            );
            commit(
              CollaboratorMutations.SET_IS_LOADING_COLLABORATORS_LIST_ARCHIVED,
              false
            );
          }
          commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, res);
          commit(CollaboratorMutations.SET_IS_LOADING_COLLABORATOR, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, err);
          commit(
            CollaboratorMutations.SET_IS_LOADING_COLLABORATORS_LIST_ARCHIVED,
            false
          );
          reject(err);
        });
    });
  },

  async getCollaboratorByUserId(
    { commit },
    { userId, updateState = true }: { userId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(CollaboratorMutations.SET_IS_LOADING_USER_COLLABORATOR_ID, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Directory/Collaborator/GetCollaboratorByUserId`,
          {
            params: {
              userId: userId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(CollaboratorMutations.SET_USER_COLLABORATOR, res.data);
            commit(
              CollaboratorMutations.SET_IS_LOADING_USER_COLLABORATOR_ID,
              false
            );
          }
          commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CollaboratorMutations.SET_IS_LOADING_USER_COLLABORATOR_ID,
            false
          );
          commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, err);
          reject(err);
        });
    });
  },

  async createCollaborator(
    { commit, rootGetters },
    { collaborator }: { collaborator: CollaboratorRequestDto }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(CollaboratorMutations.SET_IS_CREATING_COLLABORATOR, true);
      await axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            "Directory/Collaborator/CreateCollaborator",
          collaborator
        )
        .then((res) => {
          rootGetters.collaboratorsList.unshift(res.data.data);
          commit(
            CollaboratorMutations.SET_COLLABORATORS_LIST,
            rootGetters.collaboratorsList
          );
          commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, res);
          commit(CollaboratorMutations.SET_IS_CREATING_COLLABORATOR, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, err);
          commit(CollaboratorMutations.SET_IS_CREATING_COLLABORATOR, false);
          reject(err);
        });
    });
  },

  async updateCollaborator(
    { commit, rootGetters },
    { collaborator }: { collaborator: CollaboratorRequestDto }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(CollaboratorMutations.SET_IS_UPDATING_COLLABORATOR, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Directory/Collaborator/UpdateCollaborator",
          collaborator
        )
        .then((res) => {
          rootGetters.collaboratorsList.splice(
            rootGetters.collaboratorsList.findIndex(
              (elem) => elem.id == collaborator.id
            ),
            1,
            collaborator
          );
          commit(
            CollaboratorMutations.SET_COLLABORATORS_LIST,
            rootGetters.collaboratorsList
          );
          commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, res);
          commit(CollaboratorMutations.SET_IS_UPDATING_COLLABORATOR, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, err);
          commit(CollaboratorMutations.SET_IS_UPDATING_COLLABORATOR, false);
          reject(err);
        });
    });
  },

  async updateCollaboratorTagCollaborator(
    { commit },
    {
      collaboratorIds = [],
      collaboratorTagIds = [],
    }: { collaboratorIds: number[]; collaboratorTagIds: number[] }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Directory/Collaborator/UpdateCollaboratorTagCollaborator`,
          {
            collaboratorIds: collaboratorIds,
            collaboratorTagIds: collaboratorTagIds,
          }
        )
        .then((res) => {
          commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, err);
          reject(err);
        });
    });
  },

  // updateCollaboratorCustomPerimeter

  async restoreCollaborators(
    { commit, rootGetters },
    { collaboratorIds = [] }: { collaboratorIds: number[] }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(CollaboratorMutations.SET_IS_UPDATING_COLLABORATOR, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Directory/Collaborator/Restore",
          collaboratorIds
        )
        .then((res) => {
          collaboratorIds.forEach((collaboratorId) => {
            rootGetters.collaboratorsListArchived.splice(
              rootGetters.collaboratorsListArchived.findIndex(
                (collaborator) => collaborator.id == collaboratorId
              ),
              1
            );
          });
          commit(
            CollaboratorMutations.SET_COLLABORATORS_LIST_ARCHIVED,
            rootGetters.collaboratorsListArchived
          );

          commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, res);
          commit(CollaboratorMutations.SET_IS_UPDATING_COLLABORATOR, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, err);
          commit(CollaboratorMutations.SET_IS_UPDATING_COLLABORATOR, false);
          reject(err);
        });
    });
  },

  async archiveCollaborators(
    { commit, rootGetters },
    { collaboratorIds = [] }: { collaboratorIds: number[] }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(CollaboratorMutations.SET_IS_ARCHIVING_COLLABORATOR, true);
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Directory/Collaborator/Archive",
          {
            data: collaboratorIds,
          }
        )
        .then((res) => {
          collaboratorIds.forEach((collaboratorId) => {
            rootGetters.collaboratorsList.splice(
              rootGetters.collaboratorsList.findIndex(
                (collaborator) => collaborator.id == collaboratorId
              ),
              1
            );
          });
          commit(
            CollaboratorMutations.SET_COLLABORATORS_LIST,
            rootGetters.collaboratorsList
          );
          commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, res);
          commit(CollaboratorMutations.SET_IS_ARCHIVING_COLLABORATOR, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, err);
          commit(CollaboratorMutations.SET_IS_ARCHIVING_COLLABORATOR, false);
          reject(err);
        });
    });
  },

  async deleteCollaborators(
    { commit, rootGetters },
    { collaboratorIds = [] }: { collaboratorIds: number[] }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(CollaboratorMutations.SET_IS_DELETING_COLLABORATOR, true);
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Directory/Collaborator/Delete",
          {
            data: collaboratorIds,
          }
        )
        .then((res) => {
          collaboratorIds.forEach((collaboratorId) => {
            rootGetters.collaboratorsListArchived.splice(
              rootGetters.collaboratorsListArchived.findIndex(
                (collaborator) => collaborator.id == collaboratorId
              ),
              1
            );
          });
          commit(
            CollaboratorMutations.SET_COLLABORATORS_LIST_ARCHIVED,
            rootGetters.collaboratorsListArchived
          );
          commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, res);
          commit(CollaboratorMutations.SET_IS_DELETING_COLLABORATOR, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CollaboratorMutations.SET_REQUEST_STATE_COLLABORATOR, err);
          commit(CollaboratorMutations.SET_IS_DELETING_COLLABORATOR, false);
          reject(err);
        });
    });
  },
};
