import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { CollaboratorRequestDto } from "@/../Api";
import RootState from "@/store/modules/rootState";
import { ICollaboratorState } from "./states";
import { Module } from "vuex";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

export const state: ICollaboratorState = {
  collaboratorsList: Array<CollaboratorRequestDto>(),
  collaboratorsListArchived: Array<CollaboratorRequestDto>(),
  collaborator: <CollaboratorRequestDto>{},
  userCollaboratorId: null,
  userCollaborator: <any>{},
  isLoadingCollaboratorsList: false,
  isLoadingCollaboratorsListArchived: false,
  isLoadingUserCollaboratorId: false,
  isLoadingCollaborator: false,
  isCreatingCollaborator: false,
  isUpdatingCollaborator: false,
  isDeletingCollaborator: false,
  isArchivingCollaborator: false,
  requestStateCollaborator: <IRequestState>{},
};

export const CollaboratorModule: Module<ICollaboratorState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
