import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IInstitutionState } from "./states";
import { InstitutionRequestDto } from "../../../../Api";

export const getters: GetterTree<IInstitutionState, RootState> = {
  institutionsList: (state): InstitutionRequestDto[] => state.institutionsList,
  institutionsListArchived: (state): InstitutionRequestDto[] =>
    state.institutionsListArchived,
  institutionsListMinified: (state): InstitutionRequestDto[] =>
    state.institutionsListMinified,
  institution: (state): InstitutionRequestDto => state.institution,
  institutionSelected: (state): InstitutionRequestDto =>
    state.institutionSelected,

  // COMMON
  isLoadingInstitutionsList: (state): boolean =>
    state.isLoadingInstitutionsList,
  isLoadingInstitutionsListArchived: (state): boolean =>
    state.isLoadingInstitutionsListArchived,
  isLoadingInstitutionsListMinified: (state): boolean =>
    state.isLoadingInstitutionsListMinified,
  isLoadingInstitution: (state): boolean => state.isLoadingInstitution,
  isCreatingInstitution: (state): boolean => state.isCreatingInstitution,
  isUpdatingInstitution: (state): boolean => state.isUpdatingInstitution,
  isDeletingInstitution: (state): boolean => state.isDeletingInstitution,
  isArchivingInstitution: (state): boolean => state.isArchivingInstitution,
  requestStateInstitution: (state): IRequestState =>
    state.requestStateInstitution,
};
