import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { ICompanyTypeState } from "./states";

export const getters: GetterTree<ICompanyTypeState, RootState> = {
  companiesTypesList: (state): any[] =>
    state.companiesTypesList,
  companyType: (state): any => state.companyType,

  // COMMON
  isLoadingCompanyTypeList: (state): boolean => state.isLoadingCompanyTypeList,
  isLoadingCompanyType: (state): boolean => state.isLoadingCompanyType,
  isCreatingCompanyType: (state): boolean => state.isCreatingCompanyType,
  isUpdatingCompanyType: (state): boolean => state.isUpdatingCompanyType,
  isDeletingCompanyType: (state): boolean => state.isDeletingCompanyType,
  requestStateCompanyType: (state): IRequestState =>
    state.requestStateCompanyType,
};
