import { IOrderFormCustomerDetailState } from "./states";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { OrderFormCustomerDetailRequestDto } from "Api";
import { OrderFormCustomerDetailMutations } from "./mutations";
import {
  getNewIndex,
  calculateTotalDocumentLine,
} from "@/types/api-orisis/library/DetailDocumentOperations";
import { calculateSubTotalLines } from "@/types/api-orisis/library/DocumentOperations";
import { CommonLine } from "@/types/api-orisis/models/LineModels";
import { formatNumber } from "@/types/api-orisis/library/FormatOperations";
import { OrderFormCustomerDetailIndexType } from "@/types/api-orisis/interfaces/Billing/orderFormCustomer/IOrderFormCustomer";

export const actions: ActionTree<IOrderFormCustomerDetailState, RootState> = {
  getOrderFormCustomerDetailsByOrderFormCustomerId(
    { commit },
    {
      orderFormCustomerId,
      updateState = true,
      notStored = false,
    }: { orderFormCustomerId: number; updateState: boolean; notStored: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          OrderFormCustomerDetailMutations.SET_ORDER_FORM_CUSTOMER_DETAILS,
          []
        );
      }
      if (notStored) {
        axios
          .get(
            `${process.env.VUE_APP_API_ORISIS}Sale/OrderFormCustomerDetail/GetOrderFormCustomerDetailsByOrderFormCustomerId`,
            {
              params: {
                orderFormCustomerId: orderFormCustomerId,
              },
            }
          )
          .then((res) => {
            resolve(
              res.data
                .filter(
                  (el) =>
                    el.type != 0 &&
                    el.type != 1 &&
                    el.type != 2 &&
                    el.type != 11 &&
                    el.type != 12 &&
                    el.type != 13 &&
                    el.type != 18 &&
                    el.type != 17 &&
                    el.type != 16 &&
                    el.type != 15 &&
                    el.type != 14 &&
                    !el.isOption
                )
                .map((el) => {
                  return {
                    ...el,
                    referencielTvaId: el.referencielTva
                      ? el.referencielTva.id
                      : 5,
                    unitId: el.unit ? el.unit.id : 1,
                    total: el.quantity ? calculateTotalDocumentLine(el) : 0,
                    orderFormCustomerId: orderFormCustomerId,
                    titre:
                      el.type == 0 || el.type == 11 ? el.description : null,
                  };
                })
            );
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        axios
          .get(
            `${process.env.VUE_APP_API_ORISIS}Sale/OrderFormCustomerDetail/GetOrderFormCustomerDetailsByOrderFormCustomerId`,
            {
              params: {
                orderFormCustomerId: orderFormCustomerId,
              },
            }
          )
          .then((res) => {
            if (updateState) {
              commit(
                OrderFormCustomerDetailMutations.SET_ORDER_FORM_CUSTOMER_DETAILS,
                res.data.map((el: any) => {
                  return {
                    ...el,
                    referencielTvaId: el.referencielTva
                      ? el.referencielTva.id
                      : 5,
                    unitId: el.unit ? el.unit.id : 1,
                    total: el.quantity ? calculateTotalDocumentLine(el) : 0,
                    orderFormCustomerId: orderFormCustomerId,
                    titre:
                      el.type == 0 || el.type == 11 ? el.description : null,
                  };
                })
              );
            }
            commit(
              OrderFormCustomerDetailMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DETAIL,
              res
            );
            resolve(
              res.data.map((el: any) => {
                return {
                  ...el,
                  referencielTvaId: el.referencielTva
                    ? el.referencielTva.id
                    : 5,
                  unitId: el.unit ? el.unit.id : 1,
                  total: el.quantity ? calculateTotalDocumentLine(el) : 0,
                  orderFormCustomerId: orderFormCustomerId,
                  titre: el.type == 0 || el.type == 11 ? el.description : null,
                };
              })
            );
          })
          .catch((err) => {
            commit(
              OrderFormCustomerDetailMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DETAIL,
              err
            );
            reject(err);
          });
      }
    });
  },

  //getOrderFormCustomerDetailById(){}

  createOrderFormCustomerDetail(
    { commit, rootGetters },
    {
      orderFormCustomerDetail,
      position = null,
    }: {
      orderFormCustomerDetail: OrderFormCustomerDetailRequestDto;
      position: number;
    }
  ) {
    return new Promise((resolve, reject) => {
      commit(
        OrderFormCustomerDetailMutations.SET_IS_CREATING_ORDER_FORM_CUSTOMER_DETAIL,
        true
      );
      if (position) {
        axios
          .post(
            process.env.VUE_APP_API_ORISIS +
              "Sale/OrderFormCustomerDetail/CreateOrderFormCustomerDetail",
            orderFormCustomerDetail
          )
          .then((res) => {
            let newObject = {
              ...orderFormCustomerDetail,
              id: res.data.data.id,
            };
            rootGetters.getOrderFormCustomerDetails.splice(
              position,
              0,
              newObject
            );
            commit(
              OrderFormCustomerDetailMutations.SET_ORDER_FORM_CUSTOMER_DETAILS,
              rootGetters.getOrderFormCustomerDetails
            );
            commit(
              OrderFormCustomerDetailMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DETAIL,
              res
            );
            commit(
              OrderFormCustomerDetailMutations.SET_IS_CREATING_ORDER_FORM_CUSTOMER_DETAIL,
              false
            );
            resolve(newObject);
          })
          .catch((err) => {
            console.error(err);
            commit(
              OrderFormCustomerDetailMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DETAIL,
              err
            );
            commit(
              OrderFormCustomerDetailMutations.SET_IS_CREATING_ORDER_FORM_CUSTOMER_DETAIL,
              false
            );
            reject(err);
          });
      } else {
        axios
          .post(
            process.env.VUE_APP_API_ORISIS +
              "Sale/OrderFormCustomerDetail/CreateOrderFormCustomerDetail",
            orderFormCustomerDetail
          )
          .then((res) => {
            let newObject = {
              ...orderFormCustomerDetail,
              id: res.data.data.id,
            };
            rootGetters.getOrderFormCustomerDetails.push(newObject);
            commit(
              OrderFormCustomerDetailMutations.SET_ORDER_FORM_CUSTOMER_DETAILS,
              rootGetters.getOrderFormCustomerDetails
            );
            commit(
              OrderFormCustomerDetailMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DETAIL,
              res
            );
            commit(
              OrderFormCustomerDetailMutations.SET_IS_CREATING_ORDER_FORM_CUSTOMER_DETAIL,
              false
            );
            resolve(newObject);
          })
          .catch((err) => {
            console.error(err);
            commit(
              OrderFormCustomerDetailMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DETAIL,
              err
            );
            commit(
              OrderFormCustomerDetailMutations.SET_IS_CREATING_ORDER_FORM_CUSTOMER_DETAIL,
              false
            );
            reject(err);
          });
      }
    });
  },

  updateOrderFormCustomerDetail(
    { commit, rootGetters },
    {
      orderFormCustomerDetail,
    }: { orderFormCustomerDetail: OrderFormCustomerDetailRequestDto }
  ) {
    return new Promise((resolve, reject) => {
      commit(
        OrderFormCustomerDetailMutations.SET_IS_UPDATING_ORDER_FORM_CUSTOMER_DETAIL,
        true
      );
      axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Sale/OrderFormCustomerDetail/UpdateOrderFormCustomerDetail",
          orderFormCustomerDetail
        )
        .then((res) => {
          rootGetters.getOrderFormCustomerDetails.splice(
            rootGetters.getOrderFormCustomerDetails.findIndex(
              (elem) => elem.id == orderFormCustomerDetail.id
            ),
            1,
            orderFormCustomerDetail
          );
          commit(
            OrderFormCustomerDetailMutations.SET_ORDER_FORM_CUSTOMER_DETAILS,
            rootGetters.getOrderFormCustomerDetails
          );
          commit(
            OrderFormCustomerDetailMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DETAIL,
            res
          );
          commit(
            OrderFormCustomerDetailMutations.SET_IS_UPDATING_ORDER_FORM_CUSTOMER_DETAIL,
            false
          );
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          commit(
            OrderFormCustomerDetailMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DETAIL,
            err
          );
          commit(
            OrderFormCustomerDetailMutations.SET_IS_UPDATING_ORDER_FORM_CUSTOMER_DETAIL,
            false
          );
          reject(err);
        });
    });
  },

  duplicateOrderFormCustomerDetail(
    { commit, rootGetters },
    { orderFormCustomerDetailId }: { orderFormCustomerDetailId: number }
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            "Sale/OrderFormCustomerDetail/DuplicateOrderFormCustomerDetails",
          {
            params: {
              orderFormCustomerDetailId: orderFormCustomerDetailId,
            },
          }
        )
        .then((res) => {
          let result = res.data.data.map((el) => {
            return {
              ...el,
              referencielTvaId: el.referencielTva ? el.referencielTva.id : 5,
              unitId: el.unit ? el.unit.id : 1,
              total: el.quantity ? calculateTotalDocumentLine(el) : 0,
              orderFormCustomerId: rootGetters.orderFormCustomer.id,
              titre: el.type == 0 || el.type == 11 ? el.description : null,
            };
          });
          commit(
            OrderFormCustomerDetailMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DETAIL,
            res
          );
          resolve(result);
        })
        .catch((err) => {
          console.error(err);
          commit(
            OrderFormCustomerDetailMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DETAIL,
            err
          );
          reject(err);
        });
    });
  },

  updateTvaOrderFormCustomerDetail(
    { commit },
    {
      orderFormCustomerDetailIds,
      referencielTvaId,
    }: { orderFormCustomerDetailIds: Array<number>; referencielTvaId: number }
  ) {
    return new Promise((resolve, reject) => {
      commit(
        OrderFormCustomerDetailMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_DETAILS,
        true
      );
      axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Sale/OrderFormCustomerDetail/UpdateTVAOrderFormCustomerDetail",
          orderFormCustomerDetailIds,
          {
            params: {
              tvaId: referencielTvaId,
            },
          }
        )
        .then((res) => {
          commit(
            OrderFormCustomerDetailMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DETAIL,
            res
          );
          commit(
            OrderFormCustomerDetailMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_DETAILS,
            false
          );
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          commit(
            OrderFormCustomerDetailMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DETAIL,
            err
          );
          commit(
            OrderFormCustomerDetailMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_DETAILS,
            false
          );
          reject(err);
        });
    });
  },

  updateOrderFormCustomerDetailIndex(
    { commit },
    {
      orderFormCustomerDetailIndex,
    }: { orderFormCustomerDetailIndex: OrderFormCustomerDetailIndexType }
  ) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Sale/OrderFormCustomerDetail/UpdateOrderFormCustomerDetailIndex",
          orderFormCustomerDetailIndex
        )
        .then((res) => {
          commit(
            OrderFormCustomerDetailMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DETAIL,
            res
          );
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          commit(
            OrderFormCustomerDetailMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DETAIL,
            err
          );
          reject(err);
        });
    });
  },

  deleteOrderFormCustomerDetails(
    { commit, dispatch, rootGetters },
    {
      orderFormCustomerDetailIds = [],
      orderFormCustomerDetail,
      reviewIndex,
    }: {
      orderFormCustomerDetailIds: Array<number>;
      orderFormCustomerDetail: OrderFormCustomerDetailRequestDto;
      reviewIndex: boolean;
    }
  ) {
    commit(
      OrderFormCustomerDetailMutations.SET_IS_DELETING_ORDER_FORM_CUSTOMER_DETAIL,
      true
    );
    return new Promise((resolve, reject) => {
      axios
        .delete(
          process.env.VUE_APP_API_ORISIS +
            "Sale/OrderFormCustomerDetail/Delete",
          {
            data: orderFormCustomerDetailIds,
          }
        )
        .then((res) => {
          orderFormCustomerDetailIds.forEach((element) => {
            rootGetters.getOrderFormCustomerDetails.splice(
              rootGetters.getOrderFormCustomerDetails.findIndex(
                (elem) => elem.id == element
              ),
              1
            );
          });

          // On traite les lignes de composant d'un ouvrage
          if (
            orderFormCustomerDetail.type == 14 ||
            orderFormCustomerDetail.type == 15 ||
            orderFormCustomerDetail.type == 16 ||
            orderFormCustomerDetail.type == 17 ||
            orderFormCustomerDetail.type == 18
          ) {
            let workLine = rootGetters.getOrderFormCustomerDetails.find(
              (el) => el.id == orderFormCustomerDetail.parentId
            );
            let workLineElements =
              rootGetters.getOrderFormCustomerDetails.filter(
                (el) => el.parentId == orderFormCustomerDetail.parentId
              );
            let totalPriceHt = 0;
            let totalDryDisbursed = 0;
            for (let index = 0; index < workLineElements.length; index++) {
              const element = workLineElements[index];
              totalPriceHt += formatNumber(
                element.unitPriceHt * element.quantity
              );
              totalDryDisbursed += formatNumber(
                element.disbursedNetHt * element.quantity
              );
            }
            workLine.unitPriceHt = formatNumber(totalPriceHt);
            workLine.disbursedNetHt = formatNumber(totalDryDisbursed);
            workLine.marginBrutHt = formatNumber(
              workLine.unitPriceHt - workLine.disbursedNetHt
            );
            workLine.marginRate = formatNumber(
              workLine.unitPriceHt == 0
                ? 0
                : (workLine.marginBrutHt / workLine.unitPriceHt) * 100
            );

            // On met à jour le total
            workLine.total = formatNumber(
              workLine.unitPriceHt * workLine.quantity
            );
          }

          if (reviewIndex) {
            dispatch("reviewIndexOrderFormCustomer");
          }
          commit(
            OrderFormCustomerDetailMutations.SET_ORDER_FORM_CUSTOMER_DETAIL,
            rootGetters.getOrderFormCustomerDetails
          );
          commit(
            OrderFormCustomerDetailMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DETAIL,
            res
          );
          commit(
            OrderFormCustomerDetailMutations.SET_IS_DELETING_ORDER_FORM_CUSTOMER_DETAIL,
            false
          );
          resolve(res);
        })
        .catch((err) => {
          console.error(err);
          commit(
            OrderFormCustomerDetailMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DETAIL,
            err
          );
          commit(
            OrderFormCustomerDetailMutations.SET_IS_DELETING_ORDER_FORM_CUSTOMER_DETAIL,
            false
          );
          reject(err);
        });
    });
  },

  async duplicateOrderFormCustomerLine(
    { dispatch, rootGetters },
    {
      orderFormCustomer,
      index,
      position,
    }: {
      orderFormCustomer: OrderFormCustomerDetailRequestDto;
      index: number;
      position: string;
    }
  ) {
    let line = JSON.parse(JSON.stringify(orderFormCustomer));
    JSON.parse(JSON.stringify(index));
    line.id = 0;
    switch (position) {
      case "before":
        await dispatch("createOrderFormCustomerDetail", {
          orderFormCustomerDetail: { ...line, isDisabled: false },
          position: index,
        });
        await dispatch("reviewIndexOrderFormCustomer", 1);
        break;
      case "after":
        await dispatch("createOrderFormCustomerDetail", {
          orderFormCustomerDetail: { ...line, isDisabled: false },
          position: index + 1,
        });
        await dispatch("reviewIndexOrderFormCustomer", 1);
        break;
      case "beginning":
        await dispatch("createOrderFormCustomerDetail", {
          orderFormCustomerDetail: { ...line, isDisabled: false },
          position: 0,
        });
        await dispatch("reviewIndexOrderFormCustomer", 1);
        break;
      case "end":
        await dispatch("createOrderFormCustomerDetail", {
          orderFormCustomerDetail: { ...line, isDisabled: false },
          position: rootGetters.getOrderFormCustomerDetails.length,
        });
        await dispatch("reviewIndexOrderFormCustomer", 1);
        break;
      default:
        await dispatch("createOrderFormCustomerDetail", {
          orderFormCustomerDetail: { ...line, isDisabled: false },
          position: index + 1,
        });
        await dispatch("reviewIndexOrderFormCustomer", 1);
        break;
    }
  },

  async duplicateOrderFormCustomerWorkLine(
    { dispatch, rootGetters },
    {
      orderFormCustomerDetailId,
      workLineElements,
      index,
      position,
    }: {
      orderFormCustomerDetailId: Number;
      workLineElements: any[];
      index: number;
      position: string;
    }
  ) {
    let workLineLength = workLineElements.length + 1;
    switch (position) {
      case "before":
        await dispatch("duplicateOrderFormCustomerDetail", {
          orderFormCustomerDetailId: orderFormCustomerDetailId,
        }).then(async (res: any) => {
          for (let i = 0; i < res.length; i++) {
            const orderFormCustomerDetail = res[i];
            rootGetters.getOrderFormCustomerDetails.splice(
              index + i,
              0,
              orderFormCustomerDetail
            );
          }
        });
        await dispatch("reviewIndexOrderFormCustomer");
        break;
      case "after":
        await dispatch("duplicateOrderFormCustomerDetail", {
          orderFormCustomerDetailId: orderFormCustomerDetailId,
        }).then(async (res: any) => {
          for (let i = 0; i < res.length; i++) {
            const orderFormCustomerDetail = res[i];
            rootGetters.getOrderFormCustomerDetails.splice(
              index + workLineLength + i,
              0,
              orderFormCustomerDetail
            );
          }
        });
        await dispatch("reviewIndexOrderFormCustomer");
        break;
      case "beginning":
        await dispatch("duplicateOrderFormCustomerDetail", {
          orderFormCustomerDetailId: orderFormCustomerDetailId,
        }).then(async (res: any) => {
          for (let i = 0; i < res.length; i++) {
            const orderFormCustomerDetail = res[i];
            rootGetters.getOrderFormCustomerDetails.splice(
              0 + i,
              0,
              orderFormCustomerDetail
            );
          }
        });
        await dispatch("reviewIndexOrderFormCustomer");
        break;
      case "end":
        await dispatch("duplicateOrderFormCustomerDetail", {
          orderFormCustomerDetailId: orderFormCustomerDetailId,
        }).then(async (res: any) => {
          for (let i = 0; i < res.length; i++) {
            const orderFormCustomerDetail = res[i];
            rootGetters.getOrderFormCustomerDetails.splice(
              rootGetters.getOrderFormCustomerDetails.length + i,
              0,
              orderFormCustomerDetail
            );
          }
        });
        await dispatch("reviewIndexOrderFormCustomer");
        break;
      default:
        await dispatch("duplicateOrderFormCustomerDetail", {
          orderFormCustomerDetailId: orderFormCustomerDetailId,
        }).then(async (res: any) => {
          for (let i = 0; i < res.length; i++) {
            const orderFormCustomerDetail = res[i];
            rootGetters.getOrderFormCustomerDetails.splice(
              index + workLineLength + i,
              0,
              orderFormCustomerDetail
            );
          }
        });
        await dispatch("reviewIndexOrderFormCustomer");
        break;
    }
  },

  loopOrderFormCustomerDetails(
    { dispatch, rootGetters },
    { referencielTvaId }: { referencielTvaId: boolean }
  ) {
    return new Promise((resolve) => {
      let orderFormCustomerDetailIds: Array<number> = [];
      for (let i = 0; i < rootGetters.getOrderFormCustomerDetails.length; i++) {
        const line = rootGetters.getOrderFormCustomerDetails[i];
        line.referencielTvaId = referencielTvaId;
        orderFormCustomerDetailIds.push(line.id);
      }
      dispatch("updateTvaOrderFormCustomerDetail", {
        orderFormCustomerDetailIds: orderFormCustomerDetailIds,
        referencielTvaId: referencielTvaId,
      }).then((res: any) => {
        resolve(res);
      });
    });
  },

  async reviewIndexOrderFormCustomer({ dispatch, commit, rootGetters }) {
    commit(
      OrderFormCustomerDetailMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_DETAILS,
      true
    );
    let orderFormCustomerDetailIndex = [];
    for (let i = 0; i < rootGetters.getOrderFormCustomerDetails.length; i++) {
      const line = rootGetters.getOrderFormCustomerDetails[i];
      await getNewIndex(rootGetters.getOrderFormCustomerDetails, {
        ...line,
        indexArray: i - 1,
      }).then(async (res: any) => {
        line.index = res.index;
        orderFormCustomerDetailIndex.push({
          id: line.id,
          index: res.index,
        });
      });
    }
    calculateSubTotalLines(true);
    commit(
      OrderFormCustomerDetailMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_DETAILS,
      false
    );
    dispatch("updateOrderFormCustomerDetailIndex", {
      orderFormCustomerDetailIndex: orderFormCustomerDetailIndex,
    });
  },

  updateCreditDetailIndex(
    { commit },
    { creditDetailIndex }: { creditDetailIndex: number }
  ) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Sale/CreditDetail/UpdateCreditDetailIndex",
          creditDetailIndex
        )
        .then((res) => {
          commit(
            OrderFormCustomerDetailMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DETAIL,
            res
          );
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          commit(
            OrderFormCustomerDetailMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DETAIL,
            err
          );
          reject(err);
        });
    });
  },

  addOrderFormCustomerLine(
    { dispatch, rootGetters },
    {
      review,
      orderFormCustomerDetail,
      indexArray,
    }: {
      review: boolean;
      orderFormCustomerDetail: OrderFormCustomerDetailRequestDto;
      indexArray: string;
    }
  ) {
    return new Promise(async (resolve, reject) => {
      if (review) {
        let newObject = CommonLine(orderFormCustomerDetail);
        await dispatch("createOrderFormCustomerDetail", {
          orderFormCustomerDetail: newObject,
          position: indexArray,
        });
        await dispatch("reviewIndexOrderFormCustomer");
        resolve(true);
      } else {
        getNewIndex(
          rootGetters.getOrderFormCustomerDetails,
          orderFormCustomerDetail
        )
          .then((res: any) => {
            let newObject = CommonLine({
              ...orderFormCustomerDetail,
              index: res.index,
              type: res.type,
            });
            dispatch("createOrderFormCustomerDetail", {
              orderFormCustomerDetail: newObject,
            });
            resolve(true);
          })
          .catch((err) => reject(err));
      }
    });
  },
};
