import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { AffairCategoryRequestDto } from "@/../Api";
import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IAffairCategoryState } from "./states";

export const getters: GetterTree<IAffairCategoryState, RootState> = {
  affairCategoriesList: (state): AffairCategoryRequestDto[] =>
    state.affairCategoriesList,
  affairCategoriesTreeList: (state): any[] => state.affairCategoriesTreeList,
  affairCategoriesTreeListArchived: (state): any[] =>
    state.affairCategoriesTreeListArchived,
  affairCategoriesListArchived: (state): AffairCategoryRequestDto[] =>
    state.affairCategoriesListArchived,
  affairCategory: (state): AffairCategoryRequestDto => state.affairCategory,

  // COMMON
  isLoadingAffairCategoriesList: (state): boolean =>
    state.isLoadingAffairCategoriesList,
  isLoadingAffairCategoriesTreeList: (state): boolean =>
    state.isLoadingAffairCategoriesTreeList,
  isLoadingAffairCategoriesTreeListArchived: (state): boolean =>
    state.isLoadingAffairCategoriesTreeListArchived,
  isLoadingAffairCategoriesListArchived: (state): boolean =>
    state.isLoadingAffairCategoriesListArchived,
  isLoadingAffairCategory: (state): boolean => state.isLoadingAffairCategory,
  isCreatingAffairCategory: (state): boolean => state.isCreatingAffairCategory,
  isUpdatingAffairCategory: (state): boolean => state.isUpdatingAffairCategory,
  isDeletingAffairCategory: (state): boolean => state.isDeletingAffairCategory,
  isArchivingAffairCategory: (state): boolean =>
    state.isArchivingAffairCategory,
  requestStateAffairCategory: (state): IRequestState =>
    state.requestStateAffairCategory,
};
