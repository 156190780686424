import { BankAccountRequestDto } from "Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { BankAccountMutations } from "./mutations";
import { IBankAccountState } from "./states";

export const actions: ActionTree<IBankAccountState, RootState> = {
  async getBankAccounts({ commit }, { updateState = true }): Promise<void> {
    if (updateState) {
      commit(BankAccountMutations.SET_IS_LOADING_BANK_ACCOUNT, true);
    }
    await axios
      .get(process.env.VUE_APP_API_ORISIS + "Sale/BankAccount/GetBankAccounts")
      .then((res) => {
        if (updateState) {
          commit(BankAccountMutations.SET_BANK_ACCOUNTS_LIST, res.data);
          commit(BankAccountMutations.SET_BANK_ACCOUNTS_LIST_FORM, res.data);
          commit(BankAccountMutations.SET_IS_LOADING_BANK_ACCOUNT, false);
        }
        commit(BankAccountMutations.SET_REQUEST_STATE_BANK_ACCOUNT, res);
      })
      .catch((err) => {
        console.error(err);
        commit(BankAccountMutations.SET_REQUEST_STATE_BANK_ACCOUNT, err);
        commit(BankAccountMutations.SET_IS_LOADING_BANK_ACCOUNT, false);
      });
  },

  async getBankAccountById(
    { commit },
    {
      bankAccountId,
      updateState = true,
    }: { bankAccountId: number; updateState: boolean }
  ): Promise<void> {
    if (updateState) {
      commit(BankAccountMutations.SET_IS_LOADING_BANK_ACCOUNT, true);
    }
    axios
      .get(
        process.env.VUE_APP_API_ORISIS + "Sale/BankAccount/GetBankAccountById",
        {
          params: {
            bankAccountId: bankAccountId,
          },
        }
      )
      .then((res) => {
        if (updateState) {
          commit(BankAccountMutations.SET_BANK_ACCOUNT, res.data);
          commit(BankAccountMutations.SET_IS_LOADING_BANK_ACCOUNT, false);
        }
        commit(BankAccountMutations.SET_REQUEST_STATE_BANK_ACCOUNT, res);
      })
      .catch((err) => {
        console.error(err);
        commit(BankAccountMutations.SET_REQUEST_STATE_BANK_ACCOUNT, err);
        commit(BankAccountMutations.SET_IS_LOADING_BANK_ACCOUNT, false);
      });
  },

  async createBankAccount(
    { commit, rootGetters },
    { bankAccount }: { bankAccount: BankAccountRequestDto }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(BankAccountMutations.SET_IS_CREATING_BANK_ACCOUNT, true);
      await axios
        .post(
          process.env.VUE_APP_API_ORISIS + "Sale/BankAccount/CreateBankAccount",
          bankAccount
        )
        .then((res) => {
          rootGetters.bankAccountsList.push(res.data.data);
          commit(
            BankAccountMutations.SET_BANK_ACCOUNTS_LIST,
            rootGetters.bankAccountsList
          );
          commit(BankAccountMutations.SET_REQUEST_STATE_BANK_ACCOUNT, res);
          commit(BankAccountMutations.SET_IS_CREATING_BANK_ACCOUNT, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(BankAccountMutations.SET_REQUEST_STATE_BANK_ACCOUNT, err);
          commit(BankAccountMutations.SET_IS_CREATING_BANK_ACCOUNT, false);
          reject(err);
        });
    });
  },

  async updateBankAccount(
    { commit, rootGetters },
    { bankAccount }: { bankAccount: BankAccountRequestDto }
  ) {
    return new Promise((resolve, reject) => {
      commit(BankAccountMutations.SET_IS_UPDATING_BANK_ACCOUNT, true);
      axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Sale/BankAccount/UpdateBankAccount",
          bankAccount
        )
        .then((res) => {
          rootGetters.bankAccountsList.splice(
            rootGetters.bankAccountsList.findIndex(
              (elem) => elem.id == bankAccount.id
            ),
            1,
            bankAccount
          );
          commit(
            BankAccountMutations.SET_BANK_ACCOUNTS_LIST,
            rootGetters.bankAccountsList
          );
          commit(BankAccountMutations.SET_REQUEST_STATE_BANK_ACCOUNT, res);
          commit(BankAccountMutations.SET_IS_UPDATING_BANK_ACCOUNT, false);
          resolve(bankAccount);
        })
        .catch((err) => {
          console.error(err);
          commit(BankAccountMutations.SET_REQUEST_STATE_BANK_ACCOUNT, err);
          commit(BankAccountMutations.SET_IS_UPDATING_BANK_ACCOUNT, false);
          reject(err);
        });
    });
  },

  async uploadRib(
    { commit },
    { bankAccountId, file }: { bankAccountId: number; file: any }
  ) {
    return new Promise((resolve, reject) => {
      commit(BankAccountMutations.SET_IS_UPDATING_BANK_ACCOUNT, true);
      axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Sale/BankAccount/UplaodRib",
          file,
          {
            params: {
              bankAccountId: bankAccountId,
            },
          }
        )
        .then((res) => {
          commit(BankAccountMutations.SET_REQUEST_STATE_BANK_ACCOUNT, res);
          commit(BankAccountMutations.SET_IS_UPDATING_BANK_ACCOUNT, false);
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          commit(BankAccountMutations.SET_REQUEST_STATE_BANK_ACCOUNT, err);
          commit(BankAccountMutations.SET_IS_UPDATING_BANK_ACCOUNT, false);
          reject(err);
        });
    });
  },

  async restoreBankAccounts(
    { commit, rootGetters },
    { bankAccountIds = [] }: { bankAccountIds: number[] }
  ): Promise<void> {
    commit(BankAccountMutations.SET_IS_UPDATING_BANK_ACCOUNT, true);
    await axios
      .put(process.env.VUE_APP_API_ORISIS + "Sale/BankAccount/Restore", {
        data: bankAccountIds,
      })
      .then((res) => {
        bankAccountIds.forEach((bankAccountId) => {
          rootGetters.bankAccountsListArchived.splice(
            rootGetters.bankAccountsListArchived.findIndex(
              (elem) => elem.id == bankAccountId
            ),
            1
          );
        });
        commit(
          BankAccountMutations.SET_BANK_ACCOUNTS_LIST_ARCHIVED,
          rootGetters.bankAccountsListArchived
        );
        commit(BankAccountMutations.SET_REQUEST_STATE_BANK_ACCOUNT, res);
        commit(BankAccountMutations.SET_IS_UPDATING_BANK_ACCOUNT, false);
      })
      .catch((err) => {
        console.error(err);
        commit(BankAccountMutations.SET_REQUEST_STATE_BANK_ACCOUNT, err);
        commit(BankAccountMutations.SET_IS_UPDATING_BANK_ACCOUNT, false);
      });
  },

  async archiveBankAccounts(
    { commit, rootGetters },
    bankAccountIds: number[]
  ): Promise<void> {
    commit(BankAccountMutations.SET_IS_ARCHIVING_BANK_ACCOUNT, true);
    await axios
      .delete(process.env.VUE_APP_API_ORISIS + "Sale/BankAccount/Archive", {
        data: bankAccountIds,
      })
      .then((res) => {
        bankAccountIds.forEach((bankAccountId) => {
          rootGetters.bankAccountsListForm.splice(
            rootGetters.bankAccountsListForm.findIndex(
              (elem) => elem.id == bankAccountId
            ),
            1
          );
          rootGetters.bankAccountsList.splice(
            rootGetters.bankAccountsList.findIndex(
              (elem) => elem.id == bankAccountId
            ),
            1
          );
        });
        commit(
          BankAccountMutations.SET_BANK_ACCOUNTS_LIST,
          rootGetters.bankAccountsList
        );
        commit(
          BankAccountMutations.SET_BANK_ACCOUNTS_LIST_FORM,
          rootGetters.bankAccountsListForm
        );
        commit(BankAccountMutations.SET_REQUEST_STATE_BANK_ACCOUNT, res);
        commit(BankAccountMutations.SET_IS_ARCHIVING_BANK_ACCOUNT, false);
      })
      .catch((err) => {
        console.error(err);
        commit(BankAccountMutations.SET_REQUEST_STATE_BANK_ACCOUNT, err);
        commit(BankAccountMutations.SET_IS_ARCHIVING_BANK_ACCOUNT, false);
      });
  },

  async deleteBankAccounts(
    { commit, rootGetters },
    { bankAccountIds = [] }: { bankAccountIds: number[] }
  ): Promise<void> {
    commit(BankAccountMutations.SET_IS_DELETING_BANK_ACCOUNT, true);
    await axios
      .delete(process.env.VUE_APP_API_ORISIS + "Sale/BankAccount/Delete", {
        data: bankAccountIds,
      })
      .then((res) => {
        bankAccountIds.forEach((bankAccountId) => {
          rootGetters.bankAccountsListArchived.splice(
            rootGetters.bankAccountsListArchived.findIndex(
              (elem) => elem.id == bankAccountId
            ),
            1
          );
        });
        commit(
          BankAccountMutations.SET_BANK_ACCOUNTS_LIST_ARCHIVED,
          rootGetters.bankAccountsListArchived
        );
        commit(BankAccountMutations.SET_REQUEST_STATE_BANK_ACCOUNT, res);
        commit(BankAccountMutations.SET_IS_DELETING_BANK_ACCOUNT, false);
      })
      .catch((err) => {
        console.error(err);
        commit(BankAccountMutations.SET_REQUEST_STATE_BANK_ACCOUNT, err);
        commit(BankAccountMutations.SET_IS_DELETING_BANK_ACCOUNT, false);
      });
  },
};
