import { CollaboratorGoalRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { CollaboratorGoalMutations } from "./mutations";
import { ICollaboratorGoalState } from "./states";

export const actions: ActionTree<ICollaboratorGoalState, RootState> = {
  async getCollaboratorGoalById(
    { commit },
    {
      collaboratorGoalId,
      updateState = true,
    }: { collaboratorGoalId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          CollaboratorGoalMutations.SET_IS_LOADING_COLLABORATOR_GOAL,
          true
        );
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Directory/CollaboratorGoal/GetCollaboratorGoalById`,
          {
            params: {
              collaboratorGoalId: collaboratorGoalId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(CollaboratorGoalMutations.SET_COLLABORATOR_GOAL, res.data);
            commit(
              CollaboratorGoalMutations.SET_IS_LOADING_COLLABORATOR_GOAL,
              false
            );
          }
          commit(
            CollaboratorGoalMutations.SET_REQUEST_STATE_COLLABORATOR_GOAL,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CollaboratorGoalMutations.SET_REQUEST_STATE_COLLABORATOR_GOAL,
            err
          );
          commit(
            CollaboratorGoalMutations.SET_IS_LOADING_COLLABORATOR_GOAL,
            false
          );
          reject(err);
        });
    });
  },

  async getCollaboratorGoalArchivedById(
    { commit },
    {
      collaboratorGoalId,
      updateState = true,
    }: { collaboratorGoalId: string; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          CollaboratorGoalMutations.SET_IS_LOADING_COLLABORATOR_GOAL,
          true
        );
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Directory/CollaboratorGoal/GetCollaboratorGoalArchivedById`,
          {
            params: {
              collaboratorGoalId: collaboratorGoalId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(CollaboratorGoalMutations.SET_COLLABORATOR_GOAL, res.data);
            commit(
              CollaboratorGoalMutations.SET_IS_LOADING_COLLABORATOR_GOAL,
              false
            );
          }
          commit(
            CollaboratorGoalMutations.SET_REQUEST_STATE_COLLABORATOR_GOAL,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CollaboratorGoalMutations.SET_REQUEST_STATE_COLLABORATOR_GOAL,
            err
          );
          commit(
            CollaboratorGoalMutations.SET_IS_LOADING_COLLABORATOR_GOAL,
            false
          );
          reject(err);
        });
    });
  },

  async getCollaboratorGoals(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          CollaboratorGoalMutations.SET_IS_LOADING_COLLABORATOR_GOAL_LIST,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/CollaboratorGoal/GetCollaboratorGoals`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              CollaboratorGoalMutations.SET_IS_LOADING_COLLABORATOR_GOAL_LIST,
              false
            );
            commit(
              CollaboratorGoalMutations.SET_COLLABORATOR_GOALS_LIST,
              res.data
            );
          }
          commit(
            CollaboratorGoalMutations.SET_REQUEST_STATE_COLLABORATOR_GOAL,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CollaboratorGoalMutations.SET_IS_LOADING_COLLABORATOR_GOAL_LIST,
            false
          );
          commit(
            CollaboratorGoalMutations.SET_REQUEST_STATE_COLLABORATOR_GOAL,
            err
          );
          reject(err);
        });
    });
  },

  async getCollaboratorGoalsArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          CollaboratorGoalMutations.SET_IS_LOADING_COLLABORATOR_GOAL_LIST_ARCHIVED,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/CollaboratorGoal/GetCollaboratorGoalsArchived`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              CollaboratorGoalMutations.SET_IS_LOADING_COLLABORATOR_GOAL_LIST_ARCHIVED,
              false
            );
            commit(
              CollaboratorGoalMutations.SET_COLLABORATOR_GOALS_LIST_ARCHIVED,
              res.data
            );
          }
          commit(
            CollaboratorGoalMutations.SET_REQUEST_STATE_COLLABORATOR_GOAL,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CollaboratorGoalMutations.SET_IS_LOADING_COLLABORATOR_GOAL_LIST,
            false
          );
          commit(
            CollaboratorGoalMutations.SET_REQUEST_STATE_COLLABORATOR_GOAL,
            err
          );
          reject(err);
        });
    });
  },

  async createCollaboratorGoal(
    { commit, rootGetters },
    { collaboratorGoal }: { collaboratorGoal: CollaboratorGoalRequestDto }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(CollaboratorGoalMutations.SET_IS_CREATING_COLLABORATOR_GOAL, true);
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Directory/CollaboratorGoal/CreateCollaboratorGoal`,
          collaboratorGoal
        )
        .then((res) => {
          rootGetters.collaboratorGoalsList.push(res.data.data);
          commit(
            CollaboratorGoalMutations.SET_COLLABORATOR_GOALS_LIST,
            rootGetters.collaboratorGoalsList
          );
          commit(
            CollaboratorGoalMutations.SET_REQUEST_STATE_COLLABORATOR_GOAL,
            res
          );
          commit(
            CollaboratorGoalMutations.SET_IS_CREATING_COLLABORATOR_GOAL,
            false
          );
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CollaboratorGoalMutations.SET_REQUEST_STATE_COLLABORATOR_GOAL,
            err
          );
          commit(
            CollaboratorGoalMutations.SET_IS_CREATING_COLLABORATOR_GOAL,
            false
          );
          reject(err);
        });
    });
  },

  async updateCollaboratorGoal(
    { commit, rootGetters },
    { collaboratorGoal }: { collaboratorGoal: CollaboratorGoalRequestDto }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(CollaboratorGoalMutations.SET_IS_UPDATING_COLLABORATOR_GOAL, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Directory/CollaboratorGoal/UpdateCollaboratorGoal`,
          collaboratorGoal
        )
        .then((res) => {
          rootGetters.collaboratorGoalsList.splice(
            rootGetters.collaboratorGoalsList.findIndex(
              (elem) => elem.id == collaboratorGoal.id
            ),
            1,
            collaboratorGoal
          );
          commit(
            CollaboratorGoalMutations.SET_COLLABORATOR_GOALS_LIST,
            rootGetters.collaboratorGoalsList
          );
          commit(
            CollaboratorGoalMutations.SET_REQUEST_STATE_COLLABORATOR_GOAL,
            res
          );
          commit(
            CollaboratorGoalMutations.SET_IS_UPDATING_COLLABORATOR_GOAL,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CollaboratorGoalMutations.SET_REQUEST_STATE_COLLABORATOR_GOAL,
            err
          );
          commit(
            CollaboratorGoalMutations.SET_IS_UPDATING_COLLABORATOR_GOAL,
            false
          );
          reject(err);
        });
    });
  },

  async restoreCollaboratorGoals(
    { commit, rootGetters },
    { collaboratorGoalIds = [] }: { collaboratorGoalIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(CollaboratorGoalMutations.SET_IS_UPDATING_COLLABORATOR_GOAL, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Directory/CollaboratorGoal/Restore",
          collaboratorGoalIds
        )
        .then(async (res) => {
          collaboratorGoalIds.forEach((collaboratorGoalId: number) => {
            rootGetters.collaboratorGoalsListArchived.splice(
              rootGetters.collaboratorGoalsListArchived.findIndex(
                (collaboratorGoal) => collaboratorGoal.id == collaboratorGoalId
              ),
              1
            );
          });
          commit(
            CollaboratorGoalMutations.SET_COLLABORATOR_GOALS_LIST_ARCHIVED,
            rootGetters.collaboratorGoalsListArchived
          );
          commit(
            CollaboratorGoalMutations.SET_REQUEST_STATE_COLLABORATOR_GOAL,
            res
          );
          commit(
            CollaboratorGoalMutations.SET_IS_UPDATING_COLLABORATOR_GOAL,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CollaboratorGoalMutations.SET_REQUEST_STATE_COLLABORATOR_GOAL,
            err
          );
          commit(
            CollaboratorGoalMutations.SET_IS_UPDATING_COLLABORATOR_GOAL,
            false
          );
          reject(err);
        });
    });
  },

  async archiveCollaboratorGoals(
    { commit, rootGetters },
    { collaboratorGoalIds = [] }: { collaboratorGoalIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        CollaboratorGoalMutations.SET_IS_ARCHIVING_COLLABORATOR_GOAL,
        true
      );
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Directory/CollaboratorGoal/Archive",
          {
            data: collaboratorGoalIds,
          }
        )
        .then((res) => {
          collaboratorGoalIds.forEach((collaboratorGoalId: number) => {
            rootGetters.collaboratorGoalsList.splice(
              rootGetters.collaboratorGoalsList.findIndex(
                (collaboratorGoal) => collaboratorGoal.id == collaboratorGoalId
              ),
              1
            );
          });
          commit(
            CollaboratorGoalMutations.SET_COLLABORATOR_GOALS_LIST,
            rootGetters.collaboratorGoalsList
          );
          commit(
            CollaboratorGoalMutations.SET_REQUEST_STATE_COLLABORATOR_GOAL,
            res
          );
          commit(
            CollaboratorGoalMutations.SET_IS_ARCHIVING_COLLABORATOR_GOAL,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CollaboratorGoalMutations.SET_REQUEST_STATE_COLLABORATOR_GOAL,
            err
          );
          commit(
            CollaboratorGoalMutations.SET_IS_ARCHIVING_COLLABORATOR_GOAL,
            false
          );
          reject(err);
        });
    });
  },

  async deleteCollaboratorGoals(
    { commit, rootGetters },
    { collaboratorGoalIds = [] }: { collaboratorGoalIds: number[] }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(CollaboratorGoalMutations.SET_IS_DELETING_COLLABORATOR_GOAL, true);
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Directory/CollaboratorGoal/Delete",
          {
            data: collaboratorGoalIds,
          }
        )
        .then((res) => {
          collaboratorGoalIds.forEach((collaboratorGoalId: number) => {
            rootGetters.collaboratorGoalsListArchived.splice(
              rootGetters.collaboratorGoalsListArchived.findIndex(
                (collaboratorGoal: CollaboratorGoalRequestDto) =>
                  collaboratorGoal.id == collaboratorGoalId
              ),
              1
            );
          });
          commit(
            CollaboratorGoalMutations.SET_COLLABORATOR_GOALS_LIST_ARCHIVED,
            rootGetters.collaboratorGoalsListArchived
          );

          commit(
            CollaboratorGoalMutations.SET_REQUEST_STATE_COLLABORATOR_GOAL,
            res
          );
          commit(
            CollaboratorGoalMutations.SET_IS_DELETING_COLLABORATOR_GOAL,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CollaboratorGoalMutations.SET_REQUEST_STATE_COLLABORATOR_GOAL,
            err
          );
          commit(
            CollaboratorGoalMutations.SET_IS_DELETING_COLLABORATOR_GOAL,
            false
          );
          reject(err);
        });
    });
  },
};
