import { CustomFieldTemplateRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { CustomFieldTemplateMutations } from "./mutations";
import { ICustomFieldTemplateState } from "./states";
var dayjs = require("dayjs");

export const actions: ActionTree<ICustomFieldTemplateState, RootState> = {
  async getCustomFieldTemplates(
    { commit },
    { type, updateState = true }: { type: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          CustomFieldTemplateMutations.SET_IS_LOADING_CUSTOM_FIELD_TEMPLATE_LIST,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Parameters/CustomFieldTemplate/GetCustomFieldTemplates`;
      await axios
        .get(url, {
          params: {
            type: type,
          },
        })
        .then((res) => {
          let list = res.data.map((customFieldTemplate) => {
            let customFieldGroups = customFieldTemplate.customFieldGroups.map(
              (group) => {
                let customFields = group.customFields.map((elem) =>
                  elem.typeField === 10
                    ? {
                        ...elem,
                        defaultValue: elem.defaultValue
                          ? elem.defaultValue.split(";")
                          : elem.defaultValue,
                      }
                    : { ...elem }
                );
                return { ...group, customFields: customFields };
              }
            );
            return {
              ...customFieldTemplate,
              customFieldGroups: customFieldGroups,
            };
          });
          if (updateState) {
            commit(
              CustomFieldTemplateMutations.SET_IS_LOADING_CUSTOM_FIELD_TEMPLATE_LIST,
              false
            );
            commit(
              CustomFieldTemplateMutations.SET_CUSTOM_FIELD_TEMPLATES_LIST,
              list
            );
          }
          commit(
            CustomFieldTemplateMutations.SET_REQUEST_STATE_CUSTOM_FIELD_TEMPLATE,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CustomFieldTemplateMutations.SET_IS_LOADING_CUSTOM_FIELD_TEMPLATE_LIST,
            false
          );
          commit(
            CustomFieldTemplateMutations.SET_REQUEST_STATE_CUSTOM_FIELD_TEMPLATE,
            err
          );
          reject(err);
        });
    });
  },

  async getCustomFieldTemplateById(
    { commit },
    {
      customFieldTemplateId,
      updateState = true,
    }: { customFieldTemplateId: string; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          CustomFieldTemplateMutations.SET_IS_LOADING_CUSTOM_FIELD_TEMPLATE,
          true
        );
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Parameters/CustomFieldTemplate/GetCustomFieldTemplateById`,
          {
            params: {
              customFieldTemplateId: customFieldTemplateId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(
              CustomFieldTemplateMutations.SET_CUSTOM_FIELD_TEMPLATE,
              res.data
            );
            commit(
              CustomFieldTemplateMutations.SET_IS_LOADING_CUSTOM_FIELD_TEMPLATE,
              false
            );
          }
          commit(
            CustomFieldTemplateMutations.SET_REQUEST_STATE_CUSTOM_FIELD_TEMPLATE,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CustomFieldTemplateMutations.SET_REQUEST_STATE_CUSTOM_FIELD_TEMPLATE,
            err
          );
          commit(
            CustomFieldTemplateMutations.SET_IS_LOADING_CUSTOM_FIELD_TEMPLATE,
            false
          );
          reject(err);
        });
    });
  },

  async getCustomFieldTemplatesArchived(
    { commit },
    { type, updateState = true }: { type: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          CustomFieldTemplateMutations.SET_IS_LOADING_CUSTOM_FIELD_TEMPLATE_LIST_ARCHIVED,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Parameters/CustomFieldTemplate/GetCustomFieldTemplatesArchived`;
      await axios
        .get(url, {
          params: {
            type: type,
          },
        })
        .then((res) => {
          if (updateState) {
            commit(
              CustomFieldTemplateMutations.SET_IS_LOADING_CUSTOM_FIELD_TEMPLATE_LIST_ARCHIVED,
              false
            );
            commit(
              CustomFieldTemplateMutations.SET_CUSTOM_FIELD_TEMPLATES_LIST_ARCHIVED,
              res.data
            );
          }
          commit(
            CustomFieldTemplateMutations.SET_REQUEST_STATE_CUSTOM_FIELD_TEMPLATE,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CustomFieldTemplateMutations.SET_IS_LOADING_CUSTOM_FIELD_TEMPLATE_LIST,
            false
          );
          commit(
            CustomFieldTemplateMutations.SET_REQUEST_STATE_CUSTOM_FIELD_TEMPLATE,
            err
          );
          reject(err);
        });
    });
  },

  async getCustomFieldTemplateArchivedById(
    { commit },
    {
      customFieldTemplateId,
      updateState = true,
    }: { customFieldTemplateId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          CustomFieldTemplateMutations.SET_IS_LOADING_CUSTOM_FIELD_TEMPLATE,
          true
        );
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Parameters/CustomFieldTemplate/GetCustomFieldTemplateArchivedById`,
          {
            params: {
              customFieldTemplateId: customFieldTemplateId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(
              CustomFieldTemplateMutations.SET_CUSTOM_FIELD_TEMPLATE,
              res.data
            );
            commit(
              CustomFieldTemplateMutations.SET_IS_LOADING_CUSTOM_FIELD_TEMPLATE,
              false
            );
          }
          commit(
            CustomFieldTemplateMutations.SET_REQUEST_STATE_CUSTOM_FIELD_TEMPLATE,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CustomFieldTemplateMutations.SET_REQUEST_STATE_CUSTOM_FIELD_TEMPLATE,
            err
          );
          commit(
            CustomFieldTemplateMutations.SET_IS_LOADING_CUSTOM_FIELD_TEMPLATE,
            false
          );
          reject(err);
        });
    });
  },

  async createCustomFieldTemplate(
    { commit, rootGetters },
    {
      customFieldTemplate,
    }: { customFieldTemplate: CustomFieldTemplateRequestDto }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        CustomFieldTemplateMutations.SET_IS_CREATING_CUSTOM_FIELD_TEMPLATE,
        true
      );
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Parameters/CustomFieldTemplate/CreateCustomFieldTemplate`,
          customFieldTemplate
        )
        .then((res) => {
          rootGetters.customFieldTemplatesList.push(res.data.data);
          commit(
            CustomFieldTemplateMutations.SET_CUSTOM_FIELD_TEMPLATES_LIST,
            rootGetters.customFieldTemplatesList
          );
          commit(
            CustomFieldTemplateMutations.SET_IS_CREATING_CUSTOM_FIELD_TEMPLATE,
            false
          );
          commit(
            CustomFieldTemplateMutations.SET_REQUEST_STATE_CUSTOM_FIELD_TEMPLATE,
            res
          );
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CustomFieldTemplateMutations.SET_REQUEST_STATE_CUSTOM_FIELD_TEMPLATE,
            err
          );
          commit(
            CustomFieldTemplateMutations.SET_IS_CREATING_CUSTOM_FIELD_TEMPLATE,
            false
          );
          reject(err);
        });
    });
  },

  async updateCustomFieldTemplate(
    { commit, rootGetters },
    {
      customFieldTemplate,
    }: { customFieldTemplate: CustomFieldTemplateRequestDto }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        CustomFieldTemplateMutations.SET_IS_UPDATING_CUSTOM_FIELD_TEMPLATE,
        true
      );
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Parameters/CustomFieldTemplate/UpdateCustomFieldTemplate`,
          customFieldTemplate
        )
        .then((res) => {
          if (
            rootGetters.customFieldTemplatesList.find(
              (elem) => elem.id == customFieldTemplate.id
            )
          ) {
            rootGetters.customFieldTemplatesList.splice(
              rootGetters.customFieldTemplatesList.findIndex(
                (elem) => elem.id == customFieldTemplate.id
              ),
              1,
              customFieldTemplate
            );
            commit(
              CustomFieldTemplateMutations.SET_CUSTOM_FIELD_TEMPLATES_LIST,
              rootGetters.customFieldTemplatesList
            );
          }

          if (
            rootGetters.customFieldTemplatesListArchived.find(
              (elem) => elem.id == customFieldTemplate.id
            )
          ) {
            rootGetters.customFieldTemplatesListArchived.splice(
              rootGetters.customFieldTemplatesListArchived.findIndex(
                (elem) => elem.id == customFieldTemplate.id
              ),
              1,
              customFieldTemplate
            );
            commit(
              CustomFieldTemplateMutations.SET_CUSTOM_FIELD_TEMPLATES_LIST_ARCHIVED,
              rootGetters.customFieldTemplatesListArchived
            );
          }

          commit(
            CustomFieldTemplateMutations.SET_REQUEST_STATE_CUSTOM_FIELD_TEMPLATE,
            res
          );
          commit(
            CustomFieldTemplateMutations.SET_IS_UPDATING_CUSTOM_FIELD_TEMPLATE,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CustomFieldTemplateMutations.SET_REQUEST_STATE_CUSTOM_FIELD_TEMPLATE,
            err
          );
          commit(
            CustomFieldTemplateMutations.SET_IS_UPDATING_CUSTOM_FIELD_TEMPLATE,
            false
          );
          reject(err);
        });
    });
  },

  async restoreCustomFieldTemplates(
    { commit, rootGetters },
    { customFieldTemplateIds = [] }: { customFieldTemplateIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        CustomFieldTemplateMutations.SET_IS_UPDATING_CUSTOM_FIELD_TEMPLATE,
        true
      );
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Parameters/CustomFieldTemplate/Restore",
          customFieldTemplateIds
        )
        .then(async (res) => {
          customFieldTemplateIds.forEach((customFieldTemplateId: number) => {
            let customFieldTemplate =
              rootGetters.customFieldTemplatesListArchived.find(
                (customFieldTemplate: CustomFieldTemplateRequestDto) =>
                  customFieldTemplate.id == customFieldTemplateId
              );
            rootGetters.customFieldTemplatesList.unshift(customFieldTemplate);

            if (
              rootGetters.customFieldTemplatesListArchived.find(
                (customFieldTemplate: CustomFieldTemplateRequestDto) =>
                  customFieldTemplate.id == customFieldTemplateId
              )
            ) {
              rootGetters.customFieldTemplatesListArchived.splice(
                rootGetters.customFieldTemplatesListArchived.findIndex(
                  (customFieldTemplate: CustomFieldTemplateRequestDto) =>
                    customFieldTemplate.id == customFieldTemplateId
                ),
                1
              );
            }
          });
          commit(
            CustomFieldTemplateMutations.SET_CUSTOM_FIELD_TEMPLATES_LIST,
            rootGetters.customFieldTemplatesList
          );
          commit(
            CustomFieldTemplateMutations.SET_CUSTOM_FIELD_TEMPLATES_LIST_ARCHIVED,
            rootGetters.customFieldTemplatesListArchived
          );
          commit(
            CustomFieldTemplateMutations.SET_REQUEST_STATE_CUSTOM_FIELD_TEMPLATE,
            res
          );
          commit(
            CustomFieldTemplateMutations.SET_IS_UPDATING_CUSTOM_FIELD_TEMPLATE,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CustomFieldTemplateMutations.SET_REQUEST_STATE_CUSTOM_FIELD_TEMPLATE,
            err
          );
          commit(
            CustomFieldTemplateMutations.SET_IS_UPDATING_CUSTOM_FIELD_TEMPLATE,
            false
          );
          reject(err);
        });
    });
  },

  async archiveCustomFieldTemplates(
    { commit, rootGetters },
    { customFieldTemplateIds = [] }: { customFieldTemplateIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        CustomFieldTemplateMutations.SET_IS_ARCHIVING_CUSTOM_FIELD_TEMPLATE,
        true
      );
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS +
            "Parameters/CustomFieldTemplate/Archive",
          {
            data: customFieldTemplateIds,
          }
        )
        .then((res) => {
          customFieldTemplateIds.forEach((customFieldTemplateId: number) => {
            let customFieldTemplate = rootGetters.customFieldTemplatesList.find(
              (customFieldTemplate: CustomFieldTemplateRequestDto) =>
                customFieldTemplate.id == customFieldTemplateId
            );
            rootGetters.customFieldTemplatesListArchived.unshift(
              customFieldTemplate
            );

            if (
              rootGetters.customFieldTemplatesList.find(
                (customFieldTemplate: CustomFieldTemplateRequestDto) =>
                  customFieldTemplate.id == customFieldTemplateId
              )
            ) {
              rootGetters.customFieldTemplatesList.splice(
                rootGetters.customFieldTemplatesList.findIndex(
                  (customFieldTemplate: CustomFieldTemplateRequestDto) =>
                    customFieldTemplate.id == customFieldTemplateId
                ),
                1
              );
            }
          });
          commit(
            CustomFieldTemplateMutations.SET_CUSTOM_FIELD_TEMPLATES_LIST,
            rootGetters.customFieldTemplatesList
          );
          commit(
            CustomFieldTemplateMutations.SET_CUSTOM_FIELD_TEMPLATES_LIST_ARCHIVED,
            rootGetters.customFieldTemplatesListArchived
          );
          commit(
            CustomFieldTemplateMutations.SET_REQUEST_STATE_CUSTOM_FIELD_TEMPLATE,
            res
          );
          commit(
            CustomFieldTemplateMutations.SET_IS_ARCHIVING_CUSTOM_FIELD_TEMPLATE,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CustomFieldTemplateMutations.SET_REQUEST_STATE_CUSTOM_FIELD_TEMPLATE,
            err
          );
          commit(
            CustomFieldTemplateMutations.SET_IS_ARCHIVING_CUSTOM_FIELD_TEMPLATE,
            false
          );
          reject(err);
        });
    });
  },

  async deleteCustomFieldTemplates(
    { commit, rootGetters },
    { customFieldTemplateIds = [] }: { customFieldTemplateIds: number[] }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        CustomFieldTemplateMutations.SET_IS_DELETING_CUSTOM_FIELD_TEMPLATE,
        true
      );
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS +
            "Parameters/CustomFieldTemplate/Delete",
          {
            data: customFieldTemplateIds,
          }
        )
        .then((res) => {
          customFieldTemplateIds.forEach((customFieldTemplateId: number) => {
            if (
              rootGetters.customFieldTemplatesList.find(
                (customFieldTemplate: CustomFieldTemplateRequestDto) =>
                  customFieldTemplate.id == customFieldTemplateId
              )
            ) {
              rootGetters.customFieldTemplatesList.splice(
                rootGetters.customFieldTemplatesList.findIndex(
                  (customFieldTemplate: CustomFieldTemplateRequestDto) =>
                    customFieldTemplate.id == customFieldTemplateId
                ),
                1
              );
            }

            if (
              rootGetters.customFieldTemplatesListArchived.find(
                (customFieldTemplate: CustomFieldTemplateRequestDto) =>
                  customFieldTemplate.id == customFieldTemplateId
              )
            ) {
              rootGetters.customFieldTemplatesListArchived.splice(
                rootGetters.customFieldTemplatesListArchived.findIndex(
                  (customFieldTemplate: CustomFieldTemplateRequestDto) =>
                    customFieldTemplate.id == customFieldTemplateId
                ),
                1
              );
            }
          });
          commit(
            CustomFieldTemplateMutations.SET_CUSTOM_FIELD_TEMPLATES_LIST,
            rootGetters.customFieldTemplatesList
          );
          commit(
            CustomFieldTemplateMutations.SET_CUSTOM_FIELD_TEMPLATES_LIST_ARCHIVED,
            rootGetters.customFieldTemplatesListArchived
          );

          commit(
            CustomFieldTemplateMutations.SET_REQUEST_STATE_CUSTOM_FIELD_TEMPLATE,
            res
          );
          commit(
            CustomFieldTemplateMutations.SET_IS_DELETING_CUSTOM_FIELD_TEMPLATE,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CustomFieldTemplateMutations.SET_REQUEST_STATE_CUSTOM_FIELD_TEMPLATE,
            err
          );
          commit(
            CustomFieldTemplateMutations.SET_IS_DELETING_CUSTOM_FIELD_TEMPLATE,
            false
          );
          reject(err);
        });
    });
  },
};
