import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { ModuleRequestDto } from '@/../Api'
import { GetterTree } from "vuex";
import RootState from '@/store/modules/rootState'
import {  IModuleState } from "./states";

export const getters: GetterTree<IModuleState, RootState> = {
    modulesList: (state): ModuleRequestDto[] => state.modulesList,
    modulesListArchived: (state): ModuleRequestDto[] => state.modulesListArchived,
    module     : (state): ModuleRequestDto => state.module,
    
    // COMMON
    isLoadingModulesList   : (state): boolean => state.isLoadingModulesList,
    isLoadingModulesListArchived : (state): boolean => state.isLoadingModulesListArchived,
    isLoadingModule   : (state): boolean => state.isLoadingModule,
    requestStateModule: (state): IRequestState => state.requestStateModule,
    isArchivingModule : (state): boolean => state.isArchivingModule,
}