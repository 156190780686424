import { IEventTypeState } from "./states";
import { MutationTree } from "vuex";
import { IEventType } from "@/types/api-orisis/interfaces/Planning/IEventType";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";


export enum EventTypeMutations{
    SET_EVENT_TYPES_LIST = "SET_EVENT_TYPES_LIST",
    SET_EVENT_TYPES_LIST_ARCHIVED = "SET_EVENT_TYPES_LIST_ARCHIVED",
    SET_EVENT_TYPE = "SET_EVENT_TYPE",
    
    // COMON
    SET_IS_LOADING_EVENT_TYPE_LIST = "SET_IS_LOADING_EVENT_TYPE_LIST",
    SET_IS_LOADING_EVENT_TYPE_LIST_ARCHIVED = "SET_IS_LOADING_EVENT_TYPE_LIST_ARCHIVED",
    SET_IS_LOADING_EVENT_TYPE = "SET_IS_LOADING_EVENT_TYPE",
    SET_IS_CREATING_EVENT_TYPE = "SET_IS_CREATING_EVENT_TYPE",
    SET_IS_UPDATING_EVENT_TYPE = "SET_IS_UPDATING_EVENT_TYPE",
    SET_IS_DELETING_EVENT_TYPE = "SET_IS_DELETING_EVENT_TYPE",
    SET_IS_ARCHIVING_EVENT_TYPE = "SET_IS_DELETING_EVENT_TYPE",
    SET_REQUEST_STATE_EVENT_TYPE = "SET_REQUEST_STATE_EVENT_TYPE"
  }

  export const mutations: MutationTree<IEventTypeState> = {
    [EventTypeMutations.SET_EVENT_TYPES_LIST]: (state, payload: IEventType[]) => {
        state.eventTypesList = payload;
    },
    [EventTypeMutations.SET_EVENT_TYPES_LIST_ARCHIVED]: (state, payload: IEventType[]) => {
        state.eventTypesListArchived = payload;
    },
    [EventTypeMutations.SET_EVENT_TYPE]: (state, payload: IEventType) => {
        state.eventType = payload;
    },

    //COMMON
    [EventTypeMutations.SET_IS_LOADING_EVENT_TYPE_LIST]: (state, payload: boolean) => {
        state.isLoadingEventTypesList = payload;
    },
    [EventTypeMutations.SET_IS_LOADING_EVENT_TYPE_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingEventTypesListArchived = payload;
    },
    [EventTypeMutations.SET_IS_LOADING_EVENT_TYPE]: (state, payload: boolean) => {
        state.isLoadingEventType = payload;
    },
    [EventTypeMutations.SET_IS_CREATING_EVENT_TYPE]: (state, payload: boolean) => {
        state.isCreatingEventType = payload;
    },
    [EventTypeMutations.SET_IS_UPDATING_EVENT_TYPE]: (state, payload: boolean) => {
        state.isUpdatingEventType = payload;
    },
    [EventTypeMutations.SET_IS_DELETING_EVENT_TYPE]: (state, payload: boolean) => {
        state.isDeletingEventType = payload;
    },
    [EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE]: (state, payload: IRequestState) => {
        state.requestStateEventType = payload;
    },
} 