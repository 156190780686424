import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { AccountMutations } from "./mutations";
import { IAccountState } from "./states";
import { WorkspaceMutations } from "../workspace/mutations";
import { InstitutionMutations } from "../../institution/mutations";
import { InstitutionModel } from "@/types/api-orisis/models/InstitutionModel";
import { CollaboratorMutations } from "../../directory/collaborator/mutations";

export const actions: ActionTree<IAccountState, RootState> = {
  async login({ commit, dispatch }, payload) {
    return new Promise(async (resolve, reject) => {
      commit(AccountMutations.SET_IS_LOGGIN, true);
      await axios
        .post(process.env.VUE_APP_API_ORISIS + "Account/Login", payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(async function (response) {
          let dataUser = response.data;
          commit("SET_USER_INFO", dataUser);

          if (response.data?.token) {
            commit(AccountMutations.SET_USER_TOKEN, response.data.token);

            commit(AccountMutations.SET_USER_IS_LOGGED_IN, true);
            commit(AccountMutations.SET_IS_LOGGIN_MSG, "");
            commit(AccountMutations.SET_REQUEST_STATE_ACCOUNT, response);
            commit(
              WorkspaceMutations.SET_WORKSPACES_LIST_BY_USER,
              response.data.workspaces
            );

            if (response.data.workspaces.length == 1) {
              await dispatch("setIsGlobalLoading", true);
              commit(AccountMutations.SET_IS_LOGGIN, false);
              await dispatch("setWorkspaceSelected", {
                workspaceSelectedId: response.data.workspaces[0].id,
              }).then(async () => {
                await dispatch("getCollaboratorByUserId", {
                  userId: response.data.id,
                });
                dispatch("setIsGlobalLoading", false);
                resolve({ data: response.data.workspaces[0], router: true });
              });
            } else if (response.data.workspaces.length > 1) {
              commit(AccountMutations.SET_IS_LOGGIN, false);
              if (
                response.data.workspaces.filter((el) => el.isDefault === true)
                  .length > 0
              ) {
                resolve({
                  data: response.data.workspaces.filter(
                    (el) => el.isDefault === true
                  )[0],
                  router: false,
                });
              } else {
                resolve({ data: response.data.workspaces[0], router: false });
              }
            }
          } else {
            commit(CollaboratorMutations.SET_USER_COLLABORATOR, null);
            commit(AccountMutations.SET_USER_TOKEN, null);
            commit(AccountMutations.SET_USER_IS_LOGGED_IN, false);
            commit(AccountMutations.SET_IS_LOGGIN, false);
            commit(
              AccountMutations.SET_IS_LOGGIN_MSG,
              "Identifiant ou mot de passe incorrecte, veuillez recommencer."
            );
            commit(AccountMutations.SET_REQUEST_STATE_ACCOUNT, response);
            reject(
              "Identifiant ou mot de passe incorrecte, veuillez recommencer."
            );
          }
        })
        .catch(async (err) => {
          commit(AccountMutations.SET_USER_TOKEN, null);
          commit(AccountMutations.SET_USER_IS_LOGGED_IN, false);
          commit(AccountMutations.SET_IS_LOGGIN, false);
          commit(
            AccountMutations.SET_IS_LOGGIN_MSG,
            "Identifiant ou mot de passe incorrecte, veuillez recommencer."
          );
          commit(AccountMutations.SET_REQUEST_STATE_ACCOUNT, err);
          reject(err);
        });
    });
  },

  async logOut({ commit }) {
    return new Promise(async (resolve, reject) => {
      if (localStorage.getItem("userToken")) {
        await axios
          .get(process.env.VUE_APP_API_ORISIS + "Account/Logout", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          })
          .then(function (response) {
            commit(CollaboratorMutations.SET_USER_COLLABORATOR, null);
            commit(AccountMutations.SET_USER_TOKEN, null);
            commit(AccountMutations.SET_USER_IS_LOGGED_IN, false);
            commit(AccountMutations.SET_IS_LOGGIN_MSG, "");
            commit(AccountMutations.SET_REQUEST_STATE_ACCOUNT, response);
            commit(
              InstitutionMutations.SET_INSTITUTION_ACTIVE,
              InstitutionModel
            );
            localStorage.clear();
            resolve("Déconnexion réussie");
          })
          .catch(function (error) {
            commit(CollaboratorMutations.SET_USER_COLLABORATOR, null);
            commit(AccountMutations.SET_USER_TOKEN, null);
            commit(AccountMutations.SET_USER_IS_LOGGED_IN, false);
            commit(AccountMutations.SET_IS_LOGGIN_MSG, "");
            commit(AccountMutations.SET_REQUEST_STATE_ACCOUNT, error);
            commit(
              InstitutionMutations.SET_INSTITUTION_ACTIVE,
              InstitutionModel
            );
            localStorage.clear();
            reject(error);
          });
      } else {
        commit(CollaboratorMutations.SET_USER_COLLABORATOR, null);
        commit(AccountMutations.SET_USER_TOKEN, null);
        commit(AccountMutations.SET_USER_IS_LOGGED_IN, false);
        commit(AccountMutations.SET_IS_LOGGIN_MSG, "");
        commit(InstitutionMutations.SET_INSTITUTION_ACTIVE, InstitutionModel);
        localStorage.clear();
        resolve("Déconnexion réussie");
      }
    });
  },

  async chooseWorkspace(
    { commit, dispatch, rootGetters },
    workspaceIdSelected
  ) {
    return new Promise(async (resolve, reject) => {
      commit(AccountMutations.SET_IS_LOGGIN, true);
      await axios
        .post(
          process.env.VUE_APP_API_ORISIS + "Account/ChooseWorkspace",
          {},
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`,
            },
            params: {
              workspaceIdSelected: workspaceIdSelected,
            },
          }
        )
        .then(async function (response) {
          commit(AccountMutations.SET_USER_INFO, response.data);

          if (response.data?.token) {
            await dispatch("setIsGlobalLoading", true);
            commit(AccountMutations.SET_USER_TOKEN, response.data.token);
            commit(AccountMutations.SET_USER_IS_LOGGED_IN, true);
            commit(AccountMutations.SET_IS_LOGGIN, false);
            commit(AccountMutations.SET_IS_LOGGIN_MSG, "");
            commit(AccountMutations.SET_REQUEST_STATE_ACCOUNT, response);
            commit(
              WorkspaceMutations.SET_WORKSPACES_LIST_BY_USER,
              response.data.workspaces
            );
            await dispatch("setWorkspaceSelected", {
              workspaceSelectedId: workspaceIdSelected,
            }).then(async () => {
              await dispatch("getCollaboratorByUserId", {
                userId: response.data.id,
              });
              //await dispatch('getUserParametersByUserId', {userId: response.data.id} )
              dispatch("setIsGlobalLoading", false);
              resolve("Connection successful");
            });
          } else {
            commit(CollaboratorMutations.SET_USER_COLLABORATOR, null);
            commit(AccountMutations.SET_USER_TOKEN, null);
            commit(AccountMutations.SET_USER_IS_LOGGED_IN, false);
            commit(AccountMutations.SET_IS_LOGGIN, false);
            commit(
              AccountMutations.SET_IS_LOGGIN_MSG,
              "Identifiant ou mot de passe incorrecte, veuillez recommencer."
            );
            commit(AccountMutations.SET_REQUEST_STATE_ACCOUNT, response);
            dispatch("setIsGlobalLoading", false);
            reject(
              "Identifiant ou mot de passe incorrecte, veuillez recommencer."
            );
          }
        })
        .catch(function (err) {
          commit(AccountMutations.SET_IS_LOGGIN, false);
          commit(AccountMutations.SET_REQUEST_STATE_ACCOUNT, err);
          dispatch("setIsGlobalLoading", false);
          reject(err);
        });
    });
  },

  async forgotPassword({ commit }, { payload, that }) {
    await axios
      .post(
        process.env.VUE_APP_API_ORISIS + "Account/ForgotPassword",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        that.$router.push({ name: "login" });
        that.$nextTick(() => {
          that.$bvToast.toast(
            "Veuillez vérifier votre boite e-mail pour continuer la procédure.",
            {
              title: `Ré-initialisation de mot de passe initiée`,
              variant: "success",
              solid: true,
            }
          );
        });
        commit(AccountMutations.SET_REQUEST_STATE_ACCOUNT, response);
      });
  },

  async resetPassword({ commit, dispatch }, { payload, that }) {
    await dispatch("setIsGlobalLoading", true);
    await axios
      .post(process.env.VUE_APP_API_ORISIS + "Account/ResetPassword", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (!response.data) {
          that.$bvToast.toast(
            "Le délai de ré-initialisation a expiré, veuillez renouveler votre demande.",
            {
              title: `Réinitialisation impossible`,
              variant: "danger",
              solid: true,
            }
          );
          commit(AccountMutations.SET_REQUEST_STATE_ACCOUNT, response);
        } else {
          if (response.data?.token) {
            that.$router.push("/login");
            dispatch("setIsGlobalLoading", false);
            that.$nextTick(() => {
              that.$bvToast.toast(
                "Votre nouveau mot de passe a été correctement enregistré.",
                {
                  title: `Authentification réussie`,
                  variant: "success",
                  solid: true,
                }
              );
            });
          } else {
            that.$router.push("/login");
            dispatch("setIsGlobalLoading", false);
          }
        }
      })
      .catch((err) => {
        commit(AccountMutations.SET_REQUEST_STATE_ACCOUNT, err);
      });
  },

  async changePassword({ commit, dispatch }, { payload, that }) {
    await dispatch("setIsGlobalLoading", true);
    await axios
      .post(
        process.env.VUE_APP_API_ORISIS + "Account/ChangePassword",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (!response.data) {
          that.$bvToast.toast(
            "Veuillez ré-essayer avec un nouveau mot de passe.",
            {
              title: `Changement de mot passe impossible`,
              variant: "danger",
              solid: true,
            }
          );
          commit(AccountMutations.SET_REQUEST_STATE_ACCOUNT, response);
        } else {
          commit("SET_USER_INFO", response.data);
          if (response.data?.token) {
            commit("SET_USER_TOKEN", response.data.token);
            commit("SET_USER_IS_LOGGED_IN", true);
            dispatch("setWorkspaceSelected", {
              workspaceSelectedId: response.data.workspaces[0].id,
            }).then(async () => {
              await dispatch("getInstitution", {});
            });
            commit("SET_WORKSPACES_LIST_BY_USER", response.data.workspaces);
            commit(AccountMutations.SET_REQUEST_STATE_ACCOUNT, response);
            that.$router.push("/");
            dispatch("setIsGlobalLoading", false);
            that.$nextTick(() => {
              that.$bvToast.toast(
                "Votre nouveau mot de passe a été correctement enregistré.",
                {
                  title: `Authentification réussie`,
                  variant: "success",
                  solid: true,
                }
              );
            });
          } else {
            commit("SET_USER_TOKEN", null);
            commit("SET_USER_IS_LOGGED_IN", false);
            commit(AccountMutations.SET_REQUEST_STATE_ACCOUNT, response);
          }
        }
      });
  },

  async initializeStore({ commit, dispatch, rootGetters }) {
    // On initialize les listes standard
    commit("SET_UNITIES_LIST", []);
    commit("SET_PAYMENT_METHODS_LIST", []);
    commit("SET_PAYMENT_REQUEST_DUE_LIST", []);
    commit("SET_DOCUMENT_VALIDITY_LIST", []);
    commit("SET_COMPANIES_TYPES_LIST", []);
    commit("SET_COMPANIES_LIST", []);
    commit("SET_PAYMENT_CONDITIONS_LIST", []);
    commit("SET_DOCUMENT_VALIDITY_LIST", []);

    await dispatch("getUsers", {});

    for (
      let index = 0;
      index < rootGetters.workspaceSelected.modules.length;
      index++
    ) {
      const module = rootGetters.workspaceSelected.modules[index];
      if (module.label == "Vente") {
        // On Dispatch les paramètres de facturation
        await dispatch("getProductTypeEnum", {});
        await dispatch("getReferencielTVAs", {});
        await dispatch("getNatureEnum", {});
        await dispatch("getAmountTypeEnum", {});
        await dispatch("getPaymentMethodByWorkspaceId", {});
        await dispatch("getUnits", {});
        await dispatch("getCompanyTypes", {});
      } else if (module.label == "Achat") {
        // On Dispatch les paramètres du module achat
      } else if (module.label == "Baux") {
        // On Dispatch les paramètres de gestion de baux
        await dispatch("getLeaseTypes", {});
        await dispatch("getLeases", {});
        await dispatch("getPeriodicities", {});
        await dispatch("getPropertyTypes", {});
      } else if (module.label == "CRM") {
        // On Dispatch les paramètres de gestion du module CRM
        await dispatch("getActivitySourceTypes", {});
      } else if (module.label == "Planning") {
        // On Dispatch les paramètres de planning
        await dispatch("getPlanningViews", {});
        await dispatch("getPublicHolidays", {});
      } else if (module.label == "Reporting") {
      }
    }
  },

  setIsToastingNotification({ commit }, statut) {
    commit("SET_IS_TOASTING_NOTIFICATION", statut);
  },

  setIsLoggin({ commit }, statut) {
    commit("SET_IS_LOGGIN", statut);
  },

  setIsLogginMsg({ commit }, msg) {
    commit("SET_IS_LOGGIN_MSG", msg);
  },
};
