import { PaymentProviderRequestDto } from "Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { PaymentProviderMutations } from "./mutations";
import { IPaymentProviderState } from "./states";
import dayjs from "dayjs";
import { Timezone } from "@syncfusion/ej2-schedule";
import { IPaymentProvider } from "@/types/api-orisis/interfaces/Purchase/paymentProvider/IPaymentProvider";

let timezoneSchedule = new Timezone();

let startDate = new Date(
  dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
).toISOString();
let endDate = new Date(
  dayjs().endOf("month").format("YYYY-MM-DD")
).toISOString();

export const actions: ActionTree<IPaymentProviderState, RootState> = {
  async getPaymentProviderById(
    { commit, rootGetters },
    {
      paymentProviderId,
      updateState = true,
    }: { paymentProviderId: number; updateState: boolean }
  ): Promise<PaymentProviderRequestDto> {
    return new Promise(async (resolve, reject) => {
      if (updateState) {
        commit(PaymentProviderMutations.SET_IS_LOADING_PAYMENT_PROVIDER, true);
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/PaymentProvider/GetPaymentProviderById",
          {
            params: {
              paymentProviderId: paymentProviderId,
            },
          }
        )
        .then((res) => {
          let paymentProvider = {
            ...res.data,
            paymentProviderMethodId: res.data.paymentProviderMethod
              ? res.data.paymentProviderMethod.id
              : null,
          };
          if (updateState) {
            commit(
              PaymentProviderMutations.SET_PAYMENT_PROVIDER,
              paymentProvider
            );
            commit(
              PaymentProviderMutations.SET_IS_LOADING_PAYMENT_PROVIDER,
              false
            );
          }
          commit(
            PaymentProviderMutations.SET_REQUEST_STATE_PAYMENT_PROVIDER,
            res
          );
          resolve(paymentProvider);
        })
        .catch((err) => {
          console.error(err);
          commit(
            PaymentProviderMutations.SET_REQUEST_STATE_PAYMENT_PROVIDER,
            err
          );
          commit(
            PaymentProviderMutations.SET_IS_LOADING_PAYMENT_PROVIDER,
            false
          );
          reject(err);
        });
    });
  },

  async getPaymentProviders(
    { commit },
    {
      invoiceProviderId = null,
      creditProviderId = null,
      bankAccountId = null,
      startTo = null,
      endTo = null,
      updateState = true,
    }: {
      invoiceProviderId: number;
      creditProviderId: number;
      bankAccountId: number;
      startTo: Date;
      endTo: Date;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (updateState) {
        commit(
          PaymentProviderMutations.SET_IS_LOADING_PAYMENT_PROVIDERS_LIST,
          true
        );
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/PaymentProvider/GetPaymentProviders",
          {
            params: {
              invoiceProviderId: invoiceProviderId,
              creditProviderId: creditProviderId,
              bankAccountId: bankAccountId,
              startTo: startTo ? dayjs(startTo).format("YYYY-MM-DD") : null,
              endTo: endTo
                ? dayjs(endTo)
                    .hour(23)
                    .minute(59)
                    .second(59)
                    .format("YYYY-MM-DD HH:mm:ss")
                : null,
            },
          }
        )
        .then((res) => {
          let list = res.data.map((item: any) => {
            return {
              ...item,
              paymentProviderMethodId: item.paymentProviderMethod
                ? item.paymentProviderMethod.id
                : null,
              date: item.date
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(new Date(item.date))
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              remittedBankDate: item.remittedBankDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(item.remittedBankDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
            };
          });
          if (updateState) {
            commit(PaymentProviderMutations.SET_PAYMENT_PROVIDERS_LIST, list);
            commit(
              PaymentProviderMutations.SET_IS_LOADING_PAYMENT_PROVIDERS_LIST,
              false
            );
          }
          commit(
            PaymentProviderMutations.SET_REQUEST_STATE_PAYMENT_PROVIDER,
            res
          );
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          commit(
            PaymentProviderMutations.SET_REQUEST_STATE_PAYMENT_PROVIDER,
            err
          );
          commit(
            PaymentProviderMutations.SET_IS_LOADING_PAYMENT_PROVIDERS_LIST,
            false
          );
          reject(err);
        });
    });
  },

  async getPaymentProvidersByInvoiceId(
    { commit },
    {
      invoiceProviderId = null,
      startTo = null,
      endTo = null,
      updateState = true,
    }: {
      invoiceProviderId: number;
      creditProviderId: number;
      startTo: Date;
      endTo: Date;
      updateState: boolean;
    }
  ): Promise<PaymentProviderRequestDto> {
    return new Promise(async (resolve, reject) => {
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/PaymentProvider/GetPaymentProvidersByInvoiceProviderId",
          {
            params: {
              invoiceProviderId: invoiceProviderId,
              startTo: startTo ? dayjs(startTo).format("YYYY-MM-DD") : null,
              endTo: endTo
                ? dayjs(endTo)
                    .hour(23)
                    .minute(59)
                    .second(59)
                    .format("YYYY-MM-DD HH:mm:ss")
                : null,
            },
          }
        )
        .then((res) => {
          let list = res.data.map((item: any) => {
            return {
              ...item,
              paymentProviderMethodId: item.paymentProviderMethod
                ? item.paymentProviderMethod.id
                : null,
              date: item.date
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(new Date(item.date))
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              remittedBankDate: item.remittedBankDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(item.remittedBankDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
            };
          });
          commit(
            PaymentProviderMutations.SET_REQUEST_STATE_PAYMENT_PROVIDER,
            res
          );
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          commit(
            PaymentProviderMutations.SET_REQUEST_STATE_PAYMENT_PROVIDER,
            err
          );
          reject(err);
        });
    });
  },

  async getPaymentProvidersByCreditId(
    { commit },
    {
      creditProviderId = null,
      startTo = null,
      endTo = null,
      updateState = true,
    }: {
      creditProviderId: number;
      startTo: Date;
      endTo: Date;
      updateState: boolean;
    }
  ): Promise<PaymentProviderRequestDto> {
    return new Promise(async (resolve, reject) => {
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/PaymentProvider/GetPaymentProvidersByCreditProviderId",
          {
            params: {
              creditProviderId: creditProviderId,
              startTo: startTo ? dayjs(startTo).format("YYYY-MM-DD") : null,
              endTo: endTo
                ? dayjs(endTo)
                    .hour(23)
                    .minute(59)
                    .second(59)
                    .format("YYYY-MM-DD HH:mm:ss")
                : null,
            },
          }
        )
        .then((res) => {
          let list = res.data.map((item: any) => {
            return {
              ...item,
              paymentProviderMethodId: item.paymentProviderMethod
                ? item.paymentProviderMethod.id
                : null,
              date: item.date
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(new Date(item.date))
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              remittedBankDate: item.remittedBankDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(item.remittedBankDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
            };
          });
          commit(
            PaymentProviderMutations.SET_REQUEST_STATE_PAYMENT_PROVIDER,
            res
          );
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          commit(
            PaymentProviderMutations.SET_REQUEST_STATE_PAYMENT_PROVIDER,
            err
          );
          reject(err);
        });
    });
  },

  // async getPaymentProvidersByBankAccountId() {},

  async createPaymentProvider(
    { commit, rootGetters },
    {
      paymentProvider,
      remainsToBeDeducted,
    }: {
      paymentProvider: PaymentProviderRequestDto;
      remainsToBeDeducted: number;
    }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(PaymentProviderMutations.SET_IS_CREATING_PAYMENT_PROVIDER, true);
      await axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/PaymentProvider/CreatePaymentProvider",
          paymentProvider
        )
        .then((res) => {
          rootGetters.paymentProvidersList.unshift({
            ...res.data.data,
            remainsToBeDeducted: remainsToBeDeducted,
          });
          commit(
            PaymentProviderMutations.SET_PAYMENT_PROVIDERS_LIST,
            rootGetters.paymentProvidersList
          );
          commit(
            PaymentProviderMutations.SET_REQUEST_STATE_PAYMENT_PROVIDER,
            res
          );
          commit(
            PaymentProviderMutations.SET_IS_CREATING_PAYMENT_PROVIDER,
            false
          );
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            PaymentProviderMutations.SET_REQUEST_STATE_PAYMENT_PROVIDER,
            err
          );
          commit(
            PaymentProviderMutations.SET_IS_CREATING_PAYMENT_PROVIDER,
            false
          );
          reject(err);
        });
    });
  },

  async updatePaymentProvider(
    { commit, rootGetters },
    {
      paymentProvider,
      remainsToBeDeducted,
    }: {
      paymentProvider: PaymentProviderRequestDto;
      remainsToBeDeducted: number;
    }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(PaymentProviderMutations.SET_IS_UPDATING_PAYMENT_PROVIDER, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/PaymentProvider/UpdatePaymentProvider",
          paymentProvider
        )
        .then((res) => {
          rootGetters.paymentProvidersList.splice(
            rootGetters.paymentProvidersList.findIndex(
              (elem) => elem.id == paymentProvider.id
            ),
            1,
            { ...paymentProvider, remainsToBeDeducted: remainsToBeDeducted }
          );
          commit(
            PaymentProviderMutations.SET_PAYMENT_PROVIDERS_LIST,
            rootGetters.paymentProvidersList
          );
          commit(
            PaymentProviderMutations.SET_REQUEST_STATE_PAYMENT_PROVIDER,
            res
          );
          commit(
            PaymentProviderMutations.SET_IS_UPDATING_PAYMENT_PROVIDER,
            false
          );
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            PaymentProviderMutations.SET_REQUEST_STATE_PAYMENT_PROVIDER,
            err
          );
          commit(
            PaymentProviderMutations.SET_IS_UPDATING_PAYMENT_PROVIDER,
            false
          );
          reject(err);
        });
    });
  },

  async updateInvoiceProviderPaymentProvider(
    { commit, dispatch, rootGetters },
    {
      paymentProviderId,
      invoicesProvider,
    }: { paymentProviderId: number; invoicesProvider: any }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(PaymentProviderMutations.SET_IS_LINKING_PAYMENT_PROVIDER, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/PaymentProvider/UpdateInvoicePaymentProvider",
          invoicesProvider,
          {
            params: {
              paymentProviderId: paymentProviderId,
            },
          }
        )
        .then((res) => {
          let invoiceProviderFilters = rootGetters.userParametersList.find(
            (elem) => elem.key == "invoiceProviderFilters"
          );
          if (invoiceProviderFilters) {
            dispatch("getInvoicesProviders", {
              dateFrom: JSON.parse(invoiceProviderFilters.value).dateRange
                .startDate,
              dateTo: JSON.parse(invoiceProviderFilters.value).dateRange
                .endDate,
            });
          } else {
            dispatch("setFilter", {
              name: "invoiceProviderFilters",
              filter: {
                dateRangeType: 7,
                dateRange: { startDate: startDate, endDate: endDate },
              },
            });
            dispatch("getInvoicesProviders", {
              dateFrom: startDate,
              dateTo: endDate,
            });
          }
          commit(
            PaymentProviderMutations.SET_REQUEST_STATE_PAYMENT_PROVIDER,
            res
          );
          commit(
            PaymentProviderMutations.SET_IS_LINKING_PAYMENT_PROVIDER,
            false
          );
          resolve();
        })
        .catch((err) => {
          console.error(err);
          commit(
            PaymentProviderMutations.SET_REQUEST_STATE_PAYMENT_PROVIDER,
            err
          );
          commit(
            PaymentProviderMutations.SET_IS_LINKING_PAYMENT_PROVIDER,
            false
          );
          reject(err);
        });
    });
  },

  async updateCreditProviderPaymentProvider(
    { commit, dispatch, rootGetters },
    {
      paymentProviderId,
      creditsProvider,
    }: { paymentProviderId: number; creditsProvider: any }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(PaymentProviderMutations.SET_IS_LINKING_PAYMENT_PROVIDER, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/PaymentProvider/UpdateCreditPaymentProvider",
          creditsProvider,
          {
            params: {
              paymentProviderId: paymentProviderId,
            },
          }
        )
        .then((res) => {
          let creditProviderFilters = rootGetters.userParametersList.find(
            (elem) => elem.key == "creditProviderFilters"
          );
          if (creditProviderFilters) {
            dispatch("getCreditsProviders", {
              startTo: JSON.parse(creditProviderFilters.value).dateRange
                .startDate,
              endTo: JSON.parse(creditProviderFilters.value).dateRange.endDate,
            });
          } else {
            dispatch("setFilter", {
              name: "creditProviderFilters",
              filter: {
                dateRangeType: 7,
                dateRange: { startDate: startDate, endDate: endDate },
              },
            });
            dispatch("getCreditsProviders", {
              startTo: startDate,
              endTo: endDate,
            });
          }
          commit(
            PaymentProviderMutations.SET_REQUEST_STATE_PAYMENT_PROVIDER,
            res
          );
          commit(
            PaymentProviderMutations.SET_IS_LINKING_PAYMENT_PROVIDER,
            false
          );
          resolve();
        })
        .catch((err) => {
          console.error(err);
          commit(
            PaymentProviderMutations.SET_REQUEST_STATE_PAYMENT_PROVIDER,
            err
          );
          commit(
            PaymentProviderMutations.SET_IS_LINKING_PAYMENT_PROVIDER,
            false
          );
          reject(err);
        });
    });
  },

  async unlinkCreditProviderPaymentProvider(
    { commit, rootGetters },
    {
      paymentProvider,
      creditProviderIds,
      amount,
    }: {
      paymentProvider: IPaymentProvider;
      creditProviderIds: number[];
      amount: number;
    }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(PaymentProviderMutations.SET_IS_UNLINKING_PAYMENT_PROVIDER, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/PaymentProvider/UnlinkCreditProviderPaymentProvider",
          creditProviderIds,
          {
            params: {
              paymentProviderId: paymentProvider.id,
            },
          }
        )
        .then((res) => {
          for (let i = 0; i < creditProviderIds.length; i++) {
            const creditId = creditProviderIds[i];
            paymentProvider.creditProviders.splice(
              paymentProvider.creditProviders.findIndex(
                (elem) => elem.id == creditId
              ),
              1
            );
          }
          commit(
            PaymentProviderMutations.SET_PAYMENT_PROVIDER,
            paymentProvider
          );
          rootGetters.paymentProvidersList.splice(
            rootGetters.paymentProvidersList.findIndex(
              (elem) => elem.id == paymentProvider.id
            ),
            1,
            {
              ...paymentProvider,
              remainsToBeDeducted:
                Math.round(
                  (paymentProvider.remainsToBeDeducted + amount) * 100
                ) / 100,
            }
          );
          commit(
            PaymentProviderMutations.SET_PAYMENT_PROVIDERS_LIST,
            rootGetters.paymentProvidersList
          );
          commit(
            PaymentProviderMutations.SET_REQUEST_STATE_PAYMENT_PROVIDER,
            res
          );
          commit(
            PaymentProviderMutations.SET_IS_UNLINKING_PAYMENT_PROVIDER,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            PaymentProviderMutations.SET_REQUEST_STATE_PAYMENT_PROVIDER,
            err
          );
          commit(
            PaymentProviderMutations.SET_IS_UNLINKING_PAYMENT_PROVIDER,
            false
          );
          reject(err);
        });
    });
  },

  async unlinkInvoiceProviderPaymentProvider(
    { commit, dispatch, rootGetters },
    {
      paymentProvider,
      invoiceProviderIds,
      amount,
    }: {
      paymentProvider: IPaymentProvider;
      invoiceProviderIds: number[];
      amount: number;
    }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(PaymentProviderMutations.SET_IS_UNLINKING_PAYMENT_PROVIDER, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/PaymentProvider/UnlinkInvoiceProviderPaymentProvider",
          invoiceProviderIds,
          {
            params: {
              paymentProviderId: paymentProvider.id,
            },
          }
        )
        .then((res) => {
          for (let i = 0; i < invoiceProviderIds.length; i++) {
            const invoiceId = invoiceProviderIds[i];
            paymentProvider.invoiceProviders.splice(
              paymentProvider.invoiceProviders.findIndex(
                (elem) => elem.id == invoiceId
              ),
              1
            );
          }
          commit(
            PaymentProviderMutations.SET_PAYMENT_PROVIDER,
            paymentProvider
          );
          rootGetters.paymentProvidersList.splice(
            rootGetters.paymentProvidersList.findIndex(
              (elem) => elem.id == paymentProvider.id
            ),
            1,
            {
              ...paymentProvider,
              remainsToBeDeducted:
                Math.round(
                  (paymentProvider.remainsToBeDeducted + amount) * 100
                ) / 100,
            }
          );
          commit(
            PaymentProviderMutations.SET_PAYMENT_PROVIDERS_LIST,
            rootGetters.paymentProvidersList
          );
          let invoiceProviderFilters = rootGetters.userParametersList.find(
            (elem) => elem.key == "invoiceProviderFilters"
          );
          if (invoiceProviderFilters) {
            dispatch("getInvoicesProviders", {
              dateFrom: JSON.parse(invoiceProviderFilters.value).dateRange
                .startDate,
              dateTo: JSON.parse(invoiceProviderFilters.value).dateRange
                .endDate,
            });
          } else {
            dispatch("setFilter", {
              name: "invoiceProviderFilters",
              filter: {
                dateRangeType: 7,
                dateRange: { startDate: startDate, endDate: endDate },
              },
            });
            dispatch("getInvoicesProviders", {
              dateFrom: startDate,
              dateTo: endDate,
            });
          }
          commit(
            PaymentProviderMutations.SET_REQUEST_STATE_PAYMENT_PROVIDER,
            res
          );
          commit(
            PaymentProviderMutations.SET_IS_UNLINKING_PAYMENT_PROVIDER,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            PaymentProviderMutations.SET_REQUEST_STATE_PAYMENT_PROVIDER,
            err
          );
          commit(
            PaymentProviderMutations.SET_IS_UNLINKING_PAYMENT_PROVIDER,
            false
          );
          reject(err);
        });
    });
  },

  async deletePaymentProvider(
    { commit, rootGetters },
    { paymentProviderIds = [] }: { paymentProviderIds: number[] }
  ): Promise<void> {
    commit(PaymentProviderMutations.SET_IS_DELETING_PAYMENT_PROVIDER, true);
    await axios
      .delete(
        process.env.VUE_APP_API_ORISIS + "Purchase/PaymentProvider/Delete",
        {
          data: paymentProviderIds,
        }
      )
      .then((res) => {
        paymentProviderIds.forEach((paymentProviderId: number) => {
          rootGetters.paymentProvidersList.splice(
            rootGetters.paymentProvidersList.findIndex(
              (elem) => elem.id == paymentProviderId
            ),
            1
          );
        });
        commit(
          PaymentProviderMutations.SET_PAYMENT_PROVIDERS_LIST,
          rootGetters.paymentProvidersList
        );
        commit(
          PaymentProviderMutations.SET_REQUEST_STATE_PAYMENT_PROVIDER,
          res
        );
        commit(
          PaymentProviderMutations.SET_IS_DELETING_PAYMENT_PROVIDER,
          false
        );
      })
      .catch((err) => {
        console.error(err);
        commit(
          PaymentProviderMutations.SET_REQUEST_STATE_PAYMENT_PROVIDER,
          err
        );
        commit(
          PaymentProviderMutations.SET_IS_DELETING_PAYMENT_PROVIDER,
          false
        );
      });
  },
};
