import { IPaymentProviderState } from "./states";
import { MutationTree } from "vuex";
import { IPaymentProvider } from "@/types/api-orisis/interfaces/Purchase/paymentProvider/IPaymentProvider";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";


export enum PaymentProviderMutations{
    SET_PAYMENT_PROVIDERS_LIST = "SET_PAYMENT_PROVIDERS_LIST",
    SET_PAYMENT_PROVIDERS_LIST_ARCHIVED = "SET_PAYMENT_PROVIDERS_LIST_ARCHIVED",
    SET_PAYMENT_PROVIDER = "SET_PAYMENT_PROVIDER",

    //FILTERS
    SET_PAYMENT_PROVIDER_FILTERS = "SET_PAYMENT_PROVIDER_FILTERS",
    
    // COMON 
    SET_IS_LOADING_PAYMENT_PROVIDERS_LIST = "SET_IS_LOADING_PAYMENT_PROVIDERS_LIST",
    SET_IS_LOADING_PAYMENT_PROVIDERS_LIST_ARCHIVED = "SET_IS_LOADING_PAYMENT_PROVIDERS_LIST_ARCHIVED",
    SET_IS_LOADING_PAYMENT_PROVIDER = "SET_IS_LOADING_PAYMENT_PROVIDER",
    SET_IS_CREATING_PAYMENT_PROVIDER = "SET_IS_CREATING_PAYMENT_PROVIDER",
    SET_IS_UPDATING_PAYMENT_PROVIDER = "SET_IS_UPDATING_PAYMENT_PROVIDER",
    SET_IS_DELETING_PAYMENT_PROVIDER = "SET_IS_DELETING_PAYMENT_PROVIDER",
    SET_IS_ARCHIVING_PAYMENT_PROVIDER = "SET_IS_ARCHIVING_PAYMENT_PROVIDER",
    SET_IS_LINKING_PAYMENT_PROVIDER = "SET_IS_LINKING_PAYMENT_PROVIDER",
    SET_IS_UNLINKING_PAYMENT_PROVIDER = "SET_IS_UNLINKING_PAYMENT_PROVIDER",
    SET_REQUEST_STATE_PAYMENT_PROVIDER = "SET_REQUEST_STATE_PAYMENT_PROVIDER",
  }

  export const mutations: MutationTree<IPaymentProviderState> = {
    [PaymentProviderMutations.SET_PAYMENT_PROVIDERS_LIST_ARCHIVED]: (state, payload: IPaymentProvider[]) => {
        state.paymentProvidersListArchived = payload;
    },
    [PaymentProviderMutations.SET_PAYMENT_PROVIDERS_LIST]: (state, payload: IPaymentProvider[]) => {
        state.paymentProvidersList = payload;
    },
    [PaymentProviderMutations.SET_PAYMENT_PROVIDER]: (state, payload: IPaymentProvider) => {
        state.paymentProvider = payload;
    },

    //FILTERS
    [PaymentProviderMutations.SET_PAYMENT_PROVIDER_FILTERS]: (state, payload: any) => {
        state.paymentProviderFilters = payload;
    },

    //COMMON
    [PaymentProviderMutations.SET_IS_LOADING_PAYMENT_PROVIDERS_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingPaymentProvidersListArchived = payload;
    },
    [PaymentProviderMutations.SET_IS_LOADING_PAYMENT_PROVIDERS_LIST]: (state, payload: boolean) => {
        state.isLoadingPaymentProvidersList = payload;
    },
    [PaymentProviderMutations.SET_IS_LOADING_PAYMENT_PROVIDER]: (state, payload: boolean) => {
        state.isLoadingPaymentProvider = payload;
    },
    [PaymentProviderMutations.SET_IS_CREATING_PAYMENT_PROVIDER]: (state, payload: boolean) => {
        state.isCreatingPaymentProvider = payload;
    },
    [PaymentProviderMutations.SET_IS_UPDATING_PAYMENT_PROVIDER]: (state, payload: boolean) => {
        state.isUpdatingPaymentProvider = payload;
    },
    [PaymentProviderMutations.SET_IS_DELETING_PAYMENT_PROVIDER]: (state, payload: boolean) => {
        state.isDeletingPaymentProvider = payload;
    },
    [PaymentProviderMutations.SET_IS_ARCHIVING_PAYMENT_PROVIDER]: (state, payload: boolean) => {
        state.isArchivingPaymentProvider = payload;
    },
    [PaymentProviderMutations.SET_IS_LINKING_PAYMENT_PROVIDER]: (state, payload: boolean) => {
        state.isLinkingPaymentProvider = payload;
    },
    [PaymentProviderMutations.SET_IS_UNLINKING_PAYMENT_PROVIDER]: (state, payload: boolean) => {
        state.isUnlinkingPaymentProvider = payload;
    },

    
    [PaymentProviderMutations.SET_REQUEST_STATE_PAYMENT_PROVIDER]: (state, payload: IRequestState) => {
        state.requestStatePaymentProvider = payload;
    },
}