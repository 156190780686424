import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { ICreditOptionDefaultState } from "./states";
import { CreditOptionRequestDto } from "@/../Api";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export const getters: GetterTree<ICreditOptionDefaultState, RootState> = {
  creditOptionDefault: (state): CreditOptionRequestDto =>
    state.creditOptionDefault,
  creditOptionDefaultForm: (state): CreditOptionRequestDto =>
    state.creditOptionDefaultForm,
  isLoadingCreditOptionDefault: (state): boolean =>
    state.isLoadingCreditOptionDefault,
  requestStateCreditOptionDefault: (state): IRequestState =>
    state.requestStateCreditOptionDefault,
};
