import { MutationTree } from "vuex";
import { IOrderFormOptionDefaultState } from "./states";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { OrderFormOptionRequestDto } from "Api";

export const enum OrderFormOptionDefaultMutations {
  SET_ORDER_FORM_OPTION_DEFAULT = "SET_ORDER_FORM_OPTION_DEFAULT",
  SET_ORDER_FORM_OPTION_DEFAULT_FORM = "SET_ORDER_FORM_OPTION_DEFAULT_FORM",
  SET_IS_LOADING_ORDER_FORM_OPTION_DEFAULT = "SET_IS_LOADING_ORDER_FORM_OPTION_DEFAULT",
  SET_REQUEST_STATE_ORDER_FORM_OPTION_DEFAULT = "SET_REQUEST_STATE_ORDER_FORM_OPTION_DEFAULT",
}

export const mutations: MutationTree<IOrderFormOptionDefaultState> = {
  [OrderFormOptionDefaultMutations.SET_ORDER_FORM_OPTION_DEFAULT](
    state,
    payload: OrderFormOptionRequestDto
  ) {
    state.orderFormOptionDefault = payload;
  },
  [OrderFormOptionDefaultMutations.SET_ORDER_FORM_OPTION_DEFAULT_FORM](
    state,
    payload: OrderFormOptionRequestDto
  ) {
    state.orderFormOptionDefaultForm = payload;
  },
  [OrderFormOptionDefaultMutations.SET_IS_LOADING_ORDER_FORM_OPTION_DEFAULT](
    state,
    payload: boolean
  ) {
    state.isLoadingOrderFormOptionDefault = payload;
  },
  [OrderFormOptionDefaultMutations.SET_REQUEST_STATE_ORDER_FORM_OPTION_DEFAULT](
    state,
    payload: IRequestState
  ) {
    state.requestStateOrderFormOptionDefault = payload;
  },
};
