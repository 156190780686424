import { MutationTree } from "vuex";
import { IDeliveryFormOptionState } from "./states";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { DeliveryFormOptionRequestDto } from "Api";

export const enum DeliveryFormOptionMutations {
  SET_DELIVERY_FORM_OPTION = "SET_DELIVERY_FORM_OPTION",
  SET_IS_LOADING_DELIVERY_FORM_OPTION = "SET_IS_LOADING_DELIVERY_FORM_OPTION",
  SET_REQUEST_STATE_DELIVERY_FORM_OPTION = "SET_REQUEST_STATE_DELIVERY_FORM_OPTION",
}

export const mutations: MutationTree<IDeliveryFormOptionState> = {
  [DeliveryFormOptionMutations.SET_DELIVERY_FORM_OPTION](
    state,
    payload: DeliveryFormOptionRequestDto
  ) {
    state.deliveryFormOption = payload;
  },
  [DeliveryFormOptionMutations.SET_IS_LOADING_DELIVERY_FORM_OPTION](
    state,
    payload: boolean
  ) {
    state.isLoadingDeliveryFormOption = payload;
  },
  [DeliveryFormOptionMutations.SET_REQUEST_STATE_DELIVERY_FORM_OPTION](
    state,
    payload: IRequestState
  ) {
    state.requestStateDeliveryFormOption = payload;
  },
};
