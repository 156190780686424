import { CustomFieldRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { CustomFieldMutations } from "./mutations";
import { ICustomFieldState } from "./states";
var dayjs = require("dayjs");

export const actions: ActionTree<ICustomFieldState, RootState> = {
  async getCustomFields(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState)
        commit(CustomFieldMutations.SET_IS_LOADING_CUSTOM_FIELD_LIST, true);
      let url = `${process.env.VUE_APP_API_ORISIS}Parameters/CustomField/GetCustomFields`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              CustomFieldMutations.SET_IS_LOADING_CUSTOM_FIELD_LIST,
              false
            );
            commit(CustomFieldMutations.SET_CUSTOM_FIELDS_LIST, res.data);
          }
          commit(CustomFieldMutations.SET_REQUEST_STATE_CUSTOM_FIELD, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CustomFieldMutations.SET_IS_LOADING_CUSTOM_FIELD_LIST, false);
          commit(CustomFieldMutations.SET_REQUEST_STATE_CUSTOM_FIELD, err);
          reject(err);
        });
    });
  },

  async getCustomFieldById(
    { commit },
    {
      customFieldId,
      updateState = true,
    }: { customFieldId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState)
        commit(CustomFieldMutations.SET_IS_LOADING_CUSTOM_FIELD, true);
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Parameters/CustomField/GetCustomFieldById`,
          {
            params: {
              customFieldId: customFieldId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(CustomFieldMutations.SET_CUSTOM_FIELD, res.data);
            commit(CustomFieldMutations.SET_IS_LOADING_CUSTOM_FIELD, false);
          }
          commit(CustomFieldMutations.SET_REQUEST_STATE_CUSTOM_FIELD, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CustomFieldMutations.SET_REQUEST_STATE_CUSTOM_FIELD, err);
          commit(CustomFieldMutations.SET_IS_LOADING_CUSTOM_FIELD, false);
          reject(err);
        });
    });
  },

  async getCustomFieldsArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          CustomFieldMutations.SET_IS_LOADING_CUSTOM_FIELD_LIST_ARCHIVED,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Parameters/CustomField/GetCustomFieldsArchived`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              CustomFieldMutations.SET_IS_LOADING_CUSTOM_FIELD_LIST_ARCHIVED,
              false
            );
            commit(
              CustomFieldMutations.SET_CUSTOM_FIELDS_LIST_ARCHIVED,
              res.data
            );
          }
          commit(CustomFieldMutations.SET_REQUEST_STATE_CUSTOM_FIELD, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CustomFieldMutations.SET_IS_LOADING_CUSTOM_FIELD_LIST, false);
          commit(CustomFieldMutations.SET_REQUEST_STATE_CUSTOM_FIELD, err);
          reject(err);
        });
    });
  },

  async getCustomFieldArchivedById(
    { commit },
    {
      customFieldId,
      updateState = true,
    }: { customFieldId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState)
        commit(CustomFieldMutations.SET_IS_LOADING_CUSTOM_FIELD, true);
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Parameters/CustomField/GetCustomFieldArhivedById`,
          {
            params: {
              customFieldId: customFieldId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(CustomFieldMutations.SET_CUSTOM_FIELD, res.data);
            commit(CustomFieldMutations.SET_IS_LOADING_CUSTOM_FIELD, false);
          }
          commit(CustomFieldMutations.SET_REQUEST_STATE_CUSTOM_FIELD, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CustomFieldMutations.SET_REQUEST_STATE_CUSTOM_FIELD, err);
          commit(CustomFieldMutations.SET_IS_LOADING_CUSTOM_FIELD, false);
          reject(err);
        });
    });
  },

  async createCustomField(
    { commit, rootGetters },
    { customField }: { customField: CustomFieldRequestDto }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(CustomFieldMutations.SET_IS_CREATING_CUSTOM_FIELD, true);
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Parameters/CustomField/CreateCustomField`,
          customField
        )
        .then((res) => {
          rootGetters.customFieldsList.push(res.data.data);
          commit(
            CustomFieldMutations.SET_CUSTOM_FIELDS_LIST,
            rootGetters.customFieldsList
          );
          commit(CustomFieldMutations.SET_REQUEST_STATE_CUSTOM_FIELD, res);
          commit(CustomFieldMutations.SET_IS_CREATING_CUSTOM_FIELD, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CustomFieldMutations.SET_REQUEST_STATE_CUSTOM_FIELD, err);
          commit(CustomFieldMutations.SET_IS_CREATING_CUSTOM_FIELD, false);
          reject(err);
        });
    });
  },

  async updateCustomField(
    { commit, rootGetters },
    { customField }: { customField: CustomFieldRequestDto }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(CustomFieldMutations.SET_IS_UPDATING_CUSTOM_FIELD, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Parameters/CustomField/UpdateCustomField`,
          customField
        )
        .then((res) => {
          if (
            rootGetters.customFieldsList.find(
              (elem) => elem.id == customField.id
            )
          ) {
            rootGetters.customFieldsList.splice(
              rootGetters.customFieldsList.findIndex(
                (elem) => elem.id == customField.id
              ),
              1,
              customField
            );
            commit(
              CustomFieldMutations.SET_CUSTOM_FIELDS_LIST,
              rootGetters.customFieldsList
            );
          }

          if (
            rootGetters.customFieldsListArchived.find(
              (elem) => elem.id == customField.id
            )
          ) {
            rootGetters.customFieldsListArchived.splice(
              rootGetters.customFieldsListArchived.findIndex(
                (elem) => elem.id == customField.id
              ),
              1,
              customField
            );
            commit(
              CustomFieldMutations.SET_CUSTOM_FIELDS_LIST_ARCHIVED,
              rootGetters.customFieldsListArchived
            );
          }

          commit(CustomFieldMutations.SET_REQUEST_STATE_CUSTOM_FIELD, res);
          commit(CustomFieldMutations.SET_IS_UPDATING_CUSTOM_FIELD, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CustomFieldMutations.SET_REQUEST_STATE_CUSTOM_FIELD, err);
          commit(CustomFieldMutations.SET_IS_UPDATING_CUSTOM_FIELD, false);
          reject(err);
        });
    });
  },

  async restoreCustomFields(
    { commit, rootGetters },
    { customFieldIds = [] }: { customFieldIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(CustomFieldMutations.SET_IS_UPDATING_CUSTOM_FIELD, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Parameters/CustomField/Restore",
          customFieldIds
        )
        .then(async (res) => {
          customFieldIds.forEach((customFieldId: number) => {
            let customField = rootGetters.customFieldsListArchived.find(
              (customField: CustomFieldRequestDto) =>
                customField.id == customFieldId
            );
            rootGetters.customFieldsList.unshift(customField);

            if (
              rootGetters.customFieldsListArchived.find(
                (customField: CustomFieldRequestDto) =>
                  customField.id == customFieldId
              )
            ) {
              rootGetters.customFieldsListArchived.splice(
                rootGetters.customFieldsListArchived.findIndex(
                  (customField: CustomFieldRequestDto) =>
                    customField.id == customFieldId
                ),
                1
              );
            }
          });
          commit(
            CustomFieldMutations.SET_CUSTOM_FIELDS_LIST,
            rootGetters.customFieldsList
          );
          commit(
            CustomFieldMutations.SET_CUSTOM_FIELDS_LIST_ARCHIVED,
            rootGetters.customFieldsListArchived
          );
          commit(CustomFieldMutations.SET_REQUEST_STATE_CUSTOM_FIELD, res);
          commit(CustomFieldMutations.SET_IS_UPDATING_CUSTOM_FIELD, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CustomFieldMutations.SET_REQUEST_STATE_CUSTOM_FIELD, err);
          commit(CustomFieldMutations.SET_IS_UPDATING_CUSTOM_FIELD, false);
          reject(err);
        });
    });
  },

  async archiveCustomFields(
    { commit, rootGetters },
    { customFieldIds = [] }: { customFieldIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(CustomFieldMutations.SET_IS_ARCHIVING_CUSTOM_FIELD, true);
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Parameters/CustomField/Archive",
          {
            data: customFieldIds,
          }
        )
        .then((res) => {
          customFieldIds.forEach((customFieldId: number) => {
            let customField = rootGetters.customFieldsList.find(
              (customField: CustomFieldRequestDto) =>
                customField.id == customFieldId
            );
            rootGetters.customFieldsListArchived.unshift(customField);

            if (
              rootGetters.customFieldsList.find(
                (customField: CustomFieldRequestDto) =>
                  customField.id == customFieldId
              )
            ) {
              rootGetters.customFieldsList.splice(
                rootGetters.customFieldsList.findIndex(
                  (customField: CustomFieldRequestDto) =>
                    customField.id == customFieldId
                ),
                1
              );
            }
          });
          commit(
            CustomFieldMutations.SET_CUSTOM_FIELDS_LIST,
            rootGetters.customFieldsList
          );
          commit(
            CustomFieldMutations.SET_CUSTOM_FIELDS_LIST_ARCHIVED,
            rootGetters.customFieldsListArchived
          );
          commit(CustomFieldMutations.SET_REQUEST_STATE_CUSTOM_FIELD, res);
          commit(CustomFieldMutations.SET_IS_ARCHIVING_CUSTOM_FIELD, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CustomFieldMutations.SET_REQUEST_STATE_CUSTOM_FIELD, err);
          commit(CustomFieldMutations.SET_IS_ARCHIVING_CUSTOM_FIELD, false);
          reject(err);
        });
    });
  },

  async deleteCustomFields(
    { commit, rootGetters },
    { customFieldIds = [] }: { customFieldIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(CustomFieldMutations.SET_IS_DELETING_CUSTOM_FIELD, true);
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Parameters/CustomField/Delete",
          {
            data: customFieldIds,
          }
        )
        .then((res) => {
          customFieldIds.forEach((customFieldId: number) => {
            if (
              rootGetters.customFieldsList.find(
                (customField: CustomFieldRequestDto) =>
                  customField.id == customFieldId
              )
            ) {
              rootGetters.customFieldsList.splice(
                rootGetters.customFieldsList.findIndex(
                  (customField: CustomFieldRequestDto) =>
                    customField.id == customFieldId
                ),
                1
              );
            }

            if (
              rootGetters.customFieldsListArchived.find(
                (customField: CustomFieldRequestDto) =>
                  customField.id == customFieldId
              )
            ) {
              rootGetters.customFieldsListArchived.splice(
                rootGetters.customFieldsListArchived.findIndex(
                  (customField: CustomFieldRequestDto) =>
                    customField.id == customFieldId
                ),
                1
              );
            }
          });
          commit(
            CustomFieldMutations.SET_CUSTOM_FIELDS_LIST,
            rootGetters.customFieldsList
          );
          commit(
            CustomFieldMutations.SET_CUSTOM_FIELDS_LIST_ARCHIVED,
            rootGetters.customFieldsListArchived
          );

          commit(CustomFieldMutations.SET_REQUEST_STATE_CUSTOM_FIELD, res);
          commit(CustomFieldMutations.SET_IS_DELETING_CUSTOM_FIELD, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CustomFieldMutations.SET_REQUEST_STATE_CUSTOM_FIELD, err);
          commit(CustomFieldMutations.SET_IS_DELETING_CUSTOM_FIELD, false);
          reject(err);
        });
    });
  },
};
