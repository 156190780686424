import { FiscalYearRequestDto } from "Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { FiscalYearMutations } from "./mutations";
import { IFiscalYearState } from "./states";

export const actions: ActionTree<IFiscalYearState, RootState> = {
  async getFiscalYearById(
    { commit },
    {
      fiscalYearId,
      updateState = true,
    }: { fiscalYearId: number; updateState: boolean }
  ): Promise<FiscalYearRequestDto> {
    return new Promise(async (resolve, reject) => {
      if (updateState)
        commit(FiscalYearMutations.SET_IS_LOADING_FISCAL_YEAR, true);
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS + "Sale/FiscalYear/GetFiscalYearById",
          {
            params: {
              fiscalYearId: fiscalYearId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(FiscalYearMutations.SET_FISCAL_YEAR, res.data);
            commit(FiscalYearMutations.SET_IS_LOADING_FISCAL_YEAR, false);
          }
          commit(FiscalYearMutations.SET_REQUEST_STATE_FISCAL_YEAR, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(FiscalYearMutations.SET_REQUEST_STATE_FISCAL_YEAR, err);
          commit(FiscalYearMutations.SET_IS_LOADING_FISCAL_YEAR, false);
          reject(err);
        });
    });
  },

  async getFiscalYearByInstitutionSettingId(
    { commit },
    {
      institutionSettingId,
      updateState = true,
    }: { institutionSettingId: number; updateState: boolean }
  ): Promise<FiscalYearRequestDto> {
    return new Promise(async (resolve, reject) => {
      if (updateState)
        commit(FiscalYearMutations.SET_IS_LOADING_FISCAL_YEAR_LIST, true);
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/FiscalYear/GetFiscalYearByInstitutionSettingId",
          {
            params: {
              institutionSettingId: institutionSettingId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(FiscalYearMutations.SET_FISCAL_YEAR_LIST, res.data);
            commit(FiscalYearMutations.SET_IS_LOADING_FISCAL_YEAR_LIST, false);
          }
          commit(FiscalYearMutations.SET_REQUEST_STATE_FISCAL_YEAR, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(FiscalYearMutations.SET_REQUEST_STATE_FISCAL_YEAR, err);
          commit(FiscalYearMutations.SET_IS_LOADING_FISCAL_YEAR_LIST, false);
          reject(err);
        });
    });
  },

  async createFiscalYear(
    { commit, dispatch },
    { fiscalYear }: { fiscalYear: FiscalYearRequestDto }
  ): Promise<FiscalYearRequestDto> {
    return new Promise(async (resolve, reject) => {
      commit(FiscalYearMutations.SET_IS_CREATING_FISCAL_YEAR, true);
      await axios
        .post(
          process.env.VUE_APP_API_ORISIS + "Sale/FiscalYear/CreateFiscalYear",
          fiscalYear
        )
        .then(async (res) => {
          await dispatch("getFiscalYearByInstitutionSettingId", {
            institutionSettingId: fiscalYear.institutionSettingId,
          });
          commit(FiscalYearMutations.SET_REQUEST_STATE_FISCAL_YEAR, res);
          commit(FiscalYearMutations.SET_IS_CREATING_FISCAL_YEAR, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(FiscalYearMutations.SET_REQUEST_STATE_FISCAL_YEAR, err);
          commit(FiscalYearMutations.SET_IS_CREATING_FISCAL_YEAR, false);
          reject(err);
        });
    });
  },

  async deleteFiscalYears(
    { commit, rootGetters },
    { fiscalYearIds = [] }: { fiscalYearIds: number[] }
  ): Promise<FiscalYearRequestDto> {
    return new Promise(async (resolve, reject) => {
      commit(FiscalYearMutations.SET_IS_DELETING_FISCAL_YEAR, true);
      await axios
        .delete(process.env.VUE_APP_API_ORISIS + "Sale/FiscalYear/Delete", {
          data: fiscalYearIds,
        })
        .then((res) => {
          fiscalYearIds.forEach((fiscalYearId: number) => {
            rootGetters.profilesListArchived.splice(
              rootGetters.profilesListArchived.findIndex(
                (fiscalYear) => fiscalYear.id == fiscalYearId
              ),
              1
            );
          });
          commit(FiscalYearMutations.SET_REQUEST_STATE_FISCAL_YEAR, res);
          commit(FiscalYearMutations.SET_IS_DELETING_FISCAL_YEAR, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(FiscalYearMutations.SET_REQUEST_STATE_FISCAL_YEAR, err);
          commit(FiscalYearMutations.SET_IS_DELETING_FISCAL_YEAR, false);
          reject(err);
        });
    });
  },
};
