import { ModalEditRefProductState } from "./states";
import { Module } from "vuex";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import RootState from "@/store/modules/rootState";
import { ReceiptFormDetailRequestDto } from "@/../Api";

const state: ModalEditRefProductState = {
  isActiveModalEditRefProduct: false,
  isExistingProduct: true,
  isCheckReference: true,
  isLinkingProduct: true,
  productToCreate: <ReceiptFormDetailRequestDto>{},
  productProviderExisting: false,
  refToProduct: null,
};

export const ModalEditRefProductModule: Module<
  ModalEditRefProductState,
  RootState
> = {
  state,
  getters,
  mutations,
  actions,
};
