import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { GetterTree } from "vuex";
import RootState from '@/store/modules/rootState'
import {  IWasteTypeState } from "./states";

export const getters: GetterTree<IWasteTypeState, RootState> = {
    wasteTypesList: (state): any[] => state.wasteTypesList,
    wasteTypesListArchived: (state): any[] => state.wasteTypesListArchived,
    wasteType     : (state): any => state.wasteType,
    
    // COMMON
    isLoadingWasteTypesList   : (state): boolean => state.isLoadingWasteTypesList,
    isLoadingWasteTypesListArchived : (state): boolean => state.isLoadingWasteTypesListArchived,
    isLoadingWasteType : (state): boolean => state.isLoadingWasteType,
    isCreatingWasteType  : (state): boolean => state.isCreatingWasteType,
    isUpdatingWasteType : (state): boolean => state.isUpdatingWasteType,
    isDeletingWasteType : (state): boolean => state.isDeletingWasteType,
    requestStateWasteType: (state): IRequestState => state.requestStateWasteType,
    isArchivingWasteType : (state): boolean => state.isArchivingWasteType,
}