import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { CommonMutations } from "./mutations";
import { ICommonState } from "./states";

export const actions: ActionTree<ICommonState, RootState> = {
  /**
   * It fetches the list of affairs from the API.
   * @param  - **commit**: The commit function is used to send mutations to the store.
   */
  async getPublicHolidays({ commit }): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(CommonMutations.SET_IS_LOADING_PUBLIC_HOLIDAY_LIST, true);
      let url = "https://calendrier.api.gouv.fr/jours-feries/metropole.json";
      await axios
        .get(url)
        .then((res) => {
          let result: Object[] = [];
          let i = 2;
          for (const [key, value] of Object.entries(res.data)) {
            result.push({
              id: i++,
              startDate: key + "T00:00",
              duration: 1,
              durationUnit: "d",
              // endDate        : dayjs(key).+'T00:00',
              name: value,
              cls: "b-cols-publicholiday",
            });
          }
          commit(CommonMutations.SET_PUBLIC_HOLIDAY_LIST, result);
          commit(CommonMutations.SET_REQUEST_STATE_PUBLIC_HOLIDAY, res);
          commit(CommonMutations.SET_IS_LOADING_PUBLIC_HOLIDAY_LIST, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CommonMutations.SET_REQUEST_STATE_PUBLIC_HOLIDAY, err);
          commit(CommonMutations.SET_IS_LOADING_PUBLIC_HOLIDAY_LIST, false);
        });
    });
  },
};
