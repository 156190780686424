import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { OrderFormRequestDto } from "@/../Api";
import { MutationTree } from "vuex";
import { IOrderFormProviderState } from "./states";
import { IPaymentMethod } from "@/types/api-orisis/interfaces/invoices/paymentMethod/IPaymentMethod";
import { IFilters } from "@/types/api-orisis/interfaces/invoices/filters/IFilters";

export enum OrderFormProviderMutations {
  SET_ORDER_FORMS_LIST = "SET_ORDER_FORMS_LIST",
  SET_ORDER_FORMS_LIST_TEMPLATES = "SET_ORDER_FORMS_LIST_TEMPLATES",
  SET_ORDER_FORMS_LIST_ARCHIVED = "SET_ORDER_FORMS_LIST_ARCHIVED",
  SET_ORDER_FORM = "SET_ORDER_FORM",
  SET_ORDER_FORM_ARCHIVED = "SET_ORDER_FORM_ARCHIVED",
  SET_ORDER_FORM_PDF = "SET_ORDER_FORM_PDF",
  SET_INITIAL_ORDER_FORM = "SET_INITIAL_ORDER_FORM",
  SET_LAST_DOCUMENT_ORDER_FORM_REFERENCE = "SET_LAST_DOCUMENT_ORDER_FORM_REFERENCE",
  SET_ORDER_FORM_LIST_BY_AFFAIR = "SET_ORDER_FORM_LIST_BY_AFFAIR",
  SET_ORDER_FORM_LIST_BY_COMPANY = "SET_ORDER_FORM_LIST_BY_COMPANY",

  // COMMON
  SET_IS_VALIDATING_ORDER_FORM = "SET_IS_VALIDATING_ORDER_FORM",
  SET_IS_LOADING_ORDER_FORM_LIST = "SET_IS_LOADING_ORDER_FORM_LIST",
  SET_IS_LOADING_ORDER_FORM_LIST_ARCHIVED = "SET_IS_LOADING_ORDER_FORM_LIST_ARCHIVED",
  SET_IS_LOADING_ORDER_FORM = "SET_IS_LOADING_ORDER_FORM",
  SET_ORDER_FORM_PAYMENT_METHODS = "SET_ORDER_FORM_PAYMENT_METHODS",
  SET_ORDER_FORM_FILTERS = "SET_ORDER_FORM_FILTERS",
  SET_IS_LOADING_ORDER_FORM_PDF = "SET_IS_LOADING_ORDER_FORM_PDF",
  SET_IS_LOADING_ORDER_FORM_ARCHIVED = "SET_IS_LOADING_ORDER_FORM_ARCHIVED",
  SET_IS_LOADING_PAYMENT_METHODS_ORDER_FORM = "SET_IS_LOADING_PAYMENT_METHODS_ORDER_FORM",
  SET_IS_CREATING_ORDER_FORM = "SET_IS_CREATING_ORDER_FORM",
  SET_IS_UPDATING_ORDER_FORM = "SET_IS_UPDATING_ORDER_FORM",
  SET_IS_DELETING_ORDER_FORM = "SET_IS_DELETING_ORDER_FORM",
  SET_IS_LOADING_DUPLICATING_ORDER_FORM = "SET_IS_LOADING_DUPLICATING_ORDER_FORM",
  SET_IS_RESTORING_ORDER_FORM = "SET_IS_RESTORING_ORDER_FORM",
  SET_IS_ARCHIVING_ORDER_FORM = "SET_IS_ARCHIVING_ORDER_FORM",
  SET_REQUEST_STATE_ORDER_FORM = "SET_REQUEST_STATE_ORDER_FORM",
  SET_REQUEST_STATE_ORDER_FORM_PDF = "SET_REQUEST_STATE_ORDER_FORM_PDF",
  SET_ORDER_FORM_TAB_ACTIVE = "SET_ORDER_FORM_TAB_ACTIVE",
  SET_IS_LOADING_ORDER_FORMS_LIST_BY_AFFAIR = "SET_IS_LOADING_ORDER_FORMS_LIST_BY_AFFAIR",
  SET_IS_LOADING_ORDER_FORMS_LIST_BY_COMPANY = "SET_IS_LOADING_ORDER_FORMS_LIST_BY_COMPANY",
}

export const mutations: MutationTree<IOrderFormProviderState> = {
  [OrderFormProviderMutations.SET_ORDER_FORMS_LIST_ARCHIVED]: (
    state,
    payload: OrderFormRequestDto[]
  ) => {
    state.orderFormProvidersListArchived = payload;
  },
  [OrderFormProviderMutations.SET_ORDER_FORMS_LIST]: (
    state,
    payload: OrderFormRequestDto[]
  ) => {
    state.orderFormProvidersList = payload;
  },
  [OrderFormProviderMutations.SET_ORDER_FORMS_LIST_TEMPLATES]: (
    state,
    payload: OrderFormRequestDto[]
  ) => {
    state.orderFormProvidersListTemplates = payload;
  },
  [OrderFormProviderMutations.SET_ORDER_FORM]: (
    state,
    payload: OrderFormRequestDto
  ) => {
    state.orderFormProvider = payload;
  },
  [OrderFormProviderMutations.SET_ORDER_FORM_ARCHIVED]: (
    state,
    payload: OrderFormRequestDto
  ) => {
    state.orderFormProviderArchived = payload;
  },
  [OrderFormProviderMutations.SET_ORDER_FORM_PDF]: (state, payload: string) => {
    state.orderFormProviderPDF = payload + "?r=" + new Date().getTime();
  },
  [OrderFormProviderMutations.SET_LAST_DOCUMENT_ORDER_FORM_REFERENCE]: (
    state,
    payload: string
  ) => {
    state.orderFormProvider.documentReference = payload;
  },
  [OrderFormProviderMutations.SET_ORDER_FORM_LIST_BY_AFFAIR]: (
    state,
    payload: OrderFormRequestDto[]
  ) => {
    state.orderFormProvidersListByAffair = payload;
  },
  [OrderFormProviderMutations.SET_ORDER_FORM_LIST_BY_COMPANY]: (
    state,
    payload: OrderFormRequestDto[]
  ) => {
    state.orderFormProvidersListByCompany = payload;
  },

    //COMMON
    
    [OrderFormProviderMutations.SET_IS_VALIDATING_ORDER_FORM]: (state, payload: boolean) => {
        state.isValidatingOrderFormProvider = payload;
    },
    [OrderFormProviderMutations.SET_IS_LOADING_ORDER_FORM_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingOrderFormProvidersListArchived = payload;
    },
    [OrderFormProviderMutations.SET_IS_LOADING_ORDER_FORM_LIST]: (state, payload: boolean) => {
        state.isLoadingOrderFormProvidersList = payload;
    },
    [OrderFormProviderMutations.SET_IS_LOADING_ORDER_FORM]: (state, payload: boolean) => {
        state.isLoadingOrderFormProvider = payload;
    },
    [OrderFormProviderMutations.SET_IS_LOADING_PAYMENT_METHODS_ORDER_FORM]: (state, payload: boolean) => {
        state.isLoadingPaymentMethodsOrderFormProvider = payload;
    },
    [OrderFormProviderMutations.SET_IS_LOADING_ORDER_FORM_PDF]: (state, payload: boolean) => {
        state.isLoadingOrderFormProviderPDF = payload;
    },
    [OrderFormProviderMutations.SET_IS_LOADING_DUPLICATING_ORDER_FORM]: (state, payload: boolean) => {
        state.isLoadingDuplicatingOrderFormProvider = payload;
    },
    [OrderFormProviderMutations.SET_IS_LOADING_ORDER_FORM_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingOrderFormProviderArchived = payload;
    },
    [OrderFormProviderMutations.SET_INITIAL_ORDER_FORM]: (state, payload: OrderFormRequestDto) => {
      state.initialOrderFormProvider = payload;
    },
    [OrderFormProviderMutations.SET_ORDER_FORM_PAYMENT_METHODS]: (state, payload: IPaymentMethod[]) => {
        state.orderFormProviderPaymentMethods = payload;
    },
    [OrderFormProviderMutations.SET_IS_CREATING_ORDER_FORM]: (state, payload: boolean) => {
        state.isCreatingOrderFormProvider = payload;
    },
    [OrderFormProviderMutations.SET_IS_UPDATING_ORDER_FORM]: (state, payload: boolean) => {
        state.isUpdatingOrderFormProvider = payload;
    },
    [OrderFormProviderMutations.SET_IS_ARCHIVING_ORDER_FORM]: (state, payload: boolean) => {
        state.isArchivingOrderFormProvider = payload;
    },
    [OrderFormProviderMutations.SET_IS_RESTORING_ORDER_FORM]: (state, payload: boolean) => {
        state.isRestoringOrderFormProvider = payload;
    },
    [OrderFormProviderMutations.SET_IS_DELETING_ORDER_FORM]: (state, payload: boolean) => {
        state.isDeletingOrderFormProvider = payload;
    },
    [OrderFormProviderMutations.SET_REQUEST_STATE_ORDER_FORM]: (state, payload: IRequestState) => {
        state.requestStateOrderFormProvider = payload;
    },
    [OrderFormProviderMutations.SET_REQUEST_STATE_ORDER_FORM_PDF]: (state, payload: IRequestState) => {
        state.requestStateOrderFormProviderPDF = payload;
    },
    [OrderFormProviderMutations.SET_ORDER_FORM_TAB_ACTIVE]: (state, payload: any) => {
        state.orderFormProviderTabActive = payload;
    },
    [OrderFormProviderMutations.SET_ORDER_FORM_FILTERS]: (state, payload: IFilters) => {
        state.orderFormProviderFilters = payload;
    },
    [OrderFormProviderMutations.SET_IS_LOADING_ORDER_FORMS_LIST_BY_AFFAIR]: (state, payload: boolean) => {
        state.isLoadingOrderFormProvidersListByAffair = payload;
    },
    [OrderFormProviderMutations.SET_IS_LOADING_ORDER_FORMS_LIST_BY_COMPANY]: (state, payload: boolean) => {
        state.isLoadingOrderFormProvidersListByCompany = payload;
    }
}