import { IDeliveryFormState } from "./states";
import { Module } from "vuex";
import { DeliveryFormRequestDto } from "@/../Api";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import RootState from "@/store/modules/rootState";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { IPaymentMethod } from "@/types/api-orisis/interfaces/invoices/paymentMethod/IPaymentMethod";
import { IFilters } from "@/types/api-orisis/interfaces/invoices/filters/IFilters";

const state: IDeliveryFormState = {
  deliveryFormsList: Array<DeliveryFormRequestDto>(),
  filteredDeliveryFormsList: Array<DeliveryFormRequestDto>(),
  deliveryFormsListArchived: Array<DeliveryFormRequestDto>(),
  filteredDeliveryFormsListArchived: Array<DeliveryFormRequestDto>(),
  deliveryForm: <DeliveryFormRequestDto>{},
  deliveryFormArchived: <DeliveryFormRequestDto>{},
  deliveryFormPaymentMethods: Array<IPaymentMethod>(),
  isValidatingDeliveryForm             : false,
  isLoadingPaymentMethodsDeliveryForm: false,
  isLoadingDeliveryFormsList: false,
  isLoadingDeliveryFormsListArchived: false,
  isLoadingDeliveryForm: false,
  isLoadingDuplicatingDeliveryForm: false,
  isLoadingDeliveryFormArchived: false,
  isCreatingDeliveryForm: false,
  isUpdatingDeliveryForm: false,
  isRestoringDeliveryForm: false,
  isDeletingDeliveryForm: false,
  isArchivingDeliveryForm: false,
  requestStateDeliveryForm: <IRequestState>{},
  deliveryFormTabActive: "delivery-form-edit",
  deliveryFormPDF: "",
  isLoadingDeliveryFormPDF: false,
  deliveryFormFilters: <IFilters>{},
  requestStateDeliveryFormPDF: <IRequestState>{},
  initialDeliveryForm: <DeliveryFormRequestDto>{},
  deliveryFormsListByAffair: Array<DeliveryFormRequestDto>(),
  deliveryFormsListByCompany: Array<DeliveryFormRequestDto>(),
  isLoadingDeliveryFormsListByAffair: false,
  isLoadingDeliveryFormsListByCompany: false,
};

export const DeliveryFormModule: Module<IDeliveryFormState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
