import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { DegressiveSalesRatesRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IDegressiveSaleRateState } from "./states";

export enum DegressiveSaleRateMutations {
    SET_DEGRESSIVE_SALE_RATES_LIST = "SET_DEGRESSIVE_SALE_RATES_LIST",
    SET_DEGRESSIVE_SALE_RATE = "SET_DEGRESSIVE_SALE_RATE",

    // COMMON
    SET_IS_LOADING_DEGRESSIVE_SALE_RATES_LIST = "SET_IS_LOADING_DEGRESSIVE_SALE_RATES_LIST",
    SET_IS_LOADING_DEGRESSIVE_SALE_RATE = "SET_IS_LOADING_DEGRESSIVE_SALE_RATE",
    SET_IS_CREATING_DEGRESSIVE_SALE_RATE = "SET_IS_CREATING_DEGRESSIVE_SALE_RATE",
    SET_IS_UPDATING_DEGRESSIVE_SALE_RATE = "SET_IS_UPDATING_DEGRESSIVE_SALE_RATE",
    SET_IS_DELETING_DEGRESSIVE_SALE_RATE = "SET_IS_DELETING_DEGRESSIVE_SALE_RATE",
    SET_REQUEST_STATE_DEGRESSIVE_SALE_RATE = "SET_REQUEST_STATE_DEGRESSIVE_SALE_RATE",
}

export const mutations: MutationTree<IDegressiveSaleRateState> = {
    [DegressiveSaleRateMutations.SET_DEGRESSIVE_SALE_RATES_LIST]: (state, payload: DegressiveSalesRatesRequestDto[]) => {
        state.degressiveSaleRatesList = payload;
    },
    [DegressiveSaleRateMutations.SET_DEGRESSIVE_SALE_RATE]: (state, payload: DegressiveSalesRatesRequestDto) => {
        state.degressiveSaleRate = payload;
    },

    //COMMON
    [DegressiveSaleRateMutations.SET_IS_LOADING_DEGRESSIVE_SALE_RATES_LIST]: (state, payload: boolean) => {
        state.isLoadingDegressiveSaleRatesList = payload;
    },
    [DegressiveSaleRateMutations.SET_IS_LOADING_DEGRESSIVE_SALE_RATE]: (state, payload: boolean) => {
        state.isLoadingDegressiveSaleRate = payload;
    },
    [DegressiveSaleRateMutations.SET_IS_CREATING_DEGRESSIVE_SALE_RATE]: (state, payload: boolean) => {
        state.isCreatingDegressiveSaleRate = payload;
    },
    [DegressiveSaleRateMutations.SET_IS_UPDATING_DEGRESSIVE_SALE_RATE]: (state, payload: boolean) => {
        state.isUpdatingDegressiveSaleRate = payload;
    },
    [DegressiveSaleRateMutations.SET_IS_DELETING_DEGRESSIVE_SALE_RATE]: (state, payload: boolean) => {
        state.isDeletingDegressiveSaleRate = payload;
    },
    [DegressiveSaleRateMutations.SET_REQUEST_STATE_DEGRESSIVE_SALE_RATE]: (state, payload: IRequestState) => {
        state.requestStateDegressiveSaleRate = payload;
    },
}