import { OrderFormRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { OrderFormProviderMutations } from "./mutations";
import { FileMutations } from "../../ged/file/mutations";
import { FolderMutations } from "../../ged/folder/mutations";
import { IOrderFormProviderState } from "./states";
import dayjs from "dayjs";
import { DocumentMutations } from "../../invoices/document/mutations";
import { Timezone } from "@syncfusion/ej2-schedule";

let timezoneSchedule = new Timezone();

let startDate = new Date(
  dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
).toISOString();
let endDate = new Date(
  dayjs().endOf("month").format("YYYY-MM-DD")
).toISOString();

export const actions: ActionTree<IOrderFormProviderState, RootState> = {
  getOrderForms(
    { commit },
    {
      isTemplate = false,
      dateFrom = null,
      dateTo = null,
      affairId,
      companyId,
      isProvider = false,
      updateState = true,
      route,
    }: {
      isTemplate?: boolean;
      dateFrom?: Date;
      dateTo?: Date;
      affairId?: number;
      companyId?: number;
      isProvider?: boolean;
      updateState?: boolean;
      route?: string;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        switch (route) {
          case "affair":
            commit(
              OrderFormProviderMutations.SET_IS_LOADING_ORDER_FORMS_LIST_BY_AFFAIR,
              true
            );
            break;
          case "company":
            commit(
              OrderFormProviderMutations.SET_IS_LOADING_ORDER_FORMS_LIST_BY_COMPANY,
              true
            );
            break;
          default:
            commit(
              OrderFormProviderMutations.SET_IS_LOADING_ORDER_FORM_LIST,
              true
            );
            break;
        }
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/OrderForm/GetOrderForms`;
      axios
        .get(url, {
          params: {
            isProvider: isProvider,
            isTemplate: isTemplate,
            affairId: affairId,
            companyId: companyId,
            dateFrom: dateFrom ? dayjs(dateFrom).format("YYYY-MM-DD") : null,
            dateTo: dateTo
              ? dayjs(dateTo)
                  .hour(23)
                  .minute(59)
                  .second(59)
                  .format("YYYY-MM-DD HH:mm:ss")
              : null,
          },
        })
        .then((res) => {
          let list = res.data.map((item) => {
            return {
              ...item,
              documentDate: item.documentDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(item.documentDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              deliveryDate: item.deliveryDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(item.deliveryDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              receiptDate: item.receiptDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(item.receiptDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              limitDate: item.limitDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(item.limitDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              receivedOn: item.receivedOn
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(item.receivedOn)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              estimatedDateReceipt: item.estimatedDateReceipt
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(item.estimatedDateReceipt)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
            };
          });
          if (updateState) {
            switch (route) {
              case "affair":
                commit(
                  OrderFormProviderMutations.SET_IS_LOADING_ORDER_FORMS_LIST_BY_AFFAIR,
                  false
                );
                commit(
                  OrderFormProviderMutations.SET_ORDER_FORM_LIST_BY_AFFAIR,
                  list
                );
                break;
              case "company":
                commit(
                  OrderFormProviderMutations.SET_IS_LOADING_ORDER_FORMS_LIST_BY_COMPANY,
                  false
                );
                commit(
                  OrderFormProviderMutations.SET_ORDER_FORM_LIST_BY_COMPANY,
                  list
                );
                break;
              default:
                commit(
                  OrderFormProviderMutations.SET_IS_LOADING_ORDER_FORM_LIST,
                  false
                );
                if (isTemplate) {
                  commit(
                    OrderFormProviderMutations.SET_ORDER_FORMS_LIST_TEMPLATES,
                    list
                  );
                } else {
                  commit(OrderFormProviderMutations.SET_ORDER_FORMS_LIST, list);
                }
                break;
            }
          }
          commit(OrderFormProviderMutations.SET_REQUEST_STATE_ORDER_FORM, res);
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            switch (route) {
              case "affair":
                commit(
                  OrderFormProviderMutations.SET_IS_LOADING_ORDER_FORMS_LIST_BY_AFFAIR,
                  false
                );
                break;
              case "company":
                commit(
                  OrderFormProviderMutations.SET_IS_LOADING_ORDER_FORMS_LIST_BY_COMPANY,
                  false
                );
                break;
              default:
                commit(
                  OrderFormProviderMutations.SET_IS_LOADING_ORDER_FORM_LIST,
                  false
                );
                break;
            }
          }
          commit(OrderFormProviderMutations.SET_REQUEST_STATE_ORDER_FORM, err);
          reject(err);
        });
    });
  },

  getOrderFormById(
    { commit, dispatch, rootGetters },
    {
      orderFormId,
      updateState = true,
    }: { orderFormId: number; updateState?: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(OrderFormProviderMutations.SET_IS_LOADING_ORDER_FORM, true);
        commit(
          DocumentMutations.SET_LOADING_MSG,
          "Chargement des détails de la commande fournisseur..."
        );
        commit(
          OrderFormProviderMutations.SET_ORDER_FORM_TAB_ACTIVE,
          "order-form-provider-edit"
        );
        commit(OrderFormProviderMutations.SET_INITIAL_ORDER_FORM, null);
      }
      axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/OrderForm/GetOrderFormById`,
          {
            params: {
              orderFormId: orderFormId,
            },
          }
        )
        .then(async (res) => {
          let dataOrderFormProvider = {
            ...res.data,
            paymentConditionId: res.data.paymentCondition
              ? res.data.paymentCondition.id
              : 1,
          };
          await dispatch("getInitialOrderFormById", {
            orderFormId: res.data.id,
          });
          await dispatch("getOrderFormOptionByOrderFormId", {
            orderFormId: res.data.id,
          });
          if (updateState) {
            commit(
              OrderFormProviderMutations.SET_ORDER_FORM,
              dataOrderFormProvider
            );
            if (res.data.status == 3 && res.data.path) {
              commit(
                OrderFormProviderMutations.SET_ORDER_FORM_PDF,
                res.data.path
              );
            }
          }

          // On ouvre sur l'apperçu si le document est validé
          if (res.data.status >= 3 && res.data.status <= 7) {
            dispatch("changeOrderFormProviderTabActive", "inventory-pdf");
          }

          // On requete les documents détails
          await dispatch("getOrderFormDetailByOrderFormId", {
            orderFormId: orderFormId,
          });

          // On requete l'historique
          dispatch("getOrderFormCustomerHistoryByOrderFormCustomerId", {
            orderFormId: orderFormId,
          });

          commit(OrderFormProviderMutations.SET_REQUEST_STATE_ORDER_FORM, res);
          if (updateState) {
            commit(OrderFormProviderMutations.SET_IS_LOADING_ORDER_FORM, false);
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(OrderFormProviderMutations.SET_REQUEST_STATE_ORDER_FORM, err);
          if (updateState) {
            commit(OrderFormProviderMutations.SET_IS_LOADING_ORDER_FORM, false);
          }
          reject(err);
        });
    });
  },

  ///GetOrderFormTvasByOrderFormId

  ///GetLastDocumentReference

  getOrderFormEmpty(
    { commit, rootGetters, dispatch },
    {
      affairId = null,
      providerId = null,
      userId = rootGetters.connectedUser.id,
      customerId = null,
      storageLocationId = null,
      updateState = true,
    }: {
      affairId?: number;
      providerId?: number;
      userId?: number;
      customerId?: number;
      storageLocationId?: number;
      updateState?: boolean;
    }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (updateState) {
        commit(OrderFormProviderMutations.SET_IS_CREATING_ORDER_FORM, true);
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/OrderForm/GetOrderFormEmpty",
          {
            params: {
              affairId: affairId,
              providerId: providerId,
              userId: userId,
              customerId: customerId,
              storageLocationId: storageLocationId,
            },
          }
        )
        .then(async (res) => {
          if (res.data.status == 0) {
            let orderFormProviderFilters = rootGetters.userParametersList.find(
              (elem) => elem.key == "orderFormProviderFilters"
            );
            if (orderFormProviderFilters) {
              dispatch("getOrderForms", {
                dateFrom: JSON.parse(orderFormProviderFilters.value).dateRange
                  .startDate,
                dateTo: JSON.parse(orderFormProviderFilters.value).dateRange
                  .endDate,
              });
            } else {
              dispatch("setFilter", {
                name: "orderFormProviderFilters",
                filter: {
                  dateRangeType: 7,
                  dateRange: { startDate: startDate, endDate: endDate },
                },
              });
              dispatch("getOrderForms", {
                dateFrom: startDate,
                dateTo: endDate,
              });
            }
          }
          // On requete les documents détails
          await dispatch("getOrderFormDetailByOrderFormId", {
            orderFormId: res.data.id,
          });
          let dataOrderFormProvider = {
            ...res.data,
            paymentConditionId: res.data.paymentCondition
              ? res.data.paymentCondition.id
              : 1,
          };
          if (updateState) {
            commit(
              OrderFormProviderMutations.SET_ORDER_FORM,
              dataOrderFormProvider
            );
            commit(
              OrderFormProviderMutations.SET_ORDER_FORM_PAYMENT_METHODS,
              res.data.paymentMethods.length == 0
                ? []
                : res.data.paymentMethods.map((el) => el.id)
            );
            commit(
              OrderFormProviderMutations.SET_IS_CREATING_ORDER_FORM,
              false
            );
          }
          commit(OrderFormProviderMutations.SET_REQUEST_STATE_ORDER_FORM, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(OrderFormProviderMutations.SET_REQUEST_STATE_ORDER_FORM, err);
          if (updateState) {
            commit(
              OrderFormProviderMutations.SET_IS_CREATING_ORDER_FORM,
              false
            );
          }
          reject(err);
        });
    });
  },

  getInitialOrderFormById(
    { commit, rootGetters },
    {
      orderFormId,
      updateState = true,
    }: { orderFormId: number; updateState?: boolean }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/OrderForm/GetInitialOrderFormById",
          {
            params: {
              orderFormId: orderFormId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(OrderFormProviderMutations.SET_INITIAL_ORDER_FORM, res.data);
          }
          commit(OrderFormProviderMutations.SET_REQUEST_STATE_ORDER_FORM, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(OrderFormProviderMutations.SET_REQUEST_STATE_ORDER_FORM, err);
          reject(err);
        });
    });
  },

  //CreateOrderForm

  duplicateOrderForm(
    { commit, rootGetters },
    { orderFormId }: { orderFormId: number }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(OrderFormProviderMutations.SET_IS_CREATING_ORDER_FORM, true);
      axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/OrderForm/DuplicateOrderForm",
          {},
          {
            params: {
              orderFormId: orderFormId,
              userId: rootGetters.connectedUser.id,
            },
          }
        )
        .then((res) => {
          let dataOrderFormProvider = {
            ...res.data,
            paymentConditionId: res.data.paymentCondition
              ? res.data.paymentCondition.id
              : 1,
            isTemplate: false,
          };
          commit(
            OrderFormProviderMutations.SET_ORDER_FORM,
            dataOrderFormProvider
          );
          commit(OrderFormProviderMutations.SET_IS_CREATING_ORDER_FORM, false);
          commit(OrderFormProviderMutations.SET_REQUEST_STATE_ORDER_FORM, res);
          resolve({ ...res.data, isTemplate: false });
        })
        .catch((err) => {
          console.error(err);
          commit(OrderFormProviderMutations.SET_REQUEST_STATE_ORDER_FORM, err);
          commit(OrderFormProviderMutations.SET_IS_CREATING_ORDER_FORM, false);
          reject(err);
        });
    });
  },

  updateOrderForm(
    { commit, rootGetters, dispatch },
    {
      orderFormProvider,
      updateState = true,
    }: { orderFormProvider: OrderFormRequestDto; updateState: boolean }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (updateState) {
        commit(OrderFormProviderMutations.SET_IS_UPDATING_ORDER_FORM, true);
      }
      axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/OrderForm/UpdateOrderForm`,
          { ...orderFormProvider, currencyId: 1 }
        )
        .then((res) => {
          let dataOrderFormProvider = {
            ...res.data.data,
            paymentConditionId: res.data.data.paymentCondition
              ? res.data.data.paymentCondition.id
              : 1,
          };
          commit(
            OrderFormProviderMutations.SET_ORDER_FORM,
            dataOrderFormProvider
          );

          if (updateState) {
            let orderFormProviderFilters = rootGetters.userParametersList.find(
              (elem) => elem.key == "orderFormProviderFilters"
            );
            if (orderFormProviderFilters) {
              dispatch("getOrderForms", {
                dateFrom: JSON.parse(orderFormProviderFilters.value).dateRange
                  .startDate,
                dateTo: JSON.parse(orderFormProviderFilters.value).dateRange
                  .endDate,
              });
            } else {
              dispatch("setFilter", {
                name: "orderFormProviderFilters",
                filter: {
                  dateRangeType: 7,
                  dateRange: { startDate: startDate, endDate: endDate },
                },
              });
              dispatch("getOrderForms", {
                dateFrom: startDate,
                dateTo: endDate,
              });
            }
          } else {
            rootGetters.orderFormProvidersList.splice(
              rootGetters.orderFormProvidersList.findIndex(
                (elem) => elem.id == orderFormProvider.id
              ),
              1,
              dataOrderFormProvider
            );
            commit(
              OrderFormProviderMutations.SET_ORDER_FORMS_LIST,
              rootGetters.orderFormProvidersList
            );
          }

          commit(OrderFormProviderMutations.SET_REQUEST_STATE_ORDER_FORM, res);
          if (updateState) {
            commit(
              OrderFormProviderMutations.SET_IS_UPDATING_ORDER_FORM,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(OrderFormProviderMutations.SET_REQUEST_STATE_ORDER_FORM, err);
          if (updateState) {
            commit(
              OrderFormProviderMutations.SET_IS_UPDATING_ORDER_FORM,
              false
            );
          }
          reject(err);
        });
    });
  },

  uploadPathOrderFormProvider(
    { commit, rootGetters },
    { orderFormProviderId, path }: { orderFormProviderId: number; path: string }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(OrderFormProviderMutations.SET_IS_UPDATING_ORDER_FORM, true);
      axios
        .put(
          `${
            process.env.VUE_APP_API_ORISIS
          }Purchase/OrderForm/UploadPathDocument?orderFormId=${orderFormProviderId}&path=${encodeURIComponent(
            path
          )}`
        )
        .then((res) => {
          let dataOrderFormProvider = {
            ...res.data,
            paymentConditionId: res.data.paymentCondition
              ? res.data.paymentCondition.id
              : 1,
          };
          commit(
            OrderFormProviderMutations.SET_ORDER_FORM,
            dataOrderFormProvider
          );
          rootGetters.orderFormProvidersList.splice(
            rootGetters.orderFormProvidersList.findIndex(
              (elem) => elem.id == orderFormProviderId
            ),
            1,
            dataOrderFormProvider
          );
          commit(
            OrderFormProviderMutations.SET_ORDER_FORMS_LIST,
            rootGetters.orderFormProvidersList
          );
          commit(OrderFormProviderMutations.SET_REQUEST_STATE_ORDER_FORM, res);
          commit(OrderFormProviderMutations.SET_IS_UPDATING_ORDER_FORM, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(OrderFormProviderMutations.SET_REQUEST_STATE_ORDER_FORM, err);
          commit(OrderFormProviderMutations.SET_IS_UPDATING_ORDER_FORM, false);
          reject(err);
        });
    });
  },

  uploadFileOrderFormProvider(
    { commit, rootGetters, dispatch },
    {
      formData,
      orderFormProviderId,
    }: { formData: FormData; orderFormProviderId: number }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(FileMutations.SET_IS_CREATING_FILE, true);
      axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/OrderForm/UploadFile?orderFormId=${orderFormProviderId}`,
          formData
        )
        .then(async (res) => {
          if (!rootGetters.orderFormProvider.folderId) {
            await dispatch("getOrderFormById", {
              orderFormId: orderFormProviderId,
            });
          }
          commit(FolderMutations.SET_FOLDER, rootGetters.folder);
          commit(FileMutations.SET_REQUEST_STATE_FILE, res);
          commit(FileMutations.SET_IS_CREATING_FILE, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(FileMutations.SET_REQUEST_STATE_FILE, err);
          commit(FileMutations.SET_IS_CREATING_FILE, false);
          reject(err);
        });
    });
  },

  updatePaymentMethodOrderFormProvider(
    { commit, rootGetters },
    {
      paymentMethodOrderFormProvider = [],
      orderFormProviderId,
    }: { paymentMethodOrderFormProvider: number[]; orderFormProviderId: number }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(
        OrderFormProviderMutations.SET_IS_LOADING_PAYMENT_METHODS_ORDER_FORM,
        true
      );
      axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/OrderForm/UpdatePaymentMethodOrderForm",
          paymentMethodOrderFormProvider,
          {
            params: {
              orderFormId: orderFormProviderId,
            },
          }
        )
        .then((res) => {
          commit(OrderFormProviderMutations.SET_REQUEST_STATE_ORDER_FORM, res);
          commit(
            OrderFormProviderMutations.SET_IS_LOADING_PAYMENT_METHODS_ORDER_FORM,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(OrderFormProviderMutations.SET_REQUEST_STATE_ORDER_FORM, err);
          commit(
            OrderFormProviderMutations.SET_IS_LOADING_PAYMENT_METHODS_ORDER_FORM,
            false
          );
          reject(err);
        });
    });
  },

  updateOrderFormProviderTagOrderFormProvider(
    { commit, rootGetters },
    { orderFormProviderIds, orderFormProviderTagIds }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/OrderForm/UpdateOrderFormTagOrderForm`,
          {
            orderFormIds: orderFormProviderIds,
            orderFormTagIds: orderFormProviderTagIds,
          }
        )
        .then((res) => {
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          reject(err);
        });
    });
  },

  deleteOrderFormProviders(
    { commit, rootGetters, dispatch },
    { orderFormProviderIds }: { orderFormProviderIds: number[] }
  ) {
    return new Promise((resolve, reject) => {
      commit(OrderFormProviderMutations.SET_IS_DELETING_ORDER_FORM, true);
      axios
        .delete(`${process.env.VUE_APP_API_ORISIS}Purchase/OrderForm/Delete`, {
          data: orderFormProviderIds,
        })
        .then((res) => {
          let orderFormProviderFilters = rootGetters.userParametersList.find(
            (elem) => elem.key == "orderFormProviderFilters"
          );
          if (orderFormProviderFilters) {
            dispatch("getOrderForms", {
              dateFrom: JSON.parse(orderFormProviderFilters.value).dateRange
                .startDate,
              dateTo: JSON.parse(orderFormProviderFilters.value).dateRange
                .endDate,
            });
          } else {
            dispatch("setFilter", {
              name: "orderFormProviderFilters",
              filter: {
                dateRangeType: 7,
                dateRange: { startDate: startDate, endDate: endDate },
              },
            });
            dispatch("getOrderForms", {
              dateFrom: startDate,
              dateTo: endDate,
            });
          }

          // commit(OrderFormProviderMutations.SET_ORDER_FORMS_LIST, rootGetters.orderFormProvidersList.filter(elem => !orderFormProviderIds.includes(elem.id)));
          commit(OrderFormProviderMutations.SET_REQUEST_STATE_ORDER_FORM, res);
          commit(OrderFormProviderMutations.SET_IS_DELETING_ORDER_FORM, false);
          resolve(res);
        })
        .catch((err) => {
          console.error(err);
          commit(OrderFormProviderMutations.SET_REQUEST_STATE_ORDER_FORM, err);
          commit(OrderFormProviderMutations.SET_IS_DELETING_ORDER_FORM, false);
          reject(err);
        });
    });
  },

  async generateOrderFormProviderPDF({ commit, rootGetters, dispatch }, data) {
    return new Promise(async (resolve, reject) => {
      commit(OrderFormProviderMutations.SET_IS_LOADING_ORDER_FORM_PDF, true);
      await axios
        .post(`${process.env.VUE_APP_PDF_GENERATOR_URL}generate`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(async (res) => {
          // Si le document est finalisé
          if (
            rootGetters.orderFormProvider.status == 3 ||
            rootGetters.orderFormProvider.status == 4
          ) {
            await dispatch("uploadPathOrderFormProvider", {
              orderFormProviderId: rootGetters.orderFormProvider.id,
              path: res.data,
            });
          }

          commit(OrderFormProviderMutations.SET_ORDER_FORM_PDF, res.data);
          commit(OrderFormProviderMutations.SET_REQUEST_STATE_ORDER_FORM, res);
          commit(
            OrderFormProviderMutations.SET_IS_LOADING_ORDER_FORM_PDF,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(OrderFormProviderMutations.SET_REQUEST_STATE_ORDER_FORM, err);
          commit(
            OrderFormProviderMutations.SET_IS_LOADING_ORDER_FORM_PDF,
            false
          );
          reject(err);
        });
    });
  },

  async changeOrderFormProviderTabActive(
    { commit, dispatch },
    tabToActivate: string
  ) {
    commit(OrderFormProviderMutations.SET_ORDER_FORM_TAB_ACTIVE, tabToActivate);
    dispatch("setupHeaderOrderFormProvider");
  },

  async setupHeaderOrderFormProvider({ rootGetters }) {
    let document = rootGetters.orderFormOption;
    let header = rootGetters.getOrderFormProviderHeader;
    header["discount"].display = document.showDiscountColumn;
    header["unitId"].display = !document.hideUnitColumn;
    header["referencielTvaId"].display = !document.hideReferencielTvaColumn;
    header["reference"].display = document.showReferenceColumn;
    header["unitPriceHt"].display = !document.hideUnitPriceHtColumn;
    header["index"].display = !document.hideIndexColumn;
    header["total"].display = !document.hidePriceHtColumn;
  },
};
