import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IQuoteOptionState } from "./states";
import { QuoteOptionRequestDto } from "Api";

export const getters: GetterTree<IQuoteOptionState, RootState> = {
  quoteOption(state): QuoteOptionRequestDto {
    return state.quoteOption;
  },
  isLoadingQuoteOption(state): boolean {
    return state.isLoadingQuoteOption;
  },
  requestStateQuoteOption(state): any {
    return state.requestStateQuoteOption;
  },
};
