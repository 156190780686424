import { IDeliveryFormHistoryState } from "./states";
import { MutationTree } from "vuex";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export enum DeliveryFormHistoryMutations {
  SET_DELIVERY_FORM_HISTORIES_LIST = "SET_DELIVERY_FORM_HISTORIES_LIST",
  SET_DELIVERY_FORM_HISTORY = "SET_DELIVERY_FORM_HISTORY",

  // COMON
  SET_IS_LOADING_DELIVERY_FORM_HISTORIES_LIST = "SET_IS_LOADING_DELIVERY_FORM_HISTORIES_LIST",
  SET_IS_LOADING_DELIVERY_FORM_HISTORY = "SET_IS_LOADING_DELIVERY_FORM_HISTORY",
  SET_REQUEST_STATE_DELIVERY_FORM_HISTORY = "SET_REQUEST_STATE_DELIVERY_FORM_HISTORY",
}

export const mutations: MutationTree<IDeliveryFormHistoryState> = {
  [DeliveryFormHistoryMutations.SET_DELIVERY_FORM_HISTORIES_LIST]: (
    state,
    payload: []
  ) => {
    state.deliveryFormHistoriesList = payload;
  },
  [DeliveryFormHistoryMutations.SET_DELIVERY_FORM_HISTORY]: (
    state,
    payload: {}
  ) => {
    state.deliveryFormHistory = payload;
  },

  //COMMON
  [DeliveryFormHistoryMutations.SET_IS_LOADING_DELIVERY_FORM_HISTORIES_LIST]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingDeliveryFormHistoriesList = payload;
  },
  [DeliveryFormHistoryMutations.SET_IS_LOADING_DELIVERY_FORM_HISTORY]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingDeliveryFormHistory = payload;
  },
  [DeliveryFormHistoryMutations.SET_REQUEST_STATE_DELIVERY_FORM_HISTORY]: (
    state,
    payload: IRequestState
  ) => {
    state.requestStateDeliveryFormHistory = payload;
  },
};
