import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { GetterTree } from "vuex";
import RootState from '@/store/modules/rootState'
import {  IOrderFormProviderHistoryState } from "./states";

export const getters: GetterTree<IOrderFormProviderHistoryState, RootState> = {
    orderFormProviderHistoriesList: (state): [] => state.orderFormProviderHistoriesList,
    orderFormProviderHistory     : (state): {} => state.orderFormProviderHistory,
    
    // COMMON
    isLoadingOrderFormProviderHistoriesList   : (state): boolean => state.isLoadingOrderFormProviderHistoriesList,
    isLoadingOrderFormProviderHistory : (state): boolean => state.isLoadingOrderFormProviderHistory,
    requestStateOrderFormProviderHistory: (state): IRequestState => state.requestStateOrderFormProviderHistory,
}