import { MutationTree } from "vuex";
import { IQuoteOptionState } from "./states";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { QuoteOptionRequestDto } from "Api";

export const enum QuoteOptionMutations {
  SET_QUOTE_OPTION = "SET_QUOTE_OPTION",
  SET_IS_LOADING_QUOTE_OPTION = "SET_IS_LOADING_QUOTE_OPTION",
  SET_REQUEST_STATE_QUOTE_OPTION = "SET_REQUEST_STATE_QUOTE_OPTION",
}

export const mutations: MutationTree<IQuoteOptionState> = {
  [QuoteOptionMutations.SET_QUOTE_OPTION](
    state,
    payload: QuoteOptionRequestDto
  ) {
    state.quoteOption = payload;
  },
  [QuoteOptionMutations.SET_IS_LOADING_QUOTE_OPTION](state, payload: boolean) {
    state.isLoadingQuoteOption = payload;
  },
  [QuoteOptionMutations.SET_REQUEST_STATE_QUOTE_OPTION](
    state,
    payload: IRequestState
  ) {
    state.requestStateQuoteOption = payload;
  },
};
