import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { OrderFormRequestDto } from "@/../Api";
import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IOrderFormProviderState } from "./states";
import { IPaymentMethod } from "@/types/api-orisis/interfaces/invoices/paymentMethod/IPaymentMethod";
import { IFilters } from "@/types/api-orisis/interfaces/invoices/filters/IFilters";

export const getters: GetterTree<IOrderFormProviderState, RootState> = {
  orderFormProvidersList: (state): OrderFormRequestDto[] =>
    state.orderFormProvidersList,
  orderFormProvidersListTemplates: (state): OrderFormRequestDto[] =>
    state.orderFormProvidersListTemplates,
  filteredOrderFormProvidersList: (state): OrderFormRequestDto[] =>
    state.orderFormProvidersList,
  orderFormProvidersListArchived: (state): OrderFormRequestDto[] =>
    state.orderFormProvidersListArchived,
  filteredOrderFormProvidersListArchived: (state): OrderFormRequestDto[] =>
    state.orderFormProvidersListArchived,
  orderFormProvider: (state): OrderFormRequestDto => state.orderFormProvider,
  orderFormProviderPaymentMethods: (state): IPaymentMethod[] =>
    state.orderFormProviderPaymentMethods,
  orderFormProviderFilters: (state): IFilters => state.orderFormProviderFilters,
  orderFormProviderPDF: (state): string => state.orderFormProviderPDF,
  orderFormProviderArchived: (state): OrderFormRequestDto =>
    state.orderFormProviderArchived,
  initialOrderFormProvider: (state): OrderFormRequestDto =>
    state.initialOrderFormProvider,
  orderFormProvidersListByAffair: (state): OrderFormRequestDto[] =>
    state.orderFormProvidersListByAffair,
  orderFormProvidersListByCompany: (state): OrderFormRequestDto[] =>
    state.orderFormProvidersListByCompany,

  // COMMON
  isValidatingOrderFormProvider: (state): boolean =>
    state.isValidatingOrderFormProvider,
  isLoadingOrderFormProvidersList: (state): boolean =>
    state.isLoadingOrderFormProvidersList,
  isLoadingPaymentMethodsOrderFormProvider: (state): boolean =>
    state.isLoadingPaymentMethodsOrderFormProvider,
  isLoadingOrderFormProvidersListArchived: (state): boolean =>
    state.isLoadingOrderFormProvidersListArchived,
  isLoadingOrderFormProvider: (state): boolean =>
    state.isLoadingOrderFormProvider,
  isLoadingOrderFormProviderPDF: (state): boolean =>
    state.isLoadingOrderFormProviderPDF,
  isLoadingDuplicatingOrderFormProvider: (state): boolean =>
    state.isLoadingDuplicatingOrderFormProvider,
  isLoadingOrderFormProviderArchived: (state): boolean =>
    state.isLoadingOrderFormProviderArchived,
  isCreatingOrderFormProvider: (state): boolean =>
    state.isCreatingOrderFormProvider,
  isUpdatingOrderFormProvider: (state): boolean =>
    state.isUpdatingOrderFormProvider,
  isRestoringOrderFormProvider: (state): boolean =>
    state.isRestoringOrderFormProvider,
  isDeletingOrderFormProvider: (state): boolean =>
    state.isDeletingOrderFormProvider,
  isArchivingOrderFormProvider: (state): boolean =>
    state.isArchivingOrderFormProvider,
  requestStateOrderFormProvider: (state): IRequestState =>
    state.requestStateOrderFormProvider,
  requestStateOrderFormProviderPDF: (state): IRequestState =>
    state.requestStateOrderFormProviderPDF,
  orderFormProviderTabActive: (state): String =>
    state.orderFormProviderTabActive,
  isLoadingOrderFormProvidersListByAffair: (state): boolean =>
    state.isLoadingOrderFormProvidersListByAffair,
  isLoadingOrderFormProvidersListByCompany: (state): boolean =>
    state.isLoadingOrderFormProvidersListByCompany,
};
