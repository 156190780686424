import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { CreditOptionDefaultMutations } from "./mutations";
import { ICreditOptionDefaultState } from "./states";
import { CreditOptionRequestDto } from "Api";

export const actions: ActionTree<ICreditOptionDefaultState, RootState> = {
  getCreditOptionDefaultByCreditId(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          CreditOptionDefaultMutations.SET_IS_LOADING_CREDIT_OPTION_DEFAULT,
          true
        );
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/CreditOptionDefault/GetDefault`;

      axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              CreditOptionDefaultMutations.SET_CREDIT_OPTION_DEFAULT,
              res.data
            );
            commit(
              CreditOptionDefaultMutations.SET_CREDIT_OPTION_DEFAULT_FORM,
              res.data
            );
            commit(
              CreditOptionDefaultMutations.SET_IS_LOADING_CREDIT_OPTION_DEFAULT,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              CreditOptionDefaultMutations.SET_IS_LOADING_CREDIT_OPTION_DEFAULT,
              false
            );
          }
          reject(err);
        });
    });
  },

  createCreditOptionDefault(
    { commit },
    {
      creditOptionDefault,
      updateState = true,
    }: {
      creditOptionDefault: CreditOptionRequestDto;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          CreditOptionDefaultMutations.SET_IS_LOADING_CREDIT_OPTION_DEFAULT,
          true
        );
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/CreditOptionDefault/CreateCreditOptionDefault`;

      axios
        .post(url, creditOptionDefault)
        .then((res) => {
          if (updateState) {
            commit(
              CreditOptionDefaultMutations.SET_CREDIT_OPTION_DEFAULT,
              res.data
            );
            commit(
              CreditOptionDefaultMutations.SET_IS_LOADING_CREDIT_OPTION_DEFAULT,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              CreditOptionDefaultMutations.SET_IS_LOADING_CREDIT_OPTION_DEFAULT,
              false
            );
          }
          reject(err);
        });
    });
  },

  updateCreditOptionDefault(
    { commit },
    {
      creditOptionDefault,
      updateState = true,
    }: {
      creditOptionDefault: CreditOptionRequestDto;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          CreditOptionDefaultMutations.SET_IS_LOADING_CREDIT_OPTION_DEFAULT,
          true
        );
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/CreditOptionDefault/UpdateCreditOptionDefault`;

      axios
        .put(url, creditOptionDefault)
        .then((res) => {
          if (updateState) {
            commit(
              CreditOptionDefaultMutations.SET_CREDIT_OPTION_DEFAULT,
              res.data
            );
            commit(
              CreditOptionDefaultMutations.SET_IS_LOADING_CREDIT_OPTION_DEFAULT,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              CreditOptionDefaultMutations.SET_IS_LOADING_CREDIT_OPTION_DEFAULT,
              false
            );
          }
          reject(err);
        });
    });
  },
};
