import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { GetterTree } from "vuex";
import RootState from '@/store/modules/rootState'
import { ISmsState } from "./states";
import { ProgrammedSmsRequestDto } from '@/../Api'

export const getters: GetterTree<ISmsState, RootState> = {
    smsList                    : (state): ProgrammedSmsRequestDto[] => state.smsList,
    smsListByEventId                    : (state): ProgrammedSmsRequestDto[] => state.smsListByEventId,
    sms                        : (state): ProgrammedSmsRequestDto => state.sms,
    isLoadingSmsList           : (state): boolean => state.isLoadingSmsList,
    isLoadingSmsListByEventId : (state): boolean => state.isLoadingSmsListByEventId,
    isLoadingSms               : (state): boolean => state.isLoadingSms,
    requestStateSms            : (state): IRequestState => state.requestStateSms,
}