const environment = {
  name: "development",
  OpenIdConnect: {
    Authority: "https://auth-dev-orisis.azurewebsites.net/",
    ClientId: "OrisisFrontLocal",
    ClientName: "Orisis Front Local",
    ClientSecret: "DC73D915-B7C2-4A25-B257-B55379D232AA",
  },
  ResourceServer: {
    Endpoint: process.env.VUE_APP_API_ORISIS,
  },
  CurrentUrl: "https://dev.orisis.fr/",
  // CurrentUrl: "https://localhost:8080/",
};
export default environment;
