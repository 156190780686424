import { MutationTree } from "vuex";
import { IDeliveryFormOptionDefaultState } from "./states";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { DeliveryFormOptionRequestDto } from "Api";

export const enum DeliveryFormOptionDefaultMutations {
  SET_DELIVERY_FORM_OPTION_DEFAULT = "SET_DELIVERY_FORM_OPTION_DEFAULT",
  SET_DELIVERY_FORM_OPTION_DEFAULT_FORM = "SET_DELIVERY_FORM_OPTION_DEFAULT_FORM",
  SET_IS_LOADING_DELIVERY_FORM_OPTION_DEFAULT = "SET_IS_LOADING_DELIVERY_FORM_OPTION_DEFAULT",
  SET_REQUEST_STATE_DELIVERY_FORM_OPTION_DEFAULT = "SET_REQUEST_STATE_DELIVERY_FORM_OPTION_DEFAULT",
}

export const mutations: MutationTree<IDeliveryFormOptionDefaultState> = {
  [DeliveryFormOptionDefaultMutations.SET_DELIVERY_FORM_OPTION_DEFAULT](
    state,
    payload: DeliveryFormOptionRequestDto
  ) {
    state.deliveryFormOptionDefault = payload;
  },
  [DeliveryFormOptionDefaultMutations.SET_DELIVERY_FORM_OPTION_DEFAULT_FORM](
    state,
    payload: DeliveryFormOptionRequestDto
  ) {
    state.deliveryFormOptionDefaultForm = payload;
  },
  [DeliveryFormOptionDefaultMutations.SET_IS_LOADING_DELIVERY_FORM_OPTION_DEFAULT](
    state,
    payload: boolean
  ) {
    state.isLoadingDeliveryFormOptionDefault = payload;
  },
  [DeliveryFormOptionDefaultMutations.SET_REQUEST_STATE_DELIVERY_FORM_OPTION_DEFAULT](
    state,
    payload: IRequestState
  ) {
    state.requestStateDeliveryFormOptionDefault = payload;
  },
};
