import { TermsAndConditionsRequestDto } from 'Api'
import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { TermsAndConditionsMutations } from './mutations'
import { ITermsAndConditionsState } from './states'

export const actions: ActionTree<ITermsAndConditionsState, RootState> = {

  getTermsAndConditionsByInstitutionSettingId ({ commit, rootGetters }, {institutionSettingId, updateState = true} : {institutionSettingId: number, updateState: boolean} ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (updateState) {
        commit(TermsAndConditionsMutations.SET_IS_LOADING_TERMS_AND_CONDITIONS, true);
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            'Sale/TermsAndConditions/GetTermsAndConditionsByInstitutionId',
          {
            params: {
              institutionSettingId: institutionSettingId
            }
          }
        )
        .then(res => {
          if (updateState) {
            commit(TermsAndConditionsMutations.SET_TERMS_AND_CONDITIONS, res.data);
            commit(TermsAndConditionsMutations.SET_IS_LOADING_TERMS_AND_CONDITIONS, false);
          }
          commit(TermsAndConditionsMutations.SET_REQUEST_STATE_TERMS_AND_CONDITIONS, res);
          resolve(res.data);
        })
        .catch(err => {
          console.error(err);
          commit(TermsAndConditionsMutations.SET_REQUEST_STATE_TERMS_AND_CONDITIONS, err);
          if (updateState) {
            commit(TermsAndConditionsMutations.SET_IS_LOADING_TERMS_AND_CONDITIONS, false);
          }
          reject(err);
        })
    })
  },

  getTermsAndConditionsById ({ commit, rootGetters }, {termsAndConditionsId, updateState = true} : {termsAndConditionsId: number, updateState: boolean} ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (updateState) {
        commit(TermsAndConditionsMutations.SET_IS_LOADING_TERMS_AND_CONDITIONS, true);
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            'Sale/TermsAndConditions/GetTermsAndConditionsById',
          {
            params: {
              termsAndConditionsId: termsAndConditionsId
            }
          }
        )
        .then(res => {
          if (updateState) {
            commit(TermsAndConditionsMutations.SET_TERMS_AND_CONDITIONS, res.data);
            commit(TermsAndConditionsMutations.SET_IS_LOADING_TERMS_AND_CONDITIONS, false);
          }
          commit(TermsAndConditionsMutations.SET_REQUEST_STATE_TERMS_AND_CONDITIONS, res);
          resolve(res.data);
        })
        .catch(err => {
          console.error(err);
          commit(TermsAndConditionsMutations.SET_REQUEST_STATE_TERMS_AND_CONDITIONS, err);
          if (updateState) {
            commit(TermsAndConditionsMutations.SET_IS_LOADING_TERMS_AND_CONDITIONS, false);
          }
          reject(err);
        })
    })
  },

  createTermsAndConditions ({ commit, rootGetters }, { termsAndConditions } : { termsAndConditions: TermsAndConditionsRequestDto }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(TermsAndConditionsMutations.SET_IS_CREATING_TERMS_AND_CONDITIONS, true);
      axios
      .post(
        process.env.VUE_APP_API_ORISIS +
          'Sale/TermsAndConditions/CreateTermsAndConditions',
          termsAndConditions
      )
      .then(res => {
        commit(TermsAndConditionsMutations.SET_TERMS_AND_CONDITIONS, res.data);
        commit(TermsAndConditionsMutations.SET_REQUEST_STATE_TERMS_AND_CONDITIONS, res);
        commit(TermsAndConditionsMutations.SET_IS_CREATING_TERMS_AND_CONDITIONS, false);
        resolve(res.data)
      })
      .catch(err => {
        console.error(err);
        commit(TermsAndConditionsMutations.SET_REQUEST_STATE_TERMS_AND_CONDITIONS, err);
        commit(TermsAndConditionsMutations.SET_IS_CREATING_TERMS_AND_CONDITIONS, false);
        reject(err)
      })
    });
  },

  updateTermsAndConditions ({ commit, rootGetters }, { termsAndConditions }: {termsAndConditions: TermsAndConditionsRequestDto}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
    commit(TermsAndConditionsMutations.SET_IS_UPDATING_TERMS_AND_CONDITIONS, true);
    axios
      .put(
        process.env.VUE_APP_API_ORISIS +
          'Sale/TermsAndConditions/UpdateTermsAndConditions',
          termsAndConditions
      )
      .then(res => {
        commit(TermsAndConditionsMutations.SET_TERMS_AND_CONDITIONS, res.data.data);
        commit(TermsAndConditionsMutations.SET_REQUEST_STATE_TERMS_AND_CONDITIONS, res);
        commit(TermsAndConditionsMutations.SET_IS_UPDATING_TERMS_AND_CONDITIONS, false);
        resolve(res.data);
      })
      .catch(err => {
        console.error(err);
        commit(TermsAndConditionsMutations.SET_REQUEST_STATE_TERMS_AND_CONDITIONS, err);
        commit(TermsAndConditionsMutations.SET_IS_UPDATING_TERMS_AND_CONDITIONS, false);
        reject(err);
      })
    });
  },

  deleteTermsAndConditions(
    { commit, rootGetters },
    { ids }: { ids: number[] }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(TermsAndConditionsMutations.SET_IS_DELETING_TERMS_AND_CONDITIONS, true);
      let url = `${process.env.VUE_APP_API_ORISIS}Sale/TermsAndConditions/Delete`;
      axios
        .delete(url, {
          data: ids,
        })
        .then((res) => {
          commit(TermsAndConditionsMutations.SET_IS_DELETING_TERMS_AND_CONDITIONS, false);
          commit(TermsAndConditionsMutations.SET_REQUEST_STATE_TERMS_AND_CONDITIONS, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TermsAndConditionsMutations.SET_IS_DELETING_TERMS_AND_CONDITIONS, false);
          commit(TermsAndConditionsMutations.SET_REQUEST_STATE_TERMS_AND_CONDITIONS, err);
          reject(err);
        });
    });
  },
}