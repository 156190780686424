import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { ActivitySourceTypeRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { IActivitySourceTypeState } from './states'


export const getters: GetterTree<IActivitySourceTypeState, RootState> = {
  activitySourceTypesList: (state): ActivitySourceTypeRequestDto[] => state.activitySourceTypesList,
  activitySourceTypesListArchived: (state): ActivitySourceTypeRequestDto[] => state.activitySourceTypesListArchived,
  activitySourceType     : (state): ActivitySourceTypeRequestDto => state.activitySourceType,

  // COMMON
  isLoadingActivitySourceTypesList   : (state): boolean => state.isLoadingActivitySourceTypesList,
  isLoadingActivitySourceTypesListArchived   : (state): boolean => state.isLoadingActivitySourceTypesListArchived,
  isLoadingActivitySourceType   : (state): boolean => state.isLoadingActivitySourceType,
  isCreatingActivitySourceType  : (state): boolean => state.isCreatingActivitySourceType,
  isUpdatingActivitySourceType  : (state): boolean => state.isUpdatingActivitySourceType,
  isDeletingActivitySourceType  : (state): boolean => state.isDeletingActivitySourceType,
  isArchivingActivitySourceType  : (state): boolean => state.isArchivingActivitySourceType,
  requestStateActivitySourceType: (state): IRequestState => state.requestStateActivitySourceType,
}



