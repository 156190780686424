import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { CompanyIndicatorRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { ICompanyIndicatorState } from './states'


export const getters: GetterTree<ICompanyIndicatorState, RootState> = {
  companyIndicatorsList: (state): CompanyIndicatorRequestDto[] => state.companyIndicatorsList,
  companyIndicatorsListArchived: (state): CompanyIndicatorRequestDto[] => state.companyIndicatorsListArchived,
  companyIndicator     : (state): CompanyIndicatorRequestDto => state.companyIndicator,

  // COMMON
  isLoadingCompanyIndicatorsList   : (state): boolean => state.isLoadingCompanyIndicatorsList,
  isLoadingCompanyIndicatorsListArchived   : (state): boolean => state.isLoadingCompanyIndicatorsListArchived,
  isLoadingCompanyIndicator   : (state): boolean => state.isLoadingCompanyIndicator,
  isCreatingCompanyIndicator  : (state): boolean => state.isCreatingCompanyIndicator,
  isUpdatingCompanyIndicator  : (state): boolean => state.isUpdatingCompanyIndicator,
  isDeletingCompanyIndicator  : (state): boolean => state.isDeletingCompanyIndicator,
  isArchivingCompanyIndicator  : (state): boolean => state.isArchivingCompanyIndicator,
  requestStateCompanyIndicator: (state): IRequestState => state.requestStateCompanyIndicator,
}



