import { MutationTree } from "vuex";
import { IDeliveryFormDetailState } from "./states";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export enum DeliveryFormDetailMutations {
  SET_DELIVERY_FORM_HEADER = "SET_DELIVERY_FORM_HEADER",
  SET_DELIVERY_FORM_DETAIL = "SET_DELIVERY_FORM_DETAIL",
  SET_DELIVERY_FORM_DETAILS = "SET_DELIVERY_FORM_DETAILS",
  SET_IS_LOADING_DELIVERY_FORM_DETAILS = "SET_IS_LOADING_DELIVERY_FORM_DETAILS",
  SET_IS_LOADING_DELIVERY_FORM_DETAIL_DUPLICATION = "SET_IS_LOADING_DELIVERY_FORM_DETAIL_DUPLICATION",
  SET_IS_CREATING_DELIVERY_FORM_DETAIL = "SET_IS_CREATING_DELIVERY_FORM_DETAIL",
  SET_IS_UPDATING_DELIVERY_FORM_DETAIL = "SET_IS_UPDATING_DELIVERY_FORM_DETAIL",
  SET_IS_DELETING_DELIVERY_FORM_DETAIL = "SET_IS_DELETING_DELIVERY_FORM_DETAIL",
  SET_REQUEST_STATE_DELIVERY_FORM_DETAIL = "SET_REQUEST_STATE_DELIVERY_FORM_DETAIL",
}

export const mutations: MutationTree<IDeliveryFormDetailState> = {
  [DeliveryFormDetailMutations.SET_DELIVERY_FORM_HEADER]: (
    state,
    payload: any
  ) => {
    state.deliveryFormHeader = payload;
  },
  [DeliveryFormDetailMutations.SET_DELIVERY_FORM_DETAILS]: (
    state,
    payload: any[]
  ) => {
    state.deliveryFormDetails = payload;
  },
  [DeliveryFormDetailMutations.SET_DELIVERY_FORM_DETAIL]: (
    state,
    payload: any
  ) => {
    state.deliveryFormDetail = payload;
  },
  [DeliveryFormDetailMutations.SET_IS_LOADING_DELIVERY_FORM_DETAILS]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingDeliveryFormDetails = payload;
  },
  [DeliveryFormDetailMutations.SET_IS_LOADING_DELIVERY_FORM_DETAIL_DUPLICATION]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingDeliveryFormDetailDuplication = payload;
  },
  [DeliveryFormDetailMutations.SET_IS_CREATING_DELIVERY_FORM_DETAIL]: (
    state,
    payload: boolean
  ) => {
    state.isCreatingDeliveryFormDetail = payload;
  },
  [DeliveryFormDetailMutations.SET_IS_UPDATING_DELIVERY_FORM_DETAIL]: (
    state,
    payload: boolean
  ) => {
    state.isUpdatingDeliveryFormDetail = payload;
  },
  [DeliveryFormDetailMutations.SET_IS_DELETING_DELIVERY_FORM_DETAIL]: (
    state,
    payload: boolean
  ) => {
    state.isDeletingDeliveryFormDetail = payload;
  },
  [DeliveryFormDetailMutations.SET_REQUEST_STATE_DELIVERY_FORM_DETAIL]: (
    state,
    payload: IRequestState
  ) => {
    state.requestStateDeliveryFormDetail = payload;
  },
};
