import { IPaymentMethodState } from "./states";
import { MutationTree } from "vuex";
import { PaymentMethodRequestDto } from "Api";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export enum PaymentMethodMutations {
  SET_PAYMENT_METHODS_LIST = "SET_PAYMENT_METHODS_LIST",
  SET_PAYMENT_METHODS_LIST_ARCHIVED = "SET_PAYMENT_METHODS_LIST_ARCHIVED",
  SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD",

  // COMON
  SET_IS_LOADING_PAYMENT_METHODS_LIST = "SET_IS_LOADING_PAYMENT_METHODS_LIST",
  SET_IS_LOADING_PAYMENT_METHODS_LIST_ARCHIVED = "SET_IS_LOADING_PAYMENT_METHODS_LIST_ARCHIVED",
  SET_IS_LOADING_PAYMENT_METHOD = "SET_IS_LOADING_PAYMENT_METHOD",
  SET_IS_CREATING_PAYMENT_METHOD = "SET_IS_CREATING_PAYMENT_METHOD",
  SET_IS_UPDATING_PAYMENT_METHOD = "SET_IS_UPDATING_PAYMENT_METHOD",
  SET_IS_DELETING_PAYMENT_METHOD = "SET_IS_DELETING_PAYMENT_METHOD",
  SET_REQUEST_STATE_PAYMENT_METHOD = "SET_REQUEST_STATE_PAYMENT_METHOD",
  SET_IS_ARCHIVING_PAYMENT_METHOD = "SET_IS_ARCHIVING_PAYMENT_METHOD",  
}

  export const mutations: MutationTree<IPaymentMethodState> = {
    [PaymentMethodMutations.SET_PAYMENT_METHODS_LIST]: (state, payload: PaymentMethodRequestDto[]) => {
        state.paymentMethodsList = payload;
    },
    [PaymentMethodMutations.SET_PAYMENT_METHODS_LIST_ARCHIVED]: (state, payload: PaymentMethodRequestDto[]) => {
        state.paymentMethodsListArchived = payload;
    },
    [PaymentMethodMutations.SET_PAYMENT_METHOD]: (state, payload: PaymentMethodRequestDto) => {
        state.paymentMethod = payload;
    },


    //COMMON
    [PaymentMethodMutations.SET_IS_LOADING_PAYMENT_METHODS_LIST]: (state, payload: boolean) => {
        state.isLoadingPaymentMethodsList = payload;
    },
    [PaymentMethodMutations.SET_IS_LOADING_PAYMENT_METHODS_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingPaymentMethodsListArchived = payload;
    },
    [PaymentMethodMutations.SET_IS_LOADING_PAYMENT_METHOD]: (state, payload: boolean) => {
        state.isLoadingPaymentMethod = payload;
    },
    [PaymentMethodMutations.SET_IS_CREATING_PAYMENT_METHOD]: (state, payload: boolean) => {
        state.isCreatingPaymentMethod = payload;
    },
    [PaymentMethodMutations.SET_IS_UPDATING_PAYMENT_METHOD]: (state, payload: boolean) => {
        state.isUpdatingPaymentMethod = payload;
    },
    [PaymentMethodMutations.SET_IS_DELETING_PAYMENT_METHOD]: (state, payload: boolean) => {
        state.isDeletingPaymentMethod = payload;
    },
    [PaymentMethodMutations.SET_REQUEST_STATE_PAYMENT_METHOD]: (state, payload: IRequestState) => {
        state.requestStatePaymentMethod = payload;
    },
    [PaymentMethodMutations.SET_IS_ARCHIVING_PAYMENT_METHOD]: (state, payload: boolean) => {
        state.isArchivingPaymentMethod = payload;
    }
}