import { IAnalyticsSettingState } from "./states"
import { Module } from "vuex"
import { AnalyticsSettingRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IAnalyticsSettingState = {
    analyticsSettingsList         : Array<AnalyticsSettingRequestDto>(),
    analyticsSettingsListArchived : Array<AnalyticsSettingRequestDto>(),
    analyticsSettingsListTemplates         : Array<AnalyticsSettingRequestDto>(),
    analyticsSetting              : <AnalyticsSettingRequestDto>{},
    isLoadingAnalyticsSettingsList     : false,
    isLoadingAnalyticsSettingsListArchived     : false,
    isLoadingAnalyticsSettingsListTemplates     : false,
    isLoadingAnalyticsSetting     : false,
    isCreatingAnalyticsSetting    : false,
    isUpdatingAnalyticsSetting    : false,
    isDeletingAnalyticsSetting    : false,
    isArchivingAnalyticsSetting    : false,
    requestStateAnalyticsSetting: <IRequestState>{},
}

export const AnalyticsSettingModule: Module<IAnalyticsSettingState, RootState> = {
    state, 
    getters,
    mutations,
    actions
} 