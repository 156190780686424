import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import { jwtDecode } from "jwt-decode";

import router from "./router";
import store from "./store";
import App from "./App.vue";
import "./types/api-orisis/directives/directives.js";

import authService from "@/auth/authService";
import addAuthInterceptors from "./auth/authInterceptors";
import env from "@/environments";

import "material-icons/iconfont/material-icons.css"; //Material Icons

import RouterTab from "vue-router-tab";
import "vue-router-tab/dist/lib/vue-router-tab.css";

import vuetify from "./plugins/vuetify.js";

import "./global-components";
import axios from "axios";

import "@/libs/portal-vue";
import "@/libs/toastification";
import VTooltip from "v-tooltip";
import Vuesax from "vuesax";

Vue.use(CheckBoxPlugin);
import {
  ChartPlugin,
  AccumulationChartPlugin,
} from "@syncfusion/ej2-vue-charts";
Vue.use(ChartPlugin);
Vue.use(AccumulationChartPlugin);
import { TreeGridPlugin } from "@syncfusion/ej2-vue-treegrid";
Vue.use(TreeGridPlugin);
import { ListViewPlugin } from "@syncfusion/ej2-vue-lists";
Vue.use(ListViewPlugin);
import {
  DropDownListPlugin,
  DropDownListComponent,
  DropDownTreePlugin,
} from "@syncfusion/ej2-vue-dropdowns";
Vue.use(DropDownListPlugin);
Vue.use(DropDownTreePlugin);
import { MultiSelectPlugin } from "@syncfusion/ej2-vue-dropdowns";
Vue.use(MultiSelectPlugin);
import {
  ChipListPlugin,
  ButtonPlugin,
  CheckBoxPlugin,
  RadioButtonPlugin,
} from "@syncfusion/ej2-vue-buttons";
Vue.use(ChipListPlugin);
Vue.use(ButtonPlugin);
Vue.use(CheckBoxPlugin);
Vue.use(RadioButtonPlugin);
import {
  ProgressButtonPlugin,
  DropDownButtonPlugin,
} from "@syncfusion/ej2-vue-splitbuttons";
Vue.use(ProgressButtonPlugin);
Vue.use(DropDownButtonPlugin);
import { DialogUtility } from '@syncfusion/ej2-popups';
Vue.use(ButtonPlugin);

import {
  GridPlugin,
  VirtualScroll,
  Sort,
  Filter,
  Selection,
  GridComponent,
} from "@syncfusion/ej2-vue-grids";
import {
  RatingPlugin,
  NumericTextBoxPlugin,
  TextBoxPlugin,
  SignaturePlugin,
} from "@syncfusion/ej2-vue-inputs";
Vue.use(RatingPlugin);
Vue.use(NumericTextBoxPlugin);
Vue.use(TextBoxPlugin);
Vue.use(SignaturePlugin);
import {
  DateRangePickerPlugin,
  DateTimePickerPlugin,
  DatePickerPlugin,
  TimePickerPlugin,
  CalendarPlugin,
} from "@syncfusion/ej2-vue-calendars";
Vue.use(DateRangePickerPlugin);
Vue.use(DateTimePickerPlugin);
Vue.use(TimePickerPlugin);
Vue.use(DatePickerPlugin);
Vue.use(CalendarPlugin);
import { DialogPlugin } from "@syncfusion/ej2-vue-popups";
Vue.use(DialogPlugin);
Vue.use(ColorPickerPlugin);
import { ColorPickerPlugin } from "@syncfusion/ej2-vue-inputs";
Vue.use(UploaderPlugin);
import { UploaderPlugin } from "@syncfusion/ej2-vue-inputs";
Vue.use(GridPlugin);
import { RichTextEditorPlugin } from "@syncfusion/ej2-vue-richtexteditor";
Vue.use(RichTextEditorPlugin);
import { TooltipPlugin } from "@syncfusion/ej2-vue-popups";
Vue.use(TooltipPlugin);
import { ComboBoxComponent } from "@syncfusion/ej2-vue-dropdowns";
import { SwitchPlugin } from "@syncfusion/ej2-vue-buttons";
Vue.use(SwitchPlugin);
// import { ScheduleComponent, SchedulePlugin, Day, Week, WorkWeek, Month, Agenda } from '@syncfusion/ej2-vue-schedule';
// Vue.use(SchedulePlugin);

// Traduction en français
import {
  loadCldr,
  L10n,
  setCulture,
  setCurrencyCode,
  enableRipple,
} from "@syncfusion/ej2-base";
enableRipple(true);

loadCldr(
  require("cldr-data/supplemental/numberingSystems.json"),
  require("cldr-data/main/fr/ca-gregorian.json"),
  require("cldr-data/main/fr/numbers.json"),
  require("cldr-data/main/fr/timeZoneNames.json"),
  require("cldr-data/main/fr/currencies.json")
);

// import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
// import * as gregorian from 'cldr-data/main/fr/ca-gregorian.json';
// import * as numbers from 'cldr-data/main/fr/numbers.json';
// import * as timeZoneNames from 'cldr-data/main/fr/timeZoneNames.json';
// loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);

setCulture("fr");
setCurrencyCode("EUR");
import SyncFr from "./syncfusion-fr";
L10n.load(SyncFr);

import VueCryptojs from "vue-cryptojs";

Vue.use(VueCryptojs);
// import 'vuesax/dist/vuesax.css' //Vuesax styles

Vue.use(Vuesax, {
  // options here
});
Vue.use(VTooltip, {
  themes: {
    orisis: {
      $extend: "dropdown",
      triggers: ["hover"],
      autoHide: true,
      eagerMount: true,
      placement: "bottom",
      strategy: "fixed",
      instantMove: true,
      handleResize: true,
      html: true,
    },
  },
});
// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(VueSweetalert2);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require("@core/scss/core.scss");

require("@/assets/vuesax.css");
// import assets styles
require("@/assets/scss/style.scss");
// import 'v-tooltip/dist/v-tooltip.css'
import BootstrapVue from "bootstrap-vue";
Vue.use(BootstrapVue);
import VPopover from "vue-js-popover";
Vue.use(VPopover, { tooltip: true });
Vue.config.productionTip = false;
Vue.use(RouterTab);
// import {store} from 'vuex'

import "ag-grid-enterprise";

import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
import "bootstrap/scss/bootstrap.scss";
Vue.component("vue-typeahead-bootstrap", VueTypeaheadBootstrap);

import "leaflet/dist/leaflet.css";

import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCDYeBuipOfrJl4SYCBI8q-NEF8cCGbvIE",
    libraries: "places", // This is required if you use the Autocomplete plugin
  },
});


axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.data?.message) {
      store.dispatch("setIsGlobalLoading", false);
      vm.$bvToast.toast(error.response.data.message, {
        title: `Erreur ${error.response.status || ""}`,
        variant: "danger",
        solid: true,
        // toaster: 'b-toaster-bottom-center',
      });
    } else if (error.response.status === 401) {
      if (error.response.config.url.includes("Account/Login")) {
        store.dispatch(
          "setIsLogginMsg",
          "Identifiant ou mot de passe incorrect, veuillez recommencer."
        );
        store.dispatch("setIsGlobalLoading", false);
        // vm.$bvToast.toast("Identifiant ou mot de passe incorrect", {
        //   title: `Erreur d'authentification`,
        //   variant: "danger",
        //   solid: true,
        // })
      } else {
        if (!store.getters.isToastingNotification) {
          if (router.path?.includes("/login")) {
            store.dispatch(
              "setIsLogginMsg",
              "Identifiant ou mot de passe incorrect, veuillez recommencer."
            );
            store.dispatch("setIsGlobalLoading", false);
            // vm.$bvToast.toast("Identifiant ou mot de passe incorrect !", {
            //   title: `Erreur d'authentification`,
            //   variant: "danger",
            //   solid: true,
            // })
          } else {
            vm.$bvToast.toast(
              "Merci de vous reconnecter avec vos identifiants ORISIS.",
              {
                title: `Erreur d'authentification`,
                variant: "danger",
                solid: true,
              }
            );
          }
        }
        store.dispatch("setIsToastingNotification", true);
        store.dispatch("logOut");
        if (!router.path || !router.path?.includes("/login")) {
          router.push("/login");
          store.dispatch("setIsGlobalLoading", false);
        }
      }
    } else if (error.response.status === 404) {
      store.dispatch("setIsToastingNotification", true);
      vm.$bvToast.toast(
        "Une erreur s'est produite, merci de contacter le support ORISIS.",
        {
          title: `Erreur d'accès`,
          variant: "danger",
          solid: true,
        }
      );
    } else if (error.response.status === 403) {
      store.dispatch("setIsToastingNotification", true);
      vm.$bvToast.toast(
        "Vous n'avez pas les droits.",
        {
          title: `Erreur d'accès`,
          variant: "danger",
          solid: true,
        }
      );
    } else {
      store.dispatch("setIsToastingNotification", true);
      vm.$bvToast.toast(
        "Une erreur s'est produite, merci de contacter le support ORISIS.",
        {
          title: `Erreur système`,
          variant: "danger",
          solid: true,
        }
      );
    }
    return Promise.reject(error);
  }
);

//Interceptor for axios
addAuthInterceptors(axios);

import { registerLicense } from "@syncfusion/ej2-base";

registerLicense(`${process.env.VUE_APP_SYNCFUSION_KEY}`);

Vue.component("v-style", {
  render: function (createElement) {
    return createElement("style", this.$slots.default);
  },
});
let vm: Vue = null;

function renderApp() {
  vm = new Vue({
    el: "#app",
    vuetify,
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
}

authService.loadUser().then((user) => {
  store.dispatch("tryAutoLogin", authService);
  if ((authService.isLoggedIn() && jwtDecode<any>(localStorage.getItem("userToken")).institution_id)) {
    const sessionId = Math.random().toString(36).substring(2);
    sessionStorage.setItem('sessionId', sessionId);
    window.addEventListener('storage', (event) => {
      if (event.key === 'refreshSignal') {
        const { signal, originId } = JSON.parse(event.newValue);
        if (signal === 'refresh' && originId !== sessionId) {
          if ((vm as any) && (vm as any).$tabs)
            (vm as any).$tabs.reset();
          location.reload();
        }
      }
    });
    renderApp();
  }
  else if (window.location.href.includes(`/first-login`)) {
    const token = window.location.pathname.split('/first-login/')[1]
    window.location.href = `${env.OpenIdConnect.Authority}Account/ResetPassword?returnUrl=${env.CurrentUrl}&token=${token}`
  }
  else {
    if (!authService.isLoggedIn()) {
      authService.login(window.location.href);
    }
    else {
      authService.logout();
    }
  }
  
}).catch(error => {
  console.error('Error while signing in:', error);
  authService.login(window.location.href);
});

Vue.prototype.$scheduler = {};
export { vm };
