import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IGlobalState } from "./states";

export const getters: GetterTree<IGlobalState, RootState> = {
  isGlobalCreating: (state): boolean => state.isGlobalCreating,
  isGlobalLoading: (state): boolean => state.isGlobalLoading,
  isGlobalMessage: (state): String => state.isGlobalMessage,
  requestStateGlobal: (state): IRequestState => state.requestStateGlobal,
  headerDataUserParameterIsLoading: (state) =>
    state.headerDataUserParameterIsLoading,
  setModalReceiptForm: (state) =>
    state.setModalReceiptForm,
};
