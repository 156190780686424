import { MutationTree } from "vuex";
import { ICreditOptionDefaultState } from "./states";
import { CreditOptionRequestDto } from "Api";

export const enum CreditOptionDefaultMutations {
  SET_CREDIT_OPTION_DEFAULT = "SET_CREDIT_OPTION_DEFAULT",
  SET_CREDIT_OPTION_DEFAULT_FORM = "SET_CREDIT_OPTION_DEFAULT_FORM",
  SET_IS_LOADING_CREDIT_OPTION_DEFAULT = "SET_IS_LOADING_CREDIT_OPTION_DEFAULT",
  SET_REQUEST_STATE_CREDIT_OPTION_DEFAULT = "SET_REQUEST_STATE_CREDIT_OPTION_DEFAULT",
}

export const mutations: MutationTree<ICreditOptionDefaultState> = {
  [CreditOptionDefaultMutations.SET_CREDIT_OPTION_DEFAULT](
    state,
    payload: CreditOptionRequestDto
  ) {
    state.creditOptionDefault = payload;
  },
  [CreditOptionDefaultMutations.SET_CREDIT_OPTION_DEFAULT_FORM](
    state,
    payload: CreditOptionRequestDto
  ) {
    state.creditOptionDefaultForm = payload;
  },
  [CreditOptionDefaultMutations.SET_IS_LOADING_CREDIT_OPTION_DEFAULT](
    state,
    payload: boolean
  ) {
    state.isLoadingCreditOptionDefault = payload;
  },
  [CreditOptionDefaultMutations.SET_REQUEST_STATE_CREDIT_OPTION_DEFAULT](
    state,
    payload: any
  ) {
    state.requestStateCreditOptionDefault = payload;
  },
};
