import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { ProductProviderRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IProductProviderState } from "./states";

export enum ProductProviderMutations {
    SET_PRODUCT_PROVIDERS_LIST = "SET_PRODUCT_PROVIDERS_LIST",
    SET_PRODUCT_PROVIDERS_LIST_ARCHIVED = "SET_PRODUCT_PROVIDERS_LIST_ARCHIVED",
    SET_PRODUCT_PROVIDER = "SET_PRODUCT_PROVIDER",
    SET_PRODUCT_PROVIDER_SORTED_BY_PRODUCT_TYPE = 'SET_PRODUCT_PROVIDER_SORTED_BY_PRODUCT_TYPE',

    // COMMON
    SET_IS_LOADING_PRODUCT_PROVIDERS_LIST = "SET_IS_LOADING_PRODUCT_PROVIDERS_LIST",
    SET_IS_LOADING_PRODUCT_PROVIDERS_LIST_ARCHIVED = "SET_IS_LOADING_PRODUCT_PROVIDERS_LIST_ARCHIVED",
    SET_IS_LOADING_PRODUCT_PROVIDER = "SET_IS_LOADING_PRODUCT_PROVIDER",
    SET_IS_CREATING_PRODUCT_PROVIDER = "SET_IS_CREATING_PRODUCT_PROVIDER",
    SET_IS_UPDATING_PRODUCT_PROVIDER = "SET_IS_UPDATING_PRODUCT_PROVIDER",
    SET_IS_DELETING_PRODUCT_PROVIDER = "SET_IS_DELETING_PRODUCT_PROVIDER",
    SET_IS_ARCHIVING_PRODUCT_PROVIDER = "SET_IS_ARCHIVING_PRODUCT_PROVIDER",
    SET_REQUEST_STATE_PRODUCT_PROVIDER = "SET_REQUEST_STATE_PRODUCT_PROVIDER",
}

export const mutations: MutationTree<IProductProviderState> = {
    [ProductProviderMutations.SET_PRODUCT_PROVIDERS_LIST_ARCHIVED]: (state, payload: ProductProviderRequestDto[]) => {
        state.productProvidersListArchived = payload;
    }, 
    [ProductProviderMutations.SET_PRODUCT_PROVIDERS_LIST]: (state, payload: ProductProviderRequestDto[]) => {
        state.productProvidersList = payload;
    },
    [ProductProviderMutations.SET_PRODUCT_PROVIDER]: (state, payload: ProductProviderRequestDto) => {
        state.productProvider = payload;
    },
    [ProductProviderMutations.SET_PRODUCT_PROVIDER_SORTED_BY_PRODUCT_TYPE]: (state, payload: ProductProviderRequestDto[]) => {  
        state.productProviderSortedByProductType = payload;
    },

    //COMMON
    [ProductProviderMutations.SET_IS_LOADING_PRODUCT_PROVIDERS_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingProductProvidersListArchived = payload;
    },
    [ProductProviderMutations.SET_IS_LOADING_PRODUCT_PROVIDERS_LIST]: (state, payload: boolean) => {
        state.isLoadingProductProvidersList = payload;
    },
    [ProductProviderMutations.SET_IS_LOADING_PRODUCT_PROVIDER]: (state, payload: boolean) => {
        state.isLoadingProductProvider = payload;
    },
    [ProductProviderMutations.SET_IS_CREATING_PRODUCT_PROVIDER]: (state, payload: boolean) => {
        state.isCreatingProductProvider = payload;
    },
    [ProductProviderMutations.SET_IS_UPDATING_PRODUCT_PROVIDER]: (state, payload: boolean) => {
        state.isUpdatingProductProvider = payload;
    },
    [ProductProviderMutations.SET_IS_DELETING_PRODUCT_PROVIDER]: (state, payload: boolean) => {
        state.isDeletingProductProvider = payload;
    },
    [ProductProviderMutations.SET_IS_ARCHIVING_PRODUCT_PROVIDER]: (state, payload: boolean) => {
        state.isArchivingProductProvider = payload;
    },
    [ProductProviderMutations.SET_REQUEST_STATE_PRODUCT_PROVIDER]: (state, payload: IRequestState) => {
        state.requestStateProductProvider = payload;
    },
}