import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { CustomFieldGroupRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { ICustomFieldGroupState } from "./states";

export enum CustomFieldGroupMutations {
    SET_CUSTOM_FIELD_GROUPS_LIST = "SET_CUSTOM_FIELD_GROUPS_LIST",
    SET_CUSTOM_FIELD_GROUPS_LIST_ARCHIVED = "SET_CUSTOM_FIELD_GROUPS_LIST_ARCHIVED",
    SET_CUSTOM_FIELD_GROUP = "SET_CUSTOM_FIELD_GROUP",

    //FILTERS
    SET_CUSTOM_FIELD_GROUP_FILTERS = "SET_CUSTOM_FIELD_GROUP_FILTERS",

    // COMMON
    SET_IS_LOADING_CUSTOM_FIELD_GROUP_LIST = "SET_IS_LOADING_CUSTOM_FIELD_GROUP_LIST",
    SET_IS_LOADING_CUSTOM_FIELD_GROUP_LIST_ARCHIVED = "SET_IS_LOADING_CUSTOM_FIELD_GROUP_LIST_ARCHIVED",
    SET_IS_LOADING_CUSTOM_FIELD_GROUP = "SET_IS_LOADING_CUSTOM_FIELD_GROUP",
    SET_IS_CREATING_CUSTOM_FIELD_GROUP = "SET_IS_CREATING_CUSTOM_FIELD_GROUP",
    SET_IS_UPDATING_CUSTOM_FIELD_GROUP = "SET_IS_UPDATING_CUSTOM_FIELD_GROUP",
    SET_IS_DELETING_CUSTOM_FIELD_GROUP = "SET_IS_DELETING_CUSTOM_FIELD_GROUP",
    SET_IS_ARCHIVING_CUSTOM_FIELD_GROUP = "SET_IS_ARCHIVING_CUSTOM_FIELD_GROUP",
    SET_REQUEST_STATE_CUSTOM_FIELD_GROUP = "SET_REQUEST_STATE_CUSTOM_FIELD_GROUP",
}

export const mutations: MutationTree<ICustomFieldGroupState> = {
    [CustomFieldGroupMutations.SET_CUSTOM_FIELD_GROUPS_LIST]: (state, payload: CustomFieldGroupRequestDto[]) => {
        state.customFieldGroupsList = payload;
    },
    [CustomFieldGroupMutations.SET_CUSTOM_FIELD_GROUPS_LIST_ARCHIVED]: (state, payload: CustomFieldGroupRequestDto[]) => {
        state.customFieldGroupsListArchived = payload;
    }, 
    [CustomFieldGroupMutations.SET_CUSTOM_FIELD_GROUP]: (state, payload: CustomFieldGroupRequestDto) => {
        state.customFieldGroup = payload;
    },

    //FILTERS
    [CustomFieldGroupMutations.SET_CUSTOM_FIELD_GROUP_FILTERS]: (state, payload: any) => {
        state.customFieldGroupFilters = payload;
    },


    //COMMON
    [CustomFieldGroupMutations.SET_IS_LOADING_CUSTOM_FIELD_GROUP_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingCustomFieldGroupsListArchived = payload;
    },
    [CustomFieldGroupMutations.SET_IS_LOADING_CUSTOM_FIELD_GROUP_LIST]: (state, payload: boolean) => {
        state.isLoadingCustomFieldGroupsList = payload;
    },
    [CustomFieldGroupMutations.SET_IS_LOADING_CUSTOM_FIELD_GROUP]: (state, payload: boolean) => {
        state.isLoadingCustomFieldGroup = payload;
    },
    [CustomFieldGroupMutations.SET_IS_CREATING_CUSTOM_FIELD_GROUP]: (state, payload: boolean) => {
        state.isCreatingCustomFieldGroup = payload;
    },
    [CustomFieldGroupMutations.SET_IS_UPDATING_CUSTOM_FIELD_GROUP]: (state, payload: boolean) => {
        state.isUpdatingCustomFieldGroup = payload;
    },
    [CustomFieldGroupMutations.SET_IS_DELETING_CUSTOM_FIELD_GROUP]: (state, payload: boolean) => {
        state.isDeletingCustomFieldGroup = payload;
    },
    [CustomFieldGroupMutations.SET_IS_ARCHIVING_CUSTOM_FIELD_GROUP]: (state, payload: boolean) => {
        state.isArchivingCustomFieldGroup = payload;
    },
    [CustomFieldGroupMutations.SET_REQUEST_STATE_CUSTOM_FIELD_GROUP]: (state, payload: IRequestState) => {
        state.requestStateCustomFieldGroup = payload;
    }
}