import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { PayAllowanceTypeRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IPayAllowanceTypeState } from "./states";

export enum PayAllowanceTypeMutations {
    SET_PAY_ALLOWANCE_TYPES_LIST = "SET_PAY_ALLOWANCE_TYPES_LIST",
    SET_PAY_ALLOWANCE_TYPES_LIST_ARCHIVED = "SET_PAY_ALLOWANCE_TYPES_LIST_ARCHIVED",
    SET_PAY_ALLOWANCE_TYPE_ARCHIVED = "SET_PAY_ALLOWANCE_TYPE_ARCHIVED",
    SET_PAY_ALLOWANCE_TYPES_LIST_TEMPLATES = "SET_PAY_ALLOWANCE_TYPES_LIST_TEMPLATES",
    SET_PAY_ALLOWANCE_TYPE = "SET_PAY_ALLOWANCE_TYPE",

    // COMMON
    SET_IS_LOADING_PAY_ALLOWANCE_TYPE_LIST = "SET_IS_LOADING_PAY_ALLOWANCE_TYPE_LIST",
    SET_IS_LOADING_PAY_ALLOWANCE_TYPE_LIST_ARCHIVED = "SET_IS_LOADING_PAY_ALLOWANCE_TYPE_LIST_ARCHIVED",
    SET_IS_LOADING_PAY_ALLOWANCE_TYPE_ARCHIVED = "SET_IS_LOADING_PAY_ALLOWANCE_TYPE_ARCHIVED",
    SET_IS_LOADING_PAY_ALLOWANCE_TYPE_LIST_TEMPLATES = "SET_IS_LOADING_PAY_ALLOWANCE_TYPE_LIST_TEMPLATES",
    SET_IS_LOADING_PAY_ALLOWANCE_TYPE = "SET_IS_LOADING_PAY_ALLOWANCE_TYPE",
    SET_IS_CREATING_PAY_ALLOWANCE_TYPE = "SET_IS_CREATING_PAY_ALLOWANCE_TYPE",
    SET_IS_UPDATING_PAY_ALLOWANCE_TYPE = "SET_IS_UPDATING_PAY_ALLOWANCE_TYPE",
    SET_IS_DELETING_PAY_ALLOWANCE_TYPE = "SET_IS_DELETING_PAY_ALLOWANCE_TYPE",
    SET_IS_ARCHIVING_PAY_ALLOWANCE_TYPE = "SET_IS_ARCHIVING_PAY_ALLOWANCE_TYPE",
    SET_REQUEST_STATE_PAY_ALLOWANCE_TYPE = "SET_REQUEST_STATE_PAY_ALLOWANCE_TYPE",
}

export const mutations: MutationTree<IPayAllowanceTypeState> = {
    [PayAllowanceTypeMutations.SET_PAY_ALLOWANCE_TYPES_LIST_ARCHIVED]: (state, payload: PayAllowanceTypeRequestDto[]) => {
        state.payAllowanceTypesListArchived = payload;
    },
    [PayAllowanceTypeMutations.SET_PAY_ALLOWANCE_TYPE_ARCHIVED]: (state, payload: PayAllowanceTypeRequestDto) => {
        state.payAllowanceTypeArchived = payload;
    },
    [PayAllowanceTypeMutations.SET_PAY_ALLOWANCE_TYPES_LIST]: (state, payload: PayAllowanceTypeRequestDto[]) => {
        state.payAllowanceTypesList = payload;
    },
    [PayAllowanceTypeMutations.SET_PAY_ALLOWANCE_TYPES_LIST_TEMPLATES]: (state, payload: PayAllowanceTypeRequestDto[]) => {
        state.payAllowanceTypesListTemplates = payload;
    },
    [PayAllowanceTypeMutations.SET_PAY_ALLOWANCE_TYPE]: (state, payload: PayAllowanceTypeRequestDto) => {
        state.payAllowanceType = payload;
    },


    //COMMON
    [PayAllowanceTypeMutations.SET_IS_LOADING_PAY_ALLOWANCE_TYPE_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingPayAllowanceTypesListArchived = payload;
    },
    [PayAllowanceTypeMutations.SET_IS_LOADING_PAY_ALLOWANCE_TYPE_LIST]: (state, payload: boolean) => {
        state.isLoadingPayAllowanceTypesList = payload;
    },
    [PayAllowanceTypeMutations.SET_IS_LOADING_PAY_ALLOWANCE_TYPE_LIST_TEMPLATES]: (state, payload: boolean) => {
        state.isLoadingPayAllowanceTypesListTemplates = payload;
    },
    [PayAllowanceTypeMutations.SET_IS_LOADING_PAY_ALLOWANCE_TYPE]: (state, payload: boolean) => {
        state.isLoadingPayAllowanceType = payload;
    },
    [PayAllowanceTypeMutations.SET_IS_CREATING_PAY_ALLOWANCE_TYPE]: (state, payload: boolean) => {
        state.isCreatingPayAllowanceType = payload;
    },
    [PayAllowanceTypeMutations.SET_IS_UPDATING_PAY_ALLOWANCE_TYPE]: (state, payload: boolean) => {
        state.isUpdatingPayAllowanceType = payload;
    },
    [PayAllowanceTypeMutations.SET_IS_DELETING_PAY_ALLOWANCE_TYPE]: (state, payload: boolean) => {
        state.isDeletingPayAllowanceType = payload;
    },
    [PayAllowanceTypeMutations.SET_IS_ARCHIVING_PAY_ALLOWANCE_TYPE]: (state, payload: boolean) => {
        state.isArchivingPayAllowanceType = payload;
    },
    [PayAllowanceTypeMutations.SET_REQUEST_STATE_PAY_ALLOWANCE_TYPE]: (state, payload: IRequestState) => {
        state.requestStatePayAllowanceType = payload;
    },
}
