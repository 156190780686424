import { ContactRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { ContactMutations } from "./mutations";
import { IContactState } from "./states";

export const actions: ActionTree<IContactState, RootState> = {
  async getContacts({ commit }, { updateState = true }): Promise<void> {
    return await new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(ContactMutations.SET_IS_LOADING_CONTACTS_LIST, true);
      }
      await axios
        .get(process.env.VUE_APP_API_ORISIS + "Directory/Contact/GetContacts")
        .then((res) => {
          if (updateState) {
            commit(ContactMutations.SET_CONTACTS_LIST, res.data);
            commit(ContactMutations.SET_IS_LOADING_CONTACTS_LIST, false);
          }
          commit(ContactMutations.SET_REQUEST_STATE_CONTACT, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ContactMutations.SET_REQUEST_STATE_CONTACT, err);
          commit(ContactMutations.SET_IS_LOADING_CONTACTS_LIST, false);
          reject(err);
        });
    });
  },

  async getContactsArchived({ commit }, { updateState = true }): Promise<void> {
    return await new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(ContactMutations.SET_IS_LOADING_CONTACTS_LIST_ARCHIVED, true);
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Directory/Contact/GetContactsArchived"
        )
        .then((res) => {
          if (updateState) {
            commit(ContactMutations.SET_CONTACTS_LIST_ARCHIVED, res.data);
            commit(
              ContactMutations.SET_IS_LOADING_CONTACTS_LIST_ARCHIVED,
              false
            );
          }
          commit(ContactMutations.SET_REQUEST_STATE_CONTACT, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ContactMutations.SET_REQUEST_STATE_CONTACT, err);
          commit(ContactMutations.SET_IS_LOADING_CONTACTS_LIST, false);
          reject(err);
        });
    });
  },

  async getContactById(
    { commit },
    {
      contactId,
      updateState = true,
    }: { contactId: number; updateState: boolean }
  ): Promise<void> {
    return await new Promise(async (resolve, reject) => {
      if (updateState) {
        commit(ContactMutations.SET_IS_LOADING_CONTACT, true);
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS + "Directory/Contact/GetContactById",
          {
            params: {
              contactId: contactId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(ContactMutations.SET_CONTACT, res.data);
            commit(ContactMutations.SET_IS_LOADING_CONTACT, false);
          }
          commit(ContactMutations.SET_REQUEST_STATE_CONTACT, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ContactMutations.SET_REQUEST_STATE_CONTACT, err);
          commit(ContactMutations.SET_IS_LOADING_CONTACT, false);
          reject(err);
        });
    });
  },

  async getContactArchivedById(
    { commit },
    {
      contactId,
      updateState = true,
    }: { contactId: number; updateState: boolean }
  ): Promise<void> {
    return await new Promise(async (resolve, reject) => {
      if (updateState) {
        commit(ContactMutations.SET_IS_LOADING_CONTACT, true);
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Directory/Contact/GetContactArchivedById",
          {
            params: {
              contactId: contactId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(ContactMutations.SET_CONTACT, res.data);
            commit(ContactMutations.SET_IS_LOADING_CONTACT, false);
          }
          commit(ContactMutations.SET_REQUEST_STATE_CONTACT, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ContactMutations.SET_REQUEST_STATE_CONTACT, err);
          commit(ContactMutations.SET_IS_LOADING_CONTACT, false);
          reject(err);
        });
    });
  },

  async createContact(
    { commit, rootGetters },
    { contact }: { contact: ContactRequestDto }
  ): Promise<void> {
    return await new Promise(async (resolve, reject) => {
      commit(ContactMutations.SET_IS_CREATING_CONTACT, true);
      await axios
        .post(
          process.env.VUE_APP_API_ORISIS + "Directory/Contact/CreateContact",
          contact
        )
        .then((res) => {
          rootGetters.contactsList.unshift(res.data.data);
          commit(ContactMutations.SET_CONTACTS_LIST, rootGetters.contactsList);
          commit(ContactMutations.SET_REQUEST_STATE_CONTACT, res);
          commit(ContactMutations.SET_IS_CREATING_CONTACT, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ContactMutations.SET_REQUEST_STATE_CONTACT, err);
          commit(ContactMutations.SET_IS_CREATING_CONTACT, false);
          reject(err);
        });
    });
  },

  async updateContact(
    { commit, rootGetters },
    { contact }: { contact: ContactRequestDto }
  ) {
    return new Promise(async (resolve, reject) => {
      commit(ContactMutations.SET_IS_UPDATING_CONTACT, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Directory/Contact/UpdateContact`,
          contact
        )
        .then((res) => {
          rootGetters.contactsList.splice(
            rootGetters.contactsList.findIndex((elem) => elem.id == contact.id),
            1,
            contact
          );
          commit(ContactMutations.SET_CONTACTS_LIST, rootGetters.contactsList);
          commit(ContactMutations.SET_REQUEST_STATE_CONTACT, res);
          commit(ContactMutations.SET_IS_UPDATING_CONTACT, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ContactMutations.SET_REQUEST_STATE_CONTACT, err);
          commit(ContactMutations.SET_IS_UPDATING_CONTACT, false);
          reject(err);
        });
    });
  },

  async updateContactTagContact(
    { commit },
    {
      contactIds = [],
      contactTagIds = [],
    }: { contactIds: number[]; contactTagIds: number[] }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Directory/Contact/UpdateContactTagContact`,
          { 
            contactIds: contactIds, 
            contactTagIds: contactTagIds 
          }
        )
        .then(async (res) => {
          commit(ContactMutations.SET_REQUEST_STATE_CONTACT, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ContactMutations.SET_REQUEST_STATE_CONTACT, err);
          reject(err);
        });
    });
  },

  async restoreContacts(
    { commit, rootGetters },
    { contactIds = [] }: { contactIds: number[] }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(ContactMutations.SET_IS_UPDATING_CONTACT, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Directory/Contact/Restore",
          contactIds
        )
        .then((res) => {
          contactIds.forEach((contactId) => {
            rootGetters.contactsListArchived.splice(
              rootGetters.contactsListArchived.findIndex(
                (elem) => elem.id == contactId
              ),
              1
            );
          });
          commit(
            ContactMutations.SET_CONTACTS_LIST_ARCHIVED,
            rootGetters.contactsListArchived
          );
          commit(ContactMutations.SET_REQUEST_STATE_CONTACT, res);
          commit(ContactMutations.SET_IS_UPDATING_CONTACT, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ContactMutations.SET_REQUEST_STATE_CONTACT, err);
          commit(ContactMutations.SET_IS_UPDATING_CONTACT, false);
          reject(err);
        });
    });
  },

  async archiveContacts(
    { commit, rootGetters },
    { contactIds = [] }: { contactIds: number[] }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(ContactMutations.SET_IS_ARCHIVING_CONTACT, true);
      await axios
        .delete(process.env.VUE_APP_API_ORISIS + "Directory/Contact/Archive", {
          data: contactIds,
        })
        .then((res) => {
          contactIds.forEach((contactId) => {
            rootGetters.contactsList.splice(
              rootGetters.contactsList.findIndex(
                (elem) => elem.id == contactId
              ),
              1
            );
          });
          commit(ContactMutations.SET_CONTACTS_LIST, rootGetters.contactsList);
          commit(ContactMutations.SET_REQUEST_STATE_CONTACT, res);
          commit(ContactMutations.SET_IS_ARCHIVING_CONTACT, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ContactMutations.SET_REQUEST_STATE_CONTACT, err);
          commit(ContactMutations.SET_IS_ARCHIVING_CONTACT, false);
          reject(err);
        });
    });
  },

  async deleteContacts(
    { commit, rootGetters },
    { contactIds = [] }: { contactIds: number[] }
  ) {
    return new Promise(async (resolve, reject) => {
      commit(ContactMutations.SET_IS_DELETING_CONTACT, true);
      await axios
        .delete(`${process.env.VUE_APP_API_ORISIS}Directory/Contact/Delete`, {
          data: contactIds,
        })
        .then((res) => {
          contactIds.forEach((contactId) => {
            rootGetters.contactsListArchived.splice(
              rootGetters.contactsListArchived.findIndex(
                (elem) => elem.id == contactId
              ),
              1
            );
          });
          commit(
            ContactMutations.SET_CONTACTS_LIST_ARCHIVED,
            rootGetters.contactsListArchived
          );
          commit(ContactMutations.SET_REQUEST_STATE_CONTACT, res);
          commit(ContactMutations.SET_IS_DELETING_CONTACT, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ContactMutations.SET_REQUEST_STATE_CONTACT, err);
          commit(ContactMutations.SET_IS_DELETING_CONTACT, false);
          reject(err);
        });
    });
  },
};
