import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { ModuleParameterRequestDto } from '@/../Api'
import { GetterTree } from "vuex";
import RootState from '@/store/modules/rootState'
import {  IModuleParameterState } from "./states";

export const getters: GetterTree<IModuleParameterState, RootState> = {
    moduleParametersList: (state): ModuleParameterRequestDto[] => state.moduleParametersList,
    moduleParametersListArchived: (state): ModuleParameterRequestDto[] => state.moduleParametersListArchived,
    moduleParameter     : (state): ModuleParameterRequestDto => state.moduleParameter,
    
    // COMMON
    isLoadingModuleParametersList   : (state): boolean => state.isLoadingModuleParametersList,
    isLoadingModuleParametersListArchived : (state): boolean => state.isLoadingModuleParametersListArchived,
    isLoadingModuleParameter   : (state): boolean => state.isLoadingModuleParameter,
    isCreatingModuleParameter: (state): boolean => state.isCreatingModuleParameter,
    isUpdatingModuleParameter: (state): boolean => state.isUpdatingModuleParameter,
    isArchivingModuleParameter: (state): boolean => state.isArchivingModuleParameter,
    isDeletingModuleParameter: (state): boolean => state.isDeletingModuleParameter,
    requestStateModuleParameter: (state): IRequestState => state.requestStateModuleParameter,
}