import { IActivityChanelTypeState } from "./states"
import { Module } from "vuex"
import { ActivityChanelTypeRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IActivityChanelTypeState = {
    activityChanelTypesList         : Array<ActivityChanelTypeRequestDto>(),
    activityChanelTypesListArchived : Array<ActivityChanelTypeRequestDto>(),
    activityChanelType              : <ActivityChanelTypeRequestDto>{},
    isLoadingActivityChanelTypesList     : false,
    isLoadingActivityChanelTypesListArchived     : false,
    isLoadingActivityChanelType     : false,
    isCreatingActivityChanelType    : false,
    isUpdatingActivityChanelType    : false,
    isDeletingActivityChanelType    : false,
    isArchivingActivityChanelType    : false,
    requestStateActivityChanelType: <IRequestState>{},
    activityChanelTypeFilters                : {}
}

export const ActivityChanelTypeModule: Module<IActivityChanelTypeState, RootState> = {
    state, 
    getters,
    mutations,
    actions
} 