import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import RootState from "@/store/modules/rootState";
import { IInstitutionState } from "./states";
import { Module } from "vuex";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { InstitutionModel } from "@/types/api-orisis/models/InstitutionModel";
import { InstitutionRequestDto } from "Api";

export const state: IInstitutionState = {
  institutionsListArchived: Array<InstitutionRequestDto>(),
  institutionsListMinified: Array<InstitutionRequestDto>(),
  institutionsList: Array<InstitutionRequestDto>(),
  institution: InstitutionModel,
  institutionSelected: InstitutionModel,
  isLoadingInstitutionsList: false,
  isLoadingInstitutionsListArchived: false,
  isLoadingInstitutionsListMinified: false,
  isLoadingInstitution: false,
  isCreatingInstitution: false,
  isUpdatingInstitution: false,
  isDeletingInstitution: false,
  isArchivingInstitution: false,
  requestStateInstitution: <IRequestState>{},
};

export const InstitutionModule: Module<IInstitutionState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
