import { PayAllowanceTypeRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { PayAllowanceTypeMutations } from "./mutations";
import { IPayAllowanceTypeState } from "./states";

export const actions: ActionTree<IPayAllowanceTypeState, RootState> = {
  async getPayAllowanceTypeById(
    { commit },
    {
      payAllowanceTypeId,
      updateState = true,
    }: { payAllowanceTypeId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          PayAllowanceTypeMutations.SET_IS_LOADING_PAY_ALLOWANCE_TYPE,
          true
        );
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Reporting/PayAllowanceType/GetPayAllowanceTypeById`,
          {
            params: {
              payAllowanceTypeId: payAllowanceTypeId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(PayAllowanceTypeMutations.SET_PAY_ALLOWANCE_TYPE, res.data);
            commit(
              PayAllowanceTypeMutations.SET_IS_LOADING_PAY_ALLOWANCE_TYPE,
              false
            );
          }
          commit(
            PayAllowanceTypeMutations.SET_REQUEST_STATE_PAY_ALLOWANCE_TYPE,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            PayAllowanceTypeMutations.SET_REQUEST_STATE_PAY_ALLOWANCE_TYPE,
            err
          );
          if (updateState) {
            commit(
              PayAllowanceTypeMutations.SET_IS_LOADING_PAY_ALLOWANCE_TYPE,
              false
            );
          }
          reject(err);
        });
    });
  },

  async getPayAllowanceTypes(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          PayAllowanceTypeMutations.SET_IS_LOADING_PAY_ALLOWANCE_TYPE_LIST,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Reporting/PayAllowanceType/GetPayAllowanceTypes`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              PayAllowanceTypeMutations.SET_IS_LOADING_PAY_ALLOWANCE_TYPE_LIST,
              false
            );
            commit(
              PayAllowanceTypeMutations.SET_PAY_ALLOWANCE_TYPES_LIST,
              res.data
            );
          }
          commit(
            PayAllowanceTypeMutations.SET_REQUEST_STATE_PAY_ALLOWANCE_TYPE,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              PayAllowanceTypeMutations.SET_IS_LOADING_PAY_ALLOWANCE_TYPE_LIST,
              false
            );
          }
          commit(
            PayAllowanceTypeMutations.SET_REQUEST_STATE_PAY_ALLOWANCE_TYPE,
            err
          );
          reject(err);
        });
    });
  },

  async getPayAllowanceTypeArchivedById(
    { commit },
    {
      payAllowanceTypeId,
      updateState = true,
    }: { payAllowanceTypeId: number; updateState: boolean }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          PayAllowanceTypeMutations.SET_IS_LOADING_PAY_ALLOWANCE_TYPE_ARCHIVED,
          true
        );
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Reporting/PayAllowanceType/GetPayAllowanceTypeArchivedById`,
          {
            params: {
              payAllowanceTypeId: payAllowanceTypeId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(
              PayAllowanceTypeMutations.SET_PAY_ALLOWANCE_TYPE_ARCHIVED,
              res.data
            );
            commit(
              PayAllowanceTypeMutations.SET_IS_LOADING_PAY_ALLOWANCE_TYPE_ARCHIVED,
              false
            );
          }
          commit(
            PayAllowanceTypeMutations.SET_REQUEST_STATE_PAY_ALLOWANCE_TYPE,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            PayAllowanceTypeMutations.SET_REQUEST_STATE_PAY_ALLOWANCE_TYPE,
            err
          );
          if (updateState) {
            commit(
              PayAllowanceTypeMutations.SET_IS_LOADING_PAY_ALLOWANCE_TYPE_ARCHIVED,
              false
            );
          }
          reject(err);
        });
    });
  },

  async getPayAllowanceTypesArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          PayAllowanceTypeMutations.SET_IS_LOADING_PAY_ALLOWANCE_TYPE_LIST_ARCHIVED,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Reporting/PayAllowanceType/GetPayAllowanceTypesArchived`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              PayAllowanceTypeMutations.SET_IS_LOADING_PAY_ALLOWANCE_TYPE_LIST_ARCHIVED,
              false
            );
            commit(
              PayAllowanceTypeMutations.SET_PAY_ALLOWANCE_TYPES_LIST_ARCHIVED,
              res.data
            );
          }
          commit(
            PayAllowanceTypeMutations.SET_REQUEST_STATE_PAY_ALLOWANCE_TYPE,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              PayAllowanceTypeMutations.SET_IS_LOADING_PAY_ALLOWANCE_TYPE_LIST_ARCHIVED,
              false
            );
          }
          commit(
            PayAllowanceTypeMutations.SET_REQUEST_STATE_PAY_ALLOWANCE_TYPE,
            err
          );
          reject(err);
        });
    });
  },

  //GetPayAllowanceTypesListArchived

  async createPayAllowanceType(
    { commit, rootGetters },
    { payAllowanceType }: { payAllowanceType: PayAllowanceTypeRequestDto }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        PayAllowanceTypeMutations.SET_IS_CREATING_PAY_ALLOWANCE_TYPE,
        true
      );
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Reporting/PayAllowanceType/CreatePayAllowanceType`,
          payAllowanceType
        )
        .then((res) => {
          rootGetters.payAllowanceTypesList.push(res.data.data);
          commit(
            PayAllowanceTypeMutations.SET_PAY_ALLOWANCE_TYPES_LIST,
            rootGetters.payAllowanceTypesList
          );
          commit(
            PayAllowanceTypeMutations.SET_REQUEST_STATE_PAY_ALLOWANCE_TYPE,
            res
          );
          commit(
            PayAllowanceTypeMutations.SET_IS_CREATING_PAY_ALLOWANCE_TYPE,
            false
          );
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            PayAllowanceTypeMutations.SET_REQUEST_STATE_PAY_ALLOWANCE_TYPE,
            err
          );
          commit(
            PayAllowanceTypeMutations.SET_IS_CREATING_PAY_ALLOWANCE_TYPE,
            false
          );
          reject(err);
        });
    });
  },

  async updatePayAllowanceType(
    { commit, rootGetters },
    { payAllowanceType }: { payAllowanceType: PayAllowanceTypeRequestDto }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        PayAllowanceTypeMutations.SET_IS_UPDATING_PAY_ALLOWANCE_TYPE,
        true
      );
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Reporting/PayAllowanceType/UpdatePayAllowanceType`,
          payAllowanceType
        )
        .then((res) => {
          rootGetters.payAllowanceTypesList.splice(
            rootGetters.payAllowanceTypesList.findIndex(
              (elem) => elem.id == payAllowanceType.id
            ),
            1,
            res.data.data
          );
          commit(
            PayAllowanceTypeMutations.SET_PAY_ALLOWANCE_TYPES_LIST,
            rootGetters.payAllowanceTypesList
          );
          commit(
            PayAllowanceTypeMutations.SET_REQUEST_STATE_PAY_ALLOWANCE_TYPE,
            res
          );
          commit(
            PayAllowanceTypeMutations.SET_IS_UPDATING_PAY_ALLOWANCE_TYPE,
            false
          );
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            PayAllowanceTypeMutations.SET_REQUEST_STATE_PAY_ALLOWANCE_TYPE,
            err
          );
          commit(
            PayAllowanceTypeMutations.SET_IS_UPDATING_PAY_ALLOWANCE_TYPE,
            false
          );
          reject(err);
        });
    });
  },

  // async restorePayAllowanceType({}) {},

  async archivePayAllowanceTypes(
    { commit, rootGetters },
    { payAllowanceTypeIds = [] }: { payAllowanceTypeIds: number[] }
  ): Promise<void> {
    commit(PayAllowanceTypeMutations.SET_IS_ARCHIVING_PAY_ALLOWANCE_TYPE, true);
    await axios
      .delete(
        process.env.VUE_APP_API_ORISIS + "Reporting/PayAllowanceType/Archive",
        {
          data: payAllowanceTypeIds,
        }
      )
      .then((res) => {
        payAllowanceTypeIds.forEach((id) => {
          rootGetters.payAllowanceTypesList.splice(
            rootGetters.payAllowanceTypesList.findIndex(
              (elem) => elem.id == id
            ),
            1
          );
        });
        commit(
          PayAllowanceTypeMutations.SET_PAY_ALLOWANCE_TYPES_LIST,
          rootGetters.payAllowanceTypesList
        );
        commit(
          PayAllowanceTypeMutations.SET_REQUEST_STATE_PAY_ALLOWANCE_TYPE,
          res
        );
        commit(
          PayAllowanceTypeMutations.SET_IS_ARCHIVING_PAY_ALLOWANCE_TYPE,
          false
        );
      })
      .catch((err) => {
        console.error(err);
        commit(
          PayAllowanceTypeMutations.SET_REQUEST_STATE_PAY_ALLOWANCE_TYPE,
          err
        );
        commit(
          PayAllowanceTypeMutations.SET_IS_ARCHIVING_PAY_ALLOWANCE_TYPE,
          false
        );
      });
  },

  async deletePayAllowanceTypes(
    { commit, rootGetters },
    { payAllowanceTypeIds = [] }: { payAllowanceTypeIds: number[] }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        PayAllowanceTypeMutations.SET_IS_DELETING_PAY_ALLOWANCE_TYPE,
        true
      );
      await axios
        .delete(
          `${process.env.VUE_APP_API_ORISIS}Reporting/PayAllowanceType/Delete`,
          {
            data: payAllowanceTypeIds,
          }
        )
        .then((res) => {
          payAllowanceTypeIds.forEach((id) => {
            rootGetters.payAllowanceTypesList.splice(
              rootGetters.payAllowanceTypesList.findIndex(
                (elem) => elem.id == id
              ),
              1
            );
          });
          commit(
            PayAllowanceTypeMutations.SET_PAY_ALLOWANCE_TYPES_LIST,
            rootGetters.payAllowanceTypesList
          );
          commit(
            PayAllowanceTypeMutations.SET_REQUEST_STATE_PAY_ALLOWANCE_TYPE,
            res
          );
          commit(
            PayAllowanceTypeMutations.SET_IS_DELETING_PAY_ALLOWANCE_TYPE,
            false
          );
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            PayAllowanceTypeMutations.SET_REQUEST_STATE_PAY_ALLOWANCE_TYPE,
            err
          );
          commit(
            PayAllowanceTypeMutations.SET_IS_DELETING_PAY_ALLOWANCE_TYPE,
            false
          );
          reject(err);
        });
    });
  },
};
