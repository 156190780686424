import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { DegressivePurchaseRatesRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IDegressivePurchaseRateState } from "./states";

export enum DegressivePurchaseRateMutations {
    SET_DEGRESSIVE_PURCHASE_RATES_LIST = "SET_DEGRESSIVE_PURCHASE_RATES_LIST",
    SET_DEGRESSIVE_PURCHASE_RATE = "SET_DEGRESSIVE_PURCHASE_RATE",

    // COMMON
    SET_IS_LOADING_DEGRESSIVE_PURCHASE_RATES_LIST = "SET_IS_LOADING_DEGRESSIVE_PURCHASE_RATES_LIST",
    SET_IS_LOADING_DEGRESSIVE_PURCHASE_RATE = "SET_IS_LOADING_DEGRESSIVE_PURCHASE_RATE",
    SET_IS_CREATING_DEGRESSIVE_PURCHASE_RATE = "SET_IS_CREATING_DEGRESSIVE_PURCHASE_RATE",
    SET_IS_UPDATING_DEGRESSIVE_PURCHASE_RATE = "SET_IS_UPDATING_DEGRESSIVE_PURCHASE_RATE",
    SET_IS_DELETING_DEGRESSIVE_PURCHASE_RATE = "SET_IS_DELETING_DEGRESSIVE_PURCHASE_RATE",
    SET_REQUEST_STATE_DEGRESSIVE_PURCHASE_RATE = "SET_REQUEST_STATE_DEGRESSIVE_PURCHASE_RATE",
}

export const mutations: MutationTree<IDegressivePurchaseRateState> = {
    [DegressivePurchaseRateMutations.SET_DEGRESSIVE_PURCHASE_RATES_LIST]: (state, payload: DegressivePurchaseRatesRequestDto[]) => {
        state.degressivePurchaseRatesList = payload;
    },
    [DegressivePurchaseRateMutations.SET_DEGRESSIVE_PURCHASE_RATE]: (state, payload: DegressivePurchaseRatesRequestDto) => {
        state.degressivePurchaseRate = payload;
    },

    //COMMON
    [DegressivePurchaseRateMutations.SET_IS_LOADING_DEGRESSIVE_PURCHASE_RATES_LIST]: (state, payload: boolean) => {
        state.isLoadingDegressivePurchaseRatesList = payload;
    },
    [DegressivePurchaseRateMutations.SET_IS_LOADING_DEGRESSIVE_PURCHASE_RATE]: (state, payload: boolean) => {
        state.isLoadingDegressivePurchaseRate = payload;
    },
    [DegressivePurchaseRateMutations.SET_IS_CREATING_DEGRESSIVE_PURCHASE_RATE]: (state, payload: boolean) => {
        state.isCreatingDegressivePurchaseRate = payload;
    },
    [DegressivePurchaseRateMutations.SET_IS_UPDATING_DEGRESSIVE_PURCHASE_RATE]: (state, payload: boolean) => {
        state.isUpdatingDegressivePurchaseRate = payload;
    },
    [DegressivePurchaseRateMutations.SET_IS_DELETING_DEGRESSIVE_PURCHASE_RATE]: (state, payload: boolean) => {
        state.isDeletingDegressivePurchaseRate = payload;
    },
    [DegressivePurchaseRateMutations.SET_REQUEST_STATE_DEGRESSIVE_PURCHASE_RATE]: (state, payload: IRequestState) => {
        state.requestStateDegressivePurchaseRate = payload;
    },
}