import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { ITag } from "@/types/api-orisis/interfaces/Settings/tag/ITag";
import { MutationTree } from "vuex";
import { ITagState } from "./states";

export enum TagMutations {
    SET_TAGS_LIST = "SET_TAGS_LIST",
    SET_TAGS_LIST_ARCHIVED = "SET_TAGS_LIST_ARCHIVED",

    SET_QUOTE_TAGS_LIST = "SET_QUOTE_TAGS_LIST",
    SET_ORDER_FORM_CUSTOMER_TAGS_LIST = "SET_ORDER_FORM_CUSTOMER_TAGS_LIST",
    SET_COMPANY_TAGS_LIST = "SET_COMPANY_TAGS_LIST",
    SET_INVOICE_TAGS_LIST = "SET_INVOICE_TAGS_LIST",
    SET_CREDIT_TAGS_LIST = "SET_CREDIT_TAGS_LIST",
    SET_ORDER_FORM_PROVIDER_TAGS_LIST = "SET_ORDER_FORM_PROVIDER_TAGS_LIST",
    SET_DELIVERY_FORM_TAGS_LIST = "SET_DELIVERY_FORM_TAGS_LIST",
    SET_RECEIPT_FORM_PROVIDER_TAGS_LIST = "SET_RECEIPT_FORM_PROVIDER_TAGS_LIST",
    SET_INVOICE_PROVIDER_TAGS_LIST = "SET_INVOICE_PROVIDER_TAGS_LIST",
    SET_CREDIT_PROVIDER_TAGS_LIST = "SET_CREDIT_PROVIDER_TAGS_LIST",
    SET_AFFAIR_TAGS_LIST = "SET_AFFAIR_TAGS_LIST",
    SET_COLLABORATOR_TAGS_LIST = "SET_COLLABORATOR_TAGS_LIST",
    SET_CONTACT_TAGS_LIST = "SET_CONTACT_TAGS_LIST",
    
    SET_QUOTE_TAGS_LIST_SELECTED = "SET_QUOTE_TAGS_LIST_SELECTED",
    SET_ORDER_FORM_CUSTOMER_TAGS_LIST_SELECTED = "SET_ORDER_FORM_CUSTOMER_TAGS_LIST_SELECTED",
    SET_COMPANY_TAGS_LIST_SELECTED = "SET_COMPANY_TAGS_LIST_SELECTED",
    SET_INVOICE_TAGS_LIST_SELECTED = "SET_INVOICE_TAGS_LIST_SELECTED",  
    SET_CREDIT_TAGS_LIST_SELECTED = "SET_CREDIT_TAGS_LIST_SELECTED",
    SET_ORDER_FORM_PROVIDER_TAGS_LIST_SELECTED = "SET_ORDER_FORM_PROVIDER_TAGS_LIST_SELECTED",
    SET_DELIVERY_FORM_TAGS_LIST_SELECTED = "SET_DELIVERY_FORM_TAGS_LIST_SELECTED",
    SET_RECEIPT_FORM_PROVIDER_TAGS_LIST_SELECTED = "SET_RECEIPT_FORM_PROVIDER_TAGS_LIST_SELECTED",
    SET_INVOICE_PROVIDER_TAGS_LIST_SELECTED = "SET_INVOICE_PROVIDER_TAGS_LIST_SELECTED",
    SET_CREDIT_PROVIDER_TAGS_LIST_SELECTED = "SET_CREDIT_PROVIDER_TAGS_LIST_SELECTED",
    SET_AFFAIR_TAGS_LIST_SELECTED = "SET_AFFAIR_TAGS_LIST_SELECTED",
    SET_COLLABORATOR_TAGS_LIST_SELECTED = "SET_COLLABORATOR_TAGS_LIST_SELECTED",
    SET_CONTACT_TAGS_LIST_SELECTED = "SET_CONTACT_TAGS_LIST_SELECTED",

    SET_TAG = "SET_TAG",

    // COMMON
    SET_IS_LOADING_TAG_LIST = "SET_IS_LOADING_TAG_LIST",
    SET_IS_LOADING_TAG_LIST_ARCHIVED = "SET_IS_LOADING_TAG_LIST_ARCHIVED",
    SET_IS_LOADING_TAG = "SET_IS_LOADING_TAG",
    SET_IS_CREATING_TAG = "SET_IS_CREATING_TAG",
    SET_IS_UPDATING_TAG = "SET_IS_UPDATING_TAG",
    SET_IS_DELETING_TAG = "SET_IS_DELETING_TAG",
    SET_IS_ARCHIVING_TAG = "SET_IS_ARCHIVING_TAG",
    SET_REQUEST_STATE_TAG = "SET_REQUEST_STATE_TAG",
}

export const mutations: MutationTree<ITagState> = {
    [TagMutations.SET_TAGS_LIST]: (state, payload: ITag[]) => {
        state.tagsList = payload;
    },
    [TagMutations.SET_TAGS_LIST_ARCHIVED]: (state, payload: ITag[]) => {
        state.tagsListArchived = payload;
    }, 

    [TagMutations.SET_QUOTE_TAGS_LIST]: (state, payload: ITag[]) => {
        state.quoteTagsList = payload;
    },
    [TagMutations.SET_ORDER_FORM_CUSTOMER_TAGS_LIST]: (state, payload: ITag[]) => {
        state.orderFormCustomerTagsList = payload;
    },
    [TagMutations.SET_COMPANY_TAGS_LIST]: (state, payload: ITag[]) => {
        state.companyTagsList = payload;
    },
    [TagMutations.SET_INVOICE_TAGS_LIST]: (state, payload: ITag[]) => {
        state.invoiceTagsList = payload;
    },
    [TagMutations.SET_CREDIT_TAGS_LIST]: (state, payload: ITag[]) => {
        state.creditTagsList = payload;
    },
    [TagMutations.SET_ORDER_FORM_PROVIDER_TAGS_LIST]: (state, payload: ITag[]) => {
        state.orderFormProviderTagsList = payload;
    },
    [TagMutations.SET_DELIVERY_FORM_TAGS_LIST]: (state, payload: ITag[]) => {
        state.deliveryFormTagsList = payload;
    },
    [TagMutations.SET_RECEIPT_FORM_PROVIDER_TAGS_LIST]: (state, payload: ITag[]) => {
        state.receiptFormProviderTagsList = payload;
    },
    [TagMutations.SET_INVOICE_PROVIDER_TAGS_LIST]: (state, payload: ITag[]) => {
        state.invoiceProviderTagsList = payload;
    },
    [TagMutations.SET_CREDIT_PROVIDER_TAGS_LIST]: (state, payload: ITag[]) => {
        state.creditProviderTagsList = payload;
    },
    [TagMutations.SET_AFFAIR_TAGS_LIST]: (state, payload: ITag[]) => {
        state.affairTagsList = payload;
    },
    [TagMutations.SET_COLLABORATOR_TAGS_LIST]: (state, payload: ITag[]) => {
        state.collaboratorTagsList = payload;
    },
    [TagMutations.SET_CONTACT_TAGS_LIST]: (state, payload: ITag[]) => {
        state.contactTagsList = payload;
    },
    
    [TagMutations.SET_QUOTE_TAGS_LIST_SELECTED]: (state, payload: ITag[]) => {
        state.quoteTagsListSelected = payload;
    },
    [TagMutations.SET_ORDER_FORM_CUSTOMER_TAGS_LIST_SELECTED]: (state, payload: ITag[]) => {
        state.orderFormCustomerTagsListSelected = payload;
    },
    [TagMutations.SET_COMPANY_TAGS_LIST_SELECTED]: (state, payload: ITag[]) => {
        state.companyTagsListSelected = payload;
    },
    [TagMutations.SET_INVOICE_TAGS_LIST_SELECTED]: (state, payload: ITag[]) => {
        state.invoiceTagsListSelected = payload;
    },
    [TagMutations.SET_CREDIT_TAGS_LIST_SELECTED]: (state, payload: ITag[]) => {
        state.creditTagsListSelected = payload;
    },
    [TagMutations.SET_ORDER_FORM_PROVIDER_TAGS_LIST_SELECTED]: (state, payload: ITag[]) => {
        state.orderFormProviderTagsListSelected = payload;
    },
    [TagMutations.SET_DELIVERY_FORM_TAGS_LIST_SELECTED]: (state, payload: ITag[]) => {
        state.deliveryFormTagsListSelected = payload;
    },
    [TagMutations.SET_RECEIPT_FORM_PROVIDER_TAGS_LIST_SELECTED]: (state, payload: ITag[]) => {
        state.receiptFormProviderTagsListSelected = payload;
    },
    [TagMutations.SET_INVOICE_PROVIDER_TAGS_LIST_SELECTED]: (state, payload: ITag[]) => {
        state.invoiceProviderTagsListSelected = payload;
    },
    [TagMutations.SET_CREDIT_PROVIDER_TAGS_LIST_SELECTED]: (state, payload: ITag[]) => {
        state.creditProviderTagsListSelected = payload;
    },
    [TagMutations.SET_AFFAIR_TAGS_LIST_SELECTED]: (state, payload: ITag[]) => {
        state.affairTagsListSelected = payload;
    },
    [TagMutations.SET_COLLABORATOR_TAGS_LIST_SELECTED]: (state, payload: ITag[]) => {
        state.collaboratorTagsListSelected = payload;
    },
    [TagMutations.SET_CONTACT_TAGS_LIST_SELECTED]: (state, payload: ITag[]) => {
        state.contactTagsListSelected = payload;
    },
    
    [TagMutations.SET_TAG]: (state, payload: ITag) => {
        state.tag = payload;
    },

    //COMMON
    [TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingTagsListArchived = payload;
    },
    [TagMutations.SET_IS_LOADING_TAG_LIST]: (state, payload: boolean) => {
        state.isLoadingTagsList = payload;
    },
    [TagMutations.SET_IS_LOADING_TAG]: (state, payload: boolean) => {
        state.isLoadingTag = payload;
    },
    [TagMutations.SET_IS_CREATING_TAG]: (state, payload: boolean) => {
        state.isCreatingTag = payload;
    },
    [TagMutations.SET_IS_UPDATING_TAG]: (state, payload: boolean) => {
        state.isUpdatingTag = payload;
    },
    [TagMutations.SET_IS_DELETING_TAG]: (state, payload: boolean) => {
        state.isDeletingTag = payload;
    },
    [TagMutations.SET_IS_ARCHIVING_TAG]: (state, payload: boolean) => {
        state.isArchivingTag = payload;
    },
    [TagMutations.SET_REQUEST_STATE_TAG]: (state, payload: IRequestState) => {
        state.requestStateTag = payload;
    },

}