import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { HoldbackMutations } from "./mutations";
import { IHoldbackState } from "./states";

import dayjs from "dayjs";
import { Timezone } from "@syncfusion/ej2-schedule";
import { HoldbackRequestDto } from "Api";
let timezoneSchedule = new Timezone();

export const actions: ActionTree<IHoldbackState, RootState> = {
  async getHoldbacks(
    { commit },
    {
      dateFrom = null,
      dateTo = null,
      updateState = true,
    }: { dateFrom: Date; dateTo: Date; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(HoldbackMutations.SET_IS_LOADING_HOLDBACKS_LIST, true);
      }
      let url = process.env.VUE_APP_API_ORISIS + "/Sale/Holdback/GetHoldbacks";
      await axios
        .get(url, {
          params: {
            dateFrom: dateFrom ? dayjs(dateFrom).format("YYYY-MM-DD") : null,
            dateTo: dateTo
              ? dayjs(dateTo)
                  .hour(23)
                  .minute(59)
                  .second(59)
                  .format("YYYY-MM-DD HH:mm:ss")
              : null,
          },
        })
        .then((res) => {
          let list = res.data.map((el) => {
            return {
              ...el,
              date: el.date
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(new Date(el.date))
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              limiteDate: el.limiteDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(el.limiteDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
            };
          });
          if (updateState) {
            commit(HoldbackMutations.SET_IS_LOADING_HOLDBACKS_LIST, false);
            commit(HoldbackMutations.SET_HOLDBACKS_LIST, list);
          }
          commit(HoldbackMutations.SET_REQUEST_STATE_HOLDBACK, list);
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          commit(HoldbackMutations.SET_IS_LOADING_HOLDBACKS_LIST, false);
          commit(HoldbackMutations.SET_REQUEST_STATE_HOLDBACK, err);
          reject(err);
        });
    });
  },

  async getHoldbackById(
    { commit },
    {
      holdbackId,
      updateState = true,
    }: { holdbackId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(HoldbackMutations.SET_IS_LOADING_HOLDBACK, true);
      }
      let url =
        process.env.VUE_APP_API_ORISIS + "/Sale/Holdback/GetHoldbackById";
      await axios
        .get(url, {
          params: {
            holdbackId: holdbackId,
          },
        })
        .then((res) => {
          if (updateState) {
            commit(HoldbackMutations.SET_IS_LOADING_HOLDBACK, false);
            commit(HoldbackMutations.SET_HOLDBACK, res.data);
          }
          commit(HoldbackMutations.SET_REQUEST_STATE_HOLDBACK, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(HoldbackMutations.SET_IS_LOADING_HOLDBACK, false);
          commit(HoldbackMutations.SET_REQUEST_STATE_HOLDBACK, err);
          reject(err);
        });
    });
  },

  async updateHoldback(
    { commit, rootGetters },
    {
      holdback,
      updateState = true,
    }: { holdback: HoldbackRequestDto; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) commit(HoldbackMutations.SET_IS_UPDATING_HOLDBACK, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Sale/Holdback/UpdateHoldback`,
          holdback
        )
        .then((res) => {
          rootGetters.holdbacksList.splice(
            rootGetters.affairsList.findIndex((elem) => elem.id == holdback.id),
            1,
            res.data.data
          );
          commit(
            HoldbackMutations.SET_HOLDBACKS_LIST,
            rootGetters.holdbacksList
          );
          if (updateState) {
            commit(HoldbackMutations.SET_HOLDBACK, res.data.data);
            commit(HoldbackMutations.SET_IS_UPDATING_HOLDBACK, false);
          }
          commit(HoldbackMutations.SET_REQUEST_STATE_HOLDBACK, res.data.data);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(HoldbackMutations.SET_REQUEST_STATE_HOLDBACK, err);
          commit(HoldbackMutations.SET_IS_UPDATING_HOLDBACK, false);
          reject(err);
        });
    });
  },
};
