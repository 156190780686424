import { InventoryDetailRequestDto } from "Api";
import { GetterTree } from "vuex";
import RootState from '@/store/modules/rootState'
import { IInventoryDetailState } from "./states";

export const getters: GetterTree<IInventoryDetailState, RootState> = {
    inventoryDetails: (state): InventoryDetailRequestDto[] => state.inventoryDetails,
    inventoryDetailsFormated: (state): any => state.inventoryDetailsFormated,
    getInventoryHeader: (state): any => state.inventoryHeader,
    getInventoryDetail: (state) : InventoryDetailRequestDto => state.inventoryDetail,
    isLoadingInventoryDetails: (state) : boolean => state.isLoadingInventoryDetails,
    isCreatingInventoryDetail: (state): boolean => state.isCreatingInventoryDetail,
    isUpdatingInventoryDetail: (state): boolean => state.isUpdatingInventoryDetail,
    isDeletingInventoryDetail: (state): boolean => state.isDeletingInventoryDetail,
}