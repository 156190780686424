import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { CreditProviderRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { ICreditProviderState } from "./states";
import { IFilters } from "@/types/api-orisis/interfaces/invoices/filters/IFilters";

export enum CreditProviderMutations {
    SET_CREDIT_PROVIDERS_LIST = "SET_CREDIT_PROVIDERS_LIST",
    SET_CREDIT_PROVIDER = "SET_CREDIT_PROVIDER",
    SET_CREDIT_PROVIDER_PDF = "SET_CREDIT_PROVIDER_PDF",

    // COMMON
    SET_IS_LOADING_CREDIT_PROVIDER_LIST = "SET_IS_LOADING_CREDIT_PROVIDER_LIST",
    SET_IS_LOADING_CREDIT_PROVIDER = "SET_IS_LOADING_CREDIT_PROVIDER",
    SET_CREDIT_PROVIDER_FILTERS = "SET_CREDIT_PROVIDER_FILTERS",
    SET_IS_CREATING_CREDIT_PROVIDER = "SET_IS_CREATING_CREDIT_PROVIDER",
    SET_IS_UPDATING_CREDIT_PROVIDER = "SET_IS_UPDATING_CREDIT_PROVIDER",
    SET_IS_DELETING_CREDIT_PROVIDER = "SET_IS_DELETING_CREDIT_PROVIDER",
    SET_REQUEST_STATE_CREDIT_PROVIDER = "SET_REQUEST_STATE_CREDIT_PROVIDER",
    SET_IS_LOADING_CREDIT_PROVIDERS_LIST_BY_AFFAIR = "SET_IS_LOADING_CREDIT_PROVIDERS_LIST_BY_AFFAIR",
    SET_CREDIT_PROVIDERS_LIST_BY_AFFAIR = "SET_CREDIT_PROVIDERS_LIST_BY_AFFAIR",
    SET_IS_LOADING_CREDIT_PROVIDERS_LIST_BY_PROVIDER = "SET_IS_LOADING_CREDIT_PROVIDERS_LIST_BY_PROVIDER",
    SET_CREDIT_PROVIDER_LIST_BY_PROVIDER = "SET_CREDIT_PROVIDER_LIST_BY_PROVIDER",
}

export const mutations: MutationTree<ICreditProviderState> = {
    [CreditProviderMutations.SET_CREDIT_PROVIDERS_LIST]: (state, payload: CreditProviderRequestDto[]) => {
        state.creditProvidersList = payload;
    },
    [CreditProviderMutations.SET_CREDIT_PROVIDER]: (state, payload: CreditProviderRequestDto) => {
        state.creditProvider = payload;
    },

    //COMMON
    [CreditProviderMutations.SET_IS_LOADING_CREDIT_PROVIDER_LIST]: (state, payload: boolean) => {
        state.isLoadingCreditProvidersList = payload;
    },
    [CreditProviderMutations.SET_IS_LOADING_CREDIT_PROVIDER]: (state, payload: boolean) => {
        state.isLoadingCreditProvider = payload;
    },
    [CreditProviderMutations.SET_IS_CREATING_CREDIT_PROVIDER]: (state, payload: boolean) => {
        state.isCreatingCreditProvider = payload;
    },
    [CreditProviderMutations.SET_IS_UPDATING_CREDIT_PROVIDER]: (state, payload: boolean) => {
        state.isUpdatingCreditProvider = payload;
    },
    [CreditProviderMutations.SET_IS_DELETING_CREDIT_PROVIDER]: (state, payload: boolean) => {
        state.isDeletingCreditProvider = payload;
    },
    [CreditProviderMutations.SET_REQUEST_STATE_CREDIT_PROVIDER]: (state, payload: IRequestState) => {
        state.requestStateCreditProvider = payload;
    },
    [CreditProviderMutations.SET_CREDIT_PROVIDER_FILTERS]: (state, payload: IFilters) => {
        state.creditProviderFilters = payload;
    },
    [CreditProviderMutations.SET_IS_LOADING_CREDIT_PROVIDERS_LIST_BY_AFFAIR]: (state, payload: boolean) => {
        state.isLoadingCreditProvidersListByAffair = payload;
    },
    [CreditProviderMutations.SET_CREDIT_PROVIDERS_LIST_BY_AFFAIR]: (state, payload: CreditProviderRequestDto[]) => {
        state.creditProvidersListByAffair = payload;
    },
    [CreditProviderMutations.SET_IS_LOADING_CREDIT_PROVIDERS_LIST_BY_PROVIDER]: (state, payload: boolean) => {
        state.isLoadingCreditProvidersListByProvider = payload;
    },
    [CreditProviderMutations.SET_CREDIT_PROVIDER_LIST_BY_PROVIDER]: (state, payload: CreditProviderRequestDto[]) => {
        state.creditProviderListByProvider = payload;
    }
}