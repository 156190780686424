import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { GetterTree } from "vuex";
import RootState from '@/store/modules/rootState'
import {  IDocumentHistoryState } from "./states";

export const getters: GetterTree<IDocumentHistoryState, RootState> = {
    documentHistoriesList: (state): [] => state.documentHistoriesList,
    documentHistory     : (state): {} => state.documentHistory,
    
    // COMMON
    isLoadingDocumentHistoriesList   : (state): boolean => state.isLoadingDocumentHistoriesList,
    isLoadingDocumentHistory : (state): boolean => state.isLoadingDocumentHistory,
    requestStateDocumentHistory: (state): IRequestState => state.requestStateDocumentHistory,
}