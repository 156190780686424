import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { MutationTree } from "vuex";
import { IStockMovementState } from "./states";
import { IFilters } from "@/types/api-orisis/interfaces/invoices/filters/IFilters";

export enum StockMovementMutations {
    SET_STOCK_MOVEMENTS_LIST = "SET_STOCK_MOVEMENTS_LIST",
    SET_STOCK_MOVEMENT = "SET_STOCK_MOVEMENT",

    //FILTERS
    SET_STOCK_MOVEMENT_FILTERS = "SET_STOCK_MOVEMENT_FILTERS",

    // COMMON
    SET_IS_LOADING_STOCK_MOVEMENTS_LIST = "SET_IS_LOADING_STOCK_MOVEMENTS_LIST",
    SET_IS_LOADING_STOCK_MOVEMENT = "SET_IS_LOADING_STOCK_MOVEMENT",
    SET_REQUEST_STATE_STOCK_MOVEMENT = "SET_REQUEST_STATE_STOCK_MOVEMENT",
}

export const mutations: MutationTree<IStockMovementState> = {
    [StockMovementMutations.SET_STOCK_MOVEMENTS_LIST]: (state, payload: any[]) => {
        state.stockMovementsList = payload;
    },
    [StockMovementMutations.SET_STOCK_MOVEMENT]: (state, payload: any) => {
        state.stockMovement = payload;
    },

    //FILTERS
    [StockMovementMutations.SET_STOCK_MOVEMENT_FILTERS]: (state, payload: IFilters) => {
        state.stockMovementFilters = payload;
    },

    //COMMON
    [StockMovementMutations.SET_IS_LOADING_STOCK_MOVEMENTS_LIST]: (state, payload: boolean) => {
        state.isLoadingStockMovementsList = payload;
    },
    [StockMovementMutations.SET_IS_LOADING_STOCK_MOVEMENT]: (state, payload: boolean) => {
        state.isLoadingStockMovement = payload;
    },
    [StockMovementMutations.SET_REQUEST_STATE_STOCK_MOVEMENT]: (state, payload: IRequestState) => {
        state.requestStateStockMovement = payload;
    }
}