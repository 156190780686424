import { EventRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { EventMutations } from "./mutations";
import { IEventState } from "./states";
var dayjs = require("dayjs");
import { Timezone } from "@syncfusion/ej2-schedule";
import { generateParams } from "@/types/api-orisis/library/FormatOperations";
let timezoneSchedule = new Timezone();

export const actions: ActionTree<IEventState, RootState> = {
  async getEvents(
    { commit },
    {
      startDate,
      endDate,
      companyId = null,
      collaboratorIds = [],
      updateState = true,
    }: {
      startDate: Date;
      endDate: Date;
      companyId: number;
      collaboratorIds: number[];
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(EventMutations.SET_IS_LOADING_EVENT_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Plannings/Event/GetEvents`;
      await axios
        .get(url, {
          params: {
            startDate: startDate,
            endDate: endDate,
            companyId: companyId,
            collaboratorIds: collaboratorIds,
          },
        })
        .then((res) => {
          let data = res.data.map((event: EventRequestDto) => {
            return {
              ...event,
              startDate: new Date(
                new Date(
                  timezoneSchedule.removeLocalOffset(new Date(event.startDate))
                ).setHours(12, 0, 0, 0)
              ),
              endDate: new Date(
                new Date(
                  timezoneSchedule.removeLocalOffset(new Date(event.endDate))
                ).setHours(12, 0, 0, 0)
              ),
            };
          });
          if (updateState) {
            commit(EventMutations.SET_IS_LOADING_EVENT_LIST, false);
            commit(EventMutations.SET_EVENTS_LIST, data);
          }
          commit(EventMutations.SET_REQUEST_STATE_EVENT, res);
          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          commit(EventMutations.SET_IS_LOADING_EVENT_LIST, false);
          commit(EventMutations.SET_REQUEST_STATE_EVENT, err);
          reject(err);
        });
    });
  },

  async getEventsByCompanyId(
    { commit },
    {
      startDate = null,
      endDate = null,
      companyId = null,
      updateState = true,
    }: {
      startDate: Date;
      endDate: Date;
      companyId: number;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(EventMutations.SET_IS_LOADING_EVENT_LIST_BY_COMPANY, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Plannings/Event/GetEventsByCompanyId`;
      await axios
        .get(url, {
          params: {
            startDate: startDate ? dayjs(startDate).format("YYYY-MM-DD") : null,
            endDate: endDate ? dayjs(endDate).format("YYYY-MM-DD") : null,
            companyId: companyId,
          },
        })
        .then((res) => {
          let data = res.data.map((event: EventRequestDto) => {
            return {
              ...event,
              startDate: new Date(
                new Date(
                  timezoneSchedule.removeLocalOffset(new Date(event.startDate))
                ).setHours(12, 0, 0, 0)
              ),
              endDate: new Date(
                new Date(
                  timezoneSchedule.removeLocalOffset(new Date(event.endDate))
                ).setHours(12, 0, 0, 0)
              ),
            };
          });
          if (updateState) {
            commit(EventMutations.SET_IS_LOADING_EVENT_LIST_BY_COMPANY, false);
            commit(EventMutations.SET_EVENTS_LIST_BY_COMPANY, data);
          }
          commit(EventMutations.SET_REQUEST_STATE_EVENT, res);
          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          commit(EventMutations.SET_IS_LOADING_EVENT_LIST_BY_COMPANY, false);
          commit(EventMutations.SET_REQUEST_STATE_EVENT, err);
          reject(err);
        });
    });
  },

  async getEventsByCollaboratorId(
    { commit },
    {
      startDate = null,
      endDate = null,
      collaboratorIds = null,
      updateState = true,
    }: {
      startDate: Date;
      endDate: Date;
      collaboratorIds: number[];
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(EventMutations.SET_IS_LOADING_EVENT_LIST_BY_COLLABORATOR, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Plannings/Event/GetEvents`;
      let request = new URLSearchParams();
      request = generateParams(request, collaboratorIds, "collaboratorIds");
      request = generateParams(
        request,
        [dayjs(startDate).format("YYYY-MM-DD")],
        "startDate"
      );
      request = generateParams(
        request,
        [
          dayjs(endDate)
            .hour(23)
            .minute(59)
            .second(59)
            .format("YYYY-MM-DD HH:mm:ss"),
        ],
        "endDate"
      );
      await axios
        .get(url, {
          params: request,
        })
        .then((res) => {
          let data = res.data.map((event: EventRequestDto) => {
            return {
              ...event,
              startDate: new Date(
                timezoneSchedule.removeLocalOffset(new Date(event.startDate))
              ),
              endDate: new Date(
                timezoneSchedule.removeLocalOffset(new Date(event.endDate))
              ),
            };
          });
          if (updateState) {
            commit(
              EventMutations.SET_IS_LOADING_EVENT_LIST_BY_COLLABORATOR,
              false
            );
            commit(EventMutations.SET_EVENTS_LIST_BY_COLLABORATOR, data);
          }
          commit(EventMutations.SET_REQUEST_STATE_EVENT, res);
          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            EventMutations.SET_IS_LOADING_EVENT_LIST_BY_COLLABORATOR,
            false
          );
          commit(EventMutations.SET_REQUEST_STATE_EVENT, err);
          reject(err);
        });
    });
  },

  async getEventById(
    { commit },
    {
      eventId,
      updateState = true,
    }: {
      eventId: number;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(EventMutations.SET_IS_LOADING_EVENT, true);
      }
      await axios
        .get(`${process.env.VUE_APP_API_ORISIS}Plannings/Event/GetEventById`, {
          params: {
            eventId: eventId,
          },
        })
        .then((res) => {
          let data = res.data.map((event: EventRequestDto) => {
            return {
              ...event,
              startDate: new Date(
                new Date(
                  timezoneSchedule.removeLocalOffset(new Date(event.startDate))
                ).setHours(12, 0, 0, 0)
              ),
              endDate: new Date(
                new Date(
                  timezoneSchedule.removeLocalOffset(new Date(event.endDate))
                ).setHours(12, 0, 0, 0)
              ),
            };
          });
          if (updateState) {
            commit(EventMutations.SET_EVENT, data);
            commit(EventMutations.SET_IS_LOADING_EVENT, false);
          }
          commit(EventMutations.SET_REQUEST_STATE_EVENT, res);
          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          commit(EventMutations.SET_REQUEST_STATE_EVENT, err);
          commit(EventMutations.SET_IS_LOADING_EVENT, false);
          reject(err);
        });
    });
  },

  async getEventsArchived(
    { commit },
    {
      startDate = null,
      endDate = null,
      companyId = null,
      collaboratorIds = null,
      updateState = true,
    }: {
      startDate: Date;
      endDate: Date;
      companyId: number;
      collaboratorIds: number[];
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(EventMutations.SET_IS_LOADING_EVENT_LIST_ARCHIVED, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Plannings/Event/GetEventsArchived`;
      await axios
        .get(url, {
          params: {
            startDate: startDate,
            endDate: endDate,
            companyId: companyId,
            collaboratorIds: collaboratorIds,
          },
        })
        .then((res) => {
          if (updateState) {
            commit(EventMutations.SET_IS_LOADING_EVENT_LIST_ARCHIVED, false);
            commit(EventMutations.SET_EVENTS_LIST_ARCHIVED, res.data);
          }
          commit(EventMutations.SET_REQUEST_STATE_EVENT, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(EventMutations.SET_IS_LOADING_EVENT_LIST_ARCHIVED, false);
          commit(EventMutations.SET_REQUEST_STATE_EVENT, err);
          reject(err);
        });
    });
  },

  async getEventsArchivedByCompanyId(
    { commit },
    {
      startDate = null,
      endDate = null,
      companyId = null,
      collaboratorIds = null,
      updateState = true,
    }: {
      startDate: Date;
      endDate: Date;
      companyId: number;
      collaboratorIds: number[];
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          EventMutations.SET_IS_LOADING_EVENT_LIST_ARCHIVED_BY_COMPANY,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Plannings/Event/GetEventsArchived`;
      await axios
        .get(url, {
          params: {
            startDate: startDate ? dayjs(startDate).format("YYYY-MM-DD") : null,
            endDate: endDate ? dayjs(endDate).format("YYYY-MM-DD") : null,
            companyId: companyId,
            collaboratorIds: collaboratorIds,
          },
        })
        .then((res) => {
          if (updateState) {
            commit(
              EventMutations.SET_IS_LOADING_EVENT_LIST_ARCHIVED_BY_COMPANY,
              false
            );
            commit(
              EventMutations.SET_EVENTS_LIST_ARCHIVED_BY_COMPANY,
              res.data
            );
          }
          commit(EventMutations.SET_REQUEST_STATE_EVENT, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            EventMutations.SET_IS_LOADING_EVENT_LIST_ARCHIVED_BY_COMPANY,
            false
          );
          commit(EventMutations.SET_REQUEST_STATE_EVENT, err);
          reject(err);
        });
    });
  },

  async getEventsArchivedByCollaboratorId(
    { commit },
    {
      startDate = null,
      endDate = null,
      companyId = null,
      collaboratorIds = null,
      updateState = true,
    }: {
      startDate: Date;
      endDate: Date;
      companyId: number;
      collaboratorIds: number[];
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          EventMutations.SET_IS_LOADING_EVENT_LIST_ARCHIVED_BY_COLLABORATOR,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Plannings/Event/GetEventsArchived`;
      await axios
        .get(url, {
          params: {
            startDate: startDate,
            endDate: endDate,
            companyId: companyId,
            collaboratorIds: collaboratorIds,
          },
        })
        .then((res) => {
          if (updateState) {
            commit(
              EventMutations.SET_IS_LOADING_EVENT_LIST_ARCHIVED_BY_COLLABORATOR,
              false
            );
            commit(
              EventMutations.SET_EVENTS_LIST_ARCHIVED_BY_COLLABORATOR,
              res.data
            );
          }
          commit(EventMutations.SET_REQUEST_STATE_EVENT, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            EventMutations.SET_IS_LOADING_EVENT_LIST_ARCHIVED_BY_COLLABORATOR,
            false
          );
          commit(EventMutations.SET_REQUEST_STATE_EVENT, err);
          reject(err);
        });
    });
  },

  async createEvent(
    { commit, rootGetters },
    { event, route }: { event: EventRequestDto; route: string }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(EventMutations.SET_IS_CREATING_EVENT, true);
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Plannings/Event/CreateEvent`,
          event
        )
        .then((res) => {
          if (route == "event") {
            rootGetters.eventsList.push(res.data.data);
            commit(EventMutations.SET_EVENTS_LIST, rootGetters.eventsList);
          } else if (route == "company") {
            rootGetters.eventsListByCompany.push(res.data.data);
            commit(
              EventMutations.SET_EVENTS_LIST_BY_COMPANY,
              rootGetters.eventsListByCompany
            );
          } else if (route == "collaborator") {
            rootGetters.eventsListByCollaborator.push(res.data.data);
            commit(
              EventMutations.SET_EVENTS_LIST_BY_COLLABORATOR,
              rootGetters.eventsListByCollaborator
            );
          }
          commit(EventMutations.SET_REQUEST_STATE_EVENT, res);
          commit(EventMutations.SET_IS_CREATING_EVENT, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(EventMutations.SET_REQUEST_STATE_EVENT, err);
          commit(EventMutations.SET_IS_CREATING_EVENT, false);
          reject(err);
        });
    });
  },

  async updateEvent(
    { commit, rootGetters },
    { event, route }: { event: EventRequestDto; route: string }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(EventMutations.SET_IS_UPDATING_EVENT, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Plannings/Event/UpdateEvent`,
          event
        )
        .then((res) => {
          if (route == "event") {
            rootGetters.eventsList.splice(
              rootGetters.eventsList.findIndex((elem) => elem.id == event.id),
              1,
              res.data.data
            );
            commit(EventMutations.SET_EVENTS_LIST, rootGetters.eventsList);
          } else if (route == "company") {
            rootGetters.eventsListByCompany.splice(
              rootGetters.eventsListByCompany.findIndex(
                (elem) => elem.id == event.id
              ),
              1,
              res.data.data
            );
            commit(
              EventMutations.SET_EVENTS_LIST_BY_COMPANY,
              rootGetters.eventsListByCompany
            );
          } else if (route == "collaborator") {
            rootGetters.eventsListByCollaborator.splice(
              rootGetters.eventsListByCollaborator.findIndex(
                (elem) => elem.id == event.id
              ),
              1,
              res.data.data
            );
            commit(
              EventMutations.SET_EVENTS_LIST_BY_COLLABORATOR,
              rootGetters.eventsListByCollaborator
            );
          }
          commit(EventMutations.SET_REQUEST_STATE_EVENT, res);
          commit(EventMutations.SET_IS_UPDATING_EVENT, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(EventMutations.SET_REQUEST_STATE_EVENT, err);
          commit(EventMutations.SET_IS_UPDATING_EVENT, false);
          reject(err);
        });
    });
  },

  async deleteEvents(
    { commit, rootGetters },
    { eventIds, route }: { eventIds: number[]; route: string }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(EventMutations.SET_IS_DELETING_EVENT, true);
      await axios
        .delete(process.env.VUE_APP_API_ORISIS + "Plannings/Event/Delete", {
          data: eventIds,
        })
        .then((res) => {
          eventIds.forEach((eventId: number) => {
            if (route == "event") {
              eventIds.forEach((eventId: number) => {
                rootGetters.eventsList.splice(
                  rootGetters.eventsList.findIndex((elem) => elem.id == eventId),
                  1
                );
              });
              commit(
                EventMutations.SET_EVENTS_LIST_ARCHIVED,
                rootGetters.eventsListArchived
              );
            } else if (route == "company") {
              rootGetters.eventsListByCompany.splice(
                rootGetters.eventsListByCompany.findIndex(
                  (elem) => elem.id == eventId
                ),
                1
              );
              commit(
                EventMutations.SET_EVENTS_LIST_ARCHIVED_BY_COMPANY,
                rootGetters.eventsListArchivedByCompany
              );
            } else if (route == "collaborator") {
              rootGetters.eventsListByCollaborator.splice(
                rootGetters.eventsListByCollaborator.findIndex(
                  (elem) => elem.id == eventId
                ),
                1
              );
              commit(
                EventMutations.SET_EVENTS_LIST_ARCHIVED_BY_COLLABORATOR,
                rootGetters.eventsListArchivedByCollaborator
              );
            }
          });
          commit(EventMutations.SET_REQUEST_STATE_EVENT, res);
          commit(EventMutations.SET_IS_DELETING_EVENT, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(EventMutations.SET_REQUEST_STATE_EVENT, err);
          commit(EventMutations.SET_IS_DELETING_EVENT, false);
          reject(err);
        });
    });
  },
};
