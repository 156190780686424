import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { SmtpSettingRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { ISmtpSettingState } from "./states";

export enum SmtpSettingMutations {
    SET_SMTP_SETTING_LIST = "SET_SMTP_SETTING_LIST",
    SET_SMTP_SETTING_LIST_ARCHIVED = "SET_SMTP_SETTING_LIST_ARCHIVED",
    SET_SMTP_SETTING = "SET_SMTP_SETTING",

    // COMMON
    SET_IS_LOADING_SMTP_SETTING_LIST = "SET_IS_LOADING_SMTP_SETTING_LIST",
    SET_IS_LOADING_SMTP_SETTING_LIST_ARCHIVED = "SET_IS_LOADING_SMTP_SETTING_LIST_ARCHIVED",
    SET_IS_LOADING_SMTP_SETTING = "SET_IS_LOADING_SMTP_SETTING",
    SET_IS_CREATING_SMTP_SETTING = "SET_IS_CREATING_SMTP_SETTING",
    SET_IS_UPDATING_SMTP_SETTING = "SET_IS_UPDATING_SMTP_SETTING",
    SET_IS_DELETING_SMTP_SETTING = "SET_IS_DELETING_SMTP_SETTING",
    SET_IS_ARCHIVING_SMTP_SETTING = "SET_IS_ARCHIVING_SMTP_SETTING",
    SET_REQUEST_STATE_SMTP_SETTING = "SET_REQUEST_STATE_SMTP_SETTING",
}

export const mutations: MutationTree<ISmtpSettingState> = {
    [SmtpSettingMutations.SET_SMTP_SETTING_LIST_ARCHIVED]: (state, payload: SmtpSettingRequestDto[]) => {
        state.smtpSettingListArchived = payload;
    }, 
    [SmtpSettingMutations.SET_SMTP_SETTING_LIST]: (state, payload: SmtpSettingRequestDto[]) => {
        state.smtpSettingList = payload;
    },
    [SmtpSettingMutations.SET_SMTP_SETTING]: (state, payload: SmtpSettingRequestDto) => {
        state.smtpSetting = payload;
    },


    //COMMON
    [SmtpSettingMutations.SET_IS_LOADING_SMTP_SETTING_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingSmtpSettingListArchived = payload;
    },
    [SmtpSettingMutations.SET_IS_LOADING_SMTP_SETTING_LIST]: (state, payload: boolean) => {
        state.isLoadingSmtpSettingList = payload;
    },
    [SmtpSettingMutations.SET_IS_LOADING_SMTP_SETTING]: (state, payload: boolean) => {
        state.isLoadingSmtpSetting = payload;
    },
    [SmtpSettingMutations.SET_IS_CREATING_SMTP_SETTING]: (state, payload: boolean) => {
        state.isCreatingSmtpSetting = payload;
    },
    [SmtpSettingMutations.SET_IS_UPDATING_SMTP_SETTING]: (state, payload: boolean) => {
        state.isUpdatingSmtpSetting = payload;
    },
    [SmtpSettingMutations.SET_IS_DELETING_SMTP_SETTING]: (state, payload: boolean) => {
        state.isDeletingSmtpSetting = payload;
    },
    [SmtpSettingMutations.SET_IS_ARCHIVING_SMTP_SETTING]: (state, payload: boolean) => {
        state.isArchivingSmtpSetting = payload;
    },
    [SmtpSettingMutations.SET_REQUEST_STATE_SMTP_SETTING]: (state, payload: IRequestState) => {
        state.requestStateSmtpSetting = payload;
    },
}