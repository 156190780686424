import { IPaymentConditionState } from "./states";
import { MutationTree } from "vuex";
import { IPaymentCondition } from "@/types/api-orisis/interfaces/Invoices/paymentCondition/IPaymentCondition";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";


export enum PaymentConditionMutations{
    SET_PAYMENT_CONDITIONS_LIST = "SET_PAYMENT_CONDITIONS_LIST",
    SET_PAYMENT_CONDITIONS_LIST_ARCHIVED = "SET_PAYMENT_CONDITIONS_LIST_ARCHIVED",
    SET_PAYMENT_CONDITION = "SET_PAYMENT_CONDITION",
    
    // COMON 
    SET_IS_LOADING_PAYMENT_CONDITIONS_LIST = "SET_IS_LOADING_PAYMENT_CONDITIONS_LIST",
    SET_IS_LOADING_PAYMENT_CONDITIONS_LIST_ARCHIVED = "SET_IS_LOADING_PAYMENT_CONDITIONS_LIST_ARCHIVED",
    SET_IS_LOADING_PAYMENT_CONDITION = "SET_IS_LOADING_PAYMENT_CONDITION",
    SET_IS_CREATING_PAYMENT_CONDITION = "SET_IS_CREATING_PAYMENT_CONDITION",
    SET_IS_UPDATING_PAYMENT_CONDITION = "SET_IS_UPDATING_PAYMENT_CONDITION",
    SET_IS_DELETING_PAYMENT_CONDITION = "SET_IS_DELETING_PAYMENT_CONDITION",
    SET_IS_ARCHIVING_PAYMENT_CONDITION = "SET_IS_ARCHIVING_PAYMENT_CONDITION",
    SET_REQUEST_STATE_PAYMENT_CONDITION = "SET_REQUEST_STATE_PAYMENT_CONDITION",
  }

  export const mutations: MutationTree<IPaymentConditionState> = {
    [PaymentConditionMutations.SET_PAYMENT_CONDITIONS_LIST_ARCHIVED]: (state, payload: IPaymentCondition[]) => {
        state.paymentConditionsListArchived = payload;
    },
    [PaymentConditionMutations.SET_PAYMENT_CONDITIONS_LIST]: (state, payload: IPaymentCondition[]) => {
        state.paymentConditionsList = payload;
    },
    [PaymentConditionMutations.SET_PAYMENT_CONDITION]: (state, payload: IPaymentCondition) => {
        state.paymentCondition = payload;
    },

    //COMMON
    [PaymentConditionMutations.SET_IS_LOADING_PAYMENT_CONDITIONS_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingPaymentConditionsListArchived = payload;
    },
    [PaymentConditionMutations.SET_IS_LOADING_PAYMENT_CONDITIONS_LIST]: (state, payload: boolean) => {
        state.isLoadingPaymentConditionsList = payload;
    },
    [PaymentConditionMutations.SET_IS_LOADING_PAYMENT_CONDITION]: (state, payload: boolean) => {
        state.isLoadingPaymentCondition = payload;
    },
    [PaymentConditionMutations.SET_IS_CREATING_PAYMENT_CONDITION]: (state, payload: boolean) => {
        state.isCreatingPaymentCondition = payload;
    },
    [PaymentConditionMutations.SET_IS_UPDATING_PAYMENT_CONDITION]: (state, payload: boolean) => {
        state.isUpdatingPaymentCondition = payload;
    },
    [PaymentConditionMutations.SET_IS_DELETING_PAYMENT_CONDITION]: (state, payload: boolean) => {
        state.isDeletingPaymentCondition = payload;
    },
    [PaymentConditionMutations.SET_IS_ARCHIVING_PAYMENT_CONDITION]: (state, payload: boolean) => {
        state.isArchivingPaymentCondition = payload;
    },

    
    [PaymentConditionMutations.SET_REQUEST_STATE_PAYMENT_CONDITION]: (state, payload: IRequestState) => {
        state.requestStatePaymentCondition = payload;
    },
}