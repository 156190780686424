import { IPlanningStatisticsState } from "./states";
import { MutationTree } from "vuex";


export enum PlanningStatisticsMutations{
    SET_STATS_EVENT_TYPES_GLOBAL = "SET_STATS_EVENT_TYPES_GLOBAL",
    SET_STATS_EVENT_TYPES_BY_DAY = "SET_STATS_EVENT_TYPES_BY_DAY",
    SET_DATE_PERIODE = "SET_DATE_PERIODE",
    
    // COMON
    SET_IS_LOADING_STATS_EVENT_TYPES_GLOBAL = "SET_IS_LOADING_STATS_EVENT_TYPES_GLOBAL",
    SET_IS_LOADING_STATS_EVENT_TYPES_BY_DAY = "SET_IS_LOADING_STATS_EVENT_TYPES_BY_DAY",
    SET_IS_LOADING_DATE_PERIODE = "SET_IS_LOADING_DATE_PERIODE",
  }

  export const mutations: MutationTree<IPlanningStatisticsState> = {
    [PlanningStatisticsMutations.SET_STATS_EVENT_TYPES_GLOBAL]: (state, payload: []) => {
        state.statsEventTypesGlobal = payload;
    },
    [PlanningStatisticsMutations.SET_STATS_EVENT_TYPES_BY_DAY]: (state, payload: []) => {
        state.statsEventTypesByDay = payload;
    },
    [PlanningStatisticsMutations.SET_DATE_PERIODE]: (state, payload: []) => {
        state.datePeriode = payload;
    },

    //COMMON
    [PlanningStatisticsMutations.SET_IS_LOADING_STATS_EVENT_TYPES_GLOBAL]: (state, payload: boolean) => {
        state.isLoadingStatsEventTypesGlobal = payload;
    },
    [PlanningStatisticsMutations.SET_IS_LOADING_STATS_EVENT_TYPES_BY_DAY]: (state, payload: boolean) => {
        state.isLoadingStatsEventTypesByDay = payload;
    },
    [PlanningStatisticsMutations.SET_IS_LOADING_DATE_PERIODE]: (state, payload: boolean) => {
        state.isLoadingDatePeriode = payload;
    },
    
} 