import { IFiscalYearState } from "./states";
import { MutationTree } from "vuex";
import { IFiscalYear } from "@/types/api-orisis/interfaces/invoices/fiscalYear/IFiscalYear";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";


export enum FiscalYearMutations {
    SET_FISCAL_YEAR_LIST = "SET_FISCAL_YEAR_LIST",
    SET_FISCAL_YEAR = "SET_FISCAL_YEAR",
    
    // COMON 
    SET_IS_LOADING_FISCAL_YEAR_LIST = "SET_IS_LOADING_FISCAL_YEAR_LIST",
    SET_IS_LOADING_FISCAL_YEAR = "SET_IS_LOADING_FISCAL_YEAR",
    SET_IS_CREATING_FISCAL_YEAR = "SET_IS_CREATING_FISCAL_YEAR",
    SET_IS_UPDATING_FISCAL_YEAR = "SET_IS_UPDATING_FISCAL_YEAR",
    SET_IS_DELETING_FISCAL_YEAR = "SET_IS_DELETING_FISCAL_YEAR",
    SET_IS_ARCHIVING_FISCAL_YEAR = "SET_IS_ARCHIVING_FISCAL_YEAR",
    SET_REQUEST_STATE_FISCAL_YEAR = "SET_REQUEST_STATE_FISCAL_YEAR",
  }

  export const mutations: MutationTree<IFiscalYearState> = {
    [FiscalYearMutations.SET_FISCAL_YEAR_LIST]: (state, payload: IFiscalYear[]) => {
        state.fiscalYearList = payload;
    },
    [FiscalYearMutations.SET_FISCAL_YEAR]: (state, payload: IFiscalYear) => {
        state.fiscalYear = payload;
    },

    //COMMON
    [FiscalYearMutations.SET_IS_LOADING_FISCAL_YEAR_LIST]: (state, payload: boolean) => {
        state.isLoadingFiscalYearList = payload;
    },
    [FiscalYearMutations.SET_IS_LOADING_FISCAL_YEAR]: (state, payload: boolean) => {
        state.isLoadingFiscalYear = payload;
    },
    [FiscalYearMutations.SET_IS_CREATING_FISCAL_YEAR]: (state, payload: boolean) => {
        state.isCreatingFiscalYear = payload;
    },
    [FiscalYearMutations.SET_IS_UPDATING_FISCAL_YEAR]: (state, payload: boolean) => {
        state.isUpdatingFiscalYear = payload;
    },
    [FiscalYearMutations.SET_IS_DELETING_FISCAL_YEAR]: (state, payload: boolean) => {
        state.isDeletingFiscalYear = payload;
    },
    [FiscalYearMutations.SET_IS_ARCHIVING_FISCAL_YEAR]: (state, payload: boolean) => {
        state.isArchivingFiscalYear = payload;
    },

    [FiscalYearMutations.SET_REQUEST_STATE_FISCAL_YEAR]: (state, payload: IRequestState) => {
        state.requestStateFiscalYear = payload;
    },
}