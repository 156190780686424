import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { InterventionRequestDto } from "@/../Api";
import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IInterventionState } from "./states";

export const getters: GetterTree<IInterventionState, RootState> = {
  interventionsList: (state): InterventionRequestDto[] =>
    state.interventionsList,
  interventionsListByCollaboratorId: (state): InterventionRequestDto[] =>
    state.interventionsListByCollaboratorId,
  intervention: (state): InterventionRequestDto => state.intervention,

  //FILTERS
  interventionFilters: (state): any => state.interventionFilters,
  interventionFiltersByCollaborator: (state): any =>
    state.interventionFiltersByCollaborator,

  // COMMON
  isLoadingInterventionsList: (state): boolean =>
    state.isLoadingInterventionsList,
  isLoadingInterventionsByCollaboratorList: (state): boolean =>
    state.isLoadingInterventionsListByCollaboratorId,
  isLoadingIntervention: (state): boolean => state.isLoadingIntervention,
  isCreatingIntervention: (state): boolean => state.isCreatingIntervention,
  isUpdatingIntervention: (state): boolean => state.isUpdatingIntervention,
  isDeletingIntervention: (state): boolean => state.isDeletingIntervention,
  requestStateIntervention: (state): IRequestState =>
    state.requestStateIntervention,
};
