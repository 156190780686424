import RootState from "@/store/modules/rootState"
import { InitialDocumentState } from "./states";
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"

export const state: InitialDocumentState = {
    initialDocument                  : null,
    isLoadingInitialDocument         : false,
}

export const InitialDocumentModule: Module<InitialDocumentState, RootState> = {
  state, 
  getters,
  mutations,
}