import { IOrderFormProviderHistoryState } from "./states";
import { MutationTree } from "vuex";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export enum OrderFormProviderHistoryMutations {
  SET_ORDER_FORM_HISTORIES_LIST = "SET_ORDER_FORM_HISTORIES_LIST",
  SET_ORDER_FORM_HISTORY = "SET_ORDER_FORM_HISTORY",

  // COMON
  SET_IS_LOADING_ORDER_FORM_HISTORIES_LIST = "SET_IS_LOADING_ORDER_FORM_HISTORIES_LIST",
  SET_IS_LOADING_ORDER_FORM_HISTORY = "SET_IS_LOADING_ORDER_FORM_HISTORY",
  SET_REQUEST_STATE_ORDER_FORM_HISTORY = "SET_REQUEST_STATE_ORDER_FORM_HISTORY",
}

  export const mutations: MutationTree<IOrderFormProviderHistoryState> = {
    [OrderFormProviderHistoryMutations.SET_ORDER_FORM_HISTORIES_LIST]: (state, payload: []) => {
        state.orderFormProviderHistoriesList = payload;
    },
    [OrderFormProviderHistoryMutations.SET_ORDER_FORM_HISTORY]: (state, payload: {}) => {
        state.orderFormProviderHistory = payload;
    },


    //COMMON
    [OrderFormProviderHistoryMutations.SET_IS_LOADING_ORDER_FORM_HISTORIES_LIST]: (state, payload: boolean) => {
        state.isLoadingOrderFormProviderHistoriesList = payload;
    },
    [OrderFormProviderHistoryMutations.SET_IS_LOADING_ORDER_FORM_HISTORY]: (state, payload: boolean) => {
        state.isLoadingOrderFormProviderHistory = payload;
    },
    [OrderFormProviderHistoryMutations.SET_REQUEST_STATE_ORDER_FORM_HISTORY]: (state, payload: IRequestState) => {
        state.requestStateOrderFormProviderHistory = payload;
    },
}