import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { GetterTree } from "vuex";
import RootState from "../../rootState";
import { IExportState } from "./states";

export const getters: GetterTree<IExportState, RootState> = {
    exportAccounting                         : (state) => state.exportAccounting,
    exportUrl                                : (state) => state.exportUrl,
    exportSalesJournalCsv                    : (state) => state.exportSalesJournalCsv,
    
    // COMMON
    isLoadingExport                          : (state): boolean => state.isLoadingExport,
    isLoadingExportUrl                       : (state): boolean => state.isLoadingExport,
    isLoadingInvoicesCreditsOnTimeSpan       : (state): boolean => state.isLoadingInvoicesCreditsOnTimeSpan,
    isLoadingExportSalesJournalCsv           : (state): boolean => state.isLoadingExportSalesJournalCsv,
    requestStateExport                       : (state): IRequestState => state.requestStateExport,
}