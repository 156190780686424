import { IReportDetailState } from "./states";
import { Module } from "vuex";
import { ReportDetailRequestDto } from "@/../Api";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import RootState from "@/store/modules/rootState";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

const state: IReportDetailState = {
  reportDetailsList: Array<ReportDetailRequestDto>(),
  reportDetailsMinifiedList: Array<ReportDetailRequestDto>(),
  reportDetailsListArchived: Array<ReportDetailRequestDto>(),
  reportDetailsListByCollaborator: Array<ReportDetailRequestDto>(),
  reportDetail: <ReportDetailRequestDto>{},
  reportsDetail: Array<ReportDetailRequestDto>(),
  reportDetailFilters: {},
  reportDetailFiltersByCollaborator: {},
  isLoadingReportDetailsList: false,
  isLoadingReportDetailsMinifiedList: false,
  isLoadingReportDetailsListArchived: false,
  isLoadingReportDetailsListByCollaborator: false,
  isLoadingReportDetail: false,
  isLoadingReportsDetail: false,
  isCreatingReportDetail: false,
  isUpdatingReportDetail: false,
  isDeletingReportDetail: false,
  isArchivingReportDetail: false,
  requestStateReportDetail: <IRequestState>{},
};

export const ReportDetailModule: Module<IReportDetailState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
