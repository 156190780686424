import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import RootState from "@/store/modules/rootState";
import { IReceiptFormProviderHistoryState } from "./states";
import { Module } from "vuex";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

export const state: IReceiptFormProviderHistoryState = {
  receiptFormProviderHistoriesList: [],
  receiptFormProviderHistory: {},
  isLoadingReceiptFormProviderHistoriesList: false,
  isLoadingReceiptFormProviderHistory: false,
  requestStateReceiptFormProviderHistory: <IRequestState>{},
};

export const ReceiptFormProviderHistoryModule: Module<IReceiptFormProviderHistoryState, RootState> =
  {
    state,
    getters,
    mutations,
    actions,
  };
