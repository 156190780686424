import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { ReceiptFormProviderHistoryMutations } from "./mutations";
import { IReceiptFormProviderHistoryState } from "./states";

export const actions: ActionTree<IReceiptFormProviderHistoryState, RootState> =
  {
    getReceiptFormHistoryById(
      { commit, rootGetters },
      {receiptFormProviderHistoryId, updateState = true}: {receiptFormProviderHistoryId: number; updateState?: boolean}
    ): Promise<void> {
      return new Promise<void>((resolve, reject) => {
        if (updateState) {
          commit(
            ReceiptFormProviderHistoryMutations.SET_IS_LOADING_RECEIPT_FORM_HISTORY,
            true
          );
        }
        axios
          .get(
            process.env.VUE_APP_API_ORISIS +
              "purchase/ReceiptFormHistory/GetReceiptFormHistoryById",
            {
              params: {
                receiptFormHistoryId: receiptFormProviderHistoryId,
              },
            }
          )
          .then((res) => {
            if (updateState) {
              commit(
                ReceiptFormProviderHistoryMutations.SET_RECEIPT_FORM_HISTORY,
                res.data
              );
              commit(
                ReceiptFormProviderHistoryMutations.SET_IS_LOADING_RECEIPT_FORM_HISTORY,
                false
              );
            }
            commit(
              ReceiptFormProviderHistoryMutations.SET_REQUEST_STATE_RECEIPT_FORM_HISTORY,
              res
            );
            resolve(res.data);
          })
          .catch((err) => {
            console.error(err);
            commit(
              ReceiptFormProviderHistoryMutations.SET_REQUEST_STATE_RECEIPT_FORM_HISTORY,
              err
            );
            if (updateState) {
              commit(
                ReceiptFormProviderHistoryMutations.SET_IS_LOADING_RECEIPT_FORM_HISTORY,
                false
              );
            }
            reject(err);
          });
        });
    },

    getReceiptFormHistoriesByReceiptFormId(
      { commit, rootGetters },
      {receiptFormProviderId, updateState = true}: {receiptFormProviderId: number; updateState?: boolean}
    ): Promise<void> {
      return new Promise<void>((resolve, reject) => {
        if (updateState) {
          commit(
            ReceiptFormProviderHistoryMutations.SET_IS_LOADING_RECEIPT_FORM_HISTORIES_LIST,
            true
          );
          commit(
            ReceiptFormProviderHistoryMutations.SET_RECEIPT_FORM_HISTORIES_LIST,
            []
          );
        }
        axios
          .get(
            process.env.VUE_APP_API_ORISIS +
              "purchase/ReceiptFormHistory/GetReceiptFormHistoriesByReceiptFormId",
            {
              params: {
                receiptFormId: receiptFormProviderId,
              },
            }
          )
          .then((res) => {
            if (updateState) {
              commit(
                ReceiptFormProviderHistoryMutations.SET_RECEIPT_FORM_HISTORIES_LIST,
                res.data
              );
              commit(
                ReceiptFormProviderHistoryMutations.SET_IS_LOADING_RECEIPT_FORM_HISTORIES_LIST,
                false
              );
            }
            commit(
              ReceiptFormProviderHistoryMutations.SET_REQUEST_STATE_RECEIPT_FORM_HISTORY,
              res
            );
            resolve(res.data)
          })
          .catch((err) => {
            console.error(err);
            commit(
              ReceiptFormProviderHistoryMutations.SET_REQUEST_STATE_RECEIPT_FORM_HISTORY,
              err
            );
            if (updateState) {
              commit(
                ReceiptFormProviderHistoryMutations.SET_IS_LOADING_RECEIPT_FORM_HISTORIES_LIST,
                false
              );
            }
            reject(err);
          });
        });
    },
    
  };
