import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import RootState from "@/store/modules/rootState"
import { IOrderFormProviderHistoryState } from "./states";
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

export const state: IOrderFormProviderHistoryState = {
    orderFormProviderHistoriesList                  : [],
    orderFormProviderHistory                        : {},
    isLoadingOrderFormProviderHistoriesList         : false,
    isLoadingOrderFormProviderHistory               : false,
    requestStateOrderFormProviderHistory            : <IRequestState>{},
}

export const OrderFormProviderHistoryModule: Module<IOrderFormProviderHistoryState, RootState> = {
  state, 
  getters,
  mutations,
  actions
}