import { ICreditProviderState } from "./states"
import { Module } from "vuex"
import { CreditProviderRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"
import { IFilters } from "@/types/api-orisis/interfaces/invoices/filters/IFilters"

const state:ICreditProviderState = {
    creditProvidersList                    : Array<CreditProviderRequestDto>(),
    creditProvider                         : <CreditProviderRequestDto>{},
    isLoadingCreditProvidersList           : false,
    isLoadingCreditProvider                : false,
    isCreatingCreditProvider               : false,
    isUpdatingCreditProvider               : false,
    isDeletingCreditProvider               : false,
    requestStateCreditProvider             : <IRequestState>{},
    creditProviderFilters                  : <IFilters>{},
    isLoadingCreditProvidersListByAffair   : false,
    creditProvidersListByAffair            : Array<CreditProviderRequestDto>(),
    isLoadingCreditProvidersListByProvider : false,
    creditProviderListByProvider           : Array<CreditProviderRequestDto>(),
}

export const CreditProviderModule: Module<ICreditProviderState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}