import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { StorageLocationRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IStorageLocationState } from "./states";

export enum StorageLocationMutations {
    SET_STORAGE_LOCATIONS_LIST = "SET_STORAGE_LOCATIONS_LIST",
    SET_STORAGE_LOCATIONS_LIST_ARCHIVED = "SET_STORAGE_LOCATIONS_LIST_ARCHIVED",
    SET_STORAGE_LOCATION = "SET_STORAGE_LOCATION",
    SET_STORAGE_LOCATION_ARCHIVED = "SET_STORAGE_LOCATION_ARCHIVED",

    // COMMON
    SET_IS_LOADING_STORAGE_LOCATION_LIST = "SET_IS_LOADING_STORAGE_LOCATION_LIST",
    SET_IS_LOADING_STORAGE_LOCATION_LIST_ARCHIVED = "SET_IS_LOADING_STORAGE_LOCATION_LIST_ARCHIVED",
    SET_IS_LOADING_STORAGE_LOCATION = "SET_IS_LOADING_STORAGE_LOCATION",
    SET_IS_LOADING_STORAGE_LOCATION_ARCHIVED = "SET_IS_LOADING_STORAGE_LOCATION_ARCHIVED",
    SET_IS_CREATING_STORAGE_LOCATION = "SET_IS_CREATING_STORAGE_LOCATION",
    SET_IS_UPDATING_STORAGE_LOCATION = "SET_IS_UPDATING_STORAGE_LOCATION",
    SET_IS_DELETING_STORAGE_LOCATION = "SET_IS_DELETING_STORAGE_LOCATION",
    SET_IS_RESTORING_STORAGE_LOCATION = "SET_IS_RESTORING_STORAGE_LOCATION",
    SET_IS_ARCHIVING_STORAGE_LOCATION = "SET_IS_ARCHIVING_STORAGE_LOCATION",
    SET_REQUEST_STATE_STORAGE_LOCATION = "SET_REQUEST_STATE_STORAGE_LOCATION",
}

export const mutations: MutationTree<IStorageLocationState> = {
    [StorageLocationMutations.SET_STORAGE_LOCATIONS_LIST_ARCHIVED]: (state, payload: StorageLocationRequestDto[]) => {
        state.storageLocationsListArchived = payload;
    }, 
    [StorageLocationMutations.SET_STORAGE_LOCATIONS_LIST]: (state, payload: StorageLocationRequestDto[]) => {
        state.storageLocationsList = payload;
    },
    [StorageLocationMutations.SET_STORAGE_LOCATION]: (state, payload: StorageLocationRequestDto) => {
        state.storageLocation = payload;
    },
    [StorageLocationMutations.SET_STORAGE_LOCATION_ARCHIVED]: (state, payload: StorageLocationRequestDto) => {
        state.storageLocationArchived = payload;
    },

    //COMMON
    [StorageLocationMutations.SET_IS_LOADING_STORAGE_LOCATION_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingStorageLocationsListArchived = payload;
    },
    [StorageLocationMutations.SET_IS_LOADING_STORAGE_LOCATION_LIST]: (state, payload: boolean) => {
        state.isLoadingStorageLocationsList = payload;
    },
    [StorageLocationMutations.SET_IS_LOADING_STORAGE_LOCATION]: (state, payload: boolean) => {
        state.isLoadingStorageLocation = payload;
    },
    [StorageLocationMutations.SET_IS_LOADING_STORAGE_LOCATION_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingStorageLocationArchived = payload;
    },
    [StorageLocationMutations.SET_IS_CREATING_STORAGE_LOCATION]: (state, payload: boolean) => {
        state.isCreatingStorageLocation = payload;
    },
    [StorageLocationMutations.SET_IS_UPDATING_STORAGE_LOCATION]: (state, payload: boolean) => {
        state.isUpdatingStorageLocation = payload;
    },
    [StorageLocationMutations.SET_IS_ARCHIVING_STORAGE_LOCATION]: (state, payload: boolean) => {
        state.isArchivingStorageLocation = payload;
    },
    [StorageLocationMutations.SET_IS_RESTORING_STORAGE_LOCATION]: (state, payload: boolean) => {
        state.isRestoringStorageLocation = payload;
    },
    [StorageLocationMutations.SET_IS_DELETING_STORAGE_LOCATION]: (state, payload: boolean) => {
        state.isDeletingStorageLocation = payload;
    },
    [StorageLocationMutations.SET_REQUEST_STATE_STORAGE_LOCATION]: (state, payload: IRequestState) => {
        state.requestStateStorageLocation = payload;
    },
}