import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { OrderFormCustomerOptionMutations } from "./mutations";
import { IOrderFormCustomerOptionState } from "./states";
import { OrderFormCustomerOptionRequestDto } from "Api";

export const actions: ActionTree<IOrderFormCustomerOptionState, RootState> = {
  getOrderFormCustomerOptionByOrderFormCustomerId(
    { commit },
    {
      orderFormCustomerId,
      updateState = true,
    }: { orderFormCustomerId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          OrderFormCustomerOptionMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_OPTION,
          true
        );
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/OrderFormCustomerOption/GetOrderFormCustomerOptionByOrderFormCustomerId`;

      axios
        .get(url, {
          params: {
            orderFormCustomerId: orderFormCustomerId,
          },
        })
        .then((res) => {
          if (updateState) {
            commit(
              OrderFormCustomerOptionMutations.SET_ORDER_FORM_CUSTOMER_OPTION,
              res.data
            );
            commit(
              OrderFormCustomerOptionMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_OPTION,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              OrderFormCustomerOptionMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_OPTION,
              false
            );
          }
          reject(err);
        });
    });
  },

  createOrderFormCustomerOption(
    { commit },
    {
      orderFormCustomerOption,
      updateState = true,
    }: {
      orderFormCustomerOption: OrderFormCustomerOptionRequestDto;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          OrderFormCustomerOptionMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_OPTION,
          true
        );
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/OrderFormCustomerOption/CreateOrderFormCustomerOption`;

      axios
        .post(url, orderFormCustomerOption)
        .then((res) => {
          if (updateState) {
            commit(
              OrderFormCustomerOptionMutations.SET_ORDER_FORM_CUSTOMER_OPTION,
              res.data.data
            );
            commit(
              OrderFormCustomerOptionMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_OPTION,
              false
            );
          }
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              OrderFormCustomerOptionMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_OPTION,
              false
            );
          }
          reject(err);
        });
    });
  },

  updateOrderFormCustomerOption(
    { commit, rootGetters },
    {
      orderFormCustomerOption,
      updateState = true,
    }: {
      orderFormCustomerOption: OrderFormCustomerOptionRequestDto;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          OrderFormCustomerOptionMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_OPTION,
          true
        );
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/OrderFormCustomerOption/UpdateOrderFormCustomerOption`;

      axios
        .put(url, {
          ...orderFormCustomerOption,
          orderFormCustomerId: rootGetters.orderFormCustomer.id,
        })
        .then((res) => {
          if (updateState) {
            commit(
              OrderFormCustomerOptionMutations.SET_ORDER_FORM_CUSTOMER_OPTION,
              res.data.data
            );
            commit(
              OrderFormCustomerOptionMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_OPTION,
              false
            );
          }
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              OrderFormCustomerOptionMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_OPTION,
              false
            );
          }
          reject(err);
        });
    });
  },
};
