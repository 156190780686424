import { DeliveryFormDetailRequestDto } from "Api";
import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IDeliveryFormDetailState } from "./states";

export const getters: GetterTree<IDeliveryFormDetailState, RootState> = {
  getDeliveryFormHeader: (state): any => state.deliveryFormHeader,
  getDeliveryFormDetails: (state): DeliveryFormDetailRequestDto[] =>
    state.deliveryFormDetails,
  getDeliveryFormDetail: (state): DeliveryFormDetailRequestDto =>
    state.deliveryFormDetail,
  isLoadingDeliveryFormDetails: (state): boolean =>
    state.isLoadingDeliveryFormDetails,
  isLoadingDeliveryFormDetailDuplication: (state): boolean =>
    state.isLoadingDeliveryFormDetailDuplication,
  isCreatingDeliveryFormDetail: (state): boolean =>
    state.isCreatingDeliveryFormDetail,
  isUpdatingDeliveryFormDetail: (state): boolean =>
    state.isUpdatingDeliveryFormDetail,
  isDeletingDeliveryFormDetail: (state): boolean =>
    state.isDeletingDeliveryFormDetail,
};
