import { ICustomFieldTemplateState } from "./states"
import { Module } from "vuex"
import { CustomFieldTemplateRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:ICustomFieldTemplateState = {
    customFieldTemplatesList         : Array<CustomFieldTemplateRequestDto>(),
    customFieldTemplatesListArchived : Array<CustomFieldTemplateRequestDto>(),
    customFieldTemplate              : <CustomFieldTemplateRequestDto>{},
    isLoadingCustomFieldTemplatesList     : false,
    isLoadingCustomFieldTemplatesListArchived     : false,
    isLoadingCustomFieldTemplate     : false,
    isCreatingCustomFieldTemplate    : false,
    isUpdatingCustomFieldTemplate    : false,
    isDeletingCustomFieldTemplate    : false,
    isArchivingCustomFieldTemplate    : false,
    requestStateCustomFieldTemplate: <IRequestState>{},
    customFieldTemplateFilters                : {}
}

export const CustomFieldTemplateModule: Module<ICustomFieldTemplateState, RootState> = {
    state, 
    getters,
    mutations,
    actions
} 