import { IQuoteDownPaymentsRequestState } from "./states";
import { MutationTree } from "vuex";
import { QuoteDownPaymentsRequestDto } from "Api";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export enum QuoteDownPaymentsRequestMutations {
  SET_QUOTE_DOWN_PAYMENTS_REQUESTS_LIST = "SET_QUOTE_DOWN_PAYMENTS_REQUESTS_LIST",
  SET_QUOTE_DOWN_PAYMENTS_REQUESTS_LIST_ARCHIVED = "SET_QUOTE_DOWN_PAYMENTS_REQUESTS_LIST_ARCHIVED",
  SET_QUOTE_DOWN_PAYMENTS_REQUEST = "SET_QUOTE_DOWN_PAYMENTS_REQUEST",

  // COMON
  SET_IS_LOADING_QUOTE_DOWN_PAYMENTS_REQUESTS_LIST = "SET_IS_LOADING_QUOTE_DOWN_PAYMENTS_REQUESTS_LIST",
  SET_IS_LOADING_QUOTE_DOWN_PAYMENTS_REQUESTS_LIST_ARCHIVED = "SET_IS_LOADING_QUOTE_DOWN_PAYMENTS_REQUESTS_LIST_ARCHIVED",
  SET_IS_LOADING_QUOTE_DOWN_PAYMENTS_REQUEST = "SET_IS_LOADING_QUOTE_DOWN_PAYMENTS_REQUEST",
  SET_IS_CREATING_QUOTE_DOWN_PAYMENTS_REQUEST = "SET_IS_CREATING_QUOTE_DOWN_PAYMENTS_REQUEST",
  SET_IS_UPDATING_QUOTE_DOWN_PAYMENTS_REQUEST = "SET_IS_UPDATING_QUOTE_DOWN_PAYMENTS_REQUEST",
  SET_IS_DELETING_QUOTE_DOWN_PAYMENTS_REQUEST = "SET_IS_DELETING_QUOTE_DOWN_PAYMENTS_REQUEST",
  SET_REQUEST_STATE_QUOTE_DOWN_PAYMENTS_REQUEST = "SET_REQUEST_STATE_QUOTE_DOWN_PAYMENTS_REQUEST",
  SET_IS_ARCHIVING_QUOTE_DOWN_PAYMENTS_REQUEST = "SET_IS_ARCHIVING_QUOTE_DOWN_PAYMENTS_REQUEST",  
}

  export const mutations: MutationTree<IQuoteDownPaymentsRequestState> = {
    [QuoteDownPaymentsRequestMutations.SET_QUOTE_DOWN_PAYMENTS_REQUESTS_LIST]: (state, payload: QuoteDownPaymentsRequestDto[]) => {
        state.quoteDownPaymentsRequestsList = payload;
    },
    [QuoteDownPaymentsRequestMutations.SET_QUOTE_DOWN_PAYMENTS_REQUESTS_LIST_ARCHIVED]: (state, payload: QuoteDownPaymentsRequestDto[]) => {
        state.quoteDownPaymentsRequestsListArchived = payload;
    },
    [QuoteDownPaymentsRequestMutations.SET_QUOTE_DOWN_PAYMENTS_REQUEST]: (state, payload: QuoteDownPaymentsRequestDto) => {
        state.quoteDownPaymentsRequest = payload;
    },


    //COMMON
    [QuoteDownPaymentsRequestMutations.SET_IS_LOADING_QUOTE_DOWN_PAYMENTS_REQUESTS_LIST]: (state, payload: boolean) => {
        state.isLoadingQuoteDownPaymentsRequestsList = payload;
    },
    [QuoteDownPaymentsRequestMutations.SET_IS_LOADING_QUOTE_DOWN_PAYMENTS_REQUESTS_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingQuoteDownPaymentsRequestsListArchived = payload;
    },
    [QuoteDownPaymentsRequestMutations.SET_IS_LOADING_QUOTE_DOWN_PAYMENTS_REQUEST]: (state, payload: boolean) => {
        state.isLoadingQuoteDownPaymentsRequest = payload;
    },
    [QuoteDownPaymentsRequestMutations.SET_IS_CREATING_QUOTE_DOWN_PAYMENTS_REQUEST]: (state, payload: boolean) => {
        state.isCreatingQuoteDownPaymentsRequest = payload;
    },
    [QuoteDownPaymentsRequestMutations.SET_IS_UPDATING_QUOTE_DOWN_PAYMENTS_REQUEST]: (state, payload: boolean) => {
        state.isUpdatingQuoteDownPaymentsRequest = payload;
    },
    [QuoteDownPaymentsRequestMutations.SET_IS_DELETING_QUOTE_DOWN_PAYMENTS_REQUEST]: (state, payload: boolean) => {
        state.isDeletingQuoteDownPaymentsRequest = payload;
    },
    [QuoteDownPaymentsRequestMutations.SET_REQUEST_STATE_QUOTE_DOWN_PAYMENTS_REQUEST]: (state, payload: IRequestState) => {
        state.requestStateQuoteDownPaymentsRequest = payload;
    },
    [QuoteDownPaymentsRequestMutations.SET_IS_ARCHIVING_QUOTE_DOWN_PAYMENTS_REQUEST]: (state, payload: boolean) => {
        state.isArchivingQuoteDownPaymentsRequest = payload;
    }
}