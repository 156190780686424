import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import RootState from "@/store/modules/rootState"
import { IExportState } from "./states";
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

export const state: IExportState = {
    exportAccounting                      : <any>{},
    exportUrl                             : <any>[],
    exportSalesJournalCsv                 : <any>[],
    isLoadingExport                       : false,
    isLoadingExportUrl                    : false,
    isLoadingInvoicesCreditsOnTimeSpan    : false,
    isLoadingExportSalesJournalCsv        : false,
    requestStateExport                    : <IRequestState>{},
}

export const ExportModule: Module<IExportState, RootState> = {
  state, 
  getters,
  mutations,
  actions
}