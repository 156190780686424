import { ICustomFieldGroupState } from "./states"
import { Module } from "vuex"
import { CustomFieldGroupRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:ICustomFieldGroupState = {
    customFieldGroupsList         : Array<CustomFieldGroupRequestDto>(),
    customFieldGroupsListArchived : Array<CustomFieldGroupRequestDto>(),
    customFieldGroup              : <CustomFieldGroupRequestDto>{},
    isLoadingCustomFieldGroupsList     : false,
    isLoadingCustomFieldGroupsListArchived     : false,
    isLoadingCustomFieldGroup     : false,
    isCreatingCustomFieldGroup    : false,
    isUpdatingCustomFieldGroup    : false,
    isDeletingCustomFieldGroup    : false,
    isArchivingCustomFieldGroup    : false,
    requestStateCustomFieldGroup: <IRequestState>{},
    customFieldGroupFilters                : {}
}

export const CustomFieldGroupModule: Module<ICustomFieldGroupState, RootState> = {
    state, 
    getters,
    mutations,
    actions
} 