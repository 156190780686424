import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { ModuleParameterRequestDto } from '@/../Api'
import RootState from "@/store/modules/rootState"
import { IModuleParameterState } from "./states";
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

export const state: IModuleParameterState = {
  moduleParametersList                  : Array<ModuleParameterRequestDto>(),
  moduleParametersListArchived          : Array<ModuleParameterRequestDto>(),
  moduleParameter                       : <ModuleParameterRequestDto>{},
  isLoadingModuleParametersList         : false,
  isLoadingModuleParametersListArchived : false,
  isLoadingModuleParameter              : false,
  isCreatingModuleParameter            : false,
  isUpdatingModuleParameter            : false,
  isArchivingModuleParameter            : false,
  isDeletingModuleParameter            : false,
  requestStateModuleParameter           : <IRequestState>{},
}

export const ModuleParameterModule: Module<IModuleParameterState, RootState> = {
  state, 
  getters,
  mutations,
  actions
}