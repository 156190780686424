import { OrderFormCustomerDetailRequestDto } from "Api";
import { GetterTree } from "vuex";
import RootState from '@/store/modules/rootState'
import { IOrderFormCustomerDetailState } from "./states";

export const getters: GetterTree<IOrderFormCustomerDetailState, RootState> = {
    getOrderTrackingCustomerHeader: (state): any => state.orderTrackingCustomerHeader,
    getOrderTrackingCustomerDetails: (state): OrderFormCustomerDetailRequestDto[] => state.orderTrackingCustomerDetails,
    isLoadingOrderTrackingCustomerDetails: (state) : boolean => state.isLoadingOrderTrackingCustomerDetails,

    getOrderFormCustomerHeader: (state): any => state.orderFormCustomerHeader,
    getOrderFormCustomerDetails: (state): OrderFormCustomerDetailRequestDto[] => state.orderFormCustomerDetails,
    getOrderFormCustomerDetail: (state) : OrderFormCustomerDetailRequestDto => state.orderFormCustomerDetail,
    isLoadingOrderFormCustomerDetails: (state) : boolean => state.isLoadingOrderFormCustomerDetails,
    isLoadingOrderFormCustomerDetailDuplication: (state) : boolean => state.isLoadingOrderFormCustomerDetailDuplication,
    isCreatingOrderFormCustomerDetail: (state): boolean => state.isCreatingOrderFormCustomerDetail,
    isUpdatingOrderFormCustomerDetail: (state): boolean => state.isUpdatingOrderFormCustomerDetail,
    isDeletingOrderFormCustomerDetail: (state): boolean => state.isDeletingOrderFormCustomerDetail,
}