import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { IOrderFormCustomer } from "@/types/api-orisis/interfaces/invoices/orderFormCustomer/IOrderFormCustomer";
import { IDocumentStats } from "@/types/api-orisis/interfaces/invoices/document/IDocumentStats";
import { IRemaining } from "@/types/api-orisis/interfaces/invoices/document/IRemaining";
import { GetterTree } from "vuex";
import RootState from '@/store/modules/rootState'
import {  IOrderFormCustomerState } from "./states";
import { IFilters } from "@/types/api-orisis/interfaces/invoices/filters/IFilters";
import { IPaymentMethod } from "@/types/api-orisis/interfaces/invoices/paymentMethod/IPaymentMethod";

export const getters: GetterTree<IOrderFormCustomerState, RootState> = {
    orderFormCustomersList                      : (state): IOrderFormCustomer[] => state.orderFormCustomersList,
    orderFormCustomersListTemplates             : (state): IOrderFormCustomer[] => state.orderFormCustomersListTemplates,
    orderFormCustomersListByAffair              : (state): IOrderFormCustomer[] => state.orderFormCustomersListByAffair,
    orderFormCustomersListByCompany             : (state): IOrderFormCustomer[] => state.orderFormCustomersListByCompany,
    orderFormCustomersRemainingToBill         : (state): IRemaining => state.orderFormCustomersRemainingToBill,
    lastDocumentOrderFormCustomerReference    : (state): string => state.lastDocumentOrderFormCustomerReference,

    orderFormCustomersListStats                      : (state): IDocumentStats => state.orderFormCustomersListStats,
    orderFormCustomersListAllStats                      : (state): IDocumentStats => state.orderFormCustomersListAllStats,

    orderFormCustomer                          : (state): IOrderFormCustomer => state.orderFormCustomer,
    orderFormCustomerPDF                       : (state): String => state.orderFormCustomerPDF,
    orderTrackingCustomerPDF                    : (state): String => state.orderTrackingCustomerPDF,
    orderTrackingCustomer                              : (state): IOrderFormCustomer => state.orderTrackingCustomer,
    
    //FILTERS 
    orderFormCustomerFilters                   : (state): IFilters => state.orderFormCustomerFilters,

    // COMMON   
    isValidatingOrderFormCustomer              : (state): boolean => state.isValidatingOrderFormCustomer,
    isLoadingOrderFormCustomersRemainingToBill : (state): boolean => state.isLoadingOrderFormCustomersRemainingToBill,
    isLoadingOrderFormCustomersList            : (state): boolean => state.isLoadingOrderFormCustomersList,
    isLoadingOrderFormCustomersListStats       : (state): boolean => state.isLoadingOrderFormCustomersListStats,
    isLoadingOrderFormCustomersListByCompany   : (state): boolean => state.isLoadingOrderFormCustomersListByCompany,
    isLoadingLastDocumentOrderFormCustomerReference : (state): boolean => state.isLoadingLastDocumentOrderFormCustomerReference,
    isLoadingOrderFormCustomersListByAffair    : (state): boolean => state.isLoadingOrderFormCustomersListByAffair,
    isLoadingOrderFormCustomer                 : (state): boolean => state.isLoadingOrderFormCustomer,
    isLoadingOrderFormCustomerPDF              : (state): boolean => state.isLoadingOrderFormCustomerPDF,
    isLoadingDuplicatingOrderFormCustomer      : (state): boolean => state.isLoadingDuplicatingOrderFormCustomer,
    isCreatingOrderFormCustomer                : (state): boolean => state.isCreatingOrderFormCustomer,
    isUpdatingOrderFormCustomer                : (state): boolean => state.isUpdatingOrderFormCustomer,
    isDeletingOrderFormCustomer                : (state): boolean => state.isDeletingOrderFormCustomer,
    requestStateOrderFormCustomer              : (state): IRequestState => state.requestStateOrderFormCustomer,
    requestStateOrderFormCustomerPDF           : (state): IRequestState => state.requestStateOrderFormCustomerPDF,
    
    orderFormCustomermarginStatus              : (state): boolean => state.orderFormCustomermarginStatus,
    orderFormCustomerTabActive                 : (state): String => state.orderFormCustomerTabActive,
    orderFormCustomerPaymentMethods            : (state): IPaymentMethod[] => state.orderFormCustomerPaymentMethods,
  }