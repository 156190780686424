import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { CompanyTypeMutations } from "./mutations";
import { ICompanyTypeState } from "./states";

export const actions: ActionTree<ICompanyTypeState, RootState> = {
  async getCompanyTypes(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    if (updateState) {
      commit(CompanyTypeMutations.SET_IS_LOADING_COMPANY_TYPE_LIST, true);
    }
    await axios
      .get(
        process.env.VUE_APP_API_ORISIS + "Directory/CompanyType/GetCompanyTypes"
      )
      .then((res) => {
        if (updateState) {
          commit(CompanyTypeMutations.SET_COMPANIES_TYPES_LIST, res.data);
          commit(CompanyTypeMutations.SET_IS_LOADING_COMPANY_TYPE_LIST, false);
        }
        commit(CompanyTypeMutations.SET_REQUEST_STATE_COMPANY_TYPE, res);
      })
      .catch((err) => {
        console.error(err);
        commit(CompanyTypeMutations.SET_REQUEST_STATE_COMPANY_TYPE, err);
        commit(CompanyTypeMutations.SET_IS_LOADING_COMPANY_TYPE_LIST, false);
      });
  },

  async getCompanyTypeById(
    { commit },
    {
      companyTypeId,
      updateState = true,
    }: { companyTypeId: number; updateState: boolean }
  ): Promise<void> {
    if (updateState) {
      commit(CompanyTypeMutations.SET_IS_LOADING_COMPANY_TYPE, true);
    }
    await axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          "Directory/CompanyType/GetCompanyTypeById",
        {
          params: {
            companyTypeId,
          },
        }
      )
      .then((res) => {
        if (updateState) {
          commit(CompanyTypeMutations.SET_COMPANY_TYPE, res.data);
          commit(CompanyTypeMutations.SET_IS_LOADING_COMPANY_TYPE, false);
        }
        commit(CompanyTypeMutations.SET_REQUEST_STATE_COMPANY_TYPE, res);
      })
      .catch((err) => {
        console.error(err);
        commit(CompanyTypeMutations.SET_REQUEST_STATE_COMPANY_TYPE, err);
        commit(CompanyTypeMutations.SET_IS_LOADING_COMPANY_TYPE, false);
      });
  },
};
