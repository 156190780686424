import { StorageLocationRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { StorageLocationMutations } from "./mutations";
import { IStorageLocationState } from "./states";

export const actions: ActionTree<IStorageLocationState, RootState> = {
  getStorageLocations(
    { commit, rootGetters },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          StorageLocationMutations.SET_IS_LOADING_STORAGE_LOCATION_LIST,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/StorageLocation/GetStorageLocations`;
      axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              StorageLocationMutations.SET_IS_LOADING_STORAGE_LOCATION_LIST,
              false
            );
            commit(
              StorageLocationMutations.SET_STORAGE_LOCATIONS_LIST,
              res.data
            );
          }
          commit(
            StorageLocationMutations.SET_REQUEST_STATE_STORAGE_LOCATION,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              StorageLocationMutations.SET_IS_LOADING_STORAGE_LOCATION_LIST,
              false
            );
          }
          commit(
            StorageLocationMutations.SET_REQUEST_STATE_STORAGE_LOCATION,
            err
          );
          reject(err);
        });
    });
  },

  getStorageLocationById(
    { commit, rootGetters },
    {
      storageLocationId,
      updateState = true,
    }: { storageLocationId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(StorageLocationMutations.SET_IS_LOADING_STORAGE_LOCATION, true);
      }
      axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/StorageLocation/GetStorageLocationById`,
          {
            params: {
              storageLocationId: storageLocationId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(
              StorageLocationMutations.SET_IS_LOADING_STORAGE_LOCATION,
              false
            );
            commit(StorageLocationMutations.SET_STORAGE_LOCATION, res.data);
          }
          commit(
            StorageLocationMutations.SET_REQUEST_STATE_STORAGE_LOCATION,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            StorageLocationMutations.SET_REQUEST_STATE_STORAGE_LOCATION,
            err
          );
          if (updateState) {
            commit(
              StorageLocationMutations.SET_IS_LOADING_STORAGE_LOCATION,
              false
            );
          }
          reject(err);
        });
    });
  },

  getStorageLocationsArchived(
    { commit, rootGetters },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          StorageLocationMutations.SET_IS_LOADING_STORAGE_LOCATION_LIST_ARCHIVED,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/StorageLocation/GetStorageLocationsArchived`;
      axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              StorageLocationMutations.SET_IS_LOADING_STORAGE_LOCATION_LIST_ARCHIVED,
              false
            );
            commit(
              StorageLocationMutations.SET_STORAGE_LOCATIONS_LIST_ARCHIVED,
              res.data
            );
          }
          commit(
            StorageLocationMutations.SET_REQUEST_STATE_STORAGE_LOCATION,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              StorageLocationMutations.SET_IS_LOADING_STORAGE_LOCATION_LIST_ARCHIVED,
              false
            );
          }
          commit(
            StorageLocationMutations.SET_REQUEST_STATE_STORAGE_LOCATION,
            err
          );
          reject(err);
        });
    });
  },

  getStorageLocationArchivedById(
    { commit, rootGetters },
    {
      storageLocationId,
      updateState = true,
    }: { storageLocationId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          StorageLocationMutations.SET_IS_LOADING_STORAGE_LOCATION_ARCHIVED,
          true
        );
      }
      axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/StorageLocation/GetStorageLocationArchivedById`,
          {
            params: {
              storageLocationId: storageLocationId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(
              StorageLocationMutations.SET_STORAGE_LOCATION_ARCHIVED,
              res.data
            );
            commit(
              StorageLocationMutations.SET_IS_LOADING_STORAGE_LOCATION_ARCHIVED,
              false
            );
          }
          commit(
            StorageLocationMutations.SET_REQUEST_STATE_STORAGE_LOCATION,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            StorageLocationMutations.SET_REQUEST_STATE_STORAGE_LOCATION,
            err
          );
          if (updateState) {
            commit(
              StorageLocationMutations.SET_IS_LOADING_STORAGE_LOCATION_ARCHIVED,
              false
            );
          }
          reject(err);
        });
    });
  },

  createStorageLocation(
    { commit, rootGetters },
    { storageLocation }: { storageLocation: StorageLocationRequestDto }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(StorageLocationMutations.SET_IS_CREATING_STORAGE_LOCATION, true);
      axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Purchase/StorageLocation/CreateStorageLocation`,
          storageLocation
        )
        .then((res) => {
          rootGetters.storageLocationsList.push(res.data.data);
          commit(
            StorageLocationMutations.SET_STORAGE_LOCATIONS_LIST,
            rootGetters.storageLocationsList
          );
          commit(
            StorageLocationMutations.SET_REQUEST_STATE_STORAGE_LOCATION,
            res
          );
          commit(
            StorageLocationMutations.SET_IS_CREATING_STORAGE_LOCATION,
            false
          );
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            StorageLocationMutations.SET_REQUEST_STATE_STORAGE_LOCATION,
            err
          );
          commit(
            StorageLocationMutations.SET_IS_CREATING_STORAGE_LOCATION,
            false
          );
          reject(err);
        });
    });
  },

  updateStorageLocation(
    { commit, rootGetters },
    { storageLocation }: { storageLocation: StorageLocationRequestDto }
  ) {
    return new Promise<void>((resolve, reject) => {
      commit(StorageLocationMutations.SET_IS_UPDATING_STORAGE_LOCATION, true);
      axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/StorageLocation/UpdateStorageLocation`,
          storageLocation
        )
        .then((res) => {
          rootGetters.storageLocationsList.splice(
            rootGetters.storageLocationsList.findIndex(
              (elem) => elem.id == storageLocation.id
            ),
            1,
            storageLocation
          );
          commit(
            StorageLocationMutations.SET_STORAGE_LOCATIONS_LIST,
            rootGetters.storageLocationsList
          );
          commit(
            StorageLocationMutations.SET_REQUEST_STATE_STORAGE_LOCATION,
            res
          );
          commit(
            StorageLocationMutations.SET_IS_UPDATING_STORAGE_LOCATION,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            StorageLocationMutations.SET_REQUEST_STATE_STORAGE_LOCATION,
            err
          );
          commit(
            StorageLocationMutations.SET_IS_UPDATING_STORAGE_LOCATION,
            false
          );
          reject(err);
        });
    });
  },

  restoreStorageLocations(
    { commit, rootGetters },
    { storageLocationIds }: { storageLocationIds: number[] }
  ) {
    return new Promise<void>((resolve, reject) => {
      commit(StorageLocationMutations.SET_IS_RESTORING_STORAGE_LOCATION, true);
      axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/StorageLocation/Restore`,
          storageLocationIds
        )
        .then((res) => {
          for (let i = 0; i < storageLocationIds.length; i++) {
            const id = storageLocationIds[i];
            rootGetters.storageLocationsList.push(
              rootGetters.storageLocationsListArchived.find(
                (elem) => elem.id == id
              )
            );
            rootGetters.storageLocationsListArchived.splice(
              rootGetters.storageLocationsListArchived.findIndex(
                (elem) => elem.id == id
              ),
              1
            );
          }
          commit(
            StorageLocationMutations.SET_STORAGE_LOCATIONS_LIST,
            rootGetters.storageLocationsList
          );
          commit(
            StorageLocationMutations.SET_STORAGE_LOCATIONS_LIST_ARCHIVED,
            rootGetters.storageLocationsListArchived
          );
          commit(
            StorageLocationMutations.SET_REQUEST_STATE_STORAGE_LOCATION,
            res
          );
          commit(
            StorageLocationMutations.SET_IS_RESTORING_STORAGE_LOCATION,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            StorageLocationMutations.SET_REQUEST_STATE_STORAGE_LOCATION,
            err
          );
          commit(
            StorageLocationMutations.SET_IS_RESTORING_STORAGE_LOCATION,
            false
          );
          reject(err);
        });
    });
  },

  archiveStorageLocations(
    { commit, rootGetters },
    { storageLocationIds }: { storageLocationIds: number[] }
  ) {
    return new Promise<void>((resolve, reject) => {
      commit(StorageLocationMutations.SET_IS_ARCHIVING_STORAGE_LOCATION, true);
      axios
        .delete(
          `${process.env.VUE_APP_API_ORISIS}Purchase/StorageLocation/Archive`,
          {
            data: storageLocationIds,
          }
        )
        .then((res) => {
          for (let i = 0; i < storageLocationIds.length; i++) {
            const id = storageLocationIds[i];
            rootGetters.storageLocationsListArchived.push(
              rootGetters.storageLocationsList.find((elem) => elem.id == id)
            );
            rootGetters.storageLocationsList.splice(
              rootGetters.storageLocationsList.findIndex(
                (elem) => elem.id == id
              ),
              1
            );
          }
          commit(
            StorageLocationMutations.SET_STORAGE_LOCATIONS_LIST,
            rootGetters.storageLocationsList
          );
          commit(
            StorageLocationMutations.SET_STORAGE_LOCATIONS_LIST_ARCHIVED,
            rootGetters.storageLocationsListArchived
          );
          commit(
            StorageLocationMutations.SET_REQUEST_STATE_STORAGE_LOCATION,
            res
          );
          commit(
            StorageLocationMutations.SET_IS_ARCHIVING_STORAGE_LOCATION,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            StorageLocationMutations.SET_REQUEST_STATE_STORAGE_LOCATION,
            err
          );
          commit(
            StorageLocationMutations.SET_IS_ARCHIVING_STORAGE_LOCATION,
            false
          );
          reject(err);
        });
    });
  },

  deleteStorageLocations(
    { commit, rootGetters },
    { storageLocationIds }: { storageLocationIds: number[] }
  ) {
    return new Promise<void>((resolve, reject) => {
      commit(StorageLocationMutations.SET_IS_DELETING_STORAGE_LOCATION, true);
      axios
        .delete(
          `${process.env.VUE_APP_API_ORISIS}Purchase/StorageLocation/Delete`,
          {
            data: storageLocationIds,
          }
        )
        .then((res) => {
          for (let i = 0; i < storageLocationIds.length; i++) {
            const id = storageLocationIds[i];
            rootGetters.storageLocationsListArchived.splice(
              rootGetters.storageLocationsListArchived.findIndex(
                (elem) => elem.id == id
              ),
              1
            );
          }

          commit(
            StorageLocationMutations.SET_STORAGE_LOCATIONS_LIST,
            rootGetters.storageLocationsList
          );
          commit(
            StorageLocationMutations.SET_REQUEST_STATE_STORAGE_LOCATION,
            res
          );
          commit(
            StorageLocationMutations.SET_IS_DELETING_STORAGE_LOCATION,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            StorageLocationMutations.SET_REQUEST_STATE_STORAGE_LOCATION,
            err
          );
          commit(
            StorageLocationMutations.SET_IS_DELETING_STORAGE_LOCATION,
            false
          );
          reject(err);
        });
    });
  },
};
