import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { OrderFormCustomerOptionDefaultMutations } from "./mutations";
import { IOrderFormCustomerOptionDefaultState } from "./states";
import { OrderFormCustomerOptionRequestDto } from "Api";

export const actions: ActionTree<
  IOrderFormCustomerOptionDefaultState,
  RootState
> = {
  getOrderFormCustomerOptionDefaultByOrderFormCustomerId(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          OrderFormCustomerOptionDefaultMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_OPTION_DEFAULT,
          true
        );
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/OrderFormCustomerOptionDefault/GetDefault`;

      axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              OrderFormCustomerOptionDefaultMutations.SET_ORDER_FORM_CUSTOMER_OPTION_DEFAULT,
              res.data
            );
            commit(
              OrderFormCustomerOptionDefaultMutations.SET_ORDER_FORM_CUSTOMER_OPTION_DEFAULT_FORM,
              res.data
            );
            commit(
              OrderFormCustomerOptionDefaultMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_OPTION_DEFAULT,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              OrderFormCustomerOptionDefaultMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_OPTION_DEFAULT,
              false
            );
          }
          reject(err);
        });
    });
  },

  createOrderFormCustomerOptionDefault(
    { commit },
    {
      orderFormCustomerOptionDefault,
      updateState = true,
    }: {
      orderFormCustomerOptionDefault: OrderFormCustomerOptionRequestDto;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          OrderFormCustomerOptionDefaultMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_OPTION_DEFAULT,
          true
        );
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/OrderFormCustomerOptionDefault/CreateOrderFormCustomerOptionDefault`;

      axios
        .post(url, orderFormCustomerOptionDefault)
        .then((res) => {
          if (updateState) {
            commit(
              OrderFormCustomerOptionDefaultMutations.SET_ORDER_FORM_CUSTOMER_OPTION_DEFAULT,
              res.data
            );
            commit(
              OrderFormCustomerOptionDefaultMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_OPTION_DEFAULT,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              OrderFormCustomerOptionDefaultMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_OPTION_DEFAULT,
              false
            );
          }
          reject(err);
        });
    });
  },

  updateOrderFormCustomerOptionDefault(
    { commit },
    {
      orderFormCustomerOptionDefault,
      updateState = true,
    }: {
      orderFormCustomerOptionDefault: OrderFormCustomerOptionRequestDto;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          OrderFormCustomerOptionDefaultMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_OPTION_DEFAULT,
          true
        );
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/OrderFormCustomerOptionDefault/UpdateOrderFormCustomerOptionDefault`;

      axios
        .put(url, orderFormCustomerOptionDefault)
        .then((res) => {
          if (updateState) {
            commit(
              OrderFormCustomerOptionDefaultMutations.SET_ORDER_FORM_CUSTOMER_OPTION_DEFAULT,
              res.data
            );
            commit(
              OrderFormCustomerOptionDefaultMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_OPTION_DEFAULT,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              OrderFormCustomerOptionDefaultMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_OPTION_DEFAULT,
              false
            );
          }
          reject(err);
        });
    });
  },
};
