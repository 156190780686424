import store from '@/store'
export function getNewIndex(rootGetters, payload) {
  return new Promise((resolve) => {
    if (rootGetters.length == 0 || payload.indexArray == -1) {
      if (payload.type == 2) payload.type = 0;
      resolve({index: '1', type: payload.type});
    } else {
      let lastLine = rootGetters[payload.indexArray];
      let decomposeLastIndex = lastLine.index.split('.');
      let newIndex: string;
      if (decomposeLastIndex.length == 1) {
        if (payload.type == 1) {
          newIndex = (parseInt(decomposeLastIndex[0]) + 1).toString()
          resolve({index: newIndex, type: payload.type})
        } else {
          if (lastLine.type == 1) {
            newIndex = decomposeLastIndex[0] +  '.1';
            resolve({index: newIndex, type: payload.type})
          }else {
            newIndex = (parseInt(decomposeLastIndex[0]) + 1).toString()
            resolve({index: newIndex, type: payload.type})
          }
        }
      } else if (decomposeLastIndex.length == 2) {
        if (payload.type == 1) {
          newIndex = (parseInt(decomposeLastIndex[0]) + 1).toString()
          resolve({index: newIndex, type: payload.type})
        } else if (payload.type == 2) {
          newIndex = decomposeLastIndex[0] + '.' + (parseInt(decomposeLastIndex[1]) + 1);
          resolve({index: newIndex, type: payload.type})
        }
        else {
          if (lastLine.type == 2) {
            newIndex = decomposeLastIndex[0] + '.' + decomposeLastIndex[1] + '.1';
            resolve({index: newIndex, type: payload.type})
          }
          else {
            newIndex = decomposeLastIndex[0] + '.' + (parseInt(decomposeLastIndex[1]) + 1);
            resolve({index: newIndex, type: payload.type})
          }
        }
      } else if (decomposeLastIndex.length == 3) {
        if (payload.type == 1) {
          newIndex = (parseInt(decomposeLastIndex[0]) + 1).toString()
          resolve({index: newIndex, type: payload.type})
        }
        else if (payload.type == 2) {
          newIndex = decomposeLastIndex[0] + '.' + (parseInt(decomposeLastIndex[1]) + 1);
          resolve({index: newIndex, type: payload.type})
        }
        else {
          newIndex = decomposeLastIndex[0] + '.' + decomposeLastIndex[1] + '.' + (parseInt(decomposeLastIndex[2]) + 1);
          resolve({index: newIndex, type: payload.type})
        }
      }
    } 
  })
}

// TODO: A OPTIMISER
export function productMergeByTypeLine(line:any, catalogSortedByProductType:any){
  //if line.typeLine == 0 put key "Fournitures" at first position
  let catalog = catalogSortedByProductType;
  if (line.type == 7) {
    let catalogData:any = [];
    let catalogDataDivers:any = [];
    for (let key in catalog) {
      if (key == "0") {
        catalogData = [...catalogData, ...catalog[key]];
      }
      else {
          catalogDataDivers = [...catalogDataDivers, ...catalog[key]];
      }
    }
    catalog = { Fourniture: catalogData, "Autres catégories" : catalogDataDivers};
  } else if (line.type == 9) {
    let catalogData:any = [];
    let catalogDataDivers:any = [];
    for (let key in catalog) {
      if (key == "2") {
        catalogData = [...catalogData, ...catalog[key]];
      }
      else {
          catalogDataDivers = [...catalogDataDivers, ...catalog[key]];
      }
    }
    catalog = { "Matériel": catalogData, "Autres catégories" : catalogDataDivers};
  } else if (line.type == 6) {
    let catalogData:any = [];
    let catalogDataDivers:any = [];
    for (let key in catalog) {
      if (key == "4") {
        catalogData = [...catalogData, ...catalog[key]];
      }
      else {
          catalogDataDivers = [...catalogDataDivers, ...catalog[key]];
      }
    }
    catalog = { Texte: catalogData, "Autres catégories" : catalogDataDivers};
  } else if (line.type == 8) {
    let catalogData:any = [];
    let catalogDataDivers:any = [];
    for (let key in catalog) {
      if (key == "5") {
        catalogData = [...catalogData, ...catalog[key]];
      }
      else {
          catalogDataDivers = [...catalogDataDivers, ...catalog[key]];
      }
    }
    catalog = { "Main d'oeuvre": catalogData, "Autres catégories" : catalogDataDivers};
  } else if (line.type == 10) {
    let catalogData:any = [];
    let catalogDataDivers:any = [];
    for (let key in catalog) {
      if (key == "6") {
        catalogData = [...catalogData, ...catalog[key]];
      }
      else {
          catalogDataDivers = [...catalogDataDivers, ...catalog[key]];
      }
    }
    catalog = { "Autres": catalogData, "Autres catégories" : catalogDataDivers};
  }
  return catalog;
}

export function calculateTotalDeliveryFormLine(el: any) {
  let priceBrutHt = Math.round((el.unitPriceHt * el.outPutQuantity) * 100) / 100;
  return priceBrutHt;
}

export function productsListByTypeLine(line, array, keywordsCatalog) {
  if (keywordsCatalog.length > 1) {
    var strippedHtml = keywordsCatalog.replace(/<br>/g, " ");
    strippedHtml = keywordsCatalog.replace(/<[^>]+>/g, "");
    var words = strippedHtml.split(" ");
    return Object.keys(
      productMergeByTypeLine(line, array)
    ).reduce((acc, key) => {
      acc[key] = productMergeByTypeLine(
        line,
        array
      )[key].filter((product) => {
        if (product.description && product.reference && product.label) {
          return words.some(
            (v) =>
              product.description
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
                .includes(v.toLowerCase()) ||
              product.reference
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
                .includes(v.toLowerCase()) ||
              product.label
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
                .includes(v.toLowerCase())
          );
        } else if (product.description && product.reference) {
            return words.some(
              (v) =>
                product.description
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLowerCase()
                  .includes(v.toLowerCase()) ||
                product.reference
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLowerCase()
                  .includes(v.toLowerCase())
            );
          } else if (product.description && product.label) {
            return words.some(
              (v) =>
                product.description
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLowerCase()
                  .includes(v.toLowerCase()) ||
                product.label
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLowerCase()
                  .includes(v.toLowerCase())
            );
          } else if (product.reference && product.label) {
            return words.some(
              (v) =>
                product.reference
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLowerCase()
                  .includes(v.toLowerCase()) ||
                product.label
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLowerCase()
                  .includes(v.toLowerCase())
            );
        } else if (product.description) {
          return words.some((v) =>
            product.description
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
              .includes(v.toLowerCase())
          );
        } else if (product.reference) {
          return words.some((v) =>
            product.reference
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
              .includes(v.toLowerCase())
          );
        } else if (product.label) {
          return words.some((v) =>
            product.label
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
              .includes(v.toLowerCase())
          );
        }
      });
      return acc;
    }, {});
  } else {
    return productMergeByTypeLine(
      line,
      array
    );
  }
}