import { CompanyRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { CompanyMutations } from "./mutations";
import { ICompanyState } from "./states";

export const actions: ActionTree<ICompanyState, RootState> = {
  async getCompanies(
    { commit },
    {
      code = null,
      withIndicators = false,
      companyTypeId = null,
      updateState = true,
    }: {
      code?: string | null;
      withIndicators?: boolean;
      companyTypeId?: number;
      updateState?: boolean;
    }
  ): Promise<void> {
    return await new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(CompanyMutations.SET_IS_LOADING_COMPANIES_LIST, true);
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS + "Directory/Company/GetCompanies",
          {
            params: {
              code: code,
              withIndicators: withIndicators,
              companyTypeId: companyTypeId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(CompanyMutations.SET_COMPANIES_LIST, res.data);
            commit(CompanyMutations.SET_IS_LOADING_COMPANIES_LIST, false);
          }
          commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, err);
          commit(CompanyMutations.SET_IS_LOADING_COMPANIES_LIST, false);
          reject(err);
        });
    });
  },

  async getCompaniesArchived(
    { commit },
    {
      code = null,
      withIndicators = false,
      companyTypeId = null,
      updateState = true,
    }: {
      code?: string | null;
      withIndicators?: boolean;
      companyTypeId?: number;
      updateState?: boolean;
    }
  ): Promise<void> {
    return await new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(CompanyMutations.SET_IS_LOADING_COMPANIES_LIST_ARCHIVED, true);
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Directory/Company/GetCompaniesArchived",
          {
            params: {
              code: code,
              withIndicators: withIndicators,
              companyTypeId: companyTypeId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(CompanyMutations.SET_COMPANIES_LIST_ARCHIVED, res.data);
            commit(
              CompanyMutations.SET_IS_LOADING_COMPANIES_LIST_ARCHIVED,
              false
            );
          }
          commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, err);
          commit(CompanyMutations.SET_IS_LOADING_COMPANIES_LIST, false);
          reject(err);
        });
    });
  },

  async getCompanyById(
    { commit },
    {
      updateState = true,
      companyId,
    }: { updateState: boolean; companyId: number }
  ): Promise<void> {
    return await new Promise(async (resolve, reject) => {
      if (updateState) {
        commit(CompanyMutations.SET_IS_LOADING_COMPANY, true);
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS + "Directory/Company/getCompanyById",
          {
            params: {
              companyId: companyId,
            },
          }
        )
        .then((res) => {
          let company = {
            ...res.data,
            contacts: res.data.contacts?.map((contact) => {
              return { ...contact, contactId: contact.contact.id };
            }),
          };
          if (updateState) {
            commit(CompanyMutations.SET_COMPANY, company);
            commit(CompanyMutations.SET_IS_LOADING_COMPANY, false);
          }
          commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, res);
          resolve(company);
        })
        .catch((err) => {
          console.error(err);
          commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, err);
          commit(CompanyMutations.SET_IS_LOADING_COMPANY, false);
          reject(err);
        });
    });
  },

  async getCompanyArchivedById(
    { commit },
    {
      updateState = true,
      companyId,
    }: { updateState: boolean; companyId: number }
  ): Promise<void> {
    return await new Promise(async (resolve, reject) => {
      if (updateState) {
        commit(CompanyMutations.SET_IS_LOADING_COMPANY, true);
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Directory/Company/GetCompanyArchivedById",
          {
            params: {
              companyId: companyId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(CompanyMutations.SET_COMPANY, res.data);
            commit(CompanyMutations.SET_IS_LOADING_COMPANY, false);
          }
          commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, err);
          commit(CompanyMutations.SET_IS_LOADING_COMPANY, false); 
          reject(err);
        });
    });
  },

  async createCompany(
    { commit, rootGetters },
    { company }: { company: CompanyRequestDto }
  ): Promise<void> {
    return await new Promise(async (resolve, reject) => {
      commit(CompanyMutations.SET_IS_CREATING_COMPANY, true);
      await axios
        .post(
          process.env.VUE_APP_API_ORISIS + "Directory/Company/CreateCompany",
          company
        )
        .then((res) => {
          rootGetters.companiesList.push(res.data.data);
          commit(
            CompanyMutations.SET_COMPANIES_LIST,
            rootGetters.companiesList
          );
          commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, res);
          commit(CompanyMutations.SET_IS_CREATING_COMPANY, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, err);
          commit(CompanyMutations.SET_IS_CREATING_COMPANY, false);
          reject(err);
        });
    });
  },

  async updateCompany(
    { commit, rootGetters },
    { company }: { company: CompanyRequestDto }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(CompanyMutations.SET_IS_UPDATING_COMPANY, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Directory/Company/UpdateCompany",
          company
        )
        .then((res) => {
          rootGetters.companiesList.splice(
            rootGetters.companiesList.findIndex(
              (elem) => elem.id == company.id
            ),
            1,
            res.data.data
          );
          commit(
            CompanyMutations.SET_COMPANIES_LIST,
            rootGetters.companiesList
          );
          commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, res);
          commit(CompanyMutations.SET_IS_UPDATING_COMPANY, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, err);
          commit(CompanyMutations.SET_IS_UPDATING_COMPANY, false);
          reject(err);
        });
    });
  },

  async updateCompanyTagCompany(
    { commit },
    { companyIds = [], companyTagIds = [] }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Directory/Company/UpdateCompanyTagCompany`,
          { companyIds: companyIds, companyTagIds: companyTagIds }
        )
        .then(async (res) => {
          commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, err);
          reject(err);
        });
    });
  },

  async updateCompaniesAccount(
    { commit },
    { companiesAccount = [] }: { companiesAccount: any[] }
  ): Promise<void> {
    commit(CompanyMutations.SET_IS_UPDATING_COMPANY, true);
    return new Promise(async (resolve, reject) => {
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Directory/Company/UpdateCompaniesAccount",
          companiesAccount
        )
        .then((res) => {
          commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, res);
          commit(CompanyMutations.SET_IS_UPDATING_COMPANY, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, err);
          commit(CompanyMutations.SET_IS_UPDATING_COMPANY, false);
          reject(err);
        });
    });
  },

  // updateCompanyCustomPerimeter

  async restoreCompanies(
    { commit, rootGetters },
    { companyIds = [] }: { companyIds: number[] }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(CompanyMutations.SET_IS_UPDATING_COMPANY, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Directory/Company/Restore",
          companyIds
        )
        .then((res) => {
          companyIds.forEach((companyId) => {
            rootGetters.companiesListArchived.splice(
              rootGetters.companiesListArchived.findIndex(
                (elem) => elem.id == companyId
              ),
              1
            );
          });
          commit(
            CompanyMutations.SET_COMPANIES_LIST_ARCHIVED,
            rootGetters.companiesListArchived
          );
          commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, res);
          commit(CompanyMutations.SET_IS_UPDATING_COMPANY, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, err);
          commit(CompanyMutations.SET_IS_UPDATING_COMPANY, false);
          reject(err);
        });
    });
  },

  async archiveCompanies(
    { commit, rootGetters },
    { companyIds = [] }: { companyIds: number[] }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(CompanyMutations.SET_IS_ARCHIVING_COMPANY, true);
      await axios
        .delete(process.env.VUE_APP_API_ORISIS + "Directory/Company/Archive", {
          data: companyIds,
        })
        .then((res) => {
          companyIds.forEach((companyId) => {
            rootGetters.companiesList.splice(
              rootGetters.companiesList.findIndex(
                (elem) => elem.id == companyId
              ),
              1
            );
          });
          commit(
            CompanyMutations.SET_COMPANIES_LIST,
            rootGetters.companiesList
          );
          commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, res);
          commit(CompanyMutations.SET_IS_ARCHIVING_COMPANY, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, err);
          commit(CompanyMutations.SET_IS_ARCHIVING_COMPANY, false);
          reject(err);
        });
    });
  },

  async deleteCompanies(
    { commit, rootGetters },
    { companyIds = [] }: { companyIds: number[] }
  ) {
    return new Promise(async (resolve, reject) => {
      commit(CompanyMutations.SET_IS_DELETING_COMPANY, true);
      await axios
        .delete(process.env.VUE_APP_API_ORISIS + "Directory/Company/Delete", {
          data: companyIds,
        })
        .then((res) => {
          companyIds.forEach((companyId) => {
            rootGetters.companiesListArchived.splice(
              rootGetters.companiesListArchived.findIndex(
                (elem) => elem.id == companyId
              ),
              1
            );
          });
          commit(
            CompanyMutations.SET_COMPANIES_LIST_ARCHIVED,
            rootGetters.companiesListArchived
          );
          commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, res);
          commit(CompanyMutations.SET_IS_DELETING_COMPANY, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, err);
          commit(CompanyMutations.SET_IS_DELETING_COMPANY, false);
          reject(err);
        });
    });
  },

  // deleteAddressesFromCompany
};
