import { IReceiptFormProviderState } from "./states";
import { Module } from "vuex";
import { ReceiptFormRequestDto } from "@/../Api";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import RootState from "@/store/modules/rootState";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { IPaymentMethod } from "@/types/api-orisis/interfaces/invoices/paymentMethod/IPaymentMethod";
import { IFilters } from "@/types/api-orisis/interfaces/invoices/filters/IFilters";

const state: IReceiptFormProviderState = {
  receiptFormProvidersList: Array<ReceiptFormRequestDto>(),
  filteredReceiptFormProvidersList: Array<ReceiptFormRequestDto>(),
  receiptFormProvidersListArchived: Array<ReceiptFormRequestDto>(),
  filteredReceiptFormProvidersListArchived: Array<ReceiptFormRequestDto>(),
  receiptFormProvider: <ReceiptFormRequestDto>{},
  receiptFormProviderArchived: <ReceiptFormRequestDto>{},
  receiptFormProviderPaymentMethods: Array<IPaymentMethod>(),
  isValidatingReceiptFormProvider             : false,
  isLoadingPaymentMethodsReceiptFormProvider: false,
  isLoadingReceiptFormProvidersList: false,
  isLoadingReceiptFormProvidersListArchived: false,
  isLoadingReceiptFormProvider: false,
  isLoadingDuplicatingReceiptFormProvider: false,
  isLoadingReceiptFormProviderArchived: false,
  isCreatingReceiptFormProvider: false,
  isUpdatingReceiptFormProvider: false,
  isRestoringReceiptFormProvider: false,
  isDeletingReceiptFormProvider: false,
  isArchivingReceiptFormProvider: false,
  requestStateReceiptFormProvider: <IRequestState>{},
  receiptFormProviderTabActive: "receipt-form-provider-edit",
  receiptFormProviderPDF: "",
  isLoadingReceiptFormProviderPDF: false,
  receiptFormProviderFilters: <IFilters>{},
  requestStateReceiptFormProviderPDF: <IRequestState>{},
  initialReceiptFormProvider: <ReceiptFormRequestDto>{},
  receiptFormProvidersListByAffair: Array<ReceiptFormRequestDto>(),
  receiptFormProvidersListByCompany: Array<ReceiptFormRequestDto>(),
  isLoadingReceiptFormProvidersListByAffair: false,
  isLoadingReceiptFormProvidersListByCompany: false,
};

export const ReceiptFormProviderModule: Module<IReceiptFormProviderState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
