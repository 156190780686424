import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IReceiptFormOptionState } from "./states";
import { ReceiptFormOptionRequestDto } from "Api";

export const getters: GetterTree<IReceiptFormOptionState, RootState> = {
  receiptFormOption(state): ReceiptFormOptionRequestDto {
    return state.receiptFormOption;
  },
  isLoadingReceiptFormOption(state): boolean {
    return state.isLoadingReceiptFormOption;
  },
  requestStateReceiptFormOption(state): any {
    return state.requestStateReceiptFormOption;
  },
};
