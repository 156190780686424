import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import RootState from "@/store/modules/rootState";
import { IDeliveryFormHistoryState } from "./states";
import { Module } from "vuex";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

export const state: IDeliveryFormHistoryState = {
  deliveryFormHistoriesList: [],
  deliveryFormHistory: {},
  isLoadingDeliveryFormHistoriesList: false,
  isLoadingDeliveryFormHistory: false,
  requestStateDeliveryFormHistory: <IRequestState>{},
};

export const DeliveryFormHistoryModule: Module<
  IDeliveryFormHistoryState,
  RootState
> = {
  state,
  getters,
  mutations,
  actions,
};
