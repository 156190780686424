import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { IExportSalesJournal } from "@/types/api-orisis/interfaces/Accounting/exportSalesJournal/IExportSalesJournal";
import { GetterTree } from "vuex";
import RootState from '@/store/modules/rootState'
import { IExportSalesJournalState } from "./states";

export const getters: GetterTree<IExportSalesJournalState, RootState> = {
    exportSalesJournalList            : (state): IExportSalesJournal[] => state.exportSalesJournalList,
    exportSalesJournal                 : (state): IExportSalesJournal => state.exportSalesJournal,
    
    // COMMON
    isLoadingExportSalesJournalList    : (state): boolean => state.isLoadingExportSalesJournalList,
    isCreatingExportSalesJournal       : (state): boolean => state.isCreatingExportSalesJournal,
    isUpdatingExportSalesJournal       : (state): boolean => state.isUpdatingExportSalesJournal,
    isDeletingExportSalesJournal       : (state): boolean => state.isDeletingExportSalesJournal,
    isArchivingExportSalesJournal      : (state): boolean => state.isArchivingExportSalesJournal,
    requestStateExportSalesJournal     : (state): IRequestState => state.requestStateExportSalesJournal,
}