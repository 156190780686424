import { LeaseTypeRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { LeaseTypeMutations } from "./mutations";
import { ILeaseTypeState } from "./states";

export const actions: ActionTree<ILeaseTypeState, RootState> = {
  getLeaseTypes(
    { commit },
    { updateState = true }: { updateState?: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(LeaseTypeMutations.SET_IS_LOADING_LEASE_TYPE_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Management/LeaseType/GetLeaseTypes`;
      axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(LeaseTypeMutations.SET_IS_LOADING_LEASE_TYPE_LIST, false);
            commit(LeaseTypeMutations.SET_LEASE_TYPE_LIST, res.data);
          }
          commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(LeaseTypeMutations.SET_IS_LOADING_LEASE_TYPE_LIST, false);
          commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, err);
          reject(err);
        });
    });
  },

  getLeaseTypeById(
    { commit },
    {
      leaseTypeId,
      updateState = true,
    }: { leaseTypeId: number; updateState?: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(LeaseTypeMutations.SET_IS_LOADING_LEASE_TYPE, true);
      }
      axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Management/LeaseType/GetLeaseTypeById`,
          {
            params: {
              leaseTypeId: leaseTypeId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(LeaseTypeMutations.SET_LEASE_TYPE, res.data);
            commit(LeaseTypeMutations.SET_IS_LOADING_LEASE_TYPE, false);
          }
          commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, err);
          commit(LeaseTypeMutations.SET_IS_LOADING_LEASE_TYPE, false);
          reject(err);
        });
    });
  },

  getLeaseTypeArchivedById(
    { commit },
    {
      leaseTypeId,
      updateState = true,
    }: { leaseTypeId: number; updateState?: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(LeaseTypeMutations.SET_IS_LOADING_LEASE_TYPE, true);
      }
      axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Management/LeaseType/GetLeaseTypeArchivedById`,
          {
            params: {
              leaseTypeId: leaseTypeId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(LeaseTypeMutations.SET_LEASE_TYPE, res.data);
            commit(LeaseTypeMutations.SET_IS_LOADING_LEASE_TYPE, false);
          }
          commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, err);
          commit(LeaseTypeMutations.SET_IS_LOADING_LEASE_TYPE, false);
          reject(err);
        });
    });
  },

  getLeaseTypesArchived(
    { commit },
    { updateState = true }: { updateState?: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          LeaseTypeMutations.SET_IS_LOADING_LEASE_TYPE_LIST_ARCHIVED,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Management/LeaseType/GetLeaseTypesArchived`;
      axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              LeaseTypeMutations.SET_IS_LOADING_LEASE_TYPE_LIST_ARCHIVED,
              false
            );
            commit(LeaseTypeMutations.SET_LEASE_TYPE_LIST_ARCHIVED, res.data);
          }
          commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            LeaseTypeMutations.SET_IS_LOADING_LEASE_TYPE_LIST_ARCHIVED,
            false
          );
          commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, err);
          reject(err);
        });
    });
  },

  createLeaseType(
    { commit, rootGetters },
    { leaseType }: { leaseType: LeaseTypeRequestDto }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(LeaseTypeMutations.SET_IS_CREATING_LEASE_TYPE, true);
      axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Management/LeaseType/CreateLeaseType`,
          leaseType
        )
        .then((res) => {
          rootGetters.leaseTypeList.push(res.data.data);
          commit(
            LeaseTypeMutations.SET_LEASE_TYPE_LIST,
            rootGetters.leaseTypeList
          );
          commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, res);
          commit(LeaseTypeMutations.SET_IS_CREATING_LEASE_TYPE, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, err);
          commit(LeaseTypeMutations.SET_IS_CREATING_LEASE_TYPE, false);
          reject(err);
        });
    });
  },

  updateLeaseType(
    { commit, rootGetters },
    { leaseType }: { leaseType: LeaseTypeRequestDto }
  ) {
    return new Promise((resolve, reject) => {
      commit(LeaseTypeMutations.SET_IS_UPDATING_LEASE_TYPE, true);
      axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Management/LeaseType/UpdateLeaseType`,
          leaseType
        )
        .then((res) => {
          rootGetters.leaseTypeList.splice(
            rootGetters.leaseTypeList.findIndex(
              (elem) => elem.id == leaseType.id
            ),
            1,
            leaseType
          );
          commit(
            LeaseTypeMutations.SET_LEASE_TYPE_LIST,
            rootGetters.leaseTypeList
          );
          commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, res);
          commit(LeaseTypeMutations.SET_IS_UPDATING_LEASE_TYPE, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, err);
          commit(LeaseTypeMutations.SET_IS_UPDATING_LEASE_TYPE, false);
          reject(err);
        });
    });
  },

  deleteLeaseTypes(
    { commit, rootGetters },
    { leaseTypeIds = [] }: { leaseTypeIds: number[] }
  ) {
    return new Promise((resolve, reject) => {
      commit(LeaseTypeMutations.SET_IS_DELETING_LEASE_TYPE, true);
      axios
        .delete(
          `${process.env.VUE_APP_API_ORISIS}Management/LeaseType/Delete`,
          {
            data: leaseTypeIds,
          }
        )
        .then((res) => {
          leaseTypeIds.forEach((leaseTypeId: number) => {
            rootGetters.leaseTypeListArchived.splice(
              rootGetters.leaseTypeListArchived.findIndex(
                (leaseType) => leaseType.id == leaseTypeId
              ),
              1
            );
          });
          commit(
            LeaseTypeMutations.SET_LEASE_TYPE_LIST_ARCHIVED,
            rootGetters.leaseTypeListArchived
          );
          commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, res);
          commit(LeaseTypeMutations.SET_IS_DELETING_LEASE_TYPE, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, err);
          commit(LeaseTypeMutations.SET_IS_DELETING_LEASE_TYPE, false);
          reject(err);
        });
    });
  },

  async archiveLeaseTypes(
    { commit, rootGetters },
    leaseTypeIds: number[]
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(LeaseTypeMutations.SET_IS_ARCHIVING_LEASE_TYPE, true);
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Management/LeaseType/Archive",
          {
            data: leaseTypeIds,
          }
        )
        .then((res) => {
          leaseTypeIds.forEach((leaseTypeId: number) => {
            rootGetters.leaseTypeList.splice(
              rootGetters.leaseTypeList.findIndex(
                (elem) => elem.id == leaseTypeId
              ),
              1
            );
          });
          commit(
            LeaseTypeMutations.SET_LEASE_TYPE_LIST,
            rootGetters.leaseTypeList
          );
          commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, res);
          commit(LeaseTypeMutations.SET_IS_ARCHIVING_LEASE_TYPE, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, err);
          commit(LeaseTypeMutations.SET_IS_ARCHIVING_LEASE_TYPE, false);
          reject(err);
        });
    });
  },

  async restoreLeaseTypes(
    { commit, rootGetters },
    leaseTypeIds: number[]
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(LeaseTypeMutations.SET_IS_UPDATING_LEASE_TYPE, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Management/LeaseType/Restore",
          leaseTypeIds
        )
        .then((res) => {
          leaseTypeIds.forEach((leaseTypeId: number) => {
            rootGetters.leaseTypeListArchived.splice(
              rootGetters.leaseTypeListArchived.findIndex(
                (elem) => elem.id == leaseTypeId
              ),
              1
            );
          });
          commit(
            LeaseTypeMutations.SET_LEASE_TYPE_LIST_ARCHIVED,
            rootGetters.leaseTypeListArchived
          );
          commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, res);
          commit(LeaseTypeMutations.SET_IS_UPDATING_LEASE_TYPE, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, err);
          commit(LeaseTypeMutations.SET_IS_UPDATING_LEASE_TYPE, false);
          reject(err);
        });
    });
  },
};
