import { IPurchaseCategoryState } from "./states"
import { Module } from "vuex"
import { PurchaseCategoryRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state: IPurchaseCategoryState = {
    purchaseCategoriesList                     : Array<PurchaseCategoryRequestDto>(),
    purchaseCategoriesTreeList                 : Array<any>(),
    purchaseCategoriesListArchived             : Array<PurchaseCategoryRequestDto>(),
    purchaseCategory                           : <PurchaseCategoryRequestDto>{},
    isLoadingPurchaseCategoriesList            : false,
    isLoadingPurchaseCategoriesTreeList        : false,
    isLoadingPurchaseCategoriesListArchived    : false,
    isLoadingPurchaseCategory                  : false,
    isCreatingPurchaseCategory                 : false,
    isUpdatingPurchaseCategory                 : false,
    isDeletingPurchaseCategory                 : false,
    isArchivingPurchaseCategory                : false,
    requestStatePurchaseCategory               : <IRequestState>{},
}

export const PurchaseCategoryModule: Module<IPurchaseCategoryState, RootState> = {
    state, 
    getters,
    mutations,
    actions
} 