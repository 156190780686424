import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { OrderFormOptionMutations } from "./mutations";
import { IOrderFormOptionState } from "./states";
import { OrderFormOptionRequestDto } from "Api";

export const actions: ActionTree<IOrderFormOptionState, RootState> = {
  getOrderFormOptionByOrderFormId(
    { commit },
    {
      orderFormId,
      updateState = true,
    }: { orderFormId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(OrderFormOptionMutations.SET_IS_LOADING_ORDER_FORM_OPTION, true);
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/OrderFormOption/GetOrderFormOptionByOrderFormId`;

      axios
        .get(url, {
          params: { orderFormId },
        })
        .then((res) => {
          if (updateState) {
            commit(OrderFormOptionMutations.SET_ORDER_FORM_OPTION, res.data);
            commit(
              OrderFormOptionMutations.SET_IS_LOADING_ORDER_FORM_OPTION,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              OrderFormOptionMutations.SET_IS_LOADING_ORDER_FORM_OPTION,
              false
            );
          }
          reject(err);
        });
    });
  },

  createOrderFormOption(
    { commit },
    {
      orderFormOption,
      updateState = true,
    }: { orderFormOption: OrderFormOptionRequestDto; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(OrderFormOptionMutations.SET_IS_LOADING_ORDER_FORM_OPTION, true);
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/OrderFormOption/CreateOrderFormOption`;

      axios
        .post(url, orderFormOption)
        .then((res) => {
          if (updateState) {
            commit(
              OrderFormOptionMutations.SET_ORDER_FORM_OPTION,
              res.data.data
            );
            commit(
              OrderFormOptionMutations.SET_IS_LOADING_ORDER_FORM_OPTION,
              false
            );
          }
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              OrderFormOptionMutations.SET_IS_LOADING_ORDER_FORM_OPTION,
              false
            );
          }
          reject(err);
        });
    });
  },

  updateOrderFormOption(
    { commit, rootGetters },
    {
      orderFormOption,
      updateState = true,
    }: { orderFormOption: OrderFormOptionRequestDto; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(OrderFormOptionMutations.SET_IS_LOADING_ORDER_FORM_OPTION, true);
      }
      const url = `${process.env.VUE_APP_API_ORISIS}Sale/OrderFormOption/UpdateOrderFormOption`;
      axios
        .put(url, {
          ...orderFormOption,
          orderFormId: rootGetters.orderFormProvider.id,
        })
        .then((res) => {
          if (updateState) {
            commit(
              OrderFormOptionMutations.SET_ORDER_FORM_OPTION,
              res.data.data
            );
            commit(
              OrderFormOptionMutations.SET_IS_LOADING_ORDER_FORM_OPTION,
              false
            );
          }
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              OrderFormOptionMutations.SET_IS_LOADING_ORDER_FORM_OPTION,
              false
            );
          }
          reject(err);
        });
    });
  },
};
