import { IOrderFormCustomerOptionState } from "./states";
import { Module } from "vuex";
import { OrderFormCustomerOptionRequestDto } from "@/../Api";
import RootState from "@/store/modules/rootState";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

const state: IOrderFormCustomerOptionState = {
  orderFormCustomerOption: <OrderFormCustomerOptionRequestDto>{},
  isLoadingOrderFormCustomerOption: false,
  requestStateOrderFormCustomerOption: <IRequestState>{},
};

export const OrderFormCustomerOptionModule: Module<
  IOrderFormCustomerOptionState,
  RootState
> = {
  state,
  getters,
  mutations,
  actions,
};
