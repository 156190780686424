import { InventoryRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { InventoryMutations } from "./mutations";
import { FileMutations } from "../../ged/file/mutations";
import { FolderMutations } from "../../ged/folder/mutations";

import { IInventoryState } from "./states";
import { Timezone } from "@syncfusion/ej2-schedule";

let timezoneSchedule = new Timezone();

export const actions: ActionTree<IInventoryState, RootState> = {
  async getInventories(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(InventoryMutations.SET_IS_LOADING_INVENTORY_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/Inventory/GetInventories`;
      await axios
        .get(url)
        .then((res) => {
          let data = res.data.map((el) => {
            return {
              ...el,
              collaboratorId: el.collaborator ? el.collaborator.id : null,
              storageLocationId: el.storageLocation
                ? el.storageLocation.id
                : null,
              inventoryDate: el.inventoryDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(el.inventoryDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
            };
          });
          if (updateState) {
            commit(InventoryMutations.SET_IS_LOADING_INVENTORY_LIST, false);
            commit(InventoryMutations.SET_INVENTORIES_LIST, data);
          }
          commit(InventoryMutations.SET_REQUEST_STATE_INVENTORY, res);
          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          commit(InventoryMutations.SET_IS_LOADING_INVENTORY_LIST, false);
          commit(InventoryMutations.SET_REQUEST_STATE_INVENTORY, err);
          reject(err);
        });
    });
  },

  async getInventoryById(
    { commit, dispatch },
    {
      inventoryId,
      updateState = true,
    }: { inventoryId: string; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(InventoryMutations.SET_IS_LOADING_INVENTORY, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/Inventory/GetInventoryById`,
          {
            params: {
              inventoryId: inventoryId,
            },
          }
        )
        .then(async (res) => {
          res.data = {
            ...res.data,
            collaboratorId: res.data.collaborator
              ? res.data.collaborator.id
              : null,
            storageLocationId: res.data.storageLocation
              ? res.data.storageLocation.id
              : null,
          };
          if (updateState) {
            commit(InventoryMutations.SET_INVENTORY, res.data);

            if (
              res.data.status == 3 &&
              res.data.path &&
              !res.data.path.includes("null.pdf")
            ) {
              commit(InventoryMutations.SET_INVENTORY_PDF, res.data.path);
            } else {
              commit(InventoryMutations.SET_INVENTORY_PDF, "");
            }

            // On ouvre sur l'apperçu si le document est validé
            if (res.data.status >= 3) {
              dispatch("changeDocumentTabActive", "inventory-pdf");
            }

            // On requete les documents détails
            await dispatch("getInventoryDetailsByInventoryId", {
              inventoryId: inventoryId,
            });

            commit(InventoryMutations.SET_REQUEST_STATE_INVENTORY, res);
            commit(InventoryMutations.SET_IS_LOADING_INVENTORY, false);
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(InventoryMutations.SET_REQUEST_STATE_INVENTORY, err);
          commit(InventoryMutations.SET_IS_LOADING_INVENTORY, false);
          reject(err);
        });
    });
  },

  async getInventoriesArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState)
        commit(InventoryMutations.SET_IS_LOADING_INVENTORY_LIST_ARCHIVED, true);
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/Inventory/GetInventoriesArchived`;
      await axios
        .get(url)
        .then((res) => {
          let data = res.data.map((el) => {
            return {
              ...el,
              collaboratorId: el.collaborator ? el.collaborator.id : null,
              storageLocationId: el.storageLocation
                ? el.storageLocation.id
                : null,
              inventoryDate: el.inventoryDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(el.inventoryDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
            };
          });
          if (updateState) {
            commit(InventoryMutations.SET_INVENTORIES_LIST_ARCHIVED, data);
            commit(
              InventoryMutations.SET_IS_LOADING_INVENTORY_LIST_ARCHIVED,
              false
            );
          }
          commit(InventoryMutations.SET_REQUEST_STATE_INVENTORY, res);
          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            InventoryMutations.SET_IS_LOADING_INVENTORY_LIST_ARCHIVED,
            false
          );
          commit(InventoryMutations.SET_REQUEST_STATE_INVENTORY, err);
          reject(err);
        });
    });
  },

  async getInventoryArchivedById(
    { commit },
    {
      inventoryId,
      updateState = true,
    }: { inventoryId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState)
        commit(InventoryMutations.SET_IS_LOADING_INVENTORY, true);
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/Inventory/GetInventoryArchivedById`,
          {
            params: {
              inventoryId: inventoryId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(InventoryMutations.SET_INVENTORY, res.data);
            commit(InventoryMutations.SET_IS_LOADING_INVENTORY, false);
          }
          commit(InventoryMutations.SET_REQUEST_STATE_INVENTORY, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(InventoryMutations.SET_REQUEST_STATE_INVENTORY, err);
          commit(InventoryMutations.SET_IS_LOADING_INVENTORY, false);
          reject(err);
        });
    });
  },

  // async getLastDocumentReference() {},

  async getInventoryEmpty(
    { commit, rootGetters, dispatch },
    {
      updateState = true,
      storageLocationId,
    }: { updateState: boolean; storageLocationId: number }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (updateState)
        commit(InventoryMutations.SET_IS_CREATING_INVENTORY, true);
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/Inventory/GetInventoryEmpty",
          {
            params: {
              userId: rootGetters.connectedUser.id,
              storageLocationId: storageLocationId,
            },
          }
        )
        .then(async (res) => {
          res.data = {
            ...res.data,
            collaboratorId: res.data.collaborator
              ? res.data.collaborator.id
              : null,
            storageLocationId: res.data.storageLocation
              ? res.data.storageLocation.id
              : null,
          };
          commit(InventoryMutations.SET_INVENTORY, res.data);
          commit(InventoryMutations.SET_INVENTORY_PDF, "");

          // On requete les documents détails
          await dispatch("getInventoryDetailsByInventoryId", {
            inventoryId: res.data.id,
          });

          commit(InventoryMutations.SET_REQUEST_STATE_INVENTORY, res);
          commit(InventoryMutations.SET_IS_CREATING_INVENTORY, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(InventoryMutations.SET_REQUEST_STATE_INVENTORY, err);
          commit(InventoryMutations.SET_IS_CREATING_INVENTORY, false);
          reject(err);
        });
    });
  },

  async generateInventoryPDF({ commit, rootGetters, dispatch }, data) {
    return new Promise(async (resolve, reject) => {
      commit(InventoryMutations.SET_IS_LOADING_INVENTORY_PDF, true);
      await axios
        .post(`${process.env.VUE_APP_PDF_GENERATOR_URL}generate`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(async (res) => {
          // Si le document est finalisé
          if (rootGetters.inventory.status == 3) {
            await dispatch("uploadPathInventory", {
              inventoryId: rootGetters.inventory.id,
              path: res.data,
            });
          }

          commit(InventoryMutations.SET_INVENTORY_PDF, res.data);
          commit(InventoryMutations.SET_REQUEST_STATE_INVENTORY, res);
          commit(InventoryMutations.SET_IS_LOADING_INVENTORY_PDF, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(InventoryMutations.SET_REQUEST_STATE_INVENTORY, err);
          commit(InventoryMutations.SET_IS_LOADING_INVENTORY_PDF, false);
          reject(err);
        });
    });
  },

  async uploadInventory(
    { commit, rootGetters, dispatch },
    { formData, inventoryId }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(FileMutations.SET_IS_CREATING_FILE, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/Inventory/UploadFile?inventoryId=${inventoryId}`,
          formData
        )
        .then(async (res) => {
          if (!rootGetters.inventory.folderId) {
            await dispatch("getInventoryById", {
              inventoryId: inventoryId,
              updateState: false,
            });
          }
          commit(FolderMutations.SET_FOLDER, rootGetters.folder);
          commit(FileMutations.SET_REQUEST_STATE_FILE, res);
          commit(FileMutations.SET_IS_CREATING_FILE, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(FileMutations.SET_REQUEST_STATE_FILE, err);
          commit(FileMutations.SET_IS_CREATING_FILE, false);
          reject(err);
        });
    });
  },

  async uploadFileInventory(
    { commit, rootGetters, dispatch },
    { formData, inventoryId }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(FileMutations.SET_IS_CREATING_FILE, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/Inventory/UploadFile?inventoryId=${inventoryId}`,
          formData
        )
        .then(async (res) => {
          if (!rootGetters.inventory.folderId) {
            await dispatch("getInventoryById", {
              inventoryId: inventoryId,
              updateState: false,
            });
          }
          commit(FolderMutations.SET_FOLDER, rootGetters.folder);
          commit(FileMutations.SET_REQUEST_STATE_FILE, res);
          commit(FileMutations.SET_IS_CREATING_FILE, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(FileMutations.SET_REQUEST_STATE_FILE, err);
          commit(FileMutations.SET_IS_CREATING_FILE, false);
          reject(err);
        });
    });
  },

  async changeInventoryTabActive({ commit }, tabToActivate: string) {
    commit(InventoryMutations.SET_INVENTORY_TAB_ACTIVE, tabToActivate);
  },

  /*
?inventoryId=${inventoryId}&path=${encodeURIComponent(path)}*/

  async uploadPathInventory(
    { commit, rootGetters },
    { inventoryId, path }
  ): Promise<void> {
    commit(InventoryMutations.SET_IS_UPDATING_INVENTORY, true);
    await axios
      .put(
        `${
          process.env.VUE_APP_API_ORISIS
        }Purchase/Inventory/UploadPathDocument?inventoryId=${inventoryId}&path=${encodeURIComponent(
          path
        )}`
      )
      .then((res) => {
        let dataInventory = {
          ...res.data,
          collaboratorId: res.data.collaborator
            ? res.data.collaborator.id
            : null,
          storageLocationId: res.data.storageLocation
            ? res.data.storageLocation.id
            : null,
        };
        rootGetters.inventoriesList.splice(
          rootGetters.inventoriesList.findIndex(
            (elem) => elem.id == inventoryId
          ),
          1,
          dataInventory
        );
        commit(
          InventoryMutations.SET_INVENTORIES_LIST,
          rootGetters.inventoriesList
        );
        commit(InventoryMutations.SET_INVENTORY, dataInventory);
        commit(InventoryMutations.SET_REQUEST_STATE_INVENTORY, res);
        commit(InventoryMutations.SET_IS_UPDATING_INVENTORY, false);
      })
      .catch((err) => {
        console.error(err);
        commit(InventoryMutations.SET_REQUEST_STATE_INVENTORY, err);
        commit(InventoryMutations.SET_IS_UPDATING_INVENTORY, false);
      });
  },

  async createInventory(
    { commit, dispatch },
    { inventory }: { inventory: InventoryRequestDto }
  ): Promise<void> {
    commit(InventoryMutations.SET_IS_CREATING_INVENTORY, true);
    await axios
      .post(
        `${process.env.VUE_APP_API_ORISIS}Purchase/Inventory/CreateInventory`,
        inventory
      )
      .then((res) => {
        dispatch("getInventories", {});
        commit(InventoryMutations.SET_REQUEST_STATE_INVENTORY, res);
        commit(InventoryMutations.SET_IS_CREATING_INVENTORY, false);
      })
      .catch((err) => {
        console.error(err);
        commit(InventoryMutations.SET_REQUEST_STATE_INVENTORY, err);
        commit(InventoryMutations.SET_IS_CREATING_INVENTORY, false);
      });
  },

  async updateInventory(
    { commit, dispatch },
    {
      inventory,
      updateState = true,
    }: { inventory: InventoryRequestDto; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(InventoryMutations.SET_IS_UPDATING_INVENTORY, true);
      }
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/Inventory/UpdateInventory`,
          inventory
        )
        .then((res) => {
          res.data.data = {
            ...res.data.data,
            collaboratorId: res.data.data.collaborator
              ? res.data.data.collaborator.id
              : null,
            storageLocationId: res.data.data.storageLocation
              ? res.data.data.storageLocation.id
              : null,
          };
          commit(InventoryMutations.SET_INVENTORY, res.data.data);

          commit(InventoryMutations.SET_REQUEST_STATE_INVENTORY, res);
          if (updateState) {
            dispatch("getInventories", {});
            commit(InventoryMutations.SET_IS_UPDATING_INVENTORY, false);
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(InventoryMutations.SET_REQUEST_STATE_INVENTORY, err);
          if (updateState) {
            commit(InventoryMutations.SET_IS_UPDATING_INVENTORY, false);
          }
          reject(err);
        });
    });
  },

  async restoreInventories(
    { commit, rootGetters, dispatch },
    { inventoryIds }: { inventoryIds: number[] }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(InventoryMutations.SET_IS_RESTORING_INVENTORY, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/Inventory/Restore`,
          inventoryIds
        )
        .then((res) => {
          dispatch("getInventories", {});
          inventoryIds.forEach((inventoryId: number) => {
            rootGetters.inventoriesListArchived.splice(
              rootGetters.inventoriesListArchived.findIndex(
                (inventory) => inventory.id == inventoryId
              ),
              1
            );
          });
          commit(
            InventoryMutations.SET_INVENTORIES_LIST,
            rootGetters.inventoriesList
          );
          commit(
            InventoryMutations.SET_INVENTORIES_LIST_ARCHIVED,
            rootGetters.inventoriesListArchived
          );
          commit(InventoryMutations.SET_REQUEST_STATE_INVENTORY, res);
          commit(InventoryMutations.SET_IS_RESTORING_INVENTORY, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(InventoryMutations.SET_REQUEST_STATE_INVENTORY, err);
          commit(InventoryMutations.SET_IS_RESTORING_INVENTORY, false);
          reject(err);
        });
    });
  },

  async archiveInventories(
    { commit, rootGetters },
    { inventoryIds }: { inventoryIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(InventoryMutations.SET_IS_ARCHIVING_INVENTORY, true);
      await axios
        .delete(`${process.env.VUE_APP_API_ORISIS}Purchase/Inventory/Archive`, {
          data: inventoryIds,
        })
        .then((res) => {
          inventoryIds.forEach((inventoryId: number) => {
            rootGetters.inventoriesList.splice(
              rootGetters.inventoriesList.findIndex(
                (inventory) => inventory.id == inventoryId
              ),
              1
            );
          });
          commit(
            InventoryMutations.SET_INVENTORIES_LIST,
            rootGetters.inventoriesList
          );
          commit(
            InventoryMutations.SET_INVENTORIES_LIST_ARCHIVED,
            rootGetters.inventoriesListArchived
          );
          commit(InventoryMutations.SET_REQUEST_STATE_INVENTORY, res);
          commit(InventoryMutations.SET_IS_ARCHIVING_INVENTORY, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(InventoryMutations.SET_REQUEST_STATE_INVENTORY, err);
          commit(InventoryMutations.SET_IS_ARCHIVING_INVENTORY, false);
          reject(err);
        });
    });
  },

  async deleteInventories(
    { commit, rootGetters },
    { inventoryIds }: { inventoryIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(InventoryMutations.SET_IS_DELETING_INVENTORY, true);
      await axios
        .delete(`${process.env.VUE_APP_API_ORISIS}Purchase/Inventory/Delete`, {
          data: inventoryIds,
        })
        .then((res) => {
          inventoryIds.forEach((inventoryId: number) => {
            rootGetters.inventoriesListArchived.splice(
              rootGetters.inventoriesListArchived.findIndex(
                (inventory) => inventory.id == inventoryId
              ),
              1
            );
          });

          commit(
            InventoryMutations.SET_INVENTORIES_LIST_ARCHIVED,
            rootGetters.inventoriesListArchived
          );
          commit(InventoryMutations.SET_REQUEST_STATE_INVENTORY, res);
          commit(InventoryMutations.SET_IS_DELETING_INVENTORY, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(InventoryMutations.SET_REQUEST_STATE_INVENTORY, err);
          commit(InventoryMutations.SET_IS_DELETING_INVENTORY, false);
          reject(err);
        });
    });
  },
};
