import { ModuleRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { ModuleMutations } from "./mutations";
import { IModuleState } from "./states";

export const actions: ActionTree<IModuleState, RootState> = {
  getModules(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(ModuleMutations.SET_IS_LOADING_MODULES_LIST, true);
      }
      axios
        .get(process.env.VUE_APP_API_ORISIS + "Account/Module/GetModules")
        .then((res) => {
          for (let i = 0; i < res.data.length; i++) {
            const module = res.data[i];
            if (module.label == "Vente") {
              module.types = [
                {
                  label: "Document Simple",
                  id: 1,
                },
                {
                  label: "Document CEE",
                  id: 2,
                },
                {
                  label: "Document BTP",
                  id: 3,
                },
                {
                  label: "Bail",
                  id: 4,
                },
              ];
            } else {
              module.types = [];
            }
          }
          if (updateState) {
            commit(ModuleMutations.SET_MODULES_LIST, res.data);
            commit(ModuleMutations.SET_IS_LOADING_MODULES_LIST, false);
          }
          commit(ModuleMutations.SET_REQUEST_STATE_MODULE, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ModuleMutations.SET_REQUEST_STATE_MODULE, err);
          commit(ModuleMutations.SET_IS_LOADING_MODULES_LIST, false);
          reject(err);
        });
    });
  },

  getModuleById(
    { commit },
    { moduleId, updateState = true }: { moduleId: string; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(ModuleMutations.SET_IS_LOADING_MODULE, true);
      }
      axios
        .get(process.env.VUE_APP_API_ORISIS + "Account/Module/GetModuleById", {
          params: {
            moduleId: moduleId,
          },
        })
        .then((res) => {
          if (updateState) {
            commit(ModuleMutations.SET_MODULE, res.data);
            commit(ModuleMutations.SET_IS_LOADING_MODULE, false);
          }
          commit(ModuleMutations.SET_REQUEST_STATE_MODULE, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ModuleMutations.SET_REQUEST_STATE_MODULE, err);
          commit(ModuleMutations.SET_IS_LOADING_MODULE, false);
          reject(err);
        });
    });
  },

  createModule(
    { commit, rootGetters },
    { module }: { module: ModuleRequestDto }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(ModuleMutations.SET_IS_LOADING_MODULE, true);
      axios
        .post(
          process.env.VUE_APP_API_ORISIS + "Account/Module/CreateModule",
          module
        )
        .then((res) => {
          rootGetters.modulesList.unshift(res.data.data);
          commit(ModuleMutations.SET_MODULES_LIST, rootGetters.companiesList);
          commit(ModuleMutations.SET_REQUEST_STATE_MODULE, res);
          commit(ModuleMutations.SET_IS_LOADING_MODULE, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ModuleMutations.SET_REQUEST_STATE_MODULE, err);
          commit(ModuleMutations.SET_IS_LOADING_MODULE, false);
          reject(err);
        });
    });
  },

  updateMoodule(
    { commit, rootGetters },
    { module }: { module: ModuleRequestDto }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(ModuleMutations.SET_IS_LOADING_MODULE, true);
      axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Account/Module/UpdateModule",
          module
        )
        .then((res) => {
          rootGetters.modulesList.splice(
            rootGetters.modulesList.findIndex((elem) => elem.id == module.id),
            1,
            res.data.data
          );
          commit(ModuleMutations.SET_MODULES_LIST, rootGetters.modulesList);
          commit(ModuleMutations.SET_REQUEST_STATE_MODULE, res);
          commit(ModuleMutations.SET_IS_LOADING_MODULE, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ModuleMutations.SET_REQUEST_STATE_MODULE, err);
          commit(ModuleMutations.SET_IS_LOADING_MODULE, false);
          reject(err);
        });
    });
  },

  deleteModules(
    { commit, rootGetters },
    { moduleIds }: { moduleIds: number[] }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(ModuleMutations.SET_IS_LOADING_MODULE, true);
      axios
        .delete(process.env.VUE_APP_API_ORISIS + "Account/Module/Delete", {
          data: moduleIds,
        })
        .then((res) => {
          moduleIds.forEach((moduleId: number) => {
            rootGetters.modulesList.splice(
              rootGetters.modulesList.findIndex(
                (module) => module.id == moduleId
              ),
              1
            );
          });
          commit(ModuleMutations.SET_MODULES_LIST, rootGetters.modulesList);
          commit(ModuleMutations.SET_REQUEST_STATE_MODULE, res);
          commit(ModuleMutations.SET_IS_LOADING_MODULE, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ModuleMutations.SET_REQUEST_STATE_MODULE, err);
          commit(ModuleMutations.SET_IS_LOADING_MODULE, false);
          reject(err);
        });
    });
  },
};
