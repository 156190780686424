import store from "@/store";
import {
  getNewIndex,
  calculateTotalOrderFormProviderLine,
} from "@/types/api-orisis/library/DetailOrderFormProviderOperations";
import { CommonLineOrderForm } from "@/types/api-orisis/models/LineModels";
import { ProductProviderDefaultProperties } from "@/types/api-orisis/models/ProductProviderModel";

export function calcNetToPay(priceTotalNet: number, priceTotalTtc: number) {
  let deduction = store.getters.deduction;
  let deductionNumber = 0;
  let result = 0;
  deductionNumber = deducePunctualDeductions(
    deductionNumber,
    deduction.punctualDeductions
  );
  deductionNumber = deduceHoldback(deductionNumber, deduction.holdbackNumber);
  deductionNumber = addProrataAccounts(
    priceTotalNet,
    deductionNumber,
    deduction
  );
  result = priceTotalTtc - deductionNumber;
  //result = Math.round((priceTotalTtc - deductionNumber) * 100) / 100;
  return result;
}

export function calculateSubTotalLine(line: any, newLine: boolean): void {
  let orderFormProviderDetails = store.getters.getOrderFormProviderDetails;
  let subTotal = 0;
  let i = line.indexArray;
  while (i >= 0 && orderFormProviderDetails[i].type != 5) {
    if (
      orderFormProviderDetails[i].type != 1 &&
      orderFormProviderDetails[i].type != 2 &&
      orderFormProviderDetails[i].type != 3 &&
      orderFormProviderDetails[i].type != 4 &&
      orderFormProviderDetails[i].type != 5 &&
      orderFormProviderDetails[i].type != 6
    ) {
      subTotal += calculateTotalOrderFormProviderLine(
        orderFormProviderDetails[i]
      );
    }
    i--;
    if (i < 0) break;
  }
  line.total = line.unitPriceHt = Math.round(subTotal * 100) / 100;
  if (newLine) {
    getNewIndex(store.getters.getOrderFormProviderDetails, line).then(
      async (res: any) => {
        let newObject = CommonLineOrderForm({
          ...line,
          index: res.index,
          type: res.type,
        });
        await store.dispatch("createOrderFormProviderDetail", {
          orderFormProviderDetail: newObject,
        });
      }
    );
  } else {
    store.dispatch("updateOrderFormProviderDetail", {
      orderFormProviderDetail: line,
    });
  }
}

export function calculateSubTotalLineBySection(line: any): void {
  let orderFormProviderDetails = store.getters.getOrderFormProviderDetails;
  let subTotal = 0;
  let i = orderFormProviderDetails.findIndex((el) => el.id == line.id);
  let j = 0;
  let startIndex = orderFormProviderDetails.findIndex(
    (el, index) => el.type == 5 && index < i
  );
  let endIndex =
    line.type == 5
      ? i
      : orderFormProviderDetails.findIndex(
          (el, index) => el.type == 5 && index > i
        );

  if (startIndex < 0) {
    startIndex = j = 0;
  } else {
    j = startIndex + 1;
  }
  if (endIndex >= 0) {
    let end = orderFormProviderDetails[endIndex];

    while (j < endIndex) {
      if (
        orderFormProviderDetails[j].type != 1 &&
        orderFormProviderDetails[j].type != 2 &&
        orderFormProviderDetails[j].type != 3 &&
        orderFormProviderDetails[j].type != 4 &&
        orderFormProviderDetails[j].type != 5 &&
        orderFormProviderDetails[j].type != 6
      ) {
        subTotal += calculateTotalOrderFormProviderLine(
          orderFormProviderDetails[j]
        );
      }
      j++;
    }
    end.total = end.unitPriceHt = Math.round(subTotal * 100) / 100;
    store.dispatch("updateOrderFormProviderDetail", {
      orderFormProviderDetail: end,
    });
  }
}

export function calculateSubTotalLines(update: boolean): void {
  let orderFormProviderDetails = store.getters.getOrderFormProviderDetails;
  let subTotalLines = orderFormProviderDetails.filter((el) => el.type == 5);
  if (subTotalLines.length > 0) {
    for (let j = 0; j < subTotalLines.length; j++) {
      let subTotal = 0;
      const line = subTotalLines[j];
      let i = orderFormProviderDetails.findIndex((el) => el.id == line.id) - 1;
      while (i >= 0 && orderFormProviderDetails[i].type !== 5) {
        if (
          orderFormProviderDetails[i].type != 1 &&
          orderFormProviderDetails[i].type != 2 &&
          orderFormProviderDetails[i].type != 3 &&
          orderFormProviderDetails[i].type != 4 &&
          orderFormProviderDetails[i].type != 5 &&
          orderFormProviderDetails[i].type != 6
        ) {
          subTotal += calculateTotalOrderFormProviderLine(
            orderFormProviderDetails[i]
          );
        }
        i--;
        if (i < 0) break;
      }
      orderFormProviderDetails[
        orderFormProviderDetails.findIndex((el) => el.id == line.id)
      ].quantity = 1;
      orderFormProviderDetails[
        orderFormProviderDetails.findIndex((el) => el.id == line.id)
      ].total = orderFormProviderDetails[
        orderFormProviderDetails.findIndex((el) => el.id == line.id)
      ].unitPriceHt = Math.round(subTotal * 100) / 100;
      if (update) {
        store.dispatch("updateOrderFormProviderDetail", {
          orderFormProviderDetail:
            orderFormProviderDetails[
              orderFormProviderDetails.findIndex((el) => el.id == line.id)
            ],
        });
      }
    }
  }
}

export function calcTotalMarginHt(): number {
  let marginBrutHt = 0;
  let linesForCacul = store.getters.getOrderFormProviderDetails.filter(
    (el) =>
      el.type != 0 &&
      el.type != 1 &&
      el.type != 2 &&
      el.type != 11 &&
      el.type != 12 &&
      el.type != 13 &&
      el.type != 14 &&
      el.type != 15 &&
      el.type != 16 &&
      el.type != 17 &&
      el.type != 18
  );
  for (let i = 0; i < linesForCacul.length; i++) {
    const line = linesForCacul[i];
    if (line.marginRate != null) {
      marginBrutHt = marginBrutHt + line.marginBrutHt * line.quantity;
    }
  }
  return Math.round(marginBrutHt * 100) / 100;
}

export function getReferencielTvaValueById(id: number): number {
  let result = 0;
  store.getters.institutionSettingsActive.referencielTvas.find((item: any) => {
    if (item.referencielTva.id == id) {
      result = item.referencielTva.value;
    }
  });
  return result;
}

function deducePunctualDeductions(
  result: number,
  punctualDeductions: any
): number {
  if (punctualDeductions && punctualDeductions.length > 0) {
    punctualDeductions.forEach((item: any) => {
      result -= parseFloat(item.number);
    });
  } else {
    result = result;
  }
  return result;
}

function deduceHoldback(result: number, holdBackNumber: any): number {
  if (holdBackNumber) {
    result += parseFloat(holdBackNumber);
  } else {
    result = result;
  }
  return result;
}

function addProrataAccounts(
  priceTotalNet: number,
  result: number,
  deductions: any
): number {
  if (
    deductions.prorataAccountPrice != null &&
    deductions.prorataAccountPercent != null &&
    priceTotalNet != 0
  ) {
    result += deductions.prorataAccountPrice;
  } else {
    result = result;
  }
  return result;
}

export function getPricesByPriceHt(
  initialOrderFormProvider,
  newDownPaymentInvoiceForm,
  route,
  deductionInvoicesPrice = null
) {
  let orderFormProvider = JSON.parse(JSON.stringify(initialOrderFormProvider));
  let arr =
    route == "invoices"
      ? orderFormProvider.orderFormProviderDetails
      : store.getters.getOrderFormProviderDetails;
  let result = {
    totalHt: 0,
    totalTtc: 0,
  };
  const res = arr.reduce((acc: any, obj: any) => {
    let findIndex = acc.findIndex(
      (el: any) => el.referencielTvaId === obj.referencielTvaId
    );
    if (
      obj.type != 0 &&
      obj.type != 1 &&
      obj.type != 2 &&
      obj.type != 11 &&
      obj.type != 12 &&
      obj.type != 13 &&
      obj.type != 14 &&
      obj.type != 15 &&
      obj.type != 16 &&
      obj.type != 17 &&
      obj.type != 18
    ) {
      let priceHt = getPriceHt(obj, orderFormProvider, deductionInvoicesPrice);
      let priceTtc =
        priceHt * (1 + getReferencielTvaValueById(obj.referencielTvaId) / 100);
      let coeff = priceHt / orderFormProvider.totalHt;

      let advancementHt = newDownPaymentInvoiceForm.value * coeff;
      let advancementTtc =
        advancementHt *
        (1 + getReferencielTvaValueById(obj.referencielTvaId) / 100);

      if (findIndex < 0) {
        acc.push({
          priceHt: priceHt,
          priceTtc: priceTtc,
          coeff: coeff,
          advancementHt: advancementHt,
          advancementTtc: advancementTtc,
          referencielTvaId: obj.referencielTvaId,
        });
      } else {
        acc[findIndex].priceHt += priceHt;
        acc[findIndex].priceTtc += priceTtc;
        acc[findIndex].coeff =
          acc[findIndex].priceHt / orderFormProvider.totalHt;
        acc[findIndex].advancementHt =
          newDownPaymentInvoiceForm.value * acc[findIndex].coeff;
        acc[findIndex].advancementTtc =
          acc[findIndex].advancementHt *
          (1 + getReferencielTvaValueById(obj.referencielTvaId) / 100);
      }
    }
    return acc;
  }, []);
  res.forEach((element) => {
    result.totalHt =
      Math.round((result.totalHt + element.advancementHt) * 100) / 100;
    result.totalTtc =
      Math.round((result.totalTtc + element.advancementTtc) * 100) / 100;
  });
  return { tvaLine: res, totals: result };
}

export function getPricesByPriceTtc(
  initialOrderFormProvider,
  newDownPaymentInvoiceForm,
  route,
  deductionInvoicesPrice = null
) {
  let orderFormProvider = JSON.parse(JSON.stringify(initialOrderFormProvider));
  let arr =
    route == "invoices"
      ? orderFormProvider.orderFormProviderDetails
      : store.getters.getOrderFormProviderDetails;
  let result = {
    totalHt: 0,
    totalTtc: 0,
  };

  const res = arr.reduce((acc: any, obj: any) => {
    let findIndex = acc.findIndex(
      (el: any) => el.referencielTvaId === obj.referencielTvaId
    );
    if (
      obj.type != 0 &&
      obj.type != 1 &&
      obj.type != 2 &&
      obj.type != 11 &&
      obj.type != 12 &&
      obj.type != 13 &&
      obj.type != 14 &&
      obj.type != 15 &&
      obj.type != 16 &&
      obj.type != 17 &&
      obj.type != 18
    ) {
      let priceHt = getPriceHt(obj, orderFormProvider, deductionInvoicesPrice);
      let priceTtc =
        priceHt * (1 + getReferencielTvaValueById(obj.referencielTvaId) / 100);
      let coeff = priceTtc / orderFormProvider.totalTtc;
      let advancementTtc = newDownPaymentInvoiceForm.value * coeff;
      let advancementHt =
        (advancementTtc * 100) /
        (100 + getReferencielTvaValueById(obj.referencielTvaId));

      if (findIndex < 0) {
        acc.push({
          priceHt: priceHt,
          priceTtc: priceTtc,
          coeff: coeff,
          advancementHt: advancementHt,
          advancementTtc: advancementTtc,
          referencielTvaId: obj.referencielTvaId,
        });
      } else {
        acc[findIndex].priceHt += priceHt;
        acc[findIndex].priceTtc += priceTtc;
        acc[findIndex].coeff =
          acc[findIndex].priceTtc / orderFormProvider.totalTtc;
        acc[findIndex].advancementTtc =
          newDownPaymentInvoiceForm.value * acc[findIndex].coeff;
        acc[findIndex].advancementHt =
          (acc[findIndex].advancementTtc * 100) /
          (100 + getReferencielTvaValueById(obj.referencielTvaId));
      }
    }
    return acc;
  }, []);
  res.forEach((element) => {
    result.totalHt =
      Math.round((result.totalHt + element.advancementHt) * 100) / 100;
    result.totalTtc =
      Math.round((result.totalTtc + element.advancementTtc) * 100) / 100;
  });
  return { tvaLine: res, totals: result };
}

export function getPricesByPourcentagePriceTtc(
  initialOrderFormProvider,
  newDownPaymentInvoiceForm,
  route,
  deductionInvoicesPrice = null
) {
  let orderFormProvider = JSON.parse(JSON.stringify(initialOrderFormProvider));
  let totalHt: number = 0;
  let arr =
    route == "invoices"
      ? orderFormProvider.orderFormProviderDetails
      : store.getters.getOrderFormProviderDetails;
  let result = {
    totalHt: 0,
    totalTtc: 0,
  };

  const res = arr.reduce((acc: any, obj: any) => {
    let findIndex = acc.findIndex(
      (el: any) => el.referencielTvaId === obj.referencielTvaId
    );
    if (
      obj.type != 0 &&
      obj.type != 1 &&
      obj.type != 2 &&
      obj.type != 11 &&
      obj.type != 12 &&
      obj.type != 13 &&
      obj.type != 14 &&
      obj.type != 15 &&
      obj.type != 16 &&
      obj.type != 17 &&
      obj.type != 18
    ) {
      let priceHt = getPriceHt(obj, orderFormProvider, deductionInvoicesPrice);
      let priceTtc =
        priceHt * (1 + getReferencielTvaValueById(obj.referencielTvaId) / 100);
      let coeff = priceTtc / Number(orderFormProvider.totalTtc);
      let value =
        route == "credit"
          ? 100 - newDownPaymentInvoiceForm.value
          : newDownPaymentInvoiceForm.value;
      let advancementHt =
        newDownPaymentInvoiceForm.value == 0 ? 0 : priceHt * (value / 100);
      let advancementTtc =
        advancementHt *
        (1 + getReferencielTvaValueById(obj.referencielTvaId) / 100);
      if (findIndex < 0) {
        acc.push({
          priceHt: priceHt,
          priceTtc: priceTtc,
          coeff: coeff,
          advancementHt: advancementHt,
          advancementTtc: advancementTtc,
          referencielTvaId: obj.referencielTvaId,
        });
      } else {
        acc[findIndex].priceHt += priceHt;
        acc[findIndex].priceTtc += priceTtc;
        acc[findIndex].coeff =
          acc[findIndex].priceTtc / orderFormProvider.totalTtc;
        acc[findIndex].advancementHt =
          newDownPaymentInvoiceForm.value == 0
            ? 0
            : acc[findIndex].priceHt * (newDownPaymentInvoiceForm.value / 100);
        acc[findIndex].advancementTtc =
          acc[findIndex].advancementHt *
          (1 + getReferencielTvaValueById(obj.referencielTvaId) / 100);
      }
    }
    return acc;
  }, []);
  res.forEach((element) => {
    result.totalHt =
      Math.round((result.totalHt + element.advancementHt) * 100) / 100;
    result.totalTtc =
      Math.round((result.totalTtc + element.advancementTtc) * 100) / 100;
  });
  return { tvaLine: res, totals: result };
}

export function getPricesByPourcentagePriceTtcByCredit(
  initialOrderFormProvider,
  newDownPaymentInvoiceForm,
  route,
  deductionInvoicesPrice = null
) {
  let orderFormProvider = JSON.parse(JSON.stringify(initialOrderFormProvider));
  let totalHt: number = 0;
  let arr =
    route == "invoices"
      ? orderFormProvider.orderFormProviderDetails
      : store.getters.getOrderFormProviderDetails;
  let result = {
    totalHt: 0,
    totalTtc: 0,
  };

  const res = arr.reduce((acc: any, obj: any) => {
    let findIndex = acc.findIndex(
      (el: any) => el.referencielTvaId === obj.referencielTvaId
    );
    if (
      obj.type != 0 &&
      obj.type != 1 &&
      obj.type != 2 &&
      obj.type != 11 &&
      obj.type != 12 &&
      obj.type != 13
    ) {
      let priceHt = getPriceHt(obj, orderFormProvider, deductionInvoicesPrice);
      let priceTtc =
        priceHt * (1 + getReferencielTvaValueById(obj.referencielTvaId) / 100);
      let coeff = priceTtc / Number(orderFormProvider.totalTtc);
      let value =
        route == "credit"
          ? 100 - newDownPaymentInvoiceForm.value
          : newDownPaymentInvoiceForm.value;
      let advancementHt =
        newDownPaymentInvoiceForm.value == 0 ? 0 : priceHt * (value / 100);
      let advancementTtc =
        advancementHt *
        (1 + getReferencielTvaValueById(obj.referencielTvaId) / 100);
      if (findIndex < 0) {
        acc.push({
          priceHt: priceHt,
          priceTtc: priceTtc,
          coeff: coeff,
          advancementHt: advancementHt,
          advancementTtc: advancementTtc,
          referencielTvaId: obj.referencielTvaId,
        });
      } else {
        acc[findIndex].priceHt += priceHt;
        acc[findIndex].priceTtc += priceTtc;
        acc[findIndex].coseff =
          acc[findIndex].priceTtc / orderFormProvider.totalTtc;
        acc[findIndex].advancementHt =
          newDownPaymentInvoiceForm.value == 0
            ? 0
            : acc[findIndex].priceHt * (newDownPaymentInvoiceForm.value / 100);
        acc[findIndex].advancementTtc =
          acc[findIndex].advancementHt *
          (1 + getReferencielTvaValueById(obj.referencielTvaId) / 100);
      }
    }
    return acc;
  }, []);
  if (deductionInvoicesPrice) {
    totalHt =
      Math.round(
        res.reduce((acc, obj) => {
          return (acc += obj.priceHt);
        }, 0) * 100
      ) / 100;
  }
  res.forEach((element) => {
    if (deductionInvoicesPrice) {
      let coeff = totalHt == 0 ? 1 : element.priceHt / totalHt;
      element.advancementHt =
        element.advancementHt - coeff * deductionInvoicesPrice.totalHt;
    }
    result.totalHt =
      Math.round((result.totalHt + element.advancementHt) * 100) / 100;
    result.totalTtc =
      Math.round((result.totalTtc + element.advancementTtc) * 100) / 100;
  });
  if (deductionInvoicesPrice) {
    result.totalTtc -= deductionInvoicesPrice.totalTtc;
  }
  return { tvaLine: res, totals: result };
}

function getPriceHt(obj, orderFormProvider, deductionInvoicesPrice = null) {
  let priceHt =
    obj.unitPriceHt * obj.quantity * 1 * ((100 - obj.discount) / 100);

  if (orderFormProvider.discountGlobalType == 0) {
    let discountGlobalNumberHt =
      priceHt *
      ((orderFormProvider.discountGlobalNumber
        ? orderFormProvider.discountGlobalNumber
        : 0) /
        100);
    // let discount = Math.round(((Math.round((Number(discountGlobalNumberHt) + (deductionInvoicesPrice ? Number(deductionInvoicesPrice.totalHt) : 0)) * 100) / 100)) * 100) / 100;
    let discount = Number(discountGlobalNumberHt);
    return priceHt - discount;
    // return Math.round((priceHt * (1 - ((orderFormProvider.discountGlobalNumber ? orderFormProvider.discountGlobalNumber : 0) / 100))) * 100) / 100;
  } else if (orderFormProvider.discountGlobalType == 1) {
    let coeff =
      priceHt /
      (Number(orderFormProvider.totalHt) +
        Number(orderFormProvider.discountGlobalNumber));
    let discount = Number(orderFormProvider.discountGlobalNumber) * coeff;
    // let coeff = priceHt / (Number(orderFormProvider.totalHt) + Number(orderFormProvider.discountGlobalNumber) + (deductionInvoicesPrice ? Number(deductionInvoicesPrice.totalHt) : 0));
    // let discount = Math.round(((Math.round((Number(orderFormProvider.discountGlobalNumber) + (deductionInvoicesPrice ? Number(deductionInvoicesPrice.totalHt) : 0)) * 100) / 100) * coeff) * 100) / 100;
    return priceHt - discount;
  }
  return priceHt;
}

export function getTotalDeduction() {
  let result = 0;
  let deduction = store.getters.deduction;
  let holdBackNumber = deduction.holdbackNumber
    ? Number(deduction.holdbackNumber)
    : 0;
  let prorataAccountPrice = deduction.prorataAccountPrice
    ? Number(deduction.prorataAccountPrice)
    : 0;
  let punctualDeductionPrice = deduction.punctualDeductions.reduce(
    (acc, obj) => {
      return (acc = Math.round((acc + obj.number) * 100) / 100);
    },
    0
  );
  return (result =
    holdBackNumber + prorataAccountPrice + punctualDeductionPrice);
}

export function executeMultipleAsyncFunctions(list) {
  return new Promise<void>((resolve, reject) => {
    const promises = [];
    let productProviderList = [];
    for (let i = 0; i < list.length; i++) {
      const el = list[i];

      productProviderList = productProviderList.concat(
        store.getters.productProvidersList.filter((productProvider) =>
          productProvider.reference?.includes("AUTO-")
        )
      );

      let referenceProductProviderList = [];
      if (productProviderList.length > 0) {
        for (let j = 0; j < productProviderList.length; j++) {
          const element = productProviderList[j].reference;
          const match = element?.match(/^AUTO-(\d{5})$/);
          match ? referenceProductProviderList.push(parseInt(match[1])) : null;
        }
        let largestNumberExist = Math.max(...referenceProductProviderList);
        el.reference = `AUTO-${(largestNumberExist + 1)
          .toString()
          .padStart(5, "0")}`;
      } else {
        el.reference = "AUTO-00001";
      }
      let productProviderdefault = ProductProviderDefaultProperties({
        reference: el.reference,
        netPrice: el.unitPriceHt,
        companyId: store.getters.orderFormProvider.companyProviderId,
        productType: el.type == 7 ? 0 : el.type == 8 ? 5 : el.type == 9 ? 2 : 6,
        description: el.description,
        unitId: el.unitId,
      });

      productProviderList.push(productProviderdefault);

      let productProvider = productProviderdefault;

      promises.push(asyncFunction(productProvider, el));
    }

    Promise.all(promises)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
}

function asyncFunction(productProvider, el) {
  return new Promise<void>((resolve, reject) => {
    store
      .dispatch("createProductProvider", {
        productProvider: productProvider,
        reload: false,
      })
      .then((pro) => {
        el.productProviderId = pro.data.data.id;
        store
          .dispatch("updateOrderFormProviderDetail", {
            orderFormProviderDetail: el,
          })
          .then(() => {
            resolve();
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
}

export function checkMandatoryInformation() {
  return new Promise((resolve, reject) => {
    if (
      store.getters.orderFormProvider.companyProviderId == null ||
      store.getters.orderFormProvider.companyProviderId == 0
    ) {
      reject("Veuillez sélectionner un fournisseur");
    } else if (
      store.getters.orderFormProvider.companyCustomerId &&
      (store.getters.orderFormProvider.affairId == null ||
        store.getters.orderFormProvider.affairId == 0)
    ) {
      reject("Veuillez sélectionner une affaire");
    } else {
      resolve(true);
    }
    // } else if (
    //   store.getters.getOrderFormProviderDetails.filter(
    //     (detail) =>
    //       (detail.type == 7 ||
    //         detail.type == 8 ||
    //         detail.type == 9 ||
    //         detail.type == 10) &&
    //       (detail.reference == null || detail.reference == "")
    //   ).length > 0
    // ) {
    //   reject(
    //     "Veuillez completer les références des lignes de commande fournisseur"
    //   );
    // } else {
  });
}
