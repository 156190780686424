import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { WasteTypeMutations } from "./mutations";
import { IWasteTypeState } from "./states";

export const actions: ActionTree<IWasteTypeState, RootState> = {
  getWasteTypes({ commit, rootGetters }, { updateState = true } : {updateState: boolean}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(WasteTypeMutations.SET_IS_LOADING_WASTE_TYPE, true);
      }
      axios
      .get(process.env.VUE_APP_API_ORISIS + "Sale/WasteType/GetWasteTypes")
      .then((res) => {
        if (updateState) {
          commit(WasteTypeMutations.SET_WASTE_TYPES_LIST, res.data);
          commit(WasteTypeMutations.SET_IS_LOADING_WASTE_TYPE, false);
      }
        commit(WasteTypeMutations.SET_REQUEST_STATE_WASTE_TYPE, res);
        resolve(res.data);
      })
      .catch((err) => {
        console.error(err);
        commit(WasteTypeMutations.SET_REQUEST_STATE_WASTE_TYPE, err);
        if (updateState) {
          commit(WasteTypeMutations.SET_IS_LOADING_WASTE_TYPE, false);
        }
        reject(err);
      });
    })
  },

  getWasteTypeById(
    { commit, rootGetters },
    { wasteTypeId, updateState = true}: { wasteTypeId: number, updateState: boolean}
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(WasteTypeMutations.SET_IS_LOADING_WASTE_TYPE, true);
      }
      axios
      .get(
        process.env.VUE_APP_API_ORISIS + "Sale/WasteType/GetWasteTypeById",
        {
          params: {
            wasteTypeId: wasteTypeId,
          },
        }
      )
      .then((res) => {
        if (updateState) {
          commit(WasteTypeMutations.SET_WASTE_TYPE, res.data);
          commit(WasteTypeMutations.SET_IS_LOADING_WASTE_TYPE, false);
        }
        commit(WasteTypeMutations.SET_REQUEST_STATE_WASTE_TYPE, res);
        resolve(res.data);
      })
      .catch((err) => {
        console.error(err);
        commit(WasteTypeMutations.SET_REQUEST_STATE_WASTE_TYPE, err);
        if (updateState) {
          commit(WasteTypeMutations.SET_IS_LOADING_WASTE_TYPE, false);
        }
        reject(err);
      });
    });
  },
};
