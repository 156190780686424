import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import RootState from "@/store/modules/rootState"
import { IWasteTypeState } from "./states";
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

export const state: IWasteTypeState = {
    wasteTypesList                  : Array<any>(),
    wasteTypesListArchived          : Array<any>(),
    wasteType                       : <any>{},
    isLoadingWasteTypesList         : false,
    isLoadingWasteTypesListArchived : false,
    isLoadingWasteType              : false,
    isCreatingWasteType             : false,
    isUpdatingWasteType             : false,
    isDeletingWasteType             : false,
    requestStateWasteType           : <IRequestState>{},
    isArchivingWasteType            : false,
}

export const WasteTypeModule: Module<IWasteTypeState, RootState> = {
  state, 
  getters,
  mutations,
  actions
}