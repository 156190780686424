import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IOrderFormOptionState } from "./states";
import { OrderFormOptionRequestDto } from "Api";

export const getters: GetterTree<IOrderFormOptionState, RootState> = {
  orderFormOption(state): OrderFormOptionRequestDto {
    return state.orderFormOption;
  },
  isLoadingOrderFormOption(state): boolean {
    return state.isLoadingOrderFormOption;
  },
  requestStateOrderFormOption(state): any {
    return state.requestStateOrderFormOption;
  },
};
