import { InstitutionSettingsModel } from "@/types/api-orisis/models/InstitutionSettingsModel";
import { Module } from "vuex";
import RootState from "@/store/modules/rootState";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { IInstitutionSettingsStates } from "./states";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export const state: IInstitutionSettingsStates = {
  isOpendocumentSettingsPanel: false,
  institutionSettings: InstitutionSettingsModel,
  institutionSettingsActive: InstitutionSettingsModel,
  isLoadingInstitutionSettings: false,
  isCreatingInstitutionSettings: false,
  isUpdatingInstitutionSettings: false,
  tabSelected: "Préférences",
  requestStateInstitutionSettings: <IRequestState>{},
  referencielTvasInstitutionSettingsList: [],
};

export const InstitutionSettingsModule: Module<
  IInstitutionSettingsStates,
  RootState
> = {
  state,
  getters,
  mutations,
  actions,
};
