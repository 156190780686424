import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IQuoteOptionDefaultState } from "./states";
import { QuoteOptionRequestDto } from "@/../Api";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export const getters: GetterTree<IQuoteOptionDefaultState, RootState> = {
  quoteOptionDefault: (state): QuoteOptionRequestDto =>
    state.quoteOptionDefault,
  quoteOptionDefaultForm: (state): QuoteOptionRequestDto =>
    state.quoteOptionDefaultForm,
  isLoadingQuoteOptionDefault: (state): boolean =>
    state.isLoadingQuoteOptionDefault,
  requestStateQuoteOptionDefault: (state): IRequestState =>
    state.requestStateQuoteOptionDefault,
};
