import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { ActivityRequestDto } from "@/../Api";
import { MutationTree } from "vuex";
import { IActivityState } from "./states";

export enum ActivityMutations {
  SET_ACTIVITIES_LIST = "SET_ACTIVITIES_LIST",
  SET_ACTIVITIES_LIST_ARCHIVED = "SET_ACTIVITIES_LIST_ARCHIVED",
  SET_ACTIVITIES_LIST_BY_COLLABORATOR = "SET_ACTIVITIES_LIST_BY_COLLABORATOR",
  SET_ACTIVITIES_LIST_ARCHIVED_BY_COLLABORATOR = "SET_ACTIVITIES_LIST_ARCHIVED_BY_COLLABORATOR",
  SET_ACTIVITIES_LIST_BY_COMPANY = "SET_ACTIVITIES_LIST_BY_COMPANY",
  SET_ACTIVITIES_LIST_ARCHIVED_BY_COMPANY = "SET_ACTIVITIES_LIST_ARCHIVED_BY_COMPANY",
  SET_ACTIVITY = "SET_ACTIVITY",
  SET_REMINDERS_LIST = "SET_REMINDERS_LIST",
  SET_REMINDERS_LIST_BY_COLLABORATOR = "SET_REMINDERS_LIST_BY_COLLABORATOR",
  SET_REMINDERS_LIST_BY_COMPANY = "SET_REMINDERS_LIST_BY_COMPANY",

  //FILTERS
  SET_ACTIVITY_FILTERS = "SET_ACTIVITY_FILTERS",
  SET_ACTIVITY_FILTERS_BY_COLLABORATOR = "SET_ACTIVITY_FILTERS_BY_COLLABORATOR",

  // COMMON
  SET_IS_LOADING_ACTIVITY_LIST = "SET_IS_LOADING_ACTIVITY_LIST",
  SET_IS_LOADING_ACTIVITY_LIST_ARCHIVED = "SET_IS_LOADING_ACTIVITY_LIST_ARCHIVED",
  SET_IS_LOADING_ACTIVITY_LIST_BY_COLLABORATOR = "SET_IS_LOADING_ACTIVITY_LIST_BY_COLLABORATOR",
  SET_IS_LOADING_ACTIVITY_LIST_ARCHIVED_BY_COLLABORATOR = "SET_IS_LOADING_ACTIVITY_LIST_ARCHIVED_BY_COLLABORATOR",
  SET_IS_LOADING_ACTIVITY_LIST_BY_COMPANY = "SET_IS_LOADING_ACTIVITY_LIST_BY_COMPANY",
  SET_IS_LOADING_ACTIVITY_LIST_ARCHIVED_BY_COMPANY = "SET_IS_LOADING_ACTIVITY_LIST_ARCHIVED_BY_COMPANY",
  SET_IS_LOADING_REMINDERS_LIST = "SET_IS_LOADING_REMINDERS_LIST",
  SET_IS_LOADING_REMINDERS_LIST_BY_COLLABORATOR = "SET_IS_LOADING_REMINDERS_LIST_BY_COLLABORATOR",
  SET_IS_LOADING_REMINDERS_LIST_BY_COMPANY = "SET_IS_LOADING_REMINDERS_LIST_BY_COMPANY",
  SET_IS_LOADING_ACTIVITY = "SET_IS_LOADING_ACTIVITY",
  SET_IS_CREATING_ACTIVITY = "SET_IS_CREATING_ACTIVITY",
  SET_IS_UPDATING_ACTIVITY = "SET_IS_UPDATING_ACTIVITY",
  SET_IS_DELETING_ACTIVITY = "SET_IS_DELETING_ACTIVITY",
  SET_IS_ARCHIVING_ACTIVITY = "SET_IS_ARCHIVING_ACTIVITY",
  SET_REQUEST_STATE_ACTIVITY = "SET_REQUEST_STATE_ACTIVITY",
}

export const mutations: MutationTree<IActivityState> = {
  [ActivityMutations.SET_ACTIVITIES_LIST]: (state, payload: any[]) => {
    state.activitiesList = payload.map((el) => {
      return {
        ...el,
        activityResultTypeId:
          el.activityResultType && el.activityResultType.id
            ? el.activityResultType.id
            : null,
        activityChanelTypeId:
          el.activityChanelType && el.activityChanelType.id
            ? el.activityChanelType.id
            : null,
        activitySourceTypeId:
          el.activitySourceType && el.activitySourceType.id
            ? el.activitySourceType.id
            : null,
      };
    });
  },
  [ActivityMutations.SET_ACTIVITIES_LIST_ARCHIVED]: (state, payload: any[]) => {
    state.activitiesListArchived = payload.map((el) => {
      return {
        ...el,
        activityResultTypeId:
          el.activityResultType && el.activityResultType.id
            ? el.activityResultType.id
            : null,
        activityChanelTypeId:
          el.activityChanelType && el.activityChanelType.id
            ? el.activityChanelType.id
            : null,
        activitySourceTypeId:
          el.activitySourceType && el.activitySourceType.id
            ? el.activitySourceType.id
            : null,
      };
    });
  },
  [ActivityMutations.SET_ACTIVITIES_LIST_BY_COLLABORATOR]: (
    state,
    payload: any[]
  ) => {
    state.activitiesListByCollaborator = payload.map((el) => {
      return {
        ...el,
        activityResultTypeId:
          el.activityResultType && el.activityResultType.id
            ? el.activityResultType.id
            : null,
        activityChanelTypeId:
          el.activityChanelType && el.activityChanelType.id
            ? el.activityChanelType.id
            : null,
        activitySourceTypeId:
          el.activitySourceType && el.activitySourceType.id
            ? el.activitySourceType.id
            : null,
      };
    });
  },
  [ActivityMutations.SET_ACTIVITIES_LIST_ARCHIVED_BY_COLLABORATOR]: (
    state,
    payload: any[]
  ) => {
    state.activitiesListArchivedByCollaborator = payload.map((el) => {
      return {
        ...el,
        activityResultTypeId:
          el.activityResultType && el.activityResultType.id
            ? el.activityResultType.id
            : null,
        activityChanelTypeId:
          el.activityChanelType && el.activityChanelType.id
            ? el.activityChanelType.id
            : null,
        activitySourceTypeId:
          el.activitySourceType && el.activitySourceType.id
            ? el.activitySourceType.id
            : null,
      };
    });
  },
  [ActivityMutations.SET_ACTIVITIES_LIST_BY_COMPANY]: (
    state,
    payload: any[]
  ) => {
    state.activitiesListByCompany = payload.map((el) => {
      return {
        ...el,
        activityResultTypeId:
          el.activityResultType && el.activityResultType.id
            ? el.activityResultType.id
            : null,
        activityChanelTypeId:
          el.activityChanelType && el.activityChanelType.id
            ? el.activityChanelType.id
            : null,
        activitySourceTypeId:
          el.activitySourceType && el.activitySourceType.id
            ? el.activitySourceType.id
            : null,
      };
    });
  },
  [ActivityMutations.SET_ACTIVITIES_LIST_ARCHIVED_BY_COMPANY]: (
    state,
    payload: any[]
  ) => {
    state.activitiesListArchivedByCompany = payload.map((el) => {
      return {
        ...el,
        activityResultTypeId:
          el.activityResultType && el.activityResultType.id
            ? el.activityResultType.id
            : null,
        activityChanelTypeId:
          el.activityChanelType && el.activityChanelType.id
            ? el.activityChanelType.id
            : null,
        activitySourceTypeId:
          el.activitySourceType && el.activitySourceType.id
            ? el.activitySourceType.id
            : null,
      };
    });
  },
  [ActivityMutations.SET_ACTIVITY]: (state, payload: ActivityRequestDto) => {
    state.activity = payload;
  },
  [ActivityMutations.SET_REMINDERS_LIST]: (state, payload: any[]) => {
    state.remindersList = payload.map((el) => {
      return {
        ...el,
        activityResultTypeId:
          el.activityResultType && el.activityResultType.id
            ? el.activityResultType.id
            : null,
        activityChanelTypeId:
          el.activityChanelType && el.activityChanelType.id
            ? el.activityChanelType.id
            : null,
        activitySourceTypeId:
          el.activitySourceType && el.activitySourceType.id
            ? el.activitySourceType.id
            : null,
      };
    });
  },
  [ActivityMutations.SET_REMINDERS_LIST_BY_COLLABORATOR]: (
    state,
    payload: any[]
  ) => {
    state.remindersListByCollaborator = payload.map((el) => {
      return {
        ...el,
        activityResultTypeId:
          el.activityResultType && el.activityResultType.id
            ? el.activityResultType.id
            : null,
        activityChanelTypeId:
          el.activityChanelType && el.activityChanelType.id
            ? el.activityChanelType.id
            : null,
        activitySourceTypeId:
          el.activitySourceType && el.activitySourceType.id
            ? el.activitySourceType.id
            : null,
      };
    });
  },
  [ActivityMutations.SET_REMINDERS_LIST_BY_COMPANY]: (
    state,
    payload: any[]
  ) => {
    state.remindersListByCompany = payload.map((el) => {
      return {
        ...el,
        activityResultTypeId:
          el.activityResultType && el.activityResultType.id
            ? el.activityResultType.id
            : null,
        activityChanelTypeId:
          el.activityChanelType && el.activityChanelType.id
            ? el.activityChanelType.id
            : null,
        activitySourceTypeId:
          el.activitySourceType && el.activitySourceType.id
            ? el.activitySourceType.id
            : null,
      };
    });
  },

  //FILTERS
  [ActivityMutations.SET_ACTIVITY_FILTERS]: (state, payload: any) => {
    state.activityFilters = payload;
  },
  [ActivityMutations.SET_ACTIVITY_FILTERS_BY_COLLABORATOR]: (
    state,
    payload: any
  ) => {
    state.activityFiltersByCollaborator = payload;
  },

  //COMMON
  [ActivityMutations.SET_IS_LOADING_ACTIVITY_LIST_ARCHIVED]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingActivitiesListArchived = payload;
  },
  [ActivityMutations.SET_IS_LOADING_ACTIVITY_LIST]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingActivitiesList = payload;
  },
  [ActivityMutations.SET_IS_LOADING_ACTIVITY_LIST_BY_COLLABORATOR]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingActivitiesListByCollaborator = payload;
  },
  [ActivityMutations.SET_IS_LOADING_ACTIVITY_LIST_BY_COMPANY]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingActivitiesListByCompany = payload;
  },
  [ActivityMutations.SET_IS_LOADING_REMINDERS_LIST]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingRemindersList = payload;
  },
  [ActivityMutations.SET_IS_LOADING_REMINDERS_LIST_BY_COLLABORATOR]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingRemindersListByCollaborator = payload;
  },
  [ActivityMutations.SET_IS_LOADING_REMINDERS_LIST_BY_COMPANY]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingRemindersListByCompany = payload;
  },
  [ActivityMutations.SET_IS_LOADING_ACTIVITY]: (state, payload: boolean) => {
    state.isLoadingActivity = payload;
  },
  [ActivityMutations.SET_IS_CREATING_ACTIVITY]: (state, payload: boolean) => {
    state.isCreatingActivity = payload;
  },
  [ActivityMutations.SET_IS_UPDATING_ACTIVITY]: (state, payload: boolean) => {
    state.isUpdatingActivity = payload;
  },
  [ActivityMutations.SET_IS_DELETING_ACTIVITY]: (state, payload: boolean) => {
    state.isDeletingActivity = payload;
  },
  [ActivityMutations.SET_IS_ARCHIVING_ACTIVITY]: (state, payload: boolean) => {
    state.isArchivingActivity = payload;
  },
  [ActivityMutations.SET_REQUEST_STATE_ACTIVITY]: (
    state,
    payload: IRequestState
  ) => {
    state.requestStateActivity = payload;
  },
};
