import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IReceiptFormOptionDefaultState } from "./states";
import { ReceiptFormOptionRequestDto } from "@/../Api";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export const getters: GetterTree<IReceiptFormOptionDefaultState, RootState> = {
  receiptFormOptionDefault: (state): ReceiptFormOptionRequestDto =>
    state.receiptFormOptionDefault,
  receiptFormOptionDefaultForm: (state): ReceiptFormOptionRequestDto =>
    state.receiptFormOptionDefaultForm,
  isLoadingReceiptFormOptionDefault: (state): boolean =>
    state.isLoadingReceiptFormOptionDefault,
  requestStateReceiptFormOptionDefault: (state): IRequestState =>
    state.requestStateReceiptFormOptionDefault,
};
