export function DeliveryFormDetailDefaultProperties({
  index = 0,
  id = 0,
  reference = "",
  description = "",
  productId = null,
  analyticSettingId = null,
  type = 0,
  unitId = null,
  referencielTvaId = null,
  outPutQuantity = 0,
  deliveryFormId = 0,
  unitPriceHt = 0,
  totalHt = 0,
}) {
  return {
    id: id,
    index: index,
    reference: reference,
    description: description,
    outPutQuantity: outPutQuantity,
    deliveryFormId: deliveryFormId,
    unitId: unitId,
    productId: productId,
    type: type,
    unitPriceHt: unitPriceHt,
    referencielTvaId: referencielTvaId,
    analyticSettingId: analyticSettingId,
  };
}
