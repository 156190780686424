import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IInvoiceOptionState } from "./states";
import { InvoiceOptionRequestDto } from "Api";

export const getters: GetterTree<IInvoiceOptionState, RootState> = {
  invoiceOption(state): InvoiceOptionRequestDto {
    return state.invoiceOption;
  },
  isLoadingInvoiceOption(state): boolean {
    return state.isLoadingInvoiceOption;
  },
  requestStateInvoiceOption(state): any {
    return state.requestStateInvoiceOption;
  },
};
