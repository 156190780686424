import { MutationTree } from "vuex";
import { IReceiptFormOptionDefaultState } from "./states";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { ReceiptFormOptionRequestDto } from "Api";

export const enum ReceiptFormOptionDefaultMutations {
  SET_RECEIPT_FORM_OPTION_DEFAULT = "SET_RECEIPT_FORM_OPTION_DEFAULT",
  SET_RECEIPT_FORM_OPTION_DEFAULT_FORM = "SET_RECEIPT_FORM_OPTION_DEFAULT_FORM",
  SET_IS_LOADING_RECEIPT_FORM_OPTION_DEFAULT = "SET_IS_LOADING_RECEIPT_FORM_OPTION_DEFAULT",
  SET_REQUEST_STATE_RECEIPT_FORM_OPTION_DEFAULT = "SET_REQUEST_STATE_RECEIPT_FORM_OPTION_DEFAULT",
}

export const mutations: MutationTree<IReceiptFormOptionDefaultState> = {
  [ReceiptFormOptionDefaultMutations.SET_RECEIPT_FORM_OPTION_DEFAULT](
    state,
    payload: ReceiptFormOptionRequestDto
  ) {
    state.receiptFormOptionDefault = payload;
  },
  [ReceiptFormOptionDefaultMutations.SET_RECEIPT_FORM_OPTION_DEFAULT_FORM](
    state,
    payload: ReceiptFormOptionRequestDto
  ) {
    state.receiptFormOptionDefaultForm = payload;
  },
  [ReceiptFormOptionDefaultMutations.SET_IS_LOADING_RECEIPT_FORM_OPTION_DEFAULT](
    state,
    payload: boolean
  ) {
    state.isLoadingReceiptFormOptionDefault = payload;
  },
  [ReceiptFormOptionDefaultMutations.SET_REQUEST_STATE_RECEIPT_FORM_OPTION_DEFAULT](
    state,
    payload: IRequestState
  ) {
    state.requestStateReceiptFormOptionDefault = payload;
  },
};
