import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { IDeduction } from "@/types/api-orisis/interfaces/invoices/deduction/IDeduction";
import RootState from "@/store/modules/rootState"
import { IDeductionState } from "./states";
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

export const state: IDeductionState = {
    deductionsList       : Array<IDeduction>(),
    deductionsListArchived : Array<IDeduction>(),
    deduction            : <IDeduction>{},
    isLoadingDeductionsList   : false,
    isLoadingDeductionsListArchived   : false,
    isLoadingDeduction   : false,
    isCreatingDeduction  : false,
    isUpdatingDeduction  : false,
    isDeletingDeduction  : false,
    isArchivingDeduction : false,
    requestStateDeduction: <IRequestState>{},
}

export const DeductionModule: Module<IDeductionState, RootState> = {
  state, 
  getters,
  mutations,
  actions
}