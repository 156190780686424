import { SaleCategoryRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { SaleCategoryMutations } from "./mutations";
import { ISaleCategoryState } from "./states";

export const actions: ActionTree<ISaleCategoryState, RootState> = {
  getSaleCategories(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(SaleCategoryMutations.SET_IS_LOADING_SALE_CATEGORIES_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Sale/SaleCategory/GetSaleCategories`;
      axios
        .get(url)
        .then((res) => {
          let saleCategories = res.data.map((saleCategory: any) => {
            return {
              ...saleCategory,
              parentSaleCategoryId: saleCategory.parentSaleCategory
                ? saleCategory.parentSaleCategory.id
                : null,
              hasChild: res.data.filter(
                (el) => el.parentSaleCategory?.id === saleCategory.id
              ).length
                ? true
                : false,
            };
          });
          if (updateState) {
            commit(
              SaleCategoryMutations.SET_IS_LOADING_SALE_CATEGORIES_LIST,
              false
            );
            commit(
              SaleCategoryMutations.SET_SALE_CATEGORIES_LIST,
              saleCategories
            );
          }
          commit(SaleCategoryMutations.SET_REQUEST_STATE_SALE_CATEGORY, res);
          resolve(saleCategories);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              SaleCategoryMutations.SET_IS_LOADING_SALE_CATEGORIES_LIST,
              false
            );
          }
          commit(SaleCategoryMutations.SET_REQUEST_STATE_SALE_CATEGORY, err);
          reject(err);
        });
    });
  },

  getSaleCategoryById(
    { commit },
    {
      saleCategoryId,
      updateState = true,
    }: { saleCategoryId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(SaleCategoryMutations.SET_IS_LOADING_SALE_CATEGORY, true);
      }
      axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Sale/SaleCategory/GetSaleCategoryById`,
          {
            params: {
              saleCategoryId: saleCategoryId,
            },
          }
        )
        .then((res) => {
          let saleCategory = {
            ...res.data,
            parentId: res.data.parentSaleCategory
              ? res.data.parentSaleCategory.id
              : null,
          };
          if (updateState) {
            commit(SaleCategoryMutations.SET_IS_LOADING_SALE_CATEGORY, false);
            commit(SaleCategoryMutations.SET_SALE_CATEGORY, saleCategory);
          }
          commit(SaleCategoryMutations.SET_REQUEST_STATE_SALE_CATEGORY, res);
          resolve(saleCategory);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(SaleCategoryMutations.SET_IS_LOADING_SALE_CATEGORY, false);
          }
          commit(SaleCategoryMutations.SET_REQUEST_STATE_SALE_CATEGORY, err);
          reject(err);
        });
    });
  },

  getSaleCategoriesTree(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          SaleCategoryMutations.SET_IS_LOADING_SALE_CATEGORIES_TREE_LIST,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Sale/SaleCategory/GetSaleCategoriesTree`;
      axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              SaleCategoryMutations.SET_IS_LOADING_SALE_CATEGORIES_TREE_LIST,
              false
            );
            commit(
              SaleCategoryMutations.SET_SALE_CATEGORIES_TREE_LIST,
              res.data
            );
          }
          commit(SaleCategoryMutations.SET_REQUEST_STATE_SALE_CATEGORY, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              SaleCategoryMutations.SET_IS_LOADING_SALE_CATEGORIES_TREE_LIST,
              false
            );
          }
          commit(SaleCategoryMutations.SET_REQUEST_STATE_SALE_CATEGORY, err);
          reject(err);
        });
    });
  },

  getSaleCategoriesArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          SaleCategoryMutations.SET_IS_LOADING_SALE_CATEGORIES_LIST_ARCHIVED,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Sale/SaleCategory/GetSaleCategoriesArchived`;
      axios
        .get(url)
        .then((res) => {
          let saleCategoriesArchived = res.data.map((saleCategory: any) => {
            return {
              ...saleCategory,
              parentSaleCategoryId: saleCategory.parentSaleCategory
                ? saleCategory.parentSaleCategory.id
                : null,
            };
          });
          if (updateState) {
            commit(
              SaleCategoryMutations.SET_IS_LOADING_SALE_CATEGORIES_LIST_ARCHIVED,
              false
            );
            commit(
              SaleCategoryMutations.SET_SALE_CATEGORIES_LIST_ARCHIVED,
              saleCategoriesArchived
            );
          }
          commit(SaleCategoryMutations.SET_REQUEST_STATE_SALE_CATEGORY, res);
          resolve(saleCategoriesArchived);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              SaleCategoryMutations.SET_IS_LOADING_SALE_CATEGORIES_LIST_ARCHIVED,
              false
            );
          }
          commit(SaleCategoryMutations.SET_REQUEST_STATE_SALE_CATEGORY, err);
          reject(err);
        });
    });
  },

  getSaleCategoryArchivedById(
    { commit },
    {
      saleCategoryId,
      updateState = true,
    }: { saleCategoryId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(SaleCategoryMutations.SET_IS_LOADING_SALE_CATEGORY, true);
      }
      axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Sale/SaleCategory/GetSaleCategoryArchivedById`,
          {
            params: {
              saleCategoryId: saleCategoryId,
            },
          }
        )
        .then((res) => {
          let saleCategoryArchived = {
            ...res.data,
            parentId: res.data.parent ? res.data.parent.id : null,
          };
          if (updateState) {
            commit(SaleCategoryMutations.SET_IS_LOADING_SALE_CATEGORY, false);
            commit(
              SaleCategoryMutations.SET_SALE_CATEGORY,
              saleCategoryArchived
            );
          }
          commit(SaleCategoryMutations.SET_REQUEST_STATE_SALE_CATEGORY, res);
          resolve(saleCategoryArchived);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(SaleCategoryMutations.SET_IS_LOADING_SALE_CATEGORY, false);
          }
          commit(SaleCategoryMutations.SET_REQUEST_STATE_SALE_CATEGORY, err);
          reject(err);
        });
    });
  },

  createSaleCategory(
    { commit, rootGetters },
    { saleCategory }: { saleCategory: SaleCategoryRequestDto }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(SaleCategoryMutations.SET_IS_CREATING_SALE_CATEGORY, true);
      axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Sale/SaleCategory/CreateSaleCategory`,
          saleCategory
        )
        .then((res) => {
          rootGetters.saleCategoriesTreeList.push(res.data.data);
          commit(
            SaleCategoryMutations.SET_SALE_CATEGORIES_LIST,
            rootGetters.saleCategoriesTreeList
          );
          rootGetters.saleCategoriesList.push(res.data.data);
          commit(
            SaleCategoryMutations.SET_SALE_CATEGORIES_LIST,
            rootGetters.saleCategoriesList
          );
          commit(SaleCategoryMutations.SET_REQUEST_STATE_SALE_CATEGORY, res);
          commit(SaleCategoryMutations.SET_IS_CREATING_SALE_CATEGORY, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(SaleCategoryMutations.SET_REQUEST_STATE_SALE_CATEGORY, err);
          commit(SaleCategoryMutations.SET_IS_CREATING_SALE_CATEGORY, false);
          reject(err);
        });
    });
  },

  updateSaleCategory(
    { commit, rootGetters },
    { saleCategory }: { saleCategory: SaleCategoryRequestDto }
  ) {
    return new Promise<void>((resolve, reject) => {
      commit(SaleCategoryMutations.SET_IS_UPDATING_SALE_CATEGORY, true);
      axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Sale/SaleCategory/UpdateSaleCategory`,
          saleCategory
        )
        .then((res) => {
          rootGetters.saleCategoriesList.splice(
            rootGetters.saleCategoriesList.findIndex(
              (elem) => elem.id == saleCategory.id
            ),
            1,
            saleCategory
          );
          commit(
            SaleCategoryMutations.SET_SALE_CATEGORIES_LIST,
            rootGetters.saleCategoriesList
          );

          commit(SaleCategoryMutations.SET_REQUEST_STATE_SALE_CATEGORY, res);
          commit(SaleCategoryMutations.SET_IS_UPDATING_SALE_CATEGORY, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(SaleCategoryMutations.SET_REQUEST_STATE_SALE_CATEGORY, err);
          commit(SaleCategoryMutations.SET_IS_UPDATING_SALE_CATEGORY, false);
          reject(err);
        });
    });
  },

  restoreSaleCategories(
    { commit, rootGetters },
    { saleCategoryIds = [] }: { saleCategoryIds: number[] }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(SaleCategoryMutations.SET_IS_UPDATING_SALE_CATEGORY, true);
      axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Sale/SaleCategory/Restore",
          saleCategoryIds
        )
        .then((res) => {
          saleCategoryIds.forEach((saleCategoryId) => {
            rootGetters.saleCategoriesList.unshift(
              rootGetters.saleCategoriesListArchived.find(
                (elem) => elem.id == saleCategoryId
              )
            );
            rootGetters.saleCategoriesListArchived.splice(
              rootGetters.saleCategoriesListArchived.findIndex(
                (elem) => elem.id == saleCategoryId
              ),
              1
            );
          });
          commit(SaleCategoryMutations.SET_REQUEST_STATE_SALE_CATEGORY, res);
          commit(SaleCategoryMutations.SET_IS_UPDATING_SALE_CATEGORY, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(SaleCategoryMutations.SET_REQUEST_STATE_SALE_CATEGORY, err);
          commit(SaleCategoryMutations.SET_IS_UPDATING_SALE_CATEGORY, false);
          reject(err);
        });
    });
  },

  archiveSaleCategories(
    { commit, rootGetters },
    { saleCategoryIds = [] }: { saleCategoryIds: number[] }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(SaleCategoryMutations.SET_IS_ARCHIVING_SALE_CATEGORY, true);
      axios
        .delete(process.env.VUE_APP_API_ORISIS + "Sale/SaleCategory/Archive", {
          data: saleCategoryIds,
        })
        .then((res) => {
          saleCategoryIds.forEach((saleCategoryId) => {
            rootGetters.saleCategoriesListArchived.unshift(
              rootGetters.saleCategoriesList.find(
                (elem) => elem.id == saleCategoryId
              )
            );
            rootGetters.saleCategoriesList.splice(
              rootGetters.saleCategoriesList.findIndex(
                (elem) => elem.id == saleCategoryId
              ),
              1
            );
          });
          commit(
            SaleCategoryMutations.SET_SALE_CATEGORIES_LIST,
            rootGetters.saleCategoriesList
          );
          commit(SaleCategoryMutations.SET_REQUEST_STATE_SALE_CATEGORY, res);
          commit(SaleCategoryMutations.SET_IS_ARCHIVING_SALE_CATEGORY, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(SaleCategoryMutations.SET_REQUEST_STATE_SALE_CATEGORY, err);
          commit(SaleCategoryMutations.SET_IS_ARCHIVING_SALE_CATEGORY, false);
          reject(err);
        });
    });
  },

  deleteSaleCategories(
    { commit, rootGetters },
    { saleCategoryIds = [] }: { saleCategoryIds: number[] }
  ) {
    return new Promise<void>((resolve, reject) => {
      commit(SaleCategoryMutations.SET_IS_DELETING_SALE_CATEGORY, true);
      axios
        .delete(`${process.env.VUE_APP_API_ORISIS}Sale/SaleCategory/Delete`, {
          data: saleCategoryIds,
        })
        .then((res) => {
          saleCategoryIds.forEach((saleCategoryId) => {
            rootGetters.saleCategoriesListArchived.splice(
              rootGetters.saleCategoriesListArchived.findIndex(
                (elem) => elem.id == saleCategoryId
              ),
              1
            );
          });
          commit(
            SaleCategoryMutations.SET_SALE_CATEGORIES_LIST_ARCHIVED,
            rootGetters.saleCategoriesListArchived
          );
          commit(SaleCategoryMutations.SET_REQUEST_STATE_SALE_CATEGORY, res);
          commit(SaleCategoryMutations.SET_IS_DELETING_SALE_CATEGORY, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(SaleCategoryMutations.SET_REQUEST_STATE_SALE_CATEGORY, err);
          commit(SaleCategoryMutations.SET_IS_DELETING_SALE_CATEGORY, false);
          reject(err);
        });
    });
  },
};
