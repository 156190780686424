import { ActivityResultTypeRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { ActivityResultTypeMutations } from "./mutations";
import { IActivityResultTypeState } from "./states";

export const actions: ActionTree<IActivityResultTypeState, RootState> = {
  async getActivityResultTypes(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          ActivityResultTypeMutations.SET_IS_LOADING_ACTIVITY_RESULT_TYPE_LIST,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/ActivityResultType/GetActivityResultTypes`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              ActivityResultTypeMutations.SET_IS_LOADING_ACTIVITY_RESULT_TYPE_LIST,
              false
            );
            commit(
              ActivityResultTypeMutations.SET_ACTIVITY_RESULT_TYPES_LIST,
              res.data
            );
          }
          commit(
            ActivityResultTypeMutations.SET_REQUEST_STATE_ACTIVITY_RESULT_TYPE,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivityResultTypeMutations.SET_IS_LOADING_ACTIVITY_RESULT_TYPE_LIST,
            false
          );
          commit(
            ActivityResultTypeMutations.SET_REQUEST_STATE_ACTIVITY_RESULT_TYPE,
            err
          );
          reject(err);
        });
    });
  },

  async getActivityResultTypeById(
    { commit },
    {
      activityResultTypeId,
      updateState = true,
    }: { activityResultTypeId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          ActivityResultTypeMutations.SET_IS_LOADING_ACTIVITY_RESULT_TYPE,
          true
        );
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Directory/ActivityResultType/GetActivityResultTypeById`,
          {
            params: {
              activityResultTypeId: activityResultTypeId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(
              ActivityResultTypeMutations.SET_ACTIVITY_RESULT_TYPE,
              res.data
            );
            commit(
              ActivityResultTypeMutations.SET_IS_LOADING_ACTIVITY_RESULT_TYPE,
              false
            );
          }
          commit(
            ActivityResultTypeMutations.SET_REQUEST_STATE_ACTIVITY_RESULT_TYPE,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivityResultTypeMutations.SET_REQUEST_STATE_ACTIVITY_RESULT_TYPE,
            err
          );
          commit(
            ActivityResultTypeMutations.SET_IS_LOADING_ACTIVITY_RESULT_TYPE,
            false
          );
          reject(err);
        });
    });
  },

  async getActivityResultTypesArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          ActivityResultTypeMutations.SET_IS_LOADING_ACTIVITY_RESULT_TYPE_LIST_ARCHIVED,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/ActivityResultType/GetActivityResultTypesArchived`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              ActivityResultTypeMutations.SET_IS_LOADING_ACTIVITY_RESULT_TYPE_LIST_ARCHIVED,
              false
            );
            commit(
              ActivityResultTypeMutations.SET_ACTIVITY_RESULT_TYPES_LIST_ARCHIVED,
              res.data
            );
          }
          commit(
            ActivityResultTypeMutations.SET_REQUEST_STATE_ACTIVITY_RESULT_TYPE,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivityResultTypeMutations.SET_IS_LOADING_ACTIVITY_RESULT_TYPE_LIST,
            false
          );
          commit(
            ActivityResultTypeMutations.SET_REQUEST_STATE_ACTIVITY_RESULT_TYPE,
            err
          );
          reject(err);
        });
    });
  },

  async getActivityResultTypeArchivedById(
    { commit },
    {
      activityResultTypeId,
      updateState = true,
    }: { activityResultTypeId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          ActivityResultTypeMutations.SET_IS_LOADING_ACTIVITY_RESULT_TYPE,
          true
        );
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Directory/ActivityResultType/GetActivityResultTypeArchivedById`,
          {
            params: {
              activityResultTypeId: activityResultTypeId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(
              ActivityResultTypeMutations.SET_ACTIVITY_RESULT_TYPE,
              res.data
            );
            commit(
              ActivityResultTypeMutations.SET_IS_LOADING_ACTIVITY_RESULT_TYPE,
              false
            );
          }
          commit(
            ActivityResultTypeMutations.SET_REQUEST_STATE_ACTIVITY_RESULT_TYPE,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivityResultTypeMutations.SET_REQUEST_STATE_ACTIVITY_RESULT_TYPE,
            err
          );
          commit(
            ActivityResultTypeMutations.SET_IS_LOADING_ACTIVITY_RESULT_TYPE,
            false
          );
          reject(err);
        });
    });
  },

  async createActivityResultType(
    { commit, rootGetters },
    { activityResultType }: { activityResultType: ActivityResultTypeRequestDto }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        ActivityResultTypeMutations.SET_IS_CREATING_ACTIVITY_RESULT_TYPE,
        true
      );
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Directory/ActivityResultType/CreateActivityResultType`,
          activityResultType
        )
        .then((res) => {
          rootGetters.activityResultTypesList.push(res.data.data);
          commit(
            ActivityResultTypeMutations.SET_ACTIVITY_RESULT_TYPES_LIST,
            rootGetters.activityResultTypesList
          );
          commit(
            ActivityResultTypeMutations.SET_REQUEST_STATE_ACTIVITY_RESULT_TYPE,
            res
          );
          commit(
            ActivityResultTypeMutations.SET_IS_CREATING_ACTIVITY_RESULT_TYPE,
            false
          );
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivityResultTypeMutations.SET_REQUEST_STATE_ACTIVITY_RESULT_TYPE,
            err
          );
          commit(
            ActivityResultTypeMutations.SET_IS_CREATING_ACTIVITY_RESULT_TYPE,
            false
          );
          reject(err);
        });
    });
  },

  async updateActivityResultType(
    { commit, rootGetters },
    { activityResultType }: { activityResultType: ActivityResultTypeRequestDto }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        ActivityResultTypeMutations.SET_IS_UPDATING_ACTIVITY_RESULT_TYPE,
        true
      );
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Directory/ActivityResultType/UpdateActivityResultType`,
          activityResultType
        )
        .then((res) => {
          if (
            rootGetters.activityResultTypesList.find(
              (elem) => elem.id == activityResultType.id
            )
          ) {
            rootGetters.activityResultTypesList.splice(
              rootGetters.activityResultTypesList.findIndex(
                (elem) => elem.id == activityResultType.id
              ),
              1,
              activityResultType
            );
            commit(
              ActivityResultTypeMutations.SET_ACTIVITY_RESULT_TYPES_LIST,
              rootGetters.activityResultTypesList
            );
          }

          if (
            rootGetters.activityResultTypesListArchived.find(
              (elem) => elem.id == activityResultType.id
            )
          ) {
            rootGetters.activityResultTypesListArchived.splice(
              rootGetters.activityResultTypesListArchived.findIndex(
                (elem) => elem.id == activityResultType.id
              ),
              1,
              activityResultType
            );
            commit(
              ActivityResultTypeMutations.SET_ACTIVITY_RESULT_TYPES_LIST_ARCHIVED,
              rootGetters.activityResultTypesListArchived
            );
          }

          commit(
            ActivityResultTypeMutations.SET_REQUEST_STATE_ACTIVITY_RESULT_TYPE,
            res
          );
          commit(
            ActivityResultTypeMutations.SET_IS_UPDATING_ACTIVITY_RESULT_TYPE,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivityResultTypeMutations.SET_REQUEST_STATE_ACTIVITY_RESULT_TYPE,
            err
          );
          commit(
            ActivityResultTypeMutations.SET_IS_UPDATING_ACTIVITY_RESULT_TYPE,
            false
          );
          reject(err);
        });
    });
  },

  async restoreActivityResultTypes(
    { commit, rootGetters },
    { activityResultTypeIds = [] }: { activityResultTypeIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        ActivityResultTypeMutations.SET_IS_UPDATING_ACTIVITY_RESULT_TYPE,
        true
      );
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Directory/ActivityResultType/Restore",
          activityResultTypeIds
        )
        .then(async (res) => {
          activityResultTypeIds.forEach((activityResultTypeId: number) => {
            let activityResultType =
              rootGetters.activityResultTypesListArchived.find(
                (activityResultType) =>
                  activityResultType.id == activityResultTypeId
              );
            rootGetters.activityResultTypesList.unshift(activityResultType);

            if (
              rootGetters.activityResultTypesListArchived.find(
                (activityResultType) =>
                  activityResultType.id == activityResultTypeId
              )
            ) {
              rootGetters.activityResultTypesListArchived.splice(
                rootGetters.activityResultTypesListArchived.findIndex(
                  (activityResultType) =>
                    activityResultType.id == activityResultTypeId
                ),
                1
              );
            }
          });
          commit(
            ActivityResultTypeMutations.SET_ACTIVITY_RESULT_TYPES_LIST,
            rootGetters.activityResultTypesList
          );
          commit(
            ActivityResultTypeMutations.SET_ACTIVITY_RESULT_TYPES_LIST_ARCHIVED,
            rootGetters.activityResultTypesListArchived
          );
          commit(
            ActivityResultTypeMutations.SET_REQUEST_STATE_ACTIVITY_RESULT_TYPE,
            res
          );
          commit(
            ActivityResultTypeMutations.SET_IS_UPDATING_ACTIVITY_RESULT_TYPE,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivityResultTypeMutations.SET_REQUEST_STATE_ACTIVITY_RESULT_TYPE,
            err
          );
          commit(
            ActivityResultTypeMutations.SET_IS_UPDATING_ACTIVITY_RESULT_TYPE,
            false
          );
          reject(err);
        });
    });
  },

  async archiveActivityResultTypes(
    { commit, rootGetters },
    { activityResultTypeIds = [] }: { activityResultTypeIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        ActivityResultTypeMutations.SET_IS_ARCHIVING_ACTIVITY_RESULT_TYPE,
        true
      );
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS +
            "Directory/ActivityResultType/Archive",
          {
            data: activityResultTypeIds,
          }
        )
        .then((res) => {
          activityResultTypeIds.forEach((activityResultTypeId: number) => {
            let activityResultType = rootGetters.activityResultTypesList.find(
              (activityResultType) =>
                activityResultType.id == activityResultTypeId
            );
            rootGetters.activityResultTypesListArchived.unshift(
              activityResultType
            );

            if (
              rootGetters.activityResultTypesList.find(
                (activityResultType) =>
                  activityResultType.id == activityResultTypeId
              )
            ) {
              rootGetters.activityResultTypesList.splice(
                rootGetters.activityResultTypesList.findIndex(
                  (activityResultType) =>
                    activityResultType.id == activityResultTypeId
                ),
                1
              );
            }
          });
          commit(
            ActivityResultTypeMutations.SET_ACTIVITY_RESULT_TYPES_LIST,
            rootGetters.activityResultTypesList
          );
          commit(
            ActivityResultTypeMutations.SET_ACTIVITY_RESULT_TYPES_LIST_ARCHIVED,
            rootGetters.activityResultTypesListArchived
          );
          commit(
            ActivityResultTypeMutations.SET_REQUEST_STATE_ACTIVITY_RESULT_TYPE,
            res
          );
          commit(
            ActivityResultTypeMutations.SET_IS_ARCHIVING_ACTIVITY_RESULT_TYPE,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivityResultTypeMutations.SET_REQUEST_STATE_ACTIVITY_RESULT_TYPE,
            err
          );
          commit(
            ActivityResultTypeMutations.SET_IS_ARCHIVING_ACTIVITY_RESULT_TYPE,
            false
          );
          reject(err);
        });
    });
  },

  async deleteActivityResultTypes(
    { commit, rootGetters },
    { activityResultTypeIds = [] }: { activityResultTypeIds: number[] }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        ActivityResultTypeMutations.SET_IS_DELETING_ACTIVITY_RESULT_TYPE,
        true
      );
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS +
            "Directory/ActivityResultType/Delete",
          {
            data: activityResultTypeIds,
          }
        )
        .then((res) => {
          activityResultTypeIds.forEach((activityResultTypeId: number) => {
            if (
              rootGetters.activityResultTypesList.find(
                (activityResultType) =>
                  activityResultType.id == activityResultTypeId
              )
            ) {
              rootGetters.activityResultTypesList.splice(
                rootGetters.activityResultTypesList.findIndex(
                  (activityResultType) =>
                    activityResultType.id == activityResultTypeId
                ),
                1
              );
            }

            if (
              rootGetters.activityResultTypesListArchived.find(
                (activityResultType) =>
                  activityResultType.id == activityResultTypeId
              )
            ) {
              rootGetters.activityResultTypesListArchived.splice(
                rootGetters.activityResultTypesListArchived.findIndex(
                  (activityResultType) =>
                    activityResultType.id == activityResultTypeId
                ),
                1
              );
            }
          });
          commit(
            ActivityResultTypeMutations.SET_ACTIVITY_RESULT_TYPES_LIST,
            rootGetters.activityResultTypesList
          );
          commit(
            ActivityResultTypeMutations.SET_ACTIVITY_RESULT_TYPES_LIST_ARCHIVED,
            rootGetters.activityResultTypesListArchived
          );

          commit(
            ActivityResultTypeMutations.SET_REQUEST_STATE_ACTIVITY_RESULT_TYPE,
            res
          );
          commit(
            ActivityResultTypeMutations.SET_IS_DELETING_ACTIVITY_RESULT_TYPE,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivityResultTypeMutations.SET_REQUEST_STATE_ACTIVITY_RESULT_TYPE,
            err
          );
          commit(
            ActivityResultTypeMutations.SET_IS_DELETING_ACTIVITY_RESULT_TYPE,
            false
          );
          reject(err);
        });
    });
  },
};
