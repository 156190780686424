export function ReceiptFormDetailDefaultProperties({
  index = 0,
  id = 0,
  reference = "",
  description = "",
  productProviderId = null,
  analyticSettingId = null,
  receiptFormId = null,
  type = 0,
  unitId = null,
  receivedQuantity = 0,
  previousQuantitiesReceived = 0,
  orderedQuantity = 0
}) {
  return {
    id: id,
    index: index,
    reference: reference,
    description: description,
    receivedQuantity: receivedQuantity,
    previousQuantitiesReceived: previousQuantitiesReceived,
    orderedQuantity: orderedQuantity,
    unitId: unitId,
    productProviderId: productProviderId,
    receiptFormId: receiptFormId,
    type: type,
    analyticSettingId: analyticSettingId,
  };
}
