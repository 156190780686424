import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { ProductProviderRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { IProductProviderState } from './states'


export const getters: GetterTree<IProductProviderState, RootState> = {
  productProvidersList                    : (state): ProductProviderRequestDto[] => state.productProvidersList,
  productProvidersListArchived            : (state): ProductProviderRequestDto[] => state.productProvidersListArchived,
  productProvider                         : (state): ProductProviderRequestDto => state.productProvider,
  productProviderSortedByProductType      : (state): any[] => state.productProviderSortedByProductType,

  // COMMON
  isLoadingProductProvidersList            : (state): boolean => state.isLoadingProductProvidersList,
  isLoadingProductProvidersListArchived    : (state): boolean => state.isLoadingProductProvidersListArchived,
  isLoadingProductProvider                 : (state): boolean => state.isLoadingProductProvider,
  isCreatingProductProvider                : (state): boolean => state.isCreatingProductProvider,
  isUpdatingProductProvider                : (state): boolean => state.isUpdatingProductProvider,
  isDeletingProductProvider                : (state): boolean => state.isDeletingProductProvider,
  isArchivingProductProvider               : (state): boolean => state.isArchivingProductProvider,
  requestStateProductProvider              : (state): IRequestState => state.requestStateProductProvider,
}