import { IActivitySourceTypeState } from "./states"
import { Module } from "vuex"
import { ActivitySourceTypeRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IActivitySourceTypeState = {
    activitySourceTypesList         : Array<ActivitySourceTypeRequestDto>(),
    activitySourceTypesListArchived : Array<ActivitySourceTypeRequestDto>(),
    activitySourceType              : <ActivitySourceTypeRequestDto>{},
    isLoadingActivitySourceTypesList     : false,
    isLoadingActivitySourceTypesListArchived     : false,
    isLoadingActivitySourceType     : false,
    isCreatingActivitySourceType    : false,
    isUpdatingActivitySourceType    : false,
    isDeletingActivitySourceType    : false,
    isArchivingActivitySourceType    : false,
    requestStateActivitySourceType: <IRequestState>{},
    activitySourceTypeFilters                : {}
}

export const ActivitySourceTypeModule: Module<IActivitySourceTypeState, RootState> = {
    state, 
    getters,
    mutations,
    actions
} 