import { IProductProviderState } from "./states"
import { Module } from "vuex"
import { ProductProviderRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state: IProductProviderState = {
    productProvidersList                     : Array<ProductProviderRequestDto>(),
    productProvidersListArchived             : Array<ProductProviderRequestDto>(),
    productProvider                           : <ProductProviderRequestDto>{},
    productProviderSortedByProductType       : Array<any>(),
    isLoadingProductProvidersList            : false,
    isLoadingProductProvidersListArchived    : false,
    isLoadingProductProvider                  : false,
    isCreatingProductProvider                 : false,
    isUpdatingProductProvider                 : false,
    isDeletingProductProvider                 : false,
    isArchivingProductProvider                : false,
    requestStateProductProvider               : <IRequestState>{},
}

export const ProductProviderModule: Module<IProductProviderState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}