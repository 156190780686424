import { IDeliveryFormOptionState } from "./states";
import { Module } from "vuex";
import { DeliveryFormOptionRequestDto } from "@/../Api";
import RootState from "@/store/modules/rootState";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

const state: IDeliveryFormOptionState = {
  deliveryFormOption: <DeliveryFormOptionRequestDto>{},
  isLoadingDeliveryFormOption: false,
  requestStateDeliveryFormOption: <IRequestState>{},
};

export const DeliveryFormOptionModule: Module<
  IDeliveryFormOptionState,
  RootState
> = {
  state,
  getters,
  mutations,
  actions,
};
