import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import RootState from "@/store/modules/rootState";
import { IAccountState } from "./states";
import { Module } from "vuex";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

export const state: IAccountState = {
  userInfo: <any>{},
  userToken: null,
  userIsLoggedIn: false,
  planningViewSelected: <any>{},
  isToastingNotification: false,
  isOpenSidebarCampaign: false,
  isLoggin: false,
  isLogginMsg: "",
  requestStateAccount: <IRequestState>{},
};

export const AccountModule: Module<IAccountState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
