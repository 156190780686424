import { IDocumentState } from "./states";
import { MutationTree } from "vuex";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { IDocument } from "@/types/api-orisis/interfaces/invoices/document/IDocument";
import { IRemaining } from "@/types/api-orisis/interfaces/invoices/document/IRemaining";
import { IDocumentStats } from "@/types/api-orisis/interfaces/invoices/document/IDocumentStats";

import { IPaymentMethod } from "@/types/api-orisis/interfaces/invoices/paymentMethod/IPaymentMethod";
import { IFilters } from "@/types/api-orisis/interfaces/invoices/filters/IFilters";

export enum DocumentMutations {
  SET_QUOTES_LIST = "SET_QUOTES_LIST",
  SET_QUOTES_LIST_TEMPLATES = "SET_QUOTES_LIST_TEMPLATES",
  SET_QUOTES_LIST_SIGNED = "SET_QUOTES_LIST_SIGNED",
  SET_QUOTES_LIST_ARCHIVED = "SET_QUOTES_LIST_ARCHIVED",
  SET_QUOTES_LIST_BY_AFFAIR = "SET_QUOTES_LIST_BY_AFFAIR",
  SET_QUOTES_LIST_BY_COMPANY = "SET_QUOTES_LIST_BY_COMPANY",

  SET_QUOTES_LIST_STATS = "SET_QUOTES_LIST_STATS",
  SET_QUOTES_LIST_SIGNED_STATS = "SET_QUOTES_LIST_SIGNED_STATS",
  SET_QUOTES_LIST_LAST_YEAR_STATS = "SET_QUOTES_LIST_LAST_YEAR_STATS",

  SET_INVOICES_LIST = "SET_INVOICES_LIST",
  SET_INVOICES_LIST_TEMPLATES = "SET_INVOICES_LIST_TEMPLATES",
  SET_INVOICES_LIST_LAST_YEAR = "SET_INVOICES_LIST_LAST_YEAR",
  SET_INVOICES_LIST_ARCHIVED = "SET_INVOICES_LIST_ARCHIVED",
  SET_INVOICES_LIST_BY_AFFAIR = "SET_INVOICES_LIST_BY_AFFAIR",
  SET_INVOICES_LIST_BY_COMPANY = "SET_INVOICES_LIST_BY_COMPANY",

  SET_INVOICES_LIST_STATS = "SET_INVOICES_LIST_STATS",
  SET_INVOICES_LIST_LAST_YEAR_STATS = "SET_INVOICES_LIST_LAST_YEAR_STATS",
  SET_INVOICES_REMAINING_TO_PAY = "SET_INVOICES_REMAINING_TO_PAY",

  SET_CREDITS_LIST_STATS = "SET_CREDITS_LIST_STATS",
  SET_CREDITS_LIST_LAST_YEAR_STATS = "SET_CREDITS_LIST_LAST_YEAR_STATS",

  SET_CREDITS_LIST = "SET_CREDITS_LIST",
  SET_CREDITS_LIST_ARCHIVED = "SET_CREDITS_LIST_ARCHIVED",
  SET_CREDITS_LIST_BY_AFFAIR = "SET_CREDITS_LIST_BY_AFFAIR",
  SET_CREDITS_LIST_BY_COMPANY = "SET_CREDITS_LIST_BY_COMPANY",
  SET_CREDIT_BY_INVOICE = "SET_CREDIT_BY_INVOICE",

  SET_DOCUMENT = "SET_DOCUMENT",
  SET_LAST_DOCUMENT_QUOTE_REFERENCE = "SET_LAST_DOCUMENT_QUOTE_REFERENCE",
  SET_LAST_DOCUMENT_INVOICE_REFERENCE = "SET_LAST_DOCUMENT_INVOICE_REFERENCE",
  SET_LAST_DOCUMENT_CREDIT_REFERENCE = "SET_LAST_DOCUMENT_CREDIT_REFERENCE",
  SET_DOCUMENT_PDF = "SET_DOCUMENT_PDF",
  SET_INITIAL_INVOICE = "SET_INITIAL_INVOICE",
  SET_LAST_INVOICE = "SET_LAST_INVOICE",
  SET_DOCUMENT_PAYMENT_METHODS = "SET_DOCUMENT_PAYMENT_METHODS",
  SET_IS_TOTAL_CREDIT = "SET_IS_TOTAL_CREDIT",

  //FILTERS
  SET_INVOICE_FILTERS = "SET_INVOICE_FILTERS",
  SET_QUOTE_FILTERS = "SET_QUOTE_FILTERS",
  SET_CREDIT_FILTERS = "SET_CREDIT_FILTERS",

  // COMON
  SET_LOADING_MSG = "SET_LOADING_MSG",
  SET_IS_VALIDATING_DOCUMENT = "SET_IS_VALIDATING_DOCUMENT",
  SET_IS_LOADING_INVOICES_REMAINING_TO_PAY = "SET_IS_LOADING_INVOICES_REMAINING_TO_PAY",
  SET_IS_LOADING_DOCUMENTS_LIST = "SET_IS_LOADING_DOCUMENTS_LIST",
  SET_IS_LOADING_QUOTES_LIST = "SET_IS_LOADING_QUOTES_LIST",
  SET_IS_LOADING_INVOICES_LIST = "SET_IS_LOADING_INVOICES_LIST",
  SET_IS_LOADING_QUOTES_LIST_STATS = "SET_IS_LOADING_QUOTES_LIST_STATS",
  SET_IS_LOADING_INVOICES_LIST_STATS = "SET_IS_LOADING_INVOICES_LIST_STATS",
  SET_IS_LOADING_CREDITS_LIST_STATS = "SET_IS_LOADING_CREDITS_LIST_STATS",
  SET_IS_LOADING_INVOICES_LIST_LAST_YEAR_STATS = "SET_IS_LOADING_INVOICES_LIST_LAST_YEAR_STATS",
  SET_IS_LOADING_CREDITS_LIST_LAST_YEAR_STATS = "SET_IS_LOADING_CREDITS_LIST_LAST_YEAR_STATS",
  SET_IS_LOADING_CREDITS_LIST = "SET_IS_LOADING_CREDITS_LIST",
  SET_IS_LOADING_DOCUMENTS_LIST_BY_COMPANY = "SET_IS_LOADING_DOCUMENTS_LIST_BY_COMPANY",
  SET_IS_LOADING_DOCUMENTS_LIST_BY_AFFAIR = "SET_IS_LOADING_DOCUMENTS_LIST_BY_AFFAIR",
  SET_IS_LOADING_DOCUMENTS_LIST_ARCHIVED = "SET_IS_LOADING_DOCUMENTS_LIST_ARCHIVED",
  SET_IS_LOADING_DOCUMENT = "SET_IS_LOADING_DOCUMENT",
  SET_IS_LOADING_DOCUMENT_PDF = "SET_IS_LOADING_DOCUMENT_PDF",
  SET_IS_LOADING_DUPLICATING_DOCUMENT = "SET_IS_LOADING_DUPLICATING_DOCUMENT",
  SET_IS_CREATING_DOCUMENT = "SET_IS_CREATING_DOCUMENT",
  SET_IS_CREATING_LEASE_DOCUMENT = "SET_IS_CREATING_LEASE_DOCUMENT",
  SET_IS_UPDATING_DOCUMENT = "SET_IS_UPDATING_DOCUMENT",
  SET_IS_DELETING_DOCUMENT = "SET_IS_DELETING_DOCUMENT",
  SET_REQUEST_STATE_DOCUMENT = "SET_REQUEST_STATE_DOCUMENT",
  SET_REQUEST_STATE_DOCUMENT_PDF = "SET_REQUEST_STATE_DOCUMENT_PDF",
  SET_IS_ARCHIVING_DOCUMENT = "SET_IS_ARCHIVING_DOCUMENT",
  SET_IS_SENDING_EMAIL = "SET_IS_SENDING_EMAIL",

  SET_TOTAL_HT = "SET_TOTAL_HT",

  SET_MARGIN_STATUS = "SET_MARGIN_STATUS",

  SET_DOCUMENT_TAB_ACTIVE = "SET_DOCUMENT_TAB_ACTIVE",

  SET_NEW_CREDIT_FORM = "SET_NEW_CREDIT_FORM",
}

export const mutations: MutationTree<IDocumentState> = {
  [DocumentMutations.SET_LOADING_MSG]: (state, payload: string) => {
    state.loadingMsg = payload;
  },
  [DocumentMutations.SET_QUOTES_LIST]: (state, payload: IDocument[]) => {
    state.quotesList = payload;
  },
  [DocumentMutations.SET_QUOTES_LIST_TEMPLATES]: (
    state,
    payload: IDocument[]
  ) => {
    state.quotesListTemplates = payload;
  },
  [DocumentMutations.SET_QUOTES_LIST_SIGNED]: (state, payload: IDocument[]) => {
    state.quotesListSigned = payload;
  },

  [DocumentMutations.SET_QUOTES_LIST_STATS]: (
    state,
    payload: IDocumentStats
  ) => {
    state.quotesListStats = payload;
  },
  [DocumentMutations.SET_QUOTES_LIST_SIGNED_STATS]: (
    state,
    payload: IDocumentStats
  ) => {
    state.quotesListSignedStats = payload;
  },
  [DocumentMutations.SET_QUOTES_LIST_LAST_YEAR_STATS]: (
    state,
    payload: IDocumentStats
  ) => {
    state.quotesListLastYearStats = payload;
  },

  [DocumentMutations.SET_INVOICES_LIST_STATS]: (
    state,
    payload: IDocumentStats
  ) => {
    state.invoicesListStats = payload;
  },
  [DocumentMutations.SET_INVOICES_LIST_LAST_YEAR_STATS]: (
    state,
    payload: IDocumentStats
  ) => {
    state.invoicesListLastYearStats = payload;
  },
  [DocumentMutations.SET_INVOICES_REMAINING_TO_PAY]: (
    state,
    payload: IRemaining
  ) => {
    state.invoicesRemainingToPay = payload;
  },

  [DocumentMutations.SET_CREDITS_LIST_STATS]: (
    state,
    payload: IDocumentStats
  ) => {
    state.creditsListStats = payload;
  },
  [DocumentMutations.SET_CREDITS_LIST_LAST_YEAR_STATS]: (
    state,
    payload: IDocumentStats
  ) => {
    state.creditsListLastYearStats = payload;
  },

  [DocumentMutations.SET_QUOTES_LIST_ARCHIVED]: (
    state,
    payload: IDocument[]
  ) => {
    state.quotesListArchived = payload;
  },
  [DocumentMutations.SET_QUOTES_LIST_BY_AFFAIR]: (
    state,
    payload: IDocument[]
  ) => {
    state.quotesListByAffair = payload;
  },
  [DocumentMutations.SET_QUOTES_LIST_BY_COMPANY]: (
    state,
    payload: IDocument[]
  ) => {
    state.quotesListByCompany = payload;
  },
  [DocumentMutations.SET_INVOICES_LIST]: (state, payload: IDocument[]) => {
    state.invoicesList = payload;
  },
  [DocumentMutations.SET_INVOICES_LIST_TEMPLATES]: (
    state,
    payload: IDocument[]
  ) => {
    state.invoicesListTemplates = payload;
  },
  [DocumentMutations.SET_INVOICES_LIST_ARCHIVED]: (
    state,
    payload: IDocument[]
  ) => {
    state.invoicesListArchived = payload;
  },
  [DocumentMutations.SET_INVOICES_LIST_BY_AFFAIR]: (
    state,
    payload: IDocument[]
  ) => {
    state.invoicesListByAffair = payload;
  },
  [DocumentMutations.SET_INVOICES_LIST_BY_COMPANY]: (
    state,
    payload: IDocument[]
  ) => {
    state.invoicesListByCompany = payload;
  },
  [DocumentMutations.SET_CREDITS_LIST]: (state, payload: IDocument[]) => {
    state.creditsList = payload;
  },
  [DocumentMutations.SET_CREDITS_LIST_ARCHIVED]: (
    state,
    payload: IDocument[]
  ) => {
    state.creditsListArchived = payload;
  },
  [DocumentMutations.SET_CREDITS_LIST_BY_AFFAIR]: (
    state,
    payload: IDocument[]
  ) => {
    state.creditsListByAffair = payload;
  },
  [DocumentMutations.SET_CREDITS_LIST_BY_COMPANY]: (
    state,
    payload: IDocument[]
  ) => {
    state.creditsListByCompany = payload;
  },
  [DocumentMutations.SET_CREDIT_BY_INVOICE]: (state, payload: IDocument) => {
    state.creditByInvoice = payload;
  },
  [DocumentMutations.SET_DOCUMENT]: (state, payload: IDocument) => {
    state.document = payload;
  },
  [DocumentMutations.SET_DOCUMENT_PDF]: (state, payload: string) => {
    state.documentPDF = payload + "?r=" + new Date().getTime();
  },
  [DocumentMutations.SET_INITIAL_INVOICE]: (state, payload: IDocument) => {
    state.initialInvoice = payload;
  },
  [DocumentMutations.SET_LAST_INVOICE]: (state, payload) => {
    state.lastInvoice = payload;
  },
  [DocumentMutations.SET_DOCUMENT_PAYMENT_METHODS]: (
    state,
    payload: IPaymentMethod[]
  ) => {
    state.documentPaymentMethods = payload;
  },
  [DocumentMutations.SET_TOTAL_HT]: (state, payload: number) => {
    state.document.totalHt = payload;
  },
  [DocumentMutations.SET_LAST_DOCUMENT_INVOICE_REFERENCE]: (
    state,
    payload: string
  ) => {
    state.document.documentReference = payload;
  },
  [DocumentMutations.SET_LAST_DOCUMENT_QUOTE_REFERENCE]: (
    state,
    payload: string
  ) => {
    state.document.documentReference = payload;
  },
  [DocumentMutations.SET_LAST_DOCUMENT_CREDIT_REFERENCE]: (
    state,
    payload: string
  ) => {
    state.document.documentReference = payload;
  },
  [DocumentMutations.SET_IS_TOTAL_CREDIT]: (state, payload: boolean) => {
    state.isTotalCredit = payload;
  },

  //FILTERS
  [DocumentMutations.SET_INVOICE_FILTERS]: (state, payload: IFilters) => {
    state.invoiceFilters = payload;
  },
  [DocumentMutations.SET_QUOTE_FILTERS]: (state, payload: IFilters) => {
    state.quoteFilters = payload;
  },
  [DocumentMutations.SET_CREDIT_FILTERS]: (state, payload: IFilters) => {
    state.creditFilters = payload;
  },

  //COMMON
  [DocumentMutations.SET_IS_VALIDATING_DOCUMENT]: (state, payload: boolean) => {
    state.isValidatingDocument = payload;
  },
  [DocumentMutations.SET_IS_LOADING_INVOICES_REMAINING_TO_PAY]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingInvoicesRemainingToPay = payload;
  },
  [DocumentMutations.SET_IS_LOADING_DOCUMENTS_LIST]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingDocumentsList = payload;
  },
  [DocumentMutations.SET_IS_LOADING_QUOTES_LIST]: (state, payload: boolean) => {
    state.isLoadingQuotesList = payload;
  },
  [DocumentMutations.SET_IS_LOADING_INVOICES_LIST]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingInvoicesList = payload;
  },
  [DocumentMutations.SET_IS_LOADING_QUOTES_LIST_STATS]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingQuotesListStats = payload;
  },
  [DocumentMutations.SET_IS_LOADING_INVOICES_LIST_STATS]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingInvoicesListStats = payload;
  },
  [DocumentMutations.SET_IS_LOADING_INVOICES_LIST_LAST_YEAR_STATS]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingInvoicesListLastYearStats = payload;
  },
  [DocumentMutations.SET_IS_LOADING_CREDITS_LIST_STATS]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingCreditsListStats = payload;
  },
  [DocumentMutations.SET_IS_LOADING_CREDITS_LIST_LAST_YEAR_STATS]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingCreditsListLastYearStats = payload;
  },
  [DocumentMutations.SET_IS_LOADING_CREDITS_LIST]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingCreditsList = payload;
  },
  [DocumentMutations.SET_IS_LOADING_DOCUMENTS_LIST_ARCHIVED]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingDocumentsListArchived = payload;
  },
  [DocumentMutations.SET_IS_LOADING_DOCUMENTS_LIST_BY_COMPANY]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingDocumentsListByCompany = payload;
  },
  [DocumentMutations.SET_IS_LOADING_DOCUMENTS_LIST_BY_AFFAIR]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingDocumentsListByAffair = payload;
  },

  [DocumentMutations.SET_IS_LOADING_DOCUMENT]: (state, payload: boolean) => {
    state.isLoadingDocument = payload;
  },
  [DocumentMutations.SET_IS_LOADING_DOCUMENT_PDF]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingDocumentPDF = payload;
  },
  [DocumentMutations.SET_IS_LOADING_DUPLICATING_DOCUMENT]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingDuplicatingDocument = payload;
  },
  [DocumentMutations.SET_IS_CREATING_DOCUMENT]: (state, payload: boolean) => {
    state.isCreatingDocument = payload;
  },
  [DocumentMutations.SET_IS_CREATING_LEASE_DOCUMENT]: (
    state,
    payload: boolean
  ) => {
    state.isCreatingLeaseDocument = payload;
  },
  [DocumentMutations.SET_IS_UPDATING_DOCUMENT]: (state, payload: boolean) => {
    state.isUpdatingDocument = payload;
  },
  [DocumentMutations.SET_IS_DELETING_DOCUMENT]: (state, payload: boolean) => {
    state.isDeletingDocument = payload;
  },
  [DocumentMutations.SET_REQUEST_STATE_DOCUMENT]: (
    state,
    payload: IRequestState
  ) => {
    state.requestStateDocument = payload;
  },
  [DocumentMutations.SET_REQUEST_STATE_DOCUMENT_PDF]: (
    state,
    payload: IRequestState
  ) => {
    state.requestStateDocumentPDF = payload;
  },
  [DocumentMutations.SET_IS_ARCHIVING_DOCUMENT]: (state, payload: boolean) => {
    state.isArchivingDocument = payload;
  },
  [DocumentMutations.SET_MARGIN_STATUS]: (state, payload: any) => {
    state.marginStatus = payload;
  },
  [DocumentMutations.SET_DOCUMENT_TAB_ACTIVE]: (state, payload: any) => {
    state.documentTabActive = payload;
  },
  [DocumentMutations.SET_NEW_CREDIT_FORM]: (state, payload: any) => {
    state.newCreditForm = payload;
  },
  [DocumentMutations.SET_IS_SENDING_EMAIL]: (state, payload: boolean) => {
    state.isSendingEmail = payload;
  },
};
