import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import RootState from "@/store/modules/rootState"
import { ITermsAndConditionsState } from "./states";
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import { TermsAndCondictionsModel } from '@/types/api-orisis/models/TermsAndConditionsModel';

export const state: ITermsAndConditionsState = {
    termsAndConditions                         : TermsAndCondictionsModel,
    isLoadingTermsAndConditions                : false,
    isCreatingTermsAndConditions               : false,
    isUpdatingTermsAndConditions               : false,
    isDeletingTermsAndConditions               : false,
    requestStateTermsAndConditions             : <IRequestState>{},
}

export const TermsAndConditionsModule: Module<ITermsAndConditionsState, RootState> = {
  state, 
  getters,
  mutations,
  actions
}