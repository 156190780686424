import { update } from "@syncfusion/ej2-vue-inplace-editor";
import { DeliveryFormRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { DeliveryFormMutations } from "./mutations";
import { IDeliveryFormState } from "./states";
import { sha256 } from "js-sha256";
import dayjs from "dayjs";
import { EDocumentStatus } from "@/types/api-orisis/enums/enums";
import { DocumentMutations } from "../../invoices/document/mutations";

import { FileMutations } from "../../ged/file/mutations";
import { FolderMutations } from "../../ged/folder/mutations";

import { Timezone } from "@syncfusion/ej2-schedule";

let timezoneSchedule = new Timezone();

let startDate = new Date(
  dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
).toISOString();
let endDate = new Date(
  dayjs().endOf("month").format("YYYY-MM-DD")
).toISOString();

export const actions: ActionTree<IDeliveryFormState, RootState> = {
  getDeliveryForms(
    { commit, rootGetters },
    {
      dateFrom = null,
      dateTo = null,
      affairId,
      companyId,
      updateState = true,
      route,
    }: {
      dateFrom?: Date;
      dateTo?: Date;
      affairId?: number;
      companyId?: number[];
      updateState: boolean;
      route?: string;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        switch (route) {
          case "company":
            commit(
              DeliveryFormMutations.SET_IS_LOADING_DELIVERY_FORMS_LIST_BY_COMPANY,
              true
            );
            break;
          case "affair":
            commit(
              DeliveryFormMutations.SET_IS_LOADING_DELIVERY_FORMS_LIST_BY_AFFAIR,
              true
            );
            break;
          default:
            commit(
              DeliveryFormMutations.SET_IS_LOADING_DELIVERY_FORM_LIST,
              true
            );
            break;
        }
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/DeliveryForm/GetDeliveryForms`;
      axios
        .get(url, {
          params: {
            affairId: affairId,
            companyId: companyId,
            dateFrom: dateFrom ? dayjs(dateFrom).format("YYYY-MM-DD") : null,
            dateTo: dateTo
              ? dayjs(dateTo)
                  .hour(23)
                  .minute(59)
                  .second(59)
                  .format("YYYY-MM-DD HH:mm:ss")
              : null,
            route: route,
          },
        })
        .then((res) => {
          let list = res.data.map((item) => {
            return {
              ...item,
              documentDate: item.documentDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(item.documentDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
            };
          });
          if (updateState) {
            switch (route) {
              case "company":
                commit(
                  DeliveryFormMutations.SET_IS_LOADING_DELIVERY_FORMS_LIST_BY_COMPANY,
                  false
                );
                commit(
                  DeliveryFormMutations.SET_DELIVERY_FORM_LIST_BY_COMPANY,
                  list
                );
                break;
              case "affair":
                commit(
                  DeliveryFormMutations.SET_IS_LOADING_DELIVERY_FORMS_LIST_BY_AFFAIR,
                  false
                );
                commit(
                  DeliveryFormMutations.SET_DELIVERY_FORM_LIST_BY_AFFAIR,
                  list
                );
                break;
              default:
                commit(
                  DeliveryFormMutations.SET_IS_LOADING_DELIVERY_FORM_LIST,
                  false
                );
                commit(DeliveryFormMutations.SET_DELIVERY_FORMS_LIST, list);
                break;
            }
          }
          commit(DeliveryFormMutations.SET_REQUEST_STATE_DELIVERY_FORM, res);
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            switch (route) {
              case "company":
                commit(
                  DeliveryFormMutations.SET_IS_LOADING_DELIVERY_FORMS_LIST_BY_COMPANY,
                  false
                );
                break;
              case "affair":
                commit(
                  DeliveryFormMutations.SET_IS_LOADING_DELIVERY_FORMS_LIST_BY_AFFAIR,
                  false
                );
                break;

              default:
                commit(
                  DeliveryFormMutations.SET_IS_LOADING_DELIVERY_FORM_LIST,
                  false
                );
                break;
            }
          }
          commit(DeliveryFormMutations.SET_REQUEST_STATE_DELIVERY_FORM, err);
          reject(err);
        });
    });
  },

  getDeliveryFormById(
    { commit, dispatch, rootGetters },
    {
      deliveryFormId,
      updateState = true,
    }: { deliveryFormId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(DeliveryFormMutations.SET_IS_LOADING_DELIVERY_FORM, true);
        commit(
          DocumentMutations.SET_LOADING_MSG,
          "Chargement des détails du bon de sortie..."
        );
        commit(
          DeliveryFormMutations.SET_DELIVERY_FORM_TAB_ACTIVE,
          "delivery-form-edit"
        );
        commit(DeliveryFormMutations.SET_INITIAL_DELIVERY_FORM, null);
      }
      axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/DeliveryForm/GetDeliveryFormById`,
          {
            params: {
              deliveryFormId: deliveryFormId,
            },
          }
        )
        .then(async (res) => {
          if (updateState) {
            await dispatch("getDeliveryFormDetailsByDeliveryFormId", {
              deliveryFormId: deliveryFormId,
            });
            dispatch("getDeliveryFormOptionByDeliveryFormId", {
              deliveryFormId: deliveryFormId,
            });
            commit(DeliveryFormMutations.SET_IS_LOADING_DELIVERY_FORM, false);
            dispatch("getDeliveryFormHistoriesByDeliveryFormId", {
              deliveryFormId: deliveryFormId,
            });
            commit(DeliveryFormMutations.SET_DELIVERY_FORM, res.data); //, documentTvas: []
          }
          commit(DeliveryFormMutations.SET_REQUEST_STATE_DELIVERY_FORM, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(DeliveryFormMutations.SET_IS_LOADING_DELIVERY_FORM, false);
          }
          commit(DeliveryFormMutations.SET_REQUEST_STATE_DELIVERY_FORM, err);
          reject(err);
        });
    });
  },

  getDeliveryFormsArchived(
    { commit, rootGetters },
    {
      affairId,
      companyId,
      updateState = true,
    }: { affairId: number; companyId: number; updateState?: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          DeliveryFormMutations.SET_IS_LOADING_DELIVERY_FORM_LIST_ARCHIVED,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/DeliveryForm/GetDeliveryFormsArchived`;
      axios
        .get(url, {
          params: {
            affairId: affairId,
            companyId: companyId,
          },
        })
        .then((res) => {
          let list = res.data.map((item) => {
            return {
              ...item,
              documentDate: item.documentDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(item.documentDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
            };
          });
          if (updateState) {
            commit(
              DeliveryFormMutations.SET_DELIVERY_FORMS_LIST_ARCHIVED,
              list
            );
            commit(
              DeliveryFormMutations.SET_IS_LOADING_DELIVERY_FORM_LIST_ARCHIVED,
              false
            );
          }
          commit(DeliveryFormMutations.SET_REQUEST_STATE_DELIVERY_FORM, res);
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              DeliveryFormMutations.SET_IS_LOADING_DELIVERY_FORM_LIST_ARCHIVED,
              false
            );
          }
          commit(DeliveryFormMutations.SET_REQUEST_STATE_DELIVERY_FORM, err);
          reject(err);
        });
    });
  },

  getDeliveryFormsArchivedById(
    { commit, rootGetters },
    {
      deliveryFormId,
      updateState = true,
    }: { deliveryFormId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          DeliveryFormMutations.SET_IS_LOADING_DELIVERY_FORM_ARCHIVED,
          true
        );
      }
      axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/DeliveryForm/GetDeliveryFormsArchivedById`,
          {
            params: {
              deliveryFormId: deliveryFormId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(DeliveryFormMutations.SET_DELIVERY_FORM_ARCHIVED, res.data);
            commit(
              DeliveryFormMutations.SET_IS_LOADING_DELIVERY_FORM_ARCHIVED,
              false
            );
          }
          commit(DeliveryFormMutations.SET_REQUEST_STATE_DELIVERY_FORM, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DeliveryFormMutations.SET_REQUEST_STATE_DELIVERY_FORM, err);
          if (updateState) {
            commit(
              DeliveryFormMutations.SET_IS_LOADING_DELIVERY_FORM_ARCHIVED,
              false
            );
          }
          reject(err);
        });
    });
  },

  getLastDeliveryFormReference(
    { commit, rootGetters },
    { updateState = true }: { updateState: boolean }
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/DeliveryForm/GetLastDocumentReference"
        )
        .then((res) => {
          if (updateState) {
            commit(
              DeliveryFormMutations.SET_LAST_DELIVERY_FORM_REFERENCE,
              res.data
            );
          }
          commit(DeliveryFormMutations.SET_REQUEST_STATE_DELIVERY_FORM, res);
          resolve(res);
        })
        .catch((err) => {
          console.error(err);
          commit(DeliveryFormMutations.SET_REQUEST_STATE_DELIVERY_FORM, err);
          reject(err);
        });
    });
  },

  getDeliveryFormEmpty(
    { commit, rootGetters, dispatch },
    {
      affairId = null,
      userId = rootGetters.connectedUser.id,
      customerId = null,
      collaboratorId = null,
      storageLocationId = null,
      destockingLocationId = null,
      handDeliveryContactId = null,
      subContractorId = null,
      deliveryType = null,
      updateState = true,
    }: {
      affairId: number | null;
      userId: number;
      customerId: number | null;
      collaboratorId: number | null;
      storageLocationId: number | null;
      destockingLocationId: number | null;
      handDeliveryContactId: number | null;
      subContractorId: number | null;
      deliveryType: number | null;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (updateState) {
        commit(DeliveryFormMutations.SET_IS_CREATING_DELIVERY_FORM, true);
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/DeliveryForm/GetDeliveryFormEmpty",
          {
            params: {
              affairId: affairId,
              userId: userId,
              customerId: customerId,
              collaboratorId: collaboratorId,
              storageLocationId: storageLocationId,
              destockingLocationId: destockingLocationId,
              handDeliveryContactId: handDeliveryContactId,
              subContractorId: subContractorId,
              deliveryType: deliveryType,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            if (res.data.status == 3) {
              let deliveryFormFilters = rootGetters.userParametersList.find(
                (elem) => elem.key == "deliveryFormFilters"
              );
              if (deliveryFormFilters) {
                dispatch("getDeliveryForms", {
                  dateFrom: JSON.parse(deliveryFormFilters.value).dateRange
                    .startDate,
                  dateTo: JSON.parse(deliveryFormFilters.value).dateRange
                    .endDate,
                });
              } else {
                dispatch("setFilter", {
                  name: "deliveryFormFilters",
                  filter: {
                    dateRangeType: 7,
                    dateRange: { startDate: startDate, endDate: endDate },
                  },
                });
                dispatch("getDeliveryForms", {
                  dateFrom: startDate,
                  dateTo: endDate,
                });
              }
            }
            commit(DeliveryFormMutations.SET_IS_CREATING_DELIVERY_FORM, false);
          }
          commit(DeliveryFormMutations.SET_REQUEST_STATE_DELIVERY_FORM, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DeliveryFormMutations.SET_REQUEST_STATE_DELIVERY_FORM, err);
          commit(DeliveryFormMutations.SET_IS_CREATING_DELIVERY_FORM, false);
          reject(err);
        });
    });
  },

  createDeliveryForm(
    { commit, rootGetters, dispatch },
    { deliveryForm }: { deliveryForm: DeliveryFormRequestDto }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(DeliveryFormMutations.SET_IS_CREATING_DELIVERY_FORM, true);
      axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Purchase/DeliveryForm/CreateDeliveryForm`,
          deliveryForm
        )
        .then((res) => {
          let deliveryFormFilters = rootGetters.userParametersList.find(
            (elem) => elem.key == "deliveryFormFilters"
          );
          if (deliveryFormFilters) {
            dispatch("getDeliveryForms", {
              dateFrom: JSON.parse(deliveryFormFilters.value).dateRange
                .startDate,
              dateTo: JSON.parse(deliveryFormFilters.value).dateRange.endDate,
            });
          } else {
            dispatch("setFilter", {
              name: "deliveryFormFilters",
              filter: {
                dateRangeType: 7,
                dateRange: { startDate: startDate, endDate: endDate },
              },
            });
            dispatch("getDeliveryForms", {
              dateFrom: startDate,
              dateTo: endDate,
            });
          }
          commit(DeliveryFormMutations.SET_REQUEST_STATE_DELIVERY_FORM, res);
          commit(DeliveryFormMutations.SET_IS_CREATING_DELIVERY_FORM, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DeliveryFormMutations.SET_REQUEST_STATE_DELIVERY_FORM, err);
          commit(DeliveryFormMutations.SET_IS_CREATING_DELIVERY_FORM, false);
          reject(err);
        });
    });
  },

  duplicateDeliveryForm(
    { commit, rootGetters },
    {
      deliveryFormId,
      userId = rootGetters.connectedUser.id,
    }: { deliveryFormId: number; userId: number }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(DeliveryFormMutations.SET_IS_CREATING_DELIVERY_FORM, true);
      axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/DeliveryForm/DuplicateDeliveryForm",
          {},
          {
            params: {
              deliveryFormId: deliveryFormId,
              userId: userId,
            },
          }
        )
        .then((res) => {
          commit(DeliveryFormMutations.SET_DELIVERY_FORM, res.data);
          commit(DeliveryFormMutations.SET_IS_CREATING_DELIVERY_FORM, false);
          commit(DeliveryFormMutations.SET_REQUEST_STATE_DELIVERY_FORM, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DeliveryFormMutations.SET_REQUEST_STATE_DELIVERY_FORM, err);
          commit(DeliveryFormMutations.SET_IS_CREATING_DELIVERY_FORM, false);
          reject(err);
        });
    });
  },

  updateDeliveryForm(
    { commit, rootGetters, dispatch },
    {
      deliveryForm,
      updateState = true,
    }: { deliveryForm: DeliveryFormRequestDto; updateState: boolean }
  ) {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(DeliveryFormMutations.SET_IS_UPDATING_DELIVERY_FORM, true);
      }
      axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/DeliveryForm/UpdateDeliveryForm`,
          { ...deliveryForm, currencyId: 1 }
        )
        .then((res) => {
          let dataDeliveryForm = { ...res.data.data };
          commit(DeliveryFormMutations.SET_DELIVERY_FORM, dataDeliveryForm);
          let deliveryFormFilters = rootGetters.userParametersList.find(
            (elem) => elem.key == "deliveryFormFilters"
          );
          if (deliveryFormFilters) {
            dispatch("getDeliveryForms", {
              dateFrom: JSON.parse(deliveryFormFilters.value).dateRange
                .startDate,
              dateTo: JSON.parse(deliveryFormFilters.value).dateRange.endDate,
            });
          } else {
            dispatch("setFilter", {
              name: "deliveryFormFilters",
              filter: {
                dateRangeType: 7,
                dateRange: { startDate: startDate, endDate: endDate },
              },
            });
            dispatch("getDeliveryForms", {
              dateFrom: startDate,
              dateTo: endDate,
            });
          }
          commit(DeliveryFormMutations.SET_REQUEST_STATE_DELIVERY_FORM, res);
          if (updateState) {
            commit(DeliveryFormMutations.SET_IS_UPDATING_DELIVERY_FORM, false);
          } else {
            rootGetters.deliveryFormsList.splice(
              rootGetters.deliveryFormsList.findIndex(
                (elem) => elem.id == deliveryForm.id
              ),
              1,
              dataDeliveryForm
            );
            commit(
              DeliveryFormMutations.SET_DELIVERY_FORMS_LIST,
              rootGetters.deliveryFormsList
            );
          }
          resolve(dataDeliveryForm);
        })
        .catch((err) => {
          console.error(err);
          commit(DeliveryFormMutations.SET_REQUEST_STATE_DELIVERY_FORM, err);
          if (updateState) {
            commit(DeliveryFormMutations.SET_IS_UPDATING_DELIVERY_FORM, false);
          }
          reject(err);
        });
    });
  },

  uploadFileDeliveryForm(
    { commit, rootGetters, dispatch },
    { formData, deliveryFormId }: { formData: FormData; deliveryFormId: number }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(FileMutations.SET_IS_CREATING_FILE, true);
      axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/DeliveryForm/UploadFile?deliveryFormId=${deliveryFormId}`,
          formData
        )
        .then(async (res) => {
          if (!rootGetters.deliveryForm.folderId) {
            await dispatch("getDeliveryFormById", {
              deliveryFormId: deliveryFormId,
            });
          }
          commit(FolderMutations.SET_FOLDER, rootGetters.folder);
          commit(FileMutations.SET_REQUEST_STATE_FILE, res);
          commit(FileMutations.SET_IS_CREATING_FILE, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(FileMutations.SET_REQUEST_STATE_FILE, err);
          commit(FileMutations.SET_IS_CREATING_FILE, false);
          reject(err);
        });
    });
  },

  uploadPathDeliveryForm(
    { commit, rootGetters },
    { deliveryFormId, path }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(DeliveryFormMutations.SET_IS_UPDATING_DELIVERY_FORM, true);
      axios
        .put(
          `${
            process.env.VUE_APP_API_ORISIS
          }Purchase/DeliveryForm/UploadPathDocument?deliveryFormId=${deliveryFormId}&path=${encodeURIComponent(
            path
          )}`
        )
        .then((res) => {
          let dataDeliveryForm = res.data;
          rootGetters.deliveryFormsList.splice(
            rootGetters.deliveryFormsList.findIndex(
              (elem) => elem.id == deliveryFormId
            ),
            1,
            dataDeliveryForm
          );
          commit(
            DeliveryFormMutations.SET_DELIVERY_FORMS_LIST,
            rootGetters.deliveryFormsList
          );
          commit(DeliveryFormMutations.SET_DELIVERY_FORM, dataDeliveryForm);
          commit(DeliveryFormMutations.SET_REQUEST_STATE_DELIVERY_FORM, res);
          commit(DeliveryFormMutations.SET_IS_UPDATING_DELIVERY_FORM, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DeliveryFormMutations.SET_REQUEST_STATE_DELIVERY_FORM, err);
          commit(DeliveryFormMutations.SET_IS_UPDATING_DELIVERY_FORM, false);
          reject(err);
        });
    });
  },

  updateDeliveryFormTagDeliveryForm(
    { commit, rootGetters },
    { deliveryFormIds, deliveryFormTagIds }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/DeliveryForm/UpdateDeliveryFormTagDeliveryForm`,
          {
            deliveryFormIds: deliveryFormIds,
            deliveryFormTagIds: deliveryFormTagIds,
          }
        )
        .then((res) => {
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          reject(err);
        });
    });
  },

  restoreDeliveryForm(
    { commit, rootGetters },
    { deliveryFormIds }: { deliveryFormIds: number[] }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(DeliveryFormMutations.SET_IS_RESTORING_DELIVERY_FORM, true);
      axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/DeliveryForm/Restore`,
          deliveryFormIds
        )
        .then((res) => {
          for (let i = 0; i < deliveryFormIds.length; i++) {
            const id = deliveryFormIds[i];
            rootGetters.deliveryFormsList.push(
              rootGetters.deliveryFormsListArchived.find(
                (elem) => elem.id == id
              )
            );
            rootGetters.deliveryFormsListArchived.splice(
              rootGetters.deliveryFormsListArchived.findIndex(
                (elem) => elem.id == id
              ),
              1
            );
          }
          commit(
            DeliveryFormMutations.SET_DELIVERY_FORMS_LIST,
            rootGetters.deliveryFormsList
          );
          commit(
            DeliveryFormMutations.SET_DELIVERY_FORMS_LIST_ARCHIVED,
            rootGetters.deliveryFormsListArchived
          );
          commit(DeliveryFormMutations.SET_REQUEST_STATE_DELIVERY_FORM, res);
          commit(DeliveryFormMutations.SET_IS_RESTORING_DELIVERY_FORM, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DeliveryFormMutations.SET_REQUEST_STATE_DELIVERY_FORM, err);
          commit(DeliveryFormMutations.SET_IS_RESTORING_DELIVERY_FORM, false);
          reject(err);
        });
    });
  },

  archiveDeliveryForm(
    { commit, rootGetters },
    { deliveryFormIds }: { deliveryFormIds: number[] }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(DeliveryFormMutations.SET_IS_ARCHIVING_DELIVERY_FORM, true);
      axios
        .delete(
          `${process.env.VUE_APP_API_ORISIS}Purchase/DeliveryForm/Archive`,
          {
            data: deliveryFormIds,
          }
        )
        .then((res) => {
          for (let i = 0; i < deliveryFormIds.length; i++) {
            const id = deliveryFormIds[i];
            rootGetters.deliveryFormsListArchived.push(
              rootGetters.deliveryFormsList.find((elem) => elem.id == id)
            );
            rootGetters.deliveryFormsList.splice(
              rootGetters.deliveryFormsList.findIndex((elem) => elem.id == id),
              1
            );
          }
          commit(
            DeliveryFormMutations.SET_DELIVERY_FORMS_LIST,
            rootGetters.deliveryFormsList
          );
          commit(
            DeliveryFormMutations.SET_DELIVERY_FORMS_LIST_ARCHIVED,
            rootGetters.deliveryFormsListArchived
          );
          commit(DeliveryFormMutations.SET_REQUEST_STATE_DELIVERY_FORM, res);
          commit(DeliveryFormMutations.SET_IS_ARCHIVING_DELIVERY_FORM, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DeliveryFormMutations.SET_REQUEST_STATE_DELIVERY_FORM, err);
          commit(DeliveryFormMutations.SET_IS_ARCHIVING_DELIVERY_FORM, false);
          reject(err);
        });
    });
  },

  deleteDeliveryForm(
    { commit, rootGetters, dispatch },
    { deliveryFormIds }: { deliveryFormIds: number[] }
  ) {
    return new Promise<void>((resolve, reject) => {
      commit(DeliveryFormMutations.SET_IS_DELETING_DELIVERY_FORM, true);
      axios
        .delete(
          `${process.env.VUE_APP_API_ORISIS}Purchase/DeliveryForm/Delete`,
          {
            data: deliveryFormIds,
          }
        )
        .then((res) => {
          let deliveryFormFilters = rootGetters.userParametersList.find(
            (elem) => elem.key == "deliveryFormFilters"
          );
          if (deliveryFormFilters) {
            dispatch("getDeliveryForms", {
              dateFrom: JSON.parse(deliveryFormFilters.value).dateRange
                .startDate,
              dateTo: JSON.parse(deliveryFormFilters.value).dateRange.endDate,
            });
          } else {
            dispatch("setFilter", {
              name: "deliveryFormFilters",
              filter: {
                dateRangeType: 7,
                dateRange: { startDate: startDate, endDate: endDate },
              },
            });
            dispatch("getDeliveryForms", {
              dateFrom: startDate,
              dateTo: endDate,
            });
          }
          commit(DeliveryFormMutations.SET_REQUEST_STATE_DELIVERY_FORM, res);
          commit(DeliveryFormMutations.SET_IS_DELETING_DELIVERY_FORM, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DeliveryFormMutations.SET_REQUEST_STATE_DELIVERY_FORM, err);
          commit(DeliveryFormMutations.SET_IS_DELETING_DELIVERY_FORM, false);
          reject(err);
        });
    });
  },

  async generateDeliveryFormPDF({ commit, rootGetters, dispatch }, data) {
    return new Promise(async (resolve, reject) => {
      commit(DeliveryFormMutations.SET_IS_LOADING_DELIVERY_FORM_PDF, true);
      await axios
        .post(`${process.env.VUE_APP_PDF_GENERATOR_URL}generate`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(async (res) => {
          // Si le document est finalisé
          if (rootGetters.deliveryForm.status == 3) {
            await dispatch("uploadPathDeliveryForm", {
              deliveryFormId: rootGetters.deliveryForm.id,
              path: res.data,
            });
          }

          commit(DeliveryFormMutations.SET_DELIVERY_FORM_PDF, res.data);
          commit(DeliveryFormMutations.SET_REQUEST_STATE_DELIVERY_FORM, res);
          commit(DeliveryFormMutations.SET_IS_LOADING_DELIVERY_FORM_PDF, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DeliveryFormMutations.SET_REQUEST_STATE_DELIVERY_FORM, err);
          commit(DeliveryFormMutations.SET_IS_LOADING_DELIVERY_FORM_PDF, false);
          reject(err);
        });
    });
  },

  changeDeliveryFormTabActive({ commit, dispatch }, tabToActivate: string) {
    commit(DeliveryFormMutations.SET_DELIVERY_FORM_TAB_ACTIVE, tabToActivate);
    dispatch("setupHeaderDeliveryForm");
  },

  setupHeaderDeliveryForm({ rootGetters }) {
    let delivery = rootGetters.deliveryFormOption;
    let header = rootGetters.getDeliveryFormHeader;
    header["unitPriceHt"].display = !delivery.hideUnitPriceHtColumn;
    header["referencielTvaId"].display = !delivery.hideReferencielTvaColumn;
    header["total"].display = !delivery.hidePriceHtColumn;
    header["reference"].display = delivery.showReferenceColumn;
    header["index"].display = !delivery.hideIndexColumn;
    header["unitId"].display = !delivery.hideUnitColumn;
    header["outPutQuantity"].display = !delivery.hideOutPutQuantity;
  },
};
