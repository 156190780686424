import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { CustomFieldTemplateRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { ICustomFieldTemplateState } from "./states";

export enum CustomFieldTemplateMutations {
    SET_CUSTOM_FIELD_TEMPLATES_LIST = "SET_CUSTOM_FIELD_TEMPLATES_LIST",
    SET_CUSTOM_FIELD_TEMPLATES_LIST_ARCHIVED = "SET_CUSTOM_FIELD_TEMPLATES_LIST_ARCHIVED",
    SET_CUSTOM_FIELD_TEMPLATE = "SET_CUSTOM_FIELD_TEMPLATE",

    //FILTERS
    SET_CUSTOM_FIELD_TEMPLATE_FILTERS = "SET_CUSTOM_FIELD_TEMPLATE_FILTERS",

    // COMMON
    SET_IS_LOADING_CUSTOM_FIELD_TEMPLATE_LIST = "SET_IS_LOADING_CUSTOM_FIELD_TEMPLATE_LIST",
    SET_IS_LOADING_CUSTOM_FIELD_TEMPLATE_LIST_ARCHIVED = "SET_IS_LOADING_CUSTOM_FIELD_TEMPLATE_LIST_ARCHIVED",
    SET_IS_LOADING_CUSTOM_FIELD_TEMPLATE = "SET_IS_LOADING_CUSTOM_FIELD_TEMPLATE",
    SET_IS_CREATING_CUSTOM_FIELD_TEMPLATE = "SET_IS_CREATING_CUSTOM_FIELD_TEMPLATE",
    SET_IS_UPDATING_CUSTOM_FIELD_TEMPLATE = "SET_IS_UPDATING_CUSTOM_FIELD_TEMPLATE",
    SET_IS_DELETING_CUSTOM_FIELD_TEMPLATE = "SET_IS_DELETING_CUSTOM_FIELD_TEMPLATE",
    SET_IS_ARCHIVING_CUSTOM_FIELD_TEMPLATE = "SET_IS_ARCHIVING_CUSTOM_FIELD_TEMPLATE",
    SET_REQUEST_STATE_CUSTOM_FIELD_TEMPLATE = "SET_REQUEST_STATE_CUSTOM_FIELD_TEMPLATE",
}

export const mutations: MutationTree<ICustomFieldTemplateState> = {
    [CustomFieldTemplateMutations.SET_CUSTOM_FIELD_TEMPLATES_LIST]: (state, payload: CustomFieldTemplateRequestDto[]) => {
        state.customFieldTemplatesList = payload;
    },
    [CustomFieldTemplateMutations.SET_CUSTOM_FIELD_TEMPLATES_LIST_ARCHIVED]: (state, payload: CustomFieldTemplateRequestDto[]) => {
        state.customFieldTemplatesListArchived = payload;
    }, 
    [CustomFieldTemplateMutations.SET_CUSTOM_FIELD_TEMPLATE]: (state, payload: CustomFieldTemplateRequestDto) => {
        state.customFieldTemplate = payload;
    },

    //FILTERS
    [CustomFieldTemplateMutations.SET_CUSTOM_FIELD_TEMPLATE_FILTERS]: (state, payload: any) => {
        state.customFieldTemplateFilters = payload;
    },


    //COMMON
    [CustomFieldTemplateMutations.SET_IS_LOADING_CUSTOM_FIELD_TEMPLATE_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingCustomFieldTemplatesListArchived = payload;
    },
    [CustomFieldTemplateMutations.SET_IS_LOADING_CUSTOM_FIELD_TEMPLATE_LIST]: (state, payload: boolean) => {
        state.isLoadingCustomFieldTemplatesList = payload;
    },
    [CustomFieldTemplateMutations.SET_IS_LOADING_CUSTOM_FIELD_TEMPLATE]: (state, payload: boolean) => {
        state.isLoadingCustomFieldTemplate = payload;
    },
    [CustomFieldTemplateMutations.SET_IS_CREATING_CUSTOM_FIELD_TEMPLATE]: (state, payload: boolean) => {
        state.isCreatingCustomFieldTemplate = payload;
    },
    [CustomFieldTemplateMutations.SET_IS_UPDATING_CUSTOM_FIELD_TEMPLATE]: (state, payload: boolean) => {
        state.isUpdatingCustomFieldTemplate = payload;
    },
    [CustomFieldTemplateMutations.SET_IS_DELETING_CUSTOM_FIELD_TEMPLATE]: (state, payload: boolean) => {
        state.isDeletingCustomFieldTemplate = payload;
    },
    [CustomFieldTemplateMutations.SET_IS_ARCHIVING_CUSTOM_FIELD_TEMPLATE]: (state, payload: boolean) => {
        state.isArchivingCustomFieldTemplate = payload;
    },
    [CustomFieldTemplateMutations.SET_REQUEST_STATE_CUSTOM_FIELD_TEMPLATE]: (state, payload: IRequestState) => {
        state.requestStateCustomFieldTemplate = payload;
    },
}