import { IOrderFormCustomerDownPaymentsRequestState } from "./states";
import { MutationTree } from "vuex";
import { OrderFormCustomerDownPaymentsRequestRequestDto } from "Api";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export enum OrderFormCustomerDownPaymentsRequestMutations {
  SET_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUESTS_LIST = "SET_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUESTS_LIST",
  SET_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUESTS_LIST_ARCHIVED = "SET_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUESTS_LIST_ARCHIVED",
  SET_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST = "SET_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST",

  // COMON
  SET_IS_LOADING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUESTS_LIST = "SET_IS_LOADING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUESTS_LIST",
  SET_IS_LOADING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUESTS_LIST_ARCHIVED = "SET_IS_LOADING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUESTS_LIST_ARCHIVED",
  SET_IS_LOADING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST = "SET_IS_LOADING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST",
  SET_IS_CREATING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST = "SET_IS_CREATING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST",
  SET_IS_UPDATING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST = "SET_IS_UPDATING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST",
  SET_IS_DELETING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST = "SET_IS_DELETING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST",
  SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST = "SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST",
  SET_IS_ARCHIVING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST = "SET_IS_ARCHIVING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST",  
}

  export const mutations: MutationTree<IOrderFormCustomerDownPaymentsRequestState> = {
    [OrderFormCustomerDownPaymentsRequestMutations.SET_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUESTS_LIST]: (state, payload: OrderFormCustomerDownPaymentsRequestRequestDto[]) => {
        state.orderFormCustomerDownPaymentsRequestsList = payload;
    },
    [OrderFormCustomerDownPaymentsRequestMutations.SET_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUESTS_LIST_ARCHIVED]: (state, payload: OrderFormCustomerDownPaymentsRequestRequestDto[]) => {
        state.orderFormCustomerDownPaymentsRequestsListArchived = payload;
    },
    [OrderFormCustomerDownPaymentsRequestMutations.SET_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST]: (state, payload: OrderFormCustomerDownPaymentsRequestRequestDto) => {
        state.orderFormCustomerDownPaymentsRequest = payload;
    },


    //COMMON
    [OrderFormCustomerDownPaymentsRequestMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUESTS_LIST]: (state, payload: boolean) => {
        state.isLoadingOrderFormCustomerDownPaymentsRequestsList = payload;
    },
    [OrderFormCustomerDownPaymentsRequestMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUESTS_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingOrderFormCustomerDownPaymentsRequestsListArchived = payload;
    },
    [OrderFormCustomerDownPaymentsRequestMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST]: (state, payload: boolean) => {
        state.isLoadingOrderFormCustomerDownPaymentsRequest = payload;
    },
    [OrderFormCustomerDownPaymentsRequestMutations.SET_IS_CREATING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST]: (state, payload: boolean) => {
        state.isCreatingOrderFormCustomerDownPaymentsRequest = payload;
    },
    [OrderFormCustomerDownPaymentsRequestMutations.SET_IS_UPDATING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST]: (state, payload: boolean) => {
        state.isUpdatingOrderFormCustomerDownPaymentsRequest = payload;
    },
    [OrderFormCustomerDownPaymentsRequestMutations.SET_IS_DELETING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST]: (state, payload: boolean) => {
        state.isDeletingOrderFormCustomerDownPaymentsRequest = payload;
    },
    [OrderFormCustomerDownPaymentsRequestMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST]: (state, payload: IRequestState) => {
        state.requestStateOrderFormCustomerDownPaymentsRequest = payload;
    },
    [OrderFormCustomerDownPaymentsRequestMutations.SET_IS_ARCHIVING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST]: (state, payload: boolean) => {
        state.isArchivingOrderFormCustomerDownPaymentsRequest = payload;
    }
}