import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { QuoteOptionDefaultMutations } from "./mutations";
import { IQuoteOptionDefaultState } from "./states";
import { QuoteOptionRequestDto } from "Api";

export const actions: ActionTree<IQuoteOptionDefaultState, RootState> = {
  getQuoteOptionDefaultByQuoteId(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          QuoteOptionDefaultMutations.SET_IS_LOADING_QUOTE_OPTION_DEFAULT,
          true
        );
      }
      const url = `${process.env.VUE_APP_API_ORISIS}Sale/QuoteOptionDefault/GetDefault`;
      axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              QuoteOptionDefaultMutations.SET_QUOTE_OPTION_DEFAULT,
              res.data
            );
            commit(
              QuoteOptionDefaultMutations.SET_QUOTE_OPTION_DEFAULT_FORM,
              res.data
            );
            commit(
              QuoteOptionDefaultMutations.SET_IS_LOADING_QUOTE_OPTION_DEFAULT,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              QuoteOptionDefaultMutations.SET_IS_LOADING_QUOTE_OPTION_DEFAULT,
              false
            );
          }
          reject(err);
        });
    });
  },

  createQuoteOptionDefault(
    { commit },
    {
      quoteOptionDefault,
      updateState = true,
    }: {
      quoteOptionDefault: QuoteOptionRequestDto;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          QuoteOptionDefaultMutations.SET_IS_LOADING_QUOTE_OPTION_DEFAULT,
          true
        );
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/QuoteOptionDefault/CreateQuoteOptionDefault`;

      axios
        .post(url, quoteOptionDefault)
        .then((res) => {
          if (updateState) {
            commit(
              QuoteOptionDefaultMutations.SET_QUOTE_OPTION_DEFAULT,
              res.data
            );
            commit(
              QuoteOptionDefaultMutations.SET_IS_LOADING_QUOTE_OPTION_DEFAULT,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              QuoteOptionDefaultMutations.SET_IS_LOADING_QUOTE_OPTION_DEFAULT,
              false
            );
          }
          reject(err);
        });
    });
  },

  updateQuoteOptionDefault(
    { commit },
    {
      quoteOptionDefault,
      updateState = true,
    }: {
      quoteOptionDefault: QuoteOptionRequestDto;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          QuoteOptionDefaultMutations.SET_IS_LOADING_QUOTE_OPTION_DEFAULT,
          true
        );
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/QuoteOptionDefault/UpdateQuoteOptionDefault`;

      axios
        .put(url, quoteOptionDefault)
        .then((res) => {
          if (updateState) {
            commit(
              QuoteOptionDefaultMutations.SET_QUOTE_OPTION_DEFAULT,
              res.data
            );
            commit(
              QuoteOptionDefaultMutations.SET_IS_LOADING_QUOTE_OPTION_DEFAULT,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              QuoteOptionDefaultMutations.SET_IS_LOADING_QUOTE_OPTION_DEFAULT,
              false
            );
          }
          reject(err);
        });
    });
  },
};
