import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { InterventionRequestDto } from "@/../Api";
import { MutationTree } from "vuex";
import { IInterventionState } from "./states";

export enum InterventionMutations {
  SET_INTERVENTIONS_LIST = "SET_INTERVENTIONS_LIST",
  SET_INTERVENTIONS_LIST_BY_COLLABORATOR_ID = "SET_INTERVENTIONS_LIST_BY_COLLABORATOR_ID",
  SET_INTERVENTION = "SET_INTERVENTION",

  //FILTERS
  SET_INTERVENTION_FILTERS = "SET_INTERVENTION_FILTERS",
  SET_INTERVENTION_FILTERS_BY_COLLABORATOR = "SET_INTERVENTION_FILTERS_BY_COLLABORATOR",

  // COMMON
  SET_IS_LOADING_INTERVENTIONS_LIST = "SET_IS_LOADING_INTERVENTIONS_LIST",
  SET_IS_LOADING_INTERVENTIONS_LIST_BY_COLLABORATOR_ID = "SET_IS_LOADING_INTERVENTIONS_LIST_BY_COLLABORATOR_ID",
  SET_IS_LOADING_INTERVENTION = "SET_IS_LOADING_INTERVENTION",
  SET_IS_CREATING_INTERVENTION = "SET_IS_CREATING_INTERVENTION",
  SET_IS_UPDATING_INTERVENTION = "SET_IS_UPDATING_INTERVENTION",
  SET_IS_DELETING_INTERVENTION = "SET_IS_DELETING_INTERVENTION",
  SET_REQUEST_STATE_INTERVENTION = "SET_REQUEST_STATE_INTERVENTION",
}

export const mutations: MutationTree<IInterventionState> = {
  [InterventionMutations.SET_INTERVENTIONS_LIST]: (
    state,
    payload: InterventionRequestDto[]
  ) => {
    state.interventionsList = payload;
  },
  [InterventionMutations.SET_INTERVENTIONS_LIST_BY_COLLABORATOR_ID]: (
    state,
    payload: InterventionRequestDto[]
  ) => {
    state.interventionsListByCollaboratorId = payload;
  },
  [InterventionMutations.SET_INTERVENTION]: (
    state,
    payload: InterventionRequestDto
  ) => {
    state.intervention = payload;
  },

  //FILTERS
  [InterventionMutations.SET_INTERVENTION_FILTERS]: (state, payload: any) => {
    state.interventionFilters = payload;
  },
  [InterventionMutations.SET_INTERVENTION_FILTERS_BY_COLLABORATOR]: (
    state,
    payload: any
  ) => {
    state.interventionFiltersByCollaborator = payload;
  },

  //COMMON
  [InterventionMutations.SET_IS_LOADING_INTERVENTIONS_LIST]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingInterventionsList = payload;
  },
  [InterventionMutations.SET_IS_LOADING_INTERVENTIONS_LIST_BY_COLLABORATOR_ID]:
    (state, payload: boolean) => {
      state.isLoadingInterventionsListByCollaboratorId = payload;
    },
  [InterventionMutations.SET_IS_LOADING_INTERVENTION]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingIntervention = payload;
  },
  [InterventionMutations.SET_IS_CREATING_INTERVENTION]: (
    state,
    payload: boolean
  ) => {
    state.isCreatingIntervention = payload;
  },
  [InterventionMutations.SET_IS_UPDATING_INTERVENTION]: (
    state,
    payload: boolean
  ) => {
    state.isUpdatingIntervention = payload;
  },
  [InterventionMutations.SET_IS_DELETING_INTERVENTION]: (
    state,
    payload: boolean
  ) => {
    state.isDeletingIntervention = payload;
  },
  [InterventionMutations.SET_REQUEST_STATE_INTERVENTION]: (
    state,
    payload: IRequestState
  ) => {
    state.requestStateIntervention = payload;
  },
};
