import { ICustomFieldState } from "./states"
import { Module } from "vuex"
import { CustomFieldRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:ICustomFieldState = {
    customFieldsList         : Array<CustomFieldRequestDto>(),
    customFieldsListArchived : Array<CustomFieldRequestDto>(),
    customField              : <CustomFieldRequestDto>{},
    isLoadingCustomFieldsList     : false,
    isLoadingCustomFieldsListArchived     : false,
    isLoadingCustomField     : false,
    isCreatingCustomField    : false,
    isUpdatingCustomField    : false,
    isDeletingCustomField    : false,
    isArchivingCustomField    : false,
    requestStateCustomField: <IRequestState>{},
    customFieldFilters                : {}
}

export const CustomFieldModule: Module<ICustomFieldState, RootState> = {
    state, 
    getters,
    mutations,
    actions
} 