import { JobRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { JobMutations } from "./mutations";
import { IJobState } from "./states";

export const actions: ActionTree<IJobState, RootState> = {
  async getJobs(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(JobMutations.SET_IS_LOADING_JOB_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Parameters/Job/GetJobs`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(JobMutations.SET_IS_LOADING_JOB_LIST, false);
            commit(JobMutations.SET_JOBS_LIST, res.data);
          }
          commit(JobMutations.SET_REQUEST_STATE_JOB, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(JobMutations.SET_IS_LOADING_JOB_LIST, false);
          commit(JobMutations.SET_REQUEST_STATE_JOB, err);
          reject(err);
        });
    });
  },

  async getJobsArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(JobMutations.SET_IS_LOADING_JOB_LIST_ARCHIVED, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Parameters/Job/GetJobsArchived`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(JobMutations.SET_IS_LOADING_JOB_LIST_ARCHIVED, false);
            commit(JobMutations.SET_JOBS_LIST_ARCHIVED, res.data);
          }
          commit(JobMutations.SET_REQUEST_STATE_JOB, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(JobMutations.SET_IS_LOADING_JOB_LIST_ARCHIVED, false);
          commit(JobMutations.SET_REQUEST_STATE_JOB, err);
          reject(err);
        });
    });
  },

  async getJobById(
    { commit },
    { jobId, updateState = true }: { jobId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(JobMutations.SET_IS_LOADING_JOB, true);
      }
      await axios
        .get(`${process.env.VUE_APP_API_ORISIS}Parameters/Job/GetJobById`, {
          params: {
            jobId: jobId,
          },
        })
        .then((res) => {
          if (updateState) {
            commit(JobMutations.SET_JOB, res.data);
            commit(JobMutations.SET_IS_LOADING_JOB, false);
          }
          commit(JobMutations.SET_REQUEST_STATE_JOB, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(JobMutations.SET_REQUEST_STATE_JOB, err);
          commit(JobMutations.SET_IS_LOADING_JOB, false);
          reject(err);
        });
    });
  },

  async getJobArchivedById(
    { commit },
    { jobId, updateState = true }: { jobId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(JobMutations.SET_IS_LOADING_JOB, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Parameters/Job/GetJobArchivedById`,
          {
            params: {
              jobId: jobId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(JobMutations.SET_JOB, res.data);
            commit(JobMutations.SET_IS_LOADING_JOB, false);
          }
          commit(JobMutations.SET_REQUEST_STATE_JOB, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(JobMutations.SET_REQUEST_STATE_JOB, err);
          commit(JobMutations.SET_IS_LOADING_JOB, false);
          reject(err);
        });
    });
  },

  async createJob(
    { commit, rootGetters },
    { job }: { job: JobRequestDto }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(JobMutations.SET_IS_CREATING_JOB, true);
      await axios
        .post(`${process.env.VUE_APP_API_ORISIS}Parameters/Job/CreateJob`, job)
        .then((res) => {
          rootGetters.jobsList.push(res.data.data);
          commit(JobMutations.SET_JOBS_LIST, rootGetters.jobsList);
          commit(JobMutations.SET_REQUEST_STATE_JOB, res);
          commit(JobMutations.SET_IS_CREATING_JOB, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(JobMutations.SET_REQUEST_STATE_JOB, err);
          commit(JobMutations.SET_IS_CREATING_JOB, false);
          reject(err);
        });
    });
  },

  async updateJob(
    { commit, rootGetters },
    { job }: { job: JobRequestDto }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(JobMutations.SET_IS_UPDATING_JOB, true);
      await axios
        .put(`${process.env.VUE_APP_API_ORISIS}Parameters/Job/UpdateJob`, job)
        .then((res) => {
          rootGetters.jobsList.splice(
            rootGetters.jobsList.findIndex((elem) => elem.id == job.id),
            1,
            job
          );
          commit(JobMutations.SET_JOBS_LIST, rootGetters.jobsList);
          commit(JobMutations.SET_REQUEST_STATE_JOB, res);
          commit(JobMutations.SET_IS_UPDATING_JOB, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(JobMutations.SET_REQUEST_STATE_JOB, err);
          commit(JobMutations.SET_IS_UPDATING_JOB, false);
          reject(err);
        });
    });
  },

  async restoreJobs(
    { commit, rootGetters },
    { jobIds = [] }: { jobIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(JobMutations.SET_IS_UPDATING_JOB, true);
      await axios
        .put(process.env.VUE_APP_API_ORISIS + "Parameters/Job/Restore", jobIds)
        .then((res) => {
          jobIds.forEach((jobId: number) => {
            rootGetters.jobsListArchived.splice(
              rootGetters.jobsListArchived.findIndex(
                (elem) => elem.id == jobId
              ),
              1
            );
          });
          commit(JobMutations.SET_REQUEST_STATE_JOB, res);
          commit(JobMutations.SET_IS_UPDATING_JOB, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(JobMutations.SET_REQUEST_STATE_JOB, err);
          commit(JobMutations.SET_IS_UPDATING_JOB, false);
          reject(err);
        });
    });
  },

  async archiveJobs(
    { commit, rootGetters },
    { jobIds = [] }: { jobIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(JobMutations.SET_IS_ARCHIVING_JOB, true);
      await axios
        .delete(process.env.VUE_APP_API_ORISIS + "Parameters/Job/Archive", {
          data: jobIds,
        })
        .then((res) => {
          jobIds.forEach((jobId: number) => {
            rootGetters.jobsList.splice(
              rootGetters.jobsList.findIndex((elem) => elem.id == jobId),
              1
            );
          });
          commit(JobMutations.SET_JOBS_LIST, rootGetters.jobsList);
          commit(JobMutations.SET_REQUEST_STATE_JOB, res);
          commit(JobMutations.SET_IS_ARCHIVING_JOB, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(JobMutations.SET_REQUEST_STATE_JOB, err);
          commit(JobMutations.SET_IS_ARCHIVING_JOB, false);
          reject(err);
        });
    });
  },

  async deleteJobs(
    { commit, rootGetters },
    { jobIds = [] }: { jobIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(JobMutations.SET_IS_DELETING_JOB, true);
      await axios
        .delete(`${process.env.VUE_APP_API_ORISIS}Parameters/Job/Delete`, {
          data: jobIds,
        })
        .then((res) => {
          jobIds.forEach((jobId: number) => {
            rootGetters.jobsList.splice(
              rootGetters.jobsList.findIndex((elem) => elem.id == jobId),
              1
            );
          });
          commit(JobMutations.SET_JOBS_LIST, rootGetters.jobsList);
          commit(JobMutations.SET_REQUEST_STATE_JOB, res);
          commit(JobMutations.SET_IS_DELETING_JOB, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(JobMutations.SET_REQUEST_STATE_JOB, err);
          commit(JobMutations.SET_IS_DELETING_JOB, false);
          reject(err);
        });
    });
  },
};
