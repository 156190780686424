import { PurchaseCategoryRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { PurchaseCategoryMutations } from "./mutations";
import { IPurchaseCategoryState } from "./states";

export const actions: ActionTree<IPurchaseCategoryState, RootState> = {
  async getPurchaseCategories(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          PurchaseCategoryMutations.SET_IS_LOADING_PURCHASE_CATEGORIES_LIST,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/PurchaseCategory/GetPurchaseCategories`;
      await axios
        .get(url)
        .then((res) => {
          let purchaseCategories = res.data.map((purchaseCategory: any) => {
            return {
              ...purchaseCategory,
              parentPurchaseCategoryId: purchaseCategory.parentPurchaseCategory
                ? purchaseCategory.parentPurchaseCategory.id
                : null,
              hasChild: res.data.filter(
                (el) => el.parentPurchaseCategory?.id === purchaseCategory.id
              ).length
                ? true
                : false,
            };
          });
          if (updateState) {
            commit(
              PurchaseCategoryMutations.SET_IS_LOADING_PURCHASE_CATEGORIES_LIST,
              false
            );
            commit(
              PurchaseCategoryMutations.SET_PURCHASE_CATEGORIES_LIST,
              purchaseCategories
            );
          }
          commit(
            PurchaseCategoryMutations.SET_REQUEST_STATE_PURCHASE_CATEGORY,
            res
          );
          resolve(purchaseCategories);
        })
        .catch((err) => {
          console.error(err);
          commit(
            PurchaseCategoryMutations.SET_IS_LOADING_PURCHASE_CATEGORIES_LIST,
            false
          );
          commit(
            PurchaseCategoryMutations.SET_REQUEST_STATE_PURCHASE_CATEGORY,
            err
          );
          reject(err);
        });
    });
  },

  async getPurchaseCategoryById(
    { commit },
    {
      purchaseCategoryId,
      updateState = true,
    }: { purchaseCategoryId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          PurchaseCategoryMutations.SET_IS_LOADING_PURCHASE_CATEGORY,
          true
        );
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/PurchaseCategory/GetPurchaseCategoryById`,
          {
            params: {
              purchaseCategoryId: purchaseCategoryId,
            },
          }
        )
        .then((res) => {
          let purchaseCategory = {
            ...res.data,
            parentId: res.data.parentPurchaseCategory
              ? res.data.parentPurchaseCategory.id
              : null,
          };
          if (updateState) {
            commit(
              PurchaseCategoryMutations.SET_PURCHASE_CATEGORY,
              purchaseCategory
            );
            commit(
              PurchaseCategoryMutations.SET_IS_LOADING_PURCHASE_CATEGORY,
              false
            );
          }
          commit(
            PurchaseCategoryMutations.SET_REQUEST_STATE_PURCHASE_CATEGORY,
            res
          );
          resolve(purchaseCategory);
        })
        .catch((err) => {
          console.error(err);
          commit(
            PurchaseCategoryMutations.SET_REQUEST_STATE_PURCHASE_CATEGORY,
            err
          );
          commit(
            PurchaseCategoryMutations.SET_IS_LOADING_PURCHASE_CATEGORY,
            false
          );
          reject(err);
        });
    });
  },

  async getPurchaseCategoriesTree(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          PurchaseCategoryMutations.SET_IS_LOADING_PURCHASE_CATEGORIES_TREE_LIST,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/PurchaseCategory/GetPurchaseCategoriesTree`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              PurchaseCategoryMutations.SET_IS_LOADING_PURCHASE_CATEGORIES_TREE_LIST,
              false
            );
            commit(
              PurchaseCategoryMutations.SET_PURCHASE_CATEGORIES_TREE_LIST,
              res.data
            );
          }
          commit(
            PurchaseCategoryMutations.SET_REQUEST_STATE_PURCHASE_CATEGORY,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            PurchaseCategoryMutations.SET_IS_LOADING_PURCHASE_CATEGORIES_TREE_LIST,
            false
          );
          commit(
            PurchaseCategoryMutations.SET_REQUEST_STATE_PURCHASE_CATEGORY,
            err
          );
          reject(err);
        });
    });
  },

  async getPurchasedCategoriesArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          PurchaseCategoryMutations.SET_IS_LOADING_PURCHASE_CATEGORIES_LIST_ARCHIVED,
          true
        );
        commit(
          PurchaseCategoryMutations.SET_PURCHASE_CATEGORIES_LIST_ARCHIVED,
          []
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/PurchaseCategory/GetPurchaseCategoriesArchived`;
      await axios
        .get(url)
        .then((res) => {
          let purchaseCategories = res.data.map((purchaseCategory: any) => {
            return {
              ...purchaseCategory,
              parentPurchaseCategoryId: purchaseCategory.parentPurchaseCategory
                ? purchaseCategory.parentPurchaseCategory.id
                : null,
              hasChild: res.data.filter(
                (el) => el.parentPurchaseCategory?.id === purchaseCategory.id
              ).length
                ? true
                : false,
            };
          });
          if (updateState) {
            commit(
              PurchaseCategoryMutations.SET_IS_LOADING_PURCHASE_CATEGORIES_LIST_ARCHIVED,
              false
            );
            commit(
              PurchaseCategoryMutations.SET_PURCHASE_CATEGORIES_LIST_ARCHIVED,
              purchaseCategories
            );
          }
          commit(
            PurchaseCategoryMutations.SET_REQUEST_STATE_PURCHASE_CATEGORY,
            res
          );
          resolve(purchaseCategories);
        })
        .catch((err) => {
          console.error(err);
          commit(
            PurchaseCategoryMutations.SET_IS_LOADING_PURCHASE_CATEGORIES_LIST_ARCHIVED,
            false
          );
          commit(
            PurchaseCategoryMutations.SET_REQUEST_STATE_PURCHASE_CATEGORY,
            err
          );
          reject(err);
        });
    });
  },

  async getPurchaseCategoryArchivedById(
    { commit },
    {
      purchaseCategoryId,
      updateState = true,
    }: { purchaseCategoryId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(PurchaseCategoryMutations.SET_IS_LOADING_PURCHASE_CATEGORY, true);
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/PurchaseCategory/GetPurchaseCategoryArchivedById`,
          {
            params: {
              purchaseCategoryId: purchaseCategoryId,
            },
          }
        )
        .then((res) => {
          let purchaseCategoryArchived = {
            ...res.data,
            parentId: res.data.parent ? res.data.parent.id : null,
          };
          if (updateState) {
            commit(
              PurchaseCategoryMutations.SET_PURCHASE_CATEGORY,
              purchaseCategoryArchived
            );
            commit(
              PurchaseCategoryMutations.SET_IS_LOADING_PURCHASE_CATEGORY,
              false
            );
          }
          commit(
            PurchaseCategoryMutations.SET_REQUEST_STATE_PURCHASE_CATEGORY,
            res
          );
          resolve(purchaseCategoryArchived);
        })
        .catch((err) => {
          console.error(err);
          commit(
            PurchaseCategoryMutations.SET_REQUEST_STATE_PURCHASE_CATEGORY,
            err
          );
          commit(
            PurchaseCategoryMutations.SET_IS_LOADING_PURCHASE_CATEGORY,
            false
          );
          reject(err);
        });
    });
  },

  async createPurchaseCategory(
    { commit, dispatch, rootGetters },
    { purchaseCategory }: { purchaseCategory: PurchaseCategoryRequestDto }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(PurchaseCategoryMutations.SET_IS_CREATING_PURCHASE_CATEGORY, true);
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Purchase/PurchaseCategory/CreatePurchaseCategory`,
          purchaseCategory
        )
        .then((res) => {
          // rootGetters.purchaseCategoriesList.push(res.data.data);
          dispatch("getPurchaseCategories", {});
          // commit(
          //   PurchaseCategoryMutations.SET_PURCHASE_CATEGORIES_LIST,
          //   rootGetters.purchaseCategoriesList
          // );
          // rootGetters.purchaseCategoriesTreeList.push(res.data.data);
          // commit(
          //   PurchaseCategoryMutations.SET_PURCHASE_CATEGORIES_LIST,
          //   rootGetters.purchaseCategoriesTreeList
          // );
          commit(
            PurchaseCategoryMutations.SET_REQUEST_STATE_PURCHASE_CATEGORY,
            res
          );
          commit(
            PurchaseCategoryMutations.SET_IS_CREATING_PURCHASE_CATEGORY,
            false
          );
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            PurchaseCategoryMutations.SET_REQUEST_STATE_PURCHASE_CATEGORY,
            err
          );
          commit(
            PurchaseCategoryMutations.SET_IS_CREATING_PURCHASE_CATEGORY,
            false
          );
          reject(err);
        });
    });
  },

  async updatePurchaseCategory(
    { commit, dispatch, rootGetters },
    { purchaseCategory }: { purchaseCategory: PurchaseCategoryRequestDto }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(PurchaseCategoryMutations.SET_IS_UPDATING_PURCHASE_CATEGORY, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/PurchaseCategory/UpdatePurchaseCategory`,
          purchaseCategory
        )
        .then((res) => {
          // rootGetters.purchaseCategoriesList.splice(
          //   rootGetters.purchaseCategoriesList.findIndex(
          //     (elem) => elem.id == purchaseCategory.id
          //   ),
          //   1,
          //   purchaseCategory
          // );
          dispatch("getPurchaseCategories", {});
          // commit(
          //   PurchaseCategoryMutations.SET_PURCHASE_CATEGORIES_LIST,
          //   rootGetters.purchaseCategoriesList
          // );

          commit(
            PurchaseCategoryMutations.SET_REQUEST_STATE_PURCHASE_CATEGORY,
            res
          );
          commit(
            PurchaseCategoryMutations.SET_IS_UPDATING_PURCHASE_CATEGORY,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            PurchaseCategoryMutations.SET_REQUEST_STATE_PURCHASE_CATEGORY,
            err
          );
          commit(
            PurchaseCategoryMutations.SET_IS_UPDATING_PURCHASE_CATEGORY,
            false
          );
          reject(err);
        });
    });
  },

  async restorePurchaseCategories(
    { commit, rootGetters },
    { purchaseCategoryIds = [] }: { purchaseCategoryIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(PurchaseCategoryMutations.SET_IS_UPDATING_PURCHASE_CATEGORY, true);
      axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Purchase/PurchaseCategory/Restore",
          purchaseCategoryIds
        )
        .then((res) => {
          purchaseCategoryIds.forEach((purchaseCategoryId: number) => {
            rootGetters.purchaseCategoriesListArchived.splice(
              rootGetters.purchaseCategoriesListArchived.findIndex(
                (elem) => elem.id == purchaseCategoryId
              ),
              1
            );
          });
          commit(
            PurchaseCategoryMutations.SET_REQUEST_STATE_PURCHASE_CATEGORY,
            res
          );
          commit(
            PurchaseCategoryMutations.SET_IS_UPDATING_PURCHASE_CATEGORY,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            PurchaseCategoryMutations.SET_REQUEST_STATE_PURCHASE_CATEGORY,
            err
          );
          commit(
            PurchaseCategoryMutations.SET_IS_UPDATING_PURCHASE_CATEGORY,
            false
          );
          reject(err);
        });
    });
  },

  async archivePurchaseCategories(
    { commit, rootGetters },
    { purchaseCategoryIds = [] }: { purchaseCategoryIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        PurchaseCategoryMutations.SET_IS_ARCHIVING_PURCHASE_CATEGORY,
        true
      );
      axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Purchase/PurchaseCategory/Archive",
          {
            data: purchaseCategoryIds,
          }
        )
        .then((res) => {
          purchaseCategoryIds.forEach((purchaseCategoryId: number) => {
            rootGetters.purchaseCategoriesList.splice(
              rootGetters.purchaseCategoriesListArchived.findIndex(
                (elem) => elem.id == purchaseCategoryId
              )
            );
          });
          commit(
            PurchaseCategoryMutations.SET_PURCHASE_CATEGORIES_LIST,
            rootGetters.purchaseCategoriesList
          );
          commit(
            PurchaseCategoryMutations.SET_REQUEST_STATE_PURCHASE_CATEGORY,
            res
          );
          commit(
            PurchaseCategoryMutations.SET_IS_ARCHIVING_PURCHASE_CATEGORY,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            PurchaseCategoryMutations.SET_REQUEST_STATE_PURCHASE_CATEGORY,
            err
          );
          commit(
            PurchaseCategoryMutations.SET_IS_ARCHIVING_PURCHASE_CATEGORY,
            false
          );
          reject(err);
        });
    });
  },

  async deletePurchaseCategories(
    { commit, rootGetters },
    { purchaseCategoryIds = [] }: { purchaseCategoryIds: number[] }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(PurchaseCategoryMutations.SET_IS_DELETING_PURCHASE_CATEGORY, true);
      await axios
        .delete(
          `${process.env.VUE_APP_API_ORISIS}Purchase/PurchaseCategory/Delete`,
          {
            data: purchaseCategoryIds,
          }
        )
        .then((res) => {
          purchaseCategoryIds.forEach((purchaseCategoryId: number) => {
            rootGetters.purchaseCategoriesList.splice(
              rootGetters.purchaseCategoriesList.findIndex(
                (elem) => elem.id == purchaseCategoryId
              ),
              1
            );
          });
          commit(
            PurchaseCategoryMutations.SET_PURCHASE_CATEGORIES_LIST,
            rootGetters.purchaseCategoriesList
          );
          commit(
            PurchaseCategoryMutations.SET_REQUEST_STATE_PURCHASE_CATEGORY,
            res
          );
          commit(
            PurchaseCategoryMutations.SET_IS_DELETING_PURCHASE_CATEGORY,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            PurchaseCategoryMutations.SET_REQUEST_STATE_PURCHASE_CATEGORY,
            err
          );
          commit(
            PurchaseCategoryMutations.SET_IS_DELETING_PURCHASE_CATEGORY,
            false
          );
          reject(err);
        });
    });
  },
};
