import { FolderRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { FolderMutations } from "./mutations";
import { IFolderState } from "./states";

export const actions: ActionTree<IFolderState, RootState> = {
  async getFolders(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) commit(FolderMutations.SET_IS_LOADING_FOLDER_LIST, true);
      await axios
        .get(`${process.env.VUE_APP_API_ORISIS}Ged/Folder/GetFolders`)
        .then((res) => {
          if (updateState) {
            commit(FolderMutations.SET_FOLDERS_LIST, res.data);
            commit(FolderMutations.SET_IS_LOADING_FOLDER_LIST, false);
          }
          commit(FolderMutations.SET_REQUEST_STATE_FOLDER, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(FolderMutations.SET_IS_LOADING_FOLDER_LIST, false);
          commit(FolderMutations.SET_REQUEST_STATE_FOLDER, err);
          reject(err);
        });
    });
  },

  async getFoldersTreeById(
    { commit },
    {
      folderId,
      updateState = true,
    }: { folderId: number; updateState?: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) commit(FolderMutations.SET_IS_LOADING_FOLDER_LIST, true);
      if (folderId) {
        await axios
          .get(
            `${process.env.VUE_APP_API_ORISIS}Ged/Folder/GetFoldersTreeById`,
            {
              params: {
                folderId: folderId,
              },
            }
          )
          .then((res) => {
            if (updateState) {
              commit(FolderMutations.SET_FOLDERS_LIST, res.data);
              commit(FolderMutations.SET_IS_LOADING_FOLDER_LIST, false);
            }
            commit(FolderMutations.SET_REQUEST_STATE_FOLDER, res);
            resolve(res.data);
          })
          .catch((err) => {
            console.error(err);
            commit(FolderMutations.SET_REQUEST_STATE_FOLDER, err);
            commit(FolderMutations.SET_IS_LOADING_FOLDER, false);
            reject(err);
          });
      } else {
        commit(FolderMutations.SET_FOLDERS_LIST, []);
        commit(FolderMutations.SET_IS_LOADING_FOLDER_LIST, false);
      }
    });
  },

  async getFolderByName({ rootGetters }): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      await axios
        .get(`${process.env.VUE_APP_API_ORISIS}Ged/Folder/GetFolderByName`, {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
          params: {
            seachTerm: rootGetters.workspaceSelected.uniqueIdentifier,
          },
        })
        .then((res) => {
          resolve(res.data[0].id);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },

  async getFolderById(
    { commit },
    { folderId, updateState = true }: { folderId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) commit(FolderMutations.SET_IS_LOADING_FOLDER, true);
      if (folderId) {
        await axios
          .get(`${process.env.VUE_APP_API_ORISIS}Ged/Folder/GetFolderById`, {
            params: {
              folderId: folderId,
            },
          })
          .then((res) => {
            if (updateState) {
              commit(FolderMutations.SET_FOLDER, res.data);
              commit(FolderMutations.SET_IS_LOADING_FOLDER, false);
            }
            commit(FolderMutations.SET_REQUEST_STATE_FOLDER, res);
            resolve(res.data);
          })
          .catch((err) => {
            console.error(err);
            commit(FolderMutations.SET_REQUEST_STATE_FOLDER, err);
            commit(FolderMutations.SET_IS_LOADING_FOLDER, false);
            reject(err);
          });
      } else {
        commit(FolderMutations.SET_FOLDER, {});
        commit(FolderMutations.SET_IS_LOADING_FOLDER, false);
      }
    });
  },

  async createFolder(
    { commit, rootGetters, dispatch },
    { folder }: { folder: FolderRequestDto }
  ): Promise<void> {
    commit(FolderMutations.SET_IS_CREATING_FOLDER, true);
    await axios
      .post(`${process.env.VUE_APP_API_ORISIS}Ged/Folder/CreateFolder`, folder)
      .then((res) => {
        if (rootGetters.folder) {
          rootGetters.folder.subFolders.push(res.data.data);
          commit(FolderMutations.SET_FOLDER, rootGetters.folder);
        } else {
          dispatch("getFolderById", { folderId: folder.parentId });
        }
        commit(FolderMutations.SET_REQUEST_STATE_FOLDER, res);
        commit(FolderMutations.SET_IS_CREATING_FOLDER, false);
      })
      .catch((err) => {
        console.error(err);
        commit(FolderMutations.SET_REQUEST_STATE_FOLDER, err);
        commit(FolderMutations.SET_IS_CREATING_FOLDER, false);
      });
  },

  async updateFolder(
    { commit, rootGetters },
    { folder }: { folder: FolderRequestDto }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(FolderMutations.SET_IS_UPDATING_FOLDER, true);
      await axios
        .put(`${process.env.VUE_APP_API_ORISIS}Ged/Folder/UpdateFolder`, folder)
        .then((res) => {
          if (rootGetters.folder.id == folder.parentId) {
            // Le dossier n'a pas changé de dossier il est juste renommé
            rootGetters.folder.subFolders.splice(
              rootGetters.folder.subFolders.findIndex(
                (elem) => elem.id == folder.id
              ),
              1,
              folder
            );
            commit(FolderMutations.SET_FOLDER, rootGetters.folder);
          } else {
            // Le dossier a été déplacé il faut le supprimer de la liste
            rootGetters.folder.subFolders.splice(
              rootGetters.folder.subFolders.findIndex(
                (elem) => elem.id == folder.id
              ),
              1
            );
            commit(FolderMutations.SET_FOLDER, rootGetters.folder);
          }
          commit(FolderMutations.SET_REQUEST_STATE_FOLDER, res);
          commit(FolderMutations.SET_IS_UPDATING_FOLDER, false);
        })
        .catch((err) => {
          console.error(err);
          commit(FolderMutations.SET_REQUEST_STATE_FOLDER, err);
          commit(FolderMutations.SET_IS_UPDATING_FOLDER, false);
        });
    });
  },

  async deleteFolders(
    { commit, rootGetters },
    { folderIds = [] }: { folderIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(FolderMutations.SET_IS_DELETING_FOLDER, true);
      await axios
        .delete(`${process.env.VUE_APP_API_ORISIS}Ged/Folder/DeleteFolder`, {
          data: folderIds,
        })
        .then((res) => {
          folderIds.forEach((folderId: number) => {
            rootGetters.folder.subFolders.splice(
              rootGetters.folder.subFolders.findIndex(
                (elem) => elem.id == folderId
              ),
              1
            );
          });
          commit(FolderMutations.SET_FOLDER, rootGetters.folder);
          commit(FolderMutations.SET_REQUEST_STATE_FOLDER, res);
          commit(FolderMutations.SET_IS_DELETING_FOLDER, false);
        })
        .catch((err) => {
          console.error(err);
          commit(FolderMutations.SET_REQUEST_STATE_FOLDER, err);
          commit(FolderMutations.SET_IS_DELETING_FOLDER, false);
        });
    });
  },
};
