import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { PlanningStatisticsMutations } from './mutations'
import { IPlanningStatisticsState } from './states'

export const actions: ActionTree<IPlanningStatisticsState, RootState> = {

  async fetchStatsEventTypesGlobal ({ commit, rootGetters }, {startDate, endDate}): Promise<void> {
    return new Promise<void>(async (resolve, reject) => { 
      commit(PlanningStatisticsMutations.SET_IS_LOADING_STATS_EVENT_TYPES_GLOBAL, true);
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            'Plannings/PlanningStatistics/GetStatsEventTypesGlobal',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
            params: {
              startDate:startDate,
              endDate:endDate,
            }
          }
        )
        .then(res => {
          commit(PlanningStatisticsMutations.SET_STATS_EVENT_TYPES_GLOBAL, res.data);
          commit(PlanningStatisticsMutations.SET_IS_LOADING_STATS_EVENT_TYPES_GLOBAL, false);
          resolve(res.data);
        })
        .catch(err => {
          console.error(err);
          commit(PlanningStatisticsMutations.SET_IS_LOADING_STATS_EVENT_TYPES_GLOBAL, false);
          reject(err);
        })
    })
  },

  async fetchStatsEventTypesByDay ({ commit, rootGetters }, {startDate, endDate}): Promise<void> {
    return new Promise<void>(async (resolve, reject) => { 
      commit(PlanningStatisticsMutations.SET_IS_LOADING_STATS_EVENT_TYPES_BY_DAY, true);
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            'Plannings/PlanningStatistics/GetStatsEventTypes',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
            params: {
              startDate:startDate,
              endDate:endDate,
            }
          }
        )
        .then(res => {
          commit(PlanningStatisticsMutations.SET_STATS_EVENT_TYPES_BY_DAY, res.data);
          commit(PlanningStatisticsMutations.SET_IS_LOADING_STATS_EVENT_TYPES_BY_DAY, false);
          resolve(res.data);
        })
        .catch(err => {
          console.error(err);
          commit(PlanningStatisticsMutations.SET_IS_LOADING_STATS_EVENT_TYPES_BY_DAY, false);
          reject(err);
        })
    })
  },

  async fetchDatePeriode ({ commit, rootGetters }, {startDate, endDate}): Promise<void> {
    return new Promise<void>(async (resolve, reject) => { 
      commit(PlanningStatisticsMutations.SET_IS_LOADING_DATE_PERIODE, true);
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            'Plannings/PlanningStatistics/GetDatePeriode',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
            params: {
              startDate:startDate,
              endDate:endDate
            }
          }
        )
        .then(res => {
          commit(PlanningStatisticsMutations.SET_DATE_PERIODE, res.data);
          commit(PlanningStatisticsMutations.SET_IS_LOADING_DATE_PERIODE, false);
          resolve(res.data);
        })
        .catch(err => {
          console.error(err);
          commit(PlanningStatisticsMutations.SET_IS_LOADING_DATE_PERIODE, false);
          reject(err);
        })
    })
  },
}
