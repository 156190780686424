import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
// import { CurrencyRequestDto } from "@/../Api";
import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { ICurrencyState } from "./states";

export const getters: GetterTree<ICurrencyState, RootState> = {
  currenciesList: (state): any[] => state.currenciesList,
  // currenciesList: (state): CurrencyRequestDto[] => state.currenciesList,
  currenciesListArchived: (state): any[] => state.currenciesListArchived,
  // currenciesListArchived: (state): CurrencyRequestDto[] =>
  //   state.currenciesListArchived,
  currency: (state): any => state.currency,
  // currency: (state): CurrencyRequestDto => state.currency,

  // COMMON
  isLoadingCurrenciesList: (state): boolean => state.isLoadingCurrenciesList,
  isLoadingCurrenciesListArchived: (state): boolean =>
    state.isLoadingCurrenciesListArchived,
  isLoadingCurrency: (state): boolean => state.isLoadingCurrency,
  isCreatingCurrency: (state): boolean => state.isCreatingCurrency,
  isUpdatingCurrency: (state): boolean => state.isUpdatingCurrency,
  isDeletingCurrency: (state): boolean => state.isDeletingCurrency,
  isArchivingCurrency: (state): boolean => state.isArchivingCurrency,
  requestStateCurrency: (state): IRequestState => state.requestStateCurrency,
};
