import { IModuleParameterState } from "./states";
import { MutationTree } from "vuex";
import { ModuleParameterRequestDto } from '@/../Api'
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export enum ModuleParameterMutations{
    SET_MODULE_PARAMETERS_LIST = "SET_MODULE_PARAMETERS_LIST",
    SET_MODULE_PARAMETERS_LIST_ARCHIVED = "SET_MODULE_PARAMETERS_LIST_ARCHIVED",
    SET_MODULE_PARAMETER = "SET_MODULE_PARAMETER",
    
    // COMON
    SET_IS_LOADING_MODULE_PARAMETERS_LIST = "SET_IS_LOADING_MODULE_PARAMETERS_LIST",
    SET_IS_LOADING_MODULE_PARAMETERS_LIST_ARCHIVED = "SET_IS_LOADING_MODULE_PARAMETERS_LIST_ARCHIVED",
    SET_IS_LOADING_MODULE_PARAMETER = "SET_IS_LOADING_MODULE_PARAMETER",
    SET_IS_CREATING_MODULE_PARAMETER = "SET_IS_CREATING_MODULE_PARAMETER",
    SET_IS_UPDATING_MODULE_PARAMETER = "SET_IS_UPDATING_MODULE_PARAMETER",
    SET_IS_ARCHIVING_MODULE_PARAMETER = "SET_IS_ARCHIVING_MODULE_PARAMETER",
    SET_IS_DELETING_MODULE_PARAMETER = "SET_IS_DELETING_MODULE_PARAMETER",
    SET_REQUEST_STATE_MODULE_PARAMETER = "SET_REQUEST_STATE_MODULE_PARAMETER",
  }

  export const mutations: MutationTree<IModuleParameterState> = {
    [ModuleParameterMutations.SET_MODULE_PARAMETERS_LIST]: (state, payload: ModuleParameterRequestDto[]) => {
        state.moduleParametersList = payload;
    },
    [ModuleParameterMutations.SET_MODULE_PARAMETERS_LIST_ARCHIVED]: (state, payload: ModuleParameterRequestDto[]) => {
        state.moduleParametersListArchived = payload;
    },
    [ModuleParameterMutations.SET_MODULE_PARAMETER]: (state, payload: ModuleParameterRequestDto) => {
        state.moduleParameter = payload;
    },


    //COMMON
    [ModuleParameterMutations.SET_IS_LOADING_MODULE_PARAMETERS_LIST]: (state, payload: boolean) => {
        state.isLoadingModuleParametersList = payload;
    },
    [ModuleParameterMutations.SET_IS_LOADING_MODULE_PARAMETERS_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingModuleParametersListArchived = payload;
    },
    [ModuleParameterMutations.SET_IS_LOADING_MODULE_PARAMETER]: (state, payload: boolean) => {
        state.isLoadingModuleParameter = payload;
    },
    [ModuleParameterMutations.SET_IS_CREATING_MODULE_PARAMETER]: (state, payload: boolean) => {
        state.isCreatingModuleParameter = payload;
    },
    [ModuleParameterMutations.SET_IS_UPDATING_MODULE_PARAMETER]: (state, payload: boolean) => {
        state.isUpdatingModuleParameter = payload;
    },
    [ModuleParameterMutations.SET_IS_ARCHIVING_MODULE_PARAMETER]: (state, payload: IRequestState) => {
        state.requestStateModuleParameter = payload;
    },
    [ModuleParameterMutations.SET_IS_DELETING_MODULE_PARAMETER]: (state, payload: boolean) => {
        state.isDeletingModuleParameter = payload;
    },
    [ModuleParameterMutations.SET_REQUEST_STATE_MODULE_PARAMETER]: (state, payload: boolean) => {
        state.isArchivingModuleParameter = payload;
    }
}