import { MutationTree } from "vuex";
import { IOrderFormCustomerDetailState } from "./states";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export enum OrderFormCustomerDetailMutations {
  SET_ORDER_TRACKING_CUSTOMER_HEADER = "SET_ORDER_TRACKING_CUSTOMER_HEADER",
  SET_ORDER_TRACKING_CUSTOMER_DETAILS = "SET_ORDER_TRACKING_CUSTOMER_DETAILS",
  SET_IS_LOADING_ORDER_TRACKING_CUSTOMER_DETAILS = "SET_IS_LOADING_ORDER_TRACKING_CUSTOMER_DETAILS",
  SET_ORDER_FORM_CUSTOMER_HEADER = "SET_ORDER_FORM_CUSTOMER_HEADER",
  SET_ORDER_FORM_CUSTOMER_DETAIL = "SET_ORDER_FORM_CUSTOMER_DETAIL",
  SET_ORDER_FORM_CUSTOMER_DETAILS = "SET_ORDER_FORM_CUSTOMER_DETAILS",
  SET_IS_LOADING_ORDER_FORM_CUSTOMER_DETAILS = "SET_IS_LOADING_ORDER_FORM_CUSTOMER_DETAILS",
  SET_IS_LOADING_ORDER_FORM_CUSTOMER_DETAIL_DUPLICATION = "SET_IS_LOADING_ORDER_FORM_CUSTOMER_DETAIL_DUPLICATION",
  SET_IS_CREATING_ORDER_FORM_CUSTOMER_DETAIL = "SET_IS_CREATING_ORDER_FORM_CUSTOMER_DETAIL",
  SET_IS_UPDATING_ORDER_FORM_CUSTOMER_DETAIL = "SET_IS_UPDATING_ORDER_FORM_CUSTOMER_DETAIL",
  SET_IS_DELETING_ORDER_FORM_CUSTOMER_DETAIL = "SET_IS_DELETING_ORDER_FORM_CUSTOMER_DETAIL",
  SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DETAIL = "SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DETAIL",
}

export const mutations: MutationTree<IOrderFormCustomerDetailState> = {
  [OrderFormCustomerDetailMutations.SET_ORDER_TRACKING_CUSTOMER_HEADER]: (
    state,
    payload: any
  ) => {
    state.orderTrackingCustomerHeader = payload;
  },
  [OrderFormCustomerDetailMutations.SET_ORDER_FORM_CUSTOMER_HEADER]: (
    state,
    payload: any
  ) => {
    state.orderFormCustomerHeader = payload;
  },
  [OrderFormCustomerDetailMutations.SET_IS_LOADING_ORDER_TRACKING_CUSTOMER_DETAILS]:
    (state, payload: boolean) => {
      state.isLoadingOrderTrackingCustomerDetails = payload;
    },
  [OrderFormCustomerDetailMutations.SET_ORDER_TRACKING_CUSTOMER_DETAILS]: (
    state,
    payload: any[]
  ) => {
    state.orderTrackingCustomerDetails = payload;
  },
  [OrderFormCustomerDetailMutations.SET_ORDER_FORM_CUSTOMER_DETAILS]: (
    state,
    payload: any[]
  ) => {
    state.orderFormCustomerDetails = payload;
  },
  [OrderFormCustomerDetailMutations.SET_ORDER_FORM_CUSTOMER_DETAIL]: (
    state,
    payload: any
  ) => {
    state.orderFormCustomerDetail = payload;
  },
  [OrderFormCustomerDetailMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_DETAILS]:
    (state, payload: boolean) => {
      state.isLoadingOrderFormCustomerDetails = payload;
    },
  [OrderFormCustomerDetailMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_DETAIL_DUPLICATION]:
    (state, payload: boolean) => {
      state.isLoadingOrderFormCustomerDetailDuplication = payload;
    },
  [OrderFormCustomerDetailMutations.SET_IS_CREATING_ORDER_FORM_CUSTOMER_DETAIL]:
    (state, payload: boolean) => {
      state.isCreatingOrderFormCustomerDetail = payload;
    },
  [OrderFormCustomerDetailMutations.SET_IS_UPDATING_ORDER_FORM_CUSTOMER_DETAIL]:
    (state, payload: boolean) => {
      state.isUpdatingOrderFormCustomerDetail = payload;
    },
  [OrderFormCustomerDetailMutations.SET_IS_DELETING_ORDER_FORM_CUSTOMER_DETAIL]:
    (state, payload: boolean) => {
      state.isDeletingOrderFormCustomerDetail = payload;
    },
  [OrderFormCustomerDetailMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DETAIL]:
    (state, payload: IRequestState) => {
      state.requestStateDocument = payload;
    },
};
