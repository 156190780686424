import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { OrderFormCustomerDownPaymentsRequestRequestDto } from "Api";
import RootState from "@/store/modules/rootState"
import { IOrderFormCustomerDownPaymentsRequestState } from "./states";
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

export const state: IOrderFormCustomerDownPaymentsRequestState = {
    orderFormCustomerDownPaymentsRequestsList                  : Array<OrderFormCustomerDownPaymentsRequestRequestDto>(),
    orderFormCustomerDownPaymentsRequestsListArchived          : Array<OrderFormCustomerDownPaymentsRequestRequestDto>(),
    orderFormCustomerDownPaymentsRequest                       : <OrderFormCustomerDownPaymentsRequestRequestDto>{},
    isLoadingOrderFormCustomerDownPaymentsRequestsList         : false,
    isLoadingOrderFormCustomerDownPaymentsRequestsListArchived : false,
    isLoadingOrderFormCustomerDownPaymentsRequest              : false,
    isCreatingOrderFormCustomerDownPaymentsRequest             : false,
    isUpdatingOrderFormCustomerDownPaymentsRequest             : false,
    isDeletingOrderFormCustomerDownPaymentsRequest             : false,
    requestStateOrderFormCustomerDownPaymentsRequest           : <IRequestState>{},
    isArchivingOrderFormCustomerDownPaymentsRequest            : false,
}

export const OrderFormCustomerDownPaymentsRequestModule: Module<IOrderFormCustomerDownPaymentsRequestState, RootState> = {
  state, 
  getters,
  mutations,
  actions
}