import { DeductionRequestDto } from "Api";
let date = new Date()
export function DeductionDefaultProperties({quoteId=null, invoiceId=null, creditId=null, orderFormCustomerId=null}) : DeductionRequestDto {
  return {
    id: 0,
    holdbackNumber: null,
    holdbackPercent: null,
    holdbackDate: date.toISOString(),
    prorataAccountLabel: null,
    prorataAccountPrice: null,
    prorataAccountPercent: null,
    quoteId:quoteId,
    invoiceId:invoiceId,
    creditId: creditId,
    orderFormCustomerId: orderFormCustomerId,
    punctualDeductions: [],
    reviews: [],
  }
}