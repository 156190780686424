import { IOrderFormProviderDetailState } from "./states";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IndexRequestDto, OrderFormDetailRequestDto } from "Api";
import { OrderFormProviderDetailMutations } from "./mutations";
import { DocumentDetailMutations } from "@/store/modules/invoices/documentDetail/mutations";
import {
  getNewIndex,
  calculateTotalOrderFormProviderLine,
} from "@/types/api-orisis/library/DetailOrderFormProviderOperations";
import { calculateSubTotalLines } from "@/types/api-orisis/library/OrderFormProviderOperations";
import { CommonLineOrderFormProvider } from "@/types/api-orisis/models/LineModels";
import { orderFormProviderDetailTypeTranslate } from "@/types/api-orisis/library/TranslateOperations";

export const actions: ActionTree<IOrderFormProviderDetailState, RootState> = {
  getOrderFormDetailById(
    { commit, rootGetters },
    {
      orderFormDetailId,
      updateState = true,
    }: { orderFormDetailId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          OrderFormProviderDetailMutations.SET_IS_LOADING_ORDER_FORM_DETAILS,
          true
        );
      }
      axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/OrderFormDetail/GetOrderFormDetailById`,
          {
            params: {
              orderFormDetailId: orderFormDetailId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(OrderFormProviderDetailMutations.SET_ORDER_FORM_DETAIL, {
              ...res.data,
              referencielTvaId: res.data.referencielTvaId
                ? res.data.referencielTvaId
                : 5,
              unitId: res.data.unit ? res.data.unit.id : 1,
              total: res.data.quantity
                ? calculateTotalOrderFormProviderLine(res.data)
                : 0,
              titre: res.data.description,
            });
            commit(
              OrderFormProviderDetailMutations.SET_IS_LOADING_ORDER_FORM_DETAILS,
              false
            );
          }
          commit(
            OrderFormProviderDetailMutations.SET_REQUEST_STATE_ORDER_FORM_DETAIL,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          if (updateState) {
            commit(
              OrderFormProviderDetailMutations.SET_IS_LOADING_ORDER_FORM_DETAILS,
              false
            );
          }
          commit(
            OrderFormProviderDetailMutations.SET_REQUEST_STATE_ORDER_FORM_DETAIL,
            err
          );
          reject(err);
        });
    });
  },

  getOrderFormDetailByOrderFormId(
    { commit, rootGetters },
    {
      orderFormId,
      updateState = true,
    }: { orderFormId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          OrderFormProviderDetailMutations.SET_IS_LOADING_ORDER_FORM_DETAILS,
          true
        );
      }
      axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/OrderFormDetail/GetOrderFormDetailByOrderFormId`,
          {
            params: {
              orderFormId: orderFormId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(
              OrderFormProviderDetailMutations.SET_ORDER_FORM_DETAILS,
              res.data.map((el) => {
                return {
                  ...el,
                  referencielTvaId: el.referencielTvaId
                    ? el.referencielTvaId
                    : 5,
                  unitId: el.unit ? el.unit.id : 1,
                  total: el.quantity
                    ? calculateTotalOrderFormProviderLine(el)
                    : 0,
                  titre: el.description,
                };
              })
            );
            commit(
              OrderFormProviderDetailMutations.SET_IS_LOADING_ORDER_FORM_DETAILS,
              false
            );
          }
          commit(
            OrderFormProviderDetailMutations.SET_REQUEST_STATE_ORDER_FORM_DETAIL,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          if (updateState) {
            commit(
              OrderFormProviderDetailMutations.SET_IS_LOADING_ORDER_FORM_DETAILS,
              false
            );
          }
          commit(
            OrderFormProviderDetailMutations.SET_REQUEST_STATE_ORDER_FORM_DETAIL,
            err
          );
          reject(err);
        });
    });
  },

  createOrderFormProviderDetail(
    { commit, rootGetters },
    {
      orderFormProviderDetail,
      position = null,
    }: {
      orderFormProviderDetail: OrderFormDetailRequestDto;
      position?: number | null;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(
        OrderFormProviderDetailMutations.SET_IS_CREATING_ORDER_FORM_DETAIL,
        true
      );
      axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            "/Purchase/OrderFormDetail/CreateOrderFormDetail",
          orderFormProviderDetail
        )
        .then((res) => {
          let newObject;
          if (typeof position === "number") {
            newObject = { ...orderFormProviderDetail, id: res.data.data.id };
            rootGetters.getOrderFormProviderDetails.splice(
              position,
              0,
              newObject
            );
          } else {
            newObject = { ...orderFormProviderDetail, id: res.data.data.id };
            rootGetters.getOrderFormProviderDetails.push(newObject);
          }
          commit(
            OrderFormProviderDetailMutations.SET_ORDER_FORM_DETAIL,
            rootGetters.getOrderFormProviderDetails
          );
          commit(
            OrderFormProviderDetailMutations.SET_REQUEST_STATE_ORDER_FORM_DETAIL,
            res
          );
          commit(
            OrderFormProviderDetailMutations.SET_IS_CREATING_ORDER_FORM_DETAIL,
            false
          );
          resolve(newObject);
        })
        .catch((err) => {
          console.error(err);
          commit(
            OrderFormProviderDetailMutations.SET_REQUEST_STATE_ORDER_FORM_DETAIL,
            err
          );
          commit(
            OrderFormProviderDetailMutations.SET_IS_CREATING_ORDER_FORM_DETAIL,
            false
          );
          reject(err);
        });
    });
  },

  updateOrderFormProviderDetail(
    { commit, rootGetters },
    {
      orderFormProviderDetail,
    }: { orderFormProviderDetail: OrderFormDetailRequestDto }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(
        OrderFormProviderDetailMutations.SET_IS_UPDATING_ORDER_FORM_DETAIL,
        true
      );
      axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/OrderFormDetail/UpdateOrderFormDetail",
          orderFormProviderDetail
        )
        .then((res) => {
          rootGetters.getOrderFormProviderDetails.splice(
            rootGetters.getOrderFormProviderDetails.findIndex(
              (elem) => elem.id == orderFormProviderDetail.id
            ),
            1,
            {
              ...orderFormProviderDetail,
              productId: res.data.data.productId,
              total: calculateTotalOrderFormProviderLine(res.data.data),
            }
          );
          commit(
            OrderFormProviderDetailMutations.SET_ORDER_FORM_DETAILS,
            rootGetters.getOrderFormProviderDetails
          );
          commit(
            OrderFormProviderDetailMutations.SET_REQUEST_STATE_ORDER_FORM_DETAIL,
            res
          );
          commit(
            OrderFormProviderDetailMutations.SET_IS_UPDATING_ORDER_FORM_DETAIL,
            false
          );
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            OrderFormProviderDetailMutations.SET_REQUEST_STATE_ORDER_FORM_DETAIL,
            err
          );
          commit(
            OrderFormProviderDetailMutations.SET_IS_UPDATING_ORDER_FORM_DETAIL,
            false
          );
          reject(err);
        });
    });
  },

  updateTvaOrderFormProviderDetail(
    { commit, rootGetters },
    {
      orderFormDetailIds = [],
      tvaId,
    }: { orderFormDetailIds: number[]; tvaId: number }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(
        OrderFormProviderDetailMutations.SET_IS_LOADING_ORDER_FORM_DETAILS,
        true
      );
      axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/OrderFormDetail/UpdateTVAOrderFormDetail",
          orderFormDetailIds,
          {
            params: {
              tvaId: tvaId,
            },
          }
        )
        .then((res) => {
          commit(
            OrderFormProviderDetailMutations.SET_REQUEST_STATE_ORDER_FORM_DETAIL,
            res
          );
          commit(
            OrderFormProviderDetailMutations.SET_IS_LOADING_ORDER_FORM_DETAILS,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            OrderFormProviderDetailMutations.SET_REQUEST_STATE_ORDER_FORM_DETAIL,
            err
          );
          commit(
            OrderFormProviderDetailMutations.SET_IS_LOADING_ORDER_FORM_DETAILS,
            false
          );
          reject(err);
        });
    });
  },

  updateOrderFormProviderDetailIndex(
    { commit, rootGetters },
    {
      orderFormProviderDetailIndex = [],
    }: { orderFormProviderDetailIndex: Array<IndexRequestDto> }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/OrderFormDetail/UpdateOrderFormDetailIndex",
          orderFormProviderDetailIndex
        )
        .then((res) => {
          commit(
            OrderFormProviderDetailMutations.SET_REQUEST_STATE_ORDER_FORM_DETAIL,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            OrderFormProviderDetailMutations.SET_REQUEST_STATE_ORDER_FORM_DETAIL,
            err
          );
          reject(err);
        });
    });
  },

  deleteOrderFormProviderDetail(
    { commit, dispatch, rootGetters },
    {
      orderFormIds = [],
      reviewIndex = true,
    }: { orderFormIds: Array<number>; reviewIndex?: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(
        OrderFormProviderDetailMutations.SET_IS_DELETING_ORDER_FORM_DETAIL,
        true
      );
      commit(DocumentDetailMutations.SET_DISABLED_OPTIONS, true);
      axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Purchase/OrderFormDetail/Delete",
          {
            data: orderFormIds,
          }
        )
        .then((res) => {
          orderFormIds.forEach((id) => {
            rootGetters.getOrderFormProviderDetails.splice(
              rootGetters.getOrderFormProviderDetails.findIndex(
                (elem) => elem.id == id
              ),
              1
            );
          });
          if (reviewIndex) {
            dispatch("reviewIndexOrderFormProvider");
          }
          commit(
            OrderFormProviderDetailMutations.SET_ORDER_FORM_DETAIL,
            rootGetters.getOrderFormProviderDetails
          );
          commit(
            OrderFormProviderDetailMutations.SET_REQUEST_STATE_ORDER_FORM_DETAIL,
            res
          );
          commit(
            OrderFormProviderDetailMutations.SET_IS_DELETING_ORDER_FORM_DETAIL,
            false
          );
          commit(DocumentDetailMutations.SET_DISABLED_OPTIONS, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            OrderFormProviderDetailMutations.SET_REQUEST_STATE_ORDER_FORM_DETAIL,
            err
          );
          commit(
            OrderFormProviderDetailMutations.SET_IS_DELETING_ORDER_FORM_DETAIL,
            false
          );
          commit(DocumentDetailMutations.SET_DISABLED_OPTIONS, false);
          reject(err);
        });
    });
  },

  async addLineOrderFormProvider(
    { dispatch, rootGetters, commit },
    { payload }
  ) {
    return new Promise(async (resolve) => {
      commit(DocumentDetailMutations.SET_DISABLED_OPTIONS, true);
      if (payload.review) {
        let newObject = CommonLineOrderFormProvider(payload);
        await dispatch("createOrderFormProviderDetail", {
          orderFormProviderDetail: newObject,
          position: payload.indexArray,
        });
        await dispatch("reviewIndexOrderFormProvider");
        resolve(true);
      } else {
        await getNewIndex(
          rootGetters.getOrderFormProviderDetails,
          payload
        ).then(async (res: any) => {
          let newObject = CommonLineOrderFormProvider({
            ...payload,
            index: res.index,
            type: res.type,
          });
          await dispatch("createOrderFormProviderDetail", {
            orderFormProviderDetail: newObject,
          });
          resolve(true);
        });
      }
      commit(DocumentDetailMutations.SET_DISABLED_OPTIONS, false);
    });
  },

  async duplicateLineOrderFormProvider(
    { dispatch, rootGetters, commit },
    { payload, position }: { payload: any; position: string }
  ) {
    commit(DocumentDetailMutations.SET_DISABLED_OPTIONS, true);
    let line = JSON.parse(JSON.stringify(payload.line));
    let index = JSON.parse(JSON.stringify(payload.index));
    line.id = 0;
    // await dispatch("createOrderFormProviderDetail", { orderFormProviderDetail : line, position:(index + 1)});
    // await dispatch("reviewIndexOrderFormProvider");
    switch (position) {
      case "before":
        await dispatch("createOrderFormProviderDetail", {
          orderFormProviderDetail: { ...line, isDisabled: false },
          position: index,
        });
        await dispatch("reviewIndexOrderFormProvider");
        break;
      case "after":
        await dispatch("createOrderFormProviderDetail", {
          orderFormProviderDetail: { ...line, isDisabled: false },
          position: index + 1,
        });
        await dispatch("reviewIndexOrderFormProvider");
        break;
      case "beginning":
        await dispatch("createOrderFormProviderDetail", {
          orderFormProviderDetail: { ...line, isDisabled: false },
          position: 0,
        });
        await dispatch("reviewIndexOrderFormProvider");
        break;
      case "end":
        await dispatch("createOrderFormProviderDetail", {
          orderFormProviderDetail: { ...line, isDisabled: false },
          position: rootGetters.getOrderFormProviderDetails.length,
        });
        await dispatch("reviewIndexOrderFormProvider");
        break;
      default:
        await dispatch("createOrderFormProviderDetail", {
          orderFormProviderDetail: { ...line, isDisabled: false },
          position: index + 1,
        });
        await dispatch("reviewIndexOrderFormProvider");
        break;
    }
    commit(DocumentDetailMutations.SET_DISABLED_OPTIONS, false);
  },

  async duplicateOrderFormProviderDetails(
    { commit, dispatch, rootGetters },
    { orderFormProviderId, newOrderFormProviderId }
  ) {
    return new Promise(async (resolve, reject) => {
      commit(
        OrderFormProviderDetailMutations.SET_IS_LOADING_ORDER_FORM_DETAIL_DUPLICATION,
        true
      );
      let request = new URLSearchParams();
      request.append("orderFormProviderId", orderFormProviderId);
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/OrderFormDetail/GetOrderFormDetails",
          {
            params: request,
          }
        )
        .then(async (res) => {
          let newArray = res.data.map((el) => {
            return {
              ...el,
              id: 0,
              referencielTvaId: el.referencielTvaId ? el.referencielTvaId : 5,
              unitId: el.unit ? el.unit.id : 0,
              total: el.quantity ? calculateTotalOrderFormProviderLine(el) : 0,
              titre: el.type == 1 || el.type == 2 ? el.description : null,
            };
          });
          for (let i = 0; i < newArray.length; i++) {
            const line = newArray[i];
            await dispatch("createOrderFormProviderDetail", {
              orderFormProviderDetail: line,
            });
          }
          commit(
            OrderFormProviderDetailMutations.SET_IS_LOADING_ORDER_FORM_DETAIL_DUPLICATION,
            false
          );
          commit(
            OrderFormProviderDetailMutations.SET_REQUEST_STATE_ORDER_FORM_DETAIL,
            res
          );
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          commit(
            OrderFormProviderDetailMutations.SET_IS_LOADING_ORDER_FORM_DETAIL_DUPLICATION,
            false
          );
          commit(
            OrderFormProviderDetailMutations.SET_REQUEST_STATE_ORDER_FORM_DETAIL,
            err
          );
          reject(false);
        });
    });
  },

  async loopOrderFormProviderDetails(
    { dispatch, rootGetters },
    referencielTvaId
  ) {
    return new Promise((resolve) => {
      let OrderFormProviderDetailIds: Array<number> = [];
      for (let i = 0; i < rootGetters.getOrderFormProviderDetails.length; i++) {
        const line = rootGetters.getOrderFormProviderDetails[i];
        line.referencielTvaId = referencielTvaId;
        OrderFormProviderDetailIds.push(line.id);
      }
      dispatch("updateTvaOrderFormProviderDetail", {
        orderFormDetailIds: OrderFormProviderDetailIds,
        tvaId: referencielTvaId,
      }).then((res: any) => {
        resolve(res);
      });
    });
  },

  async reviewIndexOrderFormProvider({ dispatch, commit, rootGetters }) {
    commit(
      OrderFormProviderDetailMutations.SET_IS_LOADING_ORDER_FORM_DETAILS,
      true
    );
    let orderFormProviderDetailIndex = [];
    for (let i = 0; i < rootGetters.getOrderFormProviderDetails.length; i++) {
      const line = rootGetters.getOrderFormProviderDetails[i];
      await getNewIndex(rootGetters.getOrderFormProviderDetails, {
        ...line,
        indexArray: i - 1,
      }).then(async (res: any) => {
        line.index = res.index;
        orderFormProviderDetailIndex.push({
          id: line.id,
          position: line.position,
          index: res.index,
        });
      });
    }
    calculateSubTotalLines(true);
    commit(
      OrderFormProviderDetailMutations.SET_IS_LOADING_ORDER_FORM_DETAILS,
      false
    );
    dispatch("updateOrderFormProviderDetailIndex", {
      orderFormProviderDetailIndex: orderFormProviderDetailIndex,
    });
  },
};
