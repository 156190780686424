import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import RootState from "@/store/modules/rootState"
import { IDocumentHistoryState } from "./states";
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

export const state: IDocumentHistoryState = {
    documentHistoriesList                  : [],
    documentHistory                        : {},
    isLoadingDocumentHistoriesList         : false,
    isLoadingDocumentHistory               : false,
    requestStateDocumentHistory            : <IRequestState>{},
}

export const DocumentHistoryModule: Module<IDocumentHistoryState, RootState> = {
  state, 
  getters,
  mutations,
  actions
}