import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { IHoldbackState } from "./states";
import RootState from "../../rootState";
import { IHoldback } from "@/types/api-orisis/interfaces/invoices/holdback/IHoldback";
import { Module } from "vuex";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { HoldbackRequestDto } from "Api";

export const state: IHoldbackState = {
    holdbacksList: Array<IHoldback>(),
    holdback: <HoldbackRequestDto>{},

    // COMMON
    requestStateHoldback: <IRequestState>{},

    isUpdatingHoldback: false,
    isLoadingHoldbacksList: false,
    isLoadingHoldback: false,
};

export const HoldbackModule: Module<IHoldbackState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
