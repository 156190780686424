import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
// import { CurrencyRequestDto } from "@/../Api";
import { MutationTree } from "vuex";
import { ICurrencyState } from "./states";

export enum CurrencyMutations {
  SET_CURRENCIES_LIST = "setCurrenciesList",
  SET_CURRENCIES_LIST_ARCHIVED = "setCurrenciesListArchived",
  SET_CURRENCY = "setCurrency",
  SET_IS_LOADING_CURRENCIES_LIST = "setIsLoadingCurrenciesList",
  SET_IS_LOADING_CURRENCIES_LIST_ARCHIVED = "setIsLoadingCurrenciesListArchived",
  SET_IS_LOADING_CURRENCY = "setIsLoadingCurrency",
  SET_IS_CREATING_CURRENCY = "setIsCreatingCurrency",
  SET_IS_UPDATING_CURRENCY = "setIsUpdatingCurrency",
  SET_IS_DELETING_CURRENCY = "setIsDeletingCurrency",
  SET_IS_ARCHIVING_CURRENCY = "setIsArchivingCurrency",
  SET_REQUEST_STATE_CURRENCY = "setRequestStateCurrency",
}

export const mutations: MutationTree<ICurrencyState> = {
  [CurrencyMutations.SET_CURRENCIES_LIST](
    state,
    payload: any[]
    // payload: CurrencyRequestDto[]
  ) {
    state.currenciesList = payload;
  },
  [CurrencyMutations.SET_CURRENCIES_LIST_ARCHIVED](
    state,
    payload: any[]
    // payload: CurrencyRequestDto[]
  ) {
    state.currenciesListArchived = payload;
  },
  // [CurrencyMutations.SET_CURRENCY](state, payload: CurrencyRequestDto) {
  [CurrencyMutations.SET_CURRENCY](state, payload: any) {
    state.currency = payload;
  },

  // COMMON
  [CurrencyMutations.SET_IS_LOADING_CURRENCIES_LIST](state, payload: boolean) {
    state.isLoadingCurrenciesList = payload;
  },
  [CurrencyMutations.SET_IS_LOADING_CURRENCIES_LIST_ARCHIVED](
    state,
    payload: boolean
  ) {
    state.isLoadingCurrenciesListArchived = payload;
  },
  [CurrencyMutations.SET_IS_LOADING_CURRENCY](state, payload: boolean) {
    state.isLoadingCurrency = payload;
  },
  [CurrencyMutations.SET_IS_CREATING_CURRENCY](state, payload: boolean) {
    state.isCreatingCurrency = payload;
  },
  [CurrencyMutations.SET_IS_UPDATING_CURRENCY](state, payload: boolean) {
    state.isUpdatingCurrency = payload;
  },
  [CurrencyMutations.SET_IS_DELETING_CURRENCY](state, payload: boolean) {
    state.isDeletingCurrency = payload;
  },
  [CurrencyMutations.SET_IS_ARCHIVING_CURRENCY](state, payload: boolean) {
    state.isArchivingCurrency = payload;
  },
  [CurrencyMutations.SET_REQUEST_STATE_CURRENCY](
    state,
    payload: IRequestState
  ) {
    state.requestStateCurrency = payload;
  },
};
