import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { LeaseRequestDto } from "@/../Api";
import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { ILeaseState } from "./states";

export const getters: GetterTree<ILeaseState, RootState> = {
  leasesListByWorkspace: (state): LeaseRequestDto[] =>
    state.leasesListByWorkspace,
  leasesList: (state): LeaseRequestDto[] => state.leasesList,
  leasesListByAffair: (state): LeaseRequestDto[] => state.leasesListByAffair,
  leasesListArchived: (state): LeaseRequestDto[] => state.leasesListArchived,
  lease: (state): LeaseRequestDto => state.lease,
  sumLeaseModel: (state): any => state.sumLeaseModel,
  // COMMON
  isLoadingLeasesList: (state): boolean => state.isLoadingLeasesList,
  isLoadingLeasesListByAffair: (state): boolean =>
    state.isLoadingLeasesListByAffair,
  isLoadingDuplicatingLease: (state): boolean =>
    state.isLoadingDuplicatingLease,
  isLoadingLeasesListArchived: (state): boolean =>
    state.isLoadingLeasesListArchived,
  isLoadingLease: (state): boolean => state.isLoadingLease,
  isCreatingLease: (state): boolean => state.isCreatingLease,
  isUpdatingLease: (state): boolean => state.isUpdatingLease,
  isDeletingLease: (state): boolean => state.isDeletingLease,
  isArchivingLease: (state): boolean => state.isArchivingLease,
  requestStateLease: (state): IRequestState => state.requestStateLease,
};
