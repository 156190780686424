import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { StorageLocationRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { IStorageLocationState } from './states'


export const getters: GetterTree<IStorageLocationState, RootState> = {
  storageLocationsList                     : (state): StorageLocationRequestDto[] => state.storageLocationsList,
  storageLocationsListArchived             : (state): StorageLocationRequestDto[] => state.storageLocationsListArchived,
  storageLocation                          : (state): StorageLocationRequestDto => state.storageLocation,
  storageLocationArchived                  : (state): StorageLocationRequestDto => state.storageLocationArchived,

  // COMMON
  isLoadingStorageLocationsList            : (state): boolean => state.isLoadingStorageLocationsList,
  isLoadingStorageLocationsListArchived    : (state): boolean => state.isLoadingStorageLocationsListArchived,
  isLoadingStorageLocation                 : (state): boolean => state.isLoadingStorageLocation,
  isLoadingStorageLocationArchived         : (state): boolean => state.isLoadingStorageLocationArchived,
  isCreatingStorageLocation                : (state): boolean => state.isCreatingStorageLocation,
  isUpdatingStorageLocation                : (state): boolean => state.isUpdatingStorageLocation,
  isRestoringStorageLocation               : (state): boolean => state.isRestoringStorageLocation,
  isDeletingStorageLocation                : (state): boolean => state.isDeletingStorageLocation,
  isArchivingStorageLocation              : (state): boolean => state.isArchivingStorageLocation,
  requestStateStorageLocation             : (state): IRequestState => state.requestStateStorageLocation,
}



