import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { CreditProviderRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { ICreditProviderState } from './states'
import { IFilters } from '@/types/api-orisis/interfaces/invoices/filters/IFilters'


export const getters: GetterTree<ICreditProviderState, RootState> = {
  creditProvidersList                     : (state): CreditProviderRequestDto[] => state.creditProvidersList,
  creditProvider                          : (state): CreditProviderRequestDto => state.creditProvider,
  creditProviderFilters                   : (state): IFilters => state.creditProviderFilters,
  creditProvidersListByAffair             : (state): CreditProviderRequestDto[] => state.creditProvidersListByAffair,
  creditProviderListByProvider            : (state): CreditProviderRequestDto[] => state.creditProviderListByProvider,

  // COMMON
  isLoadingCreditProvidersList            : (state): boolean => state.isLoadingCreditProvidersList,
  isLoadingCreditProvider                 : (state): boolean => state.isLoadingCreditProvider,
  isCreatingCreditProvider                : (state): boolean => state.isCreatingCreditProvider,
  isUpdatingCreditProvider                : (state): boolean => state.isUpdatingCreditProvider,
  isDeletingCreditProvider                : (state): boolean => state.isDeletingCreditProvider,
  requestStateCreditProvider              : (state): IRequestState => state.requestStateCreditProvider,
  isLoadingCreditProvidersListByAffair    : (state): boolean => state.isLoadingCreditProvidersListByAffair,
  isLoadingCreditProvidersListByProvider  : (state): boolean => state.isLoadingCreditProvidersListByProvider,
}