export function ProductDefaultProperties({
  label = null,
  reference = null,
  productType = 0,
  description = null,
  quantity = 1,
  priceHT = 0.0,
  discount = 0.0,
  dryDisbursed = 0.0,
  grossMarginHT = 0.0,
  marginRate = 0.0,
  brand = null,
  unitId = 1,
  parentId = null,
  saleCategoryId = null,
  urlImage = null,
  alertQuantityMin = null,
  alertQuantityMax = null,
  destockingType = 1,
  restockingType = 1,
  ecoContribution = 0.0,
  useDegressiveSaleRate = false,
  referencielTvaId = 1,
  productCategoriesId = [],
  degressiveSalesRates = [],
  productProviders = [],
  unAvailableForSale = false,
  packagingQuantity = 1,
  total = 0,
}) {
  return {
    id: 0,
    label: label,
    reference: reference,
    productType: productType,
    description: description,
    quantity: quantity,
    priceHT: priceHT,
    discount: discount,
    dryDisbursed: dryDisbursed,
    grossMarginHT: grossMarginHT,
    marginRate: marginRate,
    brand: brand,
    unitId: unitId,
    parentId: parentId,
    saleCategoryId: saleCategoryId,
    urlImage: urlImage,
    alertQuantityMin: alertQuantityMin,
    alertQuantityMax: alertQuantityMax,
    destockingType: destockingType,
    restockingType: restockingType,
    ecoContribution: ecoContribution,
    useDegressiveSaleRate: useDegressiveSaleRate,
    referencielTvaId: referencielTvaId,
    productCategoriesId: productCategoriesId,
    degressiveSalesRates: degressiveSalesRates,
    productProviders: productProviders,
    unAvailableForSale: unAvailableForSale,
    packagingQuantity: packagingQuantity,
    total: total,
  };
}
