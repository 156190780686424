import { MutationTree } from "vuex";
import { IDocumentDetailState } from "./states";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export enum DocumentDetailMutations {
    SET_INVOICE_HEADER = "SET_INVOICE_HEADER",
    SET_DOCUMENT_DETAIL = "SET_DOCUMENT_DETAIL",
    SET_DOCUMENT_DETAILS = "SET_DOCUMENT_DETAILS",
    SET_IS_LOADING_DOCUMENT_DETAILS = "SET_IS_LOADING_DOCUMENT_DETAILS",
    SET_IS_LOADING_DOCUMENT_DETAIL_DUPLICATION = "SET_IS_LOADING_DOCUMENT_DETAIL_DUPLICATION",
    SET_IS_CREATING_DOCUMENT_DETAIL = "SET_IS_CREATING_DOCUMENT_DETAIL",
    SET_IS_UPDATING_DOCUMENT_DETAIL = "SET_IS_UPDATING_DOCUMENT_DETAIL",
    SET_IS_DELETING_DOCUMENT_DETAIL = "SET_IS_DELETING_DOCUMENT_DETAIL",
    SET_REQUEST_STATE_DOCUMENT_DETAIL = "SET_REQUEST_STATE_DOCUMENT_DETAIL",
    SET_DISABLED_OPTIONS = "SET_DISABLED_OPTIONS",
    SET_DISABLED_DRAG = "SET_DISABLED_DRAG",
}

export const mutations: MutationTree<IDocumentDetailState> = {
    [DocumentDetailMutations.SET_INVOICE_HEADER]: (state, payload: any) => {
        state.invoiceHeader = payload;
    },
    [DocumentDetailMutations.SET_DOCUMENT_DETAILS]: (state, payload: any[]) => {
        state.documentDetails = payload;
    },
    [DocumentDetailMutations.SET_DOCUMENT_DETAIL]: (state, payload: any) => {
        state.documentDetail = payload;
    },
    [DocumentDetailMutations.SET_IS_LOADING_DOCUMENT_DETAILS]: (state, payload: boolean) => {
        state.isLoadingDocumentDetails = payload;
    },
    [DocumentDetailMutations.SET_IS_LOADING_DOCUMENT_DETAIL_DUPLICATION]: (state, payload: boolean) => {
        state.isLoadingDocumentDetailDuplication = payload;
    },
    [DocumentDetailMutations.SET_IS_CREATING_DOCUMENT_DETAIL]: (state, payload: boolean) => {
        state.isCreatingDocumentDetail = payload;
    },
    [DocumentDetailMutations.SET_IS_UPDATING_DOCUMENT_DETAIL]: (state, payload: boolean) => {
        state.isUpdatingDocumentDetail = payload;
    },
    [DocumentDetailMutations.SET_IS_DELETING_DOCUMENT_DETAIL]: (state, payload: boolean) => {
        state.isDeletingDocumentDetail = payload;
    },
    [DocumentDetailMutations.SET_REQUEST_STATE_DOCUMENT_DETAIL]: (state, payload: IRequestState) => {
        state.requestStateDocument = payload;
    },
    [DocumentDetailMutations.SET_DISABLED_OPTIONS]: (state, payload: boolean) => {
        state.disabledOptions = payload;
    },
    [DocumentDetailMutations.SET_DISABLED_DRAG]: (state, payload: boolean) => {
        state.disabledDrag = payload;
    },
}