import { IOrderFormCustomerState } from "./states";
import { MutationTree } from "vuex";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { IOrderFormCustomer } from "@/types/api-orisis/interfaces/invoices/orderFormCustomer/IOrderFormCustomer";
import { IDocumentStats } from "@/types/api-orisis/interfaces/invoices/document/IDocumentStats";
import { IRemaining } from "@/types/api-orisis/interfaces/invoices/document/IRemaining";
import { IFilters } from "@/types/api-orisis/interfaces/invoices/filters/IFilters";
import { IPaymentMethod } from "@/types/api-orisis/interfaces/invoices/paymentMethod/IPaymentMethod";

export enum OrderFormCustomerMutations {
  SET_ORDER_FORM_CUSTOMERS_LIST_STATS = "SET_ORDER_FORM_CUSTOMERS_LIST_STATS",
  SET_ORDER_FORM_CUSTOMERS_LIST_ALL_STATS = "SET_ORDER_FORM_CUSTOMERS_LIST_ALL_STATS",
  SET_ORDER_FORM_CUSTOMERS_LIST = "SET_ORDER_FORM_CUSTOMERS_LIST",
  SET_ORDER_FORM_CUSTOMERS_LIST_TEMPLATES = "SET_ORDER_FORM_CUSTOMERS_LIST_TEMPLATES",
  SET_ORDER_FORM_CUSTOMERS_LIST_BY_AFFAIR = "SET_ORDER_FORM_CUSTOMERS_LIST_BY_AFFAIR",
  SET_ORDER_FORM_CUSTOMERS_BY_COMPANY = "SET_ORDER_FORM_CUSTOMERS_BY_COMPANY",
  SET_ORDER_FORM_CUSTOMERS_REMAINING_TO_BILL = "SET_ORDER_FORM_CUSTOMERS_REMAINING_TO_BILL",
  SET_LAST_DOCUMENT_ORDER_FORM_CUSTOMER_REFERENCE = "SET_LAST_DOCUMENT_ORDER_FORM_CUSTOMER_REFERENCE",

  SET_ORDER_TRACKING_CUSTOMER = "SET_ORDER_TRACKING_CUSTOMER",

  SET_ORDER_FORM_CUSTOMER = "SET_ORDER_FORM_CUSTOMER",
  SET_LAST_ORDER_FORM_CUSTOMER_REFERENCE = "SET_LAST_ORDER_FORM_CUSTOMER_REFERENCE",
  SET_ORDER_FORM_CUSTOMER_PDF = "SET_ORDER_FORM_CUSTOMER_PDF",
  SET_ORDER_TRACKING_CUSTOMER_PDF = "SET_ORDER_TRACKING_CUSTOMER_PDF",

  //FILTERS
  SET_ORDER_FORM_CUSTOMER_FILTERS = "SET_ORDER_FORM_CUSTOMER_FILTERS",

  // COMON
  SET_IS_VALIDATING_ORDER_FORM_CUSTOMER = "SET_IS_VALIDATING_ORDER_FORM_CUSTOMER",
  SET_IS_LOADING_ORDER_FORM_CUSTOMERS_REMAINING_TO_BILL = "SET_IS_LOADING_ORDER_FORM_CUSTOMERS_REMAINING_TO_BILL",
  SET_IS_LOADING_ORDER_FORM_CUSTOMERS_LIST = "SET_IS_LOADING_ORDER_FORM_CUSTOMERS_LIST",
  SET_IS_LOADING_ORDER_FORM_CUSTOMERS_LIST_STATS = "SET_IS_LOADING_ORDER_FORM_CUSTOMERS_LIST_STATS",
  SET_IS_LOADING_LAST_DOCUMENT_ORDER_FORM_CUSTOMER_REFERENCE = "SET_IS_LOADING_LAST_DOCUMENT_ORDER_FORM_CUSTOMER_REFERENCE",
  SET_IS_LOADING_ORDER_FORM_CUSTOMERS_LIST_BY_COMPANY = "SET_IS_LOADING_ORDER_FORM_CUSTOMERS_LIST_BY_COMPANY",
  SET_IS_LOADING_ORDER_FORM_CUSTOMERS_LIST_BY_AFFAIR = "SET_IS_LOADING_ORDER_FORM_CUSTOMERS_LIST_BY_AFFAIR",
  SET_IS_LOADING_ORDER_FORM_CUSTOMER = "SET_IS_LOADING_ORDER_FORM_CUSTOMER",
  SET_IS_LOADING_ORDER_FORM_CUSTOMER_PDF = "SET_IS_LOADING_ORDER_FORM_CUSTOMER_PDF",
  SET_IS_LOADING_DUPLICATING_ORDER_FORM_CUSTOMER = "SET_IS_LOADING_DUPLICATING_ORDER_FORM_CUSTOMER",
  SET_IS_CREATING_ORDER_FORM_CUSTOMER = "SET_IS_CREATING_ORDER_FORM_CUSTOMER",
  SET_IS_UPDATING_ORDER_FORM_CUSTOMER = "SET_IS_UPDATING_ORDER_FORM_CUSTOMER",
  SET_IS_DELETING_ORDER_FORM_CUSTOMER = "SET_IS_DELETING_ORDER_FORM_CUSTOMER",
  SET_REQUEST_STATE_ORDER_FORM_CUSTOMER = "SET_REQUEST_STATE_ORDER_FORM_CUSTOMER",
  SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_PDF = "SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_PDF",

  SET_ORDER_FORM_CUSTOMER_MARGIN_STATUS = "SET_ORDER_FORM_CUSTOMER_MARGIN_STATUS",
  SET_ORDER_FORM_CUSTOMER_PAYMENT_METHODS = "SET_ORDER_FORM_CUSTOMER_PAYMENT_METHODS",
  SET_ORDER_FORM_CUSTOMER_TAB_ACTIVE = "SET_ORDER_FORM_CUSTOMER_TAB_ACTIVE",
}

export const mutations: MutationTree<IOrderFormCustomerState> = {

    [OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMERS_LIST_STATS]: (state, payload: IDocumentStats) => {
        state.orderFormCustomersListStats = payload;
    },
    [OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMERS_LIST_ALL_STATS]: (state, payload: IDocumentStats) => {
        state.orderFormCustomersListAllStats = payload;
    },
    [OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMERS_REMAINING_TO_BILL]: (state, payload: IRemaining) => {
        state.orderFormCustomersRemainingToBill = payload;
    },
    [OrderFormCustomerMutations.SET_LAST_DOCUMENT_ORDER_FORM_CUSTOMER_REFERENCE]: (state, payload: string) => {
        state.lastDocumentOrderFormCustomerReference = payload;
    },
    

    [OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMERS_LIST]: (state, payload: IOrderFormCustomer[]) => {
        state.orderFormCustomersList = payload;
    },
    [OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMERS_LIST_TEMPLATES]: (state, payload: IOrderFormCustomer[]) => {
        state.orderFormCustomersListTemplates = payload;
    },
    [OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMERS_LIST_BY_AFFAIR]: (state, payload: IOrderFormCustomer[]) => {
        state.orderFormCustomersListByAffair = payload;
    },
    [OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMERS_BY_COMPANY]: (state, payload: IOrderFormCustomer[]) => {
        state.orderFormCustomersListByCompany = payload;
    },
    [OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMER]: (state, payload: IOrderFormCustomer) => {
        state.orderFormCustomer = payload;
    },
    [OrderFormCustomerMutations.SET_ORDER_TRACKING_CUSTOMER]: (state, payload: IOrderFormCustomer) => {
        state.orderTrackingCustomer = payload;
    },
    [OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMER_PDF]: (state, payload: string) => {
        state.orderFormCustomerPDF = payload+'?r='+(new Date()).getTime();
    },
    [OrderFormCustomerMutations.SET_ORDER_TRACKING_CUSTOMER_PDF]: (state, payload: string) => {
        state.orderTrackingCustomerPDF = payload+'?r='+(new Date()).getTime();
    },
    [OrderFormCustomerMutations.SET_LAST_ORDER_FORM_CUSTOMER_REFERENCE]: (state, payload: string) => {
        state.orderFormCustomer.documentReference = payload;
    },

    //FILTERS
    [OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMER_FILTERS]: (state, payload: IFilters) => {
        state.orderFormCustomerFilters = payload;
    },

    //COMMON
    [OrderFormCustomerMutations.SET_IS_VALIDATING_ORDER_FORM_CUSTOMER]: (state, payload: boolean) => {
        state.isValidatingOrderFormCustomer = payload;
    },
    [OrderFormCustomerMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMERS_REMAINING_TO_BILL]: (state, payload: boolean) => {
        state.isLoadingOrderFormCustomersRemainingToBill = payload;
    },
    [OrderFormCustomerMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMERS_LIST]: (state, payload: boolean) => {
        state.isLoadingOrderFormCustomersList = payload;
    },
    [OrderFormCustomerMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMERS_LIST_STATS]: (state, payload: boolean) => {
        state.isLoadingOrderFormCustomersListStats = payload;
    },
    [OrderFormCustomerMutations.SET_IS_LOADING_LAST_DOCUMENT_ORDER_FORM_CUSTOMER_REFERENCE]: (state, payload: boolean) => {
        state.isLoadingLastDocumentOrderFormCustomerReference = payload;
    },
    [OrderFormCustomerMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMERS_LIST_BY_COMPANY]: (state, payload: boolean) => {
        state.isLoadingOrderFormCustomersListByCompany = payload;
    },
    [OrderFormCustomerMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMERS_LIST_BY_AFFAIR]: (state, payload: boolean) => {
        state.isLoadingOrderFormCustomersListByAffair = payload;
    },
    [OrderFormCustomerMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER]: (state, payload: boolean) => {
        state.isLoadingOrderFormCustomer = payload;
    },
    [OrderFormCustomerMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_PDF]: (state, payload: boolean) => {
        state.isLoadingOrderFormCustomerPDF = payload;
    },
    [OrderFormCustomerMutations.SET_IS_LOADING_DUPLICATING_ORDER_FORM_CUSTOMER]: (state, payload: boolean) => {
        state.isLoadingDuplicatingOrderFormCustomer = payload;
    },
    [OrderFormCustomerMutations.SET_IS_CREATING_ORDER_FORM_CUSTOMER]: (state, payload: boolean) => {
        state.isCreatingOrderFormCustomer = payload;
    },
    [OrderFormCustomerMutations.SET_IS_UPDATING_ORDER_FORM_CUSTOMER]: (state, payload: boolean) => {
        state.isUpdatingOrderFormCustomer = payload;
    },
    [OrderFormCustomerMutations.SET_IS_DELETING_ORDER_FORM_CUSTOMER]: (state, payload: boolean) => {
        state.isDeletingOrderFormCustomer = payload;
    },
    [OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER]: (state, payload: IRequestState) => {
        state.requestStateOrderFormCustomer = payload;
    },
    [OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_PDF]: (state, payload: IRequestState) => {
        state.requestStateOrderFormCustomerPDF = payload;
    },
    [OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMER_MARGIN_STATUS]: (state, payload: any) => {
      state.orderFormCustomermarginStatus = payload;
    },
    [OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMER_TAB_ACTIVE]: (state, payload: any) => {
      state.orderFormCustomerTabActive = payload;
    },
    [OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMER_PAYMENT_METHODS]: (state, payload: IPaymentMethod[]) => {
        state.orderFormCustomerPaymentMethods = payload;
    }
}