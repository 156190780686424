import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IOrderFormCustomerOptionState } from "./states";
import { OrderFormCustomerOptionRequestDto } from "Api";

export const getters: GetterTree<IOrderFormCustomerOptionState, RootState> = {
  orderFormCustomerOption(state): OrderFormCustomerOptionRequestDto {
    return state.orderFormCustomerOption;
  },
  isLoadingOrderFormCustomerOption(state): boolean {
    return state.isLoadingOrderFormCustomerOption;
  },
  requestStateOrderFormCustomerOption(state): any {
    return state.requestStateOrderFormCustomerOption;
  },
};
