import { FileRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { FileMutations } from "./mutations";
import { FolderMutations } from "../folder/mutations";
import { IFileState } from "./states";

export const actions: ActionTree<IFileState, RootState> = {
  async getFiles(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(FileMutations.SET_IS_LOADING_FILE_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Ged/File/GetFiles`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(FileMutations.SET_IS_LOADING_FILE_LIST, false);
            commit(FileMutations.SET_FILES_LIST, res.data);
          }
          commit(FileMutations.SET_REQUEST_STATE_FILE, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(FileMutations.SET_IS_LOADING_FILE_LIST, false);
          commit(FileMutations.SET_REQUEST_STATE_FILE, err);
          reject(err);
        });
    });
  },

  async getFileById(
    { commit },
    { fileId, action = true }: { fileId: number; action: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (action) {
        commit(FileMutations.SET_IS_LOADING_FILE, true);
      }
      await axios
        .get(`${process.env.VUE_APP_API_ORISIS}Ged/File/GetFileById`, {
          params: {
            fileId: fileId,
          },
        })
        .then((res) => {
          if (action) {
            commit(FileMutations.SET_FILE, res.data);
            commit(FileMutations.SET_IS_LOADING_FILE, false);
            commit(FileMutations.SET_REQUEST_STATE_FILE, res);
            resolve(res.data);
          } else {
            resolve(res.data);
          }
        })
        .catch((err) => {
          console.error(err);
          commit(FileMutations.SET_REQUEST_STATE_FILE, err);
          commit(FileMutations.SET_IS_LOADING_FILE, false);
          reject(err);
        });
    });
  },

  async createFile({ commit, rootGetters }, formData): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(FileMutations.SET_IS_CREATING_FILE, true);
      await axios
        .post(`${process.env.VUE_APP_API_ORISIS}Ged/File/CreateFile`, formData)
        .then((res) => {
          rootGetters.folder.files.push(res.data.data);
          commit(FolderMutations.SET_FOLDER, rootGetters.folder);
          commit(FileMutations.SET_REQUEST_STATE_FILE, res);
          commit(FileMutations.SET_IS_CREATING_FILE, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(FileMutations.SET_REQUEST_STATE_FILE, err);
          commit(FileMutations.SET_IS_CREATING_FILE, false);
          reject(err);
        });
    });
  },

  async updateFile(
    { commit, rootGetters },
    { file }: { file: FileRequestDto }
  ) {
    commit(FileMutations.SET_IS_UPDATING_FILE, true);
    await axios
      .put(`${process.env.VUE_APP_API_ORISIS}Ged/File/UpdateFile`, {
        ...file,
        folderIds: file.folderIds ? file.folderIds : null,
        file: null,
      })
      .then((res) => {
        rootGetters.folder.files.splice(
          rootGetters.folder.files.findIndex((elem) => elem.id == file.id),
          1,
          file
        );
        commit(FileMutations.SET_FILES_LIST, rootGetters.folder);
        commit(FileMutations.SET_REQUEST_STATE_FILE, res);
        commit(FileMutations.SET_IS_UPDATING_FILE, false);
      })
      .catch((err) => {
        console.error(err);
        commit(FileMutations.SET_REQUEST_STATE_FILE, err);
        commit(FileMutations.SET_IS_UPDATING_FILE, false);
      });
  },

  async updateFileFolder({ commit, rootGetters }, { fileId, fileFolders }) {
    return new Promise<void>(async (resolve, reject) => {
      commit(FileMutations.SET_IS_UPDATING_FILE, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Ged/File/UpdateFileFolder`,
          fileFolders,
          {
            params: {
              fileId: fileId,
            },
          }
        )
        .then((res) => {
          rootGetters.folder.files.splice(
            rootGetters.folder.files.findIndex((elem) => elem.id == fileId),
            1
          );
          commit(FolderMutations.SET_FOLDER, rootGetters.folder);
          commit(FileMutations.SET_REQUEST_STATE_FILE, res);
          commit(FileMutations.SET_IS_UPDATING_FILE, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(FileMutations.SET_REQUEST_STATE_FILE, err);
          commit(FileMutations.SET_IS_UPDATING_FILE, false);
          reject(err);
        });
    });
  },

  async deleteFiles(
    { commit, rootGetters },
    { fileIds = [] }: { fileIds: number[] }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(FileMutations.SET_IS_DELETING_FILE, true);
      await axios
        .delete(`${process.env.VUE_APP_API_ORISIS}Ged/File/Delete`, {
          data: fileIds,
        })
        .then((res) => {
          fileIds.forEach((fileId) => {
            rootGetters.folder.files.splice(
              rootGetters.folder.files.findIndex((elem) => elem.id == fileId),
              1
            );
          });
          commit(FolderMutations.SET_FOLDER, rootGetters.folder);
          commit(FileMutations.SET_REQUEST_STATE_FILE, res);
          commit(FileMutations.SET_IS_DELETING_FILE, false);
        })
        .catch((err) => {
          console.error(err);
          commit(FileMutations.SET_REQUEST_STATE_FILE, err);
          commit(FileMutations.SET_IS_DELETING_FILE, false);
        });
    });
  },
};
