import store from "@/store";

type actionType =
  | "read"
  | "create"
  | "delete"
  | "update"
  | "execute"
  | "update || create"
  | "all";
export function checkFunctionUser(
  functionId: number,
  action: actionType
): boolean {
  if (!localStorage.getItem("userInfo")) {
    return false;
  } else if (functionId !== 0) {
    const userFunctions =
      store.getters.connectedUser && store.getters.connectedUser.functions
        ? store.getters.connectedUser.functions
        : JSON.parse(localStorage.getItem("userInfo") as string).functions;
    if (userFunctions.length >= 1) {
      if (
        userFunctions.find(
          (functionProfile: any) =>
            functionProfile.id == functionId &&
            ((functionProfile.allowRead && action == "read") ||
              (functionProfile.allowCreate && action == "create") ||
              (functionProfile.allowDelete && action == "delete") ||
              (functionProfile.allowUpdate && action == "update") ||
              (functionProfile.allowExecute && action == "execute") ||
              (((functionProfile.allowCreate &&
                functionProfile.allowDelete &&
                functionProfile.allowUpdate &&
                functionProfile.allowRead) ||
                functionProfile.allowExecute) &&
                action == "all") ||
              ((functionProfile.allowUpdate || functionProfile.AllowCreate) &&
                action == "update || create"))
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  } else {
    return true;
  }
}

// Cette fonction retourne vrai si au moins une des fonctions de l'utilisateur connecté a les droits d'accès à la fonction passée en paramètre
export function checkFunctionUserByFunctionIdsAndActions({
  functionIds = [],
  actions = [],
}: {
  functionIds: number[];
  actions: actionType[];
}): boolean {
  if (!localStorage.getItem("userInfo")) {
    return false;
  } else if (functionIds.length) {
    const userFunctions =
      store.getters.connectedUser && store.getters.connectedUser.functions
        ? store.getters.connectedUser.functions
        : JSON.parse(localStorage.getItem("userInfo") as string).functions;
    if (userFunctions.length >= 1) {
      if (
        userFunctions.find((functionProfile: any) =>
          functionIds.includes(functionProfile.id)
        ) &&
        userFunctions.find((functionProfile: any) =>
          actions.includes("read")
            ? functionProfile.allowRead
            : actions.includes("create")
            ? functionProfile.allowCreate
            : actions.includes("delete")
            ? functionProfile.allowDelete
            : actions.includes("update")
            ? functionProfile.allowUpdate
            : actions.includes("execute")
            ? functionProfile.allowExecute
            : actions.includes("all")
            ? functionProfile.allowCreate &&
              functionProfile.allowDelete &&
              functionProfile.allowUpdate &&
              functionProfile.allowRead
            : actions.includes("update || create")
            ? functionProfile.allowUpdate || functionProfile.allowCreate
            : false
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  } else {
    return true;
  }
}
