import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { WasteCollectionPointRequestDto } from "Api";
import { GetterTree } from "vuex";
import RootState from '@/store/modules/rootState'
import {  IWasteCollectionPointState } from "./states";

export const getters: GetterTree<IWasteCollectionPointState, RootState> = {
    wasteCollectionPointsList: (state): WasteCollectionPointRequestDto[] => state.wasteCollectionPointsList,
    wasteCollectionPointsListArchived: (state): WasteCollectionPointRequestDto[] => state.wasteCollectionPointsListArchived,
    wasteCollectionPoint     : (state): WasteCollectionPointRequestDto => state.wasteCollectionPoint,
     
    // COMMON
    isLoadingWasteCollectionPointsList   : (state): boolean => state.isLoadingWasteCollectionPointsList,
    isLoadingWasteCollectionPointsListArchived : (state): boolean => state.isLoadingWasteCollectionPointsListArchived,
    isLoadingWasteCollectionPoint : (state): boolean => state.isLoadingWasteCollectionPoint,
    isCreatingWasteCollectionPoint  : (state): boolean => state.isCreatingWasteCollectionPoint,
    isUpdatingWasteCollectionPoint : (state): boolean => state.isUpdatingWasteCollectionPoint,
    isDeletingWasteCollectionPoint : (state): boolean => state.isDeletingWasteCollectionPoint,
    requestStateWasteCollectionPoint: (state): IRequestState => state.requestStateWasteCollectionPoint,
    isArchivingWasteCollectionPoint : (state): boolean => state.isArchivingWasteCollectionPoint,

}