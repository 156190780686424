import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IOrderFormOptionDefaultState } from "./states";
import { OrderFormOptionRequestDto } from "@/../Api";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export const getters: GetterTree<IOrderFormOptionDefaultState, RootState> = {
  orderFormOptionDefault: (state): OrderFormOptionRequestDto =>
    state.orderFormOptionDefault,
  orderFormOptionDefaultForm: (state): OrderFormOptionRequestDto =>
    state.orderFormOptionDefaultForm,
  isLoadingOrderFormOptionDefault: (state): boolean =>
    state.isLoadingOrderFormOptionDefault,
  requestStateOrderFormOptionDefault: (state): IRequestState =>
    state.requestStateOrderFormOptionDefault,
};
