import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { OrderFormCustomerMutations } from "./mutations";
import { OrderFormCustomerDetailMutations } from "../orderFormCustomerDetail/mutations";
import { IOrderFormCustomerState } from "./states";
import { DeductionMutations } from "../deduction/mutations";
import { DeductionDefaultProperties } from "@/types/api-orisis/models/DeductionModels";
import dayjs from "dayjs";
import { IFilters } from "@/types/api-orisis/interfaces/invoices/filters/IFilters";
import { OrderFormCustomerRequestDto } from "Api";
import { FileMutations } from "../../ged/file/mutations";
import { FolderMutations } from "../../ged/folder/mutations";
import { DocumentMutations } from "../document/mutations";
import { generateParams } from "@/types/api-orisis/library/FormatOperations";
import { OrderFormCustomerDownPaymentsRequestMutations } from "../orderFormCustomerDownPaymentsRequest/mutations";
import { InitialDocumentMutations } from "../initialDocument/mutations";
import { DocumentDetailMutations } from "../documentDetail/mutations";
import { Timezone } from "@syncfusion/ej2-schedule";

let timezoneSchedule = new Timezone();

let startDate = new Date(
  dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
).toISOString();
let endDate = new Date(
  dayjs().endOf("month").format("YYYY-MM-DD")
).toISOString();

export const actions: ActionTree<IOrderFormCustomerState, RootState> = {
  getOrderFormCustomers(
    { commit, rootGetters },
    {
      companyIds = [],
      status = [],
      affairId = null,
      dateFrom = null,
      dateTo = null,
      updateState = true,
      isTemplate = false,
      route,
    }: {
      companyIds?: number[];
      status?: number[];
      affairId: number;
      dateFrom?: Date;
      dateTo?: Date;
      updateState?: boolean;
      isTemplate?: boolean;
      route?: string;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        switch (route) {
          case "company":
            commit(
              OrderFormCustomerMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMERS_LIST_BY_COMPANY,
              true
            );
            break;
          case "affair":
            commit(
              OrderFormCustomerMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMERS_LIST_BY_AFFAIR,
              true
            );
            break;
          default:
            commit(
              OrderFormCustomerMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMERS_LIST,
              true
            );
            break;
        }
      }
      let request = new URLSearchParams();
      request = generateParams(request, companyIds, "companyIds");
      request = generateParams(request, status, "status");
      request = affairId
        ? generateParams(request, [affairId], "affairId")
        : request;
      request = dateFrom
        ? generateParams(
            request,
            [dayjs(dateFrom).format("YYYY-MM-DD")],
            "dateFrom"
          )
        : request;
      request = dateTo
        ? generateParams(
            request,
            [
              dayjs(dateTo)
                .hour(23)
                .minute(59)
                .second(59)
                .format("YYYY-MM-DD HH:mm:ss"),
            ],
            "dateTo"
          )
        : request;
      request = generateParams(request, [isTemplate], "isTemplate");
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/OrderFormCustomer/GetOrderFormCustomers",
          {
            params: {
              companyIds: companyIds,
              status: status,
              affairId: affairId,
              dateFrom: dateFrom,
              dateTo: dateTo,
              request,
            },
          }
        )
        .then((res) => {
          let list = res.data.map((el) => {
            return {
              ...el,
              documentReference:
                el.documentReference == null
                  ? "Numéro en attente"
                  : el.documentReference,
              totalTtc: Math.round(el.totalTtc * 100) / 100,
              limitDate: el.limitDate
                ? dayjs(el.limitDate).format("YYYY-MM-DD")
                : null,
              documentDate: el.documentDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(el.documentDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              collaboratorId:
                el.companyId !== null && el.companyId !== "" && el.companyId > 0
                  ? rootGetters.companiesList.find(
                      (company) => company.id == el.companyId
                    )
                    ? rootGetters.companiesList.find(
                        (company) => company.id == el.companyId
                      ).collaboratorId
                    : ""
                  : "",
            };
          });
          if (updateState) {
            switch (route) {
              case "company":
                commit(
                  OrderFormCustomerMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMERS_LIST_BY_COMPANY,
                  false
                );
                commit(
                  OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMERS_BY_COMPANY,
                  list
                );
                break;
              case "affair":
                commit(
                  OrderFormCustomerMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMERS_LIST_BY_AFFAIR,
                  false
                );
                commit(
                  OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMERS_LIST_BY_AFFAIR,
                  list
                );
                break;
              default:
                commit(
                  OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMERS_LIST,
                  list
                );
                commit(
                  OrderFormCustomerMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMERS_LIST,
                  false
                );
                break;
            }
          }
          if (isTemplate) {
            commit(
              OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMERS_LIST_TEMPLATES,
              list
            );
          }
          commit(
            OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER,
            res
          );
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            switch (route) {
              case "company":
                commit(
                  OrderFormCustomerMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMERS_LIST_BY_COMPANY,
                  false
                );
                break;
              case "affair":
                commit(
                  OrderFormCustomerMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMERS_LIST_BY_AFFAIR,
                  false
                );
                break;
              default:
                commit(
                  OrderFormCustomerMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMERS_LIST,
                  false
                );
                break;
            }
          }
          commit(
            OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER,
            err
          );
          reject(err);
        });
    });
  },

  getOrderFormCustomerById(
    { commit, dispatch },
    {
      orderFormCustomerId,
      updateState = true,
    }: { orderFormCustomerId: number; updateState?: boolean }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (updateState) {
        commit(
          OrderFormCustomerMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER,
          true
        );
        commit(
          OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMER_TAB_ACTIVE,
          "order-form-customer-edit"
        );
        commit(InitialDocumentMutations.SET_INITIAL_DOCUMENT, null);
        commit(DocumentMutations.SET_LAST_INVOICE, null);

        // On initialise les states lié au suivi de la commande
        commit(OrderFormCustomerMutations.SET_ORDER_TRACKING_CUSTOMER, null);
        commit(
          OrderFormCustomerDetailMutations.SET_ORDER_TRACKING_CUSTOMER_DETAILS,
          null
        );
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/OrderFormCustomer/GetOrderFormCustomerById",
          {
            params: {
              orderFormCustomerId: orderFormCustomerId,
            },
          }
        )
        .then(async (res) => {
          if (!res.data.usedUrlGed)
            await dispatch("getOrderFormCustomerOptionByOrderFormCustomerId", {
              orderFormCustomerId: res.data.id,
            });
          let orderFormCustomer = {
            ...res.data,
            documentTvas: [],
          };

          if (updateState) {
            // On redirige sur la tab suivi de commande selon le statut
            if (res.data.status >= 4 || res.data.usedUrlGed) {
              dispatch(
                "changeOrderFormCustomerTabActive",
                "order-tracking-customer"
              );
            }

            if (
              res.data.status >= 3 &&
              res.data.path &&
              !res.data.path.includes("null.pdf")
            ) {
              commit(
                OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMER_PDF,
                res.data.path
              );
            } else {
              commit(
                OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMER_PDF,
                ""
              );
            }

            if (
              res.data.orderFormCustomerBtp &&
              res.data.orderFormCustomerBtp.wasteCollectionPoints
            ) {
              res.data.orderFormCustomerBtp.wasteCollectionPointIds =
                res.data.orderFormCustomerBtp.wasteCollectionPoints.map(
                  (elem) => elem.id
                );
              delete res.data.orderFormCustomerBtp.wasteCollectionPoints;
              res.data.orderFormCustomerBtp.WasteTypeIds =
                res.data.orderFormCustomerBtp.wasteTypes.map((elem) => elem.id);
              delete res.data.orderFormCustomerBtp.wasteTypes;
            }

            commit(
              OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMER,
              orderFormCustomer
            );
            commit(
              OrderFormCustomerMutations.SET_ORDER_TRACKING_CUSTOMER,
              orderFormCustomer
            );
            commit(
              DeductionMutations.SET_DEDUCTION,
              res.data.deduction == null
                ? DeductionDefaultProperties({
                    orderFormCustomerId: res.data.id,
                  })
                : res.data.deduction
            );
            commit(
              OrderFormCustomerDownPaymentsRequestMutations.SET_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUESTS_LIST,
              res.data.orderFormCustomerDownPaymentsRequests
            );
            commit(
              OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMER_PAYMENT_METHODS,
              res.data.paymentMethods
                ? res.data.paymentMethods.map((el) => el.id)
                : []
            );
            commit(
              OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER,
              res
            );

            await dispatch("getOrderFormCustomerDetailsByOrderFormCustomerId", {
              orderFormCustomerId: orderFormCustomerId,
            }).then((res) => {
              // Par défaut toutes les lignes de détails de la commande d'origine sont les lignes de commande à suivre
              commit(
                OrderFormCustomerDetailMutations.SET_ORDER_TRACKING_CUSTOMER_DETAILS,
                res.map((el: any) => {
                  return {
                    ...el,
                    situationProgress: 0,
                    situationProgressQuantity: 0,
                  };
                })
              );
            });

            await dispatch("getInitialOrderFormCustomerById", {
              orderFormCustomerId: orderFormCustomerId,
            }).then(async (res) => {
              if (res.invoices.length > 0) {
                await dispatch("getLastInvoice", {
                  document: "orderFormCustomer",
                });
              }
            });

            commit(
              OrderFormCustomerMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER,
              false
            );
          }

          resolve(updateState ? res.data : orderFormCustomer);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              OrderFormCustomerMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER,
              false
            );
          }
          commit(
            OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER,
            err
          );
          reject(err);
        });
    });
  },

  getInitialOrderFormCustomerById(
    { commit },
    {
      orderFormCustomerId,
      updateState = true,
    }: { orderFormCustomerId: number; updateState?: boolean }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (updateState) {
        commit(InitialDocumentMutations.SET_IS_LOADING_INITIAL_DOCUMENT, true);
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/OrderFormCustomer/GetInitialOrderFormCustomerById",
          {
            params: {
              orderFormCustomerId: orderFormCustomerId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            let resInitialOrderFormCustomer = res.data;
            resInitialOrderFormCustomer = {
              ...resInitialOrderFormCustomer,
              initialDocumentNature: "order-form-customer",
            };
            commit(
              InitialDocumentMutations.SET_INITIAL_DOCUMENT,
              resInitialOrderFormCustomer
            );
            commit(
              InitialDocumentMutations.SET_IS_LOADING_INITIAL_DOCUMENT,
              false
            );
          }
          commit(
            OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER,
            err
          );
          if (updateState) {
            commit(
              InitialDocumentMutations.SET_IS_LOADING_INITIAL_DOCUMENT,
              false
            );
          }
          reject(err);
        });
    });
  },

  getLastOrderFormCustomerReference(
    { commit },
    { updateState = true }: { updateState?: boolean }
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      if (updateState) {
        commit(
          OrderFormCustomerMutations.SET_IS_LOADING_LAST_DOCUMENT_ORDER_FORM_CUSTOMER_REFERENCE,
          true
        );
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/OrderFormCustomer/GetLastDocumentReference"
        )
        .then((res) => {
          if (updateState) {
            commit(
              OrderFormCustomerMutations.SET_LAST_DOCUMENT_ORDER_FORM_CUSTOMER_REFERENCE,
              res.data
            );
            commit(
              OrderFormCustomerMutations.SET_IS_LOADING_LAST_DOCUMENT_ORDER_FORM_CUSTOMER_REFERENCE,
              false
            );
          }
          commit(
            OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER,
            res
          );
          resolve(res);
        })
        .catch((err) => {
          console.error(err);
          commit(
            OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER,
            err
          );
          if (updateState) {
            commit(
              OrderFormCustomerMutations.SET_IS_LOADING_LAST_DOCUMENT_ORDER_FORM_CUSTOMER_REFERENCE,
              false
            );
          }
          reject(err);
        });
    });
  },

  getOrderFormCustomerEmpty(
    { commit, rootGetters, dispatch },
    {
      type,
      userId = rootGetters.connectedUser.id,
      affairId = null,
      updateState = true,
      companyId = null,
    }: {
      type: number;
      userId?: number;
      affairId?: number;
      updateState?: boolean;
      companyId?: number;
    }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (updateState) {
        commit(
          OrderFormCustomerMutations.SET_IS_CREATING_ORDER_FORM_CUSTOMER,
          true
        );
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/OrderFormCustomer/GetOrderFormCustomerEmpty",
          {
            params: {
              type: type,
              userId: userId,
              affairId: affairId,
              companyId: companyId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            if (res.data.status == 8) {
              let orderFormCustomerFilters =
                rootGetters.userParametersList.find(
                  (el) => el.key == "orderFormCustomerFilters"
                );
              if (orderFormCustomerFilters) {
                dispatch("getOrderFormCustomers", {
                  dateFrom: JSON.parse(orderFormCustomerFilters.value).dateRange
                    .startDate,
                  dateTo: JSON.parse(orderFormCustomerFilters.value).dateRange
                    .endDate,
                });
              } else {
                dispatch("setFilter", {
                  name: "orderFormCustomerFilters",
                  filter: {
                    dateRangeType: 7,
                    dateRange: { startDate: startDate, endDate: endDate },
                  },
                });
                dispatch("getOrderFormCustomers", {
                  dateFrom: startDate,
                  dateTo: endDate,
                });
              }
            }
            commit(OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMER_PDF, "");
            commit(
              OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMER_PAYMENT_METHODS,
              res.data.paymentMethods.length == 0
                ? []
                : res.data.paymentMethods.map((el) => el.id)
            );
            commit(
              OrderFormCustomerMutations.SET_IS_CREATING_ORDER_FORM_CUSTOMER,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER,
            err
          );
          if (updateState) {
            commit(
              OrderFormCustomerMutations.SET_IS_CREATING_ORDER_FORM_CUSTOMER,
              false
            );
          }
          reject(err);
        });
    });
  },

  getOrderFormCustomerRemainingToBill(
    { commit, rootGetters, dispatch },
    { endTo, updateState = true }: { endTo: Date; updateState?: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          OrderFormCustomerMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMERS_REMAINING_TO_BILL,
          true
        );
        commit(
          OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMERS_REMAINING_TO_BILL,
          []
        );
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/OrderFormCustomer/GetRemainingToBill",
          {
            params: {
              endTo: endTo,
            },
          }
        )
        .then((res) => {
          let list = res.data;
          if (updateState) {
            commit(
              OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMERS_REMAINING_TO_BILL,
              list
            );
          }
          commit(
            OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER,
            res
          );
          commit(
            OrderFormCustomerMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMERS_REMAINING_TO_BILL,
            false
          );
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMERS_REMAINING_TO_BILL,
              []
            );
          }
          commit(
            OrderFormCustomerMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMERS_REMAINING_TO_BILL,
            false
          );
          commit(
            OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER,
            err
          );
          reject(err);
        });
    });
  },

  getOrderFormCustomersStats(
    { commit, rootGetters },
    {
      startDate = null,
      endDate = null,
      updateState = true,
      route,
    }: {
      startDate?: Date;
      endDate?: Date;
      updateState?: boolean;
      route?: string;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        switch (route) {
          case "all":
            commit(
              OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMERS_LIST_ALL_STATS,
              { count: 0, amount: 0, datas: [] }
            );
            break;
          default:
            commit(
              OrderFormCustomerMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMERS_LIST_STATS,
              true
            );
            commit(
              OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMERS_LIST_STATS,
              {
                count: 0,
                amount: 0,
                datas: [],
              }
            );
            break;
        }
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/OrderFormCustomer/GetOrderFormCustomerStats",
          {
            params: {
              startDate: startDate,
              endDate: endDate,
            },
          }
        )
        .then((res) => {
          let list = res.data;
          if (updateState) {
            switch (route) {
              case "all":
                commit(
                  OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMERS_LIST_ALL_STATS,
                  list
                );
                break;
              default:
                commit(
                  OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMERS_LIST_STATS,
                  list
                );
                commit(
                  OrderFormCustomerMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMERS_LIST_STATS,
                  false
                );
                break;
            }
          }
          commit(
            OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER,
            res
          );
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            switch (route) {
              case "all":
                commit(
                  OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMERS_LIST_ALL_STATS,
                  { count: 0, amount: 0, datas: [] }
                );
                break;
              default:
                commit(
                  OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMERS_LIST_STATS,
                  { count: 0, amount: 0, datas: [] }
                );
                commit(
                  OrderFormCustomerMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMERS_LIST_STATS,
                  false
                );
                break;
            }
          }
          commit(
            OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER,
            err
          );
          reject(err);
        });
    });
  },

  createOrderFormCustomer(
    { commit, dispatch, rootGetters },
    { orderFormCustomer }: { orderFormCustomer: OrderFormCustomerRequestDto }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(
        OrderFormCustomerMutations.SET_IS_CREATING_ORDER_FORM_CUSTOMER,
        true
      );
      axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            "Sale/OrderFormCustomer/CreateOrderFormCustomer",
          orderFormCustomer
        )
        .then((res) => {
          if (res.data.data.status != 8) {
            let orderFormCustomerFilters = rootGetters.userParametersList.find(
              (el) => el.key == "orderFormCustomerFilters"
            );
            if (orderFormCustomerFilters) {
              dispatch("getOrderFormCustomers", {
                dateFrom: JSON.parse(orderFormCustomerFilters.value).dateRange
                  .startDate,
                dateTo: JSON.parse(orderFormCustomerFilters.value).dateRange
                  .endDate,
              });
            } else {
              dispatch("setFilter", {
                name: "orderFormCustomerFilters",
                filter: {
                  dateRangeType: 7,
                  dateRange: { startDate: startDate, endDate: endDate },
                },
              });
              dispatch("getOrderFormCustomers", {
                dateFrom: startDate,
                dateTo: endDate,
              });
            }
          }
          commit(
            OrderFormCustomerMutations.SET_IS_CREATING_ORDER_FORM_CUSTOMER,
            false
          );
          commit(
            OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER,
            err
          );
          commit(
            OrderFormCustomerMutations.SET_IS_CREATING_ORDER_FORM_CUSTOMER,
            false
          );
          reject(err);
        });
    });
  },

  duplicateOrderFormCustomer(
    { commit, rootGetters },
    {
      orderFormCustomerId,
      userId = rootGetters.connectedUser.id,
    }: { orderFormCustomerId: number; userId?: number }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(
        OrderFormCustomerMutations.SET_IS_CREATING_ORDER_FORM_CUSTOMER,
        true
      );
      axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            "Sale/OrderFormCustomer/DuplicateOrderFormCustomer",
          {},
          {
            params: {
              orderFormCustomerId: orderFormCustomerId,
              userId: userId,
            },
          }
        )
        .then((res) => {
          commit(OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMER, {
            ...res.data,
            isTemplate: false,
          });
          commit(
            OrderFormCustomerMutations.SET_IS_CREATING_ORDER_FORM_CUSTOMER,
            false
          );
          commit(
            OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER,
            res
          );
          resolve({ ...res.data, isTemplate: false });
        })
        .catch((err) => {
          console.error(err);
          commit(
            OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER,
            err
          );
          commit(
            OrderFormCustomerMutations.SET_IS_CREATING_ORDER_FORM_CUSTOMER,
            false
          );
          reject(err);
        });
    });
  },

  createOrderFormCustomerFromQuote(
    { commit, rootGetters },
    {
      quoteId,
      status = 0,
      userId = rootGetters.connectedUser.id,
    }: { quoteId: number; status?: number; userId?: number }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(
        OrderFormCustomerMutations.SET_IS_CREATING_ORDER_FORM_CUSTOMER,
        true
      );
      axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            "Sale/OrderFormCustomer/CreateOrderFormCustomerFromQuote",
          {},
          {
            params: {
              quoteId: quoteId,
              userId: userId,
              status: status,
            },
          }
        )
        .then((res) => {
          commit(OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMER, res.data);
          commit(
            OrderFormCustomerMutations.SET_IS_CREATING_ORDER_FORM_CUSTOMER,
            false
          );
          commit(
            OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER,
            err
          );
          commit(
            OrderFormCustomerMutations.SET_IS_CREATING_ORDER_FORM_CUSTOMER,
            false
          );
          reject(err);
        });
    });
  },

  updateOrderFormCustomer(
    { commit, dispatch, rootGetters },
    {
      orderFormCustomer,
      updateState = true,
    }: { orderFormCustomer: OrderFormCustomerRequestDto; updateState?: boolean }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (updateState) {
        commit(
          OrderFormCustomerMutations.SET_IS_UPDATING_ORDER_FORM_CUSTOMER,
          true
        );
      }
      axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Sale/OrderFormCustomer/UpdateOrderFormCustomer",
          { ...orderFormCustomer, currencyId: 1 }
        )
        .then(async (res) => {
          if (
            res.data.data.orderFormCustomerBtp &&
            res.data.data.orderFormCustomerBtp.wasteCollectionPoints
          ) {
            res.data.data.orderFormCustomerBtp.wasteCollectionPointIds =
              res.data.data.orderFormCustomerBtp.wasteCollectionPoints.map(
                (elem) => elem.id
              );
            delete res.data.data.orderFormCustomerBtp.wasteCollectionPoints;
            res.data.data.orderFormCustomerBtp.WasteTypeIds =
              res.data.data.orderFormCustomerBtp.wasteTypes.map(
                (elem) => elem.id
              );
            delete res.data.data.orderFormCustomerBtp.wasteTypes;
          }

          let dataDocument = {
            ...res.data.data,
            paymentConditionId: res.data.data.paymentCondition
              ? res.data.data.paymentCondition.id
              : null,
            documentTvas: [],
          };
          if (updateState) {
            commit(
              OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMER,
              dataDocument
            );

            if (rootGetters.deduction.id) {
              await dispatch("updateDeduction", {
                deduction: rootGetters.deduction,
              });
            } else if (
              rootGetters.deduction.holdbackNumber ||
              rootGetters.deduction.prorataAccountPrice ||
              (rootGetters.deduction.punctualDeductions &&
                rootGetters.deduction.punctualDeductions.length > 0)
            ) {
              await dispatch("createDeduction", {
                deduction: rootGetters.deduction,
              });
            }
            if (updateState) {
              let orderFormCustomerFilters =
                rootGetters.userParametersList.find(
                  (el) => el.key == "orderFormCustomerFilters"
                );
              if (orderFormCustomerFilters) {
                dispatch("getOrderFormCustomers", {
                  dateFrom: JSON.parse(orderFormCustomerFilters.value).dateRange
                    .startDate,
                  dateTo: JSON.parse(orderFormCustomerFilters.value).dateRange
                    .endDate,
                });
              } else {
                dispatch("setFilter", {
                  name: "orderFormCustomerFilters",
                  filter: {
                    dateRangeType: 7,
                    dateRange: { startDate: startDate, endDate: endDate },
                  },
                });
                dispatch("getOrderFormCustomers", {
                  dateFrom: startDate,
                  dateTo: endDate,
                });
              }
              commit(
                OrderFormCustomerMutations.SET_IS_UPDATING_ORDER_FORM_CUSTOMER,
                false
              );
            }

            commit(
              OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER,
              res
            );
          }
          resolve(dataDocument);
        })
        .catch((err) => {
          console.error(err);
          commit(
            OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER,
            err
          );
          if (updateState) {
            commit(
              OrderFormCustomerMutations.SET_IS_UPDATING_ORDER_FORM_CUSTOMER,
              false
            );
          }
          reject(err);
        });
    });
  },

  updatePaymentMethodOrderFormCustomer(
    { commit, rootGetters },
    {
      paymentMethodOrderFormCustomer,
      orderFormCustomerId,
    }: { paymentMethodOrderFormCustomer: number[]; orderFormCustomerId: number }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Sale/OrderFormCustomer/UpdatePaymentMethodOrderFormCustomer",
          paymentMethodOrderFormCustomer,
          {
            params: {
              orderFormCustomerId: orderFormCustomerId,
            },
          }
        )
        .then((res) => {
          commit(
            OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER,
            err
          );
          reject(err);
        });
    });
  },

  uploadPathOrderFormCustomer(
    { commit, rootGetters },
    { orderFormCustomerId, path }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(
        OrderFormCustomerMutations.SET_IS_UPDATING_ORDER_FORM_CUSTOMER,
        true
      );
      axios
        .put(
          `${
            process.env.VUE_APP_API_ORISIS
          }Sale/OrderFormCustomer/UploadPathDocument?orderFormCustomerId=${orderFormCustomerId}&path=${encodeURIComponent(
            path
          )}`
        )
        .then((res) => {
          let dataDocument = {
            ...res.data,
            paymentConditionId: res.data.paymentCondition
              ? res.data.paymentCondition.id
              : null,
            orderFormCustomerTvas: [],
          };
          commit(
            OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMER,
            dataDocument
          );

          if (
            rootGetters.orderFormCustomersList.find(
              (elem) => elem.id == orderFormCustomerId
            )
          ) {
            rootGetters.orderFormCustomersList.splice(
              rootGetters.orderFormCustomersList.findIndex(
                (elem) => elem.id == orderFormCustomerId
              ),
              1,
              dataDocument
            );
            commit(
              OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMERS_LIST,
              rootGetters.orderFormCustomersList
            );
          }

          commit(
            OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER,
            res
          );
          commit(
            OrderFormCustomerMutations.SET_IS_UPDATING_ORDER_FORM_CUSTOMER,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER,
            err
          );
          commit(
            OrderFormCustomerMutations.SET_IS_UPDATING_ORDER_FORM_CUSTOMER,
            false
          );
          reject(err);
        });
    });
  },

  uploadFileOrderFormCustomer(
    { commit, rootGetters, dispatch },
    { formData, orderFormCustomerId, institutionId }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(FileMutations.SET_IS_CREATING_FILE, true);
      axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Sale/OrderFormCustomer/UploadFile?orderFormCustomerId=${orderFormCustomerId}&institutionId=${institutionId}`,
          formData
        )
        .then(async (res) => {
          if (!rootGetters.orderFormCustomer.folderId) {
            await dispatch("getOrderFormCustomerById", {
              orderFormCustomerId: orderFormCustomerId,
              updateState: false,
            });
          }
          commit(FolderMutations.SET_FOLDER, rootGetters.folder);
          commit(FileMutations.SET_REQUEST_STATE_FILE, res);
          commit(FileMutations.SET_IS_CREATING_FILE, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(FileMutations.SET_REQUEST_STATE_FILE, err);
          commit(FileMutations.SET_IS_CREATING_FILE, false);
          reject(err);
        });
    });
  },

  updateOrderFormCustomerTagOrderFormCustomer(
    { commit, rootGetters },
    { orderFormCustomerIds, orderFormCustomerTagIds }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(
        OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMERS_LIST,
        rootGetters.orderFormCustomersList
      );
      axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Sale/OrderFormCustomer/UpdateOrderFormCustomerTagOrderFormCustomer`,
          {
            orderFormCustomerIds: orderFormCustomerIds,
            orderFormCustomerTagIds: orderFormCustomerTagIds,
          }
        )
        .then((res) => {
          commit(
            OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER,
            err
          );
          reject(err);
        });
    });
  },

  deleteOrderFormCustomer(
    { commit, dispatch, rootGetters },
    {
      orderFormCustomerIds = [],
      route,
    }: { orderFormCustomerIds: number[]; route: string }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(
        OrderFormCustomerMutations.SET_IS_DELETING_ORDER_FORM_CUSTOMER,
        true
      );
      axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Sale/OrderFormCustomer/Delete",
          {
            data: orderFormCustomerIds,
          }
        )
        .then((res) => {
          if (
            route === "order-form-customers" ||
            route === "new-order-form-customer"
          ) {
            let orderFormCustomerFilters = rootGetters.userParametersList.find(
              (el) => el.key == "orderFormCustomerFilters"
            );
            if (orderFormCustomerFilters) {
              dispatch("getOrderFormCustomers", {
                dateFrom: JSON.parse(orderFormCustomerFilters.value).dateRange
                  .startDate,
                dateTo: JSON.parse(orderFormCustomerFilters.value).dateRange
                  .endDate,
              });
            } else {
              dispatch("setFilter", {
                name: "orderFormCustomerFilters",
                filter: {
                  dateRangeType: 7,
                  dateRange: { startDate: startDate, endDate: endDate },
                },
              });
              dispatch("getOrderFormCustomers", {
                dateFrom: startDate,
                dateTo: endDate,
              });
            }
          }

          commit(
            OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER,
            res
          );
          commit(
            OrderFormCustomerMutations.SET_IS_DELETING_ORDER_FORM_CUSTOMER,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER,
            err
          );
          commit(
            OrderFormCustomerMutations.SET_IS_DELETING_ORDER_FORM_CUSTOMER,
            false
          );
          reject(err);
        });
    });
  },

  generateOrderFormCustomerPDF({ commit, rootGetters, dispatch }, data) {
    return new Promise((resolve, reject) => {
      if (rootGetters.orderFormCustomer.usedUrlGed) {
        let urlDocumentPdf =
          "https://ged.orisis.fr/" +
          rootGetters.workspaceSelected.uniqueIdentifier +
          "/Imports/" +
          rootGetters.orderFormCustomer.documentReference +
          ".pdf";
        commit(
          OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMER_PDF,
          urlDocumentPdf
        );
        commit(
          OrderFormCustomerMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_PDF,
          false
        );
        resolve(urlDocumentPdf);
      } else {
        commit(
          OrderFormCustomerMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_PDF,
          true
        );
        axios
          .post(`${process.env.VUE_APP_PDF_GENERATOR_URL}generate`, data, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then(async (res) => {
            if (
              rootGetters.orderFormCustomer.status == 3 ||
              (rootGetters.orderFormCustomer.status >= 3 &&
                (!rootGetters.orderFormCustomer.path ||
                  rootGetters.orderFormCustomer.path == null ||
                  rootGetters.orderFormCustomer.path.includes("null.pdf")))
            ) {
              await dispatch("uploadPathOrderFormCustomer", {
                orderFormCustomerId: rootGetters.orderFormCustomer.id,
                path: res.data,
              });
            }

            commit(
              OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMER_PDF,
              res.data
            );
            commit(
              OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_PDF,
              res
            );
            commit(
              OrderFormCustomerMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_PDF,
              false
            );
            resolve(res.data);
          })
          .catch((err) => {
            console.error(err);
            commit(
              OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_PDF,
              err
            );
            commit(
              OrderFormCustomerMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_PDF,
              false
            );
            reject(err);
          });
      }
    });
  },

  generateOrderTrackingCustomerPDF({ commit, rootGetters, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit(
        OrderFormCustomerMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_PDF,
        true
      );
      axios
        .post(`${process.env.VUE_APP_PDF_GENERATOR_URL}generate`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(async (res) => {
          commit(
            OrderFormCustomerMutations.SET_ORDER_TRACKING_CUSTOMER_PDF,
            res.data
          );
          commit(
            OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_PDF,
            res
          );
          commit(
            OrderFormCustomerMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_PDF,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            OrderFormCustomerMutations.SET_ORDER_TRACKING_CUSTOMER_PDF,
            ""
          );
          commit(
            OrderFormCustomerMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_PDF,
            err
          );
          commit(
            OrderFormCustomerMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_PDF,
            false
          );
          reject(err);
        });
    });
  },

  checkOrderFormCustomerMarginStatus({ commit, rootGetters }): void {
    let status = true;
    let linesForCacul = rootGetters.getOrderFormCustomerDetails.filter(
      (el) =>
        el.type != 18 &&
        el.type != 17 &&
        el.type != 16 &&
        el.type != 15 &&
        el.type != 14 &&
        el.type != 0 &&
        el.type != 1 &&
        el.type != 2 &&
        el.type != 11 &&
        el.type != 12 &&
        !el.isOption
    );
    for (let i = 0; i < linesForCacul.length; i++) {
      const line = linesForCacul[i];
      if (line.marginRate == null) {
        status = false;
        break;
      }
    }
    commit(
      OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMER_MARGIN_STATUS,
      status
    );
  },

  setupHeaderOrderFormCustomer({ rootGetters }) {
    let orderFormCustomerOption = rootGetters.orderFormCustomerOption;
    let header = rootGetters.getOrderFormCustomerHeader;
    header["reference"].display = orderFormCustomerOption.showReferenceColumn;
    header["discount"].display = orderFormCustomerOption.showDiscountColumn;
    header["quantity"].display = !orderFormCustomerOption.hideQuantityColumn;
    header["unitId"].display = !orderFormCustomerOption.hideUnitColumn;
    header["disbursedNetHt"].display =
      orderFormCustomerOption.calculateMargin &&
      rootGetters.orderFormCustomerTabActive != "document-pdf";
    header["marginCoefficient"].display =
      orderFormCustomerOption.calculateMargin &&
      rootGetters.orderFormCustomerTabActive != "document-pdf";
    header["unitPriceHt"].display =
      !orderFormCustomerOption.hideUnitPriceHtColumn;
    header["referencielTvaId"].display =
      !orderFormCustomerOption.hideReferencielTvaColumn;
    header["marginRate"].display = false;
    // orderFormCustomerOption.calculateMargin &&
    // rootGetters.orderFormCustomerTabActive != "order-form-customer-pdf";
    header["total"].display = !orderFormCustomerOption.hidePriceHtColumn;
  },

  changeOrderFormCustomerTabActive(
    { commit, dispatch },
    tabToActivate: string
  ) {
    commit(
      OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMER_TAB_ACTIVE,
      tabToActivate
    );
    dispatch("setupHeaderOrderFormCustomer");
  },
};
