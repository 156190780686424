import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { ReportDetailRequestDto } from "@/../Api";
import { MutationTree } from "vuex";
import { IReportDetailState } from "./states";

export enum ReportDetailMutations {
  SET_REPORT_DETAILS_LIST = "SET_REPORT_DETAILS_LIST",
  SET_REPORT_DETAILS_MINIFIED_LIST = "SET_REPORT_DETAILS_MINIFIED_LIST",
  SET_REPORT_DETAILS_LIST_ARCHIVED = "SET_REPORT_DETAILS_LIST_ARCHIVED",
  SET_REPORT_DETAILS_LIST_BY_COLLABORATOR = "SET_REPORT_DETAILS_LIST_BY_COLLABORATOR",
  SET_REPORT_DETAIL = "SET_REPORT_DETAIL",
  SET_REPORTS_DETAIL = "SET_REPORTS_DETAIL",
  SET_REPORT_DETAIL_FILTERS = "SET_REPORT_DETAIL_FILTERS",
  SET_REPORT_DETAIL_FILTERS_BY_COLLABORATOR = "SET_REPORT_DETAIL_FILTERS_BY_COLLABORATOR",

  // COMMON
  SET_IS_LOADING_REPORT_DETAILS_LIST = "SET_IS_LOADING_REPORT_DETAILS_LIST",
  SET_IS_LOADING_REPORT_DETAILS_MINIFIED_LIST = "SET_IS_LOADING_REPORT_DETAILS_MINIFIED_LIST",
  SET_IS_LOADING_REPORT_DETAILS_LIST_ARCHIVED = "SET_IS_LOADING_REPORT_DETAILS_LIST_ARCHIVED",
  SET_IS_LOADING_REPORT_DETAILS_LIST_BY_COLLABORATOR = "SET_IS_LOADING_REPORT_DETAILS_LIST_BY_COLLABORATOR",
  SET_IS_LOADING_REPORT_DETAIL = "SET_IS_LOADING_REPORT_DETAIL",
  SET_IS_LOADING_REPORTS_DETAIL = "SET_IS_LOADING_REPORTS_DETAIL",
  SET_IS_CREATING_REPORT_DETAIL = "SET_IS_CREATING_REPORT_DETAIL",
  SET_IS_UPDATING_REPORT_DETAIL = "SET_IS_UPDATING_REPORT_DETAIL",
  SET_IS_DELETING_REPORT_DETAIL = "SET_IS_DELETING_REPORT_DETAIL",
  SET_IS_ARCHIVING_REPORT_DETAIL = "SET_IS_ARCHIVING_REPORT_DETAIL",
  SET_REQUEST_STATE_REPORT_DETAIL = "SET_REQUEST_STATE_REPORT_DETAIL",
}

export const mutations: MutationTree<IReportDetailState> = {
  [ReportDetailMutations.SET_REPORT_DETAILS_LIST_ARCHIVED]: (
    state,
    payload: ReportDetailRequestDto[]
  ) => {
    state.reportDetailsListArchived = payload;
  },
  [ReportDetailMutations.SET_REPORT_DETAILS_LIST_BY_COLLABORATOR]: (
    state,
    payload: ReportDetailRequestDto[]
  ) => {
    state.reportDetailsListByCollaborator = payload;
  },
  [ReportDetailMutations.SET_REPORT_DETAILS_LIST]: (
    state,
    payload: ReportDetailRequestDto[]
  ) => {
    state.reportDetailsList = payload;
  },
  [ReportDetailMutations.SET_REPORT_DETAILS_MINIFIED_LIST]: (
    state,
    payload: ReportDetailRequestDto[]
  ) => {
    state.reportDetailsMinifiedList = payload;
  },
  [ReportDetailMutations.SET_REPORT_DETAIL]: (
    state,
    payload: ReportDetailRequestDto
  ) => {
    state.reportDetail = payload;
  },
  [ReportDetailMutations.SET_REPORTS_DETAIL]: (
    state,
    payload: ReportDetailRequestDto[]
  ) => {
    state.reportsDetail = payload;
  },
  [ReportDetailMutations.SET_REPORT_DETAIL_FILTERS]: (state, payload: any) => {
    state.reportDetailFilters = payload;
  },
  [ReportDetailMutations.SET_REPORT_DETAIL_FILTERS_BY_COLLABORATOR]: (
    state,
    payload: any
  ) => {
    state.reportDetailFiltersByCollaborator = payload;
  },

  //COMMON
  [ReportDetailMutations.SET_IS_LOADING_REPORT_DETAILS_LIST_ARCHIVED]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingReportDetailsListArchived = payload;
  },
  [ReportDetailMutations.SET_IS_LOADING_REPORT_DETAILS_LIST_BY_COLLABORATOR]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingReportDetailsListByCollaborator = payload;
  },
  [ReportDetailMutations.SET_IS_LOADING_REPORT_DETAILS_LIST]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingReportDetailsList = payload;
  },
  [ReportDetailMutations.SET_IS_LOADING_REPORT_DETAILS_MINIFIED_LIST]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingReportDetailsMinifiedList = payload;
  },
  [ReportDetailMutations.SET_IS_LOADING_REPORT_DETAIL]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingReportDetail = payload;
  },
  [ReportDetailMutations.SET_IS_LOADING_REPORTS_DETAIL]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingReportsDetail = payload;
  },
  [ReportDetailMutations.SET_IS_CREATING_REPORT_DETAIL]: (
    state,
    payload: boolean
  ) => {
    state.isCreatingReportDetail = payload;
  },
  [ReportDetailMutations.SET_IS_UPDATING_REPORT_DETAIL]: (
    state,
    payload: boolean
  ) => {
    state.isUpdatingReportDetail = payload;
  },
  [ReportDetailMutations.SET_IS_DELETING_REPORT_DETAIL]: (
    state,
    payload: boolean
  ) => {
    state.isDeletingReportDetail = payload;
  },
  [ReportDetailMutations.SET_IS_ARCHIVING_REPORT_DETAIL]: (
    state,
    payload: boolean
  ) => {
    state.isArchivingReportDetail = payload;
  },
  [ReportDetailMutations.SET_REQUEST_STATE_REPORT_DETAIL]: (
    state,
    payload: IRequestState
  ) => {
    state.requestStateReportDetail = payload;
  },
};
