import RootState from "@/store/modules/rootState";
import {
  CreditRequestDto,
  InvoiceRequestDto,
  QuoteOptionRequestDto,
  QuoteRequestDto,
  InvoiceOptionRequestDto,
  CreditOptionRequestDto,
} from "Api";
import axios from "axios";
import { ActionTree } from "vuex";
import { DeductionMutations } from "../deduction/mutations";
import { OrderFormCustomerDetailMutations } from "../orderFormCustomerDetail/mutations";
import { QuoteDownPaymentsRequestMutations } from "../quoteDownPaymentsRequest/mutations";
import { DocumentMutations } from "./mutations";
import { IDocumentState } from "./states";

import { DeductionDefaultProperties } from "@/types/api-orisis/models/DeductionModels";
import dayjs from "dayjs";

import { statusCheck } from "@/types/api-orisis/enums/enums";
import { FileMutations } from "../../ged/file/mutations";
import { FolderMutations } from "../../ged/folder/mutations";
import { InitialDocumentMutations } from "../initialDocument/mutations";

import { Timezone } from "@syncfusion/ej2-schedule";

import { generateParams } from "@/types/api-orisis/library/FormatOperations";
import { IDocument } from "@/types/api-orisis/interfaces/invoices/document/IDocument";

let timezoneSchedule = new Timezone();

let dateFrom = new Date(
  dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
).toISOString();
let dateTo = new Date(
  dayjs().endOf("month").format("YYYY-MM-DD")
).toISOString();

export const actions: ActionTree<IDocumentState, RootState> = {
  // ***** Credit Actions ***** //

  async getCredits(
    { commit, rootGetters },
    {
      dateFrom = null,
      dateTo = null,
      affairId = null,
      companyIds = [],
      status = [],
      updateState = true,
    }: {
      dateFrom?: Date;
      dateTo?: Date;
      affairId?: number;
      companyIds?: [];
      status?: [];
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (updateState) {
        commit(DocumentMutations.SET_IS_LOADING_DOCUMENTS_LIST, true);
        commit(DocumentMutations.SET_IS_LOADING_CREDITS_LIST, true);
      }
      let request = new URLSearchParams();
      request = generateParams(request, companyIds, "companyIds");
      request = generateParams(request, status, "status");
      if (affairId) {
        request = generateParams(request, [affairId], "affairId");
      }
      if (dateFrom) {
        request = generateParams(
          request,
          [dateFrom ? dayjs(dateFrom).format("YYYY-MM-DD") : null],
          "dateFrom"
        );
      }
      if (dateTo) {
        request = generateParams(
          request,
          [
            dateTo
              ? dayjs(dateTo)
                  .hour(23)
                  .minute(59)
                  .second(59)
                  .format("YYYY-MM-DD HH:mm:ss")
              : null,
          ],
          "dateTo"
        );
      }
      axios
        .get(process.env.VUE_APP_API_ORISIS + "Sale/Credit/GetCredits", {
          params: request,
        })
        .then((res) => {
          let list = res.data.map((el) => {
            return {
              ...el,
              documentReference:
                el.documentReference == null
                  ? "En attente"
                  : el.documentReference,
              documentDate: el.documentDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(el.documentDate)
                      )
                    ).setHours(0, 0, 0, 0)
                  )
                : null,
              countedDate: el.countedDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(el.countedDate)
                      )
                    ).setHours(0, 0, 0, 0)
                  )
                : null,
              totalTtc: Math.round(el.totalTtc * 100) / 100,
              collaboratorId:
                el.companyId !== null && el.companyId !== "" && el.companyId > 0
                  ? rootGetters.companiesList.find(
                      (company) => company.id == el.companyId
                    )
                    ? rootGetters.companiesList.find(
                        (company) => company.id == el.companyId
                      ).collaboratorId
                    : ""
                  : "",
            };
          });
          if (updateState) {
            commit(DocumentMutations.SET_IS_LOADING_DOCUMENTS_LIST, false);
            commit(DocumentMutations.SET_CREDITS_LIST, list);
          }
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_IS_LOADING_DOCUMENTS_LIST, false);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          reject(err);
        });
    });
  },

  async getCreditsByCompanyIds(
    { commit },
    {
      dateFrom = null,
      dateTo = null,
      companyIds = [],
      status = [],
      updateState = true,
    }: {
      dateFrom?: Date;
      dateTo?: Date;
      companyIds?: number[];
      status?: number[];
      updateState?: boolean;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          DocumentMutations.SET_IS_LOADING_DOCUMENTS_LIST_BY_COMPANY,
          true
        );
      }
      let request = new URLSearchParams();
      request = generateParams(request, companyIds, "companyIds");
      request = generateParams(request, status, "status");
      if (dateFrom && dateTo) {
        request = generateParams(
          request,
          [dayjs(dateFrom).format("YYYY-MM-DD")],
          "dateFrom"
        );
        request = generateParams(
          request,
          [
            dayjs(dateTo)
              .hour(23)
              .minute(59)
              .second(59)
              .format("YYYY-MM-DD HH:mm:ss"),
          ],
          "dateTo"
        );
      }
      await axios
        .get(process.env.VUE_APP_API_ORISIS + "Sale/Credit/GetCredits", {
          params: {
            request,
            dateFrom: dateFrom ? dayjs(dateFrom).format("YYYY-MM-DD") : null,
            dateTo: dateTo
              ? dayjs(dateTo)
                  .hour(23)
                  .minute(59)
                  .second(59)
                  .format("YYYY-MM-DD HH:mm:ss")
              : null,
            companyIds: companyIds,
          },
        })
        .then((res) => {
          let list = res.data.map((el) => {
            return {
              ...el,
              totalTtc: Math.round(el.totalTtc * 100) / 100,
              documentDate: el.documentDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(el.documentDate)
                      )
                    ).setHours(0, 0, 0, 0)
                  )
                : null,
              countedDate: el.countedDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(el.countedDate)
                      )
                    ).setHours(0, 0, 0, 0)
                  )
                : null,
            };
          });
          if (updateState) {
            commit(
              DocumentMutations.SET_IS_LOADING_DOCUMENTS_LIST_BY_COMPANY,
              false
            );
            commit(DocumentMutations.SET_CREDITS_LIST_BY_COMPANY, list);
          }
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DocumentMutations.SET_IS_LOADING_DOCUMENTS_LIST_BY_COMPANY,
            false
          );
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          reject(err);
        });
    });
  },

  async getCreditsByAffairId(
    { commit },
    {
      dateFrom = null,
      dateTo = null,
      affairId,
      updateState = true,
    }: {
      dateFrom?: Date;
      dateTo?: Date;
      affairId?: number;
      updateState?: boolean;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(DocumentMutations.SET_IS_LOADING_DOCUMENTS_LIST_BY_AFFAIR, true);
      }
      await axios
        .get(process.env.VUE_APP_API_ORISIS + "Sale/Credit/GetCredits", {
          params: {
            dateFrom: dateFrom ? dayjs(dateFrom).format("YYYY-MM-DD") : null,
            dateTo: dateTo
              ? dayjs(dateTo)
                  .hour(23)
                  .minute(59)
                  .second(59)
                  .format("YYYY-MM-DD HH:mm:ss")
              : null,
            affairId: affairId,
          },
        })
        .then((res) => {
          let list = res.data.map((el) => {
            return {
              ...el,
              totalTtc: Math.round(el.totalTtc * 100) / 100,
              documentDate: el.documentDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(el.documentDate)
                      )
                    ).setHours(0, 0, 0, 0)
                  )
                : null,
              countedDate: el.countedDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(el.countedDate)
                      )
                    ).setHours(0, 0, 0, 0)
                  )
                : null,
            };
          });
          if (updateState) {
            commit(
              DocumentMutations.SET_IS_LOADING_DOCUMENTS_LIST_BY_AFFAIR,
              false
            );
            commit(DocumentMutations.SET_CREDITS_LIST_BY_AFFAIR, list);
          }
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DocumentMutations.SET_IS_LOADING_DOCUMENTS_LIST_BY_AFFAIR,
            false
          );
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          reject(err);
        });
    });
  },

  async getCreditByInvoiceId(
    { commit },
    {
      dateFrom = null,
      dateTo = null,
      invoiceId,
    }: {
      dateFrom?: Date;
      dateTo?: Date;
      invoiceId?: number;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      await axios
        .get(process.env.VUE_APP_API_ORISIS + "Sale/Credit/GetCredits", {
          params: {
            dateFrom: dateFrom ? dayjs(dateFrom).format("YYYY-MM-DD") : null,
            endDate: dateTo
              ? dayjs(dateTo)
                  .hour(23)
                  .minute(59)
                  .second(59)
                  .format("YYYY-MM-DD HH:mm:ss")
              : null,
            invoiceId: invoiceId,
          },
        })
        .then((res) => {
          let result = res.data ? res.data : null;
          commit(DocumentMutations.SET_CREDIT_BY_INVOICE, result);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve(result);
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          reject(err);
        });
    });
  },

  async getCreditById(
    { commit, dispatch },
    {
      creditId,
      updateState = true,
    }: { creditId?: number; updateState?: boolean }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(DocumentMutations.SET_DOCUMENT_TAB_ACTIVE, "document-edit");
      commit(InitialDocumentMutations.SET_INITIAL_DOCUMENT, null);
      if (updateState) {
        commit(DocumentMutations.SET_INITIAL_INVOICE, null);
        commit(DocumentMutations.SET_IS_LOADING_DOCUMENT, true);
        commit(
          DocumentMutations.SET_LOADING_MSG,
          `Chargement des détails de l'avoir...`
        );
      }
      await axios
        .get(process.env.VUE_APP_API_ORISIS + "Sale/Credit/GetCreditById", {
          params: {
            creditId: creditId,
          },
        })
        .then(async (res) => {
          if (!res.data.usedUrlGed)
            await dispatch("getCreditOptionByCreditId", {
              creditId: res.data.id,
            });
          await dispatch("getPaymentsByCreditId", {
            creditId: res.data.id,
            startTo: null,
            endTo: null,
            updateState: false,
          }).then((result) => {
            res.data.payments = result.map((payment) => {
              return {
                ...payment.credits.filter((el) => el.id == res.data.id)[0],
                date: payment.date,
                paymentMethod: payment.paymentMethod.label,
              };
            });
          });

          if (
            res.data.status >= 2 &&
            res.data.path &&
            !res.data.path.includes("null.pdf")
          ) {
            commit(DocumentMutations.SET_DOCUMENT_PDF, res.data.path);
          } else {
            commit(DocumentMutations.SET_DOCUMENT_PDF, "");
          }
          let credit = {
            ...res.data,
            documentTvas: [],
          };
          commit(DocumentMutations.SET_DOCUMENT, credit);
          commit(
            DeductionMutations.SET_DEDUCTION,
            res.data.deduction == null
              ? DeductionDefaultProperties({ creditId: res.data.id })
              : res.data.deduction
          );
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);

          if (
            !statusCheck.documentEditable.includes(res.data.status) ||
            res.data.usedUrlGed
          ) {
            dispatch("changeDocumentTabActive", "document-pdf");
          }

          if (res.data.invoiceId) {
            dispatch("getInitialInvoiceById", {
              invoiceId: res.data.invoiceId,
            });
          }

          await dispatch("getDocumentDetailByCreditId", { creditId: creditId });
          commit(DocumentMutations.SET_IS_LOADING_DOCUMENT, false);
          resolve(credit);
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          commit(DocumentMutations.SET_IS_LOADING_DOCUMENT, false);
          reject(err);
        });
    });
  },

  async getCreditEmpty(
    { commit, rootGetters, dispatch },
    {
      updateState = true,
      affairId = null,
      companyId = null,
      nature = [],
    }: {
      updateState?: boolean;
      affairId?: number;
      nature?: number[];
      companyId?: number;
    }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (updateState) {
        commit(DocumentMutations.SET_IS_CREATING_DOCUMENT, true);
      }
      await axios
        .get(process.env.VUE_APP_API_ORISIS + "Sale/Credit/GetCreditEmpty", {
          params: {
            affairId: affairId,
            nature: nature,
            userId: rootGetters.connectedUser.id,
            companyId: companyId,
          },
        })
        .then((res) => {
          if (updateState) {
            if (res.data.status == 8) {
              let creditFilters = rootGetters.userParametersList.find(
                (el) => el.key == "creditFilters"
              );
              if (creditFilters) {
                dispatch("getCredits", {
                  dateFrom: JSON.parse(creditFilters.value).dateRange.dateFrom,
                  dateTo: JSON.parse(creditFilters.value).dateRange.dateTo,
                });
              } else {
                dispatch("setFilter", {
                  name: "creditFilters",
                  filter: {
                    dateRangeType: 7,
                    dateRange: { dateFrom: dateFrom, dateTo: dateTo },
                  },
                });
                dispatch("getCredits", {
                  dateFrom: dateFrom,
                  dateTo: dateTo,
                });
              }
            }
          }
          commit(DocumentMutations.SET_DOCUMENT_PDF, "");
          commit(DocumentMutations.SET_DOCUMENT, {
            ...res.data,
          });
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          commit(DocumentMutations.SET_IS_CREATING_DOCUMENT, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          commit(DocumentMutations.SET_IS_CREATING_DOCUMENT, false);
          reject(err);
        });
    });
  },

  async createCredit(
    { commit, dispatch, rootGetters },
    { credit }: { credit: CreditRequestDto }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(DocumentMutations.SET_IS_CREATING_DOCUMENT, true);
      await axios
        .post(
          process.env.VUE_APP_API_ORISIS + "Sale/Credit/CreateCredit",
          credit
        )
        .then((res) => {
          let creditFilters = rootGetters.userParametersList.find(
            (el) => el.key == "creditFilters"
          );
          if (creditFilters) {
            dispatch("getCredits", {
              dateFrom: JSON.parse(creditFilters.value).dateRange.dateFrom,
              dateTo: JSON.parse(creditFilters.value).dateRange.dateTo,
            });
          } else {
            dispatch("setFilter", {
              name: "creditFilters",
              filter: {
                dateRangeType: 7,
                dateRange: { dateFrom: dateFrom, dateTo: dateTo },
              },
            });
            dispatch("getCredits", {
              dateFrom: dateFrom,
              dateTo: dateTo,
            });
          }

          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          commit(DocumentMutations.SET_IS_CREATING_DOCUMENT, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          commit(DocumentMutations.SET_IS_CREATING_DOCUMENT, false);
          reject(err);
        });
    });
  },

  async updateCredit(
    { commit, dispatch, rootGetters },
    {
      credit,
      updateState = true,
    }: { credit: CreditRequestDto; updateState?: boolean }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (updateState) {
        commit(DocumentMutations.SET_IS_UPDATING_DOCUMENT, true);
        commit(
          DocumentMutations.SET_LOADING_MSG,
          "Chargement de l'avoir en cours..."
        );
      }
      await axios
        .put(process.env.VUE_APP_API_ORISIS + "Sale/Credit/UpdateCredit", {
          ...credit,
          currencyId: 1,
        })
        .then((res) => {
          let dataDocument = {
            ...res.data.data,
            paymentConditionId: res.data.data.paymentCondition
              ? res.data.data.paymentCondition.id
              : null,
            documentTvas: [],
          };
          commit(DocumentMutations.SET_DOCUMENT, dataDocument);
          if (updateState) {
            let creditFilters = rootGetters.userParametersList.find(
              (el) => el.key == "creditFilters"
            );
            if (creditFilters) {
              dispatch("getCredits", {
                dateFrom: JSON.parse(creditFilters.value).dateRange.dateFrom,
                dateTo: JSON.parse(creditFilters.value).dateRange.dateTo,
              });
            } else {
              dispatch("setFilter", {
                name: "creditFilters",
                filter: {
                  dateRangeType: 7,
                  dateRange: { dateFrom: dateFrom, dateTo: dateTo },
                },
              });
              dispatch("getCredits", {
                dateFrom: dateFrom,
                dateTo: dateTo,
              });
            }
            commit(DocumentMutations.SET_IS_UPDATING_DOCUMENT, false);
          }

          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve(dataDocument);
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          commit(DocumentMutations.SET_IS_UPDATING_DOCUMENT, false);
          reject(err);
        });
    });
  },

  async uploadFileCredit(
    { commit, rootGetters, dispatch },
    { formData, creditId }: { formData: FormData; creditId: number }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(FileMutations.SET_IS_CREATING_FILE, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Sale/Credit/UploadFile`,
          formData,
          {
            params: {
              creditId: creditId,
            },
          }
        )
        .then(async (res) => {
          if (!rootGetters.document.folderId) {
            await dispatch("getCreditById", {
              creditId: creditId,
              updateState: false,
            });
          }
          commit(FolderMutations.SET_FOLDER, rootGetters.folder);
          commit(FileMutations.SET_REQUEST_STATE_FILE, res);
          commit(FileMutations.SET_IS_CREATING_FILE, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(FileMutations.SET_REQUEST_STATE_FILE, err);
          commit(FileMutations.SET_IS_CREATING_FILE, false);
          reject(err);
        });
    });
  },

  async updateCreditTagCredit(
    { commit },
    { creditIds, creditTagIds }: { creditIds: number[]; creditTagIds: number[] }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Sale/Credit/UpdateCreditTagCredit`,
          { creditIds: creditIds, creditTagIds: creditTagIds }
        )
        .then((res) => {
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          reject(err);
        });
    });
  },

  async deleteCredits(
    { commit, dispatch, rootGetters },
    { creditIds = [], route }: { creditIds: number[]; route: string }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(DocumentMutations.SET_IS_DELETING_DOCUMENT, true);
      await axios
        .delete(process.env.VUE_APP_API_ORISIS + "Sale/Credit/Delete", {
          data: creditIds,
        })
        .then((res) => {
          if (route === "credits" || route === "new-credit") {
            let creditFilters = rootGetters.userParametersList.find(
              (el) => el.key == "creditFilters"
            );
            if (creditFilters) {
              dispatch("getCredits", {
                dateFrom: JSON.parse(creditFilters.value).dateRange.dateFrom,
                dateTo: JSON.parse(creditFilters.value).dateRange.dateTo,
              });
            } else {
              dispatch("setFilter", {
                name: "invoiceFilters",
                filter: {
                  dateRangeType: 7,
                  dateRange: { dateFrom: dateFrom, dateTo: dateTo },
                },
              });
              dispatch("getCredits", {
                dateFrom: dateFrom,
                dateTo: dateTo,
              });
            }
          } else if (route === "lease" || route === "affair") {
            dispatch("getCreditsByAffairId", {
              affairId: rootGetters.lease.affairId,
              updateState: true,
            });
          }

          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          commit(DocumentMutations.SET_IS_DELETING_DOCUMENT, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          commit(DocumentMutations.SET_IS_DELETING_DOCUMENT, false);
          reject(err);
        });
    });
  },

  // *** Credits Minified *** //
  async getCreditMinifiedById(
    {},
    { creditId }: { creditId?: number }
  ): Promise<object> {
    return new Promise(async (resolve, reject) => {
      await axios
        .get(process.env.VUE_APP_API_ORISIS + "Sale/Credit/GetCreditById", {
          params: {
            creditId: creditId,
          },
        })
        .then(async (res) => {
          let creditMinified = {
            documentReference: res.data.documentReference,
            documentDate: res.data.documentDate,
            totalHt: res.data.totalHt,
            totalTtc: res.data.totalTtc,
            status: res.data.status,
          };
          resolve(creditMinified);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },

  async getCreditsLastYearStats(
    { commit },
    { dateFrom = null, dateTo = null }: { dateFrom?: Date; dateTo?: Date }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        DocumentMutations.SET_IS_LOADING_CREDITS_LIST_LAST_YEAR_STATS,
        true
      );
      commit(DocumentMutations.SET_CREDITS_LIST_LAST_YEAR_STATS, {
        count: 0,
        amount: 0,
        datas: [],
      });
      await axios
        .get(process.env.VUE_APP_API_ORISIS + "Sale/Credit/GetCreditStats", {
          params: {
            startDate: dateFrom,
            endDate: dateTo,
          },
        })
        .then((res) => {
          let result = {
            ...res.data,
            datas: res.data.datas.map((el) => {
              return {
                ...el,
                nature: "credits",
              };
            }),
          };
          commit(DocumentMutations.SET_CREDITS_LIST_LAST_YEAR_STATS, result);
          commit(
            DocumentMutations.SET_IS_LOADING_CREDITS_LIST_LAST_YEAR_STATS,
            false
          );
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve(result);
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_CREDITS_LIST_LAST_YEAR_STATS, {
            count: 0,
            amount: 0,
            datas: [],
          });
          commit(
            DocumentMutations.SET_IS_LOADING_CREDITS_LIST_LAST_YEAR_STATS,
            false
          );
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          reject(err);
        });
    });
  },

  async getCreditsStats(
    { commit, dispatch },
    {
      dateFrom = null,
      dateTo = null,
      updateState = true,
    }: { dateFrom?: Date; dateTo?: Date; updateState?: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(DocumentMutations.SET_IS_LOADING_DOCUMENTS_LIST, true);
        commit(DocumentMutations.SET_IS_LOADING_CREDITS_LIST_STATS, true);
        commit(DocumentMutations.SET_CREDITS_LIST_STATS, {
          count: 0,
          amount: 0,
          datas: [],
        });
      }

      dispatch("getCreditsLastYearStats", {
        dateFrom: dayjs(dateFrom).subtract(1, "year").format("YYYY-MM-DD"),
        dateTo: dayjs(dateTo).subtract(1, "year").format("YYYY-MM-DD"),
      });

      await axios
        .get(process.env.VUE_APP_API_ORISIS + "Sale/Credit/GetCreditStats", {
          params: {
            startDate: dateFrom,
            endDate: dateTo,
          },
        })
        .then((res) => {
          let result = {
            ...res.data,
            datas: res.data.datas.map((el) => {
              return {
                ...el,
                nature: "credits",
              };
            }),
          };
          if (updateState) {
            commit(DocumentMutations.SET_CREDITS_LIST_STATS, result);
            commit(DocumentMutations.SET_IS_LOADING_CREDITS_LIST_STATS, false);
          }

          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve(result);
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_CREDITS_LIST_STATS, {
            count: 0,
            amount: 0,
            datas: [],
          });
          commit(DocumentMutations.SET_IS_LOADING_CREDITS_LIST_STATS, false);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          reject(err);
        });
    });
  },

  // ***** Invoice Actions ***** //

  async getInvoices(
    { commit, rootGetters },
    {
      companyIds = [],
      status = [],
      affairId = null,
      isTemplate = false,
      dateFrom = null,
      dateTo = null,
      isRemaining = false,
      updateState = true,
    }: {
      companyIds?: number[];
      status?: number[];
      affairId?: number;
      isTemplate?: boolean;
      dateFrom?: Date;
      dateTo?: Date;
      isRemaining?: boolean;
      updateState?: boolean;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(DocumentMutations.SET_IS_LOADING_DOCUMENTS_LIST, true);
        commit(DocumentMutations.SET_IS_LOADING_INVOICES_LIST, true);
      }
      let request = new URLSearchParams();
      request = generateParams(request, companyIds, "companyIds");
      request = generateParams(request, status, "status");
      request = affairId
        ? generateParams(request, [affairId], "affairId")
        : request;
      request = generateParams(request, [isTemplate], "isTemplate");
      request = dateFrom
        ? generateParams(
            request,
            [dayjs(dateFrom).format("YYYY-MM-DD")],
            "dateFrom"
          )
        : request;
      request = dateTo
        ? generateParams(
            request,
            [dayjs(dateTo).format("YYYY-MM-DD")],
            "dateTo"
          )
        : request;
      await axios
        .get(process.env.VUE_APP_API_ORISIS + "Sale/Invoice/GetInvoices", {
          params: request,
        })
        .then((res) => {
          let list = res.data.map((el) => {
            return {
              ...el,
              totalTtc: Math.round(el.totalTtc * 100) / 100,
              documentDate: el.documentDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(el.documentDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,

              limitDate: el.limitDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(new Date(el.limitDate))
                    ).setHours(12, 0, 0, 0)
                  )
                : null,

              countedDate: el.countedDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(el.countedDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              collaboratorId:
                el.companyId !== null && el.companyId !== "" && el.companyId > 0
                  ? rootGetters.companiesList.find(
                      (company) => company.id == el.companyId
                    )
                    ? rootGetters.companiesList.find(
                        (company) => company.id == el.companyId
                      ).collaboratorId
                    : ""
                  : "",
            };
          });
          if (updateState) {
            commit(DocumentMutations.SET_IS_LOADING_DOCUMENTS_LIST, false);
            commit(DocumentMutations.SET_IS_LOADING_INVOICES_LIST, false);
          }
          if (isTemplate) {
            commit(DocumentMutations.SET_INVOICES_LIST_TEMPLATES, list);
          } else {
            commit(DocumentMutations.SET_INVOICES_LIST, list);
          }
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_IS_LOADING_DOCUMENTS_LIST, false);
          commit(DocumentMutations.SET_IS_LOADING_INVOICES_LIST, false);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          reject(err);
        });
    });
  },

  async getInvoicesByCompanyIds(
    { commit },
    {
      companyIds = [],
      status = [],
      affairId = null,
      isTemplate = false,
      dateFrom = null,
      dateTo = null,
      isRemaining = false,
      updateState = true,
    }: {
      companyIds?: number[];
      status?: number[];
      affairId?: number;
      isTemplate?: boolean;
      dateFrom?: Date;
      dateTo?: Date;
      isRemaining?: boolean;
      updateState?: boolean;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          DocumentMutations.SET_IS_LOADING_DOCUMENTS_LIST_BY_COMPANY,
          true
        );
      }
      let request = new URLSearchParams();
      request = generateParams(request, companyIds, "companyIds");
      request = generateParams(request, status, "status");
      request = generateParams(request, affairId, "affairId");
      request = generateParams(request, isTemplate, "isTemplate");
      if (dateFrom) {
        request = generateParams(
          request,
          [dayjs(dateFrom).format("YYYY-MM-DD")],
          "dateFrom"
        );
      }
      if (dateTo) {
        request = generateParams(
          request,
          [dayjs(dateTo).format("YYYY-MM-DD")],
          "dateTo"
        );
      }
      await axios
        .get(process.env.VUE_APP_API_ORISIS + "Sale/Invoice/GetInvoices", {
          params: {
            dateFrom: dateFrom ? dayjs(dateFrom).format("YYYY-MM-DD") : null,
            dateTo: dateTo
              ? dayjs(dateTo)
                  .hour(23)
                  .minute(59)
                  .second(59)
                  .format("YYYY-MM-DD HH:mm:ss")
              : null,
            companyIds: companyIds,
            request,
          },
        })
        .then((res) => {
          let list = res.data.map((el) => {
            return {
              ...el,
              totalTtc: Math.round(el.totalTtc * 100) / 100,
              documentDate: el.documentDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(el.documentDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              countedDate: el.countedDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(el.countedDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
            };
          });
          if (updateState) {
            commit(DocumentMutations.SET_INVOICES_LIST_BY_COMPANY, list);
            commit(
              DocumentMutations.SET_IS_LOADING_DOCUMENTS_LIST_BY_COMPANY,
              false
            );
          }
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DocumentMutations.SET_IS_LOADING_DOCUMENTS_LIST_BY_COMPANY,
            false
          );
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          reject(err);
        });
    });
  },

  async getInvoicesByAffairId(
    { commit },
    {
      affairId = null,
      dateFrom = null,
      dateTo = null,
      updateState = true,
    }: {
      affairId?: number;
      dateFrom?: Date;
      dateTo?: Date;
      updateState?: boolean;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(DocumentMutations.SET_IS_LOADING_DOCUMENTS_LIST_BY_AFFAIR, true);
      }
      await axios
        .get(process.env.VUE_APP_API_ORISIS + "Sale/Invoice/GetInvoices", {
          params: {
            affairId: affairId,
            dateFrom: dateFrom ? dayjs(dateFrom).format("YYYY-MM-DD") : null,
            dateTo: dateTo
              ? dayjs(dateTo)
                  .hour(23)
                  .minute(59)
                  .second(59)
                  .format("YYYY-MM-DD HH:mm:ss")
              : null,
          },
        })
        .then((res) => {
          let list = res.data.map((el) => {
            return {
              ...el,
              totalTtc: Math.round(el.totalTtc * 100) / 100,
              documentDate: el.documentDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(el.documentDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              countedDate: el.countedDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(el.countedDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
            };
          });
          if (updateState) {
            commit(
              DocumentMutations.SET_IS_LOADING_DOCUMENTS_LIST_BY_AFFAIR,
              false
            );
            commit(DocumentMutations.SET_INVOICES_LIST_BY_AFFAIR, list);
          }
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DocumentMutations.SET_IS_LOADING_DOCUMENTS_LIST_BY_AFFAIR,
            false
          );
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          reject(err);
        });
    });
  },

  async getInvoiceById(
    { commit, dispatch, rootGetters },
    {
      invoiceId = null,
      updateState = true,
    }: { invoiceId?: number; updateState?: boolean }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(DocumentMutations.SET_DOCUMENT_TAB_ACTIVE, "document-edit");
      commit(InitialDocumentMutations.SET_INITIAL_DOCUMENT, null);
      commit(DocumentMutations.SET_INITIAL_INVOICE, null);
      if (updateState) {
        commit(DocumentMutations.SET_IS_LOADING_DOCUMENT, true);
        commit(
          DocumentMutations.SET_LOADING_MSG,
          "Chargement des détails de la facture..."
        );
      }
      await axios
        .get(process.env.VUE_APP_API_ORISIS + "Sale/Invoice/GetInvoiceById", {
          params: {
            invoiceId: invoiceId,
          },
        })
        .then(async (res) => {
          if (!res.data.usedUrlGed)
            await dispatch("getInvoiceOptionByInvoiceId", {
              invoiceId: res.data.id,
            });
          if (res.data.nature == 2) {
            res.data.showReferenceColumn = false;
            res.data.hideQuantityColumn = true;
          }

          if (
            res.data.status >= 2 &&
            res.data.path &&
            !res.data.path.includes("null.pdf")
          ) {
            commit(DocumentMutations.SET_DOCUMENT_PDF, res.data.path);
          } else {
            commit(DocumentMutations.SET_DOCUMENT_PDF, "");
          }

          if (res.data.credit && res.data.credit.id) {
            await dispatch("getCreditMinifiedById", {
              creditId: res.data.credit.id,
            }).then((credit) => {
              res.data = { ...res.data, credit: credit };
            });
          }
          await dispatch("getCreditByInvoiceId", {
            invoiceId: res.data.id,
          }).catch(() => {
            commit(DocumentMutations.SET_CREDIT_BY_INVOICE, null);
          });

          await dispatch("getInitialInvoiceById", { invoiceId: res.data.id });
          await dispatch("getPaymentsByInvoiceId", {
            invoiceId: res.data.id,
            startTo: null,
            endTo: null,
            updateState: false,
          }).then((result) => {
            res.data.payments = result.map((payment) => {
              return {
                ...payment.invoices.filter((el) => el.id == res.data.id)[0],
                date: payment.date,
                paymentMethod: payment.paymentMethod.label,
              };
            });
            commit(DocumentMutations.SET_INITIAL_INVOICE, {
              ...rootGetters.initialInvoice,
              payments: res.data.payments,
            });
          });
          let invoice = {
            ...res.data,
            paymentConditionId: res.data.paymentCondition.id,
            documentTvas: [],
          };
          commit(DocumentMutations.SET_DOCUMENT, invoice);
          commit(
            DeductionMutations.SET_DEDUCTION,
            res.data.deduction == null
              ? DeductionDefaultProperties({ invoiceId: res.data.id })
              : res.data.deduction
          );
          commit(
            DocumentMutations.SET_DOCUMENT_PAYMENT_METHODS,
            res.data.paymentMethods.length == 0
              ? []
              : res.data.paymentMethods.map((el) => el.id)
          );
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);

          if (
            !statusCheck.documentEditable.includes(res.data.status) ||
            res.data.usedUrlGed
          ) {
            dispatch("changeDocumentTabActive", "document-pdf");
          }

          if (res.data.orderFormCustomerId) {
            await dispatch("getInitialOrderFormCustomerById", {
              orderFormCustomerId: res.data.orderFormCustomerId,
            }).then(() => {
              dispatch("getLastInvoice", { document: "orderFormCustomer" });
            });
          } else if (res.data.quoteId) {
            await dispatch("getInitialQuoteById", {
              quoteId: res.data.quoteId,
            }).then(() => {
              dispatch("getLastInvoice", { document: "quote" });
            });
          }
          await dispatch("getDocumentDetailsByInvoiceId", {
            invoiceId: invoiceId,
          });
          commit(DocumentMutations.SET_IS_LOADING_DOCUMENT, false);

          resolve(invoice);
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          commit(DocumentMutations.SET_IS_LOADING_DOCUMENT, false);
          reject(err);
        });
    });
  },

  async getInvoiceEmpty(
    { commit, rootGetters, dispatch },
    {
      documentType = [],
      updateState = true,
      affairId = null,
      companyId = null,
    }: {
      documentType?: number[];
      updateState?: boolean;
      affairId?: number;
      companyId?: number;
    }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (updateState) {
        commit(DocumentMutations.SET_IS_CREATING_DOCUMENT, true);
      }
      await axios
        .get(process.env.VUE_APP_API_ORISIS + "Sale/Invoice/GetInvoiceEmpty", {
          params: {
            affairId: affairId,
            userId: rootGetters.connectedUser.id,
            type: documentType,
            companyId: companyId,
          },
        })
        .then((res) => {
          if (res.data.status == 8) {
            let invoiceFilters = rootGetters.userParametersList.find(
              (el) => el.key == "invoiceFilters"
            );
            if (invoiceFilters) {
              dispatch("getInvoices", {
                dateFrom: JSON.parse(invoiceFilters.value).dateRange.startDate,
                dateTo: JSON.parse(invoiceFilters.value).dateRange.endDate,
              });
            } else {
              dispatch("setFilter", {
                name: "invoiceFilters",
                filter: {
                  dateRangeType: 7,
                  dateRange: { dateFrom: dateFrom, dateTo: dateTo },
                },
              });
              dispatch("getInvoices", {
                dateFrom: dateFrom,
                dateTo: dateTo,
              });
            }
          }
          commit(DocumentMutations.SET_DOCUMENT_PDF, "");

          commit(
            DocumentMutations.SET_DOCUMENT_PAYMENT_METHODS,
            res.data.paymentMethods.length == 0
              ? []
              : res.data.paymentMethods.map((el) => el.id)
          );
          commit(DocumentMutations.SET_IS_CREATING_DOCUMENT, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          commit(DocumentMutations.SET_IS_CREATING_DOCUMENT, false);
          reject(err);
        });
    });
  },

  async getInitialInvoiceById(
    { commit },
    { invoiceId }: { invoiceId: number }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS + "Sale/Invoice/GetInitialInvoiceById",
          {
            params: {
              invoiceId: invoiceId,
            },
          }
        )
        .then((res) => {
          commit(DocumentMutations.SET_INITIAL_INVOICE, res.data);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve();
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          reject(err);
        });
    });
  },

  async getInvoiceRemainingToPay({ commit }): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(DocumentMutations.SET_IS_LOADING_INVOICES_REMAINING_TO_PAY, true);
      await axios
        .get(process.env.VUE_APP_API_ORISIS + "Sale/Invoice/GetRemainingToPay")
        .then((res) => {
          let list = res.data;
          commit(DocumentMutations.SET_INVOICES_REMAINING_TO_PAY, list);
          commit(
            DocumentMutations.SET_IS_LOADING_INVOICES_REMAINING_TO_PAY,
            false
          );
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_INVOICES_REMAINING_TO_PAY, {});
          commit(
            DocumentMutations.SET_IS_LOADING_INVOICES_REMAINING_TO_PAY,
            false
          );
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          reject(err);
        });
    });
  },

  async getInvoicesLastYearStats(
    { commit },
    { dateFrom = null, dateTo = null }: { dateFrom?: Date; dateTo?: Date }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        DocumentMutations.SET_IS_LOADING_INVOICES_LIST_LAST_YEAR_STATS,
        true
      );
      commit(DocumentMutations.SET_INVOICES_LIST_LAST_YEAR_STATS, {
        count: 0,
        amount: 0,
        datas: [],
      });
      await axios
        .get(process.env.VUE_APP_API_ORISIS + "Sale/Invoice/GetInvoiceStats", {
          params: {
            startDate: dateFrom,
            endDate: dateTo,
          },
        })
        .then((res) => {
          let result = {
            ...res.data,
            datas: res.data.datas.map((el) => {
              return {
                ...el,
                nature: "invoices",
              };
            }),
          };
          commit(DocumentMutations.SET_INVOICES_LIST_LAST_YEAR_STATS, result);
          commit(
            DocumentMutations.SET_IS_LOADING_INVOICES_LIST_LAST_YEAR_STATS,
            false
          );
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve(result);
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_INVOICES_LIST_LAST_YEAR_STATS, {
            count: 0,
            amount: 0,
            datas: [],
          });
          commit(
            DocumentMutations.SET_IS_LOADING_INVOICES_LIST_LAST_YEAR_STATS,
            false
          );
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          reject(err);
        });
    });
  },

  async getInvoicesStats(
    { commit, rootGetters, dispatch },
    {
      dateFrom = null,
      dateTo = null,
      updateState = true,
    }: { dateFrom?: Date; dateTo?: Date; updateState?: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(DocumentMutations.SET_IS_LOADING_DOCUMENTS_LIST, true);
        commit(DocumentMutations.SET_IS_LOADING_INVOICES_LIST_STATS, true);
        commit(DocumentMutations.SET_INVOICES_LIST_STATS, {
          count: 0,
          amount: 0,
          datas: [],
        });
      }

      dispatch("getInvoicesLastYearStats", {
        dateFrom: dayjs(dateFrom).subtract(1, "year").format("YYYY-MM-DD"),
        dateTo: dayjs(dateTo).subtract(1, "year").format("YYYY-MM-DD"),
      });

      await axios
        .get(process.env.VUE_APP_API_ORISIS + "Sale/Invoice/GetInvoiceStats", {
          params: {
            startDate: dateFrom,
            endDate: dateTo,
          },
        })
        .then((res) => {
          let result = {
            ...res.data,
            datas: res.data.datas.map((el) => {
              return {
                ...el,
                nature: "invoices",
              };
            }),
          };
          if (updateState) {
            commit(DocumentMutations.SET_INVOICES_LIST_STATS, result);
            commit(DocumentMutations.SET_IS_LOADING_INVOICES_LIST_STATS, false);
          }
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve(result);
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_INVOICES_LIST_STATS, {
            count: 0,
            amount: 0,
            datas: [],
          });
          commit(DocumentMutations.SET_IS_LOADING_INVOICES_LIST_STATS, false);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          reject(err);
        });
    });
  },

  async sendInvoice({ commit }, { data }: { data: any }): Promise<void> {
    commit(DocumentMutations.SET_IS_SENDING_EMAIL, true);
    await axios
      .post(`${process.env.VUE_APP_API_ORISIS}Sale/Invoice/SendInvoice`, data)
      .then((res) => {
        commit(DocumentMutations.SET_IS_SENDING_EMAIL, false);
      })
      .catch((err) => {
        console.error(err);
        commit(DocumentMutations.SET_IS_SENDING_EMAIL, false);
      });
  },

  async createInvoice(
    { commit, dispatch, rootGetters },
    { invoice }: { invoice: IDocument }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(DocumentMutations.SET_IS_CREATING_DOCUMENT, true);
      await axios
        .post(process.env.VUE_APP_API_ORISIS + "Sale/Invoice/CreateInvoice", {
          ...invoice,
          currencyId: 1,
        })
        .then(async (res) => {
          if (invoice.paymentMethods.length > 0) {
            await dispatch("updatePaymentMethodInvoice", {
              paymentMethodInvoice: invoice.paymentMethods.map((el) => el.id),
              invoiceId: res.data.data.id,
            });
          }
          if (res.data.data.status != 8) {
            let invoiceFilters = rootGetters.userParametersList.find(
              (el) => el.key == "invoiceFilters"
            );
            if (invoiceFilters) {
              dispatch("getInvoices", {
                dateFrom: JSON.parse(invoiceFilters.value).dateRange.dateFrom,
                dateTo: JSON.parse(invoiceFilters.value).dateRange.dateTo,
              });
            } else {
              dispatch("setFilter", {
                name: "invoiceFilters",
                filter: {
                  dateRangeType: 7,
                  dateRange: { dateFrom: dateFrom, dateTo: dateTo },
                },
              });
              dispatch("getInvoices", {
                dateFrom: dateFrom,
                dateTo: dateTo,
              });
            }
          }

          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          commit(DocumentMutations.SET_IS_CREATING_DOCUMENT, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          commit(DocumentMutations.SET_IS_CREATING_DOCUMENT, false);
          reject(err);
        });
    });
  },

  async duplicateInvoice(
    { commit, rootGetters },
    { invoiceId }: { invoiceId: number }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(DocumentMutations.SET_IS_CREATING_DOCUMENT, true);
      await axios
        .post(
          process.env.VUE_APP_API_ORISIS + "Sale/Invoice/DuplicateInvoice",
          {},
          {
            params: {
              invoiceId: invoiceId,
              userId: rootGetters.connectedUser.id,
            },
          }
        )
        .then((res) => {
          commit(DocumentMutations.SET_DOCUMENT, {
            ...res.data,
            documentTvas: [],
            isTemplate: false,
          });
          commit(DocumentMutations.SET_IS_CREATING_DOCUMENT, false);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve({ ...res.data, isTemplate: false });
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          commit(DocumentMutations.SET_IS_CREATING_DOCUMENT, false);
          reject(err);
        });
    });
  },

  async updateInvoice(
    { commit, dispatch, rootGetters },
    {
      invoice,
      updateState = true,
    }: { invoice: InvoiceRequestDto; updateState?: boolean }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (updateState) {
        commit(DocumentMutations.SET_IS_UPDATING_DOCUMENT, true);
        commit(
          DocumentMutations.SET_LOADING_MSG,
          "Mise à jour de la facture en cours..."
        );
      }
      await axios
        .put(process.env.VUE_APP_API_ORISIS + "Sale/Invoice/UpdateInvoice", {
          ...invoice,
          currencyId: 1,
        })
        .then(async (res) => {
          let dataDocument = {
            ...res.data.data,
            paymentConditionId: res.data.data.paymentCondition
              ? res.data.data.paymentCondition.id
              : null,
            documentTvas: [],
          };
          commit(DocumentMutations.SET_DOCUMENT, dataDocument);

          if (rootGetters.deduction.id) {
            await dispatch("updateDeduction", {
              deduction: rootGetters.deduction,
            });
          } else if (
            rootGetters.deduction.holdbackNumber ||
            rootGetters.deduction.prorataAccountPrice ||
            (rootGetters.deduction.punctualDeductions &&
              rootGetters.deduction.punctualDeductions.length > 0)
          ) {
            await dispatch("createDeduction", {
              deduction: rootGetters.deduction,
            });
          }
          if (updateState) {
            let invoiceFilters = rootGetters.userParametersList.find(
              (el) => el.key == "invoiceFilters"
            );
            if (invoiceFilters) {
              dispatch("getInvoices", {
                dateFrom: JSON.parse(invoiceFilters.value).dateRange.dateFrom,
                dateTo: JSON.parse(invoiceFilters.value).dateRange.dateTo,
              });
            } else {
              dispatch("setFilter", {
                name: "invoiceFilters",
                filter: {
                  dateRangeType: 7,
                  dateRange: { dateFrom: dateFrom, dateTo: dateTo },
                },
              });
              dispatch("getInvoices", {
                dateFrom: dateFrom,
                dateTo: dateTo,
              });
            }
            commit(DocumentMutations.SET_IS_UPDATING_DOCUMENT, false);
          }

          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve(dataDocument);
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          commit(DocumentMutations.SET_IS_UPDATING_DOCUMENT, false);
          reject(err);
        });
    });
  },

  async uploadFileInvoice(
    { commit, rootGetters, dispatch },
    { formData, invoiceId }: { formData: FormData; invoiceId: number }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(FileMutations.SET_IS_CREATING_FILE, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Sale/Invoice/UploadFile`,
          formData,
          {
            params: {
              invoiceId: invoiceId,
            },
          }
        )
        .then(async (res) => {
          if (!rootGetters.document.folderId) {
            await dispatch("getInvoiceById", {
              invoiceId: invoiceId,
              updateState: false,
            });
          }
          commit(FolderMutations.SET_FOLDER, rootGetters.folder);
          commit(FileMutations.SET_REQUEST_STATE_FILE, res);
          commit(FileMutations.SET_IS_CREATING_FILE, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(FileMutations.SET_REQUEST_STATE_FILE, err);
          commit(FileMutations.SET_IS_CREATING_FILE, false);
          reject(err);
        });
    });
  },

  async updateCreditInvoice(
    { commit, dispatch, rootGetters },
    {
      invoiceId,
      credits,
      cancelInvoice,
    }: { invoiceId: number; credits: CreditRequestDto; cancelInvoice: boolean }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Sale/Invoice/UpdateCreditInvoice",
          credits,
          {
            params: {
              invoiceId: invoiceId,
              cancelInvoice: cancelInvoice,
            },
          }
        )
        .then((res) => {
          let creditFilters = rootGetters.userParametersList.find(
            (el) => el.key == "creditFilters"
          );
          let invoiceFilters = rootGetters.userParametersList.find(
            (el) => el.key == "invoiceFilters"
          );
          if (creditFilters) {
            dispatch("getCredits", {
              dateFrom: JSON.parse(creditFilters.value).dateRange.dateFrom,
              dateTo: JSON.parse(creditFilters.value).dateRange.dateTo,
            });
          } else {
            dispatch("setFilter", {
              name: "invoiceFilters",
              filter: {
                dateRangeType: 7,
                dateRange: { dateFrom: dateFrom, dateTo: dateTo },
              },
            });
            dispatch("getCredits", {
              dateFrom: dateFrom,
              dateTo: dateTo,
            });
          }

          if (invoiceFilters) {
            dispatch("getInvoices", {
              dateFrom: JSON.parse(invoiceFilters.value).dateRange.dateFrom,
              dateTo: JSON.parse(invoiceFilters.value).dateRange.dateTo,
            });
          } else {
            dispatch("setFilter", {
              name: "invoiceFilters",
              filter: {
                dateRangeType: 7,
                dateRange: { dateFrom: dateFrom, dateTo: dateTo },
              },
            });
            dispatch("getInvoices", {
              dateFrom: dateFrom,
              dateTo: dateTo,
            });
          }

          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve();
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          reject();
        });
    });
  },

  async updatePaymentMethodInvoice(
    { commit },
    {
      paymentMethodInvoice,
      invoiceId,
    }: { paymentMethodInvoice: PaymentMethodData; invoiceId: number }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Sale/Invoice/UpdatePaymentMethodInvoice",
          paymentMethodInvoice,
          {
            params: {
              invoiceId: invoiceId,
            },
          }
        )
        .then((res) => {
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          reject(err);
        });
    });
  },

  async updateInvoiceTagInvoice(
    { commit },
    {
      invoiceIds,
      invoiceTagIds,
    }: { invoiceIds: number[]; invoiceTagIds: number[] }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Sale/Invoice/UpdateInvoiceTagInvoice`,
          { invoiceIds: invoiceIds, invoiceTagIds: invoiceTagIds }
        )
        .then((res) => {
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          reject(err);
        });
    });
  },

  async deleteInvoices(
    { commit, dispatch, rootGetters },
    { invoiceIds = [], route }: { invoiceIds: number[]; route: string }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(DocumentMutations.SET_IS_DELETING_DOCUMENT, true);
      await axios
        .delete(process.env.VUE_APP_API_ORISIS + "Sale/Invoice/Delete", {
          data: invoiceIds,
        })
        .then((res) => {
          if (route === "invoices" || route === "new-invoice") {
            let invoiceFilters = rootGetters.userParametersList.find(
              (el) => el.key == "invoiceFilters"
            );
            if (invoiceFilters) {
              dispatch("getInvoices", {
                dateFrom: JSON.parse(invoiceFilters.value).dateRange.dateFrom,
                dateTo: JSON.parse(invoiceFilters.value).dateRange.dateTo,
              });
            } else {
              dispatch("setFilter", {
                name: "invoiceFilters",
                filter: {
                  dateRangeType: 7,
                  dateRange: { dateFrom: dateFrom, dateTo: dateTo },
                },
              });
              dispatch("getInvoices", {
                dateFrom: dateFrom,
                dateTo: dateTo,
              });
            }
          } else if (route === "lease" || route === "affair") {
            dispatch("getInvoicesByAffairId", {
              affairId: rootGetters.lease.affairId,
              updateState: true,
            });
          }
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          commit(DocumentMutations.SET_IS_DELETING_DOCUMENT, false);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
          console.error(err);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          commit(DocumentMutations.SET_IS_DELETING_DOCUMENT, false);
        });
    });
  },

  async getLastInvoice(
    { commit, rootGetters, dispatch },
    { document }: { document: string }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (document == "quote") {
        if (
          rootGetters.initialDocument &&
          rootGetters.initialDocument.invoices
        ) {
          let previousInvoicesNotCanceled =
            rootGetters.initialDocument.invoices.filter((el) => el.status != 5);
          if (
            previousInvoicesNotCanceled &&
            previousInvoicesNotCanceled.length > 0
          ) {
            dispatch("getDocumentDetailNotStoredByInvoiceId", {
              invoiceId:
                previousInvoicesNotCanceled[
                  previousInvoicesNotCanceled.length - 1
                ].id,
            })
              .then((res) => {
                let isInvoiceValid = res
                  .filter(
                    (el) =>
                      el.type != 0 &&
                      el.type != 1 &&
                      el.type != 2 &&
                      el.type != 11 &&
                      el.type != 12 &&
                      el.type != 13 &&
                      el.type != 18 &&
                      el.type != 17 &&
                      el.type != 16 &&
                      el.type != 15 &&
                      el.type != 14 &&
                      !el.isOption
                  )
                  .every(
                    (el) =>
                      el.situationProgress == 100 ||
                      el.situationProgressQuantity == el.quantity
                  );
                commit("SET_LAST_INVOICE", {
                  ...previousInvoicesNotCanceled[
                    previousInvoicesNotCanceled.length - 1
                  ],
                  allWorkBilled: isInvoiceValid,
                });
                resolve();
              })
              .catch((err) => {
                commit("SET_LAST_INVOICE", {
                  ...previousInvoicesNotCanceled[
                    previousInvoicesNotCanceled.length - 1
                  ],
                  allWorkBilled: false,
                });
                console.error(err);
                reject(err);
              });
          } else {
            commit("SET_LAST_INVOICE", null);
            resolve();
          }
        }
      } else if (document == "orderFormCustomer") {
        if (
          rootGetters.initialDocument &&
          rootGetters.initialDocument.invoices.filter((document) =>
            [1, 2, 5, 6, 7, 9].includes(document.status)
          )
        ) {
          if (
            rootGetters.initialDocument.invoices
              .filter((document) => [1, 2, 6, 7, 9].includes(document.status))
              .find((el) => el.nature == 1)
          ) {
            // Il existe une facture finale donc il n'y a jamais eu de situation
            // On boucle les lignes de détails et on les passe à 100 %
            let orderTrackingCustomerDetails =
              rootGetters.getOrderTrackingCustomerDetails;
            for (
              let index = 0;
              index < orderTrackingCustomerDetails.length;
              index++
            ) {
              if (
                orderTrackingCustomerDetails[index].type != 0 &&
                orderTrackingCustomerDetails[index].type != 1 &&
                orderTrackingCustomerDetails[index].type != 2 &&
                orderTrackingCustomerDetails[index].type != 11 &&
                orderTrackingCustomerDetails[index].type != 12 &&
                orderTrackingCustomerDetails[index].type != 13 &&
                orderTrackingCustomerDetails[index].type != 18 &&
                orderTrackingCustomerDetails[index].type != 17 &&
                orderTrackingCustomerDetails[index].type != 16 &&
                orderTrackingCustomerDetails[index].type != 15 &&
                orderTrackingCustomerDetails[index].type != 14 &&
                !orderTrackingCustomerDetails[index].isOption
              ) {
                orderTrackingCustomerDetails[index][
                  "previousSituationProgress"
                ] = orderTrackingCustomerDetails[index][
                  "situationProgress"
                ] = 100;
              }
            }
            commit(
              OrderFormCustomerDetailMutations.SET_ORDER_TRACKING_CUSTOMER_DETAILS,
              orderTrackingCustomerDetails
            );
            resolve();
          } else if (
            rootGetters.initialDocument.invoices.find((el) =>
              [1, 2, 6, 7, 9].includes(el.status)
            )
          ) {
            // Il existe des factures non annulés
            let previousInvoicesNotCanceled =
              rootGetters.initialDocument.invoices.filter((el) =>
                [1, 2, 6, 7, 9].includes(el.status)
              );

            dispatch("getDocumentDetailNotStoredByInvoiceId", {
              invoiceId:
                previousInvoicesNotCanceled[
                  previousInvoicesNotCanceled.length - 1
                ].id,
            })
              .then((res) => {
                if (
                  previousInvoicesNotCanceled[
                    previousInvoicesNotCanceled.length - 1
                  ].nature == 4
                ) {
                  // Si la dernière facture connue est une facture de situation détaillée alors on prend ces lignes de détail pour le suivi de commande au cas où il y ait eut des avenances (lignes supplémentaires)
                  commit(
                    OrderFormCustomerDetailMutations.SET_ORDER_TRACKING_CUSTOMER_DETAILS,
                    res
                  );
                } else if (
                  [2, 3].includes(
                    previousInvoicesNotCanceled[
                      previousInvoicesNotCanceled.length - 1
                    ].nature
                  )
                ) {
                  // Si la dernière facture connue est une facture d'accompte alors on prend ces lignes de détail pour le suivi de commande au cas où il y ait eut des avenances (lignes supplémentaires)
                  let totalAcompte = 0;
                  for (
                    let index = 0;
                    index <
                    previousInvoicesNotCanceled.filter((el) =>
                      [2, 3].includes(el.nature)
                    ).length;
                    index++
                  ) {
                    const element = previousInvoicesNotCanceled.filter((el) =>
                      [2, 3].includes(el.nature)
                    )[index];
                    totalAcompte += element.totalHt;
                  }
                  let percentAct = (
                    (1 -
                      (rootGetters.initialDocument.totalHt - totalAcompte) /
                        rootGetters.initialDocument.totalHt) *
                    100
                  ).toFixed(0);
                  let orderTrackingCustomerDetails =
                    rootGetters.getOrderTrackingCustomerDetails;

                  for (
                    let index = 0;
                    index < orderTrackingCustomerDetails.length;
                    index++
                  ) {
                    if (
                      orderTrackingCustomerDetails[index].type != 0 &&
                      orderTrackingCustomerDetails[index].type != 1 &&
                      orderTrackingCustomerDetails[index].type != 2 &&
                      orderTrackingCustomerDetails[index].type != 11 &&
                      orderTrackingCustomerDetails[index].type != 12 &&
                      orderTrackingCustomerDetails[index].type != 13 &&
                      orderTrackingCustomerDetails[index].type != 18 &&
                      orderTrackingCustomerDetails[index].type != 17 &&
                      orderTrackingCustomerDetails[index].type != 16 &&
                      orderTrackingCustomerDetails[index].type != 15 &&
                      orderTrackingCustomerDetails[index].type != 14 &&
                      !orderTrackingCustomerDetails[index].isOption
                    ) {
                      orderTrackingCustomerDetails[index][
                        "previousSituationProgress"
                      ] = orderTrackingCustomerDetails[index][
                        "situationProgress"
                      ] = percentAct;
                    }
                  }
                  commit(
                    OrderFormCustomerDetailMutations.SET_ORDER_TRACKING_CUSTOMER_DETAILS,
                    orderTrackingCustomerDetails
                  );
                  // return (((1-(this.orderTrackingCustomer.totalHt - (this.orderTrackingCustomer.totalHt-remainingToBillHt))/this.orderTrackingCustomer.totalHt))*100).toFixed(0)
                }
                let isInvoiceValid = res
                  .filter(
                    (el) =>
                      el.type != 0 &&
                      el.type != 1 &&
                      el.type != 2 &&
                      el.type != 11 &&
                      el.type != 12 &&
                      el.type != 13 &&
                      el.type != 18 &&
                      el.type != 17 &&
                      el.type != 16 &&
                      el.type != 15 &&
                      el.type != 14 &&
                      !el.isOption
                  )
                  .every(
                    (el) =>
                      el.situationProgress == 100 ||
                      el.situationProgressQuantity == el.quantity
                  );
                commit("SET_LAST_INVOICE", {
                  ...previousInvoicesNotCanceled[
                    previousInvoicesNotCanceled.length - 1
                  ],
                  allWorkBilled: isInvoiceValid,
                });
                resolve();
              })
              .catch((err) => {
                commit("SET_LAST_INVOICE", {
                  ...previousInvoicesNotCanceled[
                    previousInvoicesNotCanceled.length - 1
                  ],
                  allWorkBilled: false,
                });
                reject(err);
              });
          } else {
            commit("SET_LAST_INVOICE", null);
            resolve();
          }
        } else {
          commit("SET_LAST_INVOICE", null);
          resolve();
        }
      }
    });
  },

  async cancelInvoice(
    { commit, dispatch, rootGetters },
    { invoiceId }: { invoiceId: number }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      await axios
        .get(process.env.VUE_APP_API_ORISIS + "Sale/Invoice/GetInvoiceById", {
          params: {
            invoiceId: invoiceId,
          },
        })
        .then(async (res) => {
          let newInvoice = {
            ...res.data,
            paymentConditionId: res.data.paymentCondition.id,
            status: 5,
            documentTvas: res.data.documentTvas.map((el) => {
              return { ...el, referencielTvaId: el.referencielTva.id };
            }),
          };
          await axios
            .put(
              process.env.VUE_APP_API_ORISIS + "Sale/Invoice/UpdateInvoice",
              newInvoice
            )
            .then((res) => {
              let invoiceFilters = rootGetters.userParametersList.find(
                (el) => el.key == "invoiceFilters"
              );
              if (invoiceFilters) {
                dispatch("getInvoices", {
                  dateFrom: JSON.parse(invoiceFilters.value).dateRange.dateFrom,
                  dateTo: JSON.parse(invoiceFilters.value).dateRange.dateTo,
                });
              } else {
                dispatch("setFilter", {
                  name: "invoiceFilters",
                  filter: {
                    dateRangeType: 7,
                    dateRange: { dateFrom: dateFrom, dateTo: dateTo },
                  },
                });
                dispatch("getInvoices", {
                  dateFrom: dateFrom,
                  dateTo: dateTo,
                });
              }

              commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
              resolve();
            })
            .catch((err) => {
              console.error(err);
              commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
              reject(err);
            });
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          reject(err);
        });
    });
  },

  // ***** Quote Actions ***** //

  async getQuotes(
    { commit, rootGetters },
    {
      companyIds = [],
      status = [],
      affairId = null,
      isTemplate = false,
      isSigned = false,
      dateFrom = null,
      dateTo = null,
      updateState = true,
    }: {
      companyIds?: number[];
      status?: number[];
      affairId?: number;
      isTemplate?: boolean;
      isSigned?: boolean;
      dateFrom?: Date;
      dateTo?: Date;
      updateState?: boolean;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(DocumentMutations.SET_IS_LOADING_DOCUMENTS_LIST, true);
        commit(DocumentMutations.SET_IS_LOADING_QUOTES_LIST, true);
      }
      let request = new URLSearchParams();
      request = generateParams(request, companyIds, "companyIds");
      request = generateParams(request, status, "status");
      request = affairId
        ? generateParams(request, [affairId], "affairId")
        : request;
      request = generateParams(request, [isTemplate], "isTemplate");
      request = generateParams(request, [isSigned], "isSigned");
      request = dateFrom
        ? generateParams(
            request,
            [dayjs(dateFrom).format("YYYY-MM-DD")],
            "dateFrom"
          )
        : request;
      request = dateTo
        ? generateParams(
            request,
            [dayjs(dateTo).format("YYYY-MM-DD")],
            "dateTo"
          )
        : request;
      await axios
        .get(process.env.VUE_APP_API_ORISIS + "Sale/Quote/GetQuotes", {
          params: request,
        })
        .then((res) => {
          let list = res.data.map((el) => {
            return {
              ...el,
              documentReference:
                el.documentReference == null
                  ? "Numéro en attente"
                  : el.documentReference,
              totalTtc: Math.round(el.totalTtc * 100) / 100,
              documentDate: el.documentDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(el.documentDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              signedDate: el.signedDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(el.signedDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              limitDate: el.limitDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(new Date(el.limitDate))
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              collaboratorId:
                el.companyId !== null && el.companyId !== "" && el.companyId > 0
                  ? rootGetters.companiesList.find(
                      (company) => company.id == el.companyId
                    )
                    ? rootGetters.companiesList.find(
                        (company) => company.id == el.companyId
                      ).collaboratorId
                    : ""
                  : "",
            };
          });

          if (updateState) {
            commit(DocumentMutations.SET_IS_LOADING_DOCUMENTS_LIST, false);
            commit(DocumentMutations.SET_IS_LOADING_QUOTES_LIST, false);
          }
          if (isTemplate) {
            commit(DocumentMutations.SET_QUOTES_LIST_TEMPLATES, list);
          } else {
            commit(DocumentMutations.SET_QUOTES_LIST, list);
          }
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_IS_LOADING_DOCUMENTS_LIST, false);
          commit(DocumentMutations.SET_IS_LOADING_QUOTES_LIST, false);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          reject(err);
        });
    });
  },

  async getQuotesSigned(
    { commit, rootGetters },
    {
      dateFrom = null,
      dateTo = null,
      isSigned = true,
    }: { dateFrom?: Date; dateTo?: Date; isSigned?: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      await axios
        .get(process.env.VUE_APP_API_ORISIS + "Sale/Quote/GetQuotes", {
          params: {
            dateFrom: dateFrom,
            dateTo: dateTo,
            isSigned: isSigned,
          },
        })
        .then((res) => {
          let list = res.data.map((el) => {
            return {
              ...el,
              documentReference:
                el.documentReference == null
                  ? "Numéro en attente"
                  : el.documentReference,
              totalTtc: Math.round(el.totalTtc * 100) / 100,
              collaboratorId:
                el.companyId !== null && el.companyId !== "" && el.companyId > 0
                  ? rootGetters.companiesList.find(
                      (company) => company.id == el.companyId
                    )
                    ? rootGetters.companiesList.find(
                        (company) => company.id == el.companyId
                      ).collaboratorId
                    : ""
                  : "",
            };
          });
          commit(DocumentMutations.SET_QUOTES_LIST_SIGNED, list);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          reject(err);
        });
    });
  },

  async getQuotesByCompanyIds(
    { commit },
    {
      companyIds = [],
      status = [],
      affairId = null,
      isSigned = false,
      dateFrom = null,
      dateTo = null,
      updateState = true,
    }: {
      companyIds?: number[];
      status?: number[];
      affairId?: number;
      isTemplate?: boolean;
      isSigned?: boolean;
      dateFrom?: Date;
      dateTo?: Date;
      updateState?: boolean;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          DocumentMutations.SET_IS_LOADING_DOCUMENTS_LIST_BY_COMPANY,
          true
        );
      }
      let request = new URLSearchParams();
      request = generateParams(request, companyIds, "companyIds");
      request = generateParams(request, status, "status");
      request = generateParams(request, affairId, "affairId");
      request = generateParams(request, isSigned, "isSigned");
      request = generateParams(request, dateFrom, "dateFrom");
      request = generateParams(request, dateTo, "dateTo");

      await axios
        .get(process.env.VUE_APP_API_ORISIS + "Sale/Quote/GetQuotes", {
          params: {
            dateFrom: dateFrom ? dayjs(dateFrom).format("YYYY-MM-DD") : null,
            dateTo: dateTo
              ? dayjs(dateTo)
                  .hour(23)
                  .minute(59)
                  .second(59)
                  .format("YYYY-MM-DD HH:mm:ss")
              : null,
            companyIds: companyIds,
            request,
          },
        })
        .then((res) => {
          let list = res.data.map((el) => {
            return {
              ...el,
              totalTtc: Math.round(el.totalTtc * 100) / 100,
              documentDate: el.documentDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(el.documentDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              signedDate: el.signedDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(el.signedDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              limitDate: el.limitDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(new Date(el.limitDate))
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
            };
          });
          commit(
            DocumentMutations.SET_IS_LOADING_DOCUMENTS_LIST_BY_COMPANY,
            false
          );
          commit(DocumentMutations.SET_QUOTES_LIST_BY_COMPANY, list);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DocumentMutations.SET_IS_LOADING_DOCUMENTS_LIST_BY_COMPANY,
            false
          );
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          reject(err);
        });
    });
  },

  async getQuotesByAffairId(
    { commit },
    {
      dateFrom = null,
      dateTo = null,
      affairId = null,
      updateState = true,
    }: {
      dateFrom?: Date;
      dateTo?: Date;
      affairId?: number;
      updateState?: boolean;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(DocumentMutations.SET_IS_LOADING_DOCUMENTS_LIST_BY_AFFAIR, true);
      }
      await axios
        .get(process.env.VUE_APP_API_ORISIS + "Sale/Quote/GetQuotes", {
          params: {
            dateFrom: dateFrom ? dayjs(dateFrom).format("YYYY-MM-DD") : null,
            dateTo: dateTo
              ? dayjs(dateTo)
                  .hour(23)
                  .minute(59)
                  .second(59)
                  .format("YYYY-MM-DD HH:mm:ss")
              : null,
            affairId: affairId,
          },
        })
        .then((res) => {
          let list = res.data.map((el) => {
            return {
              ...el,
              totalTtc: Math.round(el.totalTtc * 100) / 100,
              documentDate: el.documentDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(el.documentDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              signedDate: el.signedDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(el.signedDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              limitDate: el.limitDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(new Date(el.limitDate))
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
            };
          });
          if (updateState) {
            commit(
              DocumentMutations.SET_IS_LOADING_DOCUMENTS_LIST_BY_AFFAIR,
              false
            );
            commit(DocumentMutations.SET_QUOTES_LIST_BY_AFFAIR, list);
          }
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DocumentMutations.SET_IS_LOADING_DOCUMENTS_LIST_BY_AFFAIR,
            false
          );
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          reject(err);
        });
    });
  },

  async getQuoteById(
    { commit, dispatch },
    { quoteId, updateState = true }: { quoteId: number; updateState?: boolean }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (updateState) {
        commit(DocumentMutations.SET_IS_LOADING_DOCUMENT, true);
        commit(
          DocumentMutations.SET_LOADING_MSG,
          "Chargement des détails du devis..."
        );
      }
      commit(InitialDocumentMutations.SET_INITIAL_DOCUMENT, null);
      commit(DocumentMutations.SET_INITIAL_INVOICE, null);
      commit(DocumentMutations.SET_LAST_INVOICE, null);
      commit(DocumentMutations.SET_DOCUMENT_TAB_ACTIVE, "document-edit");
      await axios
        .get(process.env.VUE_APP_API_ORISIS + "Sale/Quote/GetQuoteById", {
          params: {
            quoteId: quoteId,
          },
        })
        .then(async (res) => {
          if (!res.data.usedUrlGed)
            await dispatch("getQuoteOptionByQuoteId", { quoteId: res.data.id });
          if (res.data.quoteBTP && res.data.quoteBTP.wasteCollectionPoints) {
            res.data.quoteBTP.quoteBTPWasteCollectionPointIds =
              res.data.quoteBTP.wasteCollectionPoints.map((elem) => elem.id);
            delete res.data.quoteBTP.wasteCollectionPoints;
            res.data.quoteBTP.quoteBTPWasteTypeIds =
              res.data.quoteBTP.wasteTypes.map((elem) => elem.id);
            delete res.data.quoteBTP.wasteTypes;
          }
          if (
            !statusCheck.documentEditable.includes(res.data.status) &&
            res.data.path &&
            !res.data.path.includes("null.pdf")
          ) {
            commit(DocumentMutations.SET_DOCUMENT_PDF, res.data.path);
          } else {
            commit(DocumentMutations.SET_DOCUMENT_PDF, "");
          }
          let quote = {
            ...res.data,
            documentTvas: [],
          };
          commit(DocumentMutations.SET_DOCUMENT, quote);
          commit(
            DeductionMutations.SET_DEDUCTION,
            res.data.deduction == null
              ? DeductionDefaultProperties({ quoteId: res.data.id })
              : res.data.deduction
          );
          commit(
            QuoteDownPaymentsRequestMutations.SET_QUOTE_DOWN_PAYMENTS_REQUESTS_LIST,
            res.data.quoteDownPaymentsRequests
          );
          commit(
            DocumentMutations.SET_DOCUMENT_PAYMENT_METHODS,
            res.data.paymentMethods
              ? res.data.paymentMethods.map((el) => el.id)
              : []
          );
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          // }
          if (
            !statusCheck.documentEditable.includes(res.data.status) ||
            res.data.usedUrlGed
          ) {
            dispatch("changeDocumentTabActive", "document-pdf");
          }

          await dispatch("getDocumentDetailsByQuoteId", { quoteId: quoteId });

          if (!statusCheck.documentDraft.includes(res.data.status)) {
            await dispatch("getInitialQuoteById", { quoteId: quoteId }).then(
              () => {
                dispatch("getLastInvoice", { document: "quote" });
              }
            );
          }

          if (updateState) {
            commit(DocumentMutations.SET_IS_LOADING_DOCUMENT, false);
          }
          resolve(quote);
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_IS_LOADING_DOCUMENT, false);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          reject(err);
        });
    });
  },

  async getInitialQuoteById(
    { commit },
    { quoteId }: { quoteId: number }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS + "Sale/Quote/GetInitialQuoteById",
          {
            params: {
              quoteId: quoteId,
            },
          }
        )
        .then((res) => {
          res.data = { ...res.data, initialDocumentNature: "quote" };
          commit(InitialDocumentMutations.SET_INITIAL_DOCUMENT, res.data);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve();
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          reject(err);
        });
    });
  },

  async getQuoteEmpty(
    { commit, rootGetters, dispatch },
    {
      documentType,
      updateState = true,
      affairId = null,
      companyId = null,
    }: {
      documentType: number;
      updateState?: boolean;
      affairId?: number;
      companyId?: number;
    }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (updateState) {
        commit(DocumentMutations.SET_IS_CREATING_DOCUMENT, true);
      }
      await axios
        .get(process.env.VUE_APP_API_ORISIS + "Sale/Quote/GetQuoteEmpty", {
          params: {
            type: documentType,
            userId: rootGetters.connectedUser.id,
            affairId: affairId,
            companyId: companyId,
          },
        })
        .then((res) => {
          if (res.data.status == 8) {
            let quoteFilters = rootGetters.userParametersList.find(
              (el) => el.key == "quoteFilters"
            );
            if (quoteFilters) {
              dispatch("getQuotes", {
                dateFrom: JSON.parse(quoteFilters.value).dateRange.dateFrom,
                dateTo: JSON.parse(quoteFilters.value).dateRange.dateTo,
              });
            } else {
              dispatch("setFilter", {
                name: "quoteFilters",
                filter: {
                  dateRangeType: 7,
                  dateRange: { dateFrom: dateFrom, dateTo: dateTo },
                },
              });
              dispatch("getQuotes", {
                dateFrom: dateFrom,
                dateTo: dateTo,
              });
            }
          }
          commit(DocumentMutations.SET_DOCUMENT_PDF, "");
          commit(
            DocumentMutations.SET_DOCUMENT_PAYMENT_METHODS,
            res.data.paymentMethods.length == 0
              ? []
              : res.data.paymentMethods.map((el) => el.id)
          );
          commit(DocumentMutations.SET_IS_CREATING_DOCUMENT, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          commit(DocumentMutations.SET_IS_CREATING_DOCUMENT, false);
          reject(err);
        });
    });
  },

  async getQuotesLastYearStats(
    { commit },
    {
      startDate = null,
      endDate = null,
      isSigned = false,
    }: { startDate?: Date; endDate?: Date; isSigned?: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      await axios
        .get(process.env.VUE_APP_API_ORISIS + "Sale/Quote/GetQuoteStats", {
          params: {
            startDate: startDate,
            endDate: endDate,
            isSigned: isSigned,
          },
        })
        .then((res) => {
          let list = res.data;
          commit(DocumentMutations.SET_QUOTES_LIST_LAST_YEAR_STATS, list);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_QUOTES_LIST_LAST_YEAR_STATS, {
            count: 0,
            amount: 0,
            datas: [],
          });
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          reject(err);
        });
    });
  },

  async getQuotesStats(
    { commit },
    {
      startDate = null,
      endDate = null,
      isSigned = false,
    }: { startDate?: Date; endDate?: Date; isSigned?: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(DocumentMutations.SET_IS_LOADING_QUOTES_LIST_STATS, true);
      await axios
        .get(process.env.VUE_APP_API_ORISIS + "Sale/Quote/GetQuoteStats", {
          params: {
            startDate: startDate,
            endDate: endDate,
            isSigned: isSigned,
          },
        })
        .then((res) => {
          let list = res.data;
          if (isSigned) {
            commit(DocumentMutations.SET_QUOTES_LIST_SIGNED_STATS, list);
          } else {
            commit(DocumentMutations.SET_QUOTES_LIST_STATS, list);
          }
          commit(DocumentMutations.SET_IS_LOADING_QUOTES_LIST_STATS, false);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_QUOTES_LIST_STATS, {
            count: 0,
            amount: 0,
            datas: [],
          });
          commit(DocumentMutations.SET_IS_LOADING_QUOTES_LIST_STATS, false);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          reject(err);
        });
    });
  },

  async createQuote(
    { commit, dispatch, rootGetters },
    { quote }: { quote: QuoteRequestDto }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(DocumentMutations.SET_IS_CREATING_DOCUMENT, true);
      await axios
        .post(process.env.VUE_APP_API_ORISIS + "Sale/Quote/CreateQuote", quote)
        .then((res) => {
          if (res.data.data.status != 8) {
            let quoteFilters = rootGetters.userParametersList.find(
              (el) => el.key == "quoteFilters"
            );
            if (quoteFilters) {
              dispatch("getQuotes", {
                dateFrom: JSON.parse(quoteFilters.value).dateRange.dateFrom,
                dateTo: JSON.parse(quoteFilters.value).dateRange.dateTo,
              });
            } else {
              dispatch("setFilter", {
                name: "quoteFilters",
                filter: {
                  dateRangeType: 7,
                  dateRange: { dateFrom: dateFrom, dateTo: dateTo },
                },
              });
              dispatch("getQuotes", {
                dateFrom: dateFrom,
                dateTo: dateTo,
              });
            }
          }
          commit(DocumentMutations.SET_IS_CREATING_DOCUMENT, false);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          commit(DocumentMutations.SET_IS_CREATING_DOCUMENT, false);
          reject(err);
        });
    });
  },

  async duplicateQuote(
    { commit, rootGetters },
    { quoteId }: { quoteId: number }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(DocumentMutations.SET_IS_CREATING_DOCUMENT, true);
      await axios
        .post(
          process.env.VUE_APP_API_ORISIS + "Sale/Quote/DuplicateQuote",
          {},
          {
            params: {
              quoteId: quoteId,
              userId: rootGetters.connectedUser.id,
            },
          }
        )
        .then((res) => {
          commit(DocumentMutations.SET_DOCUMENT, {
            ...res.data,
            documentTvas: [],
            isTemplate: false,
          });
          commit(DocumentMutations.SET_IS_CREATING_DOCUMENT, false);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve({ ...res.data, isTemplate: false });
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          commit(DocumentMutations.SET_IS_CREATING_DOCUMENT, false);
          reject(err);
        });
    });
  },

  async updateQuote(
    { commit, dispatch, rootGetters },
    {
      quote,
      updateState = true,
    }: { quote: QuoteRequestDto; updateState?: boolean }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (updateState) {
        commit(DocumentMutations.SET_IS_UPDATING_DOCUMENT, true);
        commit(
          DocumentMutations.SET_LOADING_MSG,
          "Mise à jour du devis en cours..."
        );
      }
      await axios
        .put(process.env.VUE_APP_API_ORISIS + "Sale/Quote/UpdateQuote", {
          ...quote,
          currencyId: 1,
        })
        .then(async (res) => {
          if (
            res.data.data.quoteBTP &&
            res.data.data.quoteBTP.wasteCollectionPoints
          ) {
            res.data.data.quoteBTP.quoteBTPWasteCollectionPointIds =
              res.data.data.quoteBTP.wasteCollectionPoints.map(
                (elem) => elem.id
              );
            delete res.data.data.quoteBTP.wasteCollectionPoints;
            res.data.data.quoteBTP.quoteBTPWasteTypeIds =
              res.data.data.quoteBTP.wasteTypes.map((elem) => elem.id);
            delete res.data.data.quoteBTP.wasteTypes;
          }

          let dataDocument = {
            ...res.data.data,
            paymentConditionId: res.data.data.paymentCondition
              ? res.data.data.paymentCondition.id
              : null,
            documentTvas: [],
          };
          commit(DocumentMutations.SET_DOCUMENT, dataDocument);

          if (rootGetters.deduction.id) {
            await dispatch("updateDeduction", {
              deduction: rootGetters.deduction,
            });
          } else if (
            rootGetters.deduction.holdbackNumber ||
            rootGetters.deduction.prorataAccountPrice ||
            (rootGetters.deduction.punctualDeductions &&
              rootGetters.deduction.punctualDeductions.length > 0)
          ) {
            await dispatch("createDeduction", {
              deduction: rootGetters.deduction,
            });
          }
          if (updateState) {
            let quoteFilters = rootGetters.userParametersList.find(
              (el) => el.key == "quoteFilters"
            );
            if (quoteFilters) {
              dispatch("getQuotes", {
                dateFrom: JSON.parse(quoteFilters.value).dateRange.dateFrom,
                dateTo: JSON.parse(quoteFilters.value).dateRange.dateTo,
              });
            } else {
              dispatch("setFilter", {
                name: "quoteFilters",
                filter: {
                  dateRangeType: 7,
                  dateRange: { dateFrom: dateFrom, dateTo: dateTo },
                },
              });
              dispatch("getQuotes", {
                dateFrom: dateFrom,
                dateTo: dateTo,
              });
            }
            commit(DocumentMutations.SET_IS_UPDATING_DOCUMENT, false);
          }

          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve(dataDocument);
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          commit(DocumentMutations.SET_IS_UPDATING_DOCUMENT, false);
          reject(err);
        });
    });
  },

  async updatePaymentMethodQuote(
    { commit },
    {
      paymentMethodQuote,
      quoteId,
    }: { paymentMethodQuote: PaymentMethodData; quoteId: number }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Sale/Quote/UpdatePaymentMethodQuote",
          paymentMethodQuote,
          {
            params: {
              quoteId: quoteId,
            },
          }
        )
        .then((res) => {
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          reject(err);
        });
    });
  },

  async uploadFileQuote(
    { commit, rootGetters, dispatch },
    { formData, quoteId }: { formData: FormData; quoteId: number }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(FileMutations.SET_IS_CREATING_FILE, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Sale/Quote/UploadFile`,
          formData,
          {
            params: {
              quoteId: quoteId,
            },
          }
        )
        .then(async (res) => {
          if (!rootGetters.document.folderId) {
            await dispatch("getQuoteById", {
              quoteId: quoteId,
              updateState: false,
            });
          }
          commit(FolderMutations.SET_FOLDER, rootGetters.folder);
          commit(FileMutations.SET_REQUEST_STATE_FILE, res);
          commit(FileMutations.SET_IS_CREATING_FILE, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(FileMutations.SET_REQUEST_STATE_FILE, err);
          commit(FileMutations.SET_IS_CREATING_FILE, false);
          reject(err);
        });
    });
  },

  async updateQuoteTagQuote(
    { commit, rootGetters },
    { quoteIds, quoteTagIds }: { quoteIds: number[]; quoteTagIds: number[] }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(DocumentMutations.SET_QUOTES_LIST, rootGetters.quotesList);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Sale/Quote/UpdateQuoteTagQuote`,
          { quoteIds: quoteIds, quoteTagIds: quoteTagIds }
        )
        .then(async (res) => {
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          // rootGetters.quotesList.splice(
          //   rootGetters.quotesList.findIndex(elem => elem.id == quoteId),
          //   1,
          //   {...rootGetters.quotesList.find(elem => elem.id == quoteId), quoteTags:tags}
          // );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          reject(err);
        });
    });
  },

  async deleteQuotes(
    { commit, dispatch, rootGetters },
    { quoteIds = [], route }: { quoteIds: number[]; route: string }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(DocumentMutations.SET_IS_DELETING_DOCUMENT, true);
      await axios
        .delete(process.env.VUE_APP_API_ORISIS + "Sale/Quote/Delete", {
          data: quoteIds,
        })
        .then((res) => {
          if (route === "quotes" || route === "new-quote") {
            let quoteFilters = rootGetters.userParametersList.find(
              (el) => el.key == "quoteFilters"
            );
            if (quoteFilters) {
              dispatch("getQuotes", {
                dateFrom: JSON.parse(quoteFilters.value).dateRange.dateFrom,
                dateTo: JSON.parse(quoteFilters.value).dateRange.dateTo,
              });
            } else {
              dispatch("setFilter", {
                name: "quoteFilters",
                filter: {
                  dateRangeType: 7,
                  dateRange: { dateFrom: dateFrom, dateTo: dateTo },
                },
              });
              dispatch("getQuotes", {
                dateFrom: dateFrom,
                dateTo: dateTo,
              });
            }
          } else if (route === "lease" || route === "affair") {
            dispatch("getQuotesByAffairId", {
              affairId: rootGetters.lease.affairId,
              updateState: true,
            });
          }
          resolve(res.data);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          commit(DocumentMutations.SET_IS_DELETING_DOCUMENT, false);
        })
        .catch((err) => {
          reject(err);
          console.error(err);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          commit(DocumentMutations.SET_IS_DELETING_DOCUMENT, false);
        });
    });
  },

  // ***** Common actions ***** //

  async generateDocumentPDF({ commit, rootGetters, dispatch }, data) {
    return new Promise(async (resolve, reject) => {
      if (rootGetters.document.usedUrlGed) {
        let urlDocumentPdf =
          "https://ged.orisis.fr/" +
          rootGetters.workspaceSelected.uniqueIdentifier +
          "/Imports/" +
          rootGetters.document.documentReference +
          ".pdf";
        commit(DocumentMutations.SET_DOCUMENT_PDF, urlDocumentPdf);
        commit(DocumentMutations.SET_IS_LOADING_DOCUMENT_PDF, false);
        resolve(urlDocumentPdf);
      } else {
        commit(DocumentMutations.SET_IS_LOADING_DOCUMENT_PDF, true);
        await axios
          .post(`${process.env.VUE_APP_PDF_GENERATOR_URL}generate`, data, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then(async (res) => {
            // Si le document est finalisé
            let nature = "Quote";
            if (
              rootGetters.document.nature == 1 ||
              rootGetters.document.nature == 2 ||
              rootGetters.document.nature == 3 ||
              rootGetters.document.nature == 4
            ) {
              nature = "Invoice";
            } else if (
              rootGetters.document.nature == 5 ||
              rootGetters.document.nature == 6
            ) {
              nature = "Credit";
            }

            if (
              rootGetters.document.status == 2 ||
              (rootGetters.document.status >= 2 &&
                rootGetters.document.status !== 8 &&
                (!rootGetters.document.path ||
                  rootGetters.document.path == "" ||
                  rootGetters.document.path.includes("null.pdf")))
            ) {
              await dispatch("uploadPathDocument", {
                documentId: rootGetters.document.id,
                path: res.data,
                nature: nature,
              });
            }

            commit(DocumentMutations.SET_DOCUMENT_PDF, res.data);
            commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
            commit(DocumentMutations.SET_IS_LOADING_DOCUMENT_PDF, false);
            resolve(res.data);
          })
          .catch((err) => {
            console.error(err);
            commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT_PDF, err);
            commit(DocumentMutations.SET_IS_LOADING_DOCUMENT_PDF, false);
            reject(err);
          });
      }
    });
  },

  async uploadPathDocument(
    { commit, rootGetters },
    {
      documentId,
      path,
      nature,
    }: { documentId: number; path: string; nature: string }
  ): Promise<void> {
    commit(DocumentMutations.SET_IS_UPDATING_DOCUMENT, true);
    await axios
      .put(
        `${
          process.env.VUE_APP_API_ORISIS
        }Sale/${nature}/UploadPathDocument?${nature.toLowerCase()}Id=${documentId}&path=${encodeURIComponent(
          path
        )}`
      )
      .then((res) => {
        let dataDocument = {
          ...res.data,
          paymentConditionId: res.data.paymentCondition
            ? res.data.paymentCondition.id
            : null,
          documentTvas: [],
        };
        commit(DocumentMutations.SET_DOCUMENT, dataDocument);

        if (dataDocument.nature == 0) {
          rootGetters.quotesList.splice(
            rootGetters.quotesList.findIndex((elem) => elem.id == documentId),
            1,
            dataDocument
          );
          commit(DocumentMutations.SET_QUOTES_LIST, rootGetters.quotesList);
        } else if (
          dataDocument.nature == 1 ||
          dataDocument.nature == 2 ||
          dataDocument.nature == 3 ||
          dataDocument.nature == 4
        ) {
          rootGetters.invoicesList.splice(
            rootGetters.invoicesList.findIndex((elem) => elem.id == documentId),
            1,
            dataDocument
          );
          commit(DocumentMutations.SET_INVOICES_LIST, rootGetters.invoicesList);
        } else if (dataDocument.nature == 5 || dataDocument.nature == 6) {
          rootGetters.creditsList.splice(
            rootGetters.creditsList.findIndex((elem) => elem.id == documentId),
            1,
            dataDocument
          );
          commit(DocumentMutations.SET_CREDITS_LIST, rootGetters.creditsList);
        }

        commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
        commit(DocumentMutations.SET_IS_UPDATING_DOCUMENT, false);
      })
      .catch((err) => {
        console.error(err);
        commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
        commit(DocumentMutations.SET_IS_UPDATING_DOCUMENT, false);
      });
  },

  async checkMarginStatus({ commit, rootGetters }): Promise<void> {
    let status = true;
    let linesForCacul = rootGetters.getDocumentDetails.filter(
      (el) =>
        el.type != 18 &&
        el.type != 17 &&
        el.type != 16 &&
        el.type != 15 &&
        el.type != 14 &&
        el.type != 0 &&
        el.type != 1 &&
        el.type != 2 &&
        el.type != 11 &&
        el.type != 12 &&
        !el.isOption
    );
    for (let i = 0; i < linesForCacul.length; i++) {
      const line = linesForCacul[i];
      if (line.marginRate == null) {
        status = false;
        break;
      }
    }
    commit(DocumentMutations.SET_MARGIN_STATUS, status);
  },

  async setupHeader({ rootGetters }, { nature = null }: { nature: number }) {
    if (nature != null) {
      let documentOptions:
        | QuoteOptionRequestDto
        | InvoiceOptionRequestDto
        | CreditOptionRequestDto;
      if (nature == 0) {
        documentOptions = rootGetters.quoteOption;
      } else if (nature == 1 || nature == 2 || nature == 3 || nature == 4) {
        documentOptions = rootGetters.invoiceOption;
      } else if (nature == 5 || nature == 6) {
        documentOptions = rootGetters.creditOption;
      }
      let document = rootGetters.document;
      let header = rootGetters.getInvoiceHeader;
      header["index"].display = !documentOptions.hideIndexColumn;
      header["reference"].display = documentOptions.showReferenceColumn;
      header["discount"].display =
        document.nature == 2 || document.nature == 3 || document.nature == 5
          ? false
          : documentOptions.showDiscountColumn;
      header["quantity"].display =
        !documentOptions.hideQuantityColumn &&
        document.nature != 5 &&
        document.nature != 2 &&
        document.nature != 3;
      header["unitId"].display =
        !documentOptions.hideUnitColumn &&
        document.nature != 5 &&
        document.nature != 2 &&
        document.nature != 3;
      header["disbursedNetHt"].display =
        documentOptions.calculateMargin &&
        rootGetters.documentTabActive != "document-pdf";
      header["marginCoefficient"].display =
        documentOptions.calculateMargin &&
        rootGetters.documentTabActive != "document-pdf";
      header["unitPriceHt"].display =
        document.nature != 5 &&
        document.nature != 2 &&
        document.nature != 3 &&
        !documentOptions.hideUnitPriceHtColumn;
      header["referencielTvaId"].display =
        !documentOptions.hideReferencielTvaColumn;
      header["marginRate"].display = false;
      // documentOptions.calculateMargin &&
      // rootGetters.documentTabActive != "document-pdf";
      header["situationProgress"].display =
        document.nature == 4 && !document.situationType ? true : false;
      header["previousSituationProgress"].display =
        document.nature == 4 &&
        !document.situationType &&
        document.documentStep > 1
          ? true
          : false;
      header["situationProgressQuantity"].display =
        document.nature == 4 && document.situationType ? true : false;
      header["previousSituationProgressQuantity"].display =
        document.nature == 4 &&
        document.situationType &&
        document.documentStep > 1
          ? true
          : false;
      header["quantityDeducted"].display = document.nature == 6 ? true : false;
      header["total"].display = !documentOptions.hidePriceHtColumn;
    }
  },

  async changeDocumentTabActive(
    { commit, dispatch, rootGetters },
    tabToActivate: string
  ) {
    commit(DocumentMutations.SET_DOCUMENT_TAB_ACTIVE, tabToActivate);
    dispatch("setupHeader", { nature: rootGetters.document.nature });
  },
};
