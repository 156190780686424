import { DegressiveSalesRatesRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { DegressiveSaleRateMutations } from "./mutations";
import { IDegressiveSaleRateState } from "./states";

export const actions: ActionTree<IDegressiveSaleRateState, RootState> = {
  async getDegressiveSalesRates(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          DegressiveSaleRateMutations.SET_IS_LOADING_DEGRESSIVE_SALE_RATES_LIST,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Sale/DegressiveSalesRates/GetDegressiveSaleRates`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              DegressiveSaleRateMutations.SET_IS_LOADING_DEGRESSIVE_SALE_RATES_LIST,
              false
            );
            commit(
              DegressiveSaleRateMutations.SET_DEGRESSIVE_SALE_RATES_LIST,
              res.data
            );
          }
          commit(
            DegressiveSaleRateMutations.SET_REQUEST_STATE_DEGRESSIVE_SALE_RATE,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DegressiveSaleRateMutations.SET_IS_LOADING_DEGRESSIVE_SALE_RATES_LIST,
            false
          );
          commit(
            DegressiveSaleRateMutations.SET_REQUEST_STATE_DEGRESSIVE_SALE_RATE,
            err
          );
          reject(err);
        });
    });
  },

  async getDegressiveSalesRateById(
    { commit },
    {
      degressiveSalesRatesId,
      updateState = true,
    }: { degressiveSalesRatesId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          DegressiveSaleRateMutations.SET_IS_LOADING_DEGRESSIVE_SALE_RATE,
          true
        );
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Sale/DegressiveSalesRates/GetDegressiveSalesRatesById`,
          {
            params: {
              degressiveSalesRatesId: degressiveSalesRatesId,
            },
          }
        )
        .then((res) => {
          let degressiveSaleRate = {
            ...res.data,
            parentId: res.data.parent ? res.data.parent.id : null,
          };
          if (updateState) {
            commit(
              DegressiveSaleRateMutations.SET_DEGRESSIVE_SALE_RATE,
              degressiveSaleRate
            );
            commit(
              DegressiveSaleRateMutations.SET_IS_LOADING_DEGRESSIVE_SALE_RATE,
              false
            );
          }
          commit(
            DegressiveSaleRateMutations.SET_REQUEST_STATE_DEGRESSIVE_SALE_RATE,
            res
          );
          resolve(degressiveSaleRate);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DegressiveSaleRateMutations.SET_REQUEST_STATE_DEGRESSIVE_SALE_RATE,
            err
          );
          commit(
            DegressiveSaleRateMutations.SET_IS_LOADING_DEGRESSIVE_SALE_RATE,
            false
          );
          reject(err);
        });
    });
  },

  async createDegressiveSaleRate(
    { commit },
    {
      degressiveSaleRate,
    }: {
      degressiveSaleRate: DegressiveSalesRatesRequestDto;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        DegressiveSaleRateMutations.SET_IS_CREATING_DEGRESSIVE_SALE_RATE,
        true
      );
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Sale/DegressiveSalesRates/CreateDegressiveSalesRates`,
          degressiveSaleRate
        )
        .then((res) => {
          commit(
            DegressiveSaleRateMutations.SET_REQUEST_STATE_DEGRESSIVE_SALE_RATE,
            res
          );
          commit(
            DegressiveSaleRateMutations.SET_IS_CREATING_DEGRESSIVE_SALE_RATE,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DegressiveSaleRateMutations.SET_REQUEST_STATE_DEGRESSIVE_SALE_RATE,
            err
          );
          commit(
            DegressiveSaleRateMutations.SET_IS_CREATING_DEGRESSIVE_SALE_RATE,
            false
          );
          reject(err);
        });
    })
  },

  async updateDegressiveSaleRate(
    { commit },
    { degressiveSaleRate }: { degressiveSaleRate: DegressiveSalesRatesRequestDto; }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        DegressiveSaleRateMutations.SET_IS_UPDATING_DEGRESSIVE_SALE_RATE,
        true
      );
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Sale/DegressiveSalesRates/UpdateDegressiveSalesRates`,
          degressiveSaleRate
        )
        .then((res) => {
          commit(
            DegressiveSaleRateMutations.SET_REQUEST_STATE_DEGRESSIVE_SALE_RATE,
            res
          );
          commit(
            DegressiveSaleRateMutations.SET_IS_UPDATING_DEGRESSIVE_SALE_RATE,
            false
          );
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DegressiveSaleRateMutations.SET_REQUEST_STATE_DEGRESSIVE_SALE_RATE,
            err
          );
          commit(
            DegressiveSaleRateMutations.SET_IS_UPDATING_DEGRESSIVE_SALE_RATE,
            false
          );
          reject(err);
        });
    })
  },

  async deleteDegressiveSaleRates(
    { commit, rootGetters },
    { degressiveSaleRateIds }: { degressiveSaleRateIds: number[] }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        DegressiveSaleRateMutations.SET_IS_DELETING_DEGRESSIVE_SALE_RATE,
        true
      );
      await axios
        .delete(
          `${process.env.VUE_APP_API_ORISIS}Sale/DegressiveSalesRates/Delete`,
          {
            data: degressiveSaleRateIds,
          }
        )
        .then((res) => {
          rootGetters.degressiveSaleRatesList.splice(
            rootGetters.degressiveSaleRatesList.findIndex(
              (elem) => elem.id == degressiveSaleRateIds
            ),
            1
          );
          commit(
            DegressiveSaleRateMutations.SET_DEGRESSIVE_SALE_RATES_LIST,
            rootGetters.degressiveSaleRatesList
          );
          commit(
            DegressiveSaleRateMutations.SET_REQUEST_STATE_DEGRESSIVE_SALE_RATE,
            res
          );
          commit(
            DegressiveSaleRateMutations.SET_IS_DELETING_DEGRESSIVE_SALE_RATE,
            false
          );
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DegressiveSaleRateMutations.SET_REQUEST_STATE_DEGRESSIVE_SALE_RATE,
            err
          );
          commit(
            DegressiveSaleRateMutations.SET_IS_DELETING_DEGRESSIVE_SALE_RATE,
            false
          );
          reject(err);
        });
    })
  },
};
