import { IOrderFormOptionDefaultState } from "./states";
import { Module } from "vuex";
import { OrderFormOptionRequestDto } from "@/../Api";
import RootState from "@/store/modules/rootState";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

const state: IOrderFormOptionDefaultState = {
  orderFormOptionDefault: <OrderFormOptionRequestDto>{},
  orderFormOptionDefaultForm: <OrderFormOptionRequestDto>{},
  isLoadingOrderFormOptionDefault: false,
  requestStateOrderFormOptionDefault: <IRequestState>{},
};

export const OrderFormOptionDefaultModule: Module<
  IOrderFormOptionDefaultState,
  RootState
> = {
  state,
  getters,
  mutations,
  actions,
};
