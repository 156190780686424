import { AffairCostingRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { AffairCostingMutations } from "./mutations";
import { IAffairCostingState } from "./states";
var dayjs = require("dayjs");

export const actions: ActionTree<IAffairCostingState, RootState> = {
  async getAffairCostingById(
    { commit },
    {
      affairCostingId,
      updateState = true,
    }: { affairCostingId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(AffairCostingMutations.SET_IS_LOADING_AFFAIR_COSTING, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Directory/AffairCosting/GetAffairCostingById`,
          {
            params: {
              affairCostingId: affairCostingId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(AffairCostingMutations.SET_AFFAIR_COSTING, res.data);
            commit(AffairCostingMutations.SET_IS_LOADING_AFFAIR_COSTING, false);
          }
          commit(AffairCostingMutations.SET_REQUEST_STATE_AFFAIR_COSTING, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(AffairCostingMutations.SET_REQUEST_STATE_AFFAIR_COSTING, err);
          commit(AffairCostingMutations.SET_IS_LOADING_AFFAIR_COSTING, false);
          reject(err);
        });
    });
  },

  async getAffairCostings(
    { commit },
    {
      startDate = null,
      endDate = null,
      affairId = null,
      sourceType,
      updateState = true,
    }: {
      startDate: string;
      endDate: string;
      affairId: number;
      sourceType: number;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(AffairCostingMutations.SET_IS_LOADING_AFFAIR_COSTING_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/AffairCosting/GetAffairCostings`;
      await axios
        .get(url, {
          params: {
            startDate: dayjs(startDate).format("YYYY-MM-DD"),
            endDate: dayjs(endDate)
              .hour(23)
              .minute(59)
              .second(59)
              .format("YYYY-MM-DD HH:mm:ss"),
            affairId: affairId,
            sourceType: sourceType,
          },
        })
        .then((res) => {
          if (updateState) {
            commit(AffairCostingMutations.SET_AFFAIR_COSTINGS_LIST, res.data);
            commit(
              AffairCostingMutations.SET_IS_LOADING_AFFAIR_COSTING_LIST,
              false
            );
          }
          commit(AffairCostingMutations.SET_REQUEST_STATE_AFFAIR_COSTING, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            AffairCostingMutations.SET_IS_LOADING_AFFAIR_COSTING_LIST,
            false
          );
          commit(AffairCostingMutations.SET_REQUEST_STATE_AFFAIR_COSTING, err);
          reject(err);
        });
    });
  },

  async getAffairCostingsByAffairId(
    { commit },
    {
      startDate = null,
      endDate = null,
      affairId,
      sourceType,
      updateState = true,
    }: {
      startDate: string;
      endDate: string;
      affairId: number;
      sourceType: number;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          AffairCostingMutations.SET_IS_LOADING_AFFAIR_COSTING_LIST_BY_AFFAIR,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/AffairCosting/GetAffairCostings`;
      await axios
        .get(url, {
          params: {
            startDate: startDate,
            endDate: endDate,
            affairId: affairId,
            sourceType: sourceType,
          },
        })
        .then((res) => {
          if (updateState) {
            commit(
              AffairCostingMutations.SET_AFFAIR_COSTINGS_LIST_BY_AFFAIR,
              res.data
            );
            commit(
              AffairCostingMutations.SET_IS_LOADING_AFFAIR_COSTING_LIST_BY_AFFAIR,
              false
            );
          }
          commit(AffairCostingMutations.SET_REQUEST_STATE_AFFAIR_COSTING, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            AffairCostingMutations.SET_IS_LOADING_AFFAIR_COSTING_LIST_BY_AFFAIR,
            false
          );
          commit(AffairCostingMutations.SET_REQUEST_STATE_AFFAIR_COSTING, err);
          reject(err);
        });
    });
  },

  async getAffairCostingArchivedById(
    { commit },
    {
      affairCostingId,
      updateState = true,
    }: { affairCostingId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(AffairCostingMutations.SET_IS_LOADING_AFFAIR_COSTING, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Directory/AffairCosting/GetAffairCostingArhivedById`,
          {
            params: {
              affairCostingId: affairCostingId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(AffairCostingMutations.SET_AFFAIR_COSTING, res.data);
            commit(AffairCostingMutations.SET_IS_LOADING_AFFAIR_COSTING, false);
          }
          commit(AffairCostingMutations.SET_REQUEST_STATE_AFFAIR_COSTING, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(AffairCostingMutations.SET_REQUEST_STATE_AFFAIR_COSTING, err);
          commit(AffairCostingMutations.SET_IS_LOADING_AFFAIR_COSTING, false);
          reject(err);
        });
    });
  },

  async getAffairCostingsArchived(
    { commit },
    {
      startDate = null,
      endDate = null,
      affairId = null,
      sourceType,
      updateState = true,
    }: {
      startDate: string;
      endDate: string;
      affairId: number;
      sourceType: number;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          AffairCostingMutations.SET_IS_LOADING_AFFAIR_COSTING_LIST_ARCHIVED,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/AffairCosting/GetAffairCostingsArchived`;
      await axios
        .get(url, {
          params: {
            startDate: startDate,
            endDate: endDate,
            affairId: affairId,
            sourceType: sourceType,
          },
        })
        .then((res) => {
          if (updateState) {
            commit(
              AffairCostingMutations.SET_IS_LOADING_AFFAIR_COSTING_LIST_ARCHIVED,
              false
            );
            commit(
              AffairCostingMutations.SET_AFFAIR_COSTINGS_LIST_ARCHIVED,
              res.data
            );
          }
          commit(AffairCostingMutations.SET_REQUEST_STATE_AFFAIR_COSTING, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            AffairCostingMutations.SET_IS_LOADING_AFFAIR_COSTING_LIST,
            false
          );
          commit(AffairCostingMutations.SET_REQUEST_STATE_AFFAIR_COSTING, err);
          reject(err);
        });
    });
  },

  async getAffairCostingsArchivedByAffairId(
    { commit },
    {
      startDate = null,
      endDate = null,
      affairId,
      sourceType,
      updateState = true,
    }: {
      startDate: string;
      endDate: string;
      affairId: number;
      sourceType: number;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          AffairCostingMutations.SET_IS_LOADING_AFFAIR_COSTING_LIST_BY_AFFAIR_ARCHIVED,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/AffairCosting/GetAffairCostingsArchived`;
      await axios
        .get(url, {
          params: {
            startDate: startDate,
            endDate: endDate,
            affairId: affairId,
            sourceType: sourceType,
          },
        })
        .then((res) => {
          if (updateState) {
            commit(
              AffairCostingMutations.SET_IS_LOADING_AFFAIR_COSTING_LIST_BY_AFFAIR_ARCHIVED,
              false
            );
            commit(
              AffairCostingMutations.SET_AFFAIR_COSTINGS_LIST_BY_AFFAIR_ARCHIVED,
              res.data
            );
          }
          commit(AffairCostingMutations.SET_REQUEST_STATE_AFFAIR_COSTING, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            AffairCostingMutations.SET_IS_LOADING_AFFAIR_COSTING_LIST_BY_AFFAIR_ARCHIVED,
            false
          );
          commit(AffairCostingMutations.SET_REQUEST_STATE_AFFAIR_COSTING, err);
          reject(err);
        });
    });
  },

  async createAffairCosting(
    { commit, rootGetters },
    {
      affairCosting,
      route,
    }: { affairCosting: AffairCostingRequestDto; route: string }
  ) {
    return new Promise(async (resolve, reject) => {
      commit(AffairCostingMutations.SET_IS_CREATING_AFFAIR_COSTING, true);
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Directory/AffairCosting/CreateAffairCosting`,
          affairCosting
        )
        .then((res) => {
          let affairCostingRes = {
            ...affairCosting,
            id: res.data.data.id,
          };
          if (route == "affair") {
            rootGetters.affairCostingsListByAffair.push(affairCostingRes);
            commit(
              AffairCostingMutations.SET_AFFAIR_COSTINGS_LIST_BY_AFFAIR,
              rootGetters.affairCostingsListByAffair
            );
          } else if (route == "affairCosting") {
            rootGetters.affairCostingsList.push(affairCostingRes);
            commit(
              AffairCostingMutations.SET_AFFAIR_COSTINGS_LIST,
              rootGetters.affairCostingsList
            );
          }
          commit(AffairCostingMutations.SET_REQUEST_STATE_AFFAIR_COSTING, res);
          commit(AffairCostingMutations.SET_IS_CREATING_AFFAIR_COSTING, false);
          resolve(affairCostingRes);
        })
        .catch((err) => {
          console.error(err);
          commit(AffairCostingMutations.SET_REQUEST_STATE_AFFAIR_COSTING, err);
          commit(AffairCostingMutations.SET_IS_CREATING_AFFAIR_COSTING, false);
          reject(err);
        });
    });
  },

  async updateAffairCosting(
    { commit, rootGetters },
    {
      affairCosting,
      route,
    }: { affairCosting: AffairCostingRequestDto; route: string }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(AffairCostingMutations.SET_IS_UPDATING_AFFAIR_COSTING, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Directory/AffairCosting/UpdateAffairCosting`,
          affairCosting
        )
        .then((res) => {
          let affairCostingRes = {
            ...affairCosting,
            id: res.data.data.id,
          };
          if (route == "affair") {
            rootGetters.affairCostingsListByAffair.splice(
              rootGetters.affairCostingsListByAffair.findIndex(
                (elem: AffairCostingRequestDto) => elem.id == affairCosting.id
              ),
              1,
              affairCostingRes
            );
            commit(
              AffairCostingMutations.SET_AFFAIR_COSTINGS_LIST_BY_AFFAIR,
              rootGetters.affairCostingsListByAffair
            );
          } else if (route == "affairCosting") {
            rootGetters.affairCostingsList.splice(
              rootGetters.affairCostingsList.findIndex(
                (elem: AffairCostingRequestDto) => elem.id == affairCosting.id
              ),
              1,
              affairCostingRes
            );
            commit(
              AffairCostingMutations.SET_AFFAIR_COSTINGS_LIST,
              rootGetters.affairCostingsList
            );
          }
          commit(AffairCostingMutations.SET_REQUEST_STATE_AFFAIR_COSTING, res);
          commit(AffairCostingMutations.SET_IS_UPDATING_AFFAIR_COSTING, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(AffairCostingMutations.SET_REQUEST_STATE_AFFAIR_COSTING, err);
          commit(AffairCostingMutations.SET_IS_UPDATING_AFFAIR_COSTING, false);
          reject(err);
        });
    });
  },

  async restoreAffairCostings(
    { commit, rootGetters },
    {
      affairCostingIds = [],
      route,
    }: { affairCostingIds: number[]; route: string }
  ): Promise<void> {
    commit(AffairCostingMutations.SET_IS_UPDATING_AFFAIR_COSTING, true);
    await axios
      .put(
        process.env.VUE_APP_API_ORISIS + "Directory/AffairCosting/Restore",
        affairCostingIds
      )
      .then(async (res) => {
        if (route == "affair") {
          affairCostingIds.forEach((affairCostingId: number) => {
            rootGetters.affairCostingsListArchivedByAffair.splice(
              rootGetters.affairCostingsListArchivedByAffair.findIndex(
                (affairCosting) => affairCosting.id == affairCostingId
              ),
              1
            );
          });
          commit(
            AffairCostingMutations.SET_AFFAIR_COSTINGS_LIST_BY_AFFAIR,
            rootGetters.affairCostingsListArchivedByAffair
          );
        } else if (route == "affairCosting") {
          affairCostingIds.forEach((affairCostingId: number) => {
            rootGetters.affairCostingsListArchived.splice(
              rootGetters.affairCostingsListArchived.findIndex(
                (affairCosting) => affairCosting.id == affairCostingId
              ),
              1
            );
          });
          commit(
            AffairCostingMutations.SET_AFFAIR_COSTINGS_LIST,
            rootGetters.affairCostingsListArchived
          );
        }
        commit(AffairCostingMutations.SET_REQUEST_STATE_AFFAIR_COSTING, res);
        commit(AffairCostingMutations.SET_IS_UPDATING_AFFAIR_COSTING, false);
      })
      .catch((err) => {
        console.error(err);
        commit(AffairCostingMutations.SET_REQUEST_STATE_AFFAIR_COSTING, err);
        commit(AffairCostingMutations.SET_IS_UPDATING_AFFAIR_COSTING, false);
      });
  },

  async archiveAffairCostings(
    { commit, rootGetters },
    {
      affairCostingIds = [],
      route,
    }: { affairCostingIds: number[]; route: string }
  ): Promise<void> {
    commit(AffairCostingMutations.SET_IS_ARCHIVING_AFFAIR_COSTING, true);
    await axios
      .delete(
        process.env.VUE_APP_API_ORISIS + "Directory/AffairCosting/Archive",
        {
          data: affairCostingIds,
        }
      )
      .then((res) => {
        if (route == "affair") {
          affairCostingIds.forEach((affairCostingId: number) => {
            rootGetters.affairCostingsListByAffair.splice(
              rootGetters.affairCostingsListByAffair.findIndex(
                (affairCosting) => affairCosting.id == affairCostingId
              ),
              1
            );
          });
          commit(
            AffairCostingMutations.SET_AFFAIR_COSTINGS_LIST_BY_AFFAIR,
            rootGetters.affairCostingsListByAffair
          );
        } else if (route == "affairCosting") {
          affairCostingIds.forEach((affairCostingId: number) => {
            rootGetters.affairCostingsList.splice(
              rootGetters.affairCostingsList.findIndex(
                (affairCosting) => affairCosting.id == affairCostingId
              ),
              1
            );
          });
          commit(
            AffairCostingMutations.SET_AFFAIR_COSTINGS_LIST,
            rootGetters.affairCostingsList
          );
        }
        commit(AffairCostingMutations.SET_REQUEST_STATE_AFFAIR_COSTING, res);
        commit(AffairCostingMutations.SET_IS_ARCHIVING_AFFAIR_COSTING, false);
      })
      .catch((err) => {
        console.error(err);
        commit(AffairCostingMutations.SET_REQUEST_STATE_AFFAIR_COSTING, err);
        commit(AffairCostingMutations.SET_IS_ARCHIVING_AFFAIR_COSTING, false);
      });
  },

  async deleteAffairCostings(
    { commit, rootGetters },
    {
      affairCostingIds = [],
      route,
    }: { affairCostingIds: number[]; route: string }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(AffairCostingMutations.SET_IS_DELETING_AFFAIR_COSTING, true);
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Directory/AffairCosting/Delete",
          {
            data: affairCostingIds,
          }
        )
        .then((res) => {
          if (route == "affair") {
            affairCostingIds.forEach((affairCostingId: number) => {
              rootGetters.affairCostingsListByAffair.splice(
                rootGetters.affairCostingsListByAffair.findIndex(
                  (affairCosting) => affairCosting.id == affairCostingId
                ),
                1
              );
            });
            commit(
              AffairCostingMutations.SET_AFFAIR_COSTINGS_LIST_BY_AFFAIR,
              rootGetters.affairCostingsListByAffair
            );
          } else if (route == "affairCosting") {
            affairCostingIds.forEach((affairCostingId: number) => {
              rootGetters.affairCostingsList.splice(
                rootGetters.affairCostingsList.findIndex(
                  (affairCosting) => affairCosting.id == affairCostingId
                ),
                1
              );
            });
            commit(
              AffairCostingMutations.SET_AFFAIR_COSTINGS_LIST,
              rootGetters.affairCostingsList
            );
          }
          commit(AffairCostingMutations.SET_REQUEST_STATE_AFFAIR_COSTING, res);
          commit(AffairCostingMutations.SET_IS_DELETING_AFFAIR_COSTING, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(AffairCostingMutations.SET_REQUEST_STATE_AFFAIR_COSTING, err);
          commit(AffairCostingMutations.SET_IS_DELETING_AFFAIR_COSTING, false);
          reject(err);
        });
    });
  },
};
