import { ReceiptFormProviderMutations } from "../receiptFormProvider/mutations";
import { IReceiptFormProviderDetailState } from "./states";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { ReceiptFormDetailRequestDto } from "Api";
import { ReceiptFormProviderDetailMutations } from "./mutations";
import { DocumentDetailMutations } from "../../invoices/documentDetail/mutations";
import {
  getNewIndex,
  calculateTotalReceiptFormProviderLine,
} from "@/types/api-orisis/library/DetailReceiptFormProviderOperations";
import { calculateSubTotalLines } from "@/types/api-orisis/library/ReceiptFormProviderOperations";
import { CommonLineReceiptFormProvider } from "@/types/api-orisis/models/LineModels";

export const actions: ActionTree<IReceiptFormProviderDetailState, RootState> = {
  getReceiptFormDetailsByReceiptFormId(
    { commit },
    {
      receiptFormId,
      updateState = true,
    }: { receiptFormId: number; updateState?: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          ReceiptFormProviderDetailMutations.SET_IS_LOADING_RECEIPT_FORM_DETAILS,
          true
        );
      }
      axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/ReceiptFormDetail/GetReceiptFormDetailsByReceiptFormId`,
          {
            params: {
              receiptFormId: receiptFormId,
            },
          }
        )
        .then((res) => {
          let data = res.data.map((el) => {
            return {
              ...el,
              referencielTvaId: el.referencielTva ? el.referencielTva.id : 5,
              unitId: el.unit ? el.unit.id : 1,
              total: el.quantity
                ? calculateTotalReceiptFormProviderLine(el)
                : 0,
              titre: el.description,
            };
          });
          if (updateState) {
            commit(
              ReceiptFormProviderDetailMutations.SET_RECEIPT_FORM_DETAILS,
              data
            );
            commit(
              ReceiptFormProviderDetailMutations.SET_IS_LOADING_RECEIPT_FORM_DETAILS,
              false
            );
          }
          commit(
            ReceiptFormProviderDetailMutations.SET_REQUEST_STATE_RECEIPT_FORM_DETAIL,
            res
          );
          resolve(data);
        })
        .catch((err) => {
          commit(
            ReceiptFormProviderDetailMutations.SET_IS_LOADING_RECEIPT_FORM_DETAILS,
            false
          );
          commit(
            ReceiptFormProviderDetailMutations.SET_REQUEST_STATE_RECEIPT_FORM_DETAIL,
            err
          );
          reject(err);
        });
    });
  },

  createReceiptFormDetail(
    { commit, rootGetters },
    { receiptFormDetail }: { receiptFormDetail: ReceiptFormDetailRequestDto }
  ) {
    return new Promise((resolve, reject) => {
      commit(
        ReceiptFormProviderDetailMutations.SET_IS_CREATING_RECEIPT_FORM_DETAIL,
        true
      );
      axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/ReceiptFormDetail/CreateReceiptFormDetail",
          receiptFormDetail
        )
        .then((res) => {
          let newObject = {
            ...receiptFormDetail,
            id: res.data.data.id,
          };
          rootGetters.getReceiptFormProviderDetails.push(newObject);
          commit(
            ReceiptFormProviderDetailMutations.SET_RECEIPT_FORM_DETAILS,
            rootGetters.getReceiptFormProviderDetails
          );
          commit(
            ReceiptFormProviderDetailMutations.SET_REQUEST_STATE_RECEIPT_FORM_DETAIL,
            res
          );
          commit(
            ReceiptFormProviderDetailMutations.SET_IS_CREATING_RECEIPT_FORM_DETAIL,
            false
          );
          resolve(newObject);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ReceiptFormProviderDetailMutations.SET_REQUEST_STATE_RECEIPT_FORM_DETAIL,
            err
          );
          commit(
            ReceiptFormProviderDetailMutations.SET_IS_CREATING_RECEIPT_FORM_DETAIL,
            false
          );
          reject(err);
        });
    });
  },

  async createReceiptFormProviderDetailSetPosition(
    { commit, rootGetters },
    { receiptFormProviderDetail, position }
  ) {
    return new Promise(async (resolve, reject) => {
      commit(
        ReceiptFormProviderDetailMutations.SET_IS_CREATING_RECEIPT_FORM_DETAIL,
        true
      );
      await axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/ReceiptFormDetail/CreateReceiptFormDetail",
          receiptFormProviderDetail
        )
        .then((res) => {
          let newObject = {
            ...receiptFormProviderDetail,
            id: res.data.data.id,
          };
          rootGetters.getReceiptFormProviderDetails.splice(
            position,
            0,
            newObject
          );
          commit(
            ReceiptFormProviderDetailMutations.SET_RECEIPT_FORM_DETAILS,
            rootGetters.getReceiptFormProviderDetails
          );
          commit(
            ReceiptFormProviderDetailMutations.SET_REQUEST_STATE_RECEIPT_FORM_DETAIL,
            res
          );
          commit(
            ReceiptFormProviderDetailMutations.SET_IS_CREATING_RECEIPT_FORM_DETAIL,
            false
          );
          resolve(newObject);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ReceiptFormProviderDetailMutations.SET_REQUEST_STATE_RECEIPT_FORM_DETAIL,
            err
          );
          commit(
            ReceiptFormProviderDetailMutations.SET_IS_CREATING_RECEIPT_FORM_DETAIL,
            false
          );
          reject(err);
        });
    });
  },

  updateReceiptFormProviderDetail(
    { commit, rootGetters },
    { receiptFormDetail }: { receiptFormDetail: ReceiptFormDetailRequestDto }
  ) {
    return new Promise(async (resolve, reject) => {
      commit(
        ReceiptFormProviderDetailMutations.SET_IS_UPDATING_RECEIPT_FORM_DETAIL,
        true
      );
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/ReceiptFormDetail/UpdateReceiptFormDetail",
          receiptFormDetail
        )
        .then((res) => {
          rootGetters.getReceiptFormProviderDetails.splice(
            rootGetters.getReceiptFormProviderDetails.findIndex(
              (elem) => elem.id == receiptFormDetail.id
            ),
            1,
            { ...receiptFormDetail, productId: res.data.data.productId }
          );
          commit(
            ReceiptFormProviderDetailMutations.SET_RECEIPT_FORM_DETAILS,
            rootGetters.getReceiptFormProviderDetails
          );
          commit(
            ReceiptFormProviderDetailMutations.SET_REQUEST_STATE_RECEIPT_FORM_DETAIL,
            res
          );
          commit(
            ReceiptFormProviderDetailMutations.SET_IS_UPDATING_RECEIPT_FORM_DETAIL,
            false
          );
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ReceiptFormProviderDetailMutations.SET_REQUEST_STATE_RECEIPT_FORM_DETAIL,
            err
          );
          commit(
            ReceiptFormProviderDetailMutations.SET_IS_UPDATING_RECEIPT_FORM_DETAIL,
            false
          );
          reject(err);
        });
    });
  },

  async loopReceiptFormProviderDetails(
    { dispatch, rootGetters },
    { referencielTvaId }: { referencielTvaId: number }
  ) {
    return new Promise((resolve) => {
      let ReceiptFormProviderDetailIds: Array<number> = [];
      for (
        let i = 0;
        i < rootGetters.getReceiptFormProviderDetails.length;
        i++
      ) {
        const line = rootGetters.getReceiptFormProviderDetails[i];
        line.referencielTvaId = referencielTvaId;
        ReceiptFormProviderDetailIds.push(line.id);
      }
      dispatch("updateTvaReceiptFormProviderDetail", {
        receiptFormProviderDetailIds: ReceiptFormProviderDetailIds,
        referencielTvaId: referencielTvaId,
      }).then((res: any) => {
        resolve(res);
      });
    });
  },

  async reviewIndexReceiptFormProvider({ dispatch, commit, rootGetters }) {
    commit(
      ReceiptFormProviderDetailMutations.SET_IS_LOADING_RECEIPT_FORM_DETAILS,
      true
    );
    let receiptFormProviderDetailIndex = [];
    for (let i = 0; i < rootGetters.getReceiptFormProviderDetails.length; i++) {
      const line = rootGetters.getReceiptFormProviderDetails[i];
      await getNewIndex(rootGetters.getReceiptFormProviderDetails, {
        ...line,
        indexArray: i - 1,
      }).then(async (res: any) => {
        line.index = res.index;
        receiptFormProviderDetailIndex.push({
          id: line.id,
          position: line.position,
          index: res.index,
        });
      });
    }
    calculateSubTotalLines(true);
    commit(
      ReceiptFormProviderDetailMutations.SET_IS_LOADING_RECEIPT_FORM_DETAILS,
      false
    );
    dispatch("updateReceiptFormProviderDetailIndex", {
      receiptFormProviderDetailIndex,
    });
  },

  async updateReceiptFormProviderDetailIndex(
    { commit },
    { receiptFormProviderDetailIndex }: { receiptFormProviderDetailIndex: any }
  ) {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/ReceiptFormDetail/UpdateReceiptDetailIndex",
          receiptFormProviderDetailIndex
        )
        .then((res) => {
          commit(
            ReceiptFormProviderDetailMutations.SET_REQUEST_STATE_RECEIPT_FORM_DETAIL,
            res
          );
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ReceiptFormProviderDetailMutations.SET_REQUEST_STATE_RECEIPT_FORM_DETAIL,
            err
          );
          reject(err);
        });
    });
  },

  async updateTvaReceiptFormProviderDetail(
    { commit, rootGetters },
    { receiptFormProviderDetailIds, referencielTvaId }
  ) {
    return new Promise(async (resolve, reject) => {
      commit(
        ReceiptFormProviderDetailMutations.SET_IS_LOADING_RECEIPT_FORM_DETAILS,
        true
      );
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/ReceiptFormDetail/UpdateTVAReceiptFormDetail",
          receiptFormProviderDetailIds,
          {
            params: {
              tvaId: referencielTvaId,
            },
          }
        )
        .then((res) => {
          commit(
            ReceiptFormProviderDetailMutations.SET_REQUEST_STATE_RECEIPT_FORM_DETAIL,
            res
          );
          commit(
            ReceiptFormProviderDetailMutations.SET_IS_LOADING_RECEIPT_FORM_DETAILS,
            false
          );
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ReceiptFormProviderDetailMutations.SET_REQUEST_STATE_RECEIPT_FORM_DETAIL,
            err
          );
          commit(
            ReceiptFormProviderDetailMutations.SET_IS_LOADING_RECEIPT_FORM_DETAILS,
            false
          );
          reject(err);
        });
    });
  },

  async addLineReceiptFormProvider(
    { dispatch, rootGetters, commit },
    { payload }
  ) {
    return new Promise(async (resolve) => {
      commit(DocumentDetailMutations.SET_DISABLED_OPTIONS, true);
      if (payload.review) {
        let newObject = CommonLineReceiptFormProvider(payload);
        await dispatch("createReceiptFormProviderDetailSetPosition", {
          receiptFormProviderDetail: newObject,
          position: payload.indexArray,
        });
        await dispatch("reviewIndexReceiptFormProvider");
        resolve(true);
      } else {
        await getNewIndex(
          rootGetters.getReceiptFormProviderDetails,
          payload
        ).then(async (res: any) => {
          let newObject = CommonLineReceiptFormProvider({
            ...payload,
            index: res.index,
            type: res.type,
          });
          await dispatch("createReceiptFormDetail", {
            receiptFormDetail: newObject,
          });
          resolve(true);
        });
      }
      commit(DocumentDetailMutations.SET_DISABLED_OPTIONS, false);
    });
  },

  async deleteReceiptFormProviderDetail(
    { commit, dispatch, rootGetters },
    { payload }
  ) {
    commit(DocumentDetailMutations.SET_DISABLED_OPTIONS, true);
    commit(
      ReceiptFormProviderDetailMutations.SET_IS_DELETING_RECEIPT_FORM_DETAIL,
      true
    );
    if (payload.length) {
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Purchase/ReceiptFormDetail/Delete",
          {
            data: payload.map((elem) => elem.receiptFormProviderDetailId),
          }
        )
        .then(async (res) => {
          // payload.forEach(detail => {
          for (let index = 0; index < payload.length; index++) {
            const detail = payload[index];
            rootGetters.getReceiptFormProviderDetails.splice(
              rootGetters.getReceiptFormProviderDetails.findIndex(
                (elem) => elem.id == detail.receiptFormProviderDetailId
              ),
              1
            );
            if (detail.reviewIndex) {
              await dispatch("reviewIndexReceiptFormProvider");
            }
          }
          commit(
            ReceiptFormProviderDetailMutations.SET_RECEIPT_FORM_DETAIL,
            rootGetters.getReceiptFormProviderDetails
          );
          commit(
            ReceiptFormProviderDetailMutations.SET_REQUEST_STATE_RECEIPT_FORM_DETAIL,
            res
          );
          commit(
            ReceiptFormProviderDetailMutations.SET_IS_DELETING_RECEIPT_FORM_DETAIL,
            false
          );
        })
        .catch((err) => {
          console.error(err);
          commit(
            ReceiptFormProviderDetailMutations.SET_REQUEST_STATE_RECEIPT_FORM_DETAIL,
            err
          );
          commit(
            ReceiptFormProviderDetailMutations.SET_IS_DELETING_RECEIPT_FORM_DETAIL,
            false
          );
        });
    }
    commit(DocumentDetailMutations.SET_DISABLED_OPTIONS, true);
  },

  async duplicateLineReceiptFormProvider(
    { dispatch, rootGetters, commit },
    { payload, position }: { payload: any; position: string }
  ) {
    commit(DocumentDetailMutations.SET_DISABLED_OPTIONS, true);
    let line = JSON.parse(JSON.stringify(payload.line));
    let index = JSON.parse(JSON.stringify(payload.index));
    line.id = 0;
    switch (position) {
      case "before":
        await dispatch("createReceiptFormProviderDetailSetPosition", {
          receiptFormProviderDetail: { ...line, isDisabled: false },
          position: index,
        });
        await dispatch("reviewIndexReceiptFormProvider", 1);
        break;
      case "after":
        await dispatch("createReceiptFormProviderDetailSetPosition", {
          receiptFormProviderDetail: { ...line, isDisabled: false },
          position: index + 1,
        });
        await dispatch("reviewIndexReceiptFormProvider", 1);
        break;
      case "beginning":
        await dispatch("createReceiptFormProviderDetailSetPosition", {
          receiptFormProviderDetail: { ...line, isDisabled: false },
          position: 0,
        });
        await dispatch("reviewIndexReceiptFormProvider", 1);
        break;
      case "end":
        await dispatch("createReceiptFormProviderDetailSetPosition", {
          receiptFormProviderDetail: { ...line, isDisabled: false },
          position: rootGetters.getReceiptFormProviderDetails.length,
        });
        await dispatch("reviewIndexReceiptFormProvider", 1);
        break;
      default:
        await dispatch("createReceiptFormProviderDetailSetPosition", {
          receiptFormProviderDetail: { ...line, isDisabled: false },
          position: index + 1,
        });
        await dispatch("reviewIndexReceiptFormProvider", 1);
        break;
    }
    commit(DocumentDetailMutations.SET_DISABLED_OPTIONS, false);
  },

  async duplicateReceiptFormProviderDetails(
    { commit, dispatch, rootGetters },
    { receiptFormProviderId, newReceiptFormProviderId }
  ) {
    return new Promise(async (resolve, reject) => {
      commit(
        ReceiptFormProviderDetailMutations.SET_IS_LOADING_RECEIPT_FORM_DETAIL_DUPLICATION,
        true
      );
      let request = new URLSearchParams();
      request.append("receiptFormProviderId", receiptFormProviderId);
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/ReceiptFormDetail/GetReceiptFormDetails",
          {
            params: request,
          }
        )
        .then(async (res) => {
          let newArray = res.data.map((el) => {
            return {
              ...el,
              id: 0,
              referencielTvaId: el.referencielTva ? el.referencielTva.id : null,
              unitId: el.unit ? el.unit.id : 0,
              total: el.quantity
                ? calculateTotalReceiptFormProviderLine(el)
                : 0,
              titre: el.type == 0 || el.type == 11 ? el.description : null,
            };
          });
          let lastWorkIndex = null;
          for (let i = 0; i < newArray.length; i++) {
            const line = newArray[i];
            if (
              line.type == 14 ||
              line.type == 15 ||
              line.type == 16 ||
              line.type == 17 ||
              line.type == 18
            )
              line.parentId = lastWorkIndex;
            await dispatch("createReceiptFormDetail", {
              receiptFormDetail: line,
            }).then((res) => {
              if (line.type == 6) lastWorkIndex = res.id;
            });
          }
          commit(
            ReceiptFormProviderDetailMutations.SET_IS_LOADING_RECEIPT_FORM_DETAIL_DUPLICATION,
            false
          );
          commit(
            ReceiptFormProviderDetailMutations.SET_REQUEST_STATE_RECEIPT_FORM_DETAIL,
            res
          );
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ReceiptFormProviderDetailMutations.SET_IS_LOADING_RECEIPT_FORM_DETAIL_DUPLICATION,
            false
          );
          commit(
            ReceiptFormProviderDetailMutations.SET_REQUEST_STATE_RECEIPT_FORM_DETAIL,
            err
          );
          reject(false);
        });
    });
  },
};

function formatNumber(number) {
  return Math.round(number * 100) / 100;
}
