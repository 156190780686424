import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { PayrollEventRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { IPayrollEventState } from './states'


export const getters: GetterTree<IPayrollEventState, RootState> = {
  payrollEventsList: (state): PayrollEventRequestDto[] => state.payrollEventsList,
  payrollEventsListArchived: (state): PayrollEventRequestDto[] => state.payrollEventsListArchived,
  payrollEventsListTemplates: (state): PayrollEventRequestDto[] => state.payrollEventsListTemplates,
  payrollEvent     : (state): PayrollEventRequestDto => state.payrollEvent,

  // COMMON
  isLoadingPayrollEventsList   : (state): boolean => state.isLoadingPayrollEventsList,
  isLoadingPayrollEventsListArchived   : (state): boolean => state.isLoadingPayrollEventsListArchived,
  isLoadingPayrollEventsListTemplates   : (state): boolean => state.isLoadingPayrollEventsListTemplates,
  isLoadingPayrollEvent   : (state): boolean => state.isLoadingPayrollEvent,
  isCreatingPayrollEvent  : (state): boolean => state.isCreatingPayrollEvent,
  isUpdatingPayrollEvent  : (state): boolean => state.isUpdatingPayrollEvent,
  isDeletingPayrollEvent  : (state): boolean => state.isDeletingPayrollEvent,
  isArchivingPayrollEvent  : (state): boolean => state.isArchivingPayrollEvent,
  requestStatePayrollEvent: (state): IRequestState => state.requestStatePayrollEvent,
}



