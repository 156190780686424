import { IGlobalState } from "./states";
import { Module } from "vuex";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import RootState from "@/store/modules/rootState";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

const state: IGlobalState = {
  isGlobalCreating: false,
  isGlobalLoading: false,
  isGlobalMessage: "Création en cours...",
  requestStateGlobal: <IRequestState>{},
  headerDataUserParameterIsLoading: false,
  setModalReceiptForm: false
};

export const GlobalModule: Module<IGlobalState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
