import { IDeliveryFormDetailState } from "./states";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { DeliveryFormDetailRequestDto } from "Api";
import { DeliveryFormDetailMutations } from "./mutations";
import { DocumentDetailMutations } from "@/store/modules/invoices/documentDetail/mutations";
import {
  getNewIndex,
  calculateTotalDeliveryFormLine,
} from "@/types/api-orisis/library/DetailDeliveryFormOperations";
import { calculateSubTotalLines } from "@/types/api-orisis/library/DeliveryFormOperations";
import { CommonLineDeliveryForm } from "@/types/api-orisis/models/LineModels";
import { DeliveryFormDetailIndex } from "@/types/api-orisis/interfaces/Purchase/deliveryFormDetail/IDeliveryFormHeader";

export const actions: ActionTree<IDeliveryFormDetailState, RootState> = {
  getDeliveryFormDetailsByDeliveryFormId(
    { commit },
    {
      deliveryFormId,
      updateState,
    }: { deliveryFormId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          DeliveryFormDetailMutations.SET_IS_LOADING_DELIVERY_FORM_DETAILS,
          true
        );
      }
      axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/DeliveryFormDetail/GetDeliveryFormDetailsByDeliveryFormId`,
          {
            params: {
              deliveryFormId: deliveryFormId,
            },
          }
        )
        .then((res) => {
          commit(
            DeliveryFormDetailMutations.SET_DELIVERY_FORM_DETAILS,
            res.data.map((el) => {
              return {
                ...el,
                referencielTvaId: el.referencielTva ? el.referencielTva.id : 5,
                unitId: el.unit ? el.unit.id : 1,
                productId: el.product ? el.product.id : null,
                total: el.outPutQuantity
                  ? calculateTotalDeliveryFormLine(el)
                  : 0,
                titre: el.description,
              };
            })
          );
          if (updateState) {
            commit(
              DeliveryFormDetailMutations.SET_IS_LOADING_DELIVERY_FORM_DETAILS,
              false
            );
          }
          commit(
            DeliveryFormDetailMutations.SET_REQUEST_STATE_DELIVERY_FORM_DETAIL,
            res
          );
          resolve();
        })
        .catch((err) => {
          commit(
            DeliveryFormDetailMutations.SET_IS_LOADING_DELIVERY_FORM_DETAILS,
            false
          );
          commit(
            DeliveryFormDetailMutations.SET_REQUEST_STATE_DELIVERY_FORM_DETAIL,
            err
          );
          reject(err);
        });
    });
  },

  createDeliveryFormDetail(
    { commit, rootGetters },
    {
      deliveryFormDetail,
      position = null,
    }: { deliveryFormDetail: DeliveryFormDetailRequestDto; position: number }
  ) {
    return new Promise((resolve, reject) => {
      commit(
        DeliveryFormDetailMutations.SET_IS_CREATING_DELIVERY_FORM_DETAIL,
        true
      );
      axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/DeliveryFormDetail/CreateDeliveryFormDetail",
          deliveryFormDetail
        )
        .then((res) => {
          let newObject = { ...deliveryFormDetail, id: res.data.data.id };
          rootGetters.getDeliveryFormDetails.push(newObject);
          commit(
            DeliveryFormDetailMutations.SET_DELIVERY_FORM_DETAILS,
            rootGetters.getDeliveryFormDetails
          );
          commit(
            DeliveryFormDetailMutations.SET_REQUEST_STATE_DELIVERY_FORM_DETAIL,
            res
          );
          commit(
            DeliveryFormDetailMutations.SET_IS_CREATING_DELIVERY_FORM_DETAIL,
            false
          );
          resolve(newObject);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DeliveryFormDetailMutations.SET_REQUEST_STATE_DELIVERY_FORM_DETAIL,
            err
          );
          commit(
            DeliveryFormDetailMutations.SET_IS_CREATING_DELIVERY_FORM_DETAIL,
            false
          );
          reject(err);
        });
    });
  },

  updateDeliveryFormDetail(
    { commit, rootGetters },
    { deliveryFormDetail }: { deliveryFormDetail: DeliveryFormDetailRequestDto }
  ) {
    return new Promise((resolve, reject) => {
      commit(
        DeliveryFormDetailMutations.SET_IS_UPDATING_DELIVERY_FORM_DETAIL,
        true
      );
      axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/DeliveryFormDetail/UpdateDeliveryFormDetail",
          deliveryFormDetail
        )
        .then((res) => {
          rootGetters.getDeliveryFormDetails.splice(
            rootGetters.getDeliveryFormDetails.findIndex(
              (elem) => elem.id == deliveryFormDetail.id
            ),
            1,
            deliveryFormDetail
          );
          commit(
            DeliveryFormDetailMutations.SET_DELIVERY_FORM_DETAILS,
            rootGetters.getDeliveryFormDetails
          );
          commit(
            DeliveryFormDetailMutations.SET_REQUEST_STATE_DELIVERY_FORM_DETAIL,
            res
          );
          commit(
            DeliveryFormDetailMutations.SET_IS_UPDATING_DELIVERY_FORM_DETAIL,
            false
          );
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DeliveryFormDetailMutations.SET_REQUEST_STATE_DELIVERY_FORM_DETAIL,
            err
          );
          commit(
            DeliveryFormDetailMutations.SET_IS_UPDATING_DELIVERY_FORM_DETAIL,
            false
          );
          reject(err);
        });
    });
  },

  updateDeliveryFormDetailIndex(
    { commit },
    {
      deliveryFormDetailIndex,
    }: { deliveryFormDetailIndex: DeliveryFormDetailIndex }
  ) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/DeliveryFormDetail/UpdateDeliveryFormDetailIndex",
          deliveryFormDetailIndex
        )
        .then((res) => {
          commit(
            DeliveryFormDetailMutations.SET_REQUEST_STATE_DELIVERY_FORM_DETAIL,
            res
          );
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DeliveryFormDetailMutations.SET_REQUEST_STATE_DELIVERY_FORM_DETAIL,
            err
          );
          reject(err);
        });
    });
  },

  updateTvaDeliveryFormDetail(
    { commit },
    {
      deliveryFormDetailIds = [],
      referencielTvaId,
    }: { deliveryFormDetailIds: Array<number>; referencielTvaId: number }
  ) {
    return new Promise((resolve, reject) => {
      commit(
        DeliveryFormDetailMutations.SET_IS_LOADING_DELIVERY_FORM_DETAILS,
        true
      );
      axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/DeliveryFormDetail/UpdateTVADeliveryFormDetail",
          deliveryFormDetailIds,
          {
            params: {
              tvaId: referencielTvaId,
            },
          }
        )
        .then((res) => {
          commit(
            DeliveryFormDetailMutations.SET_REQUEST_STATE_DELIVERY_FORM_DETAIL,
            res
          );
          commit(
            DeliveryFormDetailMutations.SET_IS_LOADING_DELIVERY_FORM_DETAILS,
            false
          );
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DeliveryFormDetailMutations.SET_REQUEST_STATE_DELIVERY_FORM_DETAIL,
            err
          );
          commit(
            DeliveryFormDetailMutations.SET_IS_LOADING_DELIVERY_FORM_DETAILS,
            false
          );
          reject(err);
        });
    });
  },

  async deleteDeliveryFormDetails(
    { commit, dispatch, rootGetters },
    {
      deliveryFormDetailIds,
      reviewIndex,
    }: { deliveryFormDetailIds: Array<number>; reviewIndex: boolean }
  ) {
    return new Promise((resolve, reject) => {
      commit(DocumentDetailMutations.SET_DISABLED_OPTIONS, true);
      commit(
        DeliveryFormDetailMutations.SET_IS_DELETING_DELIVERY_FORM_DETAIL,
        true
      );
      axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Purchase/DeliveryFormDetail/Delete",
          {
            data: deliveryFormDetailIds,
          }
        )
        .then(async (res) => {
          deliveryFormDetailIds.forEach((id) => {
            rootGetters.getDeliveryFormDetails.splice(
              rootGetters.getDeliveryFormDetails.findIndex(
                (elem) => elem.id == id
              ),
              1
            );
          });
          if (reviewIndex) {
            dispatch("reviewIndexDeliveryForm");
          }
          commit(
            DeliveryFormDetailMutations.SET_DELIVERY_FORM_DETAIL,
            rootGetters.getDeliveryFormDetails
          );
          commit(
            DeliveryFormDetailMutations.SET_REQUEST_STATE_DELIVERY_FORM_DETAIL,
            res
          );
          commit(
            DeliveryFormDetailMutations.SET_IS_DELETING_DELIVERY_FORM_DETAIL,
            false
          );
          resolve(res);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DeliveryFormDetailMutations.SET_REQUEST_STATE_DELIVERY_FORM_DETAIL,
            err
          );
          commit(
            DeliveryFormDetailMutations.SET_IS_DELETING_DELIVERY_FORM_DETAIL,
            false
          );
          reject(err);
        });
      commit(DocumentDetailMutations.SET_DISABLED_OPTIONS, false);
    });
  },

  async loopDeliveryFormDetails({ dispatch, rootGetters }, referencielTvaId) {
    return new Promise((resolve) => {
      let DeliveryFormDetailIds: Array<number> = [];
      for (let i = 0; i < rootGetters.getDeliveryFormDetails.length; i++) {
        const line = rootGetters.getDeliveryFormDetails[i];
        line.referencielTvaId = referencielTvaId;
        DeliveryFormDetailIds.push(line.id);
      }
      dispatch("updateTvaDeliveryFormDetail", {
        deliveryFormDetailIds: DeliveryFormDetailIds,
        referencielTvaId: referencielTvaId,
      }).then((res: any) => {
        resolve(res);
      });
    });
  },

  async reviewIndexDeliveryForm({ dispatch, commit, rootGetters }) {
    commit(
      DeliveryFormDetailMutations.SET_IS_LOADING_DELIVERY_FORM_DETAILS,
      true
    );
    let deliveryFormDetailIndex = [];
    for (let i = 0; i < rootGetters.getDeliveryFormDetails.length; i++) {
      const line = rootGetters.getDeliveryFormDetails[i];
      await getNewIndex(rootGetters.getDeliveryFormDetails, {
        ...line,
        indexArray: i - 1,
      }).then(async (res: any) => {
        line.index = res.index;
        deliveryFormDetailIndex.push({
          id: line.id,
          position: line.position,
          index: res.index,
        });
      });
    }
    calculateSubTotalLines(true);
    commit(
      DeliveryFormDetailMutations.SET_IS_LOADING_DELIVERY_FORM_DETAILS,
      false
    );
    dispatch("updateDeliveryFormDetailIndex", {
      deliveryFormDetailIndex: deliveryFormDetailIndex,
    });
  },

  addLineDeliveryForm(
    { dispatch, rootGetters, commit },
    {
      deliveryFormDetail,
      review,
      indexArray,
    }: {
      deliveryFormDetail: DeliveryFormDetailRequestDto;
      review: boolean;
      indexArray: number;
    }
  ) {
    return new Promise((resolve) => {
      commit(DocumentDetailMutations.SET_DISABLED_OPTIONS, true);
      if (review) {
        let newObject = CommonLineDeliveryForm(deliveryFormDetail);
        dispatch("createDeliveryFormDetail", {
          deliveryFormDetail: newObject,
          position: indexArray,
        });
        dispatch("reviewIndexDeliveryForm");
        resolve(true);
      } else {
        getNewIndex(
          rootGetters.getDeliveryFormDetails,
          deliveryFormDetail
        ).then(async (res: any) => {
          let newObject = CommonLineDeliveryForm({
            ...deliveryFormDetail,
            index: res.index,
            type: res.type,
          });
          await dispatch("createDeliveryFormDetail", {
            deliveryFormDetail: newObject,
          });
          resolve(true);
        });
      }
      commit(DocumentDetailMutations.SET_DISABLED_OPTIONS, false);
    });
  },

  async duplicateLineDeliveryForm(
    { dispatch, rootGetters, commit },
    {
      deliveryFormDetail,
      index,
      position,
    }: {
      deliveryFormDetail: DeliveryFormDetailRequestDto;
      index: number;
      position: string;
    }
  ) {
    commit(DocumentDetailMutations.SET_DISABLED_OPTIONS, true);
    let line = JSON.parse(JSON.stringify(deliveryFormDetail));
    JSON.parse(JSON.stringify(index));
    line.id = 0;
    switch (position) {
      case "before":
        await dispatch("createDeliveryFormDetail", {
          deliveryFormDetail: { ...line, isDisabled: false },
          position: index,
        });
        await dispatch("reviewIndexDeliveryForm", 1);
        break;
      case "after":
        await dispatch("createDeliveryFormDetail", {
          deliveryFormDetail: { ...line, isDisabled: false },
          position: index + 1,
        });
        await dispatch("reviewIndexDeliveryForm", 1);
        break;
      case "beginning":
        await dispatch("createDeliveryFormDetail", {
          deliveryFormDetail: { ...line, isDisabled: false },
          position: 0,
        });
        await dispatch("reviewIndexDeliveryForm", 1);
        break;
      case "end":
        await dispatch("createDeliveryFormDetail", {
          deliveryFormDetail: { ...line, isDisabled: false },
          position: rootGetters.getDeliveryFormDetails.length,
        });
        await dispatch("reviewIndexDeliveryForm", 1);
        break;
      default:
        await dispatch("createDeliveryFormDetail", {
          deliveryFormDetail: { ...line, isDisabled: false },
          position: index + 1,
        });
        await dispatch("reviewIndexDeliveryForm", 1);
        break;
    }
    commit(DocumentDetailMutations.SET_DISABLED_OPTIONS, false);
  },

  duplicateDeliveryFormDetails(
    { commit, dispatch, rootGetters },
    { deliveryFormId }: { deliveryFormId: number }
  ) {
    return new Promise((resolve, reject) => {
      commit(
        DeliveryFormDetailMutations.SET_IS_LOADING_DELIVERY_FORM_DETAIL_DUPLICATION,
        true
      );
      let request = new URLSearchParams();
      request.append("deliveryFormId", deliveryFormId.toString());
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/DeliveryFormDetail/GetDeliveryFormDetails",
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`,
            },
            params: request,
          }
        )
        .then(async (res) => {
          let newArray = res.data.map((el) => {
            return {
              ...el,
              id: 0,
              referencielTvaId: el.referencielTva ? el.referencielTva.id : null,
              unitId: el.unit ? el.unit.id : 0,
              total: el.outPutQuantity ? calculateTotalDeliveryFormLine(el) : 0,
              titre: el.type == 0 || el.type == 11 ? el.description : null,
            };
          });
          let lastWorkIndex = null;
          for (let i = 0; i < newArray.length; i++) {
            const line = newArray[i];
            if (
              line.type == 14 ||
              line.type == 15 ||
              line.type == 16 ||
              line.type == 17 ||
              line.type == 18
            )
              line.parentId = lastWorkIndex;
            await dispatch("createDeliveryFormDetail", {
              deliveryFormDetail: line,
            }).then((res) => {
              if (line.type == 6) lastWorkIndex = res.id;
            });
          }
          commit(
            DeliveryFormDetailMutations.SET_IS_LOADING_DELIVERY_FORM_DETAIL_DUPLICATION,
            false
          );
          commit(
            DeliveryFormDetailMutations.SET_REQUEST_STATE_DELIVERY_FORM_DETAIL,
            res
          );
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DeliveryFormDetailMutations.SET_IS_LOADING_DELIVERY_FORM_DETAIL_DUPLICATION,
            false
          );
          commit(
            DeliveryFormDetailMutations.SET_REQUEST_STATE_DELIVERY_FORM_DETAIL,
            err
          );
          reject(false);
        });
    });
  },
};
