import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import RootState from "@/store/modules/rootState"
import { IOrderFormCustomerHistoryState } from "./states";
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

export const state: IOrderFormCustomerHistoryState = {
    orderFormCustomerHistoriesList         : [],
    orderFormCustomerHistory               : {},
    isLoadingOrderFormCustomerHistoriesList         : false,
    isLoadingOrderFormCustomerHistory               : false,
    requestStateOrderFormCustomerHistory            : <IRequestState>{},
}

export const OrderFormCustomerHistoryModule: Module<IOrderFormCustomerHistoryState, RootState> = {
  state, 
  getters,
  mutations,
  actions
}