<template>
  <div id="app" class="h-100" :class="[skinClasses]" ref="parentApp">
    <div
      id="loading-bg"
      :class="
        isGlobalGenerationLoading
          ? 'loading-bg-generation'
          : isGlobalLoading
          ? 'loading-bg'
          : ''
      "
      v-if="isGlobalLoading || isGlobalGenerationLoading"
    >
      <div class="loader">
        <div class="outer"></div>
        <div class="middle"></div>
        <div class="inner"></div>
        <h4 v-if="isGlobalGenerationLoading" style="margin-top: 8rem">
          {{ isGlobalMessage }}
        </h4>
      </div>
    </div>
    <component
      v-if="!isGlobalLoading"
      v-show="!isGlobalGenerationLoading"
      :is="layout"
    >
      <!-- <keep-alive> -->
      <router-view />
      <!-- </keep-alive> -->
    </component>
    <tooltip />
    <!-- <vue-element-loading background-color="rgba(24, 54, 109, .9)" color="#f1f1f1" :active="true" :is-full-screen="true" :text="'Chargement en cours'"/> -->
    <!-- isGlobalLoading restore-watch  :key="$route.fullPath"  -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { provideToast } from "vue-toastification/composition";
import { watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";

import { useWindowSize, useCssVar } from "@vueuse/core";

import store from "@/store";

import VueElementLoading from "vue-element-loading";

const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () =>
  import("@/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");
//import the loadCldr from ej2-base
// import { loadCldr} from '@syncfusion/ej2-base';

// loadCldr(
//    require('cldr-data/supplemental/numberingSystems.json'),
//    require('cldr-data/main/fr-CH/ca-gregorian.json'),
//    require('cldr-data/main/fr-CH/numbers.json'),
//    require('cldr-data/main/fr-CH/timeZoneNames.json')
//  );

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    VueElementLoading,
  },
  computed: {
    ...mapGetters([
      "workspaceSelected",
      "isGlobalLoading",
      "isGlobalCreating",
      "isGlobalMessage",
    ]),
    isGlobalGenerationLoading() {
      return this.isGlobalCreating;
    },
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
  },
  created() {
    this.initializeApp();
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();
    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: true,
      icon: false,
      timeout: 4000,
      transition: "Vue-Toastification__fade",
    });

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });

    return {
      skinClasses,
    };
  },
  methods: {
    ...mapActions(["initializeApp"]),
  },
  data() {
    return {
      customLanguage: {
        tab: {
          untitled: "Onglet",
        },

        contextmenu: {
          refresh: "Actualiser l'onglet",
          refreshAll: "Actualiser tout",
          close: "Fermer",
          closeLefts: "Fermer les onglets à gauche",
          closeRights: "Fermer les onglets à droite",
          closeOthers: "Fermer tout",
        },

        msg: {
          keepLastTab: "Keep at least 1 tab",
        },
      },
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-material.css";
@import "~@syncfusion/ej2-base/styles/material.css";
@import "~@syncfusion/ej2-vue-navigations/styles/material.css";

.ag-theme-material {
  font-family: "Montserrat", Helvetica, Arial, serif !important;
  font-weight: 400 !important;

  .ag-header {
    // or write CSS selectors to make customisations beyond what the parameters support
    font-style: italic;
    background-color: #ffffff !important;
    border-bottom-width: 2px;
  }
  .ag-header-cell-center .ag-header-cell-label {
    justify-content: center !important;
  }
  .ag-row {
    background-color: #eef1f650;
  }
  .ag-row:nth-child(2n) {
    background-color: #ffffff;
  }
  .ag-row:hover {
    background-color: #eef1f6;
  }
  .ag-cell,
  .ag-header-cell,
  .ag-header-group-cell {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .ag-cell-focus,
  .ag-cell {
    border: none !important;
  }
  .ag-paging-panel {
    border-top: 1px solid #e2e2e2;
    border-bottom: 1px solid #e2e2e2;
  }
  .ag-tool-panel-wrapper {
    -webkit-box-shadow: -6px 0px 13px -7px rgb(0 0 0 / 57%);
    box-shadow: -6px 0px 13px -7px rgb(0 0 0 / 57%);
  }
  .ag-checkbox-input-wrapper.ag-checked::after {
    color: #0c3571 !important;
  }
  &.ag-archived {
    .ag-row {
      background-color: #f6f6f6 !important;
      color: #82868b !important;
      font-style: italic !important;
    }
    .ag-row:nth-child(2n) {
      background-color: #f8f8f8 !important;
    }
    .ag-row:hover {
      background-color: #eef1f6 !important;
    }
  }
  .ag-root:not(.ag-context-menu-open)
    .ag-body-viewport:not(.ag-has-focus)
    .ag-cell-range-selected-1:not(.ag-cell-inline-editing) {
    background: transparent !important;
  }
}
</style>
<style lang="scss" scoped>
::v-deep {
  // Page fade transition
  .page-fade {
    &-enter-active,
    &-leave-active {
      transition: opacity 0.5s;
    }

    &-enter,
    &-leave-to {
      opacity: 0;
    }
  }

  // Tab scale transition
  .tab-scale {
    &-enter-active,
    &-leave-active {
      transition: opacity 0.5s, transform 0.5s;
    }

    &-enter,
    &-leave-to {
      opacity: 0;
      transform: scale(1.5);
    }
  }
}
</style>
<style>
@import "assets/bootstrap5-orisis/compatibility/bootstrap5.css";
</style>
