import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IInvoiceOptionDefaultState } from "./states";
import { InvoiceOptionRequestDto } from "@/../Api";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export const getters: GetterTree<IInvoiceOptionDefaultState, RootState> = {
  invoiceOptionDefault: (state): InvoiceOptionRequestDto =>
    state.invoiceOptionDefault,
  invoiceOptionDefaultForm: (state): InvoiceOptionRequestDto =>
    state.invoiceOptionDefaultForm,
  isLoadingInvoiceOptionDefault: (state): boolean =>
    state.isLoadingInvoiceOptionDefault,
  requestStateInvoiceOptionDefault: (state): IRequestState =>
    state.requestStateInvoiceOptionDefault,
};
