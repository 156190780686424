import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { DeliveryFormRequestDto } from "@/../Api";
import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IDeliveryFormState } from "./states";
import { IPaymentMethod } from "@/types/api-orisis/interfaces/invoices/paymentMethod/IPaymentMethod";
import { IFilters } from "@/types/api-orisis/interfaces/invoices/filters/IFilters";

export const getters: GetterTree<IDeliveryFormState, RootState> = {
  deliveryFormsList: (state): DeliveryFormRequestDto[] =>
    state.deliveryFormsList,
  filteredDeliveryFormsList: (state): DeliveryFormRequestDto[] =>
    state.deliveryFormsList,
  deliveryFormsListArchived: (state): DeliveryFormRequestDto[] =>
    state.deliveryFormsListArchived,
  filteredDeliveryFormsListArchived: (state): DeliveryFormRequestDto[] =>
    state.deliveryFormsListArchived,
  deliveryForm: (state): DeliveryFormRequestDto => state.deliveryForm,
  deliveryFormPaymentMethods: (state): IPaymentMethod[] =>
    state.deliveryFormPaymentMethods,
  deliveryFormFilters: (state): IFilters => state.deliveryFormFilters,
  deliveryFormPDF: (state): string => state.deliveryFormPDF,
  deliveryFormArchived: (state): DeliveryFormRequestDto =>
    state.deliveryFormArchived,
  initialDeliveryForm: (state): DeliveryFormRequestDto =>
    state.initialDeliveryForm,
  deliveryFormsListByAffair: (state): DeliveryFormRequestDto[] =>
    state.deliveryFormsListByAffair,
  deliveryFormsListByCompany: (state): DeliveryFormRequestDto[] =>
  state.deliveryFormsListByCompany,  

  // COMMON
  isValidatingDeliveryForm            : (state): boolean => state.isValidatingDeliveryForm,
  isLoadingDeliveryFormsList: (state): boolean =>
    state.isLoadingDeliveryFormsList,
  isLoadingPaymentMethodsDeliveryForm: (state): boolean =>
    state.isLoadingPaymentMethodsDeliveryForm,
  isLoadingDeliveryFormsListArchived: (state): boolean =>
    state.isLoadingDeliveryFormsListArchived,
  isLoadingDeliveryForm: (state): boolean => state.isLoadingDeliveryForm,
  isLoadingDeliveryFormPDF: (state): boolean => state.isLoadingDeliveryFormPDF,
  isLoadingDuplicatingDeliveryForm: (state): boolean =>
    state.isLoadingDuplicatingDeliveryForm,
  isLoadingDeliveryFormArchived: (state): boolean =>
    state.isLoadingDeliveryFormArchived,
  isCreatingDeliveryForm: (state): boolean => state.isCreatingDeliveryForm,
  isUpdatingDeliveryForm: (state): boolean => state.isUpdatingDeliveryForm,
  isRestoringDeliveryForm: (state): boolean => state.isRestoringDeliveryForm,
  isDeletingDeliveryForm: (state): boolean => state.isDeletingDeliveryForm,
  isArchivingDeliveryForm: (state): boolean => state.isArchivingDeliveryForm,
  requestStateDeliveryForm: (state): IRequestState =>
    state.requestStateDeliveryForm,
  requestStateDeliveryFormPDF: (state): IRequestState =>
    state.requestStateDeliveryFormPDF,
  deliveryFormTabActive: (state): String => state.deliveryFormTabActive,
  isLoadingDeliveryFormsListByAffair: (state): boolean =>
    state.isLoadingDeliveryFormsListByAffair,
  isLoadingDeliveryFormsListByCompany: (state): boolean =>
  state.isLoadingDeliveryFormsListByCompany,  
};
