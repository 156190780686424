<template>
  <router-tab
    :lang="customLanguage"
    :restore="'true'"
    page-scroller=".b-grid-horizontal-scroller"
    :default-page="homePage"
  />
</template>

<script>
export default {
  data() {
    return {
      customLanguage: {
        tab: {
          untitled: "Page",
        },

        contextmenu: {
          refresh: "Actualiser l'onglet",
          refreshAll: "Actualiser tout",
          close: "Fermer",
          closeLefts: "Fermer les onglets à gauche",
          closeRights: "Fermer les onglets à droite",
          closeOthers: "Fermer tout",
        },

        msg: {
          keepLastTab: "Keep at least 1 tab",
        },
      },
    };
  },
  computed: {
    homePage() {
      return localStorage.getItem("homePage") || "/";
    },
  },
  // mounted() {
  //   // Créer une instance de votre composant Vue
  //   const ComponentClass = Vue.extend(Banner);
  //   const componentInstance = new ComponentClass();
  //   // Montez le composant sur un élément HTML
  //   const elementToInsert = document.createElement("div");
  //   componentInstance.$mount(elementToInsert);

  //   // Obtenez l'élément après lequel vous souhaitez insérer le composant
  //   const routerTabHeader =
  //     document.getElementsByClassName("router-tab__header")[0];

  //   // Insérez le composant monté dans le DOM après l'élément spécifié
  //   routerTabHeader.parentNode.insertBefore(
  //     componentInstance.$el,
  //     routerTabHeader.nextSibling
  //   );
  // },
};
</script>
