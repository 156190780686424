import { ICurrencyState } from "./states";
// import { CurrencyRequestDto } from "@/../Api";
import { Module } from "vuex";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import RootState from "@/store/modules/rootState";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

const state: ICurrencyState = {
  currenciesList: Array<any>(),
  // currenciesList: Array<CurrencyRequestDto>(),
  currenciesListArchived: Array<any>(),
  // currenciesListArchived: Array<CurrencyRequestDto>(),
  currency: <any>{},
  // currency: <CurrencyRequestDto>{},
  isLoadingCurrenciesList: false,
  isLoadingCurrenciesListArchived: false,
  isLoadingCurrency: false,
  isCreatingCurrency: false,
  isUpdatingCurrency: false,
  isDeletingCurrency: false,
  isArchivingCurrency: false,
  requestStateCurrency: <IRequestState>{},
};

export const CurrencyModule: Module<ICurrencyState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
