import { IAffairState } from "./states";
import { Module } from "vuex";
import { AffairRequestDto } from "@/../Api";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import RootState from "@/store/modules/rootState";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

const state: IAffairState = {
  affairsList: Array<AffairRequestDto>(),
  affairsListArchived: Array<AffairRequestDto>(),
  affairsListByCompany: Array<AffairRequestDto>(),
  affairsListArchivedByCompany: Array<AffairRequestDto>(),
  affair: <AffairRequestDto>{},
  isLoadingAffairsList: false,
  isLoadingAffairsListByCompany: false,
  isLoadingAffairsListArchived: false,
  isLoadingAffairsListArchivedByCompany: false,
  isLoadingAffair: false,
  isCreatingAffair: false,
  isUpdatingAffair: false,
  isDeletingAffair: false,
  isArchivingAffair: false,
  requestStateAffair: <IRequestState>{},
};

export const AffairModule: Module<IAffairState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
