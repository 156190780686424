import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { IFiscalYear } from "@/types/api-orisis/interfaces/invoices/fiscalYear/IFiscalYear";
import { GetterTree } from "vuex";
import RootState from '@/store/modules/rootState'
import { IFiscalYearState } from "./states";

export const getters: GetterTree<IFiscalYearState, RootState> = {
    fiscalYearList             : (state): IFiscalYear[] => state.fiscalYearList,
    fiscalYear                 : (state): IFiscalYear => state.fiscalYear,
    
    // COMMON
    isLoadingFiscalYearList    : (state): boolean => state.isLoadingFiscalYear,
    isLoadingFiscalYear        : (state): boolean => state.isLoadingFiscalYear,
    isCreatingFiscalYear       : (state): boolean => state.isCreatingFiscalYear,
    isUpdatingFiscalYear       : (state): boolean => state.isUpdatingFiscalYear,
    isDeletingFiscalYear       : (state): boolean => state.isDeletingFiscalYear,
    isArchivingFiscalYear      : (state): boolean => state.isArchivingFiscalYear,
    requestStateFiscalYear     : (state): IRequestState => state.requestStateFiscalYear,
}