import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { OrderFormOptionDefaultMutations } from "./mutations";
import { IOrderFormOptionDefaultState } from "./states";
import { OrderFormOptionRequestDto } from "Api";

export const actions: ActionTree<IOrderFormOptionDefaultState, RootState> = {
  getOrderFormOptionDefaultByOrderFormId(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          OrderFormOptionDefaultMutations.SET_IS_LOADING_ORDER_FORM_OPTION_DEFAULT,
          true
        );
      }
      const url = `${process.env.VUE_APP_API_ORISIS}Sale/OrderFormOptionDefault/GetDefault`;
      axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              OrderFormOptionDefaultMutations.SET_ORDER_FORM_OPTION_DEFAULT,
              res.data
            );
            commit(
              OrderFormOptionDefaultMutations.SET_ORDER_FORM_OPTION_DEFAULT_FORM,
              res.data
            );
            commit(
              OrderFormOptionDefaultMutations.SET_IS_LOADING_ORDER_FORM_OPTION_DEFAULT,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              OrderFormOptionDefaultMutations.SET_IS_LOADING_ORDER_FORM_OPTION_DEFAULT,
              false
            );
          }
          reject(err);
        });
    });
  },

  createOrderFormOptionDefault(
    { commit },
    {
      orderFormOptionDefault,
      updateState = true,
    }: {
      orderFormOptionDefault: OrderFormOptionRequestDto;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          OrderFormOptionDefaultMutations.SET_IS_LOADING_ORDER_FORM_OPTION_DEFAULT,
          true
        );
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/OrderFormOptionDefault/CreateOrderFormOptionDefault`;

      axios
        .post(url, orderFormOptionDefault)
        .then((res) => {
          if (updateState) {
            commit(
              OrderFormOptionDefaultMutations.SET_ORDER_FORM_OPTION_DEFAULT,
              res.data
            );
            commit(
              OrderFormOptionDefaultMutations.SET_IS_LOADING_ORDER_FORM_OPTION_DEFAULT,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              OrderFormOptionDefaultMutations.SET_IS_LOADING_ORDER_FORM_OPTION_DEFAULT,
              false
            );
          }
          reject(err);
        });
    });
  },

  updateOrderFormOptionDefault(
    { commit },
    {
      orderFormOptionDefault,
      updateState = true,
    }: {
      orderFormOptionDefault: OrderFormOptionRequestDto;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          OrderFormOptionDefaultMutations.SET_IS_LOADING_ORDER_FORM_OPTION_DEFAULT,
          true
        );
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/OrderFormOptionDefault/UpdateOrderFormOptionDefault`;

      axios
        .put(url, orderFormOptionDefault)
        .then((res) => {
          if (updateState) {
            commit(
              OrderFormOptionDefaultMutations.SET_ORDER_FORM_OPTION_DEFAULT,
              res.data
            );
            commit(
              OrderFormOptionDefaultMutations.SET_IS_LOADING_ORDER_FORM_OPTION_DEFAULT,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              OrderFormOptionDefaultMutations.SET_IS_LOADING_ORDER_FORM_OPTION_DEFAULT,
              false
            );
          }
          reject(err);
        });
    });
  },
};
