import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { IPayment } from "@/types/api-orisis/interfaces/invoices/payment/IPayment";
import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IPaymentState } from "./states";
import { IFilters } from "@/types/api-orisis/interfaces/invoices/filters/IFilters";

export const getters: GetterTree<IPaymentState, RootState> = {
  paymentsList: (state): IPayment[] => state.paymentsList,
  paymentsListByInvoice: (state): IPayment[] => state.paymentsListByInvoice,
  paymentsListByCredit: (state): IPayment[] => state.paymentsListByCredit,
  paymentsListByBankAccount: (state): IPayment[] => state.paymentsListByBankAccount,
  paymentsListArchived: (state): IPayment[] => state.paymentsListArchived,
  payment: (state): IPayment => state.payment,

  // COMMON
  isLoadingPaymentsList: (state): boolean => state.isLoadingPaymentsList,
  isLoadingPaymentsListByInvoice: (state): boolean => state.isLoadingPaymentsListByInvoice,
  isLoadingPaymentsListByCredit: (state): boolean => state.isLoadingPaymentsListByCredit,
  isLoadingPaymentsListByBankAccount: (state): boolean => state.isLoadingPaymentsListByBankAccount,
  isLoadingPaymentsListArchived: (state): boolean =>
    state.isLoadingPaymentsListArchived,
  isLoadingPayment: (state): boolean => state.isLoadingPayment,
  isCreatingPayment: (state): boolean => state.isCreatingPayment,
  isUpdatingPayment: (state): boolean => state.isUpdatingPayment,
  isDeletingPayment: (state): boolean => state.isDeletingPayment,
  isArchivingPayment: (state): boolean => state.isArchivingPayment,
  isLinkingPayment: (state): boolean => state.isLinkingPayment,
  isUnlinkingPayment: (state): boolean => state.isUnlinkingPayment,
  requestStatePayment: (state): IRequestState => state.requestStatePayment,
  paymentFilters: (state): IFilters => state.paymentFilters,
};
