import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { ContractRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IContractState } from "./states";

export enum ContractMutations {
    SET_CONTRACTS_LIST = "SET_CONTRACTS_LIST",
    SET_CONTRACTS_LIST_ARCHIVED = "SET_CONTRACTS_LIST_ARCHIVED",
    SET_CONTRACT = "SET_CONTRACT",

    // COMMON
    SET_IS_LOADING_CONTRACT_LIST = "SET_IS_LOADING_CONTRACT_LIST",
    SET_IS_LOADING_CONTRACT_LIST_ARCHIVED = "SET_IS_LOADING_CONTRACT_LIST_ARCHIVED",
    SET_IS_LOADING_CONTRACT = "SET_IS_LOADING_CONTRACT",
    SET_IS_CREATING_CONTRACT = "SET_IS_CREATING_CONTRACT",
    SET_IS_UPDATING_CONTRACT = "SET_IS_UPDATING_CONTRACT",
    SET_IS_DELETING_CONTRACT = "SET_IS_DELETING_CONTRACT",
    SET_IS_ARCHIVING_CONTRACT = "SET_IS_ARCHIVING_CONTRACT",
    SET_REQUEST_STATE_CONTRACT = "SET_REQUEST_STATE_CONTRACT",
}

export const mutations: MutationTree<IContractState> = {
    [ContractMutations.SET_CONTRACTS_LIST_ARCHIVED]: (state, payload: ContractRequestDto[]) => {
        state.contractsListArchived = payload;
    }, 
    [ContractMutations.SET_CONTRACTS_LIST]: (state, payload: ContractRequestDto[]) => {
        state.contractsList = payload;
    },
    [ContractMutations.SET_CONTRACT]: (state, payload: ContractRequestDto) => {
        state.contract = payload;
    },


    //COMMON
    [ContractMutations.SET_IS_LOADING_CONTRACT_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingContractsListArchived = payload;
    },
    [ContractMutations.SET_IS_LOADING_CONTRACT_LIST]: (state, payload: boolean) => {
        state.isLoadingContractsList = payload;
    },
    [ContractMutations.SET_IS_LOADING_CONTRACT]: (state, payload: boolean) => {
        state.isLoadingContract = payload;
    },
    [ContractMutations.SET_IS_CREATING_CONTRACT]: (state, payload: boolean) => {
        state.isCreatingContract = payload;
    },
    [ContractMutations.SET_IS_UPDATING_CONTRACT]: (state, payload: boolean) => {
        state.isUpdatingContract = payload;
    },
    [ContractMutations.SET_IS_DELETING_CONTRACT]: (state, payload: boolean) => {
        state.isDeletingContract = payload;
    },
    [ContractMutations.SET_IS_ARCHIVING_CONTRACT]: (state, payload: boolean) => {
        state.isArchivingContract = payload;
    },
    [ContractMutations.SET_REQUEST_STATE_CONTRACT]: (state, payload: IRequestState) => {
        state.requestStateContract = payload;
    },
}