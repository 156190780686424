import { ActivitySourceTypeRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { ActivitySourceTypeMutations } from "./mutations";
import { IActivitySourceTypeState } from "./states";

export const actions: ActionTree<IActivitySourceTypeState, RootState> = {
  async getActivitySourceTypes(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          ActivitySourceTypeMutations.SET_IS_LOADING_ACTIVITY_SOURCE_TYPE_LIST,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/ActivitySourceType/GetActivitySourceTypes`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              ActivitySourceTypeMutations.SET_IS_LOADING_ACTIVITY_SOURCE_TYPE_LIST,
              false
            );
            commit(
              ActivitySourceTypeMutations.SET_ACTIVITY_SOURCE_TYPES_LIST,
              res.data
            );
          }
          commit(
            ActivitySourceTypeMutations.SET_REQUEST_STATE_ACTIVITY_SOURCE_TYPE,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivitySourceTypeMutations.SET_IS_LOADING_ACTIVITY_SOURCE_TYPE_LIST,
            false
          );
          commit(
            ActivitySourceTypeMutations.SET_REQUEST_STATE_ACTIVITY_SOURCE_TYPE,
            err
          );
          reject(err);
        });
    });
  },

  async getActivitySourceTypeById(
    { commit },
    {
      activitySourceTypeId,
      updateState = true,
    }: { activitySourceTypeId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          ActivitySourceTypeMutations.SET_IS_LOADING_ACTIVITY_SOURCE_TYPE,
          true
        );
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Directory/ActivitySourceType/GetActivitySourceTypeById`,
          {
            params: {
              activitySourceTypeId: activitySourceTypeId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(
              ActivitySourceTypeMutations.SET_ACTIVITY_SOURCE_TYPE,
              res.data
            );
            commit(
              ActivitySourceTypeMutations.SET_IS_LOADING_ACTIVITY_SOURCE_TYPE,
              false
            );
          }
          commit(
            ActivitySourceTypeMutations.SET_REQUEST_STATE_ACTIVITY_SOURCE_TYPE,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivitySourceTypeMutations.SET_REQUEST_STATE_ACTIVITY_SOURCE_TYPE,
            err
          );
          commit(
            ActivitySourceTypeMutations.SET_IS_LOADING_ACTIVITY_SOURCE_TYPE,
            false
          );
          reject(err);
        });
    });
  },

  async getActivitySourceTypesArchived(
    { commit },
    {
      activitySourceTypeId,
      updateState = true,
    }: { activitySourceTypeId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          ActivitySourceTypeMutations.SET_IS_LOADING_ACTIVITY_SOURCE_TYPE,
          true
        );
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Directory/ActivitySourceType/GetActivitySourceTypesArchived`,
          {
            params: {
              activitySourceTypeId: activitySourceTypeId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(
              ActivitySourceTypeMutations.SET_ACTIVITY_SOURCE_TYPES_LIST_ARCHIVED,
              res.data
            );
            commit(
              ActivitySourceTypeMutations.SET_IS_LOADING_ACTIVITY_SOURCE_TYPE_LIST_ARCHIVED,
              false
            );
          }
          commit(
            ActivitySourceTypeMutations.SET_REQUEST_STATE_ACTIVITY_SOURCE_TYPE,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivitySourceTypeMutations.SET_REQUEST_STATE_ACTIVITY_SOURCE_TYPE,
            err
          );
          commit(
            ActivitySourceTypeMutations.SET_IS_LOADING_ACTIVITY_SOURCE_TYPE,
            false
          );
          reject(err);
        });
    });
  },

  async getActivitySourceTypeArchivedById(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          ActivitySourceTypeMutations.SET_IS_LOADING_ACTIVITY_SOURCE_TYPE_LIST_ARCHIVED,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/ActivitySourceType/GetActivitySourceTypesArchivedById`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              ActivitySourceTypeMutations.SET_IS_LOADING_ACTIVITY_SOURCE_TYPE_LIST_ARCHIVED,
              false
            );
            commit(
              ActivitySourceTypeMutations.SET_ACTIVITY_SOURCE_TYPES_LIST_ARCHIVED,
              res.data
            );
          }
          commit(
            ActivitySourceTypeMutations.SET_REQUEST_STATE_ACTIVITY_SOURCE_TYPE,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivitySourceTypeMutations.SET_IS_LOADING_ACTIVITY_SOURCE_TYPE_LIST,
            false
          );
          commit(
            ActivitySourceTypeMutations.SET_REQUEST_STATE_ACTIVITY_SOURCE_TYPE,
            err
          );
          reject(err);
        });
    });
  },

  async createActivitySourceType(
    { commit, rootGetters },
    { activitySourceType }: { activitySourceType: ActivitySourceTypeRequestDto }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        ActivitySourceTypeMutations.SET_IS_CREATING_ACTIVITY_SOURCE_TYPE,
        true
      );
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Directory/ActivitySourceType/CreateActivitySourceType`,
          activitySourceType
        )
        .then((res) => {
          rootGetters.activitySourceTypesList.push(res.data.data);
          commit(
            ActivitySourceTypeMutations.SET_ACTIVITY_SOURCE_TYPES_LIST,
            rootGetters.activitySourceTypesList
          );
          commit(
            ActivitySourceTypeMutations.SET_REQUEST_STATE_ACTIVITY_SOURCE_TYPE,
            res
          );
          commit(
            ActivitySourceTypeMutations.SET_IS_CREATING_ACTIVITY_SOURCE_TYPE,
            false
          );
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivitySourceTypeMutations.SET_REQUEST_STATE_ACTIVITY_SOURCE_TYPE,
            err
          );
          commit(
            ActivitySourceTypeMutations.SET_IS_CREATING_ACTIVITY_SOURCE_TYPE,
            false
          );
          reject(err);
        });
    });
  },

  async updateActivitySourceType(
    { commit, rootGetters },
    { activitySourceType }: { activitySourceType: ActivitySourceTypeRequestDto }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        ActivitySourceTypeMutations.SET_IS_UPDATING_ACTIVITY_SOURCE_TYPE,
        true
      );
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Directory/ActivitySourceType/UpdateActivitySourceType`,
          activitySourceType
        )
        .then((res) => {
          if (
            rootGetters.activitySourceTypesList.find(
              (elem) => elem.id == activitySourceType.id
            )
          ) {
            rootGetters.activitySourceTypesList.splice(
              rootGetters.activitySourceTypesList.findIndex(
                (elem) => elem.id == activitySourceType.id
              ),
              1,
              activitySourceType
            );
            commit(
              ActivitySourceTypeMutations.SET_ACTIVITY_SOURCE_TYPES_LIST,
              rootGetters.activitySourceTypesList
            );
          }

          if (
            rootGetters.activitySourceTypesListArchived.find(
              (elem) => elem.id == activitySourceType.id
            )
          ) {
            rootGetters.activitySourceTypesListArchived.splice(
              rootGetters.activitySourceTypesListArchived.findIndex(
                (elem) => elem.id == activitySourceType.id
              ),
              1,
              activitySourceType
            );
            commit(
              ActivitySourceTypeMutations.SET_ACTIVITY_SOURCE_TYPES_LIST_ARCHIVED,
              rootGetters.activitySourceTypesListArchived
            );
          }

          commit(
            ActivitySourceTypeMutations.SET_REQUEST_STATE_ACTIVITY_SOURCE_TYPE,
            res
          );
          commit(
            ActivitySourceTypeMutations.SET_IS_UPDATING_ACTIVITY_SOURCE_TYPE,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivitySourceTypeMutations.SET_REQUEST_STATE_ACTIVITY_SOURCE_TYPE,
            err
          );
          commit(
            ActivitySourceTypeMutations.SET_IS_UPDATING_ACTIVITY_SOURCE_TYPE,
            false
          );
          reject(err);
        });
    });
  },

  async restoreActivitySourceTypes(
    { commit, rootGetters },
    { activitySourceTypeIds = [] }: { activitySourceTypeIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        ActivitySourceTypeMutations.SET_IS_UPDATING_ACTIVITY_SOURCE_TYPE,
        true
      );
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Directory/ActivitySourceType/Restore",
          activitySourceTypeIds
        )
        .then(async (res) => {
          activitySourceTypeIds.forEach((activitySourceTypeId: number) => {
            let activitySourceType =
              rootGetters.activitySourceTypesListArchived.find(
                (activitySourceType) =>
                  activitySourceType.id == activitySourceTypeId
              );
            rootGetters.activitySourceTypesList.unshift(activitySourceType);

            if (
              rootGetters.activitySourceTypesListArchived.find(
                (activitySourceType) =>
                  activitySourceType.id == activitySourceTypeId
              )
            ) {
              rootGetters.activitySourceTypesListArchived.splice(
                rootGetters.activitySourceTypesListArchived.findIndex(
                  (activitySourceType) =>
                    activitySourceType.id == activitySourceTypeId
                ),
                1
              );
            }
          });
          commit(
            ActivitySourceTypeMutations.SET_ACTIVITY_SOURCE_TYPES_LIST,
            rootGetters.activitySourceTypesList
          );
          commit(
            ActivitySourceTypeMutations.SET_ACTIVITY_SOURCE_TYPES_LIST_ARCHIVED,
            rootGetters.activitySourceTypesListArchived
          );
          commit(
            ActivitySourceTypeMutations.SET_REQUEST_STATE_ACTIVITY_SOURCE_TYPE,
            res
          );
          commit(
            ActivitySourceTypeMutations.SET_IS_UPDATING_ACTIVITY_SOURCE_TYPE,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivitySourceTypeMutations.SET_REQUEST_STATE_ACTIVITY_SOURCE_TYPE,
            err
          );
          commit(
            ActivitySourceTypeMutations.SET_IS_UPDATING_ACTIVITY_SOURCE_TYPE,
            false
          );
          reject(err);
        });
    });
  },

  async archiveActivitySourceTypes(
    { commit, rootGetters },
    { activitySourceTypeIds = [] }: { activitySourceTypeIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        ActivitySourceTypeMutations.SET_IS_ARCHIVING_ACTIVITY_SOURCE_TYPE,
        true
      );
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS +
            "Directory/ActivitySourceType/Archive",
          {
            data: activitySourceTypeIds,
          }
        )
        .then((res) => {
          activitySourceTypeIds.forEach((activitySourceTypeId: number) => {
            let activitySourceType = rootGetters.activitySourceTypesList.find(
              (activitySourceType) =>
                activitySourceType.id == activitySourceTypeId
            );
            rootGetters.activitySourceTypesListArchived.unshift(
              activitySourceType
            );

            if (
              rootGetters.activitySourceTypesList.find(
                (activitySourceType) =>
                  activitySourceType.id == activitySourceTypeId
              )
            ) {
              rootGetters.activitySourceTypesList.splice(
                rootGetters.activitySourceTypesList.findIndex(
                  (activitySourceType) =>
                    activitySourceType.id == activitySourceTypeId
                ),
                1
              );
            }
          });
          commit(
            ActivitySourceTypeMutations.SET_ACTIVITY_SOURCE_TYPES_LIST,
            rootGetters.activitySourceTypesList
          );
          commit(
            ActivitySourceTypeMutations.SET_ACTIVITY_SOURCE_TYPES_LIST_ARCHIVED,
            rootGetters.activitySourceTypesListArchived
          );
          commit(
            ActivitySourceTypeMutations.SET_REQUEST_STATE_ACTIVITY_SOURCE_TYPE,
            res
          );
          commit(
            ActivitySourceTypeMutations.SET_IS_ARCHIVING_ACTIVITY_SOURCE_TYPE,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivitySourceTypeMutations.SET_REQUEST_STATE_ACTIVITY_SOURCE_TYPE,
            err
          );
          commit(
            ActivitySourceTypeMutations.SET_IS_ARCHIVING_ACTIVITY_SOURCE_TYPE,
            false
          );
          reject(err);
        });
    });
  },

  async deleteActivitySourceTypes(
    { commit, rootGetters },
    { activitySourceTypeIds = [] }: { activitySourceTypeIds: number[] }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        ActivitySourceTypeMutations.SET_IS_DELETING_ACTIVITY_SOURCE_TYPE,
        true
      );
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS +
            "Directory/ActivitySourceType/Delete",
          {
            data: activitySourceTypeIds,
          }
        )
        .then((res) => {
          activitySourceTypeIds.forEach((activitySourceTypeId: number) => {
            if (
              rootGetters.activitySourceTypesList.find(
                (activitySourceType) =>
                  activitySourceType.id == activitySourceTypeId
              )
            ) {
              rootGetters.activitySourceTypesList.splice(
                rootGetters.activitySourceTypesList.findIndex(
                  (activitySourceType) =>
                    activitySourceType.id == activitySourceTypeId
                ),
                1
              );
            }

            if (
              rootGetters.activitySourceTypesListArchived.find(
                (activitySourceType) =>
                  activitySourceType.id == activitySourceTypeId
              )
            ) {
              rootGetters.activitySourceTypesListArchived.splice(
                rootGetters.activitySourceTypesListArchived.findIndex(
                  (activitySourceType) =>
                    activitySourceType.id == activitySourceTypeId
                ),
                1
              );
            }
          });
          commit(
            ActivitySourceTypeMutations.SET_ACTIVITY_SOURCE_TYPES_LIST,
            rootGetters.activitySourceTypesList
          );
          commit(
            ActivitySourceTypeMutations.SET_ACTIVITY_SOURCE_TYPES_LIST_ARCHIVED,
            rootGetters.activitySourceTypesListArchived
          );

          commit(
            ActivitySourceTypeMutations.SET_REQUEST_STATE_ACTIVITY_SOURCE_TYPE,
            res
          );
          commit(
            ActivitySourceTypeMutations.SET_IS_DELETING_ACTIVITY_SOURCE_TYPE,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivitySourceTypeMutations.SET_REQUEST_STATE_ACTIVITY_SOURCE_TYPE,
            err
          );
          commit(
            ActivitySourceTypeMutations.SET_IS_DELETING_ACTIVITY_SOURCE_TYPE,
            false
          );
          reject(err);
        });
    });
  },
};
