import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { GetterTree } from "vuex";
import RootState from '@/store/modules/rootState'
import {  IOrderFormCustomerHistoryState } from "./states";

export const getters: GetterTree<IOrderFormCustomerHistoryState, RootState> = {
    orderFormCustomerHistoriesList: (state): [] => state.orderFormCustomerHistoriesList,
    orderFormCustomerHistory     : (state): {} => state.orderFormCustomerHistory,
    
    // COMMON
    isLoadingOrderFormCustomerHistoriesList   : (state): boolean => state.isLoadingOrderFormCustomerHistoriesList,
    isLoadingOrderFormCustomerHistory : (state): boolean => state.isLoadingOrderFormCustomerHistory,
    requestStateOrderFormCustomerHistory: (state): IRequestState => state.requestStateOrderFormCustomerHistory,
}