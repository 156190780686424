import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { ContactRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { IContactState } from './states'


export const getters: GetterTree<IContactState, RootState> = {
  contactsList: (state): ContactRequestDto[] => state.contactsList,
  contactsListArchived: (state): ContactRequestDto[] => state.contactsListArchived,
  contact     : (state): ContactRequestDto => state.contact,

  // COMMON
  isLoadingContactsList   : (state): boolean => state.isLoadingContactsList,
  isLoadingContactsListArchived   : (state): boolean => state.isLoadingContactsListArchived,
  isLoadingContact   : (state): boolean => state.isLoadingContact,
  isCreatingContact  : (state): boolean => state.isCreatingContact,
  isUpdatingContact  : (state): boolean => state.isUpdatingContact,
  isDeletingContact  : (state): boolean => state.isDeletingContact,
  requestStateContact: (state): IRequestState => state.requestStateContact,
  isArchivingContact : (state): boolean => state.isArchivingContact,
}