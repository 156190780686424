import { PayrollEventRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { PayrollEventMutations } from "./mutations";
import { IPayrollEventState } from "./states";

export const actions: ActionTree<IPayrollEventState, RootState> = {
  async getPayrollEvent(
    { commit },
    {
      payrollEventId,
      updateState = true,
    }: { payrollEventId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(PayrollEventMutations.SET_IS_LOADING_PAYROLL_EVENT, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Reporting/PayrollEvent/GetPayrollEventById`,
          {
            params: {
              payrollEventId: payrollEventId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(PayrollEventMutations.SET_PAYROLL_EVENT, res.data);
            commit(PayrollEventMutations.SET_IS_LOADING_PAYROLL_EVENT, false);
          }
          commit(PayrollEventMutations.SET_REQUEST_STATE_PAYROLL_EVENT, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PayrollEventMutations.SET_REQUEST_STATE_PAYROLL_EVENT, err);
          commit(PayrollEventMutations.SET_IS_LOADING_PAYROLL_EVENT, false);
          reject(err);
        });
    });
  },

  async getPayrollEvents(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(PayrollEventMutations.SET_IS_LOADING_PAYROLL_EVENT_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Reporting/PayrollEvent/GetPayrollEvents`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              PayrollEventMutations.SET_IS_LOADING_PAYROLL_EVENT_LIST,
              false
            );
            commit(PayrollEventMutations.SET_PAYROLL_EVENTS_LIST, res.data);
          }
          commit(PayrollEventMutations.SET_REQUEST_STATE_PAYROLL_EVENT, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            PayrollEventMutations.SET_IS_LOADING_PAYROLL_EVENT_LIST,
            false
          );
          commit(PayrollEventMutations.SET_REQUEST_STATE_PAYROLL_EVENT, err);
          reject(err);
        });
    });
  },

  //GetPayrollEventArchivedById

  //GetPayrollEventArchived

  async createPayrollEvent(
    { commit, rootGetters },
    { payrollEvent }: { payrollEvent: PayrollEventRequestDto }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(PayrollEventMutations.SET_IS_CREATING_PAYROLL_EVENT, true);
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Reporting/PayrollEvent/CreatePayrollEvent`,
          payrollEvent
        )
        .then((res) => {
          rootGetters.payrollEventsList.push(res.data.data);
          commit(
            PayrollEventMutations.SET_PAYROLL_EVENTS_LIST,
            rootGetters.payrollEventsList
          );
          commit(PayrollEventMutations.SET_REQUEST_STATE_PAYROLL_EVENT, res);
          commit(PayrollEventMutations.SET_IS_CREATING_PAYROLL_EVENT, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PayrollEventMutations.SET_REQUEST_STATE_PAYROLL_EVENT, err);
          commit(PayrollEventMutations.SET_IS_CREATING_PAYROLL_EVENT, false);
          reject(err);
        });
    });
  },

  async updatePayrollEvent(
    { commit, rootGetters },
    { payrollEvent }: { payrollEvent: PayrollEventRequestDto }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(PayrollEventMutations.SET_IS_UPDATING_PAYROLL_EVENT, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Reporting/PayrollEvent/UpdatePayrollEvent`,
          payrollEvent
        )
        .then((res) => {
          rootGetters.payrollEventsList.splice(
            rootGetters.payrollEventsList.findIndex(
              (elem) => elem.id == payrollEvent.id
            ),
            1,
            payrollEvent
          );
          commit(
            PayrollEventMutations.SET_PAYROLL_EVENTS_LIST,
            rootGetters.payrollEventsList
          );
          commit(PayrollEventMutations.SET_REQUEST_STATE_PAYROLL_EVENT, res);
          commit(PayrollEventMutations.SET_IS_UPDATING_PAYROLL_EVENT, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PayrollEventMutations.SET_REQUEST_STATE_PAYROLL_EVENT, err);
          commit(PayrollEventMutations.SET_IS_UPDATING_PAYROLL_EVENT, false);
          reject(err);
        });
    });
  },

  async restorePayrollEvents(
    { commit, rootGetters },
    { payrollEventIds = [] }: { payrollEventIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(PayrollEventMutations.SET_IS_UPDATING_PAYROLL_EVENT, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Reporting/PayrollEvent/Restore",
          payrollEventIds
        )
        .then((res) => {
          payrollEventIds.forEach((payrollEventId: number) => {
            rootGetters.payrollEventsListArchived.splice(
              rootGetters.payrollEventsListArchived.findIndex(
                (elem) => elem.id == payrollEventId
              ),
              1
            );
          });
          commit(PayrollEventMutations.SET_REQUEST_STATE_PAYROLL_EVENT, res);
          commit(PayrollEventMutations.SET_IS_UPDATING_PAYROLL_EVENT, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PayrollEventMutations.SET_REQUEST_STATE_PAYROLL_EVENT, err);
          commit(PayrollEventMutations.SET_IS_UPDATING_PAYROLL_EVENT, false);
          reject(err);
        });
    });
  },

  async archivePayrollEvents(
    { commit, rootGetters },
    { payrollEventIds = [] }: { payrollEventIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(PayrollEventMutations.SET_IS_ARCHIVING_PAYROLL_EVENT, true);
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Reporting/PayrollEvent/Archive",
          {
            data: payrollEventIds,
          }
        )
        .then((res) => {
          payrollEventIds.forEach((payrollEventId: number) => {
            rootGetters.payrollEventsListArchived.unshift(
              rootGetters.payrollEventsList.find(
                (elem) => elem.id == payrollEventId
              )
            );
          });
          payrollEventIds.forEach((payrollEventId: number) => {
            rootGetters.payrollEventsListArchived.splice(
              rootGetters.payrollEventsList.findIndex(
                (elem) => elem.id == payrollEventId
              ),
              1
            );
          });
          commit(
            PayrollEventMutations.SET_PAYROLL_EVENTS_LIST,
            rootGetters.payrollEventsList
          );
          commit(PayrollEventMutations.SET_REQUEST_STATE_PAYROLL_EVENT, res);
          commit(PayrollEventMutations.SET_IS_ARCHIVING_PAYROLL_EVENT, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PayrollEventMutations.SET_REQUEST_STATE_PAYROLL_EVENT, err);
          commit(PayrollEventMutations.SET_IS_ARCHIVING_PAYROLL_EVENT, false);
          reject(err);
        });
    });
  },

  async deletePayrollEvents(
    { commit, rootGetters },
    { payrollEventIds = [] }: { payrollEventIds: number[] }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(PayrollEventMutations.SET_IS_DELETING_PAYROLL_EVENT, true);
      await axios
        .delete(
          `${process.env.VUE_APP_API_ORISIS}Reporting/PayrollEvent/Delete`,
          {
            data: payrollEventIds,
          }
        )
        .then((res) => {
          payrollEventIds.forEach((payrollEventId: number) => {
            rootGetters.payrollEventsList.splice(
              rootGetters.payrollEventsList.findIndex(
                (elem) => elem.id == payrollEventId
              ),
              1
            );
          });
          commit(
            PayrollEventMutations.SET_PAYROLL_EVENTS_LIST,
            rootGetters.payrollEventsList
          );
          commit(PayrollEventMutations.SET_REQUEST_STATE_PAYROLL_EVENT, res);
          commit(PayrollEventMutations.SET_IS_DELETING_PAYROLL_EVENT, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PayrollEventMutations.SET_REQUEST_STATE_PAYROLL_EVENT, err);
          commit(PayrollEventMutations.SET_IS_DELETING_PAYROLL_EVENT, false);
          reject(err);
        });
    });
  },
};
