import { IPlanningView } from "@/types/api-orisis/interfaces/Planning/IPlanningViews";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { PlanningViewMutations } from "./mutations";
import { IPlanningViewState } from "./states";
import { PlanningViewRequestDto } from "Api";
var dayjs = require("dayjs");

export const actions: ActionTree<IPlanningViewState, RootState> = {
  async getPlanningViewById(
    { commit },
    {
      planningViewId,
      updateState = true,
    }: { planningViewId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(PlanningViewMutations.SET_IS_LOADING_PLANNING_VIEW, true);
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Plannings/PlanningView/GetPlanningViewById",
          {
            params: {
              planningViewId: planningViewId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(PlanningViewMutations.SET_PLANNING_VIEW, res.data);
            commit(PlanningViewMutations.SET_IS_LOADING_PLANNING_VIEW, false);
          }
          commit(PlanningViewMutations.SET_REQUEST_STATE_PLANNING_VIEW, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PlanningViewMutations.SET_REQUEST_STATE_PLANNING_VIEW, err);
          commit(PlanningViewMutations.SET_IS_LOADING_PLANNING_VIEW, false);
          reject(err);
        });
    });
  },

  async getPlanningViews(
    { commit },
    {
      affairId,
      route = null,
      updateState = true,
    }: { affairId?: number; route?: string | null; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(PlanningViewMutations.SET_IS_LOADING_PLANNING_VIEW_LIST, true);
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Plannings/PlanningView/GetPlanningViews"
        )
        .then((res) => {
          switch (route) {
            case "affair":
              if (updateState) {
                commit(
                  PlanningViewMutations.SET_PLANNING_VIEWS_LIST_BY_AFFAIR,
                  res.data
                );
                commit(
                  PlanningViewMutations.SET_IS_LOADING_PLANNING_VIEW_LIST_BY_AFFAIR,
                  false
                );
              }
            default:
              if (updateState) {
                commit(PlanningViewMutations.SET_PLANNING_VIEWS_LIST, res.data);
                commit(
                  PlanningViewMutations.SET_IS_LOADING_PLANNING_VIEW_LIST,
                  false
                );
              }
          }
          commit(PlanningViewMutations.SET_REQUEST_STATE_PLANNING_VIEW, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PlanningViewMutations.SET_REQUEST_STATE_PLANNING_VIEW, err);
          commit(
            PlanningViewMutations.SET_IS_LOADING_PLANNING_VIEW_LIST,
            false
          );
          reject(err);
        });
    });
  },

  async getPlanningViewsArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          PlanningViewMutations.SET_IS_LOADING_PLANNING_VIEW_LIST_ARCHIVED,
          true
        );
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Plannings/PlanningView/GetPlanningViewsArchived"
        )
        .then((res) => {
          if (updateState) {
            commit(
              PlanningViewMutations.SET_PLANNING_VIEWS_LIST_ARCHIVED,
              res.data
            );
            commit(
              PlanningViewMutations.SET_IS_LOADING_PLANNING_VIEW_LIST_ARCHIVED,
              false
            );
          }
          commit(PlanningViewMutations.SET_REQUEST_STATE_PLANNING_VIEW, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PlanningViewMutations.SET_REQUEST_STATE_PLANNING_VIEW, err);
          commit(
            PlanningViewMutations.SET_IS_LOADING_PLANNING_VIEW_LIST_ARCHIVED,
            false
          );
          reject(err);
        });
    });
  },

  async getPlanningViewArchivedById(
    { commit },
    {
      planningViewId,
      updateState = true,
    }: { planningViewId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          PlanningViewMutations.SET_IS_LOADING_PLANNING_VIEW_ARCHIVED,
          true
        );
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Plannings/PlanningView/GetPlanningViewById",
          {
            params: {
              planningViewId: planningViewId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(PlanningViewMutations.SET_PLANNING_VIEW_ARCHIVED, res.data);
            commit(
              PlanningViewMutations.SET_IS_LOADING_PLANNING_VIEW_ARCHIVED,
              false
            );
          }
          commit(PlanningViewMutations.SET_REQUEST_STATE_PLANNING_VIEW, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PlanningViewMutations.SET_REQUEST_STATE_PLANNING_VIEW, err);
          commit(
            PlanningViewMutations.SET_IS_LOADING_PLANNING_VIEW_ARCHIVED,
            false
          );
          reject(err);
        });
    });
  },

  async createPlanningView(
    { commit, rootGetters },
    { planningView }: { planningView: PlanningViewRequestDto }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(PlanningViewMutations.SET_IS_CREATING_PLANNING_VIEW, true);
      await axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            "Plannings/PlanningView/CreatePlanningView",
          planningView
        )
        .then((res) => {
          rootGetters.planningViewsList.unshift(res.data.data);
          commit(
            PlanningViewMutations.SET_PLANNING_VIEWS_LIST,
            rootGetters.planningViewsList
          );
          commit(PlanningViewMutations.SET_REQUEST_STATE_PLANNING_VIEW, res);
          commit(PlanningViewMutations.SET_IS_CREATING_PLANNING_VIEW, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PlanningViewMutations.SET_REQUEST_STATE_PLANNING_VIEW, err);
          commit(PlanningViewMutations.SET_IS_CREATING_PLANNING_VIEW, false);
          reject(err);
        });
    });
  },

  async createPlanningViewAutomatically(
    { commit, rootGetters },
    { planningView }: { planningView: PlanningViewRequestDto }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(PlanningViewMutations.SET_IS_CREATING_PLANNING_VIEW, true);
      await axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            // TODO: Revoir l'endpoint apres deploy du back
            "Plannings/PlanningView/CreatePlanningViewAutomatically",
          planningView
        )
        .then((res) => {
          rootGetters.planningViewsList.unshift(res.data.data);
          commit(
            PlanningViewMutations.SET_PLANNING_VIEWS_LIST,
            rootGetters.planningViewsList
          );
          commit(PlanningViewMutations.SET_REQUEST_STATE_PLANNING_VIEW, res);
          commit(PlanningViewMutations.SET_IS_CREATING_PLANNING_VIEW, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PlanningViewMutations.SET_REQUEST_STATE_PLANNING_VIEW, err);
          commit(PlanningViewMutations.SET_IS_CREATING_PLANNING_VIEW, false);
          reject(err);
        });
    });
  },

  async updatePlanningView(
    { commit, rootGetters },
    { planningView }: { planningView: PlanningViewRequestDto }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(PlanningViewMutations.SET_IS_UPDATING_PLANNING_VIEW, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Plannings/PlanningView/UpdatePlanningView",
          planningView
        )
        .then((res) => {
          rootGetters.planningViewsList.splice(
            rootGetters.planningViewsList.findIndex(
              (elem) => elem.id == planningView.id
            ),
            1,
            planningView
          );
          commit(
            PlanningViewMutations.SET_PLANNING_VIEWS_LIST,
            rootGetters.planningViewsList
          );
          commit(PlanningViewMutations.SET_REQUEST_STATE_PLANNING_VIEW, res);
          commit(PlanningViewMutations.SET_IS_UPDATING_PLANNING_VIEW, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PlanningViewMutations.SET_REQUEST_STATE_PLANNING_VIEW, err);
          commit(PlanningViewMutations.SET_IS_UPDATING_PLANNING_VIEW, false);
          reject(err);
        });
    });
  },

  // async sharePlanningViews() {},

  async restorePlanningViews(
    { commit, rootGetters },
    { planningViewIds = [] }: { planningViewIds: number[] }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(PlanningViewMutations.SET_IS_UPDATING_PLANNING_VIEW, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Directory/PlanningView/Restore",
          planningViewIds
        )
        .then((res) => {
          planningViewIds.forEach((planningViewId) => {
            rootGetters.planningViewsListArchived.splice(
              rootGetters.planningViewsListArchived.findIndex(
                (elem) => elem.id == planningViewId
              ),
              1
            );
          });
          commit(
            PlanningViewMutations.SET_PLANNING_VIEWS_LIST_ARCHIVED,
            rootGetters.planningViewsListArchived
          );
          commit(PlanningViewMutations.SET_REQUEST_STATE_PLANNING_VIEW, res);
          commit(PlanningViewMutations.SET_IS_UPDATING_PLANNING_VIEW, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PlanningViewMutations.SET_REQUEST_STATE_PLANNING_VIEW, err);
          commit(PlanningViewMutations.SET_IS_UPDATING_PLANNING_VIEW, false);
          reject(err);
        });
    });
  },

  async archivePlanningViews(
    { commit, rootGetters },
    { planningViewIds = [] }: { planningViewIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(PlanningViewMutations.SET_IS_ARCHIVING_PLANNING_VIEW, true);
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Plannings/PlanningView/Archive",
          {
            data: planningViewIds,
          }
        )
        .then((res) => {
          planningViewIds.forEach((planningViewId) => {
            rootGetters.planningViewsList.splice(
              rootGetters.planningViewsList.findIndex(
                (elem) => elem.id == planningViewId
              ),
              1
            );
          });
          commit(
            PlanningViewMutations.SET_PLANNING_VIEWS_LIST,
            rootGetters.planningViewsList
          );
          commit(PlanningViewMutations.SET_REQUEST_STATE_PLANNING_VIEW, res);
          commit(PlanningViewMutations.SET_IS_ARCHIVING_PLANNING_VIEW, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PlanningViewMutations.SET_REQUEST_STATE_PLANNING_VIEW, err);
          commit(PlanningViewMutations.SET_IS_ARCHIVING_PLANNING_VIEW, false);
          reject(err);
        });
    });
  },

  async deletePlanningViews(
    { commit, rootGetters },
    { planningViewIds = [] }: { planningViewIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(PlanningViewMutations.SET_IS_DELETING_PLANNING_VIEW, true);
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Plannings/PlanningView/Delete",
          {
            data: planningViewIds,
          }
        )
        .then((res) => {
          planningViewIds.forEach((planningViewId) => {
            rootGetters.planningViewsList.splice(
              rootGetters.planningViewsList.findIndex(
                (elem) => elem.id == planningViewId
              ),
              1
            );
          });
          commit(
            PlanningViewMutations.SET_PLANNING_VIEWS_LIST,
            rootGetters.planningViewsList
          );
          commit(PlanningViewMutations.SET_REQUEST_STATE_PLANNING_VIEW, res);
          commit(PlanningViewMutations.SET_IS_DELETING_PLANNING_VIEW, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PlanningViewMutations.SET_REQUEST_STATE_PLANNING_VIEW, err);
          commit(PlanningViewMutations.SET_IS_DELETING_PLANNING_VIEW, false);
          reject(err);
        });
    });
  },
};
