import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { GetterTree } from "vuex";
import RootState from '@/store/modules/rootState'
import { IInstitutionTypeState } from "./states";

export const getters: GetterTree<IInstitutionTypeState, RootState> = {
    institutionTypesList: (state): any[] => state.institutionTypesList,
    
    // COMMON
    isLoadingInstitutionTypesList   : (state): boolean => state.isLoadingInstitutionTypesList,
    isCreatingInstitutionType  : (state): boolean => state.isCreatingInstitutionType,
    isUpdatingInstitutionType  : (state): boolean => state.isUpdatingInstitutionType,
    isDeletingInstitutionType  : (state): boolean => state.isDeletingInstitutionType,
    isArchivingInstitutionType : (state): boolean => state.isArchivingInstitutionType,
    requestStateInstitutionType: (state): IRequestState => state.requestStateInstitutionType,
}