import { InstitutionSettingRequestDto } from "Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { InstitutionSettingsMutations } from "./mutations";
import { IInstitutionSettingsStates } from "./states";
import { ReferencielTVAMutations } from "../invoices/referencielTva/mutations";

export const actions: ActionTree<IInstitutionSettingsStates, RootState> = {
  getInstitutionSetting(
    { commit, dispatch },
    { updateState = true }: { updateState: boolean }
  ) {
    return new Promise((resolve, reject) => {
      if (updateState) {
        commit(
          InstitutionSettingsMutations.SET_IS_LOADING_INSTITUTION_SETTINGS,
          true
        );
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/InstitutionSetting/GetInstitutionSetting"
        )
        .then((res) => {
          if (updateState) {
            commit(
              InstitutionSettingsMutations.SET_INSTITUTION_SETTINGS_ACTIVE,
              {
                ...res.data,
                paymentConditionInvoiceDefaultId:
                  res.data.paymentConditionInvoiceDefault == null
                    ? null
                    : res.data.paymentConditionInvoiceDefault.id,
              }
            );
            commit(InstitutionSettingsMutations.SET_INSTITUTION_SETTINGS, {
              ...res.data,
              paymentConditionInvoiceDefaultId:
                res.data.paymentConditionInvoiceDefault == null
                  ? null
                  : res.data.paymentConditionInvoiceDefault.id,
            });
            commit(
              InstitutionSettingsMutations.SET_IS_LOADING_INSTITUTION_SETTINGS,
              false
            );
          }
          commit(
            InstitutionSettingsMutations.SET_REQUEST_STATE_INSTITUTION_SETTINGS,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          commit(
            InstitutionSettingsMutations.SET_REQUEST_STATE_INSTITUTION_SETTINGS,
            err
          );
          commit(
            InstitutionSettingsMutations.SET_IS_LOADING_INSTITUTION_SETTINGS,
            false
          );
          reject(err);
        });
    });
  },

  createInstitutionSetting(
    { commit },
    {
      institutionSettings,
    }: { institutionSettings: InstitutionSettingRequestDto }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(
        InstitutionSettingsMutations.SET_IS_LOADING_INSTITUTION_SETTINGS,
        true
      );
      axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            "Sale/InstitutionSetting/CreateInstitutionSetting",
          institutionSettings
        )
        .then((res) => {
          if (
            res.data.data.referencielTvas &&
            res.data.data.referencielTvas.length > 0
          ) {
            for (
              let index = 0;
              index < res.data.data.referencielTvas.length;
              index++
            ) {
              res.data.data.referencielTvas[index].referencielTvaId =
                res.data.data.referencielTvas[index].referencielTva.id;
            }
          }
          res.data.data.institutionId = institutionSettings.institutionId;
          res.data.data.paymentConditionInvoiceDefaultId =
            res.data.paymentConditionInvoiceDefault == null
              ? 1
              : res.data.paymentConditionInvoiceDefault.id;
          commit(
            InstitutionSettingsMutations.SET_INSTITUTION_SETTINGS,
            res.data.data
          );
          commit(
            InstitutionSettingsMutations.SET_REQUEST_STATE_INSTITUTION_SETTINGS,
            res
          );
          commit(
            InstitutionSettingsMutations.SET_IS_LOADING_INSTITUTION_SETTINGS,
            false
          );
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            InstitutionSettingsMutations.SET_REQUEST_STATE_INSTITUTION_SETTINGS,
            err
          );
          commit(
            InstitutionSettingsMutations.SET_IS_LOADING_INSTITUTION_SETTINGS,
            false
          );
          reject(err);
        });
    });
  },

  updateInstitutionSetting(
    { commit },
    {
      institutionSettings,
    }: { institutionSettings: InstitutionSettingRequestDto }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(
        InstitutionSettingsMutations.SET_IS_LOADING_INSTITUTION_SETTINGS,
        true
      );
      axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Sale/InstitutionSetting/UpdateInstitutionSetting",
          institutionSettings
        )
        .then((res) => {
          let institutionSettingsResult = {
            ...res.data.data,
            paymentConditionInvoiceDefaultId:
              res.data.data.paymentConditionInvoiceDefault == null
                ? 1
                : res.data.data.paymentConditionInvoiceDefault.id,
            referencielTvas: res.data.data.referencielTvas.map((item: any) => {
              return {
                ...item,
                referencielTvaId: item.referencielTva.id,
              };
            }),
            institutionId: institutionSettings.institutionId,
          };
          commit(
            InstitutionSettingsMutations.SET_INSTITUTION_SETTINGS,
            institutionSettingsResult
          );
          commit(
            InstitutionSettingsMutations.SET_INSTITUTION_SETTINGS_ACTIVE,
            institutionSettings
          );
          commit(
            InstitutionSettingsMutations.SET_REQUEST_STATE_INSTITUTION_SETTINGS,
            res
          );
          commit(
            InstitutionSettingsMutations.SET_IS_LOADING_INSTITUTION_SETTINGS,
            false
          );
          resolve(institutionSettingsResult);
        })
        .catch((err) => {
          console.error(err);
          commit(
            InstitutionSettingsMutations.SET_REQUEST_STATE_INSTITUTION_SETTINGS,
            err
          );
          commit(
            InstitutionSettingsMutations.SET_IS_LOADING_INSTITUTION_SETTINGS,
            false
          );
          reject(err);
        });
    });
  },

  updateInstitutionTypeInstitutionSetting(
    { commit },
    {
      institutionSettingId,
      institutionTypeIds = [],
    }: { institutionSettingId: number; institutionTypeIds: Array<number> }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Sale/InstitutionSetting/UpdateInstitutionTypeInstitutionSetting",
          institutionTypeIds,
          {
            params: {
              institutionSettingId: institutionSettingId,
            },
          }
        )
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateExportSalesJournalInstitutionSetting(
    { commit },
    {
      institutionSettingId,
      exportSalesJournalIds = [],
    }: { institutionSettingId: number; exportSalesJournalIds: Array<number> }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Sale/InstitutionSetting/UpdateExportSalesJournalInstitutionSetting",
          exportSalesJournalIds,
          {
            params: {
              institutionSettingId: institutionSettingId,
            },
          }
        )
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updatePaymentMethodsDefaults(
    { commit },
    {
      paymentMethodsDefaultIds = [],
      institutionSettingId,
    }: {
      paymentMethodsDefaultIds: Array<number>;
      institutionSettingId: number;
    }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(
        InstitutionSettingsMutations.SET_IS_LOADING_INSTITUTION_SETTINGS,
        true
      );
      axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Sale/InstitutionSetting/UpdateInstitutionSettingPaymentMethod",
          paymentMethodsDefaultIds,
          {
            params: {
              institutionSettingId: institutionSettingId,
            },
          }
        )
        .then((res) => {
          commit(
            InstitutionSettingsMutations.SET_REQUEST_STATE_INSTITUTION_SETTINGS,
            res
          );
          commit(
            InstitutionSettingsMutations.SET_IS_LOADING_INSTITUTION_SETTINGS,
            false
          );
          resolve();
        })
        .catch((err) => {
          console.error(err);
          commit(
            InstitutionSettingsMutations.SET_REQUEST_STATE_INSTITUTION_SETTINGS,
            err
          );
          commit(
            InstitutionSettingsMutations.SET_IS_LOADING_INSTITUTION_SETTINGS,
            false
          );
          reject(err);
        });
    });
  },

  updateInstitutionSettingReferencielTvas(
    { commit },
    {
      institutionSettingId,
      referencielTvas,
    }: {
      institutionSettingId: number;
      referencielTvas: any[];
    }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(
        InstitutionSettingsMutations.SET_IS_LOADING_INSTITUTION_SETTINGS,
        false
      );
      axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Sale/InstitutionSetting/UpdateInstitutionSettingReferencielTvas",
          referencielTvas,
          {
            params: {
              institutionSettingId: institutionSettingId,
            },
          }
        )
        .then((res) => {
          commit(
            InstitutionSettingsMutations.SET_REQUEST_STATE_INSTITUTION_SETTINGS,
            res
          );
          commit(
            InstitutionSettingsMutations.SET_IS_LOADING_INSTITUTION_SETTINGS,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            InstitutionSettingsMutations.SET_REQUEST_STATE_INSTITUTION_SETTINGS,
            err
          );
          commit(
            InstitutionSettingsMutations.SET_IS_LOADING_INSTITUTION_SETTINGS,
            false
          );
          reject(err);
        });
    });
  },

  updateInstitutionSettingPaymentCondition(
    { commit },
    {
      institutionSettingId,
      isOrderForm,
      paymentConditions,
    }: {
      institutionSettingId: number;
      isOrderForm: boolean;
      paymentConditions: any;
    }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Sale/InstitutionSetting/UpdateInstitutionSettingPaymentCondition",
          paymentConditions,
          {
            params: {
              institutionSettingId: institutionSettingId,
              isOrderForm: isOrderForm,
            },
          }
        )
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // updateInstitutionSettingUnit(
  //   { commit },
  //   {
  //     institutionSettingId,
  //     unitIds = []
  //   }: { institutionSettingId: number; unitIds: unitIds }
  // ): Promise<void> {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .put(
  //         process.env.VUE_APP_API_ORISIS +
  //           "Sale/InstitutionSetting/UpdateInstitutionSettingUnit",
  //           unitIds: unitIds,
  //         {
  //           params: {
  //             institutionSettingId: institutionSettingId,
  //           },
  //         }
  //       )
  //       .then((res) => {
  //         resolve(res.data.data);
  //       })
  //       .catch((err) => {
  //         reject(err);
  //       });
  //   });
  // },

  updateQuoteDownPaymentsRequestDefaults(
    { commit },
    { institutionSettingsId, quoteDownPaymentsRequestDefaultsIds }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(
        InstitutionSettingsMutations.SET_IS_LOADING_INSTITUTION_SETTINGS,
        true
      );
      axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Billing/QuoteDownPaymentsRequest/UpdateQuoteDownPaymentsRequest",
          quoteDownPaymentsRequestDefaultsIds,
          {
            params: {
              institutionSettingsId: institutionSettingsId,
            },
          }
        )
        .then((res) => {
          commit(
            InstitutionSettingsMutations.SET_REQUEST_STATE_INSTITUTION_SETTINGS,
            res
          );
          commit(
            InstitutionSettingsMutations.SET_IS_LOADING_INSTITUTION_SETTINGS,
            false
          );
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            InstitutionSettingsMutations.SET_REQUEST_STATE_INSTITUTION_SETTINGS,
            err
          );
          commit(
            InstitutionSettingsMutations.SET_IS_LOADING_INSTITUTION_SETTINGS,
            false
          );
          reject(err);
        });
    });
  },

  setInstitutionSettingsActive(
    { commit },
    {
      institutionSettings,
    }: { institutionSettings: InstitutionSettingRequestDto }
  ) {
    commit(
      InstitutionSettingsMutations.SET_INSTITUTION_SETTINGS_ACTIVE,
      institutionSettings
    );
  },
};
