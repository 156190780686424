import { IWasteTypeState } from "./states";
import { MutationTree } from "vuex";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export enum WasteTypeMutations {
  SET_WASTE_TYPES_LIST = "SET_WASTE_TYPES_LIST",
  SET_WASTE_TYPES_LIST_ARCHIVED = "SET_WASTE_TYPES_LIST_ARCHIVED",
  SET_WASTE_TYPE = "SET_WASTE_TYPE",

  // COMON
  SET_IS_LOADING_WASTE_TYPES_LIST = "SET_IS_LOADING_WASTE_TYPES_LIST",
  SET_IS_LOADING_WASTE_TYPES_LIST_ARCHIVED = "SET_IS_LOADING_WASTE_TYPES_LIST_ARCHIVED",
  SET_IS_LOADING_WASTE_TYPE = "SET_IS_LOADING_WASTE_TYPE",
  SET_IS_CREATING_WASTE_TYPE = "SET_IS_CREATING_WASTE_TYPE",
  SET_IS_UPDATING_WASTE_TYPE = "SET_IS_UPDATING_WASTE_TYPE",
  SET_IS_DELETING_WASTE_TYPE = "SET_IS_DELETING_WASTE_TYPE",
  SET_REQUEST_STATE_WASTE_TYPE = "SET_REQUEST_STATE_WASTE_TYPE",
  SET_IS_ARCHIVING_WASTE_TYPE = "SET_IS_ARCHIVING_WASTE_TYPE",  
}

  export const mutations: MutationTree<IWasteTypeState> = {
    [WasteTypeMutations.SET_WASTE_TYPES_LIST]: (state, payload: any[]) => {
        state.wasteTypesList = payload;
    },
    [WasteTypeMutations.SET_WASTE_TYPES_LIST_ARCHIVED]: (state, payload: any[]) => {
        state.wasteTypesListArchived = payload;
    },
    [WasteTypeMutations.SET_WASTE_TYPE]: (state, payload: any) => {
        state.wasteType = payload;
    },


    //COMMON
    [WasteTypeMutations.SET_IS_LOADING_WASTE_TYPES_LIST]: (state, payload: boolean) => {
        state.isLoadingWasteTypesList = payload;
    },
    [WasteTypeMutations.SET_IS_LOADING_WASTE_TYPES_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingWasteTypesListArchived = payload;
    },
    [WasteTypeMutations.SET_IS_LOADING_WASTE_TYPE]: (state, payload: boolean) => {
        state.isLoadingWasteType = payload;
    },
    [WasteTypeMutations.SET_IS_CREATING_WASTE_TYPE]: (state, payload: boolean) => {
        state.isCreatingWasteType = payload;
    },
    [WasteTypeMutations.SET_IS_UPDATING_WASTE_TYPE]: (state, payload: boolean) => {
        state.isUpdatingWasteType = payload;
    },
    [WasteTypeMutations.SET_IS_DELETING_WASTE_TYPE]: (state, payload: boolean) => {
        state.isDeletingWasteType = payload;
    },
    [WasteTypeMutations.SET_REQUEST_STATE_WASTE_TYPE]: (state, payload: IRequestState) => {
        state.requestStateWasteType = payload;
    },
    [WasteTypeMutations.SET_IS_ARCHIVING_WASTE_TYPE]: (state, payload: boolean) => {
        state.isArchivingWasteType = payload;
    }
}