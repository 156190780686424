import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { IPlanningView } from "@/types/api-orisis/interfaces/Planning/IPlanningViews";
import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IPlanningViewState } from "./states";

export const getters: GetterTree<IPlanningViewState, RootState> = {
  planningViewsList: (state): IPlanningView[] => state.planningViewsList,
  planningViewsListByAffair: (state): IPlanningView[] =>
    state.planningViewsListByAffair,
  planningViewsListArchived: (state): IPlanningView[] =>
    state.planningViewsListArchived,
  planningView: (state): IPlanningView => state.planningView,
  planningViewArchived: (state): IPlanningView => state.planningViewArchived,
  planningFilters: (state): {} => state.planningFilters,

  // COMMON
  isLoadingPlanningViewsList: (state): boolean =>
    state.isLoadingPlanningViewsList,
  isLoadingPlanningViewsListByAffair: (state): boolean =>
    state.isLoadingPlanningViewsListByAffair,
  isLoadingPlanningView: (state): boolean => state.isLoadingPlanningView,
  isLoadingPlanningViewArchived: (state): boolean =>
    state.isLoadingPlanningViewArchived,
  isCreatingPlanningView: (state): boolean => state.isCreatingPlanningView,
  isUpdatingPlanningView: (state): boolean => state.isUpdatingPlanningView,
  isDeletingPlanningView: (state): boolean => state.isDeletingPlanningView,
  isArchivingPlanningView: (state): boolean => state.isArchivingPlanningView,
  requestStatePlanningView: (state): IRequestState =>
    state.requestStatePlanningView,
  isLoadingPlanningViewsListArchived: (state): boolean =>
    state.isLoadingPlanningViewsListArchived,
};
