import { IStockState } from "./states"
import { Module } from "vuex"
import { StockRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"
import { IFilters } from "@/types/api-orisis/interfaces/invoices/filters/IFilters"

const state:IStockState = {
    productsWithStockList         : [],
    stocksList                    : Array<StockRequestDto>(),
    stocksListArchived            : Array<StockRequestDto>(),
    stock                         : <StockRequestDto>{},
    stockArchived                 : <StockRequestDto>{},
    isLoadingProductsWithStocksList : false,
    isLoadingStocksList           : false,
    isLoadingStocksListArchived   : false,
    isLoadingStock                : false,
    isLoadingStockArchived        : false,
    isCreatingStock               : false,
    isUpdatingStock               : false,
    isRestoringStock              : false,
    isDeletingStock               : false,
    isArchivingStock              : false,
    requestStateStock             : <IRequestState>{},
}

export const StockModule: Module<IStockState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}