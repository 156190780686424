import { IUserParameterState } from "./states"
import { Module } from "vuex"
import { UserParameterRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IUserParameterState = {
    userParametersList         : Array<UserParameterRequestDto>(),
    userParametersListByUser         : Array<UserParameterRequestDto>(),
    userParameter              : <UserParameterRequestDto>{},
    isLoadingUserParametersList     : false,
    isLoadingUserParameter     : false,
    isCreatingUserParameter    : false,
    isUpdatingUserParameter    : false,
    requestStateUserParameter: <IRequestState>{},
}

export const UserParameterModule: Module<IUserParameterState, RootState> = {
    state, 
    getters,
    mutations,
    actions
} 