import { IDeductionState } from "./states";
import { MutationTree } from "vuex";
import { IDeduction } from "@/types/api-orisis/interfaces/Invoices/deduction/IDeduction";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";


export enum DeductionMutations{
    SET_DEDUCTIONS_LIST = "SET_DEDUCTIONS_LIST",
    SET_DEDUCTIONS_LIST_ARCHIVED = "SET_DEDUCTIONS_LIST_ARCHIVED",
    SET_DEDUCTION = "SET_DEDUCTION",
    
    // COMON 
    SET_IS_LOADING_DEDUCTIONS_LIST = "SET_IS_LOADING_DEDUCTIONS_LIST",
    SET_IS_LOADING_DEDUCTIONS_LIST_ARCHIVED = "SET_IS_LOADING_DEDUCTIONS_LIST_ARCHIVED",
    SET_IS_LOADING_DEDUCTION = "SET_IS_LOADING_DEDUCTION",
    SET_IS_CREATING_DEDUCTION = "SET_IS_CREATING_DEDUCTION",
    SET_IS_UPDATING_DEDUCTION = "SET_IS_UPDATING_DEDUCTION",
    SET_IS_DELETING_DEDUCTION = "SET_IS_DELETING_DEDUCTION",
    SET_IS_ARCHIVING_DEDUCTION = "SET_IS_ARCHIVING_DEDUCTION",
    SET_REQUEST_STATE_DEDUCTION = "SET_REQUEST_STATE_DEDUCTION",
  }

  export const mutations: MutationTree<IDeductionState> = {
    [DeductionMutations.SET_DEDUCTIONS_LIST_ARCHIVED]: (state, payload: IDeduction[]) => {
        state.deductionsListArchived = payload;
    },
    [DeductionMutations.SET_DEDUCTIONS_LIST]: (state, payload: IDeduction[]) => {
        state.deductionsList = payload;
    },
    [DeductionMutations.SET_DEDUCTION]: (state, payload: IDeduction) => {
        state.deduction = payload;
    },

    //COMMON
    [DeductionMutations.SET_IS_LOADING_DEDUCTIONS_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingDeductionsListArchived = payload;
    },
    [DeductionMutations.SET_IS_LOADING_DEDUCTIONS_LIST]: (state, payload: boolean) => {
        state.isLoadingDeductionsList = payload;
    },
    [DeductionMutations.SET_IS_LOADING_DEDUCTION]: (state, payload: boolean) => {
        state.isLoadingDeduction = payload;
    },
    [DeductionMutations.SET_IS_CREATING_DEDUCTION]: (state, payload: boolean) => {
        state.isCreatingDeduction = payload;
    },
    [DeductionMutations.SET_IS_UPDATING_DEDUCTION]: (state, payload: boolean) => {
        state.isUpdatingDeduction = payload;
    },
    [DeductionMutations.SET_IS_DELETING_DEDUCTION]: (state, payload: boolean) => {
        state.isDeletingDeduction = payload;
    },
    [DeductionMutations.SET_IS_ARCHIVING_DEDUCTION]: (state, payload: boolean) => {
        state.isArchivingDeduction = payload;
    },

    
    [DeductionMutations.SET_REQUEST_STATE_DEDUCTION]: (state, payload: IRequestState) => {
        state.requestStateDeduction = payload;
    },
}