import { IPayrollEventState } from "./states"
import { Module } from "vuex"
import { PayrollEventRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IPayrollEventState = {
    payrollEventsList         : Array<PayrollEventRequestDto>(),
    payrollEventsListArchived : Array<PayrollEventRequestDto>(),
    payrollEventsListTemplates         : Array<PayrollEventRequestDto>(),
    payrollEvent              : <PayrollEventRequestDto>{},
    isLoadingPayrollEventsList     : false,
    isLoadingPayrollEventsListArchived     : false,
    isLoadingPayrollEventsListTemplates     : false,
    isLoadingPayrollEvent     : false,
    isCreatingPayrollEvent    : false,
    isUpdatingPayrollEvent    : false,
    isDeletingPayrollEvent    : false,
    isArchivingPayrollEvent    : false,
    requestStatePayrollEvent: <IRequestState>{},
}

export const PayrollEventModule: Module<IPayrollEventState, RootState> = {
    state, 
    getters,
    mutations,
    actions
} 