import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { CompanyIndicatorRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { ICompanyIndicatorState } from "./states";

export enum CompanyIndicatorMutations {
    SET_COMPANY_INDICATORS_LIST = "SET_COMPANY_INDICATORS_LIST",
    SET_COMPANY_INDICATORS_LIST_ARCHIVED = "SET_COMPANY_INDICATORS_LIST_ARCHIVED",
    SET_COMPANY_INDICATOR = "SET_COMPANY_INDICATOR",

    //FILTERS
    SET_COMPANY_INDICATOR_FILTERS = "SET_COMPANY_INDICATOR_FILTERS",

    // COMMON
    SET_IS_LOADING_COMPANY_INDICATOR_LIST = "SET_IS_LOADING_COMPANY_INDICATOR_LIST",
    SET_IS_LOADING_COMPANY_INDICATOR_LIST_ARCHIVED = "SET_IS_LOADING_COMPANY_INDICATOR_LIST_ARCHIVED",
    SET_IS_LOADING_COMPANY_INDICATOR = "SET_IS_LOADING_COMPANY_INDICATOR",
    SET_IS_CREATING_COMPANY_INDICATOR = "SET_IS_CREATING_COMPANY_INDICATOR",
    SET_IS_UPDATING_COMPANY_INDICATOR = "SET_IS_UPDATING_COMPANY_INDICATOR",
    SET_IS_DELETING_COMPANY_INDICATOR = "SET_IS_DELETING_COMPANY_INDICATOR",
    SET_IS_ARCHIVING_COMPANY_INDICATOR = "SET_IS_ARCHIVING_COMPANY_INDICATOR",
    SET_REQUEST_STATE_COMPANY_INDICATOR = "SET_REQUEST_STATE_COMPANY_INDICATOR",
}

export const mutations: MutationTree<ICompanyIndicatorState> = {
    [CompanyIndicatorMutations.SET_COMPANY_INDICATORS_LIST]: (state, payload: any[]) => {
        state.companyIndicatorsList = payload;
    },
    [CompanyIndicatorMutations.SET_COMPANY_INDICATORS_LIST_ARCHIVED]: (state, payload: any[]) => {
        state.companyIndicatorsListArchived = payload
    }, 
    [CompanyIndicatorMutations.SET_COMPANY_INDICATOR]: (state, payload: CompanyIndicatorRequestDto) => {
        state.companyIndicator = payload;
    },

    //FILTERS
    [CompanyIndicatorMutations.SET_COMPANY_INDICATOR_FILTERS]: (state, payload: any) => {
        state.companyIndicatorFilters = payload;
    },


    //COMMON
    [CompanyIndicatorMutations.SET_IS_LOADING_COMPANY_INDICATOR_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingCompanyIndicatorsListArchived = payload;
    },
    [CompanyIndicatorMutations.SET_IS_LOADING_COMPANY_INDICATOR_LIST]: (state, payload: boolean) => {
        state.isLoadingCompanyIndicatorsList = payload;
    },
    [CompanyIndicatorMutations.SET_IS_LOADING_COMPANY_INDICATOR]: (state, payload: boolean) => {
        state.isLoadingCompanyIndicator = payload;
    },
    [CompanyIndicatorMutations.SET_IS_CREATING_COMPANY_INDICATOR]: (state, payload: boolean) => {
        state.isCreatingCompanyIndicator = payload;
    },
    [CompanyIndicatorMutations.SET_IS_UPDATING_COMPANY_INDICATOR]: (state, payload: boolean) => {
        state.isUpdatingCompanyIndicator = payload;
    },
    [CompanyIndicatorMutations.SET_IS_DELETING_COMPANY_INDICATOR]: (state, payload: boolean) => {
        state.isDeletingCompanyIndicator = payload;
    },
    [CompanyIndicatorMutations.SET_IS_ARCHIVING_COMPANY_INDICATOR]: (state, payload: boolean) => {
        state.isArchivingCompanyIndicator = payload;
    },
    [CompanyIndicatorMutations.SET_REQUEST_STATE_COMPANY_INDICATOR]: (state, payload: IRequestState) => {
        state.requestStateCompanyIndicator = payload;
    },
}