import { MutationTree } from 'vuex'
import { IHelpersState } from './states'
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export enum HelpersMutations {
  // Civility
  SET_CIVILITY_LIST = 'SET_CIVILITY_LIST',
  SET_IS_LOADING_CIVILITY_LIST = 'SET_IS_LOADING_CIVILITY_LIST',
  
  SET_DOCUMENT_TYPE_LIST = 'SET_DOCUMENT_TYPE_LIST',
  SET_LEGAL_FORM_LIST = 'SET_LEGAL_FORM_LIST',
  SET_GUARANTEE_TYPE_LIST = 'SET_GUARANTEE_TYPE_LIST',
  SET_DOCUMENT_VALIDITY_LIST = 'SET_DOCUMENT_VALIDITY_LIST',
  SET_AMOUNT_TYPE_LIST = 'SET_AMOUNT_TYPE_LIST',
  SET_PAYMENT_REQUEST_DUE_LIST = 'SET_PAYMENT_REQUEST_DUE_LIST',
  SET_PRODUCT_TYPE_LIST = 'SET_PRODUCT_TYPE_LIST',
  SET_NATURE_LIST = 'SET_NATURE_LIST',
  SET_IS_LOADING_DOCUMENT_TYPE_LIST = 'SET_IS_LOADING_DOCUMENT_TYPE_LIST',
  SET_IS_LOADING_LEGAL_FORM_LIST = 'SET_IS_LOADING_LEGAL_FORM_LIST',
  SET_IS_LOADING_GUARANTEE_TYPE_LIST = 'SET_IS_LOADING_GUARANTEE_TYPE_LIST',
  SET_IS_LOADING_DOCUMENT_VALIDITY_LIST = 'SET_IS_LOADING_DOCUMENT_VALIDITY_LIST',
  SET_IS_LOADING_AMOUNT_TYPE_LIST = 'SET_IS_LOADING_AMOUNT_TYPE_LIST',
  SET_IS_LOADING_PAYMENT_REQUEST_DUE_LIST = 'SET_IS_LOADING_PAYMENT_REQUEST_DUE_LIST',
  SET_IS_LOADING_PRODUCT_TYPE_LIST = 'SET_IS_LOADING_PRODUCT_TYPE_LIST',
  SET_IS_LOADING_NATURE_LIST = 'SET_IS_LOADING_NATURE_LIST',
  SET_REQUEST_STATE_HELPERS = "SET_REQUEST_STATE_HELPERS",
}

export const mutations: MutationTree<IHelpersState> = {
  // Civility
  [HelpersMutations.SET_CIVILITY_LIST]: (state, payload: []) => {
    state.civilityList = payload
  },
  [HelpersMutations.SET_IS_LOADING_CIVILITY_LIST]: (state, payload: boolean) => {
    state.isLoadingCivilityList = payload
  },

  [HelpersMutations.SET_DOCUMENT_TYPE_LIST]: (state, payload: []) => {
    state.documentTypeList = payload
  },
  [HelpersMutations.SET_LEGAL_FORM_LIST]: (state, payload: []) => {
    state.legalFormList = payload
  },
  [HelpersMutations.SET_GUARANTEE_TYPE_LIST]: (state, payload: []) => {
    state.guaranteeTypeList = payload
  },
  [HelpersMutations.SET_DOCUMENT_VALIDITY_LIST]: (state, payload: []) => {
    state.documentValidityList = payload
  },
  [HelpersMutations.SET_AMOUNT_TYPE_LIST]: (state, payload: []) => {
    state.amountTypeList = payload
  },
  [HelpersMutations.SET_PAYMENT_REQUEST_DUE_LIST]: (state, payload: []) => {
    state.paymentRequestDueList = payload
  },
  [HelpersMutations.SET_PRODUCT_TYPE_LIST]: (state, payload: []) => {
    state.productTypeList = payload
  },
  [HelpersMutations.SET_NATURE_LIST]: (state, payload: []) => {
    state.natureList = payload
  },
  [HelpersMutations.SET_IS_LOADING_DOCUMENT_TYPE_LIST]: (state, payload: boolean) => {
    state.isLoadingDocumentTypeList = payload
  },
  [HelpersMutations.SET_IS_LOADING_LEGAL_FORM_LIST]: (state, payload: boolean) => {
    state.isLoadingLegalFormList = payload
  },
  [HelpersMutations.SET_IS_LOADING_GUARANTEE_TYPE_LIST]: (state, payload: boolean) => {
    state.isLoadingGuaranteeTypeList = payload
  },
  [HelpersMutations.SET_IS_LOADING_DOCUMENT_VALIDITY_LIST]: (state, payload: boolean) => {
    state.isLoadingDocumentValidityList = payload
  },
  [HelpersMutations.SET_IS_LOADING_AMOUNT_TYPE_LIST]: (state, payload: boolean) => {
    state.isLoadingAmountTypeList = payload
  },
  [HelpersMutations.SET_IS_LOADING_PAYMENT_REQUEST_DUE_LIST]: (state, payload: boolean) => {
    state.isLoadingPaymentRequestDueList = payload
  },
  [HelpersMutations.SET_IS_LOADING_PRODUCT_TYPE_LIST]: (state, payload: boolean) => {
    state.isLoadingProductTypeList = payload
  },
  [HelpersMutations.SET_IS_LOADING_NATURE_LIST]: (state, payload: boolean) => {
    state.isLoadingNatureList = payload
  },
  [HelpersMutations.SET_REQUEST_STATE_HELPERS]: (state, payload: IRequestState) => {
    state.requestStateHelpers = payload;
},
}
