import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { ModuleRequestDto } from '@/../Api'
import RootState from "@/store/modules/rootState"
import { IModuleState } from "./states";
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

export const state: IModuleState = {
  modulesList                  : Array<ModuleRequestDto>(),
  modulesListArchived          : Array<ModuleRequestDto>(),
  module                       : <ModuleRequestDto>{},
  isLoadingModulesList         : false,
  isLoadingModulesListArchived : false,
  isLoadingModule              : false,
  requestStateModule           : <IRequestState>{},
  isArchivingModule            : false,
}

export const ModuleModule: Module<IModuleState, RootState> = {
  state, 
  getters,
  mutations,
  actions
}