import { IReceiptFormProviderHistoryState } from "./states";
import { MutationTree } from "vuex";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export enum ReceiptFormProviderHistoryMutations {
  SET_RECEIPT_FORM_HISTORIES_LIST = "SET_RECEIPT_FORM_HISTORIES_LIST",
  SET_RECEIPT_FORM_HISTORY = "SET_RECEIPT_FORM_HISTORY",

  // COMON
  SET_IS_LOADING_RECEIPT_FORM_HISTORIES_LIST = "SET_IS_LOADING_RECEIPT_FORM_HISTORIES_LIST",
  SET_IS_LOADING_RECEIPT_FORM_HISTORY = "SET_IS_LOADING_RECEIPT_FORM_HISTORY",
  SET_REQUEST_STATE_RECEIPT_FORM_HISTORY = "SET_REQUEST_STATE_RECEIPT_FORM_HISTORY",
}

export const mutations: MutationTree<IReceiptFormProviderHistoryState> = {
  [ReceiptFormProviderHistoryMutations.SET_RECEIPT_FORM_HISTORIES_LIST]: (
    state,
    payload: []
  ) => {
    state.receiptFormProviderHistoriesList = payload;
  },
  [ReceiptFormProviderHistoryMutations.SET_RECEIPT_FORM_HISTORY]: (state, payload: {}) => {
    state.receiptFormProviderHistory = payload;
  },

  //COMMON
  [ReceiptFormProviderHistoryMutations.SET_IS_LOADING_RECEIPT_FORM_HISTORIES_LIST]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingReceiptFormProviderHistoriesList = payload;
  },
  [ReceiptFormProviderHistoryMutations.SET_IS_LOADING_RECEIPT_FORM_HISTORY]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingReceiptFormProviderHistory = payload;
  },
  [ReceiptFormProviderHistoryMutations.SET_REQUEST_STATE_RECEIPT_FORM_HISTORY]: (
    state,
    payload: IRequestState
  ) => {
    state.requestStateReceiptFormProviderHistory = payload;
  },
};
