import { IReceiptFormProviderDetailState } from "./states";
import IReceiptFormProviderHeader from "@/types/api-orisis/interfaces/Purchase/receiptFormProviderDetail/IReceiptFormProviderHeader";
import { Module } from "vuex";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import RootState from "@/store/modules/rootState";
import { EInputType } from "@/types/api-orisis/enums/enums";
import { IReceiptFormParameters } from "@/types/api-orisis/interfaces/Purchase/receiptFormProvider/IReceiptFormProviderParameters";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

const receiptFormProviderParameters: IReceiptFormParameters = {
  BShowCalcululationsMargin: false,
  BShowCalculationsRemisePerLines: false,
  BShowReferences: false,
  BShowTypes: false,
  BTvaAttestation: false,
  BPriorVisitDate: false,
  BDefaultTvaChange: false,
  BHideStyleEditor: false,
  BHideWasteManagement: false,
  BHideTotalSections: false,
  BHideQteAndUnitsCol: false,
  BHideTvaCol: false,
  BHideWorkElements: false,
  BHideRestToBeInvoiced: false,
  BShowPhoneNumber: false,
  BShowEmail: false,
  BShowTvaNumber: false,
  BShowSiren: false,
};

const receiptFormProviderHeader: IReceiptFormProviderHeader = {
  index: {
    text: "N°",
    display: true,
    editable: false,
    class: "col-num pl-0",
    style:
      "min-height: 28px !important; max-width: 60px!important;min-width: 60px!important;margin-left:25px;width: 60px!important; padding-left: 0.25em!important; padding-right: 0.25em!important;",
    inputType: EInputType.Text,
    unit: "",
    prefix: "",
  },
  reference: {
    text: "Référence",
    display: true,
    editable: true,
    class: "col-ref reference",
    style: "width:10%;max-width: 85px;",
    inputType: EInputType.Text,
  },
  description: {
    text: "Désignation",
    display: true,
    editable: true,
    class: "col-label",
    style: "width:auto; max-width: none;flex-grow: 1;",
    inputType: EInputType.SelectCatalog,
  },
  orderedQuantity: {
    text: "Qté Cmd",
    display: true,
    editable: false,
    class: "col-qte text-center",
    style: "width:20%; max-width:100px;",
    inputType: EInputType.Number,
    unit: "",
    numberToFixed: 2,
    prefix: "",
  },
  previousQuantitiesReceived: {
    text: "Dernière qté Reçu",
    display: false,
    editable: false,
    class: "col-qte text-center",
    style: "width:20%; max-width:150px;",
    inputType: EInputType.Number,
    unit: "",
    numberToFixed: 2,
    prefix: "",
  },
  receivedQuantity: {
    text: "Qté Reçu",
    display: true,
    editable: true,
    class: "col-qte text-center",
    style: "width:20%; max-width:100px;",
    inputType: EInputType.Number,
    unit: "",
    numberToFixed: 2,
    prefix: "",
  },
  unitId: {
    text: "Unité",
    display: true,
    editable: true,
    class: "col-unit text-center",
    style: "width:10%; max-width:70px;",
    inputType: EInputType.Select,
    choice: [],
  },
  titre: {
    text: 'Titre',
    display: false,
    editable: true,
    class: 'col-title text-left',
    style: 'line-height: 22px !important; width:95%;',
    inputType: EInputType.Title,
  },
  subtotal: {
    text: 'Sous-total',
    display: false,
    editable: false,
    class: 'col-title-total text-right',
    style: 'line-height: 34px !important; width:50%;',
    inputType: EInputType.Number,
    unit: '€',
    prefix: '',
    numberToFixed: 2,
  },
  editor: {
    text: 'Texte',
    display: false,
    editable: false,
    class: 'col-editor text-left',
    style: 'width:95%;',
    inputType: EInputType.Editor,
  },
};

export const state: IReceiptFormProviderDetailState = {
  ReceiptFormProviderParameters: receiptFormProviderParameters,
  receiptFormProviderHeader: receiptFormProviderHeader,
  receiptFormProviderDetails: [],
  receiptFormProviderDetail: {},
  isLoadingReceiptFormProviderDetails: false,
  isLoadingReceiptFormProviderDetailDuplication: false,
  isCreatingReceiptFormProviderDetail: false,
  isUpdatingReceiptFormProviderDetail: false,
  isDeletingReceiptFormProviderDetail: false,
  requestStateReceiptFormProviderDetail: <IRequestState>{},
};

export const ReceiptFormProviderDetailModule: Module<
  IReceiptFormProviderDetailState,
  RootState
> = {
  state,
  getters,
  mutations,
  actions,
};
