import { WorkspaceRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { WorkspaceMutations } from "./mutations";
import { IWorkspaceState } from "./states";

export const actions: ActionTree<IWorkspaceState, RootState> = {
  getWorkspaces(
    { commit, rootGetters },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(WorkspaceMutations.SET_IS_LOADING_WORKSPACES_LIST, true);
      }
      axios
        .get(`${process.env.VUE_APP_API_ORISIS}Account/Workspace/GetWorkspaces`)
        .then((res) => {
          if (updateState) {
            commit(WorkspaceMutations.SET_WORKSPACES_LIST, res.data);
            commit(WorkspaceMutations.SET_IS_LOADING_WORKSPACES_LIST, false);
          }
          commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, err);
          if (updateState) {
            commit(WorkspaceMutations.SET_IS_LOADING_WORKSPACES_LIST, false);
          }
          reject(err);
        });
    });
  },

  getWorkspace(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(WorkspaceMutations.SET_IS_LOADING_WORKSPACE, true);
      }
      axios
        .get(process.env.VUE_APP_API_ORISIS + "Account/Workspace/GetWorkspace")
        .then((res) => {
          let workspace = {
            ...res.data,
            modules: res.data.modules.map((el) => el.id),
          };
          if (updateState) {
            commit(WorkspaceMutations.SET_WORKSPACE_SELECTED, workspace);
            commit(WorkspaceMutations.SET_IS_LOADING_WORKSPACE, false);
          }
          commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, res);
          resolve(workspace);
        })
        .catch((err) => {
          console.error(err);
          commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, err);
          if (updateState) {
            commit(WorkspaceMutations.SET_IS_LOADING_WORKSPACE, false);
          }
          reject(err);
        });
    });
  },

  createWorkspace(
    { commit, rootGetters },
    { workspace }: { workspace: WorkspaceRequestDto }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(WorkspaceMutations.SET_IS_CREATING_WORKSPACE, true);
      axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Account/Workspace/CreateWorkspace`,
          workspace
        )
        .then((res) => {
          rootGetters.workspacesList.unshift(res.data.data);
          commit(
            WorkspaceMutations.SET_WORKSPACES_LIST,
            rootGetters.workspacesList
          );
          commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, res);
          commit(WorkspaceMutations.SET_IS_CREATING_WORKSPACE, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, err);
          commit(WorkspaceMutations.SET_IS_CREATING_WORKSPACE, false);
          reject(err);
        });
    });
  },

  updateWorkspace(
    { commit, rootGetters },
    { workspace }: { workspace: WorkspaceRequestDto }
  ) {
    return new Promise((resolve, reject) => {
      commit(WorkspaceMutations.SET_IS_UPDATING_WORKSPACE, true);
      axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Account/Workspace/UpdateWorkspace`,
          workspace
        )
        .then((res) => {
          rootGetters.workspacesList.splice(
            rootGetters.workspacesList.findIndex(
              (elem) => elem.id == workspace.id
            ),
            1,
            workspace
          );
          commit(
            WorkspaceMutations.SET_WORKSPACES_LIST,
            rootGetters.workspacesList
          );
          commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, res);
          commit(WorkspaceMutations.SET_IS_UPDATING_WORKSPACE, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, err);
          commit(WorkspaceMutations.SET_IS_UPDATING_WORKSPACE, false);
          reject(err);
        });
    });
  },

  updateWorkspaceModule(
    { commit, rootGetters },
    { modules, workspaceId }: { modules: number[]; workspaceId: number }
  ) {
    return new Promise((resolve, reject) => {
      commit(WorkspaceMutations.SET_IS_UPDATING_WORKSPACE, true);
      axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Account/Workspace/UpdateWorkspaceModule`,
          modules,
          {
            params: {
              workspaceId: workspaceId,
            },
          }
        )
        .then((res) => {
          commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, res);
          commit(WorkspaceMutations.SET_IS_UPDATING_WORKSPACE, false);
          resolve(res);
        })
        .catch((err) => {
          console.error(err);
          commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, err);
          commit(WorkspaceMutations.SET_IS_UPDATING_WORKSPACE, false);
          reject(err);
        });
    });
  },
};
