import { ILeaseState } from "./states";
import { Module } from "vuex";
import { LeaseRequestDto } from "@/../Api";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import RootState from "@/store/modules/rootState";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

const state: ILeaseState = {
  leasesListByWorkspace: Array<LeaseRequestDto>(),
  leasesList: Array<LeaseRequestDto>(),
  leasesListByAffair: Array<LeaseRequestDto>(),
  leasesListArchived: Array<LeaseRequestDto>(),
  lease: <LeaseRequestDto>{},
  sumLeaseModel: <any>{},
  isLoadingLeasesList: false,
  isLoadingLeasesListByAffair: false,
  isLoadingDuplicatingLease: false,
  isLoadingLeasesListArchived: false,
  isLoadingLease: false,
  isCreatingLease: false,
  isUpdatingLease: false,
  isDeletingLease: false,
  isArchivingLease: false,
  requestStateLease: <IRequestState>{},
};

export const LeaseModule: Module<ILeaseState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
