import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { ProductRequestDto, ProductCategoryRequestDto } from "Api";
import { MutationTree } from "vuex";
import { ICatalogState } from "./states";
export enum CatalogMutations {
  SET_CATALOG = "SET_CATALOG",
  SET_CATALOG_ARCHIVED = "SET_CATALOG_ARCHIVED",
  SET_CATALOG_SORTED_BY_PRODUCT_TYPE = "SET_CATALOG_SORTED_BY_PRODUCT_TYPE",
  SET_PRODUCT = "SET_PRODUCT",
  SET_REQUEST_STATE_CATALOG = "SET_REQUEST_STATE_CATALOG",
  SET_IS_CATALOG_LOADING = "SET_IS_CATALOG_LOADING",
  SET_IS_CATALOG_ARCHIVED_LOADING = "SET_IS_CATALOG_ARCHIVED_LOADING",
  SET_PRODUCT_CATEGORY_TREE = "SET_PRODUCT_CATEGORY_TREE",
  SET_IS_CREATING_PRODUCT = "SET_IS_CREATING_PRODUCT",
  SET_IS_UPDATING_PRODUCT = "SET_IS_UPDATING_PRODUCT",
  SET_IS_ARCHIVING_PRODUCT = "SET_IS_ARCHIVING_PRODUCT",
  SET_IS_DELETING_PRODUCT = "SET_IS_DELETING_PRODUCT",
  SET_IS_PRODUCT_CATEGORY_TREE_LOADING = "SET_IS_PRODUCT_CATEGORY_TREE_LOADING",
  SET_IS_LOADING_PRODUCT = "SET_IS_LOADING_PRODUCT",
  SET_WORK_ELEMENT_PRODUCT = "SET_WORK_ELEMENT_PRODUCT",
  SET_IS_LOADING_PRODUCT_ELEMENTS = "SET_IS_LOADING_PRODUCT_ELEMENTS",
  SET_IS_LOADING_CATALOG = "SET_IS_LOADING_CATALOG",
  SET_IS_FIRST_LOADING_CATALOG = "SET_IS_FIRST_LOADING_CATALOG",
  SET_IS_FIRST_LOADING_PRODUCT = "SET_IS_FIRST_LOADING_PRODUCT",
  SET_WORK_ELEMENT_DETAILS = "SET_WORK_ELEMENT_DETAILS",
  SET_WORK_ELEMENT_DETAIL = "SET_WORK_ELEMENT_DETAIL",
  SET_IS_DELETING_WORK_ELEMENT = "SET_IS_DELETING_WORK_ELEMENT",
  SET_WORK_ELEMENT_VALIDATE = "SET_WORK_ELEMENT_VALIDATE",
}
export const mutations: MutationTree<ICatalogState> = {
  [CatalogMutations.SET_CATALOG]: (state, payload: ProductRequestDto[]) => {
    state.catalog = payload;
  },
  [CatalogMutations.SET_CATALOG_ARCHIVED]: (
    state,
    payload: ProductRequestDto[]
  ) => {
    state.catalogArchived = payload;
  },
  [CatalogMutations.SET_CATALOG_SORTED_BY_PRODUCT_TYPE]: (
    state,
    payload: []
  ) => {
    state.catalogSortedByProductType = payload;
  },
  [CatalogMutations.SET_PRODUCT]: (state, payload: ProductRequestDto) => {
    state.product = payload;
  },

  [CatalogMutations.SET_REQUEST_STATE_CATALOG]: (
    state,
    payload: IRequestState
  ) => {
    state.requestStateCatalog = payload;
  },
  [CatalogMutations.SET_IS_CATALOG_LOADING]: (state, payload: boolean) => {
    state.isCatalogLoading = payload;
  },
  [CatalogMutations.SET_IS_CATALOG_ARCHIVED_LOADING]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingCatalogArchived = payload;
  },
  [CatalogMutations.SET_IS_FIRST_LOADING_CATALOG]: (
    state,
    payload: boolean
  ) => {
    state.isFirstLoadingCatalog = payload;
  },
  [CatalogMutations.SET_IS_FIRST_LOADING_PRODUCT]: (
    state,
    payload: boolean
  ) => {
    state.isFirstLoadingProduct = payload;
  },

  [CatalogMutations.SET_PRODUCT_CATEGORY_TREE]: (
    state,
    payload: ProductCategoryRequestDto
  ) => {
    state.productCategoryTree = payload;
  },

  [CatalogMutations.SET_IS_PRODUCT_CATEGORY_TREE_LOADING]: (
    state,
    payload: boolean
  ) => {
    state.isProductCategoryTreeLoading = payload;
  },
  [CatalogMutations.SET_IS_CREATING_PRODUCT]: (state, payload: boolean) => {
    state.isCreatingProduct = payload;
  },
  [CatalogMutations.SET_IS_LOADING_PRODUCT]: (state, payload: boolean) => {
    state.isLoadingProduct = payload;
  },
  [CatalogMutations.SET_IS_LOADING_CATALOG]: (state, payload: boolean) => {
    state.isLoadingCatalog = payload;
  },
  [CatalogMutations.SET_IS_UPDATING_PRODUCT]: (state, payload: boolean) => {
    state.isUpdatingProduct = payload;
  },
  [CatalogMutations.SET_IS_ARCHIVING_PRODUCT]: (state, payload: boolean) => {
    state.isArchivingCatalog = payload;
  },
  [CatalogMutations.SET_IS_DELETING_PRODUCT]: (state, payload: boolean) => {
    state.isDeletingProduct = payload;
  },
  [CatalogMutations.SET_WORK_ELEMENT_PRODUCT]: (
    state,
    payload: ProductRequestDto
  ) => {
    state.workElementProduct = payload;
  },
  [CatalogMutations.SET_IS_LOADING_PRODUCT_ELEMENTS]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingProductElements = payload;
  },

  [CatalogMutations.SET_WORK_ELEMENT_DETAILS]: (state, payload: any[]) => {
    state.workElementDetails = payload;
  },
  [CatalogMutations.SET_WORK_ELEMENT_DETAIL]: (state, payload: any) => {
    state.workElementDetail = payload;
  },
  [CatalogMutations.SET_IS_DELETING_WORK_ELEMENT]: (
    state,
    payload: boolean
  ) => {
    state.isDeletingWorkElement = payload;
  },
  [CatalogMutations.SET_WORK_ELEMENT_VALIDATE]: (state, payload: boolean) => {
    state.workElementValidate = payload;
  },
};
