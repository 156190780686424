import store from "@/store";

export function getNewIndex(rootGetters, payload) {
  return new Promise((resolve) => {
    if (rootGetters.length == 0 || payload.indexArray == -1) {
      if (payload.type == 11) payload.type = 0;
      resolve({ index: "1", type: payload.type });
    } else {
      let lastLine = rootGetters[payload.indexArray];
      let decomposeLastIndex = lastLine.index.split(".");
      let newIndex: string;
      if (decomposeLastIndex.length == 1) {
        if (payload.type == 0) {
          newIndex = (parseInt(decomposeLastIndex[0]) + 1).toString();
          resolve({ index: newIndex, type: payload.type });
        } else {
          if (lastLine.type == 0) {
            newIndex = decomposeLastIndex[0] + ".1";
            resolve({ index: newIndex, type: payload.type });
          } else if (
            payload.type == 14 ||
            payload.type == 15 ||
            payload.type == 16 ||
            payload.type == 17 ||
            payload.type == 18
          ) {
            newIndex = decomposeLastIndex[0] + ".1";
            resolve({ index: newIndex, type: payload.type });
          } else {
            newIndex = (parseInt(decomposeLastIndex[0]) + 1).toString();
            resolve({ index: newIndex, type: payload.type });
          }
        }
      } else if (decomposeLastIndex.length == 2) {
        if (payload.type == 0) {
          newIndex = (parseInt(decomposeLastIndex[0]) + 1).toString();
          resolve({ index: newIndex, type: payload.type });
        } else if (payload.type == 11) {
          newIndex =
            decomposeLastIndex[0] + "." + (parseInt(decomposeLastIndex[1]) + 1);
          resolve({ index: newIndex, type: payload.type });
        } else if (
          payload.type == 14 ||
          payload.type == 15 ||
          payload.type == 16 ||
          payload.type == 17 ||
          payload.type == 18
        ) {
          // Gestion du cas des lignes d'ouvrage
          if (
            lastLine.type == 14 ||
            lastLine.type == 15 ||
            lastLine.type == 16 ||
            lastLine.type == 17 ||
            lastLine.type == 18
          ) {
            newIndex =
              decomposeLastIndex[0] +
              "." +
              (parseInt(decomposeLastIndex[1]) + 1);
            resolve({ index: newIndex, type: payload.type });
          } else {
            newIndex =
              decomposeLastIndex[0] + "." + decomposeLastIndex[1] + ".1";
            resolve({ index: newIndex, type: payload.type });
          }
        } else {
          if (lastLine.type == 11) {
            newIndex =
              decomposeLastIndex[0] + "." + decomposeLastIndex[1] + ".1";
            resolve({ index: newIndex, type: payload.type });
          } else if (
            (payload.type !== 14 ||
              payload.type !== 15 ||
              payload.type !== 16 ||
              payload.type !== 17 ||
              payload.type !== 18) &&
            (lastLine.type == 14 ||
              lastLine.type == 15 ||
              lastLine.type == 16 ||
              lastLine.type == 17 ||
              lastLine.type == 18)
          ) {
            newIndex = (parseInt(decomposeLastIndex[0]) + 1).toString();
            resolve({ index: newIndex, type: payload.type });
          } else {
            newIndex =
              decomposeLastIndex[0] +
              "." +
              (parseInt(decomposeLastIndex[1]) + 1);
            resolve({ index: newIndex, type: payload.type });
          }
        }
      } else if (decomposeLastIndex.length == 3) {
        if (payload.type == 0) {
          newIndex = (parseInt(decomposeLastIndex[0]) + 1).toString();
          resolve({ index: newIndex, type: payload.type });
        } else if (
          payload.type == 14 ||
          payload.type == 15 ||
          payload.type == 16 ||
          payload.type == 17 ||
          payload.type == 18
        ) {
          // Gestion du cas des lignes d'ouvrage
          if (
            lastLine.type == 14 ||
            lastLine.type == 15 ||
            lastLine.type == 16 ||
            lastLine.type == 17 ||
            lastLine.type == 18
          ) {
            newIndex =
              decomposeLastIndex[0] +
              "." +
              decomposeLastIndex[1] +
              "." +
              (parseInt(decomposeLastIndex[2]) + 1);
            resolve({ index: newIndex, type: payload.type });
          } else {
            newIndex =
              decomposeLastIndex[0] +
              "." +
              decomposeLastIndex[1] +
              "." +
              decomposeLastIndex[2] +
              ".1";
            resolve({ index: newIndex, type: payload.type });
          }
        } else if (payload.type == 11) {
          newIndex =
            decomposeLastIndex[0] + "." + (parseInt(decomposeLastIndex[1]) + 1);
          resolve({ index: newIndex, type: payload.type });
        } else if (
          lastLine.type == 14 ||
          lastLine.type == 15 ||
          lastLine.type == 16 ||
          lastLine.type == 17 ||
          lastLine.type == 18
        ) {
          newIndex =
            decomposeLastIndex[0] + "." + (parseInt(decomposeLastIndex[1]) + 1);
          resolve({ index: newIndex, type: payload.type });
        } else {
          newIndex =
            decomposeLastIndex[0] +
            "." +
            decomposeLastIndex[1] +
            "." +
            (parseInt(decomposeLastIndex[2]) + 1);
          resolve({ index: newIndex, type: payload.type });
        }
      } else if (decomposeLastIndex.length == 4) {
        if (payload.type == 0) {
          newIndex = (parseInt(decomposeLastIndex[0]) + 1).toString();
          resolve({ index: newIndex, type: payload.type });
        } else if (
          payload.type == 14 ||
          payload.type == 15 ||
          payload.type == 16 ||
          payload.type == 17 ||
          payload.type == 18
        ) {
          // Gestion du cas des lignes d'ouvrage
          if (
            lastLine.type == 14 ||
            lastLine.type == 15 ||
            lastLine.type == 16 ||
            lastLine.type == 17 ||
            lastLine.type == 18
          ) {
            newIndex =
              decomposeLastIndex[0] +
              "." +
              decomposeLastIndex[1] +
              "." +
              decomposeLastIndex[2] +
              "." +
              (parseInt(decomposeLastIndex[3]) + 1);
            resolve({ index: newIndex, type: payload.type });
          } else {
            newIndex =
              decomposeLastIndex[0] +
              "." +
              decomposeLastIndex[1] +
              "." +
              decomposeLastIndex[2] +
              "." +
              decomposeLastIndex[3] +
              ".1";
            resolve({ index: newIndex, type: payload.type });
          }
        } else if (payload.type == 11) {
          newIndex =
            decomposeLastIndex[0] + "." + (parseInt(decomposeLastIndex[1]) + 1);
          resolve({ index: newIndex, type: payload.type });
        } else if (
          lastLine.type == 14 ||
          lastLine.type == 15 ||
          lastLine.type == 16 ||
          lastLine.type == 17 ||
          lastLine.type == 18
        ) {
          newIndex =
            decomposeLastIndex[0] +
            "." +
            decomposeLastIndex[1] +
            "." +
            (parseInt(decomposeLastIndex[2]) + 1);
          resolve({ index: newIndex, type: payload.type });
        } else {
          newIndex =
            decomposeLastIndex[0] +
            "." +
            decomposeLastIndex[1] +
            "." +
            decomposeLastIndex[2] +
            "." +
            (parseInt(decomposeLastIndex[3]) + 1);
          resolve({ index: newIndex, type: payload.type });
        }
      }
    }
  });
}

// TODO: A OPTIMISER
export function productMergeByTypeLine(
  line: any,
  catalogSortedByProductType: any
) {
  //if line.typeLine == 0 put key "Fournitures" at first position
  let catalog = catalogSortedByProductType;
  if (line.productType == 4) {
    let catalogData: any = [];
    let catalogDataDivers: any = [];
    for (let key in catalog) {
      if (key == "0") {
        catalogData = [
          ...catalogData,
          ...catalog[key].filter(
            (el) =>
              el.unAvailableForSale == false ||
              el.unAvailableForSale == undefined
          ),
        ];
      } else {
        catalogDataDivers = [
          ...catalogDataDivers,
          ...catalog[key].filter(
            (el) =>
              el.unAvailableForSale == false ||
              el.unAvailableForSale == undefined
          ),
        ];
      }
    }
    catalog = {
      Fourniture: catalogData,
      "Autres catégories": catalogDataDivers,
    };
  } else if (line.productType == 7) {
    let catalogData: any = [];
    let catalogDataDivers: any = [];
    for (let key in catalog) {
      if (key == "1") {
        catalogData = [
          ...catalogData,
          ...catalog[key].filter(
            (el) =>
              el.unAvailableForSale == false ||
              el.unAvailableForSale == undefined
          ),
        ];
      } else {
        catalogDataDivers = [
          ...catalogDataDivers,
          ...catalog[key].filter(
            (el) =>
              el.unAvailableForSale == false ||
              el.unAvailableForSale == undefined
          ),
        ];
      }
    }
    catalog = {
      "Sous-traitance": catalogData,
      "Autres catégories": catalogDataDivers,
    };
  } else if (line.productType == 8) {
    let catalogData: any = [];
    let catalogDataDivers: any = [];
    for (let key in catalog) {
      if (key == "2") {
        catalogData = [
          ...catalogData,
          ...catalog[key].filter(
            (el) =>
              el.unAvailableForSale == false ||
              el.unAvailableForSale == undefined
          ),
        ];
      } else {
        catalogDataDivers = [
          ...catalogDataDivers,
          ...catalog[key].filter(
            (el) =>
              el.unAvailableForSale == false ||
              el.unAvailableForSale == undefined
          ),
        ];
      }
    }
    catalog = { Matériel: catalogData, "Autres catégories": catalogDataDivers };
  } else if (line.productType == 6) {
    let catalogData: any = [];
    let catalogDataDivers: any = [];
    for (let key in catalog) {
      if (key == "3") {
        catalogData = [
          ...catalogData,
          ...catalog[key].filter(
            (el) =>
              el.unAvailableForSale == false ||
              el.unAvailableForSale == undefined
          ),
        ];
      } else {
        catalogDataDivers = [
          ...catalogDataDivers,
          ...catalog[key].filter(
            (el) =>
              el.unAvailableForSale == false ||
              el.unAvailableForSale == undefined
          ),
        ];
      }
    }
    catalog = { Ouvrage: catalogData, "Autres catégories": catalogDataDivers };
  } else if (line.productType == 1) {
    let catalogData: any = [];
    let catalogDataDivers: any = [];
    for (let key in catalog) {
      if (key == "4") {
        catalogData = [
          ...catalogData,
          ...catalog[key].filter(
            (el) =>
              el.unAvailableForSale == false ||
              el.unAvailableForSale == undefined
          ),
        ];
      } else {
        catalogDataDivers = [
          ...catalogDataDivers,
          ...catalog[key].filter(
            (el) =>
              el.unAvailableForSale == false ||
              el.unAvailableForSale == undefined
          ),
        ];
      }
    }
    catalog = { Texte: catalogData, "Autres catégories": catalogDataDivers };
  } else if (line.productType == 5) {
    let catalogData: any = [];
    let catalogDataDivers: any = [];
    for (let key in catalog) {
      if (key == "5") {
        catalogData = [
          ...catalogData,
          ...catalog[key].filter(
            (el) =>
              el.unAvailableForSale == false ||
              el.unAvailableForSale == undefined
          ),
        ];
      } else {
        catalogDataDivers = [
          ...catalogDataDivers,
          ...catalog[key].filter(
            (el) =>
              el.unAvailableForSale == false ||
              el.unAvailableForSale == undefined
          ),
        ];
      }
    }
    catalog = {
      "Main d'oeuvre": catalogData,
      "Autres catégories": catalogDataDivers,
    };
  } else {
    let catalogData: any = [];
    let catalogDataDivers: any = [];
    for (let key in catalog) {
      if (key == "6") {
        catalogData = [
          ...catalogData,
          ...catalog[key].filter(
            (el) =>
              el.unAvailableForSale == false ||
              el.unAvailableForSale == undefined
          ),
        ];
      } else {
        catalogDataDivers = [
          ...catalogDataDivers,
          ...catalog[key].filter(
            (el) =>
              el.unAvailableForSale == false ||
              el.unAvailableForSale == undefined
          ),
        ];
      }
    }
    catalog = { Autres: catalogData, "Autres catégories": catalogDataDivers };
  }
  return catalog;
}

export function calculateTotalDocumentLine(el: any) {
  let priceBrutHt = Math.round(el.unitPriceHt * el.quantity * 100) / 100;
  let advancement = 0;
  if (!store.getters.document.situationType) {
    advancement =
      store.getters.document.nature == 4 && el.type != 13
        ? Number(
            (
              (el.situationProgress - el.previousSituationProgress) /
              100
            ).toFixed(4)
          )
        : 1;
  } else {
    advancement =
      store.getters.document.nature == 4 && el.type != 13
        ? Number(
            (el.situationProgressQuantity -
              el.previousSituationProgressQuantity) /
              el.quantity
          )
        : 1;
  }
  let advancementPriceBrutHt =
    Math.round(priceBrutHt * advancement * 100) / 100;
  let discount = el.discount
    ? Math.round(((100 - el.discount) / 100) * 100) / 100
    : 1;
  return Math.round(advancementPriceBrutHt * discount * 100) / 100;
}

export function calculateTotalOrderFormCustomerLine(el: any) {
  let priceBrutHt = Math.round(el.unitPriceHt * el.quantity * 100) / 100;
  let discount = el.discount
    ? Math.round(((100 - el.discount) / 100) * 100) / 100
    : 1;
  return Math.round(priceBrutHt * discount * 100) / 100;
}

export function productsListByTypeLine(line, array, keywordsCatalog) {
  if (keywordsCatalog.length > 1) {
    var strippedHtml = keywordsCatalog.replace(/<br>/g, " ");
    strippedHtml = keywordsCatalog.replace(/<[^>]+>/g, "");
    var words = strippedHtml.split(" ");
    return Object.keys(productMergeByTypeLine(line, array)).reduce(
      (acc, key) => {
        acc[key] = productMergeByTypeLine(line, array)[key].filter(
          (product) => {
            if (product.description && product.reference && product.label) {
              return words.some(
                (v) =>
                  product.description
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase()
                    .includes(v.toLowerCase()) ||
                  product.reference
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase()
                    .includes(v.toLowerCase()) ||
                  product.label
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase()
                    .includes(v.toLowerCase())
              );
            } else if (product.description && product.reference) {
              return words.some(
                (v) =>
                  product.description
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase()
                    .includes(v.toLowerCase()) ||
                  product.reference
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase()
                    .includes(v.toLowerCase())
              );
            } else if (product.description && product.label) {
              return words.some(
                (v) =>
                  product.description
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase()
                    .includes(v.toLowerCase()) ||
                  product.label
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase()
                    .includes(v.toLowerCase())
              );
            } else if (product.reference && product.label) {
              return words.some(
                (v) =>
                  product.reference
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase()
                    .includes(v.toLowerCase()) ||
                  product.label
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase()
                    .includes(v.toLowerCase())
              );
            } else if (product.description) {
              return words.some((v) =>
                product.description
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLowerCase()
                  .includes(v.toLowerCase())
              );
            } else if (product.reference) {
              return words.some((v) =>
                product.reference
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLowerCase()
                  .includes(v.toLowerCase())
              );
            } else if (product.label) {
              return words.some((v) =>
                product.label
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLowerCase()
                  .includes(v.toLowerCase())
              );
            }
          }
        );
        return acc;
      },
      {}
    );
  } else {
    return productMergeByTypeLine(line, array);
  }
}
