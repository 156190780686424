import {
  ReportDetailPayAllowanceTypeRequestDto,
  ReportDetailRequestDto,
} from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { ReportDetailMutations } from "./mutations";
import { IReportDetailState } from "./states";
import { Timezone } from "@syncfusion/ej2-schedule";
import { generateParams } from "@/types/api-orisis/library/FormatOperations";
import dayjs from "dayjs";
import { update } from "@syncfusion/ej2-vue-inplace-editor";

let timezoneSchedule = new Timezone();

export const actions: ActionTree<IReportDetailState, RootState> = {
  getReportDetailById(
    { commit, rootGetters },
    {
      reportDetailId,
      updateState = true,
    }: { reportDetailId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(ReportDetailMutations.SET_IS_LOADING_REPORT_DETAIL, true);
      }
      axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Reporting/ReportDetail/GetReportDetailById`,
          {
            params: {
              reportDetailId: reportDetailId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(ReportDetailMutations.SET_REPORT_DETAIL, res.data);
            commit(ReportDetailMutations.SET_IS_LOADING_REPORT_DETAIL, false);
          }
          commit(ReportDetailMutations.SET_REQUEST_STATE_REPORT_DETAIL, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ReportDetailMutations.SET_REQUEST_STATE_REPORT_DETAIL, err);
          if (updateState) {
            commit(ReportDetailMutations.SET_IS_LOADING_REPORT_DETAIL, false);
          }
          reject(err);
        });
    });
  },

  getReportDetailsMinified(
    { commit },
    {
      startDate,
      endDate,
      collaboratorIds = [],
      reportDetailIds = [],
      globalReport = false,
      updateState = true,
    }: {
      startDate: Date;
      endDate: Date;
      collaboratorIds: number[];
      reportDetailIds: number[];
      globalReport: boolean;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          ReportDetailMutations.SET_IS_LOADING_REPORT_DETAILS_MINIFIED_LIST,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Reporting/ReportDetail/GetReportDetailsMinified`;
      let request = new URLSearchParams();
      request = generateParams(request, collaboratorIds, "collaboratorIds");
      request = generateParams(request, reportDetailIds, "reportDetailIds");
      request = generateParams(request, [globalReport], "globalReport");
      request = generateParams(
        request,
        [dayjs(startDate).format("YYYY-MM-DD")],
        "startDate"
      );
      request = generateParams(
        request,
        [
          dayjs(endDate)
            .hour(23)
            .minute(59)
            .second(59)
            .format("YYYY-MM-DD HH:mm:ss"),
        ],
        "endDate"
      );
      axios
        .get(url, {
          params: request,
        })
        .then((res) => {
          let data = res.data.map((el: any) => {
            return {
              ...el,
              reportDate: el.startDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(new Date(el.startDate))
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
            };
          });
          if (updateState) {
            commit(
              ReportDetailMutations.SET_IS_LOADING_REPORT_DETAILS_MINIFIED_LIST,
              false
            );
            commit(
              ReportDetailMutations.SET_REPORT_DETAILS_MINIFIED_LIST,
              data
            );
          }
          commit(ReportDetailMutations.SET_REQUEST_STATE_REPORT_DETAIL, res);
          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              ReportDetailMutations.SET_IS_LOADING_REPORT_DETAILS_MINIFIED_LIST,
              false
            );
          }
          commit(ReportDetailMutations.SET_REQUEST_STATE_REPORT_DETAIL, err);
          reject(err);
        });
    });
  },

  getReportDetails(
    { commit, rootGetters },
    {
      reportDetailIds = [],
      startDate,
      endDate,
      affairId = null,
      missionId = null,
      groupedByMission = false,
      collaboratorIds = [],
      updateState = true,
      route,
    }: {
      reportDetailIds: number[];
      startDate: Date;
      endDate: Date;
      affairId: number;
      missionId: number;
      groupedByMission: boolean;
      collaboratorIds: number[];
      updateState: boolean;
      route: string;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        switch (route) {
          case "collaborator":
            commit(
              ReportDetailMutations.SET_IS_LOADING_REPORT_DETAILS_LIST_BY_COLLABORATOR,
              true
            );
            commit(
              ReportDetailMutations.SET_REPORT_DETAILS_LIST_BY_COLLABORATOR,
              []
            );
            break;
          case "myDashboard":
            commit(
              ReportDetailMutations.SET_IS_LOADING_REPORT_DETAILS_LIST_BY_COLLABORATOR,
              true
            );
            commit(
              ReportDetailMutations.SET_REPORT_DETAILS_LIST_BY_COLLABORATOR,
              []
            );
          case "quickInfo":
            commit(ReportDetailMutations.SET_IS_LOADING_REPORTS_DETAIL, true);
            commit(ReportDetailMutations.SET_REPORTS_DETAIL, []);
            break;
          default:
            commit(
              ReportDetailMutations.SET_IS_LOADING_REPORT_DETAILS_LIST,
              true
            );
            commit(ReportDetailMutations.SET_REPORT_DETAILS_LIST, []);
            break;
        }
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Reporting/ReportDetail/GetReportDetails`;
      let request = new URLSearchParams();
      request = generateParams(request, reportDetailIds, "reportDetailIds");
      request = generateParams(request, collaboratorIds, "collaboratorIds");
      request = affairId
        ? generateParams(request, [affairId], "affairId")
        : request;
      request = missionId
        ? generateParams(request, [missionId], "missionId")
        : request;
      request = generateParams(request, [groupedByMission], "groupedByMission");
      request = startDate
        ? generateParams(
            request,
            [dayjs(startDate).format("YYYY-MM-DD")],
            "startDate"
          )
        : request;
      request = endDate
        ? generateParams(
            request,
            [
              dayjs(endDate)
                .hour(23)
                .minute(59)
                .second(59)
                .format("YYYY-MM-DD HH:mm:ss"),
            ],
            "endDate"
          )
        : request;
      axios
        .get(url, {
          params: request,
        })
        .then((res) => {
          let reports = res.data.map((report: any) => ({
            ...report,
            reportDate: report.startDate ? new Date(report.startDate) : null,
            copyAffairId: report.affairId ? report.affairId : null,
          }));

          if (updateState) {
            switch (route) {
              case "collaborator":
                reports =
                  rootGetters.reportDetailsListByCollaborator.concat(reports);
                commit(
                  ReportDetailMutations.SET_IS_LOADING_REPORT_DETAILS_LIST_BY_COLLABORATOR,
                  false
                );
                commit(
                  ReportDetailMutations.SET_REPORT_DETAILS_LIST_BY_COLLABORATOR,
                  reports
                );
                break;
              case "myDashboard":
                reports =
                  rootGetters.reportDetailsListByCollaborator.concat(reports);
                commit(
                  ReportDetailMutations.SET_IS_LOADING_REPORT_DETAILS_LIST_BY_COLLABORATOR,
                  false
                );
                commit(
                  ReportDetailMutations.SET_REPORT_DETAILS_LIST_BY_COLLABORATOR,
                  reports
                );
                break;
              case "quickInfo":
                reports = rootGetters.reportsDetail.concat(reports);
                commit(
                  ReportDetailMutations.SET_IS_LOADING_REPORTS_DETAIL,
                  false
                );
                commit(ReportDetailMutations.SET_REPORTS_DETAIL, reports);
                break;
              default:
                reports = rootGetters.reportDetailsList.concat(reports);
                commit(
                  ReportDetailMutations.SET_IS_LOADING_REPORT_DETAILS_LIST,
                  false
                );

                commit(ReportDetailMutations.SET_REPORT_DETAILS_LIST, reports);
                break;
            }
          }
          commit(ReportDetailMutations.SET_REQUEST_STATE_REPORT_DETAIL, res);
          resolve(reports);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            switch (route) {
              case "collaborator":
                commit(
                  ReportDetailMutations.SET_IS_LOADING_REPORT_DETAILS_LIST_BY_COLLABORATOR,
                  false
                );
                break;
              default:
                commit(
                  ReportDetailMutations.SET_IS_LOADING_REPORT_DETAILS_LIST,
                  false
                );
                break;
            }
          }
          commit(ReportDetailMutations.SET_REQUEST_STATE_REPORT_DETAIL, err);
          reject(err);
        });
    });
  },

  createReportDetail(
    { commit, rootGetters },
    { reportDetail }: { reportDetail: ReportDetailRequestDto }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(ReportDetailMutations.SET_IS_CREATING_REPORT_DETAIL, true);
      let url = `${process.env.VUE_APP_API_ORISIS}Reporting/ReportDetail/CreateReportDetail`;
      axios
        .post(url, reportDetail)
        .then((res) => {
          const foundPayrollEvent = rootGetters.payrollEventsList.find(
            (elem) => elem.id == res.data.data.payrollEventId
          );
          const newMinifiedList = rootGetters.reportDetailsMinifiedList.concat({
            id: res.data.data.id,
            startDate: res.data.data.startDate,
            duration: res.data.data.duration,
            collaboratorId: res.data.data.collaboratorId,
            collaboratorFullName: rootGetters.collaboratorsList.find(
              (elem) => elem.id == res.data.data.collaboratorId
            ).displayLabel,
            status: res.data.data.status,
            isValid: false,
            payrollEventId: res.data.data.payrollEventId,
            payrollEventLabel: foundPayrollEvent
              ? foundPayrollEvent.label
              : "Non spécifié",
            payrollEventColor: foundPayrollEvent
              ? foundPayrollEvent.color
              : "#FFFFFF",
            payAllowanceTypes: res.data.data.payAllowanceTypes,
          });
          commit(
            ReportDetailMutations.SET_REPORT_DETAILS_MINIFIED_LIST,
            newMinifiedList
          );
          commit(ReportDetailMutations.SET_REQUEST_STATE_REPORT_DETAIL, res);
          commit(ReportDetailMutations.SET_IS_CREATING_REPORT_DETAIL, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ReportDetailMutations.SET_REQUEST_STATE_REPORT_DETAIL, err);
          commit(ReportDetailMutations.SET_IS_CREATING_REPORT_DETAIL, false);
          reject(err);
        });
    });
  },

  //GenerateReportDetailsFromPlanning

  updateReportDetail(
    { commit, rootGetters },
    { reportDetail }: { reportDetail: ReportDetailRequestDto }
  ) {
    return new Promise<void>((resolve, reject) => {
      commit(ReportDetailMutations.SET_IS_UPDATING_REPORT_DETAIL, true);
      axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Reporting/ReportDetail/UpdateReportDetail`,
          reportDetail
        )
        .then((res) => {
          const foundPayrollEvent = rootGetters.payrollEventsList.find(
            (elem) => elem.id == res.data.data.payrollEventId
          );

          rootGetters.reportDetailsList.splice(
            rootGetters.reportDetailsList.findIndex(
              (elem) => elem.id == reportDetail.id
            ),
            1,
            reportDetail
          );
          rootGetters.reportDetailsMinifiedList.splice(
            rootGetters.reportDetailsMinifiedList.findIndex(
              (elem) => elem.id == reportDetail.id
            ),
            1,
            {
              id: res.data.data.id,
              startDate: res.data.data.startDate,
              duration: res.data.data.duration,
              collaboratorId: res.data.data.collaboratorId,
              collaboratorFullName: rootGetters.collaboratorsList.find(
                (elem) => elem.id == res.data.data.collaboratorId
              ).displayLabel,
              status: res.data.data.status,
              isValid: false,
              payrollEventId: res.data.data.payrollEventId,
              payrollEventLabel: foundPayrollEvent
                ? foundPayrollEvent.label
                : "Non spécifié",
              payrollEventColor: foundPayrollEvent
                ? foundPayrollEvent.color
                : "#FFFFFF",
              payAllowanceTypes: res.data.data.payAllowanceTypes,
            }
          );
          commit(
            ReportDetailMutations.SET_REPORT_DETAILS_LIST,
            rootGetters.reportDetailsList
          );
          commit(ReportDetailMutations.SET_REQUEST_STATE_REPORT_DETAIL, res);
          commit(ReportDetailMutations.SET_IS_UPDATING_REPORT_DETAIL, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ReportDetailMutations.SET_REQUEST_STATE_REPORT_DETAIL, err);
          commit(ReportDetailMutations.SET_IS_UPDATING_REPORT_DETAIL, false);
          reject(err);
        });
    });
  },

  updateReportDetailsPayAllowanceType(
    { commit, rootGetters },
    {
      reportDetailId,
      reportAllowanceTypes,
    }: {
      reportDetailId: number;
      reportAllowanceTypes: ReportDetailPayAllowanceTypeRequestDto[];
    }
  ) {
    return new Promise<void>((resolve, reject) => {
      commit(ReportDetailMutations.SET_IS_UPDATING_REPORT_DETAIL, true);
      axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Reporting/ReportDetail/UpdateReportPayAllowanceType",
          reportAllowanceTypes,
          {
            params: {
              reportDetailId: reportDetailId,
            },
          }
        )
        .then((res) => {
          if (
            rootGetters.reportDetailsList.find(
              (elem) => elem.id == reportDetailId
            )
          ) {
            rootGetters.reportDetailsList.splice(
              rootGetters.reportDetailsList.findIndex(
                (elem) => elem.id == reportDetailId
              ),
              1,
              res.data.data
            );
            commit(
              ReportDetailMutations.SET_REPORT_DETAILS_LIST,
              rootGetters.reportDetailsList
            );
          }
          if (
            rootGetters.reportDetailsMinifiedList.findIndex(
              (elem) => elem.id == reportDetailId
            ) !== -1
          ) {
            rootGetters.reportDetailsMinifiedList[
              rootGetters.reportDetailsMinifiedList.findIndex(
                (elem) => elem.id == reportDetailId
              )
            ].payAllowanceTypes = res.data.data.payAllowanceTypes;
          }
          commit(
            ReportDetailMutations.SET_REPORT_DETAILS_MINIFIED_LIST,
            rootGetters.reportDetailsMinifiedList
          );
          if (
            rootGetters.reportDetailsListByCollaborator.find(
              (elem) => elem.id == reportDetailId
            )
          ) {
            rootGetters.reportDetailsListByCollaborator.splice(
              rootGetters.reportDetailsListByCollaborator.findIndex(
                (elem) => elem.id == reportDetailId
              ),
              1,
              res.data.data
            );
            commit(
              ReportDetailMutations.SET_REPORT_DETAILS_LIST_BY_COLLABORATOR,
              rootGetters.reportDetailsListByCollaborator
            );
          }

          commit(ReportDetailMutations.SET_REQUEST_STATE_REPORT_DETAIL, res);
          commit(ReportDetailMutations.SET_IS_UPDATING_REPORT_DETAIL, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ReportDetailMutations.SET_REQUEST_STATE_REPORT_DETAIL, err);
          commit(ReportDetailMutations.SET_IS_UPDATING_REPORT_DETAIL, false);
          reject(err);
        });
    });
  },

  updateReportsStatus({ commit, rootGetters }, { data }: { data: any }) {
    return new Promise<void>((resolve, reject) => {
      commit(ReportDetailMutations.SET_IS_UPDATING_REPORT_DETAIL, true);
      axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Reporting/ReportDetail/UpdateReportsStatus",
          data
        )
        .then((res) => {
          for (const key in data) {
            const minifiedReport = rootGetters.reportDetailsMinifiedList.find(
              (elem) => elem.id == key
            );
            if (minifiedReport) {
              minifiedReport.status = data[key];
            }
            const report = rootGetters.reportDetailsList.find(
              (elem) => elem.id == key
            );
            if (report) {
              report.status = data[key];
            }
            const reportCollab =
              rootGetters.reportDetailsListByCollaborator.find(
                (elem) => elem.id == key
              );
            if (reportCollab) {
              reportCollab.status = data[key];
            }
          }
          commit(ReportDetailMutations.SET_REQUEST_STATE_REPORT_DETAIL, res);
          commit(ReportDetailMutations.SET_IS_UPDATING_REPORT_DETAIL, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ReportDetailMutations.SET_REQUEST_STATE_REPORT_DETAIL, err);
          commit(ReportDetailMutations.SET_IS_UPDATING_REPORT_DETAIL, false);
          reject(err);
        });
    });
  },

  deleteReportDetail(
    { commit, rootGetters },
    { reportDetailIds }: { reportDetailIds: number[] }
  ) {
    return new Promise<void>((resolve, reject) => {
      commit(ReportDetailMutations.SET_IS_DELETING_REPORT_DETAIL, true);
      axios
        .delete(
          `${process.env.VUE_APP_API_ORISIS}Reporting/ReportDetail/Delete`,
          {
            data: reportDetailIds,
          }
        )
        .then((res) => {
          reportDetailIds.forEach((reportDetailId) => {
            rootGetters.reportDetailsList.splice(
              rootGetters.reportDetailsList.findIndex(
                (elem) => elem.id == reportDetailId
              ),
              1
            );
            rootGetters.reportDetailsMinifiedList.splice(
              rootGetters.reportDetailsMinifiedList.findIndex(
                (elem) => elem.id == reportDetailId
              ),
              1
            );
          });
          commit(
            ReportDetailMutations.SET_REPORT_DETAILS_LIST,
            rootGetters.reportDetailsList
          );
          commit(ReportDetailMutations.SET_REQUEST_STATE_REPORT_DETAIL, res);
          commit(ReportDetailMutations.SET_IS_DELETING_REPORT_DETAIL, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ReportDetailMutations.SET_REQUEST_STATE_REPORT_DETAIL, err);
          commit(ReportDetailMutations.SET_IS_DELETING_REPORT_DETAIL, false);
          reject(err);
        });
    });
  },
};
