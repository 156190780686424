import { IInstitution } from '@/types/api-orisis/interfaces/invoices/institution/IInstitution';

export let InstitutionModel : IInstitution = {
  id: 0,
  name: null,
  legalForm: 5,
  address: null,
  addressComplement: null,
  zipCode: null,
  city: null,
  country: null,
  phoneNumber: null,
  secondaryPhoneNumber: null,
  email: null,
  webSite: null,
  registrationNumber: null,
  isSubjectTVA: true,
  tvaNumber: null,
  siret: null,
  rcsCity: null,
  capital: null,
  apeCode: null,
  guaranteeType: 1,
  insuranceName: null,
  insuranceCoverage: null,
  insuranceAddress: null,
  insuranceAddressComplement: null,
  insuranceZipCode: null,
  insuranceCity: null,
  insuranceCountry: null,
  logo: null,
  color: '#000000',
  secondaryColor: '#ffffff',
  longitude: null,
  latitude: null,
  code: null,
  isDefault: false,
  logoWidth: 110,
}