import { CustomFieldGroupRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { CustomFieldGroupMutations } from "./mutations";
import { ICustomFieldGroupState } from "./states";
var dayjs = require("dayjs");

export const actions: ActionTree<ICustomFieldGroupState, RootState> = {
  async getCustomFieldGroups(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          CustomFieldGroupMutations.SET_IS_LOADING_CUSTOM_FIELD_GROUP_LIST,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Parameters/CustomFieldGroup/GetCustomFieldGroups`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              CustomFieldGroupMutations.SET_IS_LOADING_CUSTOM_FIELD_GROUP_LIST,
              false
            );
            commit(
              CustomFieldGroupMutations.SET_CUSTOM_FIELD_GROUPS_LIST,
              res.data
            );
          }
          commit(
            CustomFieldGroupMutations.SET_REQUEST_STATE_CUSTOM_FIELD_GROUP,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CustomFieldGroupMutations.SET_IS_LOADING_CUSTOM_FIELD_GROUP_LIST,
            false
          );
          commit(
            CustomFieldGroupMutations.SET_REQUEST_STATE_CUSTOM_FIELD_GROUP,
            err
          );
          reject(err);
        });
    });
  },

  async getCustomFieldGroupById(
    { commit },
    {
      customFieldGroupId,
      updateState = true,
    }: { customFieldGroupId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          CustomFieldGroupMutations.SET_IS_LOADING_CUSTOM_FIELD_GROUP,
          true
        );
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Parameters/CustomFieldGroup/GetCustomFieldGroupById`,
          {
            params: {
              customFieldGroupId: customFieldGroupId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(CustomFieldGroupMutations.SET_CUSTOM_FIELD_GROUP, res.data);
            commit(
              CustomFieldGroupMutations.SET_IS_LOADING_CUSTOM_FIELD_GROUP,
              false
            );
          }
          commit(
            CustomFieldGroupMutations.SET_REQUEST_STATE_CUSTOM_FIELD_GROUP,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CustomFieldGroupMutations.SET_REQUEST_STATE_CUSTOM_FIELD_GROUP,
            err
          );
          commit(
            CustomFieldGroupMutations.SET_IS_LOADING_CUSTOM_FIELD_GROUP,
            false
          );
          reject(err);
        });
    });
  },

  async getCustomFieldGroupsArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          CustomFieldGroupMutations.SET_IS_LOADING_CUSTOM_FIELD_GROUP_LIST_ARCHIVED,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Parameters/CustomFieldGroup/GetCustomFieldGroupsArchived`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              CustomFieldGroupMutations.SET_IS_LOADING_CUSTOM_FIELD_GROUP_LIST_ARCHIVED,
              false
            );
            commit(
              CustomFieldGroupMutations.SET_CUSTOM_FIELD_GROUPS_LIST_ARCHIVED,
              res.data
            );
          }
          commit(
            CustomFieldGroupMutations.SET_REQUEST_STATE_CUSTOM_FIELD_GROUP,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CustomFieldGroupMutations.SET_IS_LOADING_CUSTOM_FIELD_GROUP_LIST,
            false
          );
          commit(
            CustomFieldGroupMutations.SET_REQUEST_STATE_CUSTOM_FIELD_GROUP,
            err
          );
          reject(err);
        });
    });
  },

  async getCustomFieldGroupArchivedById(
    { commit },
    {
      customFieldGroupId,
      updateState = true,
    }: { customFieldGroupId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          CustomFieldGroupMutations.SET_IS_LOADING_CUSTOM_FIELD_GROUP,
          true
        );
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Parameters/CustomFieldGroup/GetCustomFieldGroupArchivedById`,
          {
            params: {
              customFieldGroupId: customFieldGroupId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(CustomFieldGroupMutations.SET_CUSTOM_FIELD_GROUP, res.data);
            commit(
              CustomFieldGroupMutations.SET_IS_LOADING_CUSTOM_FIELD_GROUP,
              false
            );
          }
          commit(
            CustomFieldGroupMutations.SET_REQUEST_STATE_CUSTOM_FIELD_GROUP,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CustomFieldGroupMutations.SET_REQUEST_STATE_CUSTOM_FIELD_GROUP,
            err
          );
          commit(
            CustomFieldGroupMutations.SET_IS_LOADING_CUSTOM_FIELD_GROUP,
            false
          );
          reject(err);
        });
    });
  },

  async createCustomFieldGroup(
    { commit, rootGetters },
    { customFieldGroup }: { customFieldGroup: CustomFieldGroupRequestDto }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        CustomFieldGroupMutations.SET_IS_CREATING_CUSTOM_FIELD_GROUP,
        true
      );
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Parameters/CustomFieldGroup/CreateCustomFieldGroup`,
          customFieldGroup
        )
        .then((res) => {
          rootGetters.customFieldGroupsList.push(res.data.data);
          commit(
            CustomFieldGroupMutations.SET_CUSTOM_FIELD_GROUPS_LIST,
            rootGetters.customFieldGroupsList
          );
          commit(
            CustomFieldGroupMutations.SET_REQUEST_STATE_CUSTOM_FIELD_GROUP,
            res
          );
          commit(
            CustomFieldGroupMutations.SET_IS_CREATING_CUSTOM_FIELD_GROUP,
            false
          );
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CustomFieldGroupMutations.SET_REQUEST_STATE_CUSTOM_FIELD_GROUP,
            err
          );
          commit(
            CustomFieldGroupMutations.SET_IS_CREATING_CUSTOM_FIELD_GROUP,
            false
          );
          reject(err);
        });
    });
  },

  async updateCustomFieldGroup(
    { commit, rootGetters },
    { customFieldGroup }: { customFieldGroup: CustomFieldGroupRequestDto }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        CustomFieldGroupMutations.SET_IS_UPDATING_CUSTOM_FIELD_GROUP,
        true
      );
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Parameters/CustomFieldGroup/UpdateCustomFieldGroup`,
          customFieldGroup,
        )
        .then((res) => {
          if (
            rootGetters.customFieldGroupsList.find(
              (elem) => elem.id == customFieldGroup.id
            )
          ) {
            rootGetters.customFieldGroupsList.splice(
              rootGetters.customFieldGroupsList.findIndex(
                (elem) => elem.id == customFieldGroup.id
              ),
              1,
              customFieldGroup
            );
            commit(
              CustomFieldGroupMutations.SET_CUSTOM_FIELD_GROUPS_LIST,
              rootGetters.customFieldGroupsList
            );
          }

          if (
            rootGetters.customFieldGroupsListArchived.find(
              (elem) => elem.id == customFieldGroup.id
            )
          ) {
            rootGetters.customFieldGroupsListArchived.splice(
              rootGetters.customFieldGroupsListArchived.findIndex(
                (elem) => elem.id == customFieldGroup.id
              ),
              1,
              customFieldGroup
            );
            commit(
              CustomFieldGroupMutations.SET_CUSTOM_FIELD_GROUPS_LIST_ARCHIVED,
              rootGetters.customFieldGroupsListArchived
            );
          }

          commit(
            CustomFieldGroupMutations.SET_REQUEST_STATE_CUSTOM_FIELD_GROUP,
            res
          );
          commit(
            CustomFieldGroupMutations.SET_IS_UPDATING_CUSTOM_FIELD_GROUP,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CustomFieldGroupMutations.SET_REQUEST_STATE_CUSTOM_FIELD_GROUP,
            err
          );
          commit(
            CustomFieldGroupMutations.SET_IS_UPDATING_CUSTOM_FIELD_GROUP,
            false
          );
          reject(err);
        });
    });
  },

  async restoreCustomFieldGroups(
    { commit, rootGetters },
    { customFieldGroupIds = [] }: { customFieldGroupIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        CustomFieldGroupMutations.SET_IS_UPDATING_CUSTOM_FIELD_GROUP,
        true
      );
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Parameters/CustomFieldGroup/Restore",
          customFieldGroupIds,
        )
        .then(async (res) => {
          customFieldGroupIds.forEach((customFieldGroupId: number) => {
            let customFieldGroup =
              rootGetters.customFieldGroupsListArchived.find(
                (customFieldGroup: CustomFieldGroupRequestDto) =>
                  customFieldGroup.id == customFieldGroupId
              );
            rootGetters.customFieldGroupsList.unshift(customFieldGroup);

            if (
              rootGetters.customFieldGroupsListArchived.find(
                (customFieldGroup: CustomFieldGroupRequestDto) =>
                  customFieldGroup.id == customFieldGroupId
              )
            ) {
              rootGetters.customFieldGroupsListArchived.splice(
                rootGetters.customFieldGroupsListArchived.findIndex(
                  (customFieldGroup: CustomFieldGroupRequestDto) =>
                    customFieldGroup.id == customFieldGroupId
                ),
                1
              );
            }
          });
          commit(
            CustomFieldGroupMutations.SET_CUSTOM_FIELD_GROUPS_LIST,
            rootGetters.customFieldGroupsList
          );
          commit(
            CustomFieldGroupMutations.SET_CUSTOM_FIELD_GROUPS_LIST_ARCHIVED,
            rootGetters.customFieldGroupsListArchived
          );
          commit(
            CustomFieldGroupMutations.SET_REQUEST_STATE_CUSTOM_FIELD_GROUP,
            res
          );
          commit(
            CustomFieldGroupMutations.SET_IS_UPDATING_CUSTOM_FIELD_GROUP,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CustomFieldGroupMutations.SET_REQUEST_STATE_CUSTOM_FIELD_GROUP,
            err
          );
          commit(
            CustomFieldGroupMutations.SET_IS_UPDATING_CUSTOM_FIELD_GROUP,
            false
          );
          reject(err);
        });
    });
  },

  async archiveCustomFieldGroups(
    { commit, rootGetters },
    { customFieldGroupIds = [] }: { customFieldGroupIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        CustomFieldGroupMutations.SET_IS_ARCHIVING_CUSTOM_FIELD_GROUP,
        true
      );
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS +
            "Parameters/CustomFieldGroup/Archive",
          {
            data: customFieldGroupIds,
          }
        )
        .then((res) => {
          customFieldGroupIds.forEach((customFieldGroupId: number) => {
            let customFieldGroup = rootGetters.customFieldGroupsList.find(
              (customFieldGroup: CustomFieldGroupRequestDto) =>
                customFieldGroup.id == customFieldGroupId
            );
            rootGetters.customFieldGroupsListArchived.unshift(customFieldGroup);

            if (
              rootGetters.customFieldGroupsList.find(
                (customFieldGroup: CustomFieldGroupRequestDto) =>
                  customFieldGroup.id == customFieldGroupId
              )
            ) {
              rootGetters.customFieldGroupsList.splice(
                rootGetters.customFieldGroupsList.findIndex(
                  (customFieldGroup: CustomFieldGroupRequestDto) =>
                    customFieldGroup.id == customFieldGroupId
                ),
                1
              );
            }
          });
          commit(
            CustomFieldGroupMutations.SET_CUSTOM_FIELD_GROUPS_LIST,
            rootGetters.customFieldGroupsList
          );
          commit(
            CustomFieldGroupMutations.SET_CUSTOM_FIELD_GROUPS_LIST_ARCHIVED,
            rootGetters.customFieldGroupsListArchived
          );
          commit(
            CustomFieldGroupMutations.SET_REQUEST_STATE_CUSTOM_FIELD_GROUP,
            res
          );
          commit(
            CustomFieldGroupMutations.SET_IS_ARCHIVING_CUSTOM_FIELD_GROUP,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CustomFieldGroupMutations.SET_REQUEST_STATE_CUSTOM_FIELD_GROUP,
            err
          );
          commit(
            CustomFieldGroupMutations.SET_IS_ARCHIVING_CUSTOM_FIELD_GROUP,
            false
          );
          reject(err);
        });
    });
  },

  async deleteCustomFieldGroups(
    { commit, rootGetters },
    { customFieldGroupIds = [] }: { customFieldGroupIds: number[] }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        CustomFieldGroupMutations.SET_IS_DELETING_CUSTOM_FIELD_GROUP,
        true
      );
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Parameters/CustomFieldGroup/Delete",
          {
            data: customFieldGroupIds,
          }
        )
        .then((res) => {
          customFieldGroupIds.forEach((customFieldGroupId: number) => {
            if (
              rootGetters.customFieldGroupsList.find(
                (customFieldGroup: CustomFieldGroupRequestDto) =>
                  customFieldGroup.id == customFieldGroupId
              )
            ) {
              rootGetters.customFieldGroupsList.splice(
                rootGetters.customFieldGroupsList.findIndex(
                  (customFieldGroup: CustomFieldGroupRequestDto) =>
                    customFieldGroup.id == customFieldGroupId
                ),
                1
              );
            }

            if (
              rootGetters.customFieldGroupsListArchived.find(
                (customFieldGroup: CustomFieldGroupRequestDto) =>
                  customFieldGroup.id == customFieldGroupId
              )
            ) {
              rootGetters.customFieldGroupsListArchived.splice(
                rootGetters.customFieldGroupsListArchived.findIndex(
                  (customFieldGroup: CustomFieldGroupRequestDto) =>
                    customFieldGroup.id == customFieldGroupId
                ),
                1
              );
            }
          });
          commit(
            CustomFieldGroupMutations.SET_CUSTOM_FIELD_GROUPS_LIST,
            rootGetters.customFieldGroupsList
          );
          commit(
            CustomFieldGroupMutations.SET_CUSTOM_FIELD_GROUPS_LIST_ARCHIVED,
            rootGetters.customFieldGroupsListArchived
          );

          commit(
            CustomFieldGroupMutations.SET_REQUEST_STATE_CUSTOM_FIELD_GROUP,
            res
          );
          commit(
            CustomFieldGroupMutations.SET_IS_DELETING_CUSTOM_FIELD_GROUP,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CustomFieldGroupMutations.SET_REQUEST_STATE_CUSTOM_FIELD_GROUP,
            err
          );
          commit(
            CustomFieldGroupMutations.SET_IS_DELETING_CUSTOM_FIELD_GROUP,
            false
          );
          reject(err);
        });
    });
  },
};
