import { IExportState } from "./states";
import { MutationTree } from "vuex";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";


export enum ExportMutations {
    SET_EXPORT = "SET_EXPORT",
    SET_EXPORT_URL = "SET_EXPORT_URL",
    SET_EXPORT_SALES_JOURNAL_CSV = "SET_EXPORT_SALES_JOURNAL_CSV",
    
    // COMON 
    SET_IS_LOADING_EXPORT = "SET_IS_LOADING_EXPORT",
    SET_IS_LOADING_EXPORT_URL = "SET_IS_LOADING_EXPORT_URL",
    SET_IS_LOADING_INVOICES_CREDITS_TIME_SPAN = "SET_IS_LOADING_INVOICES_CREDITS_TIME_SPAN",
    SET_IS_LOADING_EXPORT_SALES_JOURNAL_CSV = "SET_IS_LOADING_EXPORT_SALES_JOURNAL_CSV",
    SET_REQUEST_STATE_EXPORT = "SET_REQUEST_STATE_EXPORT",
  }

  export const mutations: MutationTree<IExportState> = {
    [ExportMutations.SET_EXPORT]: (state, payload: any) => {
        state.exportAccounting = payload;
    },
    [ExportMutations.SET_EXPORT_URL]: (state, payload: any) => {
        state.exportUrl = payload;
    },
    [ExportMutations.SET_EXPORT_SALES_JOURNAL_CSV]: (state, payload: any) => {
        state.exportSalesJournalCsv = payload;
    },

    //COMMON
    [ExportMutations.SET_IS_LOADING_EXPORT]: (state, payload: boolean) => {
        state.isLoadingExport = payload;
    },
    [ExportMutations.SET_IS_LOADING_EXPORT_URL]: (state, payload: boolean) => {
        state.isLoadingExportUrl = payload;
    },
    [ExportMutations.SET_IS_LOADING_INVOICES_CREDITS_TIME_SPAN]: (state, payload: boolean) => {
        state.isLoadingInvoicesCreditsOnTimeSpan = payload;
    },
    [ExportMutations.SET_IS_LOADING_EXPORT_SALES_JOURNAL_CSV]: (state, payload: boolean) => {
        state.isLoadingExportSalesJournalCsv = payload;
    },
    [ExportMutations.SET_REQUEST_STATE_EXPORT]: (state, payload: IRequestState) => {
        state.requestStateExport = payload;
    },
}