import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { AffairCostingRequestDto } from "@/../Api";
import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IAffairCostingState } from "./states";

export const getters: GetterTree<IAffairCostingState, RootState> = {
  affairCostingsList: (state): AffairCostingRequestDto[] =>
    state.affairCostingsList,
  affairCostingsListArchived: (state): AffairCostingRequestDto[] =>
    state.affairCostingsListArchived,
  affairCostingsListByAffair: (state): AffairCostingRequestDto[] =>
    state.affairCostingsListByAffair,
  affairCostingsListByAffairArchived: (state): AffairCostingRequestDto[] =>
    state.affairCostingsListByAffairArchived,
  affairCosting: (state): AffairCostingRequestDto => state.affairCosting,

  //FILTERS
  affairCostingFilters: (state): any => state.affairCostingFilters,

  // COMMON
  isLoadingAffairCostingsList: (state): boolean =>
    state.isLoadingAffairCostingsList,
  isLoadingAffairCostingsListArchived: (state): boolean =>
    state.isLoadingAffairCostingsListArchived,
  isLoadingAffairCostingsListByAffair: (state): boolean =>
    state.isLoadingAffairCostingsListByAffair,
  isLoadingAffairCostingsListByAffairArchived: (state): boolean =>
    state.isLoadingAffairCostingsListByAffairArchived,
  isLoadingAffairCosting: (state): boolean => state.isLoadingAffairCosting,
  isCreatingAffairCosting: (state): boolean => state.isCreatingAffairCosting,
  isUpdatingAffairCosting: (state): boolean => state.isUpdatingAffairCosting,
  isDeletingAffairCosting: (state): boolean => state.isDeletingAffairCosting,
  isArchivingAffairCosting: (state): boolean => state.isArchivingAffairCosting,
  requestStateAffairCosting: (state): IRequestState =>
    state.requestStateAffairCosting,
};
