import { IDocumentDetailState } from "./states";
import { IInvoiceHeader } from "@/types/api-orisis/interfaces/invoices/documentDetail/IInvoiceHeader";
import { Module } from "vuex";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import RootState from "@/store/modules/rootState";
import { EInputType } from "@/types/api-orisis/enums/enums";
import { IInvoiceParameters } from "@/types/api-orisis/interfaces/invoices/document/IQuoteParameters";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

const invoiceParameters: IInvoiceParameters = {
  BShowCalcululationsMargin: false,
  BShowCalculationsRemisePerLines: false,
  BShowReferences: false,
  BShowTypes: false,
  BTvaAttestation: false,
  BPriorVisitDate: false,
  BDefaultTvaChange: false,
  BHideStyleEditor: false,
  BHideWasteManagement: false,
  paymentMethod: [],
  BHideTotalSections: false,
  BHideQteAndUnitsCol: false,
  BHideTvaCol: false,
  BHideWorkElements: false,
  BHideRestToBeInvoiced: false,
  BShowPhoneNumber: false,
  BShowEmail: false,
  BShowTvaNumber: false,
  BShowSiren: false,
};

const invoiceHeader: IInvoiceHeader = {
  index: {
    text: "N°",
    display: true,
    editable: false,
    class: "col-num pl-0",
    style:
      "min-height: 28px !important; max-width: 60px!important;min-width: 60px!important;margin-left:25px;width: 60px!important; padding-left: 0.25em!important; padding-right: 0.25em!important;",
    inputType: EInputType.Text,
    unit: "",
    prefix: "",
    useWorkElements: false,
  },
  reference: {
    text: "Référence",
    display: false,
    editable: true,
    class: "col-ref reference",
    style: "width:10%;max-width: 85px;",
    inputType: EInputType.Text,
    useWorkElements: true,
  },
  description: {
    text: "Désignation",
    display: true,
    editable: true,
    class: "col-label",
    style: "width:auto; max-width: none;flex-grow: 1;",
    inputType: EInputType.SelectCatalog,
    useWorkElements: true,
  },
  quantity: {
    text: "Qté",
    display: true,
    editable: true,
    class: "col-qte text-center",
    style: "width:10%; max-width:70px;",
    inputType: EInputType.Number,
    unit: "",
    numberToFixed: 2,
    prefix: "",
    useWorkElements: true,
  },
  quantityDeducted: {
    text: "Qté déduite",
    display: false,
    editable: true,
    class: "col-qte-deduction",
    style: "width:10%; max-width:95px;",
    inputType: EInputType.Number,
    unit: "",
    numberToFixed: 2,
    prefix: "",
    useWorkElements: false,
  },
  unitId: {
    text: "Unité",
    display: true,
    editable: true,
    class: "col-unit text-center",
    style: "width:10%; max-width:70px;",
    inputType: EInputType.Select,
    choice: [],
    useWorkElements: true,
  },
  previousSituationProgress: {
    text: "Sit. préc. (%)",
    display: false,
    editable: false,
    class: "col-previous-situation-progress text-right",
    style:
      "width:10%; max-width:80px; min-width:80px; letter-spacing: -0.06rem; font-size: 13px;",
    inputType: EInputType.Number,
    unit: "%",
    numberToFixed: 2,
    prefix: "",
    useWorkElements: false,
  },
  situationProgress: {
    text: "Avcmt. global (%)",
    display: false,
    editable: true,
    class: "col-situation-progress text-right",
    style:
      "width:10%; max-width:110px; min-width:110px; letter-spacing: -0.06rem; font-size: 13px;",
    inputType: EInputType.Number,
    unit: "%",
    numberToFixed: 2,
    prefix: "",
    useWorkElements: false,
  },
  previousSituationProgressQuantity: {
    text: "Sit. préc.",
    display: false,
    editable: false,
    class: "col-previous-situation-progress text-right",
    style:
      "width:10%; max-width:80px; min-width:80px; letter-spacing: -0.06rem; font-size: 13px;",
    inputType: EInputType.Number,
    unit: "",
    numberToFixed: 4,
    prefix: "",
    useWorkElements: false,
  },
  situationProgressQuantity: {
    text: "Avcmt. global",
    display: false,
    editable: true,
    class: "col-situation-progress text-right",
    style:
      "width:10%; max-width:110px; min-width:110px; letter-spacing: -0.06rem; font-size: 13px;",
    inputType: EInputType.Number,
    unit: "",
    numberToFixed: 4,
    prefix: "",
    useWorkElements: false,
  },
  disbursedNetHt: {
    text: "Déboursé",
    display: true,
    editable: true,
    class: "col-price text-right",
    style: "width:10%; max-width:90px; ",
    inputType: EInputType.Number,
    unit: "€",
    numberToFixed: 2,
    prefix: "",
    useWorkElements: true,
  },
  marginCoefficient: {
    text: "Coeff.",
    display: true,
    editable: true,
    class: "col-price text-right",
    style: "width:10%; max-width:90px; ",
    inputType: EInputType.Number,
    unit: "",
    numberToFixed: 4,
    prefix: "",
    useWorkElements: true,
  },
  unitPriceHt: {
    text: "Prix U. HT",
    display: true,
    editable: true,
    class: "col-price text-right",
    style: "width:10%; max-width:90px; ",
    inputType: EInputType.Number,
    unit: "€",
    numberToFixed: 2,
    prefix: "",
    useWorkElements: true,
  },
  discount: {
    text: "% Remise",
    display: false,
    editable: true,
    class: "col-discount discount text-right",
    style: "width:10%; max-width:90px;",
    inputType: EInputType.Number,
    unit: "%",
    prefix: "",
    useWorkElements: false,
  },
  marginRate: {
    text: "% Marge",
    display: false,
    editable: true,
    class: "col-margin margins text-right",
    style: "width:10%; max-width:90px;",
    inputType: EInputType.Margin,
    useWorkElements: true,
  },
  temps: {
    text: "Temps",
    display: false,
    editable: true,
    class: "col-hours hours",
    style: "width:10%; max-width:75px;",
    inputType: EInputType.Number,
    useWorkElements: false,
  },
  referencielTvaId: {
    text: "TVA",
    display: true,
    editable: true,
    class: "col-vat text-center",
    style: "width:10%; max-width:90px",
    inputType: EInputType.Select,
    choice: [],
    useWorkElements: true,
  },
  total: {
    text: "Total HT",
    display: true,
    editable: false,
    class: "col-vat text-center",
    style:
      "line-height: 22px !important; display: flex; align-items: center; justify-content: flex-end; width:10%; max-width:120px;",
    inputType: EInputType.Number,
    unit: "€",
    prefix: "",
    numberToFixed: 2,
    useWorkElements: false,
  },
  titre: {
    text: "Titre",
    display: false,
    editable: true,
    class: "col-title text-left",
    style: "line-height: 22px !important; width:95%;",
    inputType: EInputType.Title,
    useWorkElements: false,
  },
  subtotal: {
    text: "Sous-total",
    display: false,
    editable: false,
    class: "col-title-total text-right",
    style: "line-height: 34px !important; width:50%;",
    inputType: EInputType.Number,
    unit: "€",
    prefix: "",
    numberToFixed: 2,
    useWorkElements: false,
  },
  editor: {
    text: "Texte",
    display: false,
    editable: false,
    class: "col-editor text-left",
    style: "width:95%;",
    inputType: EInputType.Editor,
    useWorkElements: false,
  },
};

export const state: IDocumentDetailState = {
  InvoiceParameters: invoiceParameters,
  invoiceHeader: invoiceHeader,
  documentDetails: [],
  documentDetail: {},
  isLoadingDocumentDetails: false,
  isLoadingDocumentDetailDuplication: false,
  isCreatingDocumentDetail: false,
  isUpdatingDocumentDetail: false,
  isDeletingDocumentDetail: false,
  disabledOptions: false,
  disabledDrag: false,
  requestStateDocument: <IRequestState>{},
};

export const DocumentDetail: Module<IDocumentDetailState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
