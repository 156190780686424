import { MutationTree } from "vuex";
import { IOrderFormCustomerOptionState } from "./states";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { OrderFormCustomerOptionRequestDto } from "Api";

export const enum OrderFormCustomerOptionMutations {
  SET_ORDER_FORM_CUSTOMER_OPTION = "SET_ORDER_FORM_CUSTOMER_OPTION",
  SET_IS_LOADING_ORDER_FORM_CUSTOMER_OPTION = "SET_IS_LOADING_ORDER_FORM_CUSTOMER_OPTION",
  SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_OPTION = "SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_OPTION",
}

export const mutations: MutationTree<IOrderFormCustomerOptionState> = {
  [OrderFormCustomerOptionMutations.SET_ORDER_FORM_CUSTOMER_OPTION](
    state,
    payload: OrderFormCustomerOptionRequestDto
  ) {
    state.orderFormCustomerOption = payload;
  },
  [OrderFormCustomerOptionMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_OPTION](
    state,
    payload: boolean
  ) {
    state.isLoadingOrderFormCustomerOption = payload;
  },
  [OrderFormCustomerOptionMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_OPTION](
    state,
    payload: IRequestState
  ) {
    state.requestStateOrderFormCustomerOption = payload;
  },
};
