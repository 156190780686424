import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { InstitutionSettingReferencielTvaRequestDto } from "Api";
import { GetterTree } from "vuex";
import RootState from '@/store/modules/rootState'
import {  IReferencielTVAState } from "./states";

export const getters: GetterTree<IReferencielTVAState, RootState> = {
    referencielTVAsList                    : (state): InstitutionSettingReferencielTvaRequestDto[] => state.referencielTVAsList,
    referencielTVAsListArchived            : (state): InstitutionSettingReferencielTvaRequestDto[] => state.referencielTVAsListArchived,
    referencielTVA                         : (state): InstitutionSettingReferencielTvaRequestDto => state.referencielTVA,
    
    // COMMON
    isLoadingReferencielTVAsList           : (state): boolean => state.isLoadingReferencielTVAsList,
    isLoadingReferencielTVAsListArchived   : (state): boolean => state.isLoadingReferencielTVAsListArchived,
    isLoadingReferencielTVA                : (state): boolean => state.isLoadingReferencielTVA,
    isCreatingReferencielTVA               : (state): boolean => state.isCreatingReferencielTVA,
    isUpdatingReferencielTVA               : (state): boolean => state.isUpdatingReferencielTVA,
    isDeletingReferencielTVA               : (state): boolean => state.isDeletingReferencielTVA,
    requestStateReferencielTVA             : (state): IRequestState => state.requestStateReferencielTVA,
    isArchivingReferencielTVA              : (state): boolean => state.isArchivingReferencielTVA,
}
