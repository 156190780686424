import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { PurchaseCategoryRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { IPurchaseCategoryState } from './states'


export const getters: GetterTree<IPurchaseCategoryState, RootState> = {
  purchaseCategoriesList                    : (state): PurchaseCategoryRequestDto[] => state.purchaseCategoriesList,
  purchaseCategoriesTreeList                : (state): any[] => state.purchaseCategoriesTreeList,
  purchaseCategoriesListArchived            : (state): PurchaseCategoryRequestDto[] => state.purchaseCategoriesListArchived,
  purchaseCategory                          : (state): PurchaseCategoryRequestDto => state.purchaseCategory,

  // COMMON
  isLoadingPurchaseCategoriesList           : (state): boolean => state.isLoadingPurchaseCategoriesList,
  isLoadingPurchaseCategoriesTreeList       : (state): boolean => state.isLoadingPurchaseCategoriesTreeList,
  isLoadingPurchaseCategoriesListArchived   : (state): boolean => state.isLoadingPurchaseCategoriesListArchived,
  isLoadingPurchaseCategory                 : (state): boolean => state.isLoadingPurchaseCategory,
  isCreatingPurchaseCategory                : (state): boolean => state.isCreatingPurchaseCategory,
  isUpdatingPurchaseCategory                : (state): boolean => state.isUpdatingPurchaseCategory,
  isDeletingPurchaseCategory                : (state): boolean => state.isDeletingPurchaseCategory,
  isArchivingPurchaseCategory               : (state): boolean => state.isArchivingPurchaseCategory,
  requestStatePurchaseCategory              : (state): IRequestState => state.requestStatePurchaseCategory,
}