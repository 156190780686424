import { IInventoryState } from "./states"
import { Module } from "vuex"
import { InventoryRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"
import { IFilters } from "@/types/api-orisis/interfaces/invoices/filters/IFilters"

const state:IInventoryState = {
    inventoriesList                   : Array<InventoryRequestDto>(),
    inventoriesListArchived           : Array<InventoryRequestDto>(),
    inventory                         : <InventoryRequestDto>{},
    inventoryArchived                 : <InventoryRequestDto>{},
    inventoryPDF                      : "",
    isValidatingInventory             : false,
    isLoadingInventoryPDF             : false,
    isLoadingInventoriesList          : false,
    isLoadingInventoriesListArchived  : false,
    isLoadingInventory                : false,
    isLoadingInventoryArchived        : false,
    isCreatingInventory               : false,
    isUpdatingInventory               : false,
    isRestoringInventory              : false,
    isDeletingInventory               : false,
    isArchivingInventory              : false,
    requestStateInventory             : <IRequestState>{},
    inventoryTabActive                : 'document-edit',
    inventoryFilters                  : <IFilters>{},
}

export const InventoryModule: Module<IInventoryState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}