import { PaymentRequestDto } from "Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { PaymentMutations } from "./mutations";
import { IPaymentState } from "./states";
import dayjs from "dayjs";
import { Timezone } from "@syncfusion/ej2-schedule";

let timezoneSchedule = new Timezone();

let startDate = new Date(
  dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
).toISOString();
let endDate = new Date(
  dayjs().endOf("month").format("YYYY-MM-DD")
).toISOString();

export const actions: ActionTree<IPaymentState, RootState> = {
  async getPaymentById(
    { commit },
    {
      paymentId,
      updateState = true,
    }: { paymentId: number; updateState: boolean }
  ): Promise<PaymentRequestDto> {
    return new Promise(async (resolve, reject) => {
      if (updateState) {
        commit(PaymentMutations.SET_IS_LOADING_PAYMENT, true);
      }
      await axios
        .get(process.env.VUE_APP_API_ORISIS + "Sale/Payment/GetPaymentById", {
          params: {
            paymentId: paymentId,
          },
        })
        .then((res) => {
          let payment = {
            ...res.data,
            paymentMethodId: res.data.paymentMethod
              ? res.data.paymentMethod.id
              : null,
          };
          if (updateState) {
            commit(PaymentMutations.SET_PAYMENT, payment);
            commit(PaymentMutations.SET_IS_LOADING_PAYMENT, false);
          }
          commit(PaymentMutations.SET_REQUEST_STATE_PAYMENT, res);
          resolve(payment);
        })
        .catch((err) => {
          console.error(err);
          commit(PaymentMutations.SET_REQUEST_STATE_PAYMENT, err);
          commit(PaymentMutations.SET_IS_LOADING_PAYMENT, false);
          reject(err);
        });
    });
  },

  async getPayments(
    { commit },
    {
      invoiceId = null,
      creditId = null,
      bankAccountId = null,
      startTo = null,
      endTo = null,
      updateState = true,
    }: {
      invoiceId: number;
      creditId: number;
      bankAccountId: number;
      startTo: Date;
      endTo: Date;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (updateState) {
        commit(PaymentMutations.SET_IS_LOADING_PAYMENTS_LIST, true);
      }
      await axios
        .get(process.env.VUE_APP_API_ORISIS + "Sale/Payment/GetPayments", {
          params: {
            invoiceId: invoiceId,
            creditId: creditId,
            bankAccountId: bankAccountId,
            startTo: startTo ? dayjs(startTo).format("YYYY-MM-DD") : null,
            endTo: endTo
              ? dayjs(endTo)
                  .hour(23)
                  .minute(59)
                  .second(59)
                  .format("YYYY-MM-DD HH:mm:ss")
              : null,
          },
        })
        .then((res) => {
          let list = res.data.map((item: any) => {
            return {
              ...item,
              paymentMethodId: item.paymentMethod
                ? item.paymentMethod.id
                : null,
              date: item.date
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(new Date(item.date))
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              remittedBankDate: item.remittedBankDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(item.remittedBankDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
            };
          });
          if (updateState) {
            commit(PaymentMutations.SET_PAYMENTS_LIST, list);
            commit(PaymentMutations.SET_IS_LOADING_PAYMENTS_LIST, false);
          }
          commit(PaymentMutations.SET_REQUEST_STATE_PAYMENT, res);
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          commit(PaymentMutations.SET_REQUEST_STATE_PAYMENT, err);
          commit(PaymentMutations.SET_IS_LOADING_PAYMENTS_LIST, false);
          reject(err);
        });
    });
  },

  async getPaymentsByInvoiceId(
    { commit },
    {
      invoiceId = null,
      startTo = null,
      endTo = null,
      updateState = true,
    }: {
      invoiceId: number;
      startTo: Date;
      endTo: Date;
      updateState: boolean;
    }
  ): Promise<PaymentRequestDto> {
    return new Promise(async (resolve, reject) => {
      if (updateState) {
        commit(PaymentMutations.SET_IS_LOADING_PAYMENTS_LIST_BY_INVOICE, true);
      }
      await axios
        .get(process.env.VUE_APP_API_ORISIS + "Sale/Payment/GetPayments", {
          params: {
            invoiceId: invoiceId,
            startTo: startTo ? dayjs(startTo).format("YYYY-MM-DD") : null,
            endTo: endTo
              ? dayjs(endTo)
                  .hour(23)
                  .minute(59)
                  .second(59)
                  .format("YYYY-MM-DD HH:mm:ss")
              : null,
          },
        })
        .then((res) => {
          let list = res.data.map((item: any) => {
            return {
              ...item,
              paymentMethodId: item.paymentMethod
                ? item.paymentMethod.id
                : null,
              date: item.date
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(new Date(item.date))
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              remittedBankDate: item.remittedBankDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(item.remittedBankDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
            };
          });
          if (updateState) {
            commit(PaymentMutations.SET_PAYMENTS_LIST_BY_INVOICE, list);
            commit(
              PaymentMutations.SET_IS_LOADING_PAYMENTS_LIST_BY_INVOICE,
              false
            );
          }
          commit(PaymentMutations.SET_REQUEST_STATE_PAYMENT, res);
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          commit(PaymentMutations.SET_REQUEST_STATE_PAYMENT, err);
          reject(err);
        });
    });
  },

  async getPaymentsByCreditId(
    { commit, rootGetters },
    {
      creditId = null,
      startTo = null,
      endTo = null,
      updateState = true,
    }: { creditId: number; startTo: Date; endTo: Date; updateState: boolean }
  ): Promise<PaymentRequestDto> {
    return new Promise(async (resolve, reject) => {
      if (updateState) {
        commit(PaymentMutations.SET_IS_LOADING_PAYMENTS_LIST_BY_CREDIT, true);
      }
      await axios
        .get(process.env.VUE_APP_API_ORISIS + "Sale/Payment/GetPayments", {
          params: {
            creditId: creditId,
          },
        })
        .then((res) => {
          let list = res.data.map((item: any) => {
            return {
              ...item,
              paymentMethodId: item.paymentMethod
                ? item.paymentMethod.id
                : null,
              date: item.date
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(new Date(item.date))
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              remittedBankDate: item.remittedBankDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(item.remittedBankDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
            };
          });
          if (updateState) {
            commit(PaymentMutations.SET_PAYMENTS_LIST_BY_CREDIT, list);
            commit(
              PaymentMutations.SET_IS_LOADING_PAYMENTS_LIST_BY_CREDIT,
              false
            );
          }
          commit(PaymentMutations.SET_REQUEST_STATE_PAYMENT, res);
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          commit(PaymentMutations.SET_REQUEST_STATE_PAYMENT, err);
          reject(err);
        });
    });
  },

  // async getPaymentsByBankAccountId() {},

  // async getPaymentStats() {},

  async createPayment(
    { commit, rootGetters },
    {
      payment,
      remainsToBeDeducted,
    }: { payment: PaymentRequestDto; remainsToBeDeducted: number }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(PaymentMutations.SET_IS_CREATING_PAYMENT, true);
      await axios
        .post(
          process.env.VUE_APP_API_ORISIS + "Sale/Payment/CreatePayment",
          payment
        )
        .then((res) => {
          rootGetters.paymentsList.unshift({
            ...res.data.data,
            remainsToBeDeducted: remainsToBeDeducted,
          });
          commit(PaymentMutations.SET_PAYMENTS_LIST, rootGetters.paymentsList);
          commit(PaymentMutations.SET_REQUEST_STATE_PAYMENT, res);
          commit(PaymentMutations.SET_IS_CREATING_PAYMENT, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PaymentMutations.SET_REQUEST_STATE_PAYMENT, err);
          commit(PaymentMutations.SET_IS_CREATING_PAYMENT, false);
          reject(err);
        });
    });
  },

  async updatePayment(
    { commit, rootGetters },
    {
      payment,
      remainsToBeDeducted,
    }: { payment: PaymentRequestDto; remainsToBeDeducted: number }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(PaymentMutations.SET_IS_UPDATING_PAYMENT, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Sale/Payment/UpdatePayment",
          payment
        )
        .then((res) => {
          rootGetters.paymentsList.splice(
            rootGetters.paymentsList.findIndex((elem) => elem.id == payment.id),
            1,
            { ...payment, remainsToBeDeducted: remainsToBeDeducted }
          );
          commit(PaymentMutations.SET_PAYMENTS_LIST, rootGetters.paymentsList);
          commit(PaymentMutations.SET_REQUEST_STATE_PAYMENT, res);
          commit(PaymentMutations.SET_IS_UPDATING_PAYMENT, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PaymentMutations.SET_REQUEST_STATE_PAYMENT, err);
          commit(PaymentMutations.SET_IS_UPDATING_PAYMENT, false);
          reject(err);
        });
    });
  },

  // à verifier
  async updateInvoicePayment(
    { commit, dispatch, rootGetters },
    { paymentId, invoices }: { paymentId: number; invoices: any }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(PaymentMutations.SET_IS_LINKING_PAYMENT, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Sale/Payment/UpdateInvoicePayment",
          invoices,
          {
            params: {
              paymentId: paymentId,
            },
          }
        )
        .then((res) => {
          let invoiceFilters = rootGetters.userParametersList.find(
            (elem) => elem.key == "invoiceFilters"
          );
          if (invoiceFilters) {
            dispatch("getInvoices", {
              dateFrom: JSON.parse(invoiceFilters.value).dateRange.startDate,
              dateTo: JSON.parse(invoiceFilters.value).dateRange.endDate,
            });
          } else {
            dispatch("setFilter", {
              name: "invoiceFilters",
              filter: {
                dateRangeType: 7,
                dateRange: { startDate: startDate, endDate: endDate },
              },
            });
            dispatch("getInvoices", {
              dateFrom: startDate,
              dateTo: endDate,
            });
          }
          commit(PaymentMutations.SET_REQUEST_STATE_PAYMENT, res);
          commit(PaymentMutations.SET_IS_LINKING_PAYMENT, false);
          resolve();
        })
        .catch((err) => {
          console.error(err);
          commit(PaymentMutations.SET_REQUEST_STATE_PAYMENT, err);
          commit(PaymentMutations.SET_IS_LINKING_PAYMENT, false);
          reject(err);
        });
    });
  },

  // à verifier
  async updateCreditPayment(
    { commit, dispatch, rootGetters },
    { paymentId, credits }: { paymentId: number; credits: any }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(PaymentMutations.SET_IS_LINKING_PAYMENT, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Sale/Payment/UpdateCreditPayment",
          credits,
          {
            params: {
              paymentId: paymentId,
            },
          }
        )
        .then((res) => {
          let creditFilters = rootGetters.userParametersList.find(
            (elem) => elem.key == "creditFilters"
          );
          if (creditFilters) {
            dispatch("getCredits", {
              dateFrom: JSON.parse(creditFilters.value).dateRange.startDate,
              dateTo: JSON.parse(creditFilters.value).dateRange.endDate,
            });
          } else {
            dispatch("setFilter", {
              name: "creditFilters",
              filter: {
                dateRangeType: 7,
                dateRange: { startDate: startDate, endDate: endDate },
              },
            });
            dispatch("getCredits", {
              dateFrom: startDate,
              dateTo: endDate,
            });
          }
          commit(PaymentMutations.SET_REQUEST_STATE_PAYMENT, res);
          commit(PaymentMutations.SET_IS_LINKING_PAYMENT, false);
          resolve();
        })
        .catch((err) => {
          console.error(err);
          commit(PaymentMutations.SET_REQUEST_STATE_PAYMENT, err);
          commit(PaymentMutations.SET_IS_LINKING_PAYMENT, false);
          reject(err);
        });
    });
  },

  // à verifier
  async updateHoldbackPayment(
    { commit, dispatch, rootGetters },
    { paymentId, holdbacks }: { paymentId: number; holdbacks: any }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(PaymentMutations.SET_IS_LINKING_PAYMENT, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Sale/Payment/UpdateHoldbackPayment",
          holdbacks,
          {
            params: {
              paymentId: paymentId,
            },
          }
        )
        .then((res) => {
          let holdbackFilters = rootGetters.userParametersList.find(
            (elem) => elem.key == "holdbackFilters"
          );
          if (holdbackFilters) {
            dispatch("getCredits", {
              dateFrom: JSON.parse(holdbackFilters.value).dateRange.startDate,
              dateTo: JSON.parse(holdbackFilters.value).dateRange.endDate,
            });
          } else {
            dispatch("setFilter", {
              name: "holdbackFilters",
              filter: {
                dateRangeType: 7,
                dateRange: { startDate: startDate, endDate: endDate },
              },
            });
            dispatch("getHoldbacks", {
              dateFrom: startDate,
              dateTo: endDate,
            });
          }
          commit(PaymentMutations.SET_REQUEST_STATE_PAYMENT, res);
          commit(PaymentMutations.SET_IS_LINKING_PAYMENT, false);
          resolve();
        })
        .catch((err) => {
          console.error(err);
          commit(PaymentMutations.SET_REQUEST_STATE_PAYMENT, err);
          commit(PaymentMutations.SET_IS_LINKING_PAYMENT, false);
          reject(err);
        });
    });
  },

  async unlinkCreditPayment(
    { commit, rootGetters },
    { payment, creditIds, amount }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(PaymentMutations.SET_IS_UNLINKING_PAYMENT, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Sale/Payment/UnlinkCreditPayment",
          creditIds,
          {
            params: {
              paymentId: payment.id,
            },
          }
        )
        .then((res) => {
          for (let i = 0; i < creditIds.length; i++) {
            const creditId = creditIds[i];
            payment.credits.splice(
              payment.credits.findIndex((elem) => elem.id == creditId),
              1
            );
          }
          commit(PaymentMutations.SET_PAYMENT, payment);
          rootGetters.paymentsList.splice(
            rootGetters.paymentsList.findIndex((elem) => elem.id == payment.id),
            1,
            {
              ...payment,
              remainsToBeDeducted:
                Math.round((payment.remainsToBeDeducted + amount) * 100) / 100,
            }
          );
          commit(PaymentMutations.SET_PAYMENTS_LIST, rootGetters.paymentsList);
          commit(PaymentMutations.SET_REQUEST_STATE_PAYMENT, res);
          commit(PaymentMutations.SET_IS_UNLINKING_PAYMENT, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PaymentMutations.SET_REQUEST_STATE_PAYMENT, err);
          commit(PaymentMutations.SET_IS_UNLINKING_PAYMENT, false);
          reject(err);
        });
    });
  },

  async unlinkInvoicePayment(
    { commit, dispatch, rootGetters },
    { payment, invoiceIds, amount }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(PaymentMutations.SET_IS_UNLINKING_PAYMENT, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Sale/Payment/UnlinkInvoicePayment",
          invoiceIds,
          {
            params: {
              paymentId: payment.id,
            },
          }
        )
        .then((res) => {
          for (let i = 0; i < invoiceIds.length; i++) {
            const invoiceId = invoiceIds[i];
            payment.invoices.splice(
              payment.invoices.findIndex((elem) => elem.id == invoiceId),
              1
            );
          }
          commit(PaymentMutations.SET_PAYMENT, payment);
          rootGetters.paymentsList.splice(
            rootGetters.paymentsList.findIndex((elem) => elem.id == payment.id),
            1,
            {
              ...payment,
              remainsToBeDeducted:
                Math.round((payment.remainsToBeDeducted + amount) * 100) / 100,
            }
          );
          commit(PaymentMutations.SET_PAYMENTS_LIST, rootGetters.paymentsList);
          let invoiceFilters = rootGetters.userParametersList.find(
            (elem) => elem.key == "invoiceFilters"
          );
          if (invoiceFilters) {
            dispatch("getInvoices", {
              dateFrom: JSON.parse(invoiceFilters.value).dateRange.startDate,
              dateTo: JSON.parse(invoiceFilters.value).dateRange.endDate,
            });
          } else {
            dispatch("setFilter", {
              name: "invoiceFilters",
              filter: {
                dateRangeType: 7,
                dateRange: { startDate: startDate, endDate: endDate },
              },
            });
            dispatch("getInvoices", {
              dateFrom: startDate,
              dateTo: endDate,
            });
          }
          commit(PaymentMutations.SET_REQUEST_STATE_PAYMENT, res);
          commit(PaymentMutations.SET_IS_UNLINKING_PAYMENT, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PaymentMutations.SET_REQUEST_STATE_PAYMENT, err);
          commit(PaymentMutations.SET_IS_UNLINKING_PAYMENT, false);
          reject(err);
        });
    });
  },

  async unlinkHoldbackPayment(
    { commit, dispatch, rootGetters },
    { payment, holdbackIds, amount }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(PaymentMutations.SET_IS_UNLINKING_PAYMENT, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Sale/Payment/UnlinkHoldbackPayment",
          holdbackIds,
          {
            params: {
              paymentId: payment.id,
            },
          }
        )
        .then((res) => {
          for (let i = 0; i < holdbackIds.length; i++) {
            const holdbackId = holdbackIds[i];
            payment.holdbacks.splice(
              payment.holdbacks.findIndex((elem) => elem.id == holdbackId),
              1
            );
          }
          commit(PaymentMutations.SET_PAYMENT, payment);
          rootGetters.paymentsList.splice(
            rootGetters.paymentsList.findIndex((elem) => elem.id == payment.id),
            1,
            {
              ...payment,
              remainsToBeDeducted:
                Math.round((payment.remainsToBeDeducted + amount) * 100) / 100,
            }
          );
          commit(PaymentMutations.SET_PAYMENTS_LIST, rootGetters.paymentsList);
          commit(PaymentMutations.SET_REQUEST_STATE_PAYMENT, res);
          commit(PaymentMutations.SET_IS_UNLINKING_PAYMENT, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PaymentMutations.SET_REQUEST_STATE_PAYMENT, err);
          commit(PaymentMutations.SET_IS_UNLINKING_PAYMENT, false);
          reject(err);
        });
    });
  },

  // async restorePayment() {},

  // async archivePayment() {},

  async deletePayment(
    { commit, rootGetters },
    { paymentIds = [] }: { paymentIds: number[] }
  ): Promise<void> {
    commit(PaymentMutations.SET_IS_DELETING_PAYMENT, true);
    await axios
      .delete(process.env.VUE_APP_API_ORISIS + "Sale/Payment/Delete", {
        data: paymentIds,
      })
      .then((res) => {
        paymentIds.forEach((paymentId: number) => {
          rootGetters.paymentsList.splice(
            rootGetters.paymentsList.findIndex((elem) => elem.id == paymentId),
            1
          );
        });
        commit(PaymentMutations.SET_PAYMENTS_LIST, rootGetters.paymentsList);
        commit(PaymentMutations.SET_REQUEST_STATE_PAYMENT, res);
        commit(PaymentMutations.SET_IS_DELETING_PAYMENT, false);
      })
      .catch((err) => {
        console.error(err);
        commit(PaymentMutations.SET_REQUEST_STATE_PAYMENT, err);
        commit(PaymentMutations.SET_IS_DELETING_PAYMENT, false);
      });
  },
};
