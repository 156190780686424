import { IOrderFormProviderDetailState } from "./states";
import IOrderFormProviderHeader from "@/types/api-orisis/interfaces/Purchase/orderFormProviderDetail/IOrderFormProviderHeader";
import { Module } from "vuex";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import RootState from '@/store/modules/rootState'
import { EInputType } from "@/types/api-orisis/enums/enums";
import { IOrderFormProviderParameters } from "@/types/api-orisis/interfaces/Purchase/orderFormProvider/IOrderFormProviderParameters";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

const orderFormProviderParameters: IOrderFormProviderParameters = {
  BShowCalcululationsMargin: false,
  BShowCalculationsRemisePerLines: false,
  BShowReferences: false,
  BShowTypes: false,
  BTvaAttestation: false,
  BPriorVisitDate: false,
  BDefaultTvaChange: false,
  BHideStyleEditor: false,
  BHideWasteManagement: false,
  BHideTotalSections: false,
  BHideQteAndUnitsCol: false,
  BHideTvaCol: false,
  BHideWorkElements: false,
  BHideRestToBeInvoiced: false,
  BShowPhoneNumber: false,
  BShowEmail: false,
  BShowTvaNumber: false,
  BShowSiren: false,
};

const orderFormProviderHeader: IOrderFormProviderHeader = {
  index: {
    text: 'N°',
    display: true,
    editable: false,
    class: 'col-num pl-0',
    style:"min-height: 28px !important; max-width: 60px!important;min-width: 60px!important;margin-left:25px;width: 60px!important; padding-left: 0.25em!important; padding-right: 0.25em!important;",
    inputType: EInputType.Text,
    unit: '',
    prefix: '',
  },
  reference: {
    text: 'Référence',
    display: true,
    editable: true,
    class: 'col-ref reference',
    style: 'width:10%;max-width: 85px;',
    inputType: EInputType.Text,
  },
  description: {
    text: 'Désignation',
    display: true,
    editable: true,
    class: 'col-label',
    style: 'width:auto; max-width: none;flex-grow: 1;',
    inputType: EInputType.SelectCatalog,
  },
  quantity: {
    text: 'Qté',
    display: true,
    editable: true,
    class: 'col-qte text-center',
    style: 'width:10%; max-width:70px;',
    inputType: EInputType.Number,
    unit: '',
    numberToFixed : 2,
    prefix: '',
  },
  unitId: {
    text: 'Unité',
    display: true,
    editable: true,
    class: 'col-unit text-center',
    style: 'width:10%; max-width:70px;',
    inputType: EInputType.Select,
    choice: [],
  },
  unitPriceHt: {
    text: 'Prix U. HT',
    display: true,
    editable: true,
    class: 'col-price text-right',
    style: 'width:10%; max-width:90px; ',
    inputType: EInputType.Number,
    unit: '€',
    numberToFixed : 2,
    prefix: '',
  },
  discount: {
    text: '% Remise',
    display: false,
    editable: true,
    class: 'col-discount discount text-right',
    style: 'width:10%; max-width:90px;',
    inputType: EInputType.Number,
    unit: '%',
    prefix: '',
  },
  referencielTvaId: {
    text: 'TVA',
    display: true,
    editable: true,
    class: 'col-vat text-center',
    style: 'width:10%; max-width:90px',
    inputType: EInputType.Select,
    choice: [],
  },
  total: {
    text: 'Total HT',
    display: true,
    editable: false,
    class: 'col-vat text-center',
    style: 'line-height: 22px !important; display: flex; align-items: center; justify-content: flex-end; width:10%; max-width:120px;',
    inputType: EInputType.Number,
    unit: '€',
    prefix: '',
    numberToFixed: 2,
  },
  titre: {
    text: 'Titre',
    display: false,
    editable: true,
    class: 'col-title text-left',
    style: 'line-height: 22px !important; width:95%;',
    inputType: EInputType.Title,
  },
  subtotal: {
    text: 'Sous-total',
    display: false,
    editable: false,
    class: 'col-title-total text-right',
    style: 'line-height: 34px !important; width:50%;',
    inputType: EInputType.Number,
    unit: '€',
    prefix: '',
    numberToFixed: 2,
  },
  editor: {
    text: 'Texte',
    display: false,
    editable: false,
    class: 'col-editor text-left',
    style: 'width:95%;',
    inputType: EInputType.Editor,
  },
}


export const state: IOrderFormProviderDetailState = {
  OrderFormProviderParameters: orderFormProviderParameters,
  orderFormProviderHeader: orderFormProviderHeader,
  orderFormProviderDetails: [],
  orderFormProviderDetail: {},
  isLoadingOrderFormProviderDetails: false,
  isLoadingOrderFormProviderDetailDuplication: false,
  isCreatingOrderFormProviderDetail: false,
  isUpdatingOrderFormProviderDetail: false,
  isDeletingOrderFormProviderDetail: false,
  requestStateOrderFormProviderDetail: <IRequestState>{},
};

export const OrderFormProviderDetailModule: Module<IOrderFormProviderDetailState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
