import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { CreditOptionMutations } from "./mutations";
import { ICreditOptionState } from "./states";
import { CreditOptionRequestDto } from "Api";

export const actions: ActionTree<ICreditOptionState, RootState> = {
  getCreditOptionByCreditId(
    { commit },
    { creditId, updateState = true }: { creditId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(CreditOptionMutations.SET_IS_LOADING_CREDIT_OPTION, true);
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/CreditOption/GetCreditOptionByCreditId`;

      axios
        .get(url, {
          params: { creditId },
        })
        .then((res) => {
          if (updateState) {
            commit(CreditOptionMutations.SET_CREDIT_OPTION, res.data);
            commit(CreditOptionMutations.SET_IS_LOADING_CREDIT_OPTION, false);
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(CreditOptionMutations.SET_IS_LOADING_CREDIT_OPTION, false);
          }
          reject(err);
        });
    });
  },

  createCreditOption(
    { commit },
    {
      creditOption,
      updateState = true,
    }: { creditOption: CreditOptionRequestDto; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(CreditOptionMutations.SET_IS_LOADING_CREDIT_OPTION, true);
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/CreditOption/CreateCreditOption`;

      axios
        .post(url, creditOption)
        .then((res) => {
          if (updateState) {
            commit(CreditOptionMutations.SET_CREDIT_OPTION, res.data.data);
            commit(CreditOptionMutations.SET_IS_LOADING_CREDIT_OPTION, false);
          }
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(CreditOptionMutations.SET_IS_LOADING_CREDIT_OPTION, false);
          }
          reject(err);
        });
    });
  },

  updateCreditOption(
    { commit, rootGetters },
    {
      creditOption,
      updateState = true,
    }: { creditOption: CreditOptionRequestDto; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(CreditOptionMutations.SET_IS_LOADING_CREDIT_OPTION, true);
      }
      const url = `${process.env.VUE_APP_API_ORISIS}Sale/CreditOption/UpdateCreditOption`;
      axios
        .put(url, { ...creditOption, creditId: rootGetters.document.id })
        .then((res) => {
          if (updateState) {
            commit(CreditOptionMutations.SET_CREDIT_OPTION, res.data.data);
            commit(CreditOptionMutations.SET_IS_LOADING_CREDIT_OPTION, false);
          }
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(CreditOptionMutations.SET_IS_LOADING_CREDIT_OPTION, false);
          }
          reject(err);
        });
    });
  },
};
