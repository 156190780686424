import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { ReportDetailRequestDto } from "@/../Api";
import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IReportDetailState } from "./states";

export const getters: GetterTree<IReportDetailState, RootState> = {
  reportDetailsList: (state): ReportDetailRequestDto[] =>
    state.reportDetailsList,
  reportDetailsMinifiedList: (state): ReportDetailRequestDto[] =>
    state.reportDetailsMinifiedList,
  reportDetailsListArchived: (state): ReportDetailRequestDto[] =>
    state.reportDetailsListArchived,
  reportDetailsListByCollaborator: (state): ReportDetailRequestDto[] =>
    state.reportDetailsListByCollaborator,
  reportDetail: (state): ReportDetailRequestDto => state.reportDetail,
  reportsDetail: (state): ReportDetailRequestDto[] => state.reportsDetail,
  reportDetailFilters: (state): any => state.reportDetailFilters,
  reportDetailFiltersByCollaborator: (state): any =>
    state.reportDetailFiltersByCollaborator,

  // COMMON
  isLoadingReportDetailsListByCollaborator: (state): boolean =>
    state.isLoadingReportDetailsListByCollaborator,
  isLoadingReportDetailsList: (state): boolean =>
    state.isLoadingReportDetailsList,
  isLoadingReportDetailsMinifiedList: (state): boolean =>
    state.isLoadingReportDetailsMinifiedList,
  isLoadingReportDetailsListArchived: (state): boolean =>
    state.isLoadingReportDetailsListArchived,
  isLoadingReportDetail: (state): boolean => state.isLoadingReportDetail,
  isLoadingReportsDetail: (state): boolean => state.isLoadingReportsDetail,
  isCreatingReportDetail: (state): boolean => state.isCreatingReportDetail,
  isUpdatingReportDetail: (state): boolean => state.isUpdatingReportDetail,
  isDeletingReportDetail: (state): boolean => state.isDeletingReportDetail,
  isArchivingReportDetail: (state): boolean => state.isArchivingReportDetail,
  requestStateReportDetail: (state): IRequestState =>
    state.requestStateReportDetail,
};
