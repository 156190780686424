import { IMissionState } from "./states";
import { Module } from "vuex";
import { MissionRequestDto } from "@/../Api";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import RootState from "@/store/modules/rootState";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

const state: IMissionState = {
  missionsList: Array<MissionRequestDto>(),
  missionsListTemplate: Array<MissionRequestDto>(),
  missionsListArchived: Array<MissionRequestDto>(),
  missionsListByAffairId: Array<MissionRequestDto>(),
  mission: <MissionRequestDto>{},
  isLoadingMissionsList: false,
  isLoadingMissionsListTemplate: false,
  isLoadingMissionsListArchived: false,
  isLoadingMissionsListByAffairId: false,
  isLoadingMission: false,
  isCreatingMission: false,
  isUpdatingMission: false,
  isDeletingMission: false,
  isArchivingMission: false,
  requestStateMission: <IRequestState>{},
  missionFilters: {},
  missionFiltersByCollaborator: {},
};

export const MissionModule: Module<IMissionState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
