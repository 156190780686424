import { MutationTree } from "vuex";
import { IOrderFormCustomerOptionDefaultState } from "./states";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { OrderFormCustomerOptionRequestDto } from "Api";

export const enum OrderFormCustomerOptionDefaultMutations {
  SET_ORDER_FORM_CUSTOMER_OPTION_DEFAULT = "SET_ORDER_FORM_CUSTOMER_OPTION_DEFAULT",
  SET_ORDER_FORM_CUSTOMER_OPTION_DEFAULT_FORM = "SET_ORDER_FORM_CUSTOMER_OPTION_DEFAULT_FORM",
  SET_IS_LOADING_ORDER_FORM_CUSTOMER_OPTION_DEFAULT = "SET_IS_LOADING_ORDER_FORM_CUSTOMER_OPTION_DEFAULT",
  SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_OPTION_DEFAULT = "SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_OPTION_DEFAULT",
}

export const mutations: MutationTree<IOrderFormCustomerOptionDefaultState> = {
  [OrderFormCustomerOptionDefaultMutations.SET_ORDER_FORM_CUSTOMER_OPTION_DEFAULT](
    state,
    payload: OrderFormCustomerOptionRequestDto
  ) {
    state.orderFormCustomerOptionDefault = payload;
  },
  [OrderFormCustomerOptionDefaultMutations.SET_ORDER_FORM_CUSTOMER_OPTION_DEFAULT_FORM](
    state,
    payload: OrderFormCustomerOptionRequestDto
  ) {
    state.orderFormCustomerOptionDefaultForm = payload;
  },
  [OrderFormCustomerOptionDefaultMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_OPTION_DEFAULT](
    state,
    payload: boolean
  ) {
    state.isLoadingOrderFormCustomerOptionDefault = payload;
  },
  [OrderFormCustomerOptionDefaultMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_OPTION_DEFAULT](
    state,
    payload: IRequestState
  ) {
    state.requestStateOrderFormCustomerOptionDefault = payload;
  },
};
