import { CompanyIndicatorRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { CompanyIndicatorMutations } from "./mutations";
import { ICompanyIndicatorState } from "./states";
import { CompanyMutations } from "../company/mutations";

export const actions: ActionTree<ICompanyIndicatorState, RootState> = {
  async getCompanyIndicators(
    { commit },
    {
      companyTypeId = null,
      updateState = true,
    }: { companyTypeId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          CompanyIndicatorMutations.SET_IS_LOADING_COMPANY_INDICATOR_LIST,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/CompanyIndicator/GetCompanyIndicators`;
      await axios
        .get(url, {
          params: {
            companyTypeId: companyTypeId,
          },
        })
        .then((res) => {
          let companyIndicators = res.data.map((companyIndicator) => {
            companyIndicator.companyTypeId = companyIndicator.companyType
              ? companyIndicator.companyType.id
              : null;
            return companyIndicator;
          });
          if (updateState) {
            commit(
              CompanyIndicatorMutations.SET_IS_LOADING_COMPANY_INDICATOR_LIST,
              false
            );
            commit(
              CompanyIndicatorMutations.SET_COMPANY_INDICATORS_LIST,
              companyIndicators
            );
          }
          commit(
            CompanyIndicatorMutations.SET_REQUEST_STATE_COMPANY_INDICATOR,
            res
          );
          resolve(companyIndicators);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CompanyIndicatorMutations.SET_IS_LOADING_COMPANY_INDICATOR_LIST,
            false
          );
          commit(
            CompanyIndicatorMutations.SET_REQUEST_STATE_COMPANY_INDICATOR,
            err
          );
          reject(err);
        });
    });
  },

  async getCompanyIndicatorById(
    { commit },
    {
      companyIndicatorId,
      updateState = true,
    }: { companyIndicatorId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          CompanyIndicatorMutations.SET_IS_LOADING_COMPANY_INDICATOR,
          true
        );
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Directory/CompanyIndicator/GetCompanyIndicatorById`,
          {
            params: {
              companyIndicatorId: companyIndicatorId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(CompanyIndicatorMutations.SET_COMPANY_INDICATOR, res.data);
            commit(
              CompanyIndicatorMutations.SET_IS_LOADING_COMPANY_INDICATOR,
              false
            );
          }
          commit(
            CompanyIndicatorMutations.SET_REQUEST_STATE_COMPANY_INDICATOR,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CompanyIndicatorMutations.SET_REQUEST_STATE_COMPANY_INDICATOR,
            err
          );
          commit(
            CompanyIndicatorMutations.SET_IS_LOADING_COMPANY_INDICATOR,
            false
          );
          reject(err);
        });
    });
  },

  async getCompanyIndicatorsArchived(
    { commit },
    {
      companyTypeId = null,
      updateState = true,
    }: { companyTypeId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          CompanyIndicatorMutations.SET_IS_LOADING_COMPANY_INDICATOR_LIST_ARCHIVED,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/CompanyIndicator/GetCompanyIndicatorsArchived`;
      await axios
        .get(url, {
          params: {
            companyTypeId: companyTypeId,
          },
        })
        .then((res) => {
          if (updateState) {
            commit(
              CompanyIndicatorMutations.SET_IS_LOADING_COMPANY_INDICATOR_LIST_ARCHIVED,
              false
            );
            commit(
              CompanyIndicatorMutations.SET_COMPANY_INDICATORS_LIST_ARCHIVED,
              res.data
            );
          }
          commit(
            CompanyIndicatorMutations.SET_REQUEST_STATE_COMPANY_INDICATOR,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CompanyIndicatorMutations.SET_IS_LOADING_COMPANY_INDICATOR_LIST,
            false
          );
          commit(
            CompanyIndicatorMutations.SET_REQUEST_STATE_COMPANY_INDICATOR,
            err
          );
          reject(err);
        });
    });
  },

  async getCompanyIndicatorArchivedById(
    { commit },
    {
      companyIndicatorId,
      updateState = true,
    }: { companyIndicatorId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          CompanyIndicatorMutations.SET_IS_LOADING_COMPANY_INDICATOR,
          true
        );
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Directory/CompanyIndicator/GetCompanyIndicatorArchivedById`,
          {
            params: {
              companyIndicatorId: companyIndicatorId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(CompanyIndicatorMutations.SET_COMPANY_INDICATOR, res.data);
            commit(
              CompanyIndicatorMutations.SET_IS_LOADING_COMPANY_INDICATOR,
              false
            );
          }
          commit(
            CompanyIndicatorMutations.SET_REQUEST_STATE_COMPANY_INDICATOR,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CompanyIndicatorMutations.SET_REQUEST_STATE_COMPANY_INDICATOR,
            err
          );
          commit(
            CompanyIndicatorMutations.SET_IS_LOADING_COMPANY_INDICATOR,
            false
          );
          reject(err);
        });
    });
  },

  async createCompanyIndicator(
    { commit, rootGetters },
    { companyIndicator }: { companyIndicator: CompanyIndicatorRequestDto }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(CompanyIndicatorMutations.SET_IS_CREATING_COMPANY_INDICATOR, true);
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Directory/CompanyIndicator/CreateCompanyIndicator`,
          companyIndicator
        )
        .then((res) => {
          rootGetters.companyIndicatorsList.push({
            ...res.data.data,
            companyTypeId: res.data.data.companyType
              ? res.data.data.companyType.id
              : null,
          });
          commit(
            CompanyIndicatorMutations.SET_COMPANY_INDICATORS_LIST,
            rootGetters.companyIndicatorsList
          );
          commit(
            CompanyIndicatorMutations.SET_REQUEST_STATE_COMPANY_INDICATOR,
            res
          );
          commit(
            CompanyIndicatorMutations.SET_IS_CREATING_COMPANY_INDICATOR,
            false
          );
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CompanyIndicatorMutations.SET_REQUEST_STATE_COMPANY_INDICATOR,
            err
          );
          commit(
            CompanyIndicatorMutations.SET_IS_CREATING_COMPANY_INDICATOR,
            false
          );
          reject(err);
        });
    });
  },

  async updateCompanyIndicator(
    { commit, rootGetters },
    { companyIndicator }: { companyIndicator: CompanyIndicatorRequestDto }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(CompanyIndicatorMutations.SET_IS_UPDATING_COMPANY_INDICATOR, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Directory/CompanyIndicator/UpdateCompanyIndicator`,
          companyIndicator
        )
        .then((res) => {
          rootGetters.companyIndicatorsList.splice(
            rootGetters.companyIndicatorsList.findIndex(
              (elem) => elem.id == companyIndicator.id
            ),
            1,
            {
              ...res.data.data,
              companyTypeId: res.data.data.companyType
                ? res.data.data.companyType.id
                : null,
            }
          );
          commit(
            CompanyIndicatorMutations.SET_COMPANY_INDICATORS_LIST,
            rootGetters.companyIndicatorsList
          );
          commit(
            CompanyIndicatorMutations.SET_REQUEST_STATE_COMPANY_INDICATOR,
            res
          );
          commit(
            CompanyIndicatorMutations.SET_IS_UPDATING_COMPANY_INDICATOR,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CompanyIndicatorMutations.SET_REQUEST_STATE_COMPANY_INDICATOR,
            err
          );
          commit(
            CompanyIndicatorMutations.SET_IS_UPDATING_COMPANY_INDICATOR,
            false
          );
          reject(err);
        });
    });
  },

  async updateCompanyCompanyIndicator(
    { commit, rootGetters, dispatch },
    {
      companyId,
      companyIndicators,
    }: { companyId: number; companyIndicators: any }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(CompanyMutations.SET_IS_UPDATING_COMPANY, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Directory/CompanyIndicator/UpdateCompanyCompanyIndicator",
          companyIndicators,
          {
            params: {
              companyId: companyId,
            },
          }
        )
        .then((res) => {
          if (rootGetters.company && rootGetters.company.id == companyId) {
            dispatch("getCompanyById", { companyId: companyId });
          }

          commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, res);
          commit(CompanyMutations.SET_IS_UPDATING_COMPANY, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, err);
          commit(CompanyMutations.SET_IS_UPDATING_COMPANY, false);
          reject(err);
        });
    });
  },

  async restoreCompanyIndicators(
    { commit, rootGetters },
    { companyIndicatorIds }: { companyIndicatorIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(CompanyIndicatorMutations.SET_IS_UPDATING_COMPANY_INDICATOR, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Directory/CompanyIndicator/Restore",
          companyIndicatorIds
        )
        .then(async (res) => {
          companyIndicatorIds.forEach((companyIndicatorId: number) => {
            let companyIndicator =
              rootGetters.companyIndicatorsListArchived.find(
                (companyIndicator: CompanyIndicatorRequestDto) =>
                  companyIndicator.id == companyIndicatorId
              );
            rootGetters.companyIndicatorsList.unshift(companyIndicator);

            if (
              rootGetters.companyIndicatorsListArchived.find(
                (companyIndicator: CompanyIndicatorRequestDto) =>
                  companyIndicator.id == companyIndicatorId
              )
            ) {
              rootGetters.companyIndicatorsListArchived.splice(
                rootGetters.companyIndicatorsListArchived.findIndex(
                  (companyIndicator: CompanyIndicatorRequestDto) =>
                    companyIndicator.id == companyIndicatorId
                ),
                1
              );
            }
          });
          commit(
            CompanyIndicatorMutations.SET_COMPANY_INDICATORS_LIST,
            rootGetters.companyIndicatorsList
          );
          commit(
            CompanyIndicatorMutations.SET_COMPANY_INDICATORS_LIST_ARCHIVED,
            rootGetters.companyIndicatorsListArchived
          );
          commit(
            CompanyIndicatorMutations.SET_REQUEST_STATE_COMPANY_INDICATOR,
            res
          );
          commit(
            CompanyIndicatorMutations.SET_IS_UPDATING_COMPANY_INDICATOR,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CompanyIndicatorMutations.SET_REQUEST_STATE_COMPANY_INDICATOR,
            err
          );
          commit(
            CompanyIndicatorMutations.SET_IS_UPDATING_COMPANY_INDICATOR,
            false
          );
          reject(err);
        });
    });
  },

  async archiveCompanyIndicators(
    { commit, rootGetters },
    { companyIndicatorIds = [] }: { companyIndicatorIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        CompanyIndicatorMutations.SET_IS_ARCHIVING_COMPANY_INDICATOR,
        true
      );
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Directory/CompanyIndicator/Archive",
          {
            data: companyIndicatorIds,
          }
        )
        .then((res) => {
          companyIndicatorIds.forEach((companyIndicatorId: number) => {
            let companyIndicator = rootGetters.companyIndicatorsList.find(
              (companyIndicator: CompanyIndicatorRequestDto) =>
                companyIndicator.id == companyIndicatorId
            );
            rootGetters.companyIndicatorsListArchived.unshift(companyIndicator);

            if (
              rootGetters.companyIndicatorsList.find(
                (companyIndicator: CompanyIndicatorRequestDto) =>
                  companyIndicator.id == companyIndicatorId
              )
            ) {
              rootGetters.companyIndicatorsList.splice(
                rootGetters.companyIndicatorsList.findIndex(
                  (companyIndicator: CompanyIndicatorRequestDto) =>
                    companyIndicator.id == companyIndicatorId
                ),
                1
              );
            }
          });
          commit(
            CompanyIndicatorMutations.SET_COMPANY_INDICATORS_LIST,
            rootGetters.companyIndicatorsList
          );
          commit(
            CompanyIndicatorMutations.SET_COMPANY_INDICATORS_LIST_ARCHIVED,
            rootGetters.companyIndicatorsListArchived
          );
          commit(
            CompanyIndicatorMutations.SET_REQUEST_STATE_COMPANY_INDICATOR,
            res
          );
          commit(
            CompanyIndicatorMutations.SET_IS_ARCHIVING_COMPANY_INDICATOR,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CompanyIndicatorMutations.SET_REQUEST_STATE_COMPANY_INDICATOR,
            err
          );
          commit(
            CompanyIndicatorMutations.SET_IS_ARCHIVING_COMPANY_INDICATOR,
            false
          );
          reject(err);
        });
    });
  },

  async deleteCompanyIndicators(
    { commit, rootGetters },
    { companyIndicatorIds }: { companyIndicatorIds: number[] }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(CompanyIndicatorMutations.SET_IS_DELETING_COMPANY_INDICATOR, true);
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Directory/CompanyIndicator/Delete",
          {
            data: companyIndicatorIds,
          }
        )
        .then((res) => {
          companyIndicatorIds.forEach((companyIndicatorId: number) => {
            if (
              rootGetters.companyIndicatorsList.find(
                (companyIndicator: CompanyIndicatorRequestDto) =>
                  companyIndicator.id == companyIndicatorId
              )
            ) {
              rootGetters.companyIndicatorsList.splice(
                rootGetters.companyIndicatorsList.findIndex(
                  (companyIndicator: CompanyIndicatorRequestDto) =>
                    companyIndicator.id == companyIndicatorId
                ),
                1
              );
            }

            if (
              rootGetters.companyIndicatorsListArchived.find(
                (companyIndicator: CompanyIndicatorRequestDto) =>
                  companyIndicator.id == companyIndicatorId
              )
            ) {
              rootGetters.companyIndicatorsListArchived.splice(
                rootGetters.companyIndicatorsListArchived.findIndex(
                  (companyIndicator: CompanyIndicatorRequestDto) =>
                    companyIndicator.id == companyIndicatorId
                ),
                1
              );
            }
          });
          commit(
            CompanyIndicatorMutations.SET_COMPANY_INDICATORS_LIST,
            rootGetters.companyIndicatorsList
          );
          commit(
            CompanyIndicatorMutations.SET_COMPANY_INDICATORS_LIST_ARCHIVED,
            rootGetters.companyIndicatorsListArchived
          );

          commit(
            CompanyIndicatorMutations.SET_REQUEST_STATE_COMPANY_INDICATOR,
            res
          );
          commit(
            CompanyIndicatorMutations.SET_IS_DELETING_COMPANY_INDICATOR,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CompanyIndicatorMutations.SET_REQUEST_STATE_COMPANY_INDICATOR,
            err
          );
          commit(
            CompanyIndicatorMutations.SET_IS_DELETING_COMPANY_INDICATOR,
            false
          );
          reject(err);
        });
    });
  },
};
