import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { ActivitySourceTypeRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IActivitySourceTypeState } from "./states";

export enum ActivitySourceTypeMutations {
    SET_ACTIVITY_SOURCE_TYPES_LIST = "SET_ACTIVITY_SOURCE_TYPES_LIST",
    SET_ACTIVITY_SOURCE_TYPES_LIST_ARCHIVED = "SET_ACTIVITY_SOURCE_TYPES_LIST_ARCHIVED",
    SET_ACTIVITY_SOURCE_TYPE = "SET_ACTIVITY_SOURCE_TYPE",

    //FILTERS
    SET_ACTIVITY_SOURCE_TYPE_FILTERS = "SET_ACTIVITY_SOURCE_TYPE_FILTERS",

    // COMMON
    SET_IS_LOADING_ACTIVITY_SOURCE_TYPE_LIST = "SET_IS_LOADING_ACTIVITY_SOURCE_TYPE_LIST",
    SET_IS_LOADING_ACTIVITY_SOURCE_TYPE_LIST_ARCHIVED = "SET_IS_LOADING_ACTIVITY_SOURCE_TYPE_LIST_ARCHIVED",
    SET_IS_LOADING_ACTIVITY_SOURCE_TYPE = "SET_IS_LOADING_ACTIVITY_SOURCE_TYPE",
    SET_IS_CREATING_ACTIVITY_SOURCE_TYPE = "SET_IS_CREATING_ACTIVITY_SOURCE_TYPE",
    SET_IS_UPDATING_ACTIVITY_SOURCE_TYPE = "SET_IS_UPDATING_ACTIVITY_SOURCE_TYPE",
    SET_IS_DELETING_ACTIVITY_SOURCE_TYPE = "SET_IS_DELETING_ACTIVITY_SOURCE_TYPE",
    SET_IS_ARCHIVING_ACTIVITY_SOURCE_TYPE = "SET_IS_ARCHIVING_ACTIVITY_SOURCE_TYPE",
    SET_REQUEST_STATE_ACTIVITY_SOURCE_TYPE = "SET_REQUEST_STATE_ACTIVITY_SOURCE_TYPE",
}

export const mutations: MutationTree<IActivitySourceTypeState> = {
    [ActivitySourceTypeMutations.SET_ACTIVITY_SOURCE_TYPES_LIST]: (state, payload: ActivitySourceTypeRequestDto[]) => {
        state.activitySourceTypesList = payload;
    },
    [ActivitySourceTypeMutations.SET_ACTIVITY_SOURCE_TYPES_LIST_ARCHIVED]: (state, payload: ActivitySourceTypeRequestDto[]) => {
        state.activitySourceTypesListArchived = payload;
    }, 
    [ActivitySourceTypeMutations.SET_ACTIVITY_SOURCE_TYPE]: (state, payload: ActivitySourceTypeRequestDto) => {
        state.activitySourceType = payload;
    },

    //FILTERS
    [ActivitySourceTypeMutations.SET_ACTIVITY_SOURCE_TYPE_FILTERS]: (state, payload: any) => {
        state.activitySourceTypeFilters = payload;
    },


    //COMMON
    [ActivitySourceTypeMutations.SET_IS_LOADING_ACTIVITY_SOURCE_TYPE_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingActivitySourceTypesListArchived = payload;
    },
    [ActivitySourceTypeMutations.SET_IS_LOADING_ACTIVITY_SOURCE_TYPE_LIST]: (state, payload: boolean) => {
        state.isLoadingActivitySourceTypesList = payload;
    },
    [ActivitySourceTypeMutations.SET_IS_LOADING_ACTIVITY_SOURCE_TYPE]: (state, payload: boolean) => {
        state.isLoadingActivitySourceType = payload;
    },
    [ActivitySourceTypeMutations.SET_IS_CREATING_ACTIVITY_SOURCE_TYPE]: (state, payload: boolean) => {
        state.isCreatingActivitySourceType = payload;
    },
    [ActivitySourceTypeMutations.SET_IS_UPDATING_ACTIVITY_SOURCE_TYPE]: (state, payload: boolean) => {
        state.isUpdatingActivitySourceType = payload;
    },
    [ActivitySourceTypeMutations.SET_IS_DELETING_ACTIVITY_SOURCE_TYPE]: (state, payload: boolean) => {
        state.isDeletingActivitySourceType = payload;
    },
    [ActivitySourceTypeMutations.SET_IS_ARCHIVING_ACTIVITY_SOURCE_TYPE]: (state, payload: boolean) => {
        state.isArchivingActivitySourceType = payload;
    },
    [ActivitySourceTypeMutations.SET_REQUEST_STATE_ACTIVITY_SOURCE_TYPE]: (state, payload: IRequestState) => {
        state.requestStateActivitySourceType = payload;
    },
}