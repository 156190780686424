import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { QuoteDownPaymentsRequestDto } from "Api";
import RootState from "@/store/modules/rootState"
import { IQuoteDownPaymentsRequestState } from "./states";
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

export const state: IQuoteDownPaymentsRequestState = {
    quoteDownPaymentsRequestsList                  : Array<QuoteDownPaymentsRequestDto>(),
    quoteDownPaymentsRequestsListArchived          : Array<QuoteDownPaymentsRequestDto>(),
    quoteDownPaymentsRequest                       : <QuoteDownPaymentsRequestDto>{},
    isLoadingQuoteDownPaymentsRequestsList         : false,
    isLoadingQuoteDownPaymentsRequestsListArchived : false,
    isLoadingQuoteDownPaymentsRequest              : false,
    isCreatingQuoteDownPaymentsRequest             : false,
    isUpdatingQuoteDownPaymentsRequest             : false,
    isDeletingQuoteDownPaymentsRequest             : false,
    requestStateQuoteDownPaymentsRequest           : <IRequestState>{},
    isArchivingQuoteDownPaymentsRequest            : false,
}

export const QuoteDownPaymentsRequestModule: Module<IQuoteDownPaymentsRequestState, RootState> = {
  state, 
  getters,
  mutations,
  actions
}