import { IInvoiceOptionDefaultState } from "./states";
import { Module } from "vuex";
import { InvoiceOptionRequestDto } from "@/../Api";
import RootState from "@/store/modules/rootState";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

const state: IInvoiceOptionDefaultState = {
  invoiceOptionDefault: <InvoiceOptionRequestDto>{},
  invoiceOptionDefaultForm: <InvoiceOptionRequestDto>{},
  isLoadingInvoiceOptionDefault: false,
  requestStateInvoiceOptionDefault: <IRequestState>{},
};

export const InvoiceOptionDefaultModule: Module<
  IInvoiceOptionDefaultState,
  RootState
> = {
  state,
  getters,
  mutations,
  actions,
};
