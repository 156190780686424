// import { CurrencyRequestDto } from "Api";
import axios from "axios";
import { ActionTree } from "vuex";
import { ICurrencyState } from "./states";
import { CurrencyMutations } from "./mutations";
import RootState from "@/store/modules/rootState";

export const actions: ActionTree<ICurrencyState, RootState> = {
  getCurrencies(
    { commit },
    { updateState = true }: { updateState?: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(CurrencyMutations.SET_IS_LOADING_CURRENCIES_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Parameters/Currency/GetCurrencies`;
      axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(CurrencyMutations.SET_IS_LOADING_CURRENCIES_LIST, false);
            commit(CurrencyMutations.SET_CURRENCIES_LIST, res.data);
          }
          commit(CurrencyMutations.SET_REQUEST_STATE_CURRENCY, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CurrencyMutations.SET_IS_LOADING_CURRENCIES_LIST, false);
          commit(CurrencyMutations.SET_REQUEST_STATE_CURRENCY, err);
          reject(err);
        });
    });
  },

  async getCurrencyById(
    { commit },
    {
      currencyId,
      updateState = true,
    }: { currencyId: number; updateState?: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(CurrencyMutations.SET_IS_CREATING_CURRENCY, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Parameters/Currency/GetCurrencyById`;
      await axios
        .get(url, {
          params: {
            currencyId,
          },
        })
        .then((res) => {
          if (updateState) {
            commit(CurrencyMutations.SET_IS_LOADING_CURRENCY, false);
            commit(CurrencyMutations.SET_CURRENCY, res.data);
          }
          commit(CurrencyMutations.SET_REQUEST_STATE_CURRENCY, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CurrencyMutations.SET_IS_LOADING_CURRENCY, false);
          commit(CurrencyMutations.SET_REQUEST_STATE_CURRENCY, err);
          reject(err);
        });
    });
  },
};
