import { AffairCategoryRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { AffairCategoryMutations } from "./mutations";
import { IAffairCategoryState } from "./states";

export const actions: ActionTree<IAffairCategoryState, RootState> = {
  async getAffairCategoriesTree(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          AffairCategoryMutations.SET_IS_LOADING_AFFAIR_CATEGORIES_TREE_LIST,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/AffairCategory/GetAffairCategoriesTree`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              AffairCategoryMutations.SET_IS_LOADING_AFFAIR_CATEGORIES_TREE_LIST,
              false
            );
            commit(
              AffairCategoryMutations.SET_AFFAIR_CATEGORIES_TREE_LIST,
              res.data
            );
          }
          commit(
            AffairCategoryMutations.SET_REQUEST_STATE_AFFAIR_CATEGORY,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            AffairCategoryMutations.SET_IS_LOADING_AFFAIR_CATEGORIES_TREE_LIST,
            false
          );
          commit(
            AffairCategoryMutations.SET_REQUEST_STATE_AFFAIR_CATEGORY,
            err
          );
          reject(err);
        });
    });
  },

  async getAffairCategories(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          AffairCategoryMutations.SET_IS_LOADING_AFFAIR_CATEGORIES_LIST,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/AffairCategory/GetAffairCategories`;
      await axios
        .get(url)
        .then((res) => {
          let affairCategories = res.data.map((affairCategory: any) => {
            return {
              ...affairCategory,
              parentId: affairCategory.parent ? affairCategory.parent.id : null,
              hasChild: res.data.filter(
                (el) => el.parent?.id === affairCategory.id
              ).length
                ? true
                : false,
            };
          });
          if (updateState) {
            commit(
              AffairCategoryMutations.SET_IS_LOADING_AFFAIR_CATEGORIES_LIST,
              false
            );
            commit(
              AffairCategoryMutations.SET_AFFAIR_CATEGORIES_LIST,
              affairCategories
            );
          }
          commit(
            AffairCategoryMutations.SET_REQUEST_STATE_AFFAIR_CATEGORY,
            res
          );
          resolve(affairCategories);
        })
        .catch((err) => {
          console.error(err);
          commit(
            AffairCategoryMutations.SET_IS_LOADING_AFFAIR_CATEGORIES_LIST,
            false
          );
          commit(
            AffairCategoryMutations.SET_REQUEST_STATE_AFFAIR_CATEGORY,
            err
          );
          reject(err);
        });
    });
  },

  async getAffairCategoriesArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          AffairCategoryMutations.SET_IS_LOADING_AFFAIR_CATEGORIES_LIST_ARCHIVED,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/AffairCategory/GetAffairCategoriesArchived`;
      await axios
        .get(url)
        .then((res) => {
          let affairCategories = res.data.map((affairCategory: any) => {
            return {
              ...affairCategory,
              parentId: affairCategory.parent ? affairCategory.parent.id : null,
              hasChild: res.data.filter(
                (el) => el.parent?.id === affairCategory.id
              ).length
                ? true
                : false,
            };
          });
          if (updateState) {
            commit(
              AffairCategoryMutations.SET_IS_LOADING_AFFAIR_CATEGORIES_LIST_ARCHIVED,
              false
            );
            commit(
              AffairCategoryMutations.SET_AFFAIR_CATEGORIES_LIST_ARCHIVED,
              affairCategories
            );
            commit(
              AffairCategoryMutations.SET_IS_LOADING_AFFAIR_CATEGORIES_TREE_LIST_ARCHIVED,
              false
            );
            commit(
              AffairCategoryMutations.SET_AFFAIR_CATEGORIES_TREE_LIST_ARCHIVED,
              affairCategories
            );
          }
          commit(
            AffairCategoryMutations.SET_REQUEST_STATE_AFFAIR_CATEGORY,
            res
          );
          resolve(affairCategories);
        })
        .catch((err) => {
          console.error(err);
          commit(
            AffairCategoryMutations.SET_IS_LOADING_AFFAIR_CATEGORIES_LIST_ARCHIVED,
            false
          );
          commit(
            AffairCategoryMutations.SET_REQUEST_STATE_AFFAIR_CATEGORY,
            err
          );
          reject(err);
        });
    });
  },

  async getAffairCategoryById(
    { commit },
    {
      affairCategoryId,
      updateState = true,
    }: { affairCategoryId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(AffairCategoryMutations.SET_IS_LOADING_AFFAIR_CATEGORY, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Directory/AffairCategory/GetAffairCategoryById`,
          {
            params: {
              affairCategoryId: affairCategoryId,
            },
          }
        )
        .then((res) => {
          let affairCategory = {
            ...res.data,
            parentId: res.data.parent ? res.data.parent.id : null,
          };
          if (updateState) {
            commit(AffairCategoryMutations.SET_AFFAIR_CATEGORY, affairCategory);
            commit(
              AffairCategoryMutations.SET_IS_LOADING_AFFAIR_CATEGORY,
              false
            );
          }
          commit(
            AffairCategoryMutations.SET_REQUEST_STATE_AFFAIR_CATEGORY,
            res
          );
          resolve(affairCategory);
        })
        .catch((err) => {
          console.error(err);
          commit(
            AffairCategoryMutations.SET_REQUEST_STATE_AFFAIR_CATEGORY,
            err
          );
          commit(AffairCategoryMutations.SET_IS_LOADING_AFFAIR_CATEGORY, false);
          reject(err);
        });
    });
  },

  async getAffairCategoryArchivedById(
    { commit },
    {
      affairCategoryId,
      updateState = true,
    }: { affairCategoryId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(AffairCategoryMutations.SET_IS_LOADING_AFFAIR_CATEGORY, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Directory/AffairCategory/GetAffairCategoryArchivedById`,
          {
            params: {
              affairCategoryId: affairCategoryId,
            },
          }
        )
        .then((res) => {
          let affairCategoryArchived = {
            ...res.data,
            parentId: res.data.parent ? res.data.parent.id : null,
          };
          if (updateState) {
            commit(
              AffairCategoryMutations.SET_AFFAIR_CATEGORY,
              affairCategoryArchived
            );
            commit(
              AffairCategoryMutations.SET_IS_LOADING_AFFAIR_CATEGORY,
              false
            );
          }
          commit(
            AffairCategoryMutations.SET_REQUEST_STATE_AFFAIR_CATEGORY,
            res
          );
          resolve(affairCategoryArchived);
        })
        .catch((err) => {
          console.error(err);
          commit(
            AffairCategoryMutations.SET_REQUEST_STATE_AFFAIR_CATEGORY,
            err
          );
          commit(AffairCategoryMutations.SET_IS_LOADING_AFFAIR_CATEGORY, false);
          reject(err);
        });
    });
  },

  async createAffairCategory(
    { commit, rootGetters, dispatch },
    { affairCategory }: { affairCategory: AffairCategoryRequestDto }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(AffairCategoryMutations.SET_IS_CREATING_AFFAIR_CATEGORY, true);
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Directory/AffairCategory/CreateAffairCategory`,
          affairCategory
        )
        .then((res) => {
          dispatch("getAffairCategories", {});

          commit(
            AffairCategoryMutations.SET_AFFAIR_CATEGORIES_LIST,
            rootGetters.affairCategoriesList
          );
          commit(
            AffairCategoryMutations.SET_REQUEST_STATE_AFFAIR_CATEGORY,
            res
          );
          commit(
            AffairCategoryMutations.SET_IS_CREATING_AFFAIR_CATEGORY,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            AffairCategoryMutations.SET_REQUEST_STATE_AFFAIR_CATEGORY,
            err
          );
          commit(
            AffairCategoryMutations.SET_IS_CREATING_AFFAIR_CATEGORY,
            false
          );
          reject(err);
        });
    });
  },

  async updateAffairCategory(
    { commit, rootGetters, dispatch },
    { affairCategory }: { affairCategory: AffairCategoryRequestDto }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(AffairCategoryMutations.SET_IS_UPDATING_AFFAIR_CATEGORY, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Directory/AffairCategory/UpdateAffairCategory`,
          affairCategory
        )
        .then((res) => {
          dispatch("getAffairCategories", {});

          commit(
            AffairCategoryMutations.SET_AFFAIR_CATEGORIES_LIST,
            rootGetters.affairCategoriesList
          );
          commit(
            AffairCategoryMutations.SET_REQUEST_STATE_AFFAIR_CATEGORY,
            res
          );
          commit(
            AffairCategoryMutations.SET_IS_UPDATING_AFFAIR_CATEGORY,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            AffairCategoryMutations.SET_REQUEST_STATE_AFFAIR_CATEGORY,
            err
          );
          commit(
            AffairCategoryMutations.SET_IS_UPDATING_AFFAIR_CATEGORY,
            false
          );
          reject(err);
        });
    });
  },

  async restoreAffairCategories(
    { commit, rootGetters },
    { affairCategoryIds = [] }: { affairCategoryIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(AffairCategoryMutations.SET_IS_UPDATING_AFFAIR_CATEGORY, true);
      axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Directory/AffairCategory/Restore",
          affairCategoryIds
        )
        .then((res) => {
          affairCategoryIds.forEach((id) => {
            rootGetters.affairCategoriesListArchived.splice(
              rootGetters.affairCategoriesListArchived.findIndex(
                (elem) => elem.id == id
              ),
              1
            );
          });
          commit(
            AffairCategoryMutations.SET_AFFAIR_CATEGORIES_LIST_ARCHIVED,
            rootGetters.affairCategoriesListArchived
          );
          commit(
            AffairCategoryMutations.SET_REQUEST_STATE_AFFAIR_CATEGORY,
            res
          );
          commit(
            AffairCategoryMutations.SET_IS_UPDATING_AFFAIR_CATEGORY,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            AffairCategoryMutations.SET_REQUEST_STATE_AFFAIR_CATEGORY,
            err
          );
          commit(
            AffairCategoryMutations.SET_IS_UPDATING_AFFAIR_CATEGORY,
            false
          );
          reject(err);
        });
    });
  },

  async archiveAffairCategories(
    { commit, rootGetters },
    { affairCategoryIds = [] }: { affairCategoryIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(AffairCategoryMutations.SET_IS_ARCHIVING_AFFAIR_CATEGORY, true);
      axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Directory/AffairCategory/Archive",
          {
            data: affairCategoryIds,
          }
        )
        .then((res) => {
          affairCategoryIds.forEach((id) => {
            rootGetters.affairCategoriesList.splice(
              rootGetters.affairCategoriesList.findIndex(
                (elem) => elem.id == id
              ),
              1
            );
          });
          commit(
            AffairCategoryMutations.SET_AFFAIR_CATEGORIES_LIST,
            rootGetters.affairCategoriesList
          );
          commit(
            AffairCategoryMutations.SET_REQUEST_STATE_AFFAIR_CATEGORY,
            res
          );
          commit(
            AffairCategoryMutations.SET_IS_ARCHIVING_AFFAIR_CATEGORY,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            AffairCategoryMutations.SET_REQUEST_STATE_AFFAIR_CATEGORY,
            err
          );
          commit(
            AffairCategoryMutations.SET_IS_ARCHIVING_AFFAIR_CATEGORY,
            false
          );
          reject(err);
        });
    });
  },

  async deleteAffairCategories(
    { commit, rootGetters },
    { affairCategoryIds = [] }: { affairCategoryIds: number[] }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(AffairCategoryMutations.SET_IS_DELETING_AFFAIR_CATEGORY, true);
      await axios
        .delete(
          `${process.env.VUE_APP_API_ORISIS}Directory/AffairCategory/Delete`,
          {
            data: affairCategoryIds,
          }
        )
        .then((res) => {
          affairCategoryIds.forEach((id) => {
            rootGetters.affairCategoriesListArchived.splice(
              rootGetters.affairCategoriesListArchived.findIndex(
                (elem) => elem.id == id
              ),
              1
            );
          });
          commit(
            AffairCategoryMutations.SET_AFFAIR_CATEGORIES_LIST_ARCHIVED,
            rootGetters.affairCategoriesListArchived
          );
          commit(
            AffairCategoryMutations.SET_REQUEST_STATE_AFFAIR_CATEGORY,
            res
          );
          commit(
            AffairCategoryMutations.SET_IS_DELETING_AFFAIR_CATEGORY,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            AffairCategoryMutations.SET_REQUEST_STATE_AFFAIR_CATEGORY,
            err
          );
          commit(
            AffairCategoryMutations.SET_IS_DELETING_AFFAIR_CATEGORY,
            false
          );
          reject(err);
        });
    });
  },
};
