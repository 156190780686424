import store from "@/store";
import {
  getNewIndex,
  calculateTotalDeliveryFormLine,
} from "@/types/api-orisis/library/DetailDeliveryFormOperations";
import { CommonLineDeliveryForm } from "@/types/api-orisis/models/LineModels";

export function calculateSubTotalLine(line: any, newLine: boolean): void {
  let deliveryFormDetails = store.getters.getDeliveryFormDetails;
  let subTotal = 0;
  let i = line.indexArray;
  while (i >= 0 && deliveryFormDetails[i].type != 5) {
    if (
      deliveryFormDetails[i].type != 1 &&
      deliveryFormDetails[i].type != 2 &&
      deliveryFormDetails[i].type != 3 &&
      deliveryFormDetails[i].type != 4 &&
      deliveryFormDetails[i].type != 5 &&
      deliveryFormDetails[i].type != 6
    ) {
      subTotal += calculateTotalDeliveryFormLine(deliveryFormDetails[i]);
    }
    i--;
    if (i < 0) break;
  }
  line.total = line.unitPriceHt = Math.round(subTotal * 100) / 100;
  if (newLine) {
    getNewIndex(store.getters.getDeliveryFormDetails, line).then(
      async (res: any) => {
        let newObject = CommonLineDeliveryForm({
          ...line,
          index: res.index,
          type: res.type,
        });
        await store.dispatch("createDeliveryFormDetail", {
          deliveryFormDetail: newObject,
        });
      }
    );
  } else {
    store.dispatch("updateDeliveryFormDetail", { deliveryFormDetail: line });
  }
}

export function calculateSubTotalLineBySection(line: any): void {
  let deliveryFormDetails = store.getters.getDeliveryFormDetails;
  let subTotal = 0;
  let i = deliveryFormDetails.findIndex((el) => el.id == line.id);
  let j = 0;
  let startIndex = deliveryFormDetails.findIndex(
    (el, index) => el.type == 5 && index < i
  );
  let endIndex =
    line.type == 5
      ? i
      : deliveryFormDetails.findIndex((el, index) => el.type == 5 && index > i);

  if (startIndex < 0) {
    startIndex = j = 0;
  } else {
    j = startIndex + 1;
  }
  if (endIndex >= 0) {
    let end = deliveryFormDetails[endIndex];

    while (j < endIndex) {
      if (
        deliveryFormDetails[j].type != 1 &&
        deliveryFormDetails[j].type != 2 &&
        deliveryFormDetails[j].type != 3 &&
        deliveryFormDetails[j].type != 4 &&
        deliveryFormDetails[j].type != 5 &&
        deliveryFormDetails[j].type != 6
      ) {
        subTotal += calculateTotalDeliveryFormLine(deliveryFormDetails[j]);
      }
      j++;
    }
    end.total = end.unitPriceHt = Math.round(subTotal * 100) / 100;
    store.dispatch("updateDeliveryFormDetail", { deliveryFormDetail: end });
  }
}

export function calculateSubTotalLines(update: boolean): void {
  let deliveryFormDetails = store.getters.getDeliveryFormDetails;
  let subTotalLines = deliveryFormDetails.filter((el) => el.type == 5);
  if (subTotalLines.length > 0) {
    for (let j = 0; j < subTotalLines.length; j++) {
      let subTotal = 0;
      const line = subTotalLines[j];
      let i = deliveryFormDetails.findIndex((el) => el.id == line.id) - 1;
      while (i >= 0 && deliveryFormDetails[i].type !== 5) {
        if (
          deliveryFormDetails[i].type != 1 &&
          deliveryFormDetails[i].type != 2 &&
          deliveryFormDetails[i].type != 3 &&
          deliveryFormDetails[i].type != 4 &&
          deliveryFormDetails[i].type != 5 &&
          deliveryFormDetails[i].type != 6
        ) {
          subTotal += calculateTotalDeliveryFormLine(deliveryFormDetails[i]);
        }
        i--;
        if (i < 0) break;
      }
      deliveryFormDetails[
        deliveryFormDetails.findIndex((el) => el.id == line.id)
      ].outPutQuantity = 1;
      deliveryFormDetails[
        deliveryFormDetails.findIndex((el) => el.id == line.id)
      ].total = deliveryFormDetails[
        deliveryFormDetails.findIndex((el) => el.id == line.id)
      ].unitPriceHt = Math.round(subTotal * 100) / 100;
      if (update) {
        store.dispatch(
          "updateDeliveryFormDetail",
          deliveryFormDetails[
            deliveryFormDetails.findIndex((el) => el.id == line.id)
          ]
        );
      }
    }
  }
}
