import { IInstitutionTypeState } from "./states";
import { MutationTree } from "vuex";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";


export enum InstitutionTypeMutations{
    SET_INSTITUTION_TYPES_LIST = "SET_INSTITUTION_TYPES_LIST",
    
    // COMON 
    SET_IS_LOADING_INSTITUTION_TYPES_LIST = "SET_IS_LOADING_INSTITUTION_TYPES_LIST",
    SET_IS_CREATING_INSTITUTION_TYPE = "SET_IS_CREATING_INSTITUTION_TYPE",
    SET_IS_UPDATING_INSTITUTION_TYPE = "SET_IS_UPDATING_INSTITUTION_TYPE",
    SET_IS_DELETING_INSTITUTION_TYPE = "SET_IS_DELETING_INSTITUTION_TYPE",
    SET_IS_ARCHIVING_INSTITUTION_TYPE = "SET_IS_ARCHIVING_INSTITUTION_TYPE",
    SET_REQUEST_STATE_INSTITUTION_TYPE = "SET_REQUEST_STATE_INSTITUTION_TYPE",
  }

  export const mutations: MutationTree<IInstitutionTypeState> = {
    [InstitutionTypeMutations.SET_INSTITUTION_TYPES_LIST]: (state, payload: any[]) => {
        state.institutionTypesList = payload;
    },

    //COMMON
    [InstitutionTypeMutations.SET_IS_LOADING_INSTITUTION_TYPES_LIST]: (state, payload: boolean) => {
        state.isLoadingInstitutionTypesList = payload;
    },
    [InstitutionTypeMutations.SET_IS_CREATING_INSTITUTION_TYPE]: (state, payload: boolean) => {
        state.isCreatingInstitutionType = payload;
    },
    [InstitutionTypeMutations.SET_IS_UPDATING_INSTITUTION_TYPE]: (state, payload: boolean) => {
        state.isUpdatingInstitutionType = payload;
    },
    [InstitutionTypeMutations.SET_IS_DELETING_INSTITUTION_TYPE]: (state, payload: boolean) => {
        state.isDeletingInstitutionType = payload;
    },
    [InstitutionTypeMutations.SET_IS_ARCHIVING_INSTITUTION_TYPE]: (state, payload: boolean) => {
        state.isArchivingInstitutionType = payload;
    },

    
    [InstitutionTypeMutations.SET_REQUEST_STATE_INSTITUTION_TYPE]: (state, payload: IRequestState) => {
        state.requestStateInstitutionType = payload;
    },
}