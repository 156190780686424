import { AffairRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { AffairMutations } from "./mutations";
import { IAffairState } from "./states";
import { Timezone } from "@syncfusion/ej2-schedule";
let timezoneSchedule = new Timezone();

export const actions: ActionTree<IAffairState, RootState> = {
  async getAffairs(
    { commit },
    {
      institutionIds = [],
      companyId = null,
      updateState = true,
    }: { institutionIds: number[]; companyId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(AffairMutations.SET_IS_LOADING_AFFAIR_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/Affair/GetAffairs`;
      await axios
        .get(url, {
          data: institutionIds,
          params: {
            companyId: companyId,
          },
        })
        .then((res) => {
          let result = res.data.map((el) => {
            return {
              ...el,
              parentAffairLabel: el.parentAffairId
                ? res.data.find((elem) => elem.id == el.parentAffairId).name
                : null,
              collaboratorIds: el.collaborators.length
                ? el.collaborators.map((elem) => elem.id)
                : null,
              startDate: el.startDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(new Date(el.startDate))
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              endDate: el.endDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(new Date(el.endDate))
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              affairCategoryId: el.affairCategory
                ? [JSON.stringify(el.affairCategory.id)]
                : null,
            };
          });
          if (updateState) {
            commit(AffairMutations.SET_IS_LOADING_AFFAIR_LIST, false);
            commit(AffairMutations.SET_AFFAIRS_LIST, result);
          }
          commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, res);
          resolve(result);
        })
        .catch((err) => {
          console.error(err);
          commit(AffairMutations.SET_IS_LOADING_AFFAIR_LIST, false);
          commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, err);
          reject(err);
        });
    });
  },

  async getAffairsByCompanyId(
    { commit },
    {
      companyId,
      institutionIds = [],
      updateState = true,
    }: { companyId: number; institutionIds: number[]; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(AffairMutations.SET_IS_LOADING_AFFAIR_LIST_BY_COMPANY, true);
      }
      await axios
        .get(process.env.VUE_APP_API_ORISIS + "Directory/Affair/GetAffairs", {
          params: {
            companyId: companyId,
          },
          data: institutionIds,
        })
        .then((res) => {
          let result = res.data.map((el) => {
            return {
              ...el,
              startDate: el.startDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(new Date(el.startDate))
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              endDate: el.endDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(new Date(el.endDate))
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              affairCategoryId: el.affairCategory
                ? [JSON.stringify(el.affairCategory.id)]
                : null,
            };
          });
          if (updateState) {
            commit(
              AffairMutations.SET_IS_LOADING_AFFAIR_LIST_BY_COMPANY,
              false
            );
            commit(AffairMutations.SET_AFFAIRS_LIST_BY_COMPANY, result);
          }
          commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, res);
          resolve(result);
        })
        .catch((err) => {
          console.error(err);
          commit(AffairMutations.SET_IS_LOADING_AFFAIR_LIST_BY_COMPANY, false);
          commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, err);
          reject(err);
        });
    });
  },

  async getAffairById(
    { commit },
    { affairId, updateState = true }: { affairId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(AffairMutations.SET_IS_LOADING_AFFAIR, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Directory/Affair/GetAffairById`,
          {
            params: {
              affairId: affairId,
            },
          }
        )
        .then((res) => {
          for (let i = 0; i < res.data.customFields.length; i++) {
            const customField = res.data.customFields[i];
            if (customField.customField.typeField == 10 && customField.value) {
              customField.value = customField.value.split(";");
            }
          }
          let result = {
            ...res.data,
            startDate: res.data.startDate
              ? new Date(
                  new Date(
                    timezoneSchedule.removeLocalOffset(
                      new Date(res.data.startDate)
                    )
                  ).setHours(12, 0, 0, 0)
                )
              : null,
            endDate: res.data.endDate
              ? new Date(
                  new Date(
                    timezoneSchedule.removeLocalOffset(
                      new Date(res.data.endDate)
                    )
                  ).setHours(12, 0, 0, 0)
                )
              : null,
            affairCategoryId: res.data.affairCategory
              ? [JSON.stringify(res.data.affairCategory.id)]
              : null,
            currencyId: res.data.currency ? res.data.currency.id : null,
          };
          if (updateState) {
            commit(AffairMutations.SET_AFFAIR, result);
            commit(AffairMutations.SET_IS_LOADING_AFFAIR, false);
          }
          commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, res);
          resolve(result);
        })
        .catch((err) => {
          console.error(err);
          commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, err);
          commit(AffairMutations.SET_IS_LOADING_AFFAIR, false);
          reject(err);
        });
    });
  },

  // getAffairCustomFieldsByAffairId

  async getAffairArchivedById(
    { commit },
    { affairId, updateState = true }: { affairId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(AffairMutations.SET_IS_LOADING_AFFAIR, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Directory/Affair/GetAffairArchivedById`,
          {
            params: {
              affairId: affairId,
            },
          }
        )
        .then((res) => {
          for (let i = 0; i < res.data.customFields.length; i++) {
            const customField = res.data.customFields[i];
            if (customField.customField.typeField == 10 && customField.value) {
              customField.value = customField.value.split(";");
            }
          }
          let result = {
            ...res.data,
            startDate: res.data.startDate
              ? new Date(
                  new Date(
                    timezoneSchedule.removeLocalOffset(
                      new Date(res.data.startDate)
                    )
                  ).setHours(12, 0, 0, 0)
                )
              : null,
            endDate: res.data.endDate
              ? new Date(
                  new Date(
                    timezoneSchedule.removeLocalOffset(
                      new Date(res.data.endDate)
                    )
                  ).setHours(12, 0, 0, 0)
                )
              : null,
            affairCategoryId: res.data.affairCategory
              ? [JSON.stringify(res.data.affairCategory.id)]
              : null,
          };
          if (updateState) {
            commit(AffairMutations.SET_AFFAIR, result);
            commit(AffairMutations.SET_IS_LOADING_AFFAIR, false);
          }
          commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, res);
          resolve(result);
        })
        .catch((err) => {
          console.error(err);
          commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, err);
          commit(AffairMutations.SET_IS_LOADING_AFFAIR, false);
          reject(err);
        });
    });
  },

  async getAffairsArchived(
    { commit },
    {
      institutionIds = [],
      companyId = null,
      updateState = true,
    }: { institutionIds: number[]; companyId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(AffairMutations.SET_IS_LOADING_AFFAIR_LIST_ARCHIVED, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/Affair/GetAffairsArchived`;
      await axios
        .get(url, {
          data: institutionIds,
          params: {
            companyId: companyId,
          },
        })
        .then((res) => {
          let result = res.data.map((el) => {
            return {
              ...el,
              startDate: el.startDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(new Date(el.startDate))
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              endDate: el.endDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(new Date(el.endDate))
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              affairCategoryId: el.affairCategory
                ? [JSON.stringify(el.affairCategory.id)]
                : null,
            };
          });
          if (updateState) {
            commit(AffairMutations.SET_IS_LOADING_AFFAIR_LIST_ARCHIVED, false);
            commit(AffairMutations.SET_AFFAIRS_LIST_ARCHIVED, result);
          }
          commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, res);
          resolve(result);
        })
        .catch((err) => {
          console.error(err);
          commit(AffairMutations.SET_IS_LOADING_AFFAIR_LIST_ARCHIVED, false);
          commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, err);
          reject(err);
        });
    });
  },

  async getAffairsArchivedByCompanyId(
    { commit },
    {
      companyId,
      institutionIds = [],
      updateState = true,
    }: { companyId: number; institutionIds: number[]; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          AffairMutations.SET_IS_LOADING_AFFAIR_LIST_ARCHIVED_BY_COMPANY,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/Affair/GetAffairsArchived`;
      await axios
        .get(url, {
          params: {
            companyId: companyId,
          },
          data: institutionIds,
        })
        .then((res) => {
          let result = res.data.map((el) => {
            return {
              ...el,
              startDate: el.startDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(new Date(el.startDate))
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              endDate: el.endDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(new Date(el.endDate))
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              affairCategoryId: el.affairCategory
                ? [JSON.stringify(el.affairCategory.id)]
                : null,
            };
          });
          if (updateState) {
            commit(
              AffairMutations.SET_IS_LOADING_AFFAIR_LIST_ARCHIVED_BY_COMPANY,
              false
            );
            commit(
              AffairMutations.SET_AFFAIRS_LIST_ARCHIVED_BY_COMPANY,
              result
            );
          }
          commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, res);
          resolve(result);
        })
        .catch((err) => {
          console.error(err);
          commit(
            AffairMutations.SET_IS_LOADING_AFFAIR_LIST_ARCHIVED_BY_COMPANY,
            false
          );
          commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, err);
          reject(err);
        });
    });
  },

  async createAffair(
    { commit, rootGetters },
    { affair, route }: { affair: AffairRequestDto; route: string }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(AffairMutations.SET_IS_CREATING_AFFAIR, true);
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Directory/Affair/CreateAffair`,
          {
            ...affair,
            affairCategoryId: affair.affairCategoryId
              ? Number(affair.affairCategoryId)
              : null,
          }
        )
        .then((res) => {
          let result = {
            ...res.data.data,
            startDate: res.data.data.startDate
              ? new Date(
                  new Date(
                    timezoneSchedule.removeLocalOffset(
                      new Date(res.data.data.startDate)
                    )
                  ).setHours(12, 0, 0, 0)
                )
              : null,
            endDate: res.data.data.endDate
              ? new Date(
                  new Date(
                    timezoneSchedule.removeLocalOffset(
                      new Date(res.data.data.endDate)
                    )
                  ).setHours(12, 0, 0, 0)
                )
              : null,
            affairCategoryId: res.data.data.affairCategory
              ? JSON.stringify(res.data.data.affairCategory.id)
              : null,
          };
          if (route == "affair") {
            rootGetters.affairsList.push(result);
            commit(AffairMutations.SET_AFFAIRS_LIST, rootGetters.affairsList);
          } else if (route == "company") {
            rootGetters.affairsListByCompany.push(result);
            commit(
              AffairMutations.SET_AFFAIRS_LIST_BY_COMPANY,
              rootGetters.affairsListByCompany
            );
          }
          commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, res);
          commit(AffairMutations.SET_IS_CREATING_AFFAIR, false);
          resolve(result);
        })
        .catch((err) => {
          console.error(err);
          commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, err);
          commit(AffairMutations.SET_IS_CREATING_AFFAIR, false);
          reject(err);
        });
    });
  },

  async duplicateAffair(
    { commit, rootGetters },
    { affair, route }: { affair: AffairRequestDto; route: string }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(AffairMutations.SET_IS_CREATING_AFFAIR, true);
      await axios
        .put(`${process.env.VUE_APP_API_ORISIS}Directory/Affair/UpdateAffair`, {
          ...affair,
          affairCategoryId: affair.affairCategoryId
            ? Number(affair.affairCategoryId)
            : null,
        })
        .then((res) => {
          let result = {
            ...res.data.data,
            startDate: res.data.data.startDate
              ? new Date(
                  new Date(
                    timezoneSchedule.removeLocalOffset(
                      new Date(res.data.data.startDate)
                    )
                  ).setHours(12, 0, 0, 0)
                )
              : null,
            endDate: res.data.data.endDate
              ? new Date(
                  new Date(
                    timezoneSchedule.removeLocalOffset(
                      new Date(res.data.data.endDate)
                    )
                  ).setHours(12, 0, 0, 0)
                )
              : null,
            affairCategoryId: res.data.data.affairCategory
              ? JSON.stringify(res.data.data.affairCategory.id)
              : null,
          };
          if (route == "affair") {
            rootGetters.affairsList.push(result);
            commit(AffairMutations.SET_AFFAIRS_LIST, rootGetters.affairsList);
          } else if (route == "company") {
            rootGetters.affairsListByCompany.push(result);
            commit(
              AffairMutations.SET_AFFAIRS_LIST_BY_COMPANY,
              rootGetters.affairsListByCompany
            );
          }
          commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, res);
          commit(AffairMutations.SET_IS_CREATING_AFFAIR, false);
          resolve(result);
        })
        .catch((err) => {
          console.error(err);
          commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, err);
          commit(AffairMutations.SET_IS_CREATING_AFFAIR, false);
          reject(err);
        });
    });
  },

  async updateAffair(
    { commit, rootGetters },
    {
      affair,
      updateState = true,
      route,
    }: { affair: AffairRequestDto; updateState: boolean; route: string }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(AffairMutations.SET_IS_UPDATING_AFFAIR, true);
      }
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Directory/Affair/UpdateAffair`,
          affair
        )
        .then((res) => {
          if (route == "affair") {
            rootGetters.affairsList.splice(
              rootGetters.affairsList.findIndex((elem) => elem.id == affair.id),
              1,
              res.data.data
            );
            commit(AffairMutations.SET_AFFAIRS_LIST, rootGetters.affairsList);
          } else if (route == "company") {
            rootGetters.affairsListByCompany.splice(
              rootGetters.affairsListByCompany.findIndex(
                (elem) => elem.id == affair.id
              ),
              1,
              res.data.data
            );
            commit(
              AffairMutations.SET_AFFAIRS_LIST_BY_COMPANY,
              rootGetters.affairsListByCompany
            );
          }
          commit(AffairMutations.SET_AFFAIR, res.data.data);
          commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, res.data.data);
          commit(AffairMutations.SET_IS_UPDATING_AFFAIR, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, err);
          commit(AffairMutations.SET_IS_UPDATING_AFFAIR, false);
          reject(err);
        });
    });
  },

  async updateAffairTagAffair(
    { commit },
    { affairIds, affairTagIds }: { affairIds: number[]; affairTagIds: number[] }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Directory/Affair/UpdateAffairTagAffair`,
          { affairIds: affairIds, affairTagIds: affairTagIds }
        )
        .then((res) => {
          commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, err);
          reject(err);
        });
    });
  },

  async updateAffairCustomField(
    { commit },
    {
      affairId,
      affairCustomFields,
    }: { affairId: number; affairCustomFields: any }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      const customFields = { ...affairCustomFields };
      for (let [key, value] of Object.entries(customFields)) {
        //if one of the value is an array, we stringify it and separate each elements with ';'
        if (Array.isArray(value)) {
          customFields[key] = value.join(";");
        }
      }
      commit(AffairMutations.SET_IS_UPDATING_AFFAIR, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Directory/Affair/UpdateAffairCustomField",
          customFields,
          {
            params: {
              affairId: affairId,
            },
          }
        )
        .then((res) => {
          commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, res);
          commit(AffairMutations.SET_IS_UPDATING_AFFAIR, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, err);
          commit(AffairMutations.SET_IS_UPDATING_AFFAIR, false);
          reject(err);
        });
    });
  },

  // updateAffairContacts

  // updateAffairCustomPerimeter

  async uploadFileAffair(
    { commit },
    { affairId, file }: { affairId: number; file: any }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(AffairMutations.SET_IS_UPDATING_AFFAIR, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Directory/Affair/UploadFile`,
          file,
          {
            params: {
              affairId: affairId,
            },
          }
        )
        .then((res) => {
          commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, res);
          commit(AffairMutations.SET_IS_UPDATING_AFFAIR, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, err);
          commit(AffairMutations.SET_IS_UPDATING_AFFAIR, false);
          reject(err);
        });
    });
  },

  async restoreAffairs(
    { commit, rootGetters },
    { affairIds = [], route }: { affairIds: number[]; route: string }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(AffairMutations.SET_IS_UPDATING_AFFAIR, true);
      axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Directory/Affair/Restore",
          affairIds
        )
        .then((res) => {
          if (route == "affair") {
            affairIds.forEach((affairId: number) => {
              rootGetters.affairsListArchived.splice(
                rootGetters.affairsListArchived.findIndex(
                  (affair) => affair.id == affairId
                ),
                1
              );
            });
            commit(
              AffairMutations.SET_AFFAIRS_LIST_ARCHIVED,
              rootGetters.affairsList
            );
          } else if (route == "company") {
            affairIds.forEach((affairId: number) => {
              rootGetters.affaisListArchivedByCompany.splice(
                rootGetters.affaisListArchivedByCompany.findIndex(
                  (affair) => affair.id == affairId
                ),
                1
              );
            });
            commit(
              AffairMutations.SET_AFFAIRS_LIST_ARCHIVED_BY_COMPANY,
              rootGetters.affaisListArchivedByCompany
            );
          }
          commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, res);
          commit(AffairMutations.SET_IS_UPDATING_AFFAIR, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, err);
          commit(AffairMutations.SET_IS_UPDATING_AFFAIR, false);
          reject(err);
        });
    });
  },

  async archiveAffairs(
    { commit, rootGetters },
    { affairIds = [], route }: { affairIds: number[]; route: string }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(AffairMutations.SET_IS_ARCHIVING_AFFAIR, true);
      await axios
        .delete(process.env.VUE_APP_API_ORISIS + "Directory/Affair/Archive", {
          data: affairIds,
        })
        .then((res) => {
          if (route == "affair") {
            affairIds.forEach((affairId: number) => {
              rootGetters.affairsList.splice(
                rootGetters.affairsList.findIndex(
                  (affair) => affair.id == affairId
                ),
                1
              );
            });
            commit(AffairMutations.SET_AFFAIRS_LIST, rootGetters.affairsList);
          } else if (route == "company") {
            affairIds.forEach((affairId: number) => {
              rootGetters.affairsListByCompany.splice(
                rootGetters.affairsListByCompany.findIndex(
                  (affair) => affair.id == affairId
                ),
                1
              );
            });
            commit(
              AffairMutations.SET_AFFAIRS_LIST_BY_COMPANY,
              rootGetters.affairsListByCompany
            );
          }
          commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, res);
          commit(AffairMutations.SET_IS_ARCHIVING_AFFAIR, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, err);
          commit(AffairMutations.SET_IS_ARCHIVING_AFFAIR, false);
          reject(err);
        });
    });
  },

  async deleteAffairs(
    { commit, rootGetters },
    { affairIds = [], route }: { affairIds: number[]; route: string }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(AffairMutations.SET_IS_DELETING_AFFAIR, true);
      await axios
        .delete(`${process.env.VUE_APP_API_ORISIS}Directory/Affair/Delete`, {
          data: affairIds,
        })
        .then((res) => {
          if (route == "affair") {
            affairIds.forEach((affairId: number) => {
              rootGetters.affairsListArchived.splice(
                rootGetters.affairsListArchived.findIndex(
                  (affair) => affair.id == affairId
                ),
                1
              );
            });
            commit(
              AffairMutations.SET_AFFAIRS_LIST_ARCHIVED,
              rootGetters.affairsListArchived
            );
          } else if (route == "company") {
            affairIds.forEach((affairId: number) => {
              rootGetters.affaisListArchivedByCompany.splice(
                rootGetters.affaisListArchivedByCompany.findIndex(
                  (affair) => affair.id == affairId
                ),
                1
              );
            });
            commit(
              AffairMutations.SET_AFFAIRS_LIST_ARCHIVED_BY_COMPANY,
              rootGetters.affaisListArchivedByCompany
            );
          }

          commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, res);
          commit(AffairMutations.SET_IS_DELETING_AFFAIR, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, err);
          commit(AffairMutations.SET_IS_DELETING_AFFAIR, false);
          reject(err);
        });
    });
  },
};
