import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { PayAllowanceTypeRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { IPayAllowanceTypeState } from './states'


export const getters: GetterTree<IPayAllowanceTypeState, RootState> = {
  payAllowanceTypesList: (state): PayAllowanceTypeRequestDto[] => state.payAllowanceTypesList,
  payAllowanceTypesListArchived: (state): PayAllowanceTypeRequestDto[] => state.payAllowanceTypesListArchived,
  payAllowanceTypesListTemplates: (state): PayAllowanceTypeRequestDto[] => state.payAllowanceTypesListTemplates,
  payAllowanceType     : (state): PayAllowanceTypeRequestDto => state.payAllowanceType,
  payAllowanceTypeArchived     : (state): PayAllowanceTypeRequestDto => state.payAllowanceTypeArchived,

  // COMMON
  isLoadingPayAllowanceTypesList   : (state): boolean => state.isLoadingPayAllowanceTypesList,
  isLoadingPayAllowanceTypesListArchived   : (state): boolean => state.isLoadingPayAllowanceTypesListArchived,
  isLoadingPayAllowanceTypesListTemplates   : (state): boolean => state.isLoadingPayAllowanceTypesListTemplates,
  isLoadingPayAllowanceType   : (state): boolean => state.isLoadingPayAllowanceType,
  isCreatingPayAllowanceType  : (state): boolean => state.isCreatingPayAllowanceType,
  isUpdatingPayAllowanceType  : (state): boolean => state.isUpdatingPayAllowanceType,
  isDeletingPayAllowanceType  : (state): boolean => state.isDeletingPayAllowanceType,
  isArchivingPayAllowanceType  : (state): boolean => state.isArchivingPayAllowanceType,
  requestStatePayAllowanceType: (state): IRequestState => state.requestStatePayAllowanceType,
}



