import { IWasteCollectionPointState } from "./states";
import { MutationTree } from "vuex";
import { WasteCollectionPointRequestDto } from "Api";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export enum WasteCollectionPointMutations {
  SET_WASTE_COLLECTION_POINTS_LIST = "SET_WASTE_COLLECTION_POINTS_LIST",
  SET_WASTE_COLLECTION_POINTS_LIST_ARCHIVED = "SET_WASTE_COLLECTION_POINTS_LIST_ARCHIVED",
  SET_WASTE_COLLECTION_POINT = "SET_WASTE_COLLECTION_POINT",

  // COMON
  SET_IS_LOADING_WASTE_COLLECTION_POINTS_LIST = "SET_IS_LOADING_WASTE_COLLECTION_POINTS_LIST",
  SET_IS_LOADING_WASTE_COLLECTION_POINTS_LIST_ARCHIVED = "SET_IS_LOADING_WASTE_COLLECTION_POINTS_LIST_ARCHIVED",
  SET_IS_LOADING_WASTE_COLLECTION_POINT = "SET_IS_LOADING_WASTE_COLLECTION_POINT",
  SET_IS_CREATING_WASTE_COLLECTION_POINT = "SET_IS_CREATING_WASTE_COLLECTION_POINT",
  SET_IS_UPDATING_WASTE_COLLECTION_POINT = "SET_IS_UPDATING_WASTE_COLLECTION_POINT",
  SET_IS_DELETING_WASTE_COLLECTION_POINT = "SET_IS_DELETING_WASTE_COLLECTION_POINT",
  SET_REQUEST_STATE_WASTE_COLLECTION_POINT = "SET_REQUEST_STATE_WASTE_COLLECTION_POINT",
  SET_IS_ARCHIVING_WASTE_COLLECTION_POINT = "SET_IS_ARCHIVING_WASTE_COLLECTION_POINT",  
  
}

  export const mutations: MutationTree<IWasteCollectionPointState> = {
    [WasteCollectionPointMutations.SET_WASTE_COLLECTION_POINTS_LIST]: (state, payload: WasteCollectionPointRequestDto[]) => {
        state.wasteCollectionPointsList = payload;
    },
    [WasteCollectionPointMutations.SET_WASTE_COLLECTION_POINTS_LIST_ARCHIVED]: (state, payload: WasteCollectionPointRequestDto[]) => {
        state.wasteCollectionPointsListArchived = payload;
    },
    [WasteCollectionPointMutations.SET_WASTE_COLLECTION_POINT]: (state, payload: WasteCollectionPointRequestDto) => {
        state.wasteCollectionPoint = payload;
    },


    //COMMON
    [WasteCollectionPointMutations.SET_IS_LOADING_WASTE_COLLECTION_POINTS_LIST]: (state, payload: boolean) => {
        state.isLoadingWasteCollectionPointsList = payload;
    },
    [WasteCollectionPointMutations.SET_IS_LOADING_WASTE_COLLECTION_POINTS_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingWasteCollectionPointsListArchived = payload;
    },
    [WasteCollectionPointMutations.SET_IS_LOADING_WASTE_COLLECTION_POINT]: (state, payload: boolean) => {
        state.isLoadingWasteCollectionPoint = payload;
    },
    [WasteCollectionPointMutations.SET_IS_CREATING_WASTE_COLLECTION_POINT]: (state, payload: boolean) => {
        state.isCreatingWasteCollectionPoint = payload;
    },
    [WasteCollectionPointMutations.SET_IS_UPDATING_WASTE_COLLECTION_POINT]: (state, payload: boolean) => {
        state.isUpdatingWasteCollectionPoint = payload;
    },
    [WasteCollectionPointMutations.SET_IS_DELETING_WASTE_COLLECTION_POINT]: (state, payload: boolean) => {
        state.isDeletingWasteCollectionPoint = payload;
    },
    [WasteCollectionPointMutations.SET_REQUEST_STATE_WASTE_COLLECTION_POINT]: (state, payload: IRequestState) => {
        state.requestStateWasteCollectionPoint = payload;
    },
    [WasteCollectionPointMutations.SET_IS_ARCHIVING_WASTE_COLLECTION_POINT]: (state, payload: boolean) => {
        state.isArchivingWasteCollectionPoint = payload;
    },
}