import { ContractRequestDto } from "@/../Api";
import RootState from "@/store/modules/rootState";
import axios from "axios";
import { ActionTree } from "vuex";
import { ContractMutations } from "./mutations";
import { IContractState } from "./states";

export const actions: ActionTree<IContractState, RootState> = {
  async getContracts(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(ContractMutations.SET_IS_LOADING_CONTRACT_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Parameters/Contract/GetContracts`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(ContractMutations.SET_IS_LOADING_CONTRACT_LIST, false);
            commit(ContractMutations.SET_CONTRACTS_LIST, res.data);
          }
          commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ContractMutations.SET_IS_LOADING_CONTRACT_LIST, false);
          commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, err);
          reject(err);
        });
    });
  },

  async getContractById(
    { commit },
    {
      contractId,
      updateState = true,
    }: { contractId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(ContractMutations.SET_IS_LOADING_CONTRACT, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Parameters/Contract/GetContractById`,
          {
            params: {
              contractId: contractId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(ContractMutations.SET_CONTRACT, res.data);
            commit(ContractMutations.SET_IS_LOADING_CONTRACT, false);
          }
          commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, err);
          commit(ContractMutations.SET_IS_LOADING_CONTRACT, false);
          reject(err);
        });
    });
  },

  async getContractsArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(ContractMutations.SET_IS_LOADING_CONTRACT_LIST_ARCHIVED, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Parameters/Contract/GetContractsArchived`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              ContractMutations.SET_IS_LOADING_CONTRACT_LIST_ARCHIVED,
              false
            );
            commit(ContractMutations.SET_CONTRACTS_LIST_ARCHIVED, res.data);
          }
          commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ContractMutations.SET_IS_LOADING_CONTRACT_LIST_ARCHIVED,
            false
          );
          commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, err);
          reject(err);
        });
    });
  },

  async getContractArchivedById(
    { commit },
    {
      contractId,
      updateState = true,
    }: { contractId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(ContractMutations.SET_IS_LOADING_CONTRACT, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Parameters/Contract/GetContractArchivedById`,
          {
            params: {
              contractId: contractId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(ContractMutations.SET_CONTRACT, res.data);
            commit(ContractMutations.SET_IS_LOADING_CONTRACT, false);
          }
          commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, err);
          commit(ContractMutations.SET_IS_LOADING_CONTRACT, false);
          reject(err);
        });
    });
  },

  async createContract(
    { commit, rootGetters },
    { contract }: { contract: ContractRequestDto }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(ContractMutations.SET_IS_CREATING_CONTRACT, true);
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Parameters/Contract/CreateContract`,
          contract
        )
        .then((res) => {
          rootGetters.contractsList.push(res.data.data);
          commit(
            ContractMutations.SET_CONTRACTS_LIST,
            rootGetters.contractsList
          );
          commit(ContractMutations.SET_IS_CREATING_CONTRACT, false);
          commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, res);
          resolve(res.data.data);
        })
        .catch((err) => {
          commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, err);
          commit(ContractMutations.SET_IS_CREATING_CONTRACT, false);
          reject(err);
        });
    });
  },

  async updateContract(
    { commit, rootGetters },
    { contract }: { contract: ContractRequestDto }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(ContractMutations.SET_IS_UPDATING_CONTRACT, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Parameters/Contract/UpdateContract`,
          contract
        )
        .then((res) => {
          rootGetters.contractsList.splice(
            rootGetters.contractsList.findIndex(
              (elem) => elem.id == contract.id
            ),
            1,
            contract
          );
          commit(
            ContractMutations.SET_CONTRACTS_LIST,
            rootGetters.contractsList
          );
          commit(ContractMutations.SET_IS_UPDATING_CONTRACT, false);
          commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, err);
          commit(ContractMutations.SET_IS_UPDATING_CONTRACT, false);
          reject(err);
        });
    });
  },

  async restoreContracts(
    { commit, rootGetters },
    { contractIds = [] }: { contractIds: Array<number> }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(ContractMutations.SET_IS_UPDATING_CONTRACT, true);
      axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Parameters/Contract/Restore",
          contractIds
        )
        .then((res) => {
          contractIds.forEach((contractId) => {
            rootGetters.contractsListArchived.splice(
              rootGetters.contractsListArchived.findIndex(
                (elem) => elem.id == contractId
              ),
              1
            );
          });
          commit(ContractMutations.SET_IS_UPDATING_CONTRACT, false);
          commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, err);
          commit(ContractMutations.SET_IS_UPDATING_CONTRACT, false);
          reject(err);
        });
    });
  },

  async archiveContracts(
    { commit, rootGetters },
    { contractIds = [] }: { contractIds: Array<number> }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(ContractMutations.SET_IS_ARCHIVING_CONTRACT, true);
      axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Parameters/Contract/Archive",
          {
            data: contractIds,
          }
        )
        .then((res) => {
          contractIds.forEach((contractId) => {
            rootGetters.contractsList.splice(
              rootGetters.contractsList.findIndex(
                (elem) => elem.id == contractId
              ),
              1
            );
          });
          commit(
            ContractMutations.SET_CONTRACTS_LIST,
            rootGetters.contractsList
          );
          commit(ContractMutations.SET_IS_ARCHIVING_CONTRACT, false);
          commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, err);
          commit(ContractMutations.SET_IS_ARCHIVING_CONTRACT, false);
          reject(err);
        });
    });
  },

  async deleteContracts(
    { commit, rootGetters },
    { contractIds = [] }: { contractIds: Array<number> }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(ContractMutations.SET_IS_DELETING_CONTRACT, true);
      await axios
        .delete(`${process.env.VUE_APP_API_ORISIS}Parameters/Contract/Delete`, {
          data: contractIds,
        })
        .then((res) => {
          contractIds.forEach((contractId) => {
            rootGetters.contractsListArchived.splice(
              rootGetters.contractsListArchived.findIndex(
                (elem) => elem.id == contractId
              ),
              1
            );
          });
          commit(
            ContractMutations.SET_CONTRACTS_LIST,
            rootGetters.contractsList
          );
          commit(ContractMutations.SET_IS_DELETING_CONTRACT, false);
          commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, err);
          commit(ContractMutations.SET_IS_DELETING_CONTRACT, false);
          reject(err);
        });
    });
  },
};
