import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { StockRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { IStockMovementState } from './states'
import { IFilters } from '@/types/api-orisis/interfaces/invoices/filters/IFilters'


export const getters: GetterTree<IStockMovementState, RootState> = {
  stockMovementsList                     : (state): StockRequestDto[] => state.stockMovementsList,
  stockMovement                          : (state): StockRequestDto => state.stockMovement,

  //FILTERS
  stockMovementFilters                   : (state): IFilters => state.stockMovementFilters,

  // COMMON
  isLoadingStockMovementsList            : (state): boolean => state.isLoadingStockMovementsList,
  isLoadingStockMovement                 : (state): boolean => state.isLoadingStockMovement,
  requestStateStockMovement             : (state): IRequestState => state.requestStateStockMovement,
}