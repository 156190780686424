import { ActivityChanelTypeRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { ActivityChanelTypeMutations } from "./mutations";
import { IActivityChanelTypeState } from "./states";

export const actions: ActionTree<IActivityChanelTypeState, RootState> = {
  async getActivityChanelTypes(
    { commit },
    { updateState = true }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          ActivityChanelTypeMutations.SET_IS_LOADING_ACTIVITY_CHANEL_TYPE_LIST,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/ActivityChanelType/GetActivityChanelTypes`;
      await axios
        .get(url, {})
        .then((res) => {
          if (updateState) {
            commit(
              ActivityChanelTypeMutations.SET_IS_LOADING_ACTIVITY_CHANEL_TYPE_LIST,
              false
            );
            commit(
              ActivityChanelTypeMutations.SET_ACTIVITY_CHANEL_TYPES_LIST,
              res.data
            );
          }
          commit(
            ActivityChanelTypeMutations.SET_REQUEST_STATE_ACTIVITY_CHANEL_TYPE,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivityChanelTypeMutations.SET_IS_LOADING_ACTIVITY_CHANEL_TYPE_LIST,
            false
          );
          commit(
            ActivityChanelTypeMutations.SET_REQUEST_STATE_ACTIVITY_CHANEL_TYPE,
            err
          );
          reject(err);
        });
    });
  },

  async getActivityChanelTypeById(
    { commit },
    {
      activityChanelTypeId,
      updateState = true,
    }: { activityChanelTypeId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          ActivityChanelTypeMutations.SET_IS_LOADING_ACTIVITY_CHANEL_TYPE,
          true
        );
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Directory/ActivityChanelType/GetActivityChanelTypeById`,
          {
            params: {
              activityChanelTypeId: activityChanelTypeId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(
              ActivityChanelTypeMutations.SET_ACTIVITY_CHANEL_TYPE,
              res.data
            );
            commit(
              ActivityChanelTypeMutations.SET_IS_LOADING_ACTIVITY_CHANEL_TYPE,
              false
            );
          }
          commit(
            ActivityChanelTypeMutations.SET_REQUEST_STATE_ACTIVITY_CHANEL_TYPE,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivityChanelTypeMutations.SET_REQUEST_STATE_ACTIVITY_CHANEL_TYPE,
            err
          );
          commit(
            ActivityChanelTypeMutations.SET_IS_LOADING_ACTIVITY_CHANEL_TYPE,
            false
          );
          reject(err);
        });
    });
  },

  async getActivityChanelTypesArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          ActivityChanelTypeMutations.SET_IS_LOADING_ACTIVITY_CHANEL_TYPE_LIST_ARCHIVED,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/ActivityChanelType/GetActivityChanelTypesArchived`;
      await axios
        .get(url, {})
        .then((res) => {
          if (updateState) {
            commit(
              ActivityChanelTypeMutations.SET_IS_LOADING_ACTIVITY_CHANEL_TYPE_LIST_ARCHIVED,
              false
            );
            commit(
              ActivityChanelTypeMutations.SET_ACTIVITY_CHANEL_TYPES_LIST_ARCHIVED,
              res.data
            );
          }
          commit(
            ActivityChanelTypeMutations.SET_REQUEST_STATE_ACTIVITY_CHANEL_TYPE,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivityChanelTypeMutations.SET_IS_LOADING_ACTIVITY_CHANEL_TYPE_LIST,
            false
          );
          commit(
            ActivityChanelTypeMutations.SET_REQUEST_STATE_ACTIVITY_CHANEL_TYPE,
            err
          );
          reject(err);
        });
    });
  },

  async getActivityChanelTypeArchivedById(
    { commit },
    {
      activityChanelTypeId,
      updateState = true,
    }: { activityChanelTypeId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          ActivityChanelTypeMutations.SET_IS_LOADING_ACTIVITY_CHANEL_TYPE,
          true
        );
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Directory/ActivityChanelType/GetActivityChanelTypeArhivedById`,
          {
            params: {
              activityChanelTypeId: activityChanelTypeId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(
              ActivityChanelTypeMutations.SET_ACTIVITY_CHANEL_TYPE,
              res.data
            );
            commit(
              ActivityChanelTypeMutations.SET_IS_LOADING_ACTIVITY_CHANEL_TYPE,
              false
            );
          }
          commit(
            ActivityChanelTypeMutations.SET_REQUEST_STATE_ACTIVITY_CHANEL_TYPE,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivityChanelTypeMutations.SET_REQUEST_STATE_ACTIVITY_CHANEL_TYPE,
            err
          );
          commit(
            ActivityChanelTypeMutations.SET_IS_LOADING_ACTIVITY_CHANEL_TYPE,
            false
          );
          reject(err);
        });
    });
  },

  async createActivityChanelType(
    { commit, rootGetters },
    { activityChanelType }: { activityChanelType: ActivityChanelTypeRequestDto }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        ActivityChanelTypeMutations.SET_IS_CREATING_ACTIVITY_CHANEL_TYPE,
        true
      );
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Directory/ActivityChanelType/CreateActivityChanelType`,
          activityChanelType
        )
        .then((res) => {
          rootGetters.activityChanelTypesList.push(res.data.data);
          commit(
            ActivityChanelTypeMutations.SET_ACTIVITY_CHANEL_TYPES_LIST,
            rootGetters.activityChanelTypesList
          );
          commit(
            ActivityChanelTypeMutations.SET_REQUEST_STATE_ACTIVITY_CHANEL_TYPE,
            res
          );
          commit(
            ActivityChanelTypeMutations.SET_IS_CREATING_ACTIVITY_CHANEL_TYPE,
            false
          );
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivityChanelTypeMutations.SET_REQUEST_STATE_ACTIVITY_CHANEL_TYPE,
            err
          );
          commit(
            ActivityChanelTypeMutations.SET_IS_CREATING_ACTIVITY_CHANEL_TYPE,
            false
          );
          reject(err);
        });
    });
  },

  async updateActivityChanelType(
    { commit, rootGetters },
    { activityChanelType }: { activityChanelType: ActivityChanelTypeRequestDto }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        ActivityChanelTypeMutations.SET_IS_UPDATING_ACTIVITY_CHANEL_TYPE,
        true
      );
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Directory/ActivityChanelType/UpdateActivityChanelType`,
          activityChanelType
        )
        .then((res) => {
          if (
            rootGetters.activityChanelTypesList.find(
              (elem) => elem.id == activityChanelType.id
            )
          ) {
            rootGetters.activityChanelTypesList.splice(
              rootGetters.activityChanelTypesList.findIndex(
                (elem) => elem.id == activityChanelType.id
              ),
              1,
              activityChanelType
            );
            commit(
              ActivityChanelTypeMutations.SET_ACTIVITY_CHANEL_TYPES_LIST,
              rootGetters.activityChanelTypesList
            );
          }

          if (
            rootGetters.activityChanelTypesListArchived.find(
              (elem) => elem.id == activityChanelType.id
            )
          ) {
            rootGetters.activityChanelTypesListArchived.splice(
              rootGetters.activityChanelTypesListArchived.findIndex(
                (elem) => elem.id == activityChanelType.id
              ),
              1,
              activityChanelType
            );
            commit(
              ActivityChanelTypeMutations.SET_ACTIVITY_CHANEL_TYPES_LIST_ARCHIVED,
              rootGetters.activityChanelTypesListArchived
            );
          }

          commit(
            ActivityChanelTypeMutations.SET_REQUEST_STATE_ACTIVITY_CHANEL_TYPE,
            res
          );
          commit(
            ActivityChanelTypeMutations.SET_IS_UPDATING_ACTIVITY_CHANEL_TYPE,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivityChanelTypeMutations.SET_REQUEST_STATE_ACTIVITY_CHANEL_TYPE,
            err
          );
          commit(
            ActivityChanelTypeMutations.SET_IS_UPDATING_ACTIVITY_CHANEL_TYPE,
            false
          );
          reject(err);
        });
    });
  },

  async restoreActivityChanelTypes(
    { commit, rootGetters },
    { activityChanelTypeIds = [] }: { activityChanelTypeIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        ActivityChanelTypeMutations.SET_IS_UPDATING_ACTIVITY_CHANEL_TYPE,
        true
      );
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Directory/ActivityChanelType/Restore",
          activityChanelTypeIds
        )
        .then(async (res) => {
          activityChanelTypeIds.forEach((activityChanelTypeId: number) => {
            let activityChanelType =
              rootGetters.activityChanelTypesListArchived.find(
                (activityChanelType) =>
                  activityChanelType.id == activityChanelTypeId
              );
            rootGetters.activityChanelTypesList.unshift(activityChanelType);

            if (
              rootGetters.activityChanelTypesListArchived.find(
                (activityChanelType) =>
                  activityChanelType.id == activityChanelTypeId
              )
            ) {
              rootGetters.activityChanelTypesListArchived.splice(
                rootGetters.activityChanelTypesListArchived.findIndex(
                  (activityChanelType) =>
                    activityChanelType.id == activityChanelTypeId
                ),
                1
              );
            }
          });
          commit(
            ActivityChanelTypeMutations.SET_ACTIVITY_CHANEL_TYPES_LIST,
            rootGetters.activityChanelTypesList
          );
          commit(
            ActivityChanelTypeMutations.SET_ACTIVITY_CHANEL_TYPES_LIST_ARCHIVED,
            rootGetters.activityChanelTypesListArchived
          );
          commit(
            ActivityChanelTypeMutations.SET_REQUEST_STATE_ACTIVITY_CHANEL_TYPE,
            res
          );
          commit(
            ActivityChanelTypeMutations.SET_IS_UPDATING_ACTIVITY_CHANEL_TYPE,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivityChanelTypeMutations.SET_REQUEST_STATE_ACTIVITY_CHANEL_TYPE,
            err
          );
          commit(
            ActivityChanelTypeMutations.SET_IS_UPDATING_ACTIVITY_CHANEL_TYPE,
            false
          );
          reject(err);
        });
    });
  },

  async archiveActivityChanelTypes(
    { commit, rootGetters },
    { activityChanelTypeIds = [] }: { activityChanelTypeIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        ActivityChanelTypeMutations.SET_IS_ARCHIVING_ACTIVITY_CHANEL_TYPE,
        true
      );
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS +
            "Directory/ActivityChanelType/Archive",
          {
            data: activityChanelTypeIds,
          }
        )
        .then((res) => {
          activityChanelTypeIds.forEach((activityChanelTypeId: number) => {
            let activityChanelType = rootGetters.activityChanelTypesList.find(
              (activityChanelType) =>
                activityChanelType.id == activityChanelTypeId
            );
            rootGetters.activityChanelTypesListArchived.unshift(
              activityChanelType
            );

            if (
              rootGetters.activityChanelTypesList.find(
                (activityChanelType) =>
                  activityChanelType.id == activityChanelTypeId
              )
            ) {
              rootGetters.activityChanelTypesList.splice(
                rootGetters.activityChanelTypesList.findIndex(
                  (activityChanelType) =>
                    activityChanelType.id == activityChanelTypeId
                ),
                1
              );
            }
          });
          commit(
            ActivityChanelTypeMutations.SET_ACTIVITY_CHANEL_TYPES_LIST,
            rootGetters.activityChanelTypesList
          );
          commit(
            ActivityChanelTypeMutations.SET_ACTIVITY_CHANEL_TYPES_LIST_ARCHIVED,
            rootGetters.activityChanelTypesListArchived
          );
          commit(
            ActivityChanelTypeMutations.SET_REQUEST_STATE_ACTIVITY_CHANEL_TYPE,
            res
          );
          commit(
            ActivityChanelTypeMutations.SET_IS_ARCHIVING_ACTIVITY_CHANEL_TYPE,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivityChanelTypeMutations.SET_REQUEST_STATE_ACTIVITY_CHANEL_TYPE,
            err
          );
          commit(
            ActivityChanelTypeMutations.SET_IS_ARCHIVING_ACTIVITY_CHANEL_TYPE,
            false
          );
          reject(err);
        });
    });
  },

  async deleteActivityChanelTypes(
    { commit, rootGetters },
    { activityChanelTypeIds = [] }: { activityChanelTypeIds: number[] }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(
        ActivityChanelTypeMutations.SET_IS_DELETING_ACTIVITY_CHANEL_TYPE,
        true
      );
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS +
            "Directory/ActivityChanelType/Delete",
          {
            data: activityChanelTypeIds,
          }
        )
        .then((res) => {
          activityChanelTypeIds.forEach((activityChanelTypeId: number) => {
            if (
              rootGetters.activityChanelTypesList.find(
                (activityChanelType) =>
                  activityChanelType.id == activityChanelTypeId
              )
            ) {
              rootGetters.activityChanelTypesList.splice(
                rootGetters.activityChanelTypesList.findIndex(
                  (activityChanelType) =>
                    activityChanelType.id == activityChanelTypeId
                ),
                1
              );
            }

            if (
              rootGetters.activityChanelTypesListArchived.find(
                (activityChanelType) =>
                  activityChanelType.id == activityChanelTypeId
              )
            ) {
              rootGetters.activityChanelTypesListArchived.splice(
                rootGetters.activityChanelTypesListArchived.findIndex(
                  (activityChanelType) =>
                    activityChanelType.id == activityChanelTypeId
                ),
                1
              );
            }
          });
          commit(
            ActivityChanelTypeMutations.SET_ACTIVITY_CHANEL_TYPES_LIST,
            rootGetters.activityChanelTypesList
          );
          commit(
            ActivityChanelTypeMutations.SET_ACTIVITY_CHANEL_TYPES_LIST_ARCHIVED,
            rootGetters.activityChanelTypesListArchived
          );

          commit(
            ActivityChanelTypeMutations.SET_REQUEST_STATE_ACTIVITY_CHANEL_TYPE,
            res
          );
          commit(
            ActivityChanelTypeMutations.SET_IS_DELETING_ACTIVITY_CHANEL_TYPE,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ActivityChanelTypeMutations.SET_REQUEST_STATE_ACTIVITY_CHANEL_TYPE,
            err
          );
          commit(
            ActivityChanelTypeMutations.SET_IS_DELETING_ACTIVITY_CHANEL_TYPE,
            false
          );
          reject(err);
        });
    });
  },
};
