import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { IPaymentCondition } from "@/types/api-orisis/interfaces/invoices/paymentCondition/IPaymentCondition";
import RootState from "@/store/modules/rootState"
import { IPaymentConditionState } from "./states";
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

export const state: IPaymentConditionState = {
    paymentConditionsList       : Array<IPaymentCondition>(),
    paymentConditionsListArchived : Array<IPaymentCondition>(),
    paymentCondition            : <IPaymentCondition>{},
    isLoadingPaymentConditionsList   : false,
    isLoadingPaymentConditionsListArchived   : false,
    isLoadingPaymentCondition   : false,
    isCreatingPaymentCondition  : false,
    isUpdatingPaymentCondition  : false,
    isDeletingPaymentCondition  : false,
    isArchivingPaymentCondition : false,
    requestStatePaymentCondition: <IRequestState>{},
}

export const PaymentConditionModule: Module<IPaymentConditionState, RootState> = {
  state, 
  getters,
  mutations,
  actions
}