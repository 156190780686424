import { MutationTree } from "vuex";
import { IGlobalState } from "./states";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export enum GlobalMutations {
  SET_IS_GLOBAL_CREATING = "SET_IS_GLOBAL_CREATING",
  SET_REQUEST_STATE_GLOBAL = "SET_REQUEST_STATE_GLOBAL",
  SET_IS_GLOBAL_LOADING = "SET_IS_GLOBAL_LOADING",
  SET_IS_GLOBAL_MESSAGE = "SET_IS_GLOBAL_MESSAGE",
  HEADER_DATA_USER_PARAMETER_IS_LOADING = "HEADER_DATA_USER_PARAMETER_IS_LOADING",
  SET_MODAL_RECEIPT_FORM = "SET_MODAL_RECEIPT_FORM",
}

export const mutations: MutationTree<IGlobalState> = {
  [GlobalMutations.SET_IS_GLOBAL_CREATING]: (state, payload: boolean) => {
    state.isGlobalCreating = payload;
  },
  [GlobalMutations.SET_IS_GLOBAL_LOADING]: (state, payload: boolean) => {
    state.isGlobalLoading = payload;
  },
  [GlobalMutations.SET_REQUEST_STATE_GLOBAL]: (
    state,
    payload: IRequestState
  ) => {
    state.requestStateGlobal = payload;
  },
  [GlobalMutations.SET_IS_GLOBAL_MESSAGE]: (state, payload: String) => {
    state.isGlobalMessage = payload;
  },
  [GlobalMutations.HEADER_DATA_USER_PARAMETER_IS_LOADING]: (
    state,
    payload: boolean
  ) => {
    state.headerDataUserParameterIsLoading = payload;
  },
  [GlobalMutations.SET_MODAL_RECEIPT_FORM]: (state,
    payload: boolean
  ) => {
    state.setModalReceiptForm = payload;
  },
    
  

};
