import { DegressivePurchaseRatesRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { DegressivePurchaseRateMutations } from "./mutations";
import { IDegressivePurchaseRateState } from "./states";

export const actions: ActionTree<IDegressivePurchaseRateState, RootState> = {
  async getDegressivePurchaseRates(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          DegressivePurchaseRateMutations.SET_IS_LOADING_DEGRESSIVE_PURCHASE_RATES_LIST,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/DegressivePurchaseRates/GetDegressivePurchaseRates`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              DegressivePurchaseRateMutations.SET_IS_LOADING_DEGRESSIVE_PURCHASE_RATES_LIST,
              false
            );
          }
          commit(
            DegressivePurchaseRateMutations.SET_REQUEST_STATE_DEGRESSIVE_PURCHASE_RATE,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DegressivePurchaseRateMutations.SET_IS_LOADING_DEGRESSIVE_PURCHASE_RATES_LIST,
            false
          );
          commit(
            DegressivePurchaseRateMutations.SET_REQUEST_STATE_DEGRESSIVE_PURCHASE_RATE,
            err
          );
          reject(err);
        });
    });
  },

  async getDegressivePurchaseRateById(
    { commit },
    {
      degressivePurchaseRatesId,
      updateState = true,
    }: { degressivePurchaseRatesId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          DegressivePurchaseRateMutations.SET_IS_LOADING_DEGRESSIVE_PURCHASE_RATE,
          true
        );
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/DegressivePurchaseRates/GetDegressivePurchaseRateById`,
          {
            params: {
              degressivePurchaseRatesId: degressivePurchaseRatesId,
            },
          }
        )
        .then((res) => {
          commit(
            DegressivePurchaseRateMutations.SET_REQUEST_STATE_DEGRESSIVE_PURCHASE_RATE,
            res
          );
          if (updateState) {
            commit(
              DegressivePurchaseRateMutations.SET_IS_LOADING_DEGRESSIVE_PURCHASE_RATE,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DegressivePurchaseRateMutations.SET_REQUEST_STATE_DEGRESSIVE_PURCHASE_RATE,
            err
          );
          commit(
            DegressivePurchaseRateMutations.SET_IS_LOADING_DEGRESSIVE_PURCHASE_RATE,
            false
          );
          reject(err);
        });
    });
  },

  async createDegressivePurchaseRate(
    { commit },
    {
      degressivePurchaseRate,
    }: { degressivePurchaseRate: DegressivePurchaseRatesRequestDto }
  ): Promise<void> {
    commit(
      DegressivePurchaseRateMutations.SET_IS_CREATING_DEGRESSIVE_PURCHASE_RATE,
      true
    );
    await axios
      .post(
        `${process.env.VUE_APP_API_ORISIS}Purchase/DegressivePurchaseRates/CreateDegressivePurchaseRates`,
        degressivePurchaseRate
      )
      .then((res) => {
        commit(
          DegressivePurchaseRateMutations.SET_REQUEST_STATE_DEGRESSIVE_PURCHASE_RATE,
          res
        );
        commit(
          DegressivePurchaseRateMutations.SET_IS_CREATING_DEGRESSIVE_PURCHASE_RATE,
          false
        );
      })
      .catch((err) => {
        console.error(err);
        commit(
          DegressivePurchaseRateMutations.SET_REQUEST_STATE_DEGRESSIVE_PURCHASE_RATE,
          err
        );
        commit(
          DegressivePurchaseRateMutations.SET_IS_CREATING_DEGRESSIVE_PURCHASE_RATE,
          false
        );
      });
  },

  async updateDegressivePurchaseRate(
    { commit },
    {
      degressivePurchaseRate,
    }: { degressivePurchaseRate: DegressivePurchaseRatesRequestDto }
  ) {
    commit(
      DegressivePurchaseRateMutations.SET_IS_UPDATING_DEGRESSIVE_PURCHASE_RATE,
      true
    );
    await axios
      .put(
        `${process.env.VUE_APP_API_ORISIS}Purchase/DegressivePurchaseRates/UpdateDegressivePurchaseRates`,
        degressivePurchaseRate
      )
      .then((res) => {
        commit(
          DegressivePurchaseRateMutations.SET_REQUEST_STATE_DEGRESSIVE_PURCHASE_RATE,
          res
        );
        commit(
          DegressivePurchaseRateMutations.SET_IS_UPDATING_DEGRESSIVE_PURCHASE_RATE,
          false
        );
      })
      .catch((err) => {
        console.error(err);
        commit(
          DegressivePurchaseRateMutations.SET_REQUEST_STATE_DEGRESSIVE_PURCHASE_RATE,
          err
        );
        commit(
          DegressivePurchaseRateMutations.SET_IS_UPDATING_DEGRESSIVE_PURCHASE_RATE,
          false
        );
      });
  },

  async deleteDegressivePurchaseRates(
    { commit },
    { degressivePurchaseRateIds }: { degressivePurchaseRateIds: number[] }
  ) {
    commit(
      DegressivePurchaseRateMutations.SET_IS_DELETING_DEGRESSIVE_PURCHASE_RATE,
      true
    );
    await axios
      .delete(
        `${process.env.VUE_APP_API_ORISIS}Purchase/DegressivePurchaseRates/DeleteDegressivePurchaseRates`,
        {
          data: degressivePurchaseRateIds,
        }
      )
      .then((res) => {
        commit(
          DegressivePurchaseRateMutations.SET_REQUEST_STATE_DEGRESSIVE_PURCHASE_RATE,
          res
        );
        commit(
          DegressivePurchaseRateMutations.SET_IS_DELETING_DEGRESSIVE_PURCHASE_RATE,
          false
        );
      })
      .catch((err) => {
        console.error(err);
        commit(
          DegressivePurchaseRateMutations.SET_REQUEST_STATE_DEGRESSIVE_PURCHASE_RATE,
          err
        );
        commit(
          DegressivePurchaseRateMutations.SET_IS_DELETING_DEGRESSIVE_PURCHASE_RATE,
          false
        );
      });
  },
};
