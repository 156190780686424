import { StockRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { StockMutations } from "./mutations";
import { IStockState } from "./states";
import { CatalogMutations } from "../../invoices/catalog/mutations";

export const actions: ActionTree<IStockState, RootState> = {
  getStocks(
    { commit },
    {
      productId,
      storageLocationId,
      updateState = true,
    }: { productId: number; storageLocationId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(StockMutations.SET_IS_LOADING_STOCK_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/Stock/GetStocks`;
      axios
        .get(url, {
          params: {
            productId: productId,
            storageLocationId: storageLocationId,
          },
        })
        .then((res) => {
          if (updateState) {
            commit(StockMutations.SET_IS_LOADING_STOCK_LIST, false);
            commit(StockMutations.SET_STOCKS_LIST, res.data);
          }
          commit(StockMutations.SET_REQUEST_STATE_STOCK, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(StockMutations.SET_IS_LOADING_STOCK_LIST, false);
          }
          commit(StockMutations.SET_REQUEST_STATE_STOCK, err);
          reject(err);
        });
    });
  },

  getStockById(
    { commit, rootGetters },
    { stockId, updateState = true }: { stockId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(StockMutations.SET_IS_LOADING_STOCK, true);
      }
      axios
        .get(`${process.env.VUE_APP_API_ORISIS}Purchase/Stock/GetStockById`, {
          params: {
            stockId: stockId,
          },
        })
        .then((res) => {
          if (updateState) {
            commit(StockMutations.SET_STOCK, res.data);
            commit(StockMutations.SET_IS_LOADING_STOCK, false);
          }
          commit(StockMutations.SET_REQUEST_STATE_STOCK, res);

          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(StockMutations.SET_REQUEST_STATE_STOCK, err);
          if (updateState) {
            commit(StockMutations.SET_IS_LOADING_STOCK, false);
          }
          reject(err);
        });
    });
  },

  //A VERIFIER (Aurelien - doublon avec getStocks ?)
  getStockOfProductByStorageLocationId(
    { commit, rootGetters },
    {
      storageLocationId,
      productId,
      updateState = true,
    }: {
      storageLocationId: number | null;
      productId: number | null;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(StockMutations.SET_IS_LOADING_PRODUCTS_WITH_STOCK_LIST, true);
      }
      let productTypeList = rootGetters.productTypeList;
      axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/Stock/GetStocksOfProductByStorageLocationId`,
          {
            params: {
              productId: productId,
              storageLoctionId: storageLocationId,
            },
          }
        )
        .then((res) => {
          let catalogSortedByProductType = res.data.reduce(function (
            acc: any,
            obj: any
          ) {
            let key = Object.keys(productTypeList)[obj.product.productType];
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(obj.product);
            return acc;
          },
          {});
          if (updateState) {
            commit(StockMutations.SET_PRODUCT_WITH_STOCK_LIST, res.data);
            commit(
              CatalogMutations.SET_CATALOG_SORTED_BY_PRODUCT_TYPE,
              catalogSortedByProductType
            );
            commit(
              StockMutations.SET_IS_LOADING_PRODUCTS_WITH_STOCK_LIST,
              false
            );
          }
          commit(StockMutations.SET_REQUEST_STATE_STOCK, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(StockMutations.SET_REQUEST_STATE_STOCK, err);
          if (updateState) {
            commit(
              StockMutations.SET_IS_LOADING_PRODUCTS_WITH_STOCK_LIST,
              false
            );
          }
          reject(err);
        });
    });
  },

  createStock(
    { commit, rootGetters },
    { stock }: { stock: StockRequestDto }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(StockMutations.SET_IS_CREATING_STOCK, true);
      axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Purchase/Stock/CreateStock`,
          stock
        )
        .then((res) => {
          rootGetters.stocksList.push(res.data.data);
          commit(StockMutations.SET_STOCKS_LIST, rootGetters.stocksList);
          commit(StockMutations.SET_REQUEST_STATE_STOCK, res);
          commit(StockMutations.SET_IS_CREATING_STOCK, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(StockMutations.SET_REQUEST_STATE_STOCK, err);
          commit(StockMutations.SET_IS_CREATING_STOCK, false);
          reject(err);
        });
    });
  },
  updateStock({ commit, rootGetters }, stock: StockRequestDto) {
    return new Promise<void>((resolve, reject) => {
      commit(StockMutations.SET_IS_UPDATING_STOCK, true);
      axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/Stock/UpdateStock`,
          stock
        )
        .then((res) => {
          rootGetters.stocksList.splice(
            rootGetters.stocksList.findIndex((elem) => elem.id == stock.id),
            1,
            stock
          );
          commit(StockMutations.SET_STOCKS_LIST, rootGetters.stocksList);
          commit(StockMutations.SET_REQUEST_STATE_STOCK, res);
          commit(StockMutations.SET_IS_UPDATING_STOCK, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(StockMutations.SET_REQUEST_STATE_STOCK, err);
          commit(StockMutations.SET_IS_UPDATING_STOCK, false);
          reject(err);
        });
    });
  },
  deleteStocks({ commit, rootGetters }, { stockIds }: { stockIds: string[] }) {
    return new Promise<void>((resolve, reject) => {
      commit(StockMutations.SET_IS_DELETING_STOCK, true);
      axios
        .delete(`${process.env.VUE_APP_API_ORISIS}Purchase/Stock/Delete`, {
          data: stockIds,
        })
        .then((res) => {
          for (let i = 0; i < stockIds.length; i++) {
            const id = stockIds[i];
            rootGetters.stocksListArchived.splice(
              rootGetters.stocksListArchived.findIndex((elem) => elem.id == id),
              1
            );
          }
          commit(StockMutations.SET_STOCKS_LIST, rootGetters.stocksList);
          commit(StockMutations.SET_REQUEST_STATE_STOCK, res);
          commit(StockMutations.SET_IS_DELETING_STOCK, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(StockMutations.SET_REQUEST_STATE_STOCK, err);
          commit(StockMutations.SET_IS_DELETING_STOCK, false);
          reject(err);
        });
    });
  },
};
