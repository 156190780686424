import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { PropertyTypeRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { IPropertyTypeState } from './states'


export const getters: GetterTree<IPropertyTypeState, RootState> = {
  propertyTypeList: (state): PropertyTypeRequestDto[] => state.propertyTypeList,
  propertyTypeListArchived: (state): PropertyTypeRequestDto[] => state.propertyTypeListArchived,
  propertyType     : (state): PropertyTypeRequestDto => state.propertyType,

  // COMMON
  isLoadingPropertyTypeList   : (state): boolean => state.isLoadingPropertyTypeList,
  isLoadingPropertyTypeListArchived   : (state): boolean => state.isLoadingPropertyTypeListArchived,
  isLoadingPropertyType   : (state): boolean => state.isLoadingPropertyType,
  isCreatingPropertyType  : (state): boolean => state.isCreatingPropertyType,
  isUpdatingPropertyType  : (state): boolean => state.isUpdatingPropertyType,
  isDeletingPropertyType  : (state): boolean => state.isDeletingPropertyType,
  isArchivingPropertyType  : (state): boolean => state.isArchivingPropertyType,
  requestStatePropertyType: (state): IRequestState => state.requestStatePropertyType,
}



