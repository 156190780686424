import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { ActivityChanelTypeRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IActivityChanelTypeState } from "./states";

export enum ActivityChanelTypeMutations {
    SET_ACTIVITY_CHANEL_TYPES_LIST = "SET_ACTIVITY_CHANEL_TYPES_LIST",
    SET_ACTIVITY_CHANEL_TYPES_LIST_ARCHIVED = "SET_ACTIVITY_CHANEL_TYPES_LIST_ARCHIVED",
    SET_ACTIVITY_CHANEL_TYPE = "SET_ACTIVITY_CHANEL_TYPE",

    //FILTERS
    SET_ACTIVITY_CHANEL_TYPE_FILTERS = "SET_ACTIVITY_CHANEL_TYPE_FILTERS",

    // COMMON
    SET_IS_LOADING_ACTIVITY_CHANEL_TYPE_LIST = "SET_IS_LOADING_ACTIVITY_CHANEL_TYPE_LIST",
    SET_IS_LOADING_ACTIVITY_CHANEL_TYPE_LIST_ARCHIVED = "SET_IS_LOADING_ACTIVITY_CHANEL_TYPE_LIST_ARCHIVED",
    SET_IS_LOADING_ACTIVITY_CHANEL_TYPE = "SET_IS_LOADING_ACTIVITY_CHANEL_TYPE",
    SET_IS_CREATING_ACTIVITY_CHANEL_TYPE = "SET_IS_CREATING_ACTIVITY_CHANEL_TYPE",
    SET_IS_UPDATING_ACTIVITY_CHANEL_TYPE = "SET_IS_UPDATING_ACTIVITY_CHANEL_TYPE",
    SET_IS_DELETING_ACTIVITY_CHANEL_TYPE = "SET_IS_DELETING_ACTIVITY_CHANEL_TYPE",
    SET_IS_ARCHIVING_ACTIVITY_CHANEL_TYPE = "SET_IS_ARCHIVING_ACTIVITY_CHANEL_TYPE",
    SET_REQUEST_STATE_ACTIVITY_CHANEL_TYPE = "SET_REQUEST_STATE_ACTIVITY_CHANEL_TYPE",
}

export const mutations: MutationTree<IActivityChanelTypeState> = {
    [ActivityChanelTypeMutations.SET_ACTIVITY_CHANEL_TYPES_LIST]: (state, payload: ActivityChanelTypeRequestDto[]) => {
        state.activityChanelTypesList = payload;
    },
    [ActivityChanelTypeMutations.SET_ACTIVITY_CHANEL_TYPES_LIST_ARCHIVED]: (state, payload: ActivityChanelTypeRequestDto[]) => {
        state.activityChanelTypesListArchived = payload;
    }, 
    [ActivityChanelTypeMutations.SET_ACTIVITY_CHANEL_TYPE]: (state, payload: ActivityChanelTypeRequestDto) => {
        state.activityChanelType = payload;
    },

    //FILTERS
    [ActivityChanelTypeMutations.SET_ACTIVITY_CHANEL_TYPE_FILTERS]: (state, payload: any) => {
        state.activityChanelTypeFilters = payload;
    },


    //COMMON
    [ActivityChanelTypeMutations.SET_IS_LOADING_ACTIVITY_CHANEL_TYPE_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingActivityChanelTypesListArchived = payload;
    },
    [ActivityChanelTypeMutations.SET_IS_LOADING_ACTIVITY_CHANEL_TYPE_LIST]: (state, payload: boolean) => {
        state.isLoadingActivityChanelTypesList = payload;
    },
    [ActivityChanelTypeMutations.SET_IS_LOADING_ACTIVITY_CHANEL_TYPE]: (state, payload: boolean) => {
        state.isLoadingActivityChanelType = payload;
    },
    [ActivityChanelTypeMutations.SET_IS_CREATING_ACTIVITY_CHANEL_TYPE]: (state, payload: boolean) => {
        state.isCreatingActivityChanelType = payload;
    },
    [ActivityChanelTypeMutations.SET_IS_UPDATING_ACTIVITY_CHANEL_TYPE]: (state, payload: boolean) => {
        state.isUpdatingActivityChanelType = payload;
    },
    [ActivityChanelTypeMutations.SET_IS_DELETING_ACTIVITY_CHANEL_TYPE]: (state, payload: boolean) => {
        state.isDeletingActivityChanelType = payload;
    },
    [ActivityChanelTypeMutations.SET_IS_ARCHIVING_ACTIVITY_CHANEL_TYPE]: (state, payload: boolean) => {
        state.isArchivingActivityChanelType = payload;
    },
    [ActivityChanelTypeMutations.SET_REQUEST_STATE_ACTIVITY_CHANEL_TYPE]: (state, payload: IRequestState) => {
        state.requestStateActivityChanelType = payload;
    },
}