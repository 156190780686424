import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { IFiscalYear } from "@/types/api-orisis/interfaces/invoices/fiscalYear/IFiscalYear";
import RootState from "@/store/modules/rootState"
import { IFiscalYearState } from "./states";
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

export const state: IFiscalYearState = {
    fiscalYearList            : Array<IFiscalYear>(),
    fiscalYear                : <IFiscalYear>{},
    isLoadingFiscalYearList   : false,
    isLoadingFiscalYear       : false,
    isCreatingFiscalYear      : false,
    isUpdatingFiscalYear      : false,
    isDeletingFiscalYear      : false,
    isArchivingFiscalYear     : false,
    requestStateFiscalYear    : <IRequestState>{},
}

export const FiscalYearModule: Module<IFiscalYearState, RootState> = {
  state, 
  getters,
  mutations,
  actions
}