import { MutationTree } from "vuex";
import { ICreditOptionState } from "./states";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { CreditOptionRequestDto } from "Api";

export const enum CreditOptionMutations {
  SET_CREDIT_OPTION = "SET_CREDIT_OPTION",
  SET_IS_LOADING_CREDIT_OPTION = "SET_IS_LOADING",
  SET_REQUEST_STATE_CREDIT_OPTION = "SET_ERROR",
}

export const mutations: MutationTree<ICreditOptionState> = {
  [CreditOptionMutations.SET_CREDIT_OPTION](
    state,
    payload: CreditOptionRequestDto
  ) {
    state.creditOption = payload;
  },
  [CreditOptionMutations.SET_IS_LOADING_CREDIT_OPTION](
    state,
    payload: boolean
  ) {
    state.isLoadingCreditOption = payload;
  },
  [CreditOptionMutations.SET_REQUEST_STATE_CREDIT_OPTION](
    state,
    payload: IRequestState
  ) {
    state.requestStateCreditOption = payload;
  },
};
