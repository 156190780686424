import { IInvoiceStatisticsState } from "./states";
import { MutationTree } from "vuex";


export enum InvoiceStatisticsMutations{
    SET_STATS_CUSTOMER_DISTRIBUTION = "SET_STATS_CUSTOMER_DISTRIBUTION",
    SET_STATS_DOCUMENT_BY_DAY = "SET_STATS_DOCUMENT_BY_DAY",
    SET_STATS_PERIODE_INVOICE = "SET_STATS_PERIODE_INVOICE",
    SET_STATS_DISTRIBUTION_BY_ACTIVITES = "SET_STATS_DISTRIBUTION_BY_ACTIVITES",
    
    // COMON
    SET_IS_LOADING_STATS_CUSTOMER_DISTRIBUTION = "SET_IS_LOADING_STATS_CUSTOMER_DISTRIBUTION",
    SET_IS_LOADING_STATS_DOCUMENT_BY_DAY = "SET_IS_LOADING_STATS_DOCUMENT_BY_DAY",
    SET_IS_LOADING_STATS_PERIODE_INVOICE = "SET_IS_LOADING_STATS_PERIODE_INVOICE",
    SET_IS_LOADING_STATS_DISTRIBUTION_BY_ACTIVITES = "SET_IS_LOADING_STATS_DISTRIBUTION_BY_ACTIVITES"
  }

  export const mutations: MutationTree<IInvoiceStatisticsState> = {
    [InvoiceStatisticsMutations.SET_STATS_CUSTOMER_DISTRIBUTION]: (state, payload: []) => {
        state.statsCustomerDistribution = payload;
    },
    [InvoiceStatisticsMutations.SET_STATS_DOCUMENT_BY_DAY]: (state, payload: []) => {
        state.statsDocumentByDay = payload;
    },
    [InvoiceStatisticsMutations.SET_STATS_PERIODE_INVOICE]: (state, payload: []) => {
        state.statsPeriodeInvoice = payload;
    },
    [InvoiceStatisticsMutations.SET_STATS_DISTRIBUTION_BY_ACTIVITES]: (state, payload: []) => {
        state.statsDistributionByActivities = payload;
    },

    //COMMON
    [InvoiceStatisticsMutations.SET_IS_LOADING_STATS_CUSTOMER_DISTRIBUTION]: (state, payload: boolean) => {
        state.isLoadingStatsCustomerDistribution = payload;
    },
    [InvoiceStatisticsMutations.SET_IS_LOADING_STATS_DOCUMENT_BY_DAY]: (state, payload: boolean) => {
        state.isLoadingStatsDocumentByDay = payload;
    },
    [InvoiceStatisticsMutations.SET_IS_LOADING_STATS_PERIODE_INVOICE]: (state, payload: boolean) => {
        state.isLoadingStatsPeriodeInvoice = payload;
    },
    [InvoiceStatisticsMutations.SET_IS_LOADING_STATS_DISTRIBUTION_BY_ACTIVITES]: (state, payload: boolean) => {
        state.isLoadingStatsDistributionByActivities = payload;
    },
    
} 