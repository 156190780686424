import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { DeliveryFormHistoryMutations } from "./mutations";
import { IDeliveryFormHistoryState } from "./states";

export const actions: ActionTree<IDeliveryFormHistoryState, RootState> = {
  async getDeliveryFormHistoryById(
    { commit },
    {
      deliveryFormHistoryId,
      updateState = true,
    }: { deliveryFormHistoryId: number; updateState?: boolean }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (updateState) {
        commit(
          DeliveryFormHistoryMutations.SET_IS_LOADING_DELIVERY_FORM_HISTORY,
          true
        );
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "purchase/DeliveryFormHistory/GetDeliveryFormHistoryById",
          {
            params: {
              deliveryFormHistoryId: deliveryFormHistoryId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(
              DeliveryFormHistoryMutations.SET_DELIVERY_FORM_HISTORY,
              res.data
            );
            commit(
              DeliveryFormHistoryMutations.SET_IS_LOADING_DELIVERY_FORM_HISTORY,
              false
            );
          }
          commit(
            DeliveryFormHistoryMutations.SET_REQUEST_STATE_DELIVERY_FORM_HISTORY,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DeliveryFormHistoryMutations.SET_REQUEST_STATE_DELIVERY_FORM_HISTORY,
            err
          );
          commit(
            DeliveryFormHistoryMutations.SET_IS_LOADING_DELIVERY_FORM_HISTORY,
            false
          );
          reject(err);
        });
    });
  },

  async getDeliveryFormHistoriesByDeliveryFormId(
    { commit, rootGetters },
    { deliveryFormId, updateState = true }: { deliveryFormId: number; updateState?: boolean }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(
        DeliveryFormHistoryMutations.SET_IS_LOADING_DELIVERY_FORM_HISTORIES_LIST,
        true
      );
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "purchase/DeliveryFormHistory/GetDeliveryFormHistoriesByDeliveryFormId",
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`,
            },
            params: {
              deliveryFormId: deliveryFormId,
            },
          }
        )
        .then((res) => {
          commit(
            DeliveryFormHistoryMutations.SET_DELIVERY_FORM_HISTORIES_LIST,
            res.data
          );
          commit(
            DeliveryFormHistoryMutations.SET_REQUEST_STATE_DELIVERY_FORM_HISTORY,
            res
          );
          commit(
            DeliveryFormHistoryMutations.SET_IS_LOADING_DELIVERY_FORM_HISTORIES_LIST,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DeliveryFormHistoryMutations.SET_REQUEST_STATE_DELIVERY_FORM_HISTORY,
            err
          );
          commit(
            DeliveryFormHistoryMutations.SET_IS_LOADING_DELIVERY_FORM_HISTORIES_LIST,
            false
          );
          reject(err);
        });
    });
  },
};
