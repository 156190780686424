import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { PayrollEventRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IPayrollEventState } from "./states";

export enum PayrollEventMutations {
    SET_PAYROLL_EVENTS_LIST = "SET_PAYROLL_EVENTS_LIST",
    SET_PAYROLL_EVENTS_LIST_ARCHIVED = "SET_PAYROLL_EVENTS_LIST_ARCHIVED",
    SET_PAYROLL_EVENTS_LIST_TEMPLATES = "SET_PAYROLL_EVENTS_LIST_TEMPLATES",
    SET_PAYROLL_EVENT = "SET_PAYROLL_EVENT",

    // COMMON
    SET_IS_LOADING_PAYROLL_EVENT_LIST = "SET_IS_LOADING_PAYROLL_EVENT_LIST",
    SET_IS_LOADING_PAYROLL_EVENT_LIST_ARCHIVED = "SET_IS_LOADING_PAYROLL_EVENT_LIST_ARCHIVED",
    SET_IS_LOADING_PAYROLL_EVENT_LIST_TEMPLATES = "SET_IS_LOADING_PAYROLL_EVENT_LIST_TEMPLATES",
    SET_IS_LOADING_PAYROLL_EVENT = "SET_IS_LOADING_PAYROLL_EVENT",
    SET_IS_CREATING_PAYROLL_EVENT = "SET_IS_CREATING_PAYROLL_EVENT",
    SET_IS_UPDATING_PAYROLL_EVENT = "SET_IS_UPDATING_PAYROLL_EVENT",
    SET_IS_DELETING_PAYROLL_EVENT = "SET_IS_DELETING_PAYROLL_EVENT",
    SET_IS_ARCHIVING_PAYROLL_EVENT = "SET_IS_ARCHIVING_PAYROLL_EVENT",
    SET_REQUEST_STATE_PAYROLL_EVENT = "SET_REQUEST_STATE_PAYROLL_EVENT",
}

export const mutations: MutationTree<IPayrollEventState> = {
    [PayrollEventMutations.SET_PAYROLL_EVENTS_LIST_ARCHIVED]: (state, payload: PayrollEventRequestDto[]) => {
        state.payrollEventsListArchived = payload;
    }, 
    [PayrollEventMutations.SET_PAYROLL_EVENTS_LIST]: (state, payload: PayrollEventRequestDto[]) => {
        state.payrollEventsList = payload;
    },
    [PayrollEventMutations.SET_PAYROLL_EVENTS_LIST_TEMPLATES]: (state, payload: PayrollEventRequestDto[]) => {
        state.payrollEventsListTemplates = payload;
    }, 
    [PayrollEventMutations.SET_PAYROLL_EVENT]: (state, payload: PayrollEventRequestDto) => {
        state.payrollEvent = payload;
    },


    //COMMON
    [PayrollEventMutations.SET_IS_LOADING_PAYROLL_EVENT_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingPayrollEventsListArchived = payload;
    },
    [PayrollEventMutations.SET_IS_LOADING_PAYROLL_EVENT_LIST]: (state, payload: boolean) => {
        state.isLoadingPayrollEventsList = payload;
    },
    [PayrollEventMutations.SET_IS_LOADING_PAYROLL_EVENT_LIST_TEMPLATES]: (state, payload: boolean) => {
        state.isLoadingPayrollEventsListTemplates = payload;
    },
    [PayrollEventMutations.SET_IS_LOADING_PAYROLL_EVENT]: (state, payload: boolean) => {
        state.isLoadingPayrollEvent = payload;
    },
    [PayrollEventMutations.SET_IS_CREATING_PAYROLL_EVENT]: (state, payload: boolean) => {
        state.isCreatingPayrollEvent = payload;
    },
    [PayrollEventMutations.SET_IS_UPDATING_PAYROLL_EVENT]: (state, payload: boolean) => {
        state.isUpdatingPayrollEvent = payload;
    },
    [PayrollEventMutations.SET_IS_DELETING_PAYROLL_EVENT]: (state, payload: boolean) => {
        state.isDeletingPayrollEvent = payload;
    },
    [PayrollEventMutations.SET_IS_ARCHIVING_PAYROLL_EVENT]: (state, payload: boolean) => {
        state.isArchivingPayrollEvent = payload;
    },
    [PayrollEventMutations.SET_REQUEST_STATE_PAYROLL_EVENT]: (state, payload: IRequestState) => {
        state.requestStatePayrollEvent = payload;
    },
}