import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { GetterTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { IHelpersState } from './states'

export const getters: GetterTree<IHelpersState, RootState> = {
  // Civility
  civilityList: (state): [] => state.civilityList,
  isLoadingCivilityList: (state): boolean => state.isLoadingCivilityList,

  
  documentTypeList: (state): [] => state.documentTypeList,
  legalFormList: (state): [] => state.legalFormList,
  guaranteeTypeList: (state): [] => state.guaranteeTypeList,
  documentValidityList: (state): [] => state.documentValidityList,
  amountTypeList: (state): [] => state.amountTypeList,
  paymentRequestDueList: (state): [] => state.paymentRequestDueList,
  productTypeList: (state): [] => state.productTypeList,
  natureList: (state): [] => state.natureList,
  isLoadingDocumentTypeList: (state): boolean => state.isLoadingDocumentTypeList,
  isLoadingLegalFormList: (state): boolean => state.isLoadingLegalFormList,
  isLoadingGuaranteeTypeList: (state): boolean => state.isLoadingGuaranteeTypeList,
  isLoadingDocumentValidityList: (state): boolean => state.isLoadingDocumentValidityList,
  isLoadingAmountTypeList: (state): boolean => state.isLoadingAmountTypeList,
  isLoadingPaymentRequestDueList: (state): boolean => state.isLoadingPaymentRequestDueList,
  isLoadingProductTypeList: (state): boolean => state.isLoadingProductTypeList,
  isLoadingNatureList: (state): boolean => state.isLoadingNatureList,
  requestStateHelpers: (state): IRequestState => state.requestStateHelpers,
}