import { IInstitutionState } from "./states";
import { MutationTree } from "vuex";
import { IInstitution } from "@/types/api-orisis/interfaces/invoices/institution/IInstitution";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { InstitutionRequestDto } from "Api";

export enum InstitutionMutations {
  SET_INSTITUTIONS_LIST = "SET_INSTITUTIONS_LIST",
  SET_INSTITUTIONS_LIST_ARCHIVED = "SET_INSTITUTIONS_LIST_ARCHIVED",
  SET_INSTITUTIONS_LIST_MINIFIED = "SET_INSTITUTIONS_LIST_MINIFIED",
  SET_INSTITUTION = "SET_INSTITUTION",
  SET_INSTITUTION_ACTIVE = "SET_INSTITUTION_ACTIVE",
  SET_INSTITUTION_SELECTED = "SET_INSTITUTION_SELECTED",

  // COMON
  SET_IS_LOADING_INSTITUTIONS_LIST = "SET_IS_LOADING_INSTITUTIONS_LIST",
  SET_IS_LOADING_INSTITUTIONS_LIST_ARCHIVED = "SET_IS_LOADING_INSTITUTIONS_LIST_ARCHIVED",
  SET_IS_LOADING_INSTITUTIONS_LIST_MINIFIED = "SET_IS_LOADING_INSTITUTIONS_LIST_MINIFIED",
  SET_IS_LOADING_INSTITUTION = "SET_IS_LOADING_INSTITUTION",
  SET_IS_CREATING_INSTITUTION = "SET_IS_CREATING_INSTITUTION",
  SET_IS_UPDATING_INSTITUTION = "SET_IS_UPDATING_INSTITUTION",
  SET_IS_DELETING_INSTITUTION = "SET_IS_DELETING_INSTITUTION",
  SET_IS_ARCHIVING_INSTITUTION = "SET_IS_ARCHIVING_INSTITUTION",
  SET_REQUEST_STATE_INSTITUTION = "SET_REQUEST_STATE_INSTITUTION",
}

export const mutations: MutationTree<IInstitutionState> = {
  [InstitutionMutations.SET_INSTITUTIONS_LIST_ARCHIVED]: (
    state,
    payload: InstitutionRequestDto[]
  ) => {
    state.institutionsListArchived = payload;
  },
  [InstitutionMutations.SET_INSTITUTIONS_LIST_MINIFIED]: (
    state,
    payload: InstitutionRequestDto[]
  ) => {
    state.institutionsListMinified = payload;
  },
  [InstitutionMutations.SET_INSTITUTIONS_LIST]: (
    state,
    payload: InstitutionRequestDto[]
  ) => {
    state.institutionsList = payload;
  },
  [InstitutionMutations.SET_INSTITUTION]: (
    state,
    payload: InstitutionRequestDto
  ) => {
    state.institution = payload;
  },
  [InstitutionMutations.SET_INSTITUTION_ACTIVE]: (
    state,
    payload: InstitutionRequestDto
  ) => {
    state.institutionSelected = payload;
    localStorage.setItem("institutionSelected", JSON.stringify(payload));
  },
  [InstitutionMutations.SET_INSTITUTION_SELECTED]: (
    state,
    payload: InstitutionRequestDto
  ) => {
    state.institutionSelected = payload;
    localStorage.setItem('institutionSelected', JSON.stringify(payload))
  },

  //COMMON
  [InstitutionMutations.SET_IS_LOADING_INSTITUTIONS_LIST_ARCHIVED]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingInstitutionsListArchived = payload;
  },
  [InstitutionMutations.SET_IS_LOADING_INSTITUTIONS_LIST_MINIFIED]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingInstitutionsListMinified = payload;
  },
  [InstitutionMutations.SET_IS_LOADING_INSTITUTIONS_LIST]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingInstitutionsList = payload;
  },
  [InstitutionMutations.SET_IS_LOADING_INSTITUTION]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingInstitution = payload;
  },
  [InstitutionMutations.SET_IS_CREATING_INSTITUTION]: (
    state,
    payload: boolean
  ) => {
    state.isCreatingInstitution = payload;
  },
  [InstitutionMutations.SET_IS_UPDATING_INSTITUTION]: (
    state,
    payload: boolean
  ) => {
    state.isUpdatingInstitution = payload;
  },
  [InstitutionMutations.SET_IS_DELETING_INSTITUTION]: (
    state,
    payload: boolean
  ) => {
    state.isDeletingInstitution = payload;
  },
  [InstitutionMutations.SET_IS_ARCHIVING_INSTITUTION]: (
    state,
    payload: boolean
  ) => {
    state.isArchivingInstitution = payload;
  },

  [InstitutionMutations.SET_REQUEST_STATE_INSTITUTION]: (
    state,
    payload: IRequestState
  ) => {
    state.requestStateInstitution = payload;
  },
};
