import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { InventoryRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IInventoryState } from "./states";
import { IFilters } from "@/types/api-orisis/interfaces/invoices/filters/IFilters";

export enum InventoryMutations {
    SET_INVENTORIES_LIST = "SET_INVENTORIES_LIST",
    SET_INVENTORIES_LIST_ARCHIVED = "SET_INVENTORIES_LIST_ARCHIVED",
    SET_INVENTORY = "SET_INVENTORY",
    SET_INVENTORY_ARCHIVED = "SET_INVENTORY_ARCHIVED",
    SET_LAST_INVENTORY_REFERENCE = "SET_LAST_INVENTORY_REFERENCE",

    SET_INVENTORY_FILTERS = "SET_INVENTORY_FILTERS",

    // COMMON
    SET_IS_VALIDATING_INVENTORY = "SET_IS_VALIDATING_INVENTORY",
    SET_INVENTORY_PDF = "SET_INVENTORY_PDF",
    SET_IS_LOADING_INVENTORY_PDF = "SET_IS_LOADING_INVENTORY_PDF",
    SET_IS_LOADING_INVENTORY_LIST = "SET_IS_LOADING_INVENTORY_LIST",
    SET_IS_LOADING_INVENTORY_LIST_ARCHIVED = "SET_IS_LOADING_INVENTORY_LIST_ARCHIVED",
    SET_IS_LOADING_INVENTORY = "SET_IS_LOADING_INVENTORY",
    SET_IS_LOADING_INVENTORY_ARCHIVED = "SET_IS_LOADING_INVENTORY_ARCHIVED",
    SET_IS_CREATING_INVENTORY = "SET_IS_CREATING_INVENTORY",
    SET_IS_UPDATING_INVENTORY = "SET_IS_UPDATING_INVENTORY",
    SET_IS_DELETING_INVENTORY = "SET_IS_DELETING_INVENTORY",
    SET_IS_RESTORING_INVENTORY = "SET_IS_RESTORING_INVENTORY",
    SET_IS_ARCHIVING_INVENTORY = "SET_IS_ARCHIVING_INVENTORY",
    SET_REQUEST_STATE_INVENTORY = "SET_REQUEST_STATE_INVENTORY",
    SET_INVENTORY_TAB_ACTIVE = "SET_INVENTORY_TAB_ACTIVE",
}

export const mutations: MutationTree<IInventoryState> = {
    [InventoryMutations.SET_INVENTORIES_LIST_ARCHIVED]: (state, payload: InventoryRequestDto[]) => {
        state.inventoriesListArchived = payload;
    }, 
    [InventoryMutations.SET_INVENTORIES_LIST]: (state, payload: InventoryRequestDto[]) => {
        state.inventoriesList = payload;
    },
    [InventoryMutations.SET_INVENTORY]: (state, payload: InventoryRequestDto) => {
        state.inventory = payload;
    },
    [InventoryMutations.SET_INVENTORY_ARCHIVED]: (state, payload: InventoryRequestDto) => {
        state.inventoryArchived = payload;
    },
    [InventoryMutations.SET_LAST_INVENTORY_REFERENCE]: (state, payload: string) => {
        state.inventory.documentReference = payload;
    },

    //FILTERS
    [InventoryMutations.SET_INVENTORY_FILTERS]: (state, payload: IFilters) => {
        state.inventoryFilters = payload;
    },

    //COMMON
    [InventoryMutations.SET_INVENTORY_PDF]: (state, payload: string) => {
        state.inventoryPDF = payload+'?r='+(new Date()).getTime();
    },
    

    [InventoryMutations.SET_IS_VALIDATING_INVENTORY]: (state, payload: boolean) => {
        state.isValidatingInventory = payload;
    },
    [InventoryMutations.SET_IS_LOADING_INVENTORY_PDF]: (state, payload: boolean) => {
        state.isLoadingInventoryPDF = payload;
    },
    [InventoryMutations.SET_IS_LOADING_INVENTORY_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingInventoriesListArchived = payload;
    },
    [InventoryMutations.SET_IS_LOADING_INVENTORY_LIST]: (state, payload: boolean) => {
        state.isLoadingInventoriesList = payload;
    },
    [InventoryMutations.SET_IS_LOADING_INVENTORY]: (state, payload: boolean) => {
        state.isLoadingInventory = payload;
    },
    [InventoryMutations.SET_IS_LOADING_INVENTORY_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingInventoryArchived = payload;
    },
    [InventoryMutations.SET_IS_CREATING_INVENTORY]: (state, payload: boolean) => {
        state.isCreatingInventory = payload;
    },
    [InventoryMutations.SET_IS_UPDATING_INVENTORY]: (state, payload: boolean) => {
        state.isUpdatingInventory = payload;
    },
    [InventoryMutations.SET_IS_ARCHIVING_INVENTORY]: (state, payload: boolean) => {
        state.isArchivingInventory = payload;
    },
    [InventoryMutations.SET_IS_RESTORING_INVENTORY]: (state, payload: boolean) => {
        state.isRestoringInventory = payload;
    },
    [InventoryMutations.SET_IS_DELETING_INVENTORY]: (state, payload: boolean) => {
        state.isDeletingInventory = payload;
    },
    [InventoryMutations.SET_REQUEST_STATE_INVENTORY]: (state, payload: IRequestState) => {
        state.requestStateInventory = payload;
    },
    [InventoryMutations.SET_INVENTORY_TAB_ACTIVE]: (state, payload: any) => {
        state.inventoryTabActive = payload;
    },
}