import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { InvoiceProviderRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IInvoiceProviderState } from "./states";
import { IFilters } from "@/types/api-orisis/interfaces/invoices/filters/IFilters";

export enum InvoiceProviderMutations {
    SET_INVOICE_PROVIDERS_LIST = "SET_INVOICE_PROVIDERS_LIST",
    SET_INVOICE_PROVIDER = "SET_INVOICE_PROVIDER",
    SET_INVOICE_PROVIDER_PDF = "SET_INVOICE_PROVIDER_PDF",

    // COMMON
    SET_IS_LOADING_INVOICE_PROVIDER_LIST = "SET_IS_LOADING_INVOICE_PROVIDER_LIST",
    SET_IS_LOADING_INVOICE_PROVIDER = "SET_IS_LOADING_INVOICE_PROVIDER",
    SET_INVOICE_PROVIDER_FILTERS = "SET_INVOICE_PROVIDER_FILTERS",
    SET_IS_CREATING_INVOICE_PROVIDER = "SET_IS_CREATING_INVOICE_PROVIDER",
    SET_IS_UPDATING_INVOICE_PROVIDER = "SET_IS_UPDATING_INVOICE_PROVIDER",
    SET_IS_DELETING_INVOICE_PROVIDER = "SET_IS_DELETING_INVOICE_PROVIDER",
    SET_REQUEST_STATE_INVOICE_PROVIDER = "SET_REQUEST_STATE_INVOICE_PROVIDER",
    SET_IS_LOADING_INVOICE_PROVIDERS_LIST_BY_AFFAIR = "SET_IS_LOADING_INVOICE_PROVIDERS_LIST_BY_AFFAIR",
    SET_INVOICE_PROVIDERS_LIST_BY_AFFAIR = "SET_INVOICE_PROVIDERS_LIST_BY_AFFAIR",
    SET_IS_LOADING_INVOICE_PROVIDERS_LIST_BY_PROVIDER = "SET_IS_LOADING_INVOICE_PROVIDERS_LIST_BY_PROVIDER",
    SET_INVOICE_PROVIDER_LIST_BY_PROVIDER = "SET_INVOICE_PROVIDER_LIST_BY_PROVIDER",
}
 
export const mutations: MutationTree<IInvoiceProviderState> = {
    [InvoiceProviderMutations.SET_INVOICE_PROVIDERS_LIST]: (state, payload: InvoiceProviderRequestDto[]) => {
        state.invoiceProvidersList = payload;
    },
    [InvoiceProviderMutations.SET_INVOICE_PROVIDER]: (state, payload: InvoiceProviderRequestDto) => {
        state.invoiceProvider = payload;
    },

    //COMMON
    [InvoiceProviderMutations.SET_IS_LOADING_INVOICE_PROVIDER_LIST]: (state, payload: boolean) => {
        state.isLoadingInvoiceProvidersList = payload;
    },
    [InvoiceProviderMutations.SET_IS_LOADING_INVOICE_PROVIDER]: (state, payload: boolean) => {
        state.isLoadingInvoiceProvider = payload;
    },
    [InvoiceProviderMutations.SET_IS_CREATING_INVOICE_PROVIDER]: (state, payload: boolean) => {
        state.isCreatingInvoiceProvider = payload;
    },
    [InvoiceProviderMutations.SET_IS_UPDATING_INVOICE_PROVIDER]: (state, payload: boolean) => {
        state.isUpdatingInvoiceProvider = payload;
    },
    [InvoiceProviderMutations.SET_IS_DELETING_INVOICE_PROVIDER]: (state, payload: boolean) => {
        state.isDeletingInvoiceProvider = payload;
    },
    [InvoiceProviderMutations.SET_REQUEST_STATE_INVOICE_PROVIDER]: (state, payload: IRequestState) => {
        state.requestStateInvoiceProvider = payload;
    },
    [InvoiceProviderMutations.SET_INVOICE_PROVIDER_FILTERS]: (state, payload: IFilters) => {
        state.invoiceProviderFilters = payload;
    },
    [InvoiceProviderMutations.SET_IS_LOADING_INVOICE_PROVIDERS_LIST_BY_AFFAIR]: (state, payload: boolean) => {
        state.isLoadingInvoiceProvidersListByAffair = payload;
    },
    [InvoiceProviderMutations.SET_INVOICE_PROVIDERS_LIST_BY_AFFAIR]: (state, payload: InvoiceProviderRequestDto[]) => {
        state.invoiceProvidersListByAffair = payload;
    },
    [InvoiceProviderMutations.SET_IS_LOADING_INVOICE_PROVIDERS_LIST_BY_PROVIDER]: (state, payload: boolean) => {
        state.isLoadingInvoiceProvidersListByProvider = payload;
    },
    [InvoiceProviderMutations.SET_INVOICE_PROVIDER_LIST_BY_PROVIDER]: (state, payload: InvoiceProviderRequestDto[]) => {
        state.invoicesProvidersListByProvider = payload;
    }
}