import { IOrderFormCustomerOptionDefaultState } from "./states";
import { Module } from "vuex";
import { OrderFormCustomerOptionRequestDto } from "@/../Api";
import RootState from "@/store/modules/rootState";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

const state: IOrderFormCustomerOptionDefaultState = {
  orderFormCustomerOptionDefault: <OrderFormCustomerOptionRequestDto>{},
  orderFormCustomerOptionDefaultForm: <OrderFormCustomerOptionRequestDto>{},
  isLoadingOrderFormCustomerOptionDefault: false,
  requestStateOrderFormCustomerOptionDefault: <IRequestState>{},
};

export const OrderFormCustomerOptionDefaultModule: Module<
  IOrderFormCustomerOptionDefaultState,
  RootState
> = {
  state,
  getters,
  mutations,
  actions,
};
