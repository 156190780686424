import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IInstitutionSettingsStates } from "./states";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export const getters: GetterTree<IInstitutionSettingsStates, RootState> = {
  isOpendocumentSettingsPanel: (state): boolean =>
    state.isOpendocumentSettingsPanel,
  institutionSettings: (state) => state.institutionSettings,
  institutionSettingsActive: (state) => state.institutionSettingsActive,
  tabSelected: (state) => state.tabSelected,
  isLoadingInstitutionSettings: (state) => state.isLoadingInstitutionSettings,
  isCreatingInstitutionSettings: (state) => state.isCreatingInstitutionSettings,
  isUpdatingInstitutionSettings: (state) => state.isUpdatingInstitutionSettings,
  requestStateInstitutionSettings: (state): IRequestState =>
    state.requestStateInstitutionSettings,
  referencielTvasInstitutionSettingsList: (state) =>
    state.referencielTvasInstitutionSettingsList,
};
