import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { IDeduction } from "@/types/api-orisis/interfaces/invoices/deduction/IDeduction";
import { GetterTree } from "vuex";
import RootState from '@/store/modules/rootState'
import { IDeductionState } from "./states";

export const getters: GetterTree<IDeductionState, RootState> = {
    deductionsList: (state): IDeduction[] => state.deductionsList,
    deductionsListArchived: (state): IDeduction[] => state.deductionsListArchived,
    deduction     : (state): IDeduction => state.deduction,
    
    // COMMON
    isLoadingDeductionsList   : (state): boolean => state.isLoadingDeductionsList,
    isLoadingDeductionsListArchived   : (state): boolean => state.isLoadingDeductionsListArchived,
    isLoadingDeduction   : (state): boolean => state.isLoadingDeduction,
    isCreatingDeduction  : (state): boolean => state.isCreatingDeduction,
    isUpdatingDeduction  : (state): boolean => state.isUpdatingDeduction,
    isDeletingDeduction  : (state): boolean => state.isDeletingDeduction,
    isArchivingDeduction : (state): boolean => state.isArchivingDeduction,
    requestStateDeduction: (state): IRequestState => state.requestStateDeduction,
}