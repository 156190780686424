import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { IExportSalesJournal } from "@/types/api-orisis/interfaces/Accounting/exportSalesJournal/IExportSalesJournal";
import RootState from "@/store/modules/rootState";
import { IExportSalesJournalState } from "./states";
import { Module } from "vuex";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

export const state: IExportSalesJournalState = {
  exportSalesJournalList: Array<IExportSalesJournal>(),
  exportSalesJournal: <IExportSalesJournal>{},
  isLoadingExportSalesJournalList: false,
  isLoadingExportSalesJournal: false,
  isCreatingExportSalesJournal: false,
  isUpdatingExportSalesJournal: false,
  isDeletingExportSalesJournal: false,
  isArchivingExportSalesJournal: false,
  requestStateExportSalesJournal: <IRequestState>{},
};

export const ExportSalesJournalModule: Module<
  IExportSalesJournalState,
  RootState
> = {
  state,
  getters,
  mutations,
  actions,
};
