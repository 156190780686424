import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { StockRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IStockState } from "./states";
import { IFilters } from "@/types/api-orisis/interfaces/invoices/filters/IFilters";

export enum StockMutations {
    
    SET_PRODUCT_WITH_STOCK_LIST = "SET_PRODUCT_WITH_STOCK_LIST",
    SET_STOCKS_LIST = "SET_STOCKS_LIST",
    SET_STOCKS_LIST_ARCHIVED = "SET_STOCKS_LIST_ARCHIVED",
    SET_STOCK = "SET_STOCK",
    SET_STOCK_ARCHIVED = "SET_STOCK_ARCHIVED",

    // COMMON
    SET_IS_LOADING_PRODUCTS_WITH_STOCK_LIST = "SET_IS_LOADING_PRODUCTS_WITH_STOCK_LIST",
    SET_IS_LOADING_STOCK_LIST = "SET_IS_LOADING_STOCK_LIST",
    SET_IS_LOADING_STOCK_LIST_ARCHIVED = "SET_IS_LOADING_STOCK_LIST_ARCHIVED",
    SET_IS_LOADING_STOCK = "SET_IS_LOADING_STOCK",
    SET_IS_LOADING_STOCK_ARCHIVED = "SET_IS_LOADING_STOCK_ARCHIVED",
    SET_IS_CREATING_STOCK = "SET_IS_CREATING_STOCK",
    SET_IS_UPDATING_STOCK = "SET_IS_UPDATING_STOCK",
    SET_IS_DELETING_STOCK = "SET_IS_DELETING_STOCK",
    SET_IS_RESTORING_STOCK = "SET_IS_RESTORING_STOCK",
    SET_IS_ARCHIVING_STOCK = "SET_IS_ARCHIVING_STOCK",
    SET_REQUEST_STATE_STOCK = "SET_REQUEST_STATE_STOCK",
}

export const mutations: MutationTree<IStockState> = {
    [StockMutations.SET_PRODUCT_WITH_STOCK_LIST]: (state, payload:[]) => {
        state.productsWithStockList = payload;
    }, 
    [StockMutations.SET_STOCKS_LIST_ARCHIVED]: (state, payload: StockRequestDto[]) => {
        state.stocksListArchived = payload;
    }, 
    [StockMutations.SET_STOCKS_LIST]: (state, payload: StockRequestDto[]) => {
        state.stocksList = payload;
    },
    [StockMutations.SET_STOCK]: (state, payload: StockRequestDto) => {
        state.stock = payload;
    },
    [StockMutations.SET_STOCK_ARCHIVED]: (state, payload: StockRequestDto) => {
        state.stockArchived = payload;
    },

    //COMMON
    [StockMutations.SET_IS_LOADING_PRODUCTS_WITH_STOCK_LIST]: (state, payload: boolean) => {
        state.isLoadingProductsWithStocksList = payload;
    },
    [StockMutations.SET_IS_LOADING_STOCK_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingStocksListArchived = payload;
    },
    
    [StockMutations.SET_IS_LOADING_STOCK_LIST]: (state, payload: boolean) => {
        state.isLoadingStocksList = payload;
    },
    [StockMutations.SET_IS_LOADING_STOCK]: (state, payload: boolean) => {
        state.isLoadingStock = payload;
    },
    [StockMutations.SET_IS_LOADING_STOCK_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingStockArchived = payload;
    },
    [StockMutations.SET_IS_CREATING_STOCK]: (state, payload: boolean) => {
        state.isCreatingStock = payload;
    },
    [StockMutations.SET_IS_UPDATING_STOCK]: (state, payload: boolean) => {
        state.isUpdatingStock = payload;
    },
    [StockMutations.SET_IS_ARCHIVING_STOCK]: (state, payload: boolean) => {
        state.isArchivingStock = payload;
    },
    [StockMutations.SET_IS_RESTORING_STOCK]: (state, payload: boolean) => {
        state.isRestoringStock = payload;
    },
    [StockMutations.SET_IS_DELETING_STOCK]: (state, payload: boolean) => {
        state.isDeletingStock = payload;
    },
    [StockMutations.SET_REQUEST_STATE_STOCK]: (state, payload: IRequestState) => {
        state.requestStateStock = payload;
    },
}