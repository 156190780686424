import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { CollaboratorGoalRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { ICollaboratorGoalState } from './states'


export const getters: GetterTree<ICollaboratorGoalState, RootState> = {
  collaboratorGoalsList: (state): CollaboratorGoalRequestDto[] => state.collaboratorGoalsList,
  collaboratorGoalsListArchived: (state): CollaboratorGoalRequestDto[] => state.collaboratorGoalsListArchived,
  collaboratorGoal     : (state): CollaboratorGoalRequestDto => state.collaboratorGoal,

  // COMMON
  isLoadingCollaboratorGoalsList   : (state): boolean => state.isLoadingCollaboratorGoalsList,
  isLoadingCollaboratorGoalsListArchived   : (state): boolean => state.isLoadingCollaboratorGoalsListArchived,
  isLoadingCollaboratorGoal   : (state): boolean => state.isLoadingCollaboratorGoal,
  isCreatingCollaboratorGoal  : (state): boolean => state.isCreatingCollaboratorGoal,
  isUpdatingCollaboratorGoal  : (state): boolean => state.isUpdatingCollaboratorGoal,
  isDeletingCollaboratorGoal  : (state): boolean => state.isDeletingCollaboratorGoal,
  isArchivingCollaboratorGoal  : (state): boolean => state.isArchivingCollaboratorGoal,
  requestStateCollaboratorGoal: (state): IRequestState => state.requestStateCollaboratorGoal,
}



