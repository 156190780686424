import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { CollaboratorGoalRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { ICollaboratorGoalState } from "./states";

export enum CollaboratorGoalMutations {
    SET_COLLABORATOR_GOALS_LIST = "SET_COLLABORATOR_GOALS_LIST",
    SET_COLLABORATOR_GOALS_LIST_ARCHIVED = "SET_COLLABORATOR_GOALS_LIST_ARCHIVED",
    SET_COLLABORATOR_GOAL = "SET_COLLABORATOR_GOAL",

    //FILTERS
    SET_COLLABORATOR_GOAL_FILTERS = "SET_COLLABORATOR_GOAL_FILTERS",

    // COMMON
    SET_IS_LOADING_COLLABORATOR_GOAL_LIST = "SET_IS_LOADING_COLLABORATOR_GOAL_LIST",
    SET_IS_LOADING_COLLABORATOR_GOAL_LIST_ARCHIVED = "SET_IS_LOADING_COLLABORATOR_GOAL_LIST_ARCHIVED",
    SET_IS_LOADING_COLLABORATOR_GOAL = "SET_IS_LOADING_COLLABORATOR_GOAL",
    SET_IS_CREATING_COLLABORATOR_GOAL = "SET_IS_CREATING_COLLABORATOR_GOAL",
    SET_IS_UPDATING_COLLABORATOR_GOAL = "SET_IS_UPDATING_COLLABORATOR_GOAL",
    SET_IS_DELETING_COLLABORATOR_GOAL = "SET_IS_DELETING_COLLABORATOR_GOAL",
    SET_IS_ARCHIVING_COLLABORATOR_GOAL = "SET_IS_ARCHIVING_COLLABORATOR_GOAL",
    SET_REQUEST_STATE_COLLABORATOR_GOAL = "SET_REQUEST_STATE_COLLABORATOR_GOAL",
}

export const mutations: MutationTree<ICollaboratorGoalState> = {
    [CollaboratorGoalMutations.SET_COLLABORATOR_GOALS_LIST]: (state, payload: CollaboratorGoalRequestDto[]) => {
        state.collaboratorGoalsList = payload;
    },
    [CollaboratorGoalMutations.SET_COLLABORATOR_GOALS_LIST_ARCHIVED]: (state, payload: CollaboratorGoalRequestDto[]) => {
        state.collaboratorGoalsListArchived = payload;
    }, 
    [CollaboratorGoalMutations.SET_COLLABORATOR_GOAL]: (state, payload: CollaboratorGoalRequestDto) => {
        state.collaboratorGoal = payload;
    },

    //FILTERS
    [CollaboratorGoalMutations.SET_COLLABORATOR_GOAL_FILTERS]: (state, payload: any) => {
        state.collaboratorGoalFilters = payload;
    },


    //COMMON
    [CollaboratorGoalMutations.SET_IS_LOADING_COLLABORATOR_GOAL_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingCollaboratorGoalsListArchived = payload;
    },
    [CollaboratorGoalMutations.SET_IS_LOADING_COLLABORATOR_GOAL_LIST]: (state, payload: boolean) => {
        state.isLoadingCollaboratorGoalsList = payload;
    },
    [CollaboratorGoalMutations.SET_IS_LOADING_COLLABORATOR_GOAL]: (state, payload: boolean) => {
        state.isLoadingCollaboratorGoal = payload;
    },
    [CollaboratorGoalMutations.SET_IS_CREATING_COLLABORATOR_GOAL]: (state, payload: boolean) => {
        state.isCreatingCollaboratorGoal = payload;
    },
    [CollaboratorGoalMutations.SET_IS_UPDATING_COLLABORATOR_GOAL]: (state, payload: boolean) => {
        state.isUpdatingCollaboratorGoal = payload;
    },
    [CollaboratorGoalMutations.SET_IS_DELETING_COLLABORATOR_GOAL]: (state, payload: boolean) => {
        state.isDeletingCollaboratorGoal = payload;
    },
    [CollaboratorGoalMutations.SET_IS_ARCHIVING_COLLABORATOR_GOAL]: (state, payload: boolean) => {
        state.isArchivingCollaboratorGoal = payload;
    },
    [CollaboratorGoalMutations.SET_REQUEST_STATE_COLLABORATOR_GOAL]: (state, payload: IRequestState) => {
        state.requestStateCollaboratorGoal = payload;
    },
}