import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { ModalEditRefProductMutations } from "./mutations";
import { ProductProviderMutations } from "@/store/modules/purchase/productProvider/mutations";
import { CatalogMutations } from "@/store/modules/invoices/catalog/mutations";
import { ModalEditRefProductState } from "./states";
import { ProductDefaultProperties } from "@/types/api-orisis/models/ProductModel";
import { ProductProviderDefaultProperties } from "@/types/api-orisis/models/ProductProviderModel";
import { ReceiptFormDetailDefaultProperties } from "@/types/api-orisis/models/ReceiptFormDetailModel";
import { OrderFormDetailDefaultProperties } from "@/types/api-orisis/models/OrderFormDetailModel";
import { DeliveryFormDetailDefaultProperties } from "@/types/api-orisis/models/DeliveryFormDetailModel";

export const actions: ActionTree<ModalEditRefProductState, RootState> = {
  verifRefProductIsSingle({ commit, rootGetters }, reference: string) {
    if (reference) {
      commit(ModalEditRefProductMutations.SET_IS_CHECK_REFERENCE, true);
      let isSimple = rootGetters.catalog.filter(
        (product) => product.reference === reference
      );
      if (isSimple.length >= 1) {
        commit(ModalEditRefProductMutations.SET_IS_EXISTING_PRODUCT, true);
      } else {
        commit(ModalEditRefProductMutations.SET_IS_EXISTING_PRODUCT, false);
      }
    } else {
      commit(ModalEditRefProductMutations.SET_IS_CHECK_REFERENCE, false);
    }
  },
  findIndexAndArray(elem, array) {
    for (let i = 0; i < array.length; i++) {
      const childrenArray = array[i];
      for (let j = 0; j < childrenArray.length; j++) {
        if (childrenArray[j].id === elem) {
          return { index: j, array: childrenArray, arrayIndex: i };
        }
      }
    }
    return null;
  },

  async copyProductProviderForProduct(
    { dispatch, rootGetters, commit },
    path: string
  ) {
    return new Promise(async (resolve, reject) => {
      let product = ProductDefaultProperties({
        ...rootGetters.productToCreate,
        priceHT: 0,
        productType:
          rootGetters.productToCreate.type == 7
            ? 0
            : rootGetters.productToCreate.type == 8
            ? 5
            : rootGetters.productToCreate.type == 9
            ? 2
            : 6,
        reference: rootGetters.refToProduct,
      });

      let detailToUpdate;

      await dispatch("createProduct", { product: product }).then(
        async (res) => {
          let productProvider = ProductProviderDefaultProperties({
            ...rootGetters.productToCreate,
            companyId:
              path == "updateReceiptFormProviderDetail"
                ? rootGetters.receiptFormProvider.companyProviderId
                : rootGetters.orderFormProvider.companyProviderId,
            productType:
              rootGetters.productToCreate.type == 7
                ? 0
                : rootGetters.productToCreate.type == 8
                ? 5
                : rootGetters.productToCreate.type == 9
                ? 2
                : 6,
            productId: res.data.id,
            institutionId: rootGetters.institutionSelected.id,
          });

          let productsList = rootGetters.catalog;
          productsList.push(res);
          commit(CatalogMutations.SET_CATALOG, productsList);

          if (!rootGetters.productProviderExisting) {
            await dispatch("createProductProvider", {
              productProvider: { ...productProvider, id: 0 },
              reload: false,
            }).then((res) => {
              let productProvidersList = rootGetters.productProvidersList;
              productProvidersList.push(res);
              commit(
                ProductProviderMutations.SET_PRODUCT_PROVIDERS_LIST,
                productProvidersList
              );
              if (path === "updateReceiptFormProviderDetail") {
                detailToUpdate = ReceiptFormDetailDefaultProperties({
                  ...rootGetters.productToCreate,
                  index: rootGetters.productToCreate.index,
                  productProviderId: res.data.data.id,
                  amountHt: rootGetters.productToCreate.total,
                });
              } else if (path === "updateOrderFormProviderDetail") {
                detailToUpdate = OrderFormDetailDefaultProperties({
                  ...rootGetters.productToCreate,
                  productProviderId: res.data.data.id,
                  amountHt: rootGetters.productToCreate.total,
                });
              }
            });
          } else {
            await dispatch("updateProductProvider", {
              productProvider: {
                ...productProvider,
                id: rootGetters.productToCreate.productProviderId,
              },
              reload: false,
            }).then((res) => {
              dispatch("getProductProviders", {});
              if (path === "updateReceiptFormProviderDetail") {
                detailToUpdate = ReceiptFormDetailDefaultProperties({
                  ...rootGetters.productToCreate,
                  amountHt: rootGetters.productToCreate.total,
                });
              } else if (path === "updateOrderFormProviderDetail") {
                detailToUpdate = OrderFormDetailDefaultProperties({
                  ...rootGetters.productToCreate,
                  amountHt: rootGetters.productToCreate.total,
                });
              }
            });
            commit(
              ModalEditRefProductMutations.SET_PRODUCT_PROVIDER_EXISTING,
              false
            );
          }
        }
      );
      await dispatch(
        path,
        path === "updateOrderFormProviderDetail"
          ? { orderFormProviderDetail: detailToUpdate }
          : { receiptFormDetail: detailToUpdate }
      );
      commit(
        ModalEditRefProductMutations.SET_IS_ACTIVE_MODAL_EDIT_REF_PRODUCT,
        false
      );
    });
  },

  async linkProductToProductProvider(
    { dispatch, rootGetters, commit },
    path: string
  ) {
    let productProvider = ProductProviderDefaultProperties({
      ...rootGetters.productToCreate,
      companyId:
        path == "updateReceiptFormProviderDetail"
          ? rootGetters.receiptFormProvider.companyProviderId
          : rootGetters.orderFormProvider.companyProviderId,
      productType:
        rootGetters.productToCreate.type == 7
          ? 0
          : rootGetters.productToCreate.type == 8
          ? 5
          : rootGetters.productToCreate.type == 9
          ? 2
          : 6,
      institutionId: rootGetters.institutionSelected.id,
    });

    let detailToUpdate;

    if (!rootGetters.productProviderExisting) {
      await dispatch("createProductProvider", {
        productProvider: { ...productProvider, id: 0 },
        reload: false,
      }).then((res) => {
        let productProvidersList = rootGetters.productProvidersList;
        productProvidersList.push(res);
        commit(
          ProductProviderMutations.SET_PRODUCT_PROVIDERS_LIST,
          productProvidersList
        );
        if (path === "updateReceiptFormProviderDetail") {
          detailToUpdate = ReceiptFormDetailDefaultProperties({
            ...rootGetters.productToCreate,
            productProviderId: res.data.data.id,
            amountHt: rootGetters.productToCreate.total,
          });
        } else if (path === "updateOrderFormProviderDetail") {
          detailToUpdate = OrderFormDetailDefaultProperties({
            ...rootGetters.productToCreate,
            productProviderId: res.data.data.id,
            amountHt: rootGetters.productToCreate.total,
          });
        }
      });
    } else {
      await dispatch("updateProductProvider", {
        productProvider: {
          ...productProvider,
          id: rootGetters.productToCreate.productProviderId,
        },
        reload: false,
      }).then((res) => {
        dispatch("getProductProviders", {});

        if (path === "updateReceiptFormProviderDetail") {
          detailToUpdate = ReceiptFormDetailDefaultProperties({
            ...rootGetters.productToCreate,
            productProviderId: res.data.data.id,
            amountHt: rootGetters.productToCreate.total,
          });
        } else if (path === "updateOrderFormProviderDetail") {
          detailToUpdate = OrderFormDetailDefaultProperties({
            ...rootGetters.productToCreate,
            productProviderId: res.data.data.id,
            amountHt: rootGetters.productToCreate.total,
          });
        }
        commit(
          ModalEditRefProductMutations.SET_PRODUCT_PROVIDER_EXISTING,
          false
        );
      });
    }

    await dispatch(
      path,
      path === "updateOrderFormProviderDetail"
        ? { orderFormProviderDetail: detailToUpdate }
        : { receiptFormDetail: detailToUpdate }
    );
    commit(
      ModalEditRefProductMutations.SET_IS_ACTIVE_MODAL_EDIT_REF_PRODUCT,
      false
    );
  },

  createProductToDeliveryForm({ dispatch, rootGetters, commit }) {
    let product = ProductDefaultProperties({
      ...rootGetters.productToCreate,
      productType:
        rootGetters.productToCreate.type == 7
          ? 0
          : rootGetters.productToCreate.type == 8
          ? 5
          : rootGetters.productToCreate.type == 9
          ? 2
          : 6,
    });
    dispatch("createProduct", { product: product }).then(async (res) => {
      let detailToUpdate = await DeliveryFormDetailDefaultProperties({
        ...rootGetters.productToCreate,
        productId: res.data.id,
        amountHt: rootGetters.productToCreate.total,
      });
      await dispatch("updateDeliveryFormDetail", {
        deliveryFormDetail: detailToUpdate,
      });
      commit(
        ModalEditRefProductMutations.SET_IS_ACTIVE_MODAL_EDIT_REF_PRODUCT,
        false
      );
    });
  },
};
