import { ISmsState } from "./states";
import { MutationTree } from "vuex";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { ProgrammedSmsRequestDto } from '@/../Api'

export enum SmsMutations{
    SET_SMS_LIST = "SET_SMS_LIST",
    SET_SMS_LIST_BY_EVENT_ID = "SET_SMS_LIST_BY_EVENT_ID",
    SET_SMS = "SET_SMS",
    SET_IS_LOADING_SMS_LIST= "SET_IS_LOADING_SMS_LIST",
    SET_IS_LOADING_SMS_LIST_BY_EVENT_ID = "SET_IS_LOADING_SMS_LIST_BY_EVENT_ID",
    SET_IS_LOADING_SMS= "SET_IS_LOADING_SMS",
    SET_REQUEST_STATE_SMS = "SET_REQUEST_STATE_SMS"
  }

  export const mutations: MutationTree<ISmsState> = {
    [SmsMutations.SET_SMS_LIST]: (state, payload: ProgrammedSmsRequestDto[]) => {
        state.smsList = payload;
    },
    [SmsMutations.SET_SMS_LIST_BY_EVENT_ID]: (state, payload: ProgrammedSmsRequestDto[]) => {
        state.smsListByEventId = payload;
    },
    [SmsMutations.SET_SMS]: (state, payload: ProgrammedSmsRequestDto) => {
        state.sms = payload;
    },
    [SmsMutations.SET_IS_LOADING_SMS_LIST]: (state, payload: boolean) => {
        state.isLoadingSmsList = payload;
    }, 
    [SmsMutations.SET_IS_LOADING_SMS_LIST_BY_EVENT_ID]: (state, payload: boolean) => {
        state.isLoadingSmsListByEventId = payload;
    }, 
    
    [SmsMutations.SET_IS_LOADING_SMS]: (state, payload: boolean) => {
        state.isLoadingSms = payload;
    },
    [SmsMutations.SET_REQUEST_STATE_SMS]: (state, payload: IRequestState) => {
        state.requestStateSms = payload;
    },
}