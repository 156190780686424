import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { DocumentHistoryMutations } from "./mutations";
import { IDocumentHistoryState } from "./states";

export const actions: ActionTree<IDocumentHistoryState, RootState> = {
  getQuoteHistoryByQuoteId(
    { commit },
    { quoteId, updateState = true }: { quoteId: number; updateState?: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          DocumentHistoryMutations.SET_IS_LOADING_DOCUMENT_HISTORIES_LIST,
          true
        );
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/QuoteHistory/GetQuoteHistoryByQuoteId",
          {
            params: {
              quoteId: quoteId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(
              DocumentHistoryMutations.SET_DOCUMENT_HISTORIES_LIST,
              res.data
            );
            commit(
              DocumentHistoryMutations.SET_IS_LOADING_DOCUMENT_HISTORIES_LIST,
              false
            );
          }
          commit(
            DocumentHistoryMutations.SET_REQUEST_STATE_DOCUMENT_HISTORY,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DocumentHistoryMutations.SET_REQUEST_STATE_DOCUMENT_HISTORY,
            err
          );
          if (updateState) {
            commit(
              DocumentHistoryMutations.SET_IS_LOADING_DOCUMENT_HISTORIES_LIST,
              false
            );
          }
          reject(err);
        });
    });
  },

  getQuoteHistoryById(
    { commit },
    {
      quoteHistoryId,
      updateState = true,
    }: { quoteHistoryId: number; updateState?: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(DocumentHistoryMutations.SET_IS_LOADING_DOCUMENT_HISTORY, true);
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/QuoteHistory/GetQuoteHistoryById",
          {
            params: {
              quoteHistoryId: quoteHistoryId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(DocumentHistoryMutations.SET_DOCUMENT_HISTORY, res.data);
            commit(
              DocumentHistoryMutations.SET_IS_LOADING_DOCUMENT_HISTORY,
              false
            );
          }
          commit(
            DocumentHistoryMutations.SET_REQUEST_STATE_DOCUMENT_HISTORY,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DocumentHistoryMutations.SET_REQUEST_STATE_DOCUMENT_HISTORY,
            err
          );
          if (updateState) {
            commit(
              DocumentHistoryMutations.SET_IS_LOADING_DOCUMENT_HISTORY,
              false
            );
          }
          reject(err);
        });
    });
  },

  async getInvoiceHistoryByInvoiceId(
    { commit },
    {
      invoiceId,
      updateState = true,
    }: { invoiceId: number; updateState?: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(DocumentHistoryMutations.SET_IS_LOADING_DOCUMENT_HISTORY, true);
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/InvoiceHistory/GetInvoiceHistoryByInvoiceId",
          {
            params: {
              invoiceId: invoiceId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(
              DocumentHistoryMutations.SET_DOCUMENT_HISTORIES_LIST,
              res.data
            );
            commit(
              DocumentHistoryMutations.SET_IS_LOADING_DOCUMENT_HISTORY,
              false
            );
          }
          commit(
            DocumentHistoryMutations.SET_REQUEST_STATE_DOCUMENT_HISTORY,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DocumentHistoryMutations.SET_REQUEST_STATE_DOCUMENT_HISTORY,
            err
          );
          commit(
            DocumentHistoryMutations.SET_IS_LOADING_DOCUMENT_HISTORY,
            false
          );
          reject(err);
        });
    });
  },

  async getInvoiceHistoryById(
    { commit },
    {
      invoiceHistoryId,
      updateState = true,
    }: { invoiceHistoryId: number; updateState?: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(DocumentHistoryMutations.SET_IS_LOADING_DOCUMENT_HISTORY, true);
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/InvoiceHistory/GetInvoiceHistoryById",
          {
            params: {
              invoiceHistoryId: invoiceHistoryId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(DocumentHistoryMutations.SET_DOCUMENT_HISTORY, res.data);
            commit(
              DocumentHistoryMutations.SET_IS_LOADING_DOCUMENT_HISTORY,
              false
            );
          }
          commit(
            DocumentHistoryMutations.SET_REQUEST_STATE_DOCUMENT_HISTORY,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DocumentHistoryMutations.SET_REQUEST_STATE_DOCUMENT_HISTORY,
            err
          );
          commit(
            DocumentHistoryMutations.SET_IS_LOADING_DOCUMENT_HISTORY,
            false
          );
          reject(err);
        });
    });
  },

  async getCreditHistoryByCreditId(
    { commit },
    {
      creditId,
      updateState = true,
    }: { creditId: number; updateState?: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(DocumentHistoryMutations.SET_IS_LOADING_DOCUMENT_HISTORY, true);
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/CreditHistory/GetCreditHistoryByCreditId",
          {
            params: {
              creditId: creditId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(
              DocumentHistoryMutations.SET_DOCUMENT_HISTORIES_LIST,
              res.data
            );
            commit(
              DocumentHistoryMutations.SET_IS_LOADING_DOCUMENT_HISTORY,
              false
            );
          }
          commit(
            DocumentHistoryMutations.SET_REQUEST_STATE_DOCUMENT_HISTORY,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DocumentHistoryMutations.SET_REQUEST_STATE_DOCUMENT_HISTORY,
            err
          );
          commit(
            DocumentHistoryMutations.SET_IS_LOADING_DOCUMENT_HISTORY,
            false
          );
          reject(err);
        });
    });
  },

  async getCreditHistoryById(
    { commit },
    {
      creditHistoryId,
      updateState = true,
    }: { creditHistoryId: number; updateState?: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(DocumentHistoryMutations.SET_IS_LOADING_DOCUMENT_HISTORY, true);
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/CreditHistory/GetCreditHistoryById",
          {
            params: {
              creditHistoryId: creditHistoryId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(DocumentHistoryMutations.SET_DOCUMENT_HISTORY, res.data);
            commit(
              DocumentHistoryMutations.SET_IS_LOADING_DOCUMENT_HISTORY,
              false
            );
          }
          commit(
            DocumentHistoryMutations.SET_REQUEST_STATE_DOCUMENT_HISTORY,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            DocumentHistoryMutations.SET_REQUEST_STATE_DOCUMENT_HISTORY,
            err
          );
          commit(
            DocumentHistoryMutations.SET_IS_LOADING_DOCUMENT_HISTORY,
            false
          );
          reject(err);
        });
    });
  },
};
