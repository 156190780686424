import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { HelpersMutations } from "./mutations";
import { IHelpersState } from "./states";

export const actions: ActionTree<IHelpersState, RootState> = {
  async getCivilityEnum({ commit }): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(HelpersMutations.SET_IS_LOADING_CIVILITY_LIST, true);
      let url = `${process.env.VUE_APP_API_ORISIS}Common/Helper/GetCivilityEnum`;
      await axios
        .get(url)
        .then((res) => {
          let civilityArray: any = [];
          for (let key in res.data) {
            civilityArray.push({ value: res.data[key], label: key });
          }
          commit(HelpersMutations.SET_IS_LOADING_CIVILITY_LIST, false);
          commit(HelpersMutations.SET_CIVILITY_LIST, civilityArray);
          commit(HelpersMutations.SET_REQUEST_STATE_HELPERS, res);
          resolve(civilityArray);
        })
        .catch((err) => {
          console.error(err);
          commit(HelpersMutations.SET_IS_LOADING_CIVILITY_LIST, false);
          commit(HelpersMutations.SET_REQUEST_STATE_HELPERS, err);
          reject(err);
        });
    });
  },

  // async getTypeLineEnum() {},

  // async getAffairStatusEnum() {},

  // async getSmsStatusEnum() {},

  async getAmountTypeEnum({ commit, rootGetters }): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(HelpersMutations.SET_IS_LOADING_AMOUNT_TYPE_LIST, true);
      let url = `${process.env.VUE_APP_API_ORISIS}Common/Helper/GetAmountTypeEnum`;
      await axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
        })
        .then((res) => {
          let amountTypeArray: any = [];
          for (let key in res.data) {
            amountTypeArray.push({ value: res.data[key], label: key });
          }
          commit(HelpersMutations.SET_IS_LOADING_AMOUNT_TYPE_LIST, false);
          commit(HelpersMutations.SET_AMOUNT_TYPE_LIST, amountTypeArray);
          commit(HelpersMutations.SET_REQUEST_STATE_HELPERS, res);
          resolve(amountTypeArray);
        })
        .catch((err) => {
          console.error(err);
          commit(HelpersMutations.SET_IS_LOADING_AMOUNT_TYPE_LIST, false);
          commit(HelpersMutations.SET_REQUEST_STATE_HELPERS, err);
          reject(err);
        });
    });
  },

  async getPaymentRequestDueEnum({ commit, rootGetters }): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(HelpersMutations.SET_IS_LOADING_PAYMENT_REQUEST_DUE_LIST, true);
      let url = `${process.env.VUE_APP_API_ORISIS}Common/Helper/GetPaymentRequestDueEnum`;
      await axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
        })
        .then((res) => {
          let paymentRequestDueArray: any = [];
          for (let key in res.data) {
            paymentRequestDueArray.push({ value: res.data[key], label: key });
          }
          commit(
            HelpersMutations.SET_IS_LOADING_PAYMENT_REQUEST_DUE_LIST,
            false
          );
          commit(
            HelpersMutations.SET_PAYMENT_REQUEST_DUE_LIST,
            paymentRequestDueArray
          );
          commit(HelpersMutations.SET_REQUEST_STATE_HELPERS, res);
          resolve(paymentRequestDueArray);
        })
        .catch((err) => {
          console.error(err);
          commit(
            HelpersMutations.SET_IS_LOADING_PAYMENT_REQUEST_DUE_LIST,
            false
          );
          commit(HelpersMutations.SET_REQUEST_STATE_HELPERS, err);
          reject(err);
        });
    });
  },

  async getDocumentTypeEnum({ commit, rootGetters }): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(HelpersMutations.SET_IS_LOADING_DOCUMENT_TYPE_LIST, true);
      let url = `${process.env.VUE_APP_API_ORISIS}Common/Helper/GetDocumentTypeEnum`;
      await axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
        })
        .then((res) => {
          let documentTypeArray: any = [];
          for (let key in res.data) {
            documentTypeArray.push({ value: res.data[key], label: key });
          }
          commit(HelpersMutations.SET_IS_LOADING_DOCUMENT_TYPE_LIST, false);
          commit(HelpersMutations.SET_DOCUMENT_TYPE_LIST, documentTypeArray);
          commit(HelpersMutations.SET_REQUEST_STATE_HELPERS, res);
          resolve(documentTypeArray);
        })
        .catch((err) => {
          console.error(err);
          commit(HelpersMutations.SET_IS_LOADING_DOCUMENT_TYPE_LIST, false);
          commit(HelpersMutations.SET_REQUEST_STATE_HELPERS, err);
          reject(err);
        });
    });
  },

  // async getDocumentStatusEnum() {},

  // async getDocumentLineTypeEnum() {},

  // async getMeasureUnitEnum() {},

  // async getFormsDetailsTypeEnum() {},

  async getProductTypeEnum({ commit, rootGetters }): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(HelpersMutations.SET_IS_LOADING_PRODUCT_TYPE_LIST, true);
      let url = `${process.env.VUE_APP_API_ORISIS}Common/Helper/GetProductTypeEnum`;
      await axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
        })
        .then((res) => {
          let productTypeArray: any = [];
          for (let key in res.data) {
            productTypeArray.push({ value: res.data[key], label: key });
          }
          commit(HelpersMutations.SET_IS_LOADING_PRODUCT_TYPE_LIST, false);
          commit(HelpersMutations.SET_PRODUCT_TYPE_LIST, productTypeArray);
          commit(HelpersMutations.SET_REQUEST_STATE_HELPERS, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(HelpersMutations.SET_IS_LOADING_PRODUCT_TYPE_LIST, false);
          commit(HelpersMutations.SET_REQUEST_STATE_HELPERS, err);
          reject(err);
        });
    });
  },

  async getLegalFormEnum({ commit, rootGetters }): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(HelpersMutations.SET_IS_LOADING_LEGAL_FORM_LIST, true);
      let url = `${process.env.VUE_APP_API_ORISIS}Common/Helper/GetLegalFormEnum`;
      await axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
        })
        .then((res) => {
          let legalFormArray: any = [];
          for (let key in res.data) {
            legalFormArray.push({ value: res.data[key], label: key });
          }
          commit(HelpersMutations.SET_IS_LOADING_LEGAL_FORM_LIST, false);
          commit(HelpersMutations.SET_LEGAL_FORM_LIST, legalFormArray);
          commit(HelpersMutations.SET_REQUEST_STATE_HELPERS, res);
          resolve(legalFormArray);
        })
        .catch((err) => {
          console.error(err);
          commit(HelpersMutations.SET_IS_LOADING_LEGAL_FORM_LIST, false);
          commit(HelpersMutations.SET_REQUEST_STATE_HELPERS, err);
          reject(err);
        });
    });
  },

  async getGuaranteeTypeEnum({ commit, rootGetters }): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(HelpersMutations.SET_IS_LOADING_GUARANTEE_TYPE_LIST, true);
      let url = `${process.env.VUE_APP_API_ORISIS}Common/Helper/GetGuaranteeTypeEnum`;
      await axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
        })
        .then((res) => {
          let guaranteeTypeArray: any = [];
          for (let key in res.data) {
            guaranteeTypeArray.push({ value: res.data[key], label: key });
          }
          commit(HelpersMutations.SET_IS_LOADING_GUARANTEE_TYPE_LIST, false);
          commit(HelpersMutations.SET_GUARANTEE_TYPE_LIST, guaranteeTypeArray);
          commit(HelpersMutations.SET_REQUEST_STATE_HELPERS, res);
          resolve(guaranteeTypeArray);
        })
        .catch((err) => {
          console.error(err);
          commit(HelpersMutations.SET_IS_LOADING_GUARANTEE_TYPE_LIST, false);
          commit(HelpersMutations.SET_REQUEST_STATE_HELPERS, err);
          reject(err);
        });
    });
  },

  // async getPaymentTypeEnum() {},

  // async getPaymentNatureEnum() {},

  // async getModuleSaleParameterEnum() {},

  async getNatureEnum({ commit, rootGetters }): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(HelpersMutations.SET_IS_LOADING_NATURE_LIST, true);
      let url = `${process.env.VUE_APP_API_ORISIS}Common/Helper/GetNatureEnum`;
      await axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
        })
        .then((res) => {
          let natureArray: any = [];
          for (let key in res.data) {
            natureArray.push({ value: res.data[key], label: key });
          }
          commit(HelpersMutations.SET_IS_LOADING_NATURE_LIST, false);
          commit(HelpersMutations.SET_NATURE_LIST, natureArray);
          commit(HelpersMutations.SET_REQUEST_STATE_HELPERS, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(HelpersMutations.SET_IS_LOADING_NATURE_LIST, false);
          commit(HelpersMutations.SET_REQUEST_STATE_HELPERS, err);
          reject(err);
        });
    });
  },

  // async getFooterTypeEnum() {},

  async getDocumentValidityEnum({ commit, rootGetters }): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(HelpersMutations.SET_IS_LOADING_DOCUMENT_VALIDITY_LIST, true);
      let url = `${process.env.VUE_APP_API_ORISIS}Common/Helper/GetDocumentValidityEnum`;
      await axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`,
          },
        })
        .then((res) => {
          let documentValidityArray: any = [];
          for (let key in res.data) {
            documentValidityArray.push({ value: res.data[key], label: key });
          }
          commit(HelpersMutations.SET_IS_LOADING_DOCUMENT_VALIDITY_LIST, false);
          commit(
            HelpersMutations.SET_DOCUMENT_VALIDITY_LIST,
            documentValidityArray
          );
          commit(HelpersMutations.SET_REQUEST_STATE_HELPERS, res);
          resolve(documentValidityArray);
        })
        .catch((err) => {
          console.error(err);
          commit(HelpersMutations.SET_IS_LOADING_DOCUMENT_VALIDITY_LIST, false);
          commit(HelpersMutations.SET_REQUEST_STATE_HELPERS, err);
          reject(err);
        });
    });
  },

  // async getDurationEnum() {},

  // async getHoldbackStatusEnum() {},

  // async getStorageLocationTypeEnum() {},

  // async getDeliveryFormStatusEnum() {},

  // async getOrderFormStatusEnum() {},

  // async getFormsDetailsTypeEnum() {},

  // async getReceiptFormStatusEnum() {},

  // async getInventoryStatusEnum() {},

  // async getDirectionMouvementStatusEnum() {},

  // async getDestockingTypeEnum () {},

  // async getRestockingTypeEnum() {},

  // async getDeliveryTypeEnum() {},

  // async getFunctionAvailableEnum() {},

  // async getInvoiceProviderStatusEnum() {},

  // async getOperationEnum() {},

  // async getCreditProviderStatusEnum() {},

  // async getLeaseDurationTypeEnum() {},

  // async getReportingStatusEnum() {},

  // async getQuantificationTypeEnum() {},

  // async getTypeFieldEnum() {},

  // async getDocumentApplicationTypeEnum() {},
};
