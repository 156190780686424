import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { CompanyRequestDto } from '@/../Api'
import { GetterTree } from "vuex";
import RootState from '@/store/modules/rootState'
import {  ICompanyState } from "./states";

export const getters: GetterTree<ICompanyState, RootState> = {
    companiesList: (state): CompanyRequestDto[] => state.companiesList,
    companiesListArchived: (state): CompanyRequestDto[] => state.companiesListArchived,
    company     : (state): CompanyRequestDto => state.company,
    
    companiesOptions : (state): any => state.companiesList.map(company => ({ label : company.name, id : company.id })),
    // COMMON
    isLoadingCompaniesList   : (state): boolean => state.isLoadingCompaniesList,
    isLoadingCompaniesListArchived : (state): boolean => state.isLoadingCompaniesListArchived,
    isLoadingCompany   : (state): boolean => state.isLoadingCompany,
    isCreatingCompany  : (state): boolean => state.isCreatingCompany,
    isUpdatingCompany  : (state): boolean => state.isUpdatingCompany,
    isDeletingCompany  : (state): boolean => state.isDeletingCompany,
    requestStateCompany: (state): IRequestState => state.requestStateCompany,
    isArchivingCompany : (state): boolean => state.isArchivingCompany,
}