import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { SaleCategoryRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { ISaleCategoryState } from "./states";

export enum SaleCategoryMutations {
    SET_SALE_CATEGORIES_LIST = "SET_SALE_CATEGORIES_LIST",
    SET_SALE_CATEGORIES_TREE_LIST = "SET_SALE_CATEGORIES_TREE_LIST",
    SET_SALE_CATEGORIES_LIST_ARCHIVED = "SET_SALE_CATEGORIES_LIST_ARCHIVED",
    SET_SALE_CATEGORY = "SET_SALE_CATEGORY",

    // COMMON
    SET_IS_LOADING_SALE_CATEGORIES_LIST = "SET_IS_LOADING_SALE_CATEGORIES_LIST",
    SET_IS_LOADING_SALE_CATEGORIES_TREE_LIST = "SET_IS_LOADING_SALE_CATEGORIES_TREE_LIST",
    SET_IS_LOADING_SALE_CATEGORIES_LIST_ARCHIVED = "SET_IS_LOADING_SALE_CATEGORIES_LIST_ARCHIVED",
    SET_IS_LOADING_SALE_CATEGORY = "SET_IS_LOADING_SALE_CATEGORY",
    SET_IS_CREATING_SALE_CATEGORY = "SET_IS_CREATING_SALE_CATEGORY",
    SET_IS_UPDATING_SALE_CATEGORY = "SET_IS_UPDATING_SALE_CATEGORY",
    SET_IS_DELETING_SALE_CATEGORY = "SET_IS_DELETING_SALE_CATEGORY",
    SET_IS_ARCHIVING_SALE_CATEGORY = "SET_IS_ARCHIVING_SALE_CATEGORY",
    SET_REQUEST_STATE_SALE_CATEGORY = "SET_REQUEST_STATE_SALE_CATEGORY",
}

export const mutations: MutationTree<ISaleCategoryState> = {
    [SaleCategoryMutations.SET_SALE_CATEGORIES_LIST_ARCHIVED]: (state, payload: SaleCategoryRequestDto[]) => {
        state.saleCategoriesListArchived = payload;
    }, 
    [SaleCategoryMutations.SET_SALE_CATEGORIES_LIST]: (state, payload: SaleCategoryRequestDto[]) => {
        state.saleCategoriesList = payload;
    },
    [SaleCategoryMutations.SET_SALE_CATEGORIES_TREE_LIST]: (state, payload: any[]) => {
        state.saleCategoriesTreeList = payload;
    },
    [SaleCategoryMutations.SET_SALE_CATEGORY]: (state, payload: SaleCategoryRequestDto) => {
        state.saleCategory = payload;
    },

    //COMMON
    [SaleCategoryMutations.SET_IS_LOADING_SALE_CATEGORIES_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingSaleCategoriesListArchived = payload;
    },
    [SaleCategoryMutations.SET_IS_LOADING_SALE_CATEGORIES_TREE_LIST]: (state, payload: boolean) => {
        state.isLoadingSaleCategoriesTreeList = payload;
    },
    [SaleCategoryMutations.SET_IS_LOADING_SALE_CATEGORIES_LIST]: (state, payload: boolean) => {
        state.isLoadingSaleCategoriesList = payload;
    },
    [SaleCategoryMutations.SET_IS_LOADING_SALE_CATEGORY]: (state, payload: boolean) => {
        state.isLoadingSaleCategory = payload;
    },
    [SaleCategoryMutations.SET_IS_CREATING_SALE_CATEGORY]: (state, payload: boolean) => {
        state.isCreatingSaleCategory = payload;
    },
    [SaleCategoryMutations.SET_IS_UPDATING_SALE_CATEGORY]: (state, payload: boolean) => {
        state.isUpdatingSaleCategory = payload;
    },
    [SaleCategoryMutations.SET_IS_DELETING_SALE_CATEGORY]: (state, payload: boolean) => {
        state.isDeletingSaleCategory = payload;
    },
    [SaleCategoryMutations.SET_IS_ARCHIVING_SALE_CATEGORY]: (state, payload: boolean) => {
        state.isArchivingSaleCategory = payload;
    },
    [SaleCategoryMutations.SET_REQUEST_STATE_SALE_CATEGORY]: (state, payload: IRequestState) => {
        state.requestStateSaleCategory = payload;
    },
}