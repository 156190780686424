import { update } from "@syncfusion/ej2-vue-inplace-editor";
import { ReceiptFormRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { ReceiptFormProviderMutations } from "./mutations";
import { IReceiptFormProviderState } from "./states";
import { sha256 } from "js-sha256";
import dayjs from "dayjs";
import { EDocumentStatus } from "@/types/api-orisis/enums/enums";
import { DocumentMutations } from "../../invoices/document/mutations";

import { FileMutations } from "../../ged/file/mutations";
import { FolderMutations } from "../../ged/folder/mutations";
import { Timezone } from "@syncfusion/ej2-schedule";

let timezoneSchedule = new Timezone();

let startDate = new Date(
  dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
).toISOString();
let endDate = new Date(
  dayjs().endOf("month").format("YYYY-MM-DD")
).toISOString();

export const actions: ActionTree<IReceiptFormProviderState, RootState> = {
  getReceiptForms(
    { commit, rootGetters },
    {
      affairId,
      dateFrom = null,
      dateTo = null,
      companyId,
      isProvider = false,
      updateState = true,
      route,
    }: {
      affairId?: number;
      dateFrom?: Date;
      dateTo?: Date;
      companyId?: number;
      isProvider?: boolean;
      updateState?: boolean;
      route: string;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        switch (route) {
          case "company":
            commit(
              ReceiptFormProviderMutations.SET_IS_LOADING_RECEIPT_FORMS_LIST_BY_COMPANY,
              true
            );
            break;
          case "affair":
            commit(
              ReceiptFormProviderMutations.SET_IS_LOADING_RECEIPT_FORMS_LIST_BY_AFFAIR,
              true
            );
            break;
          default:
            commit(
              ReceiptFormProviderMutations.SET_IS_LOADING_RECEIPT_FORM_LIST,
              true
            );
            break;
        }
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/ReceiptForm/GetReceiptForms`;
      axios
        .get(url, {
          params: {
            dateFrom: dateFrom ? dayjs(dateFrom).format("YYYY-MM-DD") : null,
            dateTo: dateTo
              ? dayjs(dateTo)
                  .hour(23)
                  .minute(59)
                  .second(59)
                  .format("YYYY-MM-DD HH:mm:ss")
              : null,
            companyId: companyId,
            affairId: affairId,
            isProvider: isProvider,
          },
        })
        .then((res) => {
          let list = res.data.map((item) => {
            return {
              ...item,
              documentDate: item.documentDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(item.documentDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              dateGenerated: item.dateGenerated
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(item.dateGenerated)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              receiptDate: item.receiptDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(item.receiptDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
            };
          });
          if (updateState) {
            switch (route) {
              case "company":
                commit(
                  ReceiptFormProviderMutations.SET_IS_LOADING_RECEIPT_FORMS_LIST_BY_COMPANY,
                  false
                );
                commit(
                  ReceiptFormProviderMutations.SET_RECEIPT_FORM_LIST_BY_COMPANY,
                  list
                );
                break;
              case "affair":
                commit(
                  ReceiptFormProviderMutations.SET_IS_LOADING_RECEIPT_FORMS_LIST_BY_AFFAIR,
                  false
                );
                commit(
                  ReceiptFormProviderMutations.SET_RECEIPT_FORM_LIST_BY_AFFAIR,
                  list
                );
                break;
              default:
                commit(
                  ReceiptFormProviderMutations.SET_IS_LOADING_RECEIPT_FORM_LIST,
                  false
                );
                commit(
                  ReceiptFormProviderMutations.SET_RECEIPT_FORMS_LIST,
                  list
                );
                break;
            }
          }
          commit(
            ReceiptFormProviderMutations.SET_REQUEST_STATE_RECEIPT_FORM,
            res
          );
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            switch (route) {
              case "company":
                commit(
                  ReceiptFormProviderMutations.SET_IS_LOADING_RECEIPT_FORMS_LIST_BY_COMPANY,
                  false
                );
                break;
              case "affair":
                commit(
                  ReceiptFormProviderMutations.SET_IS_LOADING_RECEIPT_FORMS_LIST_BY_AFFAIR,
                  false
                );
                break;
              default:
                commit(
                  ReceiptFormProviderMutations.SET_IS_LOADING_RECEIPT_FORM_LIST,
                  false
                );
                break;
            }
          }
          commit(
            ReceiptFormProviderMutations.SET_REQUEST_STATE_RECEIPT_FORM,
            err
          );
          reject(err);
        });
    });
  },

  getReceiptFormById(
    { commit, dispatch },
    {
      receiptFormId,
      updateState = true,
    }: { receiptFormId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(ReceiptFormProviderMutations.SET_IS_LOADING_RECEIPT_FORM, true);
        commit(
          DocumentMutations.SET_LOADING_MSG,
          "Chargement des détails du bon de réception..."
        );
        commit(
          ReceiptFormProviderMutations.SET_RECEIPT_FORM_TAB_ACTIVE,
          "receipt-form-provider-edit"
        );
        commit(ReceiptFormProviderMutations.SET_INITIAL_RECEIPT_FORM, null);
      }
      axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/ReceiptForm/GetReceiptFormById`,
          {
            params: {
              receiptFormId: receiptFormId,
            },
          }
        )
        .then(async (res) => {
          if (updateState) {
            await dispatch("getReceiptFormDetailsByReceiptFormId", {
              receiptFormId: receiptFormId,
            });
            dispatch("getReceiptFormOptionByReceiptFormId", {
              receiptFormId: receiptFormId,
            });
            dispatch("getReceiptFormHistoriesByReceiptFormId", {
              receiptFormProviderId: receiptFormId,
            });
            if (res.data.orderFormId) {
              dispatch("getInitialOrderFormById", {
                orderFormId: res.data.orderFormId,
              });
            }
            commit(
              ReceiptFormProviderMutations.SET_IS_LOADING_RECEIPT_FORM,
              false
            );
            if (res.data.status == 3) {
              dispatch(
                "changeReceiptFormProviderTabActive",
                "receipt-form-provider-pdf"
              );
            }
            if (res.data.status == 3 && res.data.path) {
              commit(
                ReceiptFormProviderMutations.SET_RECEIPT_FORM_PDF,
                res.data.path
              );
            }
          }
          commit(ReceiptFormProviderMutations.SET_RECEIPT_FORM, res.data); //, documentTvas: []
          commit(
            ReceiptFormProviderMutations.SET_REQUEST_STATE_RECEIPT_FORM,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ReceiptFormProviderMutations.SET_REQUEST_STATE_RECEIPT_FORM,
            err
          );
          if (updateState) {
            commit(
              ReceiptFormProviderMutations.SET_IS_LOADING_RECEIPT_FORM,
              false
            );
          }
          reject(err);
        });
    });
  },

  GetLastReceiptFormReference(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/ReceiptForm/GetLastDocumentReference"
        )
        .then((res) => {
          if (updateState) {
            commit(
              ReceiptFormProviderMutations.SET_LAST_RECEIPT_FORM_REFERENCE,
              res.data
            );
          }
          commit(
            ReceiptFormProviderMutations.SET_REQUEST_STATE_RECEIPT_FORM,
            res
          );
          resolve(res);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ReceiptFormProviderMutations.SET_REQUEST_STATE_RECEIPT_FORM,
            err
          );
          reject(err);
        });
    });
  },

  getReceiptFormEmpty(
    { commit, rootGetters, dispatch },
    {
      affairId = null,
      providerId = null,
      userId = rootGetters.connectedUser.id,
      customerId = null,
      storageLocationId = null,
      collaboratorId = null,
      updateState = true,
    }: {
      affairId?: number;
      providerId: number;
      userId: number;
      customerId?: number;
      storageLocationId?: number;
      collaboratorId?: number;
      updateState?: boolean;
    }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (updateState) {
        commit(ReceiptFormProviderMutations.SET_IS_CREATING_RECEIPT_FORM, true);
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/ReceiptForm/GetReceiptFormEmpty",
          {
            params: {
              affairId: affairId,
              providerId: providerId,
              userId: userId,
              customerId: customerId,
              collaboratorId: collaboratorId,
              storageLocationId: storageLocationId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            if (res.data.status == 3) {
              let receiptFormProviderFilters =
                rootGetters.userParametersList.find(
                  (elem) => elem.key == "receiptFormProviderFilters"
                );
              if (receiptFormProviderFilters) {
                dispatch("getReceiptForms", {
                  dateFrom: JSON.parse(receiptFormProviderFilters.value)
                    .dateRange.startDate,
                  dateTo: JSON.parse(receiptFormProviderFilters.value).dateRange
                    .endDate,
                });
              } else {
                dispatch("setFilter", {
                  name: "receiptFormProviderFilters",
                  filter: {
                    dateRangeType: 7,
                    dateRange: { startDate: startDate, endDate: endDate },
                  },
                });
                dispatch("getReceiptForms", {
                  dateFrom: startDate,
                  dateTo: endDate,
                });
              }
            }
            commit(
              ReceiptFormProviderMutations.SET_IS_CREATING_RECEIPT_FORM,
              false
            );
          }
          commit(
            ReceiptFormProviderMutations.SET_REQUEST_STATE_RECEIPT_FORM,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ReceiptFormProviderMutations.SET_REQUEST_STATE_RECEIPT_FORM,
            err
          );
          if (updateState) {
            commit(
              ReceiptFormProviderMutations.SET_IS_CREATING_RECEIPT_FORM,
              false
            );
          }
          reject(err);
        });
    });
  },

  getReceiptFormOrderFormId(
    { commit, rootGetters, dispatch },
    {
      orderFormProviderId,
      updateState = true,
    }: { orderFormProviderId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (updateState) {
        commit(ReceiptFormProviderMutations.SET_IS_CREATING_RECEIPT_FORM, true);
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/ReceiptForm/GetReceiptFormOrderFormId",
          {
            params: {
              orderFormId: orderFormProviderId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            if (res.data.status == 3) {
              let receiptFormProviderFilters =
                rootGetters.userParametersList.find(
                  (elem) => elem.key == "receiptFormProviderFilters"
                );
              if (receiptFormProviderFilters) {
                dispatch("getReceiptForms", {
                  dateFrom: JSON.parse(receiptFormProviderFilters.value)
                    .dateRange.startDate,
                  dateTo: JSON.parse(receiptFormProviderFilters.value).dateRange
                    .endDate,
                });
              } else {
                dispatch("setFilter", {
                  name: "receiptFormProviderFilters",
                  filter: {
                    dateRangeType: 7,
                    dateRange: { startDate: startDate, endDate: endDate },
                  },
                });
                dispatch("getReceiptForms", {
                  dateFrom: startDate,
                  dateTo: endDate,
                });
              }
            }
            commit(
              ReceiptFormProviderMutations.SET_IS_CREATING_RECEIPT_FORM,
              false
            );
          }
          commit(
            ReceiptFormProviderMutations.SET_REQUEST_STATE_RECEIPT_FORM,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ReceiptFormProviderMutations.SET_REQUEST_STATE_RECEIPT_FORM,
            err
          );
          if (updateState) {
            commit(
              ReceiptFormProviderMutations.SET_IS_CREATING_RECEIPT_FORM,
              false
            );
          }
          reject(err);
        });
    });
  },

  createReceiptForm(
    { commit, rootGetters, dispatch },
    { receiptFormProvider }: { receiptFormProvider: ReceiptFormRequestDto }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(ReceiptFormProviderMutations.SET_IS_CREATING_RECEIPT_FORM, true);
      axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Purchase/ReceiptForm/CreateReceiptForm`,
          receiptFormProvider
        )
        .then((res) => {
          let receiptFormProviderFilters = rootGetters.userParametersList.find(
            (elem) => elem.key == "receiptFormProviderFilters"
          );
          if (receiptFormProviderFilters) {
            dispatch("getReceiptForms", {
              dateFrom: JSON.parse(receiptFormProviderFilters.value).dateRange
                .startDate,
              dateTo: JSON.parse(receiptFormProviderFilters.value).dateRange
                .endDate,
            });
          } else {
            dispatch("setFilter", {
              name: "receiptFormProviderFilters",
              filter: {
                dateRangeType: 7,
                dateRange: { startDate: startDate, endDate: endDate },
              },
            });
            dispatch("getReceiptForms", {
              dateFrom: startDate,
              dateTo: endDate,
            });
          }
          commit(
            ReceiptFormProviderMutations.SET_REQUEST_STATE_RECEIPT_FORM,
            res
          );
          commit(
            ReceiptFormProviderMutations.SET_IS_CREATING_RECEIPT_FORM,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ReceiptFormProviderMutations.SET_REQUEST_STATE_RECEIPT_FORM,
            err
          );
          commit(
            ReceiptFormProviderMutations.SET_IS_CREATING_RECEIPT_FORM,
            false
          );
          reject(err);
        });
    });
  },

  createReceiptFormFromPreviousReceiptForm(
    { rootGetters },
    {
      receiptFormId,
      userId = rootGetters.connectedUser.id,
    }: { receiptFormId: number; userId: number }
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Purchase/ReceiptForm/CreateReceiptFormFromPreviousReceiptForm`,
          {},
          {
            params: {
              receiptFormId: receiptFormId,
              userId: userId,
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },

  updateReceiptForm(
    { commit, rootGetters, dispatch },
    {
      receiptFormProvider,
      updateState = true,
    }: { receiptFormProvider: ReceiptFormRequestDto; updateState: boolean }
  ) {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(ReceiptFormProviderMutations.SET_IS_UPDATING_RECEIPT_FORM, true);
      }
      axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/ReceiptForm/UpdateReceiptForm`,
          { ...receiptFormProvider, currencyId: 1 }
        )
        .then((res) => {
          let dataReceiptFormProvider = res.data.data;
          commit(
            ReceiptFormProviderMutations.SET_RECEIPT_FORM,
            dataReceiptFormProvider
          );

          if (updateState) {
            let receiptFormProviderFilters =
              rootGetters.userParametersList.find(
                (elem) => elem.key == "receiptFormProviderFilters"
              );
            if (receiptFormProviderFilters) {
              dispatch("getReceiptForms", {
                dateFrom: JSON.parse(receiptFormProviderFilters.value).dateRange
                  .startDate,
                dateTo: JSON.parse(receiptFormProviderFilters.value).dateRange
                  .endDate,
              });
            } else {
              dispatch("setFilter", {
                name: "receiptFormProviderFilters",
                filter: {
                  dateRangeType: 7,
                  dateRange: { startDate: startDate, endDate: endDate },
                },
              });
              dispatch("getReceiptForms", {
                dateFrom: startDate,
                dateTo: endDate,
              });
            }
          } else {
            rootGetters.receiptFormProvidersList.splice(
              rootGetters.receiptFormProvidersList.findIndex(
                (elem) => elem.id == receiptFormProvider.id
              ),
              1,
              dataReceiptFormProvider
            );
            commit(
              ReceiptFormProviderMutations.SET_RECEIPT_FORMS_LIST,
              rootGetters.receiptFormProvidersList
            );
          }

          commit(
            ReceiptFormProviderMutations.SET_REQUEST_STATE_RECEIPT_FORM,
            res
          );
          if (updateState) {
            commit(
              ReceiptFormProviderMutations.SET_IS_UPDATING_RECEIPT_FORM,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ReceiptFormProviderMutations.SET_REQUEST_STATE_RECEIPT_FORM,
            err
          );
          if (updateState) {
            commit(
              ReceiptFormProviderMutations.SET_IS_UPDATING_RECEIPT_FORM,
              false
            );
          }
          reject(err);
        });
    });
  },

  uploadPathReceiptForm(
    { commit, rootGetters },
    { receiptFormProviderId, path }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(ReceiptFormProviderMutations.SET_IS_UPDATING_RECEIPT_FORM, true);
      axios
        .put(
          `${
            process.env.VUE_APP_API_ORISIS
          }Purchase/ReceiptForm/UploadPathDocument?receiptFormId=${receiptFormProviderId}&path=${encodeURIComponent(
            path
          )}`
        )
        .then((res) => {
          let dataReceiptFormProvider = res.data;
          commit(
            ReceiptFormProviderMutations.SET_RECEIPT_FORM,
            dataReceiptFormProvider
          );
          rootGetters.receiptFormProvidersList.splice(
            rootGetters.receiptFormProvidersList.findIndex(
              (elem) => elem.id == receiptFormProviderId
            ),
            1,
            dataReceiptFormProvider
          );
          commit(
            ReceiptFormProviderMutations.SET_RECEIPT_FORMS_LIST,
            rootGetters.receiptFormProvidersList
          );
          commit(
            ReceiptFormProviderMutations.SET_REQUEST_STATE_RECEIPT_FORM,
            res
          );
          commit(
            ReceiptFormProviderMutations.SET_IS_UPDATING_RECEIPT_FORM,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ReceiptFormProviderMutations.SET_REQUEST_STATE_RECEIPT_FORM,
            err
          );
          commit(
            ReceiptFormProviderMutations.SET_IS_UPDATING_RECEIPT_FORM,
            false
          );
          reject(err);
        });
    });
  },

  uploadFileReceiptForm(
    { commit, rootGetters, dispatch },
    { formData, receiptFormProviderId }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(FileMutations.SET_IS_CREATING_FILE, true);
      axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/ReceiptForm/UploadFile?receiptFormId=${receiptFormProviderId}`,
          formData
        )
        .then(async (res) => {
          if (!rootGetters.receiptFormProvider.folderId) {
            await dispatch("getReceiptFormById", {
              receiptFormProviderId: receiptFormProviderId,
              updateState: false,
            });
          }
          commit(FolderMutations.SET_FOLDER, rootGetters.folder);
          commit(FileMutations.SET_REQUEST_STATE_FILE, res);
          commit(FileMutations.SET_IS_CREATING_FILE, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(FileMutations.SET_REQUEST_STATE_FILE, err);
          commit(FileMutations.SET_IS_CREATING_FILE, false);
          reject(err);
        });
    });
  },

  updateReceiptFormTagReceiptForm(
    { commit, rootGetters },
    { receiptFormProviderIds, receiptFormProviderTagIds }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/ReceiptForm/UpdateReceiptFormTagReceiptForm`,
          {
            receiptFormIds: receiptFormProviderIds,
            receiptFormTagIds: receiptFormProviderTagIds,
          }
        )
        .then((res) => {
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DocumentMutations.SET_REQUEST_STATE_DOCUMENT, err);
          reject(err);
        });
    });
  },

  deleteReceiptFormProviders(
    { commit, rootGetters, dispatch },
    { receiptFormProviderIds }: { receiptFormProviderIds: number[] }
  ) {
    return new Promise<void>((resolve, reject) => {
      commit(ReceiptFormProviderMutations.SET_IS_DELETING_RECEIPT_FORM, true);
      axios
        .delete(
          `${process.env.VUE_APP_API_ORISIS}Purchase/ReceiptForm/Delete`,
          {
            data: receiptFormProviderIds,
          }
        )
        .then((res) => {
          let receiptFormProviderFilters = rootGetters.userParametersList.find(
            (elem) => elem.key == "receiptFormProviderFilters"
          );
          if (receiptFormProviderFilters) {
            dispatch("getReceiptForms", {
              dateFrom: JSON.parse(receiptFormProviderFilters.value).dateRange
                .startDate,
              dateTo: JSON.parse(receiptFormProviderFilters.value).dateRange
                .endDate,
            });
          } else {
            dispatch("setFilter", {
              name: "receiptFormProviderFilters",
              filter: {
                dateRangeType: 7,
                dateRange: { startDate: startDate, endDate: endDate },
              },
            });
            dispatch("getReceiptForms", {
              dateFrom: startDate,
              dateTo: endDate,
            });
          }
          commit(
            ReceiptFormProviderMutations.SET_REQUEST_STATE_RECEIPT_FORM,
            res
          );
          commit(
            ReceiptFormProviderMutations.SET_IS_DELETING_RECEIPT_FORM,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ReceiptFormProviderMutations.SET_REQUEST_STATE_RECEIPT_FORM,
            err
          );
          commit(
            ReceiptFormProviderMutations.SET_IS_DELETING_RECEIPT_FORM,
            false
          );
          reject(err);
        });
    });
  },

  //--------------------------------------------------------------------------------------------------------------------//

  generateReceiptFormProviderPDF({ commit, rootGetters, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit(
        ReceiptFormProviderMutations.SET_IS_LOADING_RECEIPT_FORM_PDF,
        true
      );
      axios
        .post(`${process.env.VUE_APP_PDF_GENERATOR_URL}generate`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(async (res) => {
          // Si le document est finalisé
          if (rootGetters.receiptFormProvider.status == 3) {
            await dispatch("uploadPathReceiptForm", {
              receiptFormProviderId: rootGetters.receiptFormProvider.id,
              path: res.data,
            });
          }

          commit(ReceiptFormProviderMutations.SET_RECEIPT_FORM_PDF, res.data);
          commit(
            ReceiptFormProviderMutations.SET_REQUEST_STATE_RECEIPT_FORM,
            res
          );
          commit(
            ReceiptFormProviderMutations.SET_IS_LOADING_RECEIPT_FORM_PDF,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ReceiptFormProviderMutations.SET_REQUEST_STATE_RECEIPT_FORM,
            err
          );
          commit(
            ReceiptFormProviderMutations.SET_IS_LOADING_RECEIPT_FORM_PDF,
            false
          );
          reject(err);
        });
    });
  },

  changeReceiptFormProviderTabActive(
    { commit, dispatch },
    tabToActivate: string
  ) {
    commit(
      ReceiptFormProviderMutations.SET_RECEIPT_FORM_TAB_ACTIVE,
      tabToActivate
    );
    dispatch("setupHeaderReceiptFormProvider");
  },

  setupHeaderReceiptFormProvider({ rootGetters }) {
    let document = rootGetters.receiptFormOption;
    let header = rootGetters.getReceiptFormProviderHeader;
    header["previousQuantitiesReceived"].display =
      rootGetters.receiptFormProvider > 0 &&
      !document.hidePreviousQuantitiesReceived
        ? true
        : false;
    header["orderedQuantity"].display = !document.hideOrderedQuantityColumn;
    header["reference"].display = document.showReferenceColumn;
    header["previousQuantitiesReceived"].display =
      !document.hidePreviousQuantitiesReceived;
    header["index"].display = !document.hideIndexColumn;
    header["unitId"].display = !document.hideUnitColumn;
    header["receivedQuantity"].display = !document.hideReceivedQuantityColumn;
  },

  getReceiptFormProviderPDFByReceiptFormProviderId(
    { commit, rootGetters },
    receiptFormProviderId
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let urlDocumentPdf =
        "https://ged.orisis.fr/" +
        sha256(rootGetters.document.institutionName) +
        "/import/Devis/" +
        rootGetters.document.documentReference +
        ".pdf";
      if (rootGetters.document.usedUrlGed) {
        commit(
          ReceiptFormProviderMutations.SET_RECEIPT_FORM_PDF,
          urlDocumentPdf
        );
        commit(
          ReceiptFormProviderMutations.SET_IS_LOADING_RECEIPT_FORM_PDF,
          false
        );
      } else {
        commit(
          ReceiptFormProviderMutations.SET_IS_LOADING_RECEIPT_FORM_PDF,
          true
        );
        axios
          .get(`${process.env.VUE_APP_API_ORISIS}Billing/Quote/GenerateQuote`, {
            params: {
              quoteId: receiptFormProviderId,
            },
          })
          .then((res) => {
            commit(ReceiptFormProviderMutations.SET_RECEIPT_FORM_PDF, res.data);
            commit(
              ReceiptFormProviderMutations.SET_REQUEST_STATE_RECEIPT_FORM_PDF,
              res
            );
            commit(
              ReceiptFormProviderMutations.SET_IS_LOADING_RECEIPT_FORM_PDF,
              false
            );
            resolve(res.data);
          })
          .catch((err) => {
            console.error(err);
            commit(
              ReceiptFormProviderMutations.SET_REQUEST_STATE_RECEIPT_FORM_PDF,
              err
            );
            commit(
              ReceiptFormProviderMutations.SET_IS_LOADING_RECEIPT_FORM_PDF,
              false
            );
            reject(err);
          });
      }
    });
  },
};
