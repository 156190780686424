import { MutationTree } from "vuex";
import { IReceiptFormOptionState } from "./states";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { ReceiptFormOptionRequestDto } from "Api";

export const enum ReceiptFormOptionMutations {
  SET_RECEIPT_FORM_OPTION = "SET_RECEIPT_FORM_OPTION",
  SET_IS_LOADING_RECEIPT_FORM_OPTION = "SET_IS_LOADING_RECEIPT_FORM_OPTION",
  SET_REQUEST_STATE_RECEIPT_FORM_OPTION = "SET_REQUEST_STATE_RECEIPT_FORM_OPTION",
}

export const mutations: MutationTree<IReceiptFormOptionState> = {
  [ReceiptFormOptionMutations.SET_RECEIPT_FORM_OPTION](
    state,
    payload: ReceiptFormOptionRequestDto
  ) {
    state.receiptFormOption = payload;
  },
  [ReceiptFormOptionMutations.SET_IS_LOADING_RECEIPT_FORM_OPTION](
    state,
    payload: boolean
  ) {
    state.isLoadingReceiptFormOption = payload;
  },
  [ReceiptFormOptionMutations.SET_REQUEST_STATE_RECEIPT_FORM_OPTION](
    state,
    payload: IRequestState
  ) {
    state.requestStateReceiptFormOption = payload;
  },
};
