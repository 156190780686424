import { ICreditOptionState } from "./states";
import { Module } from "vuex";
import { CreditOptionRequestDto } from "@/../Api";
import RootState from "@/store/modules/rootState";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

const state: ICreditOptionState = {
  creditOption: <CreditOptionRequestDto>{},
  isLoadingCreditOption: false,
  requestStateCreditOption: <IRequestState>{},
};

export const CreditOptionModule: Module<ICreditOptionState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
