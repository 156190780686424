import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { BankAccountRequestDto } from "Api";
import { GetterTree } from "vuex";
import RootState from '@/store/modules/rootState'
import {  IBankAccountState } from "./states";
 
export const getters: GetterTree<IBankAccountState, RootState> = {
    bankAccountsList: (state): BankAccountRequestDto[] => state.bankAccountsList,
    bankAccountsListForm: (state): BankAccountRequestDto[] => state.bankAccountsListForm,
    bankAccountsListArchived: (state): BankAccountRequestDto[] => state.bankAccountsListArchived,
    bankAccount     : (state): BankAccountRequestDto => state.bankAccount,
    
    // COMMON
    isLoadingBankAccountsList   : (state): boolean => state.isLoadingBankAccountsList,
    isLoadingBankAccountsListArchived : (state): boolean => state.isLoadingBankAccountsListArchived,
    isLoadingBankAccount : (state): boolean => state.isLoadingBankAccount,
    isCreatingBankAccount  : (state): boolean => state.isCreatingBankAccount,
    isUpdatingBankAccount : (state): boolean => state.isUpdatingBankAccount,
    isDeletingBankAccount : (state): boolean => state.isDeletingBankAccount,
    requestStateBankAccount: (state): IRequestState => state.requestStateBankAccount,
    isArchivingBankAccount : (state): boolean => state.isArchivingBankAccount,
}