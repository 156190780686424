import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { IHoldback } from "@/types/api-orisis/interfaces/invoices/holdback/IHoldback";
import { IHoldbackState } from "./states";
import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { HoldbackRequestDto } from "Api";

export const getters: GetterTree<IHoldbackState, RootState> = {
    holdbacksList: (state): IHoldback[] => state.holdbacksList,
    holdback: (state): HoldbackRequestDto => state.holdback,

    isUpdatingHoldback: (state): boolean => state.isUpdatingHoldback,
    
    // COMMON
    isLoadingHoldbacksList: (state): boolean => state.isLoadingHoldbacksList,
    isLoadingHoldback: (state): boolean => state.isLoadingHoldback,
    requestStateHoldback: (state): IRequestState => state.requestStateHoldback,
};