import { IFileState } from "./states"
import { Module } from "vuex"
import { FileRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IFileState = {
    filesList         : Array<FileRequestDto>(),
    filesListArchived : Array<FileRequestDto>(),
    file              : <FileRequestDto>{},
    isLoadingFilesList     : false,
    isLoadingFilesListArchived     : false,
    isLoadingFile     : false,
    isCreatingFile    : false,
    isUpdatingFile    : false,
    isDeletingFile    : false,
    isArchivingFile    : false,
    requestStateFile: <IRequestState>{},
}

export const FileModule: Module<IFileState, RootState> = {
    state, 
    getters,
    mutations,
    actions
} 