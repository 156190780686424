import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { QuoteDownPaymentsRequestDto } from "Api";
import { GetterTree } from "vuex";
import RootState from '@/store/modules/rootState'
import {  IQuoteDownPaymentsRequestState } from "./states";

export const getters: GetterTree<IQuoteDownPaymentsRequestState, RootState> = {
    quoteDownPaymentsRequestsList: (state): QuoteDownPaymentsRequestDto[] => state.quoteDownPaymentsRequestsList,
    quoteDownPaymentsRequestsListArchived: (state): QuoteDownPaymentsRequestDto[] => state.quoteDownPaymentsRequestsListArchived,
    quoteDownPaymentsRequest     : (state): QuoteDownPaymentsRequestDto => state.quoteDownPaymentsRequest,
    
    // COMMON
    isLoadingQuoteDownPaymentsRequestsList   : (state): boolean => state.isLoadingQuoteDownPaymentsRequestsList,
    isLoadingQuoteDownPaymentsRequestsListArchived : (state): boolean => state.isLoadingQuoteDownPaymentsRequestsListArchived,
    isLoadingQuoteDownPaymentsRequest : (state): boolean => state.isLoadingQuoteDownPaymentsRequest,
    isCreatingQuoteDownPaymentsRequest  : (state): boolean => state.isCreatingQuoteDownPaymentsRequest,
    isUpdatingQuoteDownPaymentsRequest : (state): boolean => state.isUpdatingQuoteDownPaymentsRequest,
    isDeletingQuoteDownPaymentsRequest : (state): boolean => state.isDeletingQuoteDownPaymentsRequest,
    requestStateQuoteDownPaymentsRequest: (state): IRequestState => state.requestStateQuoteDownPaymentsRequest,
    isArchivingQuoteDownPaymentsRequest : (state): boolean => state.isArchivingQuoteDownPaymentsRequest,
}