import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { CustomFieldTemplateRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { ICustomFieldTemplateState } from './states'


export const getters: GetterTree<ICustomFieldTemplateState, RootState> = {
  customFieldTemplatesList: (state): CustomFieldTemplateRequestDto[] => state.customFieldTemplatesList,
  customFieldTemplatesListArchived: (state): CustomFieldTemplateRequestDto[] => state.customFieldTemplatesListArchived,
  customFieldTemplate     : (state): CustomFieldTemplateRequestDto => state.customFieldTemplate,

  // COMMON
  isLoadingCustomFieldTemplatesList   : (state): boolean => state.isLoadingCustomFieldTemplatesList,
  isLoadingCustomFieldTemplatesListArchived   : (state): boolean => state.isLoadingCustomFieldTemplatesListArchived,
  isLoadingCustomFieldTemplate   : (state): boolean => state.isLoadingCustomFieldTemplate,
  isCreatingCustomFieldTemplate  : (state): boolean => state.isCreatingCustomFieldTemplate,
  isUpdatingCustomFieldTemplate  : (state): boolean => state.isUpdatingCustomFieldTemplate,
  isDeletingCustomFieldTemplate  : (state): boolean => state.isDeletingCustomFieldTemplate,
  isArchivingCustomFieldTemplate  : (state): boolean => state.isArchivingCustomFieldTemplate,
  requestStateCustomFieldTemplate: (state): IRequestState => state.requestStateCustomFieldTemplate,
}



