import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { CollaboratorRequestDto } from "@/../Api";
import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { ICollaboratorState } from "./states";

export const getters: GetterTree<ICollaboratorState, RootState> = {
  collaboratorsList: (state): CollaboratorRequestDto[] =>
    state.collaboratorsList,
  collaboratorsListArchived: (state): CollaboratorRequestDto[] =>
    state.collaboratorsListArchived,
  collaborator: (state): CollaboratorRequestDto => state.collaborator,
  userCollaborator: (state): any => state.userCollaborator,
  userCollaboratorId: (state): any => state.userCollaboratorId,

  // COMMON
  isLoadingCollaboratorsList: (state): boolean =>
    state.isLoadingCollaboratorsList,
  isLoadingCollaboratorsListArchived: (state): boolean =>
    state.isLoadingCollaboratorsListArchived,
  isLoadingUserCollaboratorId: (state): boolean =>
    state.isLoadingUserCollaboratorId,
  isLoadingCollaborator: (state): boolean => state.isLoadingCollaborator,
  isCreatingCollaborator: (state): boolean => state.isCreatingCollaborator,
  isUpdatingCollaborator: (state): boolean => state.isUpdatingCollaborator,
  isDeletingCollaborator: (state): boolean => state.isDeletingCollaborator,
  isArchivingCollaborator: (state): boolean => state.isArchivingCollaborator,
  requestStateCollaborator: (state): IRequestState =>
    state.requestStateCollaborator,
};
