import { IInvoiceProviderState } from "./states"
import { Module } from "vuex"
import { InvoiceProviderRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"
import { IFilters } from "@/types/api-orisis/interfaces/invoices/filters/IFilters"

const state:IInvoiceProviderState = {
    invoiceProvidersList                    : Array<InvoiceProviderRequestDto>(),
    invoiceProvider                         : <InvoiceProviderRequestDto>{},
    isLoadingInvoiceProvidersList           : false,
    isLoadingInvoiceProvider                : false,
    isCreatingInvoiceProvider               : false,
    isUpdatingInvoiceProvider               : false,
    isDeletingInvoiceProvider               : false,
    requestStateInvoiceProvider             : <IRequestState>{},
    invoiceProviderFilters                  : <IFilters>{},
    isLoadingInvoiceProvidersListByAffair   : false,
    invoiceProvidersListByAffair            : Array<InvoiceProviderRequestDto>(),
    isLoadingInvoiceProvidersListByProvider : false,
    invoicesProvidersListByProvider           : Array<InvoiceProviderRequestDto>(),
}

export const InvoiceProviderModule: Module<IInvoiceProviderState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}