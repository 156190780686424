import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { OrderFormProviderHistoryMutations } from "./mutations";
import { IOrderFormProviderHistoryState } from "./states";

export const actions: ActionTree<IOrderFormProviderHistoryState, RootState> = {
  getOrderFormHistoryById(
    { commit, rootGetters },
    {orderFormHistoryId, updateState = true}: {orderFormHistoryId: number; updateState?: boolean}
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          OrderFormProviderHistoryMutations.SET_IS_LOADING_ORDER_FORM_HISTORY,
          true
        );
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/OrderFormHistory/GetOrderFormHistoryById",
          {
            params: {
              orderFormHistoryId: orderFormHistoryId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(
              OrderFormProviderHistoryMutations.SET_ORDER_FORM_HISTORY,
              res.data
            );
            commit(
              OrderFormProviderHistoryMutations.SET_IS_LOADING_ORDER_FORM_HISTORY,
              false
            );
          }
          commit(
            OrderFormProviderHistoryMutations.SET_REQUEST_STATE_ORDER_FORM_HISTORY,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            OrderFormProviderHistoryMutations.SET_REQUEST_STATE_ORDER_FORM_HISTORY,
            err
          );
          if (updateState) {
            commit(
              OrderFormProviderHistoryMutations.SET_IS_LOADING_ORDER_FORM_HISTORY,
              false
            );
          }
          reject(err);
        });
    });
  },

  getOrderFormHistoriesByOrderFormId(
    { commit, rootGetters },
    {orderFormId, updateState = true}: {orderFormId: number; updateState?: boolean}
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          OrderFormProviderHistoryMutations.SET_IS_LOADING_ORDER_FORM_HISTORIES_LIST,
          true
        );
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/OrderFormHistory/GetOrderFormHistoriesByOrderFormId",
          {
            params: {
              orderFormId: orderFormId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(
              OrderFormProviderHistoryMutations.SET_IS_LOADING_ORDER_FORM_HISTORIES_LIST,
              false
            );
            commit(
              OrderFormProviderHistoryMutations.SET_ORDER_FORM_HISTORIES_LIST,
              res.data
            );
          }
          commit(
            OrderFormProviderHistoryMutations.SET_REQUEST_STATE_ORDER_FORM_HISTORY,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            OrderFormProviderHistoryMutations.SET_REQUEST_STATE_ORDER_FORM_HISTORY,
            err
          );
          if (updateState) {
            commit(
              OrderFormProviderHistoryMutations.SET_IS_LOADING_ORDER_FORM_HISTORIES_LIST,
              false
            );
          }
          reject(err);
        });
    });
  },
};
