import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { UserParameterRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { IUserParameterState } from './states'


export const getters: GetterTree<IUserParameterState, RootState> = {
  userParametersList: (state): UserParameterRequestDto[] => state.userParametersList,
  userParametersListByUser: (state): UserParameterRequestDto[] => state.userParametersListByUser,
  userParameter     : (state): UserParameterRequestDto => state.userParameter,

  // COMMON
  isLoadingUserParametersList   : (state): boolean => state.isLoadingUserParametersList,
  isLoadingUserParameter   : (state): boolean => state.isLoadingUserParameter,
  isCreatingUserParameter  : (state): boolean => state.isCreatingUserParameter,
  isUpdatingUserParameter  : (state): boolean => state.isUpdatingUserParameter,
  requestStateUserParameter: (state): IRequestState => state.requestStateUserParameter,
}



