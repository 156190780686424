import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { ModalEditRefProductState } from "./states";
import { ReceiptFormDetailRequestDto } from "@/../Api";

export const getters: GetterTree<ModalEditRefProductState, RootState> = {
  isActiveModalEditRefProduct: (state): boolean =>
    state.isActiveModalEditRefProduct,
  isExistingProduct: (state): boolean => state.isExistingProduct,
  isCheckReference: (state): boolean => state.isCheckReference,
  isLinkingProduct: (state): boolean => state.isLinkingProduct,
  productToCreate: (state): ReceiptFormDetailRequestDto =>
    state.productToCreate,
  productProviderExisting: (state): boolean => state.productProviderExisting,
  refToProduct: (state): string => state.refToProduct,
};
