import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { InstitutionTypeMutations } from "./mutations";
import { IInstitutionTypeState } from "./states";

export const actions: ActionTree<IInstitutionTypeState, RootState> = {
  getInstitutionTypes(
    { commit, rootGetters },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (updateState) {
        commit(
          InstitutionTypeMutations.SET_IS_LOADING_INSTITUTION_TYPES_LIST,
          true
        );
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/InstitutionType/GetInstitutionTypes"
        )
        .then((res) => {
          if (updateState) {
            commit(
              InstitutionTypeMutations.SET_INSTITUTION_TYPES_LIST,
              res.data
            );
            commit(
              InstitutionTypeMutations.SET_IS_LOADING_INSTITUTION_TYPES_LIST,
              false
            );
          }
          commit(
            InstitutionTypeMutations.SET_REQUEST_STATE_INSTITUTION_TYPE,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            InstitutionTypeMutations.SET_REQUEST_STATE_INSTITUTION_TYPE,
            err
          );
          commit(
            InstitutionTypeMutations.SET_IS_LOADING_INSTITUTION_TYPES_LIST,
            false
          );
          reject(err);
        });
    });
  },
};
