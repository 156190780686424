import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { IEventType } from "@/types/api-orisis/interfaces/Planning/IEventType";
import { GetterTree } from "vuex";
import RootState from '@/store/modules/rootState'
import { IEventTypeState } from "./states";

export const getters: GetterTree<IEventTypeState, RootState> = {
    eventTypesList: (state): IEventType[] => state.eventTypesList,
    eventTypesListArchived: (state): IEventType[] => state.eventTypesListArchived,
    eventType     : (state): IEventType => state.eventType,

    // COMMON
    isLoadingEventTypesList   : (state): boolean => state.isLoadingEventTypesList,
    isLoadingEventTypesListArchived   : (state): boolean => state.isLoadingEventTypesListArchived,
    isLoadingEventType   : (state): boolean => state.isLoadingEventType,
    isCreatingEventType  : (state): boolean => state.isCreatingEventType,
    isUpdatingEventType  : (state): boolean => state.isUpdatingEventType,
    isDeletingEventType  : (state): boolean => state.isDeletingEventType,
    isArchivingEventType  : (state): boolean => state.isArchivingEventType,
    requestStateEventType: (state): IRequestState => state.requestStateEventType
}