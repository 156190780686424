import { IAffairCostingState } from "./states";
import { Module } from "vuex";
import { AffairCostingRequestDto } from "@/../Api";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import RootState from "@/store/modules/rootState";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

const state: IAffairCostingState = {
  affairCostingsList: Array<AffairCostingRequestDto>(),
  affairCostingsListArchived: Array<AffairCostingRequestDto>(),
  affairCostingsListByAffair: Array<AffairCostingRequestDto>(),
  affairCostingsListByAffairArchived: Array<AffairCostingRequestDto>(),
  affairCosting: <AffairCostingRequestDto>{},
  isLoadingAffairCostingsList: false,
  isLoadingAffairCostingsListArchived: false,
  isLoadingAffairCostingsListByAffair: false,
  isLoadingAffairCostingsListByAffairArchived: false,
  isLoadingAffairCosting: false,
  isCreatingAffairCosting: false,
  isUpdatingAffairCosting: false,
  isDeletingAffairCosting: false,
  isArchivingAffairCosting: false,
  requestStateAffairCosting: <IRequestState>{},
  affairCostingFilters: {},
};

export const AffairCostingModule: Module<IAffairCostingState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
