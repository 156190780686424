import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { GlobalMutations } from "./mutations";
import { IGlobalState } from "./states";
import { jwtDecode } from "jwt-decode";
import { checkFunctionUser } from "@/auth/utils";
import { vm } from "@/main";

export const actions: ActionTree<IGlobalState, RootState> = {
  setIsGlobalLoading({ commit }, statut) {
    commit(GlobalMutations.SET_IS_GLOBAL_LOADING, statut);
  },
  async initializeApp({ commit, dispatch }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let allPromises = [];
      const info = jwtDecode(localStorage.getItem("userToken"));
      commit(GlobalMutations.SET_IS_GLOBAL_LOADING, true);
      allPromises.push(
        loadScript(
          "https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/90aa4470f6284d0097392b8e7d5cbe9d38f21b0961a745ac8500a03e1b7b66e8.js"
        ).then(() => {
          const userlike = document.querySelector(
            ".umm-1c94qlo"
          ) as HTMLElement;
          if (userlike) {
            userlike.style.display = "block";
          }
        })
      );
      allPromises.push(
        new Promise<void>((res, rej) => {
          dispatch("getUserInfoConnected", {}).then(
            async (userInfoConnected) => {
              const userInfoPromises = [];
              userInfoPromises.push(
                dispatch("getUserParametersByUserId", {
                  userId: userInfoConnected.id,
                }).then((settings) => {
                  if (localStorage.getItem("fromCallback")) {
                    localStorage.removeItem("fromCallback");
                    const homePage = settings.find(
                      (elem) => elem.key == "homePage"
                    );
                    if (homePage) {
                      if ((vm as any).$tabs) {
                        (vm as any).$tabs.reset();
                      }
                      vm.$router.push(homePage.value);
                    }
                  }
                })
              );
              if (checkFunctionUser(45, "read")) {
                userInfoPromises.push(dispatch("getPlanningViews", {}));
              }
              if (checkFunctionUser(39, "read")) {
                userInfoPromises.push(dispatch("getPeriodicities", {}));
              }
              if (checkFunctionUser(79, "read")) {
                userInfoPromises.push(dispatch("getReferencielTVAs", {}));
              }
              if (checkFunctionUser(2, "read")) {
                userInfoPromises.push(dispatch("getAffairCategoriesTree", {}));
              }
              if (checkFunctionUser(7, "read")) {
                userInfoPromises.push(dispatch("getCompanyTypes", {}));
              }
              if (checkFunctionUser(71, "read")) {
                userInfoPromises.push(
                  dispatch("getPaymentMethodByWorkspaceId", {})
                );
              }
              if (checkFunctionUser(70, "read")) {
                userInfoPromises.push(
                  dispatch("getPaymentConditionByWorkspaceId", {})
                );
              }
              if (checkFunctionUser(82, "read")) {
                userInfoPromises.push(dispatch("getUnits", {}));
              }
              if (checkFunctionUser(103, "read")) {
                userInfoPromises.push(dispatch("getActivitySourceTypes", {}));
              }
              if (checkFunctionUser(1, "read")) {
                userInfoPromises.push(dispatch("getAffairs", {}));
              }
              if (checkFunctionUser(6, "read")) {
                userInfoPromises.push(dispatch("getCompanies", {}));
              }
              if (checkFunctionUser(41, "read")) {
                userInfoPromises.push(dispatch("getPropertyTypes", {}));
              }
              if (checkFunctionUser(8, "read")) {
                userInfoPromises.push(dispatch("getContacts", {}));
              }
              userInfoPromises.push(dispatch("getModules", {}));

              Promise.all(userInfoPromises)
                .then(() => {
                  res();
                })
                .catch((err) => {
                  console.error("err==>", err);
                  rej();
                });
            }
          );
        })
      );
      allPromises.push(
        dispatch("getCollaboratorByUserId", { userId: info.sub })
      );
      allPromises.push(dispatch("getWorkspace", {}));
      allPromises.push(dispatch("getInstitution", {}));
      allPromises.push(dispatch("getNatureEnum", {}));
      allPromises.push(dispatch("getAmountTypeEnum", {}));
      allPromises.push(dispatch("getInstitutionsMinified", {}));
      allPromises.push(dispatch("getAmountTypeEnum", {}));
      allPromises.push(dispatch("getProductTypeEnum", {}));

      Promise.all(allPromises)
        .then((res) => {
          commit(GlobalMutations.SET_IS_GLOBAL_LOADING, false);
          resolve();
        })
        .catch((err) => {
          console.error("err==>", err);
          commit(GlobalMutations.SET_IS_GLOBAL_LOADING, false);
          reject();
        });
    });
  },
};
function loadScript(scriptUrl): Promise<void> {
  const script = document.createElement("script");
  script.src = scriptUrl;
  document.body.appendChild(script);

  return new Promise((res, rej) => {
    script.onload = function () {
      res();
    };
    script.onerror = function () {
      rej();
    };
  });
}
