import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { InvoiceOptionMutations } from "./mutations";
import { IInvoiceOptionState } from "./states";
import { InvoiceOptionRequestDto } from "Api";

export const actions: ActionTree<IInvoiceOptionState, RootState> = {
  getInvoiceOptionByInvoiceId(
    { commit },
    {
      invoiceId,
      updateState = true,
    }: { invoiceId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(InvoiceOptionMutations.SET_IS_LOADING_INVOICE_OPTION, true);
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/InvoiceOption/GetInvoiceOptionByInvoiceId`;

      axios
        .get(url, {
          params: { invoiceId },
        })
        .then((res) => {
          if (updateState) {
            commit(InvoiceOptionMutations.SET_INVOICE_OPTION, res.data);
            commit(InvoiceOptionMutations.SET_IS_LOADING_INVOICE_OPTION, false);
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(InvoiceOptionMutations.SET_IS_LOADING_INVOICE_OPTION, false);
          }
          reject(err);
        });
    });
  },

  createInvoiceOption(
    { commit },
    {
      invoiceOption,
      updateState = true,
    }: { invoiceOption: InvoiceOptionRequestDto; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(InvoiceOptionMutations.SET_IS_LOADING_INVOICE_OPTION, true);
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/InvoiceOption/CreateInvoiceOption`;

      axios
        .post(url, invoiceOption)
        .then((res) => {
          if (updateState) {
            commit(InvoiceOptionMutations.SET_INVOICE_OPTION, res.data.data);
            commit(InvoiceOptionMutations.SET_IS_LOADING_INVOICE_OPTION, false);
          }
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(InvoiceOptionMutations.SET_IS_LOADING_INVOICE_OPTION, false);
          }
          reject(err);
        });
    });
  },

  updateInvoiceOption(
    { commit, rootGetters },
    {
      invoiceOption,
      updateState = true,
    }: { invoiceOption: InvoiceOptionRequestDto; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(InvoiceOptionMutations.SET_IS_LOADING_INVOICE_OPTION, true);
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/InvoiceOption/UpdateInvoiceOption`;

      axios
        .put(url, { ...invoiceOption, invoiceId: rootGetters.document.id })
        .then((res) => {
          if (updateState) {
            commit(InvoiceOptionMutations.SET_INVOICE_OPTION, res.data.data);
            commit(InvoiceOptionMutations.SET_IS_LOADING_INVOICE_OPTION, false);
          }
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(InvoiceOptionMutations.SET_IS_LOADING_INVOICE_OPTION, false);
          }
          reject(err);
        });
    });
  },
};
