import { InitialDocumentState } from "./states";
import { MutationTree } from "vuex";

export enum InitialDocumentMutations {
  SET_INITIAL_DOCUMENT = "SET_INITIAL_DOCUMENT",
  SET_IS_LOADING_INITIAL_DOCUMENT = "SET_IS_LOADING_INITIAL_DOCUMENT",
}

export const mutations: MutationTree<InitialDocumentState> = {
  [InitialDocumentMutations.SET_INITIAL_DOCUMENT]: (state, payload) => {
    state.initialDocument = JSON.parse(JSON.stringify(payload));
  },
  [InitialDocumentMutations.SET_IS_LOADING_INITIAL_DOCUMENT]: (
    state,
    payload
  ) => {
    state.isLoadingInitialDocument = payload;
  },
};
