import { IAffairCategoryState } from "./states";
import { Module } from "vuex";
import { AffairCategoryRequestDto } from "@/../Api";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import RootState from "@/store/modules/rootState";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

const state: IAffairCategoryState = {
  affairCategoriesList: Array<AffairCategoryRequestDto>(),
  affairCategoriesTreeList: Array<any>(),
  affairCategoriesListArchived: Array<AffairCategoryRequestDto>(),
  affairCategory: <AffairCategoryRequestDto>{},
  isLoadingAffairCategoriesList: false,
  isLoadingAffairCategoriesTreeList: false,
  isLoadingAffairCategoriesTreeListArchived: false,
  isLoadingAffairCategoriesListArchived: false,
  isLoadingAffairCategory: false,
  isCreatingAffairCategory: false,
  isUpdatingAffairCategory: false,
  isDeletingAffairCategory: false,
  isArchivingAffairCategory: false,
  requestStateAffairCategory: <IRequestState>{},
  affairCategoriesTreeListArchived: Array<any>(),
};

export const AffairCategoryModule: Module<IAffairCategoryState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
