import { IHelpersState } from "./states"
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

const state:IHelpersState = {
    // Civility
    civilityList                    : [],
    isLoadingCivilityList           : false,
    
    documentTypeList                : [],
    legalFormList                   : [],
    guaranteeTypeList               : [],
    documentValidityList            : [],
    amountTypeList                  : [],
    paymentRequestDueList           : [],
    productTypeList                 : [],
    natureList                      : [],
    isLoadingDocumentTypeList       : false,
    isLoadingLegalFormList          : false,
    isLoadingGuaranteeTypeList      : false,
    isLoadingDocumentValidityList   : false,
    isLoadingAmountTypeList         : false,
    isLoadingPaymentRequestDueList  : false,
    isLoadingProductTypeList        : false,
    isLoadingNatureList             : false,
    requestStateHelpers             : <IRequestState>{},
}

export const HelpersModule: Module<IHelpersState, RootState> = {
    state, 
    getters,
    mutations,
    actions
} 