import RootState from "@/store/modules/rootState"
import { IInvoiceStatisticsState } from "./states";
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

export const state: IInvoiceStatisticsState = {
    statsCustomerDistribution       : [],
    statsDocumentByDay       : [],
    statsPeriodeInvoice            : [],
    statsDistributionByActivities: {},
    isLoadingStatsCustomerDistribution   : false,
    isLoadingStatsDocumentByDay   : false,
    isLoadingStatsPeriodeInvoice   : false,
    isLoadingStatsDistributionByActivities   : false,
    
}

export const InvoiceStatisticsModule: Module<IInvoiceStatisticsState, RootState> = {
  state, 
  getters,
  mutations,
  actions
}
