import { IPlanningLinesState } from "./states";
import { MutationTree } from "vuex";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";


export enum PlanningLineMutations{
    SET_PLANNING_LINES_LIST = "SET_PLANNING_LINES_LIST",
    SET_PLANNING_LINES_COLLABORATOR_LIST = "SET_PLANNING_LINES_COLLABORATOR_LIST",
    SET_PLANNING_LINES_AFFAIR_LIST = "SET_PLANNING_LINES_AFFAIR_LIST",
    SET_PLANNING_LINES_FREE_LINE_LIST = "SET_PLANNING_LINES_FREE_LINE_LIST",
    SET_PLANNING_LINE = "SET_PLANNING_LINE",
    
    // COMON
    SET_IS_LOADING_PLANNING_LINE_LIST = "SET_IS_LOADING_PLANNING_LINE_LIST",
    SET_IS_LOADING_PLANNING_LINE_COLLABORATOR_LIST = "SET_IS_LOADING_PLANNING_LINE_COLLABORATOR_LIST",
    SET_IS_LOADING_PLANNING_LINE_AFFAIR_LIST = "SET_IS_LOADING_PLANNING_LINE_AFFAIR_LIST",
    SET_IS_LOADING_PLANNING_LINE_FREE_LINE_LIST = "SET_IS_LOADING_PLANNING_LINE_FREE_LINE_LIST",
    SET_IS_LOADING_PLANNING_LINE = "SET_IS_LOADING_PLANNING_LINE",
    SET_IS_CREATING_PLANNING_LINE = "SET_IS_CREATING_PLANNING_LINE",
    SET_IS_UPDATING_PLANNING_LINE = "SET_IS_UPDATING_PLANNING_LINE",
    SET_IS_DELETING_PLANNING_LINE = "SET_IS_DELETING_PLANNING_LINE",
    SET_IS_ARCHIVING_PLANNING_LINE = "SET_IS_DELETING_PLANNING_LINE",
    SET_REQUEST_STATE_PLANNING_LINE = "SET_REQUEST_STATE_PLANNING_LINE"
  }

  export const mutations: MutationTree<IPlanningLinesState> = {
    [PlanningLineMutations.SET_PLANNING_LINES_LIST]: (state, payload) => {
        state.planningLinesList = payload;
    },
    [PlanningLineMutations.SET_PLANNING_LINES_COLLABORATOR_LIST]: (state, payload) => {
        state.planningLinesCollaboratorList = payload;
    },
    [PlanningLineMutations.SET_PLANNING_LINES_AFFAIR_LIST]: (state, payload) => {
        state.planningLinesAffairList = payload;
    },
    [PlanningLineMutations.SET_PLANNING_LINES_FREE_LINE_LIST]: (state, payload) => {
        state.planningLinesFreeLineList = payload;
    },
    [PlanningLineMutations.SET_PLANNING_LINE]: (state, payload) => {
        state.planningLine = payload;
    },

    //COMMON
    [PlanningLineMutations.SET_IS_LOADING_PLANNING_LINE_LIST]: (state, payload: boolean) => {
        state.isLoadingPlanningLinesList = payload;
    },
    [PlanningLineMutations.SET_IS_LOADING_PLANNING_LINE_COLLABORATOR_LIST]: (state, payload: boolean) => {
        state.isLoadingPlanningLinesCollaboratorList = payload;
    },
    [PlanningLineMutations.SET_IS_LOADING_PLANNING_LINE_AFFAIR_LIST]: (state, payload: boolean) => {
        state.isLoadingPlanningLinesAffairList = payload;
    },
    [PlanningLineMutations.SET_IS_LOADING_PLANNING_LINE_FREE_LINE_LIST]: (state, payload: boolean) => {
        state.isLoadingPlanningLinesFreeLineList = payload;
    },
    [PlanningLineMutations.SET_IS_LOADING_PLANNING_LINE]: (state, payload: boolean) => {
        state.isLoadingPlanningLine = payload;
    },
    [PlanningLineMutations.SET_IS_CREATING_PLANNING_LINE]: (state, payload: boolean) => {
        state.isCreatingPlanningLine = payload;
    },
    [PlanningLineMutations.SET_IS_UPDATING_PLANNING_LINE]: (state, payload: boolean) => {
        state.isUpdatingPlanningLine = payload;
    },
    [PlanningLineMutations.SET_IS_DELETING_PLANNING_LINE]: (state, payload: boolean) => {
        state.isDeletingPlanningLine = payload;
    },
    [PlanningLineMutations.SET_REQUEST_STATE_PLANNING_LINE]: (state, payload: IRequestState) => {
        state.requestStatePlanningLine = payload;
    }    
} 