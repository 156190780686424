import { ProductProviderRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { ProductProviderMutations } from "./mutations";
import { IProductProviderState } from "./states";

export const actions: ActionTree<IProductProviderState, RootState> = {
  async getProductProviders(
    { commit, rootGetters },
    {
      productId = null,
      companyId = null,
      updateState = true,
    }: { productId: number; companyId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          ProductProviderMutations.SET_IS_LOADING_PRODUCT_PROVIDERS_LIST,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/ProductProvider/GetProductProviders`;
      let productTypeList = rootGetters.productTypeList;
      await axios
        .get(url, {
          params: {
            productId: productId,
            companyId: companyId,
          },
        })
        .then((res) => {
          let list = res.data.map((el: any) => {
            return {
              ...el,
              unitId: el.unit ? el.unit.id : el.productType == 5 ? 2 : 1,
              companyId: el.company ? el.company.id : null,
              purchaseCategoryId: el.purchaseCategory
                ? el.purchaseCategory.id
                : null,
              degressivePurchaseRates: el.degressivePurchaseRates
                ? el.degressivePurchaseRates.map((item: any) => {
                    return {
                      ...item,
                      productProviderId: el.id,
                    };
                  })
                : [],
            };
          });
          let catalogSortedByProductType = res.data.reduce(function (
            acc: any,
            obj: any
          ) {
            let key = Object.keys(productTypeList)[obj.productType];
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
          },
          {});
          if (updateState) {
            commit(
              ProductProviderMutations.SET_IS_LOADING_PRODUCT_PROVIDERS_LIST,
              false
            );
            commit(ProductProviderMutations.SET_PRODUCT_PROVIDERS_LIST, list);
            commit(
              ProductProviderMutations.SET_PRODUCT_PROVIDER_SORTED_BY_PRODUCT_TYPE,
              catalogSortedByProductType
            );
          }
          commit(
            ProductProviderMutations.SET_REQUEST_STATE_PRODUCT_PROVIDER,
            res
          );
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ProductProviderMutations.SET_IS_LOADING_PRODUCT_PROVIDERS_LIST,
            false
          );
          commit(
            ProductProviderMutations.SET_REQUEST_STATE_PRODUCT_PROVIDER,
            err
          );
          reject(err);
        });
    });
  },

  async getProductProviderById(
    { commit },
    {
      productProviderId,
      updateState = true,
    }: { productProviderId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(ProductProviderMutations.SET_IS_LOADING_PRODUCT_PROVIDER, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/ProductProvider/GetProductProviderById`,
          {
            params: {
              productProviderId: productProviderId,
            },
          }
        )
        .then((res) => {
          let productProvider = {
            ...res.data,
            unitId: res.data.unit
              ? res.data.unit.id
              : res.data.productType == 5
              ? 2
              : 1,
            companyId: res.data.company ? res.data.company.id : null,
            productId: res.data.product ? res.data.product.id : null,
            purchaseCategoryId: res.data.purchaseCategory
              ? res.data.purchaseCategory.id
              : null,
            degressivePurchaseRates: res.data.degressivePurchaseRates
              ? res.data.degressivePurchaseRates.map((item: any) => {
                  return {
                    ...item,
                    productProviderId: res.data.id,
                  };
                })
              : [],
          };
          if (updateState) {
            commit(
              ProductProviderMutations.SET_PRODUCT_PROVIDER,
              productProvider
            );
            commit(
              ProductProviderMutations.SET_IS_LOADING_PRODUCT_PROVIDER,
              false
            );
          }
          commit(
            ProductProviderMutations.SET_REQUEST_STATE_PRODUCT_PROVIDER,
            res
          );
          resolve(productProvider);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ProductProviderMutations.SET_REQUEST_STATE_PRODUCT_PROVIDER,
            err
          );
          commit(
            ProductProviderMutations.SET_IS_LOADING_PRODUCT_PROVIDER,
            false
          );
          reject(err);
        });
    });
  },

  async getProductProvidersArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          ProductProviderMutations.SET_IS_LOADING_PRODUCT_PROVIDERS_LIST_ARCHIVED,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/ProductProvider/GetProductProvidersArchived`;
      await axios
        .get(url)
        .then((res) => {
          let list = res.data.map((el: any) => {
            return {
              ...el,
              unitId: el.unit ? el.unit.id : el.productType == 5 ? 2 : 1,
              companyId: el.company ? el.company.id : null,
              purchaseCategoryId: el.purchaseCategory
                ? el.purchaseCategory.id
                : null,
              degressivePurchaseRates: el.degressivePurchaseRates
                ? el.degressivePurchaseRates.map((item: any) => {
                    return {
                      ...item,
                      productProviderId: el.id,
                    };
                  })
                : [],
            };
          });
          if (updateState) {
            commit(
              ProductProviderMutations.SET_IS_LOADING_PRODUCT_PROVIDERS_LIST_ARCHIVED,
              false
            );
            commit(
              ProductProviderMutations.SET_PRODUCT_PROVIDERS_LIST_ARCHIVED,
              list
            );
          }
          commit(
            ProductProviderMutations.SET_REQUEST_STATE_PRODUCT_PROVIDER,
            res
          );
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ProductProviderMutations.SET_IS_LOADING_PRODUCT_PROVIDERS_LIST_ARCHIVED,
            false
          );
          commit(
            ProductProviderMutations.SET_REQUEST_STATE_PRODUCT_PROVIDER,
            err
          );
          reject(err);
        });
    });
  },

  async getProductProviderArchivedById(
    { commit },
    {
      productProviderId,
      updateState = true,
    }: { productProviderId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(ProductProviderMutations.SET_IS_LOADING_PRODUCT_PROVIDER, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/ProductProvider/GetProductProviderArchivedById`,
          {
            params: {
              productProviderId: productProviderId,
            },
          }
        )
        .then((res) => {
          let productProviderArchived = {
            ...res.data,
            unitId: res.data.unit
              ? res.data.unit.id
              : res.data.productType == 5
              ? 2
              : 1,
            companyId: res.data.company ? res.data.company.id : null,
            purchaseCategoryId: res.data.purchaseCategory
              ? res.data.purchaseCategory.id
              : null,
            degressivePurchaseRates: res.data.degressivePurchaseRates
              ? res.data.degressivePurchaseRates.map((item: any) => {
                  return {
                    ...item,
                    productProviderId: res.data.id,
                  };
                })
              : [],
          };
          if (updateState) {
            commit(
              ProductProviderMutations.SET_PRODUCT_PROVIDER,
              productProviderArchived
            );
            commit(
              ProductProviderMutations.SET_IS_LOADING_PRODUCT_PROVIDER,
              false
            );
          }
          commit(
            ProductProviderMutations.SET_REQUEST_STATE_PRODUCT_PROVIDER,
            res
          );
          resolve(productProviderArchived);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ProductProviderMutations.SET_REQUEST_STATE_PRODUCT_PROVIDER,
            err
          );
          commit(
            ProductProviderMutations.SET_IS_LOADING_PRODUCT_PROVIDER,
            false
          );
          reject(err);
        });
    });
  },

  async createProductProvider(
    { commit, dispatch, rootGetters },
    {
      productProvider,
      reload = true,
    }: { productProvider: any; reload: boolean }
  ) {
    return new Promise(async (resolve, reject) => {
      commit(ProductProviderMutations.SET_IS_CREATING_PRODUCT_PROVIDER, true);
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Purchase/ProductProvider/CreateProductProvider`,
          productProvider
        )
        .then(async (res) => {
          if (productProvider.useDegressivePurchaseRate) {
            for (
              let i = 0;
              i < productProvider.degressivePurchaseRates.length;
              i++
            ) {
              const degressivePurchaseRate =
                productProvider.degressivePurchaseRates[i];
              if (degressivePurchaseRate.id) {
                await dispatch("updateDegressivePurchaseRate", {
                  degressivePurchaseRate: degressivePurchaseRate,
                });
              } else {
                degressivePurchaseRate.productProviderId = res.data.data.id;
                await dispatch("createDegressivePurchaseRate", {
                  degressivePurchaseRate: degressivePurchaseRate,
                });
              }
            }
          }
          if (reload) {
            dispatch("getProductProviders", {});
            commit(
              ProductProviderMutations.SET_PRODUCT_PROVIDERS_LIST,
              rootGetters.productProvidersList
            );
          }
          commit(
            ProductProviderMutations.SET_REQUEST_STATE_PRODUCT_PROVIDER,
            res
          );
          commit(
            ProductProviderMutations.SET_IS_CREATING_PRODUCT_PROVIDER,
            false
          );
          resolve(res);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ProductProviderMutations.SET_REQUEST_STATE_PRODUCT_PROVIDER,
            err
          );
          commit(
            ProductProviderMutations.SET_IS_CREATING_PRODUCT_PROVIDER,
            false
          );
          reject(err);
        });
    });
  },

  async updateProductProvider(
    { commit, dispatch, rootGetters },
    { productProvider, reload }: { productProvider; reload: any }
  ) {
    return new Promise(async (resolve, reject) => {
      commit(ProductProviderMutations.SET_IS_UPDATING_PRODUCT_PROVIDER, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/ProductProvider/UpdateProductProvider`,
          productProvider,
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`,
            },
          }
        )
        .then(async (res) => {
          if (productProvider.useDegressivePurchaseRate) {
            for (
              let i = 0;
              i < productProvider.degressivePurchaseRates.length;
              i++
            ) {
              const degressivePurchaseRate =
                productProvider.degressivePurchaseRates[i];
              if (degressivePurchaseRate.id) {
                degressivePurchaseRate.productProviderId = res.data.data.id;
                await dispatch("updateDegressivePurchaseRate", {
                  degressivePurchaseRate: degressivePurchaseRate,
                });
              } else {
                degressivePurchaseRate.productProviderId = res.data.data.id;
                await dispatch("createDegressivePurchaseRate", {
                  degressivePurchaseRate: degressivePurchaseRate,
                });
              }
            }
          }
          if (reload) {
            dispatch("getProductProviders", {});
            commit(
              ProductProviderMutations.SET_PRODUCT_PROVIDERS_LIST,
              rootGetters.productProvidersList
            );
          }
          commit(
            ProductProviderMutations.SET_REQUEST_STATE_PRODUCT_PROVIDER,
            res
          );
          commit(
            ProductProviderMutations.SET_IS_UPDATING_PRODUCT_PROVIDER,
            false
          );
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          commit(
            ProductProviderMutations.SET_REQUEST_STATE_PRODUCT_PROVIDER,
            err
          );
          commit(
            ProductProviderMutations.SET_IS_UPDATING_PRODUCT_PROVIDER,
            false
          );
        });
    });
  },

  async restoreProductProviders(
    { commit, rootGetters },
    { productProviderIds = [] }: { productProviderIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(ProductProviderMutations.SET_IS_UPDATING_PRODUCT_PROVIDER, true);
      axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Purchase/ProductProvider/Restore",
          productProviderIds
        )
        .then((res) => {
          productProviderIds.forEach((productProviderId: number) => {
            rootGetters.productProvidersListArchived.splice(
              rootGetters.productProvidersListArchived.findIndex(
                (elem) => elem.id == productProviderId
              ),
              1
            );
          });
          commit(
            ProductProviderMutations.SET_REQUEST_STATE_PRODUCT_PROVIDER,
            res
          );
          commit(
            ProductProviderMutations.SET_IS_UPDATING_PRODUCT_PROVIDER,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ProductProviderMutations.SET_REQUEST_STATE_PRODUCT_PROVIDER,
            err
          );
          commit(
            ProductProviderMutations.SET_IS_UPDATING_PRODUCT_PROVIDER,
            false
          );
          reject(err);
        });
    });
  },

  async archiveProductProviders(
    { commit, rootGetters },
    { productProviderIds = [] }: { productProviderIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(ProductProviderMutations.SET_IS_ARCHIVING_PRODUCT_PROVIDER, true);
      axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Purchase/ProductProvider/Archive",
          {
            data: productProviderIds,
          }
        )
        .then((res) => {
          productProviderIds.forEach((productProviderId: number) => {
            rootGetters.productProvidersList.splice(
              rootGetters.productProvidersList.findIndex(
                (elem) => elem.id == productProviderId
              ),
              1
            );
          });
          commit(
            ProductProviderMutations.SET_PRODUCT_PROVIDERS_LIST,
            rootGetters.productProvidersList
          );
          commit(
            ProductProviderMutations.SET_REQUEST_STATE_PRODUCT_PROVIDER,
            res
          );
          commit(
            ProductProviderMutations.SET_IS_ARCHIVING_PRODUCT_PROVIDER,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ProductProviderMutations.SET_REQUEST_STATE_PRODUCT_PROVIDER,
            err
          );
          commit(
            ProductProviderMutations.SET_IS_ARCHIVING_PRODUCT_PROVIDER,
            false
          );
          reject(err);
        });
    });
  },

  async deleteProductProvider(
    { commit, rootGetters },
    { productProviderIds = [] }: { productProviderIds: number[] }
  ) {
    return new Promise(async (resolve, reject) => {
      commit(ProductProviderMutations.SET_IS_DELETING_PRODUCT_PROVIDER, true);
      await axios
        .delete(
          `${process.env.VUE_APP_API_ORISIS}Purchase/ProductProvider/Delete`,
          {
            data: productProviderIds,
          }
        )
        .then((res) => {
          productProviderIds.forEach((productProviderId: number) => {
            rootGetters.productProvidersListArchived.splice(
              rootGetters.productProvidersListArchived.findIndex(
                (elem) => elem.id == productProviderId
              ),
              1
            );
          });
          commit(
            ProductProviderMutations.SET_PRODUCT_PROVIDERS_LIST,
            rootGetters.productProvidersList
          );
          commit(
            ProductProviderMutations.SET_REQUEST_STATE_PRODUCT_PROVIDER,
            res
          );
          commit(
            ProductProviderMutations.SET_IS_DELETING_PRODUCT_PROVIDER,
            false
          );
          resolve(res);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ProductProviderMutations.SET_REQUEST_STATE_PRODUCT_PROVIDER,
            err
          );
          commit(
            ProductProviderMutations.SET_IS_DELETING_PRODUCT_PROVIDER,
            false
          );
          reject(err);
        });
    });
  },
};
