import { UserManager, User, WebStorageStateStore, Log } from "oidc-client-ts";
import env from "@/environments";
import { vm } from "@/main"
import store from "@/store";

class AuthService {
  _userManager: UserManager;
  _user?: User;

  constructor() {
    // Log.setLogger(console);
    // Log.setLevel(Log.DEBUG);
    const config = {
      authority: env.OpenIdConnect.Authority,
      client_id: env.OpenIdConnect.ClientId,
      client_name: env.OpenIdConnect.ClientName,
      client_secret: env.OpenIdConnect.ClientSecret,
      redirect_uri: `${env.CurrentUrl}callback.html`,
      silent_redirect_uri: `${env.CurrentUrl}renew-callback`,
      scope: "orisis_api profile openid offline_access",
      response_type: "code",
      loadUserInfo: true,
      post_logout_redirect_uri: `${env.CurrentUrl}`,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
    };
    localStorage.setItem("oidcConfig", JSON.stringify({authority: config.authority, client_id: config.client_id}));
    this._userManager = new UserManager(config);
    this._userManager.startSilentRenew();
    this._userManager.events.addAccessTokenExpiring(() => {
      console.log("token expiring");
    });
    this._userManager.events.addAccessTokenExpired(() => {
      console.log("token expired");
    });
    this._userManager.events.addUserSignedIn(() => {
      console.log("user signed in");
    });
    this._userManager.events.addUserLoaded(() => {
      console.log("user loaded");
      localStorage.removeItem("oidcConfig");
    });
    this._userManager.events.addUserSignedOut(() => {
      console.log("user signed out");
    });
    this._userManager.events.addUserUnloaded(() => {
      if ((vm as any) && (vm as any).$tabs){
        (vm as any).$tabs.reset()
        vm.$store.commit("SET_IS_GLOBAL_LOADING", true);
      }
      console.log("user unloaded");
    });
  }

  loadUser = async () => {
    try {
      const user = await this._userManager.getUser();
      if (user && !user.expired) {
        this._user = user;
        localStorage.setItem("userToken", user.access_token);
        await store.dispatch("getUserParametersByUserId", { userId: user.profile.sub });
        await store.dispatch("getUserInfoConnected", {});
      }
    } catch (error) {
      console.error('Error loading user:', error);
    }
  };

  login = (returnUrl: string) => {
    localStorage.setItem("returnUrl", returnUrl);
    return this._userManager.signinRedirect();
  };
  
  renewToken = async (returnUrl: string, institutionUid) => {
    return new Promise((resolve, reject) => {
      localStorage.setItem("returnUrl", returnUrl);
      const extraTokenParams = {institution_uniqueIdentifier: institutionUid};
      this._userManager.signinSilent({extraTokenParams: extraTokenParams}).then(async (res) => {
        await this.loadUser();
        resolve(res);
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
    })    
  }

  signinRedirectCallback = async (url) => {
    const user = await this._userManager.signinRedirectCallback(url)
    await this.loadUser()
    return user
  };

  signinSilentCallback = async (url) => {
    const user = await this._userManager.signinSilentCallback(url)
    await this.loadUser()
    return user
  };

  logout = () => {
    return this._userManager.signoutRedirect();
  };

  isLoggedIn = () => {
    return this._user && this._user.access_token && !this._user.expired;
  };

  getAccessToken = () => {
    return this._user ? this._user.access_token : "";
  };

  signoutRedirectCallback = () => {
    return this._userManager.signoutRedirectCallback();
  };

  getCurrentUser = () => {
    return {
      id: this._user?.profile.sub,
      workspaceId: this._user?.profile.workspace_id,
      userName: "",
      firstName: "",
      lastName: "",
    };
  };

  isAuthenticated = () => {
    return this.isLoggedIn();
  };
}

const instance = new AuthService();

export default instance;