import { IDeliveryFormDetailState } from "./states";
import IDeliveryFormHeader from "@/types/api-orisis/interfaces/Purchase/deliveryFormDetail/IDeliveryFormHeader";
import { Module } from "vuex";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import RootState from "@/store/modules/rootState";
import { EInputType } from "@/types/api-orisis/enums/enums";
import { IDeliveryFormParameters } from "@/types/api-orisis/interfaces/Purchase/deliveryForm/IDeliveryFormParameters";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

const deliveryFormParameters: IDeliveryFormParameters = {
  BShowCalcululationsMargin: false,
  BShowCalculationsRemisePerLines: false,
  BShowReferences: false,
  BShowTypes: false,
  BTvaAttestation: false,
  BPriorVisitDate: false,
  BDefaultTvaChange: false,
  BHideStyleEditor: false,
  BHideWasteManagement: false,
  BHideTotalSections: false,
  BHideQteAndUnitsCol: false,
  BHideTvaCol: false,
  BHideWorkElements: false,
  BHideRestToBeInvoiced: false,
  BShowPhoneNumber: false,
  BShowEmail: false,
  BShowTvaNumber: false,
  BShowSiren: false,
};

const deliveryFormHeader: IDeliveryFormHeader = {
  index: {
    text: "N°",
    display: true,
    editable: false,
    class: "col-num pl-0",
    style:
      "min-height: 28px !important; max-width: 60px!important;min-width: 60px!important;margin-left:25px;width: 60px!important; padding-left: 0.25em!important; padding-right: 0.25em!important;",
    inputType: EInputType.Text,
    unit: "",
    prefix: "",
  },
  reference: {
    text: "Référence",
    display: true,
    editable: true,
    class: "col-ref reference",
    style: "width:10%;max-width: 85px;",
    inputType: EInputType.Text,
  },
  description: {
    text: "Désignation",
    display: true,
    editable: true,
    class: "col-label",
    style: "width:auto; max-width: none;flex-grow: 1;",
    inputType: EInputType.SelectCatalog,
  },
  outPutQuantity: {
    text: "Qté sortie",
    display: true,
    editable: true,
    class: "col-qte text-center",
    style: "width:20%; max-width:100px;",
    inputType: EInputType.Number,
    unit: "",
    numberToFixed: 2,
    prefix: "",
  },
  unitId: {
    text: "Unité",
    display: true,
    editable: true,
    class: "col-unit text-center",
    style: "width:10%; max-width:70px;",
    inputType: EInputType.Select,
    choice: [],
  },
  unitPriceHt: {
    text: "Prix U. HT",
    display: false,
    editable: true,
    class: "col-price text-right",
    style: "width:10%; max-width:90px; ",
    inputType: EInputType.Number,
    unit: "€",
    numberToFixed: 2,
    prefix: "",
  },
  referencielTvaId: {
    text: "TVA",
    display: false,
    editable: true,
    class: "col-vat text-center",
    style: "width:10%; max-width:90px",
    inputType: EInputType.Select,
    choice: [],
  },
  titre: {
    text: "Titre",
    display: false,
    editable: true,
    class: "col-title text-left",
    style: "line-height: 22px !important; width:95%;",
    inputType: EInputType.Title,
  },
  total: {
    text: "Total HT",
    display: false,
    editable: false,
    class: "col-vat text-center",
    style:
      "line-height: 22px !important; display: flex; align-items: center; justify-content: flex-end; width:10%; max-width:120px;",
    inputType: EInputType.Number,
    unit: "€",
    prefix: "",
    numberToFixed: 2,
  },
  subtotal: {
    text: "Sous-total",
    display: false,
    editable: false,
    class: "col-title-total text-right",
    style: "line-height: 34px !important; width:50%;",
    inputType: EInputType.Number,
    unit: "€",
    prefix: "",
    numberToFixed: 2,
  },
};

export const state: IDeliveryFormDetailState = {
  DeliveryFormParameters: deliveryFormParameters,
  deliveryFormHeader: deliveryFormHeader,
  deliveryFormDetails: [],
  deliveryFormDetail: {},
  isLoadingDeliveryFormDetails: false,
  isLoadingDeliveryFormDetailDuplication: false,
  isCreatingDeliveryFormDetail: false,
  isUpdatingDeliveryFormDetail: false,
  isDeletingDeliveryFormDetail: false,
  requestStateDeliveryFormDetail: <IRequestState>{},
};

export const DeliveryFormDetailModule: Module<
  IDeliveryFormDetailState,
  RootState
> = {
  state,
  getters,
  mutations,
  actions,
};
