export enum ECivility {
  Monsieur,
  Madame,
  Autre,
  MonsieurMadame,
}

export const civilities = [
  { value: 0, label: "Monsieur" },
  { value: 1, label: "Madame" },
  { value: 2, label: "Autre" },
  { value: 3, label: "Monsieur et Madame" },
];

export enum ETypeLine {
  CollaboratorLine = 0,
  AffairLine = 1,
  FreeLine = 2,
  Title = 3,
  SubTitle = 4,
}

export enum ETypeField {
  "Nombre" = 1,
  "Montant" = 2,
  "Pourcentage" = 3,
  "Ligne de texte" = 4,
  "Zone de texte" = 5,
  "Texte enrichi" = 6,
  "Date" = 7,
  "Date Heure" = 8,
  "Selecteur simple" = 9,
  "Choix multiples" = 10,
  "Case à cocher" = 11,
  "Signature" = 12,
}

export let typeFields = [
  { value: 1, label: "Nombre" },
  { value: 2, label: "Montant" },
  { value: 3, label: "Pourcentage" },
  { value: 4, label: "Ligne de texte" },
  { value: 5, label: "Zone de texte" },
  { value: 6, label: "Texte enrichi" },
  { value: 7, label: "Date" },
  { value: 8, label: "Date Heure" },
  { value: 9, label: "Selecteur simple" },
  { value: 10, label: "Choix multiples" },
  { value: 11, label: "Case à cocher" },
  { value: 12, label: "Signature" },
];

export let statisticsPeriods = [
  { value: 0, label: "Année" },
  { value: 1, label: "Trimestre" },
  { value: 2, label: "Mois" },
  { value: 3, label: "Jour" },
];

export const timeSpans = [
  { value: 1, label: "Cette semaine" },
  { value: 2, label: "Ce mois-ci" },
  { value: 3, label: "Mois dernier" },
  { value: 4, label: "Cette année" },
  { value: 5, label: "Année dernière" },
  { value: 6, label: "Depuis le début" },
  { value: 7, label: "Période personnalisée" },
  { value: 8, label: "Aujourd'hui" },
  { value: 9, label: "Période fiscale en cours" },
];

export enum ESmsStatus {
  Encours,
  Envoye,
  Error,
}

export let smsStatus = [
  {
    value: 0,
    label: "En cours",
    class: "warning",
    icon: "schedule_send",
  },
  { value: 1, label: "Envoyé", class: "primary", icon: "send" },
  { value: 2, label: "Erreur", class: "danger", icon: "cancel_schedule_send" },
];

export enum ECustomerType {
  Particulier,
  Professionnel,
}

export enum EDocumentDetailType {
  SectionLine = 0, // Ligne de section
  CommentLine = 1, // Ligne de commentaire
  BreakLine = 2, // Ligne de saut de page
  DownPaymentLine = 3, // Ligne d'acompte
  SupplyLine = 4, // Ligne de fourniture
  ManPowerLine = 5, // Ligne de main d'oeuvre
  LaborLine = 6, // Ligne d'ouvrage
  SubContractingLine = 7, // Ligne de sous-traitance
  EquipementLine = 8, // Ligne de matériel
  VariousLine = 9, // Ligne divers
  CreditLine = 10, // Ligne de d'avoir
  SubSectionLine = 11, // Line de Sous-section
  BreakLineLine = 12, // Ligne de saut de ligne
  SubTotalLine = 13, // Ligne de sous-total
  SupplyLaborLine = 14, // Ligne d'ouvrage de fourniture
  SubContractingLaborLine = 15, // Ligne d'ouvrage de sous-traitance
  EquipementLaborLine = 16, // Ligne d'ouvrage de matériel
  ManPowerLaborLine = 17, // Ligne d'ouvrage de main d'oeuvre
  VariousLaborLine = 18, // Ligne d'ouvrage divers
}

export enum EDocumentDetailTypeReduce {
  F = 4, // Ligne de fourniture
  MO = 5, // Ligne de main d'oeuvre
  O = 6, // Ligne d'ouvrage
  ST = 7, // Ligne de sous-traitance
  M = 8, // Ligne de matériel
  A = 9, // Ligne divers
}

export enum EDocumentDetailLaborTypeReduce {
  F = 14, // Ligne de fourniture
  MO = 17, // Ligne de main d'oeuvre
  ST = 15, // Ligne de sous-traitance
  M = 16, // Ligne de matériel
  A = 18, // Ligne divers
}

export enum EOrderFormProviderDetailsType {
  SectionLine = 1, // Ligne de section
  SubSectionLine = 2, // Line de Sous-section
  BreakLineLine = 3, // Ligne de saut de ligne
  BreakLine = 4, // Ligne de saut de page
  SubTotalLine = 5, // Ligne de sous-total
  CommentLine = 6, // Ligne de commentaire
  SupplyLine = 7, // Ligne de fourniture
  ManPowerLine = 8, // Ligne de main d'oeuvre
  EquipmentLine = 9, // Ligne de matériel
  // VariousLine = 10,               // Ligne divers
}

export enum EOrderFormProviderDetailTypeReduce {
  F = 7, // Ligne de fourniture
  MO = 8, // Ligne de main d'oeuvre
  M = 9, // Ligne de matériel
  // A = 10,   // Ligne divers
}

export enum EInputType {
  Text = 1,
  Number = 2,
  Date = 3,
  Time = 4,
  DateTime = 5,
  Email = 6,
  Password = 7,
  Checkbox = 8,
  Radio = 9,
  Select = 10,
  Textarea = 11,
  File = 12,
  Hidden = 13,
  Button = 14,
  Image = 15,
  Color = 16,
  Range = 17,
  Search = 18,
  Switch = 19,
  Slider = 20,
  Editor = 21,
  Tag = 22,
  Margin = 23,
  SelectCatalog = 24,
  Title = 25,
}

export enum EUnit {
  u = "Unité",
  kg = "Kg",
  m2 = "M²",
  m3 = "M³",
  h = "h",
}

export enum EPaymentMethod {
  Cash = 1,
  Cheque = 2,
  Credit = 3,
  Debit = 4,
  Virement = 5,
  Other = 6,
}

export const deliveryFormTypes = [
  { value: 0, label: "Ordre de livraison" },
  { value: 1, label: "Transfertentre dépôts" },
  { value: 2, label: "Remise en main propre" },
];

export const paymentMethods = [
  { value: 1, label: "Espèces" },
  { value: 2, label: "Chèque" },
  { value: 3, label: "Carte de crédit" },
  { value: 4, label: "Carte de débit" },
  { value: 5, label: "Virement" },
  { value: 6, label: "Autre" },
];

export enum EProductType {
  F = 0,
  ST = 1,
  M = 2,
  O = 3,
  T = 4,
  MO = 5,
  A = 6,
}

export enum EPaymentType {
  Débit = 0,
  Crédit = 1,
}

export const paymentTypes = [
  { value: 0, label: "Débit" },
  { value: 1, label: "Crédit" },
];

export enum EProductLabelType {
  "Fourniture" = 0,
  "Sous-traitance" = 1,
  "Matériel" = 2,
  "Ouvrage" = 3,
  "Texte" = 4,
  "Main d'oeuvre" = 5,
  "Autre" = 6,
}

export const productLabelTypes = [
  { value: 0, label: "Fourniture" },
  { value: 1, label: "Sous-traitance" },
  { value: 2, label: "Matériel" },
  { value: 3, label: "Ouvrage" },
  { value: 4, label: "Texte" },
  { value: 5, label: "Main d'oeuvre" },
  { value: 6, label: "Autre" },
];

export enum EDocumentStatus {
  "Brouillon" = 0,
  "À valider" = 1,
  "Validé" = 2,
  "Signé" = 3,
  "Refusé" = 4,
  "Annulé" = 5,
  "Payé" = 6,
  "Partiellement payé" = 7,
  "Vide" = 8,
  "Envoyé" = 9,
  "En retard" = 10,
}

export const statusCheck = {
  documentEditable: [0, 1, 8],
  documentDraft: [0, 8],
  documentCanceledOrRefused: [4, 5],
  orderFormCustomerDisable: [3, 4, 5, 6, 7],
  orderFormCustomerEditable: [0, 1, 2],
  invoiceIntoAccount: [2, 3, 4, 6, 7, 9],
  invoiceCreditAllowed: [1, 2, 6, 7, 9, 10],
  invoiceCreditAvailableForPayment: [2, 7, 9, 10],
};

export let quoteStatus = [
  {
    value: 0,
    label: "Brouillon",
    class: "purple",
    icon: "edit_note",
    query: [0, 8],
  },
  { value: 1, label: "À valider", class: "warning", icon: "fact_check" },
  { value: 2, label: "Validé", class: "primary", icon: "check" },
  { value: 9, label: "Envoyé", class: "primary", icon: "send" },
  { value: 10, label: "En retard", class: "danger", icon: "hourglass_top" },
  { value: 3, label: "Signé", class: "success", icon: "handshake" },
  { value: 4, label: "Refusé", class: "dark", icon: "thumb_down_off_alt" },
  { value: 5, label: "Annulé", class: "dark", icon: "hide_source" },
  { value: 8, label: "Brouillon", class: "purple", icon: "", notUse: true },
];

export let orderFormCustomerStatus = [
  { value: 0, label: "Brouillon", class: "purple", notUse: true },
  {
    value: 1,
    label: "Brouillon",
    class: "purple",
    icon: "edit_note",
    query: [0, 1],
  },
  { value: 2, label: "À valider", class: "warning", icon: "fact_check" },
  {
    value: 3,
    label: "Validé",
    class: "primary",
    icon: "done",
  },
  {
    value: 7,
    label: "Envoyé",
    class: "primary",
    icon: "send",
  },
  {
    label: "Commandes",
    customLabel: "Commandes",
    class: "yellow",
    icon: "list_alt",
    type: "order",
  },
  {
    label: "Facturé",
    customLabel: "Facturé",
    class: "success",
    icon: "done",
    type: "billed",
  },
  {
    value: 4,
    label: "Facturé",
    customLabel: "Reste à Facturer",
    class: "success",
    icon: "hourglass_top",
    type: "remaining",
    query: [3, 4],
  },
  {
    label: "Facturé",
    subLabel: "Terminée",
    customLabel: "100% Facturé",
    class: "success",
    icon: "done",
    type: "done",
  },
  {
    value: 5,
    label: "Refusé",
    class: "danger",
    icon: "thumb_down_off_alt",
    notUse: true,
  },
  {
    value: 6,
    label: "Annulé",
    class: "dark",
    icon: "hide_source",
    notUse: true,
  },
];

export enum EOrderFormCustomerStatus {
  "Vide" = 0,
  "Brouillon" = 1,
  "À valider" = 2,
  "Validée" = 3,
  "Facturé" = 4,
  "Refusé" = 5,
  "Annulé" = 6,
  "Envoyé" = 7,
}

export let invoiceStatus = [
  {
    value: 0,
    label: "Brouillon",
    class: "purple",
    icon: "edit_note",
    query: [0, 8],
  },
  { value: 1, label: "À valider", class: "warning", icon: "fact_check" },
  { value: 2, label: "Validée", class: "primary", icon: "check" },
  { value: 9, label: "Envoyée", class: "primary", icon: "send" },
  { value: 10, label: "En retard", class: "danger", icon: "hourglass_top" },
  {
    label: "Restant dû",
    class: "warning",
    icon: "pending",
    type: "remaining",
    query: [2, 7, 9],
  },
  { value: 7, label: "P. payée", class: "yellow", icon: "add_card" },
  { value: 6, label: "Payée", class: "success", icon: "payment" },
  { value: 5, label: "Annulée", class: "dark", icon: "hide_source" },
  { value: 8, label: "Brouillon", class: "purple", notUse: true },
];

export let holdbackStatus = [
  {
    value: 0,
    label: "En attente",
    class: "purple",
    icon: "edit_note",
  },
  { value: 1, label: "Dû", class: "primary", icon: "fact_check" },
  { value: 2, label: "Payée", class: "success", icon: "payment" },
  { value: 3, label: "P.payée", class: "yellow", icon: "add_card" },
  { value: 4, label: "En retard", class: "danger", icon: "hourglass_top" },
  { value: 5, label: "Annulée", class: "dark", icon: "hide_source" },
];

export const sourceTypes = [
  { label: "Réalisé", value: 1, color: "#0c3571" },
  { label: "Prévu", value: 2, color: "#f9a825ff" },
];

export const directionTypes = [
  { label: "Entrée", value: 1 },
  { label: "Sortie", value: 2 },
];

export let creditStatus = [
  {
    value: 0,
    label: "Brouillon",
    class: "purple",
    icon: "edit_note",
    query: [0, 8],
  },
  { value: 1, label: "À valider", class: "warning", icon: "fact_check" },
  { value: 2, label: "Validé", class: "primary", icon: "check" },
  { value: 9, label: "Envoyé", class: "primary", icon: "send" },
  { value: 10, label: "En retard", class: "danger", icon: "hourglass_top" },
  { value: 7, label: "Part. payé", class: "yellow", icon: "add_card" },
  { value: 6, label: "Payé", class: "success", icon: "payment" },
  { value: 5, label: "Annulé", class: "dark", icon: "hide_source" },
  { value: 8, label: "Brouillon", class: "purple", notUse: true },
];

export enum EOrderFormProviderStatus {
  "Vide" = 0,
  "Brouillon" = 1,
  "À valider" = 2,
  "Validée" = 3,
  "Sur-ordre" = 4,
  "Annulé" = 5,
  "Partiellement réceptionné" = 6,
  "Défaut" = 7,
  "Réceptionné" = 8,
}

export let orderFormProviderStatus = [
  {
    value: 0,
    label: "Brouillon",
    class: "purple",
    icon: "edit_note",
    query: [0, 1],
  },
  { value: 1, label: "Brouillon", class: "purple", notUse: true },
  { value: 2, label: "À valider", class: "warning", icon: "fact_check" },
  { value: 3, label: "Validée", class: "primary", icon: "done" },
  {
    value: 4,
    label: "Sur-ordre",
    class: "primary",
    icon: "switch_access_shortcut_add",
  },
  { value: 5, label: "Annulé", class: "danger", icon: "hide_source" },
  { value: 6, label: "P. récept", class: "yellow", icon: "rule" },
  { value: 7, label: "Défaut", class: "danger", icon: "", notUse: true },
  { value: 8, label: "Réceptionné", class: "success", icon: "inventory" },
];

export enum EReportingStatus {
  "Vide" = 0,
  "Brouillon" = 1,
  "À valider" = 2,
  "Validé" = 3,
  "Refusé" = 4,
  "Suggéré" = -2,
  "À compléter" = -1,
}

export let reportingStatus = [
  {
    value: 0,
    label: "Brouillon",
    class: "purple",
    icon: "edit_note",
    query: [0, 1],
  },
  { value: 1, label: "Brouillon", class: "purple", notUse: true },
  { value: 2, label: "A valider", class: "warning", icon: "fact_check" },
  { value: 3, label: "Validé", class: "success", icon: "done" },
  { value: 4, label: "Refusé", class: "danger", icon: "hide_source" },
  { value: -2, label: "Suggéré", class: "primary", icon: "edit", notUse: true },
  {
    value: -1,
    label: "À compléter",
    class: "primary",
    icon: "edit",
    notUse: true,
  },
];

export enum EInterventionStatus {
  "Brouillon" = 0,
  "À réaliser" = 1,
  "En cours" = 3,
  "Réalisée" = 2,
}

export let interventionStatus = [
  {
    value: 0,
    label: "Brouillon",
    class: "purple",
    icon: "edit_note",
  },
  { value: 1, label: "À réaliser", class: "warning", icon: "fact_check" },
  { value: 3, label: "En cours", class: "yellow", icon: "fact_check" },
  { value: 2, label: "Réalisée", class: "success", icon: "done" },
];

export enum EReceiptFormProviderStatus {
  "Vide" = 0,
  "Brouillon" = 1,
  "À valider" = 2,
  "Réceptionné" = 3,
  "Annulé" = 4,
}

export let receiptFormProviderStatus = [
  {
    value: 0,
    label: "Brouillon",
    class: "purple",
    icon: "edit_note",
    query: [0, 1],
  },
  { value: 1, label: "Brouillon", class: "purple", notUse: true },
  { value: 2, label: "À valider", class: "warning", icon: "fact_check" },
  { value: 3, label: "Réceptionné", class: "success", icon: "inventory" },
  { value: 4, label: "Annulé", class: "danger", icon: "hide_source" },
];

export enum EDeliveryFormStatus {
  "Vide" = 0,
  "Brouillon" = 1,
  "À valider" = 2,
  "Sorti" = 3,
  "Annulé" = 4,
}

export let deliveryFormStatus = [
  {
    value: 0,
    label: "Brouillon",
    class: "purple",
    icon: "edit_note",
    query: [0, 1],
  },
  { value: 1, label: "Brouillon", class: "purple", notUse: true },
  { value: 2, label: "À valider", class: "warning", icon: "fact_check" },
  { value: 3, label: "Sorti", class: "success", icon: "output" },
  { value: 4, label: "Annulé", class: "danger", icon: "hide_source" },
];

export enum EInventoryFormStatus {
  "Vide" = 0,
  "Brouillon" = 1,
  "A valider" = 2,
  "Terminé" = 3,
  "Annulé" = 4,
}

export let inventoryFormStatus = [
  {
    value: 0,
    label: "Brouillon",
    class: "purple",
    icon: "edit_note",
    query: [0, 1],
  },
  { value: 1, label: "Brouillon", class: "purple", notUse: true },
  { value: 2, label: "À valider", class: "warning", icon: "fact_check" },
  { value: 3, label: "Terminé", class: "success", icon: "done" },
  { value: 4, label: "Annulé", class: "danger", icon: "hide_source" },
];

export enum EInvoiceProviderStatus {
  "À traiter" = 0,
  "À valider" = 1,
  "Validée" = 2,
  "Partiellement payée" = 3,
  "Payée" = 4,
  "Ignorée" = 5,
  "Annulée" = 6,
}

export let invoiceProviderStatus = [
  { value: 0, label: "À traiter", class: "purple", icon: "edit_note" },
  { value: 1, label: "À valider", class: "warning", icon: "fact_check" },
  { value: 2, label: "Validée", class: "success", icon: "done" },
  { value: 3, label: "P. payée", class: "yellow", icon: "add_card" },
  { value: 4, label: "Payée", class: "success", icon: "payment" },
  { value: 5, label: "Ignorée", class: "danger", icon: "inventory_2" },
  { value: 6, label: "Annulée", class: "danger", icon: "hide_source" },
];

export enum ECreditProviderStatus {
  "À traiter" = 0,
  "À valider" = 1,
  "Validée" = 2,
  "Partiellement payée" = 3,
  "Payée" = 4,
  "Ignorée" = 5,
  "Annulée" = 6,
}

export let creditProviderStatus = [
  { value: 0, label: "À traiter", class: "purple", icon: "edit_note" },
  { value: 1, label: "À valider", class: "warning", icon: "fact_check" },
  { value: 2, label: "Validé", class: "success", icon: "done" },
  { value: 3, label: "P. payée", class: "yellow", icon: "add_card" },
  { value: 4, label: "Payée", class: "success", icon: "payment" },
  { value: 5, label: "Ignorée", class: "danger", icon: "inventory_2" },
  { value: 6, label: "Annulée", class: "danger", icon: "hide_source" },
];

export enum EStorageLocationType {
  "Bâtiment" = 0,
  "Véhicule" = 1,
}

export enum ELegalForm {
  "En cours d'immatriculation" = 0,
  "Micro Entreprise" = 1,
  "Entrepreneur individuel" = 2,
  "EIRL" = 3,
  "EURL" = 4,
  "SARL" = 5,
  "SAS" = 6,
  "SASU" = 7,
  "SA" = 8,
}

export enum ENature {
  "Devis" = 0,
  "Facture" = 1,
  "Facture acompte" = 2,
  "Situation globale" = 3,
  "Situation détaillée" = 4,
  "Avoir global" = 5,
  "Avoir détaillé" = 6,
}

export let nature = [
  { value: 0, label: "Devis" },
  { value: 1, label: "Facture" },
  { value: 2, label: "Facture acompte" },
  { value: 3, label: "Situation globale" },
  { value: 4, label: "Situation détaillée" },
  { value: 5, label: "Avoir global" },
  { value: 6, label: "Avoir détaillé" },
];

export enum ENatureShort {
  "Devis" = 0,
  "Facture" = 1,
  "Acompte" = 2,
  "Situation G" = 3,
  "Situation D" = 4,
  "Avoir G" = 5,
  "Avoir D" = 6,
}

export let documentApplicationType = [
  { id: 1, label: "Aucun", typeDependant: [1, 2] },
  { id: 2, label: "Devis", typeDependant: [1] },
  { id: 3, label: "Facture", typeDependant: [1] },
  { id: 4, label: "Avoir", typeDependant: [2] },
  { id: 5, label: "Bon de commande fournisseur", typeDependant: [2] },
  { id: 6, label: "Bon de réception", typeDependant: [1, 2] },
  { id: 7, label: "Bon de livraison", typeDependant: [2] },
  { id: 8, label: "Inventaire", typeDependant: [1, 2] },
  { id: 9, label: "Facture fournisseur", typeDependant: [2] },
  { id: 10, label: "Avoir fournisseur", typeDependant: [1] },
  { id: 11, label: "Bon de commande client", typeDependant: [1, 2] },
  { id: 12, label: "Reporting", typeDependant: [2] },
];

export let affairStatus = [
  { value: 0, label: "Brouillon", class: "purple", icon: "edit_note" },
  { value: 5, label: "Étude", class: "warning", icon: "" },
  { value: 1, label: "En cours", class: "warning", icon: "" },
  { value: 2, label: "Suspendue", class: "danger", icon: "" },
  { value: 3, label: "Terminée", class: "success", icon: "done" },
  { value: 4, label: "Annulée", class: "dark", icon: "hide_source" },
];

export let customFieldTemplateType = [
  { value: 0, label: "Affaire" },
  { value: 1, label: "Intervention" },
];

export enum EContractNature {
  "Non reseigné" = 0,
  "CDI" = 1,
  "CDD" = 2,
  "CTT" = 3,
}

export enum EContractStatus {
  "Non renseigné" = 0,
  "Cadre dirigeant" = 1,
  "Cadre autonome" = 2,
  "Cadre intégré" = 3,
  "ETAM" = 4,
  "Ouvrier" = 5,
  "Apprenti" = 6,
  "Stagiaire" = 7,
  "Indépendant" = 8,
}

export const contractNature = [
  { value: 0, id: 0, label: "Non renseigné" },
  { value: 1, id: 1, label: "CDI" },
  { value: 2, id: 2, label: "CDD" },
  { value: 3, id: 3, label: "CTT" },
];

export const contractStatus = [
  { value: 0, id: 0, label: "Non renseigné" },
  { value: 1, id: 1, label: "Cadre dirigeant" },
  { value: 2, id: 2, label: "Cadre autonome" },
  { value: 3, id: 3, label: "Cadre intégré" },
  { value: 4, id: 4, label: "ETAM" },
  { value: 5, id: 5, label: "Ouvrier" },
  { value: 6, id: 6, label: "Apprenti" },
  { value: 7, id: 7, label: "Stagiaire" },
  { value: 8, id: 8, label: "Indépendant" },
];

export const affairZone = [
  { id: 0, value: 1, label: "Zone 1" },
  { id: 1, value: 2, label: "Zone 2" },
  { id: 2, value: 3, label: "Zone 3" },
  { id: 3, value: 4, label: "Zone 4" },
  { id: 4, value: 5, label: "Zone 5" },
  { id: 5, value: 6, label: "Zone 6" },
];
