import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IDeliveryFormHistoryState } from "./states";

export const getters: GetterTree<IDeliveryFormHistoryState, RootState> = {
  deliveryFormHistoriesList: (state): [] => state.deliveryFormHistoriesList,
  deliveryFormHistory: (state): {} => state.deliveryFormHistory,

  // COMMON
  isLoadingDeliveryFormHistoriesList: (state): boolean =>
    state.isLoadingDeliveryFormHistoriesList,
  isLoadingDeliveryFormHistory: (state): boolean =>
    state.isLoadingDeliveryFormHistory,
  requestStateDeliveryFormHistory: (state): IRequestState =>
    state.requestStateDeliveryFormHistory,
};
