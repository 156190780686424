import { IQuoteOptionDefaultState } from "./states";
import { Module } from "vuex";
import { QuoteOptionRequestDto } from "@/../Api";
import RootState from "@/store/modules/rootState";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

const state: IQuoteOptionDefaultState = {
  quoteOptionDefault: <QuoteOptionRequestDto>{},
  quoteOptionDefaultForm: <QuoteOptionRequestDto>{},
  isLoadingQuoteOptionDefault: false,
  requestStateQuoteOptionDefault: <IRequestState>{},
};

export const QuoteOptionDefaultModule: Module<
  IQuoteOptionDefaultState,
  RootState
> = {
  state,
  getters,
  mutations,
  actions,
};
