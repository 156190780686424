import { MutationTree } from "vuex";
import { IInvoiceOptionState } from "./states";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { InvoiceOptionRequestDto } from "Api";

export const enum InvoiceOptionMutations {
  SET_INVOICE_OPTION = "SET_INVOICE_OPTION",
  SET_IS_LOADING_INVOICE_OPTION = "SET_IS_LOADING_INVOICE_OPTION",
  SET_REQUEST_STATE_INVOICE_OPTION = "SET_REQUEST_STATE_INVOICE_OPTION",
}

export const mutations: MutationTree<IInvoiceOptionState> = {
  [InvoiceOptionMutations.SET_INVOICE_OPTION](
    state,
    payload: InvoiceOptionRequestDto
  ) {
    state.invoiceOption = payload;
  },
  [InvoiceOptionMutations.SET_IS_LOADING_INVOICE_OPTION](
    state,
    payload: boolean
  ) {
    state.isLoadingInvoiceOption = payload;
  },
  [InvoiceOptionMutations.SET_REQUEST_STATE_INVOICE_OPTION](
    state,
    payload: IRequestState
  ) {
    state.requestStateInvoiceOption = payload;
  },
};
