import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { IPlanningView } from "@/types/api-orisis/interfaces/Planning/IPlanningViews";

import RootState from "@/store/modules/rootState";
import { IPlanningViewState } from "./states";
import { Module } from "vuex";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

export const state: IPlanningViewState = {
  planningViewsList: Array<IPlanningView>(),
  planningViewsListByAffair: Array<IPlanningView>(),
  planningViewsListArchived: Array<IPlanningView>(),
  planningView: <IPlanningView>{},
  planningViewArchived: <IPlanningView>{},
  planningFilters: {},
  isLoadingPlanningViewsList: false,
  isLoadingPlanningViewsListByAffair: false,
  isLoadingPlanningView: false,
  isCreatingPlanningView: false,
  isUpdatingPlanningView: false,
  isDeletingPlanningView: false,
  isArchivingPlanningView: false,
  requestStatePlanningView: <IRequestState>{},
  isLoadingPlanningViewsListArchived: false,
  isLoadingPlanningViewArchived: false,
};

export const PlanningViewModule: Module<IPlanningViewState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
