import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { PurchaseCategoryRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IPurchaseCategoryState } from "./states";

export enum PurchaseCategoryMutations {
    SET_PURCHASE_CATEGORIES_LIST = "SET_PURCHASE_CATEGORIES_LIST",
    SET_PURCHASE_CATEGORIES_TREE_LIST = "SET_PURCHASE_CATEGORIES_TREE_LIST",
    SET_PURCHASE_CATEGORIES_LIST_ARCHIVED = "SET_PURCHASE_CATEGORIES_LIST_ARCHIVED",
    SET_PURCHASE_CATEGORY = "SET_PURCHASE_CATEGORY",

    // COMMON
    SET_IS_LOADING_PURCHASE_CATEGORIES_LIST = "SET_IS_LOADING_PURCHASE_CATEGORIES_LIST",
    SET_IS_LOADING_PURCHASE_CATEGORIES_TREE_LIST = "SET_IS_LOADING_PURCHASE_CATEGORIES_TREE_LIST",
    SET_IS_LOADING_PURCHASE_CATEGORIES_LIST_ARCHIVED = "SET_IS_LOADING_PURCHASE_CATEGORIES_LIST_ARCHIVED",
    SET_IS_LOADING_PURCHASE_CATEGORY = "SET_IS_LOADING_PURCHASE_CATEGORY",
    SET_IS_CREATING_PURCHASE_CATEGORY = "SET_IS_CREATING_PURCHASE_CATEGORY",
    SET_IS_UPDATING_PURCHASE_CATEGORY = "SET_IS_UPDATING_PURCHASE_CATEGORY",
    SET_IS_DELETING_PURCHASE_CATEGORY = "SET_IS_DELETING_PURCHASE_CATEGORY",
    SET_IS_ARCHIVING_PURCHASE_CATEGORY = "SET_IS_ARCHIVING_PURCHASE_CATEGORY",
    SET_REQUEST_STATE_PURCHASE_CATEGORY = "SET_REQUEST_STATE_PURCHASE_CATEGORY",
}

export const mutations: MutationTree<IPurchaseCategoryState> = {
    [PurchaseCategoryMutations.SET_PURCHASE_CATEGORIES_LIST_ARCHIVED]: (state, payload: PurchaseCategoryRequestDto[]) => {
        state.purchaseCategoriesListArchived = payload;
    }, 
    [PurchaseCategoryMutations.SET_PURCHASE_CATEGORIES_LIST]: (state, payload: PurchaseCategoryRequestDto[]) => {
        state.purchaseCategoriesList = payload;
    },
    [PurchaseCategoryMutations.SET_PURCHASE_CATEGORIES_TREE_LIST]: (state, payload: any[]) => {
        state.purchaseCategoriesTreeList = payload;
    },
    [PurchaseCategoryMutations.SET_PURCHASE_CATEGORY]: (state, payload: PurchaseCategoryRequestDto) => {
        state.purchaseCategory = payload;
    },

    //COMMON
    [PurchaseCategoryMutations.SET_IS_LOADING_PURCHASE_CATEGORIES_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingPurchaseCategoriesListArchived = payload;
    },
    [PurchaseCategoryMutations.SET_IS_LOADING_PURCHASE_CATEGORIES_TREE_LIST]: (state, payload: boolean) => {
        state.isLoadingPurchaseCategoriesTreeList = payload;
    },
    [PurchaseCategoryMutations.SET_IS_LOADING_PURCHASE_CATEGORIES_LIST]: (state, payload: boolean) => {
        state.isLoadingPurchaseCategoriesList = payload;
    },
    [PurchaseCategoryMutations.SET_IS_LOADING_PURCHASE_CATEGORY]: (state, payload: boolean) => {
        state.isLoadingPurchaseCategory = payload;
    },
    [PurchaseCategoryMutations.SET_IS_CREATING_PURCHASE_CATEGORY]: (state, payload: boolean) => {
        state.isCreatingPurchaseCategory = payload;
    },
    [PurchaseCategoryMutations.SET_IS_UPDATING_PURCHASE_CATEGORY]: (state, payload: boolean) => {
        state.isUpdatingPurchaseCategory = payload;
    },
    [PurchaseCategoryMutations.SET_IS_DELETING_PURCHASE_CATEGORY]: (state, payload: boolean) => {
        state.isDeletingPurchaseCategory = payload;
    },
    [PurchaseCategoryMutations.SET_IS_ARCHIVING_PURCHASE_CATEGORY]: (state, payload: boolean) => {
        state.isArchivingPurchaseCategory = payload;
    },
    [PurchaseCategoryMutations.SET_REQUEST_STATE_PURCHASE_CATEGORY]: (state, payload: IRequestState) => {
        state.requestStatePurchaseCategory = payload;
    },
}