import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { ReferencielTVAMutations } from "./mutations";
import { IReferencielTVAState } from "./states";

export const actions: ActionTree<IReferencielTVAState, RootState> = {
  getReferencielTVAs({ commit, rootGetters }, {updateState = true} : {updateState: boolean}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(ReferencielTVAMutations.SET_REFERENCIEL_TVAS_LIST, []);
        commit(ReferencielTVAMutations.SET_IS_LOADING_REFERENCIEL_TVA, true);
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/ReferencielTVA/GetReferencielTVAs"
        )
        .then((res) => {
          if (updateState) {
            commit(ReferencielTVAMutations.SET_IS_LOADING_REFERENCIEL_TVA, false);
            commit(
              ReferencielTVAMutations.SET_REFERENCIEL_TVAS_LIST,
              res.data.sort((a, b) => a.value - b.value)
            );
          }
          commit(
            ReferencielTVAMutations.SET_REQUEST_STATE_REFERENCIEL_TVA,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ReferencielTVAMutations.SET_REQUEST_STATE_REFERENCIEL_TVA,
            err
          );
          if (updateState) {
            commit(ReferencielTVAMutations.SET_IS_LOADING_REFERENCIEL_TVA, false);
          }
          reject(err);
        });
    });
  },

  getReferencielTVAById(
    { commit, rootGetters },
    {referencielTVAId, updateState = true}: {referencielTVAId: number; updateState?: boolean}
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(ReferencielTVAMutations.SET_REFERENCIEL_TVA, null);
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/ReferencielTVA/GetReferencielTVAById",
          {
            params: {
              referencielTVAId: referencielTVAId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(ReferencielTVAMutations.SET_REFERENCIEL_TVA, res.data);
            commit(ReferencielTVAMutations.SET_IS_LOADING_REFERENCIEL_TVA, false);
          }
          commit(ReferencielTVAMutations.SET_REQUEST_STATE_REFERENCIEL_TVA, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ReferencielTVAMutations.SET_REQUEST_STATE_REFERENCIEL_TVA, err);
          if (updateState) {
            commit(ReferencielTVAMutations.SET_IS_LOADING_REFERENCIEL_TVA, false);
          }
          reject(err);
        });
    });
  },
};
