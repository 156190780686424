import { IStockMovementState } from "./states"
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"
import { IFilters } from "@/types/api-orisis/interfaces/invoices/filters/IFilters"

const state:IStockMovementState = {
    stockMovementsList                    : Array<any>(),
    stockMovement                         : <any>{},
    isLoadingStockMovementsList           : false,
    isLoadingStockMovement                : false,
    requestStateStockMovement             : <IRequestState>{},
    stockMovementFilters                  : <IFilters>{},
}

export const StockMovementModule: Module<IStockMovementState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}