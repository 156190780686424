import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { UnitMutations } from "./mutations";
import { IUnitState } from "./states";

export const actions: ActionTree<IUnitState, RootState> = {
  getUnits({ commit, rootGetters }, {updateState = true}: { updateState: boolean }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(UnitMutations.SET_IS_LOADING_UNIT, true);
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS + "Sale/Unit/GetUnits"
        )
        .then((res) => {
          if (updateState) {
            commit(UnitMutations.SET_UNITIES_LIST, res.data);
            commit(UnitMutations.SET_IS_LOADING_UNIT, false);
          }
          commit(UnitMutations.SET_REQUEST_STATE_UNIT, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(UnitMutations.SET_REQUEST_STATE_UNIT, err);
          if (updateState) {
            commit(UnitMutations.SET_IS_LOADING_UNIT, false);
          }
          reject(err);
        });
    });
  },

  getUnitById({ commit, rootGetters }, { unitId, updateState = true }: { unitId: number, updateState: boolean}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(UnitMutations.SET_IS_LOADING_UNIT, true);
      }
      axios
      .get(process.env.VUE_APP_API_ORISIS + "Sale/Unit/GetUnitById", {
        params: {
          unitId: unitId,
        },
      })
      .then((res) => {
        if (updateState) {
          commit(UnitMutations.SET_UNIT, res.data);
          commit(UnitMutations.SET_IS_LOADING_UNIT, false);
        }
        commit(UnitMutations.SET_REQUEST_STATE_UNIT, res);
        resolve(res.data);
      })
      .catch((err) => {
        console.error(err);
        commit(UnitMutations.SET_REQUEST_STATE_UNIT, err);
        if (updateState) {
          commit(UnitMutations.SET_IS_LOADING_UNIT, false);
        }
        reject(err);
      });
    });
  },
};
