import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { SmtpSettingRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { ISmtpSettingState } from './states'


export const getters: GetterTree<ISmtpSettingState, RootState> = {
  smtpSettingList: (state): SmtpSettingRequestDto[] => state.smtpSettingList,
  smtpSettingListArchived: (state): SmtpSettingRequestDto[] => state.smtpSettingListArchived,
  smtpSetting: (state): SmtpSettingRequestDto => state.smtpSetting,
  isLoadingSmtpSettingList: (state): boolean => state.isLoadingSmtpSettingList,
  isLoadingSmtpSettingListArchived: (state): boolean => state.isLoadingSmtpSettingListArchived,
  isLoadingSmtpSetting: (state): boolean => state.isLoadingSmtpSetting,
  isCreatingSmtpSetting: (state): boolean => state.isCreatingSmtpSetting,
  isUpdatingSmtpSetting: (state): boolean => state.isUpdatingSmtpSetting,
  isDeletingSmtpSetting: (state): boolean => state.isDeletingSmtpSetting,
  isArchivingSmtpSetting: (state): boolean => state.isArchivingSmtpSetting,
  requestStateSmtpSetting: (state): IRequestState => state.requestStateSmtpSetting,
}



