import { IInstitutionSettings } from "@/types/api-orisis/interfaces/Settings/institutionSettings/IInstitutionSettings";

export let InstitutionSettingsModel: IInstitutionSettings = {
  id: 0,
  institutionId: 0,
  supplyMarginDefault: 0,
  manPowerMarginDefault: 0,
  subContractingMarginDefault: 0,
  equipementMarginDefault: 0,
  variousMarginDefault: 0,
  footer: "string",
  footerType: 0,
  laborMarginDefault: 0,
  termsOfSales: "string",
  holdBackAccount: "string",
  forceSuggestAffair: true,
  referencielTvas: [],
  paymentConditionOrderFormDefaultId: null,
  paymentConditionOrderFormCustomerDefault: null,
};
