import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { StockRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { IStockState } from './states'
import { IFilters } from '@/types/api-orisis/interfaces/invoices/filters/IFilters'


export const getters: GetterTree<IStockState, RootState> = {
  productsWithStockList          : (state): [] => state.productsWithStockList,
  stocksList                     : (state): StockRequestDto[] => state.stocksList,
  stocksListArchived             : (state): StockRequestDto[] => state.stocksListArchived,
  stock                          : (state): StockRequestDto => state.stock,
  stockArchived                  : (state): StockRequestDto => state.stockArchived,

  // COMMON
  isLoadingProductsWithStocksList            : (state): boolean => state.isLoadingProductsWithStocksList,
  isLoadingStocksList            : (state): boolean => state.isLoadingStocksList,
  isLoadingStocksListArchived    : (state): boolean => state.isLoadingStocksListArchived,
  isLoadingStock                 : (state): boolean => state.isLoadingStock,
  isLoadingStockArchived         : (state): boolean => state.isLoadingStockArchived,
  isCreatingStock                : (state): boolean => state.isCreatingStock,
  isUpdatingStock                : (state): boolean => state.isUpdatingStock,
  isRestoringStock               : (state): boolean => state.isRestoringStock,
  isDeletingStock                : (state): boolean => state.isDeletingStock,
  isArchivingStock              : (state): boolean => state.isArchivingStock,
  requestStateStock             : (state): IRequestState => state.requestStateStock,
}



