import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { OrderFormCustomerDownPaymentsRequestRequestDto } from "Api";
import { GetterTree } from "vuex";
import RootState from '@/store/modules/rootState'
import {  IOrderFormCustomerDownPaymentsRequestState } from "./states";

export const getters: GetterTree<IOrderFormCustomerDownPaymentsRequestState, RootState> = {
    orderFormCustomerDownPaymentsRequestsList: (state): OrderFormCustomerDownPaymentsRequestRequestDto[] => state.orderFormCustomerDownPaymentsRequestsList,
    orderFormCustomerDownPaymentsRequestsListArchived: (state): OrderFormCustomerDownPaymentsRequestRequestDto[] => state.orderFormCustomerDownPaymentsRequestsListArchived,
    orderFormCustomerDownPaymentsRequest     : (state): OrderFormCustomerDownPaymentsRequestRequestDto => state.orderFormCustomerDownPaymentsRequest,
    
    // COMMON
    isLoadingOrderFormCustomerDownPaymentsRequestsList   : (state): boolean => state.isLoadingOrderFormCustomerDownPaymentsRequestsList,
    isLoadingOrderFormCustomerDownPaymentsRequestsListArchived : (state): boolean => state.isLoadingOrderFormCustomerDownPaymentsRequestsListArchived,
    isLoadingOrderFormCustomerDownPaymentsRequest : (state): boolean => state.isLoadingOrderFormCustomerDownPaymentsRequest,
    isCreatingOrderFormCustomerDownPaymentsRequest  : (state): boolean => state.isCreatingOrderFormCustomerDownPaymentsRequest,
    isUpdatingOrderFormCustomerDownPaymentsRequest : (state): boolean => state.isUpdatingOrderFormCustomerDownPaymentsRequest,
    isDeletingOrderFormCustomerDownPaymentsRequest : (state): boolean => state.isDeletingOrderFormCustomerDownPaymentsRequest,
    requestStateOrderFormCustomerDownPaymentsRequest: (state): IRequestState => state.requestStateOrderFormCustomerDownPaymentsRequest,
    isArchivingOrderFormCustomerDownPaymentsRequest : (state): boolean => state.isArchivingOrderFormCustomerDownPaymentsRequest,
}