import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IOrderFormCustomerOptionDefaultState } from "./states";
import { OrderFormCustomerOptionRequestDto } from "@/../Api";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export const getters: GetterTree<
  IOrderFormCustomerOptionDefaultState,
  RootState
> = {
  orderFormCustomerOptionDefault: (state): OrderFormCustomerOptionRequestDto =>
    state.orderFormCustomerOptionDefault,
  orderFormCustomerOptionDefaultForm: (state): OrderFormCustomerOptionRequestDto =>
    state.orderFormCustomerOptionDefaultForm,
  isLoadingOrderFormCustomerOptionDefault: (state): boolean =>
    state.isLoadingOrderFormCustomerOptionDefault,
  requestStateOrderFormCustomerOptionDefault: (state): IRequestState =>
    state.requestStateOrderFormCustomerOptionDefault,
};
