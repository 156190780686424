import { PeriodicityRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { PeriodicityMutations } from "./mutations";
import { IPeriodicityState } from "./states";

export const actions: ActionTree<IPeriodicityState, RootState> = {
  async getPeriodicities(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(PeriodicityMutations.SET_IS_LOADING_PERIODICITY_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Management/Periodicity/GetPeriodicities`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(PeriodicityMutations.SET_IS_LOADING_PERIODICITY_LIST, false);
            commit(PeriodicityMutations.SET_PERIODICITIES_LIST, res.data);
          }
          commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PeriodicityMutations.SET_IS_LOADING_PERIODICITY_LIST, false);
          commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, err);
          reject(err);
        });
    });
  },

  async getPeriodicityById(
    { commit },
    {
      periodicityId,
      updateState = true,
    }: { periodicityId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(PeriodicityMutations.SET_IS_LOADING_PERIODICITY, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Management/Periodicity/GetPeriodicityById`;
      await axios
        .get(url, {
          params: {
            periodicityId: periodicityId,
          },
        })
        .then((res) => {
          if (updateState) {
            commit(PeriodicityMutations.SET_PERIODICITY, res.data);
            commit(PeriodicityMutations.SET_IS_LOADING_PERIODICITY, false);
          }
          commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, err);
          commit(PeriodicityMutations.SET_IS_LOADING_PERIODICITY, false);
          reject(err);
        });
    });
  },

  async getPeriodicityArchivedById(
    { commit },
    {
      periodicityId,
      updateState = true,
    }: { periodicityId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(PeriodicityMutations.SET_IS_LOADING_PERIODICITY, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Management/Periodicity/GetPeriodicityArchivedById`;
      await axios
        .get(url, {
          params: {
            periodicityId: periodicityId,
          },
        })
        .then((res) => {
          if (updateState) {
            commit(PeriodicityMutations.SET_PERIODICITY, res.data);
            commit(PeriodicityMutations.SET_IS_LOADING_PERIODICITY, false);
          }
          commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, err);
          commit(PeriodicityMutations.SET_IS_LOADING_PERIODICITY, false);
          reject(err);
        });
    });
  },

  async getPeriodicitiesArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          PeriodicityMutations.SET_IS_LOADING_PERIODICITY_LIST_ARCHIVED,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Management/Periodicity/GetPeriodicitiesArchived`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              PeriodicityMutations.SET_IS_LOADING_PERIODICITY_LIST_ARCHIVED,
              false
            );
            commit(
              PeriodicityMutations.SET_PERIODICITIES_LIST_ARCHIVED,
              res.data
            );
          }
          commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            PeriodicityMutations.SET_IS_LOADING_PERIODICITY_LIST_ARCHIVED,
            false
          );
          commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, err);
          reject(err);
        });
    });
  },

  async createPeriodicity(
    { commit, rootGetters },
    { periodicity }: { periodicity: PeriodicityRequestDto }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(PeriodicityMutations.SET_IS_CREATING_PERIODICITY, true);
      let url = `${process.env.VUE_APP_API_ORISIS}Management/Periodicity/CreatePeriodicity`;
      await axios
        .post(url, periodicity)
        .then((res) => {
          rootGetters.periodicitiesList.push(res.data.data);
          commit(
            PeriodicityMutations.SET_PERIODICITIES_LIST,
            rootGetters.periodicitiesList
          );
          commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, res);
          commit(PeriodicityMutations.SET_IS_CREATING_PERIODICITY, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, err);
          commit(PeriodicityMutations.SET_IS_CREATING_PERIODICITY, false);
          reject(err);
        });
    });
  },

  async updatePeriodicity(
    { commit, rootGetters },
    { periodicity }: { periodicity: PeriodicityRequestDto }
  ) {
    return new Promise(async (resolve, reject) => {
      commit(PeriodicityMutations.SET_IS_UPDATING_PERIODICITY, true);
      let url = `${process.env.VUE_APP_API_ORISIS}Management/Periodicity/UpdatePeriodicity`;
      await axios
        .put(url, periodicity)
        .then((res) => {
          rootGetters.periodicitiesList.splice(
            rootGetters.periodicitiesList.findIndex(
              (elem) => elem.id == periodicity.id
            ),
            1,
            periodicity
          );
          commit(
            PeriodicityMutations.SET_PERIODICITIES_LIST,
            rootGetters.periodicitiesList
          );
          commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, res);
          commit(PeriodicityMutations.SET_IS_UPDATING_PERIODICITY, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, err);
          commit(PeriodicityMutations.SET_IS_UPDATING_PERIODICITY, false);
          reject(err);
        });
    });
  },

  async restorePeriodicity(
    { commit, rootGetters },
    { periodicityIds = [] }: { periodicityIds: number[] }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(PeriodicityMutations.SET_IS_UPDATING_PERIODICITY, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Management/Periodicity/Restore",
          periodicityIds
        )
        .then((res) => {
          periodicityIds.forEach((periodicityId) => {
            rootGetters.periodicitiesList.unshift(
              rootGetters.periodicitiesListArchived.find(
                (elem) => elem.id == periodicityId
              )
            );
            rootGetters.periodicitiesListArchived.splice(
              rootGetters.periodicitiesListArchived.findIndex(
                (elem) => elem.id == periodicityId
              ),
              1
            );
          });
          commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, res);
          commit(PeriodicityMutations.SET_IS_UPDATING_PERIODICITY, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, err);
          commit(PeriodicityMutations.SET_IS_UPDATING_PERIODICITY, false);
          reject(err);
        });
    });
  },

  async archivePeriodicity(
    { commit, rootGetters },
    { periodicityIds = [] }: { periodicityIds: number[] }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(PeriodicityMutations.SET_IS_ARCHIVING_PERIODICITY, true);
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Management/Periodicity/Archive",
          {
            data: periodicityIds,
          }
        )
        .then((res) => {
          periodicityIds.forEach((periodicityId: number) => {
            rootGetters.periodicitiesListArchived.unshift(
              rootGetters.periodicitiesList.find(
                (elem) => elem.id == periodicityId
              )
            );
            rootGetters.periodicitiesList.splice(
              rootGetters.periodicitiesList.findIndex(
                (elem) => elem.id == periodicityId
              ),
              1
            );
          });
          commit(
            PeriodicityMutations.SET_PERIODICITIES_LIST,
            rootGetters.periodicitiesList
          );
          commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, res);
          commit(PeriodicityMutations.SET_IS_ARCHIVING_PERIODICITY, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, err);
          commit(PeriodicityMutations.SET_IS_ARCHIVING_PERIODICITY, false);
          reject(err);
        });
    });
  },

  async deletePeriodicity(
    { commit, rootGetters },
    { periodicityIds = [] }: { periodicityIds: number[] }
  ) {
    return new Promise(async (resolve, reject) => {
      commit(PeriodicityMutations.SET_IS_DELETING_PERIODICITY, true);
      await axios
        .delete(
          `${process.env.VUE_APP_API_ORISIS}Management/Periodicity/Delete`,
          { data: periodicityIds }
        )
        .then((res) => {
          periodicityIds.forEach((periodicityId: number) => {
            rootGetters.periodicitiesList.splice(
              rootGetters.periodicitiesList.findIndex(
                (elem) => elem.id == periodicityId
              ),
              1
            );
          });
          commit(
            PeriodicityMutations.SET_PERIODICITIES_LIST,
            rootGetters.periodicitiesList
          );
          commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, res);
          commit(PeriodicityMutations.SET_IS_DELETING_PERIODICITY, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, err);
          commit(PeriodicityMutations.SET_IS_DELETING_PERIODICITY, false);
          reject(err);
        });
    });
  },
};
