import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { AffairCategoryRequestDto } from "@/../Api";
import { MutationTree } from "vuex";
import { IAffairCategoryState } from "./states";

export enum AffairCategoryMutations {
  SET_AFFAIR_CATEGORIES_LIST = "SET_AFFAIR_CATEGORIES_LIST",
  SET_AFFAIR_CATEGORIES_TREE_LIST = "SET_AFFAIR_CATEGORIES_TREE_LIST",
  SET_AFFAIR_CATEGORIES_TREE_LIST_ARCHIVED = "SET_AFFAIR_CATEGORIES_TREE_LIST_ARCHIVED",
  SET_AFFAIR_CATEGORIES_LIST_ARCHIVED = "SET_AFFAIR_CATEGORIES_LIST_ARCHIVED",
  SET_AFFAIR_CATEGORY = "SET_AFFAIR_CATEGORY",

  // COMMON
  SET_IS_LOADING_AFFAIR_CATEGORIES_LIST = "SET_IS_LOADING_AFFAIR_CATEGORIES_LIST",
  SET_IS_LOADING_AFFAIR_CATEGORIES_TREE_LIST = "SET_IS_LOADING_AFFAIR_CATEGORIES_TREE_LIST",
  SET_IS_LOADING_AFFAIR_CATEGORIES_TREE_LIST_ARCHIVED = "SET_IS_LOADING_AFFAIR_CATEGORIES_TREE_LIST_ARCHIVED",
  SET_IS_LOADING_AFFAIR_CATEGORIES_LIST_ARCHIVED = "SET_IS_LOADING_AFFAIR_CATEGORIES_LIST_ARCHIVED",
  SET_IS_LOADING_AFFAIR_CATEGORY = "SET_IS_LOADING_AFFAIR_CATEGORY",
  SET_IS_CREATING_AFFAIR_CATEGORY = "SET_IS_CREATING_AFFAIR_CATEGORY",
  SET_IS_UPDATING_AFFAIR_CATEGORY = "SET_IS_UPDATING_AFFAIR_CATEGORY",
  SET_IS_DELETING_AFFAIR_CATEGORY = "SET_IS_DELETING_AFFAIR_CATEGORY",
  SET_IS_ARCHIVING_AFFAIR_CATEGORY = "SET_IS_ARCHIVING_AFFAIR_CATEGORY",
  SET_REQUEST_STATE_AFFAIR_CATEGORY = "SET_REQUEST_STATE_AFFAIR_CATEGORY",
}

export const mutations: MutationTree<IAffairCategoryState> = {
  [AffairCategoryMutations.SET_AFFAIR_CATEGORIES_LIST_ARCHIVED]: (
    state,
    payload: AffairCategoryRequestDto[]
  ) => {
    state.affairCategoriesListArchived = payload;
  },
  [AffairCategoryMutations.SET_AFFAIR_CATEGORIES_LIST]: (
    state,
    payload: AffairCategoryRequestDto[]
  ) => {
    state.affairCategoriesList = payload;
  },
  [AffairCategoryMutations.SET_AFFAIR_CATEGORIES_TREE_LIST]: (
    state,
    payload: any[]
  ) => {
    state.affairCategoriesTreeList = payload;
  },
  [AffairCategoryMutations.SET_AFFAIR_CATEGORIES_TREE_LIST_ARCHIVED]: (
    state,
    payload: any[]
  ) => {
    state.affairCategoriesTreeListArchived = payload;
  },
  [AffairCategoryMutations.SET_AFFAIR_CATEGORY]: (
    state,
    payload: AffairCategoryRequestDto
  ) => {
    state.affairCategory = payload;
  },

  //COMMON
  [AffairCategoryMutations.SET_IS_LOADING_AFFAIR_CATEGORIES_LIST_ARCHIVED]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingAffairCategoriesListArchived = payload;
  },
  [AffairCategoryMutations.SET_IS_LOADING_AFFAIR_CATEGORIES_TREE_LIST]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingAffairCategoriesTreeList = payload;
  },
  [AffairCategoryMutations.SET_IS_LOADING_AFFAIR_CATEGORIES_TREE_LIST_ARCHIVED]:
    (state, payload: boolean) => {
      state.isLoadingAffairCategoriesTreeListArchived = payload;
    },
  [AffairCategoryMutations.SET_IS_LOADING_AFFAIR_CATEGORIES_LIST]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingAffairCategoriesList = payload;
  },
  [AffairCategoryMutations.SET_IS_LOADING_AFFAIR_CATEGORY]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingAffairCategory = payload;
  },
  [AffairCategoryMutations.SET_IS_CREATING_AFFAIR_CATEGORY]: (
    state,
    payload: boolean
  ) => {
    state.isCreatingAffairCategory = payload;
  },
  [AffairCategoryMutations.SET_IS_UPDATING_AFFAIR_CATEGORY]: (
    state,
    payload: boolean
  ) => {
    state.isUpdatingAffairCategory = payload;
  },
  [AffairCategoryMutations.SET_IS_DELETING_AFFAIR_CATEGORY]: (
    state,
    payload: boolean
  ) => {
    state.isDeletingAffairCategory = payload;
  },
  [AffairCategoryMutations.SET_IS_ARCHIVING_AFFAIR_CATEGORY]: (
    state,
    payload: boolean
  ) => {
    state.isArchivingAffairCategory = payload;
  },
  [AffairCategoryMutations.SET_REQUEST_STATE_AFFAIR_CATEGORY]: (
    state,
    payload: IRequestState
  ) => {
    state.requestStateAffairCategory = payload;
  },
};
