import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { WasteCollectionPointRequestDto } from "Api";
import RootState from "@/store/modules/rootState"
import { IWasteCollectionPointState } from "./states";
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

export const state: IWasteCollectionPointState = {
    wasteCollectionPointsList                  : Array<WasteCollectionPointRequestDto>(),
    wasteCollectionPointsListArchived          : Array<WasteCollectionPointRequestDto>(),
    wasteCollectionPoint                       : <WasteCollectionPointRequestDto>{},
    isLoadingWasteCollectionPointsList         : false,
    isLoadingWasteCollectionPointsListArchived : false,
    isLoadingWasteCollectionPoint              : false,
    isCreatingWasteCollectionPoint             : false,
    isUpdatingWasteCollectionPoint             : false,
    isDeletingWasteCollectionPoint             : false,
    requestStateWasteCollectionPoint           : <IRequestState>{},
    isArchivingWasteCollectionPoint            : false,
}

export const WasteCollectionPointModule: Module<IWasteCollectionPointState, RootState> = {
  state, 
  getters,
  mutations,
  actions
}