export function CommonLine(payload) {
  return {
    index: payload.index,
    type: payload.type,
    description: payload.description ? payload.description : null,
    quantity: payload.quantity ? payload.quantity : 1,
    unitId: payload.unit ? payload.unit : 1,
    unitPriceHt: payload.unitPriceHt ? payload.unitPriceHt : 0,
    referencielTvaId: payload.referencielTvaId
      ? payload.referencielTvaId
      : null,
    disbursedNetHt: payload.disbursedNetHt ? payload.disbursedNetHt : 0,
    marginBrutHt: payload.marginBrutHt ? payload.marginBrutHt : null,
    marginRate: payload.marginRate ? payload.marginRate : 0,
    marginCoefficient: payload.marginCoefficient ? payload.marginCoefficient : 0,
    quantityDeducted: 0,
    reference: null,
    situationProgress: 0,
    previousSituationProgress: 0,
    situationProgressQuantity: 0,
    previousSituationProgressQuantity: 0,
    publicPrice: null,
    discount: 0,
    companyPriceId: null,
    total: payload.total ? payload.total : 0,
    parentId: payload.parentId ? payload.parentId : null,
    quoteId: payload.quoteId,
    invoiceId: payload.invoiceId,
    creditId: payload.creditId,
    orderFormCustomerId: payload.orderFormCustomerId,
    productId: null,
    isOption: false,
  };
}
export function CommonLineOrderForm(payload) {
  return {
    index: payload.index,
    type: payload.type,
    description: payload.description ? payload.description : null,
    quantity: payload.quantity ? payload.quantity : 1,
    unitId: payload.unit ? payload.unit : 1,
    unitPriceHt: payload.unitPriceHt ? payload.unitPriceHt : 0,
    marginCoefficient: payload.marginCoefficient ? payload.marginCoefficient : 0,
    referencielTvaId: payload.referencielTvaId
      ? payload.referencielTvaId
      : null,
    reference: null,
    discount: 0,
    total: payload.total ? payload.total : 0,
    amountHt: 0,
    orderFormId: payload.orderFormId,
    productProviderId: null,
  };
}
export function CommonLineOrderFormProvider(payload) {
  return {
    index: payload.index,
    type: payload.type,
    description: payload.description ? payload.description : null,
    quantity: payload.quantity ? payload.quantity : 1,
    unitId: payload.unit ? payload.unit : 1,
    unitPriceHt: payload.unitPriceHt ? payload.unitPriceHt : 0,
    referencielTvaId: payload.referencielTvaId
      ? payload.referencielTvaId
      : null,
    reference: null,
    discount: 0,
    total: payload.total ? payload.total : 0,
    amountHt: 0,
    orderFormId: payload.orderFormProviderId,
    productProviderId: null,
  };
}
export function CommonLineReceiptFormProvider(payload) {
  return {
    index: payload.index,
    type: payload.type,
    description: payload.description ? payload.description : null,
    receivedQuantity: payload.receivedQuantity ? payload.receivedQuantity : 0,
    previousQuantitiesReceived: payload.previousQuantitiesReceived
      ? payload.previousQuantitiesReceived
      : 0,
    orderedQuantity: payload.orderedQuantity ? payload.orderedQuantity : 0,
    unitId: payload.unit ? payload.unit : 1,
    reference: null,
    receiptFormId: payload.receiptFormProviderId,
    productId: null,
  };
}
export function CommonLineDeliveryForm(payload) {
  return {
    index: payload.index,
    type: payload.type,
    unitPriceHt: payload.unitPriceHt ? payload.unitPriceHt : 0,
    referencielTvaId: payload.referencielTvaId
      ? payload.referencielTvaId
      : null,
    description: payload.description ? payload.description : null,
    outPutQuantity: payload.outPutQuantity ? payload.outPutQuantity : 1,
    unitId: payload.unit ? payload.unit : 1,
    reference: null,
    deliveryFormId: payload.deliveryFormId,
    productId: null,
    total: payload.total ? payload.total : 0,
  };
}
