export function OrderFormDetailDefaultProperties({
  index = 0,
  id = 0,
  reference = "",
  description = "",
  productProviderId = null,
  analyticSettingId = null,
  orderFormId = null,
  type = 0,
  unitId = null,
  referencielTvaId = null,
  quantity = 0,
  discount = 0,
  unitPriceHt = 0,
  amountHt = 0,
}) {
  return {
    id: id,
    index: index,
    reference: reference,
    description: description,
    quantity: quantity,
    discount: discount,
    unitPriceHt: unitPriceHt,
    type: type,
    unitId: unitId,
    amountHt: amountHt,
    referencielTvaId: referencielTvaId,
    productProviderId: productProviderId,
    orderFormId: orderFormId,
    analyticSettingId: analyticSettingId,
  };
}
