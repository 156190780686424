import { UserParameterRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { UserParameterMutations } from "./mutations";
import { IUserParameterState } from "./states";
import { IFilters } from "@/types/api-orisis/interfaces/invoices/filters/IFilters";

import { OrderFormProviderMutations } from "../../purchase/orderFormProvider/mutations";
import { InventoryMutations } from "../../purchase/inventory/mutations";
import { StockMutations } from "../../purchase/stock/mutations";
import { StockMovementMutations } from "../../purchase/stockMovement/mutations";
import { AffairCostingMutations } from "../../directory/affairCosting/mutations";
import { ActivityMutations } from "../../directory/activity/mutations";
import { InvoiceProviderMutations } from "../../purchase/invoiceProvider/mutations";
import { CreditProviderMutations } from "../../purchase/creditProvider/mutations";
import { OrderFormCustomerMutations } from "../../invoices/orderFormCustomer/mutations";
import { PaymentMutations } from "../../invoices/payment/mutations";
import { DocumentMutations } from "../../invoices/document/mutations";
import { PlanningViewMutations } from "../../planning/planningView/mutations";
import { DeliveryFormMutations } from "../../purchase/deliveryForm/mutations";
import { PaymentProviderMutations } from "../../purchase/paymentProvider/mutations";
import { MissionMutations } from "../../reporting/mission/mutations";
import { ReceiptFormProviderMutations } from "../../purchase/receiptFormProvider/mutations";
import { InterventionMutations } from "../../interventions/mutations";
import { ReportDetailMutations } from "../../reporting/reportDetail/mutations";

var dayjs = require("dayjs");

export const actions: ActionTree<IUserParameterState, RootState> = {
  getUserParametersByUserId(
    { commit, rootGetters },
    {
      userId,
      updateState = true,
      isConnectedUser = true,
    }: { userId: number; updateState: boolean; isConnectedUser: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(UserParameterMutations.SET_IS_LOADING_USER_PARAMETER, true);
      }
      axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Account/UserParameter/GetUserParametersByUserId`,
          {
            params: {
              userId: userId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            if (isConnectedUser) {
              commit(
                UserParameterMutations.SET_IS_LOADING_USER_PARAMETER,
                false
              );
              commit(UserParameterMutations.SET_USER_PARAMETERS_LIST, res.data);
              const homePage = res.data.find(
                (elem) => elem.key == "homePage"
              );
              if (homePage) {
                localStorage.setItem("homePage", homePage.value);
              }
            } else {
              commit(
                UserParameterMutations.SET_IS_LOADING_USER_PARAMETER,
                false
              );
              commit(
                UserParameterMutations.SET_USER_PARAMETERS_LIST_BY_USER,
                res.data
              );
            }
          }
          commit(UserParameterMutations.SET_REQUEST_STATE_USER_PARAMETER, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(UserParameterMutations.SET_REQUEST_STATE_USER_PARAMETER, err);
          if (updateState) {
            commit(UserParameterMutations.SET_IS_LOADING_USER_PARAMETER, false);
          }
          reject(err);
        });
    });
  },

  getUserParameterByKey(
    { commit, rootGetters },
    { key, updateState = true }: { key: string; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(UserParameterMutations.SET_IS_LOADING_USER_PARAMETER, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Account/UserParameter/GetUserParameterByKey`;
      axios
        .get(url, {
          params: {
            key: key,
            userId: rootGetters.connectedUser.id,
          },
        })
        .then((res) => {
          if (updateState) {
            commit(UserParameterMutations.SET_IS_LOADING_USER_PARAMETER, false);
            commit(UserParameterMutations.SET_USER_PARAMETER, res.data);
          }
          commit(UserParameterMutations.SET_USER_PARAMETER, res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(UserParameterMutations.SET_IS_LOADING_USER_PARAMETER, false);
          }
          commit(UserParameterMutations.SET_REQUEST_STATE_USER_PARAMETER, err);
          reject(err);
        });
    });
  },

  createOrUpdateUserParameter(
    { commit, rootGetters, dispatch },
    {
      label,
      key,
      value,
      userId = rootGetters.connectedUser.id,
    }: {
      label: string;
      key: string;
      value: string;
      userId?: number;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(UserParameterMutations.SET_IS_LOADING_USER_PARAMETER, true);
      let userParameter = {
        id: 0, // n'est pas utiliser côté back
        label: label,
        key: key,
        value: value,
        userId: userId,
      } as UserParameterRequestDto;
      let url = `${process.env.VUE_APP_API_ORISIS}Account/UserParameter/CreateOrUpdateUserParameter`;
      axios
        .put(url, userParameter)
        .then((res) => {
          dispatch("getUserParametersByUserId", {
            userId: rootGetters.connectedUser.id,
          });
          commit(UserParameterMutations.SET_USER_PARAMETER, res.data.data);
          commit(UserParameterMutations.SET_IS_LOADING_USER_PARAMETER, false);
          commit(UserParameterMutations.SET_REQUEST_STATE_USER_PARAMETER, res);
          commit(UserParameterMutations.SET_USER_PARAMETER, res.data.data);
          commit(UserParameterMutations.SET_USER_PARAMETERS_LIST, [
            ...rootGetters.userParametersList.map((elem) =>
              elem.key !== res.data.data.key ? elem : res.data.data
            ),
          ]);
          commit(UserParameterMutations.SET_USER_PARAMETERS_LIST_BY_USER, [
            ...rootGetters.userParametersListByUser.map((elem) =>
              elem.key !== res.data.data.key ? elem : res.data.data
            ),
          ]);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(UserParameterMutations.SET_IS_LOADING_USER_PARAMETER, false);
          commit(UserParameterMutations.SET_REQUEST_STATE_USER_PARAMETER, err);
          reject(err);
        });
    });
  },

  setFilter(
    { dispatch, commit, rootGetters },
    {
      name: name,
      filter: filter,
      save: save = true,
    }: { name: string; filter: IFilters; change: boolean; save: boolean }
  ): Promise<void> {
    return new Promise((resolve) => {
      if (save) {
        let label = "Période de filtrage";
        switch (name) {
          case "paymentFilters":
            label = "Période par défaut des règlements";
            commit(PaymentMutations.SET_PAYMENT_FILTERS, { filter });
            localStorage.setItem("paymentFilters", JSON.stringify(filter));
            break;
          case "paymentProviderFilters":
            label = "Période par défaut des règlements fournisseur";
            commit(PaymentProviderMutations.SET_PAYMENT_PROVIDER_FILTERS, {
              filter,
            });
            localStorage.setItem(
              "paymentProviderFilters",
              JSON.stringify(filter)
            );
            break;
          case "myDashbordFilters":
            label = "Période par défaut de mon agenda personnel";
            localStorage.setItem("myDashbordFilters", JSON.stringify(filter));
            break;
          case "dashboardBauxFilters":
            label = "Période par défaut du tableau de bord de gestion des baux";
            localStorage.setItem(
              "dashboardBauxFilters",
              JSON.stringify(filter)
            );
            break;
          case "dashboardInvoiceFilters":
            label = "Période par défaut du tableau de bord de facturation";
            localStorage.setItem(
              "dashboardInvoiceFilters",
              JSON.stringify(filter)
            );
            break;
          case "dashboardCRMFilters":
            label = "Période par défaut du tableau de bord CRM";
            localStorage.setItem("dashboardCRMFilters", JSON.stringify(filter));
            break;
          case "quoteFilters":
            label = "Période par défaut de la liste des devis";
            commit(DocumentMutations.SET_QUOTE_FILTERS, { filter });
            localStorage.setItem("quoteFilters", JSON.stringify(filter));
            break;
          case "invoiceFilters":
            label = "Période par défaut de la liste des factures";
            commit(DocumentMutations.SET_INVOICE_FILTERS, filter);
            localStorage.setItem("invoiceFilters", JSON.stringify(filter));
            break;
          case "creditFilters":
            label = "Période par défaut de la liste des avoirs";
            commit(DocumentMutations.SET_CREDIT_FILTERS, filter);
            localStorage.setItem("creditFilters", JSON.stringify(filter));
            break;
          case "orderFormFilters":
            label = "Période par défaut de la liste des commandes fournisseur";
            commit(OrderFormProviderMutations.SET_ORDER_FORM_FILTERS, filter);
            localStorage.setItem("orderFormFilters", JSON.stringify(filter));
            break;
          case "deliveryFormFilters":
            label = "Période par défaut de la liste des bons de sortie";
            commit(DeliveryFormMutations.SET_DELIVERY_FORM_FILTERS, filter);
            localStorage.setItem("deliveryFormFilters", JSON.stringify(filter));
            break;
          case "inventoryFilters":
            label = "Période par défaut de la liste des inventaires";
            commit(InventoryMutations.SET_INVENTORY_FILTERS, filter);
            localStorage.setItem("inventoryFilters", JSON.stringify(filter));
            break;
          case "stockMovementFilters":
            label = "Période par défaut de la liste des mouvements de stock";
            commit(StockMovementMutations.SET_STOCK_MOVEMENT_FILTERS, filter);
            localStorage.setItem(
              "stockMovementFilters",
              JSON.stringify(filter)
            );
            break;
          case "invoiceProviderFilters":
            label = "Période par défaut de la liste des factures fournisseur";
            commit(
              InvoiceProviderMutations.SET_INVOICE_PROVIDER_FILTERS,
              filter
            );
            localStorage.setItem(
              "invoiceProviderFilters",
              JSON.stringify(filter)
            );
            break;
          case "creditProviderFilters":
            label = "Période par défaut de la liste des avoirs fournisseur";
            commit(CreditProviderMutations.SET_CREDIT_PROVIDER_FILTERS, filter);
            localStorage.setItem(
              "creditProviderFilters",
              JSON.stringify(filter)
            );
            break;
          case "planningFilters":
            label = "Période par défaut de la liste des plannings";
            commit(PlanningViewMutations.SET_PLANNING_FILTERS, filter);
            localStorage.setItem("planningFilters", JSON.stringify(filter));
            break;
          case "activityFilters":
            label = "Période par défaut de la liste des activités commerciales";
            commit(ActivityMutations.SET_ACTIVITY_FILTERS, filter);
            localStorage.setItem("activityFilters", JSON.stringify(filter));
            break;
          case "myActivityFilters":
            label =
              "Période par défaut de la liste de mes activités commerciales";
            commit(
              ActivityMutations.SET_ACTIVITY_FILTERS_BY_COLLABORATOR,
              filter
            );
            localStorage.setItem(
              "activityFiltersByCollaborator",
              JSON.stringify(filter)
            );
            break;
          case "receiptFormFilters":
            label = "Période par défaut de la liste des bons de réception";
            commit(
              ReceiptFormProviderMutations.SET_RECEIPT_FORM_FILTERS,
              filter
            );
            localStorage.setItem("receiptFormFilters", JSON.stringify(filter));
            break;
          case "reportDetailFilters":
            label = "Période par défaut de la liste des reportings";
            commit(ReportDetailMutations.SET_REPORT_DETAIL_FILTERS, filter);
            localStorage.setItem("reportDetailFilters", JSON.stringify(filter));
            break;
          case "reportDetailFiltersByCollaborator":
            label = "Période par défaut de la liste de mes reportings";
            commit(
              ReportDetailMutations.SET_REPORT_DETAIL_FILTERS_BY_COLLABORATOR,
              filter
            );
            localStorage.setItem(
              "reportDetailFiltersByCollaborator",
              JSON.stringify(filter)
            );
            break;
          case "interventionFilters":
            label = "Période par défaut de la liste des interventions";
            commit(InterventionMutations.SET_INTERVENTION_FILTERS, filter);
            localStorage.setItem("interventionFilters", JSON.stringify(filter));
            break;
          case "myInterventionFilters":
            label = "Période par défaut de la liste de mes interventions";
            commit(
              InterventionMutations.SET_INTERVENTION_FILTERS_BY_COLLABORATOR,
              filter
            );
            localStorage.setItem(
              "interventionFiltersByCollaborator",
              JSON.stringify(filter)
            );
            break;
          case "missionFilters":
            label = "Période par défaut de la liste des missions";
            commit(MissionMutations.SET_MISSION_FILTERS, filter);
            localStorage.setItem("missionFilters", JSON.stringify(filter));
            break;
          case "myMissionFilters":
            label = "Période par défaut de la liste de mes missions";
            commit(
              MissionMutations.SET_MISSION_FILTERS_BY_COLLABORATOR,
              filter
            );
            localStorage.setItem(
              "missionFiltersByCollaborator",
              JSON.stringify(filter)
            );
            break;
          case "affairCostingFilters":
            label = "Période par défaut de la liste des budgets d'affaire";
            filter.monthList = [];
            let dateDebut = new Date(filter.dateRange.startDate);
            let dateFin = new Date(filter.dateRange.endDate);
            let dateActuelle = new Date(dateDebut);
            while (dateActuelle <= dateFin) {
              let dateStr = dayjs(dateActuelle)
                .startOf("month")
                .format("MMM YY");
              filter.monthList.push({
                date: dayjs(dateActuelle).startOf("month").format("YYYY-MM-DD"),
                dateFormated: dateStr.toUpperCase(),
              });

              if (dateActuelle.getMonth() === 11) {
                dateActuelle.setFullYear(dateActuelle.getFullYear() + 1);
                dateActuelle.setMonth(0);
              } else {
                dateActuelle.setMonth(dateActuelle.getMonth() + 1);
              }
            }

            commit(AffairCostingMutations.SET_AFFAIR_COSTING_FILTERS, filter);
            localStorage.setItem(
              "affairCostingFilters",
              JSON.stringify(filter)
            );
            break;
          case "orderFormCustomerFilters":
            label = "Période par défaut de la liste des commandes client";
            commit(OrderFormCustomerMutations.SET_ORDER_FORM_CUSTOMER_FILTERS, {
              filter,
            });
            localStorage.setItem(
              "orderFormCustomerFilters",
              JSON.stringify(filter)
            );
            break;
        }
        dispatch("createOrUpdateUserParameter", {
          label: label,
          key: name,
          value: JSON.stringify(filter),
        });
        resolve();
      } else {
        resolve();
      }
    });
  },

  deleteUserParameter(
    { commit, rootGetters },
    { ids }: { ids: number[] }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(UserParameterMutations.SET_IS_LOADING_USER_PARAMETER, true);
      let url = `${process.env.VUE_APP_API_ORISIS}Account/UserParameter/Delete`;
      axios
        .delete(url, {
          data: ids,
        })
        .then((res) => {
          commit(UserParameterMutations.SET_IS_LOADING_USER_PARAMETER, false);
          commit(UserParameterMutations.SET_REQUEST_STATE_USER_PARAMETER, res);
          commit(UserParameterMutations.SET_USER_PARAMETERS_LIST, [
            ...rootGetters.userParametersList
              .filter((elem) => !ids.includes(elem.id))
              .map((elem) => elem),
          ]);
          commit(UserParameterMutations.SET_USER_PARAMETERS_LIST_BY_USER, [
            ...rootGetters.userParametersListByUser
              .filter((elem) => !ids.includes(elem.id))
              .map((elem) => elem),
          ]);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(UserParameterMutations.SET_IS_LOADING_USER_PARAMETER, false);
          commit(UserParameterMutations.SET_REQUEST_STATE_USER_PARAMETER, err);
          reject(err);
        });
    });
  },
};
