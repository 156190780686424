import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { OrderFormCustomerDownPaymentsRequestMutations } from "./mutations";
import { IOrderFormCustomerDownPaymentsRequestState } from "./states";
import { OrderFormCustomerDownPaymentsRequestRequestDto } from "@/../Api";

export const actions: ActionTree<
  IOrderFormCustomerDownPaymentsRequestState,
  RootState
> = {
  getOrderFormCustomerDownPaymentsRequestById(
    { commit },
    {
      orderFormCustomerDownPaymentsRequestId,
      updateState = true,
    }: { orderFormCustomerDownPaymentsRequestId: number; updateState?: boolean }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (updateState) {
        commit(
          OrderFormCustomerDownPaymentsRequestMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST,
          true
        );
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/OrderFormCustomerDownPaymentsRequest/GetOrderFormCustomerDownPaymentsRequestById",
          {
            params: {
              orderFormCustomerDownPaymentsRequestId:
                orderFormCustomerDownPaymentsRequestId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(
              OrderFormCustomerDownPaymentsRequestMutations.SET_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST,
              res.data
            );
            commit(
              OrderFormCustomerDownPaymentsRequestMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST,
              false
            );
          }
          commit(
            OrderFormCustomerDownPaymentsRequestMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            OrderFormCustomerDownPaymentsRequestMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST,
            err
          );
          commit(
            OrderFormCustomerDownPaymentsRequestMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST,
            false
          );
          reject(err);
        });
    });
  },

  getOrderFormCustomerDownPaymentsRequestByOrderFormCustomerId(
    { commit },
    {
      orderFormCustomerId,
      updateState = true,
    }: { orderFormCustomerId: number; updateState?: boolean }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (updateState) {
        commit(
          OrderFormCustomerDownPaymentsRequestMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST,
          true
        );
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/OrderFormCustomerDownPaymentsRequest/GetOrderFormCustomerDownPaymentsRequestsByOrderFormCustomerId",
          {
            params: {
              orderFormCustomerId: orderFormCustomerId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(
              OrderFormCustomerDownPaymentsRequestMutations.SET_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUESTS_LIST,
              res.data
            );
            commit(
              OrderFormCustomerDownPaymentsRequestMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST,
              false
            );
          }
          commit(
            OrderFormCustomerDownPaymentsRequestMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            OrderFormCustomerDownPaymentsRequestMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST,
            err
          );
          commit(
            OrderFormCustomerDownPaymentsRequestMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST,
            false
          );
          reject(err);
        });
    });
  },

  createOrderFormCustomerDownPaymentsRequest(
    { commit, rootGetters },
    {
      orderFormCustomerDownPaymentsRequest,
      orderFormCustomerDownPaymentsRequestIndex = null,
    }: {
      orderFormCustomerDownPaymentsRequest: OrderFormCustomerDownPaymentsRequestRequestDto;
      orderFormCustomerDownPaymentsRequestIndex?: number;
    }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(
        OrderFormCustomerDownPaymentsRequestMutations.SET_IS_CREATING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST,
        true
      );
      axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            "Sale/OrderFormCustomerDownPaymentsRequest/CreateOrderFormCustomerDownPaymentsRequest",
          orderFormCustomerDownPaymentsRequest
        )
        .then((res) => {
          if (
            orderFormCustomerDownPaymentsRequest.institutionSettingsId != null
          ) {
            rootGetters.institutionSettings.orderFormCustomerDownPaymentsRequestDefaults.splice(
              orderFormCustomerDownPaymentsRequestIndex,
              1,
              res.data.data
            );
          } else if (
            orderFormCustomerDownPaymentsRequest.orderFormCustomerId != null
          ) {
            rootGetters.orderFormCustomerDownPaymentsRequestsList.push(
              res.data.data
            );
          }
          commit(
            OrderFormCustomerDownPaymentsRequestMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST,
            res
          );
          commit(
            OrderFormCustomerDownPaymentsRequestMutations.SET_IS_CREATING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST,
            false
          );
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            OrderFormCustomerDownPaymentsRequestMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST,
            err
          );
          commit(
            OrderFormCustomerDownPaymentsRequestMutations.SET_IS_CREATING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST,
            false
          );
          reject(err);
        });
    });
  },

  updateOrderFormCustomerDownPaymentsRequest(
    { commit, rootGetters },
    {
      orderFormCustomerDownPaymentsRequest,
    }: {
      orderFormCustomerDownPaymentsRequest: OrderFormCustomerDownPaymentsRequestRequestDto;
    }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(
        OrderFormCustomerDownPaymentsRequestMutations.SET_IS_UPDATING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST,
        true
      );
      axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Sale/OrderFormCustomerDownPaymentsRequest/UpdateOrderFormCustomerDownPaymentsRequest",
          orderFormCustomerDownPaymentsRequest
        )
        .then((res) => {
          if (
            orderFormCustomerDownPaymentsRequest.institutionSettingsId != null
          ) {
            rootGetters.institutionSettings.orderFormCustomerDownPaymentsRequestDefaults.splice(
              rootGetters.institutionSettings.orderFormCustomerDownPaymentsRequestDefaults.findIndex(
                (elem) => elem.id == orderFormCustomerDownPaymentsRequest.id
              ),
              1,
              orderFormCustomerDownPaymentsRequest
            );
          } else if (
            orderFormCustomerDownPaymentsRequest.orderFormCustomerId != null
          ) {
            rootGetters.orderFormCustomerDownPaymentsRequestsList.splice(
              rootGetters.orderFormCustomerDownPaymentsRequestsList.findIndex(
                (elem) => elem.id == orderFormCustomerDownPaymentsRequest.id
              ),
              1,
              orderFormCustomerDownPaymentsRequest
            );
          }
          commit(
            OrderFormCustomerDownPaymentsRequestMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST,
            res
          );
          commit(
            OrderFormCustomerDownPaymentsRequestMutations.SET_IS_UPDATING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST,
            false
          );
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            OrderFormCustomerDownPaymentsRequestMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST,
            err
          );
          commit(
            OrderFormCustomerDownPaymentsRequestMutations.SET_IS_UPDATING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST,
            false
          );
          reject(err);
        });
    });
  },

  deleteOrderFormCustomerDownPaymentsRequests(
    { commit, rootGetters },
    {
      orderFormCustomerDownPaymentsRequestIds = [],
      route,
    }: { orderFormCustomerDownPaymentsRequestIds: number[]; route: string }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(
        OrderFormCustomerDownPaymentsRequestMutations.SET_IS_DELETING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST,
        true
      );
      axios
        .delete(
          process.env.VUE_APP_API_ORISIS +
            "Sale/OrderFormCustomerDownPaymentsRequest/Delete",
          {
            data: orderFormCustomerDownPaymentsRequestIds,
          }
        )
        .then((res) => {
          if (route == "institutionSettings") {
            orderFormCustomerDownPaymentsRequestIds.forEach(
              (orderFormCustomerDownPaymentsRequestId) => {
                rootGetters.institutionSettings.orderFormCustomerDownPaymentsRequestDefaults.splice(
                  rootGetters.institutionSettings.orderFormCustomerDownPaymentsRequestDefaults.findIndex(
                    (elem) => elem.id == orderFormCustomerDownPaymentsRequestId
                  ),
                  1
                );
              }
            );
          } else if (route == "orderFormCustomerDownPaymentsRequest") {
            orderFormCustomerDownPaymentsRequestIds.forEach(
              (orderFormCustomerDownPaymentsRequestId) => {
                rootGetters.orderFormCustomerDownPaymentsRequestsList.splice(
                  rootGetters.orderFormCustomerDownPaymentsRequestsList.findIndex(
                    (elem) => elem.id == orderFormCustomerDownPaymentsRequestId
                  ),
                  1
                );
              }
            );
          }
          commit(
            OrderFormCustomerDownPaymentsRequestMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST,
            res
          );
          commit(
            OrderFormCustomerDownPaymentsRequestMutations.SET_IS_DELETING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            OrderFormCustomerDownPaymentsRequestMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST,
            err
          );
          commit(
            OrderFormCustomerDownPaymentsRequestMutations.SET_IS_DELETING_ORDER_FORM_CUSTOMER_DOWN_PAYMENTS_REQUEST,
            false
          );
          reject(err);
        });
    });
  },
};
