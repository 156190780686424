import { InstitutionSettingsModel } from "./InstitutionSettingsModel";

export function ProductProviderDefaultProperties({
  reference = null,
  netPrice = 0,
  companyId = null,
  productId = null,
  productType = 0,
  description = null,
  unitId = 1,
  institutionId = null,
  id = 0,
  label = null,
  ecoContribution = 0.0,
  discount = 0,
  isDefault = false,
  useDegressivePurchaseRate = false,
  grossMarginHT = 0.0,
  marginRate = 0.0,
  purchaseCategoryId = null,
  quantity = 1,
  degressivePurchaseRates = [],
}) {
  return {
    id: id,
    label: label,
    reference: reference,
    discount: discount,
    netPrice: netPrice,
    isDefault: isDefault,
    companyId: companyId,
    productId: productId,
    useDegressivePurchaseRate: useDegressivePurchaseRate,
    grossMarginHT: grossMarginHT,
    marginRate: marginRate,
    ecoContribution: ecoContribution,
    purchaseCategoryId: purchaseCategoryId,
    productType: productType,
    description: description,
    unitId: unitId,
    quantity: quantity,
    degressivePurchaseRates: degressivePurchaseRates,
    institutionId: institutionId,
  };
}
