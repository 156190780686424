import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IDeliveryFormOptionState } from "./states";

export const getters: GetterTree<IDeliveryFormOptionState, RootState> = {
  deliveryFormOption(state): any {
    return state.deliveryFormOption;
  },
  isLoadingDeliveryFormOption(state): boolean {
    return state.isLoadingDeliveryFormOption;
  },
  requestStateDeliveryFormOption(state): any {
    return state.requestStateDeliveryFormOption;
  },
};
