import { MutationTree } from "vuex";
import { IInventoryDetailState } from "./states";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export enum InventoryDetailMutations {
    SET_INVENTORY_HEADER = "SET_INVENTORY_HEADER",
    SET_INVENTORY_DETAIL = "SET_INVENTORY_DETAIL",
    SET_INVENTORY_DETAILS = "SET_INVENTORY_DETAILS",
    SET_INVENTORY_DETAILS_FORMATED = "SET_INVENTORY_DETAILS_FORMATED",
    SET_IS_LOADING_INVENTORY_DETAILS = "SET_IS_LOADING_INVENTORY_DETAILS",
    SET_IS_CREATING_INVENTORY_DETAIL = "SET_IS_CREATING_INVENTORY_DETAIL",
    SET_IS_UPDATING_INVENTORY_DETAIL = "SET_IS_UPDATING_INVENTORY_DETAIL",
    SET_IS_DELETING_INVENTORY_DETAIL = "SET_IS_DELETING_INVENTORY_DETAIL",
    SET_REQUEST_STATE_INVENTORY_DETAIL = "SET_REQUEST_STATE_INVENTORY_DETAIL",
}

export const mutations: MutationTree<IInventoryDetailState> = {
    [InventoryDetailMutations.SET_INVENTORY_HEADER]: (state, payload: any) => {
        state.inventoryHeader = payload;
    },
    [InventoryDetailMutations.SET_INVENTORY_DETAILS]: (state, payload: any[]) => {
        state.inventoryDetails = payload;
    },
    [InventoryDetailMutations.SET_INVENTORY_DETAILS_FORMATED]: (state, payload: any[]) => {
        state.inventoryDetailsFormated = payload;
    },
    [InventoryDetailMutations.SET_INVENTORY_DETAIL]: (state, payload: any) => {
        state.inventoryDetail = payload;
    },
    [InventoryDetailMutations.SET_IS_LOADING_INVENTORY_DETAILS]: (state, payload: boolean) => {
        state.isLoadingInventoryDetails = payload;
    },
    [InventoryDetailMutations.SET_IS_CREATING_INVENTORY_DETAIL]: (state, payload: boolean) => {
        state.isCreatingInventoryDetail = payload;
    },
    [InventoryDetailMutations.SET_IS_UPDATING_INVENTORY_DETAIL]: (state, payload: boolean) => {
        state.isUpdatingInventoryDetail = payload;
    },
    [InventoryDetailMutations.SET_IS_DELETING_INVENTORY_DETAIL]: (state, payload: boolean) => {
        state.isDeletingInventoryDetail = payload;
    },
    [InventoryDetailMutations.SET_REQUEST_STATE_INVENTORY_DETAIL]: (state, payload: IRequestState) => {
        state.requestStateInventory = payload;
    },
}