import { Module } from "vuex";
import IWorkElementHeader from "@/types/api-orisis/interfaces/invoices/catalog/IWorkElementHeader";
import { EInputType } from "@/types/api-orisis/enums/enums";
import RootState from "@/store/modules/rootState";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { getters } from "./getters";
import { ICatalogState } from "./states";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { ProductCategoryRequestDto } from "Api";
import { ProductRequestDto } from "../../../../../Api";

const workElementHeader: IWorkElementHeader = {
  reference: {
    text: "Référence",
    display: false,
    editable: true,
    class: "col-ref reference",
    style: "width:10%;max-width: 100px;",
    inputType: EInputType.Text,
  },
  description: {
    text: "Désignation",
    display: true,
    editable: true,
    class: "col-label",
    style: "width:auto; max-width: none;flex-grow: 1;",
    inputType: EInputType.SelectCatalog,
  },
  quantity: {
    text: "Qté",
    display: true,
    editable: true,
    class: "col-qte text-center",
    style: "width:10%; max-width:70px;",
    inputType: EInputType.Number,
    unit: "",
    numberToFixed: 2,
    prefix: "",
  },
  unitId: {
    text: "Unité",
    display: true,
    editable: true,
    class: "col-unit text-center",
    style: "width:10%; max-width:70px;",
    inputType: EInputType.Select,
    choice: [],
  },
  dryDisbursed: {
    text: "Déboursé HT",
    display: true,
    editable: true,
    class: "col-margin margins text-right",
    style: "width:10%; max-width:100px;",
    inputType: EInputType.Number,
    numberToFixed: 2,
  },
  marginRate: {
    text: "% Marge",
    display: true,
    editable: true,
    class: "col-margin margins text-right",
    style: "width:10%; max-width:100px;",
    inputType: EInputType.Number,
    numberToFixed: 2,
  },
  priceHT: {
    text: "Prix U. HT",
    display: true,
    editable: true,
    class: "col-price text-right",
    style: "width:10%; max-width:100px; ",
    inputType: EInputType.Number,
    unit: "€",
    numberToFixed: 2,
    prefix: "",
  },
  total: {
    text: "Total HT",
    display: true,
    editable: false,
    class: "col-total text-right",
    style:
      "line-height: 22px !important; display: flex; align-items: center; justify-content: flex-end; width:10%; max-width:100px;",
    inputType: EInputType.Number,
    unit: "€",
    prefix: "",
    numberToFixed: 2,
  },
};

export const product = {
  id: 0,
  label: null,
  reference: null,
  productType: 0,
  description: null,
  quantity: 1,
  priceHT: 0.0,
  dryDisbursed: 0.0,
  grossMarginHT: 0.0,
  marginRate: 0.0,
  brand: null,
  unitId: 1,
  parentId: null,
  saleCategoryId: null,
  urlImage: null,
  alertQuantityMin: null,
  alertQuantityMax: null,
  destockingType: 0,
  restockingType: 0,
  ecoContribution: 0.0,
  useDegressivesSaleRateProvider: false,
  referencielTvaId: 1,
  productCategoriesId: [],
  degressivesSaleRateProvider: [],
  productProviders: [],
};

export const state = {
  catalog: [],
  catalogArchived: [],
  catalogSortedByProductType: Array<any>(),
  isCatalogLoading: false,
  requestStateCatalog: <IRequestState>{},
  productCategoryTree: <ProductCategoryRequestDto>{},
  product: product,
  isCreatingProduct: false,
  isUpdatingProduct: false,
  isArchivingCatalog: false,
  isDeletingProduct: false,
  isProductCategoryTreeLoading: false,
  isLoadingProduct: false,
  isLoadingProductElements: false,
  isDeletingWorkElement: false,
  isLoadingCatalog: false,
  isLoadingCatalogArchived: false,
  workElementProduct: product,
  isFirstLoadingCatalog: true,
  isFirstLoadingProduct: true,
  workElementDetails: [],
  workElementDetail: product,
  workElementHeader: workElementHeader,
  workElementValidate: false,
};

export const CatalogModule: Module<ICatalogState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
