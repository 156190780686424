import { DocumentDetailRequestDto } from "Api";
import { GetterTree } from "vuex";
import RootState from '@/store/modules/rootState'
import { IDocumentDetailState } from "./states";

export const getters: GetterTree<IDocumentDetailState, RootState> = {
    getInvoiceHeader: (state): any => state.invoiceHeader,
    getDocumentDetails: (state): DocumentDetailRequestDto[] => state.documentDetails,
    getDocumentDetail: (state) : DocumentDetailRequestDto => state.documentDetail,
    
    isLoadingDocumentDetails: (state) : boolean => state.isLoadingDocumentDetails,
    isLoadingDocumentDetailDuplication: (state) : boolean => state.isLoadingDocumentDetailDuplication,
    isCreatingDocumentDetail: (state): boolean => state.isCreatingDocumentDetail,
    isUpdatingDocumentDetail: (state): boolean => state.isUpdatingDocumentDetail,
    isDeletingDocumentDetail: (state): boolean => state.isDeletingDocumentDetail,
    disabledOptions: (state): boolean => state.disabledOptions,
    disabledDrag: (state): boolean => state.disabledDrag
}