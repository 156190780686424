import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { QuoteOptionMutations } from "./mutations";
import { IQuoteOptionState } from "./states";
import { QuoteOptionRequestDto } from "Api";

export const actions: ActionTree<IQuoteOptionState, RootState> = {
  getQuoteOptionByQuoteId(
    { commit },
    { quoteId, updateState = true }: { quoteId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(QuoteOptionMutations.SET_IS_LOADING_QUOTE_OPTION, true);
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/QuoteOption/GetQuoteOptionByQuoteId`;

      axios
        .get(url, {
          params: { quoteId: quoteId },
        })
        .then((res) => {
          if (updateState) {
            commit(QuoteOptionMutations.SET_QUOTE_OPTION, res.data);
            commit(QuoteOptionMutations.SET_IS_LOADING_QUOTE_OPTION, false);
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(QuoteOptionMutations.SET_IS_LOADING_QUOTE_OPTION, false);
          }
          reject(err);
        });
    });
  },

  createQuoteOption(
    { commit },
    {
      quoteOption,
      updateState = true,
    }: { quoteOption: QuoteOptionRequestDto; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(QuoteOptionMutations.SET_IS_LOADING_QUOTE_OPTION, true);
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/QuoteOption/CreateQuoteOption`;

      axios
        .post(url, quoteOption)
        .then((res) => {
          if (updateState) {
            commit(QuoteOptionMutations.SET_QUOTE_OPTION, res.data.data);
            commit(QuoteOptionMutations.SET_IS_LOADING_QUOTE_OPTION, false);
          }
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(QuoteOptionMutations.SET_IS_LOADING_QUOTE_OPTION, false);
          }
          reject(err);
        });
    });
  },

  updateQuoteOption(
    { commit, rootGetters },
    {
      quoteOption,
      updateState = true,
    }: { quoteOption: QuoteOptionRequestDto; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(QuoteOptionMutations.SET_IS_LOADING_QUOTE_OPTION, true);
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/QuoteOption/UpdateQuoteOption`;

      axios
        .put(url, { ...quoteOption, quoteId: rootGetters.document.id })
        .then((res) => {
          if (updateState) {
            commit(QuoteOptionMutations.SET_QUOTE_OPTION, res.data.data);
            commit(QuoteOptionMutations.SET_IS_LOADING_QUOTE_OPTION, false);
          }
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(QuoteOptionMutations.SET_IS_LOADING_QUOTE_OPTION, false);
          }
          reject(err);
        });
    });
  },
};
