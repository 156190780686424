import { IStorageLocationState } from "./states"
import { Module } from "vuex"
import { StorageLocationRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IStorageLocationState = {
    storageLocationsList                    : Array<StorageLocationRequestDto>(),
    storageLocationsListArchived            : Array<StorageLocationRequestDto>(),
    storageLocation                         : <StorageLocationRequestDto>{},
    storageLocationArchived                 : <StorageLocationRequestDto>{},
    isLoadingStorageLocationsList           : false,
    isLoadingStorageLocationsListArchived   : false,
    isLoadingStorageLocation                : false,
    isLoadingStorageLocationArchived        : false,
    isCreatingStorageLocation               : false,
    isUpdatingStorageLocation               : false,
    isRestoringStorageLocation              : false,
    isDeletingStorageLocation               : false,
    isArchivingStorageLocation              : false,
    requestStateStorageLocation             : <IRequestState>{},
}

export const StorageLocationModule: Module<IStorageLocationState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}