import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { ActivityResultTypeRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { IActivityResultTypeState } from './states'


export const getters: GetterTree<IActivityResultTypeState, RootState> = {
  activityResultTypesList: (state): ActivityResultTypeRequestDto[] => state.activityResultTypesList,
  activityResultTypesListArchived: (state): ActivityResultTypeRequestDto[] => state.activityResultTypesListArchived,
  activityResultType     : (state): ActivityResultTypeRequestDto => state.activityResultType,

  // COMMON
  isLoadingActivityResultTypesList   : (state): boolean => state.isLoadingActivityResultTypesList,
  isLoadingActivityResultTypesListArchived   : (state): boolean => state.isLoadingActivityResultTypesListArchived,
  isLoadingActivityResultType   : (state): boolean => state.isLoadingActivityResultType,
  isCreatingActivityResultType  : (state): boolean => state.isCreatingActivityResultType,
  isUpdatingActivityResultType  : (state): boolean => state.isUpdatingActivityResultType,
  isDeletingActivityResultType  : (state): boolean => state.isDeletingActivityResultType,
  isArchivingActivityResultType  : (state): boolean => state.isArchivingActivityResultType,
  requestStateActivityResultType: (state): IRequestState => state.requestStateActivityResultType,
}



