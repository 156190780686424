import { PropertyTypeRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { PropertyTypeMutations } from "./mutations";
import { IPropertyTypeState } from "./states";

export const actions: ActionTree<IPropertyTypeState, RootState> = {
  async getPropertyTypes(
    { commit },
    {
      updateState = true,
    }: {
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(PropertyTypeMutations.SET_IS_LOADING_PROPERTY_TYPE_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Management/PropertyType/GetPropertyTypes`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              PropertyTypeMutations.SET_IS_LOADING_PROPERTY_TYPE_LIST,
              false
            );
            commit(PropertyTypeMutations.SET_PROPERTY_TYPE_LIST, res.data);
          }
          commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            PropertyTypeMutations.SET_IS_LOADING_PROPERTY_TYPE_LIST,
            false
          );
          commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, err);
          reject(err);
        });
    });
  },

  async getPropertyTypeById(
    { commit },
    {
      propertyTypeId,
      updateState = true,
    }: { propertyTypeId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(PropertyTypeMutations.SET_IS_LOADING_PROPERTY_TYPE, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Management/PropertyType/GetPropertyTypeById`,
          {
            params: {
              propertyTypeId: propertyTypeId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(PropertyTypeMutations.SET_PROPERTY_TYPE, res.data);
            commit(PropertyTypeMutations.SET_IS_LOADING_PROPERTY_TYPE, false);
          }
          commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, err);
          commit(PropertyTypeMutations.SET_IS_LOADING_PROPERTY_TYPE, false);
          reject(err);
        });
    });
  },

  async getPropertyTypeArchivedById(
    { commit },
    {
      propertyTypeId,
      updateState = true,
    }: { propertyTypeId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(PropertyTypeMutations.SET_IS_LOADING_PROPERTY_TYPE, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Management/PropertyType/GetPropertyTypeArchivedById`,
          {
            params: {
              Id: propertyTypeId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(PropertyTypeMutations.SET_PROPERTY_TYPE, res.data);
            commit(PropertyTypeMutations.SET_IS_LOADING_PROPERTY_TYPE, false);
          }
          commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, err);
          commit(PropertyTypeMutations.SET_IS_LOADING_PROPERTY_TYPE, false);
          reject(err);
        });
    });
  },

  async getPropertyTypesArchived(
    { commit },
    {
      updateState = true,
    }: {
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          PropertyTypeMutations.SET_IS_LOADING_PROPERTY_TYPE_LIST_ARCHIVED,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Management/PropertyType/GetPropertyTypesArchived`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              PropertyTypeMutations.SET_IS_LOADING_PROPERTY_TYPE_LIST_ARCHIVED,
              false
            );
            commit(
              PropertyTypeMutations.SET_PROPERTY_TYPE_LIST_ARCHIVED,
              res.data
            );
          }
          commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            PropertyTypeMutations.SET_IS_LOADING_PROPERTY_TYPE_LIST_ARCHIVED,
            false
          );
          commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, err);
          reject(err);
        });
    });
  },

  async createPropertyType(
    { commit, rootGetters },
    { propertyType }: { propertyType: PropertyTypeRequestDto }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(PropertyTypeMutations.SET_IS_CREATING_PROPERTY_TYPE, true);
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Management/PropertyType/CreatePropertyType`,
          propertyType
        )
        .then((res) => {
          rootGetters.propertyTypeList.push(res.data.data);
          commit(
            PropertyTypeMutations.SET_PROPERTY_TYPE_LIST,
            rootGetters.propertyTypeList
          );
          commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, res);
          commit(PropertyTypeMutations.SET_IS_CREATING_PROPERTY_TYPE, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, err);
          commit(PropertyTypeMutations.SET_IS_CREATING_PROPERTY_TYPE, false);
          reject(err);
        });
    });
  },

  async updatePropertyType(
    { commit, rootGetters },
    { propertyType }: { propertyType: PropertyTypeRequestDto }
  ) {
    return new Promise(async (resolve, reject) => {
      commit(PropertyTypeMutations.SET_IS_UPDATING_PROPERTY_TYPE, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Management/PropertyType/UpdatePropertyType`,
          propertyType
        )
        .then((res) => {
          rootGetters.propertyTypeList.splice(
            rootGetters.propertyTypeList.findIndex(
              (elem) => elem.id == propertyType.id
            ),
            1,
            propertyType
          );
          commit(
            PropertyTypeMutations.SET_PROPERTY_TYPE_LIST,
            rootGetters.propertyTypeList
          );
          commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, res);
          commit(PropertyTypeMutations.SET_IS_UPDATING_PROPERTY_TYPE, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, err);
          commit(PropertyTypeMutations.SET_IS_UPDATING_PROPERTY_TYPE, false);
          reject(err);
        });
    });
  },

  async restorePropertyTypes(
    { commit, rootGetters },
    { propertyTypeIds = [] }: { propertyTypeIds: number[] }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(PropertyTypeMutations.SET_IS_UPDATING_PROPERTY_TYPE, true);
      axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Management/PropertyType/Restore",
          propertyTypeIds
        )
        .then((res) => {
          propertyTypeIds.forEach((propertyTypeId: number) => {
            rootGetters.propertyTypeList.unshift(
              rootGetters.propertyTypeListArchived.find(
                (elem) => elem.id == propertyTypeId
              )
            );
            rootGetters.propertyTypeListArchived.splice(
              rootGetters.propertyTypeListArchived.findIndex(
                (elem) => elem.id == propertyTypeId
              ),
              1
            );
          });
          commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, res);
          commit(PropertyTypeMutations.SET_IS_UPDATING_PROPERTY_TYPE, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, err);
          commit(PropertyTypeMutations.SET_IS_UPDATING_PROPERTY_TYPE, false);
          reject(err);
        });
    });
  },

  async archivePropertyTypes(
    { commit, rootGetters },
    { propertyTypeIds = [] }: { propertyTypeIds: number[] }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(PropertyTypeMutations.SET_IS_ARCHIVING_PROPERTY_TYPE, true);
      axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Management/PropertyType/Archive",
          {
            data: propertyTypeIds,
          }
        )
        .then((res) => {
          propertyTypeIds.forEach((propertyTypeId: number) => {
            rootGetters.propertyTypeList.splice(
              rootGetters.propertyTypeList.findIndex(
                (elem) => elem.id == propertyTypeId
              ),
              1
            );
          });
          commit(
            PropertyTypeMutations.SET_PROPERTY_TYPE_LIST,
            rootGetters.propertyTypeList
          );
          commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, res);
          commit(PropertyTypeMutations.SET_IS_ARCHIVING_PROPERTY_TYPE, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, err);
          commit(PropertyTypeMutations.SET_IS_ARCHIVING_PROPERTY_TYPE, false);
          reject(err);
        });
    });
  },

  async deletePropertyTypes(
    { commit, rootGetters },
    { propertyTypeIds = [] }: { propertyTypeIds: number[] }
  ) {
    return new Promise(async (resolve, reject) => {
      commit(PropertyTypeMutations.SET_IS_DELETING_PROPERTY_TYPE, true);
      await axios
        .delete(
          `${process.env.VUE_APP_API_ORISIS}Management/PropertyType/Delete`,
          {
            data: propertyTypeIds,
          }
        )
        .then((res) => {
          propertyTypeIds.forEach((propertyTypeId: number) => {
            rootGetters.propertyTypeList.splice(
              rootGetters.propertyTypeList.findIndex(
                (elem) => elem.id == propertyTypeId
              ),
              1
            );
          });
          commit(
            PropertyTypeMutations.SET_PROPERTY_TYPE_LIST,
            rootGetters.propertyTypeList
          );
          commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, res);
          commit(PropertyTypeMutations.SET_IS_DELETING_PROPERTY_TYPE, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, err);
          commit(PropertyTypeMutations.SET_IS_DELETING_PROPERTY_TYPE, false);
          reject(err);
        });
    });
  },
};
