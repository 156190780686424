import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { DegressivePurchaseRatesRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { IDegressivePurchaseRateState } from './states'


export const getters: GetterTree<IDegressivePurchaseRateState, RootState> = {
  degressivePurchaseRatesList                    : (state): DegressivePurchaseRatesRequestDto[] => state.degressivePurchaseRatesList,
  degressivePurchaseRate                         : (state): DegressivePurchaseRatesRequestDto => state.degressivePurchaseRate,

  // COMMON
  isLoadingDegressivePurchaseRatesList            : (state): boolean => state.isLoadingDegressivePurchaseRatesList,
  isLoadingDegressivePurchaseRate                 : (state): boolean => state.isLoadingDegressivePurchaseRate,
  isCreatingDegressivePurchaseRate                : (state): boolean => state.isCreatingDegressivePurchaseRate,
  isUpdatingDegressivePurchaseRate                : (state): boolean => state.isUpdatingDegressivePurchaseRate,
  isDeletingDegressivePurchaseRate                : (state): boolean => state.isDeletingDegressivePurchaseRate,
  requestStateDegressivePurchaseRate              : (state): IRequestState => state.requestStateDegressivePurchaseRate,
}