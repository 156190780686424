import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { StockMovementMutations } from "./mutations";
import { IStockMovementState } from "./states";
import dayjs from "dayjs";
import { Timezone } from "@syncfusion/ej2-schedule";

let timezoneSchedule = new Timezone();

export const actions: ActionTree<IStockMovementState, RootState> = {
  getStockMovements(
    { commit, rootGetters },
    { startTo, endTo, updateState = true }: { startTo: Date; endTo: Date; updateState?: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(StockMovementMutations.SET_IS_LOADING_STOCK_MOVEMENTS_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/StockMovement/GetStockMovements`;
      axios
        .get(url, {
          params: {
            startTo: startTo ? dayjs(startTo).format("YYYY-MM-DD") : null,
            endTo: endTo ? dayjs(endTo)
              .hour(23)
              .minute(59)
              .second(59)
              .format("YYYY-MM-DD HH:mm:ss") : null,
          },
        })
        .then((res) => {
          let list = res.data.map((item) => {
            return {
              ...item,
              movementDate: item.movementDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(item.movementDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              date: item.date
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(new Date(item.date))
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
            };
          });
          if (updateState) {
            commit(
              StockMovementMutations.SET_IS_LOADING_STOCK_MOVEMENTS_LIST,
              false
            );
            commit(StockMovementMutations.SET_STOCK_MOVEMENTS_LIST, list);
          }
          commit(StockMovementMutations.SET_REQUEST_STATE_STOCK_MOVEMENT, res);
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              StockMovementMutations.SET_IS_LOADING_STOCK_MOVEMENTS_LIST,
              false
            );
          }
          commit(StockMovementMutations.SET_REQUEST_STATE_STOCK_MOVEMENT, err);
          reject(err);
        });
    });
  },
  getStockMovementById(
    { commit, rootGetters },
    {stockMovementId, updateState = true}: {stockMovementId: number; updateState?: boolean}
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(StockMovementMutations.SET_IS_LOADING_STOCK_MOVEMENT, true);
      }
      axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/StockMovement/GetStockMovementById`,
          {
            params: {
              stockMovementId: stockMovementId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(StockMovementMutations.SET_STOCK_MOVEMENT, res.data);
            commit(StockMovementMutations.SET_IS_LOADING_STOCK_MOVEMENT, false);
          }
          commit(StockMovementMutations.SET_REQUEST_STATE_STOCK_MOVEMENT, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(StockMovementMutations.SET_REQUEST_STATE_STOCK_MOVEMENT, err);
          if (updateState) {
            commit(StockMovementMutations.SET_IS_LOADING_STOCK_MOVEMENT, false);
          }
          reject(err);
        });
    });
  },
};
