import { DeductionRequestDto } from "Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { DeductionMutations } from "./mutations";
import { IDeductionState } from "./states";

export const actions: ActionTree<IDeductionState, RootState> = {
  async getDeductionById(
    { commit },
    {
      deductionId,
      updateState = true,
    }: { deductionId: number; updateState: boolean }
  ): Promise<DeductionRequestDto> {
    return new Promise(async (resolve, reject) => {
      if (updateState) {
        commit(DeductionMutations.SET_IS_LOADING_DEDUCTION, true);
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS + "Sale/Deduction/GetDeductionById",
          {
            params: {
              deductionId: deductionId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(DeductionMutations.SET_DEDUCTION, res.data);
            commit(DeductionMutations.SET_IS_LOADING_DEDUCTION, false);
          }
          commit(DeductionMutations.SET_REQUEST_STATE_DEDUCTION, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DeductionMutations.SET_REQUEST_STATE_DEDUCTION, err);
          commit(DeductionMutations.SET_IS_LOADING_DEDUCTION, false);
          reject(err);
        });
    });
  },

  async getDeductionByInvoiceId(
    { commit },
    {
      invoiceId,
      updateState = true,
    }: { invoiceId: number; updateState: boolean }
  ): Promise<DeductionRequestDto> {
    return new Promise(async (resolve, reject) => {
      if (updateState) {
        commit(DeductionMutations.SET_IS_LOADING_DEDUCTION, true);
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/Deduction/GetDeductionByInvoiceId",
          {
            params: {
              invoiceId: invoiceId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(DeductionMutations.SET_DEDUCTION, res.data);
            commit(DeductionMutations.SET_IS_LOADING_DEDUCTION, false);
          }
          commit(DeductionMutations.SET_REQUEST_STATE_DEDUCTION, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DeductionMutations.SET_REQUEST_STATE_DEDUCTION, err);
          commit(DeductionMutations.SET_IS_LOADING_DEDUCTION, false);
          reject(err);
        });
    });
  },

  async getDeductionByQuoteId(
    { commit },
    { quoteId, updateState = true }: { quoteId: number; updateState: boolean }
  ): Promise<DeductionRequestDto> {
    return new Promise(async (resolve, reject) => {
      if (updateState) {
        commit(DeductionMutations.SET_IS_LOADING_DEDUCTION, true);
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/Deduction/GetDeductionByQuoteId",
          {
            params: {
              quoteId: quoteId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(DeductionMutations.SET_DEDUCTION, res.data);
            commit(DeductionMutations.SET_IS_LOADING_DEDUCTION, false);
          }
          commit(DeductionMutations.SET_REQUEST_STATE_DEDUCTION, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DeductionMutations.SET_REQUEST_STATE_DEDUCTION, err);
          commit(DeductionMutations.SET_IS_LOADING_DEDUCTION, false);
          reject(err);
        });
    });
  },

  async getDeductionByCreditId(
    { commit },
    { creditId, updateState = true }: { creditId: number; updateState: boolean }
  ): Promise<DeductionRequestDto> {
    return new Promise(async (resolve, reject) => {
      if (updateState) {
        commit(DeductionMutations.SET_IS_LOADING_DEDUCTION, true);
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/Deduction/GetDeductionByCreditId",
          {
            params: {
              creditId: creditId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(DeductionMutations.SET_DEDUCTION, res.data);
            commit(DeductionMutations.SET_IS_LOADING_DEDUCTION, false);
          }
          commit(DeductionMutations.SET_REQUEST_STATE_DEDUCTION, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DeductionMutations.SET_REQUEST_STATE_DEDUCTION, err);
          commit(DeductionMutations.SET_IS_LOADING_DEDUCTION, false);
          reject(err);
        });
    });
  },

  async createDeduction(
    { commit },
    { deduction }: { deduction: DeductionRequestDto }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(DeductionMutations.SET_IS_CREATING_DEDUCTION, true);
      await axios
        .post(
          process.env.VUE_APP_API_ORISIS + "Sale/Deduction/CreateDeduction",
          deduction
        )
        .then((res) => {
          commit(DeductionMutations.SET_DEDUCTION, {
            ...res.data.data,
            quoteId: deduction.quoteId > 0 ? deduction.quoteId : null,
            invoiceId: deduction.invoiceId > 0 ? deduction.invoiceId : null,
            creditId: deduction.creditId > 0 ? deduction.creditId : null,
          });
          commit(DeductionMutations.SET_REQUEST_STATE_DEDUCTION, res);
          commit(DeductionMutations.SET_IS_CREATING_DEDUCTION, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DeductionMutations.SET_REQUEST_STATE_DEDUCTION, err);
          commit(DeductionMutations.SET_IS_CREATING_DEDUCTION, false);
          reject(err);
        });
    });
  },

  async updateDeduction(
    { commit },
    { deduction }: { deduction: DeductionRequestDto }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(DeductionMutations.SET_IS_UPDATING_DEDUCTION, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Sale/Deduction/UpdateDeduction",
          deduction
        )
        .then((res) => {
          commit(DeductionMutations.SET_DEDUCTION, {
            ...res.data.data,
            quoteId: deduction.quoteId > 0 ? deduction.quoteId : null,
            invoiceId: deduction.invoiceId > 0 ? deduction.invoiceId : null,
            creditId: deduction.creditId > 0 ? deduction.creditId : null,
          });
          commit(DeductionMutations.SET_REQUEST_STATE_DEDUCTION, res);
          commit(DeductionMutations.SET_IS_UPDATING_DEDUCTION, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DeductionMutations.SET_REQUEST_STATE_DEDUCTION, err);
          commit(DeductionMutations.SET_IS_UPDATING_DEDUCTION, false);
          reject(err);
        });
    });
  },

  async deleteDeductions(
    { commit, rootGetters },
    { deductionIds = [] }: { deductionIds: number[] }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(DeductionMutations.SET_IS_DELETING_DEDUCTION, true);
      await axios
        .delete(process.env.VUE_APP_API_ORISIS + "Sale/Deduction/Delete", {
          data: deductionIds,
        })
        .then((res) => {
          deductionIds.forEach((deductionId) => {
            rootGetters.deductionsList.splice(
              rootGetters.deductionsList.findIndex(
                (elem) => elem.id == deductionId
              ),
              1
            );
          });
          commit(
            DeductionMutations.SET_DEDUCTIONS_LIST,
            rootGetters.deductionsList
          );
          commit(DeductionMutations.SET_REQUEST_STATE_DEDUCTION, res);
          commit(DeductionMutations.SET_IS_DELETING_DEDUCTION, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(DeductionMutations.SET_REQUEST_STATE_DEDUCTION, err);
          commit(DeductionMutations.SET_IS_DELETING_DEDUCTION, false);
          reject(err);
        });
    });
  },
};
