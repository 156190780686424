import { ISmsState } from "./states";

import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"
import { ProgrammedSmsRequestDto } from '@/../Api'

const state:ISmsState = {
    sms            : <ProgrammedSmsRequestDto>{},
    smsListByEventId : Array<ProgrammedSmsRequestDto>(),
    smsList       : Array<ProgrammedSmsRequestDto>(),
    isLoadingSms   : false,
    isLoadingSmsList   : false,
    isLoadingSmsListByEventId : false,
    requestStateSms: <IRequestState>{}
}

export const SmsModule: Module<ISmsState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}