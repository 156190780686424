import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { ReceiptFormRequestDto } from "@/../Api";
import { MutationTree } from "vuex";
import { IReceiptFormProviderState } from "./states";
import { IPaymentMethod } from "@/types/api-orisis/interfaces/invoices/paymentMethod/IPaymentMethod";
import { IFilters } from "@/types/api-orisis/interfaces/invoices/filters/IFilters";

export enum ReceiptFormProviderMutations {
  SET_RECEIPT_FORMS_LIST = "SET_RECEIPT_FORMS_LIST",
  SET_RECEIPT_FORMS_LIST_ARCHIVED = "SET_RECEIPT_FORMS_LIST_ARCHIVED",
  SET_RECEIPT_FORM = "SET_RECEIPT_FORM",
  SET_RECEIPT_FORM_ARCHIVED = "SET_RECEIPT_FORM_ARCHIVED",
  SET_RECEIPT_FORM_PDF = "SET_RECEIPT_FORM_PDF",
  SET_INITIAL_RECEIPT_FORM = "SET_INITIAL_RECEIPT_FORM",
  SET_RECEIPT_FORM_LIST_BY_AFFAIR = "SET_RECEIPT_FORM_LIST_BY_AFFAIR",
  SET_RECEIPT_FORM_LIST_BY_COMPANY = "SET_RECEIPT_FORM_LIST_BY_COMPANY",

  // COMMON
  SET_IS_VALIDATING_RECEIPT_FORM = "SET_IS_VALIDATING_RECEIPT_FORM",
  SET_IS_LOADING_RECEIPT_FORM_LIST = "SET_IS_LOADING_RECEIPT_FORM_LIST",
  SET_IS_LOADING_RECEIPT_FORM_LIST_ARCHIVED = "SET_IS_LOADING_RECEIPT_FORM_LIST_ARCHIVED",
  SET_IS_LOADING_RECEIPT_FORM = "SET_IS_LOADING_RECEIPT_FORM",
  // SET_RECEIPT_FORM_PAYMENT_METHODS = "SET_RECEIPT_FORM_PAYMENT_METHODS",
  SET_RECEIPT_FORM_FILTERS = "SET_RECEIPT_FORM_FILTERS",
  SET_IS_LOADING_RECEIPT_FORM_PDF = "SET_IS_LOADING_RECEIPT_FORM_PDF",
  SET_LAST_RECEIPT_FORM_REFERENCE = "SET_LAST_RECEIPT_FORM_REFERENCE",
  SET_IS_LOADING_RECEIPT_FORM_ARCHIVED = "SET_IS_LOADING_RECEIPT_FORM_ARCHIVED",
  SET_IS_LOADING_PAYMENT_METHODS_RECEIPT_FORM = "SET_IS_LOADING_PAYMENT_METHODS_RECEIPT_FORM",
  SET_IS_CREATING_RECEIPT_FORM = "SET_IS_CREATING_RECEIPT_FORM",
  SET_IS_UPDATING_RECEIPT_FORM = "SET_IS_UPDATING_RECEIPT_FORM",
  SET_IS_DELETING_RECEIPT_FORM = "SET_IS_DELETING_RECEIPT_FORM",
  SET_IS_LOADING_DUPLICATING_RECEIPT_FORM = "SET_IS_LOADING_DUPLICATING_RECEIPT_FORM",
  SET_IS_RESTORING_RECEIPT_FORM = "SET_IS_RESTORING_RECEIPT_FORM",
  SET_IS_ARCHIVING_RECEIPT_FORM = "SET_IS_ARCHIVING_RECEIPT_FORM",
  SET_REQUEST_STATE_RECEIPT_FORM = "SET_REQUEST_STATE_RECEIPT_FORM",
  SET_REQUEST_STATE_RECEIPT_FORM_PDF = "SET_REQUEST_STATE_RECEIPT_FORM_PDF",
  SET_RECEIPT_FORM_TAB_ACTIVE = "SET_RECEIPT_FORM_TAB_ACTIVE",
  SET_IS_LOADING_RECEIPT_FORMS_LIST_BY_AFFAIR = "SET_IS_LOADING_RECEIPT_FORMS_LIST_BY_AFFAIR",
  SET_IS_LOADING_RECEIPT_FORMS_LIST_BY_COMPANY = "SET_IS_LOADING_RECEIPT_FORMS_LIST_BY_COMPANY",
}

export const mutations: MutationTree<IReceiptFormProviderState> = {
  [ReceiptFormProviderMutations.SET_RECEIPT_FORMS_LIST_ARCHIVED]: (
    state,
    payload: ReceiptFormRequestDto[]
  ) => {
    state.receiptFormProvidersListArchived = payload;
  },
  [ReceiptFormProviderMutations.SET_RECEIPT_FORMS_LIST]: (
    state,
    payload: ReceiptFormRequestDto[]
  ) => {
    state.receiptFormProvidersList = payload;
  },
  [ReceiptFormProviderMutations.SET_RECEIPT_FORM]: (
    state,
    payload: ReceiptFormRequestDto
  ) => {
    state.receiptFormProvider = payload;
  },
  [ReceiptFormProviderMutations.SET_RECEIPT_FORM_ARCHIVED]: (
    state,
    payload: ReceiptFormRequestDto
  ) => {
    state.receiptFormProviderArchived = payload;
  },
  [ReceiptFormProviderMutations.SET_RECEIPT_FORM_PDF]: (state, payload: string) => {
    state.receiptFormProviderPDF = payload+'?r='+(new Date()).getTime();
  },
  [ReceiptFormProviderMutations.SET_RECEIPT_FORM_LIST_BY_AFFAIR]: (
    state,
    payload: ReceiptFormRequestDto[]
  ) => {    
    state.receiptFormProvidersListByAffair = payload;
  },
  [ReceiptFormProviderMutations.SET_RECEIPT_FORM_LIST_BY_COMPANY]: (
    state,
    payload: ReceiptFormRequestDto[]
  ) => {
    state.receiptFormProvidersListByCompany = payload;
  },

  //COMMON
  [ReceiptFormProviderMutations.SET_IS_VALIDATING_RECEIPT_FORM]: (state, payload: boolean) => {
    state.isValidatingReceiptFormProvider = payload;
  },
  [ReceiptFormProviderMutations.SET_IS_LOADING_RECEIPT_FORM_LIST_ARCHIVED]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingReceiptFormProvidersListArchived = payload;
  },
  [ReceiptFormProviderMutations.SET_LAST_RECEIPT_FORM_REFERENCE]: (state, payload: string) => {
      state.receiptFormProvider.documentReference = payload;
  },
  [ReceiptFormProviderMutations.SET_IS_LOADING_RECEIPT_FORM_LIST]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingReceiptFormProvidersList = payload;
  },
  [ReceiptFormProviderMutations.SET_IS_LOADING_RECEIPT_FORM]: (state, payload: boolean) => {
    state.isLoadingReceiptFormProvider = payload;
  },
  [ReceiptFormProviderMutations.SET_IS_LOADING_PAYMENT_METHODS_RECEIPT_FORM]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingPaymentMethodsReceiptFormProvider = payload;
  },
  [ReceiptFormProviderMutations.SET_IS_LOADING_RECEIPT_FORM_PDF]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingReceiptFormProviderPDF = payload;
  },
  [ReceiptFormProviderMutations.SET_IS_LOADING_DUPLICATING_RECEIPT_FORM]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingDuplicatingReceiptFormProvider = payload;
  },
  [ReceiptFormProviderMutations.SET_IS_LOADING_RECEIPT_FORM_ARCHIVED]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingReceiptFormProviderArchived = payload;
  },
  [ReceiptFormProviderMutations.SET_INITIAL_RECEIPT_FORM]: (
    state,
    payload: ReceiptFormRequestDto
  ) => {
    state.initialReceiptFormProvider = payload;
  },
  // [ReceiptFormProviderMutations.SET_RECEIPT_FORM_PAYMENT_METHODS]: (
  //   state,
  //   payload: IPaymentMethod[]
  // ) => {
  //   state.receiptFormProviderPaymentMethods = payload;
  // },
  [ReceiptFormProviderMutations.SET_IS_CREATING_RECEIPT_FORM]: (
    state,
    payload: boolean
  ) => {
    state.isCreatingReceiptFormProvider = payload;
  },
  [ReceiptFormProviderMutations.SET_IS_UPDATING_RECEIPT_FORM]: (
    state,
    payload: boolean
  ) => {
    state.isUpdatingReceiptFormProvider = payload;
  },
  [ReceiptFormProviderMutations.SET_IS_ARCHIVING_RECEIPT_FORM]: (
    state,
    payload: boolean
  ) => {
    state.isArchivingReceiptFormProvider = payload;
  },
  [ReceiptFormProviderMutations.SET_IS_RESTORING_RECEIPT_FORM]: (
    state,
    payload: boolean
  ) => {
    state.isRestoringReceiptFormProvider = payload;
  },
  [ReceiptFormProviderMutations.SET_IS_DELETING_RECEIPT_FORM]: (
    state,
    payload: boolean
  ) => {
    state.isDeletingReceiptFormProvider = payload;
  },
  [ReceiptFormProviderMutations.SET_REQUEST_STATE_RECEIPT_FORM]: (
    state,
    payload: IRequestState
  ) => {
    state.requestStateReceiptFormProvider = payload;
  },
  [ReceiptFormProviderMutations.SET_REQUEST_STATE_RECEIPT_FORM_PDF]: (
    state,
    payload: IRequestState
  ) => {
    state.requestStateReceiptFormProviderPDF = payload;
  },
  [ReceiptFormProviderMutations.SET_RECEIPT_FORM_TAB_ACTIVE]: (state, payload: any) => {
    state.receiptFormProviderTabActive = payload;
  },
  [ReceiptFormProviderMutations.SET_RECEIPT_FORM_FILTERS]: (state, payload: IFilters) => {
    state.receiptFormProviderFilters = payload;
  },
  [ReceiptFormProviderMutations.SET_IS_LOADING_RECEIPT_FORMS_LIST_BY_AFFAIR]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingReceiptFormProvidersListByAffair = payload;
  },
  [ReceiptFormProviderMutations.SET_IS_LOADING_RECEIPT_FORMS_LIST_BY_COMPANY]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingReceiptFormProvidersListByCompany = payload;
  },

};
