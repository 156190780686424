import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { DegressiveSalesRatesRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { IDegressiveSaleRateState } from './states'


export const getters: GetterTree<IDegressiveSaleRateState, RootState> = {
  degressiveSaleRatesList                    : (state): DegressiveSalesRatesRequestDto[] => state.degressiveSaleRatesList,
  degressiveSaleRate                         : (state): DegressiveSalesRatesRequestDto => state.degressiveSaleRate,

  // COMMON
  isLoadingDegressiveSaleRatesList            : (state): boolean => state.isLoadingDegressiveSaleRatesList,
  isLoadingDegressiveSaleRate                 : (state): boolean => state.isLoadingDegressiveSaleRate,
  isCreatingDegressiveSaleRate                : (state): boolean => state.isCreatingDegressiveSaleRate,
  isUpdatingDegressiveSaleRate                : (state): boolean => state.isUpdatingDegressiveSaleRate,
  isDeletingDegressiveSaleRate                : (state): boolean => state.isDeletingDegressiveSaleRate,
  requestStateDegressiveSaleRate              : (state): IRequestState => state.requestStateDegressiveSaleRate,
}