import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { ActivityRequestDto } from "@/../Api";
import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IActivityState } from "./states";

export const getters: GetterTree<IActivityState, RootState> = {
  activitiesList: (state): ActivityRequestDto[] => state.activitiesList,
  activitiesListArchived: (state): ActivityRequestDto[] =>
    state.activitiesListArchived,
  activitiesListByCollaborator: (state): ActivityRequestDto[] =>
    state.activitiesListByCollaborator,
  activitiesListArchivedByCollaborator: (state): ActivityRequestDto[] =>
    state.activitiesListArchivedByCollaborator,
  activitiesListByCompany: (state): ActivityRequestDto[] =>
    state.activitiesListByCompany,
  activitiesListArchivedByCompany: (state): ActivityRequestDto[] =>
    state.activitiesListArchivedByCompany,
  activity: (state): ActivityRequestDto => state.activity,

  remindersList: (state): ActivityRequestDto[] => state.remindersList,
  remindersListByCollaborator: (state): ActivityRequestDto[] =>
    state.remindersListByCollaborator,
  remindersListByCompany: (state): ActivityRequestDto[] =>
    state.remindersListByCompany,

  activityFilters: (state): any => state.activityFilters,
  activityFiltersByCollaborator: (state): any =>
    state.activityFiltersByCollaborator,

  // COMMON
  isLoadingActivitiesList: (state): boolean => state.isLoadingActivitiesList,
  isLoadingActivitiesListArchived: (state): boolean =>
    state.isLoadingActivitiesListArchived,
  isLoadingActivitiesListByCollaborator: (state): boolean =>
    state.isLoadingActivitiesListByCollaborator,
  isLoadingActivitiesListArchivedByCollaborator: (state): boolean =>
    state.isLoadingActivitiesListArchivedByCollaborator,
  isLoadingActivitiesListByCompany: (state): boolean =>
    state.isLoadingActivitiesListByCompany,
  isLoadingActivitiesListArchivedByCompany: (state): boolean =>
    state.isLoadingActivitiesListArchivedByCompany,
  isLoadingRemindersList: (state): boolean => state.isLoadingRemindersList,
  isLoadingRemindersListByCollaborator: (state): boolean =>
    state.isLoadingRemindersListByCollaborator,
  isLoadingRemindersListByCompany: (state): boolean =>
    state.isLoadingRemindersListByCompany,
  isLoadingActivity: (state): boolean => state.isLoadingActivity,
  isCreatingActivity: (state): boolean => state.isCreatingActivity,
  isUpdatingActivity: (state): boolean => state.isUpdatingActivity,
  isDeletingActivity: (state): boolean => state.isDeletingActivity,
  isArchivingActivity: (state): boolean => state.isArchivingActivity,
  requestStateActivity: (state): IRequestState => state.requestStateActivity,
};
