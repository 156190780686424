import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { PaymentMethodRequestDto } from "Api";
import RootState from '@/store/modules/rootState'
import { IPaymentMethodState } from "./states";
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

export const state: IPaymentMethodState = {
    paymentMethodsList                  : Array<PaymentMethodRequestDto>(),
    paymentMethodsListArchived          : Array<PaymentMethodRequestDto>(),
    paymentMethod                       : <PaymentMethodRequestDto>{},
    isLoadingPaymentMethodsList         : false,
    isLoadingPaymentMethodsListArchived : false,
    isLoadingPaymentMethod              : false,
    isCreatingPaymentMethod             : false,
    isUpdatingPaymentMethod             : false,
    isDeletingPaymentMethod             : false,
    requestStatePaymentMethod           : <IRequestState>{},
    isArchivingPaymentMethod            : false,
}

export const PaymentMethodModule: Module<IPaymentMethodState, RootState> = {
  state, 
  getters,
  mutations,
  actions
}