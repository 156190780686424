import { WasteCollectionPointRequestDto } from "Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { WasteCollectionPointMutations } from "./mutations";
import { IWasteCollectionPointState } from "./states";

export const actions: ActionTree<IWasteCollectionPointState, RootState> = {
  getWasteCollectionPoints(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          WasteCollectionPointMutations.SET_IS_LOADING_WASTE_COLLECTION_POINT,
          true
        );
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/WasteCollectionPoint/GetWasteCollectionPoints"
        )
        .then((res) => {
          if (updateState) {
            commit(
              WasteCollectionPointMutations.SET_WASTE_COLLECTION_POINTS_LIST,
              res.data
            );
            commit(
              WasteCollectionPointMutations.SET_IS_LOADING_WASTE_COLLECTION_POINT,
              false
            );
          }
          commit(
            WasteCollectionPointMutations.SET_REQUEST_STATE_WASTE_COLLECTION_POINT,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            WasteCollectionPointMutations.SET_REQUEST_STATE_WASTE_COLLECTION_POINT,
            err
          );
          if (updateState) {
            commit(
              WasteCollectionPointMutations.SET_IS_LOADING_WASTE_COLLECTION_POINT,
              false
            );
          }
          reject(err);
        });
    });
  },

  getWasteCollectionPointById(
    { commit, rootGetters },
    {
      wasteCollectionPointId,
      updateState = true,
    }: { wasteCollectionPointId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          WasteCollectionPointMutations.SET_IS_LOADING_WASTE_COLLECTION_POINT,
          true
        );
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/WasteCollectionPoint/GetWasteCollectionPointById",
          {
            params: {
              wasteCollectionPointId: wasteCollectionPointId,
            },
          }
        )
        .then((res) => {
          commit(
            WasteCollectionPointMutations.SET_REQUEST_STATE_WASTE_COLLECTION_POINT,
            res
          );
          if (updateState) {
            commit(
              WasteCollectionPointMutations.SET_WASTE_COLLECTION_POINT,
              res.data
            );
            commit(
              WasteCollectionPointMutations.SET_IS_LOADING_WASTE_COLLECTION_POINT,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              WasteCollectionPointMutations.SET_IS_LOADING_WASTE_COLLECTION_POINT,
              false
            );
          }
          commit(
            WasteCollectionPointMutations.SET_REQUEST_STATE_WASTE_COLLECTION_POINT,
            err
          );
          reject(err);
        });
    });
  },

  getWasteCollectionPointsArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          WasteCollectionPointMutations.SET_IS_LOADING_WASTE_COLLECTION_POINTS_LIST_ARCHIVED,
          true
        );
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/WasteCollectionPoint/GetWasteCollectionPointsArchived"
        )
        .then((res) => {
          if (updateState) {
            commit(
              WasteCollectionPointMutations.SET_WASTE_COLLECTION_POINTS_LIST_ARCHIVED,
              res.data
            );
            commit(
              WasteCollectionPointMutations.SET_IS_LOADING_WASTE_COLLECTION_POINTS_LIST_ARCHIVED,
              false
            );
          }
          commit(
            WasteCollectionPointMutations.SET_REQUEST_STATE_WASTE_COLLECTION_POINT,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            WasteCollectionPointMutations.SET_REQUEST_STATE_WASTE_COLLECTION_POINT,
            err
          );
          if (updateState) {
            commit(
              WasteCollectionPointMutations.SET_IS_LOADING_WASTE_COLLECTION_POINTS_LIST_ARCHIVED,
              false
            );
          }
          reject(err);
        });
    });
  },

  getWasteCollectionPointArchivedById(
    { commit, rootGetters },
    {
      wasteCollectionPointId,
      updateState = true,
    }: { wasteCollectionPointId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          WasteCollectionPointMutations.SET_IS_ARCHIVING_WASTE_COLLECTION_POINT,
          true
        );
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/WasteCollectionPoint/GetWasteCollectionPointArchivedById",
          {
            params: {
              wasteCollectionPointArchivedId: wasteCollectionPointId,
            },
          }
        )
        .then((res) => {
          commit(
            WasteCollectionPointMutations.SET_REQUEST_STATE_WASTE_COLLECTION_POINT,
            res
          );
          if (updateState) {
            commit(
              WasteCollectionPointMutations.SET_WASTE_COLLECTION_POINT,
              res.data
            );
            commit(
              WasteCollectionPointMutations.SET_IS_ARCHIVING_WASTE_COLLECTION_POINT,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              WasteCollectionPointMutations.SET_IS_ARCHIVING_WASTE_COLLECTION_POINT,
              false
            );
          }
          commit(
            WasteCollectionPointMutations.SET_REQUEST_STATE_WASTE_COLLECTION_POINT,
            err
          );
          reject(err);
        });
    });
  },

  createWasteCollectionPoint(
    { commit, rootGetters },
    {
      wasteCollectionPoint,
    }: { wasteCollectionPoint: WasteCollectionPointRequestDto }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(
        WasteCollectionPointMutations.SET_IS_CREATING_WASTE_COLLECTION_POINT,
        true
      );
      axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            "Sale/WasteCollectionPoint/CreateWasteCollectionPoint",
          wasteCollectionPoint
        )
        .then((res) => {
          rootGetters.wasteCollectionPointsList.push(res.data.data);
          commit(
            WasteCollectionPointMutations.SET_WASTE_COLLECTION_POINTS_LIST,
            rootGetters.wasteCollectionPointsList
          );
          commit(
            WasteCollectionPointMutations.SET_REQUEST_STATE_WASTE_COLLECTION_POINT,
            res
          );
          commit(
            WasteCollectionPointMutations.SET_IS_CREATING_WASTE_COLLECTION_POINT,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            WasteCollectionPointMutations.SET_REQUEST_STATE_WASTE_COLLECTION_POINT,
            err
          );
          commit(
            WasteCollectionPointMutations.SET_IS_CREATING_WASTE_COLLECTION_POINT,
            false
          );
          reject(err);
        });
    });
  },

  updateWasteCollectionPoint(
    { commit, rootGetters },
    {
      wasteCollectionPoint,
    }: { wasteCollectionPoint: WasteCollectionPointRequestDto }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(
        WasteCollectionPointMutations.SET_IS_UPDATING_WASTE_COLLECTION_POINT,
        true
      );
      axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Sale/WasteCollectionPoint/UpdateWasteCollectionPoint",
          wasteCollectionPoint
        )
        .then((res) => {
          rootGetters.wasteCollectionPointsList.splice(
            rootGetters.wasteCollectionPointsList.findIndex(
              (elem) => elem.id == wasteCollectionPoint.id
            ),
            1,
            res.data.data
          );
          commit(
            WasteCollectionPointMutations.SET_WASTE_COLLECTION_POINTS_LIST,
            rootGetters.wasteCollectionPointsList
          );
          commit(
            WasteCollectionPointMutations.SET_REQUEST_STATE_WASTE_COLLECTION_POINT,
            res
          );
          commit(
            WasteCollectionPointMutations.SET_IS_UPDATING_WASTE_COLLECTION_POINT,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            WasteCollectionPointMutations.SET_REQUEST_STATE_WASTE_COLLECTION_POINT,
            err
          );
          commit(
            WasteCollectionPointMutations.SET_IS_UPDATING_WASTE_COLLECTION_POINT,
            false
          );
          reject(err);
        });
    });
  },

  restoreWasteCollectionPoints(
    { commit, rootGetters },
    { wasteCollectionPointIds = [] }: { wasteCollectionPointIds: string[] }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(
        WasteCollectionPointMutations.SET_IS_UPDATING_WASTE_COLLECTION_POINT,
        true
      );
      axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Sale/WasteCollectionPoint/Restore",
          wasteCollectionPointIds,
          {
            data: wasteCollectionPointIds,
          }
        )
        .then((res) => {
          wasteCollectionPointIds.forEach((wasteCollectionPointId) => {
            rootGetters.wasteCollectionPointsList.unshift(
              rootGetters.wasteCollectionPointsListArchived.find(
                (elem) => elem.id == wasteCollectionPointId
              )
            );
            rootGetters.wasteCollectionPointsListArchived.splice(
              rootGetters.wasteCollectionPointsListArchived.findIndex(
                (elem) => elem.id == wasteCollectionPointId
              ),
              1
            );
          });
          commit(
            WasteCollectionPointMutations.SET_WASTE_COLLECTION_POINTS_LIST,
            rootGetters.wasteCollectionPointsList
          );
          commit(
            WasteCollectionPointMutations.SET_WASTE_COLLECTION_POINTS_LIST_ARCHIVED,
            rootGetters.wasteCollectionPointsListArchived
          );
          commit(
            WasteCollectionPointMutations.SET_REQUEST_STATE_WASTE_COLLECTION_POINT,
            res
          );
          commit(
            WasteCollectionPointMutations.SET_IS_UPDATING_WASTE_COLLECTION_POINT,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            WasteCollectionPointMutations.SET_REQUEST_STATE_WASTE_COLLECTION_POINT,
            err
          );
          commit(
            WasteCollectionPointMutations.SET_IS_UPDATING_WASTE_COLLECTION_POINT,
            false
          );
          reject(err);
        });
    });
  },

  archiveWasteCollectionPoints(
    { commit, rootGetters },
    { wasteCollectionPointIds = [] }: { wasteCollectionPointIds: number[] }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(
        WasteCollectionPointMutations.SET_IS_ARCHIVING_WASTE_COLLECTION_POINT,
        true
      );
      axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Sale/WasteCollectionPoint/Archive",
          {
            data: wasteCollectionPointIds,
          }
        )
        .then((res) => {
          wasteCollectionPointIds.forEach((wasteCollectionPointId) => {
            const index = rootGetters.wasteCollectionPointsList.findIndex(
              (elem) => elem.id == wasteCollectionPointId
            );
            if (index !== -1) {
              rootGetters.wasteCollectionPointsListArchived.unshift(
                rootGetters.wasteCollectionPointsList[index]
              );
              rootGetters.wasteCollectionPointsList.splice(index, 1);
              commit(
                WasteCollectionPointMutations.SET_WASTE_COLLECTION_POINTS_LIST,
                rootGetters.wasteCollectionPointsList
              );
              commit(
                WasteCollectionPointMutations.SET_WASTE_COLLECTION_POINTS_LIST_ARCHIVED,
                rootGetters.wasteCollectionPointsListArchived
              );
            }
          });
          commit(
            WasteCollectionPointMutations.SET_REQUEST_STATE_WASTE_COLLECTION_POINT,
            res
          );
          commit(
            WasteCollectionPointMutations.SET_IS_ARCHIVING_WASTE_COLLECTION_POINT,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            WasteCollectionPointMutations.SET_REQUEST_STATE_WASTE_COLLECTION_POINT,
            err
          );
          commit(
            WasteCollectionPointMutations.SET_IS_ARCHIVING_WASTE_COLLECTION_POINT,
            false
          );
          reject(err);
        });
    });
  },

  deleteWasteCollectionPoints(
    { commit, rootGetters },
    { wasteCollectionPointIds = [] }: { wasteCollectionPointIds: number[] }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(
        WasteCollectionPointMutations.SET_IS_DELETING_WASTE_COLLECTION_POINT,
        true
      );
      axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Sale/WasteCollectionPoint/Delete",
          {
            data: wasteCollectionPointIds,
          }
        )
        .then((res) => {
          wasteCollectionPointIds.forEach((wasteCollectionPointId) => {
            const index =
              rootGetters.wasteCollectionPointsListArchived.findIndex(
                (elem) => elem.id == wasteCollectionPointId
              );
            if (index !== -1) {
              rootGetters.wasteCollectionPointsListArchived.splice(index, 1);
              commit(
                WasteCollectionPointMutations.SET_WASTE_COLLECTION_POINTS_LIST_ARCHIVED,
                rootGetters.wasteCollectionPointsListArchived
              );
            }
          });
          commit(
            WasteCollectionPointMutations.SET_REQUEST_STATE_WASTE_COLLECTION_POINT,
            res
          );
          commit(
            WasteCollectionPointMutations.SET_IS_DELETING_WASTE_COLLECTION_POINT,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            WasteCollectionPointMutations.SET_REQUEST_STATE_WASTE_COLLECTION_POINT,
            err
          );
          commit(
            WasteCollectionPointMutations.SET_IS_DELETING_WASTE_COLLECTION_POINT,
            false
          );
          reject(err);
        });
    });
  },
};
