import { ICompanyState } from "./states";
import { MutationTree } from "vuex";
import { CompanyRequestDto } from "@/../Api";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export enum CompanyMutations {
  SET_COMPANIES_LIST = "SET_COMPANIES_LIST",
  SET_COMPANIES_LIST_ARCHIVED = "SET_COMPANIES_LIST_ARCHIVED",
  SET_COMPANY = "SET_COMPANY",

  // COMON
  SET_IS_LOADING_COMPANIES_LIST = "SET_IS_LOADING_COMPANIES_LIST",
  SET_IS_LOADING_COMPANIES_LIST_ARCHIVED = "SET_IS_LOADING_COMPANIES_LIST_ARCHIVED",

  SET_IS_LOADING_COMPANY = "SET_IS_LOADING_COMPANY",
  SET_IS_CREATING_COMPANY = "SET_IS_CREATING_COMPANY",
  SET_IS_UPDATING_COMPANY = "SET_IS_UPDATING_COMPANY",
  SET_IS_DELETING_COMPANY = "SET_IS_DELETING_COMPANY",
  SET_IS_ARCHIVING_COMPANY = "SET_IS_ARCHIVING_COMPANY",
  SET_REQUEST_STATE_COMPANY = "SET_REQUEST_STATE",
}

export const mutations: MutationTree<ICompanyState> = {
  [CompanyMutations.SET_COMPANIES_LIST]: (state, payload: any[]) => {
    state.companiesList = payload.map((el) => {
      // On gère les indemnités
      el["companyTypeId"] = el.companyType ? el.companyType.id : null;
      if (el.companyIndicators && el.companyIndicators.length > 0) {
        for (let index = 0; index < el.companyIndicators.length; index++) {
          const companyIndicator = el.companyIndicators[index];
          el["companyIndicator_" + companyIndicator.companyIndicator.id] =
            companyIndicator.value;
        }
      }
      return el;
    });
  },
  [CompanyMutations.SET_COMPANIES_LIST_ARCHIVED]: (state, payload: any[]) => {
    state.companiesListArchived = payload.map((el) => {
      // On gère les indemnités
      el["companyTypeId"] = el.companyType ? el.companyType.id : null;
      if (el.companyIndicators && el.companyIndicators.length > 0) {
        for (let index = 0; index < el.companyIndicators.length; index++) {
          const companyIndicator = el.companyIndicators[index];
          el["companyIndicator_" + companyIndicator.companyIndicator.id] =
            companyIndicator.value;
        }
      }
      return el;
    });
  },
  [CompanyMutations.SET_COMPANY]: (state, payload: any) => {
    state.company = {
      ...payload,
      companyTypeId: payload.companyType ? payload.companyType.id : null,
    };
  },

  //COMMON
  [CompanyMutations.SET_IS_LOADING_COMPANIES_LIST]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingCompaniesList = payload;
  },
  [CompanyMutations.SET_IS_LOADING_COMPANIES_LIST_ARCHIVED]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingCompaniesListArchived = payload;
  },
  [CompanyMutations.SET_IS_LOADING_COMPANY]: (state, payload: boolean) => {
    state.isLoadingCompany = payload;
  },
  [CompanyMutations.SET_IS_CREATING_COMPANY]: (state, payload: boolean) => {
    state.isCreatingCompany = payload;
  },
  [CompanyMutations.SET_IS_UPDATING_COMPANY]: (state, payload: boolean) => {
    state.isUpdatingCompany = payload;
  },
  [CompanyMutations.SET_IS_DELETING_COMPANY]: (state, payload: boolean) => {
    state.isDeletingCompany = payload;
  },
  [CompanyMutations.SET_REQUEST_STATE_COMPANY]: (
    state,
    payload: IRequestState
  ) => {
    state.requestStateCompany = payload;
  },
  [CompanyMutations.SET_IS_ARCHIVING_COMPANY]: (state, payload: boolean) => {
    state.isArchivingCompany = payload;
  },
};
