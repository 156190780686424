import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { CustomFieldGroupRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { ICustomFieldGroupState } from './states'


export const getters: GetterTree<ICustomFieldGroupState, RootState> = {
  customFieldGroupsList: (state): CustomFieldGroupRequestDto[] => state.customFieldGroupsList,
  customFieldGroupsListArchived: (state): CustomFieldGroupRequestDto[] => state.customFieldGroupsListArchived,
  customFieldGroup     : (state): CustomFieldGroupRequestDto => state.customFieldGroup,

  // COMMON
  isLoadingCustomFieldGroupsList   : (state): boolean => state.isLoadingCustomFieldGroupsList,
  isLoadingCustomFieldGroupsListArchived   : (state): boolean => state.isLoadingCustomFieldGroupsListArchived,
  isLoadingCustomFieldGroup   : (state): boolean => state.isLoadingCustomFieldGroup,
  isCreatingCustomFieldGroup  : (state): boolean => state.isCreatingCustomFieldGroup,
  isUpdatingCustomFieldGroup  : (state): boolean => state.isUpdatingCustomFieldGroup,
  isDeletingCustomFieldGroup  : (state): boolean => state.isDeletingCustomFieldGroup,
  isArchivingCustomFieldGroup  : (state): boolean => state.isArchivingCustomFieldGroup,
  requestStateCustomFieldGroup: (state): IRequestState => state.requestStateCustomFieldGroup,
}



