import { ITermsAndConditionsState } from "./states";
import { MutationTree } from "vuex";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { TermsAndConditionsRequestDto } from "Api";


export enum TermsAndConditionsMutations{
    SET_TERMS_AND_CONDITIONS = "SET_TERMS_AND_CONDITIONS",
    
    // COMON 
    SET_IS_LOADING_TERMS_AND_CONDITIONS = "SET_IS_LOADING_TERMS_AND_CONDITIONS",
    SET_IS_CREATING_TERMS_AND_CONDITIONS = "SET_IS_CREATING_TERMS_AND_CONDITIONS",
    SET_IS_UPDATING_TERMS_AND_CONDITIONS = "SET_IS_UPDATING_TERMS_AND_CONDITIONS",
    SET_IS_DELETING_TERMS_AND_CONDITIONS = "SET_IS_DELETING_TERMS_AND_CONDITIONS",
    SET_REQUEST_STATE_TERMS_AND_CONDITIONS = "SET_REQUEST_STATE_TERMS_AND_CONDITIONS",
  }

  export const mutations: MutationTree<ITermsAndConditionsState> = {
    [TermsAndConditionsMutations.SET_TERMS_AND_CONDITIONS]: (state, payload: TermsAndConditionsRequestDto) => {
        state.termsAndConditions = payload;
    },

    //COMMON
    [TermsAndConditionsMutations.SET_IS_LOADING_TERMS_AND_CONDITIONS]: (state, payload: boolean) => {
        state.isLoadingTermsAndConditions = payload;
    },
    [TermsAndConditionsMutations.SET_IS_CREATING_TERMS_AND_CONDITIONS]: (state, payload: boolean) => {
        state.isCreatingTermsAndConditions = payload;
    },
    [TermsAndConditionsMutations.SET_IS_UPDATING_TERMS_AND_CONDITIONS]: (state, payload: boolean) => {
        state.isUpdatingTermsAndConditions = payload;
    },
    [TermsAndConditionsMutations.SET_IS_DELETING_TERMS_AND_CONDITIONS]: (state, payload: boolean) => {
        state.isDeletingTermsAndConditions = payload;
    },
    [TermsAndConditionsMutations.SET_REQUEST_STATE_TERMS_AND_CONDITIONS]: (state, payload: IRequestState) => {
        state.requestStateTermsAndConditions = payload;
    },
}