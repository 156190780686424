import { ProductRequestDto } from "Api";
import axios from "axios";
import { CatalogMutations } from "./mutations";
import { generateParams } from "@/types/api-orisis/library/FormatOperations";
import { IProduct } from "@/types/api-orisis/interfaces/invoices/catalog/IProduct";
import { formatNumber } from "@/types/api-orisis/library/FormatOperations";
import { checkFunctionUser } from "@/auth/utils";

export const actions = {
  // Verifier la fonction, voir si les paramètres sont correctement passés
  async getProducts(
    { rootGetters, commit, dispatch },
    {
      updateState = true,
    }: {
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(CatalogMutations.SET_IS_CATALOG_LOADING, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Sale/Product/GetProducts`;
      await axios
        .get(url)
        .then(async (res) => {
          let list = res.data.map((el: any) => {
            return {
              ...el,
              unitId: el.unit ? el.unit.id : el.productType == 5 ? 2 : 1,
            };
          });

          if (
            rootGetters.moduleParametersList.find(
              (el) => el.key == "addProductProvidersToSearchEditDocument"
            ) &&
            rootGetters.moduleParametersList.find(
              (el) => el.key == "addProductProvidersToSearchEditDocument"
            ).value == "1"
          ) {
            let defaultMarginRate = rootGetters.moduleParametersList.find(
              (el) => el.key == "defaultMarginRate"
            )
              ? rootGetters.moduleParametersList.find(
                  (el) => el.key == "defaultMarginRate"
                ).value
              : 1.6;
            let productProviders = await dispatch("getProductProviders", {});
            productProviders = productProviders
              ? productProviders.map((product: any) => {
                  return {
                    id: product.id,
                    label: product.label,
                    reference: product.reference,
                    productType: product.productType,
                    description: product.description,
                    quantity: product.quantity,
                    packagingQuantity: product.quantity,
                    priceHT: Number(
                      product.netPrice * Number(defaultMarginRate)
                    ),
                    dryDisbursed: product.netPrice,
                    grossMarginHT: Number(
                      product.netPrice * Number(defaultMarginRate) -
                        product.netPrice
                    ),
                    marginRate:
                      defaultMarginRate > 1
                        ? (defaultMarginRate - 1) * 100
                        : defaultMarginRate * 100,
                    marginCoefficient: defaultMarginRate,
                    unAvailableForSale: false,
                    manageStock: false,
                    unit: product.unit,
                    referencielTvaId: product.referencielTvaId,
                    parentId: product.productId,
                  };
                })
              : [];

            dispatch("sortCatalogByProductType", {
              payload: res.data.concat(productProviders),
            });
          } else {
            dispatch("sortCatalogByProductType", { payload: res.data });
          }

          if (updateState) {
            commit(CatalogMutations.SET_IS_CATALOG_LOADING, false);
            commit(CatalogMutations.SET_CATALOG, list);
          }
          commit(CatalogMutations.SET_REQUEST_STATE_CATALOG, res);
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          commit(CatalogMutations.SET_IS_CATALOG_LOADING, false);
          commit(CatalogMutations.SET_REQUEST_STATE_CATALOG, err);
          reject(err);
        });
    });
  },

  async getProductById(
    { commit, dispatch },
    {
      productId,
      updateState = true,
    }: { productId: number; updateState: boolean }
  ): Promise<ProductRequestDto> {
    return new Promise(async (resolve, reject) => {
      if (updateState) commit(CatalogMutations.SET_IS_LOADING_PRODUCT, true);
      await axios
        .get(process.env.VUE_APP_API_ORISIS + "Sale/Product/GetProductById", {
          params: {
            productId: productId,
          },
        })
        .then(async (res) => {
          let productProviders = [];
          let stocks = [];
          if (checkFunctionUser(74, "all")) {
            await dispatch("getProductProviders", {
              productId: productId,
              updateState: false,
            }).then((res) => {
              productProviders = res;
            });
          }
          if (checkFunctionUser(34, "all")) {
            await dispatch("getStocks", {
              productId: productId,
              updateState: false,
            }).then((res) => {
              stocks = res;
            });
          }
          let product = {
            ...res.data,
            unitId: res.data.unit ? res.data.unit.id : null,
            referencielTvaId: res.data.referencielTva
              ? res.data.referencielTva.id
              : null,
            subProducts: res.data.subProducts.map((item: any) => {
              return {
                ...item.product,
                unitId: item.product.unit ? item.product.unit.id : null,
                total: item.product.quantity
                  ? item.product.priceHT * item.product.quantity
                  : 0,
                parentId: res.data.id,
                quantity: item.subQuantity,
              };
            }),
            productCategoriesId: res.data.productCategories.map(
              (item: any) => item.id
            ),
            saleCategoryId: res.data.saleCategory
              ? res.data.saleCategory.id
              : null,
            productProviders: productProviders,
            stocks: stocks,
          };
          commit(CatalogMutations.SET_REQUEST_STATE_CATALOG, res);
          commit(
            CatalogMutations.SET_WORK_ELEMENT_DETAILS,
            product.subProducts
          );
          commit(CatalogMutations.SET_IS_LOADING_PRODUCT, false);
          resolve(product);
        })
        .catch((err) => {
          console.error(err);
          commit(CatalogMutations.SET_REQUEST_STATE_CATALOG, err);
          commit(CatalogMutations.SET_IS_LOADING_PRODUCT, false);
          reject(err);
        });
    });
  },

  async getProductByProductTypeId(
    { commit },
    {
      productTypes,
      updateState = true,
    }: {
      productTypes: number;
      updateState: boolean;
    }
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (updateState) {
        commit(CatalogMutations.SET_IS_LOADING_PRODUCT, true);
      }
      let request = new URLSearchParams();
      request = generateParams(request, productTypes, "productTypes");
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/Product/GetProductsByProductType",
          {
            params: request,
          }
        )
        .then((res) => {
          commit(CatalogMutations.SET_REQUEST_STATE_CATALOG, res);
          if (updateState) {
            commit(CatalogMutations.SET_IS_LOADING_PRODUCT, false);
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CatalogMutations.SET_REQUEST_STATE_CATALOG, err);
          commit(CatalogMutations.SET_IS_LOADING_PRODUCT, false);
          reject(err);
        });
    });
  },

  async getProductsArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState)
        commit(CatalogMutations.SET_IS_CATALOG_ARCHIVED_LOADING, true);
      let url = `${process.env.VUE_APP_API_ORISIS}Sale/Product/GetProductsArchived`;
      await axios
        .get(url)
        .then((res) => {
          let list = res.data.map((el: any) => {
            return {
              ...el,
              unitId: el.unit ? el.unit.id : el.productType == 5 ? 2 : 1,
            };
          });
          if (updateState) {
            commit(CatalogMutations.SET_IS_CATALOG_ARCHIVED_LOADING, false);
            commit(CatalogMutations.SET_CATALOG_ARCHIVED, list);
          }
          commit(CatalogMutations.SET_REQUEST_STATE_CATALOG, res);
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          commit(CatalogMutations.SET_IS_CATALOG_ARCHIVED_LOADING, false);
          commit(CatalogMutations.SET_REQUEST_STATE_CATALOG, err);
          reject(err);
        });
    });
  },

  // async getProductsArchivedByCompany() {},

  async getProductArchivedById(
    { commit, dispatch },
    {
      productId,
      updateState = true,
    }: {
      productId: number;
      updateState: boolean;
    }
  ): Promise<ProductRequestDto> {
    return new Promise(async (resolve, reject) => {
      if (updateState) {
        commit(CatalogMutations.SET_IS_LOADING_PRODUCT, true);
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/Product/GetProductArchivedById",
          {
            params: {
              productId: productId,
            },
          }
        )
        .then(async (res) => {
          let productProviders = [];
          let stocks = [];
          if (checkFunctionUser(74, "all")) {
            await dispatch("getProductProviders", {
              productId: productId,
              updateState: false,
            }).then((res) => {
              productProviders = res;
            });
          }
          if (checkFunctionUser(34, "all")) {
            await dispatch("getStocks", {
              productId: productId,
              updateState: false,
            }).then((res) => {
              stocks = res;
            });
          }
          let product = {
            ...res.data,
            unitId: res.data.unit ? res.data.unit.id : null,
            subProducts: res.data.subProducts.map((item: any) => {
              return {
                ...item.product,
                unitId: item.product.unit ? item.product.unit.id : null,
                total: item.product.quantity
                  ? item.product.priceHT * item.product.quantity
                  : 0,
                parentId: res.data.id,
                quantity: item.subQuantity,
              };
            }),
            productCategoriesId: res.data.productCategories.map(
              (item: any) => item.id
            ),
            saleCategoryId: res.data.saleCategory
              ? res.data.saleCategory.id
              : null,
            productProviders: productProviders,
            stocks: stocks,
          };

          commit(CatalogMutations.SET_REQUEST_STATE_CATALOG, res);
          commit(
            CatalogMutations.SET_WORK_ELEMENT_DETAILS,
            product.subProducts
          );
          if (updateState) {
            commit(CatalogMutations.SET_IS_LOADING_PRODUCT, false);
          }
          resolve(product);
        })
        .catch((err) => {
          console.error(err);
          commit(CatalogMutations.SET_REQUEST_STATE_CATALOG, err);
          commit(CatalogMutations.SET_IS_LOADING_PRODUCT, false);
          reject(err);
        });
    });
  },

  async getProductsFormatedForInventory(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (updateState) {
        commit(CatalogMutations.SET_IS_LOADING_PRODUCT, true);
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/Product/GetProductsFormatedForInventory"
        )
        .then((res) => {
          if (updateState) {
            commit(CatalogMutations.SET_IS_LOADING_PRODUCT, false);
          }
          commit(CatalogMutations.SET_REQUEST_STATE_CATALOG, res);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // A verifier les erreurs dû au typage du product
  async createProduct(
    { commit, dispatch, rootGetters },
    { product, route }: { product: IProduct; route: string }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(CatalogMutations.SET_IS_CREATING_PRODUCT, true);
      await axios
        .post(
          process.env.VUE_APP_API_ORISIS + "Sale/Product/CreateProduct",
          product
        )
        .then(async (res) => {
          // SI LE PRODUIT EST UN OUVRAGE
          if (product.productType == 3) {
            let parentId = res.data.data.id;
            let workElementDetails = rootGetters.getWorkElementDetails;
            for (let index = 0; index < workElementDetails.length; index++) {
              const workElement = rootGetters.getWorkElementDetails[index];
              workElement.parentId = parentId;
              if (workElement.id === 0) {
                await dispatch("createProductWorkElement", {
                  product: workElement,
                });
              }
            }
          }
          await dispatch("updateProductProductCategory", {
            productCategories: product.productCategoriesId,
            productId: res.data.data.id,
          });
          if (product.useDegressiveSaleRate) {
            for (let i = 0; i < product.degressiveSalesRates.length; i++) {
              const degressiveSaleRate = product.degressiveSalesRates[i];

              if (degressiveSaleRate.id) {
                await dispatch("updateDegressiveSaleRate", {
                  degressiveSaleRate: degressiveSaleRate,
                });
              } else {
                degressiveSaleRate.productId = res.data.data.id;

                await dispatch("createDegressiveSaleRate", {
                  degressiveSaleRate: degressiveSaleRate,
                });
              }
            }
          }
          if (product.productProviders.length > 0) {
            for (let j = 0; j < product.productProviders.length; j++) {
              const productProvider = {
                ...product.productProviders[j],
                productId: res.data.data.id,
              };

              if (productProvider.id) {
                await dispatch("updateProductProvider", {
                  productProvider: productProvider,
                  reload: false,
                });
              } else {
                productProvider.productId = res.data.data.id;

                await dispatch("createProductProvider", {
                  productProvider: productProvider,
                  reload: false,
                });
              }
            }
            dispatch("getProductProviders", {});
          }
          if (route == "catalog") {
            rootGetters.catalog.unshift(res.data);
          } else if (route == "company") {
            rootGetters.catalogByCompany.unshift(res.data);
          }
          dispatch("sortCatalogByProductType", {
            payload: rootGetters.catalog,
          });
          commit(CatalogMutations.SET_CATALOG, rootGetters.catalog);
          commit(CatalogMutations.SET_PRODUCT, rootGetters.catalog);
          commit(CatalogMutations.SET_IS_CREATING_PRODUCT, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CatalogMutations.SET_IS_CREATING_PRODUCT, false);
          reject(err);
        });
    });
  },

  async createProductWorkElement(
    { commit, rootGetters, dispatch },
    { product }: { product: ProductRequestDto }
  ) {
    return new Promise(async (resolve, reject) => {
      commit(CatalogMutations.SET_IS_CREATING_PRODUCT, true);
      await axios
        .post(
          process.env.VUE_APP_API_ORISIS + "Sale/Product/CreateProduct",
          product
        )
        .then(async (res) => {
          rootGetters.catalog.unshift(res.data.data);
          rootGetters.getWorkElementDetails.splice(
            rootGetters.getWorkElementDetails.findIndex(
              (elem) => elem.reference == res.data.data.reference
            ),
            1,
            res.data.data
          );

          dispatch("sortCatalogByProductType", {
            payload: rootGetters.catalog,
          });

          commit(
            CatalogMutations.SET_WORK_ELEMENT_DETAILS,
            rootGetters.getWorkElementDetails
          );
          commit(CatalogMutations.SET_IS_CREATING_PRODUCT, false);
          commit(CatalogMutations.SET_IS_CATALOG_LOADING, false);
          commit(CatalogMutations.SET_CATALOG, rootGetters.catalog);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          commit(CatalogMutations.SET_IS_CREATING_PRODUCT, false);
        });
    });
  },

  // A verifier les erreurs dû au typage du product
  async updateProduct(
    { commit, rootGetters, dispatch },
    {
      product,
      updateWorkElement = true,
    }: { product: IProduct; updateWorkElement: boolean }
  ) {
    return new Promise(async (resolve, reject) => {
      commit(CatalogMutations.SET_IS_CREATING_PRODUCT, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Sale/Product/UpdateProduct",
          product
        )

        .then(async (res) => {
          if (product.productType == 3 && updateWorkElement) {
            let parentId = res.data.data.id;
            let workElementDetails = rootGetters.getWorkElementDetails;
            // on boucle les product enfant et on les ajoute
            for (let index = 0; index < workElementDetails.length; index++) {
              const workElement = rootGetters.getWorkElementDetails[index];
              workElement.parentId = parentId;
              workElement.referencielTvaId =
                workElement.referencielTvaId ?? workElement.referencielTva.id;

              if (workElement.id == 0) {
                await dispatch("createProductWorkElement", {
                  product: workElement,
                });
              }
            }
          }
          await dispatch("updateProductProductCategory", {
            productCategories: product.productCategoriesId,
            productId: res.data.data.id,
          });
          if (product.useDegressiveSaleRate) {
            for (let i = 0; i < product.degressiveSalesRates.length; i++) {
              const degressiveSaleRate = product.degressiveSalesRates[i];

              if (degressiveSaleRate.id) {
                degressiveSaleRate.productId = res.data.data.id;
                await dispatch("updateDegressiveSaleRate", {
                  degressiveSaleRate: degressiveSaleRate,
                });
              } else {
                degressiveSaleRate.productId = res.data.data.id;
                await dispatch("createDegressiveSaleRate", {
                  degressiveSaleRate: degressiveSaleRate,
                });
              }
            }
          }
          if (product.productProviders.length > 0) {
            for (let j = 0; j < product.productProviders.length; j++) {
              const productProvider = product.productProviders[j];
              if (productProvider.id) {
                await dispatch("updateProductProvider", {
                  productProvider: {
                    ...productProvider,
                    productId: res.data.data.id,
                  },

                  reload: false,
                });
              } else {
                productProvider.productId = res.data.data.id;
                await dispatch("createProductProvider", {
                  productProvider: productProvider,
                  reload: false,
                });
              }
            }
            dispatch("getProductProviders", {});
          }
          if (product.parentId && product.productType != 3) {
            let productParent = await dispatch("getProductById", {
              productId: product.parentId,
            });
            let totalPriceHt = 0;
            let totalDryDisbursed = 0;
            productParent.subProducts.forEach((productChildren) => {
              if (productChildren.id === product.id) {
                totalPriceHt += formatNumber(
                  product.priceHT * product.quantity
                );
                totalDryDisbursed += formatNumber(
                  product.dryDisbursed * product.quantity
                );
              } else {
                totalPriceHt += formatNumber(
                  productChildren.priceHT * productChildren.quantity
                );
                totalDryDisbursed += formatNumber(
                  productChildren.dryDisbursed * productChildren.quantity
                );
              }
            });
            productParent.priceHT = formatNumber(
              productParent.quantity * totalPriceHt
            );
            productParent.dryDisbursed = formatNumber(totalDryDisbursed);
            productParent.grossMarginHT = formatNumber(
              productParent.priceHT - productParent.dryDisbursed
            );
            productParent.marginRate = formatNumber(
              productParent.priceHT == 0
                ? 0
                : (productParent.grossMarginHT / productParent.priceHT) * 100
            );
            dispatch("updateProduct", {
              product: productParent,
              updateWorkElement: false,
            });
          }

          // Délai pour alimenter la grid car sinon le footer dépasse de la page (Autorisé par Baptiste ).
          setTimeout(() => {
            dispatch("getProducts", {});
          }, 500);

          dispatch("sortCatalogByProductType", {
            payload: rootGetters.catalog,
          });
          commit(CatalogMutations.SET_PRODUCT, rootGetters.catalog);
          commit(CatalogMutations.SET_CATALOG, rootGetters.catalog);
          commit(CatalogMutations.SET_IS_CREATING_PRODUCT, false);
          resolve(res.data.data);
        })

        .catch((err) => {
          console.error(err);
          commit(CatalogMutations.SET_IS_CREATING_PRODUCT, false);
          reject(err);
        });
    });
  },

  async updateProductProductCategory(
    { commit },
    {
      productCategories,
      productId,
    }: { productCategories: number[]; productId: number }
  ) {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Sale/Product/UpdateProductProductCategory",
          productCategories,
          {
            params: {
              productId: productId,
            },
          }
        )
        .then(async (res) => {
          commit(CatalogMutations.SET_IS_CREATING_PRODUCT, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CatalogMutations.SET_IS_CREATING_PRODUCT, false);
          reject(err);
        });
    });
  },
  async updateProductSubProduct(
    { commit },
    {
      parentProductId,
      productIds,
    }: { parentProductId: number; productIds: Array<number> }
  ) {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Sale/Product/updateProductSubProduct",
          productIds,
          {
            params: {
              parentProductId: parentProductId,
            },
          }
        )
        .then(async (res) => {
          commit(CatalogMutations.SET_IS_UPDATING_PRODUCT, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(CatalogMutations.SET_IS_UPDATING_PRODUCT, false);
          reject(err);
        });
    });
  },

  async updateProductWorkElement(
    { commit, rootGetters, dispatch },
    { product }: { product: ProductRequestDto }
  ) {
    return new Promise(async (resolve, reject) => {
      commit(CatalogMutations.SET_IS_CREATING_PRODUCT, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Sale/Product/UpdateProduct",
          product
        )
        .then((res) => {
          rootGetters.catalog.splice(
            rootGetters.catalog.findIndex((elem) => elem.id == product.id),
            1,
            res.data.data
          );
          dispatch("sortCatalogByProductType", {
            payload: rootGetters.catalog,
          });
          rootGetters.getWorkElementDetails.splice(
            rootGetters.getWorkElementDetails.findIndex(
              (elem) => elem.id == res.data.data.id
            ),
            1,
            res.data.data
          );

          commit(
            CatalogMutations.SET_WORK_ELEMENT_DETAILS,
            rootGetters.getWorkElementDetails
          );
          commit(CatalogMutations.SET_CATALOG, rootGetters.catalog);
          commit(CatalogMutations.SET_IS_CREATING_PRODUCT, true);
          resolve(res);
        })
        .catch((err) => {
          commit(CatalogMutations.SET_IS_CREATING_PRODUCT, true);
          reject(err);
        });
    });
  },

  async addWorkElement({ rootGetters, commit }, { payload }) {
    return new Promise(async (resolve) => {
      rootGetters.getWorkElementDetails.push(payload);
      commit(CatalogMutations.SET_WORK_ELEMENT_VALIDATE, false);
      resolve(true);
    });
  },

  async deleteWorkElement(
    { commit, dispatch, rootGetters },
    { index, product }
  ) {
    commit(CatalogMutations.SET_IS_DELETING_WORK_ELEMENT, true);
    if (product.id == 0) {
      rootGetters.getWorkElementDetails.splice(index, 1);
      commit(CatalogMutations.SET_IS_DELETING_WORK_ELEMENT, false);
      commit(
        CatalogMutations.SET_WORK_ELEMENT_DETAIL,
        rootGetters.getDocumentDetails
      );
    } else {
      await dispatch("deleteProducts", {
        productId: [product.id],
        parentId: product.parentId,
      }).then(() => {
        rootGetters.getWorkElementDetails.splice(index, 1);
        commit(CatalogMutations.SET_IS_DELETING_WORK_ELEMENT, false);
        commit(
          CatalogMutations.SET_WORK_ELEMENT_DETAIL,
          rootGetters.getDocumentDetails
        );
      });
    }
  },

  async restoreProducts(
    { commit, rootGetters },
    { productIds = [] }: { productIds: number[] }
  ) {
    return new Promise(async (resolve, reject) => {
      commit(CatalogMutations.SET_IS_UPDATING_PRODUCT, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Sale/Product/Restore",
          productIds
        )
        .then((res) => {
          productIds.forEach((productId: number) => {
            rootGetters.catalog.splice(
              rootGetters.catalog.findIndex((elem) => elem.id == productId),
              1
            );
          });
          commit(CatalogMutations.SET_IS_UPDATING_PRODUCT, false);
          commit(CatalogMutations.SET_REQUEST_STATE_CATALOG, res);
          resolve(res);
        })
        .catch((err) => {
          console.error(err);
          commit(CatalogMutations.SET_IS_UPDATING_PRODUCT, false);
          commit(CatalogMutations.SET_REQUEST_STATE_CATALOG, err);
          reject(err);
        });
    });
  },

  async archiveProducts(
    { commit, dispatch, rootGetters },
    { productIds = [] }: { productIds: number[] }
  ) {
    return new Promise(async (resolve, reject) => {
      commit(CatalogMutations.SET_IS_ARCHIVING_PRODUCT, true);
      await axios
        .delete(process.env.VUE_APP_API_ORISIS + "Sale/Product/Archive", {
          data: productIds,
        })
        .then((res) => {
          productIds.forEach((productId: number) => {
            rootGetters.catalog.splice(
              rootGetters.catalog.findIndex((elem) => elem.id == productId),
              1
            );
          });
          dispatch("sortCatalogByProductType", {
            payload: rootGetters.catalog,
          });
          commit(CatalogMutations.SET_IS_ARCHIVING_PRODUCT, false);
          resolve(res);
        })
        .catch((err) => {
          console.error(err);
          commit(CatalogMutations.SET_IS_ARCHIVING_PRODUCT, false);
          reject(err);
        });
    });
  },

  async deleteProducts(
    { commit, dispatch },
    {
      productIds = [],
      parentId = null,
    }: { productIds: number[]; parentId: number }
  ) {
    return new Promise(async (resolve, reject) => {
      commit(CatalogMutations.SET_IS_DELETING_PRODUCT, true);
      await axios
        .delete(process.env.VUE_APP_API_ORISIS + "Sale/Product/Delete", {
          data: productIds,
        })
        .then((res) => {
          if (!parentId || parentId == 0) {
            dispatch("getProductsArchived", {});
          }
          commit(CatalogMutations.SET_IS_DELETING_PRODUCT, false);
          resolve(res);
        })
        .catch((err) => {
          console.error(err);
          commit(CatalogMutations.SET_IS_DELETING_PRODUCT, false);
          reject(err);
        });
    });
  },

  sortCatalogByProductType({ commit, rootGetters }, { payload }) {
    let catalogSortedByProductType = payload.reduce(function (
      acc: any,
      obj: any
    ) {
      let key = Object.keys(rootGetters.productTypeList)[obj.productType];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    },
    {});
    commit(
      CatalogMutations.SET_CATALOG_SORTED_BY_PRODUCT_TYPE,
      catalogSortedByProductType
    );
  },
};
