import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { WasteCollectionPointTypeMutations } from "./mutations";
import { IWasteCollectionPointTypeState } from "./states";

export const actions: ActionTree<IWasteCollectionPointTypeState, RootState> = {
  getWasteCollectionPointTypes({
    commit,
    rootGetters,
  }, { updateState = true } : { updateState: boolean }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          WasteCollectionPointTypeMutations.SET_IS_LOADING_WASTE_COLLECTION_POINT_TYPE,
          true
        );
        commit(
          WasteCollectionPointTypeMutations.SET_WASTE_COLLECTION_POINT_TYPES_LIST,
          []
        );
      }
      axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          "Sale/WasteCollectionPointType/GetWasteCollectionPointTypes")
      .then((res) => {
        commit(
          WasteCollectionPointTypeMutations.SET_REQUEST_STATE_WASTE_COLLECTION_POINT_TYPE,
          res
        );
        if (updateState) {
          commit(
            WasteCollectionPointTypeMutations.SET_IS_LOADING_WASTE_COLLECTION_POINT_TYPE,
            false
          );
          commit(
            WasteCollectionPointTypeMutations.SET_WASTE_COLLECTION_POINT_TYPES_LIST,
            res.data
          );
        }
        resolve(res.data);
      })
      .catch((err) => {
        console.error(err);
        commit(
          WasteCollectionPointTypeMutations.SET_REQUEST_STATE_WASTE_COLLECTION_POINT_TYPE,
          err
        );
        if (updateState) {
          commit(
            WasteCollectionPointTypeMutations.SET_IS_LOADING_WASTE_COLLECTION_POINT_TYPE,
            false
          );
        }
        reject(err);
      });
    });
  },

  getWasteCollectionPointTypeById(
    { commit, rootGetters },
    { wasteCollectionPointTypeId, updateState = true }: { wasteCollectionPointTypeId: number, updateState: boolean}
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          WasteCollectionPointTypeMutations.SET_IS_LOADING_WASTE_COLLECTION_POINT_TYPE,
          true
        );
      }
      axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          "Sale/WasteCollectionPointType/GetWasteCollectionPointTypeById",
        {
          params: {
            wasteCollectionPointTypeId: wasteCollectionPointTypeId,
          },
        }
      )
      .then((res) => {
        if (updateState) {
          commit(
            WasteCollectionPointTypeMutations.SET_WASTE_COLLECTION_POINT_TYPE,
            res.data
          );
          commit(
            WasteCollectionPointTypeMutations.SET_IS_LOADING_WASTE_COLLECTION_POINT_TYPE,
            false
          );
        }
        commit(
          WasteCollectionPointTypeMutations.SET_REQUEST_STATE_WASTE_COLLECTION_POINT_TYPE,
          res
        );
        resolve(res.data);
      })
      .catch((err) => {
        console.error(err);
        commit(
          WasteCollectionPointTypeMutations.SET_REQUEST_STATE_WASTE_COLLECTION_POINT_TYPE,
          err
        );
        if (updateState) {
          commit(
            WasteCollectionPointTypeMutations.SET_IS_LOADING_WASTE_COLLECTION_POINT_TYPE,
            false
          );
        }
        reject(err);
      });
    });
  }
};
