import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { SaleCategoryRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { ISaleCategoryState } from './states'


export const getters: GetterTree<ISaleCategoryState, RootState> = {
  saleCategoriesList                    : (state): SaleCategoryRequestDto[] => state.saleCategoriesList,
  saleCategoriesTreeList                : (state): any[] => state.saleCategoriesTreeList,
  saleCategoriesListArchived            : (state): SaleCategoryRequestDto[] => state.saleCategoriesListArchived,
  saleCategory                          : (state): SaleCategoryRequestDto => state.saleCategory,

  // COMMON
  isLoadingSaleCategoriesList           : (state): boolean => state.isLoadingSaleCategoriesList,
  isLoadingSaleCategoriesTreeList       : (state): boolean => state.isLoadingSaleCategoriesTreeList,
  isLoadingSaleCategoriesListArchived   : (state): boolean => state.isLoadingSaleCategoriesListArchived,
  isLoadingSaleCategory                 : (state): boolean => state.isLoadingSaleCategory,
  isCreatingSaleCategory                : (state): boolean => state.isCreatingSaleCategory,
  isUpdatingSaleCategory                : (state): boolean => state.isUpdatingSaleCategory,
  isDeletingSaleCategory                : (state): boolean => state.isDeletingSaleCategory,
  isArchivingSaleCategory               : (state): boolean => state.isArchivingSaleCategory,
  requestStateSaleCategory              : (state): IRequestState => state.requestStateSaleCategory,
}