import { ModuleParameterRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { ModuleParameterMutations } from "./mutations";
import { IModuleParameterState } from "./states";

export const actions: ActionTree<IModuleParameterState, RootState> = {
  getModuleParameterById(
    { commit },
    {
      moduleParameterId,
      updateState = true,
    }: { moduleParameterId: number; updateState: boolean }
  ) {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(ModuleParameterMutations.SET_IS_LOADING_MODULE_PARAMETER, true);
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Account/ModuleParameter/GetModuleParameterById",
          {
            params: {
              moduleParameterId: moduleParameterId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(ModuleParameterMutations.SET_MODULE_PARAMETER, res.data);
            commit(
              ModuleParameterMutations.SET_IS_LOADING_MODULE_PARAMETER,
              false
            );
          }
          commit(
            ModuleParameterMutations.SET_REQUEST_STATE_MODULE_PARAMETER,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ModuleParameterMutations.SET_IS_LOADING_MODULE_PARAMETER,
            false
          );
          commit(
            ModuleParameterMutations.SET_REQUEST_STATE_MODULE_PARAMETER,
            err
          );
          reject(err);
        });
    });
  },

  getModuleParameters(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          ModuleParameterMutations.SET_IS_LOADING_MODULE_PARAMETERS_LIST,
          true
        );
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Account/ModuleParameter/GetModuleParameters"
        )
        .then((res) => {
          if (updateState) {
            commit(
              ModuleParameterMutations.SET_MODULE_PARAMETERS_LIST,
              res.data
            );
            commit(
              ModuleParameterMutations.SET_IS_LOADING_MODULE_PARAMETERS_LIST,
              false
            );
          }
          commit(
            ModuleParameterMutations.SET_REQUEST_STATE_MODULE_PARAMETER,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ModuleParameterMutations.SET_REQUEST_STATE_MODULE_PARAMETER,
            err
          );
          commit(
            ModuleParameterMutations.SET_IS_LOADING_MODULE_PARAMETERS_LIST,
            false
          );
          reject(err);
        });
    });
  },

  createModuleParameter(
    { commit, rootGetters },
    { moduleParameter }: { moduleParameter: ModuleParameterRequestDto }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(ModuleParameterMutations.SET_IS_LOADING_MODULE_PARAMETER, true);
      axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            "Account/ModuleParameter/CreateModuleParameter",
          { ...moduleParameter, workspaceId: rootGetters.workspaceSelected.id }
        )
        .then((res) => {
          rootGetters.moduleParametersList.push(res.data.data);
          commit(
            ModuleParameterMutations.SET_MODULE_PARAMETERS_LIST,
            rootGetters.moduleParametersList
          );
          commit(
            ModuleParameterMutations.SET_IS_CREATING_MODULE_PARAMETER,
            false
          );
          commit(
            ModuleParameterMutations.SET_REQUEST_STATE_MODULE_PARAMETER,
            res
          );
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ModuleParameterMutations.SET_IS_CREATING_MODULE_PARAMETER,
            false
          );
          commit(
            ModuleParameterMutations.SET_REQUEST_STATE_MODULE_PARAMETER,
            err
          );
          reject(err);
        });
    });
  },

  updateModuleParameter(
    { commit, rootGetters },
    { moduleParameter }: { moduleParameter: ModuleParameterRequestDto }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(ModuleParameterMutations.SET_IS_UPDATING_MODULE_PARAMETER, true);
      axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Account/ModuleParameter/UpdateModuleParameter",
          { ...moduleParameter, workspaceId: rootGetters.workspaceSelected.id }
        )
        .then((res) => {
          rootGetters.moduleParametersList.splice(
            rootGetters.moduleParametersList.findIndex(
              (elem) => elem.id == moduleParameter.id
            ),
            1,
            res.data.data
          );
          commit(
            ModuleParameterMutations.SET_MODULE_PARAMETERS_LIST,
            rootGetters.moduleParametersList
          );
          commit(
            ModuleParameterMutations.SET_IS_UPDATING_MODULE_PARAMETER,
            false
          );
          commit(
            ModuleParameterMutations.SET_REQUEST_STATE_MODULE_PARAMETER,
            res
          );
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ModuleParameterMutations.SET_IS_UPDATING_MODULE_PARAMETER,
            false
          );
          commit(
            ModuleParameterMutations.SET_REQUEST_STATE_MODULE_PARAMETER,
            err
          );
          reject(err);
        });
    });
  },

  //   async archiveModuleParameter(
  //     { commit, rootGetters },
  //     moduleParameterId: string
  //   ): Promise<void> {
  //     commit(ModuleParameterMutations.SET_IS_LOADING_MODULE_PARAMETER, true);
  //     await axios
  //       .delete(
  //         process.env.VUE_APP_API_ORISIS + "Account/ModuleParameter/Archive",
  //         {
  //           headers: {
  //             Authorization: `Bearer ${rootGetters.userToken}`,
  //           },
  //           data: [moduleParameterId],
  //         }
  //       )
  //       .then((res) => {
  //         rootGetters.moduleParametersListArchived.unshift(
  //           rootGetters.moduleParametersList.find(
  //             (elem) => elem.id == moduleParameterId
  //           )
  //         );
  //         rootGetters.moduleParametersList.splice(
  //           rootGetters.moduleParametersList.findIndex(
  //             (elem) => elem.id == moduleParameterId
  //           ),
  //           1
  //         );
  //         commit(
  //           ModuleParameterMutations.SET_MODULE_PARAMETERS_LIST,
  //           rootGetters.moduleParametersList
  //         );
  //         commit(
  //           ModuleParameterMutations.SET_REQUEST_STATE_MODULE_PARAMETER,
  //           res
  //         );
  //         commit(ModuleParameterMutations.SET_IS_LOADING_MODULE_PARAMETER, false);
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //         commit(
  //           ModuleParameterMutations.SET_REQUEST_STATE_MODULE_PARAMETER,
  //           err
  //         );
  //         commit(ModuleParameterMutations.SET_IS_LOADING_MODULE_PARAMETER, false);
  //       });
  //   },

  deleteModuleParameters(
    { commit, rootGetters },
    { moduleParameterIds = [] }: { moduleParameterIds: number[] }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(ModuleParameterMutations.SET_IS_DELETING_MODULE_PARAMETER, true);
      axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Account/ModuleParameter/Delete",
          {
            data: moduleParameterIds,
          }
        )
        .then((res) => {
          moduleParameterIds.forEach((moduleParameterId: number) => {
            rootGetters.moduleParametersList.splice(
              rootGetters.moduleParametersList.findIndex(
                (elem) => elem.id == moduleParameterId
              ),
              1
            );
          });
          commit(
            ModuleParameterMutations.SET_MODULE_PARAMETERS_LIST,
            rootGetters.moduleParametersList
          );
          commit(
            ModuleParameterMutations.SET_IS_DELETING_MODULE_PARAMETER,
            false
          );
          commit(
            ModuleParameterMutations.SET_REQUEST_STATE_MODULE_PARAMETER,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ModuleParameterMutations.SET_IS_DELETING_MODULE_PARAMETER,
            false
          );
          commit(
            ModuleParameterMutations.SET_REQUEST_STATE_MODULE_PARAMETER,
            err
          );
          reject(err);
        });
    });
  },
};
