import { MutationTree } from 'vuex'
import { IAuthState } from './states'
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export enum AuthMutations {
  SET_AUTH_SERVICE = 'SET_AUTH_SERVICE',
}

export const mutations: MutationTree<IAuthState> = {
  [AuthMutations.SET_AUTH_SERVICE]: (state, authService) => {
    state.authService = authService;
  },
}
