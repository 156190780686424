import { IPaymentCondition } from "@/types/api-orisis/interfaces/invoices/paymentCondition/IPaymentCondition";
import { PaymentConditionRequestDto, Civility } from "Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { PaymentConditionMutations } from "./mutations";
import { IPaymentConditionState } from "./states";

export const actions: ActionTree<IPaymentConditionState, RootState> = {
  async getPaymentConditionByWorkspaceId(
    { commit, rootGetters },
    { updateState = true } : { updateState: boolean }
  ): Promise<void> {
    if (updateState) {
      commit(
        PaymentConditionMutations.SET_IS_LOADING_PAYMENT_CONDITIONS_LIST,
        true
      );
    }
    await axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          "Sale/PaymentCondition/GetPaymentConditionByWorkspaceId",
        {
          params: {
            workspaceId: rootGetters.workspaceSelected.id,
          },
        }
      )
      .then((res) => {
        if (updateState) {
          commit(PaymentConditionMutations.SET_PAYMENT_CONDITIONS_LIST, res.data);
          commit(
            PaymentConditionMutations.SET_IS_LOADING_PAYMENT_CONDITIONS_LIST,
            false
          );
        }
        commit(
          PaymentConditionMutations.SET_REQUEST_STATE_PAYMENT_CONDITION,
          res
        );
      })
      .catch((err) => {
        console.error(err);
        commit(
          PaymentConditionMutations.SET_REQUEST_STATE_PAYMENT_CONDITION,
          err
        );
        commit(
          PaymentConditionMutations.SET_IS_LOADING_PAYMENT_CONDITIONS_LIST,
          false
        );
      });
  },

  async getPaymentConditionById(
    { commit },
    { paymentConditionId = null, updateState = true }: { paymentConditionId: number, updateState: boolean }
  ): Promise<PaymentConditionRequestDto> {
    return new Promise(async (resolve, reject) => {
      if (updateState) {
        commit(PaymentConditionMutations.SET_IS_LOADING_PAYMENT_CONDITION, true);
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/PaymentCondition/GetPaymentConditionById",
          {
            params: {
              paymentConditionId: paymentConditionId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(PaymentConditionMutations.SET_PAYMENT_CONDITION, res.data);
            commit(
              PaymentConditionMutations.SET_IS_LOADING_PAYMENT_CONDITION,
              false
            );
          }
          commit(
            PaymentConditionMutations.SET_REQUEST_STATE_PAYMENT_CONDITION,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            PaymentConditionMutations.SET_REQUEST_STATE_PAYMENT_CONDITION,
            err
          );
          commit(
            PaymentConditionMutations.SET_IS_LOADING_PAYMENT_CONDITION,
            false
          );
          reject(err);
        });
    });
  },

  async createPaymentCondition(
    { commit, rootGetters },
    { paymentCondition } : { paymentCondition: PaymentConditionRequestDto }
  ): Promise<void> {
    commit(PaymentConditionMutations.SET_IS_CREATING_PAYMENT_CONDITION, true);
    await axios
      .post(
        process.env.VUE_APP_API_ORISIS +
          "Sale/PaymentCondition/CreatePaymentCondition", paymentCondition,
      )
      .then((res) => {
        rootGetters.paymentConditionsList.unshift(res.data.data);
        commit(
          PaymentConditionMutations.SET_PAYMENT_CONDITIONS_LIST,
          rootGetters.paymentConditionsList
        );
        commit(
          PaymentConditionMutations.SET_REQUEST_STATE_PAYMENT_CONDITION,
          res
        );
        commit(
          PaymentConditionMutations.SET_IS_CREATING_PAYMENT_CONDITION,
          false
        );
      })
      .catch((err) => {
        console.error(err);
        commit(
          PaymentConditionMutations.SET_REQUEST_STATE_PAYMENT_CONDITION,
          err
        );
        commit(
          PaymentConditionMutations.SET_IS_CREATING_PAYMENT_CONDITION,
          false
        );
      });
  },

  async updatePaymentCondition(
    { commit, rootGetters },
    { paymentCondition } : { paymentCondition: PaymentConditionRequestDto }
  ): Promise<void> {
    commit(PaymentConditionMutations.SET_IS_UPDATING_PAYMENT_CONDITION, true);
    await axios
      .put(
        process.env.VUE_APP_API_ORISIS +
          "Sale/PaymentCondition/UpdatePaymentCondition", paymentCondition,
      )
      .then((res) => {
        rootGetters.paymentConditionsList.splice(
          rootGetters.paymentConditionsList.findIndex(
            (elem) => elem.id == paymentCondition.id
          ),
          1,
          paymentCondition
        );
        commit(
          PaymentConditionMutations.SET_PAYMENT_CONDITIONS_LIST,
          rootGetters.paymentConditionsList
        );
        commit(
          PaymentConditionMutations.SET_REQUEST_STATE_PAYMENT_CONDITION,
          res
        );
        commit(
          PaymentConditionMutations.SET_IS_UPDATING_PAYMENT_CONDITION,
          false
        );
      })
      .catch((err) => {
        console.error(err);
        commit(
          PaymentConditionMutations.SET_REQUEST_STATE_PAYMENT_CONDITION,
          err
        );
        commit(
          PaymentConditionMutations.SET_IS_UPDATING_PAYMENT_CONDITION,
          false
        );
      });
  },

  async restorePaymentCondition(
    { commit, rootGetters },
    { paymentConditionIds = [] } : { paymentConditionIds: number[] }
  ): Promise<void> {
    commit(PaymentConditionMutations.SET_IS_UPDATING_PAYMENT_CONDITION, true);
    await axios
      .put(
        process.env.VUE_APP_API_ORISIS + "Sale/PaymentCondition/Restore",
        paymentConditionIds,
      )
      .then((res) => {
        paymentConditionIds.forEach((paymentConditionId: number) => {
          rootGetters.paymentConditionsList.unshift(
            rootGetters.paymentConditionsListArchived.find(
              (elem) => elem.id == paymentConditionId
            )
          );
          rootGetters.paymentConditionsListArchived.splice(
            rootGetters.paymentConditionsListArchived.findIndex(
              (elem) => elem.id == paymentConditionId
            ),
            1
          );
        });
        commit(
          PaymentConditionMutations.SET_REQUEST_STATE_PAYMENT_CONDITION,
          res
        );
        commit(
          PaymentConditionMutations.SET_IS_UPDATING_PAYMENT_CONDITION,
          false
        );
      })
      .catch((err) => {
        console.error(err);
        commit(
          PaymentConditionMutations.SET_REQUEST_STATE_PAYMENT_CONDITION,
          err
        );
        commit(
          PaymentConditionMutations.SET_IS_UPDATING_PAYMENT_CONDITION,
          false
        );
      });
  },

  async archivePaymentCondition(
    { commit, rootGetters },
    { paymentConditionIds = [] } : { paymentConditionIds: number[] }
  ): Promise<void> {
    commit(PaymentConditionMutations.SET_IS_ARCHIVING_PAYMENT_CONDITION, true);
    await axios
      .delete(
        process.env.VUE_APP_API_ORISIS + "Sale/PaymentCondition/Archive",
        {
          data: paymentConditionIds,
        }
      )
      .then((res) => {
        paymentConditionIds.forEach((paymentConditionId: number) => {
          rootGetters.paymentConditionsListArchived.unshift(
            rootGetters.paymentConditionsList.find(
              (elem) => elem.id == paymentConditionId
            )
          );
          rootGetters.paymentConditionsList.splice(
            rootGetters.paymentConditionsList.findIndex(
              (elem) => elem.id == paymentConditionId
            ),
            1
          );
        });
        commit(
          PaymentConditionMutations.SET_PAYMENT_CONDITIONS_LIST,
          rootGetters.paymentConditionsList
        );
        commit(
          PaymentConditionMutations.SET_REQUEST_STATE_PAYMENT_CONDITION,
          res
        );
        commit(
          PaymentConditionMutations.SET_IS_ARCHIVING_PAYMENT_CONDITION,
          false
        );
      })
      .catch((err) => {
        console.error(err);
        commit(
          PaymentConditionMutations.SET_REQUEST_STATE_PAYMENT_CONDITION,
          err
        );
        commit(
          PaymentConditionMutations.SET_IS_ARCHIVING_PAYMENT_CONDITION,
          false
        );
      });
  },

  async deletePaymentCondition(
    { commit, rootGetters },
    { paymentConditionIds = [] } : { paymentConditionIds: number[] }
  ): Promise<void> {
    commit(PaymentConditionMutations.SET_IS_DELETING_PAYMENT_CONDITION, true);
    await axios
      .delete(
        process.env.VUE_APP_API_ORISIS +
          "Sale/PaymentCondition/DeletePaymentCondition",
        {
          data: paymentConditionIds,
        }
      )
      .then((res) => {
        paymentConditionIds.forEach((paymentConditionId: number) => {
          rootGetters.paymentConditionsListArchived.splice(
            rootGetters.paymentConditionsListArchived.findIndex(
              (elem) => elem.id == paymentConditionId
            ),
            1
          );
        });
        commit(
          PaymentConditionMutations.SET_PAYMENT_CONDITIONS_LIST,
          rootGetters.paymentConditionsList
        );
        commit(
          PaymentConditionMutations.SET_REQUEST_STATE_PAYMENT_CONDITION,
          res
        );
        commit(
          PaymentConditionMutations.SET_IS_DELETING_PAYMENT_CONDITION,
          false
        );
      })
      .catch((err) => {
        console.error(err);
        commit(
          PaymentConditionMutations.SET_REQUEST_STATE_PAYMENT_CONDITION,
          err
        );
        commit(
          PaymentConditionMutations.SET_IS_DELETING_PAYMENT_CONDITION,
          false
        );
      });
  },
};
