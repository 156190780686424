import { MissionRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { MissionMutations } from "./mutations";
import { IMissionState } from "./states";
import dayjs from "dayjs";
import { Timezone } from "@syncfusion/ej2-schedule";
let timezoneSchedule = new Timezone();

export const actions: ActionTree<IMissionState, RootState> = {
  getMissionById(
    { commit, rootGetters },
    {
      missionId,
      updateState = true,
    }: { missionId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(MissionMutations.SET_IS_LOADING_MISSION, true);
      }
      axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Reporting/Mission/GetMissionById`,
          {
            params: {
              missionId: missionId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(MissionMutations.SET_MISSION, res.data);
            commit(MissionMutations.SET_IS_LOADING_MISSION, false);
          }
          commit(MissionMutations.SET_REQUEST_STATE_MISSION, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(MissionMutations.SET_REQUEST_STATE_MISSION, err);
          if (updateState) {
            commit(MissionMutations.SET_IS_LOADING_MISSION, false);
          }
          reject(err);
        });
    });
  },

  getMissions(
    { commit },
    {
      affairId,
      analyticSettingId,
      templateOnly = false,
      updateState = true,
    }: {
      affairId: number;
      analyticSettingId: number;
      templateOnly: boolean;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(MissionMutations.SET_IS_LOADING_MISSION_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Reporting/Mission/GetMissions`;
      axios
        .get(url, {
          params: {
            affairId: affairId,
            analyticSettingId: analyticSettingId,
            templateOnly: templateOnly,
          },
        })
        .then((res) => {
          let list = res.data.map((el) => {
            return {
              ...el,
              date: el.date
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(new Date(el.date))
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
            };
          });
          if (updateState) {
            commit(MissionMutations.SET_MISSIONS_LIST, list);
            commit(MissionMutations.SET_MISSIONS_LIST_TEMPLATE, list);
            commit(MissionMutations.SET_IS_LOADING_MISSION_LIST, false);
          }
          commit(MissionMutations.SET_REQUEST_STATE_MISSION, res);
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          commit(MissionMutations.SET_REQUEST_STATE_MISSION, err);
          if (updateState) {
            commit(MissionMutations.SET_IS_LOADING_MISSION_LIST, false);
          }
          reject(err);
        });
    });
  },

  getMissionsByAnalyticsSettingId(
    { commit, rootGetters },
    {
      analyticsSettingId,
      updateState = true,
    }: { analyticsSettingId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(MissionMutations.SET_IS_LOADING_MISSION_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Reporting/Mission/GetMissions`;
      axios
        .get(url, {
          params: {
            analyticsSettingId: analyticsSettingId,
          },
        })
        .then((res) => {
          if (updateState) {
            commit(MissionMutations.SET_IS_LOADING_MISSION_LIST, false);
            commit(MissionMutations.SET_MISSIONS_LIST, res.data);
          }
          commit(MissionMutations.SET_REQUEST_STATE_MISSION, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(MissionMutations.SET_IS_LOADING_MISSION_LIST, false);
          }
          commit(MissionMutations.SET_REQUEST_STATE_MISSION, err);
          reject(err);
        });
    });
  },

  getMissionsByAffairId(
    { commit, rootGetters },
    { affairId, updateState = true }: { affairId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(MissionMutations.SET_IS_LOADING_MISSION_LIST_BY_AFFAIR_ID, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Reporting/Mission/GetMissions`;
      axios
        .get(url, {
          params: {
            affairId: affairId,
          },
        })
        .then((res) => {
          let missionsList = res.data.filter(
            (el: any) => el.affairId == affairId || el.affairId == null
          );
          if (updateState) {
            commit(
              MissionMutations.SET_IS_LOADING_MISSION_LIST_BY_AFFAIR_ID,
              false
            );
            commit(
              MissionMutations.SET_MISSIONS_LIST_BY_AFFAIR_ID,
              missionsList
            );
          }
          commit(MissionMutations.SET_REQUEST_STATE_MISSION, res);
          resolve(missionsList);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              MissionMutations.SET_IS_LOADING_MISSION_LIST_BY_AFFAIR_ID,
              false
            );
          }
          commit(MissionMutations.SET_REQUEST_STATE_MISSION, err);
          reject(err);
        });
    });
  },

  getMissionsTemplates(
    { commit, rootGetters },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(MissionMutations.SET_IS_LOADING_MISSION_LIST_TEMPLATE, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Reporting/Mission/GetMissions`;
      axios
        .get(url, {
          params: {
            templateOnly: true,
          },
        })
        .then((res) => {
          if (updateState) {
            commit(
              MissionMutations.SET_IS_LOADING_MISSION_LIST_TEMPLATE,
              false
            );
            commit(MissionMutations.SET_MISSIONS_LIST_TEMPLATE, res.data);
          }
          commit(MissionMutations.SET_REQUEST_STATE_MISSION, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              MissionMutations.SET_IS_LOADING_MISSION_LIST_TEMPLATE,
              false
            );
          }
          commit(MissionMutations.SET_REQUEST_STATE_MISSION, err);
          reject(err);
        });
    });
  },

  getMissionArchived(
    { commit, rootGetters },
    {
      missionId,
      updateState = true,
    }: { missionId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(MissionMutations.SET_IS_LOADING_MISSION, true);
      }
      axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Reporting/Mission/GetMissionArchivedById`,
          {
            params: {
              missionId: missionId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(MissionMutations.SET_MISSION, res.data);
            commit(MissionMutations.SET_IS_LOADING_MISSION, false);
          }
          commit(MissionMutations.SET_REQUEST_STATE_MISSION, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(MissionMutations.SET_REQUEST_STATE_MISSION, err);
          if (updateState) {
            commit(MissionMutations.SET_IS_LOADING_MISSION, false);
          }
          reject(err);
        });
    });
  },

  getMissionsArchived(
    { commit, rootGetters },
    {
      affairId,
      analyticSettingId,
      templateOnly = false,
      updateState = true,
    }: {
      affairId: number;
      analyticSettingId: number;
      templateOnly: boolean;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(MissionMutations.SET_IS_LOADING_MISSION_LIST_ARCHIVED, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Reporting/Mission/GetMissionsArchived`;
      axios
        .get(url, {
          params: {
            affairId: affairId,
            analyticSettingId: analyticSettingId,
            templateOnly: templateOnly,
          },
        })
        .then((res) => {
          if (updateState) {
            commit(
              MissionMutations.SET_IS_LOADING_MISSION_LIST_ARCHIVED,
              false
            );
            commit(MissionMutations.SET_MISSIONS_LIST_ARCHIVED, res.data);
          }
          commit(MissionMutations.SET_REQUEST_STATE_MISSION, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              MissionMutations.SET_IS_LOADING_MISSION_LIST_ARCHIVED,
              false
            );
          }
          commit(MissionMutations.SET_REQUEST_STATE_MISSION, err);
          reject(err);
        });
    });
  },

  createMission(
    { commit, rootGetters },
    {
      mission,
      route = null,
    }: { mission: MissionRequestDto; route?: string | null }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(MissionMutations.SET_IS_CREATING_MISSION, true);
      axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Reporting/Mission/CreateMission`,
          mission
        )
        .then((res) => {
          switch (route) {
            case "affair":
              rootGetters.missionsListByAffairId.push(res.data.data);
              commit(
                MissionMutations.SET_MISSIONS_LIST_BY_AFFAIR_ID,
                rootGetters.missionsListByAffairId
              );
              break;
            default:
              rootGetters.missionsList.push(res.data.data);
              commit(
                MissionMutations.SET_MISSIONS_LIST,
                rootGetters.missionsList
              );
          }
          commit(MissionMutations.SET_REQUEST_STATE_MISSION, res);
          commit(MissionMutations.SET_IS_CREATING_MISSION, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(MissionMutations.SET_REQUEST_STATE_MISSION, err);
          commit(MissionMutations.SET_IS_CREATING_MISSION, false);
          reject(err);
        });
    });
  },

  updateMission(
    { commit, rootGetters },
    {
      mission,
      route = null,
    }: { mission: MissionRequestDto; route?: string | null }
  ) {
    return new Promise<void>((resolve, reject) => {
      commit(MissionMutations.SET_IS_UPDATING_MISSION, true);
      axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Reporting/Mission/UpdateMission`,
          mission
        )
        .then((res) => {
          switch (route) {
            case "affair":
              if (
                rootGetters.missionsListByAffairId.find(
                  (elem) => elem.id == mission.id
                )
              ) {
                rootGetters.missionsListByAffairId.splice(
                  rootGetters.missionsListByAffairId.findIndex(
                    (elem) => elem.id == mission.id
                  ),
                  1,
                  mission
                );
                commit(
                  MissionMutations.SET_MISSIONS_LIST_BY_AFFAIR_ID,
                  rootGetters.missionsListByAffairId
                );
              }
              break;
            default:
              if (
                rootGetters.missionsList.find((elem) => elem.id == mission.id)
              ) {
                rootGetters.missionsList.splice(
                  rootGetters.missionsList.findIndex(
                    (elem) => elem.id == mission.id
                  ),
                  1,
                  mission
                );
                commit(
                  MissionMutations.SET_MISSIONS_LIST,
                  rootGetters.missionsList
                );
              }
              break;
          }
          commit(MissionMutations.SET_REQUEST_STATE_MISSION, res);
          commit(MissionMutations.SET_IS_UPDATING_MISSION, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(MissionMutations.SET_REQUEST_STATE_MISSION, err);
          commit(MissionMutations.SET_IS_UPDATING_MISSION, false);
          reject(err);
        });
    });
  },

  restoreMissions(
    { commit, rootGetters },
    { missionIds = [] }: { missionIds: number[] }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(MissionMutations.SET_IS_UPDATING_MISSION, true);
      axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Reporting/Mission/Restore",
          missionIds
        )
        .then((res) => {
          missionIds.forEach((missionId) => {
            const index = rootGetters.missionsListArchived.findIndex(
              (elem) => elem.id == missionId
            );
            const indexAffair = rootGetters.missionsListByAffairId.findIndex(
              (elem) => elem.id == missionId
            );
            if (index != -1) {
              rootGetters.missionsList.unshift(
                rootGetters.missionsListArchived[index]
              );
              commit(
                MissionMutations.SET_MISSIONS_LIST,
                rootGetters.missionsList
              );
            }
            if (indexAffair != -1) {
              rootGetters.missionsListByAffairId.unshift(
                rootGetters.missionsListArchived[indexAffair]
              );
              commit(
                MissionMutations.SET_MISSIONS_LIST_BY_AFFAIR_ID,
                rootGetters.missionsListByAffairId
              );
            }
            rootGetters.missionsListArchived.splice(index, 1);
            commit(
              MissionMutations.SET_MISSIONS_LIST_ARCHIVED,
              rootGetters.missionsListArchived
            );
          });
          commit(MissionMutations.SET_MISSIONS_LIST, rootGetters.missionsList);
          commit(
            MissionMutations.SET_MISSIONS_LIST_ARCHIVED,
            rootGetters.missionsListArchived
          );
          commit(MissionMutations.SET_REQUEST_STATE_MISSION, res);
          commit(MissionMutations.SET_IS_UPDATING_MISSION, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(MissionMutations.SET_REQUEST_STATE_MISSION, err);
          commit(MissionMutations.SET_IS_UPDATING_MISSION, false);
          reject(err);
        });
    });
  },

  archiveMissions(
    { commit, rootGetters },
    { missionIds = [] }: { missionIds: number[] }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(MissionMutations.SET_IS_ARCHIVING_MISSION, true);
      axios
        .delete(process.env.VUE_APP_API_ORISIS + "Reporting/Mission/Archive", {
          data: missionIds,
        })
        .then((res) => {
          missionIds.forEach((missionId) => {
            const index = rootGetters.missionsList.findIndex(
              (elem) => elem.id == missionId
            );
            const indexAffair = rootGetters.missionsListByAffairId.findIndex(
              (elem) => elem.id == missionId
            );
            if (index != -1) {
              rootGetters.missionsList.splice(index, 1);
              commit(
                MissionMutations.SET_MISSIONS_LIST,
                rootGetters.missionsList
              );
            }
            if (indexAffair != -1) {
              rootGetters.missionsListByAffairId.splice(indexAffair, 1);
              commit(
                MissionMutations.SET_MISSIONS_LIST_BY_AFFAIR_ID,
                rootGetters.missionsListByAffairId
              );
            }
            rootGetters.missionsListArchived.unshift(
              rootGetters.missionsList[index]
            );
            commit(
              MissionMutations.SET_MISSIONS_LIST_ARCHIVED,
              rootGetters.missionsListArchived
            );
          });
          commit(MissionMutations.SET_MISSIONS_LIST, rootGetters.missionsList);
          commit(MissionMutations.SET_REQUEST_STATE_MISSION, res);
          commit(MissionMutations.SET_IS_ARCHIVING_MISSION, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(MissionMutations.SET_REQUEST_STATE_MISSION, err);
          commit(MissionMutations.SET_IS_ARCHIVING_MISSION, false);
          reject(err);
        });
    });
  },

  deleteMissions(
    { commit, rootGetters },
    { missionIds = [] }: { missionIds: number[] }
  ) {
    return new Promise<void>((resolve, reject) => {
      commit(MissionMutations.SET_IS_DELETING_MISSION, true);
      axios
        .delete(`${process.env.VUE_APP_API_ORISIS}Reporting/Mission/Delete`, {
          data: missionIds,
        })
        .then((res) => {
          missionIds.forEach((missionId) => {
            const index = rootGetters.missionsList.findIndex(
              (elem) => elem.id == missionId
            );
            const indexAffair = rootGetters.missionsListByAffairId.findIndex(
              (elem) => elem.id == missionId
            );
            if (index != -1) {
              rootGetters.missionsList.splice(index, 1);
              commit(
                MissionMutations.SET_MISSIONS_LIST,
                rootGetters.missionsList
              );
            }
            if (indexAffair != -1) {
              rootGetters.missionsListByAffairId.splice(indexAffair, 1);
              commit(
                MissionMutations.SET_MISSIONS_LIST_BY_AFFAIR_ID,
                rootGetters.missionsListByAffairId
              );
            }
          });
          commit(MissionMutations.SET_REQUEST_STATE_MISSION, res);
          commit(MissionMutations.SET_IS_DELETING_MISSION, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(MissionMutations.SET_REQUEST_STATE_MISSION, err);
          commit(MissionMutations.SET_IS_DELETING_MISSION, false);
          reject(err);
        });
    });
  },
};
