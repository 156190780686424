import { IDegressivePurchaseRateState } from "./states"
import { Module } from "vuex"
import { DegressivePurchaseRatesRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state: IDegressivePurchaseRateState = {
    degressivePurchaseRatesList                     : Array<DegressivePurchaseRatesRequestDto>(),
    degressivePurchaseRate                          : <DegressivePurchaseRatesRequestDto>{},
    isLoadingDegressivePurchaseRatesList            : false,
    isLoadingDegressivePurchaseRate                 : false,
    isCreatingDegressivePurchaseRate                : false,
    isUpdatingDegressivePurchaseRate                : false,
    isDeletingDegressivePurchaseRate                : false,
    requestStateDegressivePurchaseRate              : <IRequestState>{},
}

export const DegressivePurchaseRateModule: Module<IDegressivePurchaseRateState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}