import dayjs from "dayjs";

export function formatCurrency(value: number): string {
  var formatter = new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  });

  return formatter
    .format(Number(value))
    .replace(/&nbsp;/g, "")
    .replace(" ", "");
}

export function replaceNbsps(str: string): string {
  var re = new RegExp(String.fromCharCode(160), "g");
  return str.replace(re, " ");
}

export function formatNumber(value: number, fixed = 2): number {
  const numberFixed = 10 ** fixed;
  let round = Math.round(value * numberFixed) / numberFixed;
  if (isNaN(round)) {
    round = 0;
  }
  return round;
}

export function formatNumberDecimal(num, decimals = 2) {
  return parseFloat(num).toFixed(decimals);
}

export function formatNumberToString(value: number, fixed = 2) {
  const numberOfDecimal = value.toString().includes(".")
    ? value.toString().split(".")[1].length
    : 0;
  let numberFixed = 0;
  if (numberOfDecimal <= 4) {
    numberFixed = 10 ** numberOfDecimal;
  } else {
    numberFixed = 10 ** 4;
  }
  let round = Math.round(value * numberFixed) / numberFixed;
  if (isNaN(round)) {
    round = 0;
  }
  let formattedNumber = round.toFixed(fixed);
  formattedNumber = formattedNumber.replace(".", ",");
  return formattedNumber;
}

export function formatDate(date: Date) {
  return dayjs(date).format("DD/MM/YYYY");
}

export function formatPercent(value: number) {
  var formatter = new Intl.NumberFormat("fr-FR", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formatter.format(Number(value) / 100);
}

export function formatHTMLToString(value: string) {
  return value ? value.replace(/<[^>]+>/g, "") : null;
}

export function generateParams(request, params, paramsType) {
  for (let i = 0; i < (params ? params.length : 0); i++) {
    request.append(paramsType, params[i]);
  }
  return request;
}
