import { IEventType } from "@/types/api-orisis/interfaces/Planning/IEventType";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { EventTypeMutations } from "./mutations";
import { IEventTypeState } from "./states";
import { EventTypeRequestDto } from "Api";

export const actions: ActionTree<IEventTypeState, RootState> = {
  async getEventTypeById(
    { commit },
    {
      eventTypeId,
      updateState = true,
    }: { eventTypeId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(EventTypeMutations.SET_IS_LOADING_EVENT_TYPE, true);
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Plannings/EventType/GetEventTypeById",
          {
            params: {
              eventTypeId: eventTypeId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(EventTypeMutations.SET_EVENT_TYPE, res.data);
            commit(EventTypeMutations.SET_IS_LOADING_EVENT_TYPE, false);
          }
          commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, err);
          commit(EventTypeMutations.SET_IS_LOADING_EVENT_TYPE, false);
          reject(err);
        });
    });
  },

  async getEventTypeArchivedById(
    { commit },
    {
      eventTypeId,
      updateState = true,
    }: { eventTypeId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(EventTypeMutations.SET_IS_LOADING_EVENT_TYPE, true);
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Plannings/EventType/GetEventTypeArchivedById",
          {
            params: {
              eventTypeId: eventTypeId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(EventTypeMutations.SET_EVENT_TYPE, res.data);
            commit(EventTypeMutations.SET_IS_LOADING_EVENT_TYPE, false);
          }
          commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, err);
          commit(EventTypeMutations.SET_IS_LOADING_EVENT_TYPE, false);
          reject(err);
        });
    });
  },

  async getEventTypes(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(EventTypeMutations.SET_IS_LOADING_EVENT_TYPE_LIST, true);
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS + "Plannings/EventType/GetEventTypes"
        )
        .then((res) => {
          if (updateState) {
            commit(EventTypeMutations.SET_EVENT_TYPES_LIST, res.data);
            commit(EventTypeMutations.SET_IS_LOADING_EVENT_TYPE_LIST, false);
          }
          commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err),
            commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, err),
            commit(EventTypeMutations.SET_IS_LOADING_EVENT_TYPE_LIST, false),
            reject(err);
        });
    });
  },

  async getEventTypesArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(EventTypeMutations.SET_IS_LOADING_EVENT_TYPE_LIST_ARCHIVED, true);
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Plannings/EventType/GetEventTypesArchived"
        )
        .then((res) => {
          if (updateState) {
            commit(EventTypeMutations.SET_EVENT_TYPES_LIST_ARCHIVED, res.data);
            commit(EventTypeMutations.SET_IS_LOADING_EVENT_TYPE_LIST_ARCHIVED, false);
          }
          commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, err);
          commit(
            EventTypeMutations.SET_IS_LOADING_EVENT_TYPE_LIST_ARCHIVED,
            false
          );
          reject(err);
        });
    });
  },

  async createEventType(
    { commit, rootGetters },
    { eventType }: { eventType: EventTypeRequestDto }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(EventTypeMutations.SET_IS_CREATING_EVENT_TYPE, true);
      axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            "Plannings/EventType/CreateEventType",
          eventType
        )
        .then((res) => {
          rootGetters.eventTypesList.push(res.data.data);
          commit(
            EventTypeMutations.SET_EVENT_TYPES_LIST,
            rootGetters.eventTypesList
          );
          commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, res);
          commit(EventTypeMutations.SET_IS_CREATING_EVENT_TYPE, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, err);
          commit(EventTypeMutations.SET_IS_CREATING_EVENT_TYPE, false);
          reject(err);
        });
    });
  },

  async updateEventType(
    { commit, rootGetters },
    { eventType }: { eventType: EventTypeRequestDto }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(EventTypeMutations.SET_IS_UPDATING_EVENT_TYPE, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Plannings/EventType/UpdateEventType",
          eventType
        )
        .then((res) => {
          rootGetters.eventTypesList.splice(
            rootGetters.eventTypesList.findIndex(
              (elem) => elem.id == eventType.id
            ),
            1,
            res.data.data
          );
          commit(
            EventTypeMutations.SET_EVENT_TYPES_LIST,
            rootGetters.eventTypesList
          );
          commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, res);
          commit(EventTypeMutations.SET_IS_UPDATING_EVENT_TYPE, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, err);
          commit(EventTypeMutations.SET_IS_UPDATING_EVENT_TYPE, false);
          reject(err);
        });
    });
  },

  async restoreEventTypes(
    { commit, rootGetters },
    { eventTypeIds = [] }: { eventTypeIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(EventTypeMutations.SET_IS_UPDATING_EVENT_TYPE, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Plannings/EventType/Restore",
          eventTypeIds
        )
        .then((res) => {
          eventTypeIds.forEach((eventTypeId: number) => {
            rootGetters.eventTypesList.splice(
              rootGetters.eventTypesListArchived.findIndex(
                (elem) => elem.id == eventTypeId
              )
            );
          });
          commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, res);
          commit(EventTypeMutations.SET_IS_UPDATING_EVENT_TYPE, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, err);
          commit(EventTypeMutations.SET_IS_UPDATING_EVENT_TYPE, false);
          reject(err);
        });
    });
  },

  async archiveEventTypes(
    { commit, rootGetters },
    { eventTypeIds = [] }: { eventTypeIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(EventTypeMutations.SET_IS_ARCHIVING_EVENT_TYPE, true);
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Plannings/EventType/Archive",
          {
            data: eventTypeIds,
          }
        )
        .then((res) => {
          eventTypeIds.forEach((eventTypeId: number) => {
            rootGetters.eventTypesList.splice(
              rootGetters.eventTypesList.findIndex(
                (elem) => elem.id == eventTypeId
              )
            );
          });
          commit(
            EventTypeMutations.SET_EVENT_TYPES_LIST,
            rootGetters.eventTypesList
          );
          commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, res);
          commit(EventTypeMutations.SET_IS_ARCHIVING_EVENT_TYPE, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, err);
          commit(EventTypeMutations.SET_IS_ARCHIVING_EVENT_TYPE, false);
          reject(err);
        });
    });
  },

  async deleteEventTypes(
    { commit, rootGetters },
    { eventTypeIds = [] }: { eventTypeIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(EventTypeMutations.SET_IS_DELETING_EVENT_TYPE, true);
      await axios
        .delete(process.env.VUE_APP_API_ORISIS + "Plannings/EventType/Delete", {
          data: eventTypeIds,
        })
        .then((res) => {
          eventTypeIds.forEach((eventTypeId: number) => {
            rootGetters.eventTypesList.splice(
              rootGetters.eventTypesList.findIndex(
                (elem) => elem.id == eventTypeId
              )
            );
          });
          commit(
            EventTypeMutations.SET_EVENT_TYPES_LIST,
            rootGetters.eventTypesList
          );
          commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, res);
          commit(EventTypeMutations.SET_IS_DELETING_EVENT_TYPE, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, err);
          commit(EventTypeMutations.SET_IS_DELETING_EVENT_TYPE, false);
          reject(err);
        });
    });
  },
};
