import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { ExportSalesJournalMutations } from "./mutations";
import { IExportSalesJournalState } from "./states";
import { IExportSalesJournal } from "@/types/api-orisis/interfaces/Accounting/exportSalesJournal/IExportSalesJournal";

export const actions: ActionTree<IExportSalesJournalState, RootState> = {
  async getExportSalesJournals(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (updateState) {
        commit(
          ExportSalesJournalMutations.SET_IS_LOADING_EXPORT_SALES_JOURNAL_LIST,
          true
        );
      }
      let url =
        process.env.VUE_APP_API_ORISIS +
        "Sale/ExportSalesJournal/GetExportSalesJournals";
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              ExportSalesJournalMutations.SET_EXPORT_SALES_JOURNAL_LIST,
              res.data
            );
            commit(
              ExportSalesJournalMutations.SET_IS_LOADING_EXPORT_SALES_JOURNAL_LIST,
              false
            );
          }
          commit(
            ExportSalesJournalMutations.SET_REQUEST_STATE_EXPORT_SALES_JOURNAL,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ExportSalesJournalMutations.SET_REQUEST_STATE_EXPORT_SALES_JOURNAL,
            err
          );
          commit(
            ExportSalesJournalMutations.SET_IS_LOADING_EXPORT_SALES_JOURNAL_LIST,
            false
          );
          reject(err);
        });
    });
  },

  async getExportSalesJournalById(
    { commit },
    {
      exportSalesJournalId,
      updateState = true,
    }: { exportSalesJournalId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      commit(
        ExportSalesJournalMutations.SET_IS_LOADING_EXPORT_SALES_JOURNAL,
        true
      );
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/ExportSalesJournal/GetExportSalesJournalById",
          {
            params: {
              exportSalesJournalId: exportSalesJournalId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(
              ExportSalesJournalMutations.SET_EXPORT_SALES_JOURNAL,
              res.data
            );
            commit(
              ExportSalesJournalMutations.SET_IS_LOADING_EXPORT_SALES_JOURNAL,
              false
            );
          }
          commit(
            ExportSalesJournalMutations.SET_REQUEST_STATE_EXPORT_SALES_JOURNAL,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            ExportSalesJournalMutations.SET_REQUEST_STATE_EXPORT_SALES_JOURNAL,
            err
          );
          commit(
            ExportSalesJournalMutations.SET_IS_LOADING_EXPORT_SALES_JOURNAL,
            false
          );
          reject(err);
        });
    });
  },
};
