import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { FolderRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IFolderState } from "./states";

export enum FolderMutations {
    SET_FOLDERS_LIST = "SET_FOLDERS_LIST",
    SET_FOLDER = "SET_FOLDER",

    // COMMON
    SET_IS_LOADING_FOLDER_LIST = "SET_IS_LOADING_FOLDER_LIST",
    SET_IS_LOADING_FOLDER = "SET_IS_LOADING_FOLDER",
    SET_IS_CREATING_FOLDER = "SET_IS_CREATING_FOLDER",
    SET_IS_UPDATING_FOLDER = "SET_IS_UPDATING_FOLDER",
    SET_IS_DELETING_FOLDER = "SET_IS_DELETING_FOLDER",
    SET_IS_ARCHIVING_FOLDER = "SET_IS_ARCHIVING_FOLDER",
    SET_REQUEST_STATE_FOLDER = "SET_REQUEST_STATE_FOLDER",
}

export const mutations: MutationTree<IFolderState> = {
    [FolderMutations.SET_FOLDERS_LIST]: (state, payload: FolderRequestDto[]) => {
        state.foldersList = payload;
    },
    [FolderMutations.SET_FOLDER]: (state, payload: FolderRequestDto) => {
        state.folder = payload;
    },


    //COMMON
    [FolderMutations.SET_IS_LOADING_FOLDER_LIST]: (state, payload: boolean) => {
        state.isLoadingFoldersList = payload;
    },
    [FolderMutations.SET_IS_LOADING_FOLDER]: (state, payload: boolean) => {
        state.isLoadingFolder = payload;
    },
    [FolderMutations.SET_IS_CREATING_FOLDER]: (state, payload: boolean) => {
        state.isCreatingFolder = payload;
    },
    [FolderMutations.SET_IS_UPDATING_FOLDER]: (state, payload: boolean) => {
        state.isUpdatingFolder = payload;
    },
    [FolderMutations.SET_IS_DELETING_FOLDER]: (state, payload: boolean) => {
        state.isDeletingFolder = payload;
    },
    [FolderMutations.SET_IS_ARCHIVING_FOLDER]: (state, payload: boolean) => {
        state.isArchivingFolder = payload;
    },
    [FolderMutations.SET_REQUEST_STATE_FOLDER]: (state, payload: IRequestState) => {
        state.requestStateFolder = payload;
    },
}