import { IFolderState } from "./states"
import { Module } from "vuex"
import { FolderRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IFolderState = {
    foldersList             : Array<FolderRequestDto>(),
    folder                  : <FolderRequestDto>{},
    isLoadingFoldersList    : false,
    isLoadingFolder         : false,
    isCreatingFolder        : false,
    isUpdatingFolder        : false,
    isDeletingFolder        : false,
    isArchivingFolder       : false,
    requestStateFolder      : <IRequestState>{},
}

export const FolderModule: Module<IFolderState, RootState> = {
    state, 
    getters,
    mutations,
    actions
} 