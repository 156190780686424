import { IUnitState } from "./states";
import { MutationTree } from "vuex";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export enum UnitMutations {
  SET_UNITIES_LIST = "SET_UNITIES_LIST",
  SET_UNITIES_LIST_ARCHIVED = "SET_UNITIES_LIST_ARCHIVED",
  SET_UNIT = "SET_UNIT",

  // COMON
  SET_IS_LOADING_UNITIES_LIST = "SET_IS_LOADING_UNITIES_LIST",
  SET_IS_LOADING_UNITIES_LIST_ARCHIVED = "SET_IS_LOADING_UNITIES_LIST_ARCHIVED",
  SET_IS_LOADING_UNIT = "SET_IS_LOADING_UNIT",
  SET_IS_CREATING_UNIT = "SET_IS_CREATING_UNIT",
  SET_IS_UPDATING_UNIT = "SET_IS_UPDATING_UNIT",
  SET_IS_DELETING_UNIT = "SET_IS_DELETING_UNIT",
  SET_REQUEST_STATE_UNIT = "SET_REQUEST_STATE_UNIT",
  SET_IS_ARCHIVING_UNIT = "SET_IS_ARCHIVING_UNIT",  
}

  export const mutations: MutationTree<IUnitState> = {
    [UnitMutations.SET_UNITIES_LIST]: (state, payload: any[]) => {
        state.unitsList = payload;
    },
    [UnitMutations.SET_UNITIES_LIST_ARCHIVED]: (state, payload: any[]) => {
        state.unitsListArchived = payload;
    },
    [UnitMutations.SET_UNIT]: (state, payload: any) => {
        state.unit = payload;
    },


    //COMMON
    [UnitMutations.SET_IS_LOADING_UNITIES_LIST]: (state, payload: boolean) => {
        state.isLoadingUnitsList = payload;
    },
    [UnitMutations.SET_IS_LOADING_UNITIES_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingUnitsListArchived = payload;
    },
    [UnitMutations.SET_IS_LOADING_UNIT]: (state, payload: boolean) => {
        state.isLoadingUnit = payload;
    },
    [UnitMutations.SET_IS_CREATING_UNIT]: (state, payload: boolean) => {
        state.isCreatingUnit = payload;
    },
    [UnitMutations.SET_IS_UPDATING_UNIT]: (state, payload: boolean) => {
        state.isUpdatingUnit = payload;
    },
    [UnitMutations.SET_IS_DELETING_UNIT]: (state, payload: boolean) => {
        state.isDeletingUnit = payload;
    },
    [UnitMutations.SET_REQUEST_STATE_UNIT]: (state, payload: IRequestState) => {
        state.requestStateUnit = payload;
    },
    [UnitMutations.SET_IS_ARCHIVING_UNIT]: (state, payload: boolean) => {
        state.isArchivingUnit = payload;
    }
}