import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { DeliveryFormRequestDto } from "@/../Api";
import { MutationTree } from "vuex";
import { IDeliveryFormState } from "./states";
import { IPaymentMethod } from "@/types/api-orisis/interfaces/invoices/paymentMethod/IPaymentMethod";
import { IFilters } from "@/types/api-orisis/interfaces/invoices/filters/IFilters";

export enum DeliveryFormMutations {
  SET_DELIVERY_FORMS_LIST = "SET_DELIVERY_FORMS_LIST",
  SET_DELIVERY_FORMS_LIST_ARCHIVED = "SET_DELIVERY_FORMS_LIST_ARCHIVED",
  SET_DELIVERY_FORM = "SET_DELIVERY_FORM",
  SET_DELIVERY_FORM_ARCHIVED = "SET_DELIVERY_FORM_ARCHIVED",
  SET_DELIVERY_FORM_PDF = "SET_DELIVERY_FORM_PDF",
  SET_INITIAL_DELIVERY_FORM = "SET_INITIAL_DELIVERY_FORM",
  SET_DELIVERY_FORM_LIST_BY_AFFAIR = "SET_DELIVERY_FORM_LIST_BY_AFFAIR",
  SET_DELIVERY_FORM_LIST_BY_COMPANY = "SET_DELIVERY_FORM_LIST_BY_COMPANY",

  // COMMON
  SET_IS_VALIDATING_DELIVERY_FORM = "SET_IS_VALIDATING_DELIVERY_FORM",
  SET_IS_LOADING_DELIVERY_FORM_LIST = "SET_IS_LOADING_DELIVERY_FORM_LIST",
  SET_IS_LOADING_DELIVERY_FORM_LIST_ARCHIVED = "SET_IS_LOADING_DELIVERY_FORM_LIST_ARCHIVED",
  SET_IS_LOADING_DELIVERY_FORM = "SET_IS_LOADING_DELIVERY_FORM",
  // SET_DELIVERY_FORM_PAYMENT_METHODS = "SET_DELIVERY_FORM_PAYMENT_METHODS",
  SET_DELIVERY_FORM_FILTERS = "SET_DELIVERY_FORM_FILTERS",
  SET_IS_LOADING_DELIVERY_FORM_PDF = "SET_IS_LOADING_DELIVERY_FORM_PDF",
  SET_LAST_DELIVERY_FORM_REFERENCE = "SET_LAST_DELIVERY_FORM_REFERENCE",
  SET_IS_LOADING_DELIVERY_FORM_ARCHIVED = "SET_IS_LOADING_DELIVERY_FORM_ARCHIVED",
  SET_IS_LOADING_PAYMENT_METHODS_DELIVERY_FORM = "SET_IS_LOADING_PAYMENT_METHODS_DELIVERY_FORM",
  SET_IS_CREATING_DELIVERY_FORM = "SET_IS_CREATING_DELIVERY_FORM",
  SET_IS_UPDATING_DELIVERY_FORM = "SET_IS_UPDATING_DELIVERY_FORM",
  SET_IS_DELETING_DELIVERY_FORM = "SET_IS_DELETING_DELIVERY_FORM",
  SET_IS_LOADING_DUPLICATING_DELIVERY_FORM = "SET_IS_LOADING_DUPLICATING_DELIVERY_FORM",
  SET_IS_RESTORING_DELIVERY_FORM = "SET_IS_RESTORING_DELIVERY_FORM",
  SET_IS_ARCHIVING_DELIVERY_FORM = "SET_IS_ARCHIVING_DELIVERY_FORM",
  SET_REQUEST_STATE_DELIVERY_FORM = "SET_REQUEST_STATE_DELIVERY_FORM",
  SET_REQUEST_STATE_DELIVERY_FORM_PDF = "SET_REQUEST_STATE_DELIVERY_FORM_PDF",
  SET_DELIVERY_FORM_TAB_ACTIVE = "SET_DELIVERY_FORM_TAB_ACTIVE",
  SET_IS_LOADING_DELIVERY_FORMS_LIST_BY_AFFAIR = "SET_IS_LOADING_DELIVERY_FORMS_LIST_BY_AFFAIR",
  SET_IS_LOADING_DELIVERY_FORMS_LIST_BY_COMPANY = "SET_IS_LOADING_DELIVERY_FORMS_LIST_BY_COMPANY",
}

export const mutations: MutationTree<IDeliveryFormState> = {
  [DeliveryFormMutations.SET_DELIVERY_FORMS_LIST_ARCHIVED]: (
    state,
    payload: DeliveryFormRequestDto[]
  ) => {
    state.deliveryFormsListArchived = payload;
  },
  [DeliveryFormMutations.SET_DELIVERY_FORMS_LIST]: (
    state,
    payload: DeliveryFormRequestDto[]
  ) => {
    state.deliveryFormsList = payload;
  },
  [DeliveryFormMutations.SET_DELIVERY_FORM]: (
    state,
    payload: DeliveryFormRequestDto
  ) => {
    state.deliveryForm = payload;
  },
  [DeliveryFormMutations.SET_DELIVERY_FORM_ARCHIVED]: (
    state,
    payload: DeliveryFormRequestDto
  ) => {
    state.deliveryFormArchived = payload;
  },
  [DeliveryFormMutations.SET_DELIVERY_FORM_PDF]: (state, payload: string) => {
    state.deliveryFormPDF = payload+'?r='+(new Date()).getTime();
  },
  [DeliveryFormMutations.SET_DELIVERY_FORM_LIST_BY_AFFAIR]: (
    state,
    payload: DeliveryFormRequestDto[]
  ) => {
    state.deliveryFormsListByAffair = payload;
  },
  [DeliveryFormMutations.SET_DELIVERY_FORM_LIST_BY_COMPANY]: (
    state,
    payload: DeliveryFormRequestDto[]
  ) => {
    state.deliveryFormsListByCompany = payload;
  },

  //COMMON
  [DeliveryFormMutations.SET_IS_VALIDATING_DELIVERY_FORM]: (state, payload: boolean) => {
    state.isValidatingDeliveryForm = payload;
  },
  [DeliveryFormMutations.SET_IS_LOADING_DELIVERY_FORM_LIST_ARCHIVED]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingDeliveryFormsListArchived = payload;
  },
  [DeliveryFormMutations.SET_LAST_DELIVERY_FORM_REFERENCE]: (
    state,
    payload: string
  ) => {
    state.deliveryForm.documentReference = payload;
  },
  [DeliveryFormMutations.SET_IS_LOADING_DELIVERY_FORM_LIST]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingDeliveryFormsList = payload;
  },
  [DeliveryFormMutations.SET_IS_LOADING_DELIVERY_FORM]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingDeliveryForm = payload;
  },
  [DeliveryFormMutations.SET_IS_LOADING_PAYMENT_METHODS_DELIVERY_FORM]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingPaymentMethodsDeliveryForm = payload;
  },
  [DeliveryFormMutations.SET_IS_LOADING_DELIVERY_FORM_PDF]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingDeliveryFormPDF = payload;
  },
  [DeliveryFormMutations.SET_IS_LOADING_DUPLICATING_DELIVERY_FORM]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingDuplicatingDeliveryForm = payload;
  },
  [DeliveryFormMutations.SET_IS_LOADING_DELIVERY_FORM_ARCHIVED]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingDeliveryFormArchived = payload;
  },
  [DeliveryFormMutations.SET_INITIAL_DELIVERY_FORM]: (
    state,
    payload: DeliveryFormRequestDto
  ) => {
    state.initialDeliveryForm = payload;
  },
  // [DeliveryFormMutations.SET_DELIVERY_FORM_PAYMENT_METHODS]: (
  //   state,
  //   payload: IPaymentMethod[]
  // ) => {
  //   state.deliveryFormPaymentMethods = payload;
  // },
  [DeliveryFormMutations.SET_IS_CREATING_DELIVERY_FORM]: (
    state,
    payload: boolean
  ) => {
    state.isCreatingDeliveryForm = payload;
  },
  [DeliveryFormMutations.SET_IS_UPDATING_DELIVERY_FORM]: (
    state,
    payload: boolean
  ) => {
    state.isUpdatingDeliveryForm = payload;
  },
  [DeliveryFormMutations.SET_IS_ARCHIVING_DELIVERY_FORM]: (
    state,
    payload: boolean
  ) => {
    state.isArchivingDeliveryForm = payload;
  },
  [DeliveryFormMutations.SET_IS_RESTORING_DELIVERY_FORM]: (
    state,
    payload: boolean
  ) => {
    state.isRestoringDeliveryForm = payload;
  },
  [DeliveryFormMutations.SET_IS_DELETING_DELIVERY_FORM]: (
    state,
    payload: boolean
  ) => {
    state.isDeletingDeliveryForm = payload;
  },
  [DeliveryFormMutations.SET_REQUEST_STATE_DELIVERY_FORM]: (
    state,
    payload: IRequestState
  ) => {
    state.requestStateDeliveryForm = payload;
  },
  [DeliveryFormMutations.SET_REQUEST_STATE_DELIVERY_FORM_PDF]: (
    state,
    payload: IRequestState
  ) => {
    state.requestStateDeliveryFormPDF = payload;
  },
  [DeliveryFormMutations.SET_DELIVERY_FORM_TAB_ACTIVE]: (state, payload: any) => {
    state.deliveryFormTabActive = payload;
  },
  [DeliveryFormMutations.SET_DELIVERY_FORM_FILTERS]: (
    state,
    payload: IFilters
  ) => {
    state.deliveryFormFilters = payload;
  },
  [DeliveryFormMutations.SET_IS_LOADING_DELIVERY_FORMS_LIST_BY_AFFAIR]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingDeliveryFormsListByAffair = payload;
  },
  [DeliveryFormMutations.SET_IS_LOADING_DELIVERY_FORMS_LIST_BY_COMPANY]: (
    state,
    payload: boolean
  ) => {
    
    state.isLoadingDeliveryFormsListByCompany = payload;
  }
};
