import { IReceiptFormOptionState } from "./states";
import { Module } from "vuex";
import { ReceiptFormOptionRequestDto } from "@/../Api";
import RootState from "@/store/modules/rootState";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

const state: IReceiptFormOptionState = {
  receiptFormOption: <ReceiptFormOptionRequestDto>{},
  isLoadingReceiptFormOption: false,
  requestStateReceiptFormOption: <IRequestState>{},
};

export const ReceiptFormOptionModule: Module<
  IReceiptFormOptionState,
  RootState
> = {
  state,
  getters,
  mutations,
  actions,
};
