import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { DeliveryFormOptionDefaultMutations } from "./mutations";
import { IDeliveryFormOptionDefaultState } from "./states";
import { DeliveryFormOptionRequestDto } from "Api";

export const actions: ActionTree<IDeliveryFormOptionDefaultState, RootState> = {
  getDeliveryFormOptionDefaultByDeliveryFormId(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          DeliveryFormOptionDefaultMutations.SET_IS_LOADING_DELIVERY_FORM_OPTION_DEFAULT,
          true
        );
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/DeliveryFormOptionDefault/GetDefault`;

      axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              DeliveryFormOptionDefaultMutations.SET_DELIVERY_FORM_OPTION_DEFAULT,
              res.data
            );
            commit(
              DeliveryFormOptionDefaultMutations.SET_DELIVERY_FORM_OPTION_DEFAULT_FORM,
              res.data
            );
            commit(
              DeliveryFormOptionDefaultMutations.SET_IS_LOADING_DELIVERY_FORM_OPTION_DEFAULT,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              DeliveryFormOptionDefaultMutations.SET_IS_LOADING_DELIVERY_FORM_OPTION_DEFAULT,
              false
            );
          }
          reject(err);
        });
    });
  },

  createDeliveryFormOptionDefault(
    { commit },
    {
      deliveryFormOptionDefault,
      updateState = true,
    }: {
      deliveryFormOptionDefault: DeliveryFormOptionRequestDto;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          DeliveryFormOptionDefaultMutations.SET_IS_LOADING_DELIVERY_FORM_OPTION_DEFAULT,
          true
        );
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/DeliveryFormOptionDefault/CreateDeliveryFormOptionDefault`;

      axios
        .post(url, deliveryFormOptionDefault)
        .then((res) => {
          if (updateState) {
            commit(
              DeliveryFormOptionDefaultMutations.SET_DELIVERY_FORM_OPTION_DEFAULT,
              res.data
            );
            commit(
              DeliveryFormOptionDefaultMutations.SET_IS_LOADING_DELIVERY_FORM_OPTION_DEFAULT,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              DeliveryFormOptionDefaultMutations.SET_IS_LOADING_DELIVERY_FORM_OPTION_DEFAULT,
              false
            );
          }
          reject(err);
        });
    });
  },

  updateDeliveryFormOptionDefault(
    { commit },
    {
      deliveryFormOptionDefault,
      updateState = true,
    }: {
      deliveryFormOptionDefault: DeliveryFormOptionRequestDto;
      updateState: boolean;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          DeliveryFormOptionDefaultMutations.SET_IS_LOADING_DELIVERY_FORM_OPTION_DEFAULT,
          true
        );
      }

      const url = `${process.env.VUE_APP_API_ORISIS}Sale/DeliveryFormOptionDefault/UpdateDeliveryFormOptionDefault`;

      axios
        .put(url, deliveryFormOptionDefault)
        .then((res) => {
          if (updateState) {
            commit(
              DeliveryFormOptionDefaultMutations.SET_DELIVERY_FORM_OPTION_DEFAULT,
              res.data
            );
            commit(
              DeliveryFormOptionDefaultMutations.SET_IS_LOADING_DELIVERY_FORM_OPTION_DEFAULT,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              DeliveryFormOptionDefaultMutations.SET_IS_LOADING_DELIVERY_FORM_OPTION_DEFAULT,
              false
            );
          }
          reject(err);
        });
    });
  },
};
