import { InstitutionSettingRequestDto } from "Api";
import { MutationTree } from "vuex";
import { IInstitutionSettingsStates } from "./states";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export enum InstitutionSettingsMutations {
  SET_IS_OPEN_DOCUMENT_SETTINGS_PANEL = "SET_IS_OPEN_DOCUMENT_SETTINGS_PANEL",
  SET_INSTITUTION_SETTINGS = "SET_INSTITUTION_SETTINGS",
  SET_INSTITUTION_SETTINGS_ACTIVE = "SET_INSTITUTION_SETTINGS_ACTIVE",
  SET_TAB_SELECTED = "SET_TAB_SELECTED",
  SET_IS_LOADING_INSTITUTION_SETTINGS = "SET_IS_LOADING_INSTITUTION_SETTINGS",
  SET_IS_CREATING_INSTITUTION_SETTINGS = "SET_IS_CREATING_INSTITUTION_SETTINGS",
  SET_IS_UPDATING_INSTITUTION_SETTINGS = "SET_IS_UPDATING_INSTITUTION_SETTINGS",
  SET_REQUEST_STATE_INSTITUTION_SETTINGS = "SET_REQUEST_STATE_INSTITUTION_SETTINGS",

  SET_PAYMENT_METHODS_DEFAULTS = "SET_PAYMENT_METHODS_DEFAULTS",
  SET_QUOTE_DOWN_PAYMENTS_REQUEST_DEFAULTS = "SET_QUOTE_DOWN_PAYMENTS_REQUEST_DEFAULTS",
  SET_REFERENCIEL_TVAS_INSTITUTION_SETTINGS_LIST = "SET_REFERENCIEL_TVAS_INSTITUTION_SETTINGS_LIST",
}

export const mutations: MutationTree<IInstitutionSettingsStates> = {
  [InstitutionSettingsMutations.SET_IS_OPEN_DOCUMENT_SETTINGS_PANEL]: (
    state,
    payload: boolean
  ) => {
    state.isOpendocumentSettingsPanel = payload;
  },
  [InstitutionSettingsMutations.SET_INSTITUTION_SETTINGS]: (
    state,
    payload: InstitutionSettingRequestDto
  ) => {
    state.institutionSettings = payload;
  },
  [InstitutionSettingsMutations.SET_INSTITUTION_SETTINGS_ACTIVE]: (
    state,
    payload: InstitutionSettingRequestDto
  ) => {
    localStorage.setItem("institutionSettingsActive", JSON.stringify(payload));
    state.institutionSettingsActive = payload;
  },
  [InstitutionSettingsMutations.SET_REFERENCIEL_TVAS_INSTITUTION_SETTINGS_LIST]:
    (state, payload: any) => {
      state.referencielTvasInstitutionSettingsList = payload;
    },

  [InstitutionSettingsMutations.SET_TAB_SELECTED]: (state, payload: string) => {
    state.tabSelected = payload;
  },
  [InstitutionSettingsMutations.SET_IS_LOADING_INSTITUTION_SETTINGS]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingInstitutionSettings = payload;
  },
  [InstitutionSettingsMutations.SET_IS_CREATING_INSTITUTION_SETTINGS]: (
    state,
    payload: boolean
  ) => {
    state.isCreatingInstitutionSettings = payload;
  },
  [InstitutionSettingsMutations.SET_IS_UPDATING_INSTITUTION_SETTINGS]: (
    state,
    payload: boolean
  ) => {
    state.isUpdatingInstitutionSettings = payload;
  },
  [InstitutionSettingsMutations.SET_REQUEST_STATE_INSTITUTION_SETTINGS]: (
    state,
    payload: IRequestState
  ) => {
    state.requestStateInstitutionSettings = payload;
  },
};
