import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { AffairCostingRequestDto } from "@/../Api";
import { MutationTree } from "vuex";
import { IAffairCostingState } from "./states";
var dayjs = require("dayjs");

export enum AffairCostingMutations {
  SET_AFFAIR_COSTINGS_LIST = "SET_AFFAIR_COSTINGS_LIST",
  SET_AFFAIR_COSTINGS_LIST_ARCHIVED = "SET_AFFAIR_COSTINGS_LIST_ARCHIVED",
  SET_AFFAIR_COSTINGS_LIST_BY_AFFAIR = "SET_AFFAIR_COSTINGS_LIST_BY_AFFAIR",
  SET_AFFAIR_COSTINGS_LIST_BY_AFFAIR_ARCHIVED = "SET_AFFAIR_COSTINGS_LIST_BY_AFFAIR_ARCHIVED",
  SET_AFFAIR_COSTING = "SET_AFFAIR_COSTING",

  //FILTERS
  SET_AFFAIR_COSTING_FILTERS = "SET_AFFAIR_COSTING_FILTERS",

  // COMMON
  SET_IS_LOADING_AFFAIR_COSTING_LIST = "SET_IS_LOADING_AFFAIR_COSTING_LIST",
  SET_IS_LOADING_AFFAIR_COSTING_LIST_ARCHIVED = "SET_IS_LOADING_AFFAIR_COSTING_LIST_ARCHIVED",
  SET_IS_LOADING_AFFAIR_COSTING_LIST_BY_AFFAIR = "SET_IS_LOADING_AFFAIR_COSTING_LIST_BY_AFFAIR",
  SET_IS_LOADING_AFFAIR_COSTING_LIST_BY_AFFAIR_ARCHIVED = "SET_IS_LOADING_AFFAIR_COSTING_LIST_BY_AFFAIR_ARCHIVED",
  SET_IS_LOADING_AFFAIR_COSTING = "SET_IS_LOADING_AFFAIR_COSTING",
  SET_IS_CREATING_AFFAIR_COSTING = "SET_IS_CREATING_AFFAIR_COSTING",
  SET_IS_UPDATING_AFFAIR_COSTING = "SET_IS_UPDATING_AFFAIR_COSTING",
  SET_IS_DELETING_AFFAIR_COSTING = "SET_IS_DELETING_AFFAIR_COSTING",
  SET_IS_ARCHIVING_AFFAIR_COSTING = "SET_IS_ARCHIVING_AFFAIR_COSTING",
  SET_REQUEST_STATE_AFFAIR_COSTING = "SET_REQUEST_STATE_AFFAIR_COSTING",
}

export const mutations: MutationTree<IAffairCostingState> = {
  [AffairCostingMutations.SET_AFFAIR_COSTINGS_LIST]: (
    state,
    payload: AffairCostingRequestDto[]
  ) => {
    state.affairCostingsList = payload.map((el: any) => {
      return {
        ...el,
        date: new Date(dayjs(el.date).startOf("month").format("YYYY-MM-DD")),
        collaboratorId: el.collaboratorId
          ? el.collaboratorId
          : el.collaborator
          ? el.collaborator.id
          : null,
      };
    });
  },
  [AffairCostingMutations.SET_AFFAIR_COSTINGS_LIST_ARCHIVED]: (
    state,
    payload: AffairCostingRequestDto[]
  ) => {
    state.affairCostingsListArchived = payload.map((el: any) => {
      return {
        ...el,
        date: new Date(dayjs(el.date).startOf("month").format("YYYY-MM-DD")),
        collaboratorId: el.collaboratorId
          ? el.collaboratorId
          : el.collaborator
          ? el.collaborator.id
          : null,
      };
    });
  },
  [AffairCostingMutations.SET_AFFAIR_COSTINGS_LIST_BY_AFFAIR]: (
    state,
    payload: AffairCostingRequestDto[]
  ) => {
    state.affairCostingsListByAffair = payload.map((el: any) => {
      return {
        ...el,
        date: new Date(dayjs(el.date).startOf("month").format("YYYY-MM-DD")),
        collaboratorId: el.collaboratorId
          ? el.collaboratorId
          : el.collaborator
          ? el.collaborator.id
          : null,
      };
    });
  },
  [AffairCostingMutations.SET_AFFAIR_COSTINGS_LIST_BY_AFFAIR_ARCHIVED]: (
    state,
    payload: AffairCostingRequestDto[]
  ) => {
    state.affairCostingsListByAffairArchived = payload.map((el: any) => {
      return {
        ...el,
        date: new Date(dayjs(el.date).startOf("month").format("YYYY-MM-DD")),
        collaboratorId: el.collaboratorId
          ? el.collaboratorId
          : el.collaborator
          ? el.collaborator.id
          : null,
      };
    });
  },
  [AffairCostingMutations.SET_AFFAIR_COSTING]: (
    state,
    payload: AffairCostingRequestDto
  ) => {
    state.affairCosting = payload;
  },

  //FILTERS
  [AffairCostingMutations.SET_AFFAIR_COSTING_FILTERS]: (
    state,
    payload: any
  ) => {
    state.affairCostingFilters = payload;
  },

  //COMMON
  [AffairCostingMutations.SET_IS_LOADING_AFFAIR_COSTING_LIST_ARCHIVED]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingAffairCostingsListArchived = payload;
  },
  [AffairCostingMutations.SET_IS_LOADING_AFFAIR_COSTING_LIST_BY_AFFAIR]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingAffairCostingsListByAffair = payload;
  },
  [AffairCostingMutations.SET_IS_LOADING_AFFAIR_COSTING_LIST_BY_AFFAIR_ARCHIVED]:
    (state, payload: boolean) => {
      state.isLoadingAffairCostingsListByAffairArchived = payload;
    },
  [AffairCostingMutations.SET_IS_LOADING_AFFAIR_COSTING_LIST]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingAffairCostingsList = payload;
  },
  [AffairCostingMutations.SET_IS_LOADING_AFFAIR_COSTING]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingAffairCosting = payload;
  },
  [AffairCostingMutations.SET_IS_CREATING_AFFAIR_COSTING]: (
    state,
    payload: boolean
  ) => {
    state.isCreatingAffairCosting = payload;
  },
  [AffairCostingMutations.SET_IS_UPDATING_AFFAIR_COSTING]: (
    state,
    payload: boolean
  ) => {
    state.isUpdatingAffairCosting = payload;
  },
  [AffairCostingMutations.SET_IS_DELETING_AFFAIR_COSTING]: (
    state,
    payload: boolean
  ) => {
    state.isDeletingAffairCosting = payload;
  },
  [AffairCostingMutations.SET_IS_ARCHIVING_AFFAIR_COSTING]: (
    state,
    payload: boolean
  ) => {
    state.isArchivingAffairCosting = payload;
  },
  [AffairCostingMutations.SET_REQUEST_STATE_AFFAIR_COSTING]: (
    state,
    payload: IRequestState
  ) => {
    state.requestStateAffairCosting = payload;
  },
};
