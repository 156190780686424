import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { BankAccountRequestDto } from "Api";
import RootState from "@/store/modules/rootState"
import { IBankAccountState } from "./states";
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

export const state: IBankAccountState = {
    bankAccountsList                  : Array<BankAccountRequestDto>(),
    bankAccountsListForm                  : Array<BankAccountRequestDto>(),
    bankAccountsListArchived          : Array<BankAccountRequestDto>(),
    bankAccount                       : <BankAccountRequestDto>{},
    isLoadingBankAccountsList         : false,
    isLoadingBankAccountsListArchived : false,
    isLoadingBankAccount              : false,
    isCreatingBankAccount             : false,
    isUpdatingBankAccount             : false,
    isDeletingBankAccount             : false,
    requestStateBankAccount           : <IRequestState>{},
    isArchivingBankAccount            : false,
}

export const BankAccountModule: Module<IBankAccountState, RootState> = {
  state, 
  getters,
  mutations,
  actions
}