import { AnalyticsSettingRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { AnalyticsSettingMutations } from "./mutations";
import { IAnalyticsSettingState } from "./states";

export const actions: ActionTree<IAnalyticsSettingState, RootState> = {
  getAnalyticsSettingById(
    { commit },
    {
      analyticsSettingId,
      updateState = true,
    }: { analyticsSettingId: string; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          AnalyticsSettingMutations.SET_IS_LOADING_ANALYTICS_SETTING,
          true
        );
      }
      axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Reporting/AnalyticsSetting/GetAnalyticsSettingById`,
          {
            params: {
              analyticsSettingId: analyticsSettingId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(
              AnalyticsSettingMutations.SET_REQUEST_STATE_ANALYTICS_SETTING,
              res
            );
            commit(AnalyticsSettingMutations.SET_ANALYTICS_SETTING, res.data);
            commit(
              AnalyticsSettingMutations.SET_IS_LOADING_ANALYTICS_SETTING,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            AnalyticsSettingMutations.SET_REQUEST_STATE_ANALYTICS_SETTING,
            err
          );
          if (updateState) {
            commit(
              AnalyticsSettingMutations.SET_IS_LOADING_ANALYTICS_SETTING,
              false
            );
          }
          reject(err);
        });
    });
  },

  getAnalyticsSettings(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          AnalyticsSettingMutations.SET_IS_LOADING_ANALYTICS_SETTING_LIST,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Reporting/AnalyticsSetting/GetAnalyticsSettings`;
      axios
        .get(url)
        .then((res) => {
          let analyticsSettings = res.data.map((elem: any) => {
            return {
              ...elem,
              parentAnalyticsSettingId: elem.parentAnalyticsSetting
                ? elem.parentAnalyticsSetting.id
                : null,
            };
          });
          if (updateState) {
            commit(
              AnalyticsSettingMutations.SET_IS_LOADING_ANALYTICS_SETTING_LIST,
              false
            );
            commit(
              AnalyticsSettingMutations.SET_ANALYTICS_SETTINGS_LIST,
              analyticsSettings
            );
          }
          commit(
            AnalyticsSettingMutations.SET_REQUEST_STATE_ANALYTICS_SETTING,
            res
          );
          resolve(analyticsSettings);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              AnalyticsSettingMutations.SET_IS_LOADING_ANALYTICS_SETTING_LIST,
              false
            );
          }
          commit(
            AnalyticsSettingMutations.SET_REQUEST_STATE_ANALYTICS_SETTING,
            err
          );
          reject(err);
        });
    });
  },

  //GetAnalyticsSettingArchivedById

  //GetAnalyticsSettingsArchived

  getAnalyticsSettingsTemplates(
    { commit, rootGetters },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          AnalyticsSettingMutations.SET_IS_LOADING_ANALYTICS_SETTING_LIST,
          true
        );
        commit(
          AnalyticsSettingMutations.SET_ANALYTICS_SETTINGS_LIST_TEMPLATES,
          []
        );
      }

      let url = `${process.env.VUE_APP_API_ORISIS}Reporting/AnalyticsSetting/GetAnalyticsSettingsTemplates`;
      axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              AnalyticsSettingMutations.SET_IS_LOADING_ANALYTICS_SETTING_LIST_TEMPLATES,
              false
            );
            commit(
              AnalyticsSettingMutations.SET_ANALYTICS_SETTINGS_LIST_TEMPLATES,
              res.data
            );
          }
          commit(
            AnalyticsSettingMutations.SET_REQUEST_STATE_ANALYTICS_SETTING,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          if (updateState) {
            commit(
              AnalyticsSettingMutations.SET_IS_LOADING_ANALYTICS_SETTING_LIST,
              false
            );
          }
          commit(
            AnalyticsSettingMutations.SET_REQUEST_STATE_ANALYTICS_SETTING,
            err
          );
          reject(err);
        });
    });
  },

  createAnalyticsSetting(
    { commit, rootGetters },
    { analyticsSetting }: { analyticsSetting: AnalyticsSettingRequestDto }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(AnalyticsSettingMutations.SET_IS_CREATING_ANALYTICS_SETTING, true);
      axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Reporting/AnalyticsSetting/CreateAnalyticsSettings`,
          {
            ...analyticsSetting,
            parentAnalyticsSettingId: analyticsSetting.parentAnalyticsSettingId
              ? analyticsSetting.parentAnalyticsSettingId
              : null,
          }
        )
        .then((res) => {
          rootGetters.analyticsSettingsList.push(res.data.data);
          commit(
            AnalyticsSettingMutations.SET_ANALYTICS_SETTINGS_LIST,
            rootGetters.analyticsSettingsList
          );
          commit(
            AnalyticsSettingMutations.SET_REQUEST_STATE_ANALYTICS_SETTING,
            res
          );
          commit(
            AnalyticsSettingMutations.SET_IS_CREATING_ANALYTICS_SETTING,
            false
          );
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            AnalyticsSettingMutations.SET_REQUEST_STATE_ANALYTICS_SETTING,
            err
          );
          commit(
            AnalyticsSettingMutations.SET_IS_CREATING_ANALYTICS_SETTING,
            false
          );
          reject(err);
        });
    });
  },

  updateAnalyticsSetting(
    { commit, rootGetters },
    { analyticsSetting }: { analyticsSetting: AnalyticsSettingRequestDto }
  ) {
    return new Promise<void>((resolve, reject) => {
      commit(AnalyticsSettingMutations.SET_IS_UPDATING_ANALYTICS_SETTING, true);
      axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Reporting/AnalyticsSetting/UpdateAnalyticsSetting`,
          {
            ...analyticsSetting,
            parentAnalyticsSettingId: analyticsSetting.parentAnalyticsSettingId
              ? analyticsSetting.parentAnalyticsSettingId
              : null,
          }
        )
        .then((res) => {
          rootGetters.analyticsSettingsList.splice(
            rootGetters.analyticsSettingsList.findIndex(
              (elem) => elem.id == analyticsSetting.id
            ),
            1,
            res.data.data
          );
          commit(
            AnalyticsSettingMutations.SET_ANALYTICS_SETTINGS_LIST,
            rootGetters.analyticsSettingsList
          );
          commit(
            AnalyticsSettingMutations.SET_REQUEST_STATE_ANALYTICS_SETTING,
            res
          );
          commit(
            AnalyticsSettingMutations.SET_IS_UPDATING_ANALYTICS_SETTING,
            false
          );
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            AnalyticsSettingMutations.SET_REQUEST_STATE_ANALYTICS_SETTING,
            err
          );
          commit(
            AnalyticsSettingMutations.SET_IS_UPDATING_ANALYTICS_SETTING,
            false
          );
          reject(err);
        });
    });
  },

  //Restore

  archiveAnalyticsSettings(
    { commit, rootGetters },
    { analyticsSettingIds = [] }: { analyticsSettingIds: number[] }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(
        AnalyticsSettingMutations.SET_IS_ARCHIVING_ANALYTICS_SETTING,
        true
      );
      axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Reporting/AnalyticsSetting/Archive",
          {
            data: analyticsSettingIds,
          }
        )
        .then((res) => {
          rootGetters.analyticsSettingsListArchived.unshift(
            rootGetters.analyticsSettingsList.find(
              (elem) => elem.id == analyticsSettingIds[0]
            )
          );
          rootGetters.analyticsSettingsList.splice(
            rootGetters.analyticsSettingsList.findIndex(
              (elem) => elem.id == analyticsSettingIds[0]
            ),
            1
          );
          commit(
            AnalyticsSettingMutations.SET_ANALYTICS_SETTINGS_LIST,
            rootGetters.analyticsSettingsList
          );
          commit(
            AnalyticsSettingMutations.SET_REQUEST_STATE_ANALYTICS_SETTING,
            res
          );
          commit(
            AnalyticsSettingMutations.SET_IS_ARCHIVING_ANALYTICS_SETTING,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            AnalyticsSettingMutations.SET_REQUEST_STATE_ANALYTICS_SETTING,
            err
          );
          commit(
            AnalyticsSettingMutations.SET_IS_ARCHIVING_ANALYTICS_SETTING,
            false
          );
          reject(err);
        });
    });
  },

  deleteAnalyticsSettings(
    { commit, rootGetters },
    { analyticsSettingIds = [] }: { analyticsSettingIds: number[] }
  ) {
    return new Promise<void>((resolve, reject) => {
      commit(AnalyticsSettingMutations.SET_IS_DELETING_ANALYTICS_SETTING, true);
      axios
        .delete(
          `${process.env.VUE_APP_API_ORISIS}Reporting/AnalyticsSetting/Delete`,
          {
            data: analyticsSettingIds,
          }
        )
        .then((res) => {
          if (
            rootGetters.analyticsSettingsList.find(
              (elem) => elem.id == analyticsSettingIds[0]
            )
          ) {
            rootGetters.analyticsSettingsList.splice(
              rootGetters.analyticsSettingsList.findIndex(
                (elem) => elem.id == analyticsSettingIds[0]
              ),
              1
            );
            commit(
              AnalyticsSettingMutations.SET_ANALYTICS_SETTINGS_LIST,
              rootGetters.analyticsSettingsList
            );
          }
          commit(
            AnalyticsSettingMutations.SET_REQUEST_STATE_ANALYTICS_SETTING,
            res
          );
          commit(
            AnalyticsSettingMutations.SET_IS_DELETING_ANALYTICS_SETTING,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            AnalyticsSettingMutations.SET_REQUEST_STATE_ANALYTICS_SETTING,
            err
          );
          commit(
            AnalyticsSettingMutations.SET_IS_DELETING_ANALYTICS_SETTING,
            false
          );
          reject(err);
        });
    });
  },
};
