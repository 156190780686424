import { IUserState } from "./states";
import { MutationTree } from "vuex";
import { UserRequestDto } from "@/../Api";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export enum UserMutations {
  SET_USERS_LIST = "SET_USERS_LIST",
  SET_USERS_LIST_ARCHIVED = "SET_USERS_LIST_ARCHIVED",
  SET_USER = "SET_USER",
  SET_CONNECTED_USER = "SET_CONNECTED_USER",

  // COMON
  SET_IS_LOADING_USERS_LIST = "SET_IS_LOADING_USERS_LIST",
  SET_IS_LOADING_USERS_LIST_ARCHIVED = "SET_IS_LOADING_USERS_LIST_ARCHIVED",
  SET_IS_LOADING_USER = "SET_IS_LOADING_USER",
  SET_IS_CREATING_USER = "SET_IS_CREATING_USER",
  SET_IS_UPDATING_USER = "SET_IS_UPDATING_USER",
  SET_IS_DELETING_USER = "SET_IS_DELETING_USER",
  SET_IS_ARCHIVING_USER = "SET_IS_ARCHIVING_USER",
  SET_REQUEST_STATE_USER = "SET_REQUEST_STATE_USER",
}

export const mutations: MutationTree<IUserState> = {
  [UserMutations.SET_USERS_LIST_ARCHIVED]: (
    state,
    payload: UserRequestDto[]
  ) => {
    state.usersListArchived = payload;
  },
  [UserMutations.SET_USERS_LIST]: (state, payload: UserRequestDto[]) => {
    state.usersList = payload;
  },
  [UserMutations.SET_USER]: (state, payload: UserRequestDto) => {
    state.user = payload;
  },
  [UserMutations.SET_CONNECTED_USER]: (state, payload: UserRequestDto) => {
    state.connectedUser = payload;
    localStorage.setItem("userInfo", JSON.stringify(payload));
    window.dispatchEvent(new CustomEvent('user-info-changed', {
      detail: {
        storage: localStorage.getItem('userInfo')
      }
    }));
  },

  //COMMON
  [UserMutations.SET_IS_LOADING_USERS_LIST_ARCHIVED]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingUsersListArchived = payload;
  },
  [UserMutations.SET_IS_LOADING_USERS_LIST]: (state, payload: boolean) => {
    state.isLoadingUsersList = payload;
  },
  [UserMutations.SET_IS_LOADING_USER]: (state, payload: boolean) => {
    state.isLoadingUser = payload;
  },
  [UserMutations.SET_IS_CREATING_USER]: (state, payload: boolean) => {
    state.isCreatingUser = payload;
  },
  [UserMutations.SET_IS_UPDATING_USER]: (state, payload: boolean) => {
    state.isUpdatingUser = payload;
  },
  [UserMutations.SET_IS_DELETING_USER]: (state, payload: boolean) => {
    state.isDeletingUser = payload;
  },
  [UserMutations.SET_IS_ARCHIVING_USER]: (state, payload: boolean) => {
    state.isArchivingUser = payload;
  },
  [UserMutations.SET_REQUEST_STATE_USER]: (state, payload: IRequestState) => {
    state.requestStateUser = payload;
  },
};