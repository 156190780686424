import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { FileRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { IFileState } from './states'


export const getters: GetterTree<IFileState, RootState> = {
  filesList                    : (state): FileRequestDto[] => state.filesList,
  filesListArchived            : (state): FileRequestDto[] => state.filesListArchived,
  file                         : (state): FileRequestDto => state.file,

  // COMMON
  isLoadingFilesList           : (state): boolean => state.isLoadingFilesList,
  isLoadingFilesListArchived   : (state): boolean => state.isLoadingFilesListArchived,
  isLoadingFile                : (state): boolean => state.isLoadingFile,
  isCreatingFile               : (state): boolean => state.isCreatingFile,
  isUpdatingFile               : (state): boolean => state.isUpdatingFile,
  isDeletingFile               : (state): boolean => state.isDeletingFile,
  isArchivingFile              : (state): boolean => state.isArchivingFile,
  requestStateFile             : (state): IRequestState => state.requestStateFile,
}



