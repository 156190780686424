import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import RootState from "@/store/modules/rootState";
import { ICompanyTypeState } from "./states";
import { Module } from "vuex";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

export const state: ICompanyTypeState = {
  companiesTypesList: Array<any>(),
  companyType: <any>{},
  isLoadingCompanyTypeList: false,
  isLoadingCompanyType: false,
  isCreatingCompanyType: false,
  isUpdatingCompanyType: false,
  isDeletingCompanyType: false,
  requestStateCompanyType: <IRequestState>{},
};

export const CompaniesTypesModule: Module<ICompanyTypeState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
