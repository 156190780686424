import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { InvoiceStatisticsMutations } from './mutations'
import { IInvoiceStatisticsState } from './states'

export const actions: ActionTree<IInvoiceStatisticsState, RootState> = {

  async fetchStatsCustomerDistribution ({ commit, rootGetters }, {startDate, endDate, institutionId = null}): Promise<void> {
    return new Promise<void>(async (resolve, reject) => { 
      commit(InvoiceStatisticsMutations.SET_IS_LOADING_STATS_CUSTOMER_DISTRIBUTION, true);
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            'Sale/FacturationStatistics/GetStatsCustomerDistribution',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
            params: {
              dateFrom: startDate,
              dateTo: endDate,
              institutionId: institutionId
            }
          }
        )
        .then(res => {
          commit(InvoiceStatisticsMutations.SET_STATS_CUSTOMER_DISTRIBUTION, res.data);
          commit(InvoiceStatisticsMutations.SET_IS_LOADING_STATS_CUSTOMER_DISTRIBUTION, false);
          resolve(res.data);
        })
        .catch(err => {
          console.error(err);
          commit(InvoiceStatisticsMutations.SET_IS_LOADING_STATS_CUSTOMER_DISTRIBUTION, false);
          reject(err);
        })
    })
  },

  async fetchStatsDocumentByDay ({ commit, rootGetters }, {startDate, endDate, institutionId = null}): Promise<void> {
    return new Promise<void>(async (resolve, reject) => { 
      commit(InvoiceStatisticsMutations.SET_IS_LOADING_STATS_DOCUMENT_BY_DAY, true);
      await axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            'Sale/FacturationStatistics/GetStatsDocuments',
            [institutionId],
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
            params: {
              dateFrom:startDate,
              dateTo:endDate,
            }
          }
        )
        .then(res => {
          commit(InvoiceStatisticsMutations.SET_STATS_DOCUMENT_BY_DAY, res.data);
          commit(InvoiceStatisticsMutations.SET_IS_LOADING_STATS_DOCUMENT_BY_DAY, false);
          resolve(res.data);
        })
        .catch(err => {
          console.error(err);
          commit(InvoiceStatisticsMutations.SET_IS_LOADING_STATS_DOCUMENT_BY_DAY, false);
          reject(err);
        })
    })
  },

  async fetchStatsDistributionByActivities ({ commit, rootGetters }, {startDate, endDate, institutionId = null}): Promise<void> {
    return new Promise<void>(async (resolve, reject) => { 
      commit(InvoiceStatisticsMutations.SET_IS_LOADING_STATS_DISTRIBUTION_BY_ACTIVITES, true);
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            'Sale/FacturationStatistics/GetStatsDistributionByActivities',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
            params: {
              dateFrom:startDate,
              dateTo:endDate,
              institutionId: institutionId
            }
          }
        )
        .then(res => {
          commit(InvoiceStatisticsMutations.SET_STATS_DISTRIBUTION_BY_ACTIVITES, res.data);
          commit(InvoiceStatisticsMutations.SET_IS_LOADING_STATS_DISTRIBUTION_BY_ACTIVITES, false);
          resolve(res.data);
        })
        .catch(err => {
          console.error(err);
          commit(InvoiceStatisticsMutations.SET_IS_LOADING_STATS_DISTRIBUTION_BY_ACTIVITES, false);
          reject(err);
        })
    })
  },
}