import { IInventoryDetailState } from "./states";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { InventoryDetailRequestDto } from "Api";
import { InventoryDetailMutations } from "./mutations";

export const actions: ActionTree<IInventoryDetailState, RootState> = {
  async getInventoryDetailById(
    { commit },
    {
      inventoryDetailId,
      updateState = true,
    }: { inventoryDetailId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(InventoryDetailMutations.SET_IS_LOADING_INVENTORY_DETAILS, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/InventoryDetail/GetInventoryDetailById`,
          {
            params: {
              inventoryDetailId: inventoryDetailId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(
              InventoryDetailMutations.SET_IS_LOADING_INVENTORY_DETAILS,
              false
            );
            commit(InventoryDetailMutations.SET_INVENTORY_DETAIL, res.data);
          }
          commit(
            InventoryDetailMutations.SET_REQUEST_STATE_INVENTORY_DETAIL,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            InventoryDetailMutations.SET_IS_LOADING_INVENTORY_DETAILS,
            false
          );
          commit(
            InventoryDetailMutations.SET_REQUEST_STATE_INVENTORY_DETAIL,
            err
          );
          reject(err);
        });
    });
  },
  async getInventoryDetailsByInventoryId(
    { commit, rootGetters, dispatch },
    { inventoryId, action = true }: { inventoryId: number; action: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/InventoryDetail/GetInventoryDetailsByInventoryId`,
          {
            params: {
              inventoryId: inventoryId,
            },
          }
        )
        .then(async (res) => {
          let dataDetails = res.data.map((el) => {
            return {
              ...el,
              inventoryId: inventoryId,
              unitId: res.data.unit ? res.data.unit.id : 1,
              productId: el.product ? el.product.id : 0,
            };
          });
          commit(InventoryDetailMutations.SET_INVENTORY_DETAILS, dataDetails);

          if (action) {
            await dispatch("getProductsFormatedForInventory", {}).then(
              (res) => {
                let dataMergeProducts = res.map((el) => {
                  // On recherche si il existe un stock pour ce produit
                  let inventoryStock = rootGetters.productsWithStockList.find(
                    (stock) => stock.product.id == el.id
                  );
                  // On recherche si il existe un inventoryDetail pour ce produit
                  let inventoryDetail = dataDetails.find(
                    (product) => product.productId == el.id
                  );
                  return {
                    ...el,
                    stock: inventoryStock ? inventoryStock.quantity : 0,
                    unitId: el.unit ? el.unit.id : 1,
                    quantityNoted: inventoryDetail
                      ? inventoryDetail.quantity
                      : null,
                    inventoryDetailId: inventoryDetail
                      ? inventoryDetail.id
                      : null,
                    inventoryDetail: inventoryDetail ? inventoryDetail : null,
                  };
                });
                commit(
                  InventoryDetailMutations.SET_INVENTORY_DETAILS_FORMATED,
                  dataMergeProducts
                );
              }
            );
            commit(
              InventoryDetailMutations.SET_IS_LOADING_INVENTORY_DETAILS,
              false
            );
            commit(
              InventoryDetailMutations.SET_REQUEST_STATE_INVENTORY_DETAIL,
              res
            );
            resolve(dataDetails);
          } else {
            resolve(
              res.data.map((el) => {
                return { ...el, inventoryId: inventoryId };
              })
            );
          }
        })
        .catch((err) => {
          commit(
            InventoryDetailMutations.SET_REQUEST_STATE_INVENTORY_DETAIL,
            err
          );
          commit(
            InventoryDetailMutations.SET_IS_LOADING_INVENTORY_DETAILS,
            false
          );
          reject(err);
        });
    });
  },

  // async getInventoryDetailsArchived() {},

  // async getInventoryDetailArchivedById() {},

  async createInventoryDetail(
    { commit, rootGetters },
    { inventoryDetail }: { inventoryDetail: InventoryDetailRequestDto }
  ) {
    return new Promise(async (resolve, reject) => {
      commit(InventoryDetailMutations.SET_IS_CREATING_INVENTORY_DETAIL, true);
      await axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/InventoryDetail/CreateInventoryDetail",
          inventoryDetail
        )
        .then((res) => {
          let newObject = { ...inventoryDetail, id: res.data.data.id };
          rootGetters.inventoryDetails.push(newObject);

          let product = rootGetters.inventoryDetailsFormated.find(
            (elem) => elem.id == inventoryDetail.productId
          );

          rootGetters.inventoryDetailsFormated.splice(
            rootGetters.inventoryDetailsFormated.findIndex(
              (elem) => elem.id == inventoryDetail.productId
            ),
            1,
            {
              ...product,
              quantityNoted: res.data.data.quantity,
              inventoryDetailId: res.data.data.id,
              inventoryDetail: res.data.data,
            }
          );
          commit(
            InventoryDetailMutations.SET_INVENTORY_DETAILS_FORMATED,
            rootGetters.inventoryDetailsFormated
          );

          commit(
            InventoryDetailMutations.SET_INVENTORY_DETAILS,
            rootGetters.inventoryDetails
          );
          commit(
            InventoryDetailMutations.SET_REQUEST_STATE_INVENTORY_DETAIL,
            res
          );
          commit(
            InventoryDetailMutations.SET_IS_CREATING_INVENTORY_DETAIL,
            false
          );
          resolve(newObject);
        })
        .catch((err) => {
          console.error(err);
          commit(
            InventoryDetailMutations.SET_REQUEST_STATE_INVENTORY_DETAIL,
            err
          );
          commit(
            InventoryDetailMutations.SET_IS_CREATING_INVENTORY_DETAIL,
            false
          );
          reject(err);
        });
    });
  },

  async updateInventoryDetail(
    { commit, rootGetters },
    inventoryDetail: InventoryDetailRequestDto
  ) {
    return new Promise(async (resolve, reject) => {
      commit(InventoryDetailMutations.SET_IS_UPDATING_INVENTORY_DETAIL, true);
      inventoryDetail = {
        ...inventoryDetail,
        quantity: !inventoryDetail.quantity ? null : inventoryDetail.quantity,
      };
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/InventoryDetail/UpdateInventoryDetail",
          inventoryDetail,
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`,
            },
          }
        )
        .then((res) => {
          rootGetters.inventoryDetails.splice(
            rootGetters.inventoryDetails.findIndex(
              (elem) => elem.id == inventoryDetail.id
            ),
            1,
            inventoryDetail
          );

          let product = rootGetters.inventoryDetailsFormated.find(
            (elem) => elem.id == inventoryDetail.productId
          );

          rootGetters.inventoryDetailsFormated.splice(
            rootGetters.inventoryDetailsFormated.findIndex(
              (elem) => elem.id == inventoryDetail.productId
            ),
            1,
            {
              ...product,
              quantityNoted: res.data.data.quantity,
              inventoryDetailId: res.data.data.id,
              inventoryDetail: res.data.data,
            }
          );
          commit(
            InventoryDetailMutations.SET_INVENTORY_DETAILS_FORMATED,
            rootGetters.inventoryDetailsFormated
          );

          commit(
            InventoryDetailMutations.SET_INVENTORY_DETAILS,
            rootGetters.inventoryDetails
          );
          commit(
            InventoryDetailMutations.SET_REQUEST_STATE_INVENTORY_DETAIL,
            res
          );
          commit(
            InventoryDetailMutations.SET_IS_UPDATING_INVENTORY_DETAIL,
            false
          );
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          commit(
            InventoryDetailMutations.SET_REQUEST_STATE_INVENTORY_DETAIL,
            err
          );
          commit(
            InventoryDetailMutations.SET_IS_UPDATING_INVENTORY_DETAIL,
            false
          );
          reject(err);
        });
    });
  },

  async deleteInventoryDetail(
    { commit, rootGetters },
    { inventoryDetailsIds = [] }: { inventoryDetailsIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(InventoryDetailMutations.SET_IS_DELETING_INVENTORY_DETAIL, true);
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Purchase/InventoryDetail/Delete",
          {
            data: inventoryDetailsIds,
          }
        )
        .then(async (res) => {
          inventoryDetailsIds.forEach((id) => {
            rootGetters.inventoryDetails.splice(
              rootGetters.inventoryDetails.findIndex((elem) => elem.id == id),
              1
            );
          });
          commit(
            InventoryDetailMutations.SET_IS_DELETING_INVENTORY_DETAIL,
            false
          );
          commit(
            InventoryDetailMutations.SET_INVENTORY_DETAIL,
            rootGetters.inventoryDetails
          );
          commit(
            InventoryDetailMutations.SET_REQUEST_STATE_INVENTORY_DETAIL,
            res
          );
          commit(
            InventoryDetailMutations.SET_IS_DELETING_INVENTORY_DETAIL,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            InventoryDetailMutations.SET_IS_DELETING_INVENTORY_DETAIL,
            false
          );
          commit(
            InventoryDetailMutations.SET_REQUEST_STATE_INVENTORY_DETAIL,
            err
          );
          commit(
            InventoryDetailMutations.SET_IS_DELETING_INVENTORY_DETAIL,
            false
          );
          reject(err);
        });
    });
  },
};
