import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { AffairRequestDto } from "@/../Api";
import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IAffairState } from "./states";

export const getters: GetterTree<IAffairState, RootState> = {
  affairsList: (state): AffairRequestDto[] => state.affairsList,
  affairsListByCompany: (state): AffairRequestDto[] =>
    state.affairsListByCompany,
  affairsListArchived: (state): AffairRequestDto[] => state.affairsListArchived,
  affairsListArchivedByCompany: (state): AffairRequestDto[] =>
    state.affairsListArchivedByCompany,
  affair: (state): AffairRequestDto => state.affair,

  // COMMON
  isLoadingAffairsList: (state): boolean => state.isLoadingAffairsList,
  isLoadingAffairsListByCompany: (state): boolean =>
    state.isLoadingAffairsListByCompany,
  isLoadingAffairsListArchived: (state): boolean =>
    state.isLoadingAffairsListArchived,
  isLoadingAffairsListArchivedByCompany: (state): boolean =>
    state.isLoadingAffairsListArchivedByCompany,
  isLoadingAffair: (state): boolean => state.isLoadingAffair,
  isCreatingAffair: (state): boolean => state.isCreatingAffair,
  isUpdatingAffair: (state): boolean => state.isUpdatingAffair,
  isDeletingAffair: (state): boolean => state.isDeletingAffair,
  isArchivingAffair: (state): boolean => state.isArchivingAffair,
  requestStateAffair: (state): IRequestState => state.requestStateAffair,
};
