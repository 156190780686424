import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { InventoryRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { IInventoryState } from './states'
import { IFilters } from '@/types/api-orisis/interfaces/invoices/filters/IFilters'


export const getters: GetterTree<IInventoryState, RootState> = {
  inventoriesList                     : (state): InventoryRequestDto[] => state.inventoriesList,
  inventoriesListArchived             : (state): InventoryRequestDto[] => state.inventoriesListArchived,
  inventory                          : (state): InventoryRequestDto => state.inventory,
  inventoryArchived                  : (state): InventoryRequestDto => state.inventoryArchived,

  //FILTERS
  inventoryFilters                    : (state): IFilters => state.inventoryFilters,

  // COMMON
  inventoryPDF                     : (state): String => state.inventoryPDF,
  isLoadingInventoryPDF           : (state): boolean => state.isLoadingInventoryPDF,
  isLoadingInventoriesList            : (state): boolean => state.isLoadingInventoriesList,
  isLoadingInventoriesListArchived    : (state): boolean => state.isLoadingInventoriesListArchived,
  isValidatingInventory               : (state): boolean => state.isValidatingInventory,
  isLoadingInventory                 : (state): boolean => state.isLoadingInventory,
  isLoadingInventoryArchived         : (state): boolean => state.isLoadingInventoryArchived,
  isCreatingInventory                : (state): boolean => state.isCreatingInventory,
  isUpdatingInventory                : (state): boolean => state.isUpdatingInventory,
  isRestoringInventory               : (state): boolean => state.isRestoringInventory,
  isDeletingInventory               : (state): boolean => state.isDeletingInventory,
  isArchivingInventory              : (state): boolean => state.isArchivingInventory,
  requestStateInventory             : (state): IRequestState => state.requestStateInventory,
  inventoryTabActive                : (state): String => state.inventoryTabActive,
}



