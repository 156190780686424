import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { AnalyticsSettingRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IAnalyticsSettingState } from "./states";

export enum AnalyticsSettingMutations {
    SET_ANALYTICS_SETTINGS_LIST = "SET_ANALYTICS_SETTINGS_LIST",
    SET_ANALYTICS_SETTINGS_LIST_ARCHIVED = "SET_ANALYTICS_SETTINGS_LIST_ARCHIVED",
    SET_ANALYTICS_SETTINGS_LIST_TEMPLATES = "SET_ANALYTICS_SETTINGS_LIST_TEMPLATES",
    SET_ANALYTICS_SETTING = "SET_ANALYTICS_SETTING",

    // COMMON
    SET_IS_LOADING_ANALYTICS_SETTING_LIST = "SET_IS_LOADING_ANALYTICS_SETTING_LIST",
    SET_IS_LOADING_ANALYTICS_SETTING_LIST_ARCHIVED = "SET_IS_LOADING_ANALYTICS_SETTING_LIST_ARCHIVED",
    SET_IS_LOADING_ANALYTICS_SETTING_LIST_TEMPLATES = "SET_IS_LOADING_ANALYTICS_SETTING_LIST_TEMPLATES",
    SET_IS_LOADING_ANALYTICS_SETTING = "SET_IS_LOADING_ANALYTICS_SETTING",
    SET_IS_CREATING_ANALYTICS_SETTING = "SET_IS_CREATING_ANALYTICS_SETTING",
    SET_IS_UPDATING_ANALYTICS_SETTING = "SET_IS_UPDATING_ANALYTICS_SETTING",
    SET_IS_DELETING_ANALYTICS_SETTING = "SET_IS_DELETING_ANALYTICS_SETTING",
    SET_IS_ARCHIVING_ANALYTICS_SETTING = "SET_IS_ARCHIVING_ANALYTICS_SETTING",
    SET_REQUEST_STATE_ANALYTICS_SETTING = "SET_REQUEST_STATE_ANALYTICS_SETTING",
}

export const mutations: MutationTree<IAnalyticsSettingState> = {
    [AnalyticsSettingMutations.SET_ANALYTICS_SETTINGS_LIST_ARCHIVED]: (state, payload: AnalyticsSettingRequestDto[]) => {
        state.analyticsSettingsListArchived = payload;
    }, 
    [AnalyticsSettingMutations.SET_ANALYTICS_SETTINGS_LIST]: (state, payload: AnalyticsSettingRequestDto[]) => {
        state.analyticsSettingsList = payload;
    },
    [AnalyticsSettingMutations.SET_ANALYTICS_SETTINGS_LIST_TEMPLATES]: (state, payload: AnalyticsSettingRequestDto[]) => {
        state.analyticsSettingsListTemplates = payload;
    }, 
    [AnalyticsSettingMutations.SET_ANALYTICS_SETTING]: (state, payload: AnalyticsSettingRequestDto) => {
        state.analyticsSetting = payload;
    },


    //COMMON
    [AnalyticsSettingMutations.SET_IS_LOADING_ANALYTICS_SETTING_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingAnalyticsSettingsListArchived = payload;
    },
    [AnalyticsSettingMutations.SET_IS_LOADING_ANALYTICS_SETTING_LIST]: (state, payload: boolean) => {
        state.isLoadingAnalyticsSettingsList = payload;
    },
    [AnalyticsSettingMutations.SET_IS_LOADING_ANALYTICS_SETTING_LIST_TEMPLATES]: (state, payload: boolean) => {
        state.isLoadingAnalyticsSettingsListTemplates = payload;
    },
    [AnalyticsSettingMutations.SET_IS_LOADING_ANALYTICS_SETTING]: (state, payload: boolean) => {
        state.isLoadingAnalyticsSetting = payload;
    },
    [AnalyticsSettingMutations.SET_IS_CREATING_ANALYTICS_SETTING]: (state, payload: boolean) => {
        state.isCreatingAnalyticsSetting = payload;
    },
    [AnalyticsSettingMutations.SET_IS_UPDATING_ANALYTICS_SETTING]: (state, payload: boolean) => {
        state.isUpdatingAnalyticsSetting = payload;
    },
    [AnalyticsSettingMutations.SET_IS_DELETING_ANALYTICS_SETTING]: (state, payload: boolean) => {
        state.isDeletingAnalyticsSetting = payload;
    },
    [AnalyticsSettingMutations.SET_IS_ARCHIVING_ANALYTICS_SETTING]: (state, payload: boolean) => {
        state.isArchivingAnalyticsSetting = payload;
    },
    [AnalyticsSettingMutations.SET_REQUEST_STATE_ANALYTICS_SETTING]: (state, payload: IRequestState) => {
        state.requestStateAnalyticsSetting = payload;
    },
}