import { IPaymentState } from "./states";
import { MutationTree } from "vuex";
import { IPayment } from "@/types/api-orisis/interfaces/invoices/payment/IPayment";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { IFilters } from "@/types/api-orisis/interfaces/invoices/filters/IFilters";

export enum PaymentMutations {
  SET_PAYMENTS_LIST = "SET_PAYMENTS_LIST",
  SET_PAYMENTS_LIST_BY_INVOICE = "SET_PAYMENTS_LIST_BY_INVOICE",
  SET_PAYMENTS_LIST_BY_CREDIT = "SET_PAYMENTS_LIST_BY_CREDIT",
  SET_PAYMENTS_LIST_BY_BANK_ACCOUNT = "SET_PAYMENTS_LIST_BY_BANK_ACCOUNT",
  SET_PAYMENTS_LIST_ARCHIVED = "SET_PAYMENTS_LIST_ARCHIVED",
  SET_PAYMENT = "SET_PAYMENT",

  //FILTERS
  SET_PAYMENT_FILTERS = "SET_PAYMENT_FILTERS",

  // COMON
  SET_IS_LOADING_PAYMENTS_LIST = "SET_IS_LOADING_PAYMENTS_LIST",
  SET_IS_LOADING_PAYMENTS_LIST_BY_INVOICE = "SET_IS_LOADING_PAYMENTS_BY_INVOICE",
  SET_IS_LOADING_PAYMENTS_LIST_BY_CREDIT = "SET_IS_LOADING_PAYMENTS_BY_CREDIT",
  SET_IS_LOADING_PAYMENTS_LIST_BY_BANK_ACCOUNT = "SET_IS_LOADING_PAYMENTS_BY_BANK_ACCOUNT",
  SET_IS_LOADING_PAYMENTS_LIST_ARCHIVED = "SET_IS_LOADING_PAYMENTS_LIST_ARCHIVED",
  SET_IS_LOADING_PAYMENT = "SET_IS_LOADING_PAYMENT",
  SET_IS_CREATING_PAYMENT = "SET_IS_CREATING_PAYMENT",
  SET_IS_UPDATING_PAYMENT = "SET_IS_UPDATING_PAYMENT",
  SET_IS_DELETING_PAYMENT = "SET_IS_DELETING_PAYMENT",
  SET_IS_ARCHIVING_PAYMENT = "SET_IS_ARCHIVING_PAYMENT",
  SET_IS_LINKING_PAYMENT = "SET_IS_LINKING_PAYMENT",
  SET_IS_UNLINKING_PAYMENT = "SET_IS_UNLINKING_PAYMENT",
  SET_REQUEST_STATE_PAYMENT = "SET_REQUEST_STATE_PAYMENT",
}

export const mutations: MutationTree<IPaymentState> = {
  [PaymentMutations.SET_PAYMENTS_LIST_ARCHIVED]: (
    state,
    payload: IPayment[]
  ) => {
    state.paymentsListArchived = payload;
  },
  [PaymentMutations.SET_PAYMENTS_LIST]: (state, payload: IPayment[]) => {
    state.paymentsList = payload;
  },
  [PaymentMutations.SET_PAYMENTS_LIST_BY_INVOICE]: (
    state,
    payload: IPayment[]
  ) => {
    state.paymentsListByInvoice = payload;
  },
  [PaymentMutations.SET_PAYMENTS_LIST_BY_CREDIT]: (
    state,
    payload: IPayment[]
  ) => {
    state.paymentsListByCredit = payload;
  },
  [PaymentMutations.SET_PAYMENTS_LIST_BY_BANK_ACCOUNT]: (
    state,
    payload: IPayment[]
  ) => {
    state.paymentsListByBankAccount = payload;
  },
  [PaymentMutations.SET_PAYMENT]: (state, payload: IPayment) => {
    state.payment = payload;
  },

  //FILTERS
  [PaymentMutations.SET_PAYMENT_FILTERS]: (state, payload: IFilters) => {
    state.paymentFilters = payload;
  },

  //COMMON
  [PaymentMutations.SET_IS_LOADING_PAYMENTS_LIST_ARCHIVED]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingPaymentsListArchived = payload;
  },
  [PaymentMutations.SET_IS_LOADING_PAYMENTS_LIST]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingPaymentsList = payload;
  },
  [PaymentMutations.SET_IS_LOADING_PAYMENTS_LIST_BY_INVOICE]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingPaymentsListByInvoice = payload;
  },
  [PaymentMutations.SET_IS_LOADING_PAYMENTS_LIST_BY_CREDIT]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingPaymentsListByCredit = payload;
  },
  [PaymentMutations.SET_IS_LOADING_PAYMENTS_LIST_BY_BANK_ACCOUNT]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingPaymentsListByBankAccount = payload;
  },
  [PaymentMutations.SET_IS_LOADING_PAYMENT]: (state, payload: boolean) => {
    state.isLoadingPayment = payload;
  },
  [PaymentMutations.SET_IS_CREATING_PAYMENT]: (state, payload: boolean) => {
    state.isCreatingPayment = payload;
  },
  [PaymentMutations.SET_IS_UPDATING_PAYMENT]: (state, payload: boolean) => {
    state.isUpdatingPayment = payload;
  },
  [PaymentMutations.SET_IS_DELETING_PAYMENT]: (state, payload: boolean) => {
    state.isDeletingPayment = payload;
  },
  [PaymentMutations.SET_IS_ARCHIVING_PAYMENT]: (state, payload: boolean) => {
    state.isArchivingPayment = payload;
  },
  [PaymentMutations.SET_IS_LINKING_PAYMENT]: (state, payload: boolean) => {
    state.isLinkingPayment = payload;
  },
  [PaymentMutations.SET_IS_UNLINKING_PAYMENT]: (state, payload: boolean) => {
    state.isUnlinkingPayment = payload;
  },

  [PaymentMutations.SET_REQUEST_STATE_PAYMENT]: (
    state,
    payload: IRequestState
  ) => {
    state.requestStatePayment = payload;
  },
};
