import { IExportSalesJournalState } from "./states";
import { MutationTree } from "vuex";
import { IExportSalesJournal } from "@/types/api-orisis/interfaces/Accounting/exportSalesJournal/IExportSalesJournal";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";


export enum ExportSalesJournalMutations {
    SET_EXPORT_SALES_JOURNAL_LIST = "SET_EXPORT_SALES_JOURNAL_LIST",
    SET_EXPORT_SALES_JOURNAL = "SET_EXPORT_SALES_JOURNAL",
    
    // COMON 
    SET_IS_LOADING_EXPORT_SALES_JOURNAL_LIST = "SET_IS_LOADING_EXPORT_SALES_JOURNAL_LIST",
    SET_IS_LOADING_EXPORT_SALES_JOURNAL = "SET_IS_LOADING_EXPORT_SALES_JOURNAL",
    SET_IS_CREATING_EXPORT_SALES_JOURNAL = "SET_IS_CREATING_EXPORT_SALES_JOURNAL",
    SET_IS_UPDATING_EXPORT_SALES_JOURNAL = "SET_IS_UPDATING_EXPORT_SALES_JOURNAL",
    SET_IS_DELETING_EXPORT_SALES_JOURNAL = "SET_IS_DELETING_EXPORT_SALES_JOURNAL",
    SET_IS_ARCHIVING_EXPORT_SALES_JOURNAL = "SET_IS_ARCHIVING_EXPORT_SALES_JOURNAL",
    SET_REQUEST_STATE_EXPORT_SALES_JOURNAL = "SET_REQUEST_STATE_EXPORT_SALES_JOURNAL",
  }

  export const mutations: MutationTree<IExportSalesJournalState> = {
    [ExportSalesJournalMutations.SET_EXPORT_SALES_JOURNAL_LIST]: (state, payload: IExportSalesJournal[]) => {
        state.exportSalesJournalList = payload;
    },
    [ExportSalesJournalMutations.SET_EXPORT_SALES_JOURNAL]: (state, payload: IExportSalesJournal) => {
        state.exportSalesJournal = payload;
    },

    //COMMON
    [ExportSalesJournalMutations.SET_IS_LOADING_EXPORT_SALES_JOURNAL_LIST]: (state, payload: boolean) => {
        state.isLoadingExportSalesJournalList = payload;
    },
    [ExportSalesJournalMutations.SET_IS_LOADING_EXPORT_SALES_JOURNAL]: (state, payload: boolean) => {
        state.isLoadingExportSalesJournal = payload;
    },
    [ExportSalesJournalMutations.SET_IS_CREATING_EXPORT_SALES_JOURNAL]: (state, payload: boolean) => {
        state.isCreatingExportSalesJournal = payload;
    },
    [ExportSalesJournalMutations.SET_IS_UPDATING_EXPORT_SALES_JOURNAL]: (state, payload: boolean) => {
        state.isUpdatingExportSalesJournal = payload;
    },
    [ExportSalesJournalMutations.SET_IS_DELETING_EXPORT_SALES_JOURNAL]: (state, payload: boolean) => {
        state.isDeletingExportSalesJournal = payload;
    },
    [ExportSalesJournalMutations.SET_IS_ARCHIVING_EXPORT_SALES_JOURNAL]: (state, payload: boolean) => {
        state.isArchivingExportSalesJournal = payload;
    },

    [ExportSalesJournalMutations.SET_REQUEST_STATE_EXPORT_SALES_JOURNAL]: (state, payload: IRequestState) => {
        state.requestStateExportSalesJournal = payload;
    },
}