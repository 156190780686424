import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { IPayment } from "@/types/api-orisis/interfaces/invoices/payment/IPayment";
import RootState from "@/store/modules/rootState";
import { IPaymentState } from "./states";
import { Module } from "vuex";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { IFilters } from "@/types/api-orisis/interfaces/invoices/filters/IFilters";

export const state: IPaymentState = {
  paymentsList: Array<IPayment>(),
  paymentsListByInvoice: Array<IPayment>(),
  paymentsListByCredit: Array<IPayment>(),
  paymentsListByBankAccount: Array<IPayment>(),
  paymentsListArchived: Array<IPayment>(),
  payment: <IPayment>{},
  isLoadingPaymentsList: false,
  isLoadingPaymentsListByInvoice: false,
  isLoadingPaymentsListByCredit: false,
  isLoadingPaymentsListByBankAccount: false,
  isLoadingPaymentsListArchived: false,
  isLoadingPayment: false,
  isCreatingPayment: false,
  isUpdatingPayment: false,
  isDeletingPayment: false,
  isArchivingPayment: false,
  isLinkingPayment: false,
  isUnlinkingPayment: false,
  requestStatePayment: <IRequestState>{},
  paymentFilters: <IFilters>{},
};

export const PaymentModule: Module<IPaymentState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
