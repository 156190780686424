import { CreditProviderRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { CreditProviderMutations } from "./mutations";
import { ICreditProviderState } from "./states";
import dayjs from "dayjs";
import { generateParams } from "@/types/api-orisis/library/FormatOperations";
import { ICreditProvider } from "@/types/api-orisis/interfaces/Purchase/credit-provider/ICreditProvider";

import { Timezone } from "@syncfusion/ej2-schedule";

let timezoneSchedule = new Timezone();

export let startDate = new Date(
  dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
).toISOString();
export let endDate = new Date(
  dayjs().endOf("month").format("YYYY-MM-DD")
).toISOString();

export const actions: ActionTree<ICreditProviderState, RootState> = {
  getCreditsProviders(
    { commit, rootGetters },
    {
      providerIds = [],
      status = [],
      affairId = null,
      invoiceProviderId = null,
      startTo = null,
      endTo = null,
      startValueDate = null,
      endValueDate = null,
      updateState = true,
      route = null,
    }: {
      providerIds: Array<number>;
      status: Array<number>;
      affairId: number | null;
      invoiceProviderId: number | null;
      startTo: Date | null;
      endTo: Date | null;
      startValueDate?: Date | null;
      endValueDate?: Date | null;
      updateState?: boolean;
      route?: string | null;
    }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        switch (route) {
          case "affair":
            commit(
              CreditProviderMutations.SET_IS_LOADING_CREDIT_PROVIDERS_LIST_BY_AFFAIR,
              true
            );
            break;
          case "provider":
            commit(
              CreditProviderMutations.SET_IS_LOADING_CREDIT_PROVIDERS_LIST_BY_PROVIDER,
              true
            );
            break;
          default:
            commit(
              CreditProviderMutations.SET_IS_LOADING_CREDIT_PROVIDER_LIST,
              true
            );
            break;
        }
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/CreditProvider/GetCreditsProviders`;
      let request = new URLSearchParams();
      request = generateParams(request, providerIds, "providerIds");
      request = status ? generateParams(request, status, "status") : request;
      request = affairId
        ? generateParams(request, [affairId], "affairId")
        : request;
      request = invoiceProviderId
        ? generateParams(request, [invoiceProviderId], "invoiceProviderId")
        : request;
      request = startTo
        ? generateParams(
            request,
            [dayjs(startTo).format("YYYY-MM-DD")],
            "startTo"
          )
        : request;
      request = endTo
        ? generateParams(
            request,
            [
              dayjs(endTo)
                .hour(23)
                .minute(59)
                .second(59)
                .format("YYYY-MM-DD HH:mm:ss"),
            ],
            "endTo"
          )
        : request;
      request = startValueDate
        ? generateParams(
            request,
            [dayjs(startValueDate).format("YYYY-MM-DD")],
            "startValueDate"
          )
        : request;
      request = endValueDate
        ? generateParams(
            request,
            [
              dayjs(endValueDate)
                .hour(23)
                .minute(59)
                .second(59)
                .format("YYYY-MM-DD HH:mm:ss"),
            ],
            "endValueDate"
          )
        : request;
      axios
        .get(url, {
          params: request,
        })
        .then((res) => {
          let list = res.data.map((item) => {
            return {
              ...item,
              documentDate: item.documentDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(item.documentDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              sourceDate: item.sourceDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(item.sourceDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
            };
          });
          if (updateState) {
            switch (route) {
              case "affair":
                commit(
                  CreditProviderMutations.SET_IS_LOADING_CREDIT_PROVIDERS_LIST_BY_AFFAIR,
                  false
                );
                commit(
                  CreditProviderMutations.SET_CREDIT_PROVIDERS_LIST_BY_AFFAIR,
                  list
                );
                break;
              case "provider":
                commit(
                  CreditProviderMutations.SET_IS_LOADING_CREDIT_PROVIDERS_LIST_BY_PROVIDER,
                  false
                );
                commit(
                  CreditProviderMutations.SET_CREDIT_PROVIDER_LIST_BY_PROVIDER,
                  list
                );
                break;
              default:
                commit(
                  CreditProviderMutations.SET_IS_LOADING_CREDIT_PROVIDER_LIST,
                  false
                );
                commit(CreditProviderMutations.SET_CREDIT_PROVIDERS_LIST, list);
                break;
            }
          }
          commit(
            CreditProviderMutations.SET_REQUEST_STATE_CREDIT_PROVIDER,
            res
          );
          resolve(list);
        })
        .catch((err) => {
          if (updateState) {
            commit(
              CreditProviderMutations.SET_IS_LOADING_CREDIT_PROVIDER_LIST,
              false
            );
          }
          commit(
            CreditProviderMutations.SET_REQUEST_STATE_CREDIT_PROVIDER,
            err
          );
          reject(err);
        });
    });
  },

  getCreditsProvidersByAffairId(
    { commit, rootGetters },
    {
      affairId = null,
      startTo = null,
      endTo = null,
      startValueDate = null,
      endValueDate = null,
      updateState = true,
    }: {
      affairId?: number | null;
      startTo?: Date | null;
      endTo?: Date | null;
      startValueDate?: Date | null;
      endValueDate?: Date | null;
      updateState?: boolean;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          CreditProviderMutations.SET_IS_LOADING_CREDIT_PROVIDERS_LIST_BY_AFFAIR,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/CreditProvider/GetCreditsProviders`;
      axios
        .get(url, {
          params: {
            affairId,
            startTo: startTo ? dayjs(startTo).format("YYYY-MM-DD") : null,
            endTo: endTo
              ? dayjs(endTo)
                  .hour(23)
                  .minute(59)
                  .second(59)
                  .format("YYYY-MM-DD HH:mm:ss")
              : null,
            startValueDate: startValueDate,
            endValueDate: endValueDate,
          },
        })
        .then((res) => {
          let list = res.data.map((item) => {
            return {
              ...item,
              documentDate: item.documentDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(item.documentDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              sourceDate: item.sourceDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(item.sourceDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
            };
          });
          commit(
            CreditProviderMutations.SET_IS_LOADING_CREDIT_PROVIDERS_LIST_BY_AFFAIR,
            false
          );
          if (updateState) {
            commit(
              CreditProviderMutations.SET_CREDIT_PROVIDERS_LIST_BY_AFFAIR,
              list
            );
          }
          commit(
            CreditProviderMutations.SET_REQUEST_STATE_CREDIT_PROVIDER,
            res
          );
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CreditProviderMutations.SET_IS_LOADING_CREDIT_PROVIDERS_LIST_BY_AFFAIR,
            false
          );
          commit(
            CreditProviderMutations.SET_REQUEST_STATE_CREDIT_PROVIDER,
            err
          );
          reject(err);
        });
    });
  },

  getCreditsProvidersByProviderIds(
    { commit, rootGetters },
    {
      providerIds = [],
      startTo = null,
      endTo = null,
      status = [],
      updateState = true,
    }: {
      providerIds?: number[];
      startTo?: Date | null;
      endTo?: Date | null;
      status?: number[];
      updateState?: boolean;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          CreditProviderMutations.SET_IS_LOADING_CREDIT_PROVIDERS_LIST_BY_PROVIDER,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/CreditProvider/GetCreditsProviders`;
      let request = new URLSearchParams();
      request = generateParams(request, providerIds, "providerIds");
      request = generateParams(request, status, "status");
      if (startTo) {
        request.append("startTo", dayjs(startTo).format("YYYY-MM-DD"));
      }
      if (endTo) {
        request.append(
          "endTo",
          dayjs(endTo)
            .hour(23)
            .minute(59)
            .second(59)
            .format("YYYY-MM-DD HH:mm:ss")
        );
      }
      axios
        .get(url, {
          params: request,
        })
        .then((res) => {
          let list = res.data.map((item) => {
            return {
              ...item,
              documentDate: item.documentDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(item.documentDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
              sourceDate: item.sourceDate
                ? new Date(
                    new Date(
                      timezoneSchedule.removeLocalOffset(
                        new Date(item.sourceDate)
                      )
                    ).setHours(12, 0, 0, 0)
                  )
                : null,
            };
          });
          commit(
            CreditProviderMutations.SET_IS_LOADING_CREDIT_PROVIDERS_LIST_BY_PROVIDER,
            false
          );
          if (updateState) {
            commit(
              CreditProviderMutations.SET_CREDIT_PROVIDER_LIST_BY_PROVIDER,
              list
            );
          }
          commit(
            CreditProviderMutations.SET_REQUEST_STATE_CREDIT_PROVIDER,
            res
          );
          resolve(list);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CreditProviderMutations.SET_IS_LOADING_CREDIT_PROVIDERS_LIST_BY_PROVIDER,
            false
          );
          commit(
            CreditProviderMutations.SET_REQUEST_STATE_CREDIT_PROVIDER,
            err
          );
          reject(err);
        });
    });
  },

  async getCreditsProviderById(
    { commit, dispatch, rootGetters },
    {
      creditProviderId,
      getPaymentProviders = false,
      updateStatus = true,
    }: {
      creditProviderId: number;
      getPaymentProviders?: boolean;
      updateStatus: boolean;
    }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateStatus) {
        commit(CreditProviderMutations.SET_IS_LOADING_CREDIT_PROVIDER, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/CreditProvider/getCreditsProviderById`,
          {
            params: {
              creditProviderId: creditProviderId,
            },
          }
        )
        .then(async (res) => {
          if (getPaymentProviders) {
            await dispatch("getPaymentProviders", {
              creditProviderId: res.data.id,
              startTo: null,
              endTo: null,
              updateState: false,
            }).then((result) => {
              res.data.paymentProviders = result.map((payment) => {
                return {
                  amount: payment.amount,
                  date: payment.date,
                  paymentMethod: payment.paymentMethod.label,
                };
              });
            });
          }
          let creditProvider = {
            ...res.data,
            providerId: res.data.provider ? res.data.provider.id : null,
            purchaseCategoryId: res.data.purchaseCategory
              ? res.data.purchaseCategory.id
              : null,
          };
          if (updateStatus) {
            commit(CreditProviderMutations.SET_CREDIT_PROVIDER, creditProvider);
            commit(
              CreditProviderMutations.SET_IS_LOADING_CREDIT_PROVIDER,
              false
            );
          }
          commit(
            CreditProviderMutations.SET_REQUEST_STATE_CREDIT_PROVIDER,
            res
          );
          resolve(creditProvider);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CreditProviderMutations.SET_REQUEST_STATE_CREDIT_PROVIDER,
            err
          );
          if (updateStatus) {
            commit(
              CreditProviderMutations.SET_IS_LOADING_CREDIT_PROVIDER,
              false
            );
          }
          reject(err);
        });
    });
  },

  async createCreditProvider(
    { commit, rootGetters, dispatch },
    {
      creditProvider,
      route = null,
    }: { creditProvider?: CreditProviderRequestDto; route?: string | null }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(CreditProviderMutations.SET_IS_CREATING_CREDIT_PROVIDER, true);
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Purchase/CreditProvider/CreateCreditsProvider`,
          creditProvider
        )
        .then((res) => {
          let creditProviderFilters = null;
          switch (route) {
            case "provider":
              creditProviderFilters = rootGetters.userParametersList.find(
                (elem) => elem.key == "companyCreditProviders"
              );
              if (creditProviderFilters) {
                dispatch("getCreditsProvidersByProviderIds", {
                  providerIds: [creditProvider.providerId],
                });
              } else {
                dispatch("setFilter", {
                  name: "companyCreditProviders",
                  filter: {
                    dateRangeType: 7,
                    dateRange: { startDate: startDate, endDate: endDate },
                  },
                });
                dispatch("getCreditsProvidersByProviderIds", {
                  providerIds: [creditProvider.providerId],
                });
              }
              break;
            default:
              creditProviderFilters = rootGetters.userParametersList.find(
                (elem) => elem.key == "creditProviderFilters"
              );
              if (creditProviderFilters) {
                dispatch("getCreditsProviders", {
                  startTo: JSON.parse(creditProviderFilters.value).dateRange
                    .startDate,
                  endTo: JSON.parse(creditProviderFilters.value).dateRange
                    .endDate,
                });
              } else {
                dispatch("setFilter", {
                  name: "creditProviderFilters",
                  filter: {
                    dateRangeType: 7,
                    dateRange: { startDate: startDate, endDate: endDate },
                  },
                });
                dispatch("getCreditsProviders", {
                  startTo: JSON.parse(creditProviderFilters.value).dateRange
                    .startDate,
                  endTo: JSON.parse(creditProviderFilters.value).dateRange
                    .endDate,
                });
              }
              break;
          }
          commit(
            CreditProviderMutations.SET_REQUEST_STATE_CREDIT_PROVIDER,
            res
          );
          commit(
            CreditProviderMutations.SET_IS_CREATING_CREDIT_PROVIDER,
            false
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CreditProviderMutations.SET_REQUEST_STATE_CREDIT_PROVIDER,
            err
          );
          commit(
            CreditProviderMutations.SET_IS_CREATING_CREDIT_PROVIDER,
            false
          );
          reject(err);
        });
    });
  },

  async updateCreditsProvider(
    { commit, rootGetters, dispatch },
    {
      creditProvider,
      updateStatus = true,
      route = null,
    }: {
      creditProvider: ICreditProvider;
      updateStatus: boolean;
      route?: string | null;
    }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (updateStatus) {
        commit(CreditProviderMutations.SET_IS_UPDATING_CREDIT_PROVIDER, true);
      }
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/CreditProvider/UpdateCreditsProvider`,
          creditProvider
        )
        .then(async (res) => {
          commit(CreditProviderMutations.SET_CREDIT_PROVIDER, res.data.data);
          let creditProviderFilters = null;
          if (updateStatus) {
            switch (route) {
              case "provider":
                creditProviderFilters = rootGetters.userParametersList.find(
                  (elem) => elem.key == "companyCreditProviders"
                );
                if (creditProviderFilters) {
                  dispatch("getCreditsProvidersByProviderIds", {
                    providerIds: [creditProvider.providerId],
                  });
                } else {
                  dispatch("setFilter", {
                    name: "companyCreditProviders",
                    filter: {
                      dateRangeType: 7,
                      dateRange: { startDate: startDate, endDate: endDate },
                    },
                  });
                  dispatch("getCreditsProvidersByProviderIds", {
                    providerIds: [creditProvider.providerId],
                  });
                }
                break;
              default:
                creditProviderFilters = rootGetters.userParametersList.find(
                  (elem) => elem.key == "creditProviderFilters"
                );
                if (creditProviderFilters) {
                  dispatch("getCreditsProviders", {
                    startTo: JSON.parse(creditProviderFilters.value).dateRange
                      .startDate,
                    endTo: JSON.parse(creditProviderFilters.value).dateRange
                      .endDate,
                  });
                } else {
                  dispatch("setFilter", {
                    name: "creditProviderFilters",
                    filter: {
                      dateRangeType: 7,
                      dateRange: { startDate: startDate, endDate: endDate },
                    },
                  });
                  dispatch("getCreditsProviders", {
                    startTo: JSON.parse(creditProviderFilters.value).dateRange
                      .startDate,
                    endTo: JSON.parse(creditProviderFilters.value).dateRange
                      .endDate,
                  });
                }
                break;
            }
          } else {
            switch (route) {
              case "provider":
                const indexProvider = rootGetters.creditProvidersList.findIndex(
                  (elem) => elem.id == creditProvider.id
                );
                if (indexProvider !== -1) {
                  rootGetters.creditProvidersList.splice(
                    indexProvider,
                    1,
                    res.data.data
                  );
                  commit(
                    CreditProviderMutations.SET_CREDIT_PROVIDERS_LIST,
                    rootGetters.creditProvidersList
                  );
                }
                break;
              default:
                const index = rootGetters.creditProvidersList.findIndex(
                  (elem) => elem.id == creditProvider.id
                );
                if (index !== -1) {
                  rootGetters.creditProvidersList.splice(
                    index,
                    1,
                    res.data.data
                  );
                  commit(
                    CreditProviderMutations.SET_CREDIT_PROVIDERS_LIST,
                    rootGetters.creditProvidersList
                  );
                }
                break;
            }
          }
          commit(
            CreditProviderMutations.SET_REQUEST_STATE_CREDIT_PROVIDER,
            res
          );
          if (updateStatus) {
            commit(
              CreditProviderMutations.SET_IS_UPDATING_CREDIT_PROVIDER,
              false
            );
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CreditProviderMutations.SET_REQUEST_STATE_CREDIT_PROVIDER,
            err
          );
          if (updateStatus) {
            commit(
              CreditProviderMutations.SET_IS_UPDATING_CREDIT_PROVIDER,
              false
            );
          }
          reject(err);
        });
    });
  },

  async updateCreditProviderRepartitions(
    { commit, rootGetters },
    {
      creditProviderId,
      repartition,
    }: { creditProviderId: number; repartition: any }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/CreditProvider/UpdateCreditProviderRepartitions`,
          repartition,
          {
            params: {
              creditProviderId: creditProviderId,
            },
          }
        )
        .then((res) => {
          commit(
            CreditProviderMutations.SET_REQUEST_STATE_CREDIT_PROVIDER,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CreditProviderMutations.SET_REQUEST_STATE_CREDIT_PROVIDER,
            err
          );
          reject(err);
        });
    });
  },

  async updateCreditProviderTva(
    { commit, rootGetters },
    { creditProviderId, tvas }: { creditProviderId: number; tvas: any }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/CreditProvider/UpdateCreditProviderTva`,
          tvas,
          {
            params: {
              creditProviderId: creditProviderId,
            },
          }
        )
        .then((res) => {
          commit(
            CreditProviderMutations.SET_REQUEST_STATE_CREDIT_PROVIDER,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CreditProviderMutations.SET_REQUEST_STATE_CREDIT_PROVIDER,
            err
          );
          reject(err);
        });
    });
  },

  async updateCreditProviderOptions(
    { commit, dispatch },
    {
      creditProvider,
      updateStatus = true,
      route = null,
    }: {
      creditProvider: ICreditProvider;
      updateStatus: boolean;
      route?: string | null;
    }
  ): Promise<void> {
    if (updateStatus) {
      commit(CreditProviderMutations.SET_IS_UPDATING_CREDIT_PROVIDER, true);
    }
    let allPromises = [];
    if (creditProvider.creditProviderRepartitions?.length > 0) {
      allPromises.push(
        dispatch("updateCreditProviderRepartitions", {
          creditProviderId: creditProvider.id,
          repartition: creditProvider.creditProviderRepartitions,
          route: route,
        })
      );
    }
    if (creditProvider.referentielTvas?.length > 0) {
      allPromises.push(
        dispatch("updateCreditProviderTva", {
          creditProviderId: creditProvider.id,
          tvas: creditProvider.referentielTvas,
          route: route,
        })
      );
    }
    await Promise.all(allPromises).then(() => {
      return Promise.all(allPromises)
        .then(() => {
          dispatch("updateCreditsProvider", {
            creditProvider,
            updateStatus,
            route,
          }).then((res) => {
            commit(CreditProviderMutations.SET_CREDIT_PROVIDER, res.data);
          });
        })
        .catch((err) => {
          commit(
            CreditProviderMutations.SET_IS_UPDATING_CREDIT_PROVIDER,
            false
          );
          console.error(err);
        });
    });
  },

  async updateCreditProviderTagCreditProvider(
    { commit, rootGetters },
    {
      creditProviderIds,
      creditProviderTagIds,
    }: { creditProviderIds: number[]; creditProviderTagIds: number[] }
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/CreditProvider/UpdateCreditProviderTagCreditProvider`,
          {
            creditProviderIds: creditProviderIds,
            creditProviderTagIds: creditProviderTagIds,
          }
        )
        .then((res) => {
          commit(
            CreditProviderMutations.SET_REQUEST_STATE_CREDIT_PROVIDER,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CreditProviderMutations.SET_REQUEST_STATE_CREDIT_PROVIDER,
            err
          );
          reject(err);
        });
    });
  },

  //updateCreditProviderInvoiceProvider

  async uploadCreditProviderFile(
    { commit, rootGetters, dispatch },
    { formData }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/CreditProvider/UploadCreditProviderFile`,
          formData
        )
        .then(async (res) => {
          let creditProviderFilters = rootGetters.userParametersList.find(
            (elem) => elem.key == "creditProviderFilters"
          );
          if (creditProviderFilters) {
            dispatch("getCreditsProviders", {
              startTo: JSON.parse(creditProviderFilters.value).dateRange
                .startDate,
              endTo: JSON.parse(creditProviderFilters.value).dateRange.endDate,
            });
          } else {
            dispatch("setFilter", {
              name: "creditProviderFilters",
              filter: {
                dateRangeType: 7,
                dateRange: { startDate: startDate, endDate: endDate },
              },
            });
            dispatch("getCreditsProviders", {
              startTo: startDate,
              endTo: endDate,
            });
          }
          commit(
            CreditProviderMutations.SET_REQUEST_STATE_CREDIT_PROVIDER,
            res
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(
            CreditProviderMutations.SET_REQUEST_STATE_CREDIT_PROVIDER,
            err
          );
          reject(err);
        });
    });
  },

  async deleteCreditProviders(
    { commit, rootGetters, dispatch },
    { creditProviderIds = [] }: { creditProviderIds: number[] }
  ) {
    commit(CreditProviderMutations.SET_IS_DELETING_CREDIT_PROVIDER, true);
    await axios
      .delete(
        `${process.env.VUE_APP_API_ORISIS}Purchase/CreditProvider/Delete`,
        {
          data: creditProviderIds,
        }
      )
      .then((res) => {
        let creditProviderFilters = rootGetters.userParametersList.find(
          (elem) => elem.key == "creditProviderFilters"
        );
        if (creditProviderFilters) {
          dispatch("getCreditsProviders", {
            startTo: JSON.parse(creditProviderFilters.value).dateRange
              .startDate,
            endTo: JSON.parse(creditProviderFilters.value).dateRange.endDate,
          });
        } else {
          dispatch("setFilter", {
            name: "creditProviderFilters",
            filter: {
              dateRangeType: 7,
              dateRange: { startDate: startDate, endDate: endDate },
            },
          });
          dispatch("getCreditsProviders", {
            startTo: startDate,
            endTo: endDate,
          });
        }

        commit(CreditProviderMutations.SET_REQUEST_STATE_CREDIT_PROVIDER, res);
        commit(CreditProviderMutations.SET_IS_DELETING_CREDIT_PROVIDER, false);
      })
      .catch((err) => {
        console.error(err);
        commit(CreditProviderMutations.SET_REQUEST_STATE_CREDIT_PROVIDER, err);
        commit(CreditProviderMutations.SET_IS_DELETING_CREDIT_PROVIDER, false);
      });
  },
};
