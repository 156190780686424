import { IContractState } from "./states"
import { Module } from "vuex"
import { ContractRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IContractState = {
    contractsList         : Array<ContractRequestDto>(),
    contractsListArchived : Array<ContractRequestDto>(),
    contract              : <ContractRequestDto>{},
    isLoadingContractsList     : false,
    isLoadingContractsListArchived     : false,
    isLoadingContract     : false,
    isCreatingContract    : false,
    isUpdatingContract    : false,
    isDeletingContract    : false,
    isArchivingContract    : false,
    requestStateContract: <IRequestState>{},
}

export const ContractModule: Module<IContractState, RootState> = {
    state, 
    getters,
    mutations,
    actions
} 