import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import RootState from "@/store/modules/rootState"
import { IUnitState } from "./states";
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

export const state: IUnitState = {
    unitsList                  : Array<any>(),
    unitsListArchived          : Array<any>(),
    unit                       : <any>{},
    isLoadingUnitsList         : false,
    isLoadingUnitsListArchived : false,
    isLoadingUnit              : false,
    isCreatingUnit             : false,
    isUpdatingUnit             : false,
    isDeletingUnit             : false,
    requestStateUnit           : <IRequestState>{},
    isArchivingUnit            : false,
}

export const UnitsModule: Module<IUnitState, RootState> = {
  state, 
  getters,
  mutations,
  actions
}