import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { IDocument } from "@/types/api-orisis/interfaces/invoices/document/IDocument";
import { IDocumentStats } from "@/types/api-orisis/interfaces/invoices/document/IDocumentStats";
import { IRemaining } from "@/types/api-orisis/interfaces/invoices/document/IRemaining";
import { INewCreditForm } from "@/types/api-orisis/interfaces/invoices/document/INewCreditForm";
import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IDocumentState } from "./states";
import { IPaymentMethod } from "@/types/api-orisis/interfaces/invoices/paymentMethod/IPaymentMethod";
import { IFilters } from "@/types/api-orisis/interfaces/invoices/filters/IFilters";

export const getters: GetterTree<IDocumentState, RootState> = {
  quotesList: (state): IDocument[] => state.quotesList,
  quotesListTemplates: (state): IDocument[] => state.quotesListTemplates,
  quotesListSigned: (state): IDocument[] => state.quotesListSigned,
  quotesListArchived: (state): IDocument[] => state.quotesListArchived,
  quotesListByAffair: (state): IDocument[] => state.quotesListByAffair,
  quotesListByCompany: (state): IDocument[] => state.quotesListByCompany,

  quotesListStats: (state): IDocumentStats => state.quotesListStats,
  quotesListSignedStats: (state): IDocumentStats => state.quotesListSignedStats,
  quotesListLastYearStats: (state): IDocumentStats =>
    state.quotesListLastYearStats,

  invoicesList: (state): IDocument[] => state.invoicesList,
  invoicesListTemplates: (state): IDocument[] => state.invoicesListTemplates,
  invoicesListArchived: (state): IDocument[] => state.invoicesListArchived,
  invoicesListByAffair: (state): IDocument[] => state.invoicesListByAffair,
  invoicesListByCompany: (state): IDocument[] => state.invoicesListByCompany,

  invoicesListStats: (state): IDocumentStats => state.invoicesListStats,
  invoicesListLastYearStats: (state): IDocumentStats =>
    state.invoicesListLastYearStats,
  invoicesRemainingToPay: (state): IRemaining => state.invoicesRemainingToPay,

  creditsListStats: (state): IDocumentStats => state.creditsListStats,
  creditsListLastYearStats: (state): IDocumentStats =>
    state.creditsListLastYearStats,

  creditsList: (state): IDocument[] => state.creditsList,
  creditsListArchived: (state): IDocument[] => state.creditsListArchived,
  creditsListByAffair: (state): IDocument[] => state.creditsListByAffair,
  creditsListByCompany: (state): IDocument[] => state.creditsListByCompany,
  creditByInvoice: (state): IDocument => state.creditByInvoice,

  document: (state): IDocument => state.document,
  documentPDF: (state): String => state.documentPDF,
  initialInvoice: (state): IDocument => state.initialInvoice,
  lastInvoice: (state) => state.lastInvoice,
  documentPaymentMethods: (state): IPaymentMethod[] =>
    state.documentPaymentMethods,
  isTotalCredit: (state): boolean => state.isTotalCredit,

  //FILTERS
  invoiceFilters: (state): IFilters => state.invoiceFilters,
  quoteFilters: (state): IFilters => state.quoteFilters,
  creditFilters: (state): IFilters => state.creditFilters,

  // COMMON
  loadingMsg: (state): String => state.loadingMsg,
  isValidatingDocument: (state): boolean => state.isValidatingDocument,
  isLoadingInvoicesRemainingToPay: (state): boolean =>
    state.isLoadingInvoicesRemainingToPay,
  isLoadingDocumentsListByCompany: (state): boolean =>
    state.isLoadingDocumentsListByCompany,
  isLoadingDocumentsList: (state): boolean => state.isLoadingDocumentsList,
  isLoadingDocumentsListArchived: (state): boolean =>
    state.isLoadingDocumentsListArchived,
  isLoadingDocumentsListByAffair: (state): boolean =>
    state.isLoadingDocumentsListByAffair,
  isLoadingDocument: (state): boolean => state.isLoadingDocument,
  isLoadingDocumentPDF: (state): boolean => state.isLoadingDocumentPDF,
  isLoadingDuplicatingDocument: (state): boolean =>
    state.isLoadingDuplicatingDocument,
  isCreatingDocument: (state): boolean => state.isCreatingDocument,
  isCreatingLeaseDocument: (state): boolean => state.isCreatingLeaseDocument,
  isUpdatingDocument: (state): boolean => state.isUpdatingDocument,
  isDeletingDocument: (state): boolean => state.isDeletingDocument,
  requestStateDocument: (state): IRequestState => state.requestStateDocument,
  isArchivingDocument: (state): boolean => state.isArchivingDocument,
  isLoadingQuotesList: (state): boolean => state.isLoadingQuotesList,
  isLoadingQuotesListStats: (state): boolean => state.isLoadingQuotesListStats,
  isLoadingInvoicesList: (state): boolean => state.isLoadingInvoicesList,
  isLoadingInvoicesListStats: (state): boolean => state.isLoadingInvoicesListStats,
  isLoadingInvoicesListLastYearStats: (state): boolean => state.isLoadingInvoicesListStats,
  isLoadingCreditsList: (state): boolean => state.isLoadingInvoicesList,
  isLoadingCreditsListStats: (state): boolean => state.isLoadingCreditsListStats,
  isLoadingCreditsListLastYearStats: (state): boolean => state.isLoadingCreditsListStats,
  isSendingEmail: (state): boolean => state.isSendingEmail,

  marginStatus: (state): boolean => state.marginStatus,

  documentTabActive: (state): String => state.documentTabActive,

  newCreditForm: (state): INewCreditForm => state.newCreditForm,
};
