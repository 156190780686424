import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { EventRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { IEventState } from './states'


export const getters: GetterTree<IEventState, RootState> = {
  eventsList: (state): EventRequestDto[] => state.eventsList,
  eventsListByCollaborator: (state): EventRequestDto[] => state.eventsListByCollaborator,
  eventsListByCompany: (state): EventRequestDto[] => state.eventsListByCompany,
  eventsListArchived: (state): EventRequestDto[] => state.eventsListArchived,
  eventsListArchivedByCollaborator: (state): EventRequestDto[] => state.eventsListArchivedByCollaborator,
  eventsListArchivedByCompany: (state): EventRequestDto[] => state.eventsListArchivedByCompany,
  event     : (state): EventRequestDto => state.event,

  // COMMON
  isLoadingEventsList   : (state): boolean => state.isLoadingEventsList,
  isLoadingEventsListByCollaborator   : (state): boolean => state.isLoadingEventsListByCollaborator,
  isLoadingEventsListByCompany   : (state): boolean => state.isLoadingEventsListByCompany,
  isLoadingEventsListArchived   : (state): boolean => state.isLoadingEventsListArchived,
  isLoadingEventsListArchivedByCollaborator   : (state): boolean => state.isLoadingEventsListArchivedByCollaborator,
  isLoadingEventsListArchivedByCompany   : (state): boolean => state.isLoadingEventsListArchivedByCompany,
  isLoadingEvent   : (state): boolean => state.isLoadingEvent,
  isCreatingEvent  : (state): boolean => state.isCreatingEvent,
  isUpdatingEvent  : (state): boolean => state.isUpdatingEvent,
  isDeletingEvent  : (state): boolean => state.isDeletingEvent,
  requestStateEvent: (state): IRequestState => state.requestStateEvent,
}



