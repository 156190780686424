import { ICreditOptionDefaultState } from "./states";
import { Module } from "vuex";
import { CreditOptionRequestDto } from "@/../Api";
import RootState from "@/store/modules/rootState";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

const state: ICreditOptionDefaultState = {
  creditOptionDefault: <CreditOptionRequestDto>{},
  creditOptionDefaultForm: <CreditOptionRequestDto>{},
  isLoadingCreditOptionDefault: false,
  requestStateCreditOptionDefault: <IRequestState>{},
};

export const CreditOptionDefaultModule: Module<
  ICreditOptionDefaultState,
  RootState
> = {
  state,
  getters,
  mutations,
  actions,
};
