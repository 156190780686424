import { IReceiptFormOptionDefaultState } from "./states";
import { Module } from "vuex";
import { ReceiptFormOptionRequestDto } from "@/../Api";
import RootState from "@/store/modules/rootState";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

const state: IReceiptFormOptionDefaultState = {
  receiptFormOptionDefault: <ReceiptFormOptionRequestDto>{},
  receiptFormOptionDefaultForm: <ReceiptFormOptionRequestDto>{},
  isLoadingReceiptFormOptionDefault: false,
  requestStateReceiptFormOptionDefault: <IRequestState>{},
};

export const ReceiptFormOptionDefaultModule: Module<
  IReceiptFormOptionDefaultState,
  RootState
> = {
  state,
  getters,
  mutations,
  actions,
};
