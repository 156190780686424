import { IDegressiveSaleRateState } from "./states"
import { Module } from "vuex"
import { DegressiveSalesRatesRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state: IDegressiveSaleRateState = {
    degressiveSaleRatesList                     : Array<DegressiveSalesRatesRequestDto>(),
    degressiveSaleRate                          : <DegressiveSalesRatesRequestDto>{},
    isLoadingDegressiveSaleRatesList            : false,
    isLoadingDegressiveSaleRate                 : false,
    isCreatingDegressiveSaleRate                : false,
    isUpdatingDegressiveSaleRate                : false,
    isDeletingDegressiveSaleRate                : false,
    requestStateDegressiveSaleRate              : <IRequestState>{},
}

export const DegressiveSaleRateModule: Module<IDegressiveSaleRateState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}