import store from "@/store";
import {
  calculateTotalDocumentLine,
  calculateTotalOrderFormCustomerLine,
  getNewIndex,
} from "@/types/api-orisis/library/DetailDocumentOperations";
import { formatNumber } from "@/types/api-orisis/library/FormatOperations";
import { CommonLine } from "@/types/api-orisis/models/LineModels";

export function calcNetToPay(priceTotalNet: number, priceTotalTtc: number) {
  let deduction = store.getters.deduction;
  let deductionNumber = 0;
  let result = 0;
  deductionNumber = deducePunctualDeductions(
    deductionNumber,
    deduction.punctualDeductions
  );
  deductionNumber = deduceHoldback(deductionNumber, deduction.holdbackNumber);
  deductionNumber = addProrataAccounts(
    priceTotalNet,
    deductionNumber,
    deduction
  );
  result = priceTotalTtc - deductionNumber;
  return result;
}

export function calculateSubTotalLineBySection(line: any): void {
  let documentDetails = store.getters.getDocumentDetails;
  let subTotal = 0;
  let i = documentDetails.findIndex((el) => el.id == line.id);
  let j = 0;
  let startIndex = documentDetails.findLastIndex(
    (el, index) => el.type == 13 && index < i
  );

  let endIndex =
    line.type == 13
      ? i
      : documentDetails.findIndex((el, index) => el.type == 13 && index > i);
  let calculateOption = false;

  if (startIndex < 0) {
    startIndex = j = 0;
  } else {
    j = startIndex + 1;
  }
  if (endIndex >= 0) {
    let end = documentDetails[endIndex];
    calculateOption = end.isOption;

    while (j < endIndex) {
      if (
        documentDetails[j].type != 0 &&
        documentDetails[j].type != 1 &&
        documentDetails[j].type != 2 &&
        documentDetails[j].type != 11 &&
        documentDetails[j].type != 12 &&
        documentDetails[j].type != 13 &&
        documentDetails[j].isOption == calculateOption
      ) {
        // subTotal += calculateTotalDocumentLine(documentDetails[j]);
        subTotal += documentDetails[j].total;
      }
      j++;
    }
    end.total = end.unitPriceHt = Math.round(subTotal * 100) / 100;

    store.dispatch("updateDocumentDetail", { documentDetail: end });
  }
}

export function calculateSubTotalOrderFormCustomerLineBySection(
  line: any
): void {
  let orderFormCustomerDetails = store.getters.getOrderFormCustomerDetails;
  let subTotal = 0;
  let i = orderFormCustomerDetails.findIndex((el) => el.id == line.id);
  let j = 0;
  let startIndex = orderFormCustomerDetails.findIndex(
    (el, index) => el.type == 13 && index < i
  );
  let endIndex =
    line.type == 13
      ? i
      : orderFormCustomerDetails.findIndex(
          (el, index) => el.type == 13 && index > i
        );
  let calculateOption = false;

  if (startIndex < 0) {
    startIndex = j = 0;
  } else {
    j = startIndex + 1;
  }
  if (endIndex >= 0) {
    let end = orderFormCustomerDetails[endIndex];
    calculateOption = end.isOption;

    while (j < endIndex) {
      if (
        orderFormCustomerDetails[j].type != 0 &&
        orderFormCustomerDetails[j].type != 1 &&
        orderFormCustomerDetails[j].type != 2 &&
        orderFormCustomerDetails[j].type != 11 &&
        orderFormCustomerDetails[j].type != 12 &&
        orderFormCustomerDetails[j].type != 13 &&
        orderFormCustomerDetails[j].isOption == calculateOption
      ) {
        subTotal += calculateTotalOrderFormCustomerLine(
          orderFormCustomerDetails[j]
        );
      }
      j++;
    }
    end.total = end.unitPriceHt = Math.round(subTotal * 100) / 100;
    store.dispatch("updateOrderFormCustomerDetail", {
      orderFormCustomerDetail: end,
    });
  }
}

export function calculateSubTotalLine(
  line: any,
  calculateOption: boolean,
  newLine: boolean
): void {
  let documentDetails = store.getters.getDocumentDetails;
  let subTotal = 0;
  let i = line.indexArray;
  while (
    i >= 0 &&
    (documentDetails[i].type != 13 ||
      documentDetails[i].isOption != calculateOption)
  ) {
    if (
      documentDetails[i].type != 0 &&
      documentDetails[i].type != 1 &&
      documentDetails[i].type != 2 &&
      documentDetails[i].type != 11 &&
      documentDetails[i].type != 12 &&
      documentDetails[i].type != 13 &&
      documentDetails[i].isOption == calculateOption
    ) {
      subTotal += calculateTotalDocumentLine(documentDetails[i]);
    }
    i--;
    if (i < 0) break;
  }
  line.total = line.unitPriceHt = Math.round(subTotal * 100) / 100;
  if (newLine) {
    getNewIndex(store.getters.getDocumentDetails, line).then(
      async (res: any) => {
        let newObject = CommonLine({
          ...line,
          index: res.index,
          type: res.type,
        });
        await store.dispatch("createDocumentDetail", {
          documentDetail: newObject,
        });
      }
    );
  } else {
    store.dispatch("updateDocumentDetail", { documentDetail: line });
  }
}

export function calculateSubTotalOrderFormCustomerLine(
  line: any,
  calculateOption: boolean,
  newLine: boolean
): void {
  let orderFormCustomerDetails = store.getters.getOrderFormCustomerDetails;
  let subTotal = 0;
  let i = line.indexArray;
  while (
    i >= 0 &&
    (orderFormCustomerDetails[i].type != 13 ||
      orderFormCustomerDetails[i].isOption != calculateOption)
  ) {
    if (
      orderFormCustomerDetails[i].type != 0 &&
      orderFormCustomerDetails[i].type != 1 &&
      orderFormCustomerDetails[i].type != 2 &&
      orderFormCustomerDetails[i].type != 11 &&
      orderFormCustomerDetails[i].type != 12 &&
      orderFormCustomerDetails[i].type != 13 &&
      orderFormCustomerDetails[i].isOption == calculateOption
    ) {
      subTotal += calculateTotalOrderFormCustomerLine(
        orderFormCustomerDetails[i]
      );
    }
    i--;
    if (i < 0) break;
  }
  line.total = line.unitPriceHt = Math.round(subTotal * 100) / 100;
  if (newLine) {
    getNewIndex(store.getters.getOrderFormCustomerDetails, line).then(
      async (res: any) => {
        let newObject = CommonLine({
          ...line,
          index: res.index,
          type: res.type,
        });
        await store.dispatch("createOrderFormCustomerDetail", {
          orderFormCustomerDetail: newObject,
        });
      }
    );
  } else {
    store.dispatch("updateOrderFormCustomerDetail", {
      orderFormCustomerDetail: line,
    });
  }
}

export function calculateSubTotalLines(update: boolean): void {
  let documentDetails = store.getters.getDocumentDetails;
  let subTotalLines = documentDetails.filter((el) => el.type == 13);
  if (subTotalLines.length > 0) {
    for (let j = 0; j < subTotalLines.length; j++) {
      let subTotal = 0;
      const line = subTotalLines[j];
      let i = documentDetails.findIndex((el) => el.id == line.id) - 1;
      while (
        i >= 0 &&
        (documentDetails[i].type !== 13 ||
          documentDetails[i].isOption != line.isOption)
      ) {
        if (
          documentDetails[i].type != 0 &&
          documentDetails[i].type != 1 &&
          documentDetails[i].type != 2 &&
          documentDetails[i].type != 11 &&
          documentDetails[i].type != 12 &&
          documentDetails[i].type != 13 &&
          documentDetails[i].type != 14 &&
          documentDetails[i].type != 15 &&
          documentDetails[i].type != 16 &&
          documentDetails[i].type != 17 &&
          documentDetails[i].type != 18 &&
          documentDetails[i].isOption == line.isOption
        ) {
          subTotal += calculateTotalDocumentLine(documentDetails[i]);
        }
        i--;
        if (i < 0) break;
      }
      documentDetails[
        documentDetails.findIndex((el) => el.id == line.id)
      ].quantity = 1;
      documentDetails[
        documentDetails.findIndex((el) => el.id == line.id)
      ].total = documentDetails[
        documentDetails.findIndex((el) => el.id == line.id)
      ].unitPriceHt = Math.round(subTotal * 100) / 100;
      if (update) {
        store.dispatch("updateDocumentDetail", {
          documentDetail:
            documentDetails[
              documentDetails.findIndex((el) => el.id == line.id)
            ],
        });
      }
    }
  }
}

export function calcTotalMarginBrutHt(
  discountAmount: number,
  nature: number
): number {
  let marginBrutHt = calcTotalMarginHt(discountAmount, nature);
  return marginBrutHt;
}

export function calcTotalMarginHt(
  discountAmount: number,
  nature: number
): number {
  let marginBrutHt = 0;
  let details =
    nature == 7
      ? store.getters.getOrderFormCustomerDetails
      : store.getters.getDocumentDetails;
  let linesForCacul = details.filter(
    (el) =>
      el.type != 0 &&
      el.type != 1 &&
      el.type != 2 &&
      el.type != 11 &&
      el.type != 12 &&
      el.type != 13 &&
      el.type != 14 &&
      el.type != 15 &&
      el.type != 16 &&
      el.type != 17 &&
      el.type != 18 &&
      !el.isOption
  );
  for (let i = 0; i < linesForCacul.length; i++) {
    const line = linesForCacul[i];
    if (line.marginBrutHt) {
      marginBrutHt = marginBrutHt + line.marginBrutHt * line.quantity;
    }
  }
  let options = null;
  switch (nature) {
    case 0:
      options = store.getters.quoteOption;
      break;
    case 1:
    case 2:
    case 3:
    case 4:
      options = store.getters.invoiceOption;
    case 5:
    case 6:
      options = store.getters.creditOption;
    case 7:
      options = store.getters.orderFormCustomerOption;
    default:
      break;
  }
  if (options && options.showDiscount) {
    return Math.round((marginBrutHt - discountAmount) * 100) / 100;
  }
  return Math.round(marginBrutHt * 100) / 100;
}

export function getReferencielTvaValueById(id: number): number {
  let result = 0;
  store.getters.institutionSettingsActive.referencielTvas.find((item: any) => {
    if (item.referencielTva.id == id) {
      result = item.referencielTva.value;
    }
  });
  return result;
}

function deducePunctualDeductions(
  result: number,
  punctualDeductions: any
): number {
  if (punctualDeductions && punctualDeductions.length > 0) {
    punctualDeductions.forEach((item: any) => {
      result -= parseFloat(item.number);
    });
  } else {
    result = result;
  }
  return result;
}

function deduceHoldback(result: number, holdBackNumber: any): number {
  if (holdBackNumber) {
    result += parseFloat(holdBackNumber);
  } else {
    result = result;
  }
  return result;
}

function addProrataAccounts(
  priceTotalNet: number,
  result: number,
  deductions: any
): number {
  if (
    deductions.prorataAccountPrice != null &&
    deductions.prorataAccountPercent != null &&
    priceTotalNet != 0
  ) {
    result += deductions.prorataAccountPrice;
  } else {
    result = result;
  }
  return result;
}

export function getPricesByPriceHt(
  initialDocument,
  newDownPaymentInvoiceForm,
  route
) {
  let document = JSON.parse(JSON.stringify(initialDocument));
  let arr =
    route == "invoices" && initialDocument.initialDocumentNature == "quote"
      ? document.documentDetails
      : route == "invoices" &&
        initialDocument.initialDocumentNature == "order-form-customer"
      ? document.orderFormCustomerDetails
      : initialDocument.initialDocumentNature == "order-form-customer"
      ? store.getters.getOrderFormCustomerDetails
      : store.getters.getDocumentDetails;
  let result = {
    totalHt: 0,
    totalTtc: 0,
  };

  const res = arr.reduce((acc: any, obj: any) => {
    let findIndex = acc.findIndex(
      (el: any) => el.referencielTvaId === obj.referencielTvaId
    );
    if (
      obj.type != 0 &&
      obj.type != 1 &&
      obj.type != 2 &&
      obj.type != 11 &&
      obj.type != 12 &&
      obj.type != 13 &&
      obj.type != 14 &&
      obj.type != 15 &&
      obj.type != 16 &&
      obj.type != 17 &&
      obj.type != 18 &&
      !obj.isOption
    ) {
      let priceHt = getPriceHt(obj, document);
      let priceTtc =
        priceHt * (1 + getReferencielTvaValueById(obj.referencielTvaId) / 100);
      let coeff = priceHt / document.totalHt;
      let advancementHt = newDownPaymentInvoiceForm.value * coeff;
      let advancementTtc =
        advancementHt *
        (1 + getReferencielTvaValueById(obj.referencielTvaId) / 100);

      if (findIndex < 0) {
        acc.push({
          priceHt: priceHt,
          priceTtc: priceTtc,
          coeff: coeff,
          advancementHt: advancementHt,
          advancementTtc: advancementTtc,
          referencielTvaId: obj.referencielTvaId,
        });
      } else {
        acc[findIndex].priceHt += priceHt;
        acc[findIndex].priceTtc += priceTtc;
        acc[findIndex].coeff = acc[findIndex].priceHt / document.totalHt;
        acc[findIndex].advancementHt =
          newDownPaymentInvoiceForm.value * acc[findIndex].coeff;
        acc[findIndex].advancementTtc =
          acc[findIndex].advancementHt *
          (1 + getReferencielTvaValueById(obj.referencielTvaId) / 100);
      }
    }
    return acc;
  }, []);
  res.forEach((element) => {
    result.totalHt =
      Math.round((result.totalHt + element.advancementHt) * 100) / 100;
    result.totalTtc =
      Math.round((result.totalTtc + element.advancementTtc) * 100) / 100;
  });
  return { tvaLine: res, totals: result };
}

export function getPricesByPriceTtc(
  documentParams,
  newDownPaymentInvoiceForm,
  route,
  deductionInvoicesPrice,
  initialDocumentParams
) {
  let document = JSON.parse(JSON.stringify(documentParams));
  let initialDocument = JSON.parse(JSON.stringify(initialDocumentParams));
  let arr =
    route == "invoices" && document.initialDocumentNature == "quote"
      ? document.documentDetails
      : route == "invoices" &&
        document.initialDocumentNature == "order-form-customer"
      ? document.orderFormCustomerDetails
      : document.initialDocumentNature == "order-form-customer"
      ? store.getters.getOrderFormCustomerDetails
      : store.getters.getDocumentDetails;
  let result = {
    totalHt: 0,
    totalTtc: 0,
  };

  let arrayReview = document?.deduction?.reviews.map((el) => {
    return {
      type: "review",
      unitPriceHt: el.number,
      quantity: 1,
      discount: 0,
      referencielTvaId: el.referencielTvaId,
    };
  });

  if (arrayReview?.length > 0) {
    arr = arr.concat(arrayReview);
  }

  const res = arr.reduce((acc: any, obj: any) => {
    let findIndex = acc.findIndex(
      (el: any) => el.referencielTvaId === obj.referencielTvaId
    );
    if (
      obj.type != 0 &&
      obj.type != 1 &&
      obj.type != 2 &&
      obj.type != 11 &&
      obj.type != 12 &&
      obj.type != 13 &&
      obj.type != 14 &&
      obj.type != 15 &&
      obj.type != 16 &&
      obj.type != 17 &&
      obj.type != 18 &&
      !obj.isOption
    ) {
      let priceHt = getPriceHt(obj, document);
      let priceTtc =
        priceHt * (1 + getReferencielTvaValueById(obj.referencielTvaId) / 100);
      let coeff = priceTtc / document.totalTtc;
      let advancementTtc = newDownPaymentInvoiceForm.value * coeff;
      let advancementHt =
        (advancementTtc * 100) /
        (100 + getReferencielTvaValueById(obj.referencielTvaId));

      if (findIndex < 0) {
        acc.push({
          priceHt: priceHt,
          priceTtc: priceTtc,
          coeff: coeff,
          advancementHt: advancementHt,
          advancementTtc: advancementTtc,
          referencielTvaId: obj.referencielTvaId,
        });
      } else {
        acc[findIndex].priceHt += priceHt;
        acc[findIndex].priceTtc += priceTtc;
        acc[findIndex].coeff = acc[findIndex].priceTtc / document.totalTtc;
        acc[findIndex].advancementTtc =
          newDownPaymentInvoiceForm.value * acc[findIndex].coeff;
        acc[findIndex].advancementHt =
          (acc[findIndex].advancementTtc * 100) /
          (100 + getReferencielTvaValueById(obj.referencielTvaId));
      }
    }
    return acc;
  }, []);
  if (
    (document.nature == 1 || document.nature == 3 || document.nature == 4) &&
    initialDocument?.invoices?.some((el) => el.nature == 2 && el.status != 5)
  ) {
    initialDocument.invoices
      .filter((el) => el.nature == 2 && el.status != 5)
      .forEach((el) => {
        el.tvas.forEach((tva) => {
          let findIndex = res.findIndex(
            (el: any) => el.referencielTvaId === tva.referencielTvaId
          );
          if (findIndex >= 0) {
            res[findIndex].advancementHt -= tva.totalHt;
            res[findIndex].advancementTtc = tva.totalHt + tva.amount;
          }
        });
      });
  }

  res.forEach((element) => {
    result.totalHt =
      Math.round((result.totalHt + element.advancementHt) * 100) / 100;
    result.totalTtc =
      Math.round((result.totalTtc + element.advancementTtc) * 100) / 100;
  });
  return { tvaLine: res, totals: result };
}

export function getPricesByPourcentagePriceTtc(
  initialDocument,
  newDownPaymentInvoiceForm,
  route
) {
  let document = JSON.parse(JSON.stringify(initialDocument));
  let arr =
    route == "invoices" && initialDocument.initialDocumentNature == "quote"
      ? document.documentDetails
      : route == "invoices" &&
        document.initialDocumentNature == "order-form-customer"
      ? document.orderFormCustomerDetails
      : document.initialDocumentNature == "order-form-customer"
      ? store.getters.getOrderFormCustomerDetails
      : store.getters.getDocumentDetails;
  let result = {
    totalHt: 0,
    totalTtc: 0,
  };
  let value =
    route == "credit"
      ? 100 - newDownPaymentInvoiceForm.value
      : newDownPaymentInvoiceForm.value;
  const res = arr.reduce((acc: any, obj: any) => {
    let findIndex = acc.findIndex(
      (el: any) => el.referencielTvaId === obj.referencielTvaId
    );
    if (
      obj.type != 0 &&
      obj.type != 1 &&
      obj.type != 2 &&
      obj.type != 11 &&
      obj.type != 12 &&
      obj.type != 13 &&
      obj.type != 14 &&
      obj.type != 15 &&
      obj.type != 16 &&
      obj.type != 17 &&
      obj.type != 18 &&
      !obj.isOption
    ) {
      let priceHt = getPriceHt(obj, document);
      let priceTtc =
        Math.round(
          priceHt *
            (1 + getReferencielTvaValueById(obj.referencielTvaId) / 100) *
            100
        ) / 100;
      let coeff = priceTtc / Number(document.totalTtc);
      if (findIndex < 0) {
        acc.push({
          priceHt: priceHt,
          priceTtc: priceTtc,
          coeff: coeff,
          referencielTvaId: obj.referencielTvaId,
        });
      } else {
        acc[findIndex].priceHt += priceHt;
        acc[findIndex].priceTtc += priceTtc;
        acc[findIndex].coeff = acc[findIndex].priceTtc / document.totalTtc;
      }
    }
    return acc;
  }, []);
  res.forEach((element) => {
    element.advancementHt =
      Math.round(element.priceHt * (value / 100) * 100) / 100;
    element.advancementTtc =
      Math.round(element.priceTtc * (value / 100) * 100) / 100;
    result.totalHt =
      Math.round((result.totalHt + element.advancementHt) * 100) / 100;
    result.totalTtc =
      Math.round((result.totalTtc + element.advancementTtc) * 100) / 100;
  });
  return { tvaLine: res, totals: result };
}

export function getPricesByPourcentagePriceTtcByCredit(
  initialDocument,
  newDownPaymentInvoiceForm,
  route,
  deductionInvoicesPrice = null
) {
  let document = JSON.parse(JSON.stringify(initialDocument));
  let totalHt: number = 0;
  let arr =
    route == "invoices"
      ? document.documentDetails
      : store.getters.getDocumentDetails;
  let result = {
    totalHt: 0,
    totalTtc: 0,
  };

  const res = arr.reduce((acc: any, obj: any) => {
    let findIndex = acc.findIndex(
      (el: any) => el.referencielTvaId === obj.referencielTvaId
    );
    if (
      obj.type != 0 &&
      obj.type != 1 &&
      obj.type != 2 &&
      obj.type != 11 &&
      obj.type != 12 &&
      obj.type != 13 &&
      !obj.isOption
    ) {
      let priceHt = getPriceHt(obj, document);
      let priceTtc =
        priceHt * (1 + getReferencielTvaValueById(obj.referencielTvaId) / 100);
      let coeff = priceTtc / Number(document.totalTtc);
      let value =
        route == "credit"
          ? 100 - newDownPaymentInvoiceForm.value
          : newDownPaymentInvoiceForm.value;
      let advancementHt =
        newDownPaymentInvoiceForm.value == 0 ? 0 : priceHt * (value / 100);
      let advancementTtc =
        advancementHt *
        (1 + getReferencielTvaValueById(obj.referencielTvaId) / 100);
      if (findIndex < 0) {
        acc.push({
          priceHt: priceHt,
          priceTtc: priceTtc,
          coeff: coeff,
          advancementHt: advancementHt,
          advancementTtc: advancementTtc,
          referencielTvaId: obj.referencielTvaId,
        });
      } else {
        acc[findIndex].priceHt += priceHt;
        acc[findIndex].priceTtc += priceTtc;
        acc[findIndex].coseff = acc[findIndex].priceTtc / document.totalTtc;
        acc[findIndex].advancementHt =
          newDownPaymentInvoiceForm.value == 0
            ? 0
            : acc[findIndex].priceHt * (newDownPaymentInvoiceForm.value / 100);
        acc[findIndex].advancementTtc =
          acc[findIndex].advancementHt *
          (1 + getReferencielTvaValueById(obj.referencielTvaId) / 100);
      }
    }
    return acc;
  }, []);
  if (deductionInvoicesPrice) {
    totalHt =
      Math.round(
        res.reduce((acc, obj) => {
          return (acc += obj.priceHt);
        }, 0) * 100
      ) / 100;
  }
  res.forEach((element) => {
    if (deductionInvoicesPrice) {
      let coeff = totalHt == 0 ? 1 : element.priceHt / totalHt;
      element.advancementHt =
        element.advancementHt - coeff * deductionInvoicesPrice.totalHt;
    }
    result.totalHt =
      Math.round((result.totalHt + element.advancementHt) * 100) / 100;
    result.totalTtc =
      Math.round((result.totalTtc + element.advancementTtc) * 100) / 100;
  });
  if (deductionInvoicesPrice) {
    result.totalTtc -= deductionInvoicesPrice.totalTtc;
  }
  return { tvaLine: res, totals: result };
}

function getPriceHt(obj, document) {
  let priceHt = 0;
  if (!document.situationType) {
    priceHt =
      Math.round(
        obj.unitPriceHt *
          obj.quantity *
          (document.nature == 4
            ? (obj.situationProgress - obj.previousSituationProgress) / 100
            : 1) *
          100
      ) / 100;
  } else {
    priceHt =
      Math.round(
        obj.unitPriceHt *
          (obj.situationProgressQuantity -
            obj.previousSituationProgressQuantity) *
          100
      ) / 100;
  }
  priceHt = formatNumber(priceHt * ((100 - obj.discount) / 100));

  if (document.discountGlobalType == 0) {
    let discountGlobalNumberHt =
      Math.round(
        priceHt *
          ((document.discountGlobalNumber ? document.discountGlobalNumber : 0) /
            100) *
          100
      ) / 100;
    let discount = Number(discountGlobalNumberHt);
    return priceHt - discount;
  } else if (document.discountGlobalType == 1) {
    let coeff =
      priceHt /
      (Number(document.totalHt) + Number(document.discountGlobalNumber));
    let discount =
      Math.round(Number(document.discountGlobalNumber) * coeff * 100) / 100;
    return priceHt - discount;
  }
  return priceHt;
}

export function getTotalDeduction() {
  let result = 0;
  let deduction = store.getters.deduction;
  let holdBackNumber = deduction.holdbackNumber
    ? Number(deduction.holdbackNumber)
    : 0;
  let prorataAccountPrice = deduction.prorataAccountPrice
    ? Number(deduction.prorataAccountPrice)
    : 0;
  let punctualDeductionPrice = deduction.punctualDeductions.reduce(
    (acc, obj) => {
      return (acc = Math.round((acc + obj.number) * 100) / 100);
    },
    0
  );
  return (result =
    holdBackNumber + prorataAccountPrice + punctualDeductionPrice);
}
