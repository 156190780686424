import {PaymentMethodRequestDto} from "Api";
import axios from "axios";
import {ActionTree} from "vuex";
import RootState from "@/store/modules/rootState";
import {PaymentMethodMutations} from "./mutations";
import {IPaymentMethodState} from "./states";

export const actions: ActionTree<IPaymentMethodState, RootState> = {
  async getPaymentMethodByWorkspaceId(
    {commit, rootGetters},
    {updateState = true}: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(PaymentMethodMutations.SET_IS_LOADING_PAYMENT_METHOD, true);
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
          "Sale/PaymentMethod/GetPaymentMethodByWorkspaceId",
          {
            params: {
              workspaceId: rootGetters.workspaceSelected.id,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(PaymentMethodMutations.SET_PAYMENT_METHODS_LIST, res.data);
            commit(PaymentMethodMutations.SET_IS_LOADING_PAYMENT_METHOD, false);
          }
          commit(PaymentMethodMutations.SET_REQUEST_STATE_PAYMENT_METHOD, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PaymentMethodMutations.SET_REQUEST_STATE_PAYMENT_METHOD, err);
          commit(PaymentMethodMutations.SET_IS_LOADING_PAYMENT_METHOD, false);
          reject(err);
        });
    });
  },

  async getPaymentMethodById(
    {commit},
    {paymentMethodId = null, updateState = true}: { paymentMethodId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
        if (updateState) {
          commit(PaymentMethodMutations.SET_IS_LOADING_PAYMENT_METHOD, true);
        }
        await axios
          .get(
            process.env.VUE_APP_API_ORISIS +
            "Sale/PaymentMethod/GetPaymentMethodById",
            {
              params: {
                paymentMethodId: paymentMethodId,
              },
            }
          )
          .then((res) => {
            if (updateState) {
              commit(PaymentMethodMutations.SET_PAYMENT_METHOD, res.data);
              commit(PaymentMethodMutations.SET_IS_LOADING_PAYMENT_METHOD, false);
            }
            commit(PaymentMethodMutations.SET_REQUEST_STATE_PAYMENT_METHOD, res);
            resolve(res.data);
          })
          .catch((err) => {
            console.error(err);
            commit(PaymentMethodMutations.SET_REQUEST_STATE_PAYMENT_METHOD, err);
            commit(PaymentMethodMutations.SET_IS_LOADING_PAYMENT_METHOD, false);
            reject(err);
          });
      },
    );
  },

  async createPaymentMethod(
    {commit, rootGetters},
    {paymentMethod}: { paymentMethod: PaymentMethodRequestDto }
  ):
    Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(PaymentMethodMutations.SET_IS_CREATING_PAYMENT_METHOD, true);
      await axios
        .post(
          process.env.VUE_APP_API_ORISIS +
          "Sale/PaymentMethod/CreatePaymentMethod",
          paymentMethod,
        )
        .then((res) => {
          rootGetters.paymentMethodsList.unshift(res.data.data);
          commit(
            PaymentMethodMutations.SET_PAYMENT_METHODS_LIST,
            rootGetters.PaymentMethodsList
          );
          commit(PaymentMethodMutations.SET_REQUEST_STATE_PAYMENT_METHOD, res);
          commit(PaymentMethodMutations.SET_IS_CREATING_PAYMENT_METHOD, false);
          resolve(res.data.data)
        })
        .catch((err) => {
          console.error(err);
          commit(PaymentMethodMutations.SET_REQUEST_STATE_PAYMENT_METHOD, err);
          commit(PaymentMethodMutations.SET_IS_CREATING_PAYMENT_METHOD, false);
          reject(err);
        });
    });
  },

  async updatePaymentMethod(
    {commit, rootGetters},
    {paymentMethod}: { paymentMethod: PaymentMethodRequestDto }
  ):
    Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(PaymentMethodMutations.SET_IS_UPDATING_PAYMENT_METHOD, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS +
          "Sale/PaymentMethod/UpdatePaymentMethod", paymentMethod
        )
        .then((res) => {
          rootGetters.PaymentMethodsList.splice(
            rootGetters.PaymentMethodsList.findIndex(
              (elem) => elem.id == paymentMethod.id
            ),
            1,
            paymentMethod
          );
          commit(
            PaymentMethodMutations.SET_PAYMENT_METHODS_LIST,
            rootGetters.PaymentMethodsList
          );
          commit(PaymentMethodMutations.SET_REQUEST_STATE_PAYMENT_METHOD, res);
          commit(PaymentMethodMutations.SET_IS_UPDATING_PAYMENT_METHOD, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PaymentMethodMutations.SET_REQUEST_STATE_PAYMENT_METHOD, err);
          commit(PaymentMethodMutations.SET_IS_UPDATING_PAYMENT_METHOD, false);
          reject(err);
        });
    });
  },

  async restorePaymentMethod(
    {commit, rootGetters},
    {paymentMethodIds = []}: { paymentMethodIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(PaymentMethodMutations.SET_IS_UPDATING_PAYMENT_METHOD, true
      )
      ;
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Sale/PaymentMethod/Restore",
          paymentMethodIds
        )
        .then((res) => {
          paymentMethodIds.forEach((paymentMethodId) => {
            rootGetters.paymentMethodsList.unshift(
              rootGetters.paymentMethodsListArchived.find(
                (elem) => elem.id == paymentMethodId
              )
            );
          });
          commit(PaymentMethodMutations.SET_REQUEST_STATE_PAYMENT_METHOD, res);
          commit(PaymentMethodMutations.SET_IS_UPDATING_PAYMENT_METHOD, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PaymentMethodMutations.SET_REQUEST_STATE_PAYMENT_METHOD, err);
          commit(PaymentMethodMutations.SET_IS_UPDATING_PAYMENT_METHOD, false);
          reject(err);
        });
    });
  },

  async archivePaymentMethod(
    {commit, rootGetters},
    { paymentMethodIds = [] }: { paymentMethodIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(PaymentMethodMutations.SET_IS_ARCHIVING_PAYMENT_METHOD, true);
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Sale/PaymentMethod/Archive", {
            data: paymentMethodIds,
          }
        )
        .then((res) => {
          paymentMethodIds.forEach((paymentMethodId) => {
            rootGetters.paymentMethodsList.splice(
              rootGetters.paymentMethodsList.findIndex(
                (elem) => elem.id == paymentMethodId
              ),
              1
            );
          });
          commit(
            PaymentMethodMutations.SET_PAYMENT_METHODS_LIST,
            rootGetters.paymentMethodsList
          );
          commit(PaymentMethodMutations.SET_REQUEST_STATE_PAYMENT_METHOD, res);
          commit(PaymentMethodMutations.SET_IS_ARCHIVING_PAYMENT_METHOD, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PaymentMethodMutations.SET_REQUEST_STATE_PAYMENT_METHOD, err);
          commit(PaymentMethodMutations.SET_IS_ARCHIVING_PAYMENT_METHOD, false);
          reject(err);
        });
    });
  },

  async deletePaymentMethod(
    {commit, rootGetters},
    {paymentMethodsIds = []}: { paymentMethodsIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(PaymentMethodMutations.SET_IS_DELETING_PAYMENT_METHOD, true);
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS +
          "Sale/PaymentMethod/Delete",
          {
            data: paymentMethodsIds,
          }
        )
        .then((res) => {
          paymentMethodsIds.forEach((paymentMethodId) => {
            rootGetters.PaymentMethodsList.splice(
              rootGetters.PaymentMethodsList.findIndex(
                (elem) => elem.id == paymentMethodId
              ),
              1
            );
          });
          commit(
            PaymentMethodMutations.SET_PAYMENT_METHODS_LIST,
            rootGetters.PaymentMethodsList
          );
          commit(PaymentMethodMutations.SET_REQUEST_STATE_PAYMENT_METHOD, res);
          commit(PaymentMethodMutations.SET_IS_DELETING_PAYMENT_METHOD, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PaymentMethodMutations.SET_REQUEST_STATE_PAYMENT_METHOD, err);
          commit(PaymentMethodMutations.SET_IS_DELETING_PAYMENT_METHOD, false);
          reject(err);
        });
    });
  },
};
