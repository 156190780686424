import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { ITag } from "@/types/api-orisis/interfaces/Settings/tag/ITag";
import { GetterTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { ITagState } from './states'


export const getters: GetterTree<ITagState, RootState> = {
  tagsList                                : (state): ITag[] => state.tagsList,
  tagsListArchived                        : (state): ITag[] => state.tagsListArchived,
  
  quoteTagsList                           : (state): ITag[] => state.quoteTagsList,
  orderFormCustomerTagsList               : (state): ITag[] => state.orderFormCustomerTagsList,
  companyTagsList                         : (state): ITag[] => state.companyTagsList,
  invoiceTagsList                         : (state): ITag[] => state.invoiceTagsList,
  creditTagsList                          : (state): ITag[] => state.creditTagsList,
  orderFormProviderTagsList               : (state): ITag[] => state.orderFormProviderTagsList,
  deliveryFormTagsList                    : (state): ITag[] => state.deliveryFormTagsList,
  receiptFormProviderTagsList             : (state): ITag[] => state.receiptFormProviderTagsList,
  invoiceProviderTagsList                 : (state): ITag[] => state.invoiceProviderTagsList,
  creditProviderTagsList                  : (state): ITag[] => state.creditProviderTagsList,
  affairTagsList                          : (state): ITag[] => state.affairTagsList,
  collaboratorTagsList                    : (state): ITag[] => state.collaboratorTagsList,
  contactTagsList                         : (state): ITag[] => state.contactTagsList,
  
  quoteTagsListSelected                   : (state): ITag[] => state.quoteTagsListSelected,
  orderFormCustomerTagsListSelected       : (state): ITag[] => state.orderFormCustomerTagsListSelected,
  companyTagsListSelected                 : (state): ITag[] => state.companyTagsListSelected,
  invoiceTagsListSelected                 : (state): ITag[] => state.invoiceTagsListSelected,
  creditTagsListSelected                  : (state): ITag[] => state.creditTagsListSelected,
  orderFormProviderTagsListSelected       : (state): ITag[] => state.orderFormProviderTagsListSelected,
  deliveryFormTagsListSelected            : (state): ITag[] => state.deliveryFormTagsListSelected,
  receiptFormProviderTagsListSelected     : (state): ITag[] => state.receiptFormProviderTagsListSelected,
  invoiceProviderTagsListSelected         : (state): ITag[] => state.invoiceProviderTagsListSelected,
  creditProviderTagsListSelected          : (state): ITag[] => state.creditProviderTagsListSelected,
  affairTagsListSelected                  : (state): ITag[] => state.affairTagsListSelected,
  collaboratorTagsListSelected            : (state): ITag[] => state.collaboratorTagsListSelected,
  contactTagsListSelected                 : (state): ITag[] => state.contactTagsListSelected,
  
  tag                                     : (state): ITag => state.tag,

  // COMMON
  isLoadingTagsList                       : (state): boolean => state.isLoadingTagsList,
  isLoadingTagsListArchived               : (state): boolean => state.isLoadingTagsListArchived,
  isLoadingTag                            : (state): boolean => state.isLoadingTag,
  isCreatingTag                           : (state): boolean => state.isCreatingTag,
  isUpdatingTag                           : (state): boolean => state.isUpdatingTag,
  isDeletingTag                           : (state): boolean => state.isDeletingTag,
  isArchivingTag                          : (state): boolean => state.isArchivingTag,
  requestStateTag                         : (state): IRequestState => state.requestStateTag,
}