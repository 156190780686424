import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IReceiptFormProviderHistoryState } from "./states";

export const getters: GetterTree<IReceiptFormProviderHistoryState, RootState> = {
  receiptFormProviderHistoriesList: (state): [] => state.receiptFormProviderHistoriesList,
  receiptFormProviderHistory: (state): {} => state.receiptFormProviderHistory,

  // COMMON
  isLoadingReceiptFormProviderHistoriesList: (state): boolean =>
    state.isLoadingReceiptFormProviderHistoriesList,
  isLoadingReceiptFormProviderHistory: (state): boolean =>
    state.isLoadingReceiptFormProviderHistory,
  requestStateReceiptFormProviderHistory: (state): IRequestState =>
    state.requestStateReceiptFormProviderHistory,
};
