import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import RootState from "@/store/modules/rootState"
import { IWasteCollectionPointTypeState } from "./states";
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

export const state: IWasteCollectionPointTypeState = {
    wasteCollectionPointTypesList                  : Array<any>(),
    wasteCollectionPointTypesListArchived          : Array<any>(),
    wasteCollectionPointType                       : <any>{},
    isLoadingWasteCollectionPointTypesList         : false,
    isLoadingWasteCollectionPointTypesListArchived : false,
    isLoadingWasteCollectionPointType              : false,
    isCreatingWasteCollectionPointType             : false,
    isUpdatingWasteCollectionPointType             : false,
    isDeletingWasteCollectionPointType             : false,
    requestStateWasteCollectionPointType           : <IRequestState>{},
    isArchivingWasteCollectionPointType            : false,
}

export const WasteCollectionPointTypeModule: Module<IWasteCollectionPointTypeState, RootState> = {
  state, 
  getters,
  mutations,
  actions
}