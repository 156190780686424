import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { ProductCategoryRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IProductCategoryState } from "./states";

export enum ProductCategoryMutations {
    SET_PRODUCT_CATEGORIES_LIST = "SET_PRODUCT_CATEGORIES_LIST",
    SET_PRODUCT_CATEGORIES_TREE_LIST = "SET_PRODUCT_CATEGORIES_TREE_LIST",
    SET_PRODUCT_CATEGORIES_LIST_ARCHIVED = "SET_PRODUCT_CATEGORIES_LIST_ARCHIVED",
    SET_PRODUCT_CATEGORY = "SET_PRODUCT_CATEGORY",

    // COMMON
    SET_IS_LOADING_PRODUCT_CATEGORIES_LIST = "SET_IS_LOADING_PRODUCT_CATEGORIES_LIST",
    SET_IS_LOADING_PRODUCT_CATEGORIES_TREE_LIST = "SET_IS_LOADING_PRODUCT_CATEGORIES_TREE_LIST",
    SET_IS_LOADING_PRODUCT_CATEGORIES_LIST_ARCHIVED = "SET_IS_LOADING_PRODUCT_CATEGORIES_LIST_ARCHIVED",
    SET_IS_LOADING_PRODUCT_CATEGORY = "SET_IS_LOADING_PRODUCT_CATEGORY",
    SET_IS_CREATING_PRODUCT_CATEGORY = "SET_IS_CREATING_PRODUCT_CATEGORY",
    SET_IS_UPDATING_PRODUCT_CATEGORY = "SET_IS_UPDATING_PRODUCT_CATEGORY",
    SET_IS_DELETING_PRODUCT_CATEGORY = "SET_IS_DELETING_PRODUCT_CATEGORY",
    SET_IS_ARCHIVING_PRODUCT_CATEGORY = "SET_IS_ARCHIVING_PRODUCT_CATEGORY",
    SET_REQUEST_STATE_PRODUCT_CATEGORY = "SET_REQUEST_STATE_PRODUCT_CATEGORY",
}

export const mutations: MutationTree<IProductCategoryState> = {
    [ProductCategoryMutations.SET_PRODUCT_CATEGORIES_LIST_ARCHIVED]: (state, payload: ProductCategoryRequestDto[]) => {
        state.productCategoriesListArchived = payload;
    }, 
    [ProductCategoryMutations.SET_PRODUCT_CATEGORIES_LIST]: (state, payload: ProductCategoryRequestDto[]) => {
        state.productCategoriesList = payload;
    },
    [ProductCategoryMutations.SET_PRODUCT_CATEGORIES_TREE_LIST]: (state, payload: any[]) => {
        state.productCategoriesTreeList = payload;
    },
    [ProductCategoryMutations.SET_PRODUCT_CATEGORY]: (state, payload: ProductCategoryRequestDto) => {
        state.productCategory = payload;
    },

    //COMMON
    [ProductCategoryMutations.SET_IS_LOADING_PRODUCT_CATEGORIES_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingProductCategoriesListArchived = payload;
    },
    [ProductCategoryMutations.SET_IS_LOADING_PRODUCT_CATEGORIES_TREE_LIST]: (state, payload: boolean) => {
        state.isLoadingProductCategoriesTreeList = payload;
    },
    [ProductCategoryMutations.SET_IS_LOADING_PRODUCT_CATEGORIES_LIST]: (state, payload: boolean) => {
        state.isLoadingProductCategoriesList = payload;
    },
    [ProductCategoryMutations.SET_IS_LOADING_PRODUCT_CATEGORY]: (state, payload: boolean) => {
        state.isLoadingProductCategory = payload;
    },
    [ProductCategoryMutations.SET_IS_CREATING_PRODUCT_CATEGORY]: (state, payload: boolean) => {
        state.isCreatingProductCategory = payload;
    },
    [ProductCategoryMutations.SET_IS_UPDATING_PRODUCT_CATEGORY]: (state, payload: boolean) => {
        state.isUpdatingProductCategory = payload;
    },
    [ProductCategoryMutations.SET_IS_DELETING_PRODUCT_CATEGORY]: (state, payload: boolean) => {
        state.isDeletingProductCategory = payload;
    },
    [ProductCategoryMutations.SET_IS_ARCHIVING_PRODUCT_CATEGORY]: (state, payload: boolean) => {
        state.isArchivingProductCategory = payload;
    },
    [ProductCategoryMutations.SET_REQUEST_STATE_PRODUCT_CATEGORY]: (state, payload: IRequestState) => {
        state.requestStateProductCategory = payload;
    },
}