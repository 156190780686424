import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { MissionRequestDto } from "@/../Api";
import { MutationTree } from "vuex";
import { IMissionState } from "./states";

export enum MissionMutations {
  SET_MISSIONS_LIST = "SET_MISSIONS_LIST",
  SET_MISSIONS_LIST_TEMPLATE = "SET_MISSIONS_LIST_TEMPLATE",
  SET_MISSIONS_LIST_ARCHIVED = "SET_MISSIONS_LIST_ARCHIVED",
  SET_MISSIONS_LIST_BY_AFFAIR_ID = "SET_MISSIONS_LIST_BY_AFFAIR_ID",
  SET_MISSION = "SET_MISSION",

  //FILTERS
  SET_MISSION_FILTERS = "SET_MISSION_FILTERS",
  SET_MISSION_FILTERS_BY_COLLABORATOR = "SET_MISSION_FILTERS_BY_COLLABORATOR",

  // COMMON
  SET_IS_LOADING_MISSION_LIST = "SET_IS_LOADING_MISSION_LIST",
  SET_IS_LOADING_MISSION_LIST_TEMPLATE = "SET_IS_LOADING_MISSION_LIST_TEMPLATE",
  SET_IS_LOADING_MISSION_LIST_ARCHIVED = "SET_IS_LOADING_MISSION_LIST_ARCHIVED",
  SET_IS_LOADING_MISSION_LIST_BY_AFFAIR_ID = "SET_IS_LOADING_MISSION_LIST_BY_AFFAIR_ID",
  SET_IS_LOADING_MISSION = "SET_IS_LOADING_MISSION",
  SET_IS_CREATING_MISSION = "SET_IS_CREATING_MISSION",
  SET_IS_UPDATING_MISSION = "SET_IS_UPDATING_MISSION",
  SET_IS_DELETING_MISSION = "SET_IS_DELETING_MISSION",
  SET_IS_ARCHIVING_MISSION = "SET_IS_ARCHIVING_MISSION",
  SET_REQUEST_STATE_MISSION = "SET_REQUEST_STATE_MISSION",
}

export const mutations: MutationTree<IMissionState> = {
  [MissionMutations.SET_MISSIONS_LIST_ARCHIVED]: (
    state,
    payload: MissionRequestDto[]
  ) => {
    state.missionsListArchived = payload;
  },
  [MissionMutations.SET_MISSIONS_LIST]: (
    state,
    payload: MissionRequestDto[]
  ) => {
    state.missionsList = payload;
  },
  [MissionMutations.SET_MISSIONS_LIST_TEMPLATE]: (
    state,
    payload: MissionRequestDto[]
  ) => {
    state.missionsListTemplate = payload.filter(
      (el: MissionRequestDto) => el.affairId == null
    );
  },
  [MissionMutations.SET_MISSIONS_LIST_BY_AFFAIR_ID]: (
    state,
    payload: MissionRequestDto[]
  ) => {
    state.missionsListByAffairId = payload;
  },
  [MissionMutations.SET_MISSION]: (state, payload: MissionRequestDto) => {
    state.mission = payload;
  },

  //FILTERS
  [MissionMutations.SET_MISSION_FILTERS]: (state, payload: any) => {
    state.missionFilters = payload;
  },
  [MissionMutations.SET_MISSION_FILTERS_BY_COLLABORATOR]: (
    state,
    payload: any
  ) => {
    state.missionFiltersByCollaborator = payload;
  },

  //COMMON
  [MissionMutations.SET_IS_LOADING_MISSION_LIST_BY_AFFAIR_ID]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingMissionsListByAffairId = payload;
  },
  [MissionMutations.SET_IS_LOADING_MISSION_LIST_ARCHIVED]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingMissionsListArchived = payload;
  },
  [MissionMutations.SET_IS_LOADING_MISSION_LIST]: (state, payload: boolean) => {
    state.isLoadingMissionsList = payload;
  },
  [MissionMutations.SET_IS_LOADING_MISSION_LIST_TEMPLATE]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingMissionsListTemplate = payload;
  },
  [MissionMutations.SET_IS_LOADING_MISSION]: (state, payload: boolean) => {
    state.isLoadingMission = payload;
  },
  [MissionMutations.SET_IS_CREATING_MISSION]: (state, payload: boolean) => {
    state.isCreatingMission = payload;
  },
  [MissionMutations.SET_IS_UPDATING_MISSION]: (state, payload: boolean) => {
    state.isUpdatingMission = payload;
  },
  [MissionMutations.SET_IS_DELETING_MISSION]: (state, payload: boolean) => {
    state.isDeletingMission = payload;
  },
  [MissionMutations.SET_IS_ARCHIVING_MISSION]: (state, payload: boolean) => {
    state.isArchivingMission = payload;
  },
  [MissionMutations.SET_REQUEST_STATE_MISSION]: (
    state,
    payload: IRequestState
  ) => {
    state.requestStateMission = payload;
  },
};
