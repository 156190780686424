import { IOrderFormCustomerHistoryState } from "./states";
import { MutationTree } from "vuex";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export enum OrderFormCustomerHistoryMutations {
  SET_ORDER_FORM_CUSTOMER_HISTORIES_LIST = "SET_ORDER_FORM_CUSTOMER_HISTORIES_LIST",
  SET_ORDER_FORM_CUSTOMER_HISTORY = "SET_ORDER_FORM_CUSTOMER_HISTORY",

  // COMON
  SET_IS_LOADING_ORDER_FORM_CUSTOMER_HISTORIES_LIST = "SET_IS_LOADING_ORDER_FORM_CUSTOMER_HISTORIES_LIST",
  SET_IS_LOADING_ORDER_FORM_CUSTOMER_HISTORY = "SET_IS_LOADING_ORDER_FORM_CUSTOMER_HISTORY",
  SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_HISTORY = "SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_HISTORY",
}

  export const mutations: MutationTree<IOrderFormCustomerHistoryState> = {
    [OrderFormCustomerHistoryMutations.SET_ORDER_FORM_CUSTOMER_HISTORIES_LIST]: (state, payload: []) => {
        state.orderFormCustomerHistoriesList = payload;
    },
    [OrderFormCustomerHistoryMutations.SET_ORDER_FORM_CUSTOMER_HISTORY]: (state, payload: {}) => {
        state.orderFormCustomerHistory = payload;
    },


    //COMMON
    [OrderFormCustomerHistoryMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_HISTORIES_LIST]: (state, payload: boolean) => {
        state.isLoadingOrderFormCustomerHistoriesList = payload;
    },
    [OrderFormCustomerHistoryMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_HISTORY]: (state, payload: boolean) => {
        state.isLoadingOrderFormCustomerHistory = payload;
    },
    [OrderFormCustomerHistoryMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_HISTORY]: (state, payload: IRequestState) => {
        state.requestStateOrderFormCustomerHistory = payload;
    },
}