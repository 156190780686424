import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IAccountState } from "./states";

export const getters: GetterTree<IAccountState, RootState> = {
  userInfo: (state): any => state.userInfo,
  userToken: (state): any => state.userToken,
  planningViewSelected: (state): any => state.planningViewSelected,
  isToastingNotification: (state): boolean => state.isToastingNotification,
  isOpenSidebarCampaign: (state): boolean => state.isOpenSidebarCampaign,
  isLoggin: (state): boolean => state.isLoggin,
  isLogginMsg: (state): string => state.isLogginMsg,
  requestStateAccount: (state): IRequestState => state.requestStateAccount,
};
