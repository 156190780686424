import { ITag } from "@/types/api-orisis/interfaces/Settings/tag/ITag";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { TagMutations } from "./mutations";
import { ITagState } from "./states";

export const actions: ActionTree<ITagState, RootState> = {
  // COMPANIES TAG
  async getCompanyTags(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/CompanyTag/GetCompanyTags`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_IS_LOADING_TAG_LIST, false);
            commit(TagMutations.SET_TAGS_LIST, res.data);
            commit(TagMutations.SET_COMPANY_TAGS_LIST, res.data);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_IS_LOADING_TAG_LIST, false);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          reject(err);
        });
    });
  },

  async getCompanyTagById(
    { commit },
    {
      companyTagId,
      updateState = true,
    }: { companyTagId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Directory/CompanyTag/GetCompanyTagById`,
          {
            params: {
              companyTagId: companyTagId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_TAG, res.data);
            commit(TagMutations.SET_IS_LOADING_TAG, false);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_LOADING_TAG, false);
          reject(err);
        });
    });
  },

  async getCompanyTagsArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/CompanyTag/GetCompanyTagsArchived`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, false);
            commit(TagMutations.SET_TAGS_LIST_ARCHIVED, res.data);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, false);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          reject(err);
        });
    });
  },

  async getCompanyTagArchivedById(
    { commit },
    {
      companyTagId,
      updateState = true,
    }: { companyTagId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Directory/CompanyTag/GetCompanyTagArchivedById`,
          {
            params: {
              companyTagId: companyTagId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_TAG, res.data);
            commit(TagMutations.SET_IS_LOADING_TAG, false);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_LOADING_TAG, false);
          reject(err);
        });
    });
  },

  async createCompanyTag(
    { commit, rootGetters },
    { tag }: { tag: ITag }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_CREATING_TAG, true);
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Directory/CompanyTag/CreateCompanyTag`,
          tag
        )
        .then((res) => {
          rootGetters.companyTagsList.unshift(res.data.data);
          commit(
            TagMutations.SET_COMPANY_TAGS_LIST,
            rootGetters.companyTagsList
          );
          commit(TagMutations.SET_TAGS_LIST, rootGetters.companyTagsList);
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_CREATING_TAG, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_CREATING_TAG, false);
          reject(err);
        });
    });
  },

  async updateCompanyTag({ commit, rootGetters }, { tag }: { tag: ITag }) {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_UPDATING_TAG, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Directory/CompanyTag/UpdateCompanyTag`,
          tag
        )
        .then((res) => {
          rootGetters.companyTagsList.splice(
            rootGetters.companyTagsList.findIndex((elem) => elem.id == tag.id),
            1,
            tag
          );
          commit(
            TagMutations.SET_COMPANY_TAGS_LIST,
            rootGetters.companyTagsList
          );
          commit(TagMutations.SET_TAGS_LIST, rootGetters.companyTagsList);
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          reject(err);
        });
    });
  },

  async restoreCompanyTags(
    { commit, rootGetters, dispatch },
    { companyTagIds = [] }: { companyTagIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_UPDATING_TAG, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Directory/CompanyTag/Restore",
          companyTagIds
        )
        .then(async (res) => {
          companyTagIds.forEach((companyTagId) => {
            rootGetters.tagsListArchived.splice(
              rootGetters.tagsListArchived.findIndex(
                (elem) => elem.id == companyTagId
              ),
              1
            );
          });
          await dispatch("getCompanyTags", {});
          await dispatch("getCompanyTagsArchived", {});
          commit(
            TagMutations.SET_TAGS_LIST_ARCHIVED,
            rootGetters.tagsListArchived
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          reject(err);
        });
    });
  },

  async archiveCompanyTags(
    { commit, rootGetters, dispatch },
    { companyTagIds = [] }: { companyTagIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_ARCHIVING_TAG, true);
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Directory/CompanyTag/Archive",
          {
            data: companyTagIds,
          }
        )
        .then(async (res) => {
          companyTagIds.forEach((companyTagId) => {
            rootGetters.tagsList.splice(
              rootGetters.tagsList.findIndex((elem) => elem.id == companyTagId),
              1
            );
          });
          await dispatch("getCompanyTags", {});
          await dispatch("getCompanyTagsArchived", {});
          commit(TagMutations.SET_TAGS_LIST, rootGetters.tagsList);
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_ARCHIVING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_ARCHIVING_TAG, false);
          reject(err);
        });
    });
  },

  async deleteCompanyTags(
    { commit, rootGetters },
    { companyTagIds = [] }: { companyTagIds: number[] }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_DELETING_TAG, true);
      await axios
        .delete(
          `${process.env.VUE_APP_API_ORISIS}Directory/CompanyTag/Delete`,
          {
            data: companyTagIds,
          }
        )
        .then((res) => {
          companyTagIds.forEach((companyTagId) => {
            rootGetters.tagsListArchived.splice(
              rootGetters.tagsListArchived.findIndex(
                (elem) => elem.id == companyTagId
              ),
              1
            );
          });
          commit(
            TagMutations.SET_TAGS_LIST_ARCHIVED,
            rootGetters.tagsListArchived
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_DELETING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_DELETING_TAG, false);
          reject(err);
        });
    });
  },

  // QUOTE TAG
  async getQuoteTags(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/QuoteTag/GetQuoteTags`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_IS_LOADING_TAG_LIST, false);
            commit(TagMutations.SET_TAGS_LIST, res.data);
            commit(TagMutations.SET_QUOTE_TAGS_LIST, res.data);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_IS_LOADING_TAG_LIST, false);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          reject(err);
        });
    });
  },

  async getQuoteTagById(
    { commit },
    {
      quoteTagId,
      updateState = true,
    }: { quoteTagId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/QuoteTag/GetQuoteTagById`,
          {
            params: {
              quoteTagId: quoteTagId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_TAG, res.data);
            commit(TagMutations.SET_IS_LOADING_TAG, false);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_LOADING_TAG, false);
          reject(err);
        });
    });
  },

  async getQuoteTagsArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/QuoteTag/GetQuoteTagsArchived`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, false);
            commit(TagMutations.SET_TAGS_LIST_ARCHIVED, res.data);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, false);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          reject(err);
        });
    });
  },

  async getQuoteTagArchivedById(
    { commit },
    {
      quoteTagId,
      updateState = true,
    }: { quoteTagId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/QuoteTag/GetQuoteTagArchivedById`,
          {
            params: {
              quoteTagId: quoteTagId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_TAG, res.data);
            commit(TagMutations.SET_IS_LOADING_TAG, false);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_LOADING_TAG, false);
          reject(err);
        });
    });
  },

  async createQuoteTag(
    { commit, rootGetters },
    { tag }: { tag: ITag }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_CREATING_TAG, true);
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Purchase/QuoteTag/CreateQuoteTag`,
          tag
        )
        .then((res) => {
          rootGetters.quoteTagsList.unshift(res.data.data);
          commit(TagMutations.SET_QUOTE_TAGS_LIST, rootGetters.quoteTagsList);
          commit(TagMutations.SET_TAGS_LIST, rootGetters.quoteTagsList);
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_CREATING_TAG, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_CREATING_TAG, false);
          reject(err);
        });
    });
  },

  async updateQuoteTag({ commit, rootGetters }, { tag }: { tag: ITag }) {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_UPDATING_TAG, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/QuoteTag/UpdateQuoteTag`,
          tag
        )
        .then((res) => {
          rootGetters.quoteTagsList.splice(
            rootGetters.quoteTagsList.findIndex((elem) => elem.id == tag.id),
            1,
            tag
          );
          commit(TagMutations.SET_QUOTE_TAGS_LIST, rootGetters.quoteTagsList);
          commit(TagMutations.SET_TAGS_LIST, rootGetters.quoteTagsList);
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          reject(err);
        });
    });
  },

  async restoreQuoteTags(
    { commit, rootGetters, dispatch },
    { quoteTagIds = [] }: { quoteTagIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_UPDATING_TAG, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Purchase/QuoteTag/Restore",
          quoteTagIds
        )
        .then(async (res) => {
          quoteTagIds.forEach((quoteTagId) => {
            rootGetters.tagsListArchived.splice(
              rootGetters.tagsListArchived.findIndex(
                (elem) => elem.id == quoteTagId
              ),
              1
            );
          });
          await dispatch("getQuoteTags", {});
          await dispatch("getQuoteTagsArchived", {});
          commit(
            TagMutations.SET_TAGS_LIST_ARCHIVED,
            rootGetters.tagsListArchived
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          reject(err);
        });
    });
  },

  async archiveQuoteTags(
    { commit, rootGetters, dispatch },
    { quoteTagIds = [] }: { quoteTagIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_ARCHIVING_TAG, true);
      await axios
        .delete(process.env.VUE_APP_API_ORISIS + "Purchase/QuoteTag/Archive", {
          data: quoteTagIds,
        })
        .then(async (res) => {
          quoteTagIds.forEach((quoteTagId) => {
            rootGetters.tagsList.splice(
              rootGetters.tagsList.findIndex((elem) => elem.id == quoteTagId),
              1
            );
          });
          await dispatch("getQuoteTags", {});
          await dispatch("getQuoteTagsArchived", {});
          commit(TagMutations.SET_TAGS_LIST, rootGetters.tagsList);
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_ARCHIVING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_ARCHIVING_TAG, false);
          reject(err);
        });
    });
  },

  async deleteQuoteTags(
    { commit, rootGetters },
    { quoteTagIds = [] }: { quoteTagIds: number[] }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_DELETING_TAG, true);
      await axios
        .delete(`${process.env.VUE_APP_API_ORISIS}Purchase/QuoteTag/Delete`, {
          data: quoteTagIds,
        })
        .then((res) => {
          quoteTagIds.forEach((quoteTagId) => {
            rootGetters.tagsListArchived.splice(
              rootGetters.tagsListArchived.findIndex(
                (elem) => elem.id == quoteTagId
              ),
              1
            );
          });
          commit(
            TagMutations.SET_TAGS_LIST_ARCHIVED,
            rootGetters.tagsListArchived
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_DELETING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_DELETING_TAG, false);
          reject(err);
        });
    });
  },

  // ORDER FORM CUSTOMER TAG
  async getOrderFormCustomerTags(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/OrderFormCustomerTag/GetOrderFormCustomerTags`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_IS_LOADING_TAG_LIST, false);
            commit(TagMutations.SET_TAGS_LIST, res.data);
            commit(TagMutations.SET_ORDER_FORM_CUSTOMER_TAGS_LIST, res.data);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_IS_LOADING_TAG_LIST, false);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          reject(err);
        });
    });
  },

  async getOrderFormCustomerTagById(
    { commit },
    {
      orderFormCustomerTagId,
      updateState = true,
    }: { orderFormCustomerTagId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/OrderFormCustomerTag/GetOrderFormCustomerTagById`,
          {
            params: {
              orderFormCustomerTagId: orderFormCustomerTagId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_TAG, res.data);
            commit(TagMutations.SET_IS_LOADING_TAG, false);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_LOADING_TAG, false);
          reject(err);
        });
    });
  },

  async getOrderFormCustomerTagsArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/OrderFormCustomerTag/GetOrderFormCustomerTagsArchived`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, false);
            commit(TagMutations.SET_TAGS_LIST_ARCHIVED, res.data);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, false);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          reject(err);
        });
    });
  },

  async getOrderFormCustomerTagArchivedById(
    { commit },
    {
      orderFormCustomerTagId,
      updateState = true,
    }: { orderFormCustomerTagId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/OrderFormCustomerTag/GetOrderFormCustomerTagArchivedById`,
          {
            params: {
              orderFormCustomerTagId: orderFormCustomerTagId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_TAG, res.data);
            commit(TagMutations.SET_IS_LOADING_TAG, false);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_LOADING_TAG, false);
          reject(err);
        });
    });
  },

  async createOrderFormCustomerTag(
    { commit, rootGetters },
    { tag }: { tag: ITag }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_CREATING_TAG, true);
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Purchase/OrderFormCustomerTag/CreateOrderFormCustomerTag`,
          tag
        )
        .then((res) => {
          rootGetters.orderFormCustomerTagsList.unshift(res.data.data);
          commit(
            TagMutations.SET_ORDER_FORM_CUSTOMER_TAGS_LIST,
            rootGetters.orderFormCustomerTagsList
          );
          commit(
            TagMutations.SET_TAGS_LIST,
            rootGetters.orderFormCustomerTagsList
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_CREATING_TAG, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_CREATING_TAG, false);
          reject(err);
        });
    });
  },

  async updateOrderFormCustomerTag(
    { commit, rootGetters },
    { tag }: { tag: ITag }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_UPDATING_TAG, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/OrderFormCustomerTag/UpdateOrderFormCustomerTag`,
          tag
        )
        .then((res) => {
          rootGetters.orderFormCustomerTagsList.splice(
            rootGetters.orderFormCustomerTagsList.findIndex(
              (elem) => elem.id == tag.id
            ),
            1,
            tag
          );
          commit(
            TagMutations.SET_ORDER_FORM_CUSTOMER_TAGS_LIST,
            rootGetters.orderFormCustomerTagsList
          );
          commit(
            TagMutations.SET_TAGS_LIST,
            rootGetters.orderFormCustomerTagsList
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          reject(err);
        });
    });
  },

  async restoreOrderFormCustomerTags(
    { commit, rootGetters, dispatch },
    { orderFormCustomerTagIds = [] }: { orderFormCustomerTagIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_UPDATING_TAG, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/OrderFormCustomerTag/Restore",
          orderFormCustomerTagIds
        )
        .then(async (res) => {
          orderFormCustomerTagIds.forEach((orderFormCustomerTagId) => {
            rootGetters.tagsListArchived.splice(
              rootGetters.tagsListArchived.findIndex(
                (elem) => elem.id == orderFormCustomerTagId
              ),
              1
            );
          });
          await dispatch("getOrderFormCustomerTags", {});
          await dispatch("getOrderFormCustomerTagsArchived", {});
          commit(
            TagMutations.SET_TAGS_LIST_ARCHIVED,
            rootGetters.tagsListArchived
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          reject(err);
        });
    });
  },

  async archiveOrderFormCustomerTags(
    { commit, rootGetters, dispatch },
    { orderFormCustomerTagIds = [] }: { orderFormCustomerTagIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_ARCHIVING_TAG, true);
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/OrderFormCustomerTag/Archive",
          {
            data: orderFormCustomerTagIds,
          }
        )
        .then(async (res) => {
          orderFormCustomerTagIds.forEach((orderFormCustomerTagId) => {
            rootGetters.tagsList.splice(
              rootGetters.tagsList.findIndex(
                (elem) => elem.id == orderFormCustomerTagId
              ),
              1
            );
          });
          await dispatch("getOrderFormCustomerTags", {});
          await dispatch("getOrderFormCustomerTagsArchived", {});
          commit(TagMutations.SET_TAGS_LIST, rootGetters.tagsList);
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_ARCHIVING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_ARCHIVING_TAG, false);
          reject(err);
        });
    });
  },

  async deleteOrderFormCustomerTags(
    { commit, rootGetters },
    { orderFormCustomerTagIds = [] }: { orderFormCustomerTagIds: number[] }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_DELETING_TAG, true);
      await axios
        .delete(
          `${process.env.VUE_APP_API_ORISIS}Purchase/OrderFormCustomerTag/Delete`,
          {
            data: orderFormCustomerTagIds,
          }
        )
        .then((res) => {
          orderFormCustomerTagIds.forEach((orderFormCustomerTagId) => {
            rootGetters.tagsListArchived.splice(
              rootGetters.tagsListArchived.findIndex(
                (elem) => elem.id == orderFormCustomerTagId
              ),
              1
            );
          });
          commit(
            TagMutations.SET_TAGS_LIST_ARCHIVED,
            rootGetters.tagsListArchived
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_DELETING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_DELETING_TAG, false);
          reject(err);
        });
    });
  },

  // INVOICE TAG
  async getInvoiceTags(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/InvoiceTag/GetInvoiceTags`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_IS_LOADING_TAG_LIST, false);
            commit(TagMutations.SET_TAGS_LIST, res.data);
            commit(TagMutations.SET_INVOICE_TAGS_LIST, res.data);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_IS_LOADING_TAG_LIST, false);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          reject(err);
        });
    });
  },

  async getInvoiceTagById(
    { commit },
    {
      invoiceTagId,
      updateState = true,
    }: { invoiceTagId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/InvoiceTag/GetInvoiceTagById`,
          {
            params: {
              invoiceTagId: invoiceTagId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_TAG, res.data);
            commit(TagMutations.SET_IS_LOADING_TAG, false);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_LOADING_TAG, false);
          reject(err);
        });
    });
  },

  async getInvoiceTagsArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/InvoiceTag/GetInvoiceTagsArchived`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, false);
            commit(TagMutations.SET_TAGS_LIST_ARCHIVED, res.data);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, false);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          reject(err);
        });
    });
  },

  async getInvoiceTagArchivedById(
    { commit },
    {
      invoiceTagId,
      updateState = true,
    }: { invoiceTagId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/InvoiceTag/GetInvoiceTagArchivedById`,
          {
            params: {
              invoiceTagId: invoiceTagId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_TAG, res.data);
            commit(TagMutations.SET_IS_LOADING_TAG, false);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_LOADING_TAG, false);
          reject(err);
        });
    });
  },

  async createInvoiceTag(
    { commit, rootGetters },
    { tag }: { tag: ITag }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_CREATING_TAG, true);
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Purchase/InvoiceTag/CreateInvoiceTag`,
          tag
        )
        .then((res) => {
          rootGetters.invoiceTagsList.unshift(res.data.data);
          commit(
            TagMutations.SET_INVOICE_TAGS_LIST,
            rootGetters.invoiceTagsList
          );
          commit(TagMutations.SET_TAGS_LIST, rootGetters.invoiceTagsList);
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_CREATING_TAG, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_CREATING_TAG, false);
          reject(err);
        });
    });
  },

  async updateInvoiceTag({ commit, rootGetters }, { tag }: { tag: ITag }) {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_UPDATING_TAG, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/InvoiceTag/UpdateInvoiceTag`,
          tag
        )
        .then((res) => {
          rootGetters.invoiceTagsList.splice(
            rootGetters.invoiceTagsList.findIndex((elem) => elem.id == tag.id),
            1,
            tag
          );
          commit(
            TagMutations.SET_INVOICE_TAGS_LIST,
            rootGetters.invoiceTagsList
          );
          commit(TagMutations.SET_TAGS_LIST, rootGetters.invoiceTagsList);
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          reject(err);
        });
    });
  },

  async restoreInvoiceTags(
    { commit, rootGetters, dispatch },
    { invoiceTagIds = [] }: { invoiceTagIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_UPDATING_TAG, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Purchase/InvoiceTag/Restore",
          invoiceTagIds
        )
        .then(async (res) => {
          invoiceTagIds.forEach((invoiceTagId) => {
            rootGetters.tagsListArchived.splice(
              rootGetters.tagsListArchived.findIndex(
                (elem) => elem.id == invoiceTagId
              ),
              1
            );
          });
          await dispatch("getInvoiceTags", {});
          await dispatch("getInvoiceTagsArchived", {});
          commit(
            TagMutations.SET_TAGS_LIST_ARCHIVED,
            rootGetters.tagsListArchived
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          reject(err);
        });
    });
  },

  async archiveInvoiceTags(
    { commit, rootGetters, dispatch },
    { invoiceTagIds = [] }: { invoiceTagIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_ARCHIVING_TAG, true);
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Purchase/InvoiceTag/Archive",
          {
            data: invoiceTagIds,
          }
        )
        .then(async (res) => {
          invoiceTagIds.forEach((invoiceTagId) => {
            rootGetters.tagsList.splice(
              rootGetters.tagsList.findIndex((elem) => elem.id == invoiceTagId),
              1
            );
          });
          await dispatch("getInvoiceTags", {});
          await dispatch("getInvoiceTagsArchived", {});
          commit(TagMutations.SET_TAGS_LIST, rootGetters.tagsList);
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_ARCHIVING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_ARCHIVING_TAG, false);
          reject(err);
        });
    });
  },

  async deleteInvoiceTags(
    { commit, rootGetters },
    { invoiceTagIds = [] }: { invoiceTagIds: number[] }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_DELETING_TAG, true);
      await axios
        .delete(`${process.env.VUE_APP_API_ORISIS}Purchase/InvoiceTag/Delete`, {
          data: invoiceTagIds,
        })
        .then((res) => {
          invoiceTagIds.forEach((invoiceTagId) => {
            rootGetters.tagsListArchived.splice(
              rootGetters.tagsListArchived.findIndex(
                (elem) => elem.id == invoiceTagId
              ),
              1
            );
          });
          commit(
            TagMutations.SET_TAGS_LIST_ARCHIVED,
            rootGetters.tagsListArchived
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_DELETING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_DELETING_TAG, false);
          reject(err);
        });
    });
  },

  // CREDIT TAG
  async getCreditTags(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/CreditTag/GetCreditTags`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_IS_LOADING_TAG_LIST, false);
            commit(TagMutations.SET_TAGS_LIST, res.data);
            commit(TagMutations.SET_CREDIT_TAGS_LIST, res.data);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_IS_LOADING_TAG_LIST, false);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          reject(err);
        });
    });
  },

  async getCreditTagById(
    { commit },
    {
      creditTagId,
      updateState = true,
    }: { creditTagId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/CreditTag/GetCreditTagById`,
          {
            params: {
              creditTagId: creditTagId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_TAG, res.data);
            commit(TagMutations.SET_IS_LOADING_TAG, false);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_LOADING_TAG, false);
          reject(err);
        });
    });
  },

  async getCreditTagsArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/CreditTag/GetCreditTagsArchived`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, false);
            commit(TagMutations.SET_TAGS_LIST_ARCHIVED, res.data);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, false);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          reject(err);
        });
    });
  },

  async getCreditTagArchivedById(
    { commit },
    {
      creditTagId,
      updateState = true,
    }: { creditTagId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/CreditTag/GetCreditTagArchivedById`,
          {
            params: {
              creditTagId: creditTagId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_TAG, res.data);
            commit(TagMutations.SET_IS_LOADING_TAG, false);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_LOADING_TAG, false);
          reject(err);
        });
    });
  },

  async createCreditTag(
    { commit, rootGetters },
    { tag }: { tag: ITag }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_CREATING_TAG, true);
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Purchase/CreditTag/CreateCreditTag`,
          tag
        )
        .then((res) => {
          rootGetters.creditTagsList.unshift(res.data.data);
          commit(TagMutations.SET_CREDIT_TAGS_LIST, rootGetters.creditTagsList);
          commit(TagMutations.SET_TAGS_LIST, rootGetters.creditTagsList);
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_CREATING_TAG, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_CREATING_TAG, false);
          reject(err);
        });
    });
  },

  async updateCreditTag({ commit, rootGetters }, { tag }: { tag: ITag }) {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_UPDATING_TAG, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/CreditTag/UpdateCreditTag`,
          tag
        )
        .then((res) => {
          rootGetters.creditTagsList.splice(
            rootGetters.creditTagsList.findIndex((elem) => elem.id == tag.id),
            1,
            tag
          );
          commit(TagMutations.SET_CREDIT_TAGS_LIST, rootGetters.creditTagsList);
          commit(TagMutations.SET_TAGS_LIST, rootGetters.creditTagsList);
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          reject(err);
        });
    });
  },

  async restoreCreditTags(
    { commit, rootGetters, dispatch },
    { creditTagIds = [] }: { creditTagIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_UPDATING_TAG, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Purchase/CreditTag/Restore",
          creditTagIds
        )
        .then(async (res) => {
          creditTagIds.forEach((creditTagId) => {
            rootGetters.tagsListArchived.splice(
              rootGetters.tagsListArchived.findIndex(
                (elem) => elem.id == creditTagId
              ),
              1
            );
          });
          await dispatch("getCreditTags", {});
          await dispatch("getCreditTagsArchived", {});
          commit(
            TagMutations.SET_TAGS_LIST_ARCHIVED,
            rootGetters.tagsListArchived
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          reject(err);
        });
    });
  },

  async archiveCreditTags(
    { commit, rootGetters, dispatch },
    { creditTagIds = [] }: { creditTagIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_ARCHIVING_TAG, true);
      await axios
        .delete(process.env.VUE_APP_API_ORISIS + "Purchase/CreditTag/Archive", {
          data: creditTagIds,
        })
        .then(async (res) => {
          creditTagIds.forEach((creditTagId) => {
            rootGetters.tagsList.splice(
              rootGetters.tagsList.findIndex((elem) => elem.id == creditTagId),
              1
            );
          });
          await dispatch("getCreditTags", {});
          await dispatch("getCreditTagsArchived", {});
          commit(TagMutations.SET_TAGS_LIST, rootGetters.tagsList);
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_ARCHIVING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_ARCHIVING_TAG, false);
          reject(err);
        });
    });
  },

  async deleteCreditTags(
    { commit, rootGetters },
    { creditTagIds = [] }: { creditTagIds: number[] }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_DELETING_TAG, true);
      await axios
        .delete(`${process.env.VUE_APP_API_ORISIS}Purchase/CreditTag/Delete`, {
          data: creditTagIds,
        })
        .then((res) => {
          creditTagIds.forEach((creditTagId) => {
            rootGetters.tagsListArchived.splice(
              rootGetters.tagsListArchived.findIndex(
                (elem) => elem.id == creditTagId
              ),
              1
            );
          });
          commit(
            TagMutations.SET_TAGS_LIST_ARCHIVED,
            rootGetters.tagsListArchived
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_DELETING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_DELETING_TAG, false);
          reject(err);
        });
    });
  },

  // ORDERFORM TAG
  async getOrderFormProviderTags(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/OrderFormTag/GetOrderFormTags`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_IS_LOADING_TAG_LIST, false);
            commit(TagMutations.SET_TAGS_LIST, res.data);
            commit(TagMutations.SET_ORDER_FORM_PROVIDER_TAGS_LIST, res.data);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_IS_LOADING_TAG_LIST, false);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          reject(err);
        });
    });
  },

  async getOrderFormProviderTagById(
    { commit },
    {
      orderFormProviderTagId,
      updateState = true,
    }: { orderFormProviderTagId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/OrderFormTag/GetOrderFormTagById`,
          {
            params: {
              orderFormTagId: orderFormProviderTagId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_TAG, res.data);
            commit(TagMutations.SET_IS_LOADING_TAG, false);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_LOADING_TAG, false);
          reject(err);
        });
    });
  },

  async getOrderFormProviderTagsArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/OrderFormTag/GetOrderFormTagsArchived`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, false);
            commit(TagMutations.SET_TAGS_LIST_ARCHIVED, res.data);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, false);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          reject(err);
        });
    });
  },

  async getOrderFormProviderTagArchivedById(
    { commit },
    {
      orderFormProviderTagId,
      updateState = true,
    }: { orderFormProviderTagId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/OrderFormTag/GetOrderFormTagArchivedById`,
          {
            params: {
              orderFormTagId: orderFormProviderTagId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_TAG, res.data);
            commit(TagMutations.SET_IS_LOADING_TAG, false);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_LOADING_TAG, false);
          reject(err);
        });
    });
  },

  async createOrderFormProviderTag(
    { commit, rootGetters },
    { tag }: { tag: ITag }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_CREATING_TAG, true);
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Purchase/OrderFormTag/CreateOrderFormTag`,
          tag
        )
        .then((res) => {
          rootGetters.orderFormProviderTagsList.unshift(res.data.data);
          commit(
            TagMutations.SET_ORDER_FORM_PROVIDER_TAGS_LIST,
            rootGetters.orderFormProviderTagsList
          );
          commit(
            TagMutations.SET_TAGS_LIST,
            rootGetters.orderFormProviderTagsList
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_CREATING_TAG, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_CREATING_TAG, false);
          reject(err);
        });
    });
  },

  async updateOrderFormProviderTag(
    { commit, rootGetters },
    { tag }: { tag: ITag }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_UPDATING_TAG, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/OrderFormTag/UpdateOrderFormTag`,
          tag
        )
        .then((res) => {
          rootGetters.orderFormProviderTagsList.splice(
            rootGetters.orderFormProviderTagsList.findIndex(
              (elem) => elem.id == tag.id
            ),
            1,
            tag
          );
          commit(
            TagMutations.SET_ORDER_FORM_PROVIDER_TAGS_LIST,
            rootGetters.orderFormProviderTagsList
          );
          commit(
            TagMutations.SET_TAGS_LIST,
            rootGetters.orderFormProviderTagsList
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          reject(err);
        });
    });
  },

  async restoreOrderFormProviderTags(
    { commit, rootGetters, dispatch },
    { orderFormProviderTagIds = [] }: { orderFormProviderTagIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_UPDATING_TAG, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Purchase/OrderFormTag/Restore",
          orderFormProviderTagIds
        )
        .then(async (res) => {
          orderFormProviderTagIds.forEach((orderFormProviderTagId) => {
            rootGetters.tagsListArchived.splice(
              rootGetters.tagsListArchived.findIndex(
                (elem) => elem.id == orderFormProviderTagId
              ),
              1
            );
          });
          await dispatch("getOrderFormProviderTags", {});
          await dispatch("getOrderFormProviderTagsArchived", {});
          commit(
            TagMutations.SET_TAGS_LIST_ARCHIVED,
            rootGetters.tagsListArchived
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          reject(err);
        });
    });
  },

  async archiveOrderFormProviderTags(
    { commit, rootGetters, dispatch },
    { orderFormProviderTagIds = [] }: { orderFormProviderTagIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_ARCHIVING_TAG, true);
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Purchase/OrderFormTag/Archive",
          {
            data: orderFormProviderTagIds,
          }
        )
        .then(async (res) => {
          orderFormProviderTagIds.forEach((orderFormProviderTagId) => {
            rootGetters.tagsList.splice(
              rootGetters.tagsList.findIndex(
                (elem) => elem.id == orderFormProviderTagId
              ),
              1
            );
          });
          await dispatch("getOrderFormProviderTags", {});
          await dispatch("getOrderFormProviderTagsArchived", {});
          commit(TagMutations.SET_TAGS_LIST, rootGetters.tagsList);
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_ARCHIVING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_ARCHIVING_TAG, false);
          reject(err);
        });
    });
  },

  async deleteOrderFormProviderTags(
    { commit, rootGetters },
    { orderFormProviderTagIds = [] }: { orderFormProviderTagIds: number[] }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_DELETING_TAG, true);
      await axios
        .delete(
          `${process.env.VUE_APP_API_ORISIS}Purchase/OrderFormTag/Delete`,
          {
            data: orderFormProviderTagIds,
          }
        )
        .then((res) => {
          orderFormProviderTagIds.forEach((orderFormProviderTagId) => {
            rootGetters.tagsListArchived.splice(
              rootGetters.tagsListArchived.findIndex(
                (elem) => elem.id == orderFormProviderTagId
              ),
              1
            );
          });
          commit(
            TagMutations.SET_TAGS_LIST_ARCHIVED,
            rootGetters.tagsListArchived
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_DELETING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_DELETING_TAG, false);
          reject(err);
        });
    });
  },

  // RECEIPTFORM TAG
  async getReceiptFormProviderTags(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/ReceiptFormTag/GetReceiptFormTags`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_IS_LOADING_TAG_LIST, false);
            commit(TagMutations.SET_TAGS_LIST, res.data);
            commit(TagMutations.SET_RECEIPT_FORM_PROVIDER_TAGS_LIST, res.data);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_IS_LOADING_TAG_LIST, false);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          reject(err);
        });
    });
  },

  async getReceiptFormProviderTagById(
    { commit },
    {
      receiptFormProviderTagId,
      updateState = true,
    }: { receiptFormProviderTagId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/ReceiptFormTag/GetReceiptFormTagById`,
          {
            params: {
              receiptFormTagId: receiptFormProviderTagId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_TAG, res.data);
            commit(TagMutations.SET_IS_LOADING_TAG, false);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_LOADING_TAG, false);
          reject(err);
        });
    });
  },

  async getReceiptFormProviderTagsArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/ReceiptFormTag/GetReceiptFormTagsArchived`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, false);
            commit(TagMutations.SET_TAGS_LIST_ARCHIVED, res.data);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, false);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          reject(err);
        });
    });
  },

  async getReceiptFormProviderTagArchivedById(
    { commit },
    {
      receiptFormProviderTagId,
      updateState = true,
    }: { receiptFormProviderTagId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/ReceiptFormTag/GetReceiptFormTagArchivedById`,
          {
            params: {
              receiptFormTagId: receiptFormProviderTagId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_TAG, res.data);
            commit(TagMutations.SET_IS_LOADING_TAG, false);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_LOADING_TAG, false);
          reject(err);
        });
    });
  },

  async createReceiptFormProviderTag(
    { commit, rootGetters },
    { tag }: { tag: ITag }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_CREATING_TAG, true);
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Purchase/ReceiptFormTag/CreateReceiptFormTag`,
          tag
        )
        .then((res) => {
          rootGetters.receiptFormProviderTagsList.unshift(res.data.data);
          commit(
            TagMutations.SET_RECEIPT_FORM_PROVIDER_TAGS_LIST,
            rootGetters.receiptFormProviderTagsList
          );
          commit(
            TagMutations.SET_TAGS_LIST,
            rootGetters.receiptFormProviderTagsList
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_CREATING_TAG, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_CREATING_TAG, false);
          reject(err);
        });
    });
  },

  async updateReceiptFormProviderTag(
    { commit, rootGetters },
    { tag }: { tag: ITag }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_UPDATING_TAG, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/ReceiptFormTag/UpdateReceiptFormTag`,
          tag
        )
        .then((res) => {
          rootGetters.receiptFormProviderTagsList.splice(
            rootGetters.receiptFormProviderTagsList.findIndex(
              (elem) => elem.id == tag.id
            ),
            1,
            tag
          );
          commit(
            TagMutations.SET_RECEIPT_FORM_PROVIDER_TAGS_LIST,
            rootGetters.receiptFormProviderTagsList
          );
          commit(
            TagMutations.SET_TAGS_LIST,
            rootGetters.receiptFormProviderTagsList
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          reject(err);
        });
    });
  },

  async restoreReceiptFormProviderTags(
    { commit, rootGetters, dispatch },
    { receiptFormProviderTagIds = [] }: { receiptFormProviderTagIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_UPDATING_TAG, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Purchase/ReceiptFormTag/Restore",
          receiptFormProviderTagIds
        )
        .then(async (res) => {
          receiptFormProviderTagIds.forEach((receiptFormProviderTagId) => {
            rootGetters.tagsListArchived.splice(
              rootGetters.tagsListArchived.findIndex(
                (elem) => elem.id == receiptFormProviderTagId
              ),
              1
            );
          });
          await dispatch("getReceiptFormProviderTags", {});
          await dispatch("getReceiptFormProviderTagsArchived", {});
          commit(
            TagMutations.SET_TAGS_LIST_ARCHIVED,
            rootGetters.tagsListArchived
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          reject(err);
        });
    });
  },

  async archiveReceiptFormProviderTags(
    { commit, rootGetters, dispatch },
    { receiptFormProviderTagIds = [] }: { receiptFormProviderTagIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_ARCHIVING_TAG, true);
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Purchase/ReceiptFormTag/Archive",
          {
            data: receiptFormProviderTagIds,
          }
        )
        .then(async (res) => {
          receiptFormProviderTagIds.forEach((receiptFormProviderTagId) => {
            rootGetters.tagsList.splice(
              rootGetters.tagsList.findIndex(
                (elem) => elem.id == receiptFormProviderTagId
              ),
              1
            );
          });
          await dispatch("getReceiptFormProviderTags", {});
          await dispatch("getReceiptFormProviderTagsArchived", {});
          commit(TagMutations.SET_TAGS_LIST, rootGetters.tagsList);
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_ARCHIVING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_ARCHIVING_TAG, false);
          reject(err);
        });
    });
  },

  async deleteReceiptFormProviderTags(
    { commit, rootGetters },
    { receiptFormProviderTagIds = [] }: { receiptFormProviderTagIds: number[] }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_DELETING_TAG, true);
      await axios
        .delete(
          `${process.env.VUE_APP_API_ORISIS}Purchase/ReceiptFormTag/Delete`,
          {
            data: receiptFormProviderTagIds,
          }
        )
        .then((res) => {
          receiptFormProviderTagIds.forEach((receiptFormProviderTagId) => {
            rootGetters.tagsListArchived.splice(
              rootGetters.tagsListArchived.findIndex(
                (elem) => elem.id == receiptFormProviderTagId
              ),
              1
            );
          });
          commit(
            TagMutations.SET_TAGS_LIST_ARCHIVED,
            rootGetters.tagsListArchived
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_DELETING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_DELETING_TAG, false);
          reject(err);
        });
    });
  },

  // DELIVERYFORM TAG
  async getDeliveryFormTags(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/DeliveryFormTag/GetDeliveryFormTags`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_IS_LOADING_TAG_LIST, false);
            commit(TagMutations.SET_TAGS_LIST, res.data);
            commit(TagMutations.SET_DELIVERY_FORM_TAGS_LIST, res.data);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_IS_LOADING_TAG_LIST, false);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          reject(err);
        });
    });
  },

  async getDeliveryFormTagById(
    { commit },
    {
      deliveryFormTagId,
      updateState = true,
    }: { deliveryFormTagId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/DeliveryFormTag/GetDeliveryFormTagById`,
          {
            params: {
              deliveryFormTagId: deliveryFormTagId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_TAG, res.data);
            commit(TagMutations.SET_IS_LOADING_TAG, false);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_LOADING_TAG, false);
          reject(err);
        });
    });
  },

  async getDeliveryFormTagsArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/DeliveryFormTag/GetDeliveryFormTagsArchived`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, false);
            commit(TagMutations.SET_TAGS_LIST_ARCHIVED, res.data);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, false);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          reject(err);
        });
    });
  },

  async getDeliveryFormTagArchivedById(
    { commit },
    {
      deliveryFormTagId,
      updateState = true,
    }: { deliveryFormTagId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/DeliveryFormTag/GetDeliveryFormTagArchivedById`,
          {
            params: {
              deliveryFormTagId: deliveryFormTagId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_TAG, res.data);
            commit(TagMutations.SET_IS_LOADING_TAG, false);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_LOADING_TAG, false);
          reject(err);
        });
    });
  },

  async createDeliveryFormTag(
    { commit, rootGetters },
    { tag }: { tag: ITag }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_CREATING_TAG, true);
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Purchase/DeliveryFormTag/CreateDeliveryFormTag`,
          tag
        )
        .then((res) => {
          rootGetters.deliveryFormTagsList.unshift(res.data.data);
          commit(
            TagMutations.SET_DELIVERY_FORM_TAGS_LIST,
            rootGetters.deliveryFormTagsList
          );
          commit(TagMutations.SET_TAGS_LIST, rootGetters.deliveryFormTagsList);
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_CREATING_TAG, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_CREATING_TAG, false);
          reject(err);
        });
    });
  },

  async updateDeliveryFormTag({ commit, rootGetters }, { tag }: { tag: ITag }) {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_UPDATING_TAG, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/DeliveryFormTag/UpdateDeliveryFormTag`,
          tag
        )
        .then((res) => {
          rootGetters.deliveryFormTagsList.splice(
            rootGetters.deliveryFormTagsList.findIndex(
              (elem) => elem.id == tag.id
            ),
            1,
            tag
          );
          commit(
            TagMutations.SET_DELIVERY_FORM_TAGS_LIST,
            rootGetters.deliveryFormTagsList
          );
          commit(TagMutations.SET_TAGS_LIST, rootGetters.deliveryFormTagsList);
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          reject(err);
        });
    });
  },

  async restoreDeliveryFormTags(
    { commit, rootGetters, dispatch },
    { deliveryFormTagIds = [] }: { deliveryFormTagIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_UPDATING_TAG, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Purchase/DeliveryFormTag/Restore",
          deliveryFormTagIds
        )
        .then(async (res) => {
          deliveryFormTagIds.forEach((deliveryFormTagId) => {
            rootGetters.tagsListArchived.splice(
              rootGetters.tagsListArchived.findIndex(
                (elem) => elem.id == deliveryFormTagId
              ),
              1
            );
          });
          await dispatch("getDeliveryFormTags", {});
          await dispatch("getDeliveryFormTagsArchived", {});
          commit(
            TagMutations.SET_TAGS_LIST_ARCHIVED,
            rootGetters.tagsListArchived
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          reject(err);
        });
    });
  },

  async archiveDeliveryFormTags(
    { commit, rootGetters, dispatch },
    { deliveryFormTagIds = [] }: { deliveryFormTagIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_ARCHIVING_TAG, true);
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Purchase/DeliveryFormTag/Archive",
          {
            data: deliveryFormTagIds,
          }
        )
        .then(async (res) => {
          deliveryFormTagIds.forEach((deliveryFormTagId) => {
            rootGetters.tagsList.splice(
              rootGetters.tagsList.findIndex(
                (elem) => elem.id == deliveryFormTagId
              ),
              1
            );
          });
          await dispatch("getDeliveryFormTags", {});
          await dispatch("getDeliveryFormTagsArchived", {});
          commit(TagMutations.SET_TAGS_LIST, rootGetters.tagsList);
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_ARCHIVING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_ARCHIVING_TAG, false);
          reject(err);
        });
    });
  },

  async deleteDeliveryFormTags(
    { commit, rootGetters },
    { deliveryFormTagIds = [] }: { deliveryFormTagIds: number[] }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_DELETING_TAG, true);
      await axios
        .delete(
          `${process.env.VUE_APP_API_ORISIS}Purchase/DeliveryFormTag/Delete`,
          {
            data: deliveryFormTagIds,
          }
        )
        .then((res) => {
          deliveryFormTagIds.forEach((deliveryFormTagId) => {
            rootGetters.tagsListArchived.splice(
              rootGetters.tagsListArchived.findIndex(
                (elem) => elem.id == deliveryFormTagId
              ),
              1
            );
          });
          commit(
            TagMutations.SET_TAGS_LIST_ARCHIVED,
            rootGetters.tagsListArchived
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_DELETING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_DELETING_TAG, false);
          reject(err);
        });
    });
  },

  // AFFAIR TAG
  async getAffairTags(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/AffairTag/GetAffairTags`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_IS_LOADING_TAG_LIST, false);
            commit(TagMutations.SET_TAGS_LIST, res.data);
            commit(TagMutations.SET_AFFAIR_TAGS_LIST, res.data);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_IS_LOADING_TAG_LIST, false);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          reject(err);
        });
    });
  },

  async getAffairTagById(
    { commit },
    {
      affairTagId,
      updateState = true,
    }: { affairTagId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Directory/AffairTag/GetAffairTagById`,
          {
            params: {
              affairTagId: affairTagId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_TAG, res.data);
            commit(TagMutations.SET_IS_LOADING_TAG, false);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_LOADING_TAG, false);
          reject(err);
        });
    });
  },

  async getAffairTagsArchived(
    { commit },
    { updateState }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/AffairTag/GetAffairTagsArchived`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, false);
            commit(TagMutations.SET_TAGS_LIST_ARCHIVED, res.data);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, false);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          reject(err);
        });
    });
  },

  async getAffairTagArchivedById(
    { commit },
    {
      affairTagId,
      updateState = true,
    }: { affairTagId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/AffairTag/GetAffairTagArchivedById`,
          {
            params: {
              affairTagId: affairTagId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_TAG, res.data);
            commit(TagMutations.SET_IS_LOADING_TAG, false);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_LOADING_TAG, false);
          reject(err);
        });
    });
  },

  async createAffairTag(
    { commit, rootGetters },
    { tag }: { tag: ITag }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_CREATING_TAG, true);
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Directory/AffairTag/CreateAffairTag`,
          tag
        )
        .then((res) => {
          rootGetters.affairTagsList.unshift(res.data.data);
          commit(TagMutations.SET_AFFAIR_TAGS_LIST, rootGetters.affairTagsList);
          commit(TagMutations.SET_TAGS_LIST, rootGetters.affairTagsList);
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_CREATING_TAG, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_CREATING_TAG, false);
          reject(err);
        });
    });
  },

  async updateAffairTag({ commit, rootGetters }, { tag }: { tag: ITag }) {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_UPDATING_TAG, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Directory/AffairTag/UpdateAffairTag`,
          tag
        )
        .then((res) => {
          rootGetters.affairTagsList.splice(
            rootGetters.affairTagsList.findIndex((elem) => elem.id == tag.id),
            1,
            tag
          );
          commit(TagMutations.SET_AFFAIR_TAGS_LIST, rootGetters.affairTagsList);
          commit(TagMutations.SET_TAGS_LIST, rootGetters.affairTagsList);
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          reject(err);
        });
    });
  },

  async restoreAffairTags(
    { commit, rootGetters, dispatch },
    { affairTagIds = [] }: { affairTagIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_UPDATING_TAG, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Directory/AffairTag/Restore",
          affairTagIds
        )
        .then(async (res) => {
          affairTagIds.forEach((affairTagId) => {
            rootGetters.tagsListArchived.splice(
              rootGetters.tagsListArchived.findIndex(
                (elem) => elem.id == affairTagId
              ),
              1
            );
          });
          await dispatch("getAffairTags", {});
          await dispatch("getAffairTagsArchived", {});
          commit(
            TagMutations.SET_TAGS_LIST_ARCHIVED,
            rootGetters.tagsListArchived
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          reject(err);
        });
    });
  },

  async archiveAffairTags(
    { commit, rootGetters, dispatch },
    { affairTagIds = [] }: { affairTagIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_ARCHIVING_TAG, true);
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Directory/AffairTag/Archive",
          {
            data: affairTagIds,
          }
        )
        .then(async (res) => {
          affairTagIds.forEach((affairTagId) => {
            rootGetters.tagsList.splice(
              rootGetters.tagsList.findIndex((elem) => elem.id == affairTagId),
              1
            );
          });
          await dispatch("getAffairTags", {});
          await dispatch("getAffairTagsArchived", {});
          commit(TagMutations.SET_TAGS_LIST, rootGetters.tagsList);
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_ARCHIVING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_ARCHIVING_TAG, false);
          reject(err);
        });
    });
  },

  async deleteAffairTags(
    { commit, rootGetters },
    { affairTagIds = [] }: { affairTagIds: number[] }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_DELETING_TAG, true);
      await axios
        .delete(`${process.env.VUE_APP_API_ORISIS}Directory/AffairTag/Delete`, {
          data: affairTagIds,
        })
        .then((res) => {
          affairTagIds.forEach((affairTagId) => {
            rootGetters.tagsListArchived.splice(
              rootGetters.tagsListArchived.findIndex(
                (elem) => elem.id == affairTagId
              ),
              1
            );
            commit(
              TagMutations.SET_TAGS_LIST_ARCHIVED,
              rootGetters.tagsListArchived
            );
          });
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_DELETING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_DELETING_TAG, false);
          reject(err);
        });
    });
  },

  // INVOICE PROVIDER TAG
  async getInvoiceProviderTags(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/InvoiceProviderTag/GetInvoiceProviderTags`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_IS_LOADING_TAG_LIST, false);
            commit(TagMutations.SET_TAGS_LIST, res.data);
            commit(TagMutations.SET_INVOICE_PROVIDER_TAGS_LIST, res.data);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_IS_LOADING_TAG_LIST, false);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          reject(err);
        });
    });
  },

  async getInvoiceProviderTagById(
    { commit },
    {
      invoiceProviderTagId,
      updateState = true,
    }: { invoiceProviderTagId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/InvoiceProviderTag/GetInvoiceProviderTagById`,
          {
            params: {
              invoiceProviderTagId: invoiceProviderTagId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_TAG, res.data);
            commit(TagMutations.SET_IS_LOADING_TAG, false);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_LOADING_TAG, false);
          reject(err);
        });
    });
  },

  async getInvoiceProviderTagsArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/InvoiceProviderTag/GetInvoiceProviderTagsArchived`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, false);
            commit(TagMutations.SET_TAGS_LIST_ARCHIVED, res.data);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, false);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          reject(err);
        });
    });
  },

  async getInvoiceProviderTagArchivedById(
    { commit },
    {
      invoiceProviderTagId,
      updateState = true,
    }: { invoiceProviderTagId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/InvoiceProviderTag/GetInvoiceProviderTagArchivedById`,
          {
            params: {
              invoiceProviderTagId: invoiceProviderTagId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_TAG, res.data);
            commit(TagMutations.SET_IS_LOADING_TAG, false);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_LOADING_TAG, false);
          reject(err);
        });
    });
  },

  async createInvoiceProviderTag(
    { commit, rootGetters },
    { tag }: { tag: ITag }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_CREATING_TAG, true);
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Purchase/InvoiceProviderTag/CreateInvoiceProviderTag`,
          tag
        )
        .then((res) => {
          rootGetters.invoiceProviderTagsList.unshift(res.data.data);
          commit(
            TagMutations.SET_INVOICE_PROVIDER_TAGS_LIST,
            rootGetters.invoiceProviderTagsList
          );
          commit(
            TagMutations.SET_TAGS_LIST,
            rootGetters.invoiceProviderTagsList
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_CREATING_TAG, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_CREATING_TAG, false);
          reject(err);
        });
    });
  },

  async updateInvoiceProviderTag(
    { commit, rootGetters },
    { tag }: { tag: ITag }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_UPDATING_TAG, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/InvoiceProviderTag/UpdateInvoiceProviderTag`,
          tag
        )
        .then((res) => {
          rootGetters.invoiceProviderTagsList.splice(
            rootGetters.invoiceProviderTagsList.findIndex(
              (elem) => elem.id == tag.id
            ),
            1,
            tag
          );
          commit(
            TagMutations.SET_INVOICE_PROVIDER_TAGS_LIST,
            rootGetters.invoiceProviderTagsList
          );
          commit(
            TagMutations.SET_TAGS_LIST,
            rootGetters.invoiceProviderTagsList
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          reject(err);
        });
    });
  },

  async restoreInvoiceProviderTags(
    { commit, rootGetters, dispatch },
    { invoiceProviderTagIds = [] }: { invoiceProviderTagIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_UPDATING_TAG, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/InvoiceProviderTag/Restore",
          invoiceProviderTagIds
        )
        .then(async (res) => {
          invoiceProviderTagIds.forEach((invoiceProviderTagId) => {
            rootGetters.tagsListArchived.splice(
              rootGetters.tagsListArchived.findIndex(
                (elem) => elem.id == invoiceProviderTagId
              ),
              1
            );
          });
          await dispatch("getInvoiceProviderTags", {});
          await dispatch("getInvoiceProviderTagsArchived", {});
          commit(
            TagMutations.SET_TAGS_LIST_ARCHIVED,
            rootGetters.tagsListArchived
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          reject(err);
        });
    });
  },

  async archiveInvoiceProviderTags(
    { commit, rootGetters, dispatch },
    { invoiceProviderTagIds = [] }: { invoiceProviderTagIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_ARCHIVING_TAG, true);
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS +
            "Purchase/InvoiceProviderTag/Archive",
          {
            data: invoiceProviderTagIds,
          }
        )
        .then(async (res) => {
          invoiceProviderTagIds.forEach((invoiceProviderTagId) => {
            rootGetters.tagsList.splice(
              rootGetters.tagsList.findIndex(
                (elem) => elem.id == invoiceProviderTagId
              ),
              1
            );
          });
          await dispatch("getInvoiceProviderTags", {});
          await dispatch("getInvoiceProviderTagsArchived", {});
          commit(TagMutations.SET_TAGS_LIST, rootGetters.tagsList);
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_ARCHIVING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_ARCHIVING_TAG, false);
          reject(err);
        });
    });
  },

  async deleteInvoiceProviderTags(
    { commit, rootGetters },
    { invoiceProviderTagIds = [] }: { invoiceProviderTagIds: number[] }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_DELETING_TAG, true);
      await axios
        .delete(
          `${process.env.VUE_APP_API_ORISIS}Purchase/InvoiceProviderTag/Delete`,
          {
            data: invoiceProviderTagIds,
          }
        )
        .then((res) => {
          invoiceProviderTagIds.forEach((invoiceProviderTagId) => {
            rootGetters.tagsListArchived.splice(
              rootGetters.tagsListArchived.findIndex(
                (elem) => elem.id == invoiceProviderTagId
              ),
              1
            );
          });
          commit(
            TagMutations.SET_TAGS_LIST_ARCHIVED,
            rootGetters.tagsListArchived
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_DELETING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_DELETING_TAG, false);
          reject(err);
        });
    });
  },

  // CREDIT PROVIDER TAG
  async getCreditProviderTags(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/CreditProviderTag/GetCreditProviderTags`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_IS_LOADING_TAG_LIST, false);
            commit(TagMutations.SET_TAGS_LIST, res.data);
            commit(TagMutations.SET_CREDIT_PROVIDER_TAGS_LIST, res.data);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_IS_LOADING_TAG_LIST, false);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          reject(err);
        });
    });
  },

  async getCreditProviderTagById(
    { commit },
    {
      creditProviderTagId,
      updateState = true,
    }: { creditProviderTagId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/CreditProviderTag/GetCreditProviderTagById`,
          {
            params: {
              creditProviderTagId: creditProviderTagId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_TAG, res.data);
            commit(TagMutations.SET_IS_LOADING_TAG, false);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_LOADING_TAG, false);
          reject(err);
        });
    });
  },

  async getCreditProviderTagsArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Purchase/CreditProviderTag/GetCreditProviderTagsArchived`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, false);
            commit(TagMutations.SET_TAGS_LIST_ARCHIVED, res.data);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, false);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          reject(err);
        });
    });
  },

  async getCreditProviderTagArchivedById(
    { commit },
    {
      creditProviderTagId,
      updateState = true,
    }: { creditProviderTagId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Purchase/CreditProviderTag/GetCreditProviderTagArchivedById`,
          {
            params: {
              creditProviderTagId: creditProviderTagId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_TAG, res.data);
            commit(TagMutations.SET_IS_LOADING_TAG, false);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_LOADING_TAG, false);
          reject(err);
        });
    });
  },

  async createCreditProviderTag(
    { commit, rootGetters },
    { tag }: { tag: ITag }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_CREATING_TAG, true);
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Purchase/CreditProviderTag/CreateCreditProviderTag`,
          tag
        )
        .then((res) => {
          rootGetters.creditProviderTagsList.unshift(res.data.data);
          commit(
            TagMutations.SET_CREDIT_PROVIDER_TAGS_LIST,
            rootGetters.creditProviderTagsList
          );
          commit(
            TagMutations.SET_TAGS_LIST,
            rootGetters.creditProviderTagsList
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_CREATING_TAG, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_CREATING_TAG, false);
          reject(err);
        });
    });
  },

  async updateCreditProviderTag(
    { commit, rootGetters },
    { tag }: { tag: ITag }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_UPDATING_TAG, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Purchase/CreditProviderTag/UpdateCreditProviderTag`,
          tag
        )
        .then((res) => {
          rootGetters.creditProviderTagsList.splice(
            rootGetters.creditProviderTagsList.findIndex(
              (elem) => elem.id == tag.id
            ),
            1,
            tag
          );
          commit(
            TagMutations.SET_CREDIT_PROVIDER_TAGS_LIST,
            rootGetters.creditProviderTagsList
          );
          commit(
            TagMutations.SET_TAGS_LIST,
            rootGetters.creditProviderTagsList
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          reject(err);
        });
    });
  },

  async restoreCreditProviderTags(
    { commit, rootGetters, dispatch },
    { creditProviderTagIds = [] }: { creditProviderTagIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_UPDATING_TAG, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Purchase/CreditProviderTag/Restore",
          creditProviderTagIds
        )
        .then(async (res) => {
          creditProviderTagIds.forEach((creditProviderTagId) => {
            rootGetters.tagsListArchived.splice(
              rootGetters.tagsListArchived.findIndex(
                (elem) => elem.id == creditProviderTagId
              ),
              1
            );
          });
          await dispatch("getCreditProviderTags", {});
          await dispatch("getCreditProviderTagsArchived", {});
          commit(
            TagMutations.SET_TAGS_LIST_ARCHIVED,
            rootGetters.tagsListArchived
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          reject(err);
        });
    });
  },

  async archiveCreditProviderTags(
    { commit, rootGetters, dispatch },
    { creditProviderTagIds = [] }: { creditProviderTagIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_ARCHIVING_TAG, true);
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Purchase/CreditProviderTag/Archive",
          {
            data: creditProviderTagIds,
          }
        )
        .then(async (res) => {
          creditProviderTagIds.forEach((creditProviderTagId) => {
            rootGetters.tagsList.splice(
              rootGetters.tagsList.findIndex(
                (elem) => elem.id == creditProviderTagId
              ),
              1
            );
          });
          await dispatch("getCreditProviderTags", {});
          await dispatch("getCreditProviderTagsArchived", {});
          commit(TagMutations.SET_TAGS_LIST, rootGetters.tagsList);
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_ARCHIVING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_ARCHIVING_TAG, false);
          reject(err);
        });
    });
  },

  async deleteCreditProviderTags(
    { commit, rootGetters },
    { creditProviderTagIds = [] }: { creditProviderTagIds: number[] }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_DELETING_TAG, true);
      await axios
        .delete(
          `${process.env.VUE_APP_API_ORISIS}Purchase/CreditProviderTag/Delete`,
          {
            data: creditProviderTagIds,
          }
        )
        .then((res) => {
          creditProviderTagIds.forEach((creditProviderTagId) => {
            rootGetters.tagsListArchived.splice(
              rootGetters.tagsListArchived.findIndex(
                (elem) => elem.id == creditProviderTagId
              ),
              1
            );
          });
          commit(
            TagMutations.SET_TAGS_LIST_ARCHIVED,
            rootGetters.tagsListArchived
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_DELETING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_DELETING_TAG, false);
          reject(err);
        });
    });
  },

  // COLLABORATOR TAG
  async getCollaboratorTags(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/CollaboratorTag/GetCollaboratorTags`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_IS_LOADING_TAG_LIST, false);
            commit(TagMutations.SET_TAGS_LIST, res.data);
            commit(TagMutations.SET_COLLABORATOR_TAGS_LIST, res.data);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_IS_LOADING_TAG_LIST, false);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          reject(err);
        });
    });
  },

  async getCollaboratorTagById(
    { commit },
    {
      collaboratorTagId,
      updateState = true,
    }: { collaboratorTagId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Directory/CollaboratorTag/GetCollaboratorTagById`,
          {
            params: {
              collaboratorTagId: collaboratorTagId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_TAG, res.data);
            commit(TagMutations.SET_IS_LOADING_TAG, false);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_LOADING_TAG, false);
          reject(err);
        });
    });
  },

  async getCollaboratorTagsArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/CollaboratorTag/GetCollaboratorTagsArchived`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, false);
            commit(TagMutations.SET_TAGS_LIST_ARCHIVED, res.data);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, false);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          reject(err);
        });
    });
  },

  async getCollaboratorTagArchivedById(
    { commit },
    {
      collaboratorTagId,
      updateState = true,
    }: { collaboratorTagId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Directory/CollaboratorTag/GetCollaboratorTagArchivedById`,
          {
            params: {
              collaboratorTagId: collaboratorTagId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_TAG, res.data);
            commit(TagMutations.SET_IS_LOADING_TAG, false);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_LOADING_TAG, false);
          reject(err);
        });
    });
  },

  async createCollaboratorTag(
    { commit, rootGetters },
    { tag }: { tag: ITag }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_CREATING_TAG, true);
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Directory/CollaboratorTag/CreateCollaboratorTag`,
          tag
        )
        .then((res) => {
          rootGetters.collaboratorTagsList.unshift(res.data.data);
          commit(
            TagMutations.SET_COLLABORATOR_TAGS_LIST,
            rootGetters.collaboratorTagsList
          );
          commit(TagMutations.SET_TAGS_LIST, rootGetters.collaboratorTagsList);
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_CREATING_TAG, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_CREATING_TAG, false);
          reject(err);
        });
    });
  },

  async updateCollaboratorTag({ commit, rootGetters }, { tag }: { tag: ITag }) {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_UPDATING_TAG, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Directory/CollaboratorTag/UpdateCollaboratorTag`,
          tag
        )
        .then((res) => {
          rootGetters.collaboratorTagsList.splice(
            rootGetters.collaboratorTagsList.findIndex(
              (elem) => elem.id == tag.id
            ),
            1,
            tag
          );
          commit(
            TagMutations.SET_COLLABORATOR_TAGS_LIST,
            rootGetters.collaboratorTagsList
          );
          commit(TagMutations.SET_TAGS_LIST, rootGetters.collaboratorTagsList);
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          reject(err);
        });
    });
  },

  async restoreCollaboratorTags(
    { commit, rootGetters, dispatch },
    { collaboratorTagIds = [] }: { collaboratorTagIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_UPDATING_TAG, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Directory/CollaboratorTag/Restore",
          collaboratorTagIds
        )
        .then(async (res) => {
          collaboratorTagIds.forEach((collaboratorTagId) => {
            rootGetters.tagsListArchived.splice(
              rootGetters.tagsListArchived.findIndex(
                (elem) => elem.id == collaboratorTagId
              ),
              1
            );
          });
          await dispatch("getCollaboratorTags", {});
          await dispatch("getCollaboratorTagsArchived", {});
          commit(
            TagMutations.SET_TAGS_LIST_ARCHIVED,
            rootGetters.tagsListArchived
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          reject(err);
        });
    });
  },

  async archiveCollaboratorTags(
    { commit, rootGetters, dispatch },
    { collaboratorTagIds = [] }: { collaboratorTagIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_ARCHIVING_TAG, true);
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Directory/CollaboratorTag/Archive",
          {
            data: collaboratorTagIds,
          }
        )
        .then(async (res) => {
          collaboratorTagIds.forEach((collaboratorTagId) => {
            rootGetters.tagsList.splice(
              rootGetters.tagsList.findIndex(
                (elem) => elem.id == collaboratorTagId
              ),
              1
            );
          });
          await dispatch("getCollaboratorTags", {});
          await dispatch("getCollaboratorTagsArchived", {});
          commit(TagMutations.SET_TAGS_LIST, rootGetters.tagsList);
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_ARCHIVING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_ARCHIVING_TAG, false);
          reject(err);
        });
    });
  },

  async deleteCollaboratorTags(
    { commit, rootGetters },
    { collaboratorTagIds = [] }: { collaboratorTagIds: number[] }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_DELETING_TAG, true);
      await axios
        .delete(
          `${process.env.VUE_APP_API_ORISIS}Directory/CollaboratorTag/Delete`,
          {
            data: collaboratorTagIds,
          }
        )
        .then((res) => {
          collaboratorTagIds.forEach((collaboratorTagId) => {
            rootGetters.tagsListArchived.splice(
              rootGetters.tagsListArchived.findIndex(
                (elem) => elem.id == collaboratorTagId
              ),
              1
            );
          });
          commit(
            TagMutations.SET_TAGS_LIST_ARCHIVED,
            rootGetters.tagsListArchived
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_DELETING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_DELETING_TAG, false);
          reject(err);
        });
    });
  },

  // CONTACT TAG
  async getContactTags(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG_LIST, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/ContactTag/GetContactTags`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_IS_LOADING_TAG_LIST, false);
            commit(TagMutations.SET_TAGS_LIST, res.data);
            commit(TagMutations.SET_CONTACT_TAGS_LIST, res.data);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_IS_LOADING_TAG_LIST, false);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          reject(err);
        });
    });
  },

  async getContactTagById(
    { commit },
    {
      contactTagId,
      updateState = true,
    }: { contactTagId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Directory/ContactTag/GetContactTagById`,
          {
            params: {
              contactTagId: contactTagId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_TAG, res.data);
            commit(TagMutations.SET_IS_LOADING_TAG, false);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_LOADING_TAG, false);
          reject(err);
        });
    });
  },

  async getContactTagsArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, true);
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Directory/ContactTag/GetContactTagsArchived`;
      await axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, false);
            commit(TagMutations.SET_TAGS_LIST_ARCHIVED, res.data);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_IS_LOADING_TAG_LIST_ARCHIVED, false);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          reject(err);
        });
    });
  },

  async getContactTagArchivedById(
    { commit },
    {
      contactTagId,
      updateState = true,
    }: { contactTagId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(TagMutations.SET_IS_LOADING_TAG, true);
      }
      await axios
        .get(
          `${process.env.VUE_APP_API_ORISIS}Directory/ContactTag/GetContactTagArchivedById`,
          {
            params: {
              contactTagId: contactTagId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(TagMutations.SET_TAG, res.data);
            commit(TagMutations.SET_IS_LOADING_TAG, false);
          }
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_LOADING_TAG, false);
          reject(err);
        });
    });
  },

  async createContactTag(
    { commit, rootGetters },
    { tag }: { tag: ITag }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_CREATING_TAG, true);
      await axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Directory/ContactTag/CreateContactTag`,
          tag
        )
        .then((res) => {
          rootGetters.contactTagsList.unshift(res.data.data);
          commit(
            TagMutations.SET_CONTACT_TAGS_LIST,
            rootGetters.contactTagsList
          );
          commit(TagMutations.SET_TAGS_LIST, rootGetters.contactTagsList);
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_CREATING_TAG, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_CREATING_TAG, false);
          reject(err);
        });
    });
  },

  async updateContactTag({ commit, rootGetters }, { tag }: { tag: ITag }) {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_UPDATING_TAG, true);
      await axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Directory/ContactTag/UpdateContactTag`,
          tag
        )
        .then((res) => {
          rootGetters.contactTagsList.splice(
            rootGetters.contactTagsList.findIndex((elem) => elem.id == tag.id),
            1,
            tag
          );
          commit(
            TagMutations.SET_CONTACT_TAGS_LIST,
            rootGetters.contactTagsList
          );
          commit(TagMutations.SET_TAGS_LIST, rootGetters.contactTagsList);
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          reject(err);
        });
    });
  },

  async restoreContactTags(
    { commit, rootGetters, dispatch },
    { contactTagIds = [] }: { contactTagIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_UPDATING_TAG, true);
      await axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Directory/ContactTag/Restore",
          contactTagIds
        )
        .then(async (res) => {
          contactTagIds.forEach((contactTagId) => {
            rootGetters.tagsListArchived.splice(
              rootGetters.tagsListArchived.findIndex(
                (elem) => elem.id == contactTagId
              ),
              1
            );
          });
          await dispatch("getContactTags", {});
          await dispatch("getContactTagsArchived", {});
          commit(
            TagMutations.SET_TAGS_LIST_ARCHIVED,
            rootGetters.tagsListArchived
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_UPDATING_TAG, false);
          reject(err);
        });
    });
  },

  async archiveContactTags(
    { commit, rootGetters, dispatch },
    { contactTagIds = [] }: { contactTagIds: number[] }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_ARCHIVING_TAG, true);
      await axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Directory/ContactTag/Archive",
          {
            data: contactTagIds,
          }
        )
        .then(async (res) => {
          contactTagIds.forEach((contactTagId) => {
            rootGetters.tagsList.splice(
              rootGetters.tagsList.findIndex((elem) => elem.id == contactTagId),
              1
            );
          });
          await dispatch("getContactTags", {});
          await dispatch("getContactTagsArchived", {});
          commit(TagMutations.SET_TAGS_LIST, rootGetters.tagsList);
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_ARCHIVING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_ARCHIVING_TAG, false);
          reject(err);
        });
    });
  },

  async deleteContactTags(
    { commit, rootGetters },
    { contactTagIds = [] }: { contactTagIds: number[] }
  ) {
    return new Promise<void>(async (resolve, reject) => {
      commit(TagMutations.SET_IS_DELETING_TAG, true);
      await axios
        .delete(
          `${process.env.VUE_APP_API_ORISIS}Directory/ContactTag/Delete`,
          {
            data: contactTagIds,
          }
        )
        .then((res) => {
          contactTagIds.forEach((contactTagId) => {
            rootGetters.tagsListArchived.splice(
              rootGetters.tagsListArchived.findIndex(
                (elem) => elem.id == contactTagId
              ),
              1
            );
          });
          commit(
            TagMutations.SET_TAGS_LIST_ARCHIVED,
            rootGetters.tagsListArchived
          );
          commit(TagMutations.SET_REQUEST_STATE_TAG, res);
          commit(TagMutations.SET_IS_DELETING_TAG, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(TagMutations.SET_REQUEST_STATE_TAG, err);
          commit(TagMutations.SET_IS_DELETING_TAG, false);
          reject(err);
        });
    });
  },
};
