import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { IPaymentProvider } from "@/types/api-orisis/interfaces/Purchase/paymentProvider/IPaymentProvider";
import RootState from "@/store/modules/rootState"
import { IPaymentProviderState } from "./states";
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

export const state: IPaymentProviderState = {
    paymentProvidersList                  : Array<IPaymentProvider>(),
    paymentProvidersListArchived          : Array<IPaymentProvider>(),
    paymentProvider                       : <IPaymentProvider>{},
    isLoadingPaymentProvidersList         : false,
    isLoadingPaymentProvidersListArchived : false,
    isLoadingPaymentProvider              : false,
    isCreatingPaymentProvider             : false,
    isUpdatingPaymentProvider             : false,
    isDeletingPaymentProvider             : false,
    isArchivingPaymentProvider            : false,
    isLinkingPaymentProvider              : false,
    isUnlinkingPaymentProvider            : false,
    requestStatePaymentProvider           : <IRequestState>{},
    paymentProviderFilters                : {}
}

export const PaymentProviderModule: Module<IPaymentProviderState, RootState> = {
  state, 
  getters,
  mutations,
  actions
}