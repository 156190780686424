import { IOrderFormProviderState } from "./states";
import { Module } from "vuex";
import { OrderFormRequestDto } from "@/../Api";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import RootState from "@/store/modules/rootState";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { IPaymentMethod } from "@/types/api-orisis/interfaces/invoices/paymentMethod/IPaymentMethod";
import { IFilters } from "@/types/api-orisis/interfaces/invoices/filters/IFilters";

const state: IOrderFormProviderState = {
  orderFormProvidersList: Array<OrderFormRequestDto>(),
  orderFormProvidersListTemplates: Array<OrderFormRequestDto>(),
  filteredOrderFormProvidersList: Array<OrderFormRequestDto>(),
  orderFormProvidersListArchived: Array<OrderFormRequestDto>(),
  filteredOrderFormProvidersListArchived: Array<OrderFormRequestDto>(),
  orderFormProvider: <OrderFormRequestDto>{},
  orderFormProviderArchived: <OrderFormRequestDto>{},
  orderFormProviderPaymentMethods: Array<IPaymentMethod>(),
  isValidatingOrderFormProvider: false,
  isLoadingPaymentMethodsOrderFormProvider: false,
  isLoadingOrderFormProvidersList: false,
  isLoadingOrderFormProvidersListArchived: false,
  isLoadingOrderFormProvider: false,
  isLoadingDuplicatingOrderFormProvider: false,
  isLoadingOrderFormProviderArchived: false,
  isCreatingOrderFormProvider: false,
  isUpdatingOrderFormProvider: false,
  isRestoringOrderFormProvider: false,
  isDeletingOrderFormProvider: false,
  isArchivingOrderFormProvider: false,
  requestStateOrderFormProvider: <IRequestState>{},
  orderFormProviderTabActive: "order-form-provider-edit",
  orderFormProviderPDF: "",
  isLoadingOrderFormProviderPDF: false,
  orderFormProviderFilters: <IFilters>{},
  requestStateOrderFormProviderPDF: <IRequestState>{},
  initialOrderFormProvider: <OrderFormRequestDto>{},
  orderFormProvidersListByAffair: Array<OrderFormRequestDto>(),
  orderFormProvidersListByCompany: Array<OrderFormRequestDto>(),
  isLoadingOrderFormProvidersListByAffair: false,
  isLoadingOrderFormProvidersListByCompany: false,
};

export const OrderFormProviderModule: Module<
  IOrderFormProviderState,
  RootState
> = {
  state,
  getters,
  mutations,
  actions,
};
