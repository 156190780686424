import { ISmtpSettingState } from "./states"
import { Module } from "vuex"
import { SmtpSettingRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:ISmtpSettingState = {
    smtpSettingList         : Array<SmtpSettingRequestDto>(),
    smtpSettingListArchived : Array<SmtpSettingRequestDto>(),
    smtpSetting              : <SmtpSettingRequestDto>{},
    isLoadingSmtpSettingList: false,
    isLoadingSmtpSettingListArchived: false,
    isLoadingSmtpSetting    : false,
    isCreatingSmtpSetting   : false,
    isUpdatingSmtpSetting   : false,
    isDeletingSmtpSetting   : false,
    isArchivingSmtpSetting   : false,
    requestStateSmtpSetting: <IRequestState>{},
}

export const SmtpSettingModule: Module<ISmtpSettingState, RootState> = {
    state, 
    getters,
    mutations,
    actions
} 