import { IBankAccountState } from "./states";
import { MutationTree } from "vuex";
import { BankAccountRequestDto } from "Api";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export enum BankAccountMutations {
  SET_BANK_ACCOUNTS_LIST = "SET_BANK_ACCOUNTS_LIST",
  SET_BANK_ACCOUNTS_LIST_FORM = "SET_BANK_ACCOUNTS_LIST_FORM",
  SET_BANK_ACCOUNTS_LIST_ARCHIVED = "SET_BANK_ACCOUNTS_LIST_ARCHIVED",
  SET_BANK_ACCOUNT = "SET_BANK_ACCOUNT",

  // COMON
  SET_IS_LOADING_BANK_ACCOUNTS_LIST = "SET_IS_LOADING_BANK_ACCOUNTS_LIST",
  SET_IS_LOADING_BANK_ACCOUNTS_LIST_ARCHIVED = "SET_IS_LOADING_BANK_ACCOUNTS_LIST_ARCHIVED",
  SET_IS_LOADING_BANK_ACCOUNT = "SET_IS_LOADING_BANK_ACCOUNT",
  SET_IS_CREATING_BANK_ACCOUNT = "SET_IS_CREATING_BANK_ACCOUNT",
  SET_IS_UPDATING_BANK_ACCOUNT = "SET_IS_UPDATING_BANK_ACCOUNT",
  SET_IS_DELETING_BANK_ACCOUNT = "SET_IS_DELETING_BANK_ACCOUNT",
  SET_REQUEST_STATE_BANK_ACCOUNT = "SET_REQUEST_STATE_BANK_ACCOUNT",
  SET_IS_ARCHIVING_BANK_ACCOUNT = "SET_IS_ARCHIVING_BANK_ACCOUNT",  
}

  export const mutations: MutationTree<IBankAccountState> = {
    [BankAccountMutations.SET_BANK_ACCOUNTS_LIST]: (state, payload: BankAccountRequestDto[]) => {
        state.bankAccountsList = payload;
    },
    [BankAccountMutations.SET_BANK_ACCOUNTS_LIST_FORM]: (state, payload: BankAccountRequestDto[]) => {
        state.bankAccountsListForm = payload;
    },
    [BankAccountMutations.SET_BANK_ACCOUNTS_LIST_ARCHIVED]: (state, payload: BankAccountRequestDto[]) => {
        state.bankAccountsListArchived = payload;
    },
    [BankAccountMutations.SET_BANK_ACCOUNT]: (state, payload: BankAccountRequestDto) => {
        state.bankAccount = payload;
    },


    //COMMON
    [BankAccountMutations.SET_IS_LOADING_BANK_ACCOUNTS_LIST]: (state, payload: boolean) => {
        state.isLoadingBankAccountsList = payload;
    },
    [BankAccountMutations.SET_IS_LOADING_BANK_ACCOUNTS_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingBankAccountsListArchived = payload;
    },
    [BankAccountMutations.SET_IS_LOADING_BANK_ACCOUNT]: (state, payload: boolean) => {
        state.isLoadingBankAccount = payload;
    },
    [BankAccountMutations.SET_IS_CREATING_BANK_ACCOUNT]: (state, payload: boolean) => {
        state.isCreatingBankAccount = payload;
    },
    [BankAccountMutations.SET_IS_UPDATING_BANK_ACCOUNT]: (state, payload: boolean) => {
        state.isUpdatingBankAccount = payload;
    },
    [BankAccountMutations.SET_IS_DELETING_BANK_ACCOUNT]: (state, payload: boolean) => {
        state.isDeletingBankAccount = payload;
    },
    [BankAccountMutations.SET_REQUEST_STATE_BANK_ACCOUNT]: (state, payload: IRequestState) => {
        state.requestStateBankAccount = payload;
    },
    [BankAccountMutations.SET_IS_ARCHIVING_BANK_ACCOUNT]: (state, payload: boolean) => {
        state.isArchivingBankAccount = payload;
    }
}