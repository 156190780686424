import { IAuthState } from "./states"
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"

const state: IAuthState = {
    authService: null
}

export const AuthModule: Module<IAuthState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}