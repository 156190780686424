import { MutationTree } from "vuex";
import { IQuoteOptionDefaultState } from "./states";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { QuoteOptionRequestDto } from "Api";

export const enum QuoteOptionDefaultMutations {
  SET_QUOTE_OPTION_DEFAULT = "SET_QUOTE_OPTION_DEFAULT",
  SET_QUOTE_OPTION_DEFAULT_FORM = "SET_QUOTE_OPTION_DEFAULT_FORM",
  SET_IS_LOADING_QUOTE_OPTION_DEFAULT = "SET_IS_LOADING_QUOTE_OPTION_DEFAULT",
  SET_REQUEST_STATE_QUOTE_OPTION_DEFAULT = "SET_REQUEST_STATE_QUOTE_OPTION_DEFAULT",
}

export const mutations: MutationTree<IQuoteOptionDefaultState> = {
  [QuoteOptionDefaultMutations.SET_QUOTE_OPTION_DEFAULT](
    state,
    payload: QuoteOptionRequestDto
  ) {
    state.quoteOptionDefault = payload;
  },
  [QuoteOptionDefaultMutations.SET_QUOTE_OPTION_DEFAULT_FORM](
    state,
    payload: QuoteOptionRequestDto
  ) {
    state.quoteOptionDefaultForm = payload;
  },
  [QuoteOptionDefaultMutations.SET_IS_LOADING_QUOTE_OPTION_DEFAULT](
    state,
    payload: boolean
  ) {
    state.isLoadingQuoteOptionDefault = payload;
  },
  [QuoteOptionDefaultMutations.SET_REQUEST_STATE_QUOTE_OPTION_DEFAULT](
    state,
    payload: IRequestState
  ) {
    state.requestStateQuoteOptionDefault = payload;
  },
};
