import { IInterventionState } from "./states";
import { Module } from "vuex";
import { InterventionRequestDto } from "@/../Api";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import RootState from "@/store/modules/rootState";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

const state: IInterventionState = {
  interventionsList: Array<InterventionRequestDto>(),
  interventionsListByCollaboratorId: Array<InterventionRequestDto>(),
  intervention: <InterventionRequestDto>{},
  isLoadingInterventionsList: false,
  isLoadingInterventionsListByCollaboratorId: false,
  isLoadingIntervention: false,
  isCreatingIntervention: false,
  isUpdatingIntervention: false,
  isDeletingIntervention: false,
  requestStateIntervention: <IRequestState>{},
  interventionFilters: {},
  interventionFiltersByCollaborator: {},
};

export const InterventionModule: Module<IInterventionState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
