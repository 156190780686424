import { MutationTree } from "vuex";
import { IOrderFormOptionState } from "./states";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { OrderFormOptionRequestDto } from "Api";

export const enum OrderFormOptionMutations {
  SET_ORDER_FORM_OPTION = "SET_ORDER_FORM_OPTION",
  SET_IS_LOADING_ORDER_FORM_OPTION = "SET_IS_LOADING_ORDER_FORM_OPTION",
  SET_REQUEST_STATE_ORDER_FORM_OPTION = "SET_REQUEST_STATE_ORDER_FORM_OPTION",
}

export const mutations: MutationTree<IOrderFormOptionState> = {
  [OrderFormOptionMutations.SET_ORDER_FORM_OPTION](
    state,
    payload: OrderFormOptionRequestDto
  ) {
    state.orderFormOption = payload;
  },
  [OrderFormOptionMutations.SET_IS_LOADING_ORDER_FORM_OPTION](
    state,
    payload: boolean
  ) {
    state.isLoadingOrderFormOption = payload;
  },
  [OrderFormOptionMutations.SET_REQUEST_STATE_ORDER_FORM_OPTION](
    state,
    payload: IRequestState
  ) {
    state.requestStateOrderFormOption = payload;
  },
};
