import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { ProductCategoryRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { IProductCategoryState } from './states'


export const getters: GetterTree<IProductCategoryState, RootState> = {
  productCategoriesList                    : (state): ProductCategoryRequestDto[] => state.productCategoriesList,
  productCategoriesTreeList                : (state): any[] => state.productCategoriesTreeList,
  productCategoriesListArchived            : (state): ProductCategoryRequestDto[] => state.productCategoriesListArchived,
  productCategory                          : (state): ProductCategoryRequestDto => state.productCategory,

  // COMMON
  isLoadingProductCategoriesList           : (state): boolean => state.isLoadingProductCategoriesList,
  isLoadingProductCategoriesTreeList       : (state): boolean => state.isLoadingProductCategoriesTreeList,
  isLoadingProductCategoriesListArchived   : (state): boolean => state.isLoadingProductCategoriesListArchived,
  isLoadingProductCategory                 : (state): boolean => state.isLoadingProductCategory,
  isCreatingProductCategory                : (state): boolean => state.isCreatingProductCategory,
  isUpdatingProductCategory                : (state): boolean => state.isUpdatingProductCategory,
  isDeletingProductCategory                : (state): boolean => state.isDeletingProductCategory,
  isArchivingProductCategory               : (state): boolean => state.isArchivingProductCategory,
  requestStateProductCategory              : (state): IRequestState => state.requestStateProductCategory,
}