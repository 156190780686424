import { IModuleState } from "./states";
import { MutationTree } from "vuex";
import { ModuleRequestDto } from '@/../Api'
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export enum ModuleMutations{
    SET_MODULES_LIST = "SET_MODULES_LIST",
    SET_MODULES_LIST_ARCHIVED = "SET_MODULES_LIST_ARCHIVED",
    SET_MODULE = "SET_MODULE",
    
    // COMON
    SET_IS_LOADING_MODULES_LIST = "SET_IS_LOADING_MODULES_LIST",
    SET_IS_LOADING_MODULES_LIST_ARCHIVED = "SET_IS_LOADING_MODULES_LIST_ARCHIVED",
    SET_IS_LOADING_MODULE = "SET_IS_LOADING_MODULE",
    SET_IS_ARCHIVING_MODULE = "SET_IS_ARCHIVING_MODULE",
    SET_REQUEST_STATE_MODULE = "SET_REQUEST_STATE_MODULE",
  }

  export const mutations: MutationTree<IModuleState> = {
    [ModuleMutations.SET_MODULES_LIST]: (state, payload: ModuleRequestDto[]) => {
        state.modulesList = payload;
    },
    [ModuleMutations.SET_MODULES_LIST_ARCHIVED]: (state, payload: ModuleRequestDto[]) => {
        state.modulesListArchived = payload;
    },
    [ModuleMutations.SET_MODULE]: (state, payload: ModuleRequestDto) => {
        state.module = payload;
    },


    //COMMON
    [ModuleMutations.SET_IS_LOADING_MODULES_LIST]: (state, payload: boolean) => {
        state.isLoadingModulesList = payload;
    },
    [ModuleMutations.SET_IS_LOADING_MODULES_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingModulesListArchived = payload;
    },
    [ModuleMutations.SET_IS_LOADING_MODULE]: (state, payload: boolean) => {
        state.isLoadingModule = payload;
    },
    [ModuleMutations.SET_IS_ARCHIVING_MODULE]: (state, payload: IRequestState) => {
        state.requestStateModule = payload;
    },
    [ModuleMutations.SET_REQUEST_STATE_MODULE]: (state, payload: boolean) => {
        state.isArchivingModule = payload;
    }
}