import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { ActivityChanelTypeRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { IActivityChanelTypeState } from './states'


export const getters: GetterTree<IActivityChanelTypeState, RootState> = {
  activityChanelTypesList: (state): ActivityChanelTypeRequestDto[] => state.activityChanelTypesList,
  activityChanelTypesListArchived: (state): ActivityChanelTypeRequestDto[] => state.activityChanelTypesListArchived,
  activityChanelType     : (state): ActivityChanelTypeRequestDto => state.activityChanelType,

  // COMMON
  isLoadingActivityChanelTypesList   : (state): boolean => state.isLoadingActivityChanelTypesList,
  isLoadingActivityChanelTypesListArchived   : (state): boolean => state.isLoadingActivityChanelTypesListArchived,
  isLoadingActivityChanelType   : (state): boolean => state.isLoadingActivityChanelType,
  isCreatingActivityChanelType  : (state): boolean => state.isCreatingActivityChanelType,
  isUpdatingActivityChanelType  : (state): boolean => state.isUpdatingActivityChanelType,
  isDeletingActivityChanelType  : (state): boolean => state.isDeletingActivityChanelType,
  isArchivingActivityChanelType  : (state): boolean => state.isArchivingActivityChanelType,
  requestStateActivityChanelType: (state): IRequestState => state.requestStateActivityChanelType,
}



