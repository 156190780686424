import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState';
import { IHoldbackState } from './states';
import { MutationTree } from 'vuex';
import { IHoldback } from '@/types/api-orisis/interfaces/invoices/holdback/IHoldback';
import { HoldbackRequestDto } from 'Api';

export enum HoldbackMutations {
    SET_HOLDBACKS_LIST = 'SET_HOLDBACKS_LIST',
    SET_HOLDBACK = 'SET_HOLDBACK',

    SET_IS_UPDATING_HOLDBACK = 'SET_IS_UPDATING_HOLDBACK',

    SET_IS_LOADING_HOLDBACKS_LIST = 'SET_LOADING_HOLDBACKS_LIST',
    SET_IS_LOADING_HOLDBACK = 'SET_LOADING_HOLDBACK',

    SET_REQUEST_STATE_HOLDBACK = 'SET_REQUEST_STATE_HOLDBACK',
}

export const mutations: MutationTree<IHoldbackState> = {
    [HoldbackMutations.SET_HOLDBACKS_LIST]: (state, payload: IHoldback[]) => {
        state.holdbacksList = payload;
    },
    [HoldbackMutations.SET_HOLDBACK]: (state, payload: HoldbackRequestDto) => {
        state.holdback = payload;
    },
    [HoldbackMutations.SET_IS_UPDATING_HOLDBACK]: (state, payload: boolean) => {
        state.isUpdatingHoldback = payload;
    },
    [HoldbackMutations.SET_IS_LOADING_HOLDBACKS_LIST]: (state, payload: boolean) => {
        state.isLoadingHoldbacksList = payload;
    },
    [HoldbackMutations.SET_IS_LOADING_HOLDBACK]: (state, payload: boolean) => {
        state.isLoadingHoldback = payload;
    },
    [HoldbackMutations.SET_REQUEST_STATE_HOLDBACK]: (state, payload: IRequestState) => {
        state.requestStateHoldback = payload;
    },
};