import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { EventRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IEventState } from "./states";

export enum EventMutations {
    SET_EVENTS_LIST = "SET_EVENTS_LIST",
    SET_EVENTS_LIST_BY_COLLABORATOR = "SET_EVENTS_LIST_BY_COLLABORATOR",
    SET_EVENTS_LIST_ARCHIVED = "SET_EVENTS_LIST_ARCHIVED",
    SET_IS_LOADING_EVENT_LIST_ARCHIVED_BY_COLLABORATOR = "SET_IS_LOADING_EVENT_LIST_ARCHIVED_BY_COLLABORATOR",
    SET_EVENTS_LIST_ARCHIVED_BY_COLLABORATOR = "SET_EVENTS_LIST_ARCHIVED_BY_COLLABORATOR",
    SET_EVENTS_LIST_BY_COMPANY = "SET_EVENTS_LIST_BY_COMPANY",
    SET_IS_LOADING_EVENT_LIST_ARCHIVED_BY_COMPANY = "SET_IS_LOADING_EVENT_LIST_ARCHIVED_BY_COMPANY",
    SET_EVENTS_LIST_ARCHIVED_BY_COMPANY = "SET_EVENTS_LIST_ARCHIVED_BY_COMPANY",
    SET_EVENT = "SET_EVENT",

    //FILTERS
    SET_EVENT_FILTERS = "SET_EVENT_FILTERS",

    // COMMON
    SET_IS_LOADING_EVENT_LIST = "SET_IS_LOADING_EVENT_LIST",
    SET_IS_LOADING_EVENT_LIST_ARCHIVED = "SET_IS_LOADING_EVENT_LIST_ARCHIVED",
    SET_IS_LOADING_EVENT_LIST_BY_COLLABORATOR = "SET_IS_LOADING_EVENT_LIST_BY_COLLABORATOR",
    SET_IS_LOADING_EVENT_LIST_BY_COMPANY = "SET_IS_LOADING_EVENT_LIST_BY_COMPANY",
    SET_IS_LOADING_EVENT = "SET_IS_LOADING_EVENT",
    SET_IS_CREATING_EVENT = "SET_IS_CREATING_EVENT",
    SET_IS_UPDATING_EVENT = "SET_IS_UPDATING_EVENT",
    SET_IS_DELETING_EVENT = "SET_IS_DELETING_EVENT",
    SET_REQUEST_STATE_EVENT = "SET_REQUEST_STATE_EVENT",
}

export const mutations: MutationTree<IEventState> = {
    [EventMutations.SET_EVENTS_LIST]: (state, payload: any[]) => {
        state.eventsList = payload;
    },
    [EventMutations.SET_EVENTS_LIST_BY_COLLABORATOR]: (state, payload: any[]) => {
        state.eventsListByCollaborator = payload
    }, 
    [EventMutations.SET_EVENTS_LIST_BY_COMPANY]: (state, payload: any[]) => {
        state.eventsListByCompany = payload
    },
    [EventMutations.SET_EVENTS_LIST_ARCHIVED]: (state, payload: any[]) => {
        state.eventsListArchived = payload
    },
    [EventMutations.SET_EVENTS_LIST_ARCHIVED_BY_COLLABORATOR]: (state, payload: any[]) => {
        state.eventsListArchivedByCollaborator = payload
    }, 
    [EventMutations.SET_EVENTS_LIST_ARCHIVED_BY_COMPANY]: (state, payload: any[]) => {
        state.eventsListArchivedByCompany = payload
    },
    [EventMutations.SET_EVENT]: (state, payload: EventRequestDto) => {
        state.event = payload;
    },

    //FILTERS
    [EventMutations.SET_EVENT_FILTERS]: (state, payload: any) => {
        state.eventFilters = payload;
    },


    //COMMON
    [EventMutations.SET_IS_LOADING_EVENT_LIST]: (state, payload: boolean) => {
        state.isLoadingEventsList = payload;
    },
    [EventMutations.SET_IS_LOADING_EVENT_LIST_BY_COLLABORATOR]: (state, payload: boolean) => {
        state.isLoadingEventsListByCollaborator = payload;
    },
    [EventMutations.SET_IS_LOADING_EVENT_LIST_BY_COMPANY]: (state, payload: boolean) => {
        state.isLoadingEventsListByCompany = payload;
    },
    [EventMutations.SET_IS_LOADING_EVENT_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingEventsListArchived = payload;
    },
    [EventMutations.SET_IS_LOADING_EVENT_LIST_ARCHIVED_BY_COLLABORATOR]: (state, payload: boolean) => {
        state.isLoadingEventsListArchivedByCollaborator = payload;
    },
    [EventMutations.SET_IS_LOADING_EVENT_LIST_ARCHIVED_BY_COMPANY]: (state, payload: boolean) => {
        state.isLoadingEventsListArchivedByCompany = payload;
    },
    [EventMutations.SET_IS_LOADING_EVENT]: (state, payload: boolean) => {
        state.isLoadingEvent = payload;
    },
    [EventMutations.SET_IS_CREATING_EVENT]: (state, payload: boolean) => {
        state.isCreatingEvent = payload;
    },
    [EventMutations.SET_IS_UPDATING_EVENT]: (state, payload: boolean) => {
        state.isUpdatingEvent = payload;
    },
    [EventMutations.SET_IS_DELETING_EVENT]: (state, payload: boolean) => {
        state.isDeletingEvent = payload;
    },
    [EventMutations.SET_REQUEST_STATE_EVENT]: (state, payload: IRequestState) => {
        state.requestStateEvent = payload;
    },
}