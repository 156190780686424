import { MutationTree } from "vuex";
import { IReceiptFormProviderDetailState } from "./states";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export enum ReceiptFormProviderDetailMutations {
  SET_RECEIPT_FORM_HEADER = "SET_RECEIPT_FORM_HEADER",
  SET_RECEIPT_FORM_DETAIL = "SET_RECEIPT_FORM_DETAIL",
  SET_RECEIPT_FORM_DETAILS = "SET_RECEIPT_FORM_DETAILS",
  SET_IS_LOADING_RECEIPT_FORM_DETAILS = "SET_IS_LOADING_RECEIPT_FORM_DETAILS",
  SET_IS_LOADING_RECEIPT_FORM_DETAIL_DUPLICATION = "SET_IS_LOADING_RECEIPT_FORM_DETAIL_DUPLICATION",
  SET_IS_CREATING_RECEIPT_FORM_DETAIL = "SET_IS_CREATING_RECEIPT_FORM_DETAIL",
  SET_IS_UPDATING_RECEIPT_FORM_DETAIL = "SET_IS_UPDATING_RECEIPT_FORM_DETAIL",
  SET_IS_DELETING_RECEIPT_FORM_DETAIL = "SET_IS_DELETING_RECEIPT_FORM_DETAIL",
  SET_REQUEST_STATE_RECEIPT_FORM_DETAIL = "SET_REQUEST_STATE_RECEIPT_FORM_DETAIL",
}

export const mutations: MutationTree<IReceiptFormProviderDetailState> = {
  [ReceiptFormProviderDetailMutations.SET_RECEIPT_FORM_HEADER]: (state, payload: any) => {
    state.receiptFormProviderHeader = payload;
  },
  [ReceiptFormProviderDetailMutations.SET_RECEIPT_FORM_DETAILS]: (
    state,
    payload: any[]
  ) => {
    state.receiptFormProviderDetails = payload;
  },
  [ReceiptFormProviderDetailMutations.SET_RECEIPT_FORM_DETAIL]: (state, payload: any) => {
    state.receiptFormProviderDetail = payload;
  },
  [ReceiptFormProviderDetailMutations.SET_IS_LOADING_RECEIPT_FORM_DETAILS]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingReceiptFormProviderDetails = payload;
  },
  [ReceiptFormProviderDetailMutations.SET_IS_LOADING_RECEIPT_FORM_DETAIL_DUPLICATION]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingReceiptFormProviderDetailDuplication = payload;
  },
  [ReceiptFormProviderDetailMutations.SET_IS_CREATING_RECEIPT_FORM_DETAIL]: (
    state,
    payload: boolean
  ) => {
    state.isCreatingReceiptFormProviderDetail = payload;
  },
  [ReceiptFormProviderDetailMutations.SET_IS_UPDATING_RECEIPT_FORM_DETAIL]: (
    state,
    payload: boolean
  ) => {
    state.isUpdatingReceiptFormProviderDetail = payload;
  },
  [ReceiptFormProviderDetailMutations.SET_IS_DELETING_RECEIPT_FORM_DETAIL]: (
    state,
    payload: boolean
  ) => {
    state.isDeletingReceiptFormProviderDetail = payload;
  },
  [ReceiptFormProviderDetailMutations.SET_REQUEST_STATE_RECEIPT_FORM_DETAIL]: (
    state,
    payload: IRequestState
  ) => {
    state.requestStateReceiptFormProviderDetail = payload;
  },
};
