import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { MissionRequestDto } from "@/../Api";
import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { IMissionState } from "./states";

export const getters: GetterTree<IMissionState, RootState> = {
  missionsList: (state): MissionRequestDto[] => state.missionsList,
  missionsListTemplate: (state): MissionRequestDto[] =>
    state.missionsListTemplate,
  missionsListArchived: (state): MissionRequestDto[] =>
    state.missionsListArchived,
  missionsListByAffairId: (state): MissionRequestDto[] =>
    state.missionsListByAffairId,
  mission: (state): MissionRequestDto => state.mission,

  //FILTERS
  missionFilters: (state): any => state.missionFilters,
  missionFiltersByCollaborator: (state): any =>
    state.missionFiltersByCollaborator,

  // COMMON
  isLoadingMissionsList: (state): boolean => state.isLoadingMissionsList,
  isLoadingMissionsListTemplate: (state): boolean =>
    state.isLoadingMissionsListTemplate,
  isLoadingMissionsListArchived: (state): boolean =>
    state.isLoadingMissionsListArchived,
  isLoadingMissionsListByAffairId: (state): boolean =>
    state.isLoadingMissionsListByAffairId,
  isLoadingMission: (state): boolean => state.isLoadingMission,
  isCreatingMission: (state): boolean => state.isCreatingMission,
  isUpdatingMission: (state): boolean => state.isUpdatingMission,
  isDeletingMission: (state): boolean => state.isDeletingMission,
  isArchivingMission: (state): boolean => state.isArchivingMission,
  requestStateMission: (state): IRequestState => state.requestStateMission,
};
