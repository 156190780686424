import store from "@/store";
import {
  getNewIndex,
  calculateTotalReceiptFormProviderLine,
} from "@/types/api-orisis/library/DetailReceiptFormProviderOperations";
import { CommonLineReceiptFormProvider } from "@/types/api-orisis/models/LineModels";
import { ProductDefaultProperties } from "@/types/api-orisis/models/ProductModel";
import { ProductProviderDefaultProperties } from "@/types/api-orisis/models/ProductProviderModel";

export function calcNetToPay(priceTotalNet: number, priceTotalTtc: number) {
  let deduction = store.getters.deduction;
  let deductionNumber = 0;
  let result = 0;
  deductionNumber = deducePunctualDeductions(
    deductionNumber,
    deduction.punctualDeductions
  );
  deductionNumber = deduceHoldback(deductionNumber, deduction.holdbackNumber);
  deductionNumber = addProrataAccounts(
    priceTotalNet,
    deductionNumber,
    deduction
  );
  result = priceTotalTtc - deductionNumber;
  //result = Math.round((priceTotalTtc - deductionNumber) * 100) / 100;
  return result;
}

export function calculateSubTotalLine(line: any, newLine: boolean): void {
  let receiptFormProviderDetails = store.getters.getReceiptFormProviderDetails;
  let subTotal = 0;
  let i = line.indexArray;
  while (i >= 0 && receiptFormProviderDetails[i].type != 5) {
    if (
      receiptFormProviderDetails[i].type != 1 &&
      receiptFormProviderDetails[i].type != 2 &&
      receiptFormProviderDetails[i].type != 3 &&
      receiptFormProviderDetails[i].type != 4 &&
      receiptFormProviderDetails[i].type != 5 &&
      receiptFormProviderDetails[i].type != 6
    ) {
      subTotal += calculateTotalReceiptFormProviderLine(
        receiptFormProviderDetails[i]
      );
    }
    i--;
    if (i < 0) break;
  }
  line.total = line.unitPriceHt = Math.round(subTotal * 100) / 100;
  if (newLine) {
    getNewIndex(store.getters.getReceiptFormProviderDetails, line).then(
      async (res: any) => {
        let newObject = CommonLineReceiptFormProvider({
          ...line,
          index: res.index,
          type: res.type,
        });
        await store.dispatch("createReceiptFormDetail", {
          receiptFormDetail: newObject,
        });
      }
    );
  } else {
    store.dispatch("updateReceiptFormProviderDetail", {
      receiptFormDetail: line,
    });
  }
}

export function calculateSubTotalLineBySection(line: any): void {
  let receiptFormProviderDetails = store.getters.getReceiptFormProviderDetails;
  let subTotal = 0;
  let i = receiptFormProviderDetails.findIndex((el) => el.id == line.id);
  let j = 0;
  let startIndex = receiptFormProviderDetails.findIndex(
    (el, index) => el.type == 5 && index < i
  );
  let endIndex =
    line.type == 5
      ? i
      : receiptFormProviderDetails.findIndex(
          (el, index) => el.type == 5 && index > i
        );

  if (startIndex < 0) {
    startIndex = j = 0;
  } else {
    j = startIndex + 1;
  }
  if (endIndex >= 0) {
    let end = receiptFormProviderDetails[endIndex];

    while (j < endIndex) {
      if (
        receiptFormProviderDetails[j].type != 1 &&
        receiptFormProviderDetails[j].type != 2 &&
        receiptFormProviderDetails[j].type != 3 &&
        receiptFormProviderDetails[j].type != 4 &&
        receiptFormProviderDetails[j].type != 5 &&
        receiptFormProviderDetails[j].type != 6
      ) {
        subTotal += calculateTotalReceiptFormProviderLine(
          receiptFormProviderDetails[j]
        );
      }
      j++;
    }
    end.total = end.unitPriceHt = Math.round(subTotal * 100) / 100;
    store.dispatch("updateReceiptFormProviderDetail", {
      receiptFormDetail: end,
    });
  }
}

export function calculateSubTotalLines(update: boolean): void {
  let receiptFormProviderDetails = store.getters.getReceiptFormProviderDetails;
  let subTotalLines = receiptFormProviderDetails.filter((el) => el.type == 13);
  if (subTotalLines.length > 0) {
    for (let j = 0; j < subTotalLines.length; j++) {
      let subTotal = 0;
      const line = subTotalLines[j];
      let i =
        receiptFormProviderDetails.findIndex((el) => el.id == line.id) - 1;
      while (i >= 0 && receiptFormProviderDetails[i].type !== 5) {
        if (
          receiptFormProviderDetails[i].type != 1 &&
          receiptFormProviderDetails[i].type != 2 &&
          receiptFormProviderDetails[i].type != 3 &&
          receiptFormProviderDetails[i].type != 4 &&
          receiptFormProviderDetails[i].type != 5 &&
          receiptFormProviderDetails[i].type != 6
        ) {
          subTotal += calculateTotalReceiptFormProviderLine(
            receiptFormProviderDetails[i]
          );
        }
        i--;
        if (i < 0) break;
      }
      receiptFormProviderDetails[
        receiptFormProviderDetails.findIndex((el) => el.id == line.id)
      ].quantity = 1;
      receiptFormProviderDetails[
        receiptFormProviderDetails.findIndex((el) => el.id == line.id)
      ].total = receiptFormProviderDetails[
        receiptFormProviderDetails.findIndex((el) => el.id == line.id)
      ].unitPriceHt = Math.round(subTotal * 100) / 100;
      if (update) {
        store.dispatch(
          "updateReceiptFormProviderDetail",
          receiptFormProviderDetails[
            receiptFormProviderDetails.findIndex((el) => el.id == line.id)
          ]
        );
      }
    }
  }
}

export function calcTotalMarginHt(): number {
  let marginBrutHt = 0;
  let linesForCacul = store.getters.getReceiptFormProviderDetails.filter(
    (el) =>
      el.type != 0 &&
      el.type != 1 &&
      el.type != 2 &&
      el.type != 11 &&
      el.type != 12 &&
      el.type != 13 &&
      el.type != 14 &&
      el.type != 15 &&
      el.type != 16 &&
      el.type != 17 &&
      el.type != 18
  );
  for (let i = 0; i < linesForCacul.length; i++) {
    const line = linesForCacul[i];
    if (line.marginRate != null) {
      marginBrutHt = marginBrutHt + line.marginBrutHt * line.quantity;
    }
  }
  return Math.round(marginBrutHt * 100) / 100;
}

export function getReferencielTvaValueById(id: number): number {
  let result = 0;
  store.getters.institutionSettingsActive.referencielTva.find((item: any) => {
    if (item.referencielTva.id == id) {
      result = item.referencielTva.value;
    }
  });
  return result;
}

function deducePunctualDeductions(
  result: number,
  punctualDeductions: any
): number {
  if (punctualDeductions && punctualDeductions.length > 0) {
    punctualDeductions.forEach((item: any) => {
      result -= parseFloat(item.number);
    });
  } else {
    result = result;
  }
  return result;
}

function deduceHoldback(result: number, holdBackNumber: any): number {
  if (holdBackNumber) {
    result += parseFloat(holdBackNumber);
  } else {
    result = result;
  }
  return result;
}

function addProrataAccounts(
  priceTotalNet: number,
  result: number,
  deductions: any
): number {
  if (
    deductions.prorataAccountPrice != null &&
    deductions.prorataAccountPercent != null &&
    priceTotalNet != 0
  ) {
    result += deductions.prorataAccountPrice;
  } else {
    result = result;
  }
  return result;
}

export function getPricesByPriceHt(
  initialReceiptFormProvider,
  newDownPaymentInvoiceForm,
  route,
  deductionInvoicesPrice = null
) {
  let receiptFormProvider = JSON.parse(
    JSON.stringify(initialReceiptFormProvider)
  );
  let arr =
    route == "invoices"
      ? receiptFormProvider.receiptFormProviderDetails
      : store.getters.getReceiptFormProviderDetails;
  let result = {
    totalHt: 0,
    totalTtc: 0,
  };
  const res = arr.reduce((acc: any, obj: any) => {
    let findIndex = acc.findIndex(
      (el: any) => el.referencielTvaId === obj.referencielTvaId
    );
    if (
      obj.type != 0 &&
      obj.type != 1 &&
      obj.type != 2 &&
      obj.type != 11 &&
      obj.type != 12 &&
      obj.type != 13 &&
      obj.type != 14 &&
      obj.type != 15 &&
      obj.type != 16 &&
      obj.type != 17 &&
      obj.type != 18
    ) {
      let priceHt = getPriceHt(
        obj,
        receiptFormProvider,
        deductionInvoicesPrice
      );
      let priceTtc =
        priceHt * (1 + getReferencielTvaValueById(obj.referencielTvaId) / 100);
      let coeff = priceHt / receiptFormProvider.totalHt;

      let advancementHt = newDownPaymentInvoiceForm.value * coeff;
      let advancementTtc =
        advancementHt *
        (1 + getReferencielTvaValueById(obj.referencielTvaId) / 100);

      if (findIndex < 0) {
        acc.push({
          priceHt: priceHt,
          priceTtc: priceTtc,
          coeff: coeff,
          advancementHt: advancementHt,
          advancementTtc: advancementTtc,
          referencielTvaId: obj.referencielTvaId,
        });
      } else {
        acc[findIndex].priceHt += priceHt;
        acc[findIndex].priceTtc += priceTtc;
        acc[findIndex].coeff =
          acc[findIndex].priceHt / receiptFormProvider.totalHt;
        acc[findIndex].advancementHt =
          newDownPaymentInvoiceForm.value * acc[findIndex].coeff;
        acc[findIndex].advancementTtc =
          acc[findIndex].advancementHt *
          (1 + getReferencielTvaValueById(obj.referencielTvaId) / 100);
      }
    }
    return acc;
  }, []);
  res.forEach((element) => {
    result.totalHt =
      Math.round((result.totalHt + element.advancementHt) * 100) / 100;
    result.totalTtc =
      Math.round((result.totalTtc + element.advancementTtc) * 100) / 100;
  });
  return { tvaLine: res, totals: result };
}

export function getPricesByPriceTtc(
  initialReceiptFormProvider,
  newDownPaymentInvoiceForm,
  route,
  deductionInvoicesPrice = null
) {
  let receiptFormProvider = JSON.parse(
    JSON.stringify(initialReceiptFormProvider)
  );
  let arr =
    route == "invoices"
      ? receiptFormProvider.receiptFormProviderDetails
      : store.getters.getReceiptFormProviderDetails;
  let result = {
    totalHt: 0,
    totalTtc: 0,
  };

  const res = arr.reduce((acc: any, obj: any) => {
    let findIndex = acc.findIndex(
      (el: any) => el.referencielTvaId === obj.referencielTvaId
    );
    if (
      obj.type != 0 &&
      obj.type != 1 &&
      obj.type != 2 &&
      obj.type != 11 &&
      obj.type != 12 &&
      obj.type != 13 &&
      obj.type != 14 &&
      obj.type != 15 &&
      obj.type != 16 &&
      obj.type != 17 &&
      obj.type != 18
    ) {
      let priceHt = getPriceHt(
        obj,
        receiptFormProvider,
        deductionInvoicesPrice
      );
      let priceTtc =
        priceHt * (1 + getReferencielTvaValueById(obj.referencielTvaId) / 100);
      let coeff = priceTtc / receiptFormProvider.totalTtc;
      let advancementTtc = newDownPaymentInvoiceForm.value * coeff;
      let advancementHt =
        (advancementTtc * 100) /
        (100 + getReferencielTvaValueById(obj.referencielTvaId));

      if (findIndex < 0) {
        acc.push({
          priceHt: priceHt,
          priceTtc: priceTtc,
          coeff: coeff,
          advancementHt: advancementHt,
          advancementTtc: advancementTtc,
          referencielTvaId: obj.referencielTvaId,
        });
      } else {
        acc[findIndex].priceHt += priceHt;
        acc[findIndex].priceTtc += priceTtc;
        acc[findIndex].coeff =
          acc[findIndex].priceTtc / receiptFormProvider.totalTtc;
        acc[findIndex].advancementTtc =
          newDownPaymentInvoiceForm.value * acc[findIndex].coeff;
        acc[findIndex].advancementHt =
          (acc[findIndex].advancementTtc * 100) /
          (100 + getReferencielTvaValueById(obj.referencielTvaId));
      }
    }
    return acc;
  }, []);
  res.forEach((element) => {
    result.totalHt =
      Math.round((result.totalHt + element.advancementHt) * 100) / 100;
    result.totalTtc =
      Math.round((result.totalTtc + element.advancementTtc) * 100) / 100;
  });
  return { tvaLine: res, totals: result };
}

export function getPricesByPourcentagePriceTtc(
  initialReceiptFormProvider,
  newDownPaymentInvoiceForm,
  route,
  deductionInvoicesPrice = null
) {
  let receiptFormProvider = JSON.parse(
    JSON.stringify(initialReceiptFormProvider)
  );
  let totalHt: number = 0;
  let arr =
    route == "invoices"
      ? receiptFormProvider.receiptFormProviderDetails
      : store.getters.getReceiptFormProviderDetails;
  let result = {
    totalHt: 0,
    totalTtc: 0,
  };

  const res = arr.reduce((acc: any, obj: any) => {
    let findIndex = acc.findIndex(
      (el: any) => el.referencielTvaId === obj.referencielTvaId
    );
    if (
      obj.type != 0 &&
      obj.type != 1 &&
      obj.type != 2 &&
      obj.type != 11 &&
      obj.type != 12 &&
      obj.type != 13 &&
      obj.type != 14 &&
      obj.type != 15 &&
      obj.type != 16 &&
      obj.type != 17 &&
      obj.type != 18
    ) {
      let priceHt = getPriceHt(
        obj,
        receiptFormProvider,
        deductionInvoicesPrice
      );
      let priceTtc =
        priceHt * (1 + getReferencielTvaValueById(obj.referencielTvaId) / 100);
      let coeff = priceTtc / Number(receiptFormProvider.totalTtc);
      let value =
        route == "credit"
          ? 100 - newDownPaymentInvoiceForm.value
          : newDownPaymentInvoiceForm.value;
      let advancementHt =
        newDownPaymentInvoiceForm.value == 0 ? 0 : priceHt * (value / 100);
      let advancementTtc =
        advancementHt *
        (1 + getReferencielTvaValueById(obj.referencielTvaId) / 100);
      if (findIndex < 0) {
        acc.push({
          priceHt: priceHt,
          priceTtc: priceTtc,
          coeff: coeff,
          advancementHt: advancementHt,
          advancementTtc: advancementTtc,
          referencielTvaId: obj.referencielTvaId,
        });
      } else {
        acc[findIndex].priceHt += priceHt;
        acc[findIndex].priceTtc += priceTtc;
        acc[findIndex].coeff =
          acc[findIndex].priceTtc / receiptFormProvider.totalTtc;
        acc[findIndex].advancementHt =
          newDownPaymentInvoiceForm.value == 0
            ? 0
            : acc[findIndex].priceHt * (newDownPaymentInvoiceForm.value / 100);
        acc[findIndex].advancementTtc =
          acc[findIndex].advancementHt *
          (1 + getReferencielTvaValueById(obj.referencielTvaId) / 100);
      }
    }
    return acc;
  }, []);
  res.forEach((element) => {
    result.totalHt =
      Math.round((result.totalHt + element.advancementHt) * 100) / 100;
    result.totalTtc =
      Math.round((result.totalTtc + element.advancementTtc) * 100) / 100;
  });
  return { tvaLine: res, totals: result };
}

export function getPricesByPourcentagePriceTtcByCredit(
  initialReceiptFormProvider,
  newDownPaymentInvoiceForm,
  route,
  deductionInvoicesPrice = null
) {
  let receiptFormProvider = JSON.parse(
    JSON.stringify(initialReceiptFormProvider)
  );
  let totalHt: number = 0;
  let arr =
    route == "invoices"
      ? receiptFormProvider.receiptFormProviderDetails
      : store.getters.getReceiptFormProviderDetails;
  let result = {
    totalHt: 0,
    totalTtc: 0,
  };

  const res = arr.reduce((acc: any, obj: any) => {
    let findIndex = acc.findIndex(
      (el: any) => el.referencielTvaId === obj.referencielTvaId
    );
    if (
      obj.type != 0 &&
      obj.type != 1 &&
      obj.type != 2 &&
      obj.type != 11 &&
      obj.type != 12 &&
      obj.type != 13
    ) {
      let priceHt = getPriceHt(
        obj,
        receiptFormProvider,
        deductionInvoicesPrice
      );
      let priceTtc =
        priceHt * (1 + getReferencielTvaValueById(obj.referencielTvaId) / 100);
      let coeff = priceTtc / Number(receiptFormProvider.totalTtc);
      let value =
        route == "credit"
          ? 100 - newDownPaymentInvoiceForm.value
          : newDownPaymentInvoiceForm.value;
      let advancementHt =
        newDownPaymentInvoiceForm.value == 0 ? 0 : priceHt * (value / 100);
      let advancementTtc =
        advancementHt *
        (1 + getReferencielTvaValueById(obj.referencielTvaId) / 100);
      if (findIndex < 0) {
        acc.push({
          priceHt: priceHt,
          priceTtc: priceTtc,
          coeff: coeff,
          advancementHt: advancementHt,
          advancementTtc: advancementTtc,
          referencielTvaId: obj.referencielTvaId,
        });
      } else {
        acc[findIndex].priceHt += priceHt;
        acc[findIndex].priceTtc += priceTtc;
        acc[findIndex].coseff =
          acc[findIndex].priceTtc / receiptFormProvider.totalTtc;
        acc[findIndex].advancementHt =
          newDownPaymentInvoiceForm.value == 0
            ? 0
            : acc[findIndex].priceHt * (newDownPaymentInvoiceForm.value / 100);
        acc[findIndex].advancementTtc =
          acc[findIndex].advancementHt *
          (1 + getReferencielTvaValueById(obj.referencielTvaId) / 100);
      }
    }
    return acc;
  }, []);
  if (deductionInvoicesPrice) {
    totalHt =
      Math.round(
        res.reduce((acc, obj) => {
          return (acc += obj.priceHt);
        }, 0) * 100
      ) / 100;
  }
  res.forEach((element) => {
    if (deductionInvoicesPrice) {
      let coeff = totalHt == 0 ? 1 : element.priceHt / totalHt;
      element.advancementHt =
        element.advancementHt - coeff * deductionInvoicesPrice.totalHt;
    }
    result.totalHt =
      Math.round((result.totalHt + element.advancementHt) * 100) / 100;
    result.totalTtc =
      Math.round((result.totalTtc + element.advancementTtc) * 100) / 100;
  });
  if (deductionInvoicesPrice) {
    result.totalTtc -= deductionInvoicesPrice.totalTtc;
  }
  return { tvaLine: res, totals: result };
}

function getPriceHt(obj, receiptFormProvider, deductionInvoicesPrice = null) {
  let priceHt =
    obj.unitPriceHt * obj.quantity * 1 * ((100 - obj.discount) / 100);

  if (receiptFormProvider.discountGlobalType == 0) {
    let discountGlobalNumberHt =
      priceHt *
      ((receiptFormProvider.discountGlobalNumber
        ? receiptFormProvider.discountGlobalNumber
        : 0) /
        100);
    // let discount = Math.round(((Math.round((Number(discountGlobalNumberHt) + (deductionInvoicesPrice ? Number(deductionInvoicesPrice.totalHt) : 0)) * 100) / 100)) * 100) / 100;
    let discount = Number(discountGlobalNumberHt);
    return priceHt - discount;
    // return Math.round((priceHt * (1 - ((receiptFormProvider.discountGlobalNumber ? receiptFormProvider.discountGlobalNumber : 0) / 100))) * 100) / 100;
  } else if (receiptFormProvider.discountGlobalType == 1) {
    let coeff =
      priceHt /
      (Number(receiptFormProvider.totalHt) +
        Number(receiptFormProvider.discountGlobalNumber));
    let discount = Number(receiptFormProvider.discountGlobalNumber) * coeff;
    // let coeff = priceHt / (Number(receiptFormProvider.totalHt) + Number(receiptFormProvider.discountGlobalNumber) + (deductionInvoicesPrice ? Number(deductionInvoicesPrice.totalHt) : 0));
    // let discount = Math.round(((Math.round((Number(receiptFormProvider.discountGlobalNumber) + (deductionInvoicesPrice ? Number(deductionInvoicesPrice.totalHt) : 0)) * 100) / 100) * coeff) * 100) / 100;
    return priceHt - discount;
  }
  return priceHt;
}

export function getTotalDeduction() {
  let result = 0;
  let deduction = store.getters.deduction;
  let holdBackNumber = deduction.holdbackNumber
    ? Number(deduction.holdbackNumber)
    : 0;
  let prorataAccountPrice = deduction.prorataAccountPrice
    ? Number(deduction.prorataAccountPrice)
    : 0;
  let punctualDeductionPrice = deduction.punctualDeductions.reduce(
    (acc, obj) => {
      return (acc = Math.round((acc + obj.number) * 100) / 100);
    },
    0
  );
  return (result =
    holdBackNumber + prorataAccountPrice + punctualDeductionPrice);
}

export function executeMultipleAsyncFunctions(list: any[]) {
  return new Promise<void>((resolve, reject) => {
    const promises = [];
    for (let i = 0; i < list.length; i++) {
      const el = list[i];
      let product = ProductDefaultProperties({
        reference: el.reference,
        priceHT: 0,
        productType: el.type == 7 ? 0 : el.type == 8 ? 5 : el.type == 9 ? 2 : 6,
        description: el.description,
        unAvailableForSale: true,
      });
      promises.push(asyncFunction(product, el));
    }

    Promise.all(promises)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
}

function asyncFunction(product, el) {
  return new Promise<void>((resolve, reject) => {
    store
      .dispatch("createProduct", { product: product })
      .then((productResponse) => {
        let productProvider = ProductProviderDefaultProperties({
          productId: productResponse.data.id,
          companyId: store.getters.receiptFormProvider.companyProviderId,
          reference: el.reference,
          netPrice: el.unitPriceHt,
          productType:
            el.type == 7 ? 0 : el.type == 8 ? 5 : el.type == 9 ? 2 : 6,
          description: el.description,
          unitId: el.unitId,
        });
        if (el.productProviderId) {
          store
            .dispatch("updateProductProvider", {
              productProvider: productProvider,
              reload: false,
            })
            .then((productProviderResponse) => {
              el.productProviderId = productProviderResponse.data.data.id;
              store
                .dispatch("updateReceiptFormProviderDetail", {
                  receiptFormDetail: el,
                })
                .then(() => {
                  resolve();
                })
                .catch((err) => {
                  console.error(err);
                  reject(err);
                });
            })
            .catch((err) => {
              console.error(err);
              reject(err);
            });
        } else {
          store
            .dispatch("createProductProvider", {
              productProvider: productProvider,
              reload: false,
            })
            .then((productProviderResponse) => {
              el.productProviderId = productProviderResponse.data.data.id;
              store
                .dispatch("updateReceiptFormProviderDetail", {
                  receiptFormDetail: el,
                })
                .then(() => {
                  resolve();
                })
                .catch((err) => {
                  console.error(err);
                  reject(err);
                });
            })
            .catch((err) => {
              console.error(err);
              reject(err);
            });
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
}

export function checkMandatoryInformation() {
  return new Promise((resolve, reject) => {
    if (
      store.getters.receiptFormProvider.companyProviderId == null ||
      store.getters.receiptFormProvider.companyProviderId == 0
    ) {
      reject("Veuillez sélectionner un fournisseur");
    } else if (
      store.getters.receiptFormProvider.companyCustomerId &&
      (store.getters.receiptFormProvider.affairId == null ||
        store.getters.receiptFormProvider.affairId == 0)
    ) {
      reject("Veuillez sélectionner une affaire");
    } else if (
      !store.getters.receiptFormProvider.storageLocationId &&
      !store.getters.receiptFormProvider.deliverToAffairAddress
    ) {
      reject(
        "Veuillez sélectionner un point de stockage ou indiquer « Reçu sur le lieu de l'affaire »"
      );
    } else if (
      store.getters.getReceiptFormProviderDetails.filter(
        (detail) =>
          (detail.type == 7 ||
            detail.type == 8 ||
            detail.type == 9 ||
            detail.type == 10) &&
          (detail.reference == null || detail.reference == "")
      ).length > 0
    ) {
      reject(
        "Veuillez completer les références des lignes du bon de réception"
      );
    } else {
      resolve(true);
    }
  });
}
