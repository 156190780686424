import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import RootState from "@/store/modules/rootState";
import { IDocumentState } from "./states";
import { Module } from "vuex";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { IDocument } from "@/types/api-orisis/interfaces/invoices/document/IDocument";
import { IDocumentStats } from "@/types/api-orisis/interfaces/invoices/document/IDocumentStats";
import { IRemaining } from "@/types/api-orisis/interfaces/invoices/document/IRemaining";
import { IPaymentMethod } from "@/types/api-orisis/interfaces/invoices/paymentMethod/IPaymentMethod";
import { INewCreditForm } from "@/types/api-orisis/interfaces/invoices/document/INewCreditForm";
import { IFilters } from "@/types/api-orisis/interfaces/invoices/filters/IFilters";

export const state: IDocumentState = {
  quotesList: Array<IDocument>(),
  quotesListTemplates: Array<IDocument>(),
  quotesListSigned: Array<IDocument>(),
  quotesListArchived: Array<IDocument>(),
  quotesListByAffair: Array<IDocument>(),
  quotesListByCompany: Array<IDocument>(),

  quotesListStats: <IDocumentStats>{},
  quotesListSignedStats: <IDocumentStats>{},
  quotesListLastYearStats: <IDocumentStats>{},

  invoicesList: Array<IDocument>(),
  invoicesListTemplates: Array<IDocument>(),
  invoicesListArchived: Array<IDocument>(),
  invoicesListByAffair: Array<IDocument>(),
  invoicesListByCompany: Array<IDocument>(),

  invoicesListStats: <IDocumentStats>{},
  invoicesListLastYearStats: <IDocumentStats>{},
  invoicesRemainingToPay: <IRemaining>{},

  creditsListStats: <IDocumentStats>{},
  creditsListLastYearStats: <IDocumentStats>{},

  creditsList: Array<IDocument>(),
  creditsListArchived: Array<IDocument>(),
  creditsListByAffair: Array<IDocument>(),
  creditsListByCompany: Array<IDocument>(),
  creditByInvoice: <IDocument>{},
  document: <IDocument>{},
  documentPDF: "",
  initialInvoice: <IDocument>{},
  lastInvoice: null,
  documentPaymentMethods: Array<IPaymentMethod>(),
  isValidatingDocument: false,
  isLoadingInvoicesRemainingToPay: false,
  isLoadingDocumentsList: false,
  isLoadingQuotesList: false,
  isLoadingQuotesListStats: false,
  isLoadingInvoicesList: false,
  isLoadingInvoicesListStats: false,
  isLoadingInvoicesListLastYearStats: false,
  isLoadingCreditsList: false,
  isLoadingCreditsListStats: false,
  isLoadingCreditsListLastYearStats: false,
  isLoadingDocumentsListArchived: false,
  isLoadingDocumentsListByCompany: false,
  isLoadingDocumentsListByAffair: false,
  isLoadingDocument: false,
  isLoadingDocumentPDF: false,
  isLoadingDuplicatingDocument: false,
  isCreatingDocument: false,
  isCreatingLeaseDocument: false,
  isUpdatingDocument: false,
  isDeletingDocument: false,
  requestStateDocument: <IRequestState>{},
  requestStateDocumentPDF: <IRequestState>{},
  isArchivingDocument: false,
  marginStatus: true,
  documentTabActive: "document-edit",
  loadingMsg: "Chargement en cours...",
  newCreditForm: <INewCreditForm>{},
  isTotalCredit: false,
  invoiceFilters: <IFilters>{},
  quoteFilters: <IFilters>{},
  creditFilters: <IFilters>{},
  isSendingEmail: false,
};

export const DocumentModule: Module<IDocumentState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
