import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import authService from "@/auth/authService";
// RouterTabRoutes
import { RouterTabRoutes } from "vue-router-tab";
import Frame from "@/layouts/Frame.vue";
import { checkFunctionUser } from "@/auth/utils";
import "material-icons/iconfont/material-icons.css"; //Material Icons

Vue.use(VueRouter);

export const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      // parent component must contain <router-tab>
      component: Frame,
      // routes that serve as tab contents
      children: [
        // integrate RouterTabRoutes to support iframe tabs
        ...RouterTabRoutes,
        {
          path: "/",
          name: "home",
          component: () => import("@/views/Dashboard.vue"),
          meta: {
            pageTitle: "Tableau de bord",
            breadcrumb: [
              {
                text: "Tableau de bord",
                active: true,
              },
            ],
            title: "Tableau de bord",
            tips: "Tableau de bord",
            key: "path",
            closable: true,
            functionIds: [134],
          },
        },
        {
          path: "/my-agenda",
          name: "my-agenda",
          component: () => import("@/views/MyDashboard.vue"),
          meta: {
            pageTitle: "Mon agenda",
            breadcrumb: [
              {
                text: "Mon agenda",
                active: true,
              },
            ],
            title: "Mon agenda",
            tips: "Mon agenda",
            key: "path",
            closable: true,
          },
        },
        {
          path: "/dashboard-analytique",
          name: "dashboard-analytique",
          component: () => import("@/views/Dashboard.vue"),
          meta: {
            pageTitle: "Tableau de bord Analytique",
            breadcrumb: [
              {
                text: "Tableau de bord Analytique",
                active: true,
              },
            ],
            title: "Tableau de bord Analytique",
            tips: "Tableau de bord Analytique",
            key: "path",
            closable: true,
            functionIds: [134],
          },
        },
        {
          path: "/analytical-results",
          name: "analytical-results",
          component: () => import("@/views/AnalyticalResults.vue"),
          meta: {
            pageTitle: "Résultats analytiques",
            breadcrumb: [
              {
                text: "Résultats analytiques",
                active: true,
              },
            ],
            title: "Résultats analytiques",
            tips: "Résultats analytiques",
            key: "path",
            closable: true,
            functionIds: [139],
          },
        },
        {
          path: "/analytical-entries",
          name: "analytical-entries",
          component: () => import("@/views/AnalyticalEntries.vue"),
          meta: {
            pageTitle: "Ecritures analytiques",
            breadcrumb: [
              {
                text: "Ecritures analytiques",
                active: true,
              },
            ],
            title: "Ecritures analytiques",
            tips: "Ecritures analytiques",
            key: "path",
            closable: true,
            functionIds: [140],
          },
        },
        {
          path: "/dashboard-crm",
          name: "dashboard-crm",
          component: () => import("@/views/DashboardCRM.vue"),
          meta: {
            pageTitle: "Tableau de bord CRM",
            breadcrumb: [
              {
                text: "Tableau de bord CRM",
                active: true,
              },
            ],
            title: "Tableau de bord CRM",
            tips: "Tableau de bord CRM",
            key: "path",
            closable: true,
          },
        },
        {
          path: "/dashboard-immo",
          name: "dashboard-immo",
          component: () => import("@/views/DashboardImmo.vue"),
          meta: {
            pageTitle: "Tableau de bord Immo",
            breadcrumb: [
              {
                text: "Tableau de bord Immo",
                active: true,
              },
            ],
            title: "Tableau de bord Immo",
            tips: "Tableau de bord Immo",
            key: "path",
            closable: true,
          },
        },
        {
          path: "/dashboard-gestion",
          name: "dashboard-gestion",
          component: () => import("@/views/DashboardGestion.vue"),
          meta: {
            pageTitle: "Tableau de bord Gestion",
            breadcrumb: [
              {
                text: "Tableau de bord Gestion",
                active: true,
              },
            ],
            title: "Tableau de bord Gestion",
            tips: "Tableau de bord de gestion",
            key: "path",
            closable: true,
          },
        },
        {
          path: "/dashboard-production",
          name: "dashboard-production",
          component: () => import("@/views/DashboardProduction.vue"),
          meta: {
            pageTitle: "Planning / Production",
            breadcrumb: [
              {
                text: "Planning / Production",
                active: true,
              },
            ],
            title: "Planning / Production",
            tips: "Tableau de bord de production",
            key: "path",
            closable: true,
          },
        },
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/views/Dashboard.vue"),
          meta: {
            pageTitle: "Tableau de bord",
            breadcrumb: [
              {
                text: "Tableau de bord",
                active: true,
              },
            ],
            title: "Tableau de bord",
            tips: "Tableau de bord",
            key: "path",
            closable: true,
          },
        },

        /* *** ROUTES D'ADMINISTRATION GLOBALE */
        {
          path: "/admin/admin-users",
          name: "adminUsers",
          component: () => import("@/views/account/users/AdminUsers.vue"),
          meta: {
            pageTitle: "Gestion des utilisateurs",
            breadcrumb: [
              {
                text: "Gestion des utilisateurs",
                active: true,
              },
            ],
            title: "Utilisateurs",
            icon: "icon-home",
            tabClass: "custom-tab", // custom class, optional
            tips: "Gestion des utilisateurs ORISIS",
            key: "path",
            closable: true,
            role: "admin",
            closeOnSwitchWorkspace: true,
            functionIds: [89],
          },
        },
        {
          path: "/admin/new-profile",
          name: "new-profile",
          component: () => import("@/views/account/users/EditProfile.vue"),
          props: true,
          meta: {
            pageTitle: "Nouveau profil",
            breadcrumb: [
              {
                text: "Profils",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Nouveau profil",
            icon: "icon-home",
            tips: "Créer un nouveau profil",
            key: "path",
            closable: true,
            role: "admin",
            closeOnSwitchWorkspace: true,
            functionIds: [9],
          },
        },
        {
          path: "/admin/new-module-parameter",
          name: "new-module-parameter",
          component: () =>
            import("@/views/account/moduleParameter/ModuleParameters.vue"),
          meta: {
            pageTitle: "Nouveau périmètre",
            breadcrumb: [
              {
                text: "Profils",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Nouveau périmètre",
            icon: "icon-home",
            tips: "Créer un nouveau périmètre",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [9],
          },
        },
        {
          path: "/admin/admin-workspaces",
          name: "adminWorkspaces",
          component: () => import("@/views/account/workspace/Workspaces.vue"),
          meta: {
            pageTitle: "Environnements",
            breadcrumb: [
              {
                text: "Environnements",
                active: true,
              },
            ],
            title: "Workspaces",
            icon: "icon-home",
            tabClass: "custom-tab", // custom class, optional
            tips: "Gestion des bases de données des clients",
            key: "path",
            closable: true,
            role: "admin",
            closeOnSwitchWorkspace: true,
          },
        },
        {
          path: "/institutions",
          name: "institutions",
          component: () => import("@/views/institution/Institutions.vue"),
          meta: {
            pageTitle: "Mes sociétés",
            breadcrumb: [
              {
                text: "Mes sociétés",
                active: true,
              },
            ],
            title: "Mes sociétés",
            icon: "icon-home",
            tabClass: "custom-tab", // custom class, optional
            tips: "Mes sociétés",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [63],
          },
        },
        {
          path: "/new-institution",
          name: "new-institution",
          component: () => import("@/views/institution/EditInstitution.vue"),
          props: true,
          beforeEnter: async (to, from, next) => {
            if (
              store.state.InstitutionModule.institution &&
              store.state.InstitutionModule.institution.id == to.params.id
            ) {
              let documentStored = store.state.InstitutionModule.institution;
              to.meta.title = documentStored.name
                ? "Société : " + documentStored.name
                : "Nouvelle société";
            } else {
              if (to.params.id && to.params.id > 0) {
                let loadId = to.params.id ? to.params.id : 0;
                await Promise.all([
                  store.dispatch("getInstitution", {}),
                  store.dispatch("getQuoteOptionDefaultByQuoteId", {}),
                  store.dispatch(
                    "getOrderFormCustomerOptionDefaultByOrderFormCustomerId",
                    {}
                  ),
                  store.dispatch("getInvoiceOptionDefaultByInvoiceId", {}),
                  store.dispatch("getCreditOptionDefaultByCreditId", {}),
                  store.dispatch("getOrderFormOptionDefaultByOrderFormId", {}),
                  store.dispatch(
                    "getReceiptFormOptionDefaultByReceiptFormId",
                    {}
                  ),
                  store.dispatch(
                    "getDeliveryFormOptionDefaultByDeliveryFormId",
                    {}
                  ),
                ]).then((res) => {
                  to.meta.title = res.name
                    ? "Société : " + res.name
                    : "Nouvelle société";
                });
              } else {
                to.meta.title = "Nouvelle société";
              }
            }
            next();
          },
          meta: {
            pageTitle: "Nouvelle société",
            breadcrumb: [
              {
                text: "Sociétés",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Nouvelle société",
            icon: "icon-home",
            tips: "Créer une nouvelle société",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [63],
          },
        },
        {
          path: "/edit-institution/:id",
          name: "edit-institution",
          component: () => import("@/views/institution/EditInstitution.vue"),
          props: true,
          beforeEnter: async (to, from, next) => {
            if (
              store.state.InstitutionModule.institution &&
              store.state.InstitutionModule.institution.id == to.params.id
            ) {
              let documentStored = store.state.InstitutionModule.institution;
              to.meta.title = documentStored.name
                ? "Société : " + documentStored.name
                : "Nouvelle société";
            } else {
              if (to.params.id && to.params.id > 0) {
                let loadId = to.params.id ? to.params.id : 0;
                await store.dispatch("getInstitution", {}).then((res) => {
                  to.meta.title = store.getters.institutionSelected //res.name
                    ? "Société : " + store.getters.institutionSelected.name
                    : "Nouvelle société";
                });
              } else {
                to.meta.title = "Nouvelle société";
              }
            }
            next();
          },
          meta: {
            pageTitle: "Fiche société",
            breadcrumb: [
              {
                text: "Sociétés",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Editer une société",
            icon: "icon-home",
            tips: "Editer une société",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [63],
          },
        },

        /* *** Gestion du compte *** */
        {
          path: "/account",
          name: "account",
          component: () => import("@/views/account/users/Account.vue"),
          meta: {
            pageTitle: "Mon compte",
            breadcrumb: [
              {
                text: "Mon compte",
                active: true,
              },
            ],
            title: "Mon compte",
            icon: "icon-user",
            tips: "Gestion de mon compte ORISIS",
            key: "path",
            closable: true,
          },
        },

        /* *** Gestion des Users *** */
        {
          path: "/users",
          name: "users",
          component: () => import("@/views/directory/user/Users.vue"),
          meta: {
            pageTitle: "Utilisateurs",
            breadcrumb: [
              {
                text: "Utilisateurs",
                active: true,
              },
            ],
            title: "Utilisateurs",
            icon: "icon-users",
            tips: "Liste des utilisateurs",
            key: "path",
            closable: true,
            role: "admin",
            functionIds: [89],
          },
        },
        {
          path: "/new-user",
          name: "new-user",
          component: () => import("@/views/directory/user/EditUser.vue"),
          props: true,
          meta: {
            pageTitle: "Nouvel utilisateur",
            breadcrumb: [
              {
                text: "Utilisateurs",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Nouvel utilisateur",
            icon: "icon-home",
            tips: "Créer un nouveau utilisateur",
            key: "path",
            closable: true,
            role: "admin",
            closeOnSwitchWorkspace: true,
            functionIds: [89],
          },
        },
        {
          path: "/edit-user/:id",
          name: "edit-user",
          component: () => import("@/views/directory/user/EditUser.vue"),
          props: true,
          meta: {
            pageTitle: "Informations",
            breadcrumb: [
              {
                text: "Utilisateurs",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Editer un utilisateur",
            icon: "icon-home",
            tips: "Editer un utilisateur",
            key: "path",
            closable: true,
            role: "admin",
            closeOnSwitchWorkspace: true,
            functionIds: [89],
          },
        },
        {
          path: "/user/:id",
          name: "user",
          component: () => import("@/views/directory/user/User.vue"),
          props: true,
          meta: {
            pageTitle: "Utilisateur",
            breadcrumb: [
              {
                text: "Utilisateurs",
                active: true,
              },
              {
                text: "Consulter",
                active: true,
              },
            ],
            title: "Consulter un utilisateur",
            icon: "icon-home",
            tips: "Consulter un utilisateur",
            key: "path",
            closable: true,
            role: "admin",
            closeOnSwitchWorkspace: true,
            functionIds: [89],
          },
        },

        /* *** Gestion des Collaborateurs *** */
        {
          path: "/collaborators",
          name: "collaborators",
          component: () =>
            import("@/views/directory/collaborator/Collaborators.vue"),
          meta: {
            pageTitle: "Collaborateurs",
            breadcrumb: [
              {
                text: "Collaborateurs",
                active: true,
              },
            ],
            title: "Collaborateurs",
            icon: "icon-users",
            tips: "Liste des collaborateurs",
            key: "path",
            closable: true,
            functionIds: [5],
          },
        },
        {
          path: "/new-collaborator",
          name: "new-collaborator",
          component: () =>
            import("@/views/directory/collaborator/EditCollaborator.vue"),
          props: true,
          meta: {
            pageTitle: "Nouveau collaborateur",
            breadcrumb: [
              {
                text: "Collaborateurs",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Nouveau collaborateur",
            icon: "icon-home",
            tips: "Créer un nouveau collaborateur",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [5],
          },
        },
        {
          path: "/edit-collaborator/:id",
          name: "edit-collaborator",
          component: () =>
            import("@/views/directory/collaborator/EditCollaborator.vue"),
          props: true,
          meta: {
            pageTitle: "Fiche collaborateur",
            breadcrumb: [
              {
                text: "Collaborateurs",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Editer un collaborateur",
            icon: "icon-home",
            tips: "Editer un collaborateur",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [5],
          },
        },
        /* *** Gestion des Affaires *** */
        {
          path: "/tags",
          name: "tags",
          component: () => import("@/views/parameters/tags/Tags.vue"),
          meta: {
            pageTitle: "Tags",
            breadcrumb: [
              {
                text: "Tags",
                active: true,
              },
            ],
            title: "Tags",
            icon: "icon-users",
            tips: "Liste des tags",
            key: "path",
            closable: true,
          },
        },
        /* *** Gestion des Affaires *** */
        {
          path: "/affairs",
          name: "affairs",
          component: () => import("@/views/directory/affair/Affairs.vue"),
          meta: {
            pageTitle: "Affaires",
            breadcrumb: [
              {
                text: "Affaires",
                active: true,
              },
            ],
            title: "Affaires",
            icon: "icon-users",
            tips: "Liste des affaires",
            key: "path",
            closable: true,
            functionIds: [1],
          },
        },
        {
          path: "/affairs/:id",
          name: "affair",
          component: () => import("@/views/directory/affair/Affair.vue"),
          props: true,
          beforeEnter: async (to, from, next) => {
            // if (
            //   store.state.AffairModule.affair &&
            //   store.state.AffairModule.affair.id == to.params.id &&
            //   store.state.AffairModule.affair.id > 0 &&
            //   to.params.id &&
            //   to.params.id > 0
            // ) {
            //   let documentStored = store.state.AffairModule.affair;
            //   to.meta.title =
            //     documentStored.name && to.name == "edit-affair"
            //       ? "Edit : " + documentStored.name
            //       : documentStored.name && to.name == "affair"
            //       ? "Affaire : " + documentStored.name
            //       : "Nouvelle affaire";
            // } else {
            let loadId = to.params.id ? to.params.id : 0;
            await store
              .dispatch("getAffairById", { affairId: loadId })
              .then((res) => {
                to.meta.title =
                  res.name && to.name == "edit-affair"
                    ? "Edit : " + res.name
                    : res.name && to.name == "affair"
                    ? "Affaire : " + res.name
                    : "Nouvelle affaire";
              });
            // }
            next();
          },
          meta: {
            pageTitle: "Fiche affaire",
            breadcrumb: [
              {
                text: "Affaires",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Fiche affaire",
            icon: "icon-home",
            tips: "Fiche affaire",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [1],
          },
        },
        {
          path: "/new-affair",
          name: "new-affair",
          component: () => import("@/views/directory/affair/EditAffair.vue"),
          props: true,
          meta: {
            pageTitle: "Nouvelle affaire",
            breadcrumb: [
              {
                text: "Affaires",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Nouvelle affaire",
            icon: "icon-home",
            tips: "Créer une nouvelle affaire",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [1],
          },
        },
        {
          path: "/edit-affair/:id",
          name: "edit-affair",
          component: () => import("@/views/directory/affair/EditAffair.vue"),
          props: true,
          beforeEnter: async (to, from, next) => {
            // if (
            //   store.state.AffairModule.affair &&
            //   store.state.AffairModule.affair.id == to.params.id &&
            //   store.state.AffairModule.affair.id > 0 &&
            //   to.params.id &&
            //   to.params.id > 0
            // ) {
            //   let documentStored = store.state.AffairModule.affair;
            //   to.meta.title =
            //     documentStored.name && to.name == "edit-affair"
            //       ? "Edit : " + documentStored.name
            //       : documentStored.name && to.name == "affair"
            //       ? "Affaire : " + documentStored.name
            //       : "Nouvelle affaire";
            // } else {
            let loadId = to.params.id ? to.params.id : 0;
            await store
              .dispatch("getAffairById", { affairId: loadId })
              .then((res) => {
                to.meta.title =
                  res.name && to.name == "edit-affair"
                    ? "Edit : " + res.name
                    : res.name && to.name == "affair"
                    ? "Affaire : " + res.name
                    : "Nouvelle affaire";
              });
            // }
            next();
          },
          meta: {
            pageTitle: "Fiche affaire",
            breadcrumb: [
              {
                text: "Affaires",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Editer une affaire",
            icon: "icon-home",
            tips: "Editer une affaire",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [1],
          },
        },
        /* *** Gestion des stocks *** */
        {
          path: "/storage-locations",
          name: "storage-locations",
          component: () =>
            import("@/views/purchase/stock/StorageLocations.vue"),
          meta: {
            modules: [4],
            pageTitle: "Points de stockage",
            breadcrumb: [
              {
                text: "Points de stockage",
                active: true,
              },
            ],
            title: "Points de stockage",
            icon: "icon-users",
            tips: "Liste des points de stockage",
            key: "path",
            closable: true,
            functionIds: [36],
          },
        },
        {
          path: "/new-storage-location",
          name: "new-storage-location",
          component: () =>
            import("@/views/purchase/stock/EditStorageLocation.vue"),
          props: true,
          meta: {
            modules: [4],
            pageTitle: "Nouveau point de stockage",
            breadcrumb: [
              {
                text: "Points de stockage",
                active: true,
              },
              {
                text: "Modifier",
                active: true,
              },
            ],
            title: "Nouveau point de stockage",
            icon: "icon-home",
            tips: "Créer un nouveau point de stockage",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [36],
          },
        },
        {
          path: "/edit-storage-location/:id",
          name: "edit-storage-location",
          component: () =>
            import("@/views/purchase/stock/EditStorageLocation.vue"),
          props: true,
          meta: {
            modules: [4],
            pageTitle: "Fiche point de stockage",
            breadcrumb: [
              {
                text: "Point de stockage",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Editer un point de stockage",
            icon: "icon-home",
            tips: "Editer un point de stockage",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [36],
          },
        },

        /* *** MODULE CRM *** */
        {
          path: "/directory/companies/:name/:id",
          name: "companies",
          component: () => import("@/views/directory/company/Companies.vue"),
          meta: {
            pageTitle: "Clients",
            breadcrumb: [
              {
                text: "Clients",
                active: true,
              },
            ],
            title: "Clients",
            icon: "icon-home",
            tips: "Liste des clients",
            key: "path",
            closable: true,
            functionIds: [6],
          },
        },
        {
          path: "/directory/company/:id",
          name: "company",
          component: () => import("@/views/directory/company/Company.vue"),
          props: true,
          meta: {
            pageTitle: "Fiche société",
            breadcrumb: [
              {
                text: "Sociétés",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Fiche société",
            icon: "icon-home",
            tips: "Fiche société",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [6],
          },
        },
        {
          path: "/directory/new-company/:name/:companyTypeId",
          name: "new-company",
          component: () => import("@/views/directory/company/EditCompany.vue"),
          props: true,
          meta: {
            pageTitle: "Nouveau client",
            breadcrumb: [
              {
                text: "Clients",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Nouveau client",
            icon: "icon-home",
            tips: "Créer un nouveau client",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [6],
          },
        },
        {
          path: "/directory/edit-company/:id",
          name: "edit-company",
          component: () => import("@/views/directory/company/EditCompany.vue"),
          props: true,
          meta: {
            pageTitle: "Fiche client",
            breadcrumb: [
              {
                text: "Clients",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Editer un client",
            icon: "icon-home",
            tips: "Editer un client",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [6],
          },
        },
        {
          path: "/directory/my-activities",
          name: "my-activities",
          component: () =>
            import("@/views/directory/activity/MyActivities.vue"),
          meta: {
            modules: [7],
            pageTitle: "Mes activités commerciales",
            breadcrumb: [
              {
                text: "Mes activités commerciales",
                active: true,
              },
            ],
            title: "Mes activités commerciales",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste de mes activités commerciales",
            key: "path",
            closable: true,
            functionIds: [100],
          },
        },
        {
          path: "/directory/activities-by-collaborators",
          name: "activities-by-collaborators",
          component: () => import("@/views/directory/activity/Activities.vue"),
          meta: {
            modules: [7],
            pageTitle: "Activités commerciales",
            breadcrumb: [
              {
                text: "Activités commerciales",
                active: true,
              },
            ],
            title: "Activités commerciales",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des activités commerciales",
            key: "path",
            closable: true,
            functionIds: [100],
          },
        },
        {
          path: "/directory/my-reminders",
          name: "my-reminders",
          component: () => import("@/views/directory/activity/MyReminders.vue"),
          meta: {
            modules: [7],
            pageTitle: "Mes relances commerciales",
            breadcrumb: [
              {
                text: "Mes relances commerciales",
                active: true,
              },
            ],
            title: "Mes relances commerciales",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste de mes relances commerciales",
            key: "path",
            closable: true,
            functionIds: [100],
          },
        },
        {
          path: "/directory/reminders-by-collaborators",
          name: "reminders-by-collaborators",
          component: () => import("@/views/directory/activity/Reminders.vue"),
          meta: {
            modules: [7],
            pageTitle: "Relances commerciales",
            breadcrumb: [
              {
                text: "Relances commerciales",
                active: true,
              },
            ],
            title: "Relances commerciales",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des relances commerciales",
            key: "path",
            closable: true,
            functionIds: [100],
          },
        },
        {
          path: "/directory/new-activity",
          name: "new-activity",
          component: () =>
            import("@/views/directory/activity/EditActivity.vue"),
          props: true,
          meta: {
            modules: [7],
            pageTitle: "Activité commerciale",
            breadcrumb: [
              {
                text: "Activité commerciale",
                active: true,
              },
              {
                text: "Nouveau",
                active: true,
              },
            ],
            title: "Créer un rapport d'activité commerciale",
            icon: "icon-home",
            tips: "Créer un rapport d'activité commerciale",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            mode: "single",
            functionIds: [100],
          },
        },
        {
          path: "/directory/new-activity-team",
          name: "new-activity-team",
          component: () =>
            import("@/views/directory/activity/EditActivity.vue"),
          props: true,
          meta: {
            modules: [7],
            pageTitle: "Activité commerciale",
            breadcrumb: [
              {
                text: "Activité commerciale",
                active: true,
              },
              {
                text: "Nouveau",
                active: true,
              },
            ],
            title: "Créer un rapport d'activité commerciale",
            icon: "icon-home",
            tips: "Créer un rapport d'activité commerciale",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            mode: "team",
            functionIds: [100],
          },
        },

        /* *** Gestion des Contacts *** */
        {
          path: "/directory/contacts",
          name: "contacts",
          component: () => import("@/views/directory/contact/Contacts.vue"),
          meta: {
            pageTitle: "Contacts",
            breadcrumb: [
              {
                text: "Contacts",
                active: true,
              },
            ],
            title: "Contacts",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des contacts",
            key: "path",
            closable: true,
            functionIds: [8],
          },
        },
        {
          path: "/directory/new-contact",
          name: "new-contact",
          component: () => import("@/views/directory/contact/EditContact.vue"),
          props: true,
          meta: {
            pageTitle: "Nouveau contact",
            breadcrumb: [
              {
                text: "Contacts",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Nouveau contact",
            icon: "icon-home",
            tips: "Créer un nouveau contact",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [8],
          },
        },
        {
          path: "/directory/edit-contact/:id",
          name: "edit-contact",
          component: () => import("@/views/directory/contact/EditContact.vue"),
          props: true,
          meta: {
            pageTitle: "Fiche contact",
            breadcrumb: [
              {
                text: "Contacts",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Editer un contact",
            icon: "icon-home",
            tips: "Editer un contact",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [8],
          },
        },

        /* *** Gestion des Baux ****/
        {
          path: "/leases",
          name: "leases",
          component: () => import("@/views/billing/lease/Leases.vue"),
          meta: {
            modules: [3],
            pageTitle: "Baux",
            breadcrumb: [
              {
                text: "Baux",
                active: true,
              },
            ],
            title: "Baux",
            icon: "icon-users",
            tips: "Liste des baux",
            key: "path",
            closable: true,
            functionIds: [37],
          },
        },
        {
          path: "/leases/:id",
          name: "lease",
          component: () => import("@/views/billing/lease/Lease.vue"),
          props: true,
          beforeEnter: async (to, from, next) => {
            if (
              store.state.LeaseModule.lease &&
              store.state.LeaseModule.lease.id == to.params.id &&
              store.state.LeaseModule.lease.id > 0 &&
              to.params.id &&
              to.params.id > 0
            ) {
              let documentStored = store.state.LeaseModule.lease;
              to.meta.title =
                documentStored.label && to.name == "edit-lease"
                  ? "Edit : " + documentStored.label
                  : documentStored.label && to.name == "lease"
                  ? "Bail : " + documentStored.label
                  : "Nouveau bail";
            } else {
              let loadId = to.params.id ? to.params.id : 0;
              await store
                .dispatch("getLeaseById", { leaseId: loadId })
                .then((res) => {
                  to.meta.title =
                    res.label && to.name == "edit-lease"
                      ? "Edit : " + res.label
                      : res.label && to.name == "lease"
                      ? "Bail : " + res.label
                      : "Nouveau bail";
                });
            }
            next();
          },
          meta: {
            modules: [3],
            pageTitle: "Fiche bail",
            breadcrumb: [
              {
                text: "Baux",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Fiche bail",
            icon: "icon-home",
            tips: "Fiche bail",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [37],
          },
        },
        {
          path: "/new-lease",
          name: "new-lease",
          component: () => import("@/views/billing/lease/EditLease.vue"),
          props: true,
          meta: {
            modules: [3],
            pageTitle: "Nouveau bail",
            breadcrumb: [
              {
                text: "Baux",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Nouveau bail",
            icon: "icon-home",
            tips: "Créer un nouveau bail",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [37],
          },
        },
        {
          path: "/edit-lease/:id",
          name: "edit-lease",
          component: () => import("@/views/billing/lease/EditLease.vue"),
          props: true,
          beforeEnter: async (to, from, next) => {
            if (
              store.state.LeaseModule.lease &&
              store.state.LeaseModule.lease.id == to.params.id &&
              store.state.LeaseModule.lease.id > 0 &&
              to.params.id &&
              to.params.id > 0
            ) {
              let documentStored = store.state.LeaseModule.lease;
              to.meta.title =
                documentStored.label && to.name == "edit-lease"
                  ? "Edit : " + documentStored.label
                  : documentStored.label && to.name == "lease"
                  ? "Bail : " + documentStored.label
                  : "Nouveau bail";
            } else {
              let loadId = to.params.id ? to.params.id : 0;
              await store
                .dispatch("getLeaseById", { leaseId: loadId })
                .then((res) => {
                  to.meta.title =
                    res.label && to.name == "edit-lease"
                      ? "Edit : " + res.label
                      : res.label && to.name == "lease"
                      ? "Bail : " + res.label
                      : "Nouveau bail";
                });
            }
            next();
          },
          meta: {
            modules: [3],
            pageTitle: "Fiche bail",
            breadcrumb: [
              {
                text: "Baux",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Editer un bail",
            icon: "icon-home",
            tips: "Editer un bail",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [37],
          },
        },
        {
          path: "/billing/periodicities",
          name: "periodicities",
          component: () =>
            import("@/views/billing/periodicity/Periodicities.vue"),
          meta: {
            modules: [3],
            pageTitle: "Périodicités",
            breadcrumb: [
              {
                text: "Périodicités",
                active: true,
              },
            ],
            title: "Périodicités",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des périodicités",
            key: "path",
            closable: true,
            functionIds: [39],
          },
        },
        {
          path: "/billing/lease-types",
          name: "lease-types",
          component: () => import("@/views/billing/lease-type/LeaseTypes.vue"),
          meta: {
            modules: [3],
            pageTitle: "Types de baux",
            breadcrumb: [
              {
                text: "Types de baux",
                active: true,
              },
            ],
            title: "Types de baux",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des types de baux",
            key: "path",
            closable: true,
            functionIds: [38],
          },
        },
        {
          path: "/billing/property-types",
          name: "property-types",
          component: () =>
            import("@/views/billing/property-type/PropertyTypes.vue"),
          meta: {
            modules: [3],
            pageTitle: "Types de biens",
            breadcrumb: [
              {
                text: "Types de biens",
                active: true,
              },
            ],
            title: "Types de biens",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des types de biens",
            key: "path",
            closable: true,
            functionIds: [41],
          },
        },
        /* *** Gestion des Commandes *** */
        {
          path: "/order-form-providers",
          name: "order-form-providers",
          component: () =>
            import(
              "@/views/purchase/order-form-provider/OrderFormProviders.vue"
            ),
          meta: {
            modules: [4],
            pageTitle: "Commandes fournisseur",
            breadcrumb: [
              {
                text: "Commandes fournisseur",
                active: true,
              },
            ],
            title: "Commandes fournisseur",
            icon: "icon-users",
            tips: "Liste des commandes fournisseur",
            key: "path",
            closable: true,
            functionIds: [24, 25, 26],
          },
        },
        {
          path: "/new-order-form-provider/:id",
          name: "new-order-form-provider",
          component: () =>
            import(
              "@/views/purchase/order-form-provider/EditOrderFormProvider.vue"
            ),
          props: true,
          beforeEnter: async (to, from, next) => {
            // if (
            //   store.state.OrderFormProviderModule.orderFormProvider &&
            //   store.state.OrderFormProviderModule.orderFormProvider.id ==
            //     to.params.id
            // ) {
            //   let documentStored =
            //     store.state.OrderFormProviderModule.orderFormProvider;
            //   to.meta.title = documentStored.documentReference
            //     ? documentStored.documentReference
            //     : "Nouvelle commande fournisseur";
            // } else {
            let loadId = to.params.id ? to.params.id : 0;
            await store
              .dispatch("getOrderFormById", { orderFormId: loadId })
              .then((res) => {
                to.meta.title = res.documentReference
                  ? res.documentReference
                  : "Nouvelle commande fournisseur";
              });
            // }
            next();
          },
          meta: {
            modules: [4],
            pageTitle: "Nouvelle commande fournisseur",
            breadcrumb: [
              {
                text: "Commande fournisseur",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Nouvelle commande fournisseur",
            icon: "icon-home",
            tips: "Créer une nouvelle commande fournisseur",
            key: "fullPath",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [24, 25, 26],
          },
        },
        {
          path: "/edit-order-form-provider/:id",
          name: "edit-order-form-provider",
          component: () =>
            import(
              "@/views/purchase/order-form-provider/EditOrderFormProvider.vue"
            ),
          props: true,
          beforeEnter: async (to, from, next) => {
            // if (
            //   store.state.OrderFormProviderModule.orderFormProvider &&
            //   store.state.OrderFormProviderModule.orderFormProvider.id ==
            //     to.params.id
            // ) {
            //   let documentStored =
            //     store.state.OrderFormProviderModule.orderFormProvider;
            //   to.meta.title = documentStored.documentReference
            //     ? documentStored.documentReference
            //     : "Éditer une commande fournisseur";
            // } else {
            let loadId = to.params.id ? to.params.id : 0;
            await store
              .dispatch("getOrderFormById", { orderFormId: loadId })
              .then((res) => {
                to.meta.title = res.documentReference
                  ? res.documentReference
                  : "Éditer une commande fournisseur";
              });
            // }
            next();
          },
          meta: {
            modules: [4],
            pageTitle: "Commande fournisseur",
            breadcrumb: [
              {
                text: "Commande fournisseur",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Editer une commande fournisseur",
            icon: "icon-home",
            tips: "Editer une commande fournisseur",
            key: "fullPath",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [24, 25, 26],
          },
        },
        // Gestion des bons de réception
        {
          path: "/receipt-form-providers",
          name: "receipt-form-providers",
          component: () =>
            import(
              "@/views/purchase/order-form-provider/ReceiptFormProviders.vue"
            ),
          meta: {
            modules: [4],
            pageTitle: "Bons de réception",
            breadcrumb: [
              {
                text: "Bons de réception",
                active: true,
              },
            ],
            title: "Bons de réception",
            icon: "icon-users",
            tips: "Liste des bons de réception",
            key: "path",
            closable: true,
            functionIds: [30, 31, 32],
          },
        },
        {
          path: "/edit-receipt-form-provider/:id",
          name: "edit-receipt-form-provider",
          component: () =>
            import(
              "@/views/purchase/order-form-provider/EditReceiptFormProvider.vue"
            ),
          props: true,
          beforeEnter: async (to, from, next) => {
            // if (
            //   store.state.ReceiptFormProviderModule.receiptFormProvider &&
            //   store.state.ReceiptFormProviderModule.receiptFormProvider.id ==
            //     to.params.id
            // ) {
            //   let documentStored =
            //     store.state.ReceiptFormProviderModule.receiptFormProvider;
            //   to.meta.title = documentStored.documentReference
            //     ? documentStored.documentReference
            //     : "Éditer un bon de réception";
            // } else {
            let loadId = to.params.id ? to.params.id : 0;
            await store
              .dispatch("getReceiptFormById", { receiptFormId: loadId })
              .then((res) => {
                to.meta.title = res.documentReference
                  ? res.documentReference
                  : "Éditer un bon de réception";
              });
            // }
            next();
          },
          meta: {
            modules: [4],
            pageTitle: "Bon de réception",
            breadcrumb: [
              {
                text: "Bon de réception",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            // title: 'Editer un bon de réception',
            icon: "icon-home",
            tips: "Editer un bon de réception",
            key: "fullPath",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [30, 31, 32],
          },
        },
        {
          path: "/new-receipt-form-provider/:id",
          name: "new-receipt-form-provider",
          component: () =>
            import(
              "@/views/purchase/order-form-provider/EditReceiptFormProvider.vue"
            ),
          props: true,
          beforeEnter: async (to, from, next) => {
            // if (
            //   store.state.ReceiptFormProviderModule.receiptFormProvider &&
            //   store.state.ReceiptFormProviderModule.receiptFormProvider.id ==
            //     to.params.id
            // ) {
            //   let documentStored =
            //     store.state.ReceiptFormProviderModule.receiptFormProvider;
            //   to.meta.title = documentStored.documentReference
            //     ? documentStored.documentReference
            //     : "Nouveau bon de réception";
            // } else {
            let loadId = to.params.id ? to.params.id : 0;
            await store
              .dispatch("getReceiptFormById", { receiptFormId: loadId })
              .then((res) => {
                to.meta.title = res.documentReference
                  ? res.documentReference
                  : "Nouveau bon de réception";
              });
            // }
            next();
          },
          meta: {
            modules: [4],
            pageTitle: "Nouveau bon de réception",
            breadcrumb: [
              {
                text: "Bon de réception",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Nouveau bon de réception",
            icon: "icon-home",
            tips: "Créer un nouveau bon de réception",
            key: "fullPath",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [30, 31, 32],
          },
        },
        /* *** Gestion des bons de livraison *** */
        {
          path: "/delivery-forms",
          name: "delivery-forms",
          component: () =>
            import("@/views/purchase/order-form-provider/DeliveryForms.vue"),
          meta: {
            modules: [4],
            pageTitle: "Bons de sortie",
            breadcrumb: [
              {
                text: "Bons de sortie",
                active: true,
              },
            ],
            title: "Bons de sortie",
            icon: "icon-users",
            tips: "Liste des bons de livraison",
            key: "path",
            closable: true,
            functionIds: [12, 13, 14],
          },
        },
        {
          path: "/edit-delivery-form/:id",
          name: "edit-delivery-form",
          component: () =>
            import("@/views/purchase/order-form-provider/EditDeliveryForm.vue"),
          props: true,
          beforeEnter: async (to, from, next) => {
            // if (
            //   store.state.DeliveryFormModule.deliveryForm &&
            //   store.state.DeliveryFormModule.deliveryForm.id == to.params.id
            // ) {
            //   let documentStored = store.state.DeliveryFormModule.deliveryForm;
            //   to.meta.title = documentStored.documentReference
            //     ? documentStored.documentReference
            //     : "Éditer un bon de sortie";
            // } else {
            let loadId = to.params.id ? to.params.id : 0;
            await store
              .dispatch("getDeliveryFormById", { deliveryFormId: loadId })
              .then((res) => {
                to.meta.title = res.documentReference
                  ? res.documentReference
                  : "Éditer un bon de sortie";
              });
            // }
            next();
          },
          meta: {
            modules: [4],
            pageTitle: "Bon de sortie",
            breadcrumb: [
              {
                text: "Bon de sortie",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            // title: 'Editer un bon de livraison',
            icon: "icon-home",
            tips: "Editer un bon de sortie",
            key: "fullPath",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [12, 13, 14],
          },
        },
        {
          path: "/new-delivery-form/:id",
          name: "new-delivery-form",
          component: () =>
            import("@/views/purchase/order-form-provider/EditDeliveryForm.vue"),
          props: true,
          beforeEnter: async (to, from, next) => {
            // if (
            //   store.state.DeliveryFormModule.deliveryForm &&
            //   store.state.DeliveryFormModule.deliveryForm.id == to.params.id
            // ) {
            //   let documentStored = store.state.DeliveryFormModule.deliveryForm;
            //   to.meta.title = documentStored.documentReference
            //     ? documentStored.documentReference
            //     : "Nouveau bon de sortie";
            // } else {
            let loadId = to.params.id ? to.params.id : 0;
            await store
              .dispatch("getDeliveryFormById", { deliveryFormId: loadId })
              .then((res) => {
                to.meta.title = res.documentReference
                  ? res.documentReference
                  : "Nouveau bon de sortie";
              });
            // }
            next();
          },
          meta: {
            modules: [4],
            pageTitle: "Nouveau bon de sortie",
            breadcrumb: [
              {
                text: "Bon de sortie",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Nouveau bon de sortie",
            icon: "icon-home",
            tips: "Créer un nouveau bon de sortie",
            key: "fullPath",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [12, 13, 14],
          },
        },
        /* *** Gestion des Facturations *** */
        {
          path: "/invoices-provider",
          name: "invoices-provider",
          component: () =>
            import("@/views/purchase/invoices-provider/InvoicesProvider.vue"),
          meta: {
            modules: [4],
            pageTitle: "Factures fournisseur",
            breadcrumb: [
              {
                text: "Factures fournisseur",
                active: true,
              },
            ],
            title: "Factures fournisseur",
            icon: "icon-users",
            tips: "Liste des factures fournisseur",
            key: "path",
            closable: true,
            functionIds: [22],
          },
        },
        {
          path: "/edit-invoice-provider/:id",
          name: "edit-invoice-provider",
          component: () =>
            import(
              "@/views/purchase/invoices-provider/EditInvoiceProvider.vue"
            ),
          meta: {
            modules: [4],
            pageTitle: "Facture fournisseur",
            breadcrumb: [
              {
                text: "Facture fournisseur",
                active: true,
              },
            ],
            title: "Facture fournisseur",
            icon: "icon-users",
            tips: "Edition de la facture fournisseur",
            key: "path",
            closable: true,
            functionIds: [22],
          },
        },
        // {
        //   path: "/expense-reports",
        //   name: "expense-reports",
        //   component: () =>
        //     import("@/views/purchase/expense-reports/ExpenseReports.vue"),
        //   meta: {
        //     modules: [4],
        //     pageTitle: "Notes de frais",
        //     breadcrumb: [
        //       {
        //         text: "Notes de frais",
        //         active: true,
        //       },
        //     ],
        //     title: "Notes de frais",
        //     icon: "icon-users",
        //     tips: "Liste des notes de frais",
        //     key: "path",
        //     closable: true,
        //   },
        // },
        {
          path: "/credits-provider",
          name: "credits-provider",
          component: () =>
            import("@/views/purchase/credits-provider/CreditsProvider.vue"),
          meta: {
            modules: [4],
            pageTitle: "Avoirs fournisseur",
            breadcrumb: [
              {
                text: "Avoirs fournisseur",
                active: true,
              },
            ],
            title: "Avoirs fournisseur",
            icon: "icon-users",
            tips: "Liste des avoirs fournisseur",
            key: "path",
            closable: true,
            functionIds: [17],
          },
        },
        {
          path: "/edit-credit-provider/:id",
          name: "edit-credit-provider",
          component: () =>
            import("@/views/purchase/credits-provider/EditCreditProvider.vue"),
          meta: {
            modules: [4],
            pageTitle: "Avoir fournisseur",
            breadcrumb: [
              {
                text: "Avoir fournisseur",
                active: true,
              },
            ],
            title: "Avoir fournisseur",
            icon: "icon-users",
            tips: `Edition de l'avoir fournisseur`,
            key: "path",
            closable: true,
            functionIds: [17],
          },
        },
        /* *** Gestion des stocks *** */
        {
          path: "/stock/inventories",
          name: "inventories",
          component: () => import("@/views/purchase/stock/Inventories.vue"),
          meta: {
            modules: [4],
            pageTitle: "Inventaires",
            breadcrumb: [
              {
                text: "Inventaires",
                active: true,
              },
            ],
            title: "Inventaires",
            icon: "icon-users",
            tips: "Inventaires",
            key: "path",
            closable: true,
            functionIds: [19, 20, 21],
          },
        },
        {
          path: "/stock/new-inventory/:id",
          name: "new-inventory",
          component: () =>
            import("@/views/purchase/inventory/EditInventory.vue"),
          props: true,
          beforeEnter: async (to, from, next) => {
            const inventory = await store.dispatch("getInventoryById", {
              inventoryId: to.params.id,
            });
            to.meta.title = inventory.documentReference
              ? inventory.documentReference
              : "Nouvel inventaire";
            next();
          },
          meta: {
            modules: [4],
            pageTitle: "Nouvel inventaire",
            breadcrumb: [
              {
                text: "Inventaire",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Nouvel inventaire",
            icon: "icon-home",
            tips: "Créer un nouvel inven taire",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [19, 20, 21],
          },
        },
        {
          path: "/stock/edit-inventory/:id",
          name: "edit-inventory",
          component: () =>
            import("@/views/purchase/inventory/EditInventory.vue"),
          props: true,
          beforeEnter: async (to, from, next) => {
            const inventory = await store.dispatch("getInventoryById", {
              inventoryId: to.params.id,
            });
            to.meta.title = inventory.documentReference
              ? inventory.documentReference
              : "Editer un inventaire";
            next();
          },
          meta: {
            modules: [4],
            pageTitle: "Editer un inventaire",
            breadcrumb: [
              {
                text: "Inventaire",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Editer un inventaire",
            icon: "icon-home",
            tips: "Editer un inventaire",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [19, 20, 21],
          },
        },
        {
          path: "/stock-movements",
          name: "stock-movements",
          component: () => import("@/views/purchase/stock/StockMovements.vue"),
          meta: {
            modules: [4],
            pageTitle: "Mouvements de stock",
            breadcrumb: [
              {
                text: "Mouvements de stock",
                active: true,
              },
            ],
            title: "Mouvements de stock",
            icon: "icon-users",
            tips: "Liste des mouvements de stock",
            key: "path",
            closable: true,
            functionIds: [35],
          },
        },
        /* *** Gestion des Plannings *** */
        {
          path: "/planning/:id",
          name: "planning",
          props: true,
          component: () => import("@/views/Bryntum.vue"),
          beforeEnter: async (to, from, next) => {
            const planning = await store.dispatch("getPlanningViewById", {
              planningViewId: to.params.id,
            });
            to.meta.title = planning.name
              ? "Planning : " + planning.name
              : "Planning";
            to.params.tips = "Planning : " + planning.name;
            to.params.title = "Planning : " + planning.name;
            next();
          },
          meta: {
            modules: [1],
            pageTitle: "Planning",
            breadcrumb: [
              {
                text: "Planning",
                active: true,
              },
            ],
            title: "Planning",
            icon: "icon-home",
            tips: "Gestionnaire de planning",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            keepAlive: false,
            functionIds: [45, 46],
          },
        },
        {
          path: "/planning/settings/event-types",
          name: "eventTypes",
          component: () => import("@/views/planning/settings/EventTypes.vue"),
          meta: {
            modules: [1],
            pageTitle: "Types d'évènement",
            breadcrumb: [
              {
                text: "Types d'évènement",
                active: true,
              },
            ],
            title: "Types d'évènement de planning",
            icon: "icon-home",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des types d'évènements",
            key: "path",
            closable: true,
            functionIds: [44],
          },
        },
        {
          path: "/planning/settings/planning-views",
          name: "planningViews",
          component: () =>
            import("@/views/planning/settings/PlanningViews.vue"),
          meta: {
            modules: [1],
            pageTitle: "Gestion des plannings",
            breadcrumb: [
              {
                text: "Vues de planning",
                active: true,
              },
            ],
            title: "Vues de planning",
            icon: "icon-home",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des vues de planning",
            key: "path",
            closable: true,
            functionIds: [47],
          },
        },
        {
          path: "/planning/settings/new-planning-view",
          name: "newPlanningView",
          component: () =>
            import("@/views/planning/settings/EditPlanningView.vue"),
          props: true,
          meta: {
            modules: [1],
            pageTitle: "Nouveau planning",
            breadcrumb: [
              {
                text: "Nouveau planning",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Nouveau planning",
            icon: "icon-home",
            tabClass: "custom-tab", // custom class, optional
            tips: "Créer un nouveau planning",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [47],
          },
        },
        {
          path: "/planning/settings/edit-planning-view/:id",
          name: "edit-planning-view",
          component: () =>
            import("@/views/planning/settings/EditPlanningView.vue"),
          props: true,
          meta: {
            modules: [1],
            pageTitle: "Planning",
            breadcrumb: [
              {
                text: "Planning",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Editer un planning",
            icon: "icon-home",
            tabClass: "custom-tab", // custom class, optional
            tips: "Editer un planning",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [47],
          },
        },

        {
          path: "/planning/settings/sms",
          name: "sms",
          component: () => import("@/views/planning/settings/Sms.vue"),
          meta: {
            modules: [1],
            pageTitle: "Historique des SMS",
            breadcrumb: [
              {
                text: "SMS",
                active: true,
              },
            ],
            title: "SMS",
            icon: "icon-home",
            tabClass: "custom-tab", // custom class, optional
            tips: "Historique des SMS",
            key: "path",
            closable: true,
            functionIds: [48],
          },
        },
        /* *** Gestion du module Intervention *** */
        {
          path: "/intervention/interventions-by-collaborators",
          name: "interventions-by-collaborators",
          component: () => import("@/views/interventions/Interventions.vue"),
          meta: {
            modules: [1],
            pageTitle: "Interventions",
            breadcrumb: [
              {
                text: "Interventions",
                active: true,
              },
            ],
            title: "Interventions",
            icon: "icon-home",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des interventions",
            key: "path",
            closable: true,
            functionIds: [121],
          },
        },
        {
          path: "/intervention/my-interventions",
          name: "myInterventions",
          component: () => import("@/views/interventions/MyInterventions.vue"),
          meta: {
            modules: [1],
            pageTitle: "Mes interventions",
            breadcrumb: [
              {
                text: "Mes interventions",
                active: true,
              },
            ],
            title: "Mes interventions",
            icon: "icon-home",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des mes interventions",
            key: "path",
            closable: true,
            functionIds: [121],
          },
        },
        {
          path: "/intervention/new-intervention",
          name: "new-intervention",
          component: () => import("@/views/interventions/EditIntervention.vue"),
          props: true,
          meta: {
            modules: [1],
            pageTitle: "Intervention",
            breadcrumb: [
              {
                text: "Intervention",
                active: true,
              },
              {
                text: "Nouveau",
                active: true,
              },
            ],
            title: "Créer une intervention",
            icon: "icon-home",
            tips: "Créer une intervention",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            mode: "single",
            functionIds: [121],
          },
        },
        {
          path: "/intervention/new-intervention-team",
          name: "new-intervention-team",
          component: () => import("@/views/interventions/EditIntervention.vue"),
          props: true,
          meta: {
            modules: [1],
            pageTitle: "Intervention",
            breadcrumb: [
              {
                text: "Intervention",
                active: true,
              },
              {
                text: "Nouveau",
                active: true,
              },
            ],
            title: "Créer une intervention",
            icon: "icon-home",
            tips: "Créer une intervention",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            mode: "team",
            functionIds: [121],
          },
        },
        {
          path: "/intervention/edit-intervention/:id",
          name: "edit-intervention",
          component: () => import("@/views/interventions/EditIntervention.vue"),
          props: true,
          meta: {
            modules: [1],
            pageTitle: "Intervention",
            breadcrumb: [
              {
                text: "Intervention",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Editer une intervention",
            icon: "icon-home",
            tips: "Editer une intervention",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            mode: "single",
            functionIds: [121],
          },
        },

        /* *** Gestion du module Reporting *** */
        {
          path: "/report/analytics-settings",
          name: "analyticsSettings",
          component: () => import("@/views/report/AnalyticsSettings.vue"),
          meta: {
            pageTitle: "Codes analytiques",
            breadcrumb: [
              {
                text: "Codes analytiques",
                active: true,
              },
            ],
            title: "Codes analytiques",
            icon: "icon-home",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des codes analytiques",
            key: "path",
            closeOnSwitchWorkspace: true,
            closable: true,
            functionIds: [94],
          },
        },
        {
          path: "/report/payroll-events",
          name: "payrollEvents",
          component: () => import("@/views/report/PayrollEvents.vue"),
          meta: {
            modules: [1],
            pageTitle: "Variables de paie",
            breadcrumb: [
              {
                text: "Variables de paie",
                active: true,
              },
            ],
            title: "Variables de paie",
            icon: "icon-home",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des variables de paie",
            key: "path",
            closeOnSwitchWorkspace: true,
            closable: true,
            functionIds: [96],
          },
        },
        {
          path: "/report/mission-types",
          name: "missionTypes",
          component: () => import("@/views/report/MissionTypes.vue"),
          meta: {
            modules: [1],
            pageTitle: "Plan de missions",
            breadcrumb: [
              {
                text: "Plan de missions",
                active: true,
              },
            ],
            title: "Plan de missions",
            icon: "icon-home",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des missions types",
            key: "path",
            closeOnSwitchWorkspace: true,
            closable: true,
          },
        },
        {
          path: "/report/pay-allowance-type",
          name: "payAllowanceTypes",
          component: () => import("@/views/report/PayAllowanceTypes.vue"),
          meta: {
            modules: [1],
            pageTitle: "Types d'indemnités de paie",
            breadcrumb: [
              {
                text: "Type d'indemnités de paie",
                active: true,
              },
            ],
            title: "Types d'indemnités de paie",
            icon: "icon-home",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des types d'indemnités de paie",
            key: "path",
            closeOnSwitchWorkspace: true,
            closable: true,
            functionIds: [95],
          },
        },
        {
          path: "/report/my-reports",
          name: "my-reports",
          component: () => import("@/views/report/MyReports.vue"),
          meta: {
            modules: [1],
            pageTitle: "Mes reportings",
            breadcrumb: [
              {
                text: "Mes reportings",
                active: true,
              },
            ],
            title: "Mes reportings",
            icon: "icon-home",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des mes reportings",
            key: "path",
            closeOnSwitchWorkspace: true,
            closable: true,
            functionIds: [92],
          },
        },
        {
          path: "/report/reports-by-collaborators",
          name: "reports-by-collaborators",
          component: () => import("@/views/report/Reports.vue"),
          meta: {
            modules: [1],
            pageTitle: "Reportings",
            breadcrumb: [
              {
                text: "Reportings",
                active: true,
              },
            ],
            title: "Reportings",
            icon: "icon-home",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des reportings",
            key: "path",
            closeOnSwitchWorkspace: true,
            closable: true,
            functionIds: [92],
          },
        },

        {
          path: "/report/my-missions",
          name: "my-missions",
          component: () => import("@/views/report/MyMissions.vue"),
          meta: {
            modules: [1],
            pageTitle: "Mes missions",
            breadcrumb: [
              {
                text: "Mes missions",
                active: true,
              },
            ],
            title: "Mes missions",
            icon: "icon-home",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des mes missions",
            key: "path",
            closeOnSwitchWorkspace: true,
            closable: true,
            functionIds: [93],
          },
        },
        {
          path: "/report/missions-by-collaborators",
          name: "missions-by-collaborators",
          component: () => import("@/views/report/Missions.vue"),
          meta: {
            modules: [1],
            pageTitle: "Missions",
            breadcrumb: [
              {
                text: "Missions",
                active: true,
              },
            ],
            title: "Missions",
            icon: "icon-home",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des missions",
            key: "path",
            closeOnSwitchWorkspace: true,
            closable: true,
            functionIds: [93],
          },
        },

        /* *** Gestion des Paramètres Custom Field *** */
        {
          path: "/parameters/customFieldTemplates",
          name: "customFieldTemplates",
          component: () =>
            import(
              "@/views/parameters/customFieldTemplate/CustomFieldTemplates.vue"
            ),
          meta: {
            pageTitle: "Formulaires de champs personnalisés",
            breadcrumb: [
              {
                text: "Formulaires de champs personnalisés",
                active: true,
              },
            ],
            title: "Formulaires de champs personnalisés",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des formulaires de champs personnalisés",
            key: "path",
            closeOnSwitchWorkspace: true,
            closable: true,
            functionIds: [97],
          },
        },
        {
          path: "/parameters/new-custom-field-template",
          name: "new-custom-field-template",
          component: () =>
            import(
              "@/views/parameters/customFieldTemplate/EditCustomFieldTemplate.vue"
            ),
          props: true,
          meta: {
            pageTitle: "Formulaire de champs personnalisés",
            breadcrumb: [
              {
                text: "Formulaire de champs personnalisés",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Créer un formulaire de champs personnalisés",
            icon: "icon-home",
            tips: "Créer un formulaire de champs personnalisés",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [97],
          },
        },
        {
          path: "/parameters/edit-custom-field-template/:id",
          name: "edit-custom-field-template",
          component: () =>
            import(
              "@/views/parameters/customFieldTemplate/EditCustomFieldTemplate.vue"
            ),
          props: true,
          meta: {
            pageTitle: "Formulaire de champs personnalisés",
            breadcrumb: [
              {
                text: "Formulaire de champs personnalisés",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Editer un formulaire de champs personnalisés",
            icon: "icon-home",
            tips: "Editer un formulaire de champs personnalisés",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [97],
          },
        },
        /* *** Gestion des Paramètres Divers *** */
        {
          path: "/parameters/activityChanelTypes",
          name: "activityChanelTypes",
          component: () =>
            import(
              "@/views/parameters/activityChanelType/ActivityChanelTypes.vue"
            ),
          meta: {
            modules: [7],
            pageTitle: "Canaux de contact",
            breadcrumb: [
              {
                text: "Canaux de contact",
                active: true,
              },
            ],
            title: "Canaux de contact",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des canaux de contact",
            key: "path",
            closeOnSwitchWorkspace: true,
            closable: true,
            functionIds: [101],
          },
        },
        {
          path: "/parameters/activityResultTypes",
          name: "activityResultTypes",
          component: () =>
            import(
              "@/views/parameters/activityResultType/ActivityResultTypes.vue"
            ),
          meta: {
            modules: [7],
            pageTitle: "Type de résultat de contact",
            breadcrumb: [
              {
                text: "Type de résultat de contact",
                active: true,
              },
            ],
            title: "Type de résultat de contact",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des type de résultat de contact",
            key: "path",
            closeOnSwitchWorkspace: true,
            closable: true,
            functionIds: [102],
          },
        },
        {
          path: "/parameters/activitySourceTypes",
          name: "activitySourceTypes",
          component: () =>
            import(
              "@/views/parameters/activitySourceType/ActivitySourceTypes.vue"
            ),
          meta: {
            modules: [7],
            pageTitle: "Origines de contact",
            breadcrumb: [
              {
                text: "Origines de contact",
                active: true,
              },
            ],
            title: "Origines de contact",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des origines de contact",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [103],
          },
        },
        {
          path: "/parameters/companyIndicators",
          name: "companyIndicators",
          component: () =>
            import("@/views/parameters/companyIndicator/CompanyIndicators.vue"),
          meta: {
            modules: [7],
            pageTitle: "Indicateurs sur société",
            breadcrumb: [
              {
                text: "Indicateurs sur société",
                active: true,
              },
            ],
            title: "Indicateurs sur société",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des indicateurs sur société",
            key: "path",
            closeOnSwitchWorkspace: true,
            closable: true,
            functionIds: [104],
          },
        },
        {
          path: "/parameters/collaboratorGoals",
          name: "collaboratorGoals",
          component: () =>
            import("@/views/parameters/collaboratorGoal/CollaboratorGoals.vue"),
          meta: {
            modules: [7],
            pageTitle: "Objectifs par collaborateur",
            breadcrumb: [
              {
                text: "Objectifs par collaborateur",
                active: true,
              },
            ],
            title: "Objectifs par collaborateur",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des objectifs par collaborateur",
            key: "path",
            closeOnSwitchWorkspace: true,
            closable: true,
          },
        },

        {
          path: "/parameters/productCategories",
          name: "productCategories",
          component: () =>
            import(
              "@/views/parameters/productCategories/ProductCategories.vue"
            ),
          meta: {
            modules: [2, 4],
            pageTitle: "Catégories des produits et services",
            breadcrumb: [
              {
                text: "Catégories des produits et services",
                active: true,
              },
            ],
            title: "Catégories des produits et services",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des catégories de produits et services",
            key: "path",
            closeOnSwitchWorkspace: true,
            closable: true,
            functionIds: [73],
          },
        },
        {
          path: "/parameters/saleCategories",
          name: "saleCategories",
          component: () =>
            import("@/views/parameters/saleCategories/SaleCategories.vue"),
          meta: {
            modules: [2],
            pageTitle: "Catégories de vente",
            breadcrumb: [
              {
                text: "Catégories de vente",
                active: true,
              },
            ],
            title: "Catégories de vente",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des catégories de vente",
            key: "path",
            closeOnSwitchWorkspace: true,
            closable: true,
            functionIds: [80],
          },
        },
        {
          path: "/parameters/purchaseCategories",
          name: "purchaseCategories",
          component: () =>
            import(
              "@/views/parameters/purchaseCategories/PurchaseCategories.vue"
            ),
          meta: {
            modules: [4],
            pageTitle: `Catégories d'achat`,
            breadcrumb: [
              {
                text: `Catégories d'achat`,
                active: true,
              },
            ],
            title: `Catégories d'achat`,
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: `Liste des catégories d'achat`,
            key: "path",
            closeOnSwitchWorkspace: true,
            closable: true,
            functionIds: [29],
          },
        },
        {
          path: "/parameters/affairCategories",
          name: "affairCategories",
          component: () =>
            import("@/views/parameters/affairCategories/AffairCategories.vue"),
          meta: {
            pageTitle: "Catégories des affaires",
            breadcrumb: [
              {
                text: "Catégories des affaires",
                active: true,
              },
            ],
            title: "Catégories des affaires",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des catégories d'affaires",
            key: "path",
            closeOnSwitchWorkspace: true,
            closable: true,
            functionIds: [2],
          },
        },
        {
          path: "/parameters/contracts",
          name: "contracts",
          component: () => import("@/views/parameters/contracts/Contracts.vue"),
          meta: {
            pageTitle: "Contrats",
            breadcrumb: [
              {
                text: "Contrats",
                active: true,
              },
            ],
            title: "Contrats",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des contrats",
            key: "path",
            closeOnSwitchWorkspace: true,
            closable: true,
            functionIds: [105],
          },
        },
        {
          path: "/parameters/edit-contract/:id",
          name: "edit-contract",
          component: () =>
            import("@/views/parameters/contracts/EditContract.vue"),
          props: true,
          meta: {
            pageTitle: "Formulaire de contrat",
            breadcrumb: [
              {
                text: "Formulaire de contrat",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Editer un contrat",
            icon: "icon-home",
            tips: "Editer un contrat",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
          },
        },
        {
          path: "/parameters/jobs",
          name: "jobs",
          component: () => import("@/views/parameters/jobs/Jobs.vue"),
          meta: {
            pageTitle: "Métiers",
            breadcrumb: [
              {
                text: "Métiers",
                active: true,
              },
            ],
            title: "Métiers",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des métiers",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [106],
          },
        },
        /**************************************************************
         ***                       FACTURATION                       ***
         **************************************************************/
        {
          path: "/quotes",
          name: "quotes",
          component: () => import("@/views/invoices/Quotes.vue"),
          meta: {
            modules: [2],
            pageTitle: "Devis",
            breadcrumb: [
              {
                text: "Devis",
                active: true,
              },
            ],
            title: "Devis",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des devis",
            key: "fullPath",
            closable: true,
            functionIds: [75],
          },
        },
        {
          path: "/new-quote/:id",
          name: "new-quote",
          component: () => import("@/views/invoices/EditDocument.vue"),
          props: true,
          beforeEnter: async (to, from, next) => {
            // if (
            //   store.state.DocumentModule.document &&
            //   store.state.DocumentModule.document.id == to.params.id
            // ) {
            //   let documentStored = store.state.DocumentModule.document;
            //   to.meta.title = documentStored.documentReference
            //     ? documentStored.documentReference
            //     : "Nouveau devis";
            // } else {
            let loadId = to.params.id ? to.params.id : 0;
            await store
              .dispatch("getQuoteById", { quoteId: loadId })
              .then((res) => {
                to.meta.title = res.documentReference
                  ? res.documentReference
                  : "Nouveau devis";
              });
            // }
            next();
          },
          meta: {
            modules: [2],
            pageTitle: "Nouveau devis",
            breadcrumb: [
              {
                text: "Devis",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Nouveau devis",
            icon: "icon-home",
            tips: "Créer un nouveau devis",
            key: "fullPath",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [75],
          },
        },
        {
          path: "/edit-quote/:id",
          name: "edit-quote",
          component: () => import("@/views/invoices/EditDocument.vue"),
          props: true,
          beforeEnter: async (to, from, next) => {
            let loadId = to.params.id ? to.params.id : 0;
            await store
              .dispatch("getQuoteById", { quoteId: loadId })
              .then((res) => {
                to.meta.title = res.documentReference
                  ? res.documentReference
                  : "Éditer un devis";
              });
            next();
          },
          meta: {
            modules: [2],
            pageTitle: "Devis",
            breadcrumb: [
              {
                text: "Devis",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            // title: 'Editer un devis',
            icon: "icon-home",
            tips: "Editer un devis",
            key: "fullPath",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [75],
          },
        },
        {
          path: "/order-form-customers",
          name: "order-form-customers",
          component: () => import("@/views/invoices/OrderFormCustomers.vue"),
          meta: {
            modules: [2],
            pageTitle: "Commandes client",
            breadcrumb: [
              {
                text: "Commandes client",
                active: true,
              },
            ],
            title: "Commandes client",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des commandes client",
            key: "fullPath",
            closable: true,
            functionIds: [108, 109, 110],
          },
        },
        {
          path: "/new-order-form-customer/:id",
          name: "new-order-form-customer",
          component: () => import("@/views/invoices/EditOrderFormCustomer.vue"),
          props: true,
          beforeEnter: async (to, from, next) => {
            // if (
            //   store.state.OrderFormCustomerModule.orderFormCustomer &&
            //   store.state.OrderFormCustomerModule.orderFormCustomer.id ==
            //     to.params.id
            // ) {
            //   let orderFormCustomerStored =
            //     store.state.OrderFormCustomerModule.orderFormCustomer;
            //   to.meta.title = orderFormCustomerStored.documentReference
            //     ? orderFormCustomerStored.documentReference
            //     : "Nouvelle commande client";
            // } else {
            let loadId = to.params.id ? to.params.id : 0;
            await store
              .dispatch("getOrderFormCustomerById", {
                orderFormCustomerId: loadId,
              })
              .then((res) => {
                to.meta.title = res.documentReference
                  ? res.documentReference
                  : "Nouvelle commande client";
              });
            // }
            next();
          },
          meta: {
            modules: [2],
            pageTitle: "Nouvelle commande client",
            breadcrumb: [
              {
                text: "Commande client",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Nouvelle commande client",
            icon: "icon-home",
            tips: "Créer une nouvelle commande client",
            key: "fullPath",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [108, 109, 110],
          },
        },
        {
          path: "/edit-order-form-customer/:id",
          name: "edit-order-form-customer",
          component: () => import("@/views/invoices/EditOrderFormCustomer.vue"),
          props: true,
          beforeEnter: async (to, from, next) => {
            // if (
            //   store.state.OrderFormCustomerModule.orderFormCustomer &&
            //   store.state.OrderFormCustomerModule.orderFormCustomer.id ==
            //     to.params.id
            // ) {
            //   let orderFormCustomerStored =
            //     store.state.OrderFormCustomerModule.orderFormCustomer;
            //   to.meta.title = orderFormCustomerStored.documentReference
            //     ? orderFormCustomerStored.documentReference
            //     : "Éditer une commande client";
            // } else {
            let loadId = to.params.id ? to.params.id : 0;
            await store
              .dispatch("getOrderFormCustomerById", {
                orderFormCustomerId: loadId,
              })
              .then((res) => {
                to.meta.title = res.documentReference
                  ? res.documentReference
                  : "Éditer une commande client";
              });
            // }
            next();
          },
          meta: {
            modules: [2],
            pageTitle: "Commande client",
            breadcrumb: [
              {
                text: "Commande client",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            // title: 'Editer un devis',
            icon: "icon-home",
            tips: "Editer une commande client",
            key: "fullPath",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [108, 109, 110],
          },
        },
        {
          path: "/invoices",
          name: "invoices",
          component: () => import("@/views/invoices/Invoices.vue"),
          props: true,
          meta: {
            modules: [2],
            pageTitle: "Factures",
            breadcrumb: [
              {
                text: "Factures",
                active: true,
              },
            ],
            title: "Factures",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des factures",
            key: "fullPath",
            closable: true,
            functionIds: [66, 67],
          },
        },
        {
          path: "/new-invoice/:id",
          name: "new-invoice",
          component: () => import("@/views/invoices/EditDocument.vue"),
          props: true,
          beforeEnter: async (to, from, next) => {
            // if (
            //   store.state.DocumentModule.document &&
            //   store.state.DocumentModule.document.id == to.params.id
            // ) {
            //   let documentStored = store.state.DocumentModule.document;
            //   to.meta.title = documentStored.documentReference
            //     ? documentStored.documentReference
            //     : "Nouvelle facture";
            // } else {
            let loadId = to.params.id ? to.params.id : 0;
            await store
              .dispatch("getInvoiceById", { invoiceId: loadId })
              .then((res) => {
                to.meta.title = res.documentReference
                  ? res.documentReference
                  : "Nouvelle facture";
              });
            // }
            next();
          },
          meta: {
            modules: [2],
            pageTitle: "Nouvelle facture",
            breadcrumb: [
              {
                text: "Facture",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Nouvelle facture",
            icon: "icon-home",
            tips: "Créer une nouvelle facture",
            key: "fullPath",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [66, 67],
          },
        },
        {
          path: "/edit-invoice/:id",
          name: "edit-invoice",
          component: () => import("@/views/invoices/EditDocument.vue"),
          props: true,
          beforeEnter: async (to, from, next) => {
            // if (
            //   store.state.DocumentModule.document &&
            //   store.state.DocumentModule.document.id == to.params.id
            // ) {
            //   let documentStored = store.state.DocumentModule.document;
            //   to.meta.title = documentStored.documentReference
            //     ? documentStored.documentReference
            //     : "Éditer une facture";
            // } else {
            let loadId = to.params.id ? to.params.id : 0;
            await store
              .dispatch("getInvoiceById", { invoiceId: loadId })
              .then((res) => {
                to.meta.title = res.documentReference
                  ? res.documentReference
                  : "Éditer une facture";
              });
            // }
            next();
          },
          meta: {
            modules: [2],
            pageTitle: "Facture",
            breadcrumb: [
              {
                text: "Facture",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            // title: 'Editer une facture',
            icon: "icon-home",
            tips: "Editer une facture",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [66, 67],
          },
        },
        {
          path: "/credits",
          name: "credits",
          component: () => import("@/views/invoices/Credits.vue"),
          meta: {
            modules: [2],
            pageTitle: "Avoirs",
            breadcrumb: [
              {
                text: "Avoirs",
                active: true,
              },
            ],
            title: "Avoirs",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des avoirs",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [51, 52, 53],
          },
        },
        {
          path: "/new-credit/:id",
          name: "new-credit",
          component: () => import("@/views/invoices/EditDocument.vue"),
          props: true,
          beforeEnter: async (to, from, next) => {
            // if (
            //   store.state.DocumentModule.document &&
            //   store.state.DocumentModule.document.id == to.params.id
            // ) {
            //   let documentStored = store.state.DocumentModule.document;
            //   to.meta.title = documentStored.documentReference
            //     ? documentStored.documentReference
            //     : "Nouvel avoir";
            // } else {
            let loadId = to.params.id ? to.params.id : 0;
            await store
              .dispatch("getCreditById", { creditId: loadId })
              .then((res) => {
                to.meta.title = res.documentReference
                  ? res.documentReference
                  : "Nouvel avoir";
              });
            // }
            next();
          },
          meta: {
            modules: [2],
            pageTitle: "Nouvel avoir",
            breadcrumb: [
              {
                text: "Avoir",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Nouvel avoir",
            icon: "icon-home",
            tips: "Créer un nouvel avoir",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [51, 52, 53],
          },
        },
        {
          path: "/edit-credit/:id",
          name: "edit-credit",
          component: () => import("@/views/invoices/EditDocument.vue"),
          props: true,
          beforeEnter: async (to, from, next) => {
            // if (
            //   store.state.DocumentModule.document &&
            //   store.state.DocumentModule.document.id == to.params.id
            // ) {
            //   let documentStored = store.state.DocumentModule.document;
            //   to.meta.title = documentStored.documentReference
            //     ? documentStored.documentReference
            //     : "Éditer un avoir";
            // } else {
            let loadId = to.params.id ? to.params.id : 0;
            await store
              .dispatch("getCreditById", { creditId: loadId })
              .then((res) => {
                to.meta.title = res.documentReference
                  ? res.documentReference
                  : "Éditer un avoir";
              });
            // }
            next();
          },
          meta: {
            modules: [2],
            pageTitle: "Avoir",
            breadcrumb: [
              {
                text: "Avoir",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Editer un avoir",
            icon: "icon-home",
            tips: "Editer un avoir",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [51, 52, 53],
          },
        },
        {
          path: "/holdbacks",
          name: "holdbacks",
          component: () => import("@/views/invoices/Holdbacks.vue"),
          meta: {
            modules: [2],
            pageTitle: "Retenues de garantie",
            breadcrumb: [
              {
                text: "Retenues de garantie",
                active: true,
              },
            ],
            title: "Retenues de garantie",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des retenues de garantie",
            key: "path",
            closable: true,
            functionIds: [61, 62],
          },
        },
        {
          path: "/payments",
          name: "payments",
          component: () => import("@/views/invoices/Payments.vue"),
          meta: {
            modules: [2],
            pageTitle: "Règlements",
            breadcrumb: [
              {
                text: "Règlements",
                active: true,
              },
            ],
            title: "Règlements",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des règlements",
            key: "path",
            closable: true,
            functionIds: [69],
          },
        },
        {
          path: "/new-payment",
          name: "new-payment",
          component: () => import("@/views/invoices/EditPayment.vue"),
          props: true,
          meta: {
            modules: [2],
            pageTitle: "Nouveau règlement",
            breadcrumb: [
              {
                text: "Règlement",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Nouveau règlement",
            icon: "icon-home",
            tips: "Créer un nouveau règlement",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [69],
          },
        },
        {
          path: "/edit-payment/:id",
          name: "edit-payment",
          component: () => import("@/views/invoices/EditPayment.vue"),
          props: true,
          meta: {
            modules: [2],
            pageTitle: "Editer un règlement",
            breadcrumb: [
              {
                text: "Règlement",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Editer un règlement",
            icon: "icon-home",
            tips: "Editer un règlement",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [69],
          },
        },
        {
          path: "/new-payment-holdback",
          name: "new-payment-holdback",
          component: () => import("@/views/invoices/EditPaymentHoldback.vue"),
          props: true,
          meta: {
            modules: [2],
            pageTitle: "Nouveau règlement (Retenue de garantie)",
            breadcrumb: [
              {
                text: "Règlement",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Nouveau règlement (Retenue de garantie)",
            icon: "icon-home",
            tips: "Créer un nouveau règlement (Retenue de garantie)",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
          },
        },
        {
          path: "/edit-payment-holdback/:id",
          name: "edit-payment-holdback",
          component: () => import("@/views/invoices/EditPaymentHoldback.vue"),
          props: true,
          meta: {
            modules: [2],
            pageTitle: "Editer un règlement (Retenue de garantie)",
            breadcrumb: [
              {
                text: "Règlement",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Editer un règlement (Retenue de garantie)",
            icon: "icon-home",
            tips: "Editer un règlement (Retenue de garantie)",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
          },
        },
        {
          path: "/deduct-credits",
          name: "deduct-credits",
          component: () => import("@/views/invoices/DeductCredits.vue"),
          props: true,
          meta: {
            modules: [2],
            pageTitle: "Déduire un avoir",
            breadcrumb: [
              {
                text: "Déduire un avoir",
                active: true,
              },
            ],
            title: "Déduire un avoir",
            icon: "icon-home",
            tips: "Déduire un avoir",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [55],
          },
        },
        {
          path: "/payment-providers",
          name: "payment-providers",
          component: () =>
            import("@/views/purchase/payment-provider/PaymentProviders.vue"),
          meta: {
            modules: [2],
            pageTitle: "Règlements fournisseur",
            breadcrumb: [
              {
                text: "Règlements fournisseur",
                active: true,
              },
            ],
            title: "Règlements fournisseur",
            icon: "icon-users",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des règlements fournisseur",
            key: "path",
            closable: true,
            functionIds: [113],
          },
        },
        {
          path: "/new-payment-provider",
          name: "new-payment-provider",
          component: () =>
            import("@/views/purchase/payment-provider/EditPaymentProvider.vue"),
          props: true,
          meta: {
            modules: [2],
            pageTitle: "Nouveau règlement fournisseur",
            breadcrumb: [
              {
                text: "Règlement fournisseur",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Nouveau règlement fournisseur",
            icon: "icon-home",
            tips: "Créer un nouveau règlement fournisseur",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [113],
          },
        },
        {
          path: "/edit-payment-provider/:id",
          name: "edit-payment-provider",
          component: () =>
            import("@/views/purchase/payment-provider/EditPaymentProvider.vue"),
          props: true,
          meta: {
            modules: [2],
            pageTitle: "Editer un règlement fournisseur",
            breadcrumb: [
              {
                text: "Règlement fournisseur",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Editer un règlement fournisseur",
            icon: "icon-home",
            tips: "Editer un règlement fournisseur",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [113],
          },
        },
        {
          path: "/deduct-credits-providers",
          name: "deduct-credits-providers",
          component: () =>
            import(
              "@/views/purchase/invoices-provider/DeductCreditsProviders.vue"
            ),
          props: true,
          meta: {
            modules: [2],
            pageTitle: "Déduire un avoir fournisseur",
            breadcrumb: [
              {
                text: "Déduire un avoir fournisseur",
                active: true,
              },
            ],
            title: "Déduire un avoir fournisseur",
            icon: "icon-home",
            tips: "Déduire un avoir fournisseur",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [55],
          },
        },
        /**************************************************************
         ***                       GED
         **************************************************************/
        {
          path: "/ged",
          name: "ged",
          component: () => import("@/views/Ged.vue"),
          meta: {
            modules: [5],
            pageTitle: "GED",
            breadcrumb: [
              {
                text: "GED",
                active: true,
              },
            ],
            title: "GED",
            icon: "icon-inbox",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des documents",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
          },
        },
        /**************************************************************
         ***                       CATALOG
         **************************************************************/
        {
          path: "/catalog",
          name: "catalog",
          component: () => import("@/views/catalog/Products.vue"),
          meta: {
            modules: [2, 4],
            pageTitle: "Produits de vente/stock",
            breadcrumb: [
              {
                text: "Produits de vente/stock",
                active: true,
              },
            ],
            title: "Produits de vente/stock",
            icon: "icon-sheet",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des produits",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [72],
          },
        },
        {
          path: "/new-product",
          name: "new-product",
          component: () => import("@/views/catalog/EditProduct.vue"),
          meta: {
            modules: [2, 4],
            pageTitle: "Nouveau produit",
            breadcrumb: [
              {
                text: "Produit",
                active: true,
              },
            ],
            title: "Nouveau produit",
            icon: "icon-sheet",
            tips: "Créer un nouveau produit",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [72],
          },
        },
        {
          path: "/edit-product/:id",
          name: "edit-product",
          props: true,
          component: () => import("@/views/catalog/EditProduct.vue"),
          meta: {
            modules: [2, 4],
            pageTitle: "Editer un produit",
            breadcrumb: [
              {
                text: "Editer un produit",
                active: true,
              },
            ],
            title: "Editer un produit",
            icon: "icon-sheet",
            tips: "Editer un de produit",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [72],
          },
        },
        /**************************************************************
         ***                       PRODUCT PROVIDER
         **************************************************************/
        {
          path: "/product-provider",
          name: "product-provider",
          component: () =>
            import("@/views/purchase/product-provider/ProductProviders.vue"),
          meta: {
            modules: [4],
            pageTitle: "Produits fournisseur",
            breadcrumb: [
              {
                text: "Produits fournisseur",
                active: true,
              },
            ],
            title: "Produits fournisseur",
            icon: "icon-sheet",
            tabClass: "custom-tab", // custom class, optional
            tips: "Liste des produits fournisseur",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [74],
          },
        },
        {
          path: "/new-product-provider",
          name: "new-product-provider",
          component: () =>
            import("@/views/purchase/product-provider/EditProductProvider.vue"),
          props: true,
          meta: {
            modules: [4],
            pageTitle: "Nouveau produit fournisseur",
            breadcrumb: [
              {
                text: "Nouveau produit fournisseur",
                active: true,
              },
            ],
            title: "Nouveau produit fournisseur",
            icon: "icon-sheet",
            tips: "Créer un nouveau produit fournisseur",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [74],
          },
        },
        {
          path: "/edit-product-provider/:id",
          name: "edit-product-provider",
          props: true,
          component: () =>
            import("@/views/purchase/product-provider/EditProductProvider.vue"),
          meta: {
            modules: [4],
            pageTitle: "Editer un produit fournisseur",
            breadcrumb: [
              {
                text: "Editer un produit fournisseur",
                active: true,
              },
            ],
            title: "Editer un produit fournisseur",
            icon: "icon-sheet",
            tips: "Editer un de produit fournisseur",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [74],
          },
        },
        /**************************************************************
         *                      WASTE COLLECTION POINTS
         * ************************************************************/
        {
          path: "/waste-collection-points",
          name: "waste-collection-points",
          component: () =>
            import(
              "@/views/invoices/wasteCollectionPoint/WasteCollectionPoints.vue"
            ),
          meta: {
            modules: [2],
            pageTitle: "Points de collecte des déchets",
            breadcrumb: [
              {
                text: "Point de collecte des déchets",
                active: true,
              },
            ],
            title: "Point de collecte des déchets",
            icon: "icon-users",
            tabClass: "custom-tab",
            tips: "Liste des points de collecte des déchets",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [83],
          },
        },
        {
          path: "/new-waste-collection-point",
          name: "new-waste-collection-point",
          component: () =>
            import(
              "@/views/invoices/wasteCollectionPoint/EditWasteCollectionPoint.vue"
            ),
          props: true,
          meta: {
            modules: [2],
            pageTitle: "Nouveau point de collecte des déchets",
            breadcrumb: [
              {
                text: "Point de collecte des déchets",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Nouveau point de collecte des déchets",
            icon: "icon-home",
            tips: "Créer un nouveau point de collecte des déchets",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [83],
          },
        },
        {
          path: "/edit-waste-collection-point/:id",
          name: "edit-waste-collection-point",
          component: () =>
            import(
              "@/views/invoices/wasteCollectionPoint/EditWasteCollectionPoint.vue"
            ),
          props: true,
          meta: {
            modules: [2],
            pageTitle: "Point de collecte des déchets",
            breadcrumb: [
              {
                text: "Point de collecte des déchets",
                active: true,
              },
              {
                text: "Editer",
                active: true,
              },
            ],
            title: "Editer un point de collecte des déchets",
            icon: "icon-home",
            tips: "Editer un point de collecte des déchets",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
            functionIds: [83],
          },
        },
        /**************************************************************
         ***                       EXPORT
         **************************************************************/
        {
          path: "/sales-journal",
          name: "sales-journal",
          component: () => import("@/views/salesJournal/SalesJournal.vue"),
          meta: {
            modules: [2],
            pageTitle: "Journal de vente",
            breadcrumb: [
              {
                text: "Journal de vente",
                active: true,
              },
            ],
            title: "Journal de vente",
            icon: "icon-file",
            tabClass: "custom-tab", // custom class, optional
            tips: "Journal de vente",
            key: "path",
            closable: true,
            closeOnSwitchWorkspace: true,
          },
        },
      ],
    },
    {
      path: "/renew-callback",
      name: "renewCallback",
      component: () => import("@/auth/OidcRenewRedirect.vue"),
    },
    {
      path: "/error-401",
      name: "error401",
      component: () => import("@/views/error/Error401.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/error-404",
      name: "error404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "/",
    },
    {
      path: "/documentation",
      name: "documentation",
      beforeEnter() {
        window.open("https://doc.orisis.fr/", "_blank");
      },
    },
  ],
});

const [navigationEntry] = performance.getEntriesByType("navigation");
const isPageReload = navigationEntry && navigationEntry.type === "reload";
let isInitialLoad = true;
if (isPageReload) {
  isInitialLoad = false;
}

router.beforeEach(async (to, from, next) => {
  if (authService.isAuthenticated()) {
    if (!from.name && (localStorage.getItem("fromCallback") || isInitialLoad)) {
      localStorage.removeItem("fromCallback");
      isInitialLoad = false;
      if (localStorage.getItem("homePage")) {
        next({ path: localStorage.getItem("homePage") });
      } else if (checkFunctionUser(134, "execute")) {
        next({ name: "home" });
      } else if (checkFunctionUser(135, "execute")) {
        next({ name: "dashboard-gestion" });
      } else if (checkFunctionUser(136, "execute")) {
        next({ name: "dashboard-crm" });
      } else if (checkFunctionUser(137, "execute")) {
        next({ name: "dashboard-immo" });
      } else if (checkFunctionUser(1, "read")) {
        next({ name: "affairs" });
      } else {
        next({ name: "my-agenda" });
      }
    } else {
      const newRoute = router.options.routes[0].children.find(
        (elem) => elem.name == to.name
      );
      //Check with checkfunctionuser and functionIds if user can access to the route
      if (to.meta.functionIds && to.meta.functionIds.length) {
        if (
          to.meta.functionIds.every(
            (funct) =>
              (checkFunctionUser(funct, "read") &&
                checkFunctionUser(funct, "create") &&
                checkFunctionUser(funct, "update") &&
                checkFunctionUser(funct, "delete")) ||
              checkFunctionUser(funct, "execute")
          )
        ) {
          if (to.name == from.name && newRoute && newRoute.beforeEnter) {
            return newRoute.beforeEnter(to, from, next);
          } else {
            next();
          }
        } else {
          next({ name: "error401" });
        }
      } else {
        if (to.name == from.name && newRoute && newRoute.beforeEnter) {
          return newRoute.beforeEnter(to, from, next);
        } else {
          next();
        }
      }
    }
  } else {
    authService.login();
  }
  //Gestion des titre de tabs dynamiquement
  if (to.params && to.params.title) {
    to.meta.title = to.params.title;
    to.meta.tips = to.params.tips ? to.params.tips : to.params.title;
  } else if (to.params && to.params.name) {
    if (to.path.includes("new-")) {
      to.meta.title = "Nouveau " + to.params.name;
      to.meta.pageTitle = "Nouveau " + to.params.name;
      to.meta.tips = "Créer un nouveau " + to.params.name;
    } else {
      to.meta.title = to.params.name[0].toUpperCase() + to.params.name.slice(1);
      to.meta.pageTitle =
        to.params.name[0].toUpperCase() + to.params.name.slice(1);
      to.meta.tips = "Liste des " + to.params.name + "s";
    }
  }
});
export default router;
