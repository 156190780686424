import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { CustomFieldRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { ICustomFieldState } from './states'


export const getters: GetterTree<ICustomFieldState, RootState> = {
  customFieldsList: (state): CustomFieldRequestDto[] => state.customFieldsList,
  customFieldsListArchived: (state): CustomFieldRequestDto[] => state.customFieldsListArchived,
  customField     : (state): CustomFieldRequestDto => state.customField,

  // COMMON
  isLoadingCustomFieldsList   : (state): boolean => state.isLoadingCustomFieldsList,
  isLoadingCustomFieldsListArchived   : (state): boolean => state.isLoadingCustomFieldsListArchived,
  isLoadingCustomField   : (state): boolean => state.isLoadingCustomField,
  isCreatingCustomField  : (state): boolean => state.isCreatingCustomField,
  isUpdatingCustomField  : (state): boolean => state.isUpdatingCustomField,
  isDeletingCustomField  : (state): boolean => state.isDeletingCustomField,
  isArchivingCustomField  : (state): boolean => state.isArchivingCustomField,
  requestStateCustomField: (state): IRequestState => state.requestStateCustomField,
}



