import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { PaymentMethodRequestDto } from "Api";
import { GetterTree } from "vuex";
import RootState from '@/store/modules/rootState'
import {  IPaymentMethodState } from "./states";

export const getters: GetterTree<IPaymentMethodState, RootState> = {
    paymentMethodsList: (state): PaymentMethodRequestDto[] => state.paymentMethodsList,
    paymentMethodsListArchived: (state): PaymentMethodRequestDto[] => state.paymentMethodsListArchived,
    paymentMethod     : (state): PaymentMethodRequestDto => state.paymentMethod,
    
    // COMMON
    isLoadingPaymentMethodsList   : (state): boolean => state.isLoadingPaymentMethodsList,
    isLoadingPaymentMethodsListArchived : (state): boolean => state.isLoadingPaymentMethodsListArchived,
    isLoadingPaymentMethod : (state): boolean => state.isLoadingPaymentMethod,
    isCreatingPaymentMethod  : (state): boolean => state.isCreatingPaymentMethod,
    isUpdatingPaymentMethod : (state): boolean => state.isUpdatingPaymentMethod,
    isDeletingPaymentMethod : (state): boolean => state.isDeletingPaymentMethod,
    requestStatePaymentMethod: (state): IRequestState => state.requestStatePaymentMethod,
    isArchivingPaymentMethod : (state): boolean => state.isArchivingPaymentMethod,
}