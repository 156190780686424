import { ISaleCategoryState } from "./states"
import { Module } from "vuex"
import { SaleCategoryRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state: ISaleCategoryState = {
    saleCategoriesList                     : Array<SaleCategoryRequestDto>(),
    saleCategoriesTreeList                 : Array<any>(),
    saleCategoriesListArchived             : Array<SaleCategoryRequestDto>(),
    saleCategory                           : <SaleCategoryRequestDto>{},
    isLoadingSaleCategoriesList            : false,
    isLoadingSaleCategoriesTreeList        : false,
    isLoadingSaleCategoriesListArchived    : false,
    isLoadingSaleCategory                  : false,
    isCreatingSaleCategory                 : false,
    isUpdatingSaleCategory                 : false,
    isDeletingSaleCategory                 : false,
    isArchivingSaleCategory                : false,
    requestStateSaleCategory               : <IRequestState>{},
}

export const SaleCategoryModule: Module<ISaleCategoryState, RootState> = {
    state, 
    getters,
    mutations,
    actions
} 