import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import createPersistedState from "vuex-persistedstate";

import { GlobalModule } from "./modules/global/index";

import { AccountModule } from "./modules/account/account/index";
import { AuthModule } from "./modules/auth/index";
import { EventTypeModule } from "./modules/planning/eventType/index";
import { EventModule } from "./modules/planning/event/index";
import { SmsModule } from "./modules/planning/sms/index";

import { WorkspaceModule } from "./modules/account/workspace/index"; // fait mais il reste une fonction à placer au bon endroit
import { UserModule } from "./modules/directory/user/index";

import { AffairModule } from "./modules/directory/affair/index";
import { AffairCostingModule } from "./modules/directory/affairCosting/index";

import { CustomFieldModule } from "./modules/parameters/customField";
import { CustomFieldGroupModule } from "./modules/parameters/customFieldGroup";
import { CustomFieldTemplateModule } from "./modules/parameters/customFieldTemplate";

import { CollaboratorModule } from "./modules/directory/collaborator/index";
import { CollaboratorGoalModule } from "./modules/directory/collaboratorGoal/index";
import { CompanyModule } from "./modules/directory/company/index";
import { CompaniesTypesModule } from "./modules/directory/companiesTypes/index";
import { ContactModule } from "./modules/directory/contact/index";
import { PlanningViewModule } from "./modules/planning/planningView/index";
import { PlanningStatisticsModule } from "./modules/planning/planningStatistics/index";
import { PlanningLineModule } from "./modules/planning/planningLine/index";
import { ContractModule } from "./modules/parameters/contract/index";
import { JobModule } from "./modules/parameters/job/index";
import { CommonModule } from "./modules/directory/common";
import { InvoiceStatisticsModule } from "./modules/invoices/invoiceStatistics/index";
import { DocumentDetail } from "./modules/invoices/documentDetail";
import { QuoteOptionModule } from "./modules/documentSettings/quoteOption";
import { QuoteOptionDefaultModule } from "./modules/documentSettings/quoteOptionDefault";
import { InvoiceOptionModule } from "./modules/documentSettings/invoiceOption";
import { InvoiceOptionDefaultModule } from "./modules/documentSettings/invoiceOptionDefault";
import { CreditOptionModule } from "./modules/documentSettings/creditOption";
import { CreditOptionDefaultModule } from "./modules/documentSettings/creditOptionDefault";
import { OrderFormCustomerOptionModule } from "./modules/documentSettings/orderFormCustomerOption";
import { OrderFormCustomerOptionDefaultModule } from "./modules/documentSettings/orderFormCustomerOptionDefault";
import { OrderFormOptionModule } from "./modules/documentSettings/orderFormOption";
import { OrderFormOptionDefaultModule } from "./modules/documentSettings/orderFormOptionDefault";
import { ReceiptFormOptionModule } from "./modules/documentSettings/receiptFormOption";
import { ReceiptFormOptionDefaultModule } from "./modules/documentSettings/receiptFormOptionDefault";
import { DeliveryFormOptionModule } from "./modules/documentSettings/deliveryFormOption";
import { DeliveryFormOptionDefaultModule } from "./modules/documentSettings/deliveryFormOptionDefault";
import { BankAccountModule } from "./modules/invoices/bankAccount";
import { PaymentMethodModule } from "./modules/invoices/paymentMethod";
import { DocumentModule } from "./modules/invoices/document";
import { QuoteDownPaymentsRequestModule } from "./modules/invoices/quoteDownPaymentsRequest";
import { DocumentHistoryModule } from "./modules/invoices/documentHistory";
import { ReferencielTVAModule } from "./modules/invoices/referencielTva";
import { UnitsModule } from "./modules/invoices/unit";
import { WasteCollectionPointModule } from "./modules/invoices/wasteCollectionPoint";
import { WasteCollectionPointTypeModule } from "./modules/invoices/wasteCollectionPointType";
import { WasteTypeModule } from "./modules/invoices/wasteType";
import { PaymentModule } from "./modules/invoices/payment";
import { CatalogModule } from "./modules/invoices/catalog";
import { DeductionModule } from "./modules/invoices/deduction";
import { PaymentConditionModule } from "./modules/invoices/paymentCondition";
import { InstitutionSettingsModule } from "./modules/institutionSettings";
import { InstitutionTypeModule } from "./modules/institutionType";
import { InstitutionModule } from "./modules/institution";
import { HelpersModule } from "./modules/helpers";
import { TermsAndConditionsModule } from "./modules/invoices/termsAndConditions";
import { ModuleModule } from "./modules/account/module";
import { ModuleParameterModule } from "./modules/account/moduleParameter";
import { ExportModule } from "./modules/accounting/export";
import { ExportSalesJournalModule } from "./modules/accounting/exportSalesJournal";
import { FiscalYearModule } from "./modules/invoices/fiscalYear";
import { AffairCategoryModule } from "./modules/parameters/affairCategory";
import { ProductCategoryModule } from "./modules/parameters/productCategory";
import { SaleCategoryModule } from "./modules/parameters/saleCategory";
import { PurchaseCategoryModule } from "./modules/parameters/purchaseCategory";
import { DegressivePurchaseRateModule } from "./modules/invoices/degressivePurchaseRates";
import { DegressiveSaleRateModule } from "./modules/invoices/degressiveSaleRates";
import { TagModule } from "./modules/parameters/tags";
import { SmtpSettingModule } from "./modules/parameters/smtpSetting";

import { FolderModule } from "./modules/ged/folder";
import { FileModule } from "./modules/ged/file";

import { LeaseModule } from "./modules/billing/lease/index";
import { PeriodicityModule } from "./modules/billing/periodicity/index";
import { LeaseTypeModule } from "./modules/billing/lease-type/index";

import { ActivityModule } from "./modules/directory/activity";
import { ActivityChanelTypeModule } from "./modules/directory/activityChanelType";
import { ActivityResultTypeModule } from "./modules/directory/activityResultType";
import { ActivitySourceTypeModule } from "./modules/directory/activitySourceType";
import { CompanyIndicatorModule } from "./modules/directory/companyIndicator";

import { StorageLocationModule } from "./modules/purchase/storageLocation/index";
import { InvoiceProviderModule } from "./modules/purchase/invoiceProvider/index";
import { CreditProviderModule } from "./modules/purchase/creditProvider/index";
import { OrderFormProviderModule } from "./modules/purchase/orderFormProvider/index";
import { ReceiptFormProviderModule } from "./modules/purchase/receiptFormProvider/index";
import { ReceiptFormProviderDetailModule } from "./modules/purchase/receiptFormProviderDetails/index";
import { OrderFormProviderDetailModule } from "./modules/purchase/orderFormProviderDetails/index";
import { OrderFormProviderHistoryModule } from "./modules/purchase/orderFormProviderHistory/index";
import { InventoryModule } from "./modules/purchase/inventory/index";
import { InventoryDetailModule } from "./modules/purchase/inventoryDetail/index";
import { StockModule } from "./modules/purchase/stock/index";
import { ReceiptFormProviderHistoryModule } from "./modules/purchase/receiptFormProviderHistory/index";
import { StockMovementModule } from "./modules/purchase/stockMovement/index";
import { ProductProviderModule } from "./modules/purchase/productProvider/index";
import { DeliveryFormModule } from "./modules/purchase/deliveryForm/index";
import { DeliveryFormDetailModule } from "./modules/purchase/deliveryFormDetails/index";
import { DeliveryFormHistoryModule } from "./modules/purchase/deliveryFormHistory/index";
import { PaymentProviderModule } from "./modules/purchase/paymentProvider/index";
import { ModalEditRefProductModule } from "./modules/purchase/modalEditRefProduct/index";

import { AnalyticsSettingModule } from "./modules/reporting/analyticsSetting/index";
import { MissionModule } from "./modules/reporting/mission/index";
import { ReportDetailModule } from "./modules/reporting/reportDetail/index";
import { PayrollEventModule } from "./modules/reporting/payrollEvent/index";
import { PayAllowanceTypeModule } from "./modules/reporting/payAllowanceType/index";
import { InterventionModule } from "./modules/interventions/index";

import { PropertyTypeModule } from "./modules/billing/property-type/index";

import { OrderFormCustomerModule } from "./modules/invoices/orderFormCustomer/index";
import { OrderFormCustomerDetailModule } from "./modules/invoices/orderFormCustomerDetail/index";
import { OrderFormCustomerDownPaymentsRequestModule } from "./modules/invoices/orderFormCustomerDownPaymentsRequest/index";
import { OrderFormCustomerHistoryModule } from "./modules/invoices/orderFormCustomerHistory/index";

import { HoldbackModule } from "./modules/invoices/holdback";

import { InitialDocumentModule } from "./modules/invoices/initialDocument/index";

import { UserParameterModule } from "./modules/account/userParameter/index";

import { CurrencyModule } from "./modules/parameters/currency";

Vue.use(Vuex);
const authState = createPersistedState({
  paths: ["AccountModule"],
  key: "AccountModule",
});
// const workspaceState = createPersistedState({ paths: ['workspaceSelected'], key: "workspaceSelected" });
const planningViewState = createPersistedState({
  paths: ["planningViewSelected"],
  key: "planningViewSelected",
});

const store: StoreOptions<{}> = {
  modules: {
    //app de base
    app,
    appConfig,
    verticalMenu,

    GlobalModule,

    AccountModule,
    AuthModule,
    SmsModule,
    WorkspaceModule,
    CommonModule,

    CustomFieldModule,
    CustomFieldGroupModule,
    CustomFieldTemplateModule,

    EventModule,
    EventTypeModule,
    UserModule,
    AffairModule,
    AffairCostingModule,
    CollaboratorModule,
    CollaboratorGoalModule,
    CompanyModule,
    CompaniesTypesModule,
    ContactModule,
    PlanningLineModule,
    ContractModule,
    PlanningViewModule,
    PlanningStatisticsModule,
    JobModule,
    CatalogModule,
    InvoiceStatisticsModule,
    QuoteOptionModule,
    QuoteOptionDefaultModule,
    InvoiceOptionModule,
    InvoiceOptionDefaultModule,
    CreditOptionModule,
    CreditOptionDefaultModule,
    OrderFormCustomerOptionModule,
    OrderFormCustomerOptionDefaultModule,
    OrderFormOptionModule,
    OrderFormOptionDefaultModule,
    ReceiptFormOptionModule,
    ReceiptFormOptionDefaultModule,
    DeliveryFormOptionModule,
    DeliveryFormOptionDefaultModule,

    DocumentDetail,
    BankAccountModule,
    PaymentMethodModule,
    DocumentModule,
    QuoteDownPaymentsRequestModule,
    DocumentHistoryModule,
    ReferencielTVAModule,
    UnitsModule,
    WasteCollectionPointModule,
    WasteCollectionPointTypeModule,
    WasteTypeModule,
    PaymentModule,
    DeductionModule,
    PaymentConditionModule,
    InstitutionSettingsModule,
    InstitutionModule,
    InstitutionTypeModule,
    TermsAndConditionsModule,
    HelpersModule,
    ModuleModule,
    ModuleParameterModule,
    ExportModule,
    ExportSalesJournalModule,
    FiscalYearModule,
    AffairCategoryModule,
    ProductCategoryModule,
    SaleCategoryModule,
    PurchaseCategoryModule,
    DegressivePurchaseRateModule,
    DegressiveSaleRateModule,
    TagModule,
    SmtpSettingModule,
    CurrencyModule,
    // Module GED
    FolderModule,
    FileModule,

    // Module CRM
    ActivityModule,
    ActivityChanelTypeModule,
    ActivityResultTypeModule,
    ActivitySourceTypeModule,
    CompanyIndicatorModule,

    // Module Baux
    LeaseModule,
    PeriodicityModule,
    LeaseTypeModule,
    PropertyTypeModule,

    // Module Achat
    StorageLocationModule,
    InvoiceProviderModule,
    CreditProviderModule,
    OrderFormProviderModule,
    ReceiptFormProviderModule,
    ReceiptFormProviderDetailModule,
    OrderFormProviderDetailModule,
    OrderFormProviderHistoryModule,
    ReceiptFormProviderHistoryModule,
    InventoryModule,
    InventoryDetailModule,
    StockModule,
    StockMovementModule,
    ProductProviderModule,
    DeliveryFormModule,
    DeliveryFormDetailModule,
    DeliveryFormHistoryModule,
    PaymentProviderModule,
    ModalEditRefProductModule,

    // Module Reporting
    AnalyticsSettingModule,
    MissionModule,
    ReportDetailModule,
    PayrollEventModule,
    PayAllowanceTypeModule,

    // Module Interventions
    InterventionModule,

    // Module Bon de commande client
    OrderFormCustomerModule,
    OrderFormCustomerDetailModule,
    OrderFormCustomerDownPaymentsRequestModule,
    OrderFormCustomerHistoryModule,

    // Module Document initial
    InitialDocumentModule,

    UserParameterModule,
    HoldbackModule,
  },
  plugins: [authState, planningViewState],
};

export default new Vuex.Store<{}>(store);
