import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { InstitutionSettingReferencielTvaRequestDto } from "Api";
import RootState from "@/store/modules/rootState"
import { IReferencielTVAState } from "./states";
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

export const state: IReferencielTVAState = {
    referencielTVAsList                  : Array<InstitutionSettingReferencielTvaRequestDto>(),
    referencielTVAsListArchived          : Array<InstitutionSettingReferencielTvaRequestDto>(),
    referencielTVA                       : <InstitutionSettingReferencielTvaRequestDto>{},
    isLoadingReferencielTVAsList         : false,
    isLoadingReferencielTVAsListArchived : false,
    isLoadingReferencielTVA              : false,
    isCreatingReferencielTVA             : false,
    isUpdatingReferencielTVA             : false,
    isDeletingReferencielTVA             : false,
    requestStateReferencielTVA           : <IRequestState>{},
    isArchivingReferencielTVA            : false,
}

export const ReferencielTVAModule: Module<IReferencielTVAState, RootState> = {
  state, 
  getters,
  mutations,
  actions
}
