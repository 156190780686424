import { GetterTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { ICreditOptionState } from "./states";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { CreditOptionRequestDto } from "Api";

export const getters: GetterTree<ICreditOptionState, RootState> = {
  creditOption(state): CreditOptionRequestDto {
    return state.creditOption;
  },
  isLoadingCreditOption(state): boolean {
    return state.isLoadingCreditOption;
  },
  requestStateCreditOption(state): IRequestState {
    return state.requestStateCreditOption;
  },
};
