import { ICompanyIndicatorState } from "./states"
import { Module } from "vuex"
import { CompanyIndicatorRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:ICompanyIndicatorState = {
    companyIndicatorsList         : Array<CompanyIndicatorRequestDto>(),
    companyIndicatorsListArchived : Array<CompanyIndicatorRequestDto>(),
    companyIndicator              : <CompanyIndicatorRequestDto>{},
    isLoadingCompanyIndicatorsList     : false,
    isLoadingCompanyIndicatorsListArchived     : false,
    isLoadingCompanyIndicator     : false,
    isCreatingCompanyIndicator    : false,
    isUpdatingCompanyIndicator    : false,
    isDeletingCompanyIndicator    : false,
    isArchivingCompanyIndicator    : false,
    requestStateCompanyIndicator: <IRequestState>{},
    companyIndicatorFilters                : {}
}

export const CompanyIndicatorModule: Module<ICompanyIndicatorState, RootState> = {
    state, 
    getters,
    mutations,
    actions
} 