import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { UserParameterRequestDto } from "@/../Api";
import { MutationTree } from "vuex";
import { IUserParameterState } from "./states";

export enum UserParameterMutations {
  SET_USER_PARAMETERS_LIST = "SET_USER_PARAMETERS_LIST",
  SET_USER_PARAMETERS_LIST_BY_USER = "SET_USER_PARAMETERS_LIST_BY_USER",
  SET_USER_PARAMETER = "SET_USER_PARAMETER",

  // COMMON
  SET_IS_LOADING_USER_PARAMETER_LIST = "SET_IS_LOADING_USER_PARAMETER_LIST",
  SET_IS_LOADING_USER_PARAMETER = "SET_IS_LOADING_USER_PARAMETER",
  SET_IS_CREATING_USER_PARAMETER = "SET_IS_CREATING_USER_PARAMETER",
  SET_IS_UPDATING_USER_PARAMETER = "SET_IS_UPDATING_USER_PARAMETER",
  SET_IS_DELETING_USER_PARAMETER = "SET_IS_DELETING_USER_PARAMETER",
  SET_REQUEST_STATE_USER_PARAMETER = "SET_REQUEST_STATE_USER_PARAMETER",
}

export const mutations: MutationTree<IUserParameterState> = {
  [UserParameterMutations.SET_USER_PARAMETERS_LIST]: (
    state,
    payload: any[]
  ) => {
    state.userParametersList = payload;
  },
  [UserParameterMutations.SET_USER_PARAMETERS_LIST_BY_USER]: (
    state,
    payload: any[]
  ) => {
    state.userParametersListByUser = payload;
  },
  [UserParameterMutations.SET_USER_PARAMETER]: (
    state,
    payload: UserParameterRequestDto
  ) => {
    state.userParameter = payload;
  },

  //COMMON
  [UserParameterMutations.SET_IS_LOADING_USER_PARAMETER_LIST]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingUserParametersList = payload;
  },
  [UserParameterMutations.SET_IS_LOADING_USER_PARAMETER]: (
    state,
    payload: boolean
  ) => {
    state.isLoadingUserParameter = payload;
  },
  [UserParameterMutations.SET_IS_CREATING_USER_PARAMETER]: (
    state,
    payload: boolean
  ) => {
    state.isCreatingUserParameter = payload;
  },
  [UserParameterMutations.SET_IS_UPDATING_USER_PARAMETER]: (
    state,
    payload: boolean
  ) => {
    state.isUpdatingUserParameter = payload;
  },
  [UserParameterMutations.SET_REQUEST_STATE_USER_PARAMETER]: (
    state,
    payload: IRequestState
  ) => {
    state.requestStateUserParameter = payload;
  },
};
