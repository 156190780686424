import { IActivityResultTypeState } from "./states"
import { Module } from "vuex"
import { ActivityResultTypeRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IActivityResultTypeState = {
    activityResultTypesList         : Array<ActivityResultTypeRequestDto>(),
    activityResultTypesListArchived : Array<ActivityResultTypeRequestDto>(),
    activityResultType              : <ActivityResultTypeRequestDto>{},
    isLoadingActivityResultTypesList     : false,
    isLoadingActivityResultTypesListArchived     : false,
    isLoadingActivityResultType     : false,
    isCreatingActivityResultType    : false,
    isUpdatingActivityResultType    : false,
    isDeletingActivityResultType    : false,
    isArchivingActivityResultType    : false,
    requestStateActivityResultType: <IRequestState>{},
    activityResultTypeFilters                : {}
}

export const ActivityResultTypeModule: Module<IActivityResultTypeState, RootState> = {
    state, 
    getters,
    mutations,
    actions
} 