import axios from "axios";
import { ActionTree } from "vuex";
import { InstitutionSettingsMutations } from "../institutionSettings/mutations";
import RootState from "@/store/modules/rootState";
import { InstitutionMutations } from "./mutations";
import { IInstitutionState } from "./states";
import { InstitutionRequestDto } from "Api";
import { checkFunctionUser } from "../../../auth/utils";

export const actions: ActionTree<IInstitutionState, RootState> = {
  getInstitution(
    { commit, dispatch },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      if (updateState) {
        commit(InstitutionMutations.SET_IS_LOADING_INSTITUTION, true);
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS + "Account/Institution/GetInstitution"
        )
        .then(async (res) => {
          let data = {
            ...res.data,
            secondaryColor: res.data.secondaryColor
              ? res.data.secondaryColor
              : "#ffffff",
          };
          commit(InstitutionMutations.SET_INSTITUTION_SELECTED, data);
          commit(InstitutionMutations.SET_REQUEST_STATE_INSTITUTION, res);
          if (res.data.id != 60) {
            await dispatch("getInstitutionSetting", {});
          }
          if (checkFunctionUser(50, "read")) {
            await dispatch("getBankAccounts", {});
          }
          if (checkFunctionUser(60, "read")) {
            await dispatch("getFiscalYearByInstitutionSettingId", {
              institutionSettingId: data.id,
            });
          }
          commit(InstitutionMutations.SET_IS_LOADING_INSTITUTION, false);
          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          commit(InstitutionMutations.SET_REQUEST_STATE_INSTITUTION, err);
          commit(InstitutionMutations.SET_IS_LOADING_INSTITUTION, false);
          reject(err);
        });
    });
  },

  getInstitutionsArchived(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          InstitutionMutations.SET_IS_LOADING_INSTITUTIONS_LIST_ARCHIVED,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Account/Institution/GetInstitutionsArchived`;
      axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              InstitutionMutations.SET_IS_LOADING_INSTITUTIONS_LIST_ARCHIVED,
              false
            );
            commit(
              InstitutionMutations.SET_INSTITUTIONS_LIST_ARCHIVED,
              res.data
            );
          }
          commit(InstitutionMutations.SET_REQUEST_STATE_INSTITUTION, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(InstitutionMutations.SET_IS_LOADING_INSTITUTIONS_LIST, false);
          commit(InstitutionMutations.SET_REQUEST_STATE_INSTITUTION, err);
          reject(err);
        });
    });
  },

  getInstitutionsMinified(
    { commit },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(
          InstitutionMutations.SET_IS_LOADING_INSTITUTIONS_LIST_MINIFIED,
          true
        );
      }
      let url = `${process.env.VUE_APP_API_ORISIS}Account/Institution/GetInstitutionMinified`;
      axios
        .get(url)
        .then((res) => {
          if (updateState) {
            commit(
              InstitutionMutations.SET_IS_LOADING_INSTITUTIONS_LIST_MINIFIED,
              false
            );
            commit(
              InstitutionMutations.SET_INSTITUTIONS_LIST_MINIFIED,
              res.data
            );
          }
          commit(InstitutionMutations.SET_REQUEST_STATE_INSTITUTION, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(InstitutionMutations.SET_IS_LOADING_INSTITUTIONS_LIST_MINIFIED, false);
          commit(InstitutionMutations.SET_REQUEST_STATE_INSTITUTION, err);
          reject(err);
        });
    });
  },

  createInstitution(
    { dispatch, commit, rootGetters },
    {
      institution,
      action = true,
    }: { institution: InstitutionRequestDto; action: boolean }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(InstitutionMutations.SET_IS_CREATING_INSTITUTION, true);
      axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            "Account/Institution/CreateInstitution",
          institution
        )
        .then(async (res) => {
          if (action) {
            rootGetters.institutionsList.push(res.data);
            commit(
              InstitutionMutations.SET_INSTITUTIONS_LIST,
              rootGetters.institutionsList
            );
            commit(InstitutionMutations.SET_INSTITUTION, res.data.data);
            commit(InstitutionMutations.SET_REQUEST_STATE_INSTITUTION, res);
            commit(InstitutionMutations.SET_IS_CREATING_INSTITUTION, false);
            await dispatch("getInstitution", {});
            await dispatch("getInstitutionSetting", {});
            resolve(res.data.data);
          } else {
            let institutionSettings = {
              institutionId: res.data.data.id,
              supplyMarginDefault: 0,
              manPowerMarginDefault: 0,
              subContractingMarginDefault: 0,
              equipementMarginDefault: 0,
              variousMarginDefault: 0,
              calculateMarginDefault: true,
              showReferenceColumnDefault: true,
              hideTotalSectionDefault: true,
              hideUnitColumnDefault: true,
              hideQuantityColumnDefault: true,
              hideReferencielTvaColumnDefault: true,
              hideWasteCollectionDefault: true,
              hideLinesQuantityEmptyDefault: false,
              showDetailWorkLineDefault: false,
              showDescriptionDefault: false,
              showPhoneNumberCompanyDefault: true,
              showMailCompanyDefault: true,
              showSiretCompanyDefault: true,
              showDiscountColumnDefault: true,
              showWaterMarkDefault: false,
              hideUserDefault: true,
              footer: "",
              footerType: 1,
              showTvaNumberCompanyDefault: true,
              laborMarginDefault: 0,
              validityQuoteDefault: 30,
              commentQuoteDefault: "",
              paymentConditionInvoiceDefaultId: 1,
              commentInvoiceDefault: "",
              showTermsOfSales: true,
              showTermsOfSalesInvoice: true,
              termsOfSales: "",
              referencielTvas: [],
              paymentMethodsDefaults: [],
              quoteDownPaymentsRequestDefaults: [],
            };
            await dispatch("createInstitutionSettings", {
              institutionSettings: institutionSettings,
            });
            commit(InstitutionMutations.SET_IS_CREATING_INSTITUTION, false);
            resolve(res.data.data);
          }
        })
        .catch((err) => {
          console.error(err);
          commit(InstitutionMutations.SET_REQUEST_STATE_INSTITUTION, err);
          commit(InstitutionMutations.SET_IS_CREATING_INSTITUTION, false);
          reject(err);
        });
    });
  },

  updateInstitutionDefault(
    { commit, rootGetters },
    { institutionId }: { institutionId: number }
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      commit(InstitutionMutations.SET_IS_LOADING_INSTITUTIONS_LIST, true);
      for (
        let index = 0;
        index < rootGetters.institutionsList.length;
        index++
      ) {
        const institution = rootGetters.institutionsList[index];
        // On met à jour l'institution
        if (
          (institution.id !== institutionId && institution.isDefault == true) ||
          institution.id == institutionId
        ) {
          if (institution.id == institutionId) {
            institution.isDefault = true;
          } else {
            institution.isDefault = false;
          }
          commit(InstitutionMutations.SET_IS_UPDATING_INSTITUTION, true);
          axios
            .put(
              process.env.VUE_APP_API_ORISIS +
                "Account/Institution/UpdateInstitution",
              institution
            )
            .then((res) => {
              commit(InstitutionMutations.SET_REQUEST_STATE_INSTITUTION, res);
              commit(InstitutionMutations.SET_IS_UPDATING_INSTITUTION, false);
            })
            .catch((err) => {
              console.error(err);
              commit(InstitutionMutations.SET_REQUEST_STATE_INSTITUTION, err);
              commit(InstitutionMutations.SET_IS_UPDATING_INSTITUTION, false);
              reject(err);
            });
        }
      }
      // await dispatch("initializeStore");
      resolve(true);
    });
  },

  updateInstitution({ dispatch, commit }, { institution }): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(InstitutionMutations.SET_IS_UPDATING_INSTITUTION, true);
      axios
        .put(
          process.env.VUE_APP_API_ORISIS +
            "Account/Institution/UpdateInstitution",
          institution
        )
        .then((res) => {
          let data = res.data.data;
          data.institutionTypes = institution.institutionTypes;
          commit(InstitutionMutations.SET_INSTITUTION, data);
          commit(InstitutionMutations.SET_REQUEST_STATE_INSTITUTION, res);
          commit(InstitutionMutations.SET_IS_UPDATING_INSTITUTION, false);
          dispatch("getInstitution", {});
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(InstitutionMutations.SET_REQUEST_STATE_INSTITUTION, err);
          commit(InstitutionMutations.SET_IS_UPDATING_INSTITUTION, false);
          reject(err);
        });
    });
  },

  uploadLogo({ commit }, { file }: { file: any }): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Account/Institution/UploadLogo",
          file
        )
        .then((res) => {
          commit(InstitutionMutations.SET_REQUEST_STATE_INSTITUTION, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(InstitutionMutations.SET_REQUEST_STATE_INSTITUTION, err);
          reject(err);
        });
    });
  },

  restoreInstitutions(
    { commit, dispatch, rootGetters },
    { institutionIds = [] }: { institutionIds: number[] }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(InstitutionMutations.SET_IS_UPDATING_INSTITUTION, true);
      axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Sale/Institution/Restore",
          institutionIds
        )
        .then((res) => {
          institutionIds.forEach((institutionId: number) => {
            rootGetters.institutionsListArchived.splice(
              rootGetters.institutionsListArchived.findIndex(
                (institution) => institution.id == institutionId
              ),
              1
            );
          });
          dispatch("getInstitutionsArchived", {});
          commit(InstitutionMutations.SET_REQUEST_STATE_INSTITUTION, res);
          commit(InstitutionMutations.SET_IS_UPDATING_INSTITUTION, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(InstitutionMutations.SET_REQUEST_STATE_INSTITUTION, err);
          commit(InstitutionMutations.SET_IS_UPDATING_INSTITUTION, false);
          reject(err);
        });
    });
  },

  archiveInstitutions(
    { commit, dispatch, rootGetters },
    { institutionIds = [] }: { institutionIds: number[] }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(InstitutionMutations.SET_IS_ARCHIVING_INSTITUTION, true);
      axios
        .delete(
          process.env.VUE_APP_API_ORISIS + "Account/Institution/Archive",
          {
            data: institutionIds,
          }
        )
        .then((res) => {
          institutionIds.forEach((institutionId: number) => {
            rootGetters.institutionsList.splice(
              rootGetters.institutionsList.findIndex(
                (institution) => institution.id == institutionId
              ),
              1
            );
          });
          dispatch("getInstitution", {});
          commit(InstitutionMutations.SET_REQUEST_STATE_INSTITUTION, res);
          commit(InstitutionMutations.SET_IS_ARCHIVING_INSTITUTION, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(InstitutionMutations.SET_REQUEST_STATE_INSTITUTION, err);
          commit(InstitutionMutations.SET_IS_ARCHIVING_INSTITUTION, false);
          reject(err);
        });
    });
  },

  deleteInstitutions(
    { commit, rootGetters },
    { institutionIds = [] }: { institutionIds: number[] }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(InstitutionMutations.SET_IS_DELETING_INSTITUTION, true);
      axios
        .delete(process.env.VUE_APP_API_ORISIS + "Account/Institution/Delete", {
          data: institutionIds,
        })
        .then((res) => {
          institutionIds.forEach((institutionId: number) => {
            rootGetters.institutionsListArchived.splice(
              rootGetters.institutionsListArchived.findIndex(
                (institution) => institution.id == institutionId
              ),
              1
            );
          });
          commit(InstitutionMutations.SET_REQUEST_STATE_INSTITUTION, res);
          commit(InstitutionMutations.SET_IS_DELETING_INSTITUTION, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(InstitutionMutations.SET_REQUEST_STATE_INSTITUTION, err);
          commit(InstitutionMutations.SET_IS_DELETING_INSTITUTION, false);
          reject(err);
        });
    });
  },
};
