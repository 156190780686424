import { IProductCategoryState } from "./states"
import { Module } from "vuex"
import { ProductCategoryRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state: IProductCategoryState = {
    productCategoriesList                     : Array<ProductCategoryRequestDto>(),
    productCategoriesTreeList                 : Array<any>(),
    productCategoriesListArchived             : Array<ProductCategoryRequestDto>(),
    productCategory                           : <ProductCategoryRequestDto>{},
    isLoadingProductCategoriesList            : false,
    isLoadingProductCategoriesTreeList        : false,
    isLoadingProductCategoriesListArchived    : false,
    isLoadingProductCategory                  : false,
    isCreatingProductCategory                 : false,
    isUpdatingProductCategory                 : false,
    isDeletingProductCategory                 : false,
    isArchivingProductCategory                : false,
    requestStateProductCategory               : <IRequestState>{},
}

export const ProductCategoryModule: Module<IProductCategoryState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}