import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { PropertyTypeRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IPropertyTypeState } from "./states";

export enum PropertyTypeMutations {
    SET_PROPERTY_TYPE_LIST = "SET_PROPERTY_TYPE_LIST",
    SET_PROPERTY_TYPE_LIST_ARCHIVED = "SET_PROPERTY_TYPE_LIST_ARCHIVED",
    SET_PROPERTY_TYPE = "SET_PROPERTY_TYPE",

    // COMMON
    SET_IS_LOADING_PROPERTY_TYPE_LIST = "SET_IS_LOADING_PROPERTY_TYPE_LIST",
    SET_IS_LOADING_PROPERTY_TYPE_LIST_ARCHIVED = "SET_IS_LOADING_PROPERTY_TYPE_LIST_ARCHIVED",
    SET_IS_LOADING_PROPERTY_TYPE = "SET_IS_LOADING_PROPERTY_TYPE",
    SET_IS_CREATING_PROPERTY_TYPE = "SET_IS_CREATING_PROPERTY_TYPE",
    SET_IS_UPDATING_PROPERTY_TYPE = "SET_IS_UPDATING_PROPERTY_TYPE",
    SET_IS_DELETING_PROPERTY_TYPE = "SET_IS_DELETING_PROPERTY_TYPE",
    SET_IS_ARCHIVING_PROPERTY_TYPE = "SET_IS_ARCHIVING_PROPERTY_TYPE",
    SET_REQUEST_STATE_PROPERTY_TYPE = "SET_REQUEST_STATE_PROPERTY_TYPE",
}

export const mutations: MutationTree<IPropertyTypeState> = {
    [PropertyTypeMutations.SET_PROPERTY_TYPE_LIST_ARCHIVED]: (state, payload: PropertyTypeRequestDto[]) => {
        state.propertyTypeListArchived = payload;
    }, 
    [PropertyTypeMutations.SET_PROPERTY_TYPE_LIST]: (state, payload: PropertyTypeRequestDto[]) => {
        state.propertyTypeList = payload;
    },
    [PropertyTypeMutations.SET_PROPERTY_TYPE]: (state, payload: PropertyTypeRequestDto) => {
        state.propertyType = payload;
    },


    //COMMON
    [PropertyTypeMutations.SET_IS_LOADING_PROPERTY_TYPE_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingPropertyTypeListArchived = payload;
    },
    [PropertyTypeMutations.SET_IS_LOADING_PROPERTY_TYPE_LIST]: (state, payload: boolean) => {
        state.isLoadingPropertyTypeList = payload;
    },
    [PropertyTypeMutations.SET_IS_LOADING_PROPERTY_TYPE]: (state, payload: boolean) => {
        state.isLoadingPropertyType = payload;
    },
    [PropertyTypeMutations.SET_IS_CREATING_PROPERTY_TYPE]: (state, payload: boolean) => {
        state.isCreatingPropertyType = payload;
    },
    [PropertyTypeMutations.SET_IS_UPDATING_PROPERTY_TYPE]: (state, payload: boolean) => {
        state.isUpdatingPropertyType = payload;
    },
    [PropertyTypeMutations.SET_IS_DELETING_PROPERTY_TYPE]: (state, payload: boolean) => {
        state.isDeletingPropertyType = payload;
    },
    [PropertyTypeMutations.SET_IS_ARCHIVING_PROPERTY_TYPE]: (state, payload: boolean) => {
        state.isArchivingPropertyType = payload;
    },
    [PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE]: (state, payload: IRequestState) => {
        state.requestStatePropertyType = payload;
    },
}