import { GetterTree } from "vuex";
import RootState from '@/store/modules/rootState'
import { IInvoiceStatisticsState } from "./states";

export const getters: GetterTree<IInvoiceStatisticsState, RootState> = {
    statsCustomerDistribution: (state): [] => state.statsCustomerDistribution,
    statsDocumentByDay: (state): [] => state.statsDocumentByDay,
    statsPeriodeInvoice     : (state): [] => state.statsPeriodeInvoice,
    statsDistributionByActivities :  (state): {} => state.statsDistributionByActivities,

    // COMMON
    isLoadingStatsCustomerDistribution   : (state): boolean => state.isLoadingStatsCustomerDistribution,
    isLoadingStatsDocumentByDay   : (state): boolean => state.isLoadingStatsDocumentByDay,
    isLoadingStatsPeriodeInvoice  : (state): boolean => state.isLoadingStatsPeriodeInvoice,
    isLoadingStatsDistributionByActivities  : (state): boolean => state.isLoadingStatsDistributionByActivities,
}