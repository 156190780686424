import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { PlanningLineMutations } from "./mutations";
import { IPlanningLinesState } from "./states";
import { IFreeLine } from "@/types/api-orisis/interfaces/Planning/IFreeLine";
import { FreeLinePlanningLineRequestDto } from "Api";

export const actions: ActionTree<IPlanningLinesState, RootState> = {
  async getPlanningLineCollaborators(
    { commit },
    { updateState = true } : { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          PlanningLineMutations.SET_IS_LOADING_PLANNING_LINE_COLLABORATOR_LIST,
          true
        );
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Plannings/PlanningLine/GetPlanningLineCollaborators",
        )
        .then((res) => {
          if (updateState) {
            commit(
              PlanningLineMutations.SET_PLANNING_LINES_COLLABORATOR_LIST,
              res.data
            );
            commit(
              PlanningLineMutations.SET_IS_LOADING_PLANNING_LINE_COLLABORATOR_LIST,
              false
            );
          }
          commit(PlanningLineMutations.SET_REQUEST_STATE_PLANNING_LINE, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PlanningLineMutations.SET_REQUEST_STATE_PLANNING_LINE, err);
          commit(
            PlanningLineMutations.SET_IS_LOADING_PLANNING_LINE_COLLABORATOR_LIST,
            false
          );
          reject(err);
        });
    });
  },
  async getPlanningLineAffairs(
    { commit },
    { updateState = true } : { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          PlanningLineMutations.SET_IS_LOADING_PLANNING_LINE_AFFAIR_LIST,
          true
        );
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Plannings/PlanningLine/GetPlanningLineAffairs"
        )
        .then((res) => {
          if (updateState) {
            commit(
              PlanningLineMutations.SET_PLANNING_LINES_AFFAIR_LIST,
              res.data
            );
            commit(
              PlanningLineMutations.SET_IS_LOADING_PLANNING_LINE_AFFAIR_LIST,
              false
            );
          }
          commit(PlanningLineMutations.SET_REQUEST_STATE_PLANNING_LINE, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PlanningLineMutations.SET_REQUEST_STATE_PLANNING_LINE, err);
          commit(
            PlanningLineMutations.SET_IS_LOADING_PLANNING_LINE_AFFAIR_LIST,
            false
          );
          reject(err);
        });
    });
  },

  async getPlanningLineFreeLines(
    { commit },
    { updateState = true } : { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(
          PlanningLineMutations.SET_IS_LOADING_PLANNING_LINE_FREE_LINE_LIST,
          true
        );
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Plannings/PlanningLine/GetPlanningLineFreeLines"
        )
        .then((res) => {
          if (updateState) {
            commit(
              PlanningLineMutations.SET_PLANNING_LINES_FREE_LINE_LIST,
              res.data
            );
            commit(
              PlanningLineMutations.SET_IS_LOADING_PLANNING_LINE_FREE_LINE_LIST,
              false
            );
          }
          commit(PlanningLineMutations.SET_REQUEST_STATE_PLANNING_LINE, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PlanningLineMutations.SET_REQUEST_STATE_PLANNING_LINE, err);
          commit(
            PlanningLineMutations.SET_IS_LOADING_PLANNING_LINE_FREE_LINE_LIST,
            false
          );
          reject(err);
        });
    });
  },

  async getPlanningLineById(
    { commit },
    { planningLineId, updateState = true }: { planningLineId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (updateState) {
        commit(PlanningLineMutations.SET_IS_LOADING_PLANNING_LINE, true);
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Plannings/PlanningLine/GetPlanningLineById",
          {
            params: {
              planningLineId: planningLineId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(PlanningLineMutations.SET_PLANNING_LINE, res.data);
            commit(PlanningLineMutations.SET_IS_LOADING_PLANNING_LINE, false);
          }
          commit(PlanningLineMutations.SET_REQUEST_STATE_PLANNING_LINE, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PlanningLineMutations.SET_REQUEST_STATE_PLANNING_LINE, err);
          commit(PlanningLineMutations.SET_IS_LOADING_PLANNING_LINE, false);
          reject(err);
        });
    });
  },

  async createPlanningLine(
    { commit, rootGetters },
    { planningLine } : { planningLine: FreeLinePlanningLineRequestDto }
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      commit(PlanningLineMutations.SET_IS_CREATING_PLANNING_LINE, true);
      await axios
        .post(
          process.env.VUE_APP_API_ORISIS +
            "Plannings/PlanningLine/CreateFreeLine",
          planningLine,
        )
        .then((res) => {
          rootGetters.planningLinesFreeLineList.unshift(res.data.data);
          commit(
            PlanningLineMutations.SET_PLANNING_LINES_FREE_LINE_LIST,
            rootGetters.planningLinesFreeLineList
          );
          commit(PlanningLineMutations.SET_REQUEST_STATE_PLANNING_LINE, res);
          commit(PlanningLineMutations.SET_IS_CREATING_PLANNING_LINE, false);
          resolve(res.data.data);
        })
        .catch((err) => {
          console.error(err);
          commit(PlanningLineMutations.SET_REQUEST_STATE_PLANNING_LINE, err);
          commit(PlanningLineMutations.SET_IS_CREATING_PLANNING_LINE, false);
          reject(err);
        });
    });
  },

  async updatePlanningLine(
    { commit, rootGetters },
    { planningLine } : { planningLine: FreeLinePlanningLineRequestDto }
  ): Promise<void> {
    commit(PlanningLineMutations.SET_IS_UPDATING_PLANNING_LINE, true);
    await axios
      .put(
        process.env.VUE_APP_API_ORISIS +
          "Plannings/PlanningLine/UpdateFreeLine",
        planningLine,
      )
      .then((res) => {
        rootGetters.planningLinesFreeLineList.splice(
          rootGetters.planningLinesFreeLineList.findIndex(
            (elem) => elem.id == planningLine.id
          ),
          1,
          planningLine
        );
        commit(
          PlanningLineMutations.SET_PLANNING_LINES_FREE_LINE_LIST,
          rootGetters.planningLinesFreeLineList
        );
        commit(PlanningLineMutations.SET_REQUEST_STATE_PLANNING_LINE, res);
        commit(PlanningLineMutations.SET_IS_UPDATING_PLANNING_LINE, false);
      })
      .catch((err) => {
        console.error(err);
        commit(PlanningLineMutations.SET_REQUEST_STATE_PLANNING_LINE, err);
        commit(PlanningLineMutations.SET_IS_UPDATING_PLANNING_LINE, false);
      });
  },
};
