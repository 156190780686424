import { OrderFormDetailRequestDto } from "Api";
import { GetterTree } from "vuex";
import RootState from '@/store/modules/rootState'
import { IOrderFormProviderDetailState } from "./states";

export const getters: GetterTree<IOrderFormProviderDetailState, RootState> = {
    getOrderFormProviderHeader: (state): any => state.orderFormProviderHeader,
    getOrderFormProviderDetails: (state): OrderFormDetailRequestDto[] => state.orderFormProviderDetails,
    getOrderFormProviderDetail: (state) : OrderFormDetailRequestDto => state.orderFormProviderDetail,
    isLoadingOrderFormProviderDetails: (state) : boolean => state.isLoadingOrderFormProviderDetails,
    isLoadingOrderFormProviderDetailDuplication: (state) : boolean => state.isLoadingOrderFormProviderDetailDuplication,
    isCreatingOrderFormProviderDetail: (state): boolean => state.isCreatingOrderFormProviderDetail,
    isUpdatingOrderFormProviderDetail: (state): boolean => state.isUpdatingOrderFormProviderDetail,
    isDeletingOrderFormProviderDetail: (state): boolean => state.isDeletingOrderFormProviderDetail,
}