import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "../../rootState";
import { ExportMutations } from "./mutations";
import { IExportState } from "./states";
import dayjs from "dayjs";

export const actions: ActionTree<IExportState, RootState> = {
  async getExportPeriodByInstitution(
    { commit, rootGetters },
    {
      exportTypeId,
      startTo,
      endTo,
      updateState = true,
    }: {
      exportTypeId: number;
      startTo: Date;
      endTo: Date;
      updateState: boolean;
    }
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      if (updateState) {
        commit(ExportMutations.SET_IS_LOADING_EXPORT, true);
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/Export/ExportPeriodByInstitution",
          {
            params: {
              exportTypeId: exportTypeId,
              startTo: startTo,
              endTo: endTo,
              haveHoldbackGuarantee:
                rootGetters.institution.id === 9 ? true : false,
              numberingInvoiceCreditFollow: true,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(ExportMutations.SET_EXPORT, res.data);
            commit(ExportMutations.SET_IS_LOADING_EXPORT, false);
          }
          commit(ExportMutations.SET_REQUEST_STATE_EXPORT, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ExportMutations.SET_REQUEST_STATE_EXPORT, err);
          commit(ExportMutations.SET_IS_LOADING_EXPORT, false);
          reject(err);
        });
    });
  },

  async getExportCsvPeriodByInstitution(
    { commit },
    {
      startTo,
      endTo,
      updateState = true,
    }: {
      startTo: Date;
      endTo: Date;
      updateState: boolean;
    }
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (updateState) {
        commit(ExportMutations.SET_IS_LOADING_EXPORT_SALES_JOURNAL_CSV, true);
      }
      await axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/Export/ExportCsvPeriodByInstitution",
          {
            params: {
              startTo: dayjs(startTo).format("YYYY-MM-DD"),
              endTo: dayjs(endTo)
                .hour(23)
                .minute(59)
                .second(59)
                .format("YYYY-MM-DD HH:mm:ss"),
              haveHoldbackGuarantee: false,
              numberingInvoiceCreditFollow: true,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(ExportMutations.SET_EXPORT_SALES_JOURNAL_CSV, res.data);
            commit(
              ExportMutations.SET_IS_LOADING_EXPORT_SALES_JOURNAL_CSV,
              false
            );
          }
          commit(ExportMutations.SET_REQUEST_STATE_EXPORT, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ExportMutations.SET_REQUEST_STATE_EXPORT, err);
          commit(
            ExportMutations.SET_IS_LOADING_EXPORT_SALES_JOURNAL_CSV,
            false
          );
          reject(err);
        });
    });
  },

  async getInvoicesCreditsOnTimeSpan(
    { commit },
    {
      startTo,
      endTo,
      updateState = true,
    }: {
      startTo: Date;
      endTo: Date;
      updateState: boolean;
    }
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      if (updateState) {
        commit(ExportMutations.SET_IS_LOADING_INVOICES_CREDITS_TIME_SPAN, true);
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            "Sale/Export/GetInvoicesCreditsOnTimeSpan",
          {
            params: {
              dateFrom: startTo,
              dateTo: endTo,
            },
          }
        )
        .then((res) => {
          let data = res.data.map((item: any) => {
            return {
              ...item,
              companyTypeId: item.companyType.id ? item.companyType.id : 5,
              contactIds: item.contacts.map((contact: any) => contact.id),
            };
          });
          commit(ExportMutations.SET_REQUEST_STATE_EXPORT, res);
          if (updateState) {
            commit(
              ExportMutations.SET_IS_LOADING_INVOICES_CREDITS_TIME_SPAN,
              false
            );
          }
          resolve(data);
        })
        .catch((err) => {
          console.error(err);
          commit(ExportMutations.SET_REQUEST_STATE_EXPORT, err);
          commit(
            ExportMutations.SET_IS_LOADING_INVOICES_CREDITS_TIME_SPAN,
            false
          );
          reject(err);
        });
    });
  },

  async getExportUrl(
    { commit },
    {
      exportTypeId,
      startTo,
      endTo,
      updateState = true,
    }: {
      exportTypeId: number;
      startTo: Date;
      endTo: Date;
      updateState: boolean;
    }
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (updateState) {
        commit(ExportMutations.SET_IS_LOADING_EXPORT_URL, true);
      }
      await axios
        .get(process.env.VUE_APP_API_ORISIS + "Sale/Export/GetExportUrl", {
          params: {
            exportTypeId: exportTypeId,
            startTo: startTo,
            endTo: endTo,
          },
        })
        .then((res) => {
          if (updateState) {
            commit(ExportMutations.SET_EXPORT_URL, res.data);
            commit(ExportMutations.SET_IS_LOADING_EXPORT_URL, false);
          }
          commit(ExportMutations.SET_REQUEST_STATE_EXPORT, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(ExportMutations.SET_REQUEST_STATE_EXPORT, err);
          commit(ExportMutations.SET_IS_LOADING_EXPORT_URL, false);
          reject(err);
        });
    });
  },
};
