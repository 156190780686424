import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '@/store/modules/rootState'
import { OrderFormCustomerHistoryMutations } from './mutations'
import { IOrderFormCustomerHistoryState } from './states'

export const actions: ActionTree<IOrderFormCustomerHistoryState, RootState> = {
  getOrderFormCustomerHistoryByOrderFormCustomerId ({ commit, rootGetters }, {orderFormCustomerId, updateState = true}: {orderFormCustomerId: number, updateState: boolean}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(OrderFormCustomerHistoryMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_HISTORIES_LIST, true);
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            'Sale/OrderFormCustomerHistory/GetOrderFormCustomerHistoryByOrderFormCustomerId',
          {
            params: {
              orderFormCustomerId: orderFormCustomerId
            }
          }
        )
        .then(res => {
          if (updateState) {
            commit(OrderFormCustomerHistoryMutations.SET_ORDER_FORM_CUSTOMER_HISTORIES_LIST, res.data);
            commit(OrderFormCustomerHistoryMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_HISTORIES_LIST, false);
          }
          commit(OrderFormCustomerHistoryMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_HISTORY, res);
          resolve(res.data);
        })
        .catch(err => {
          console.error(err);
          commit(OrderFormCustomerHistoryMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_HISTORY, err);
          if (updateState) {
            commit(OrderFormCustomerHistoryMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_HISTORIES_LIST, false);
          }
          reject(err);
        })
    });
  },
  getOrderFormCustomerHistoryById ({ commit, rootGetters }, {orderFormCustomerHistoryId, updateState = true}: {orderFormCustomerHistoryId: number, updateState: boolean}): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(OrderFormCustomerHistoryMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_HISTORY, true);
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            'Sale/OrderFormCustomerHistory/GetOrderFormCustomerHistoryById',
          {
            params: {
              orderFormCustomerHistoryId: orderFormCustomerHistoryId
            }
          }
        )
        .then(res => {
          if (updateState) {
            commit(OrderFormCustomerHistoryMutations.SET_ORDER_FORM_CUSTOMER_HISTORY, res.data);
            commit(OrderFormCustomerHistoryMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_HISTORY, false);
          }
          commit(OrderFormCustomerHistoryMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_HISTORY, res);
          resolve(res.data);
        })
        .catch(err => {
          console.error(err);
          commit(OrderFormCustomerHistoryMutations.SET_REQUEST_STATE_ORDER_FORM_CUSTOMER_HISTORY, err);
          if (updateState) {
            commit(OrderFormCustomerHistoryMutations.SET_IS_LOADING_ORDER_FORM_CUSTOMER_HISTORY, false);
          }
          reject(err);
        })
    });
  },
}