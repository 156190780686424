import { ICollaboratorGoalState } from "./states"
import { Module } from "vuex"
import { CollaboratorGoalRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/rootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:ICollaboratorGoalState = {
    collaboratorGoalsList         : Array<CollaboratorGoalRequestDto>(),
    collaboratorGoalsListArchived : Array<CollaboratorGoalRequestDto>(),
    collaboratorGoal              : <CollaboratorGoalRequestDto>{},
    isLoadingCollaboratorGoalsList     : false,
    isLoadingCollaboratorGoalsListArchived     : false,
    isLoadingCollaboratorGoal     : false,
    isCreatingCollaboratorGoal    : false,
    isUpdatingCollaboratorGoal    : false,
    isDeletingCollaboratorGoal    : false,
    isArchivingCollaboratorGoal    : false,
    requestStateCollaboratorGoal: <IRequestState>{},
    collaboratorGoalFilters                : {}
}

export const CollaboratorGoalModule: Module<ICollaboratorGoalState, RootState> = {
    state, 
    getters,
    mutations,
    actions
} 