import { IDocumentHistoryState } from "./states";
import { MutationTree } from "vuex";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export enum DocumentHistoryMutations {
  SET_DOCUMENT_HISTORIES_LIST = "SET_DOCUMENT_HISTORIES_LIST",
  SET_DOCUMENT_HISTORY = "SET_DOCUMENT_HISTORY",

  // COMON
  SET_IS_LOADING_DOCUMENT_HISTORIES_LIST = "SET_IS_LOADING_DOCUMENT_HISTORIES_LIST",
  SET_IS_LOADING_DOCUMENT_HISTORY = "SET_IS_LOADING_DOCUMENT_HISTORY",
  SET_REQUEST_STATE_DOCUMENT_HISTORY = "SET_REQUEST_STATE_DOCUMENT_HISTORY",
}

  export const mutations: MutationTree<IDocumentHistoryState> = {
    [DocumentHistoryMutations.SET_DOCUMENT_HISTORIES_LIST]: (state, payload: []) => {
        state.documentHistoriesList = payload;
    },
    [DocumentHistoryMutations.SET_DOCUMENT_HISTORY]: (state, payload: {}) => {
        state.documentHistory = payload;
    },


    //COMMON
    [DocumentHistoryMutations.SET_IS_LOADING_DOCUMENT_HISTORIES_LIST]: (state, payload: boolean) => {
        state.isLoadingDocumentHistoriesList = payload;
    },
    [DocumentHistoryMutations.SET_IS_LOADING_DOCUMENT_HISTORY]: (state, payload: boolean) => {
        state.isLoadingDocumentHistory = payload;
    },
    [DocumentHistoryMutations.SET_REQUEST_STATE_DOCUMENT_HISTORY]: (state, payload: IRequestState) => {
        state.requestStateDocumentHistory = payload;
    },
}