import { UserRequestDto } from "@/../Api";
import axios from "axios";
import { ActionTree } from "vuex";
import RootState from "@/store/modules/rootState";
import { UserMutations } from "./mutations";
import { IUserState } from "./states";
import { update } from "@syncfusion/ej2-vue-inplace-editor";

export const actions: ActionTree<IUserState, RootState> = {
  getUsers(
    { commit, rootGetters },
    { updateState = true }: { updateState?: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(UserMutations.SET_IS_LOADING_USERS_LIST, true);
      }
      axios
        .get(process.env.VUE_APP_API_ORISIS + "Account/User/GetUsers", {
          params: {
            institutionId: rootGetters.institutionSelected.id
          }
        })
        .then((res) => {
          commit(UserMutations.SET_REQUEST_STATE_USER, res);
          if (updateState) {
            commit(UserMutations.SET_USERS_LIST, res.data);
            commit(UserMutations.SET_IS_LOADING_USERS_LIST, false);
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(UserMutations.SET_REQUEST_STATE_USER, err);
          if (updateState) {
            commit(UserMutations.SET_IS_LOADING_USERS_LIST, false);
          }
          reject(err);
        });
    });
  },

  getUsersByInstitution(
    { commit, rootGetters },
    { updateState = true }: { updateState?: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(UserMutations.SET_IS_LOADING_USERS_LIST, true);
      }
      axios
        .get(process.env.VUE_APP_API_ORISIS + "Account/User/GetUsersByInstitution", {
          params: {
            institutionId: rootGetters.institutionSelected.id
          }
        })
        .then((res) => {
          commit(UserMutations.SET_REQUEST_STATE_USER, res);
          if (updateState) {
            commit(UserMutations.SET_USERS_LIST, res.data);
            commit(UserMutations.SET_IS_LOADING_USERS_LIST, false);
          }
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(UserMutations.SET_REQUEST_STATE_USER, err);
          if (updateState) {
            commit(UserMutations.SET_IS_LOADING_USERS_LIST, false);
          }
          reject(err);
        });
    });
  },

  getUserById(
    { commit, rootGetters },
    { userId, updateState = true }: { userId: number; updateState: boolean }
  ): Promise<UserRequestDto> {
    return new Promise<UserRequestDto>((resolve, reject) => {
      if (updateState) {
        commit(UserMutations.SET_IS_LOADING_USER, true);
      }
      axios
        .get(process.env.VUE_APP_API_ORISIS + "Account/User/GetUserById", {
          params: {
            userId: userId,
          },
        })
        .then((res) => {
          if (updateState) {
            commit(UserMutations.SET_IS_LOADING_USER, false);
            commit(UserMutations.SET_USER, res.data);
          }
          commit(UserMutations.SET_REQUEST_STATE_USER, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(UserMutations.SET_REQUEST_STATE_USER, err);
          if (updateState) {
            commit(UserMutations.SET_IS_LOADING_USER, false);
          }
          reject(err);
        });
    });
  },

  getUserInfoConnected(
    { commit, rootGetters },
    { updateState = true }: { updateState?: boolean } = {}
  ): Promise<UserRequestDto> {
    return new Promise<UserRequestDto>((resolve, reject) => {
      if (updateState) {
        commit(UserMutations.SET_IS_LOADING_USER, true);
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS + "Account/User/getUserInfoConnected"
        )
        .then((res) => {
          if (updateState) {
            commit(UserMutations.SET_IS_LOADING_USER, false);
            commit(UserMutations.SET_CONNECTED_USER, res.data);
          }
          commit(UserMutations.SET_REQUEST_STATE_USER, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(UserMutations.SET_REQUEST_STATE_USER, err);
          if (updateState) {
            commit(UserMutations.SET_IS_LOADING_USER, false);
          }
          reject(err);
        });
    });
  },

  createUser(
    { commit, rootGetters },
    { user }: { user: UserRequestDto }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(UserMutations.SET_IS_CREATING_USER, true);
      axios
        .post(process.env.VUE_APP_API_ORISIS + "Account/User/CreateUser", user)
        .then((res) => {
          rootGetters.usersList.unshift(res.data);
          commit(UserMutations.SET_USERS_LIST, rootGetters.usersList);
          commit(UserMutations.SET_REQUEST_STATE_USER, res);
          commit(UserMutations.SET_IS_CREATING_USER, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(UserMutations.SET_REQUEST_STATE_USER, err);
          commit(UserMutations.SET_IS_CREATING_USER, false);
          reject(err);
        });
    });
  },

  updateUser(
    { commit, rootGetters },
    { user }: { user: UserRequestDto }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(UserMutations.SET_IS_UPDATING_USER, true);
      axios
        .put(process.env.VUE_APP_API_ORISIS + "Account/User/UpdateUser", user)
        .then((res) => {
          rootGetters.usersList.splice(
            rootGetters.usersList.findIndex((elem) => elem.id == user.id),
            1,
            user
          );
          commit(UserMutations.SET_USERS_LIST, rootGetters.usersList);
          commit(UserMutations.SET_REQUEST_STATE_USER, res);
          commit(UserMutations.SET_IS_UPDATING_USER, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(UserMutations.SET_REQUEST_STATE_USER, err);
          commit(UserMutations.SET_IS_UPDATING_USER, false);
          reject(err);
        });
    });
  },

  activeUserAccounts(
    { commit, rootGetters },
    { userIds }: { userIds: number[] }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(UserMutations.SET_IS_UPDATING_USER, true);
      axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Account/User/ActiveUserAccounts",
          userIds
        )
        .then((res) => {
          commit(UserMutations.SET_REQUEST_STATE_USER, res);
          commit(UserMutations.SET_IS_UPDATING_USER, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(UserMutations.SET_REQUEST_STATE_USER, err);
          commit(UserMutations.SET_IS_UPDATING_USER, false);
          reject(err);
        });
    });
  },

  disableUserAccounts(
    { commit, rootGetters },
    { userIds }: { userIds: number[] }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(UserMutations.SET_IS_UPDATING_USER, true);
      axios
        .put(
          process.env.VUE_APP_API_ORISIS + "Account/User/DisableUserAccounts",
          userIds
        )
        .then((res) => {
          commit(UserMutations.SET_REQUEST_STATE_USER, res);
          commit(UserMutations.SET_IS_UPDATING_USER, false);
          commit(
            UserMutations.SET_USERS_LIST,
            rootGetters.usersList.map((user) => {
              if (userIds.includes(user.id)) {
                user.isActive = false;
              }
              return user;
            })
          );
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(UserMutations.SET_REQUEST_STATE_USER, err);
          commit(UserMutations.SET_IS_UPDATING_USER, false);
          reject(err);
        });
    });
  },

  getUserArchivedById(
    { commit, rootGetters },
    { userId, updateState = true }: { userId: number; updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(UserMutations.SET_IS_LOADING_USER, true);
      }
      axios
        .get(
          process.env.VUE_APP_API_ORISIS + "Account/User/GetUserArchivedById",
          {
            params: {
              userId: userId,
            },
          }
        )
        .then((res) => {
          if (updateState) {
            commit(UserMutations.SET_IS_LOADING_USER, false);
            commit(UserMutations.SET_USER, res.data);
          }
          commit(UserMutations.SET_REQUEST_STATE_USER, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(UserMutations.SET_REQUEST_STATE_USER, err);
          if (updateState) {
            commit(UserMutations.SET_IS_LOADING_USER, false);
          }
          reject(err);
        });
    });
  },

  getUsersArchived(
    { commit, rootGetters },
    { updateState = true }: { updateState: boolean }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (updateState) {
        commit(UserMutations.SET_IS_LOADING_USERS_LIST_ARCHIVED, true);
      }
      axios
        .get(process.env.VUE_APP_API_ORISIS + "Account/User/GetUsersArchived")
        .then((res) => {
          if (updateState) {
            commit(UserMutations.SET_USERS_LIST_ARCHIVED, res.data);
            commit(UserMutations.SET_IS_LOADING_USERS_LIST_ARCHIVED, false);
          }
          commit(UserMutations.SET_REQUEST_STATE_USER, res);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(UserMutations.SET_REQUEST_STATE_USER, err);
          if (updateState) {
            commit(UserMutations.SET_IS_LOADING_USERS_LIST_ARCHIVED, false);
          }
          reject(err);
        });
    });
  },

  restoreUsers(
    { commit, rootGetters },
    { usersIds }: { usersIds: number[] }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(UserMutations.SET_IS_UPDATING_USER, true);
      axios
        .put(process.env.VUE_APP_API_ORISIS + "Account/User/Restore", usersIds)
        .then((res) => {
          rootGetters.usersList = rootGetters.usersList.filter(
            (elem) => !usersIds.includes(elem.id)
          );
          commit(UserMutations.SET_USERS_LIST, rootGetters.usersList);
          commit(UserMutations.SET_REQUEST_STATE_USER, res);
          commit(UserMutations.SET_IS_UPDATING_USER, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(UserMutations.SET_REQUEST_STATE_USER, err);
          commit(UserMutations.SET_IS_UPDATING_USER, false);
          reject(err);
        });
    });
  },

  archiveUsers(
    { commit, rootGetters },
    { usersIds }: { usersIds: number[] }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(UserMutations.SET_IS_ARCHIVING_USER, true);
      axios
        .delete(process.env.VUE_APP_API_ORISIS + "Account/User/Archive", {
          data: usersIds,
        })
        .then((res) => {
          rootGetters.usersList = rootGetters.usersList.filter(
            (elem) => !usersIds.includes(elem.id)
          );
          commit(UserMutations.SET_USERS_LIST, rootGetters.usersList);
          commit(UserMutations.SET_REQUEST_STATE_USER, res);
          commit(UserMutations.SET_IS_ARCHIVING_USER, false);
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          commit(UserMutations.SET_REQUEST_STATE_USER, err);
          commit(UserMutations.SET_IS_ARCHIVING_USER, false);
          reject(err);
        });
    });
  },
};
