import { IInventoryDetailState } from "./states";
import IInventoryHeader from "@/types/api-orisis/interfaces/Purchase/inventoryDetail/IInventoryHeader";
import { Module } from "vuex";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import RootState from '@/store/modules/rootState'
import { EInputType } from "@/types/api-orisis/enums/enums";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";


const inventoryHeader: IInventoryHeader = {
  // index: {
  //   text: 'N°',
  //   display: true,
  //   editable: false,
  //   class: 'col-num pl-0',
  //   style:"min-height: 28px !important; max-width: 60px!important;min-width: 60px!important;margin-left:25px;width: 60px!important; padding-left: 0.25em!important; padding-right: 0.25em!important;",
  //   inputType: EInputType.Text,
  //   unit: '',
  //   prefix: '',
  //   useWorkElements:false
  // },
  reference: {
    text: 'Référence',
    display: true,
    editable: true,
    class: 'col-ref reference',
    style: 'width:10%; max-width: 90px; min-height: 28px !important;',
    inputType: EInputType.Text,
    useWorkElements:true
  },
  description: {
    text: 'Désignation',
    display: true,
    editable: true,
    class: 'col-label',
    style: 'width:auto; max-width: none;flex-grow: 1;',
    inputType: EInputType.SelectCatalog,
    useWorkElements:true
  },
  stock: {
    text: 'Stock informatique',
    display: true,
    editable: true,
    class: 'col-qte text-center',
    style: 'width:10%; max-width:90px;',
    inputType: EInputType.Number,
    unit: '',
    numberToFixed : 2,
    prefix: '',
    useWorkElements:true
  },
  quantityNoted: {
    text: 'Quantité relevée',
    display: true,
    editable: true,
    class: 'col-qte text-center',
    style: 'width:10%; max-width:90px;',
    inputType: EInputType.Number,
    unit: '',
    numberToFixed : 2,
    prefix: '',
    useWorkElements:true
  },
  unitId: {
    text: 'Unité',
    display: true,
    editable: true,
    class: 'col-unit text-center',
    style: 'width:10%; max-width:70px;',
    inputType: EInputType.Select,
    choice: [],
    useWorkElements:true
  }
}

export const state: IInventoryDetailState = {
  inventoryHeader: inventoryHeader,
  inventoryDetails: [],
  inventoryDetail: {},
  inventoryDetailsFormated: [],
  isLoadingInventoryDetails: false,
  isCreatingInventoryDetail: false,
  isUpdatingInventoryDetail: false,
  isDeletingInventoryDetail: false,
  requestStateInventory: <IRequestState>{},
};

export const InventoryDetailModule: Module<IInventoryDetailState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
